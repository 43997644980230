import React, { Dispatch, SetStateAction } from 'react';

import QuotaComponentDescription from 'app/components/BattleCardDiagram/Cards/BattleCardContent/QuotaComponentDescription/QuotaComponentDescription';

import { BattleCardData, BattleCardSection, SectionName } from 'app/models';

import block from 'utils/bem-css-modules';

import BattleCardCollapsedGlobalMeasures from './BattleCardCollapsedGlobalMeasures/BattleCardCollapsedGlobalMeasures';
import style from './BattleCardCollapsedView.module.pcss';
import PlanningButtonContainer from './PlanningButtonContainer/PlanningButtonContainer';

const b = block(style);

interface BattleCardCollapsedViewProps {
  isBcActive?: boolean;
  section: BattleCardSection;
  panelOpenState: Record<string, boolean>;
  setPanelOpenState: Dispatch<SetStateAction<Record<string, boolean>>>;
  index: number;
  toggleViewExpandedCard: (event, cardId, panelSectionName) => void;
  cardData: BattleCardData;
}

const BattleCardCollapsedView: React.FC<BattleCardCollapsedViewProps> = ({
  cardData,
  isBcActive,
  section,
  panelOpenState,
  index,
  toggleViewExpandedCard
}: BattleCardCollapsedViewProps) => {
  const isNotChildSectionName = section.name === SectionName.GLOBAL_MEASURES;
  const isExpanded = isBcActive && panelOpenState[section.name];

  return (
    <div>
      {isNotChildSectionName && (
        <>
          <QuotaComponentDescription cardData={cardData} data-testid="qc-description" />
          <div className={b('collapse')}>
            <span className={b('panelTitleAreaBattleCardRefactor')}>{section.header}</span>
            <BattleCardCollapsedGlobalMeasures
              key={index}
              isExpanded={isExpanded}
              section={section}
              cardData={cardData}
              data-testid={`collapsed-global-measures`}
            />
            <PlanningButtonContainer
              toggleViewExpandedCard={toggleViewExpandedCard}
              cardData={cardData}
              data-testid={`planning-button-container`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BattleCardCollapsedView;
