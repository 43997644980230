import React, { FC, useMemo } from 'react';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import {
  PermissionsContextRedistributorContextValues,
  PermissionsContextRedistributorProvider
} from './permissionsContextRedistributorProvider';

export const BCCanvasPermissionsLoader: FC = ({ children }) => {
  const { selectedTenant } = useScope();
  const { selectedBattleCardId, battleCardLookupMap } = useBattleCard();
  const { userRole, userMembershipSpecs } = useUser();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { selectedPillIdTDR, tdrLookupMap } = useTerritoryDefineAndRefine();

  const selectedTenantId = selectedTenant?.id;

  const selectedTerritoryGroupId = selectedPillIdPlanTargets || selectedPillIdTDR;

  const territoryGroups = Object.values(tdrLookupMap || {});

  const contextValue = useMemo(
    (): PermissionsContextRedistributorContextValues => ({
      selectedTenantId,
      selectedBattleCardId,
      battleCardLookupMap,
      selectedTerritoryGroupId,
      territoryGroups,
      userRole,
      userMembershipSpecs
    }),
    [
      selectedTenantId,
      selectedBattleCardId,
      battleCardLookupMap,
      selectedTerritoryGroupId,
      territoryGroups,
      userRole,
      userMembershipSpecs
    ]
  );

  return (
    <PermissionsContextRedistributorProvider value={contextValue}> {children}</PermissionsContextRedistributorProvider>
  );
};
