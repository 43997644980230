// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hh-f7, ._0-wDi, .YHxj6, .VsSFU, ._4mzAM {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-bottom: 10px;\n  }\n  .Hh-f7, ._4mzAM {\n    padding-left: 20px;\n    padding-right: 5px;\n    color: rgb(var(--color-gray-3));\n  }\n  ._4mzAM {\n    padding-right: 0;\n  }\n  .zZ1wJ {\n    padding-left: 24px;\n    font-size: 12px;\n    overflow-y: auto;\n    overflow-x: hidden;\n    max-height: 160px;\n  }\n  .iJqDd {\n    background-color: rgba(var(--color-cobalt-4), 0.3);\n    color: rgb(var(--color-cobalt-3));\n    padding: 5px;\n    border-radius: 4px;\n  }\n  .YHxj6 {\n    background-color: rgb(var(--color-green-5));\n    color: rgb(var(--color-green-2));\n    padding: 5px;\n    border-radius: 4px;\n  }\n  .VsSFU {\n    background-color: rgba(var(--color-red-3), 0.15);\n    color: rgb(var(--color-red-3));\n    padding: 5px;\n    border-radius: 4px;\n  }\n  .Hk3fg {\n    flex-grow: 1;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AllocationSummary_allocationSummaryItem": "Hh-f7",
	"AllocationSummary_quotaItem": "_0-wDi",
	"AllocationSummary_positiveGapItem": "YHxj6",
	"AllocationSummary_negativeGapItem": "VsSFU",
	"AllocationSummary_noValueItem": "_4mzAM",
	"AllocationSummary_hierarchyTargets": "zZ1wJ",
	"AllocationSummary_allocationItemValueWithBackground": "iJqDd",
	"AllocationSummary_allocationItemValueSkeleton": "Hk3fg"
};
export default ___CSS_LOADER_EXPORT___;
