// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._09pgT {\n    height: 100%;\n    border-radius: 12px;\n    background-color: var(--white);\n    padding: var(--m);\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n  .Xy2gt {\n    width: 100%;\n  }\n  .zLtFs {\n    display: flex;\n    justify-content: space-between;\n  }\n  ._0UABG {\n    margin: var(--m) 0;\n  }\n  .H9ecS {\n    background-color: white !important;\n    padding: 0 !important;\n    margin: var(--m) 0;\n  }\n  .H9ecS .bp3-text-overflow-ellipsis {\n      max-width: 200px !important;\n    }\n  ._7Bnsu {\n    margin-left: var(--m);\n  }\n  .aTPRu {\n    height: 20px;\n    color: rgb(var(--color-red-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Constraints_constraintsContainer": "_09pgT",
	"Constraints_content": "Xy2gt",
	"Constraints_footer": "zLtFs",
	"Constraints_constraintsPageDescription": "_0UABG",
	"Constraints_conditionBuilder": "H9ecS",
	"Constraints_optimizeBtn": "_7Bnsu",
	"Constraints_validationMessage": "aTPRu"
};
export default ___CSS_LOADER_EXPORT___;
