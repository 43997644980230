// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VYJuR {\n  width: inherit;\n  height: inherit;\n}\n\n  ._3h\\+Dj {\n    width: 768px;\n    padding: 10px;\n    margin-bottom: 20px;\n    border: 0;\n    border-bottom: 1px solid black;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchableTreeDialog": "VYJuR",
	"SearchableTreeDialog_searchInput": "_3h+Dj"
};
export default ___CSS_LOADER_EXPORT___;
