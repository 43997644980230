import React, { useState, useMemo, createContext, useRef, useCallback } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { PCJobType } from 'app/graphql/generated/graphqlApolloTypes';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { UserRoleType, UserPlanningCycle, MembershipSpec, BaseContext } from 'app/models';

import {
  getScreenResolution,
  getViewportResolution,
  isLikelyExternalUser,
  NavigatorWithDeviceMemory
} from 'utils/helpers/datadogEventTransformers';
import { deepEqual } from 'utils/helpers/index';

export type UserProfile = {
  tenant: { id?: number; slug?: string };
  subjectId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  isSupportUser: boolean;
};

export type InvitationParams = {
  invitationId: string;
  regType: string;
};

export type PcJobInProgress = {
  planningCycleId: number;
  planningCycleName: string;
  jobType: PCJobType;
};

export interface UserContextValues extends BaseContext {
  invitation: InvitationParams;
  setInvitation: (invitation: InvitationParams) => void;
  userProfile: UserProfile;
  setUserProfile: (userProfile: UserProfile) => void;
  userRole: UserRoleType;
  setUserRole: (userRole: UserRoleType) => void;
  isServiceRep: boolean;
  setIsServiceRep: (isServiceRep: boolean) => void;
  userPlanningCycles: UserPlanningCycle[];
  setUserPlanningCycles: (userPlanningCycles: UserPlanningCycle[]) => void;
  userMembershipSpecs: MembershipSpec[];
  setUserMembershipSpecs: (membershipSpecs: MembershipSpec[]) => void;
  shouldFetchUserSession: boolean;
  setShouldFetchUserSession: (shouldFetchUserSession: boolean) => void;
  pcJobsInProgress: PcJobInProgress[];
  setPcJobsInProgress: (pcJobsInProgress: PcJobInProgress[]) => void;
  resetValues: () => void;
}

export const UserContext = createContext<UserContextValues | null>(null);
UserContext.displayName = 'UserContext';

export const emptyUserProfile = {
  tenant: { id: null, slug: '' },
  subjectId: null,
  firstName: '',
  lastName: '',
  emailAddress: '',
  isSupportUser: false
};

// Repairing this hack and the other ref hacks in this file is scoped to https://varicent.atlassian.net/browse/TQP-12931
const fallbackUserPlanningCycles = [
  {
    planningCycleId: null,
    planningCycleName: null,
    planningCyclePeriodicity: null,
    planningCycleStartDate: null,
    planningCycleDuration: null,
    planningCycleSlug: null,
    currencyCode: null,
    deploymentModels: null,
    planningCycleArchived: false,
    planningCycleDescription: ''
  }
];

export const UserProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [invitation, setInvitation] = useState<InvitationParams>({ invitationId: null, regType: null });
  const [userRole, setUserRole] = useState(UserRoleType.NONE);
  const [isServiceRep, setIsServiceRep] = useState(false);
  const [userProfile, setUserProfileRaw] = useState<UserProfile>(emptyUserProfile);
  const [shouldFetchUserSession, setShouldFetchUserSession] = useState(false); // don't put this in resetValues - see the description of https://github.com/varicent-tq/tq-ui/pull/1498 for context

  const [pcJobsInProgress, setPcJobsInProgress] = useState<PcJobInProgress[]>([]);
  const [userPlanningCycles, setUserPlanningCycles] = useState<UserPlanningCycle[]>(fallbackUserPlanningCycles);

  const [userMembershipSpecs, setUserMembershipSpecs] = useState<MembershipSpec[]>([
    {
      memberId: null,
      tenantId: null,
      tenantSlug: null,
      tenantName: null,
      systemRoleName: null,
      planningCycles: null,
      tenantGlobalId: null
    }
  ]);

  const userProfileRef = useRef<UserProfile>({
    tenant: null,
    subjectId: null,
    firstName: '',
    lastName: '',
    emailAddress: '',
    isSupportUser: false
  });

  // update user profile in context and DataDog RUM
  const setUserProfile = useCallback((userProfile: UserProfile) => {
    setUserProfileRaw(userProfile);

    const { maxTouchPoints, languages, hardwareConcurrency, deviceMemory } = navigator as NavigatorWithDeviceMemory;

    datadogRum.setUser({
      id: userProfile.subjectId,
      name: `${userProfile.firstName} ${userProfile.lastName}`,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      email: userProfile.emailAddress,
      isLikelyExternalUser: isLikelyExternalUser(userProfile.emailAddress),
      viewportResolution: getViewportResolution(),
      screenResolution: getScreenResolution(),
      maxTouchPoints,
      languages,
      hardwareConcurrency,
      deviceMemory
    });
  }, []);

  // since these two objects are bloated, assigning them to ref to track their state change

  if (!deepEqual(userProfileRef.current, userProfile)) {
    userProfileRef.current = userProfile;
  }

  const resetValues = () => {
    setUserRole(UserRoleType.NONE);
    setUserPlanningCycles(fallbackUserPlanningCycles);
    setInvitation({ invitationId: null, regType: null });
    setUserMembershipSpecs(null);
    setIsServiceRep(false);
  };

  const values = useMemo(
    () => ({
      invitation,
      setInvitation,
      userProfile,
      setUserProfile,
      userRole,
      setUserRole,
      isServiceRep,
      setIsServiceRep,
      userPlanningCycles,
      setUserPlanningCycles,
      userMembershipSpecs,
      setUserMembershipSpecs,
      shouldFetchUserSession,
      setShouldFetchUserSession,
      pcJobsInProgress,
      setPcJobsInProgress,
      resetValues
    }),
    [
      invitation,
      userProfileRef.current,
      userPlanningCycles,
      userRole,
      isServiceRep,
      shouldFetchUserSession,
      userMembershipSpecs,
      pcJobsInProgress
    ]
  );

  // Return the interface that we want to expose to our other components
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// Custom hook to read these values from
export const useUser = (): UserContextValues => useContextSafe(UserContext);
