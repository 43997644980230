import React from 'react';

import CyclesPageHeader from 'app/components/CyclesPageHeader/CyclesPageHeader';
import UserMenu from 'app/components/UserMenu/UserMenu';

import ErrorPage, { ErrorType } from 'app/containers/App/ErrorPage/ErrorPage';

import { formatMessage } from 'utils/messages/utils';

import varicentLogo from 'assets/svgs/varicentlogo.svg';

const MaintenancePage: React.FC = () => (
  <>
    <CyclesPageHeader
      logoPath="/"
      logoButton={<img src={varicentLogo} alt={formatMessage('VARICENT_SP')} />}
      rightActionItems={[<UserMenu key="user-menu" />]}
      hideActions
    />
    <ErrorPage errorType={ErrorType._503} />
  </>
);

export default MaintenancePage;
