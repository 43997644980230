import React from 'react';

import { Field, Form } from 'formik';

import TerritorySearchableSelect from 'app/components/AdvancedGrid/Sheets/Account/TerritorySearchableSelect';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SimpleReassignDialogBody.module.pcss';

const b = block(style);

export interface SimpleReassignDialogBodyProps {
  territoryGroupId: number;
}

const SimpleReassignDialogBody: React.FC<SimpleReassignDialogBodyProps> = ({ territoryGroupId }) => {
  return (
    <>
      <p>{formatMessage('MOVE_ACCOUNTS_DIALOG_BODY')}</p>
      <div className={b('territorySearchHeader')}>{formatMessage('TERRITORY_REQUIRED_MARK')}</div>
      <Form>
        <Field name="targetRule" component={TerritorySearchableSelect} territoryGroupId={territoryGroupId} />
      </Form>
    </>
  );
};

export default SimpleReassignDialogBody;
