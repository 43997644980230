// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yfu4k {\n  width: 100%;\n  min-height: 100%;\n  overflow-y: scroll;\n  display: inline-flex;\n  flex-direction: column;\n  padding-bottom: var(--data-panel-content-bottom-padding);\n}\n\n  .mKsPG {\n    padding: var(--data-panel-padding);\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n\n  ._8mOSg {\n    border-style: solid;\n    border-color: rgb(var(--color-light-gray-3));\n    border-width: 1px;\n    overflow: auto;\n  }\n\n  ._8mOSg:last-child {\n    border-width: 1px;\n  }\n\n  .YZtX7 {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n\n  .\\+OXwd {\n    display: flex;\n    height: var(--sheet-drill-in-grid-height);\n  }\n\n  .\\+OXwd .ag-theme-alpine .ag-body-horizontal-scroll-viewport {\n      display: none;\n    }\n\n  .BIsxH {\n    border-top: 1px solid rgb(var(--color-light-gray-1));\n    padding: var(--data-panel-padding);\n  }\n\n  ._5H0sw {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-top: var(--data-panel-margin);\n  }\n\n  .zLp1V {\n    margin-left: var(--data-panel-xs-margin);\n  }\n\n  .BVibU {\n    background-color: rgb(var(--color-light-gray-5)) !important;\n    font-weight: bold;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritorySheetDetail": "Yfu4k",
	"TerritorySheetDetail_content": "mKsPG",
	"TerritorySheetDetail_gridSection": "_8mOSg",
	"TerritorySheetDetail_fullWidthGrid": "YZtX7",
	"TerritorySheetDetail_territorySheetColumnsGrid": "+OXwd",
	"TerritorySheetDetail_noData": "BIsxH",
	"TerritorySheetDetail_showTotalsArea": "_5H0sw",
	"TerritorySheetDetail_showTotalsText": "zLp1V",
	"TerritorySheetDetail_bottomRowPinned": "BVibU"
};
export default ___CSS_LOADER_EXPORT___;
