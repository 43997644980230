import * as yup from 'yup';

import { HierarchyAttributeType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export default yup.object().shape({
  hierarchyName: yup.string().required(formatMessage('REQUIRED_FIELD')),
  hierarchyKey: yup.string().required(formatMessage('REQUIRED_FIELD')),
  customProperties: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      val: yup.lazy((_value, context) => {
        if (context.parent.type === HierarchyAttributeType.NUMERIC) {
          return yup.number().typeError(formatMessage('MEASURE_VALUE_TYPE_ERROR')).nullable();
        } else if (context.parent.type === HierarchyAttributeType.DATE) {
          return yup.date().typeError(formatMessage('MEASURE_VALUE_TYPE_ERROR_DATE_REQUIRED')).nullable();
        } else {
          return yup.string().nullable();
        }
      }),
      type: yup.string()
    })
  )
});
