import React, { FC, ReactElement, ReactNode, Children } from 'react';

import { TabId } from '@blueprintjs/core';
// eslint-disable-next-line no-restricted-imports
import { Tabs as VaricentTabs, Tab as VaricentTab } from '@varicent/components';

import Tab from 'components/Tab/Tab';

import block from 'utils/bem-css-modules';

import style from './Tabs.module.pcss';

const b = block(style);

const Tabs: FC<{
  id: string;
  large?: boolean;
  onChange?(newTabId: TabId, prevTabId: TabId | undefined, event: React.MouseEvent<HTMLElement>): void;
  animate?: boolean;
  renderActiveTabPanelOnly?: boolean;
  selectedTabId?: TabId;
  className?: string;
  children: ReactNode;
}> = ({ children, ...props }) => (
  <VaricentTabs {...props} className={`${props.className} ${b()}`}>
    {Children.map(children, (child) => {
      if (React.isValidElement(child) && child.type === React.Fragment) {
        console.warn('Warning: <React.Fragment> should not be a direct child of <Tabs>.');
      }
      return isTab(child) ? <VaricentTab {...child.props} data-testid={formatTestId(child.props.id)} /> : child;
    })}
  </VaricentTabs>
);

const isTab = (element: ReactNode): element is ReactElement => {
  if (React.isValidElement(element)) {
    return element?.type?.['displayName'] === Tab.displayName;
  }
  return false;
};

const formatTestId = (id: string | number) => `${id.toString().toLowerCase().replaceAll(' ', '-')}-tab`;

export default Tabs;
