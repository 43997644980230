import React, { useEffect } from 'react';

import { Drawer } from '@varicent/components';

import DirtyPrompt from 'app/components/DirtyFormPrompt/DirtyPrompt';
import TerritoryOptimizationHeader from 'app/components/TerritoryOptimization/TerritoryOptimizationHeader/TerritoryOptimizationHeader';
import TerritoryOptimizationPage from 'app/components/TerritoryOptimization/TerritoryOptimizationPage/TerritoryOptimizationPage';
import TerritoryOptimizationPreview from 'app/components/TerritoryOptimization/TerritoryOptimizationPreview/TerritoryOptimizationPreview';
import TerritoryOptimizationPublishPage from 'app/components/TerritoryOptimization/TerritoryOptimizationPublishPage/TerritoryOptimizationPublishPage';
import TerritoryOptimizationStepper from 'app/components/TerritoryOptimization/TerritoryOptimizationStepper/TerritoryOptimizationStepper';

import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { TerritoryOptimizationStep } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './TerritoryOptimizationDrawer.module.pcss';

const b = block(style);

interface OptimizationStepSettings {
  showBorder?: boolean;
  showStepper?: boolean;
  promptOnNavigate?: boolean;
}

const stepSettingsRecord: Record<TerritoryOptimizationStep, OptimizationStepSettings> = {
  [TerritoryOptimizationStep.START]: { promptOnNavigate: false },
  [TerritoryOptimizationStep.PARAMETERS]: { showBorder: true, promptOnNavigate: true, showStepper: true },
  [TerritoryOptimizationStep.CONSTRAINTS]: { showBorder: true, promptOnNavigate: true, showStepper: true },
  [TerritoryOptimizationStep.OPTIMIZE_TERRITORIES]: { showBorder: true, showStepper: true },
  [TerritoryOptimizationStep.OPTIMIZE_RESULTS]: { showBorder: true, showStepper: true, promptOnNavigate: false },
  [TerritoryOptimizationStep.PREVIEW]: { showBorder: true, promptOnNavigate: false },
  [TerritoryOptimizationStep.PUBLISHED]: { promptOnNavigate: false }
};

interface TerritoryOptimizationDrawerProps {
  containerElement: HTMLElement | null;
}

const TerritoryOptimizationDrawer: React.FC<TerritoryOptimizationDrawerProps> = ({
  containerElement
}: TerritoryOptimizationDrawerProps) => {
  const { territoryOptimizationDrawerState, setTerritoryOptimizationDrawerState } = useTerritoryOptimization();
  const { setIsPreviewMapOpen } = useMapVariant();
  const stepSettings = stepSettingsRecord[territoryOptimizationDrawerState];
  const isDirty = stepSettings?.promptOnNavigate;

  useEffect(() => {
    if (territoryOptimizationDrawerState !== TerritoryOptimizationStep.PREVIEW) setIsPreviewMapOpen(false);
  }, [territoryOptimizationDrawerState]);

  return (
    <div className={b()} data-testid="territory-optimization-drawer">
      <Drawer
        container={containerElement}
        open={territoryOptimizationDrawerState !== null}
        trigger={null}
        showCloseButton={false}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setTerritoryOptimizationDrawerState(null);
          }
        }}
      >
        <DirtyPrompt dirty={isDirty} submitCount={0} data-testid="dirty-form-prompt" />
        <Drawer.Header>
          <TerritoryOptimizationHeader />
        </Drawer.Header>
        <Drawer.Body includePadding={false}>
          <div
            className={b('container', {
              preview: territoryOptimizationDrawerState === TerritoryOptimizationStep.PREVIEW,
              showBorder: stepSettings?.showBorder
            })}
          >
            {territoryOptimizationDrawerState === TerritoryOptimizationStep.START && (
              <TerritoryOptimizationPage data-testid="territory-optimization-page" />
            )}
            {stepSettings?.showStepper && <TerritoryOptimizationStepper data-testid="territory-optimization-stepper" />}
            {territoryOptimizationDrawerState === TerritoryOptimizationStep.PREVIEW && <TerritoryOptimizationPreview />}
            {territoryOptimizationDrawerState === TerritoryOptimizationStep.PUBLISHED && (
              <TerritoryOptimizationPublishPage data-testid="territory-optimization-publish-page" />
            )}
          </div>
        </Drawer.Body>
      </Drawer>
    </div>
  );
};

export default TerritoryOptimizationDrawer;
