import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

const useMemberId = (): number => {
  const { selectedTenant } = useScope();
  const { userMembershipSpecs } = useUser();

  return userMembershipSpecs.find((membershipSpec) => membershipSpec.tenantId === selectedTenant?.id)?.memberId;
};

export default useMemberId;
