import React from 'react';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './NotApplicableCellRenderer.module.pcss';

const b = block(style);

const NotApplicableCellRenderer: React.FC = () => {
  return (
    <div className={b()} data-testid="not-applicable-cell-renderer">
      {formatMessage('NOT_APPLICABLE_ABBREVIATION')}
    </div>
  );
};

export default NotApplicableCellRenderer;
