import React, { useState } from 'react';

import { TextArea } from '@blueprintjs/core';
import { FieldProps } from 'formik';

import block from 'utils/bem-css-modules';

import style from './FormTextArea.module.pcss';

const b = block(style);
interface FormTextAreaProps extends FieldProps {
  label: string;
  disabled: boolean;
  showErrors?: boolean;
  enableMaxLength?: boolean;
  maxLength?: number;
  shouldValidateOnTouch?: boolean;
  placeholder?: string;
  onBlur?: (value: string) => void;
  enableGrowVertically?: boolean;
  growVerticallyMaxLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormTextArea: React.FC<FormTextAreaProps> = ({
  field: { name, value },
  form: { touched, errors, status, setFieldValue, setFieldTouched },
  label,
  disabled,
  showErrors = true,
  enableMaxLength = false,
  maxLength = 1000,
  shouldValidateOnTouch,
  placeholder = '',
  onBlur,
  onChange,
  enableGrowVertically = false,
  growVerticallyMaxLength
}: FormTextAreaProps) => {
  const isError = !!(touched[name] && errors[name]);
  const [textareaRows, settextareaRows] = useState(1);
  let displayError;

  if (isError) {
    displayError = errors[name];
  } else if (status && Object.keys(status).length > 0) {
    displayError = status[name];
  }

  const handleOnChange = (event) => {
    setFieldValue(name, event.target.value);

    //calculate total number of rows according to user's input length
    const inputLengths = event.target.value.length;
    const noOfRows = Math.ceil(inputLengths / growVerticallyMaxLength);
    if (noOfRows) {
      settextareaRows(noOfRows);
    }
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div
      onBlur={() => {
        if (onBlur) {
          onBlur(value);
        }
        setFieldTouched(name, true, shouldValidateOnTouch);
      }}
    >
      <div className={b('topRow')}>
        <label className={b('label')} htmlFor={name}>
          {label}
        </label>
        {enableMaxLength && (
          <span data-testid={'max-length-count'}>
            {value?.length} / {maxLength}
          </span>
        )}
      </div>
      <TextArea
        placeholder={placeholder}
        id={name}
        value={value}
        disabled={disabled}
        data-testid={`form-text-area-${name}`}
        onChange={handleOnChange}
        rows={enableGrowVertically && textareaRows ? textareaRows : null}
      />
      {showErrors ? (
        <div className={b('validationMessage')} data-testid={`form-text-area-${name}-error-message`}>
          {displayError ?? ''}
        </div>
      ) : null}
    </div>
  );
};

export default FormTextArea;
