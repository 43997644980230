// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".r7F9l {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AuthSpinner": "r7F9l"
};
export default ___CSS_LOADER_EXPORT___;
