import gql from 'graphql-tag';

export const SAVE_SYMON_CREDENTIALS = gql`
  mutation SaveSymonCredentials($input: SaveSymonCredentialsInput) {
    saveSymonCredentials(input: $input) {
      tenantId
      symonAccountName
      symonAccountEmailAddress
      symonAccountCreatedAt
      symonDomain
      symonUiClientKey
    }
  }
`;
