//! This helper is unintuitive but offers critical performance gains, please read before modifying

// We construct a number of large lookup tables and data structures using the mapbox polygonId as a key
//
// Chrome's JS engine (V8) has a number of optimizations for small integers (SMIs)
// The value range of the mapbox polygon IDs are sometimes SMIs and other times are not
// However, some are very close to but just above the SMI boundary (2^31)-1
//
// This combination seemingly causes Maps and Sets to de-optimize significantly reducing performance
// Prefixing with an obviously non-numeric character forces it to be treated as true-string and not a SMI
//
// In some cases the performance gains are 200x faster with the prefix

export const optimizeNativePolygonIdForHashing = (baseId: string | number): string => `m${baseId}`;
