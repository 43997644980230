// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X8nx0 {\n    --tab-list-height: 52px;\n\n    width: 100%;\n    height: 100%;\n  }\n\n    .X8nx0 .bp3-tab-indicator-wrapper ~ .bp3-tab {\n      padding: 9px 16px;\n    }\n  .oRFV3 {\n    background-color: var(--white);\n    padding: var(--s) var(--m) 0;\n    height: var(--tab-list-height);\n  }\n  .s74sp {\n    height: calc(var(--center-content-height) - var(--tab-list-height));\n  }\n  .AqXEB {\n    padding: var(--dialog-row-padding);\n  }\n  .k97zZ {\n    height: 100%;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n            user-select: none\n  }\n  .k97zZ.bp3-tab {\n      line-height: 26px !important;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IntegrationPanel_container": "X8nx0",
	"IntegrationPanel_tabList": "oRFV3",
	"IntegrationPanel_tabPanel": "s74sp",
	"IntegrationPanel_legacyPanel": "AqXEB",
	"IntegrationPanel_tab": "k97zZ"
};
export default ___CSS_LOADER_EXPORT___;
