import React from 'react';

import DocsLink from 'app/components/DocsLink/DocsLink';

import { ExternalRoutePaths } from 'app/containers/App/Router/routePaths';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AccountLocationUploadInstructions.module.pcss';

const b = block(style);

const AccountLocationUploadInstructions: React.FC = () => {
  return (
    <div className={b()}>
      {formatMessage('CUSTOMER_ACCOUNT_UPLOAD_BODY')}
      <DocsLink
        relativeUrl={ExternalRoutePaths.CUSTOMER_ACCOUNT_HIERARCHY_UPLOAD_EXTENSION}
        label={formatMessage('DOCUMENTATION')}
        variant="text"
      />
      {formatMessage('FOR_MORE_INFORMATION')}
    </div>
  );
};

export default AccountLocationUploadInstructions;
