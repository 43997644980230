import React from 'react';

import { Drawer } from '@blueprintjs/core';

import SheetsAddFieldForm from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldForm/SheetsAddFieldForm';

import { useData } from 'app/contexts/dataProvider';

import { DEFAULT_DRAWER_WIDTH } from 'app/global/variables';

import { DataSheetDrawerFormType, DataSheetDrawerState } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './SheetsAddFieldDrawer.module.pcss';

const b = block(style);

const SheetsAddFieldDrawer: React.FC = () => {
  const { dataSheetDrawerState, setDataSheetDrawerState, setSheetDefinition, setDataSheetDrawerFormType } = useData();

  return (
    <div className={b()} data-testid="quota-sheet-column-measure-drawer">
      <Drawer
        canEscapeKeyClose={false}
        onClose={() => {
          setDataSheetDrawerState(DataSheetDrawerState.CLOSE);
          setDataSheetDrawerFormType(DataSheetDrawerFormType.NEW);
          setSheetDefinition(null);
        }}
        className={b('transition')}
        isOpen={dataSheetDrawerState === DataSheetDrawerState.OPEN}
        canOutsideClickClose
        portalClassName="CommandCenterDrawerPortal"
        size={`${DEFAULT_DRAWER_WIDTH}px`}
      >
        <SheetsAddFieldForm data-testid="custom-measure-form" />
      </Drawer>
    </div>
  );
};

export default SheetsAddFieldDrawer;
