import React, { Fragment } from 'react';

import { Classes } from '@blueprintjs/core';

import {
  BattleCardTargetPill,
  TerritoryGroupWithMeasuresPill
} from 'app/components/TerritoriesAndPlanTargets/TerritoryPill/customTerritoryPillsByType';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';

import { TerritoryGroupWithMeasuresNodeData, BattleCardTarget, PillLevel, CategoryName } from 'app/models';

import block from 'utils/bem-css-modules';
import { planTargetsValueFormatter } from 'utils/helpers/planTargetsHelpers';
import { getTreeNodeChildrenIds } from 'utils/helpers/treeAndTreeNodeUtils';

import style from './ExpandedPlanTargetsTree.module.pcss';

const b = block(style);

export const isBattleCardTarget = (
  data: BattleCardTarget | TerritoryGroupWithMeasuresNodeData
): data is BattleCardTarget => {
  return (data as BattleCardTarget).newBusinessTarget !== undefined;
};

interface TreeNodeProps {
  nodeData: BattleCardTarget | TerritoryGroupWithMeasuresNodeData;
  valueFormatter: (value: number, isCurrencyConversionValid: boolean, currency: string) => string;
  pillIndex: number;
  categoryName: CategoryName;
  battleCardId: string;
  animateTerritoryGroup: boolean;
  ancestors: string[];
  childrenIds;
}

const TreeNode: React.FC<TreeNodeProps> = ({
  nodeData,
  valueFormatter,
  pillIndex,
  categoryName,
  battleCardId,
  animateTerritoryGroup,
  ancestors,
  childrenIds
}: TreeNodeProps) => {
  if (isBattleCardTarget(nodeData)) {
    return (
      <BattleCardTargetPill
        nodeData={nodeData}
        valueFormatter={valueFormatter}
        pillIndex={pillIndex}
        animateTerritoryGroup={animateTerritoryGroup}
        battleCardId={battleCardId}
        data-testid="root-bc-node"
      />
    );
  } else {
    const pillLevel = nodeData.territoryGroupParentId ? PillLevel.TERRITORY : PillLevel.CATEGORY;

    return pillLevel === PillLevel.TERRITORY ? (
      <TerritoryGroupWithMeasuresPill
        nodeData={nodeData}
        valueFormatter={valueFormatter}
        pillIndex={pillIndex}
        categoryName={categoryName}
        animateTerritoryGroup={animateTerritoryGroup}
        ancestors={ancestors}
        childrenIds={childrenIds}
        battleCardId={battleCardId}
        data-testid={`child-node-${nodeData.territoryGroupId}`}
      />
    ) : (
      <div className={b('categoryNode')}>
        <span className={b('arrowNode')} />
        <TerritoryGroupWithMeasuresPill
          nodeData={nodeData}
          valueFormatter={valueFormatter}
          pillIndex={pillIndex}
          categoryName={categoryName}
          animateTerritoryGroup={animateTerritoryGroup}
          ancestors={ancestors}
          childrenIds={childrenIds}
          battleCardId={battleCardId}
          data-testid={`category-node-${nodeData.territoryGroupId}`}
        />
      </div>
    );
  }
};

interface TreeProps {
  treeData: TerritoryGroupWithMeasuresNodeData;
  valueFormatter: (value: number, isCurrencyConversionValid: boolean, currency: string) => string;
  categoryIndex: number;
  categoryName: CategoryName;
  battleCardId: string;
  animateTerritoryGroup: boolean;
  ancestors: string[];
  childrenIds;
}

const Tree: React.FC<TreeProps> = ({
  treeData,
  valueFormatter,
  categoryIndex,
  categoryName,
  battleCardId,
  animateTerritoryGroup,
  ancestors,
  childrenIds
}: TreeProps) => {
  const { tgExpandedLookupMap } = usePlanTargets();

  const isTreeNodeExpanded = tgExpandedLookupMap[treeData?.territoryGroupId];

  return (
    <Fragment key={treeData.territoryGroupId}>
      <TreeNode
        nodeData={treeData}
        valueFormatter={valueFormatter}
        pillIndex={categoryIndex}
        categoryName={categoryName}
        battleCardId={battleCardId}
        animateTerritoryGroup={animateTerritoryGroup}
        ancestors={ancestors}
        childrenIds={childrenIds}
      />
      {!!treeData?.children?.length && (treeData?.territoryGroupParentId ? isTreeNodeExpanded : true) && (
        <ul className={b('treeNode')}>
          {treeData.children.map((child, childIndex) => {
            return (
              <li className={b('listNode')} key={childIndex}>
                <Tree
                  treeData={child}
                  valueFormatter={valueFormatter}
                  categoryIndex={childIndex}
                  categoryName={categoryName}
                  battleCardId={battleCardId}
                  animateTerritoryGroup={animateTerritoryGroup}
                  ancestors={[...ancestors, treeData.territoryGroupId]}
                  childrenIds={getTreeNodeChildrenIds(child)}
                />
              </li>
            );
          })}
        </ul>
      )}
    </Fragment>
  );
};

interface ExpandedPlanTargetsTreeProps {
  battleCardAllocationData: BattleCardTarget;
  territoryGroupAllocationData: TerritoryGroupWithMeasuresNodeData[];
  animateTerritoryGroup: boolean;
  battleCardId: string;
}

const ExpandedPlanTargetsTree: React.FC<ExpandedPlanTargetsTreeProps> = ({
  battleCardAllocationData,
  territoryGroupAllocationData,
  animateTerritoryGroup,
  battleCardId
}: ExpandedPlanTargetsTreeProps) => {
  const { selectedPillIdPlanTargets, setSelectedPillIdPlanTargets } = usePlanTargets();
  const { battleCardLookupMap } = useBattleCard();
  const owner = battleCardLookupMap?.[battleCardId]?.owner;
  const className = owner?.firstName && owner?.lastName ? b('rootNodewithOwnerInfo') : b('rootNode');

  const allData = !!(battleCardAllocationData && territoryGroupAllocationData);

  return (
    <div
      onClick={(event) => {
        // On canvas click, deselect current territory pill.
        // Because we have an onClick handler on the entire plan targets canvas,
        // we need to call event.stopPropagation() in TerritoryPill
        // so that the territory pill's onClick handler can be triggered, and a pill can be selected,
        // without bubbling up and triggering this handler (which would deselect it again)
        if (selectedPillIdPlanTargets) {
          setSelectedPillIdPlanTargets(null);
        }
        event.stopPropagation();
      }}
      className={b()}
      data-testid="expanded-plan-targets-tree"
    >
      {!allData && <div className={`${Classes.SKELETON} ${b('loadingPill')}`} data-testid="loading-pill" />}
      {battleCardAllocationData && (
        <div className={className}>
          <TreeNode
            nodeData={{ ...battleCardAllocationData, territoryGroupId: 'battlecard' }}
            valueFormatter={planTargetsValueFormatter}
            pillIndex={0}
            categoryName={null}
            battleCardId={battleCardId}
            animateTerritoryGroup={animateTerritoryGroup}
            ancestors={[]} // ancestors is [] since it's root
            // since root node always need to do animation, right now just leave childIds empty
            // if childrenIds become useful for other task rather than simply doing animation
            // we can modify the utils to git the result
            childrenIds={[]}
            data-testid="root-bc-node"
          />
        </div>
      )}
      <div className={b('tree')}>
        {allData && territoryGroupAllocationData.length
          ? territoryGroupAllocationData.map((treeItem, treeItemIndex) => (
              <Tree
                key={treeItemIndex}
                treeData={treeItem}
                valueFormatter={planTargetsValueFormatter}
                categoryIndex={treeItemIndex}
                categoryName={treeItem.name as CategoryName}
                battleCardId={battleCardId}
                animateTerritoryGroup={animateTerritoryGroup}
                ancestors={['battlecard']}
                childrenIds={getTreeNodeChildrenIds(treeItem)}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default ExpandedPlanTargetsTree;
