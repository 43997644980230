// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6OpYs {\n    font-size: 16px;\n  }\n  .ZHpad .bp3-menu-item {\n      align-items: center;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryOptimizationPreviewMenu_previewMenuButton": "_6OpYs",
	"TerritoryOptimizationPreviewMenu_menuItemWrapper": "ZHpad"
};
export default ___CSS_LOADER_EXPORT___;
