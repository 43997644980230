import React from 'react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AddUserRoleStatusBar.module.pcss';

const b = block(style);

export const AddUserRoleStatusBar: React.FC = () => {
  return (
    <div className={b('')}>
      <TextButton
        type="button"
        text={formatMessage('ADD_USER_ROLE')}
        minimal
        disabled
        testId={'add-user-role-status-bar'}
      />
    </div>
  );
};

export default AddUserRoleStatusBar;
