// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4HRGd {\n    height: 100%;\n    border-radius: 12px;\n    background-color: var(--white);\n    padding: var(--m);\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n  .ietMh {\n    width: 100%;\n    display: flex;\n    height: 80%;\n  }\n  /* stylelint-disable plugin/selector-bem-pattern */\n  .Jq5iA {\n    margin: var(--s);\n    flex: 1;\n  }\n  .Jq5iA > svg {\n      display: block;\n      height: 57px;\n      width: 48px;\n      color: rgb(var(--color-gray-2));\n    }\n  .j7-JN {\n    margin: var(--s);\n  }\n  .atCM7 {\n    min-height: 40px;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n  }\n  ._3PwoF .vds-accordion-item {\n      background-color: var(--white) !important;\n    }\n  ._3PwoF .accordion-title-text {\n      font-weight: 700;\n    }\n  .WJmeE {\n    margin: var(--m) var(--s);\n  }\n  .WJmeE .bp3-card {\n      padding: 0 var(--s);\n    }\n  .xQr\\+\\+ {\n      display: none;\n    }\n  .qm6r5 {\n    display: flex;\n    flex-direction: row;\n    margin-bottom: var(--s);\n    width: calc(100% + 30px);\n  }\n  .PpJ7d {\n    margin-left: 0 !important; /* In order to align with the other divider */\n    margin-right: 5px !important;\n  }\n  .oAwT1 {\n    flex: 1;\n    margin-right: 20px\n  }\n  .oAwT1:not(:first-child) {\n      margin-left: var(--m);\n    }\n  .yG1PK {\n    display: flex;\n    justify-content: space-between;\n    font-size: 12px;\n    line-height: 16px;\n  }\n  .ci8bn {\n    justify-self: flex-end;\n  }\n  .\\+-NEn {\n    color: var(--text-placeholder);\n    margin-left: 5px;\n  }\n  .\\+-y3H {\n    display: flex;\n  }\n  .yzX2D {\n    display: flex;\n    justify-content: space-between;\n  }\n  .XjiJT {\n    margin-bottom: var(--m);\n  }\n  .XjiJT .bp3-button.bp3-minimal {\n      padding-left: 0;\n    }\n  .imn1K {\n    margin-left: var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OptimizeResults_resultsContainer": "_4HRGd",
	"OptimizeResults_content": "ietMh",
	"OptimizeResults_card": "Jq5iA",
	"OptimizeResults_icon": "j7-JN",
	"OptimizeResults_description": "atCM7",
	"OptimizeResults_accordion": "_3PwoF",
	"OptimizeResults_scoreBreakdownCard": "WJmeE",
	"OptimizeResults_scoreBreakdownCard__hidden": "xQr++",
	"OptimizeResults_scores": "qm6r5",
	"OptimizeResults_scoreBreakdownDivider": "PpJ7d",
	"OptimizeResults_scoreItems": "oAwT1",
	"OptimizeResults_scoreItem": "yG1PK",
	"OptimizeResults_minNumAccounts": "ci8bn",
	"OptimizeResults_scoreDiff": "+-NEn",
	"OptimizeResults_score": "+-y3H",
	"OptimizeResults_footer": "yzX2D",
	"OptimizeResults_exportBtn": "XjiJT",
	"OptimizeResults_publishBtn": "imn1K"
};
export default ___CSS_LOADER_EXPORT___;
