// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CTqK8 {\n    background: white;\n    border-top: 1px solid rgb(var(--color-light-gray-2));\n    display: flex;\n    flex-direction: column;\n    flex: 1 1 auto;\n    min-height: 25px\n  }\n  .CTqK8:last-child {\n      border-bottom: 1px solid rgb(var(--color-light-gray-2));\n    }\n  .CTqK8 .bp3-collapse {\n      flex: 1 1 auto;\n      min-height: 0;\n      display: flex;\n    }\n  .QhdB0 .bp3-collapse-body {\n      width: 100%;\n    }\n  .ho8f3 {\n    height: 24px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: 14px;\n    color: rgb(var(--color-gray-1));\n    padding: 16px 10px;\n    background-color: rgb(var(--color-light-gray-5));\n  }\n  .LJyzf {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n  .fwnpj {\n    cursor: pointer;\n    margin: 0 6px 0 -2px;\n    transition: transform 0.1s ease-in;\n    padding: 2px 0 0;\n  }\n  .MROmm {\n      transform: rotate(0deg);\n    }\n  .Id\\+0x {\n      transform: rotate(-90deg);\n    }\n  .LH5oz {\n    cursor: pointer;\n    max-width: var(--command-center-header-title-width);\n    font-weight: bold;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    vertical-align: middle;\n    margin-bottom: 0;\n  }\n  .jpAdJ {\n    font-weight: normal;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Accordion_collapse": "CTqK8",
	"Accordion_collapseContainer": "QhdB0",
	"Accordion_panelHeader": "ho8f3",
	"Accordion_panelTitleArea": "LJyzf",
	"Accordion_panelCollapseIcon": "fwnpj",
	"Accordion_panelCollapseIcon__open": "MROmm",
	"Accordion_panelCollapseIcon__closed": "Id+0x",
	"Accordion_panelTitle": "LH5oz",
	"Accordion_panelSubTitle": "jpAdJ"
};
export default ___CSS_LOADER_EXPORT___;
