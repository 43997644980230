export class APIError extends Error {
  public statusCode: number;
  public statusText: string;
  public endpoint: string;

  constructor(message: string, statusCode: number, statusText: string, endpoint: string) {
    super(message);
    this.statusCode = statusCode;
    this.statusText = statusText;
    this.endpoint = endpoint;
    this.name = 'APIError';
  }
}
