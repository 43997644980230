import React, { FC, useMemo } from 'react';

import { ChevronDown } from '@carbon/icons-react';
import { Field, Formik } from 'formik';

import SelectMenu from 'components/SelectMenu/SelectMenu';

import { ExternalLink } from 'app/components/ExternalLink/ExternalLink';
import { HeaderSelect } from 'app/components/HeaderSelect/HeaderSelect';
import PageLoadingBar from 'app/components/PageLoadingBar/PageLoadingBar';

import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { useScope } from 'app/contexts/scopeProvider';

import useMakePlanningPath from 'app/hooks/useMakePlanningPath';

import { MapContextBattleCard } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './EmbeddedMapHeader.module.pcss';

const b = block(style);

export interface EmbeddedMapHeaderProps {
  availableBattleCards: MapContextBattleCard[];
  selectedBattleCardId: number | null;
  selectedQuotaComponentId: number | null;
  onSelectedBattleCardChange: (selectedBattleCardId: number) => void;
  onSelectedQuotaComponentChange: (selectedQuotaComponentId: number) => void;
  localCurrencyCode: string;
}

export const EmbeddedMapHeader: FC<EmbeddedMapHeaderProps> = ({
  availableBattleCards,
  selectedBattleCardId,
  selectedQuotaComponentId,
  onSelectedBattleCardChange,
  onSelectedQuotaComponentChange,
  localCurrencyCode
}) => {
  const makePlanningPath = useMakePlanningPath();
  const { selectedPlanningCycle, selectedTenant } = useScope();
  const { isMapLoading } = useMapVariant();
  const battleCardItems = useMemo(
    () => availableBattleCards.map((bc) => ({ key: bc.battlecardName, value: bc.battlecardId })),
    [availableBattleCards]
  );
  const quotaComponentItems = useMemo(
    () =>
      availableBattleCards
        .find((bc) => bc.battlecardId === selectedBattleCardId)
        ?.quotaComponents.map((qc) => ({ key: qc.quotaComponentName, value: qc.quotaComponentId })) ?? [],
    [availableBattleCards, selectedBattleCardId]
  );

  const initialFormValues = useMemo(
    () => ({
      selectedBattlecard: battleCardItems.find((bc) => bc.value === selectedBattleCardId) ?? null,
      selectedQuotaComponent: quotaComponentItems.find((qc) => qc.value === selectedQuotaComponentId) ?? null
    }),
    [battleCardItems, quotaComponentItems, selectedBattleCardId, selectedQuotaComponentId]
  );

  const urlToNativeSite = useMemo(() => {
    if (!selectedTenant?.slug || !selectedPlanningCycle?.slug) {
      return window.location.origin;
    }
    const planningPath = makePlanningPath({
      tenantSlug: selectedTenant.slug,
      planningCycleSlug: selectedPlanningCycle.slug
    });
    const fullUrl = new URL(window.location.origin);
    fullUrl.pathname = planningPath.pathname;
    fullUrl.search = planningPath.search;
    return fullUrl.toString();
  }, [selectedTenant, selectedPlanningCycle]);

  const handleSubmit = () => {
    // No behaviour
  };

  return (
    <>
      <header className={b()} data-testid="embedded-map-header">
        <Formik initialValues={initialFormValues} enableReinitialize onSubmit={handleSubmit}>
          <div className={b('headerControls')}>
            <div>
              <h1 className={b('planningCycleTitle')}>{selectedPlanningCycle?.planningCycleName}</h1>
              <Field
                name="selectedBattlecard"
                items={battleCardItems}
                component={HeaderSelect}
                onChange={(item) => onSelectedBattleCardChange(item.value)}
              />
            </div>
            <div className={b('rightControls')}>
              <div>
                <Field
                  name="selectedQuotaComponent"
                  items={quotaComponentItems}
                  theme="tertiary"
                  rightIcon={<ChevronDown />}
                  showErrors={false}
                  component={SelectMenu}
                  disabled={quotaComponentItems.length === 0}
                  onChange={(item) => onSelectedQuotaComponentChange(item.value)}
                />
              </div>

              <div className={b('currencyLabel')}>{localCurrencyCode}</div>
              <div className={b('salesPlanningLink')} data-testid="link-to-native-site">
                <ExternalLink linkText={formatMessage('SALES_PLANNING')} url={urlToNativeSite} />
              </div>
            </div>
          </div>
        </Formik>
      </header>
      <PageLoadingBar isLoading={isMapLoading} />
    </>
  );
};
