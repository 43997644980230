import { HierarchyType } from 'app/models';

export const filterTerritoryGroupsByHierarchyType = <
  T extends {
    territoryGroupParentId: number | null;
    hierarchyTopId: number;
    hierarchyType: HierarchyType | string;
  }
>(
  territoryGroups: T[],
  hierarchyType: HierarchyType
): T[] => {
  if (!territoryGroups?.length) return territoryGroups;
  const tgRoots = territoryGroups.filter((group) => !group.territoryGroupParentId);
  const hierarchyTopToType = new Map(tgRoots.map((tgRoot) => [tgRoot.hierarchyTopId, tgRoot.hierarchyType]));
  return territoryGroups.filter((tg) => hierarchyTopToType.get(tg.hierarchyTopId) === hierarchyType);
};
