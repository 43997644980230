import React from 'react';

import { CloseOutline } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { formatMessage } from 'utils/messages/utils';

export interface ClearSelectionButtonV2Props {
  disabled?: boolean;
  onDeselect: () => void;
}

const ClearSelectionButtonV2: React.FC<ClearSelectionButtonV2Props> = ({ onDeselect, disabled = false }) => {
  return (
    <IconButton
      onClick={onDeselect}
      tooltipPlacement="bottom-end"
      tooltipText={formatMessage('CLEAR_SELECTION')}
      type="button"
      icon={<CloseOutline />}
      testId="clear-selection"
      disabled={disabled}
      minimal
    />
  );
};

export default ClearSelectionButtonV2;
