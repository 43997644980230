import React, { useEffect, useState } from 'react';

import { GridApi, GridOptions } from '@ag-grid-community/core';
import { Checkbox } from '@blueprintjs/core';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import buildTerritorySheetDetailColumnDefs from 'app/components/AdvancedGrid/Sheets/Territory/TerritorySheetDetail/TerritorySheetColumnDef';
import {
  buildSheetsPreviewBottomRowData,
  buildSheetsPreviewColumnData
} from 'app/components/DataPanel/SheetsPanel/SheetDetail/buildSheetsPreviewColumnsData';
import SheetDetailHeader from 'app/components/DataPanel/SheetsPanel/SheetDetailHeader/SheetDetailHeader';
import SheetGridHeader from 'app/components/DataPanel/SheetsPanel/SheetGridHeader/SheetGridHeader';
import SheetsAddFieldDrawer from 'app/components/SheetsAddFieldDrawer/SheetsAddFieldDrawer';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import usePhase from 'app/hooks/usePhase';

import { DataPanelViews, DeploymentModelPhase } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritorySheetDetail.module.pcss';

const b = block(style);

const TerritorySheetDetail: React.FC = () => {
  const territorySheetGridContainerRef = React.useRef(null);

  const [territorySheetShowTotals, setTerritorySheetShowTotals] = useState<boolean>(true);
  const deploymentModelPhase = usePhase();

  const { selectedDeploymentModelId } = useScope();
  const [territorySheetGridApi, setTerritorySheetGridApi] = useState<GridApi>(null);
  const {
    dataSheetDrawerState,
    setSelectedSheet,
    selectedSheet,
    getSheetDefinitions,
    sheetDefinitions,
    setSelectedDataView,
    sheetDefinitionsLoading
  } = useData();
  const territorySheetGridOptions: GridOptions = {
    getRowClass: (params) => {
      let className = '';
      if (params.node.rowPinned === 'bottom') {
        className = b('bottomRowPinned');
      }
      return className;
    }
  };

  const onTerritorySheetGridReady = (params) => {
    setTerritorySheetGridApi(params?.api);
  };

  useEffect(() => {
    if (!territorySheetGridOptions) {
      return;
    }
    if (territorySheetShowTotals && territorySheetGridApi) {
      territorySheetGridApi?.setPinnedBottomRowData([buildSheetsPreviewBottomRowData(sheetDefinitions)]);
    } else {
      territorySheetGridApi?.setPinnedBottomRowData([]);
    }
  }, [territorySheetShowTotals, territorySheetGridApi]);

  useEffect(() => {
    const isTQM = deploymentModelPhase === DeploymentModelPhase.manage ? true : false;
    if (!!selectedSheet) {
      getSheetDefinitions(selectedDeploymentModelId, selectedSheet?.sheetId, isTQM);
    }
  }, [selectedSheet]);

  const onBackClick = () => {
    setSelectedDataView(DataPanelViews.DATA_OVERVIEW);
    setSelectedSheet(null);
  };

  return (
    <div className={b()} data-testid="territory-sheet-detail">
      <SheetDetailHeader onBackClick={onBackClick} />
      <div className={b('content')}>
        <div className={b('gridSection')}>
          <SheetGridHeader
            title={formatMessage('TERRITORY_SHEET_COLUMNS')}
            subtitle={formatMessage('COLUMNS_DESCRIPTION')}
          />
          <div className={b('territorySheetColumnsGrid')}>
            <div className={`ag-theme-alpine ${b('fullWidthGrid')}`} ref={territorySheetGridContainerRef}>
              {sheetDefinitionsLoading || sheetDefinitions?.length > 0 ? (
                <AdvancedGrid
                  gridOptions={territorySheetGridOptions}
                  columnDefs={buildTerritorySheetDetailColumnDefs(sheetDefinitions)}
                  rowData={buildSheetsPreviewColumnData(sheetDefinitions)}
                  onGridReady={onTerritorySheetGridReady}
                  data-testid="territory-sheet-columns-grid"
                  gridWidth={territorySheetGridContainerRef?.current?.offsetWidth}
                  gridHeight={territorySheetGridContainerRef?.current?.offsetHeight}
                  showGridLoading={sheetDefinitionsLoading}
                />
              ) : (
                <div className={b('noData')} data-testid="territory-sheet-columns-grid-empty">
                  {formatMessage('EMPTY_GRID')}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={b('showTotalsArea')}>
          <Checkbox
            data-testid="show-totals-checkbox"
            checked={territorySheetShowTotals}
            onClick={() => setTerritorySheetShowTotals(!territorySheetShowTotals)}
          />
          <span className={b('showTotalsText')} data-testid="show-totals-text">
            {formatMessage('SHOW_TOTALS')}
          </span>
        </div>
      </div>
      {dataSheetDrawerState && <SheetsAddFieldDrawer />}
    </div>
  );
};

export default TerritorySheetDetail;
