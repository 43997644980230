import React, { FC } from 'react';

import { Spinner } from '@blueprintjs/core';

import { SPINNER_DEFAULT_SIZE } from 'components/global/variables';

import block from 'utils/bem-css-modules';

import style from './AuthSpinner.module.pcss';

const b = block(style);

export const AuthSpinner: FC = () => (
  <div className={b()} data-testid="auth-spinner">
    <Spinner intent="primary" size={SPINNER_DEFAULT_SIZE} />
  </div>
);
export default AuthSpinner;
