// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b4uBc {\n    padding-bottom: var(--s);\n  }\n  .Jr6pt {\n    margin-top: var(--s);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToastMessage_title": "b4uBc",
	"ToastMessage_actionButton": "Jr6pt"
};
export default ___CSS_LOADER_EXPORT___;
