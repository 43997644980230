import gql from 'graphql-tag';

export const ADD_CUSTOM_MEASURE = gql`
  mutation AddCustomMeasure(
    $tenantId: Int!
    $planningCycleId: Int!
    $measureName: String!
    $measureFieldType: String!
    $measureFormatType: String!
  ) {
    addCustomMeasure(
      input: {
        tenantId: $tenantId
        planningCycleId: $planningCycleId
        measureName: $measureName
        measureFieldType: $measureFieldType
        measureFormatType: $measureFormatType
      }
    ) {
      measureId
    }
  }
`;
