import React from 'react';

import { FieldMetaProps } from 'formik';

import { KeyValue } from 'components/models';
import SelectMenu from 'components/SelectMenu/SelectMenu';

import block from 'utils/bem-css-modules';
import { form, noop } from 'utils/helpers/mockFormikInput';

import style from './CustomSelect.module.pcss';

const b = block(style);

interface CustomSelectProps {
  value: KeyValue<string>;
  onChange: (value: KeyValue<string>) => void;
  items: KeyValue<string>[];
  disabledItems?: KeyValue<string>[];
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  placeHolderText?: string;
  disabled?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  value,
  onChange,
  items,
  disabledItems,
  icon,
  rightIcon,
  placeHolderText,
  disabled
}: CustomSelectProps) => {
  return (
    <div className={b()}>
      <SelectMenu
        field={{
          name: '',
          value,
          onChange: noop,
          onBlur: noop
        }}
        form={form}
        meta={{} as FieldMetaProps<unknown>}
        items={items}
        allowPopperEventListeners={false}
        disabledItems={disabledItems as unknown as Record<string, string>[]}
        onChange={(item) => {
          onChange(item as KeyValue<string>);
        }}
        showErrors={false}
        icon={icon}
        rightIcon={rightIcon}
        placeHolderText={placeHolderText}
        disabled={disabled}
        minimal={disabled}
      />
    </div>
  );
};

export default CustomSelect;
