import { HierarchyTypeName } from 'app/models';

export const MENU_INFINITE_SCROLL_ITEM_HEIGHT = 35;
export const MEASURES_GRID_COLUMN_WIDTH = 220;
export const MEASURES_GRID_COLUMN_WIDTH_SMALL = 150;
export const MEASURES_GRID_COLUMN_WIDTH_LARGE = 300;
export const MEASURES_GRID_COLUMN_WIDTH_EXTRA_LARGE = 370;
export const MEASURES_GRID_COLUMN_WIDTH_ICON = 32;
export const MEASURES_GRID_COLUMN_WIDTH_ICON_SMALL = 24;
export const ALLOCATION_COLUMN_OFFSET = 33;
export const COMMAND_CENTER_DRAWER_HALF_WIDTH = 720;
export const COMMAND_CENTER_DRAWER_OPEN_WIDTH = 360;
export const CUSTOMER_ACCOUNT_ID = 'Customer Account ID';
export const DEFAULT_DRAWER_WIDTH = 360;
export const SHOW_LOADING_AFTER = 200;
export const COLOR_COBALT_3 = '#2965CC';
export const EXPANDED_BATTLECARD_DRAWER_MARGIN = 20;
export const NAVBAR_HEIGHT = 60;
export const BLOCK_SIZE = 10;
export const ACCOUNT_QUOTA_BLOCK_SIZE = 50;
export const ACCOUNT_RULE_BLOCK_SIZE = 200;
export const IMPORT_ERRORS_BLOCK_SIZE = 50;
export const PROGRESS_MIN_LENGTH = 0.03;
export const HIERARCHY_POLYGON_PAGE_SIZE = 50_000;
export const TRANSITION_NONE = 'none';
export const DEFAULT_BLOCK_SIZE = 100;
export const DEFAULT_REBALANCING_HIERARCHY = {
  key: HierarchyTypeName.CUSTOMER_ACCOUNTS as string,
  value: CUSTOMER_ACCOUNT_ID
};
export const SELECT_POINT_CURSOR = 'pointer';
export const LASSO_CURSOR = 'url(/assets/svgs/LassoCursor.svg), crosshair';
export const SHOW_ICON_AFTER = 5000;
export const COMMENT_FIELD_MAX_LENGTH = 36;
export const TERRITORY_MAP_RULES_BLOCK_SIZE = 600;
export const TERRITORY_MAP_GRID_WIDTH = 316;
export const MAP_GRID_ROW_HEIGHT = 28;
export const MAP_GRID_HEADER_HEIGHT = 32;
export const TERRITORY_MAP_PADDING = {
  top: 20,
  bottom: 20,
  left: 440,
  right: 20
};
export const ZOOM_DISTANCE = 0.5;
export const ZOOM_TRANSITION_DURATION = 200;
export const DEFAULT_PLACEHOLDER_COLOR = '#A8A8A8';

// Enum not used to avoid having to cast to literal
export const GeoJsonTypes = {
  Feature: 'Feature',
  Polygon: 'Polygon',
  MultiPolygon: 'MultiPolygon',
  Point: 'Point'
} as const;
export const DOWNLOAD_LOCATION_TEMPLATE_FILE_NAME = 'pinset_template.csv';
export const COMMENT_VERSION = 1;
export const DOWNLOAD_ACTIVITY_TEMPLATE_FILE_NAME = 'activity_template.csv';
export const DOWNLOAD_TERRITORY_QUOTA_TEMPLATE_FILE_NAME = 'territoryquota_template.csv';
export const DOWNLOAD_SELLER_ASSIGNMENT_TEMPLATE_FILE_NAME = 'sellerassignment_template.csv';
export const LOCATION_HEADERS = 'Country,ZipPostal,Name,Address1,Address2,City,StateProvince,Latitude,Longitude';
export const MAX_PIN_SET_NAME_LENGTH = 20;
export const MAP_PROXY_PATH = '/proxy/mapbox';
export const SYMON_PROXY_PATH = '/proxy/symon';
export const DATE_FORMAT = 'MMMM D, YYYY [at] h:mm A';
export const NUMBER_GREATER_THAN_ZERO = /^(\d+(\.\d+)?)$/;
export const DEFAULT_DATE_RANGE_MIN_YEARS_FROM_CURRENT = 4;
export const DEFAULT_DATE_RANGE_MAX_YEARS_FROM_CURRENT = 10;
export const ADD_HISTORICAL_FIELD_EMPTY_DATE_RANGE_MAX_YEARS_FROM_CURRENT = 5;
export const ADD_HISTORICAL_FIELD_EMPTY_DATE_RANGE_MIN_YEARS_FROM_CURRENT = 10;
export const HIERARCHY_PREVIEW_COUNT = 3;
export const RULE_PREVIEW_COUNT = 3;
export const SEASONALITY_RAMP_MONTH_REGEX = /Month (?:1|2|3|4|5|6|7|8|9|10|11|12)/;
export const FORMULA_EDITOR_MIN_HEIGHT = '400px';
export const SYMON_PIPE_POLL_INTERVAL = 4000;
export const SCHEDULE_COLUMN_WIDTH = 200;
export const DROPDOWN_MAX_HEIGHT = '300px';
export const INVITATION_ID = 'invitationId';
export const HIERARCHY_TARGET_SETTING_MAX_100_MEMBER_COUNT = 100;
export const HIERARCHY_TARGET_SETTING_MAX_20_MEMBER_COUNT = 20;
export const CONSECUTIVE_POLLING_ERROR_LIMIT = 10;

//const used for requestAllocateTopdown
export const TDA_STOP_POLLING_TIME = 90000;
export const POLLING_INTERVAL = 2000;

export const PRE_TENANT_INFO = ['GetUserSpec', 'ExchangeTemporaryToken', 'GetUserTenant', 'AddUser', 'AddUserToTenant'];
export const QUOTA_COLUMN_HIGHLIGHTING_THRESHOLD_HIGH = 0.1;
export const QUOTA_COLUMN_HIGHLIGHTING_THRESHOLD_LOW = -0.1;
