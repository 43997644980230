import { useMemo } from 'react';

import { useScope } from 'app/contexts/scopeProvider';

import { TenantHeader } from 'app/models';

import { getImpersonationToken } from 'utils/ImpersonationLocalStorage';

export const useTenantHeaders = (): {
  [TenantHeader.TENANT_GLOBAL_ID]?: string;
  [TenantHeader.TENANT_SLUG]?: string;
} => {
  const { selectedTenant } = useScope();
  return useMemo(() => {
    const headers = {};
    if (selectedTenant?.globalId) {
      headers[TenantHeader.TENANT_GLOBAL_ID] = selectedTenant.globalId;
    }
    if (selectedTenant?.slug) {
      headers[TenantHeader.TENANT_SLUG] = selectedTenant.slug;
    }

    const impersonationToken = getImpersonationToken();
    if (impersonationToken) {
      headers[TenantHeader.IMPERSONATION_TOKEN] = impersonationToken;
    }

    return headers;
  }, [selectedTenant?.globalId, selectedTenant?.slug]);
};
