// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KfyVT {\n  margin: 0 20px 0 5px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AssistantMenu": "KfyVT"
};
export default ___CSS_LOADER_EXPORT___;
