import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

import { useScope } from 'app/contexts/scopeProvider';

import { AqgTerritoryKind } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './AccountEditableFieldCellRenderer.module.pcss';

const b = block(style);

const AccountEditableFieldCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const { selectedPlanningCycle } = useScope();
  const { value, data } = props;
  const { planningCycleStartDate } = selectedPlanningCycle;
  const shouldShowDate = !(
    data.kind === AqgTerritoryKind.EXPANDED_SOURCE && data.firstRedirectStartDate === planningCycleStartDate
  );

  return (
    <div className={b('alignLeft')} data-testid="account-quota-cell-renderer-read-only">
      <div data-testid="cell-value">{value && shouldShowDate ? value : ''}</div>
    </div>
  );
};

export default AccountEditableFieldCellRenderer;
