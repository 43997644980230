import React, { useState } from 'react';

import { Spinner, SpinnerSize } from '@blueprintjs/core';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { RuleForMap } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import ColorPalette from './ColorPalette';
import style from './TerritoryColorCellRenderer.module.pcss';

const b = block(style);

interface TerritoryColorCellRendererTooltipContentProps {
  onUpdateRules: () => void;
  isPaletteOpen: boolean;
  rule: RuleForMap;
}

export const TerritoryColorCellRendererTooltipContent: React.FC<TerritoryColorCellRendererTooltipContentProps> = ({
  onUpdateRules,
  isPaletteOpen,
  rule
}: TerritoryColorCellRendererTooltipContentProps) => {
  const { isColoringByTerritoryGroup } = useDedicatedMapProvider();

  const [isLoadingUpsert, setIsLoadingUpsert] = useState(false);

  const tooltipText = isColoringByTerritoryGroup
    ? formatMessage('RECOLOR_DISABLED_TOOLTIP')
    : formatMessage('CHANGE_COLOR');

  const renderColorPaletteContent = () => {
    if (isLoadingUpsert) return <Spinner size={SpinnerSize.SMALL} />;

    return <ColorPalette onLoadingChange={setIsLoadingUpsert} onUpdateRules={onUpdateRules} rule={rule} />;
  };

  return (
    <>
      {isPaletteOpen ? (
        <div className={b('colorPalette', { loading: isLoadingUpsert })} data-testid="color-palette">
          {renderColorPaletteContent()}
        </div>
      ) : (
        <div>{tooltipText}</div>
      )}
    </>
  );
};

export default TerritoryColorCellRendererTooltipContent;
