import React, { Dispatch, SetStateAction } from 'react';

import { Drawer } from '@blueprintjs/core';

import CommandCenterMenu from 'app/components/CommandCenter/CommandCenterMenu/CommandCenterMenu';

import { useCommandCenter } from 'app/contexts/commandCenterProvider';

import { GetRootHierarchies_getRootHierarchies } from 'app/graphql/generated/graphqlApolloTypes';

import { CommandCenterDrawerState } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './CommandCenterDrawer.module.pcss';

const b = block(style);

const DRAWER_SIZES = {
  [CommandCenterDrawerState.EXPAND]: '100%',
  [CommandCenterDrawerState.HALF]: '720px',
  [CommandCenterDrawerState.OPEN]: '360px'
};

interface CommandCenterDrawerProps {
  setHierarchyToEdit: Dispatch<SetStateAction<GetRootHierarchies_getRootHierarchies | null>>;
}

const CommandCenterDrawer: React.FC<CommandCenterDrawerProps> = ({ setHierarchyToEdit }: CommandCenterDrawerProps) => {
  const { commandCenterDrawerState, setCommandCenterDrawerState, setActiveMenu } = useCommandCenter();

  return (
    <div data-testid="command-center-drawer">
      <Drawer
        canEscapeKeyClose={false}
        onClose={() => {
          setCommandCenterDrawerState(CommandCenterDrawerState.CLOSE);
          setActiveMenu(null);
        }}
        className={b('transition')}
        isOpen={commandCenterDrawerState !== CommandCenterDrawerState.CLOSE}
        hasBackdrop={commandCenterDrawerState === CommandCenterDrawerState.HALF}
        canOutsideClickClose={false}
        portalClassName="CommandCenterDrawerPortal"
        size={DRAWER_SIZES[commandCenterDrawerState] || ''}
        enforceFocus={false}
      >
        {/*
          Close Button must have class of close and be a
          direct descendant of the Drawer.
        */}
        <CommandCenterMenu setHierarchyToEdit={setHierarchyToEdit} />
      </Drawer>
    </div>
  );
};

export default CommandCenterDrawer;
