import { ColDef } from '@ag-grid-community/core';
import dayjs from 'dayjs';

import DateRangeCellEditor from 'app/components/AdvancedGrid/CellEditors/DateRangeCellEditor/DateRangeCellEditor';
import AccountEditableFieldCellRenderer from 'app/components/AdvancedGrid/CellRenderers/AccountEditableFieldCellRenderer/AccountEditableFieldCellRenderer';
import AccountTerritoryCellRenderer, {
  AccountTerritoryCellRendererProps
} from 'app/components/AdvancedGrid/CellRenderers/AccountTerritoryCellRenderer/AccountTerritoryCellRenderer';
import AccountTerritoryStatusCellRenderer from 'app/components/AdvancedGrid/CellRenderers/AccountTerritoryStatusCellRenderer/AccountTerritoryStatusCellRenderer';
import { TextRenderer } from 'app/components/AdvancedGrid/CellRenderers/TextRenderer/TextRenderer';

import { MEASURES_GRID_COLUMN_WIDTH_LARGE } from 'app/global/variables';

import { AccountRedirectInput } from 'app/graphql/generated/graphqlApolloTypes';

import {
  AccountMoveVariables,
  AqgTerritoryKind,
  DeleteRedirectVariables,
  GridFields,
  Toast,
  SelectedPlanningCycle,
  GridHeaders,
  HierarchySpec,
  HierarchyType
} from 'app/models';

import { formatNumber } from 'utils/messages/utils';

import {
  getDayBeforeDate,
  getEffectiveDateValue,
  getPlanningCycleEndDate,
  setEffectiveDateValue
} from './AccountQuotaUtils';

const buildAccountQuotaGridColumnDef = (
  openAccountMoveDialog: (moveVariables: AccountMoveVariables) => void,
  openDeleteRedirectDialog: (deleteVariables: DeleteRedirectVariables) => void,
  selectedPlanningCycle: SelectedPlanningCycle,
  quotaBreakdownHierarchies: HierarchySpec[],
  handleRedirectAccountUnit: (accountRedirectInput: AccountRedirectInput, toast: Toast) => void,
  canEditAccountQuota: boolean,
  currency: string,
  territoryGroupTypeId?: number,
  isAccountMoveWithQuotaEnabled = false,
  isAccountMoveWithQuotaTwoHierarchiesEnabled: boolean = false
): ColDef[] => {
  const { planningCycleStartDate, planningCycleDuration } = selectedPlanningCycle;
  const showAMWQTwoHierarchies = isAccountMoveWithQuotaTwoHierarchiesEnabled && quotaBreakdownHierarchies?.length > 1;

  const customHierarchyBreakdown = quotaBreakdownHierarchies.find(
    ({ hierarchyType }) => hierarchyType === HierarchyType.CustomHierarchy
  );

  const customHierarchy =
    customHierarchyBreakdown && showAMWQTwoHierarchies
      ? [
          {
            headerName: `${customHierarchyBreakdown.rootName} name`,
            field: GridFields.CUSTOM_HIERARCHY_NAME,
            flex: 2,
            maxWidth: MEASURES_GRID_COLUMN_WIDTH_LARGE
          },
          {
            headerName: `${customHierarchyBreakdown.rootKey} key`,
            field: GridFields.CUSTOM_HIERARCHY_KEY,
            flex: 2,
            maxWidth: MEASURES_GRID_COLUMN_WIDTH_LARGE
          }
        ]
      : [];

  const cellRendererSelector = ({ data, node }) => {
    const parentAccount = node.parent?.data;
    const {
      territoryName,
      territoryId,
      kind,
      accountName,
      accountId,
      customHierarchyName,
      customHierarchyId,
      sourceRuleId,
      redirects,
      redirectId,
      sourceRule
    } = data;
    return {
      frameworkComponent: AccountTerritoryCellRenderer,
      params: {
        territoryName,
        territoryId,
        kind,
        accountName: accountName ?? parentAccount?.accountName,
        accountId: accountId ?? parentAccount?.accountId,
        ...(isAccountMoveWithQuotaTwoHierarchiesEnabled && {
          customHierarchyName: customHierarchyName ?? parentAccount?.customHierarchyName,
          customHierarchyId: customHierarchyId ?? parentAccount?.customHierarchyId
        }),
        sourceRuleId,
        sourceRule,
        openAccountMoveDialog,
        openDeleteRedirectDialog,
        redirects: redirects ?? parentAccount?.redirects,
        isCurrentTerritory: !!accountId,
        redirectId,
        handleRedirectAccountUnit,
        // TODO TQP-11297 Split view and edit functionality in AccountTerritoryCellRenderer
        editable: canEditAccountQuota,
        territoryGroupTypeId
      } satisfies AccountTerritoryCellRendererProps
    };
  };

  return [
    {
      headerName: GridHeaders.ACCOUNT_NAME,
      field: GridFields.ACCOUNT_NAME,
      hide: true,
      flex: 2
    },
    {
      headerName: GridHeaders.ACCOUNT_KEY,
      field: GridFields.ACCOUNT_KEY,
      cellRendererFramework: TextRenderer,
      flex: 1
    },
    ...customHierarchy,
    {
      headerName: GridHeaders.CURRENT_TERRITORY,
      field: GridFields.TERRITORY,
      cellRendererSelector,
      flex: 2,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_LARGE
    },
    {
      headerName: GridHeaders.STATUS,
      field: GridFields.STATUS,
      flex: 2,
      // TODO TQP-11297 Split view and edit functionality in AccountTerritoryCellRenderer
      cellRendererSelector: ({ data }) => {
        const { kind, redirects, redirectStartDate, redirectEndDate, accountId } = data;
        return {
          frameworkComponent: AccountTerritoryStatusCellRenderer,
          params: {
            kind,
            redirects,
            redirectStartDate,
            redirectEndDate,
            isAccountStatus: !!accountId
          }
        };
      }
    },
    {
      headerName: GridHeaders.ACCOUNT_QUOTA,
      field: GridFields.ACCOUNT_QUOTA,
      flex: 2,
      hide: !isAccountMoveWithQuotaEnabled,
      valueGetter: (params) => params.data.accountQuotaMeasureValue,
      valueFormatter: (params) => {
        // Check if the data kind is 'EXPANDED_SOURCE', which determines if a value is original account quota or not
        // Check if the node level is greater than 0 and data kind is move back, which determines if a value indicates a move back to original account quota
        const isExpandedSource =
          params.data.kind === AqgTerritoryKind.EXPANDED_SOURCE ||
          (params.data.kind === AqgTerritoryKind.MOVE_BACK && params.node.level > 0);
        //  Set 'value' to null if 'isExpandedSource' is true, otherwise use 'accountQuotaMeasureValue' or default to 0 for accounts without a quota
        const value =
          isExpandedSource && !params.data.accountQuotaMeasureValue ? null : params.data.accountQuotaMeasureValue ?? 0;
        return isExpandedSource ? null : formatNumber(value, { style: 'currency', currency });
      }
    },
    {
      headerName: GridHeaders.EFFECTIVE_DATE,
      field: GridFields.EFFECTIVE_DATE,
      flex: 2,
      editable: canEditAccountQuota,
      cellEditorFramework: DateRangeCellEditor,
      cellEditorParams: (params) => {
        const effectiveDate = params?.data?.effectiveStartDate ?? planningCycleStartDate;
        const effectiveEndDate =
          params?.data?.effectiveEndDate ?? getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration);

        const isDatePickerEnabled = params?.data?.kind !== AqgTerritoryKind.EXPANDED_SOURCE;

        const maxDate = isDatePickerEnabled ? getDayBeforeDate(params.data.redirectEndDate) : null;

        return {
          name: 'AccountEffectiveDates',
          effectiveStartDate: params?.data?.redirectStartDate ?? effectiveDate,
          effectiveEndDate: params?.data?.redirectEndDate ?? effectiveEndDate,
          endDateDisabled: true,
          disabled: !isDatePickerEnabled,
          maxDate: maxDate ? dayjs(maxDate).toDate() : dayjs().toDate(),
          minDate: dayjs(params.data.minDateForUpsert).toDate()
        };
      },
      valueGetter: (params) => getEffectiveDateValue(params, planningCycleStartDate, planningCycleDuration),
      valueSetter: (params) => setEffectiveDateValue(params, handleRedirectAccountUnit),
      cellRendererFramework: AccountEditableFieldCellRenderer
    }
  ];
};

export default buildAccountQuotaGridColumnDef;
