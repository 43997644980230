import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { DeploymentModelTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';
import { GET_DEPLOYMENT_MODEL_SPEC } from 'app/graphql/queries/getDeploymentModelSpec';

import { DeploymentModel, DeploymentModelSpec } from 'app/models';

export const getDeploymentModel = async (
  deploymentModelId: number,
  suppressCache = false,
  isTQM = false
): Promise<DeploymentModel> => {
  try {
    // eslint-disable-next-line no-restricted-syntax
    const deploymentModel = await apolloClient.query({
      query: GET_DEPLOYMENT_MODEL_SPEC,
      notifyOnNetworkStatusChange: true,
      variables: { deploymentModelId, isTQM },
      fetchPolicy: suppressCache ? 'network-only' : 'cache-first'
    });

    return deploymentModel?.data?.getDeploymentModelSpec;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const findPublishedDeploymentModel = (dmsWithinPlanningCycle: DeploymentModelSpec[]): DeploymentModelSpec =>
  dmsWithinPlanningCycle.find((dm) => dm?.deploymentModelType === DeploymentModelTypeEnum.Plan && dm.isPublished);

export const findQcIdToSelect = (
  availableQcs: Array<{ quotaComponentId: number }>,
  currentQuotaComponentId: number
): number | null => {
  if (currentQuotaComponentId && availableQcs.some((qc) => qc.quotaComponentId === currentQuotaComponentId)) {
    return currentQuotaComponentId;
  }
  const firstQuotaComponent = availableQcs[0]?.quotaComponentId;
  return firstQuotaComponent ?? null;
};
