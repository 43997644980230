// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aJTOR {\n  padding: 0 var(--data-panel-padding);\n  width: 100%;\n  overflow: auto;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n  .aJTOR .bp3-dialog {\n    padding: var(--m);\n  }\n\n  /* needed to override the default styles in advanced grid */\n\n  .SNK1k {\n    color: rgb(var(--color-cobalt-3)) !important;\n  }\n\n  .aJTOR .bp3-popover-target {\n    display: flex;\n  }\n\n  .aJTOR .ag-react-container {\n    height: 100%;\n  }\n\n.aJTOR.ag-theme-alpine .ag-root-wrapper {\n    border: 1px solid rgb(var(--color-light-gray-3));\n  }\n\n.i9jsH {\n    color: rgb(var(--color-cobalt-3));\n  }\n\n.PRw-5 {\n    display: flex;\n    height: 100%;\n    justify-content: center;\n  }\n\n.\\+0DYt {\n    height: 40px;\n    width: 100%;\n    margin: 4px;\n  }\n\n.wIg6- {\n    border-top: 1px solid #babfc7;\n    padding: 12px 16px;\n  }\n\n.GEHjv {\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    padding: 0 6px 0 16px;\n    height: 35px;\n  }\n\n.OxrlK {\n    margin-left: var(--xs);\n  }\n\n.YGtca {\n    font-weight: bold;\n  }\n\n.qQv24 {\n    align-items: center;\n    display: flex;\n  }\n\n.SRRql {\n    font-weight: normal;\n    margin: 0 8px 0 0;\n  }\n\n.R3QTl {\n    cursor: pointer;\n    height: 26px;\n  }\n\n._7YpQP {\n    font-weight: bold;\n  }\n\n.FPRUT {\n    padding: var(--m) 0 var(--s) 0;\n  }\n\n.mVwSY {\n    padding: var(--s) 0 var(--m) 0;\n  }\n\n.iGaNN {\n    display: flex;\n    justify-content: flex-end;\n  }\n\n.kObVR {\n    display: flex;\n    justify-content: space-between;\n    width: 180px;\n  }\n\n.yHq44 {\n    width: 100%;\n    flex: 1;\n    overflow: auto;\n  }\n\n.B46JS {\n    width: 100%;\n    flex: 1;\n    overflow: hidden;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserManagementPanel": "aJTOR",
	"UserManagementPanel_emailBlue": "SNK1k",
	"UserManagementPanel_action": "i9jsH",
	"UserManagementPanel_delete": "PRw-5",
	"UserManagementPanel_gridLoading": "+0DYt",
	"UserManagementPanel_noMembers": "wIg6-",
	"UserManagementPanel_tableHeader": "GEHjv",
	"UserManagementPanel_mailIcon": "OxrlK",
	"UserManagementPanel_tableTitle": "YGtca",
	"UserManagementPanel_tableNavigation": "qQv24",
	"UserManagementPanel_showSystemAccess": "SRRql",
	"UserManagementPanel_secondaryMenu": "R3QTl",
	"UserManagementPanel_dialogTitle": "_7YpQP",
	"UserManagementPanel_inviteDialogBody": "FPRUT",
	"UserManagementPanel_bodyText": "mVwSY",
	"UserManagementPanel_footer": "iGaNN",
	"UserManagementPanel_buttons": "kObVR",
	"UserManagementPanel_gridContainer": "yHq44",
	"UserManagementPanel_gridContainer__loading": "B46JS"
};
export default ___CSS_LOADER_EXPORT___;
