import React, { useCallback, useEffect, useRef } from 'react';

import { Spinner } from '@blueprintjs/core';
import {
  localeMessages as componentsLibMessages,
  notifyConfig,
  useSymonModalContextValue
} from '@symon-ai/wisepipe-components';
import {
  SymonImportsModalsC,
  ImportC,
  ImportConnector,
  ImportProviderC,
  localeMessages as importsLibMessages
} from '@symon-ai/wisepipe-imports';
import { createIntl } from 'react-intl';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import IntegrationPanelFallback from 'app/components/IntegrationPanel/IntegrationPanelFallback';
import SymonSpinner from 'app/components/IntegrationPanel/SymonSpinner';

import { RoutePaths } from 'app/containers/App/Router/routePaths';

import useForwardAuthParamsToSymon from 'app/hooks/symon/useForwardRedirectParamsToSymon';
import useIsSymonImportLoading from 'app/hooks/symon/useIsSymonImportLoading';
import useSymonEvent from 'app/hooks/symon/useSymonEvent';
import useSymonRedirectHandler from 'app/hooks/symon/useSymonRedirectHandler';
import { SymonTokenState } from 'app/hooks/symon/useSymonToken';
import useSymonUploadCreds from 'app/hooks/symon/useSymonUploadCreds';
import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SymonImport.module.pcss';
import { symonSourcesByApiOrigin, SymonUploadCredentials, UploadStage, UploadStatus } from './SymonTypes';

export type SymonImportProps = {
  tokenState: SymonTokenState;
  onStartImport: () => void;
  symonAPIOrigin: string;
  isSymonAPIOriginLoading: boolean;
};

const b = block(style);

const oauthReturnUrl = `${window.location.origin}${RoutePaths.EMBEDDED_AUTH}`;

const appIntl = createIntl({
  locale: 'en',
  defaultLocale: 'en',
  messages: {
    ...componentsLibMessages,
    ...importsLibMessages,
    'symon.imports.select_source.import': formatMessage('IMPORT_DATA'),
    'symon.imports.select.third_party_sources': formatMessage('SYMON_IMPORT_THIRD_PARTY_HEADER_OVERRIDE')
  }
});

const SymonImport: React.FC<SymonImportProps> = ({
  tokenState,
  onStartImport,
  symonAPIOrigin,
  isSymonAPIOriginLoading
}) => {
  const symonModalContext = useSymonModalContextValue();
  const awsCreds = useSymonUploadCreds(tokenState.token);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isLoading, isInitialLoading } = useIsSymonImportLoading();
  const showToast = useShowToast();

  useForwardAuthParamsToSymon(isInitialLoading);
  useSymonRedirectHandler();

  const importSource = symonSourcesByApiOrigin[symonAPIOrigin];
  useEffect(() => {
    if (!importSource) console.warn('No Symon source available for url', symonAPIOrigin);
  }, [importSource]);

  useEffect(() => {
    if (containerRef.current) {
      notifyConfig({
        duration: 5,
        top: 64,
        getContainer: () => containerRef.current
      });
    }
  }, [containerRef.current]);

  useSymonEvent(
    'onUpload',
    useCallback(
      (stage, status, data) => {
        console.debug(`SymonImports onUpload: ${stage}, ${status}`);
        switch (stage) {
          case UploadStage.attempt: {
            if (status === UploadStatus.before) {
              return formatAwsCredsForEvent(awsCreds);
            }
            if (status === UploadStatus.failure && data?.error) {
              showToast(data.error, 'danger');
            }
            break;
          }
          case UploadStage.prepare: {
            if (status === UploadStatus.before) {
              showToast(formatMessage('SYMON_UPLOAD_START_MESSAGE'), 'success');
              if (data?.renamedFile) {
                showToast(
                  appIntl.formatMessage({ id: 'data.uploaded_file_renamed' }, { file: data.renamedFile }),
                  'warning'
                );
              }
            }
            if (status === UploadStatus.failure && data?.error) {
              showToast(data.error, 'danger');
            }
            break;
          }
          case UploadStage.process: {
            if (status === UploadStatus.before && data) {
              showToast(formatMessage('SYMON_IMPORT_START_MESSAGE'), 'success');
              onStartImport();
            }
            break;
          }
          case UploadStage.monitor: {
            if (status === UploadStatus.success && data?.data && data?.uploadID) {
              console.debug(`Discovery has succeeded for upload: ${data.uploadID}`);
            }
            if (status === UploadStatus.failure && data?.uploadID) {
              console.log(`Discovery has failed for upload: ${data.uploadID}`);
              showToast(formatMessage('SYMON_UPLOAD_ERROR'), 'danger');
            }
            break;
          }
        }
        // Can return anything, but nothing needed
        return undefined;
      },
      [awsCreds, onStartImport]
    )
  );

  useSymonEvent('onConnection', (operation, connection, extras) => {
    if (connection) {
      switch (operation) {
        case 'failure':
          showToast(
            <ToastMessage
              title={formatMessage('SYMON_CONNECTION_FAILURE_TITLE')}
              message={formatMessage('SYMON_CONNECTION_FAILURE_BODY', { connectionType: connection.connectionType })}
            />,
            'danger'
          );
          break;
        case 'update':
          if (!extras?.isRename) {
            showToast(
              <ToastMessage
                title={formatMessage('SYMON_CONNECTION_UPDATED_TITLE')}
                message={formatMessage('SYMON_CONNECTION_UPDATED_BODY', { connectionType: connection.connectionType })}
              />,
              'success'
            );
          }
          break;
      }
    }
    return undefined;
  });

  if (tokenState.hasError) {
    return <IntegrationPanelFallback showRefreshButton={false} />;
  } else if (tokenState.isLoading || isSymonAPIOriginLoading) {
    return <SymonSpinner />;
  }

  return (
    <div className={b()} ref={containerRef} data-testid="symon-import">
      <div className={b('embedContainer')}>
        <div className={b('gutters')}>
          <ImportProviderC
            modalContext={symonModalContext}
            authToken={tokenState.token.token}
            orgID={tokenState.token.orgID}
            apiUrl={symonAPIOrigin}
            formatMessage={appIntl.formatMessage}
            themeType="light"
            getPopupContainer={() => containerRef.current}
            source={importSource}
          >
            <ImportC
              oauthReturnUrl={oauthReturnUrl}
              addConnectors
              addFileUpload
              multipleDataSet
              addSampleData={false}
              isConnectionOnly={false}
              hiddenConnectors={[ImportConnector.Tableau, ImportConnector.PowerBI, ImportConnector.Shopify]}
            />
            <SymonImportsModalsC />
          </ImportProviderC>
        </div>
      </div>
      {isLoading && (
        <div className={b('internalSpinnerContainer')} data-testid="symon-overlay-spinner">
          <Spinner intent="primary" size={40} />
        </div>
      )}
    </div>
  );
};

export default SymonImport;

const formatAwsCredsForEvent = (awsCreds: SymonUploadCredentials) => ({
  awsCreds: {
    ...awsCreds,
    expiration: new Date(awsCreds.expiration)
  },
  params: {}
});
