import { useCallback, useEffect, useState } from 'react';

import { FromEmbedderMessage } from 'app/containers/App/EmbeddedAuth/EmbedderTypes';

import { TokenState } from 'app/models';

import { useOnEmbedderMessage } from './useOnEmbedderMessage';
import { useSendEmbedderMessage } from './useSendEmbedderMessage';
import { useShouldUseEmbeddedAuth } from './useShouldUseEmbeddedAuth';

export const useTemporaryAuthToken = (): [TokenState, () => void] => {
  const shouldUseEmbeddedAuth = useShouldUseEmbeddedAuth();
  const [state, setState] = useState<TokenState>({ token: null, isLoading: true });

  const handleEmbedderMessage = useCallback((message: FromEmbedderMessage) => {
    if (message.type !== 'one-time-token') return;
    setState({ token: message.payload.token, isLoading: false });
  }, []);
  useOnEmbedderMessage(handleEmbedderMessage);

  const sendEmbedderMessage = useSendEmbedderMessage();

  const startTempTokenFlow = useCallback(() => {
    if (shouldUseEmbeddedAuth) {
      setState((state) => ({ ...state, isLoading: true }));
      sendEmbedderMessage({ type: 'get-one-time-token' });
    } else {
      setState({ isLoading: false, token: null });
    }
  }, [sendEmbedderMessage, shouldUseEmbeddedAuth]);

  useEffect(() => {
    startTempTokenFlow();
  }, [shouldUseEmbeddedAuth]);

  return [state, startTempTokenFlow];
};
