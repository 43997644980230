// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xKDdO {\n      --territory-color: transparent !important;\n    }\n  .deEpX, .deEpX.bp3-button.bp3-disabled {\n    min-width: 12px;\n    min-height: 12px;\n    border-radius: 0;\n    opacity: 0.6;\n    cursor: pointer;\n    background-color: var(--territory-color) !important;\n  }\n  ._4xvzp {\n    display: grid;\n    grid-template-rows: repeat(4, auto);\n    grid-auto-flow: column;\n  }\n  .z4UiM {\n      justify-content: center;\n    }\n  .A2lqQ {\n    min-width: 19px;\n    height: 19px;\n    margin: 4px;\n    background: white;\n    cursor: pointer;\n    border-radius: 50%;\n    opacity: 0.6;\n  }\n  .Vzb8J {\n    display: flex;\n  }\n  .XRzBo {\n    -webkit-appearance: none;\n       -moz-appearance: none;\n            appearance: none;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryColorCellRenderer_coloredSquare__isIgnored": "xKDdO",
	"TerritoryColorCellRenderer_coloredSquare": "deEpX",
	"TerritoryColorCellRenderer_colorPalette": "_4xvzp",
	"TerritoryColorCellRenderer_colorPalette__loading": "z4UiM",
	"TerritoryColorCellRenderer_coloredCircle": "A2lqQ",
	"TerritoryColorCellRenderer_pickerLabel": "Vzb8J",
	"TerritoryColorCellRenderer_radioField": "XRzBo"
};
export default ___CSS_LOADER_EXPORT___;
