import gql from 'graphql-tag';

export const UPSERT_SYMON_PIPE_CONFIGURATION = gql`
  mutation upsertSymonPipeConfiguration($input: UpsertSymonPipeConfigurationInput) {
    upsertSymonPipeConfiguration(input: $input) {
      symonPipeConfigurationId
      pipeId
      exportId
      planningCycleId
      tableNameLabel
      fileType
      fileFormat
      runId
      downloadId
      status
      progress
      startAt
      endAt
      seqPipeExecution
      properties
    }
  }
`;
