import React, { FC, useEffect } from 'react';

import AuthSpinner from 'components/AuthSpinner/AuthSpinner';

import ScenarioPlanningCompareAcrossDmHeader from 'app/components/TerritoryMap/ScenarioPlanningMapHeader/ScenarioPlanningCompareAcrossDmHeader';
import TerritoryMapV3 from 'app/components/TerritoryMap/TerritoryMapV3';

import { DedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { HeaderSetter } from 'app/contexts/HeaderCascade';
import { MapComparisonMcrLoader } from 'app/contexts/MapContextRedistributor/MapComparisonMcrLoader';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { SecondaryMapWorkerProvider } from 'app/contexts/mapWorkerContext';

import { useMarkScenariosOpen } from 'app/graphql/mutations/markScenariosOpen';

import { CustomHeader, MapCompareState } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './ScenarioPlanningMap.module.pcss';

const b = block(style);

const ScenarioPlanningMap: FC = () => {
  const { mapCompareState } = useMapVariant();
  const [markScenariosOpen] = useMarkScenariosOpen();

  useEffect(() => {
    if (mapCompareState) {
      markScenariosOpen({
        variables: {
          deploymentModelIds: [mapCompareState.primary.deploymentModelId, mapCompareState.secondary.deploymentModelId]
        }
      });
    }
  }, [mapCompareState.primary.deploymentModelId, mapCompareState.secondary.deploymentModelId]);

  return (
    <div className={b('scenarioPlanningPage')}>
      <div className={b('scenarioPlanningMapContainer')}>
        <div className={b('scenarioPlanningMap', { primaryMap: true })}>
          <MapStack isPrimary={true} mapCompareState={mapCompareState} />
        </div>
        <div className={b('scenarioPlanningMap')}>
          <SecondaryMapWorkerProvider>
            <MapStack isPrimary={false} mapCompareState={mapCompareState} />
          </SecondaryMapWorkerProvider>
        </div>
      </div>
    </div>
  );
};

const MapStack: FC<{ isPrimary: boolean; mapCompareState: MapCompareState }> = ({ isPrimary, mapCompareState }) => {
  const compareState = isPrimary ? mapCompareState.primary : mapCompareState.secondary;
  return (
    <HeaderSetter headerKey={CustomHeader.DEPLOYMENT_MODEL_ID} value={compareState.deploymentModelId}>
      <HeaderSetter headerKey={CustomHeader.BATTLE_CARD_ID} value={compareState.battlecardId}>
        <MapComparisonMcrLoader isPrimaryMap={isPrimary}>
          <DedicatedMapProvider>
            <ScenarioPlanningCompareAcrossDmHeader />
            <MapWithLoadedBattleCard />
          </DedicatedMapProvider>
        </MapComparisonMcrLoader>
      </HeaderSetter>
    </HeaderSetter>
  );
};

const MapWithLoadedBattleCard: FC = () => {
  const { isBattlecardDataLoading, selectedBattleCard } = useMapContextRedistributor();
  if (isBattlecardDataLoading || !selectedBattleCard) return <AuthSpinner />;
  return <TerritoryMapV3 />;
};

export default ScenarioPlanningMap;
