import React from 'react';

import { Intent } from '@blueprintjs/core';

import Dialog from 'components/Dialog/Dialog';

import { formatMessage } from 'utils/messages/utils';

import { useBulkDeleteRulesForMap } from './hooks/useBulkDeleteRulesForMap';

export interface DeleteTerritoryDialogProps {
  onClose: () => void;
  ruleIds: number[];
  onTerritoryDeleted: () => void;
}

const DeleteTerritoryDialog: React.FC<DeleteTerritoryDialogProps> = ({
  onClose,
  ruleIds,
  onTerritoryDeleted
}: DeleteTerritoryDialogProps) => {
  const { startRuleDelete, isLoading } = useBulkDeleteRulesForMap({
    onCompleted: () => {
      onClose();
      onTerritoryDeleted();
    }
  });

  return (
    <Dialog
      isOpen
      onClose={onClose}
      onSubmit={() => startRuleDelete(ruleIds)}
      confirmButtonText={formatMessage('DELETE')}
      confirmButtonLoading={isLoading}
      confirmButtonIntent={Intent.DANGER}
      title={formatMessage('DELETE_TERRITORY_TEXT')}
      bodyMinHeight={0}
      size="small"
      children={
        <div data-testid="delete-territory-dialog">
          {ruleIds.length > 1
            ? formatMessage('TERRITORIES_DELETE_DIALOG_BODY', { count: ruleIds.length })
            : formatMessage('TERRITORY_DELETE_DIALOG_BODY', { count: ruleIds.length })}
        </div>
      }
    />
  );
};

export default DeleteTerritoryDialog;
