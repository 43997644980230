// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9oFUV {\n    width: 100%;\n  }\n  .LF1HJ .bp3-heading {\n      font-size: 16px;\n      font-weight: 600;\n    }\n  .kSqA6 span.bp3-popover2-target {\n    width: 100%;\n  }\n  .Lm57v .bp3-button:not([class*='bp3-intent-']) {\n      border: 1px solid rgb(var(--color-light-gray-1));\n    }\n  .jam1C .bp3-button:not([class*='bp3-intent-']) {\n        border: 1px solid rgb(var(--color-red-3));\n      }\n  ._4tu1J .bp3-button:not([class*='bp3-intent-']) {\n        border: 1px solid rgb(var(--color-cobalt-3));\n      }\n  ._2Mapn {\n    min-width: var(--data-drill-in-menu-min-width);\n    height: 45vh;\n    overflow-y: scroll;\n  }\n  ._0LrE9 {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .iR9CB {\n    border-bottom: 1px solid;\n    border-color: var(--border-divider);\n  }\n  .gl\\+qA {\n    width: 100%;\n  }\n  ._0zxkV {\n      color: rgb(var(--color-light-gray-1));\n      background-color: rgb(var(--color-light-gray-4));\n      cursor: not-allowed;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DataMappingSelectMenu_button": "_9oFUV",
	"DataMappingSelectMenu_sectionHeader": "LF1HJ",
	"DataMappingSelectMenu": "kSqA6",
	"DataMappingSelectMenu_textButton": "Lm57v",
	"DataMappingSelectMenu_textButton__error": "jam1C",
	"DataMappingSelectMenu_textButton__autoMatched": "_4tu1J",
	"DataMappingSelectMenu_menu": "_2Mapn",
	"DataMappingSelectMenu_rightIcon": "_0LrE9",
	"DataMappingSelectMenu_staticSection": "iR9CB",
	"DataMappingSelectMenu_menuItem": "gl+qA",
	"DataMappingSelectMenu_menuItem__disabled": "_0zxkV"
};
export default ___CSS_LOADER_EXPORT___;
