import { ColDef } from '@ag-grid-community/core';

import { SheetsDetailReferencedGridType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const buildQuotaComponentReferencedColumnDef = (checkboxRenderer: (params) => JSX.Element): ColDef[] => {
  const columnDefs: ColDef[] = [];

  columnDefs.push({
    headerName: formatMessage('QUOTA_COMPONENT'),
    field: 'quotaComponent',
    flex: 2,
    minWidth: 200
  });

  columnDefs.push({
    headerName: formatMessage('REFERENCE_THIS_SHEET'),
    headerTooltip: formatMessage('REFERENCE_THIS_SHEET'),
    field: 'referenceThisSheet',
    flex: 1,
    minWidth: 100,
    cellStyle: {
      'background-color': 'rgb(var(--light-blue-translucent))',
      display: 'flex',
      'justify-content': 'center'
    },
    checkboxSelection: (params) => {
      params.node.setSelected(true);
      return false;
    },
    headerCheckboxSelection: false, // disable header checkbox selection because we don't support updating/deleting a reference at this moment
    cellRendererSelector: () => {
      return {
        frameworkComponent: checkboxRenderer,
        params: {
          gridType: SheetsDetailReferencedGridType.QUOTA_COMPONENT
        }
      };
    }
  });

  return columnDefs;
};

export default buildQuotaComponentReferencedColumnDef;
