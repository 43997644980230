import { useCallback, useMemo, useState } from 'react';

import type { MapSourceDataEvent, MapStyleDataEvent } from 'mapbox-gl';

import { SourceGroup } from 'app/models';

export const useIsLoadingSources = (
  sourceGroups: SourceGroup[]
): {
  isLoadingSources: boolean;
  onSourceData: (event: MapSourceDataEvent) => void;
  onStyleData: (event: MapStyleDataEvent) => void;
} => {
  const [loadedSources, setLoadedSources] = useState<Record<string, boolean>>({});
  const [isLoadingStyle, setIsLoadingStyle] = useState(true);

  const onSourceData = useCallback((event: MapSourceDataEvent) => {
    const sourceId = event.sourceId;
    // The event.isLoaded never becomes true, so we use isSourceLoaded instead
    if (!event.target.isSourceLoaded(sourceId)) return;

    setLoadedSources((currentLoadedSources) => {
      if (currentLoadedSources[sourceId]) return currentLoadedSources;
      return { ...currentLoadedSources, [sourceId]: true };
    });
  }, []);

  const isLoadingSources = useMemo(
    () => isLoadingStyle || sourceGroups.length === 0 || sourceGroups.some((group) => !loadedSources[group.sourceId]),
    [isLoadingStyle, sourceGroups, loadedSources]
  );

  const onStyleData = useCallback(() => setIsLoadingStyle(false), []);

  return { isLoadingSources, onSourceData, onStyleData };
};
