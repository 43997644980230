import gql from 'graphql-tag';

export const GET_DEPLOYMENT_MODEL_SPEC = gql`
  query GetDeploymentModelSpec($deploymentModelId: Int!, $battlecardId: Int, $isTQM: Boolean) {
    getDeploymentModelSpec(
      input: { deploymentModelId: $deploymentModelId, battlecardId: $battlecardId, isTQM: $isTQM }
    ) {
      deploymentModelId
      deploymentModelName
      quotaComponents {
        quotaComponentId
        quotaComponentName
        quotaComponentComment
        canDelete
      }
      isTerritoryWorkflowStarted
      dataSheets {
        isTQM
        sheetId
        sheetName
        sheetType
        sheetCreatedAt
        sheetUpdatedAt
      }
    }
  }
`;
