import React from 'react';

import { useFormikContext } from 'formik';

import DirtyPrompt from './DirtyPrompt';

/* wraps DirtyPrompt in Formik context so consumers don't have to */
const DirtyFormPrompt: React.FC = () => {
  const { dirty, submitCount } = useFormikContext();

  return <DirtyPrompt dirty={dirty} submitCount={submitCount} data-testid="dirty-prompt" />;
};

export default DirtyFormPrompt;
