import { ColDef } from '@ag-grid-community/core';

import { PlanMeasureType } from 'app/models';

export const enum ColumnName {
  NAME = 'Name',
  GROUP = 'Group',
  TYPE = 'Type',
  FORMAT = 'Format',
  FORMULA = 'Formula'
}

const buildMeasureColumnDef = (
  hasData: boolean,
  measureType: PlanMeasureType,
  actionItems: (params) => JSX.Element = null
): ColDef[] => {
  const columnDefs: ColDef[] = [];
  if (hasData) {
    columnDefs.push({
      headerName: ColumnName.NAME,
      field: 'measureName',
      sortable: true,
      flex: 1,
      minWidth: 250
    });

    columnDefs.push({
      headerName: ColumnName.TYPE,
      field: 'measureFieldType',
      sortable: true,
      flex: 1,
      minWidth: 150
    });

    columnDefs.push({
      headerName: ColumnName.GROUP,
      field: 'measureFieldGroup',
      sortable: true,
      flex: 1,
      minWidth: 150
    });

    columnDefs.push({
      headerName: ColumnName.FORMAT,
      field: 'measureFormatType',
      sortable: true,
      flex: 1,
      minWidth: 150
    });

    columnDefs.push({
      headerName: ColumnName.FORMULA,
      field: 'measureFormula',
      sortable: true,
      flex: 1,
      minWidth: measureType === PlanMeasureType.CUSTOM ? 300 : 500
    });

    if (measureType === PlanMeasureType.CUSTOM) {
      columnDefs.push({
        minWidth: 100,
        cellRendererFramework: actionItems
      });
    }
  }

  return columnDefs;
};

export default buildMeasureColumnDef;
