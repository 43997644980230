import { useEffect, useState } from 'react';

import { useAuthHeader } from 'app/contexts/AuthHeaderProvider';

const INTERVAL_DURATION = 30_000;

const useAuth0TokenForMapbox = (): string | null => {
  const { getAuthHeader } = useAuthHeader();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const refreshToken = () => {
      getAuthHeader()
        .then(setToken)
        .catch((error) => {
          console.error('Failed to get token for mapbox', error);
          setToken(null);
        });
    };
    const intervalId = window.setInterval(refreshToken, INTERVAL_DURATION);
    refreshToken();
    return () => window.clearInterval(intervalId);
  }, []);

  return token;
};

export default useAuth0TokenForMapbox;
