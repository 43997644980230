import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { defaultSortModel } from 'app/contexts/gridProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { SplitFeatures } from 'app/global/features';
import { TERRITORY_MAP_RULES_BLOCK_SIZE } from 'app/global/variables';

import { GetTerritoryRulesForMapVariables, SourceInput } from 'app/graphql/generated/graphqlApolloTypes';
import { useGetTerritoryRulesForMap } from 'app/graphql/queries/getTerritoryRulesForMap';

import useTreatment from 'app/hooks/useTreatment';

import { RuleChangeCause, RuleChangeCauses } from 'app/models';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useMapRules = (
  input: {
    source?: SourceInput;
  },
  isWorkerLoading: boolean
): {
  loading: boolean;
  refetch: (cause: RuleChangeCause) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(true);

  const { selectedQuotaComponentId, selectedTerritoryGroupId, primaryHierarchy } = useMapContextRedistributor();
  const { insightsMeasureId } = useMapVariant();
  const { chosenCustomHierarchy } = useDedicatedMapProvider();
  const [isUndoEnabled] = useTreatment(SplitFeatures.MAP_UNDO);

  const postMessage = useMapWorkerPostMessage();
  const initializedFor = useRef<GetTerritoryRulesForMapVariables | null>(null);

  const fetchVariables = useMemo(() => {
    if (!selectedTerritoryGroupId || !selectedQuotaComponentId || !insightsMeasureId) return null;
    return {
      territoryGroupId: selectedTerritoryGroupId,
      quotaComponentId: selectedQuotaComponentId,
      measureId: insightsMeasureId,
      source: input.source,
      sorting: defaultSortModel,
      startRow: 1,
      endRow: TERRITORY_MAP_RULES_BLOCK_SIZE
    };
  }, [selectedTerritoryGroupId, selectedQuotaComponentId, insightsMeasureId, input.source]);

  const getRulesForMap = useGetTerritoryRulesForMap({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !fetchVariables,
    variables: fetchVariables,
    onCompleted(data) {
      let automaticFetchCause: RuleChangeCause | null = null;
      if (
        fetchVariables?.territoryGroupId !== initializedFor.current?.territoryGroupId ||
        fetchVariables?.source !== initializedFor?.current.source
      ) {
        automaticFetchCause = RuleChangeCauses.InitialLoad;
      } else if (
        fetchVariables?.quotaComponentId !== initializedFor.current?.quotaComponentId ||
        fetchVariables?.measureId !== initializedFor.current?.measureId
      ) {
        automaticFetchCause = RuleChangeCauses.MeasureChange;
      }
      if (!automaticFetchCause) return;

      postMessage({
        primaryHierarchy,
        cause: automaticFetchCause,
        type: 'rules-change',
        rules: data.getTerritoryRules?.territoryRules ?? [],
        chosenCustomHierarchy,
        isUndoEnabled
      });
      initializedFor.current = fetchVariables;
    },
    onError() {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('TERRITORY_GRID_ERROR'), 'danger');
    }
  });

  useEffect(() => {
    if (!initializedFor.current) return;
    postMessage({
      cause: RuleChangeCauses.LimitationChange,
      primaryHierarchy,
      type: 'rules-change',
      isUndoEnabled,
      rules: getRulesForMap.data?.getTerritoryRules?.territoryRules ?? [],
      chosenCustomHierarchy
    });
  }, [chosenCustomHierarchy, isUndoEnabled, primaryHierarchy]);

  const isMountedRef = useIsMountedRef();

  const refetch = useCallback(
    async (cause: RuleChangeCause) => {
      const result = await getRulesForMap.refetch();
      if (!isMountedRef.current) return;
      postMessage({
        cause,
        type: 'rules-change',
        rules: result.data.getTerritoryRules?.territoryRules ?? [],
        chosenCustomHierarchy,
        isUndoEnabled,
        primaryHierarchy
      });
    },
    [chosenCustomHierarchy, isUndoEnabled]
  );

  useEffect(() => {
    if (getRulesForMap.loading || !fetchVariables) {
      setIsLoading(true);
    }
  }, [getRulesForMap.loading, fetchVariables]);

  useEffect(() => {
    if (!isWorkerLoading && fetchVariables) {
      setIsLoading(false);
    }
  }, [isWorkerLoading]);

  return {
    refetch,
    loading: isLoading
  };
};

const useIsMountedRef = () => {
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return isMountedRef;
};
