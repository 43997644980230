// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uS\\+OM {\n  text-align: right;\n  width: 100%;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NotApplicableCellRenderer": "uS+OM"
};
export default ___CSS_LOADER_EXPORT___;
