// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BUZ7F {\n    padding-bottom: 36px;\n    margin-bottom: 40px;\n    border-bottom: 1px solid #d8d8d8;\n  }\n\n    /* Undoing Bootstrap stlyes */\n\n    .BUZ7F .bp3-radio {\n      font-weight: normal;\n      color: rgb(var(--color-gray-2));\n    }\n  .dlxLh .bp3-spinner {\n      display: inline-block;\n      margin-left: 6px;\n    }\n  .pdMZ3 .bp3-dialog {\n      width: 610px;\n      padding: 16px;\n      border-radius: 12px;\n      box-sizing: content-box;\n    }\n  .pdMZ3 .bp3-dialog-header {\n      min-height: unset;\n      padding: 0 0 26px;\n    }\n  .pdMZ3 .bp3-dialog-header .bp3-heading {\n      font-weight: 600;\n      font-size: var(--ml);\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PinSetDialog_radioContainer": "BUZ7F",
	"PinSetDialog_radioLabel": "dlxLh",
	"PinSetDialog_dialog": "pdMZ3"
};
export default ___CSS_LOADER_EXPORT___;
