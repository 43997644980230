import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { Checkbox, Classes, InputGroup } from '@blueprintjs/core';
import { ChevronDown, Close, Search } from '@carbon/icons-react';
import { useCombobox, useMultipleSelection } from 'downshift';
import { FieldProps } from 'formik';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import TextButton from 'components/Buttons/TextButton/TextButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import { KeyValue, MultiSelectBubbleColor } from 'components/models';

import useCombinedRefs from 'app/hooks/useCombinedRefs';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MultiSelectMenu.module.pcss';

const b = block(style);

interface MultiSelectMenuProps {
  form?: FieldProps['form'];
  meta?: FieldProps['meta'];
  field: { name: string; value: KeyValue<string>[] };
  theme?: 'default' | 'secondary';
  items: KeyValue<string>[];
  disabled?: boolean;
  minimal?: boolean;
  small?: boolean;
  placeHolderText?: string;
  allowFlip?: boolean;
  bubbleColor?: MultiSelectBubbleColor;
  bubbleIcon?: ReactElement;
  disabledItems?: string[];
  onSelectOpen?: () => void;
  onChange?: (items: KeyValue<string>[]) => void;
  loadingComplete: boolean;
  errorMessage?: string;
  showErrors?: boolean;
  usePortal?: boolean;
  portalRef?: { current: Element };
  showFilter?: boolean;
  tooltipEnabled?: boolean;
  dropdownTooltipText?: string;
}

/* Separation of concerns between Formik, Downshift's (useMultipleSelection, useCombobox), Blueprint and react-popper:
// 1. Formik controls initial values and current values
// 2. Downshift's useSelect controls accessibility and interactions, and its onSelectedItemChange prop
// sets Formik state via form.setFieldValue */
// 3. Blueprint provides styling via Button, menu and menu item classes
// 4. react-popper provides positioning (because Blueprint popover conflicts with Downshift)

const MultiSelectMenu: React.FC<MultiSelectMenuProps> = ({
  field: { name, value },
  form,
  theme,
  items,
  disabled,
  minimal,
  small,
  placeHolderText,
  dropdownTooltipText,
  bubbleColor,
  bubbleIcon,
  disabledItems = [],
  allowFlip = true,
  onSelectOpen,
  onChange,
  portalRef,
  loadingComplete = false,
  errorMessage,
  showErrors = true,
  usePortal = false,
  showFilter = false,
  tooltipEnabled = true
}: MultiSelectMenuProps) => {
  const [inputItems, setInputItems] = useState(items ?? []);

  const validationMessage = form?.errors?.[name] || form?.status?.[name];
  const isError = !!(form?.touched?.[name] && validationMessage);

  const { removeSelectedItem, addSelectedItem, selectedItems, setSelectedItems, getDropdownProps } =
    useMultipleSelection({
      initialSelectedItems: value ?? [],
      onSelectedItemsChange: ({ selectedItems }) => {
        onChange?.(selectedItems);
      }
    });

  useEffect(() => {
    form?.setFieldValue(name, selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (items) {
      setInputItems(items);
    }
  }, [items]);

  useEffect(() => {
    // Updates the checked selection if the field value is updated outside the menu
    if (value && value?.length !== selectedItems?.length) {
      setSelectedItems(value);
    }
  }, [value]);

  const isItemDisabled = (itemName: string): boolean => {
    return disabledItems.includes(itemName);
  };

  const { isOpen, selectedItem, getToggleButtonProps, getItemProps, getMenuProps, getInputProps, getComboboxProps } =
    useCombobox({
      selectedItem: null,
      items: inputItems,
      onSelectedItemChange: ({ selectedItem }) => {
        if (!selectedItem || isItemDisabled(selectedItem['key'])) {
          return;
        }
        // what this section does:
        // it checks to see if the item is already selected (index >= 0), then it deselects the item (in order to make the array immutable
        // upon removal, using spread operation)
        // it checks to see if the item is not selected then it add the item to the selected items
        const index = selectedItems.findIndex((item) => item['value'] === selectedItem.value);

        if (index > 0) {
          setSelectedItems([...selectedItems.slice(0, index), ...selectedItems.slice(index + 1)]);
        } else if (index === 0) {
          setSelectedItems([...selectedItems.slice(1)]);
        } else {
          setSelectedItems([...selectedItems, selectedItem]);
        }
      },
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep menu open after selection.
              highlightedIndex: state.highlightedIndex,
              inputValue: '' // don't add the item string as input value at selection.
            };
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              inputValue: '' // don't add the item string as input value at selection.
            };
          default:
            return changes;
        }
      },
      onInputValueChange: ({ inputValue }) => {
        setInputItems(items.filter((item) => item.value.toLowerCase().startsWith(inputValue.toLowerCase())));
        addSelectedItem(selectedItem);
      }
    });
  const defaultButtonText = placeHolderText ? placeHolderText : formatMessage('SELECT');

  const referenceElement = useRef<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(null);

  const { attributes, forceUpdate } = usePopper(referenceElement.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        enabled: allowFlip
      }
    ]
  });
  const [reallyOpen, setReallyOpen] = useState(false);
  useEffect(() => {
    setReallyOpen(isOpen);
    if (forceUpdate) {
      forceUpdate();
    }
  }, [isOpen]);
  const { ref: buttonRef, ...buttonProps } = getToggleButtonProps();

  const combinedButtonRef = useCombinedRefs(buttonRef, referenceElement);
  const { ref: menuRef, ...menuProps } = getMenuProps();
  const combinedMenuRef = useCombinedRefs(menuRef, setPopperElement);

  const wrapWithPortal = (node: React.ReactNode) =>
    usePortal ? createPortal(node, portalRef?.current ?? document.body) : node;

  return (
    <div
      className={b({ default: theme === 'default', secondary: theme === 'secondary' })}
      data-testid="multi-select-menu"
    >
      <div className={b('select-box')} {...getComboboxProps()}>
        <MessageTooltip
          className={b('select-box-popover')}
          content={!!dropdownTooltipText ? dropdownTooltipText : ''}
          placement={'top'}
          usePortal={false}
          target={
            <TextButton
              refProps={combinedButtonRef}
              {...buttonProps}
              text={selectedItem ? selectedItem.key : defaultButtonText}
              type="button"
              rightIcon={<ChevronDown size={20} />}
              disabled={disabled}
              minimal={minimal}
              small={small}
              testId={`multi-select-menu-button-${name}`}
              onClick={(e) => {
                e.stopPropagation();
                buttonProps.onClick(e);
                if (onSelectOpen) onSelectOpen();
                form?.setFieldTouched(name);
              }}
            />
          }
        />
        <input type="hidden" {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))} />
        {wrapWithPortal(
          <ul
            ref={combinedMenuRef}
            className={b('select-menu', { portalMenu: usePortal })}
            {...menuProps}
            {...getMenuProps()}
            style={{
              visibility: reallyOpen ? 'visible' : 'hidden'
            }}
            {...attributes.popper}
            data-testid={'multi-select-menu-content'}
          >
            {showFilter && <SimpleFilter onItemsChange={(updatedItems) => setInputItems(updatedItems)} items={items} />}
            {reallyOpen && !loadingComplete && (
              <div className={'bp3-skeleton'} data-testid="multi-select-loading-indicator" />
            )}
            {reallyOpen && loadingComplete && inputItems?.length === 0 && (
              <div className={b('validationMessage')}>{errorMessage ?? formatMessage('NO_DATA')}</div>
            )}
            {reallyOpen &&
              loadingComplete &&
              inputItems?.length > 0 &&
              inputItems.map((item, index) => (
                <MessageTooltip
                  content={item.key}
                  placement={'top'}
                  key={`selected-item-${index}`}
                  className={[Classes.MENU_ITEM, 'bp3-dropdown-message-tooltip'].join(' ')}
                  disabled={!tooltipEnabled}
                  target={
                    <li
                      className={'bp3-dropdown-list-item'}
                      key={`${item.value}${index}`}
                      {...getItemProps({
                        item,
                        index
                      })}
                      data-testid={`multi-select-menu-item-${item.value}`}
                    >
                      <Checkbox
                        checked={!!selectedItems.find((selectedItem) => selectedItem['value'] === item.value)}
                        onClick={(e) => e.stopPropagation()} // This make clicking on checkbox trigger onSelectedItemChange
                        labelElement={<span className={Classes.MENU_ITEM_LABEL}>{item.key}</span>}
                        data-testid={`list-item-checkbox-${item.value}`}
                        disabled={isItemDisabled(item.key)}
                      />
                    </li>
                  }
                />
              ))}
          </ul>
        )}
        {showErrors ? (
          <div className={b('validationMessage')} data-testid={`multi-select-menu-validation-message-${name}`}>
            {isError ? validationMessage : ''}
          </div>
        ) : null}
      </div>
      {bubbleColor && bubbleIcon && (
        <div className={b('bubble-container')}>
          {selectedItems.map((selectedItem, index) => (
            <MessageTooltip
              content={
                isItemDisabled(selectedItem['key'])
                  ? formatMessage('DISALLOW_REMOVE_QUOTA_COMPONENT')
                  : selectedItem['key']
              }
              placement={'top'}
              key={`selected-item-${index}`}
              target={
                <span
                  className={b('bubble', { disabled: isItemDisabled(selectedItem['key']) })}
                  style={{ backgroundColor: bubbleColor }}
                >
                  <span className={b('bubble-item')}>
                    {bubbleIcon}
                    <span className={b('bubble-item-text')} data-testid={'bubble-item-text'}>
                      {selectedItem['key']}
                    </span>
                  </span>

                  <span
                    className={b('bubble-remove', { disabled: isItemDisabled(selectedItem['key']) })}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isItemDisabled(selectedItem['key'])) {
                        removeSelectedItem(selectedItem);
                        form?.setFieldTouched(name);
                      }
                    }}
                    data-testid={`${name}-remove-button-${selectedItem['key']}${
                      isItemDisabled(selectedItem['key']) ? '-disabled' : ''
                    }`}
                  >
                    <Close aria-label={formatMessage('REMOVE')} className={b('bubble-remove-icon')} />
                  </span>
                </span>
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectMenu;

interface SimpleFilterProps {
  items: KeyValue<string>[];
  onItemsChange: (items: KeyValue<string>[]) => void;
}

const passesFilter = (query: string, content: string | number) =>
  typeof content === 'string' && content.toLowerCase().includes(query);

const SimpleFilter: React.FC<SimpleFilterProps> = ({ onItemsChange, items }: SimpleFilterProps) => {
  const [rawQuery, setRawQuery] = useState('');

  useEffect(() => {
    const query = rawQuery.trim().toLocaleLowerCase();
    if (!query) {
      onItemsChange(items);
      return;
    }

    onItemsChange(items?.filter((item) => passesFilter(query, item.key) || passesFilter(query, item.value)));
  }, [rawQuery, items]);

  const handleChange = (e) => setRawQuery(e.currentTarget.value);

  return (
    <div className={b('searchInputWrapper')}>
      <InputGroup
        placeholder={formatMessage('SEARCH')}
        onChange={handleChange}
        value={rawQuery}
        data-testid={'search-field'}
        leftElement={
          <div className={b('searchIconContainer')}>
            <Search />
          </div>
        }
      />
    </div>
  );
};
