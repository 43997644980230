import React, { useState } from 'react';

import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { ErrorOutline } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { ExternalRoutePaths } from 'app/containers/App/Router/routePaths';

import { INVITATION_ID } from 'app/global/variables';

import { Auth0ScreenTypes, UserAccountType } from 'app/models';

import block from 'utils/bem-css-modules';
import { config } from 'utils/config';
import { wrapError } from 'utils/errors/ErrorUtil';
import { getSearchParams } from 'utils/helpers/searchParams';
import { formatMessage } from 'utils/messages/utils';

import style from './Login.module.pcss';
import varicentLogo from './Varicent-logo.png';

const b = block(style);

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const history = useHistory();
  const username = getSearchParams(history.location.search, 'username');
  const domain = getSearchParams(history.location.search, 'domain');
  const emailAddress = username && domain ? `${username}@${domain}` : null;
  const invitationId = getSearchParams(history.location.search, INVITATION_ID);
  const regType = getSearchParams(history.location.search, 'regType');
  const userAccountType = getSearchParams(history.location.search, 'userAccountType');
  const [authError, setAuthError] = useState<string>('');

  const handleSubmit = async (_values, { setSubmitting }) => {
    try {
      const invitation = getSearchParams(history.location.search, 'invitation');
      const organization = getSearchParams(history.location.search, 'organization');
      const basicLogin = getSearchParams(history.location.search, 'basic');
      localStorage.setItem('orgId', organization);
      if (invitationId) {
        localStorage.setItem(INVITATION_ID, invitationId);
        if (regType) {
          localStorage.setItem('regType', regType);
        }
      }

      const loginOptions: RedirectLoginOptions = {};
      if (invitation && organization) {
        loginOptions.invitation = invitation;
        loginOptions.organization = organization;
        loginOptions.screen_hint =
          userAccountType === UserAccountType.new ? Auth0ScreenTypes.signup : Auth0ScreenTypes.login;
      }
      if (emailAddress) {
        loginOptions.login_hint = emailAddress;
      }
      if (!!basicLogin) {
        loginOptions.connection = config.AUTH0_CONNECTION_NAME;
      }
      // check for a from state from PrivateRoute redirect
      if (history?.location?.state?.['from']) {
        const { pathname, search } = history.location.state['from'];
        window.history.replaceState({}, document.title); // clear the history state
        loginOptions.appState = { returnTo: { pathname, search } };
      }

      loginWithRedirect(loginOptions);
    } catch (err) {
      console.log('err', err);
      const error = wrapError(err);
      setAuthError(error.message);
      setSubmitting(false);
    }
  };

  return (
    <div className={b()}>
      <div className={b('content')}>
        <div className={b('formWrapper')}>
          <div className={b('spacer')}> </div>
          <div className={b('headerWrapper')}>
            <img className={b('varicentLogo')} alt="" src={varicentLogo} />
            <div className={b('header')}>
              <HTMLHeading tagLevel="h1" styleLevel="h2" text={formatMessage('VARICENT_SP')} />
            </div>
          </div>

          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            onSubmit={handleSubmit}
          >
            {({ isValid, isSubmitting }) => (
              <div className={b('form')}>
                <div className={b('authSpacer')}>
                  {authError && (
                    <div className={b('authMessage', { error: true })}>
                      <span className={b('errorIcon')}>
                        <ErrorOutline />
                      </span>
                      {authError}
                    </div>
                  )}
                </div>
                <Form>
                  <TextButton
                    text={formatMessage('SIGN_IN')}
                    type="submit"
                    intent="primary"
                    disabled={!isValid}
                    loading={isSubmitting}
                    testId={'signIn-button'}
                  />
                </Form>
              </div>
            )}
          </Formik>
          <div className={b('footer')}>
            <br />
            <a
              className={b('link')}
              href={ExternalRoutePaths.PRIVACY_STATEMENT}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage('PRIVACY_POLICY')}
            </a>
          </div>
        </div>
      </div>
      <div className={b('graphic')}> </div>
    </div>
  );
};

export default Login;
