import React, { FC, useRef } from 'react';

import { useIsVisible } from 'app/hooks/useIsVisible';

type LazyContainerProps = JSX.IntrinsicElements['div'];

const LazyContainer: FC<LazyContainerProps> = ({ children, ...rest }) => {
  const ref = useRef<HTMLDivElement>(undefined!);
  const isVisible = useIsVisible(ref);
  return (
    <div {...rest} ref={ref}>
      {isVisible && children}
    </div>
  );
};

export default LazyContainer;
