import { useState } from 'react';

import { TargetRuleComponent } from 'app/graphql/generated/graphqlApolloTypes';
import { useReassignRuleHierarchies } from 'app/graphql/mutations/reassignRuleHierarchies';

import useShowToast from 'app/hooks/useShowToast';

import { AccountRuleRow } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

import { useDuplicateOverrideSplit } from './useDuplicateOverrideSplit';

interface Input {
  onSubmit: () => void;
  currentTerritoryGroupTypeId: number;
}

export const useAccountReassign = ({
  onSubmit,
  currentTerritoryGroupTypeId
}: Input): {
  toggleDynamicReassignPreference: () => void;
  reassignHierarchiesDynamically: (targetRuleId: number, selectedAccountRules: AccountRuleRow[]) => Promise<void>;
  shouldAlwaysDynamicReassign: boolean;
  didSimpleReassignFail: boolean;
} => {
  const showToast = useShowToast();
  const shouldIncludeRuleIdsFilter = useDuplicateOverrideSplit();

  const [shouldAlwaysDynamicReassign, setShouldAlwaysDynamicReassign] = useState(
    () => !!JSON.parse(sessionStorage.getItem('shouldAlwaysDynamicReassign'))
  );

  const [reassignRuleHierarchies, { error }] = useReassignRuleHierarchies({
    onCompleted: () => {
      showToast(formatMessage('REASSIGN_ACCOUNT_SUCCESS_TOAST'), 'success');
      onSubmit();
    }
  });
  const didSimpleReassignFail = error?.knownError?.errorCode === 'CANNOT_SIMPLY_REASSIGN_DIMENSIONS';

  const reassignHierarchiesDynamically = async (targetRuleId: number, selectedAccountRules: AccountRuleRow[]) => {
    const sourceRuleIds = [];
    const sourceHierarchyIds = [];
    selectedAccountRules.forEach(({ ruleId, accountId }: AccountRuleRow) => {
      sourceRuleIds.push(ruleId);
      sourceHierarchyIds.push(accountId);
    });

    const targetRuleComponent =
      didSimpleReassignFail || shouldAlwaysDynamicReassign
        ? TargetRuleComponent.SimpleDimensionOrModifier
        : TargetRuleComponent.SimpleDimensionOrThrow;
    const ruleIdsFilter = shouldIncludeRuleIdsFilter ? [...new Set([targetRuleId, ...sourceRuleIds])] : null;

    await reassignRuleHierarchies({
      variables: {
        input: {
          scopingTerritoryGroupId: currentTerritoryGroupTypeId,
          sourceHierarchyIds,
          targetRuleId,
          targetRuleComponent,
          ruleIdsFilter
        }
      }
    });
  };

  const toggleDynamicReassignPreference = () => {
    sessionStorage.setItem('shouldAlwaysDynamicReassign', JSON.stringify(!shouldAlwaysDynamicReassign));
    setShouldAlwaysDynamicReassign(!shouldAlwaysDynamicReassign);
  };

  return {
    toggleDynamicReassignPreference,
    reassignHierarchiesDynamically,
    shouldAlwaysDynamicReassign,
    didSimpleReassignFail
  };
};
