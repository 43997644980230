// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MQEGR {\n    display: flex;\n    align-self: center;\n    align-items: center;\n    justify-content: center;\n    border-radius: 4px;\n    padding: 8px;\n    font-weight: 600;\n    min-width: 30px;\n    margin: 0 8px;\n    height: 24px;\n  }\n\n    .gtBtl {\n      background-color: #e4eed9;\n      color: rgb(56, 158, 13);\n      z-index: 1;\n    }\n\n    .iQsiY {\n      background-color: #e6dff7;\n      color: rgb(114, 46, 209);\n      z-index: 1;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Combinator_combinatorContainer": "MQEGR",
	"Combinator_combinatorContainer__and": "gtBtl",
	"Combinator_combinatorContainer__or": "iQsiY"
};
export default ___CSS_LOADER_EXPORT___;
