import React, { FC, useMemo } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import {
  PermissionsContextRedistributorContextValues,
  PermissionsContextRedistributorProvider
} from './permissionsContextRedistributorProvider';

export const MapPermissionsLoader: FC = ({ children }) => {
  const { selectedBattleCardId, selectedTerritoryGroupId } = useMapContextRedistributor();
  const { userRole, userMembershipSpecs } = useUser();

  const selectedTenantId = null;
  const battleCardLookupMap = {};
  const territoryGroups = [];

  const contextValue = useMemo(
    (): PermissionsContextRedistributorContextValues => ({
      selectedTenantId,
      selectedBattleCardId,
      battleCardLookupMap,
      selectedTerritoryGroupId: `${selectedTerritoryGroupId}`,
      territoryGroups,
      userRole,
      userMembershipSpecs
    }),
    [
      selectedBattleCardId,
      battleCardLookupMap,
      selectedTerritoryGroupId,
      territoryGroups,
      userRole,
      userMembershipSpecs
    ]
  );

  return (
    <PermissionsContextRedistributorProvider value={contextValue}> {children}</PermissionsContextRedistributorProvider>
  );
};
