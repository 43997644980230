// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vTlRq {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n  }\n  .la2O4 {\n    display: flex;\n  }\n  .LierK {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    margin: var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryOptimizationHeader_header": "vTlRq",
	"TerritoryOptimizationHeader_titleContainer": "la2O4",
	"TerritoryOptimizationHeader_optimizationResultsTitleContainer": "LierK"
};
export default ___CSS_LOADER_EXPORT___;
