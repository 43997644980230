import { useEffect, useMemo } from 'react';

import { useEmbeddedMapSlugs } from 'app/hooks/EmbeddedMap/useEmbeddedMapSlugs';

import { TokenState } from 'app/models';

import { useExchangeToken } from './useExchangeToken';
import { useSendLoadStatus } from './useSendLoadStatus';
import { useTemporaryAuthToken } from './useTemporaryAuthToken';

export const useEmbeddedToken = (): TokenState => {
  const { tenantSlug } = useEmbeddedMapSlugs();
  const [{ token: tempToken, isLoading: isLoadingTempToken }, refetchTempToken] = useTemporaryAuthToken();
  const {
    token: finalToken,
    isLoading: isLoadingFinalToken,
    expiresAt
  } = useExchangeToken({
    tempToken,
    tenantSlug
  });
  const isLoading = isLoadingTempToken || isLoadingFinalToken;
  const tokenState = useMemo(() => ({ token: finalToken, isLoading }), [finalToken, isLoading]);

  useEffect(() => {
    if (!expiresAt) return noCleanup;

    const delay = determineExpiryDelay(expiresAt);
    if (!delay || delay < 0) {
      console.warn(`Ignoring negative expiry delay`);
      return noCleanup;
    }

    const timeoutId = setTimeout(() => refetchTempToken(), delay);
    return () => clearTimeout(timeoutId);
  }, [expiresAt]);

  useSendLoadStatus(tokenState);
  return tokenState;
};

const determineExpiryDelay = (expiresAtMillis: number, buffer = 10_000) => expiresAtMillis - Date.now() - buffer;

const noCleanup = () => {
  // Used for effects that conditionally have cleanup
};
