import React, { useState } from 'react';

import { AddAlt } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';

import HierarchySearchDialog from 'app/components/HierarchySearchDialog/HierarchySearchDialog';
import SearchableTagInput from 'app/components/SearchableTagInput/SearchableTagInput';
import TerritorySearchDialog from 'app/components/TerritorySearchDialog/TerritorySearchDialog';

import { useScope } from 'app/contexts/scopeProvider';

import {
  HierarchyItem,
  HierarchyType,
  HierarchyTypeName,
  RulePartType,
  TerritoryRuleHierarchyWithInfo,
  HierarchyItemDeltaMap
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ExpandedHierarchyRule.module.pcss';

const b = block(style);

export const getHierarchyItemStrings = (hierarchyItems: HierarchyItem[]): string[] => {
  return hierarchyItems?.map((inclusionOrExclusion) => {
    return `${inclusionOrExclusion?.name} (${inclusionOrExclusion?.key ?? inclusionOrExclusion.territoryId})`;
  });
};

export const handleDelta = (
  newInclusionsExclusions: HierarchyItem[],
  delta: HierarchyItemDeltaMap
): HierarchyItem[] => {
  let newInclusionsExclusionsWithDelta = [...newInclusionsExclusions];
  Object.keys(delta).forEach((key) => {
    if (delta[key].isSelected) {
      newInclusionsExclusionsWithDelta.push(delta[key].hierarchyItem);
    } else {
      newInclusionsExclusionsWithDelta = newInclusionsExclusionsWithDelta.filter((item: HierarchyItem) => {
        return item.hierarchyId !== delta[key].hierarchyItem.hierarchyId;
      });
    }
  });
  return newInclusionsExclusionsWithDelta;
};

const convertContainsToInheritsFrom = (contains): TerritoryRuleHierarchyWithInfo[] => {
  const inheritsFrom = contains.map((territoryContains) => {
    return {
      ruleId: territoryContains.ruleId,
      territoryId: territoryContains.territoryId || territoryContains.hierarchyId,
      territoryName: territoryContains.name,
      __typename: 'TerritoryRuleHierarchyWithInfo'
    };
  });

  return inheritsFrom;
};

interface ExpandedHierarchyRuleProps {
  rootHierarchyName: string;
  rootHierarchyId: number;
  hierarchyType: HierarchyType;
  rulePartType: RulePartType;
  inclusions: HierarchyItem[];
  setInclusionsExclusions: (newInclusionsExclusions) => void;
  exclusions: HierarchyItem[];
  onSave: () => void;
  onCancel: () => void;
  setNewInheritsFrom?: (inheritsFrom: TerritoryRuleHierarchyWithInfo[]) => void;
}

const ExpandedHierarchyRule: React.FC<ExpandedHierarchyRuleProps> = ({
  rootHierarchyName,
  rootHierarchyId,
  hierarchyType,
  rulePartType,
  inclusions = [],
  exclusions = [],
  setInclusionsExclusions,
  setNewInheritsFrom,
  onSave,
  onCancel
}: ExpandedHierarchyRuleProps) => {
  const [newInclusions, setNewInclusions] = useState([...inclusions]);
  const [newExclusions, setNewExclusions] = useState([...exclusions]);

  const handleSave = () => {
    if (rootHierarchyName === HierarchyTypeName.OVERLAY_TERRITORIES) {
      setNewInheritsFrom(convertContainsToInheritsFrom(newInclusions));
    } else {
      setInclusionsExclusions({ inclusions: newInclusions, exclusions: newExclusions });
    }

    if (onSave) {
      onSave();
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleInclusionsChange = (values: HierarchyItem[]) => {
    setNewInclusions([...values]);
  };

  const handleExclusionsChange = (values: HierarchyItem[]) => {
    setNewExclusions([...values]);
  };

  const handleInclusionsDelta = (deltaMap: HierarchyItemDeltaMap) => {
    const newInclusionsAfterDelta = handleDelta([...newInclusions], deltaMap);
    setNewInclusions([...newInclusionsAfterDelta]);
  };

  const handleExclusionsDelta = (deltaMap: HierarchyItemDeltaMap) => {
    const newExclusionsAfterDelta = handleDelta([...newExclusions], deltaMap);
    setNewExclusions([...newExclusionsAfterDelta]);
  };

  // must have inclusion or exclusion to save
  const shouldDisableSave = !newInclusions?.length && !newExclusions?.length;
  const isRuleTypeTerritory = rootHierarchyName === HierarchyTypeName.OVERLAY_TERRITORIES;
  const shouldShowExclusions = !isRuleTypeTerritory && rulePartType === RulePartType.BASE;

  const [showTreeSelectDialog, setShowTreeSelectDialog] = useState<boolean>(false);
  const { selectedPlanningCycle } = useScope();
  const [treeSelectType, setTreeSelectType] = useState<RulePartType>(null);
  const [showTerritorySearchDialog, setShowTerritorySearchDialog] = useState<boolean>(false);

  const standardInclusionsOnClick = () => {
    setTreeSelectType(RulePartType.INCLUSION);
    setShowTreeSelectDialog(true);
  };

  const overlayInclusionsOnClick = () => setShowTerritorySearchDialog(true);

  return (
    <div className={b()} data-testid="expanded-hierarchy-rule">
      <span className={b('hierarchyTitle')} data-testid="expanded-hierarchy-rule-title">
        {rootHierarchyName}
      </span>
      <span className={b('inclExclTitle')} data-testid="expanded-hierarchy-rule-inclusions">
        {formatMessage('INCLUSIONS')}
        <IconButton
          className={b('multiSelectButton')}
          type={'button'}
          title={formatMessage('ADD_MORE')}
          testId={isRuleTypeTerritory ? 'territory-add-button' : 'inclusions-multi-select-button'}
          icon={<AddAlt />}
          onClick={isRuleTypeTerritory ? overlayInclusionsOnClick : standardInclusionsOnClick}
        />
      </span>
      <SearchableTagInput
        items={newInclusions}
        valueFormatter={getHierarchyItemStrings}
        rootHierarchyId={rootHierarchyId}
        planningCycleId={selectedPlanningCycle?.id}
        hierarchyType={hierarchyType}
        onChange={handleInclusionsChange}
        data-testid="expanded-hierarchy-rule-inclusions-input"
        isRuleTypeTerritory={isRuleTypeTerritory}
        isOverrideRule={rulePartType === RulePartType.OVERRIDE}
      />
      {shouldShowExclusions && (
        <>
          <span className={b('inclExclTitle')} data-testid="expanded-hierarchy-rule-exclusions">
            {formatMessage('EXCLUSIONS')}
            <IconButton
              className={b('multiSelectButton')}
              type={'button'}
              title={formatMessage('ADD_MORE')}
              testId={'exclusions-multi-select-button'}
              icon={<AddAlt />}
              onClick={() => {
                setTreeSelectType(RulePartType.EXCLUSION);
                setShowTreeSelectDialog(true);
              }}
            />
          </span>
          <SearchableTagInput
            items={newExclusions}
            valueFormatter={getHierarchyItemStrings}
            rootHierarchyId={rootHierarchyId}
            planningCycleId={selectedPlanningCycle?.id}
            hierarchyType={hierarchyType}
            onChange={handleExclusionsChange}
            data-testid="expanded-hierarchy-rule-exclusions-input"
            isRuleTypeTerritory={isRuleTypeTerritory}
            isOverrideRule={false}
          />
        </>
      )}
      <div className={b('buttonContainer')}>
        <TextButton
          text={formatMessage('CANCEL')}
          onClick={handleCancel}
          minimal
          type="button"
          testId="expanded-hierarchy-rule-cancel-btn"
        />
        <TextButton
          text={formatMessage('SAVE')}
          onClick={handleSave}
          disabled={shouldDisableSave}
          type="button"
          testId="expanded-hierarchy-rule-save-btn"
          className={b('saveBtn')}
        />
      </div>
      <HierarchySearchDialog
        rootHierarchyId={rootHierarchyId}
        hierarchyType={hierarchyType}
        planningCycleId={selectedPlanningCycle?.id}
        rootHierarchyName={rootHierarchyName}
        isOpen={showTreeSelectDialog}
        setShowTreeSelectDialog={setShowTreeSelectDialog}
        testId={'multi-select-dialog'}
        rulePartType={rulePartType}
        onSelectionChange={
          treeSelectType?.valueOf() === RulePartType.INCLUSION ? handleInclusionsDelta : handleExclusionsDelta
        }
        initialSelection={treeSelectType?.valueOf() === RulePartType.INCLUSION ? newInclusions : newExclusions}
      />
      <TerritorySearchDialog
        setShowTreeSelectDialog={setShowTerritorySearchDialog}
        isOpen={showTerritorySearchDialog}
        selectedNodes={newInclusions}
        onSelectionChange={handleInclusionsDelta}
      />
    </div>
  );
};

export default ExpandedHierarchyRule;
