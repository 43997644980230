import React, { FC, useEffect } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';

import AuthSpinner from 'components/AuthSpinner/AuthSpinner';

import { RoutePaths } from 'app/containers/App/Router/routePaths';

import { EmbeddedAuthContextProvider, useSaveEmbeddedAuthParams } from 'app/contexts/EmbeddedAuthProvider';

import {
  createLocationFromSavedAppState,
  createRestrictedOauthQueryParams,
  getAppStateFromQuery
} from 'utils/helpers/embeddedAuthHelpers';

export const EmbeddedAuthRouter: FC = ({ children }) => (
  <EmbeddedAuthContextProvider>
    <Switch>
      <Route path={RoutePaths.EMBEDDED_AUTH} exact component={EmbeddedAuthExtractor} />
      <Route render={() => children} />
    </Switch>
  </EmbeddedAuthContextProvider>
);

const EmbeddedAuthExtractor: FC = () => {
  const history = useHistory();
  const saveAuthParams = useSaveEmbeddedAuthParams();
  useEffect(() => {
    const allParams = new URLSearchParams(history.location.search);
    const authParams = createRestrictedOauthQueryParams(allParams);
    const appState = getAppStateFromQuery(authParams);

    if (appState) {
      saveAuthParams(authParams);
      history.push(createLocationFromSavedAppState(appState));
    } else {
      history.push('/');
    }
  }, []);

  return <AuthSpinner />;
};
export default EmbeddedAuthRouter;
