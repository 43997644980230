import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { FormatNumberOptions } from 'react-intl';

import EditableCurrencyCellRenderer from 'app/components/AdvancedGrid/CellRenderers/EditableCurrencyCellRenderer/EditableCurrencyCellRenderer';

import { useGrid } from 'app/contexts/gridProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { CurrencyCode, MeasureFormatType, UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatNumber } from 'utils/messages/utils';

import style from './QuotaAdjustmentCellRenderer.module.pcss';

const b = block(style);

export const enum backgroundColours {
  BLUE = '#F0F5FF',
  GREY = '#e8e8e8'
}

interface QuotaAdjustmentCellRendererProps extends ICellRendererParams {
  isEditable: boolean;
  value: number;
  currency: CurrencyCode;
  type: MeasureFormatType;
  header: string;
  disabledCellInputs?: Record<string, string>[];
  errorMessage?: string;
  errorItems?: Record<string, string>[];
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

const QuotaAdjustmentCellRenderer: React.FC<QuotaAdjustmentCellRendererProps> = (props) => {
  const {
    isEditable,
    onChange,
    onBlur,
    value,
    data,
    header,
    errorMessage,
    errorItems,
    disabledCellInputs,
    currency,
    type
  } = props;
  const { userRole } = useUser();
  const { isQuotaAdjustmentEditable } = useGrid();
  let backgroundColor;
  // eslint-disable-next-line no-restricted-syntax
  if (userRole === UserRoleType.CONTRIBUTOR) {
    backgroundColor = isQuotaAdjustmentEditable ? backgroundColours.BLUE : backgroundColours.GREY;
  }

  const errorItem = errorItems?.find((item) => item[props?.node?.id]);
  const disabledItem = disabledCellInputs?.find((item) => item[props?.node?.id]);
  // isEditable is determined by the sheet, shouldBeEditable, isEditable is based on the column and drill down view
  // In case drill down view for quota breakdown does not exist:
  // For example, with PC has periodicity = Quarterly and duration 6 months
  // after expand the quota adjustment column, only Q1 and Q2 will have shouldBeEditable and isEditable set to true.
  // H1 and quota adjustment will have shouldBeEditable set to false
  // In case drill down view for quota breakdown do exist:
  // Quota adjustment column for all periodic columns will be uneditable and isEditable set to false
  // this is to support editing within drill in view
  const isColumnEditable = isEditable && data[header]?.shouldBeEditable;
  const formattedValue = (): FormatNumberOptions => {
    if (type === MeasureFormatType.CURRENCY) {
      return {
        style: 'currency',
        currency
      };
    } else if (type === MeasureFormatType.PERCENTAGE) {
      return {
        style: 'percent',
        maximumFractionDigits: 2
      };
    }

    return {};
  };

  const valueDisplayed = () => {
    const curValue = value ?? 0;
    if (type === MeasureFormatType.PERCENTAGE && curValue !== 0) {
      return curValue / 100;
    }
    return curValue;
  };

  return (
    <div className={b('')} style={{ backgroundColor }} data-testid="quota-cell-renderer">
      {isQuotaAdjustmentEditable && isColumnEditable ? (
        <EditableCurrencyCellRenderer
          data-testid="editable-currency-renderer"
          onChange={onChange}
          onBlur={onBlur}
          value={value.toString()}
          currency={currency}
          type={type}
          inputColor={backgroundColor}
          hasErrors={errorItem && errorItem[props?.node?.id]?.includes(header)}
          disabled={disabledItem && disabledItem[props?.node?.id]?.includes(header)}
          errorMessage={errorMessage}
          allowNegativeValue
        />
      ) : (
        <div className={`${b('readOnly')}`} data-testid="quota-cell-renderer-read-only">
          <div className={b('readOnlyText')}>{formatNumber(valueDisplayed(), formattedValue())}</div>
        </div>
      )}
    </div>
  );
};

export default QuotaAdjustmentCellRenderer;
