import React, { useState } from 'react';

import Dialog from 'components/Dialog/Dialog';

import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';
import FileUploadSequence from 'app/core/fileUpload/FileUploadSequence/FileUploadSequence';

import { FileType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export type FileUploadDialogProps = {
  dialogTitle: string;
  fileUploadType: FileType;
  onProcessingCompleted?: () => void;
};

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({ dialogTitle, fileUploadType, onProcessingCompleted }) => {
  const [disableCancel, setDisableCancel] = useState<boolean>(false);
  const [disableComplete, setDisableComplete] = useState<boolean>(true);

  const { showUploadSequenceByFileType, setShowUploadSequenceByFileType } = useFileUpload();
  const isOpen = showUploadSequenceByFileType[fileUploadType];

  const handleCancel = () => {
    setShowUploadSequenceByFileType({ ...showUploadSequenceByFileType, [fileUploadType]: false });
    setDisableComplete(false);
  };

  const handleComplete = () => {
    setShowUploadSequenceByFileType({ ...showUploadSequenceByFileType, [fileUploadType]: false });
    onProcessingCompleted();
    setDisableCancel(false);
  };

  return (
    <Dialog
      title={dialogTitle}
      isOpen={isOpen}
      disableCancel={disableCancel}
      disableConfirm={disableComplete}
      onClose={handleCancel}
      onSubmit={handleComplete}
      cancelButtonText={formatMessage('CANCEL')}
      confirmButtonText={formatMessage('COMPLETE')}
      data-testid="file-upload-dialog"
    >
      <FileUploadSequence
        fileUploadType={fileUploadType}
        setDisableDialogCancel={setDisableCancel}
        setDisableDialogComplete={setDisableComplete}
      />
    </Dialog>
  );
};

export default FileUploadDialog;
