import gql from 'graphql-tag';

export const REVOKE_SYSTEM_ROLE = gql`
  mutation RevokeSystemRole($memberId: Int!) {
    revokeSystemRole(input: { memberId: $memberId }) {
      tenantId
      systemRoleName
      memberUpdatedAt
      memberId
      memberCreatedAt
      jobTitle
      lastName
      firstName
      employeeId
      emailAddress
      deleted
      customProperties
    }
  }
`;
