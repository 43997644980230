import { useState, useEffect } from 'react';

import { FileTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { useGetMappingFields } from 'app/graphql/queries/getMappingFields';

import { MappingFields } from 'app/models';

interface UseMappingFieldsResult {
  mappingFields: MappingFields[];
  setMappingFields: (fields: MappingFields[]) => void;
  mappingFieldsLoading: boolean;
}

export const useMappingFields = (
  planningCycleId: number,
  fileType: FileTypeEnum = FileTypeEnum.Activity,
  rootHierarchyId?: number,
  battlecardId?: number
): UseMappingFieldsResult => {
  const [mappingFields, setMappingFields] = useState<MappingFields[]>([]);
  const [mappingFieldsLoading, setMappingFieldsLoading] = useState<boolean>(false);
  const { data, loading } = useGetMappingFields({
    fetchPolicy: 'network-only',
    variables: {
      planningCycleId,
      fileType,
      rootHierarchyId: rootHierarchyId || null,
      battlecardId: battlecardId || null
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  useEffect(() => {
    if (loading && !data) {
      setMappingFieldsLoading(true);
    } else if (data && !loading) {
      // sort the list based on field name
      const fieldList = getMappingFields(data.getMappingFields)?.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      );
      setMappingFields(fieldList);
      setMappingFieldsLoading(false);
    }
  }, [data, loading]);

  const getMappingFields = (fields) => {
    return fields.map(
      ({ colRequired, valueRequired, displayMappingFieldName, dataType, fieldType, idAssociatedToFieldType }) => ({
        key: idAssociatedToFieldType,
        value: displayMappingFieldName,
        properties: {
          colRequired,
          valueRequired,
          dataType,
          fieldType
        }
      })
    );
  };

  return { mappingFields, setMappingFields, mappingFieldsLoading };
};
