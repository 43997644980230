import React from 'react';

import { LicenseManager } from '@ag-grid-enterprise/core';
import { FocusStyleManager } from '@blueprintjs/core';
import ReactDOM from 'react-dom';

import App from 'app/app';

import { config } from 'utils/config';
import { formatMessage } from 'utils/messages/utils';

LicenseManager.setLicenseKey(config.AG_GRID_KEY);

// disable focus unless tabbing
// https://design-system-storybook.spmdev.varicent.com/?path=/story/docs-accessibility--page#focus-management
FocusStyleManager.onlyShowFocusOnTabs();

// Change the title of the web app
document.title = formatMessage('VARICENT_SP');

ReactDOM.render(<App />, document.getElementById('root'));
