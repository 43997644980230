// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kMPH\\+ {\n    height: 32px;\n    width: 100%;\n  }\n  .-c\\+H\\+ {\n    width: 100%;\n  }\n  .rE8C2 {\n    text-align: left;\n    height: 32px;\n    width: 100%;\n    padding: 0 8px;\n    border: none;\n    border-radius: 4px;\n    box-shadow: var(--elevation-0);\n    line-height: 1;\n    text-overflow: ellipsis;\n    background: transparent;\n  }\n  ._5AS4S {\n      background-color: rgb(var(--color-light-gray-4));\n    }\n  .XUUO- {\n      padding: 0 8px 0 28px;\n    }\n  .z0KQb {\n    position: absolute;\n    left: 24px;\n    top: 3px;\n    color: rgb(var(--color-red-3));\n    cursor: pointer;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EditableTextCellRenderer_editableInputWrapper": "kMPH+",
	"EditableTextCellRenderer_editableInputTooltip": "-c+H+",
	"EditableTextCellRenderer_editableInput": "rE8C2",
	"EditableTextCellRenderer_editableInput__disabled": "_5AS4S",
	"EditableTextCellRenderer_editableInput__error": "XUUO-",
	"EditableTextCellRenderer_errorIcon": "z0KQb"
};
export default ___CSS_LOADER_EXPORT___;
