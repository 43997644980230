import React, { useMemo, useState } from 'react';

import { Card, Spinner } from '@blueprintjs/core';
import { Formik, Form, Field } from 'formik';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Dialog from 'components/Dialog/Dialog';
import { SPINNER_DEFAULT_SIZE } from 'components/global/variables';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { expectedSymonApiOrigin } from 'app/containers/Symon/SymonTypes';

import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { GET_SYMON_ACCOUNT } from 'app/graphql/queries/getSymonAccount';

import useTreatment from 'app/hooks/useTreatment';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { useCheckSymonConnectivity } from './hooks/useCheckSymonConnectivity';
import { useSaveSymonIntegrations } from './hooks/useSaveSymonIntegrations';
import style from './SymonIntegrationForm.module.pcss';
import { validationSchema } from './validationSchema';

const b = block(style);

export interface SymonIntegrationFormInput {
  symonDomain: string;
  symonUiClientKey: string;
}

interface SymonIntegrationFormProps {
  symonDomain: string;
  symonUiClientKey: string;
  symonAccountLoading: boolean;
}

export const formattedSymonDomain = (symonDomain: string): string =>
  symonDomain?.slice(-1) === '/' ? symonDomain?.slice(0, -1) : symonDomain;

const SymonIntegrationForm: React.FC<SymonIntegrationFormProps> = ({
  symonDomain,
  symonUiClientKey,
  symonAccountLoading
}) => {
  const { selectedTenant } = useScope();
  const [allowCustomSymonDomain] = useTreatment(SplitFeatures.ALLOW_CUSTOM_SYMON_DOMAIN);

  const [isSymonKeyValid, setIsSymonKeyValid] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false);
  const [saveSymonIntegration, { loading: savingSymonIntegration }] = useSaveSymonIntegrations();

  const isIntegrationKeyAvailable = !!(symonDomain && symonUiClientKey);

  const [checkSymonConnectivity, { loading: checkSymonConnectivityLoading }] = useCheckSymonConnectivity(
    setIsSymonKeyValid,
    setIsValidating
  );
  const onSaveSymonKey = (data) => {
    saveSymonIntegration({
      variables: {
        input: {
          tenantId: selectedTenant.id,
          symonDomain: formattedSymonDomain(data?.symonDomain),
          symonUiClientKey: data.symonUiClientKey
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_SYMON_ACCOUNT,
          variables: { tenantId: selectedTenant?.id }
        }
      ]
    });
  };

  const onDeleteSymonKey = () => {
    saveSymonIntegration({
      variables: {
        input: {
          tenantId: selectedTenant.id,
          symonDomain: null,
          symonUiClientKey: null
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_SYMON_ACCOUNT,
          variables: { tenantId: selectedTenant?.id }
        }
      ]
    }).then(() => {
      setIsSymonKeyValid(false);
    });
  };

  const onValidateSymonKey = (values, validateForm) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setIsValidating(true);
        setLoadingModalOpen(true);
        checkSymonConnectivity({
          variables: {
            input: {
              symonDomain: formattedSymonDomain(values?.symonDomain),
              symonUiClientKey: values.symonUiClientKey
            }
          }
        });
      }
    });
  };

  const initialFormValues = useMemo(
    (): SymonIntegrationFormInput => ({
      symonDomain: symonDomain || expectedSymonApiOrigin,
      symonUiClientKey: symonUiClientKey || ''
    }),
    [symonDomain, symonUiClientKey]
  );

  if (symonAccountLoading) {
    return (
      <div className={b('loadingContainer')}>
        <Spinner intent="primary" size={SPINNER_DEFAULT_SIZE} />
      </div>
    );
  }
  return (
    <div className={b('formContainer')} data-testid="symon-integration-form">
      <Dialog
        isOpen={loadingModalOpen}
        isLoading={isValidating}
        title={formatMessage('VALIDATE_API_KEY')}
        confirmButtonText={formatMessage('CONFIRM')}
        showCancel={false}
        onClose={() => null}
        onSubmit={() => setLoadingModalOpen(false)}
        size="small"
        spinnerElement={
          <div data-testid="dialog-loading-spinner" className={b('spinnerContainer')}>
            <Spinner intent="primary" size={SPINNER_DEFAULT_SIZE} />
            <span className={b('validationText')}>{formatMessage('SYMON_CREDENTIALS_BEING_VALIDATED')}</span>
          </div>
        }
      >
        <div className={b('validationText')} data-testid="dialog-validation-text">
          {isSymonKeyValid ? formatMessage('SYMON_CREDENTIALS_VALID') : formatMessage('SYMON_CREDENTIALS_INVALID')}
        </div>
      </Dialog>
      <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validationSchema={validationSchema(allowCustomSymonDomain)}
        onSubmit={onSaveSymonKey}
      >
        {({ values, validateForm }) => {
          return (
            <Form>
              <Field
                disabled={!allowCustomSymonDomain}
                label={formatMessage('SYMON_DOMAIN')}
                name="symonDomain"
                type="text"
                component={FormTextInputGroup}
                data-testid="symon-domain-input"
              />
              <Field
                disabled={isIntegrationKeyAvailable}
                label={formatMessage('SYSTEM_API_KEY')}
                name="symonUiClientKey"
                type="text"
                component={FormTextInputGroup}
                data-testid="symon-ui-client-key-input"
                autoComplete="off"
              />
              <div className={b('buttonContainer')}>
                {isIntegrationKeyAvailable ? (
                  <div className={b('button')}>
                    <TextButton
                      type="button"
                      loading={savingSymonIntegration}
                      text={formatMessage('DELETE_API_KEY')}
                      testId="delete-api-key-button"
                      onClick={onDeleteSymonKey}
                      intent="danger"
                    />
                  </div>
                ) : (
                  <div className={b('button')}>
                    <TextButton
                      type="button"
                      loading={checkSymonConnectivityLoading}
                      text={formatMessage('VALIDATE_API_KEY')}
                      testId="validate-api-key-button"
                      onClick={() => {
                        onValidateSymonKey(values, validateForm);
                      }}
                    />
                  </div>
                )}
                <div className={b('button')}>
                  <TextButton
                    type="submit"
                    loading={savingSymonIntegration}
                    disabled={savingSymonIntegration || !isSymonKeyValid || isIntegrationKeyAvailable}
                    text={formatMessage('SAVE')}
                    testId="save-api-key-button"
                    intent="primary"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {isIntegrationKeyAvailable && symonDomain !== expectedSymonApiOrigin && !allowCustomSymonDomain && (
        <Card>
          <h4>{formatMessage('SAVED_SYMON_DOMAIN_INVALID_TITLE')}</h4>
          <p data-testid="invalid-saved-symon-domain">
            {formatMessage('SAVED_SYMON_DOMAIN_INVALID_DESCRIPTION')}
            <code>{expectedSymonApiOrigin}</code>
          </p>
        </Card>
      )}
    </div>
  );
};

export default SymonIntegrationForm;
