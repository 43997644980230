import gql from 'graphql-tag';

export const ADD_TERRITORY_WORKFLOW_COMMENT = gql`
  mutation AddTerritoryWorkflowComment(
    $deploymentModelId: Int!
    $battlecardId: Int!
    $threadId: Int
    $content: String!
    $version: Int!
  ) {
    addTerritoryWorkflowComment(
      input: {
        deploymentModelId: $deploymentModelId
        battlecardId: $battlecardId
        threadId: $threadId
        content: $content
        version: $version
      }
    ) {
      success
      threadId
      commentId
    }
  }
`;
