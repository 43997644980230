import React from 'react';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './EmptyPanel.module.pcss';

const b = block(style);

const EmptyPanel: React.FC = () => {
  return (
    <div className={b('container')} data-testid="empty-panel">
      <div className={b('header')}>{formatMessage('ADD_TERRITORY')}</div>
      <div className={b('body')}>
        <div className={b('dashedContainer')}>
          <div className={b('textContainer')}>{formatMessage('EMPTY_PANEL_TEXT')}</div>
        </div>
      </div>
    </div>
  );
};

export default EmptyPanel;
