import gql from 'graphql-tag';

export const UPDATE_CUSTOM_MEASURE = gql`
  mutation UpdateCustomMeasure($tenantId: Int!, $measureId: Int!, $measureName: String!, $measureFormatType: String!) {
    updateCustomMeasure(
      input: {
        tenantId: $tenantId
        measureId: $measureId
        measureName: $measureName
        measureFormatType: $measureFormatType
      }
    ) {
      success
    }
  }
`;
