import gql from 'graphql-tag';

export const GET_WORKFLOWS = gql`
  query GetWorkflows($deploymentModelId: Int!) {
    getWorkflows(deploymentModelId: $deploymentModelId) {
      completionRate
      workflowInitiated
      workflows {
        approverEmail
        approverId
        battlecardId
        battlecardName
        battlecardOwnerId
        battlecardParentId
        battlecardWorkflowId
        children
        ownerEmail
        status
        ownerLastName
        ownerFirstName
        approverLastName
        approverFirstName
        territoryGroups {
          approverEmail
          approverFirstName
          approverId
          approverLastName
          children
          ownerEmail
          ownerFirstName
          ownerLastName
          status
          territoryGroupId
          territoryGroupName
          territoryGroupOwnerId
          territoryGroupParentId
          territoryGroupWorkflowId
        }
      }
    }
  }
`;
