// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rOyxu {\n  padding: 0 var(--data-panel-padding);\n  width: 100%;\n  overflow: scroll;\n  height: 100%;\n  display: flex;\n  flex-direction: column\n}\n.rOyxu.ag-theme-alpine .ag-root-wrapper {\n    border: 0;\n    height: 100%;\n  }\n.rOyxu.ag-theme-alpine .ag-row {\n    cursor: pointer;\n  }\n._4586\\+ {\n    height: 100%;\n    flex: 1;\n    min-height: 120px;\n  }\n._9hw9a {\n    border: 1px solid rgb(var(--color-light-gray-3));\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n.V7fEo {\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImportErrorsPanel": "rOyxu",
	"ImportErrorsPanel_importErrorsGrid": "_4586+",
	"ImportErrorsPanel_grid": "_9hw9a",
	"ImportErrorsPanel_noData": "V7fEo " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-no-data"] + ""
};
export default ___CSS_LOADER_EXPORT___;
