import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertBattlecard, UpsertBattlecardVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const UPSERT_BATTLECARD = gql`
  mutation UpsertBattlecard($input: UpsertBattlecardInput!) {
    upsertBattlecard(input: $input) {
      battlecardId
    }
  }
`;

export const useUpsertBattleCard = createUseMutation<UpsertBattlecard, UpsertBattlecardVariables>(UPSERT_BATTLECARD);
