import React from 'react';

import { Download } from '@carbon/icons-react';
import { CSVLink } from 'react-csv';
import { LinkProps } from 'react-csv/components/Link';

import IconButton from 'components/Buttons/IconButton/IconButton';

import block from 'utils/bem-css-modules';

import style from './DownloadCsvSample.module.pcss';

const b = block(style);

interface DownloadCsvSampleProps extends LinkProps {
  linkText?: string;
}

const DownloadCsvSample: React.FC<DownloadCsvSampleProps> = ({
  data,
  headers,
  filename,
  linkText
}: DownloadCsvSampleProps) => {
  return (
    <div data-testid="csv-download-sample">
      <CSVLink filename={filename} data={data} headers={headers} className={b()}>
        <IconButton
          intent="primary"
          type="button"
          icon={<Download />}
          testId={'download-csv-button'}
          title="Download CSV"
        />
        <p className={b('linkText')} data-testid="csv-download-text">
          {linkText}
        </p>
      </CSVLink>
    </div>
  );
};

export default DownloadCsvSample;
