import React from 'react';

import IntegrationPanelFallback from 'app/components/IntegrationPanel/IntegrationPanelFallback';
import SymonSpinner from 'app/components/IntegrationPanel/SymonSpinner';

import SymonPortal from 'app/containers/Symon/SymonPortal';
import { SymonIframePaths } from 'app/containers/Symon/SymonTypes';

import { SymonTokenState } from 'app/hooks/symon/useSymonToken';

interface SymonPortalWrapperProps {
  pathname: SymonIframePaths;
  tokenState: SymonTokenState;
  symonDomain: string;
  symonDomainLoading: boolean;
}

const SymonPortalWrapper: React.FC<SymonPortalWrapperProps> = ({
  pathname,
  tokenState,
  symonDomain,
  symonDomainLoading
}) => {
  return (
    <>
      {tokenState.isLoading || symonDomainLoading ? (
        <SymonSpinner />
      ) : (
        <>
          {tokenState.hasError && <IntegrationPanelFallback showRefreshButton={false} />}
          {tokenState.token && <SymonPortal symonDomain={symonDomain} pathname={pathname} token={tokenState.token} />}
        </>
      )}
    </>
  );
};

export default SymonPortalWrapper;
