// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5wiF8 {\n    margin: var(--m) 0 !important;\n  }\n  .occKc {\n    font-weight: normal;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OverrideReassignDialogBody_overrideReassignText": "_5wiF8",
	"OverrideReassignDialogBody_labelElement": "occKc"
};
export default ___CSS_LOADER_EXPORT___;
