// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QvdDN {\n  display: flex;\n  align-items: center;\n}\n\n  .QvdDN .bp3-button, .QvdDN .bp3-button:not([class*='bp3-intent-']) {\n    height: 32px;\n    margin-right: 12px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CoinsortButtons": "QvdDN"
};
export default ___CSS_LOADER_EXPORT___;
