import { ColDef } from '@ag-grid-community/core';

export const TerritorySearchColumnDefs = (checkboxSelection: (params) => boolean): ColDef[] => {
  return [
    {
      valueFormatter: (params) => {
        const { territoryId, territoryName, primarySeller } = params.data;
        const firstName = primarySeller?.firstName ?? '';
        const lastName = primarySeller?.lastName ?? '';
        return primarySeller
          ? `${territoryId} / ${territoryName} / ${firstName} ${lastName}`
          : `${territoryId} / ${territoryName}`;
      },
      checkboxSelection
    }
  ];
};
