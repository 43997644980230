// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._17XOX .bp3-dialog {\n    width: 620px;\n    box-sizing: border-box;\n    padding: 24px 24px 29px;\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);\n  }\n  ._17XOX .bp3-overlay-backdrop {\n    background-color: rgba(var(--color-dark-gray-3), 0.4);\n  }\n  .hOJ6h {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 32px;\n  }\n  .nil5p {\n    font-size: 16px;\n    color: rgb(var(--color-dark-gray-3));\n    font-weight: normal;\n  }\n  .ljKOn {\n    min-height: 100px;\n    font-size: 14px;\n    color: rgb(var(--color-gray-2));\n  }\n  .D-pN5 {\n    height: 24px;\n  }\n  .mpuVE {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n  .h\\+tnx {\n    flex-grow: 2;\n  }\n  .Ybnb6 {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    flex-grow: 1;\n  }\n  .wJcbB {\n    margin-right: 18px;\n  }\n  ._17XOX .bp3-overlay.bp3-overlay-scroll-container.bp3-overlay-inline {\n    position: fixed;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConfirmDeleteModal": "_17XOX",
	"ConfirmDeleteModal_modalHeader": "hOJ6h",
	"ConfirmDeleteModal_title": "nil5p",
	"ConfirmDeleteModal_modalBody": "ljKOn",
	"ConfirmDeleteModal_loadingPlaceholder": "D-pN5",
	"ConfirmDeleteModal_modalFooter": "mpuVE",
	"ConfirmDeleteModal_footerSpacer": "h+tnx",
	"ConfirmDeleteModal_buttons": "Ybnb6",
	"ConfirmDeleteModal_button": "wJcbB"
};
export default ___CSS_LOADER_EXPORT___;
