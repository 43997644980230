import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { InviteUser, InviteUserVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const INVITE_USER = gql`
  mutation InviteUser(
    $emailAddress: String!
    $firstName: String!
    $lastName: String!
    $tenantId: Int!
    $systemRoleName: SystemRoleName
  ) {
    inviteUser(
      input: {
        emailAddress: $emailAddress
        firstName: $firstName
        lastName: $lastName
        tenantId: $tenantId
        systemRoleName: $systemRoleName
      }
    )
  }
`;

export const useInviteUser = createUseMutation<InviteUser, InviteUserVariables>(INVITE_USER);
