import React from 'react';

import { RulerAlt } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { MapSelectionTool } from 'app/models';

import { isLineRulerTool } from 'utils/helpers/territoryMapUtils';
import { formatMessage } from 'utils/messages/utils';

interface LineRulerButtonProps {
  onSelectLineRulerTool: (tool: MapSelectionTool) => void;
  selectionTool: MapSelectionTool;
  disabled?: boolean;
}

const LineRulerButton: React.FC<LineRulerButtonProps> = ({ onSelectLineRulerTool, selectionTool, disabled }) => {
  return (
    <IconButton
      icon={<RulerAlt />}
      type="button"
      testId="line-ruler-button"
      onClick={() => onSelectLineRulerTool(MapSelectionTool.lineRulerTool)}
      active={isLineRulerTool(selectionTool)}
      disabled={disabled}
      tooltipText={formatMessage('DISTANCE_CALCULATOR')}
      tooltipPlacement="bottom"
    />
  );
};

export default LineRulerButton;
