// Add a common pagination check for all grids to fetch more rows
// To avoid the incident of the total number of rows is exactly one more than a paging interval
// which create ghost row due to inconsistent of totalCount check within FE repo
export const getPaginationCheck = (startRow: number, totalCount: number) => {
  return startRow + 1 <= totalCount;
};

export const formatDataForExtraTagCellRenderer = (params, dataKey, formatRemainingElements) => {
  let shouldRenderNoData = false;
  const dataItems = params.data[dataKey] || [];
  const items = dataItems.map(formatRemainingElements);

  if (!dataItems || dataItems.length === 0 || params.data?.isRowExpanded) {
    shouldRenderNoData = true;
  }

  return {
    shouldRenderNoData,
    items
  };
};
