import { useHistory } from 'react-router-dom';

import useMakePlanningPath from './useMakePlanningPath';

export const useChangeScenario = () => {
  const history = useHistory();
  const makePlanningPath = useMakePlanningPath();

  return (scenarioName: string | null) => {
    if (scenarioName === null) {
      history.replace({ ...history.location, search: omitScenarioSearchParam(history.location.search) });
    } else {
      history.replace(makePlanningPath({ scenarioParam: scenarioName }));
    }
  };
};

const omitScenarioSearchParam = (search: string) => {
  const params = new URLSearchParams(search);
  params.delete('scenario');
  return params.toString();
};
