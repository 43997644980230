import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetPermissions, GetPermissionsVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_PERMISSIONS = gql`
  query GetPermissions($planningCycleId: Int!) {
    getPlanningCycleSpec(input: { planningCycleId: $planningCycleId }) {
      planningCycleId
      permissions {
        actionName
        deploymentModelId
        roleName
      }
    }
  }
`;

export const useGetPermissions = createUseQuery<GetPermissions, GetPermissionsVariables>(GET_PERMISSIONS);
