import React from 'react';

import { Checkbox } from '@blueprintjs/core';
import { FieldProps } from 'formik';

interface FormCheckboxProps extends FieldProps {
  labelElement?: JSX.Element;
  disabled?: boolean;
  checkedCount?: number;
  setCheckedCount?: (value) => void;
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  field: { name, value },
  form: { setFieldValue },
  labelElement,
  disabled,
  checkedCount = null,
  setCheckedCount
}: FormCheckboxProps) => {
  const handleChange = () => {
    setFieldValue(name, !value);
    if (setCheckedCount && checkedCount !== null) {
      setCheckedCount(value ? checkedCount - 1 : checkedCount + 1);
    }
  };
  return <Checkbox labelElement={labelElement} disabled={disabled} checked={value} onChange={handleChange} />;
};

export default FormCheckbox;
