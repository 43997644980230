import React from 'react';

import { AddComment } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import CommentForm from 'app/components/CommentPanel/CommentForm/CommentForm';
import CommentThread from 'app/components/CommentPanel/CommentThread/CommentThread';

import { useComments } from 'app/contexts/commentProvider';

import { GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items } from 'app/graphql/generated/graphqlApolloTypes';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import hierarchyEmpty from 'assets/pngs/hierarchy_empty.png';

import style from './CommentPanelContent.module.pcss';

const b = block(style);

interface CommentPanelContentProps {
  threads?: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items[];
  isLoading: boolean;
}

const CommentPanelContent: React.FC<CommentPanelContentProps> = ({ threads, isLoading }: CommentPanelContentProps) => {
  const { shouldRenderCommentForm, setShouldRenderCommentForm } = useComments();
  const ReplyForm = ({ threadId }) => {
    return (
      <div className={b('replyForm')} data-testid="reply-form">
        <CommentForm
          buttonText={formatMessage('REPLY')}
          placeholderText={formatMessage('REPLY')}
          threadId={threadId}
          data-testid="comment-form"
        />
      </div>
    );
  };

  const CommentThreadPlaceHolder = () => {
    return (
      <>
        <div className={b('commentImgWrapper')} data-testid="comment-thread-placeholder">
          <img
            data-testid="comment-placeholder-img"
            className={b('commentImg')}
            src={hierarchyEmpty}
            alt={formatMessage('NO_COMMENTS_TO_SHOW')}
          />
          <div className={b('commentPlaceHolderText')} data-testid="comment-thread-placeholder-text">
            <span className={b('noCommentText')}>{formatMessage('NO_COMMENTS_TO_SHOW')}</span>
            <span>{formatMessage('ADD_COMMENTS_MESSAGE')}</span>
          </div>
          <span className={b('addBtn')}>
            <TextButton
              testId={'add-comment'}
              type="button"
              intent="primary"
              text={formatMessage('ADD_COMMENTS')}
              icon={<AddComment />}
              onClick={() => {
                setShouldRenderCommentForm(true);
              }}
            />
          </span>
        </div>
      </>
    );
  };

  return (
    <div className={b('commentBodyContainer')} data-testid="comment-panel-content">
      {shouldRenderCommentForm && (
        <CommentForm
          buttonText={formatMessage('COMMENT')}
          placeholderText={formatMessage('NEW_COMMENTS_PLACEHOLDER')}
          threadId={null}
          data-testid="comment-form"
        />
      )}
      {/* By default, the backend will only return the 2 most recent replies, if user wants to see more
       replies, the request will be handling in CommentThread */}
      {threads?.length > 0
        ? threads?.map(({ topLevelComment, threadId, threadReplies, threadTags }) => {
            // if a comment thread has multiple of the same tag, only return a list of unique tags
            return (
              <div className={b('commentContent')} key={`${topLevelComment?.commentId}`}>
                <CommentThread
                  topLevelComment={topLevelComment}
                  collapsedReplyList={threadReplies}
                  threadId={threadId}
                  threadTags={threadTags}
                />
                <ReplyForm threadId={threadId} />
              </div>
            );
          })
        : !shouldRenderCommentForm && !isLoading && <CommentThreadPlaceHolder />}
    </div>
  );
};

export default CommentPanelContent;
