// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QGxfE {\n  display: flex;\n  justify-content: flex-end;\n  height: 100%;\n  width: 100%;\n  border-bottom: 1px solid rgb(var(--color-light-gray-4));\n  position: relative;\n}\n\n  .uDHK4 {\n    height: 100%;\n    position: absolute;\n    overflow: hidden;\n  }\n\n  .AH9Pw {\n      height: 100%;\n      position: absolute;\n      padding-right: var(--allocation-margin);\n    }\n\n  .ICxrN {\n      left: 0;\n      background-color: white;\n    }\n\n  .ICxrN > .AH9Pw {\n      left: 0;\n      color: rgb(var(--color-gray-2));\n    }\n\n  .A06cD {\n      right: 0;\n    }\n\n  .A06cD > .AH9Pw {\n      right: 0;\n      color: white;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PercentCellRenderer": "QGxfE",
	"PercentCellRenderer_bar": "uDHK4",
	"PercentCellRenderer_bar__text": "AH9Pw",
	"PercentCellRenderer_bar__notProgressed": "ICxrN",
	"PercentCellRenderer_bar__progressed": "A06cD"
};
export default ___CSS_LOADER_EXPORT___;
