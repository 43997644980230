// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E-T1t {\n  padding: var(--dialog-padding) 0;\n}\n\n  ._5WxVX {\n  }\n\n  .CF33Y {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 20px;\n  }\n\n  ._2IJfx {\n    width: 33%;\n  }\n\n  .HQBFS {\n    margin-top: var(--m);\n  }\n\n  .gs1PN {\n    width: 50%;\n  }\n\n  ._1O7ZN {\n    padding-top: 24px;\n  }\n\n  .E-T1t .bp3-divider {\n    margin: 24px 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImportSymonDataFileType": "E-T1t",
	"ImportSymonDataFileType_label": "_5WxVX " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label-text"] + "",
	"ImportSymonDataFileType_subTitle": "CF33Y",
	"ImportSymonDataFileType_fileTypeInput": "_2IJfx",
	"ImportSymonDataFileType_callout": "HQBFS",
	"ImportSymonDataFileType_destinationFieldContainer": "gs1PN",
	"ImportSymonDataFileType_destinationInput": "_1O7ZN"
};
export default ___CSS_LOADER_EXPORT___;
