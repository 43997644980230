// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QNUHw {\n    color: white;\n  }\n\n    .QNUHw .bp3-text-overflow-ellipsis {\n      max-width: -moz-max-content;\n      max-width: max-content;\n    }\n  .ET0eI .mapboxgl-popup {\n      height: 30px;\n      border-top-color: var(--background-inverse);\n    }\n  .ET0eI .mapboxgl-popup-tip {\n      border-top-color: var(--background-inverse) !important;\n    }\n  .ET0eI .bp3-tag {\n      font-size: 0.875rem !important;\n      text-align: center;\n      margin: 2px 0;\n    }\n  .ET0eI .bp3-tag:not([class*='bp3-intent-']) {\n      background-color: var(--background-inverse) !important;\n      color: white !important;\n    }\n  .ET0eI .mapboxgl-popup-content {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      padding: 8px 16px;\n      background: var(--background-inverse);\n      border-radius: 4px;\n      pointer-events: none;\n    }\n  .ET0eI .bp3-intent-warning > svg {\n        color: #faad14;\n      }\n  .Ba\\+Rt {\n    max-width: 148px;\n    display: flex;\n  }\n  .TM7TP {\n    color: white;\n    font-size: 12px;\n  }\n  .\\+VUm5 {\n    color: white;\n    font-size: 12px;\n  }\n  .Jpgy- {\n    color: white;\n    font-size: 12px;\n  }\n  .Yjoxq {\n    color: white;\n    font-size: 12px;\n    font-weight: 600;\n  }\n  .KIHcp {\n    padding-right: var(--s);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapTooltip_groupItem": "QNUHw",
	"MapTooltip_tooltipWrapper": "ET0eI",
	"MapTooltip_tooltip": "Ba+Rt",
	"MapTooltip_unassigned": "TM7TP",
	"MapTooltip_polygonName": "+VUm5",
	"MapTooltip_territoryIdAndName": "Jpgy-",
	"MapTooltip_conflicts": "Yjoxq",
	"MapTooltip_lockIcon": "KIHcp"
};
export default ___CSS_LOADER_EXPORT___;
