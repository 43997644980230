import { SymonApiResponseTypeMap, SymonToken, SymonUploadCredentials } from 'app/containers/Symon/SymonTypes';

import { SYMON_PROXY_PATH } from 'app/global/variables';

import { TenantHeader } from 'app/models';

import { config } from 'utils/config';

const baseSymonProxyUrl = `${config.API_GATEWAY_ENDPOINT}${SYMON_PROXY_PATH}`;

interface SymonApiProxyInit {
  tenantId: number;
  tenantGlobalId: string;
  getAuthorization: () => Promise<string>;
}

export class SymonApiProxyClient {
  private readonly getAuthorization: () => Promise<string>;
  private readonly tenantId: number;
  private readonly tenantGlobalId: string;

  constructor({ tenantId, tenantGlobalId, getAuthorization }: SymonApiProxyInit) {
    this.tenantId = tenantId;
    this.tenantGlobalId = tenantGlobalId;
    this.getAuthorization = getAuthorization;
  }

  private async symonFetch<PathType extends keyof SymonApiResponseTypeMap>(
    pathname: PathType,
    init?: RequestInit
  ): Promise<SymonApiResponseTypeMap[PathType]> {
    const response = await fetch(`${baseSymonProxyUrl}/${pathname}`, {
      ...init,
      headers: {
        ...init?.headers,
        [`X-SP-TENANT-ID`]: this.tenantId.toString(),
        [TenantHeader.TENANT_GLOBAL_ID]: this.tenantGlobalId,
        [`Authorization`]: await this.getAuthorization()
      }
    });
    if (!response.ok) throw new Error(`Symon Proxy API Error ${response.status}`);
    return response.json();
  }

  public async getSymonToken(): Promise<SymonToken> {
    return this.symonFetch('auth/jwtLoginToken');
  }

  public async getSymonUploadCreds(orgId: string): Promise<SymonUploadCredentials> {
    return this.symonFetch('auth/aws', {
      headers: {
        [`wisepipe-org-id`]: orgId
      }
    });
  }
}
