// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Lglp9 {\n  border-radius: 4px;\n  background: rgb(var(--color-light-gray-5));\n  margin: 4px 0;\n  display: flex;\n  flex-direction: row;\n  padding: 0 5px;\n  align-items: center;\n  font-weight: normal;\n}\n\n  .Rqnod {\n    background: rgb(var(--color-light-gray-5));\n  }\n\n  .tZEVl {\n    margin: 0 4px;\n    width: 60px;\n  }\n\n  .v4VBk {\n    font-weight: normal;\n  }\n\n  /* TODO TQP-1421 Replace border color with opacity after we update the color variables */\n\n  .XjGvb {\n    border: 2px solid rgb(var(--color-cobalt-4));\n    border-radius: 50%;\n    color: white;\n    background: rgb(var(--color-cobalt-3));\n    font-size: 12px;\n    text-align: center;\n    padding: 0 5px;\n    background-clip: padding-box;\n    margin-left: 5px;\n  }\n\n  .wp-gS {\n    background: rgb(var(--color-light-gray-1));\n    border: 2px solid rgb(var(--color-light-gray-4));\n  }\n\n  .vrp3g {\n    min-width: 7px;\n  }\n\n  .VNMII {\n    margin-left: 12px;\n    width: 135px;\n    color: rgb(var(--color-dark-gray-3));\n  }\n\n  .iXJgN {\n    color: rgb(var(--color-light-gray-2));\n  }\n\n  ._9l1A7 {\n    margin-left: 12px;\n    width: 150px;\n    height: 24px;\n  }\n\n  .cvvLq {\n    margin-left: 12px;\n  }\n\n  .rl0Oq {\n    font-weight: normal;\n  }\n\n  .Lglp9 .bp3-input-group .bp3-input {\n    height: 24px;\n    align-items: center;\n    display: flex;\n  }\n\n  .Lglp9 .bp3-input:disabled, .Lglp9 .bp3-input.bp3-disabled {\n    height: 24px;\n    align-items: center;\n    display: flex;\n  }\n\n  .Lglp9 .bp3-control.pb3-switch > label {\n    font-weight: normal;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReorderableListItem": "Lglp9",
	"ReorderableListItem__disabled": "Rqnod",
	"ReorderableListItem_switch": "tZEVl",
	"ReorderableListItem_switchLabel": "v4VBk",
	"ReorderableListItem_numberLabel": "XjGvb",
	"ReorderableListItem_numberLabel__disabled": "wp-gS",
	"ReorderableListItem_number": "vrp3g",
	"ReorderableListItem_hierarchies": "VNMII",
	"ReorderableListItem_hierarchies__disabled": "iXJgN",
	"ReorderableListItem_referToAs": "_9l1A7",
	"ReorderableListItem_hierarchyTop": "cvvLq",
	"ReorderableListItem_checkboxLabel": "rl0Oq"
};
export default ___CSS_LOADER_EXPORT___;
