import gql from 'graphql-tag';

export const APPROVE_WORKFLOW = gql`
  mutation ApproveWorkflow($battlecardWorkflowId: Int, $territoryGroupWorkflowId: Int) {
    approveWorkflow(
      input: { battlecardWorkflowId: $battlecardWorkflowId, territoryGroupWorkflowId: $territoryGroupWorkflowId }
    ) {
      success
    }
  }
`;
