import React, { useMemo } from 'react';

import { Search } from '@carbon/icons-react';
import { Formik, Form, Field } from 'formik';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { formatStaticInitialValues } from 'app/components/DataTray/TerritoryGrid/territoryGridUtils';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { useBattleCard } from 'app/contexts/battleCardProvider';

import { FilterChangeInput, FilterInput, GridFields, GridHeaders, HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryQuotaDrillInGridFilter.module.pcss';

const b = block(style);

export interface TerritoryQuotaDrillInGridFilterProps {
  accountFilters: FilterInput;
  onFilterChange: (updatedFilter: FilterChangeInput) => void;
  onFilterApply: () => void;
}

const TerritoryQuotaDrillInGridFilter: React.FC<TerritoryQuotaDrillInGridFilterProps> = ({
  accountFilters,
  onFilterChange,
  onFilterApply
}) => {
  const { quotaBreakdownHierarchies } = useBattleCard();

  const territoryQuotaDrillInFilterOptions = useMemo(() => {
    const options = quotaBreakdownHierarchies.flatMap((hierarchy) => {
      if (hierarchy.hierarchyType === HierarchyType.CustomerAccountHierarchy) {
        return [
          { label: GridHeaders.ACCOUNT_KEY, colId: GridFields.ACCOUNT_KEY },
          { label: GridHeaders.ACCOUNT_NAME, colId: GridFields.ACCOUNT_NAME }
        ];
      } else if (hierarchy.hierarchyType === HierarchyType.CustomHierarchy) {
        return [
          {
            label: `${hierarchy.rootKey} key` as GridHeaders,
            colId: `customHierarchyKey`
          },
          {
            label: `${hierarchy.rootName} name` as GridHeaders,
            colId: `customHierarchyName`
          }
        ];
      }
      return [];
    });

    options.push(
      {
        label: GridHeaders.ADJUSTMENT_TYPE,
        colId: GridFields.ADJUSTMENT_TYPE
      },
      {
        label: GridHeaders.ACCOUNT_MOVED_FROM,
        colId: GridFields.ACCOUNT_MOVED_FROM
      },
      {
        label: GridHeaders.ACCOUNT_MOVED_TO,
        colId: GridFields.ACCOUNT_MOVED_TO
      }
    );

    return options;
  }, [quotaBreakdownHierarchies]);

  const staticInitialValues = useMemo(() => {
    const initialValues = territoryQuotaDrillInFilterOptions.reduce((acc, option) => {
      acc[option.colId] = '';
      return acc;
    }, {});

    return {
      ...initialValues,
      ...formatStaticInitialValues(accountFilters)
    };
  }, [accountFilters]);

  const handleFilterChange = ({ filterType, type, filter, colId }) =>
    onFilterChange({ filterType, type, filter, colId });
  return (
    <div className={b('inputWrapper')} data-testid="territory-quota-drill-in-grid-filter">
      <Formik initialValues={staticInitialValues} onSubmit={null} enableReinitialize>
        {() => {
          return (
            <Form>
              {territoryQuotaDrillInFilterOptions.map((column) => (
                <div className={b('fieldWrapper')} key={column.colId}>
                  <Field
                    component={FormTextInputGroup}
                    name={column.colId}
                    label={column.label}
                    value={accountFilters[column.colId]?.filter ?? ''}
                    showErrors={false}
                    leftIcon={<Search />}
                    onChange={(e) =>
                      handleFilterChange({
                        filterType: 'text',
                        type: 'contains',
                        filter: e.target.value,
                        colId: column.colId
                      })
                    }
                  />
                </div>
              ))}
            </Form>
          );
        }}
      </Formik>
      <div className={b('applyFilterButton')}>
        <TextButton
          text={formatMessage('APPLY')}
          type="button"
          testId="filter-apply-button"
          large={false}
          onClick={onFilterApply}
        />
      </div>
    </div>
  );
};
export default TerritoryQuotaDrillInGridFilter;
