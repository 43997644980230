// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SXZHs {\n  height: 100%;\n  width: 100%;\n  position: relative;\n  pointer-events: none;\n}\n\n  .RT-Hu {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    pointer-events: none;\n  }\n\n  .cm6Te {\n      /* Steal the pointer events from the map to prevent clicking through the gap */\n      pointer-events: all;\n    }\n\n  .gzRF4 {\n    display: flex;\n    align-items: center;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n    padding-left: var(--xs);\n    font-weight: 600;\n    font-size: 16px;\n    margin: 0;\n    color: var(--text-heading);\n  }\n\n  .AQN7g {\n      height: 100%;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Gutter": "SXZHs",
	"Gutter_deadZone": "RT-Hu",
	"Gutter_deadZone__isActive": "cm6Te",
	"Gutter_insightsHeader": "gzRF4",
	"Gutter_insightsContentRuler__fill": "AQN7g"
};
export default ___CSS_LOADER_EXPORT___;
