import gql from 'graphql-tag';

export const DELETE_MEMBER = gql`
  mutation DeleteMember($memberId: Int!) {
    deleteMember(input: { memberId: $memberId }) {
      tenantId
      systemRoleName
      memberUpdatedAt
      memberId
      memberCreatedAt
      jobTitle
      lastName
      firstName
      employeeId
      emailAddress
      deleted
      customProperties
    }
  }
`;
