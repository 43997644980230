import { useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import useMakePlanningPath from 'app/hooks/useMakePlanningPath';

import { DeploymentModelPhase } from 'app/models';

interface SlugParts {
  deploymentModelSlug: string;
}

// Returns the deployment model phase (Manage or Plan) for TQM Navigation
// 'Plan' is returned as the default phase if the deploymentModelSlug does not exist
const usePhase = (): DeploymentModelPhase => {
  const { deploymentModelSlug } = useParams<SlugParts>();
  const history = useHistory();
  const makePlanningPath = useMakePlanningPath();
  const phase: DeploymentModelPhase = DeploymentModelPhase[deploymentModelSlug];

  useEffect(() => {
    if (!phase) {
      history.push(makePlanningPath());
    }
  }, [phase]);

  if (!phase) {
    return DeploymentModelPhase.plan;
  } else {
    return phase;
  }
};

export default usePhase;
