import React from 'react';

import { Draggable } from 'react-beautiful-dnd';

import block from 'utils/bem-css-modules';

import style from './ReorderableListItem.module.pcss';

const b = block(style);

interface ReorderableListItemProps {
  item: React.ReactElement;
  itemIndex: number;
  reorderableList: Array<React.ReactElement>;
  children;
  removeItem: () => void;
}

const ReorderableListItem: React.FC<ReorderableListItemProps> = ({
  item,
  itemIndex,
  reorderableList,
  children,
  removeItem
}: ReorderableListItemProps) => {
  const itemId = item['id'];
  const childrenWithExtraProp = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      item,
      itemIndex,
      reorderableList,
      removeItem
    });
  });

  return (
    <Draggable draggableId={itemId} key={itemId} index={itemIndex}>
      {(provided) => (
        <div
          className={b()}
          key={itemId}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {childrenWithExtraProp}
        </div>
      )}
    </Draggable>
  );
};

export default ReorderableListItem;
