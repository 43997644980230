import gql from 'graphql-tag';

export const GET_HIERARCHY_BY_KEY = gql`
  query GetHierarchyByKey(
    $hierarchyType: String!
    $hierarchyKey: String!
    $planningCycleId: Int!
    $version: Int!
    $rootId: Int
  ) {
    getHierarchyByKey(
      input: {
        hierarchyType: $hierarchyType
        planningCycleId: $planningCycleId
        version: $version
        hierarchyKey: $hierarchyKey
        rootId: $rootId
      }
    ) {
      children
      cloneId
      customProperties
      fileId
      hierarchyId
      hierarchyType
      key
      name
      parentKey
      path
      planningCycleId
      version
      rootId
    }
  }
`;
