import React, { FC, useState } from 'react';

import { Classes, Menu } from '@blueprintjs/core';

import { MenuItem } from 'components/menu/MenuItem';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { useDynamicMapHierarchyCopy } from 'app/components/TerritoryMap/hooks/useDynamicMapHierarchyCopy';
import { useLockAndIgnoreActions } from 'app/components/TerritoryMap/hooks/useLockAndIgnoreActions';
import { useCheckMapCapable } from 'app/components/TerritoryMap/hooks/useMapCapability';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { MapActions, MapSelectionTarget, PossibleMapActions } from 'app/models';

import block from 'utils/bem-css-modules';
import { MapActionIcons } from 'utils/maps/mapActionIcons';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';
import { formatMessage } from 'utils/messages/utils';

import style from './ActionBarV2.module.pcss';

const b = block(style);

export interface ActionBarV2Props {
  onClickAction: (action: MapActions) => void;
  possibleMapActions: PossibleMapActions;
  isMapLoading: boolean;
  selectionTarget: MapSelectionTarget;
  isMultipleRulesSelected: boolean;
  isLoading: boolean;
  isSmallVariant: boolean;
  isAllIsolatedTerritoriesSelected: boolean;
}

const ActionBarV2: FC<ActionBarV2Props> = ({
  onClickAction,
  possibleMapActions,
  isMapLoading,
  selectionTarget,
  isMultipleRulesSelected,
  isLoading,
  isSmallVariant,
  isAllIsolatedTerritoriesSelected
}: ActionBarV2Props) => {
  const checkMapCapable = useCheckMapCapable();

  const sharedMenuItemsProps = {
    isSmallVariant,
    onClickAction,
    isLoading,
    isMapLoading,
    possibleMapActions
  };

  const { isolateRules, unIsolateRules } = useLockAndIgnoreActions();
  const { unassignAccountText } = useDynamicMapHierarchyCopy();
  const [isLockingEnhancementsOn] = useTreatment(SplitFeatures.TERRITORY_LOCKING_ENHANCEMENTS);

  const isolateAction = isAllIsolatedTerritoriesSelected && isLockingEnhancementsOn ? unIsolateRules : isolateRules;

  const isolateRulesText =
    isLockingEnhancementsOn && isAllIsolatedTerritoriesSelected
      ? formatMessage('SHOW_ALL')
      : formatMessage('ISOLATE_SELECTED_TERRITORIES');

  const menuClassName = isSmallVariant ? b('dropdown') : b('bar');
  switch (selectionTarget) {
    case MapSelectionTarget.territories:
      return (
        <Menu className={menuClassName}>
          <MenuItemWithTooltip
            {...sharedMenuItemsProps}
            text={isolateRulesText}
            action={MapActions.Isolate}
            onClickAction={isolateAction}
          />
          {checkMapCapable(MapCapability.UNDO) && (
            <MenuItemWithTooltip {...sharedMenuItemsProps} action={MapActions.Undo} text={formatMessage('UNDO')} />
          )}
          {checkMapCapable(MapCapability.FIX_OVERLAP) && possibleMapActions?.FixConflict && (
            <MenuItemWithTooltip
              {...sharedMenuItemsProps}
              text={formatMessage('REMOVE_CONFLICT_BUTTON')}
              action={MapActions.FixConflict}
            />
          )}
          {checkMapCapable(MapCapability.DELETE_RULE) && (
            <MenuItemWithTooltip
              {...sharedMenuItemsProps}
              text={
                isMultipleRulesSelected
                  ? formatMessage('DELETE_MULTIPLE_TERRITORIES_TEXT')
                  : formatMessage('DELETE_TERRITORY_TEXT')
              }
              action={MapActions.Delete}
            />
          )}
        </Menu>
      );
    case MapSelectionTarget.polygons:
      return (
        <Menu className={menuClassName}>
          <MenuItemWithTooltip
            {...sharedMenuItemsProps}
            text={isolateRulesText}
            action={MapActions.Isolate}
            onClickAction={isolateAction}
          />
          {checkMapCapable(MapCapability.UNDO) && (
            <MenuItemWithTooltip {...sharedMenuItemsProps} action={MapActions.Undo} text={formatMessage('UNDO')} />
          )}
          {checkMapCapable(MapCapability.CREATE_RULE) && (
            <MenuItemWithTooltip
              {...sharedMenuItemsProps}
              text={formatMessage('CREATE_TERRITORY')}
              action={MapActions.Create}
            />
          )}
          {checkMapCapable(MapCapability.ASSIGN_TO_RULE) && (
            <MenuItemWithTooltip
              {...sharedMenuItemsProps}
              action={MapActions.Reassign}
              text={formatMessage('REASSIGN_GEOGRAPHIC_REGION')}
            />
          )}
          {checkMapCapable(MapCapability.UNASSIGN_FROM_RULE) && (
            <MenuItemWithTooltip
              {...sharedMenuItemsProps}
              action={MapActions.Unassign}
              text={formatMessage('UNASSIGN_GEO_TITLE')}
            />
          )}
        </Menu>
      );
    case MapSelectionTarget.accounts:
      return (
        <Menu className={menuClassName}>
          <MenuItemWithTooltip
            {...sharedMenuItemsProps}
            text={isolateRulesText}
            action={MapActions.Isolate}
            onClickAction={isolateAction}
          />
          {checkMapCapable(MapCapability.UNDO) && (
            <MenuItemWithTooltip {...sharedMenuItemsProps} action={MapActions.Undo} text={formatMessage('UNDO')} />
          )}
          {checkMapCapable(MapCapability.CREATE_RULE) && (
            <MenuItemWithTooltip
              {...sharedMenuItemsProps}
              text={formatMessage('CREATE_TERRITORY')}
              action={MapActions.Create}
            />
          )}
          {checkMapCapable(MapCapability.ASSIGN_TO_RULE) && (
            <MenuItemWithTooltip
              {...sharedMenuItemsProps}
              action={MapActions.Reassign}
              text={formatMessage('MAP_ACTION_MENU_REASSIGN_ACCOUNT')}
            />
          )}
          {checkMapCapable(MapCapability.UNASSIGN_FROM_RULE) && (
            <MenuItemWithTooltip {...sharedMenuItemsProps} action={MapActions.Unassign} text={unassignAccountText} />
          )}
        </Menu>
      );
  }
};

const MenuItemWithTooltip: FC<{
  action: MapActions;
  text: string;
  possibleMapActions: PossibleMapActions;
  isMapLoading: boolean;
  onClickAction: (action: MapActions) => void;
  isLoading: boolean;
  isSmallVariant: boolean;
}> = ({ isMapLoading, action, text, onClickAction, isLoading, isSmallVariant, possibleMapActions }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const isMenuItemDisabled = isMapLoading || !possibleMapActions?.[action];
  const icon = MapActionIcons[action];

  const handleShowTooltip = () => {
    setShowTooltip(true);
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <MessageTooltip
      content={text}
      disabled={isSmallVariant}
      placement="bottom"
      isOpen={showTooltip}
      target={
        <div onPointerOver={handleShowTooltip} onPointerLeave={handleHideTooltip}>
          <MenuItem
            onClick={() => onClickAction(action)}
            data-testid={`action-menu-item-${action.toLowerCase()}`}
            text={isSmallVariant ? text : ''}
            disabled={isMenuItemDisabled}
            icon={icon}
            textClassName={isLoading ? Classes.SKELETON : ''}
          />
        </div>
      }
    />
  );
};

export default React.memo(ActionBarV2);
