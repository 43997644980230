import gql from 'graphql-tag';

export const GET_TERRITORY_WORKFLOW_COMMENT_THREADS = gql`
  query GetTerritoryWorkflowCommentThreads(
    $battlecardId: Int!
    $deploymentModelId: Int!
    $startRow: Int!
    $endRow: Int!
  ) {
    getDeploymentModelSpec(
      input: {
        battlecardId: $battlecardId
        deploymentModelId: $deploymentModelId
        territoryWorkflowCommentThreads: { startRow: $startRow, endRow: $endRow }
      }
    ) {
      territoryWorkflowCommentThreads {
        items {
          topLevelComment {
            commentId
            content
            createdAt
            emailAddress
            firstName
            lastName
            memberId
            version
          }
          updatedAt
          threadId
          threadTags {
            alias
            name
            tagId
            threadTagId
            type
          }
          threadReplies {
            items {
              commentId
              content
              createdAt
              emailAddress
              firstName
              lastName
              memberId
              version
            }
            totalCount
          }
        }
        totalCount
      }
    }
  }
`;

export const GET_TERRITORY_WORKFLOW_COMMENT_THREAD_REPLIES = gql`
  query GetTerritoryWorkflowCommentThreadReplies(
    $battlecardId: Int!
    $deploymentModelId: Int!
    $startRow: Int!
    $endRow: Int!
    $threadId: Int!
  ) {
    getDeploymentModelSpec(
      input: {
        battlecardId: $battlecardId
        deploymentModelId: $deploymentModelId
        territoryWorkflowCommentThreads: {
          threadReplies: { startRow: $startRow, endRow: $endRow }
          threadId: $threadId
        }
      }
    ) {
      territoryWorkflowCommentThreads {
        items {
          threadId
          threadReplies {
            items {
              commentId
              createdAt
              content
              emailAddress
              firstName
              lastName
              version
              memberId
            }
            totalCount
          }
        }
        totalCount
      }
    }
  }
`;
