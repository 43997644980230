import { useCallback, useRef, useState } from 'react';

const isWiderThanScroll = (element: HTMLElement, padding: number): boolean => {
  return element?.scrollWidth > element?.parentElement?.clientWidth + padding;
};

const useIsWiderThanScroll = (
  padding = 0
): { showEllipsis: boolean; ref: (element: HTMLElement | undefined) => void } => {
  const [showEllipsis, setShowEllipsis] = useState(false);

  const dataRef = useRef<{ element: HTMLElement; observer: ResizeObserver }>(null);

  const callbackRef = useCallback(
    (element: HTMLElement | null) => {
      if (dataRef.current?.element === element) return;

      dataRef.current?.observer?.disconnect();

      if (!element) {
        dataRef.current = { element, observer: null };
        return;
      }

      setShowEllipsis(isWiderThanScroll(element, padding));
      const observer = new ResizeObserver(() => {
        return setShowEllipsis(isWiderThanScroll(element, padding));
      });
      observer.observe(element.parentElement);
      dataRef.current = { element, observer };
    },
    [padding]
  );

  return { showEllipsis, ref: callbackRef };
};

export default useIsWiderThanScroll;
