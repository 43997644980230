// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QzKpm {\n    align-items: center;\n    display: flex;\n    margin-left: 12px;\n    overflow: auto;\n  }\n\n    /* stylelint-disable-next-line no-descending-specificity */\n\n    .QzKpm .hierarchyButton, .QzKpm .hierarchyButton:not([class*='bp3-intent-']) {\n      margin-right: 8px;\n      background: white;\n      height: 18px;\n      padding: 8px !important;\n      border-radius: 18px !important;\n      min-width: unset;\n    }\n\n    .QzKpm .bp3-intent-primary.hierarchyButton, .QzKpm .hierarchyButton:not([class*='bp3-intent-']):focus, .QzKpm .hierarchyButton:not([class*='bp3-intent-']):active, .QzKpm .hierarchyButton:not([class*='bp3-intent-']):hover {\n      background: rgb(var(--color-cobalt-3)) !important;\n      color: white;\n    }\n  .DGXVP {\n    height: 32px\n  }\n  .DGXVP.bp3-divider {\n      margin: 0 12px;\n    }\n  .vd108 .bp3-overlay {\n      white-space: normal;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryGridActionButtons_hierarchyButtons": "QzKpm",
	"TerritoryGridActionButtons_mapToggleDivider": "DGXVP",
	"TerritoryGridActionButtons_mapToggle": "vd108"
};
export default ___CSS_LOADER_EXPORT___;
