import { formatCurrency, formatMessage } from 'utils/messages/utils';

export const planTargetsValueFormatter = (
  value: number,
  isCurrencyConversionValid: boolean,
  currency: string
): string => {
  if (!isCurrencyConversionValid) {
    return formatMessage('NOT_APPLICABLE_ABBREVIATION');
  } else if (value) {
    return formatCurrency(value, currency);
  } else {
    return formatMessage('HYPHEN');
  }
};
