// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-hyLF .bp3-button {\n      padding: 0 10px !important;\n    }\n  .zh0uM {\n    width: 100%;\n    height: auto;\n  }\n  .Kh6rn {\n    box-shadow: var(--elevation-2);\n    border-radius: 5px;\n    padding: 16px;\n    margin: 20px 0;\n    border-bottom: 1px solid rgb(var(--color-light-gray-3));\n  }\n  .ePiZS, ._2HU6B {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n  }\n  .ePiZS {\n    margin-top: 20%;\n  }\n  ._2HU6B {\n    width: 60%;\n    text-align: center;\n    line-height: 20px;\n    padding: 20px 0;\n  }\n  .Fs2vz {\n    font-weight: 600;\n    font-size: inherit;\n  }\n  .ytmLX {\n    border-top: 1px solid rgb(var(--color-light-gray-3));\n  }\n  .ytmLX textarea.bp3-input {\n      margin-top: 10px;\n      resize: none;\n    }\n  .jGVnb {\n    width: 50%;\n    height: auto;\n  }\n  .-Zn-G {\n    display: flex;\n    flex-flow: row wrap;\n    margin-top: 5px;\n    margin-bottom: 5px;\n  }\n  .bTQ1O {\n    margin-right: 6px;\n    margin-bottom: 6px;\n    padding: 4px 8px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommentPanelContent_addBtn": "-hyLF",
	"CommentPanelContent_commentBodyContainer": "zh0uM",
	"CommentPanelContent_commentContent": "Kh6rn",
	"CommentPanelContent_commentImgWrapper": "ePiZS",
	"CommentPanelContent_commentPlaceHolderText": "_2HU6B",
	"CommentPanelContent_noCommentText": "Fs2vz",
	"CommentPanelContent_replyForm": "ytmLX",
	"CommentPanelContent_commentImg": "jGVnb",
	"CommentPanelContent_tagsContainer": "-Zn-G",
	"CommentPanelContent_tagRow": "bTQ1O"
};
export default ___CSS_LOADER_EXPORT___;
