import { useMemo } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { GetPinSets_getDeploymentModelSpec_battlecards_pinSets } from 'app/graphql/generated/graphqlApolloTypes';
import { useGetPinSets } from 'app/graphql/queries/getPinSets';

interface Output {
  availablePinSets: GetPinSets_getDeploymentModelSpec_battlecards_pinSets[];
  isPinSetsLoading: boolean;
}

export const useGetAvailablePinSets = (): Output => {
  const { selectedDeploymentModelId, selectedBattleCardId } = useMapContextRedistributor();
  const { data, loading: isPinSetsLoading } = useGetPinSets({
    variables: {
      battlecardId: +selectedBattleCardId,
      deploymentModelId: selectedDeploymentModelId
    }
  });
  const availablePinSets = useMemo(() => data?.getDeploymentModelSpec?.battlecards?.[0]?.pinSets ?? [], [data]);
  return useMemo(() => ({ availablePinSets, isPinSetsLoading }), [availablePinSets, isPinSetsLoading]);
};
