// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a4KBF label, .a4KBF .bp3-control-group, .a4KBF .bp3-popover-target, .a4KBF .bp3-input {\n      width: 100% !important;\n    }\n    .a4KBF .bp3-input-group {\n      flex: 50%;\n    }\n  .qlUWr .bp3-overlay {\n    pointer-events: all !important;\n  }\n  .qlUWr .bp3-input-group {\n    display: flex;\n  }\n  .qbyqo .bp3-input {\n      width: 170px !important;\n    }\n  ._8qOmt .bp3-control-group {\n      display: block;\n    }\n  ._8qOmt .bp3-input-group {\n      align-items: center;\n    }\n  ._8qOmt .bp3-input-group > .bp3-icon {\n      top: auto;\n      top: initial;\n    }\n  ._8qOmt .bp3-control-group > :first-child {\n      border-radius: 0.25rem 0.25rem 0 0;\n    }\n  ._8qOmt .bp3-control-group > :last-child {\n      border-radius: 0 0 0.25rem 0.25rem;\n    }\n  .XbbQ2 {\n    height: 20px;\n    color: rgb(var(--color-red-3));\n    margin-top: 6px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormDateRangePicker__fullWidth": "a4KBF",
	"FormDateRangePicker": "qlUWr",
	"FormDateRangePicker__defaultWidth": "qbyqo",
	"FormDateRangePicker__stacked": "_8qOmt",
	"FormDateRangePicker_validationMessage": "XbbQ2"
};
export default ___CSS_LOADER_EXPORT___;
