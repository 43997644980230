import { ColDef } from '@ag-grid-community/core';

import { SheetsAddFieldCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/SheetsAddFieldCellRenderer/SheetsAddFieldCellRenderer';

import { SEASONALITY_RAMP_MONTH_REGEX } from 'app/global/variables';

import { ISheetDefinitionDef } from 'app/graphql/generated/graphqlApolloTypes';

import { GridHeaders } from 'app/models';

import { sheetDefinitionColumns } from 'utils/helpers/sheetsPreviewUtils';

const buildTerritorySheetColumnDef = (sheetDefinitions: ISheetDefinitionDef[]): Array<ColDef> => [
  {
    headerName: GridHeaders.TERRITORY_ID,
    field: 'territoryId',
    minWidth: 181,
    flex: 1
  },
  {
    headerName: GridHeaders.TERRITORY_NAME,
    field: 'territoryName',
    minWidth: 181,
    flex: 1
  },
  {
    headerName: GridHeaders.TERRITORY_EFFECTIVE_DATES,
    field: 'territoryEffectiveDates',
    minWidth: 300,
    flex: 1
  },
  ...sheetDefinitionColumns(
    sheetDefinitions.filter((sheetDef) => !SEASONALITY_RAMP_MONTH_REGEX.test(sheetDef.measureName))
  ),
  {
    maxWidth: 56,
    pinned: 'right',
    cellStyle: { border: 'none' },
    headerComponentFramework: SheetsAddFieldCellRenderer
  }
];

export default buildTerritorySheetColumnDef;
