// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mmCQF {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  line-height: 0;\n  cursor: pointer;\n  overflow: hidden;\n}\n\n  .mmCQF .bp3-menu-item {\n    /* cancels out line-height: 0 so menus are rendered properly */\n    line-height: normal;\n  }\n\n  .mmCQF .bp3-input {\n    width: -moz-fit-content;\n    width: fit-content;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RuleCellRenderer": "mmCQF"
};
export default ___CSS_LOADER_EXPORT___;
