// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yPs3h {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: var(--m);\n  height: 100%;\n  background: var(--white);\n  border-radius: 12px;\n}\n  .k-Enk {\n    display: flex;\n    grid-gap: var(--m);\n    margin-top: var(--m);\n  }\n  .JieG1 {\n  }\n  .kg4Q5 {\n    display: flex;\n    flex-direction: column;\n  }\n  .mtsoZ {\n    padding-top: var(--xs);\n  }\n  .EbH4H {\n    height: 32px;\n    width: 300px;\n    margin-top: var(--xs);\n  }\n  ._1QT5S {\n    margin-top: var(--xs);\n  }\n  .QxJ9Z {\n    padding: var(--m) 0;\n  }\n  ._9G07v {\n    display: flex;\n    justify-content: flex-end;\n  }\n  .iO0Ss {\n    color: rgb(var(--color-red-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ParametersAndTerritoryRules": "yPs3h",
	"ParametersAndTerritoryRules_section": "k-Enk",
	"ParametersAndTerritoryRules_subText": "JieG1 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["sub-text"] + "",
	"ParametersAndTerritoryRules_textContainer": "kg4Q5",
	"ParametersAndTerritoryRules_description": "mtsoZ",
	"ParametersAndTerritoryRules_dropdown": "EbH4H",
	"ParametersAndTerritoryRules_switch": "_1QT5S",
	"ParametersAndTerritoryRules_divider": "QxJ9Z",
	"ParametersAndTerritoryRules_buttonContainer": "_9G07v",
	"ParametersAndTerritoryRules_validationMessage": "iO0Ss"
};
export default ___CSS_LOADER_EXPORT___;
