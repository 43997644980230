import gql from 'graphql-tag';

export const GET_MEMBER_LIST = gql`
  query GetMemberList($endRow: Int!, $sorting: SortModel, $startRow: Int!, $tenantId: Int!) {
    getMemberList(input: { endRow: $endRow, sorting: $sorting, startRow: $startRow, tenantId: $tenantId }) {
      totalCount
      memberList {
        customProperties
        deleted
        emailAddress
        employeeId
        firstName
        jobTitle
        memberCreatedAt
        lastName
        memberId
        memberUpdatedAt
        systemRoleName
        tenantId
        memberInvitesInfo {
          memberInvitedAs
          memberInvitedAt
        }
      }
    }
  }
`;
