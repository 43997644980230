// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+0LGn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 60vh;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommentThreadLoading": "+0LGn"
};
export default ___CSS_LOADER_EXPORT___;
