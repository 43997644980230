import { FileType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

import { FileHeader } from './fileUploadProvider';

export const fileTypeToFileHeaderMap = new Map<FileType, FileHeader[]>();

const ACTIVITY_FILE_HEADERS: FileHeader[] = [
  {
    csvHeader: 'Customer Account ID',
    dbColumn: '_customer_account_id',
    columnRequired: true,
    columnType: 'Hierarchy',
    valueRequired: false,
    valueType: 'character varying'
  },
  {
    csvHeader: 'Geography ID',
    dbColumn: '_geography_id',
    columnRequired: true,
    columnType: 'Hierarchy',
    valueRequired: false,
    valueType: 'character varying'
  },
  {
    csvHeader: 'Date',
    dbColumn: '_adate',
    columnRequired: true,
    columnType: 'Activity',
    valueRequired: true,
    valueType: 'date'
  },
  {
    csvHeader: 'Sales',
    dbColumn: '_sales',
    columnRequired: true,
    columnType: 'Measure',
    valueRequired: true,
    valueType: 'numeric'
  },
  {
    csvHeader: 'Units Sold',
    dbColumn: '_units_sold',
    columnRequired: false,
    columnType: 'Measure',
    valueRequired: false,
    valueType: 'numeric'
  },
  {
    csvHeader: 'Quota Component',
    dbColumn: '_quota_component',
    columnRequired: true,
    columnType: 'Activity',
    valueRequired: true,
    valueType: 'character varying'
  },
  {
    csvHeader: 'Currency Code',
    dbColumn: '_currency_code',
    columnRequired: false,
    columnType: 'Activity',
    valueRequired: false,
    valueType: 'character varying'
  }
];

const CUSTOMER_ACCOUNT_HIERARCHY_FILE_HEADERS: FileHeader[] = [
  {
    csvHeader: 'Name',
    dbColumn: 'name',
    columnRequired: true,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: true,
    valueType: 'character varying'
  },
  {
    csvHeader: 'Key',
    dbColumn: 'key',
    columnRequired: true,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: true,
    valueType: 'character varying'
  },
  {
    csvHeader: 'ParentKey',
    dbColumn: 'parentkey',
    columnRequired: true,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: false,
    valueType: 'character varying'
  },
  {
    csvHeader: 'City',
    dbColumn: 'city',
    columnRequired: false,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: false,
    valueType: 'character varying'
  },
  {
    csvHeader: 'StateProvince',
    dbColumn: 'region',
    columnRequired: false,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: false,
    valueType: 'character varying'
  },
  {
    csvHeader: 'Address1',
    dbColumn: 'address1',
    columnRequired: false,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: false,
    valueType: 'text'
  },
  {
    csvHeader: 'Address2',
    dbColumn: 'address2',
    columnRequired: false,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: false,
    valueType: 'text'
  },
  {
    csvHeader: 'Country',
    dbColumn: 'country',
    columnRequired: false,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: false,
    valueType: 'character varying'
  },
  {
    csvHeader: 'ZipPostal',
    dbColumn: 'postalcode',
    columnRequired: false,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: false,
    valueType: 'character varying'
  },
  {
    csvHeader: 'Industry',
    dbColumn: 'industry',
    columnRequired: false,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: false,
    valueType: 'character varying'
  }
];

const GENERIC_HIERARCHY_FILE_HEADERS: FileHeader[] = [
  {
    csvHeader: 'Name',
    dbColumn: 'name',
    columnRequired: true,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: true,
    valueType: 'character varying'
  },
  {
    csvHeader: 'Key',
    dbColumn: 'key',
    columnRequired: true,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: true,
    valueType: 'character varying'
  },
  {
    csvHeader: 'ParentKey',
    dbColumn: 'parentkey',
    columnRequired: true,
    columnType: 'CustomerAccountHierarchy',
    valueRequired: false,
    valueType: 'character varying'
  }
];

const GENERIC_LOCATION_HEADERS: FileHeader[] = [
  {
    csvHeader: 'Name',
    dbColumn: 'name',
    columnRequired: false,
    columnType: 'Location',
    valueRequired: false,
    valueType: 'text'
  },
  {
    csvHeader: 'Country',
    dbColumn: 'country',
    columnRequired: true,
    columnType: 'Location',
    valueRequired: true,
    valueType: 'text'
  },
  {
    csvHeader: 'ZipPostal',
    dbColumn: 'zippostal',
    columnRequired: true,
    columnType: 'Location',
    valueRequired: true,
    valueType: 'text'
  },
  {
    csvHeader: 'Address1',
    dbColumn: 'address1',
    columnRequired: false,
    columnType: 'Location',
    valueRequired: false,
    valueType: 'text'
  },
  {
    csvHeader: 'Address2',
    dbColumn: 'address2',
    columnRequired: false,
    columnType: 'Location',
    valueRequired: false,
    valueType: 'text'
  },
  {
    csvHeader: 'StateProvince',
    dbColumn: 'stateprovince',
    columnRequired: false,
    columnType: 'Location',
    valueRequired: false,
    valueType: 'text'
  },
  {
    csvHeader: 'City',
    dbColumn: 'city',
    columnRequired: false,
    columnType: 'Location',
    valueRequired: false,
    valueType: 'text'
  },
  {
    csvHeader: 'Latitude',
    dbColumn: 'latitude',
    columnRequired: false,
    columnType: 'Location',
    valueRequired: false,
    valueType: 'real'
  },
  {
    csvHeader: 'Longitude',
    dbColumn: 'longitude',
    columnRequired: false,
    columnType: 'Location',
    valueRequired: false,
    valueType: 'real'
  }
];

fileTypeToFileHeaderMap.set(FileType.ACTIVITY, ACTIVITY_FILE_HEADERS);
fileTypeToFileHeaderMap.set(FileType.CUSTOMER_ACCOUNT_HIERARCHY, CUSTOMER_ACCOUNT_HIERARCHY_FILE_HEADERS);
fileTypeToFileHeaderMap.set(FileType.GEOGRAPHIC_TERRITORY_HIERARCHY, GENERIC_HIERARCHY_FILE_HEADERS);
fileTypeToFileHeaderMap.set(FileType.CUSTOM_HIERARCHY, GENERIC_HIERARCHY_FILE_HEADERS);
fileTypeToFileHeaderMap.set(FileType.LOCATION, GENERIC_LOCATION_HEADERS);

export const getFileUploadHeaders = (fileUploadType: FileType): string[] => {
  return fileTypeToFileHeaderMap.has(fileUploadType)
    ? fileTypeToFileHeaderMap
        .get(fileUploadType)
        .filter((fileHeader) => fileHeader.columnRequired)
        .map((fileHeader) => fileHeader.csvHeader)
    : null;
};

export const getFileUploadHeadersErrorMessage = (missingHeaders: string[]): string => {
  let message = '';
  missingHeaders.forEach((missingHeader, index) => {
    if (missingHeaders.length > 1) {
      if (index === 0) {
        message = message.concat(`${formatMessage('FILE_UPLOAD_HEADER_ERROR_PLURAL', { value: missingHeader })},`);
      } else if (index === missingHeaders.length - 1) {
        message = message.concat(` ${missingHeader}`);
      } else {
        message = message.concat(` ${missingHeader},`);
      }
    } else {
      message = formatMessage('FILE_UPLOAD_HEADER_ERROR_SINGLE', { value: missingHeader });
    }
  });
  return message;
};
