import gql from 'graphql-tag';

export const GET_ACTIVITIES_FOR_TERRITORY_GROUP = gql`
  query GetActivitiesForTerritoryGroup(
    $battlecardId: Int
    $territoryGroupId: Int!
    $quotaComponentId: Int!
    $startRow: Int
    $endRow: Int
  ) {
    getActivitiesForTerritoryGroup(
      input: {
        battlecardId: $battlecardId
        territoryGroupId: $territoryGroupId
        quotaComponentId: $quotaComponentId
        startRow: $startRow
        endRow: $endRow
      }
    ) {
      territoryActivitiesList
      territoryActivityCount
    }
  }
`;
