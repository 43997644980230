import {
  GET_SUBTREE_CUSTOM_HIERARCHIES,
  GET_SUBTREE_CUSTOMER_ACCOUNT_HIERARCHIES,
  GET_SUBTREE_GEOGRAPHIC_HIERARCHIES
} from 'app/graphql/queries/getSubtreeHierarchies';

import { HierarchyType } from 'app/models';

export const getSubtreeQueryLookupMap = {
  [HierarchyType.CustomerAccountHierarchy]: {
    queryName: 'getSubtreeCustomerAccountHierarchies',
    queryConstant: GET_SUBTREE_CUSTOMER_ACCOUNT_HIERARCHIES
  },
  [HierarchyType.GeographicTerritoryHierarchy]: {
    queryName: 'getSubtreeGeographicTerritoryHierarchies',
    queryConstant: GET_SUBTREE_GEOGRAPHIC_HIERARCHIES
  },
  [HierarchyType.CustomHierarchy]: {
    queryName: 'getSubtreeCustomHierarchies',
    queryConstant: GET_SUBTREE_CUSTOM_HIERARCHIES
  }
};
