import { useRouteMatch } from 'react-router-dom';

import { RoutePaths } from 'app/containers/App/Router/routePaths';

import useQueryParamState from 'app/hooks/useQueryParamState';

export interface EmbeddedMapSlugs {
  tenantSlug: string | null;
  planningCycleSlug: string | null;
}
export const useEmbeddedMapSlugs = (): EmbeddedMapSlugs => {
  const match = useRouteMatch<{ tenantSlug?: string }>(`${RoutePaths.EMBEDDED_MAP}/:tenantSlug`);

  const [planningCycleSlug] = useQueryParamState('planningCycleSlug');

  return { tenantSlug: match?.params?.tenantSlug ?? null, planningCycleSlug };
};
