import React, { Dispatch, SetStateAction, useState, useRef, MutableRefObject } from 'react';

import BattleCardNode from 'app/components/BattleCardDiagram/Canvas/BattleCardNode/BattleCardNode';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useRebalancing } from 'app/contexts/rebalancingProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { BattleCardDefinition } from 'app/models';
import { PendingSelectedBattleCardId } from 'app/models/index';

import block from 'utils/bem-css-modules';

import style from './BattleCardTree.module.pcss';

const b = block(style);

interface BattleCardTreeProps {
  setParentBattleCardId: Dispatch<SetStateAction<string | null>>;
  setShowAddBattleCardDialog: Dispatch<SetStateAction<boolean>>;
  cachedPanelCollapseStateByCard: Record<string, Record<string, boolean>>;
  setCachedPanelCollapseStateByCard: Dispatch<SetStateAction<Record<string, Record<string, boolean>>>>;
  rootCard: BattleCardDefinition | null;
  setEditingBattleCardId: Dispatch<SetStateAction<string | null>>;
  deploymentModelId: number;
  canvasBackgroundRef: MutableRefObject<HTMLDivElement>;
}

const BattleCardTree: React.FC<BattleCardTreeProps> = ({
  setParentBattleCardId,
  setShowAddBattleCardDialog,
  cachedPanelCollapseStateByCard,
  setCachedPanelCollapseStateByCard,
  rootCard,
  setEditingBattleCardId,
  deploymentModelId,
  canvasBackgroundRef
}: BattleCardTreeProps) => {
  const [hideBackgroundNodes, setHideBackgroundNodes] = useState(false);
  const battleCardTreeRef = useRef(null);
  const { selectedPillIdTDR, setSelectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { isMoved } = useRebalancing();
  const { selectedBattleCardId, setSelectedBattleCardId, expandedBattleCardId, setPendingSelectedBattleCardId } =
    useBattleCard();
  const { selectedPillIdPlanTargets, setSelectedPillIdPlanTargets } = usePlanTargets();

  const renderBattleCardNode = (battlecardList: BattleCardDefinition) => {
    return (
      <BattleCardNode
        setParentBattleCardId={setParentBattleCardId}
        setShowDialog={setShowAddBattleCardDialog}
        cachedPanelCollapseStateByCard={cachedPanelCollapseStateByCard}
        setCachedPanelCollapseStateByCard={setCachedPanelCollapseStateByCard}
        setEditingBattleCardId={setEditingBattleCardId}
        deploymentModelId={deploymentModelId}
        nodeData={battlecardList}
        hideBackgroundNodes={hideBackgroundNodes}
        setHideBackgroundNodes={setHideBackgroundNodes}
        data-testid={`battle-card-node-${battlecardList?.data?.battlecardId}`}
        canvasBackgroundRef={canvasBackgroundRef}
      />
    );
  };

  const renderBattleCard = (battlecardNodeData) => {
    return (
      <>
        {renderBattleCardNode(battlecardNodeData)}
        {battlecardNodeData.children.length ? (
          <ul className={b('treeNode')}>
            {(battlecardNodeData.children || []).map((battlecardChild) => {
              return (
                <li className={b('listNode')} key={battlecardChild.data.battlecardId}>
                  <span className={b('arrowNode')} />
                  {renderBattleCard(battlecardChild)}
                </li>
              );
            })}
          </ul>
        ) : null}
      </>
    );
  };

  return (
    <div ref={battleCardTreeRef} data-testid="battle-card-tree-canvas">
      {rootCard && (
        <ul className={b('tree', { obscured: !!hideBackgroundNodes })}>
          <li
            className={b('rootListNode')}
            onClick={(event) => {
              if (selectedBattleCardId && !expandedBattleCardId && !isMoved) {
                // deselect current battle card if selected
                setSelectedBattleCardId(null);
              } else if (selectedBattleCardId && !expandedBattleCardId && isMoved) {
                setPendingSelectedBattleCardId(PendingSelectedBattleCardId.NONE);
              }
              if ((selectedPillIdTDR || selectedPillIdPlanTargets) && !expandedBattleCardId) {
                // deselect current TD&R and plan targets pills if selected
                setSelectedPillIdTDR(null);
                setSelectedPillIdPlanTargets(null);
              }

              // Because we have an onClick handler on the entire battle card canvas,
              // we need to call event.stopPropagation() in BattleCard
              // so that the battle card's onClick handler can be triggered, and a battle card can be selected,
              // without bubbling up and triggering this handler (which would deselect it again)
              event.stopPropagation();
            }}
            data-testid="battle-card-tree-node"
          >
            {renderBattleCard(rootCard)}
          </li>
        </ul>
      )}
    </div>
  );
};

export default BattleCardTree;
