import React, { useEffect, useLayoutEffect, useState } from 'react';

import { GridApi, GridOptions, GridReadyEvent, SelectionChangedEvent } from '@ag-grid-community/core';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import buildContributorColumnDef from 'app/components/AdvancedGrid/GridHelpers/Contributor/ContributorColumnDef';
import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';
import {
  createLookupMap,
  getBattleCardCanvas
} from 'app/components/BattleCardDiagram/Canvas/BattleCardCanvas/battleCardCanvasUtils';

import { VIEWED_QUOTA_COMPONENT_TABS } from 'app/constants/ContributorConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useContributor } from 'app/contexts/contributorProvider';
import { useData } from 'app/contexts/dataProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { BCInfoLevelEnum } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { APPROVE_WORKFLOW } from 'app/graphql/mutations/approveWorkflow';
import { REJECT_WORKFLOW } from 'app/graphql/mutations/rejectWorkflow';
import { SUBMIT_WORKFLOW } from 'app/graphql/mutations/submitWorkflow';

import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useWindowSize from 'app/hooks/useWindowResize';

import { ContributorTreeData, DeploymentModelPhase, WorkflowStatus, WorkflowType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ContributorPage.module.pcss';
import {
  getContributorGridData,
  getContributorRowDataById,
  getContributorRowNodeId,
  getParentNodes
} from './contributorPageUtils/contributorPageUtils';

const b = block(style);

const expandActionParents = (actionParents: string[], gridApi: GridApi) => {
  // expand the parent node and make sure grid scrolls to the row that action happened
  actionParents?.forEach((nodeId) => {
    const node = gridApi.getRowNode(nodeId);
    node?.setExpanded(true);
  });
};

interface ContributorPageProps {
  forwardedRef: React.MutableRefObject<HTMLDivElement>;
}

const ContributorPage: React.FC<ContributorPageProps> = ({ forwardedRef }: ContributorPageProps) => {
  const {
    userProfile: { firstName, lastName, emailAddress }
  } = useUser();
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();
  const { isLocalCurrencyMode } = useLocalization();
  const {
    getContributorWorkflow,
    contributorWorkflow,
    contributorWorkflowLoading,
    contributorTerritoryGroupToBattlecardMapping
  } = useContributor();
  const { selectedBattleCardId, setSelectedBattleCardId, battleCardLookupMap, setBattleCardLookupMap } =
    useBattleCard();
  const { setSelectedPillIdPlanTargets } = usePlanTargets();
  const { trayHeight, setTrayOpen } = useDataTray();
  const { setIsQuotaAdjustmentEditable, setIsCommentEditable } = useGrid();
  const deploymentModelPhase = usePhase();
  const { sheetDefinitions } = useData();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();

  const showToast = useShowToast();
  const { windowHeight } = useWindowSize();

  const [gridApi, setGridApi] = useState<GridApi>();
  const [contributorTreeData, setContributorTreeData] = useState<ContributorTreeData[]>([]);
  const [actionParents, setActionParents] = useState<string[]>(null);
  const [actionNodeId, setActionNodeId] = useState<string>(null);
  const [contributorGridHeight, setContributorGridHeight] = useState<number>(0);
  const [isActionComplete, setIsActionComplete] = useState<boolean>(false);

  const containerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTrayOpen(true);
    // Need to refetch contributor tree data whenever workflow action changes
    if ((contributorTreeData.length === 0 && !contributorWorkflowLoading) || isActionComplete) {
      setContributorTreeData(getContributorGridData(contributorWorkflow));
    }
  }, [contributorWorkflowLoading, isActionComplete]);

  useEffect(() => {
    expandActionParents(actionParents, gridApi);
  }, [actionParents, gridApi]);

  useEffect(() => {
    if (isActionComplete && actionNodeId && !contributorWorkflowLoading) {
      handleRowUpdate();
      setActionNodeId(null);
      setIsActionComplete(false);
    }
  }, [contributorWorkflowLoading, actionNodeId, isActionComplete]);

  const handleRowUpdate = () => {
    const actionNode = gridApi.getRowNode(actionNodeId);
    if (actionNode) {
      actionNode.selectThisNode(true);
      actionNode.setData(
        getContributorGridData(contributorWorkflow).find(
          (gridItem) => gridItem.territoryGroupWorkflowId.toString() === actionNodeId.split('_')[0]
        )
      );
      gridApi.flashCells({
        rowNodes: [actionNode]
      });
    }
  };

  useLayoutEffect(() => {
    if (trayHeight) {
      const newHeight = (forwardedRef.current?.offsetHeight ?? 0) - trayHeight - 100; // 100 is to account for the gap between and also the title of the page
      setContributorGridHeight(newHeight);
    }
  }, [containerRef, trayHeight, forwardedRef, windowHeight]);

  const [submitWorkflow] = useMutation(SUBMIT_WORKFLOW, {
    onCompleted() {
      onSuccessfulAction();
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      onActionFailed();
    }
  });

  const [approveWorkflow] = useMutation(APPROVE_WORKFLOW, {
    onCompleted() {
      onSuccessfulAction();
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      onActionFailed();
    }
  });

  const [rejectWorkflow] = useMutation(REJECT_WORKFLOW, {
    onCompleted() {
      onSuccessfulAction();
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      onActionFailed();
    }
  });

  const onActionFailed = () => {
    showToast(formatMessage('UNABLE_TO_PROCESS_CONTRIBUTOR'), 'danger');
    setIsActionComplete(true);
  };

  const onSuccessfulAction = () => {
    getContributorWorkflow(selectedDeploymentModelId, selectedPlanningCycle?.id);
    setIsActionComplete(true);
  };

  const gridOptions: GridOptions = {
    suppressCellSelection: true,
    suppressMenuHide: true,
    animateRows: true,
    enableCellChangeFlash: false,
    treeData: true,
    autoGroupColumnDef: {
      headerName: formatMessage('TERRITORY_GROUP'),
      minWidth: 300,
      flex: 1,
      cellRendererParams: { suppressCount: true },
      menuTabs: [],
      colId: 'territoryGroupIdsPath',
      valueGetter: (params) => {
        return params?.data?.territoryGroupName;
      }
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    const gridApi = params.api;
    setGridApi(gridApi);
    getBattleCardData();

    // contributor with only TG access can also have a selected battle card
    // in this case, we need to make sure when they requesting data, they cant only provide the battle card id
    const isBCSelectedWithFullAccess =
      selectedBattleCardId && battleCardLookupMap?.[selectedBattleCardId]?.battlecardInfoLevel === BCInfoLevelEnum.all;

    if (actionNodeId) {
      const parsedActionNode = actionNodeId.split('_');
      const workflowId = parsedActionNode[0];
      const parentId = parsedActionNode[1] === 'null' ? null : parsedActionNode[1];
      const rootParentType = parsedActionNode[2];

      const actionItem = contributorTreeData?.find(
        (data) =>
          data.territoryGroupWorkflowId.toString() === workflowId &&
          data.parentTerritoryGroupId === parentId &&
          data.rootParentType.toString() === rootParentType
      );

      updateSelectedBCAndTG(actionItem);
      updateQuotaGridEditableStatus(actionItem);
    } else if (isBCSelectedWithFullAccess || selectedPillIdTDR) {
      const selectedRow = getContributorRowDataById(
        selectedPillIdTDR ? selectedPillIdTDR.toString() : selectedBattleCardId,
        !selectedPillIdTDR,
        contributorTreeData
      );
      if (selectedPillIdTDR) {
        setSelectedPillIdPlanTargets(selectedPillIdTDR);
      }
      gridApi.getRowNode(getContributorRowNodeId(selectedRow)).selectThisNode(true);
      handleActions(selectedRow);
    } else {
      const firstItem = contributorTreeData?.length ? contributorTreeData[0] : null;
      gridApi.getRowNode(getContributorRowNodeId(firstItem)).selectThisNode(true);
      updateQuotaGridEditableStatus(firstItem);
      updateSelectedBCAndTG(firstItem);
    }
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedRow = event.api.getSelectedRows()?.[0];
    updateQuotaGridEditableStatus(selectedRow);
    updateSelectedBCAndTG(selectedRow);
  };

  const getWorkflowVariables = (type: WorkflowType, territoryGroupWorkflowId: number) => {
    const variables =
      type === WorkflowType.BATTLECARD
        ? { battlecardWorkflowId: territoryGroupWorkflowId }
        : { territoryGroupWorkflowId };

    return variables;
  };

  const onSubmit = (data: ContributorTreeData) => {
    const { type, territoryGroupWorkflowId } = data;

    const variables = getWorkflowVariables(type, territoryGroupWorkflowId);
    submitWorkflow({ variables });
    handleActions(data);
  };

  const onApprove = (data: ContributorTreeData) => {
    const { type, territoryGroupWorkflowId } = data;

    const variables = getWorkflowVariables(type, territoryGroupWorkflowId);
    approveWorkflow({ variables });
    handleActions(data);
  };

  const onReject = (data: ContributorTreeData) => {
    const { type, territoryGroupWorkflowId } = data;

    const variables = getWorkflowVariables(type, territoryGroupWorkflowId);
    rejectWorkflow({ variables });
    handleActions(data);
  };

  const handleActions = (data: ContributorTreeData) => {
    updateQuotaGridEditableStatus(data);
    setActionParents(getParentNodes(data, contributorTreeData));
    setActionNodeId(getContributorRowNodeId(data));
  };

  const updateSelectedBCAndTG = (item: ContributorTreeData) => {
    if (!item) {
      return;
    }

    if (item.type === WorkflowType.BATTLECARD) {
      const battleCardId = item.territoryGroupId.replace('BC-', '');
      setSelectedBattleCardId(battleCardId);
      setSelectedPillIdPlanTargets('battlecard');
    } else {
      // Need to look for parent battlecardId of the territory group which will find the local currency codes
      // also setting the battlecardId helps to determine if the territory group has a drill in view or not
      // based off if the parent bc has existing quota breakdown hierarchy
      const territoryGroupId = item.territoryGroupId;
      setSelectedBattleCardId(contributorTerritoryGroupToBattlecardMapping?.get(territoryGroupId));
      setSelectedPillIdPlanTargets(territoryGroupId);
    }
  };

  const updateQuotaGridEditableStatus = (item: ContributorTreeData) => {
    if (!item) {
      return;
    }

    const hasChildren = !!contributorTreeData?.find((data) => data.parentTerritoryGroupId === item.territoryGroupId);

    // quota adjustment should be only editable for leaf nodes when their status is in progress and current user is the owner
    const isQuotaAdjustmentEditable =
      !hasChildren && item.status === WorkflowStatus.IN_PROGRESS && item.email === emailAddress;

    setIsQuotaAdjustmentEditable(isQuotaAdjustmentEditable);

    const approverEmail = contributorTreeData?.find(
      (treeData) => treeData.territoryGroupId === item.parentTerritoryGroupId
    )?.email;

    // comment should be editable when current user is the owner and the status is in-progress or
    // current user is the approver and the status is submitted
    const isCommentEditable =
      (item.status === WorkflowStatus.IN_PROGRESS && item.email === emailAddress) ||
      (item.status === WorkflowStatus.SUBMITTED && approverEmail === emailAddress);
    setIsCommentEditable(isCommentEditable);
  };

  const viewedQuotaComponentTabs = JSON.parse(localStorage.getItem(VIEWED_QUOTA_COMPONENT_TABS));

  // get quota components list from sheetDefinitions because quota components are referenced to sheets
  const unviewedQuotaComponentTabs = sheetDefinitions?.[0]?.quotaComponents?.filter(
    (quotaComponent) => !viewedQuotaComponentTabs?.includes(quotaComponent.quotaComponentId)
  );

  const getUnviewedQuotaComponentNames = () => {
    let unviewedQuotaComponentNames = '';

    unviewedQuotaComponentTabs.forEach((quotaComponent, i) => {
      const comma = i < unviewedQuotaComponentTabs?.length - 1 ? ', ' : '';
      unviewedQuotaComponentNames = unviewedQuotaComponentNames + quotaComponent.quotaComponentName + comma;
    });
    return unviewedQuotaComponentNames;
  };

  const allQuotaComponentTabsViewed = !unviewedQuotaComponentTabs?.length;

  const submitWarningMessage = !allQuotaComponentTabsViewed
    ? formatMessage('CONTRIBUTOR_QUOTA_COMPONENT_SUBMIT_WARNING', {
        value: getUnviewedQuotaComponentNames()
      })
    : formatMessage('CONTRIBUTOR_HIERARCHY_SUBMIT_WARNING');

  const getBattleCardData = async () => {
    if (Object.keys(battleCardLookupMap).length === 0) {
      try {
        const suppressCache = false;

        const isTQMEnabled = deploymentModelPhase === DeploymentModelPhase.plan ? false : true;
        // get battle card data
        const battleCardList = await getBattleCardCanvas(
          selectedDeploymentModelId,
          !isLocalCurrencyMode,
          suppressCache,
          isTQMEnabled
        );
        // create the battleCardLookupMap
        const battleCardLookupMap = createLookupMap(battleCardList);
        setBattleCardLookupMap(battleCardLookupMap);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className={b()} ref={forwardedRef} data-testid="contributor-page">
      <h3 className={b('contributorHeader')}>
        {formatMessage('QUOTA_ALLOCATION')}
        <b>
          {firstName} {lastName}
        </b>
      </h3>
      <div>
        <div
          className={b('container')}
          ref={containerRef}
          data-testid="contributor-grid-container"
          style={{ height: contributorGridHeight }}
        >
          <div className={`ag-theme-alpine ${b('grid')}`}>
            {contributorTreeData.length > 0 ? (
              <AdvancedGrid
                data={JSON.stringify(contributorTreeData)}
                columnDefs={buildContributorColumnDef(
                  contributorTreeData,
                  onApprove,
                  onReject,
                  onSubmit,
                  submitWarningMessage,
                  b('emailBlue')
                )}
                gridProps={gridOptions}
                noDataMessage={formatMessage('EMPTY_GRID')}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged}
                rowSelection="single"
                getDataPath={(data) => data?.territoryGroupIdsPath}
                getRowNodeId={(data) => {
                  return getContributorRowNodeId(data);
                }}
                gridWidth={containerRef?.current?.offsetWidth}
                gridHeight={containerRef?.current?.offsetHeight}
                data-testid="contributor-page-advanced-grid"
                groupDefaultExpanded={-1}
              />
            ) : (
              <GridLoading
                gridHeight={containerRef?.current?.offsetHeight}
                gridWidth={containerRef?.current?.offsetWidth}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributorPage;
