// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wES6C {\n  margin-left: -30px; /* align with the text in the column */\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddUserRoleStatusBar": "wES6C"
};
export default ___CSS_LOADER_EXPORT___;
