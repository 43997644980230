import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Placement, DropdownMenu as VDSDropdownMenu } from '@varicent/components';

interface DropdownMenuProps {
  content: React.ReactNode;
  target: React.ReactNode;
  tooltip?: string;
  placement?: Placement;
  onOpenChange?: (newState: boolean) => void;
  open?: boolean;
  maxWidth?: 'small' | 'medium' | 'large';
}

const DropdownMenu: React.FC<DropdownMenuProps> = (props: DropdownMenuProps) => {
  return <VDSDropdownMenu.Menu {...props} />;
};

export default DropdownMenu;
