// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FHi\\+n.bp3-overlay-enter-done {\n    /* TODO TQP-1414 Reuse .transition-animate from common classes\n    here instead of adding the properties manually */\n    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;\n  }\n\n.CommandCenterDrawerPortal .bp3-drawer {\n  margin-top: 60px;\n  max-width: 100% !important;\n  pointer-events: all;\n}\n\n.CommandCenterDrawerPortal .bp3-overlay {\n  margin-top: 61px;\n  max-width: 100% !important;\n  pointer-events: none;\n  z-index: 19; /* set z-index to 19 so that the overlay backdrop is behind the canvas header */\n}\n\n.CommandCenterDrawerPortal .bp3-overlay-backdrop {\n  background-color: var(--command-center-drawer-portal-backdrop);\n}\n\n.CommandCenterDrawerPortal .bp3-popover2 {\n  pointer-events: all;\n}\n\n/* to offset the margin-top which was given above to bp3-overlay */\n\n.bp3-popover2-target + .bp3-overlay {\n  margin: 0;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommandCenterDrawer_transition": "FHi+n"
};
export default ___CSS_LOADER_EXPORT___;
