// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fC51F {\n    display: flex;\n    width: 100%;\n    background-color: rgb(var(--color-light-gray-5));\n    align-items: center;\n    padding-left: 16px;\n    cursor: pointer;\n    border: none;\n  }\n  .yn-gT {\n    color: rgb(var(--color-cobalt-3));\n    font-size: 14px;\n    margin-right: 5px;\n  }\n  .XtZo2 {\n    color: rgb(var(--color-cobalt-3));\n    height: 100%;\n    display: flex;\n    align-items: center;\n  }\n  .RxOXN {\n    display: flex;\n  }\n  ._035Xo {\n    display: flex;\n    align-items: center;\n    padding: 0 var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DataTrayDrillInViewHeader_backButton": "fC51F",
	"DataTrayDrillInViewHeader_buttonText": "yn-gT",
	"DataTrayDrillInViewHeader_icon": "XtZo2",
	"DataTrayDrillInViewHeader_drillInHeader": "RxOXN",
	"DataTrayDrillInViewHeader_drillInHeaderText": "_035Xo"
};
export default ___CSS_LOADER_EXPORT___;
