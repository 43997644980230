import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetIsTerritoryIdAvailable,
  GetIsTerritoryIdAvailableVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_IS_TERRITORY_ID_AVAILABLE = gql`
  query GetIsTerritoryIdAvailable($territoryGroupId: Int!, $territoryId: String!) {
    getIsTerritoryIdAvailable(input: { territoryGroupId: $territoryGroupId, territoryId: $territoryId }) {
      foundInBattlecardId
      isAvailable
    }
  }
`;

export const useGetIsTerritoryIdAvailableLazy = createUseLazyQuery<
  GetIsTerritoryIdAvailable,
  GetIsTerritoryIdAvailableVariables
>(GET_IS_TERRITORY_ID_AVAILABLE);
