import React, { useState } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Checkbox } from '@blueprintjs/core';

import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { MeasureFieldType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './PermissionCheckboxCellRenderer.module.pcss';

const b = block(style);

interface PermissionCheckboxCellRendererProps extends ICellRendererParams {
  params: ICellRendererParams;
  measureFieldType: MeasureFieldType;
}

export const PermissionCheckboxCellRenderer: React.FC<PermissionCheckboxCellRendererProps> = ({
  params,
  measureFieldType
}: PermissionCheckboxCellRendererProps) => {
  const { value, setValue } = params;
  const editableFieldEnabled = measureFieldType === MeasureFieldType.INPUT;

  const [permissions, setPermissions] = useState({ visible: value?.visible, editable: value?.editable });

  const handleVisiblePermissionUpdate = () => {
    const newVisiblePermission = permissions.visible === 'Y' ? 'N' : 'Y';
    if (newVisiblePermission === 'N') {
      setValue({ visible: 'N', editable: 'N' });
      setPermissions({ visible: 'N', editable: 'N' });
    } else {
      setValue({ visible: 'Y', editable: 'N' });
      setPermissions({ visible: 'Y', editable: 'N' });
    }
  };

  const handleEditablePermissionUpdate = () => {
    const newEditablePermission = permissions.editable === 'Y' ? 'N' : 'Y';
    if (newEditablePermission === 'Y') {
      setValue({ visible: 'Y', editable: 'Y' });
      setPermissions({ visible: 'Y', editable: 'Y' });
    } else {
      setValue({ visible: 'Y', editable: 'N' });
      setPermissions({ visible: 'Y', editable: 'N' });
    }
  };

  return (
    <div className={b('permissionCell')} data-testid="permission-cells">
      <div>
        <Checkbox
          checked={permissions.visible === 'Y'}
          onChange={handleVisiblePermissionUpdate}
          disabled={false}
          data-testid="visible-checkbox"
        />
      </div>
      <div>
        <MessageTooltip
          content={formatMessage('DISABLED_CHECKBOX_TOOLTIP')}
          disabled={editableFieldEnabled}
          target={
            <Checkbox
              onChange={handleEditablePermissionUpdate}
              disabled={!editableFieldEnabled}
              checked={permissions.editable === 'Y'}
              data-testid="editable-checkbox"
            />
          }
          placement={'top'}
        />
      </div>
    </div>
  );
};

export default PermissionCheckboxCellRenderer;
