import { useEffect, useState, MutableRefObject } from 'react';

function useOutsideClick(ref: MutableRefObject<HTMLDivElement>): boolean {
  const [clickedOutside, setClickedOutside] = useState(false);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickedOutside(true);
      } else {
        setClickedOutside(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return clickedOutside;
}
export default useOutsideClick;
