import gql from 'graphql-tag';

export const ADD_QUOTA_COMPONENT_TO_BATTLE_CARD = gql`
  mutation addQuotaComponentToBattlecard($battlecardId: Int!, $quotaComponentId: Int!) {
    addQuotaComponentToBattlecard(input: { battlecardId: $battlecardId, quotaComponentId: $quotaComponentId }) {
      battlecardId
      quotaComponentId
    }
  }
`;
