// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0zxP3 {\n    height: calc(95vh - var(--header-height) - 30px);\n    width: 464px;\n    position: absolute;\n    background-color: white;\n    top: 120px;\n    right: 15px;\n    z-index: 18;\n    border-radius: 5px;\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);\n    padding: 16px;\n    overflow: scroll;\n  }\n  .ybrUu {\n    display: flex;\n    justify-content: space-between;\n    padding-bottom: 20px;\n  }\n  .NQ7zI {\n    font-weight: 600;\n    font-size: 24px;\n    color: rgb(var(--color-dark-gray-2));\n  }\n  .c3pv8 {\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n    padding-left: 20px;\n    justify-content: space-between;\n  }\n  .wbmaX, .j1UiS {\n    display: flex;\n    justify-content: center;\n  }\n  .j1UiS {\n    padding-right: 50px;\n  }\n  .lMVUz {\n    padding: 5px;\n    background-color: rgb(var(--color-cobalt-3));\n    display: flex;\n    justify-content: center;\n    border-radius: 5px;\n  }\n  .lMVUz .bp3-button > svg {\n        fill: white;\n        color: white;\n      }\n  .lMVUz .bp3-button:active, .lMVUz .bp3-button:hover {\n        background-color: transparent;\n      }\n  .lMVUz .bp3-button:active > svg, .lMVUz .bp3-button:hover > svg {\n          fill: white;\n          color: white;\n        }\n  .lMVUz .bp3-button.bp3-minimal > svg {\n        fill: white;\n        color: white;\n      }\n  .lMVUz .bp3-button.bp3-minimal:active, .lMVUz .bp3-button.bp3-minimal:hover {\n        background-color: transparent;\n      }\n  .lMVUz .bp3-button.bp3-minimal:active > svg, .lMVUz .bp3-button.bp3-minimal:hover > svg {\n          fill: white;\n          color: white;\n        }\n  .lMVUz:hover {\n    background-color: rgb(var(--color-cobalt-2));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommentPanel_commentPanelWrapper": "_0zxP3",
	"CommentPanel_commentHeaderContainer": "ybrUu",
	"CommentPanel_commentHeader": "NQ7zI",
	"CommentPanel_filterContainer": "c3pv8",
	"CommentPanel_filterGroup": "wbmaX",
	"CommentPanel_filterTitle": "j1UiS",
	"CommentPanel_commentIcon": "lMVUz"
};
export default ___CSS_LOADER_EXPORT___;
