import React, { useEffect, useRef, useState } from 'react';

import { EditableText } from '@blueprintjs/core';

import { NumberInputFormatStyle } from 'app/models';

import { getFormattedValue, getLastDigitPosition, numberUnformatter } from 'utils/helpers/formNumberFormattingUtils';

interface FormattedContentEditableNumberInputProps {
  value: number | '';
  placeHolder: string;
  formatStyle: NumberInputFormatStyle;
  currency: string;
  maxDigit?: number;
  onConfirm(value: number): void;
  onChange(value: number | ''): void;
  onCancel(): void;
}

const FormattedContentEditableNumberInput: React.FC<FormattedContentEditableNumberInputProps> = ({
  value,
  placeHolder,
  formatStyle,
  currency,
  maxDigit = 15,
  onConfirm,
  onChange,
  onCancel
}) => {
  const [maskedValue, setMaskedValue] = useState<string>(value?.toLocaleString());
  const [cursor, setCursor] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement>(null);

  // when currency changes, update field formatting and values
  useEffect(() => {
    updateValues(maskedValue);
  }, [currency]);

  const setInputCursorPosition = (value: string) => {
    setIsEditing(true);
    if (!value) {
      return;
    }

    const inputCursor = getLastDigitPosition(value, formatStyle, currency);
    setCursor(inputCursor);
  };

  const updateValues = (value) => {
    const calculatedValue = numberUnformatter(value);
    const calculatedMaskedValue = getFormattedValue(calculatedValue, currency, formatStyle);

    if (calculatedValue?.toString().length > maxDigit) {
      return null;
    }

    setMaskedValue(calculatedMaskedValue);

    return { calculatedValue, calculatedMaskedValue };
  };

  const handleOnChange = (inputValue) => {
    const updatedValues = updateValues(inputValue);

    if (!updatedValues) {
      return;
    }

    const { calculatedValue, calculatedMaskedValue } = updatedValues;

    const newValue = !calculatedValue || isNaN(calculatedValue) ? '' : calculatedValue;
    onChange(newValue);
    setInputCursorPosition(calculatedMaskedValue);
  };

  const handleOnConfirm = (value) => {
    let newConfirmedValue;
    if (!value) {
      newConfirmedValue = '';
    } else {
      newConfirmedValue = numberUnformatter(value);
    }
    setIsEditing(false);
    onConfirm(newConfirmedValue);
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEditing(true);

    setInputCursorPosition(maskedValue);
  };

  const handleOnCancel = () => {
    onCancel();
    setIsEditing(false);
  };

  useEffect(() => {
    const currentValue = value || '';
    if (value !== null && value !== undefined) {
      setMaskedValue(getFormattedValue(currentValue, currency, formatStyle));
    }
  }, [value]);

  useEffect(() => {
    if (inputRef && typeof inputRef === 'object' && inputRef.current && isEditing) {
      const input = inputRef.current.children[0].children[0] as HTMLInputElement; //sadly blueprint doesnt give a nice way to access the ref for input element, so this is the "hacky" way to get the element
      if (input?.setSelectionRange) {
        input.setSelectionRange(cursor, cursor);
      }
    }
  }, [cursor, isEditing]);

  return (
    <div onClick={(e) => handleOnClick(e)} ref={inputRef} data-testid={'editable-text-container'}>
      <EditableText
        value={maskedValue}
        placeholder={placeHolder}
        onChange={(value) => handleOnChange(value)}
        onConfirm={(value) => handleOnConfirm(value)}
        onCancel={handleOnCancel}
        minWidth={0}
      />
    </div>
  );
};

export default FormattedContentEditableNumberInput;
