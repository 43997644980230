import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { DropdownMenu as VDSDropdownMenu } from '@varicent/components';

interface DropdownMenuHeadingProps {
  children?: React.ReactElement;
}

const DropdownMenuHeading: React.FC<DropdownMenuHeadingProps> = (props: DropdownMenuHeadingProps) => {
  return <VDSDropdownMenu.Heading>{props.children} </VDSDropdownMenu.Heading>;
};

export default DropdownMenuHeading;
