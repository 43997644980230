import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import { config } from 'utils/config';

const Auth0ConnectorWrapper: React.FC = ({ children }): JSX.Element => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    if (appState?.returnTo?.pathname) {
      const { pathname, search } = appState.returnTo;
      history.push({ pathname, search });
    } else {
      history.push(window.location.pathname);
    }
  };
  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_SPA_CLIENT_ID}
      audience={config.APPSYNC_GRAPHQL_ENDPOINT}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ConnectorWrapper;
