// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+GjSQ {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n  }\n  .c3Ut9 {\n    width: 200px;\n    height: 32px;\n  }\n  .lRPzi {\n    display: flex;\n    height: 100%;\n    width: 100%;\n  }\n  .xRLD0 {\n    height: 100%;\n    width: 100%;\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n  }\n  .ZAWMu {\n      padding-right: 0;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ScenarioPlanningMap_scenarioPlanningPage": "+GjSQ",
	"ScenarioPlanningMap_selectMenu": "c3Ut9",
	"ScenarioPlanningMap_scenarioPlanningMapContainer": "lRPzi",
	"ScenarioPlanningMap_scenarioPlanningMap": "xRLD0",
	"ScenarioPlanningMap_scenarioPlanningMap__primaryMap": "ZAWMu"
};
export default ___CSS_LOADER_EXPORT___;
