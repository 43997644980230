// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".laXQi {\n    display: inline-flex;\n    justify-content: flex-end;\n  }\n  .ligLU {\n    height: 100%;\n    width: 100%;\n  }\n  .ligLU .ag-react-container {\n      display: flex;\n      justify-content: flex-end;\n    }\n  .djFk2 {\n  }\n  .\\+E93F {\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HierarchyQuotaDistribution_totalsHeader": "laXQi",
	"HierarchyQuotaDistribution_hierarchyQuotaDrillInWrapper": "ligLU",
	"HierarchyQuotaDistribution_gridOverlayContainer": "djFk2 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-container"] + "",
	"HierarchyQuotaDistribution_gridOverlayText": "+E93F " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-text"] + ""
};
export default ___CSS_LOADER_EXPORT___;
