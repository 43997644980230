import React from 'react';

import Dialog from 'components/Dialog/Dialog';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SearchableTreeDialog.module.pcss';

const b = block(style);

export interface SearchableTreeDialogProps {
  title: string;
  defaultComponent: React.ReactNode;
  searchComponent: React.ReactNode;
  isOpen: boolean;
  setSearchString: (params) => void;
  searchString: string;
  onSubmit: (params) => void;
  onClose: () => void;
  className?: string;
}

const SearchableTreeDialog: React.FC<SearchableTreeDialogProps> = ({
  isOpen,
  title,
  defaultComponent,
  searchComponent,
  className,
  setSearchString,
  searchString,
  onSubmit,
  onClose
}: SearchableTreeDialogProps) => {
  return (
    <Dialog
      className={className ?? b()}
      confirmButtonText={formatMessage('ADD')}
      title={title}
      onSubmit={onSubmit}
      onClose={onClose}
      isOpen={isOpen}
    >
      <input
        data-testid={'search-input'}
        className={b('searchInput')}
        onChange={(res) => {
          setSearchString(res.target.value);
        }}
      />
      {searchString && searchComponent}
      {!searchString && defaultComponent}
    </Dialog>
  );
};

export default SearchableTreeDialog;
