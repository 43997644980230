import { useEffect, useState, FC } from 'react';

import { useRouteMatch } from 'react-router-dom';

export const LockRoute: FC<{
  path: string;
  onceMatched: JSX.Element;
  fallback: JSX.Element;
}> = ({ path, onceMatched, fallback }) => {
  const match = useRouteMatch(path);
  const hasMatch = !!match;
  const [hasEverHadMatch, setHasEverHadMatch] = useState(hasMatch);

  useEffect(() => {
    if (hasMatch) setHasEverHadMatch(true);
  }, [hasMatch]);

  if (hasEverHadMatch) return onceMatched;

  return fallback;
};
