import React, { FC, useMemo } from 'react';

import { Select, MultiSelect } from '@varicent/components';

import { BasicSelectItem, SelectProps, MultiSelectProps } from './SelectPropsType';

export type BasicSelectProps<ValueType> = Omit<
  SelectProps<BasicSelectItem<ValueType>>,
  | 'id'
  // These are replaced by the value change variants
  | 'selectedItem'
  | 'onSelectedItemChange'
  // This variant is non-searchable for now
  | 'searchInputValue'
  | 'onSearchInputValueChange'
  // These are all managed here because the ItemType is known in advance
  | AutoGetters
> & {
  label: string;
  value: ValueType;
  onSelectedValueChange: (value: ValueType) => void;
  testId: string;
  placeholder?: string;
};

export function BasicSelect<T extends string | number>(props: BasicSelectProps<T>) {
  const { testId, items, value, onSelectedValueChange, ...selectProps } = props;
  const selectedItem = useMemo(() => items?.find((item) => item.value === value) ?? null, [items, value]);

  const itemsStrict = useMemo(() => items ?? [], [items]);

  return (
    <Select
      containerElementLookup=".bp3-overlay"
      id={`bsf-${testId}`} // Also used as infix to "data-test" values
      placement="auto-start"
      {...selectProps}
      items={itemsStrict}
      selectedItem={selectedItem}
      onSelectedItemChange={(item) => onSelectedValueChange(item && item.value)}
      getItemId={getters.getItemId}
      getItemText={getters.getItemText}
      getItemTooltip={getters.getItemTooltip}
      getIcon={getters.getIcon}
    />
  );
}

export type BasicMultiSelectProps<ValueType> = Omit<
  MultiSelectProps<BasicSelectItem<ValueType>>,
  'id' | 'selectedItems' | 'onSelectedItemsChange' | 'searchInputValue' | 'onSearchInputValueChange' | AutoGetters
> & {
  label: string;
  values: ReadonlyArray<ValueType>;
  onValuesChange: (value: ValueType[]) => void;
  testId: string;
  placeholder?: string;
};
export function BasicMultiSelect<T extends string | number>(props: BasicMultiSelectProps<T>) {
  const { testId, items, values, onValuesChange, ...selectProps } = props;

  const selectedItems = useMemo(() => {
    if (!values) return [];
    if (!items) return [];
    return items.filter((item) => values.includes(item.value));
  }, [items, values]);

  const itemsStrict = useMemo(() => items ?? [], [items]);

  return (
    <MultiSelect
      containerElementLookup=".bp3-overlay"
      id={`bmsf-${testId}`} // Also used as infix to "data-test" values
      placement="auto-start"
      {...selectProps}
      items={itemsStrict}
      selectedItems={selectedItems}
      onSelectedItemsChange={(items) => onValuesChange(items.map((i) => i.value))}
      getItemId={getters.getItemId}
      getItemText={getters.getItemText}
      getItemTooltip={getters.getItemTooltip}
      getIcon={getters.getIcon}
    />
  );
}

const getters = {
  getItemId: (item: BasicSelectItem<unknown>) => `${item?.value}`,
  getItemText: (item: BasicSelectItem<unknown>) => item.text,
  getItemTooltip: (item: BasicSelectItem<unknown>) => item.tooltip,
  getIcon: (item: BasicSelectItem<unknown>): FC => (item.icon ? () => <>{item.icon}</> : null)
} as const;
type AutoGetters = keyof typeof getters;
