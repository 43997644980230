// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qcWKh {\n  height: 48px;\n  background-color: rgb(var(--color-light-gray-5));\n  box-shadow: inset 0 -1px var(--border);\n  display: flex;\n  align-items: center;\n}\n\n  .KKWQl {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    align-items: center;\n    padding-right: var(--m);\n  }\n\n  .RDhSA {\n    margin-top: var(--m);\n    display: flex;\n    align-items: center;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n  }\n\n  .RDhSA .bp3-minimal {\n      background-color: transparent !important;\n    }\n\n  .N\\+0Um {\n    margin-bottom: var(--m);\n  }\n\n  .VhErC {\n    width: 278px;\n    height: 300px;\n    padding: var(--xs) var(--xs) 0 var(--xs);\n    border-radius: var(--xs);\n    margin-top: -16px;\n    display: flex;\n    flex-direction: column;\n    overflow: scroll;\n  }\n\n  .TkJxi {\n    padding: var(--s);\n  }\n\n  .gliac {\n    height: var(--xl);\n    width: 30px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .wzG1u {\n    margin-bottom: var(--xs);\n  }\n\n  .D\\+LAq {\n    background: white;\n    padding: 2px 7px;\n    border-radius: 50%;\n    margin-left: var(--xs);\n  }\n\n  .KBo2J {\n    display: flex;\n    justify-content: flex-end;\n    position: sticky;\n    background: white;\n    bottom: 0;\n    z-index: 1;\n    padding: var(--s) var(--xs);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountRuleHeader": "qcWKh",
	"AccountRuleHeader_container": "KKWQl",
	"AccountRuleHeader_tgtWrapper": "RDhSA",
	"AccountRuleHeader_filterButton": "N+0Um",
	"AccountRuleHeader_inputWrapper": "VhErC",
	"AccountRuleHeader_fieldWrapper": "TkJxi",
	"AccountRuleHeader_searchIcon": "gliac",
	"AccountRuleHeader_searchLabel": "wzG1u",
	"AccountRuleHeader_filterCount": "D+LAq",
	"AccountRuleHeader_applyFilterButton": "KBo2J"
};
export default ___CSS_LOADER_EXPORT___;
