import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetFileImportErrors, GetFileImportErrorsVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_FILE_IMPORT_ERRORS = gql`
  query GetFileImportErrors($planningCycleId: Int!, $lookupInput: LookupInput, $tableInput: TableInput) {
    getPlanningCycleSpec(
      input: { planningCycleId: $planningCycleId, lookupInput: $lookupInput, tableInput: $tableInput }
    ) {
      planningCycleId
      tables {
        tableList {
          published
          status
          tableDataType
          tableId
          tableName
          tableType
          messages
          createdAt
          user {
            emailAddress
          }
        }
        totalNumOfTables
      }
    }
  }
`;

export const useGetFileImportErrorsLazy = createUseLazyQuery<GetFileImportErrors, GetFileImportErrorsVariables>(
  GET_FILE_IMPORT_ERRORS
);
