import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Button, ButtonGroup } from '@varicent/components';

import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

interface ToggleSwitchProps {
  rightToggleLabel: string;
  leftToggleLabel: string;
  onLeftToggle: () => void;
  onRightToggle: () => void;
  currentSelection: boolean;
  isRightToggleLoading?: boolean;
  leftToggleIcon?: React.FunctionComponent;
  rightToggleIcon?: React.FunctionComponent;
  isLeftToggleDisabled?: boolean;
  isRightToggleDisabled?: boolean;
  leftTooltipContent?: string;
  rightTooltipContent?: string | JSX.Element;
  small?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  leftToggleLabel,
  rightToggleLabel,
  onLeftToggle,
  onRightToggle,
  currentSelection,
  isRightToggleLoading,
  leftToggleIcon = null,
  rightToggleIcon = null,
  isLeftToggleDisabled = false,
  isRightToggleDisabled = false,
  leftTooltipContent = '',
  rightTooltipContent = '',
  small = false
}: ToggleSwitchProps) => {
  const sharedButtonProps = { type: 'button', outlined: true } as const;
  return (
    <div data-testid="toggle-switch">
      <ButtonGroup>
        <MessageTooltip
          content={leftTooltipContent}
          disabled={!leftTooltipContent}
          placement={'top'}
          usePortal={false}
          target={
            <Button
              small={small}
              text={leftToggleLabel}
              icon={leftToggleIcon}
              data-testid="left-toggle"
              onClick={onLeftToggle}
              active={!currentSelection}
              disabled={isLeftToggleDisabled}
              {...sharedButtonProps}
            />
          }
        />
        <MessageTooltip
          content={rightTooltipContent}
          disabled={!isRightToggleDisabled}
          placement={'top'}
          usePortal={false}
          target={
            <Button
              small={small}
              icon={rightToggleIcon}
              text={rightToggleLabel}
              loading={isRightToggleLoading}
              data-testid="right-toggle"
              onClick={onRightToggle}
              active={currentSelection}
              disabled={isRightToggleDisabled}
              {...sharedButtonProps}
            />
          }
        />
      </ButtonGroup>
    </div>
  );
};

export default ToggleSwitch;
