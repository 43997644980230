// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E62Zv {\n  height: calc(100vh - var(--header-height));\n  width: calc(100vw - var(--command-center-menu-width));\n  position: fixed;\n  margin: 0 0 0 var(--command-center-menu-width);\n  display: flex;\n  flex-direction: column;\n}\n\n  ._2CHig {\n    width: calc(100vw - var(--command-center-menu-icon-only-width));\n    margin: 0 0 0 var(--command-center-menu-icon-only-width);\n    transform: translate3d(var(--command-center-menu-icon-only-width) 0 0);\n  }\n\n  ._2FaR7 {\n    transform: translate3d(100vw 0 0);\n  }\n\n  .kXFUk {\n    transform: translate3d(var(--command-center-menu-width) 0 0);\n    width: calc(100vw - var(--command-center-menu-width));\n  }\n\n  .vJltH {\n    --center-content-height: calc(100vh - var(--header-height) - var(--command-center-header-height));\n\n    height: var(--center-content-height);\n    background-color: rgb(var(--color-light-gray-5));\n    display: inline-flex;\n    min-width: 100%;\n    flex-direction: column;\n    border-left: var(--grey-border);\n  }\n\n  .na\\+5W {\n    height: var(--command-center-header-height);\n    width: 100%;\n    font-size: var(--ml);\n    font-weight: bold;\n    line-height: 1.2;\n    color: rgb(var(--color-dark-gray-3));\n    display: flex;\n    align-items: center;\n    border-left: var(--grey-border);\n    border-bottom: var(--grey-border);\n  }\n\n  .VwJeY {\n    width: var(--command-center-middle-panel-width);\n  }\n\n  .DMobb {\n    width: 100%;\n    background: white;\n  }\n\n  .UvdHH {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 var(--command-center-header-padding-right);\n    width: 100%;\n  }\n\n  .DtLvn {\n    margin-right: 20px;\n    flex-grow: 2;\n  }\n\n  .kI2-D {\n    border-right: var(--grey-border);\n    height: var(--command-center-header-height);\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommandCenterContent": "E62Zv",
	"CommandCenterContent__showWithIconOnlyMenu": "_2CHig " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["transition-animate"] + "",
	"CommandCenterContent__hide": "_2FaR7",
	"CommandCenterContent__showWithFullMenu": "kXFUk " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["transition-animate"] + "",
	"CommandCenterContent_content": "vJltH",
	"CommandCenterContent_contentHeader": "na+5W",
	"CommandCenterContent_contentHeader__half": "VwJeY",
	"CommandCenterContent_contentHeader__expanded": "DMobb",
	"CommandCenterContent_headerInnerItems": "UvdHH",
	"CommandCenterContent_title": "DtLvn",
	"CommandCenterContent_collapseButton": "kI2-D"
};
export default ___CSS_LOADER_EXPORT___;
