import React, { useEffect, useState } from 'react';

import { Collapse } from '@blueprintjs/core';
import { ChevronDown, Maximize } from '@carbon/icons-react';

import { AccordionData } from 'components/models';

import block from 'utils/bem-css-modules';

import style from './Accordion.module.pcss';

const b = block(style);

export interface AccordionProps {
  data: AccordionData[];
  showExpandButton?: boolean;
  allowMultipleOpenPanels?: boolean;
  collapseClassName?: string;
  panelHeaderClassName?: string;
  panelTitleClassName?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  data,
  showExpandButton = false,
  allowMultipleOpenPanels = false,
  panelHeaderClassName = '',
  collapseClassName = '',
  panelTitleClassName = ''
}: AccordionProps): JSX.Element => {
  const [panelOpenState, setPanelOpenState] = useState<Record<string, boolean>>();

  // Set the Panel State on render.
  useEffect(() => {
    let defaultPanelOpenStateObject: Record<string, boolean>;

    // If multiple panels are allowed to be open, then allow for them to be toggled open.
    if (allowMultipleOpenPanels) {
      defaultPanelOpenStateObject = data?.reduce((acc, currentPanel) => {
        acc[currentPanel.name] = currentPanel.isOpen;

        return acc;
      }, {});
    } else {
      // Only allow the first listed item (in order) to be opened if it is set to open.
      defaultPanelOpenStateObject = data?.reduce((acc, currentPanel) => {
        if (Object.keys(acc).length === 0 || !Object.values(acc).includes(true)) {
          acc[currentPanel.name] = currentPanel.isOpen;
        } else {
          acc[currentPanel.name] = false;
        }

        return acc;
      }, {});
    }

    setPanelOpenState(defaultPanelOpenStateObject);
  }, [data]);

  const togglePanelCollapse = (sectionName: string) => {
    setPanelOpenState((prevState) => {
      if (prevState[sectionName] === true) {
        // allow any panel to be collapsed
        return { ...prevState, [sectionName]: false };
      } else {
        if (allowMultipleOpenPanels) {
          // allow any panel to be expanded
          return { ...prevState, [sectionName]: true };
        } else {
          // only allow one panel can be open at a time
          const updatedPanelCollapseStateBySection = {};

          Object.keys(prevState).forEach((currentSection) => {
            updatedPanelCollapseStateBySection[currentSection] = currentSection === sectionName;
          });

          return { ...prevState, ...updatedPanelCollapseStateBySection };
        }
      }
    });
  };

  return (
    <>
      {panelOpenState &&
        data.map(
          (section, key) =>
            !section.hidePanel && (
              <div
                key={key}
                className={`${b('collapse')} ${collapseClassName}`}
                data-testid={section.name}
                id={section.name}
              >
                <div className={`${b('panelHeader')} ${panelHeaderClassName}`}>
                  <span className={b('panelTitleArea')}>
                    <span
                      className={`${b('panelCollapseIcon')} ${
                        panelOpenState[section.name] ? b('panelCollapseIcon__open') : b('panelCollapseIcon__closed')
                      }`}
                      onClick={() => togglePanelCollapse(section.name)}
                    >
                      <ChevronDown />
                    </span>
                    <h5
                      className={`${b('panelTitle')} ${panelTitleClassName}`}
                      onClick={() => togglePanelCollapse(section.name)}
                      data-testid="accordion-panel-title"
                    >
                      {section.header}
                      {section.subheader && <span className={b('panelSubTitle')}>{section.subheader}</span>}
                    </h5>
                  </span>
                  {showExpandButton && (
                    <span>
                      <Maximize />
                    </span>
                  )}
                </div>
                <Collapse
                  className={b('collapseContainer')}
                  key={`Collapse_${key}`}
                  isOpen={panelOpenState[section.name]}
                >
                  {section.component}
                </Collapse>
              </div>
            )
        )}
    </>
  );
};

export default Accordion;
