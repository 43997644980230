// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g8ti5 {\n  padding: 0.7rem;\n  background-color: rgb(var(--color-light-gray-5));\n  height: 100%;\n  margin-bottom: 0 !important;\n}\n\n  .g8ti5 .ag-row {\n    color: rgb(var(--color-gray-2));\n  }\n\n  .g8ti5 .ag-theme-alpine {\n    border-top: 0;\n    box-shadow: var(--elevated-box);\n    background-color: white;\n    overflow: auto;\n    width: 100%;\n    height: 100%;\n  }\n\n  .g8ti5 .bp3-minimal {\n    padding: 0;\n  }\n\n  .g8ti5 .ag-theme-alpine .ag-root-wrapper {\n    border: none !important;\n  }\n\n  .g8ti5 .ag-header-cell-label .ag-header-cell-text {\n    margin: 0 47px 0 0;\n    font-size: 14px;\n    font-weight: normal;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: 1.43;\n    letter-spacing: normal;\n    color: rgb(var(--color-gray-1));\n  }\n\n  .g8ti5 .ag-theme-alpine .ag-row {\n    cursor: pointer;\n    color: rgb(var(--color-gray-2));\n  }\n\n  .g8ti5 .ag-theme-alpine .ag-row-selected {\n    cursor: pointer;\n    background-color: rgba(var(--color-cobalt-5)) !important;\n  }\n\n  .T9AoG {\n    display: flex;\n    flex-direction: column;\n    margin: 28px 0;\n    height: 100%;\n  }\n\n  .e0NoM {\n    color: rgb(var(--color-dark-gray-3));\n  }\n\n  ._60k5y {\n    height: 100%;\n    width: 100%;\n  }\n\n  .mxLRO {\n    color: rgb(var(--color-cobalt-3));\n  }\n\n  ._4IP24 {\n    width: 100%;\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 0 10px 16px;\n    background-color: white;\n    box-shadow: var(--elevated-box);\n    border-bottom: 0;\n  }\n\n  .V7fz2 {\n    font-size: var(--ml);\n    color: rgb(var(--color-dark-gray-3));\n  }\n\n  .D2CdS {\n    align-items: center;\n    display: flex;\n    margin-right: 10px;\n    color: rgb(var(--color-gray-1));\n  }\n\n  .VJSEv {\n    height: 40px;\n    width: 100%;\n    margin: 4px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContributorPage": "g8ti5",
	"ContributorPage_container": "T9AoG",
	"ContributorPage_contributorHeader": "e0NoM",
	"ContributorPage_grid": "_60k5y",
	"ContributorPage_emailBlue": "mxLRO",
	"ContributorPage_tableHeader": "_4IP24",
	"ContributorPage_tableTitle": "V7fz2",
	"ContributorPage_tableAction": "D2CdS",
	"ContributorPage_gridLoading": "VJSEv"
};
export default ___CSS_LOADER_EXPORT___;
