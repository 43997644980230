import React from 'react';

import { Alignment, RadioGroup, Radio } from '@varicent/components';

import block from 'utils/bem-css-modules';

import style from './FormRadioGroup.module.pcss';

const b = block(style);

interface FormRadioGroupProps {
  inline?: boolean;
  disabled?: boolean;
  selectedRadio: string;
  setSelectedRadio: (option: string) => void;
  alignIndicator?: Alignment;
  radioOptions: {
    label: string;
    value: string;
  }[];
  name: string;
  label?: string;
}

const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  inline = true,
  disabled = false,
  selectedRadio,
  alignIndicator = Alignment.LEFT,
  radioOptions,
  label,
  name,
  setSelectedRadio
}: FormRadioGroupProps) => {
  return (
    <div className={b()} data-testid="form-radio-group">
      <RadioGroup
        disabled={disabled}
        label={label}
        onChange={(e) => setSelectedRadio(e.currentTarget.value)}
        selectedValue={selectedRadio}
        inline={inline}
        name={name}
      >
        {radioOptions.map((radio) => {
          return <Radio key={radio.label} alignIndicator={alignIndicator} label={radio.label} value={radio.value} />;
        })}
      </RadioGroup>
    </div>
  );
};

export default FormRadioGroup;
