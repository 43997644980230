import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { TDAByHierarchy, TDAByHierarchyVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_TDA_BY_HIERARCHY = gql`
  query TDAByHierarchy($battlecardId: Int!, $quotaComponentId: Int!, $territoryGroupId: Int, $sheetId: Int) {
    getTerritoryQuota(
      input: {
        battlecardId: $battlecardId
        quotaComponentId: $quotaComponentId
        territoryGroupId: $territoryGroupId
        sheetId: $sheetId
      }
    ) {
      quotaByHierarchy {
        measureId
        measureName
        measureValue
        hierarchyQuotaInfo {
          hierarchyId
          hierarchyName
          hierarchyQuota
        }
      }
    }
  }
`;

export const useGetTDAByHierarchyLazy = createUseLazyQuery<TDAByHierarchy, TDAByHierarchyVariables>(
  GET_TDA_BY_HIERARCHY
);
