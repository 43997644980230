// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3XYTW {\n  border-radius: 50%;\n}\n\n  ._3XYTW .bp3-button > svg {\n      fill: rgb(var(--color-cobalt-3));\n      color: rgb(var(--color-cobalt-3));\n    }\n\n  .TgvLh .bp3-button {\n      background: transparent !important;\n    }\n\n  .TgvLh .bp3-button > svg {\n        fill: rgb(var(--color-cobalt-3));\n        color: rgb(var(--color-cobalt-3));\n      }\n\n  .TgvLh .bp3-button > svg > path:last-child {\n          fill: white;\n        }\n\n  .TgvLh .bp3-button:hover > svg {\n        fill: rgb(var(--color-orange-3)) !important;\n        color: rgb(var(--color-orange-3)) !important;\n      }\n\n  ._3XYTW .bp3-button:hover > svg, ._3XYTW .bp3-button:active > svg {\n      fill: rgb(var(--color-cobalt-2));\n      color: rgb(var(--color-cobalt-2));\n    }\n\n  ._3XYTW .bp3-button.bp3-minimal:hover {\n    background: transparent;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RoundIconButton": "_3XYTW",
	"RoundIconButton__primary": "TgvLh"
};
export default ___CSS_LOADER_EXPORT___;
