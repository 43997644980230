// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2JAWB, .ljHlZ {\n    display: flex;\n    flex-direction: row;\n    position: relative;\n  }\n  .CCSkd, .YP1Ku {\n    flex: 1 1 auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    position: relative;\n    padding: 0 10px;\n  }\n  /* Parent-To-Child Line */\n  ._2JAWB::before {\n    content: '';\n    position: absolute;\n    top: -46px;\n    left: 50%;\n    border-left: 1px solid rgb(var(--color-dark-gray-3));\n    height: 23px;\n  }\n  /* Child-to-Parent Line */\n  .CCSkd::before {\n    content: '';\n    position: absolute;\n    top: -24px;\n    left: 50%;\n    height: 23px;\n    border-left: 1px solid rgb(var(--color-dark-gray-3));\n  }\n  /* Horizontal Line (base styles) */\n  .CCSkd::after {\n    content: '';\n    position: absolute;\n    top: -24px;\n    border-top: 1px solid rgb(var(--color-dark-gray-3));\n  }\n  /* Single node levels have no horizontal lines */\n  .CCSkd:only-child::after {\n    display: none;\n  }\n  /* Horizontal Line (left-most child) */\n  .CCSkd:first-of-type::after {\n    right: 0;\n    width: 50%;\n  }\n  /* Horizontal Line (right-most child) */\n  .CCSkd:last-of-type::after {\n    left: 0;\n    width: 50%;\n  }\n  /* Hide lines when obscured by expanded battlecard */\n  .rC6vy ._2JAWB::before, .rC6vy ._2JAWB::after, .rC6vy .CCSkd::before, .rC6vy .CCSkd::after {\n    display: none;\n  }\n  /* Horizontal Line (middle children) */\n  .CCSkd:not(:first-of-type):not(:last-of-type)::after {\n    width: 100%;\n  }\n  .H7awm {\n    position: absolute;\n    top: -7px;\n    border: 7px solid transparent;\n    border-bottom: 0;\n    border-right-color: transparent;\n    border-top-color: rgb(var(--color-dark-gray-3));\n    border-left-color: transparent;\n    display: block;\n    width: 0;\n    height: 0;\n    z-index: 1;\n    transform: scaleX(0.5) translate(5%, 0);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BattleCardTree_treeNode": "_2JAWB",
	"BattleCardTree_tree": "ljHlZ",
	"BattleCardTree_listNode": "CCSkd",
	"BattleCardTree_rootListNode": "YP1Ku",
	"BattleCardTree_tree__obscured": "rC6vy",
	"BattleCardTree_arrowNode": "H7awm"
};
export default ___CSS_LOADER_EXPORT___;
