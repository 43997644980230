import React from 'react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { useScope } from 'app/contexts/scopeProvider';

import usePhase from 'app/hooks/usePhase';

import { BattleCardData, BattlecardType, DeploymentModelPhase, SectionName, SelectedPlanningType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './PlanningButtonContainer.module.pcss';

const b = block(style);

interface PlanningButtonContainerProps {
  toggleViewExpandedCard: (event, cardId, panelSectionName) => void;
  cardData: BattleCardData;
}

const PlanningButtonContainer: React.FC<PlanningButtonContainerProps> = ({
  cardData,
  toggleViewExpandedCard
}: PlanningButtonContainerProps) => {
  const { selectedPlanningType } = useScope();
  const deploymentModelPhase = usePhase();
  const { isRootCard, battlecardId, battlecardType } = cardData;

  const isTerritoryPlanning = selectedPlanningType === SelectedPlanningType.TERRITORY_PLANNING;

  const showPlanTargets = (event) => toggleViewExpandedCard(event, battlecardId, SectionName.PLAN_TARGETS);
  const showTerritoryDefine = (event) => toggleViewExpandedCard(event, battlecardId, SectionName.TERRITORY_DEFINE);

  return (
    <div className={b('planningTypeButtonContainer')}>
      {isRootCard ? (
        <>
          {selectedPlanningType === SelectedPlanningType.QUOTA_PLANNING && (
            <TextButton
              text={formatMessage('QUOTA_WORKFLOW')}
              testId={'quota-workflow-button'}
              type="button"
              onClick={(event) => toggleViewExpandedCard(event, battlecardId, SectionName.WORKFLOW)}
            />
          )}
        </>
      ) : (
        <>
          {battlecardType !== BattlecardType.Rollup && (
            <>
              {deploymentModelPhase === DeploymentModelPhase.plan && (
                <CanUser
                  perform={UserAction.BATTLE_CARD_CANVAS_MODIFY}
                  yes={
                    <TextButton
                      text={isTerritoryPlanning ? formatMessage('PLAN_TERRITORIES') : formatMessage('PLAN_QUOTAS')}
                      testId={isTerritoryPlanning ? 'plan-territories-button' : 'plan-quotas-button'}
                      type="button"
                      onClick={isTerritoryPlanning ? showTerritoryDefine : showPlanTargets}
                    />
                  }
                  no={
                    <TextButton
                      text={isTerritoryPlanning ? formatMessage('VIEW_TERRITORIES') : formatMessage('VIEW_QUOTAS')}
                      testId={isTerritoryPlanning ? 'view-territories-button' : 'view-quotas-button'}
                      type="button"
                      onClick={isTerritoryPlanning ? showTerritoryDefine : showPlanTargets}
                    />
                  }
                />
              )}
              {deploymentModelPhase === DeploymentModelPhase.manage && (
                <TextButton
                  text={formatMessage('MANAGE')}
                  testId={'manage-button'}
                  type="button"
                  onClick={showPlanTargets}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PlanningButtonContainer;
