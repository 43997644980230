import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import { Classes, Dialog } from '@blueprintjs/core';
import { AddFilled, TrashCan } from '@carbon/icons-react';
import { Field, Form, Formik } from 'formik';

import Accordion from 'components/Accordion/Accordion';
import TextButton from 'components/Buttons/TextButton/TextButton';
import { AccordionData } from 'components/models';
import SelectMenu from 'components/SelectMenu/SelectMenu';

import FormTextArea from 'app/components/FormFields/FormTextArea/FormTextArea';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { getDeploymentModel } from 'app/containers/PlanningCycles/PlanningCyclePage/planningCyclePageUtils';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { handleError } from 'app/graphql/handleError';
import { UPSERT_QUOTA_COMPONENT } from 'app/graphql/mutations/upsertQuotaComponent';
import { useGetRootHierarchies } from 'app/graphql/queries/getRootHierarchies';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { HierarchyType, UpsertQuotaComponentDeleted } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './QuotaComponentsPage.module.pcss';
import validationsSchema from './validationSchema';

const b = block(style);

const QuotaComponentsPage: React.FC = () => {
  const {
    quotaComponentList,
    setQuotaComponentList,
    selectedQuotaComponentId,
    setSelectedQuotaComponentId,
    setShouldRefetchBattleCardDataImmediately
  } = useBattleCard();
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();
  const [accordionData, setAccordionData] = useState<AccordionData[]>([]);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);
  const [quotaComponentToDelete, setQuotaComponentToDelete] = useState<Record<string, unknown> | null>(null);
  const [addNewQuotaComponentMode, setAddNewQuotaComponentMode] = useState<boolean>(false);

  const [isQuotaTargetSettingEnabled] = useTreatment(SplitFeatures.QUOTA_TARGET_SETTING);
  const showToast = useShowToast();

  const setQuotaComponents = async (quotaComponentId, wasQuotaComponentDeleted) => {
    const deploymentModel = await getDeploymentModel(selectedDeploymentModelId, true);
    if (!deploymentModel) {
      showToast(formatMessage('DEPLOYMENT_MODEL_ERROR'), 'danger');
      return;
    }
    const newQuotaComponents = deploymentModel.quotaComponents;
    if (newQuotaComponents?.length) {
      if (wasQuotaComponentDeleted) {
        showToast(formatMessage('QUOTA_COMPONENTS_DELETED_SUCCESS'), 'success');

        // the selected quota component was deleted, so we need to "select" a new quota component
        if (selectedQuotaComponentId === quotaComponentId) {
          setSelectedQuotaComponentId(newQuotaComponents[0].quotaComponentId);
        }
      } else {
        showToast(formatMessage('QUOTA_COMPONENTS_UPDATE_SUCCESS'), 'success');
      }

      setQuotaComponentList(newQuotaComponents);
      setShouldRefetchBattleCardDataImmediately(true);
    }
  };

  const [upsertQuotaComponents, { loading: upsertQuotaComponentsLoading }] = useMutation(UPSERT_QUOTA_COMPONENT, {
    onCompleted(result) {
      const mutatedQuotaComponentId = result?.upsertQuotaComponent?.quotaComponentId;
      const wasQuotaComponentDeleted = result?.upsertQuotaComponent?.deleted === UpsertQuotaComponentDeleted.TRUE;

      setQuotaComponents(mutatedQuotaComponentId, wasQuotaComponentDeleted);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('QUOTA_COMPONENTS_UPDATE_ERROR'), 'danger');
    }
  });

  // Add a new Quota Component.
  const handleAddQuotaComponent = () => {
    const accordionDataToAppend = [...accordionData];

    accordionDataToAppend.push({
      name: `quota-component-accordion-header-new`,
      header: formatMessage('UNTITLED'),
      component: <QuotaComponentForm isNew />,
      isOpen: true
    });

    setAccordionData(accordionDataToAppend);
  };

  // Delete a Quota component.
  const handleDeleteQuotaComponent = async () => {
    await upsertQuotaComponents({
      variables: {
        deleted: UpsertQuotaComponentDeleted.TRUE,
        deploymentModelId: selectedDeploymentModelId,
        quotaComponentComment: '',
        quotaComponentName: quotaComponentToDelete.quotaComponentName,
        quotaComponentId: quotaComponentToDelete.quotaComponentId
      }
    });

    setShowConfirmDeleteDialog(false);
  };

  const QuotaComponentForm = ({
    quotaComponentName = '',
    quotaComponentComment = '',
    quotaComponentId = null,
    canDelete = false,
    isNew = false
  }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const initialValues = {
      quotaComponentName,
      quotaComponentComment: quotaComponentComment ?? '',
      quotaComponentId: quotaComponentId ?? ''
    };

    // If the Quota component exists, append the Quota component Id.
    if (!isNew && quotaComponentId) {
      initialValues['quotaComponentId'] = quotaComponentId;
    }

    const DeleteButton = () => {
      return (
        <TextButton
          testId={'delete-button'}
          type="button"
          icon={<TrashCan />}
          text={formatMessage('DELETE')}
          disabled={!canDelete}
          tooltipText={canDelete ? '' : formatMessage('DELETE_QUOTA_COMPONENT_DISABLED_TEXT')}
          onClick={() => {
            if (quotaComponentId) {
              setShowConfirmDeleteDialog(true);
              setQuotaComponentToDelete({
                quotaComponentName,
                quotaComponentComment,
                quotaComponentId
              });
            }
          }}
          minimal
          intent="danger"
        />
      );
    };

    return (
      <Formik
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationsSchema}
        onSubmit={async (values) => {
          setLoading(true);

          const variables = {
            deleted: UpsertQuotaComponentDeleted.FALSE,
            deploymentModelId: selectedDeploymentModelId,
            quotaComponentComment: values.quotaComponentComment ?? '',
            quotaComponentName: values.quotaComponentName
          };

          // If the Quota component exists, append the Quota component Id to the variables object.
          if (!isNew && quotaComponentId) {
            variables['quotaComponentId'] = values.quotaComponentId;
          }

          await upsertQuotaComponents({
            variables
          });

          setLoading(false);
        }}
      >
        {({ isValid }) => {
          return (
            <Form className={b('form')}>
              <div className={b('quotaComponentInput')}>
                <Field type="hidden" name="quotaComponentId" />
                <Field
                  isRequired
                  placeHolder={formatMessage('UNTITLED')}
                  component={FormTextInputGroup}
                  label={formatMessage('NAME')}
                  name="quotaComponentName"
                  type="text"
                />
              </div>
              <div className={b('quotaComponentInput')}>
                <Field
                  placeholder={formatMessage('ADD_DESCRIPTION_PLACEHOLDER')}
                  enableMaxLength
                  component={FormTextArea}
                  label={formatMessage('DESCRIPTION')}
                  name="quotaComponentComment"
                  type="text"
                />
              </div>
              <div className={b('footer')}>
                <div className={b('buttons')}>
                  <div>{<DeleteButton />}</div>
                  <div>
                    <TextButton
                      testId={'save-button'}
                      text={formatMessage('SAVE')}
                      type="submit"
                      loading={loading}
                      intent="primary"
                      disabled={!isValid}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const { data: hierarchies, loading: loadingHierarchies } = useGetRootHierarchies({
    variables: { planningCycleId: selectedPlanningCycle?.id },
    skip: !selectedPlanningCycle?.id
  });
  const hierarchyItems =
    hierarchies?.getRootHierarchies
      .filter((hierarchy) => hierarchy.hierarchyType === HierarchyType.CustomHierarchy)
      .map((hierarchy) => ({
        key: hierarchy.rootName,
        value: hierarchy.rootHierarchyId
      })) ?? [];

  // Initialize the Quota Component forms.
  useEffect(() => {
    let accordionDataToSet = [];

    // Populate the Accordion with existing Quota Components. If there are none, then initialize with a default Quota Component.
    if (quotaComponentList?.length > 0) {
      accordionDataToSet = quotaComponentList.map((quotaComponent, i) => {
        return {
          name: `quota-component-accordion-header-${quotaComponent.quotaComponentId}`,
          header: quotaComponent.quotaComponentName,
          component: (
            <QuotaComponentForm
              canDelete={quotaComponent.canDelete}
              quotaComponentName={quotaComponent.quotaComponentName}
              quotaComponentId={quotaComponent.quotaComponentId}
              quotaComponentComment={quotaComponent.quotaComponentComment}
            />
          ),
          isOpen: i === 0
        };
      });
    }

    setAccordionData(accordionDataToSet);
  }, [quotaComponentList]);

  return (
    <div className={b('')}>
      <div>
        <Accordion collapseClassName={b('quotaComponentsAccordion')} allowMultipleOpenPanels data={accordionData} />
      </div>
      {!loadingHierarchies && (
        <div data-testid="target-setting-section-wrapper">
          {isQuotaTargetSettingEnabled && hierarchyItems.length > 0 && (
            <div className={b('targetSettingSection')} data-testid="target-setting-section">
              <Formik initialValues={{ targetSetting: null }} onSubmit={null}>
                {() => (
                  <Form>
                    <label className={`${b('label')} ${Classes.LABEL}`} data-testid="target-setting-label">
                      {formatMessage('TARGET_SETTING')}
                    </label>
                    <Field
                      name="targetSetting"
                      items={hierarchyItems}
                      component={SelectMenu}
                      theme="default"
                      placeHolderText={formatMessage('SELECT')}
                      helpText={formatMessage('TARGET_SETTING_DESCRIPTION')}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      )}
      <div className={b('addQuotaComponent')}>
        <TextButton
          testId={'add_button'}
          type="button"
          icon={<AddFilled />}
          text={formatMessage('ADD_NEW_QC')}
          disabled={addNewQuotaComponentMode}
          onClick={() => {
            setAddNewQuotaComponentMode(true);
            handleAddQuotaComponent();
          }}
          minimal
        />
      </div>
      {showConfirmDeleteDialog && quotaComponentToDelete && (
        <Dialog
          data-testid="confirm-dialog-delete"
          isOpen
          portalClassName={b('')}
          onClose={() => {
            setQuotaComponentToDelete(null);
          }}
        >
          <div>
            <h4>{formatMessage('DELETE_QUOTA_COMPONENT')}</h4>
            <p className={b('dialogTitle')}>
              {formatMessage('CONFIRM_DELETE_QUOTA_COMPONENT', {
                name: `${quotaComponentToDelete.quotaComponentName}`
              })}
            </p>
            <div className={b('footer')}>
              <div className={b('buttons')}>
                <TextButton
                  testId={'cancel-button'}
                  text={formatMessage('CANCEL')}
                  type="button"
                  onClick={() => {
                    setQuotaComponentToDelete(null);
                  }}
                />
                <div>
                  <TextButton
                    testId={'confirm-dialog-delete-button'}
                    text={formatMessage('DELETE')}
                    type="submit"
                    intent="danger"
                    loading={upsertQuotaComponentsLoading}
                    onClick={async () => await handleDeleteQuotaComponent()}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default QuotaComponentsPage;
