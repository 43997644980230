import { useBattleCard } from 'app/contexts/battleCardProvider';

import { BattlecardType } from 'app/models';

export const useIsOverlayBattleCardSelected = (): boolean => {
  const { selectedBattleCardId, battleCardLookupMap } = useBattleCard();

  const battleCardType: BattlecardType = battleCardLookupMap?.[selectedBattleCardId]?.battlecardType;
  const isOverlayBattleCardSelected =
    battleCardType === BattlecardType.DirectOverlay || battleCardType === BattlecardType.IndirectOverlay;

  return isOverlayBattleCardSelected;
};
