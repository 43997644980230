import gql from 'graphql-tag';

export const UPSERT_CONVERSION_RATES = gql`
  mutation UpsertConversionRates($upsertConversionRatesInput: [UpsertConversionRatesInput]!) {
    upsertConversionRates(input: $upsertConversionRatesInput) {
      canDelete
      currencyHierarchyId
      planningCycleId
      reportingCurrencyCode
      reportingCurrencyName
      localCurrencies {
        localCurrencyName
        localCurrencyCode
        currencyHierarchyId
        conversionRate
        canDelete
      }
    }
  }
`;
