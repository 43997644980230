import {
  MutationTuple,
  // eslint-disable-next-line no-restricted-imports
  useMutation
} from '@apollo/client';

import {
  DeleteSymonPipeConfigurations,
  DeleteSymonPipeConfigurationsVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { DELETE_SYMON_PIPE_CONFIGURATIONS } from 'app/graphql/mutations/deleteSymonPipeConfigurations';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useDeleteSymonPipeConfigurations = (): MutationTuple<
  DeleteSymonPipeConfigurations,
  DeleteSymonPipeConfigurationsVariables
> =>
  useMutation<DeleteSymonPipeConfigurations, DeleteSymonPipeConfigurationsVariables>(DELETE_SYMON_PIPE_CONFIGURATIONS, {
    onCompleted(data) {
      if (data?.deleteSymonPipeConfigurations) {
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('DELETE_SYMON_PIPE_CONFIGURATION_SUCCESS'), 'success');
      }
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('DELETE_SYMON_PIPE_CONFIGURATION_ERROR'), 'danger');
    }
  });
