import { useEffect, useMemo } from 'react';

import { findPublishedDeploymentModel } from 'app/containers/PlanningCycles/PlanningCyclePage/planningCyclePageUtils';

import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { DeploymentModelTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { useChangeScenario } from 'app/hooks/useChangeScenario';
import usePhase from 'app/hooks/usePhase';
import { useSearchParam } from 'app/hooks/useSearchParam';

import { DeploymentModelPhase, DeploymentModelSpec } from 'app/models';

type NullishDm = DeploymentModelSpec | null | undefined;

type RelevantDms = {
  selected: NullishDm;
  plan: NullishDm;
  manage: NullishDm;
  planPublished: NullishDm;
  planFromUrl: NullishDm;
};

export const useDeploymentModelRouting = (): RelevantDms => {
  const { selectedPlanningCycle } = useScope();
  const scenarioNameFromUrl = useSearchParam('scenario');
  const relevantDms = useFindRelevantDms(selectedPlanningCycle?.id, scenarioNameFromUrl);
  const selectedDm = relevantDms.selected;

  useRemoveIgnoredScenarioUrl(selectedDm, scenarioNameFromUrl);

  return relevantDms;
};

const useFindRelevantDms = (selectedPcId: number | undefined, scenarioNameFromUrl: string): RelevantDms => {
  const { userPlanningCycles, userRole } = useUser();
  const phaseFromUrl = usePhase();
  return useMemo(() => {
    const dmsInPc = userPlanningCycles?.find((pc) => pc.planningCycleId === selectedPcId)?.deploymentModels ?? [];

    const relevantDms: RelevantDms = {
      selected: null,
      plan: null,
      manage: dmsInPc.find((dm) => dm.deploymentModelType === DeploymentModelTypeEnum.Manage),
      planPublished: findPublishedDeploymentModel(dmsInPc),
      planFromUrl: dmsInPc.find(
        (dm) =>
          dm?.deploymentModelType === DeploymentModelTypeEnum.Plan && dm.deploymentModelName === scenarioNameFromUrl
      )
    };

    if (phaseFromUrl === DeploymentModelPhase.manage) {
      relevantDms.selected = relevantDms.manage;
      relevantDms.plan = relevantDms.planPublished;
    } else if (relevantDms.planFromUrl) {
      relevantDms.selected = relevantDms.planFromUrl;
      relevantDms.plan = relevantDms.planFromUrl;
    } else {
      relevantDms.selected = relevantDms.planPublished;
      relevantDms.plan = relevantDms.planPublished;
    }
    return relevantDms;
  }, [selectedPcId, userPlanningCycles, scenarioNameFromUrl, phaseFromUrl, userRole]);
};

const useRemoveIgnoredScenarioUrl = (selectedDm: DeploymentModelSpec, scenarioNameFromUrl: string) => {
  const changeScenario = useChangeScenario();
  const shouldRemoveSlugFromUrl =
    selectedDm &&
    scenarioNameFromUrl &&
    (selectedDm.isPublished || scenarioNameFromUrl !== selectedDm.deploymentModelName);
  useEffect(() => {
    if (shouldRemoveSlugFromUrl) changeScenario(null);
  }, [shouldRemoveSlugFromUrl]);
};
