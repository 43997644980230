// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ILEEG {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n  .C8key {\n    max-width: 80%;\n  }\n\n  .D\\+trM {\n    margin: var(--m) 0;\n  }\n\n  ._9YvH0 {\n    display: flex;\n    justify-content: center;\n    margin: var(--m) 0;\n  }\n\n  .ybmfm {\n    display: flex;\n    justify-content: center;\n    margin-top: var(--l);\n  }\n\n  /* stylelint-disable plugin/selector-bem-pattern */\n\n  .ybmfm > span:not(:first-of-type) {\n        margin-left: var(--xs);\n      }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryOptimizationPage": "ILEEG",
	"TerritoryOptimizationPage_container": "C8key",
	"TerritoryOptimizationPage_subtitleContainer": "D+trM",
	"TerritoryOptimizationPage_imageContainer": "_9YvH0",
	"TerritoryOptimizationPage_buttonContainer": "ybmfm"
};
export default ___CSS_LOADER_EXPORT___;
