import { useState, useEffect } from 'react';

interface Size {
  windowWidth: number;
  windowHeight: number;
}

const getWindowSize = () => ({
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight
});

// Hook
const useWindowSize = (): Size => {
  const [windowSize, setWindowSize] = useState(getWindowSize);

  useEffect(() => {
    const handleResize = () => setWindowSize(getWindowSize);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

export default useWindowSize;
