import React from 'react';

import { Close } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import taskOptimization from 'assets/svgs/task-optimization.svg';

import style from './TerritoryOptimizationPublishPage.module.pcss';

const b = block(style);

const TerritoryOptimizationPublishPage: React.FC = () => {
  const { optimizationTarget, setTerritoryOptimizationDrawerState, selectedOptimizationMetric } =
    useTerritoryOptimization();

  return (
    <>
      <div className={b()} data-testid="territory-optimization-publish-page">
        <div className={b('container')}>
          <HTMLHeading
            bold
            tagLevel="h3"
            text={formatMessage('PUBLISHED_OPTIMIZATION_TITLE', { territoryGroup: optimizationTarget?.name })}
          />
          <div className={b('subTitle')} data-testid="territory-optimization-publish-page-subtitle">
            {selectedOptimizationMetric
              ? formatMessage('OPTIMIZATION_PUBLISH_SUBTITLE', {
                  selectedOptimizationMetric: selectedOptimizationMetric.key
                })
              : formatMessage('OPTIMIZATION_PUBLISH_SUBTITLE_GENERAL')}
          </div>
          <div className={b('imageContainer')}>
            <img src={taskOptimization} alt="task-optimization" />
          </div>
          <div className={b('buttonContainer')}>
            <TextButton
              text={formatMessage('EXIT_OPTIMIZATION')}
              icon={<Close />}
              intent="primary"
              type={'button'}
              large={false}
              testId={'territory-optimization-exit-button'}
              onClick={() => setTerritoryOptimizationDrawerState(null)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TerritoryOptimizationPublishPage;
