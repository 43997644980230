// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w7Fyn {\n    display: flex;\n    flex-direction: column;\n    grid-row-gap: 10px;\n    row-gap: 10px;\n  }\n  .boJmP {\n    display: flex;\n    margin: 15px 0;\n  }\n  .tGPcl {\n    color: rgb(var(--color-gold-3));\n    position: absolute;\n    left: 15px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ActivatePlanningCycleDialog_explanationPage": "w7Fyn",
	"ActivatePlanningCycleDialog_warningMessage": "boJmP",
	"ActivatePlanningCycleDialog_paddedIcon": "tGPcl"
};
export default ___CSS_LOADER_EXPORT___;
