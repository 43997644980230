import { LocationDescriptor } from 'history';

import { integrationTabQueryParamKey } from 'app/components/IntegrationPanel/useIntegrationTabQueryParamState';

import { IntegrationTabIds } from 'app/containers/Symon/SymonTypes';

import { commandCenterActiveMenuQueryKey } from 'app/contexts/commandCenterProvider';

import { CommandCenterMenuItem, EmbeddedAuthSavedAppState } from 'app/models';

function maybeJson<T>(jsonText: string): T {
  try {
    return JSON.parse(jsonText);
  } catch {
    return null;
  }
}

interface ExpectedQueryParamState {
  extras?: {
    SP_APP_STATE?: EmbeddedAuthSavedAppState;
  };
}

export const SP_APP_STATE_KEY = 'SP_APP_STATE';
export const getAppStateFromQuery = (searchParams: URLSearchParams): EmbeddedAuthSavedAppState | null => {
  const stateRaw = searchParams.get('state');
  if (!stateRaw) return null;

  const stateParsed = maybeJson<ExpectedQueryParamState>(stateRaw);
  if (!stateParsed) return null;

  const appState = stateParsed?.extras?.[SP_APP_STATE_KEY];
  if (!appState) return null;

  return appState;
};

export const createLocationFromSavedAppState = ({
  tenantSlug,
  planningCycleSlug,
  deploymentModelPhase
}: EmbeddedAuthSavedAppState): LocationDescriptor => {
  const pathParts = [tenantSlug, planningCycleSlug];
  if (deploymentModelPhase) pathParts.push(deploymentModelPhase);

  const params = new URLSearchParams();
  params.set(commandCenterActiveMenuQueryKey, CommandCenterMenuItem.INTEGRATION);
  params.set(integrationTabQueryParamKey, IntegrationTabIds.SYMON_IMPORT);
  params.set('view', 'territory');

  return {
    pathname: `/${pathParts.join('/')}`,
    search: params.toString()
  };
};

export const createRestrictedOauthQueryParams = (sourceParams: URLSearchParams): URLSearchParams => {
  const restrictedParams = new URLSearchParams();
  sourceParams.forEach((value, key) => {
    if (allowedOauthQueryKeys.has(key)) restrictedParams.set(key, value);
  });
  return restrictedParams;
};

// List of all allowed query params, sourced from:
// https://www.iana.org/assignments/oauth-parameters/oauth-parameters.xhtml#parameters
const allowedOauthQueryKeys = new Set([
  'access_token',
  'ace_client_recipientid',
  'ace_profile',
  'ace_server_recipientid',
  'acr_values',
  'actor_token_type',
  'actor_token',
  'assertion',
  'aud',
  'audience',
  'claim_token',
  'claims_locales',
  'claims',
  'client_assertion_type',
  'client_assertion',
  'client_id',
  'client_secret',
  'cnf',
  'code_challenge_method',
  'code_challenge',
  'code_verifier',
  'code',
  'device_code',
  'display',
  'error_description',
  'error_uri',
  'error',
  'exp',
  'expires_in',
  'grant_type',
  'iat',
  'id_token_hint',
  'id_token',
  'iss',
  'issued_token_type',
  'jti',
  'login_hint',
  'max_age',
  'nbf',
  'nfv_token',
  'nonce',
  'nonce1',
  'nonce2',
  'password',
  'pct',
  'pct',
  'prompt',
  'redirect_uri',
  'refresh_token',
  'registration',
  'req_cnf',
  'request_uri',
  'request',
  'requested_token_type',
  'resource',
  'response_mode',
  'response_type',
  'rpt',
  'rs_cnf',
  'scope',
  'session_state',
  'state',
  'sub',
  'subject_token_type',
  'subject_token',
  'ticket',
  'token_type',
  'ui_locales',
  'upgraded',
  'username',
  'vtr'
]);
