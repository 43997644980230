import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

export const useAccountFirstPinsWarningThreshold = (): number => {
  const [_isFlagOn, { config: accountMapWarningConfig }] = useTreatment(SplitFeatures.ACCOUNT_MAP_WARNING);

  if (!accountMapWarningConfig) return null;

  try {
    return parseInt(JSON.parse(accountMapWarningConfig)?.accountFirstPinsWarningThreshold);
  } catch {
    return null;
  }
};
