import { FC, ReactNode } from 'react';

interface TabProps {
  id: string | number;
  title?: ReactNode;
  disabled?: boolean;
  panel?: JSX.Element;
  className?: string;
  panelClassName?: string;
}

// Used here as a dummy component in order to pass it's props to the VDS Tab element inside the Tabs component
// This is done to ensure the VDS Tab is a direct child of VDS Tabs and renders correctly
const Tab: FC<TabProps> = () => null;

// In order to distinguish between tab and non-tab children inside Tabs and render them appropriately
Tab.displayName = 'SalesPlanning.Tab';

export default Tab;
