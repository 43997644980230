import React from 'react';

import { Spinner } from '@blueprintjs/core';

import block from 'utils/bem-css-modules';

import style from './BattleCardLoading.module.pcss';

const b = block(style);

const BattleCardLoading: React.FC = () => {
  return (
    <div className={b()} data-testid="battle-card-loading">
      <div className={b('container')}>
        <div data-testid="spinner">
          <Spinner intent="primary" size={40} />
        </div>
      </div>
    </div>
  );
};

export default BattleCardLoading;
