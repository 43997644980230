import throttle from 'lodash.throttle';

interface SortByPrecedenceProps {
  precedence?: number;
}

const isPrimitive = (obj) => {
  return obj !== Object(obj);
};

// Types for deepEqual are conflicting, which requires this to be eslint disabled.
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
export const deepEqual = (obj1, obj2): boolean => {
  if (obj1 === obj2)
    // it's just the same object. No need to compare.
    return true;
  else if (!obj1 || !obj2)
    // at least one item is falsy but they are not equal
    return false;

  if (isPrimitive(obj1) && isPrimitive(obj2))
    // compare primitives
    return obj1 === obj2;

  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

  // compare objects with same number of keys
  for (const key in obj1) {
    if (!(key in obj2)) return false; //other object doesn't have this prop

    if (!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};

export const sortByPrecedence = (nodeA: SortByPrecedenceProps, nodeB: SortByPrecedenceProps): number => {
  if (nodeA.precedence && nodeB.precedence) return nodeA.precedence - nodeB.precedence;
  else return 0;
};

export const memberToString = (
  member: { firstName?: string; lastName?: string; employeeId?: string },
  showEmployeeId = true
): string => {
  if (member && member.firstName && member.lastName) {
    if (showEmployeeId) {
      return `${member.employeeId ? `(${member.employeeId}) ` : ''}${member.lastName}, ${member.firstName}`;
    }
    return `${member.firstName} ${member.lastName}`;
  } else {
    return '';
  }
};

export const getLeafNodes = (node, leafNodes) => {
  if (!node.children || !node.children.length) {
    leafNodes.push(node);
  }

  // iterate over children, calling getLeafNodes recursively
  if (node.children && node.children.length) {
    for (let i = 0; i < node.children.length; i++) {
      getLeafNodes(node.children[i], leafNodes);
    }
  }
};

export const asyncThrottle = (func, wait) => {
  const throttled = throttle((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);
  return (...args) =>
    new Promise((resolve, reject) => {
      throttled(resolve, reject, args);
    });
};
