import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetTerritoryOptimizationPrerequisites,
  GetTerritoryOptimizationPrerequisitesVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_TERRITORY_OPTIMIZATION_PREREQUISITES = gql`
  query GetTerritoryOptimizationPrerequisites(
    $planningCycleId: Int!
    $deploymentModelId: Int!
    $quotaComponentId: Int!
    $battlecardId: Int!
    $territoryGroupId: Int!
  ) {
    getDeploymentModelSpec(
      input: {
        deploymentModelId: $deploymentModelId
        battlecardId: $battlecardId
        quotaComponentId: $quotaComponentId
        territoryGroupId: $territoryGroupId
      }
    ) {
      sheetMeasures {
        measureId
        measureName
        measureFormatType
      }
    }
    getRootHierarchies(planningCycleId: $planningCycleId, version: 1) {
      hierarchyType
      rootHierarchyId
      attributes {
        name
        type
        editable
      }
    }
    getNumberOfTerritoriesInTerritoryGroup(battlecardId: $battlecardId, territoryGroupId: $territoryGroupId) {
      numOfTerritories
    }
  }
`;

export const useGetTerritoryOptimizationPrerequisites = createUseQuery<
  GetTerritoryOptimizationPrerequisites,
  GetTerritoryOptimizationPrerequisitesVariables
>(GET_TERRITORY_OPTIMIZATION_PREREQUISITES);
