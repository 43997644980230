import { useMemo } from 'react';

import { useScope } from 'app/contexts/scopeProvider';

import { useGetRootHierarchies } from 'app/graphql/queries/getRootHierarchies';

import useShowToast from 'app/hooks/useShowToast';

import { HierarchyType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export const useGetRootCustomHierarchiesInfo = (skip: boolean) => {
  const { selectedPlanningCycle } = useScope();
  const showToast = useShowToast();

  const rootHierarchies = useGetRootHierarchies({
    variables: { planningCycleId: selectedPlanningCycle?.id },
    skip: !selectedPlanningCycle?.id || skip,
    onError() {
      showToast(formatMessage('HIERARCHY_ERROR'), 'danger');
    }
  });
  const rootCustomHierarchies = useMemo(
    () =>
      rootHierarchies.data?.getRootHierarchies
        ?.filter((hierarchy) => hierarchy.hierarchyType === HierarchyType.CustomHierarchy)
        .map(({ rootHierarchyId, rootKey, rootName }) => ({ rootHierarchyId, rootKey, rootName })) ?? [],
    [rootHierarchies.data]
  );

  return { rootCustomHierarchies, rootHierarchies, rootHierarchiesLoading: rootHierarchies.loading };
};
