import { KeyValue } from 'components/models';

import { ExpandedTerritoryGroupDefineAndRefinePillData, FilterInput } from 'app/models';

export const formatTerritoryGroupItems = (
  tdrBattlecardLookupMap: Record<string, ExpandedTerritoryGroupDefineAndRefinePillData>,
  searchString: string
): KeyValue<string>[] => {
  return Object.values(tdrBattlecardLookupMap ?? {})
    .filter(
      (territoryGroup) =>
        territoryGroup.children.length === 0 &&
        territoryGroup.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
    )
    .map((filteredTerritoryGroup) => ({
      key: filteredTerritoryGroup.name,
      value: filteredTerritoryGroup.territoryGroupId
    }));
};

export const formatStaticInitialValues = (territoryFilters: FilterInput): Record<string, string> => {
  const staticInitialValues = {};
  for (const key in territoryFilters) {
    staticInitialValues[key] = territoryFilters[key].filter;
  }
  return staticInitialValues;
};
