// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1T7-l {\n  display: flex;\n  align-items: center;\n  flex: 1;\n  justify-content: right;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConfigurationActionsCellRenderer": "_1T7-l"
};
export default ___CSS_LOADER_EXPORT___;
