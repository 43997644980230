import React, { FC, useEffect } from 'react';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

export const BootstrapDisabler: FC = ({ children }) => {
  const [shouldDisableBootstrap] = useTreatment(SplitFeatures.REMOVE_BOOTSTRAP);
  useEffect(() => {
    findBootstrapAndSetDisabledTo(shouldDisableBootstrap);
  }, [shouldDisableBootstrap]);
  return <>{children}</>;
};

const findBootstrapAndSetDisabledTo = (isDisabled: boolean) => {
  const stylesheets = document.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]');
  stylesheets.forEach((sheet) => {
    if (sheet.href?.endsWith('/bootstrap.min.css')) {
      sheet.disabled = isDisabled;
    }
  });
};
