// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CYebk {\n    display: flex;\n    align-items: center;\n  }\n  .lIJ4i {\n    color: rgb(var(--color-cobalt-3));\n    cursor: pointer\n  }\n  .lIJ4i:hover {\n      text-decoration: underline;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DownloadQuotaFileUploadTemplate_linkContainer": "CYebk",
	"DownloadQuotaFileUploadTemplate_linkText": "lIJ4i"
};
export default ___CSS_LOADER_EXPORT___;
