import React, { FC } from 'react';

import Dialog from 'components/Dialog/Dialog';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import useShowToast from 'app/hooks/useShowToast';

import { RuleChangeCause, RuleChangeCauses } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

import { useMapUndo } from './hooks/useMapUndo';

interface MapUndoDialogProps {
  onClose: () => void;
  onTerritoryUpdated: (cause: RuleChangeCause) => void;
}

export const MapUndoDialog: FC<MapUndoDialogProps> = ({ onTerritoryUpdated, onClose }) => {
  const showToast = useShowToast();

  const [undoState, performUndo] = useMapUndo({
    onComplete: () => {
      showToast(formatMessage('MAP_UNDO_TOAST_SUCCESS'), 'success');
      onTerritoryUpdated(RuleChangeCauses.FinishUndo);
      onClose();
    },
    onError: () => {
      showToast(
        <ToastMessage
          title={formatMessage('MAP_UNDO_TOAST_ERROR_TITLE')}
          message={formatMessage('MAP_UNDO_TOAST_ERROR_BODY')}
        />,
        'danger'
      );
      onTerritoryUpdated(RuleChangeCauses.FailedUndo);
      onClose();
    }
  });
  return (
    <Dialog
      isOpen
      onClose={onClose}
      onSubmit={performUndo}
      title={formatMessage('UNDO')}
      confirmButtonText={formatMessage('UNDO')}
      confirmButtonLoading={undoState.isLoading}
      showOverflow
      size="small"
      bodyMinHeight={0}
    >
      {formatMessage('MAP_UNDO_BODY')}
    </Dialog>
  );
};
