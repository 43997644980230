import React from 'react';

import { WatsonHealthStackedMove } from '@carbon/icons-react';
import { Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';

import { AccountRuleRow } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

import OverrideReassignDialogBody from './OverrideReassignDialogBody';
import SimpleReassignDialogBody from './SimpleReassignDialogBody';
import { useAccountReassign } from './useAccountReassign';

export interface AccountReassignDialogProps {
  currentTerritoryGroupTypeId: number;
  onClose: () => void;
  onSubmit: () => void;
  selectedAccountRules: AccountRuleRow[];
}

export interface AccountReassignFormValues {
  targetRule: {
    key: string;
    value: string;
  };
}

const AccountReassignDialog: React.FC<AccountReassignDialogProps> = ({
  currentTerritoryGroupTypeId,
  onClose,
  onSubmit,
  selectedAccountRules
}) => {
  const {
    toggleDynamicReassignPreference,
    reassignHierarchiesDynamically,
    didSimpleReassignFail,
    shouldAlwaysDynamicReassign
  } = useAccountReassign({
    onSubmit,
    currentTerritoryGroupTypeId
  });

  const title = didSimpleReassignFail ? formatMessage('OVERRIDE_ACCOUNTS_TITLE') : formatMessage('MOVE_ACCOUNTS');

  const initialValues: AccountReassignFormValues = {
    targetRule: {
      key: '',
      value: ''
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: AccountReassignFormValues) =>
        reassignHierarchiesDynamically(+values.targetRule.value, selectedAccountRules)
      }
    >
      {({ handleSubmit, values, isSubmitting }) => {
        return (
          <Dialog
            isOpen
            onClose={onClose}
            onSubmit={handleSubmit}
            title={title}
            cancelButtonText={formatMessage('CANCEL')}
            confirmButtonText={formatMessage('MOVE')}
            confirmButtonIcon={<WatsonHealthStackedMove />}
            confirmButtonLoading={isSubmitting}
            disableConfirm={!values.targetRule.value}
            showOverflow
            size={'small'}
            data-testid="account-reassign-dialog"
            bodyMinHeight={0}
          >
            {didSimpleReassignFail ? (
              <OverrideReassignDialogBody
                isChecked={shouldAlwaysDynamicReassign}
                onChange={toggleDynamicReassignPreference}
              />
            ) : (
              <SimpleReassignDialogBody territoryGroupId={currentTerritoryGroupTypeId} />
            )}
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default AccountReassignDialog;
