import React from 'react';

const AssistantIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none" {...props}>
      <path
        d="M26.7349 11.8443C26.9724 11.2902 27.758 11.2902 27.9955 11.8443L29.7792 16.0064C31.5297 20.0908 34.7978 23.3359 38.8944 25.0575L42.8816 26.7331C43.4343 26.9654 43.4438 27.745 42.897 27.9908L38.5092 29.9626C34.6458 31.6987 31.5619 34.8036 29.8521 38.6787L27.9926 42.893C27.752 43.4382 26.9784 43.4382 26.7378 42.893L24.8783 38.6787C23.1685 34.8036 20.0846 31.6987 16.2212 29.9626L11.8334 27.9908C11.2866 27.745 11.2961 26.9654 11.8488 26.7331L15.836 25.0575C19.9326 23.3359 23.2007 20.0908 24.9512 16.0064L26.7349 11.8443Z"
        fill="#2B4FF4"
      />
      <path
        d="M42.4561 34.5941C42.5838 34.2961 43.0063 34.2961 43.1341 34.5941L44.0934 36.8327C45.0349 39.0295 46.7927 40.7748 48.996 41.7008L51.1406 42.602C51.4378 42.7269 51.4429 43.1463 51.1488 43.2784L48.7889 44.339C46.7109 45.2727 45.0523 46.9427 44.1326 49.0269L43.1325 51.2935C43.0031 51.5868 42.587 51.5868 42.4576 51.2935L41.4575 49.0269C40.5379 46.9427 38.8792 45.2727 36.8013 44.339L34.4413 43.2784C34.1472 43.1463 34.1523 42.7269 34.4496 42.602L36.5941 41.7008C38.7975 40.7748 40.5552 39.0295 41.4967 36.8327L42.4561 34.5941Z"
        fill="#2B4FF4"
      />
    </svg>
  );
};

export default AssistantIcon;
