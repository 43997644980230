// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s0N8i {\n  height: 100%;\n  display: grid;\n  grid-template-rows: auto 1fr;\n}\n\n  .K3zzd {\n    color: rgb(var(--color-light-gray-3)) !important;\n  }\n\n  .K3zzd .ag-group-contracted, .K3zzd .ag-group-expanded {\n    color: rgb(var(--color-light-gray-3));\n  }\n\n  ._7NZwV {\n    margin-bottom: var(--s);\n  }\n\n  .s0N8i .ag-cell {\n    padding: 0;\n  }\n\n  .s0N8i .ag-cell-wrapper.ag-row-group {\n    padding-left: 6px;\n    align-items: center;\n  }\n\n  .s0N8i .ag-theme-alpine .ag-overlay-loading-wrapper {\n    margin-top: 47px;\n  }\n\n  .s0N8i .ag-theme-alpine .ag-root-wrapper {\n    border: 1px solid rgb(var(--color-light-gray-3));\n    border-radius: var(--xs);\n  }\n\n  .s0N8i .ag-row-selected {\n    background-color: var(--map-background-color) !important;\n  }\n\n  .S42x8 {\n    visibility: hidden;\n  }\n\n  .ag-header-cell:hover .S42x8 {\n    visibility: visible;\n  }\n\n  .-vzeh {\n    padding: 0 !important;\n  }\n\n  .-vzeh .ag-react-container {\n      align-items: center;\n      justify-content: center;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryMapGridV2": "s0N8i",
	"TerritoryMapGridV2_ignoredGroup": "K3zzd",
	"TerritoryMapGridV2_searchInput": "_7NZwV",
	"TerritoryMapGridV2_hideUntilHover": "S42x8",
	"TerritoryMapGridV2_iconCell": "-vzeh"
};
export default ___CSS_LOADER_EXPORT___;
