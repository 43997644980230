import { CustomHeader, SelectedPlanningCycle, SelectedTenant, TenantHeader, Headers } from 'app/models';

export interface ApiHeaders extends Headers {
  'x-broker-api-version': string;
  Authorization: string;
  [CustomHeader.DEPLOYMENT_MODEL_ID]: string;
  [CustomHeader.PLANNING_CYCLE_ID]?: string;
  [TenantHeader.TENANT_GLOBAL_ID]: string;
  [CustomHeader.BATTLE_CARD_ID]?: string;
}

export interface BuildHeaderParams {
  authToken: string;
  selectedTenant: SelectedTenant;
  selectedDeploymentModel: number;
  selectedPlanningCycle?: SelectedPlanningCycle;
  selectedBattlecard?: string;
}

export const buildHeaders = ({
  authToken,
  selectedTenant,
  selectedDeploymentModel,
  selectedPlanningCycle,
  selectedBattlecard
}: BuildHeaderParams): ApiHeaders => {
  return {
    'x-broker-api-version': '2.17', // to delete
    Authorization: `Bearer ${authToken}`,
    [CustomHeader.DEPLOYMENT_MODEL_ID]: selectedDeploymentModel.toString(),
    [CustomHeader.PLANNING_CYCLE_ID]: selectedPlanningCycle.id.toString(),
    [TenantHeader.TENANT_GLOBAL_ID]: selectedTenant.globalId,
    [CustomHeader.BATTLE_CARD_ID]: selectedBattlecard
  };
};
