import React from 'react';

import { Copy } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ClipboardTextInput.module.pcss';

const b = block(style);

interface ClipboardTextInputProps {
  inputValue: string;
  toastMessage: string;
}

const ClipboardTextInput: React.FC<ClipboardTextInputProps> = ({
  inputValue,
  toastMessage
}: ClipboardTextInputProps) => {
  const showToast = useShowToast();
  const toastText = (
    <>
      <p className={b('toastHeader')}>{formatMessage('COPIED_TO_CLIPBOARD')}</p>
      {toastMessage && <p>{toastMessage}</p>}
    </>
  );

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(inputValue);
    showToast(toastText, 'success');
  };

  return (
    <div className={b()} data-testid="clipboard-text-input-container">
      <input className={b('clipboardInput')} value={inputValue} data-testid="clipboard-text-input" readOnly />
      <IconButton
        title="copy-to-clipboard-button"
        type="button"
        icon={<Copy className={b('copyToClipboardIcon')} />}
        onClick={onCopyToClipboard}
        testId="copy-to-clipboard-button"
        minimal={false}
      />
    </div>
  );
};

export default ClipboardTextInput;
