// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZdNDl {\n    max-height: 210px;\n    border-radius: 4px;\n    overflow-y: auto;\n    border: 1px solid rgb(var(--color-light-gray-4));\n    margin-top: 15px;\n  }\n  .b\\+VQO {\n    width: 100%;\n    border-radius: 4px;\n  }\n  .b\\+VQO th, .b\\+VQO td {\n      font-weight: unset;\n      padding: 4px 16px;\n    }\n  .b\\+VQO tr {\n      border-bottom: 1px solid rgb(var(--color-light-gray-4));\n    }\n  .G14Lj {\n    background-color: rgb(var(--color-light-gray-5));\n  }\n  .LFSz3 tr:last-child {\n      border-bottom: none;\n    }\n  .nvtI6, .RYQto {\n    text-align: right;\n  }\n  .pk01K {\n    font-weight: bold !important;\n    text-align: right;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuotaReallocationPreviewTable_tableContainer": "ZdNDl",
	"QuotaReallocationPreviewTable_table": "b+VQO",
	"QuotaReallocationPreviewTable_tableHeaderRow": "G14Lj",
	"QuotaReallocationPreviewTable_tableBody": "LFSz3",
	"QuotaReallocationPreviewTable_fullAccountQuotaRow": "nvtI6",
	"QuotaReallocationPreviewTable_reallocatingRowHeader": "RYQto",
	"QuotaReallocationPreviewTable_reallocatingRow": "pk01K"
};
export default ___CSS_LOADER_EXPORT___;
