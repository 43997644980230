import { useCallback, useState } from 'react';

import { PrimaryTerritoryField } from 'app/models';

import { createScopedLocalStorage } from 'utils/helpers/localStorageHelpers';

const storage = createScopedLocalStorage<PrimaryTerritoryField>('Map_PrimaryTerritoryField');

export const usePrimaryTerritoryField = (): [PrimaryTerritoryField, (field: PrimaryTerritoryField) => void] => {
  const [field, setFieldState] = useState<PrimaryTerritoryField>(() => storage.read() ?? 'territoryId');

  const setField = useCallback((field) => {
    setFieldState(field);
    storage.write(field);
  }, []);

  return [field, setField];
};
