import { ScrambledTerritoryColorList, TerritoryColors } from 'app/models';

export class LeastUsedColorPicker {
  private readonly colorFrequencies = new Map<TerritoryColors, number>(
    ScrambledTerritoryColorList.map((color) => [color, 0])
  );
  constructor(items: Iterable<{ color: string | null }>) {
    for (const item of items) {
      this.useColor(item.color);
    }
  }

  public useColor(color: TerritoryColors | string | null): void {
    const frequency = this.colorFrequencies.get(color as TerritoryColors);

    // Ignore unexpected colours
    if (frequency === undefined) return;

    this.colorFrequencies.set(color as TerritoryColors, frequency + 1);
  }

  private getLeastUsedColor(): TerritoryColors {
    let leastUsedEntry = [ScrambledTerritoryColorList[0], Infinity] as const;
    for (const entry of this.colorFrequencies.entries()) {
      if (entry[1] < leastUsedEntry[1]) {
        leastUsedEntry = entry;
      }
    }
    return leastUsedEntry[0];
  }

  public useLeastUsedColor(): TerritoryColors {
    const leastUsedColor = this.getLeastUsedColor();
    this.useColor(leastUsedColor);
    return leastUsedColor;
  }
}
