import { useCallback, useState } from 'react';

import useSymonEvent from 'app/hooks/symon/useSymonEvent';

interface LoadingState {
  isLoading: boolean;
  isInitialLoading: boolean;
}

const useIsSymonImportLoading = (): LoadingState => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useSymonEvent(
    'onLoading',
    useCallback((isLoadingNow) => {
      setIsLoading(isLoadingNow);
      if (!isLoadingNow) setIsInitialLoading(false);
    }, [])
  );

  return { isLoading, isInitialLoading };
};

export default useIsSymonImportLoading;
