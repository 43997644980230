// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6y8kb {\n  width: 70%;\n  max-width: 250px;\n  min-width: 190px;\n  z-index: 1000 !important;\n  position: relative;\n}\n\n  ._6y8kb .bp3-menu {\n    width: auto !important;\n    min-width: 100% !important;\n  }\n\n  ._6y8kb .bp3-menu-item {\n    width: 100%;\n    height: calc(var(--default-cell-height) + 1px);\n    padding: 0 12px;\n  }\n\n  ._6y8kb .bp3-button > svg {\n    margin-left: auto;\n  }\n\n  ._6y8kb .bp3-button:not([class*='bp3-intent-']), ._6y8kb .bp3-button:not([class*='bp3-intent-']).bp3-active {\n    min-height: 0;\n    height: 32px;\n    margin-top: 4px;\n    box-shadow: none;\n    color: rgb(var(--color-gray-2));\n    border: 1px solid rgb(var(--color-light-gray-1));\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    background-color: #fff !important\n  }\n\n  ._6y8kb .bp3-button:not([class*='bp3-intent-']):hover, ._6y8kb .bp3-button:not([class*='bp3-intent-']).bp3-active:hover {\n      color: rgb(var(--color-gray-2));\n      border: 1px solid rgb(var(--color-light-gray-1));\n      box-shadow: none;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DropdownMenuCellEditor": "_6y8kb"
};
export default ___CSS_LOADER_EXPORT___;
