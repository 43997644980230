import React from 'react';

import {
  Copy,
  AddAlt,
  RecentlyViewed,
  Network_3Reference as NetworkReference,
  Compare,
  Edit,
  TrashCan,
  Checkmark,
  View
} from '@carbon/icons-react';

import DropdownMenuDivider from 'components/DropdownMenu/DropdownMenuDivider';
import DropdownMenuItem from 'components/DropdownMenu/DropdownMenuItem';

import { ScenarioActions } from 'app/components/PlanningCyclePageHeader/ScenarioMenu';

import { ScenarioSummary } from 'app/graphql/hooks/useScenarioSummaries';

import { useChangeScenario } from 'app/hooks/useChangeScenario';

import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

export interface ScenarioMenuActionItemsProps {
  onActionClick: (action: ScenarioActions) => void;
  hidePublishAction: boolean;
  hideActivateAction: boolean;
  hideCreateAction?: boolean;
  hideRecentsAction?: boolean;
  hideManageAction?: boolean;
  recentScenarios?: ScenarioSummary[];
  hideLaunchAction: boolean;
}

const ScenarioMenuActionItems: React.FC<ScenarioMenuActionItemsProps> = ({
  onActionClick,
  hidePublishAction,
  hideActivateAction,
  hideCreateAction = false,
  hideRecentsAction = false,
  hideManageAction = false,
  recentScenarios = [],
  hideLaunchAction
}) => {
  const changeScenario = useChangeScenario();
  const shouldDisableRecentMenuItem = recentScenarios?.length === 0;

  return (
    <>
      {!hideLaunchAction && (
        <DropdownMenuItem
          data-testid={'open-item'}
          icon={View}
          text={formatMessage('OPEN')}
          onClick={() => onActionClick(ScenarioActions.Launch)}
        />
      )}
      <CanUser
        perform={UserAction.SCENARIOS_MODIFY}
        yes={
          <DropdownMenuItem
            data-testid={'copy-item'}
            icon={Copy}
            text={formatMessage('MAKE_A_COPY')}
            onClick={() => onActionClick(ScenarioActions.Copy)}
          />
        }
      />
      {!hideCreateAction && (
        <CanUser
          perform={UserAction.SCENARIOS_MODIFY}
          yes={
            <DropdownMenuItem
              data-testid={'create-item'}
              icon={AddAlt}
              text={formatMessage('CREATE_SCENARIO')}
              onClick={() => onActionClick(ScenarioActions.Create)}
            />
          }
        />
      )}
      {!hideRecentsAction && (
        <DropdownMenuItem
          data-testid={'recent-item'}
          disabled={shouldDisableRecentMenuItem}
          tooltip={formatMessage('NO_RECENT_SCENARIOS')}
          icon={RecentlyViewed}
          text={formatMessage('OPEN_RECENT')}
        >
          <>
            {recentScenarios.map((scenario) => (
              <DropdownMenuItem
                text={scenario.deploymentModelName}
                key={scenario.deploymentModelId}
                onClick={() => changeScenario(scenario.deploymentModelName)}
                data-testid={`${scenario.deploymentModelName}-${scenario.deploymentModelId}`}
              />
            ))}
            <DropdownMenuDivider />
            <DropdownMenuItem
              text={formatMessage('SEE_ALL_SCENARIOS')}
              onClick={() => onActionClick(ScenarioActions.Manage)}
              data-testid="see-all-scenarios-item"
            />
          </>
        </DropdownMenuItem>
      )}
      {!hideManageAction && (
        <DropdownMenuItem
          data-testid={'manage-item'}
          icon={NetworkReference}
          onClick={() => onActionClick(ScenarioActions.Manage)}
          text={formatMessage('MANAGE_SCENARIOS')}
        />
      )}
      <DropdownMenuDivider />
      <DropdownMenuItem
        data-testid={'compare-item'}
        icon={Compare}
        text={formatMessage('COMPARE')}
        onClick={() => onActionClick(ScenarioActions.Compare)}
      />
      {!hideActivateAction && (
        <CanUser
          perform={UserAction.SCENARIOS_MODIFY}
          yes={
            <>
              <DropdownMenuDivider />
              <DropdownMenuItem
                data-testid={'activate-item'}
                icon={Checkmark}
                text={formatMessage('ACTIVATE_SCENARIO')}
                onClick={() => onActionClick(ScenarioActions.Activate)}
              />
            </>
          }
        />
      )}
      {!hidePublishAction && (
        <CanUser
          perform={UserAction.SCENARIOS_MODIFY}
          yes={
            <>
              <DropdownMenuDivider />
              <DropdownMenuItem
                data-testid={'publish-item'}
                icon={Checkmark}
                text={formatMessage('PUBLISH')}
                onClick={() => onActionClick(ScenarioActions.Publish)}
              />
            </>
          }
        />
      )}
      <CanUser
        perform={UserAction.SCENARIOS_MODIFY}
        yes={
          <>
            <DropdownMenuDivider />
            <DropdownMenuItem
              data-testid={'rename-item'}
              icon={Edit}
              text={formatMessage('RENAME')}
              onClick={() => onActionClick(ScenarioActions.Rename)}
            />
          </>
        }
      />
      <CanUser
        perform={UserAction.SCENARIOS_MODIFY}
        yes={
          <DropdownMenuItem
            data-testid={'remove-item'}
            icon={TrashCan}
            text={formatMessage('DELETE')}
            intent="danger"
            onClick={() => {
              onActionClick(ScenarioActions.Delete);
            }}
          />
        }
      />
    </>
  );
};

export default ScenarioMenuActionItems;
