import React from 'react';

import TerritoryPill from 'app/components/TerritoriesAndPlanTargets/TerritoryPill/TerritoryPill';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import {
  BattleCardTarget,
  CategoryName,
  ExpandedTerritoryGroupDefineAndRefinePillData,
  MeasureType,
  PillLevel,
  TerritoryGroupWithMeasuresNodeData
} from 'app/models';

import { getMeasureByType } from 'utils/helpers/measureUtils';
import { formatCurrency, formatMessage } from 'utils/messages/utils';
interface BattleCardTargetPillProps {
  nodeData: BattleCardTarget;
  valueFormatter: (value: number, isCurrencyConversionValid: boolean, currency: string) => string;
  pillIndex: number;
  animateTerritoryGroup: boolean;
  battleCardId: string;
}

export const BattleCardTargetPill: React.FC<BattleCardTargetPillProps> = ({
  nodeData,
  valueFormatter,
  pillIndex,
  animateTerritoryGroup,
  battleCardId
}: BattleCardTargetPillProps) => {
  const { battleCardLookupMap } = useBattleCard();
  const getAllocationMessage = (
    target: number,
    updatedQuota: number,
    currency: string,
    isCurrencyConversionValid: boolean
  ): string => {
    const difference = target - updatedQuota;
    let message;
    if (!isCurrencyConversionValid) {
      return null;
      // change difference to 1 and -1 instead of 0 due to rounding issues
    } else if (difference >= 1) {
      message = formatMessage('UNDERALLOCATED_BY', { amount: formatCurrency(difference, currency) });
    } else if (difference <= -1) {
      message = formatMessage('OVERALLOCATED_BY', { amount: formatCurrency(Math.abs(difference), currency) });
    }
    return message;
  };

  const quotaEntryMode = true;
  const pillLevel = PillLevel.ROOT;

  const isBattleCardCurrencyConversionValid = !battleCardLookupMap?.[battleCardId]?.invalidConversionReason;
  const battleCardLocalCurrency = battleCardLookupMap?.[battleCardId]?.localCurrencyCode;
  const owner = battleCardLookupMap?.[battleCardId]?.owner;
  const ownerInfo = { firstName: owner?.firstName, lastName: owner?.lastName };

  const formattedTarget = valueFormatter(
    nodeData?.newBusinessTarget,
    isBattleCardCurrencyConversionValid,
    battleCardLocalCurrency
  );
  const formattedQuota = valueFormatter(
    nodeData?.updatedQuota,
    isBattleCardCurrencyConversionValid,
    battleCardLocalCurrency
  );

  const allocationMessage = getAllocationMessage(
    Math.round(nodeData?.newBusinessTarget),
    Math.round(nodeData?.updatedQuota),
    battleCardLocalCurrency,
    isBattleCardCurrencyConversionValid
  );

  return (
    <TerritoryPill
      owner={ownerInfo}
      nodeData={nodeData}
      pillLevel={pillLevel}
      pillIndex={pillIndex}
      quotaEntryMode={quotaEntryMode}
      target={formattedTarget}
      updatedQuota={formattedQuota}
      allocationMessage={allocationMessage}
      // since it's the root. No matter what change, this will be change, hence need animation.
      animate={animateTerritoryGroup}
      battleCardLocalCurrency={battleCardLocalCurrency}
      battleCardId={battleCardId}
    />
  );
};

interface TerritoryGroupWithMeasuresPillProps {
  nodeData: TerritoryGroupWithMeasuresNodeData;
  valueFormatter: (value: number, isCurrencyConversionValid: boolean, currency: string) => string;
  pillIndex: number;
  categoryName: CategoryName;
  animateTerritoryGroup: boolean;
  ancestors: string[];
  childrenIds;
  battleCardId: string;
}

export const TerritoryGroupWithMeasuresPill: React.FC<TerritoryGroupWithMeasuresPillProps> = ({
  nodeData,
  valueFormatter,
  pillIndex,
  categoryName,
  animateTerritoryGroup,
  ancestors,
  childrenIds,
  battleCardId
}: TerritoryGroupWithMeasuresPillProps) => {
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { battleCardLookupMap } = useBattleCard();
  const { tdrLookupMap } = useTerritoryDefineAndRefine();
  const currentPill = nodeData?.territoryGroupId;
  const owner = tdrLookupMap[currentPill]?.owner;

  const quotaEntryMode = true;
  const pillLevel = nodeData.territoryGroupParentId ? PillLevel.TERRITORY : PillLevel.CATEGORY;

  const categoryIsSelected = nodeData.territoryGroupId === selectedPillIdPlanTargets;
  const updatedQuota = getMeasureByType(nodeData.measures, MeasureType.UPDATED_QUOTA);

  const isBattleCardCurrencyConversionValid = !battleCardLookupMap?.[battleCardId]?.invalidConversionReason;
  const battleCardLocalCurrency = battleCardLookupMap?.[battleCardId]?.localCurrencyCode;

  const formattedQuota = valueFormatter(
    updatedQuota?.measureValue,
    isBattleCardCurrencyConversionValid,
    battleCardLocalCurrency
  );

  return (
    <TerritoryPill
      owner={owner}
      nodeData={nodeData}
      pillLevel={pillLevel}
      pillIndex={pillIndex}
      categoryName={categoryName}
      quotaEntryMode={quotaEntryMode}
      updatedQuota={formattedQuota}
      battleCardLocalCurrency={battleCardLocalCurrency}
      animate={
        animateTerritoryGroup &&
        !categoryIsSelected &&
        (ancestors.includes(selectedPillIdPlanTargets) || childrenIds.includes(selectedPillIdPlanTargets))
      }
      battleCardId={battleCardId}
    />
  );
};

interface TerritoryGroupDefineAndRefinePillProps {
  nodeData: ExpandedTerritoryGroupDefineAndRefinePillData;
  pillIndex: number;
  categoryName: CategoryName;
  battleCardId: string;
  enableClick?: boolean;
}

export const TerritoryGroupDefineAndRefinePill: React.FC<TerritoryGroupDefineAndRefinePillProps> = ({
  nodeData,
  pillIndex,
  categoryName,
  battleCardId,
  enableClick
}: TerritoryGroupDefineAndRefinePillProps) => {
  const pillLevel = nodeData.territoryGroupParentId ? PillLevel.TERRITORY : PillLevel.CATEGORY;
  const quotaEntryMode = false;

  const { tdrLookupMap } = useTerritoryDefineAndRefine();
  const currentPill = nodeData?.territoryGroupId;
  const owner = tdrLookupMap[currentPill]?.owner;

  return (
    <TerritoryPill
      owner={owner}
      nodeData={nodeData}
      pillLevel={pillLevel}
      pillIndex={pillIndex}
      categoryName={categoryName}
      quotaEntryMode={quotaEntryMode}
      battleCardId={battleCardId}
      enableClick={enableClick}
    />
  );
};

interface UnassignedPillProps {
  nodeData: ExpandedTerritoryGroupDefineAndRefinePillData;
  battleCardId: string;
}

export const UnassignedPill: React.FC<UnassignedPillProps> = ({ nodeData, battleCardId }: UnassignedPillProps) => {
  return (
    <TerritoryPill
      enableClick={false}
      nodeData={nodeData}
      pillLevel={PillLevel.CATEGORY}
      categoryName={CategoryName.UNASSIGNED}
      quotaEntryMode={false}
      enableContextMenu={false}
      battleCardId={battleCardId}
    />
  );
};
