// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QFMvG {\n    color: rgb(var(--color-red-3));\n  }\n  .AblFJ {\n    color: rgb(var(--color-cobalt-3));\n  }\n  .PmFZE {\n    display: flex;\n  }\n  .nHJZr {\n    margin-left: var(--xs);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FileStatusCellRenderer_textError": "QFMvG",
	"FileStatusCellRenderer_textAction": "AblFJ",
	"FileStatusCellRenderer_deleteTableCell": "PmFZE",
	"FileStatusCellRenderer_deleteTableCellText": "nHJZr"
};
export default ___CSS_LOADER_EXPORT___;
