import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import {
  requestDeploymentModelDelete,
  requestDeploymentModelDeleteVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const REQUEST_DEPLOYMENT_MODEL_DELETE = gql`
  mutation requestDeploymentModelDelete($deploymentModelIds: [Int!]!) {
    requestDeploymentModelDelete(input: { deploymentModelIds: $deploymentModelIds }) {
      messageId
      jobKey
    }
  }
`;

export const useRequestDeploymentModelDelete = createUseMutation<
  requestDeploymentModelDelete,
  requestDeploymentModelDeleteVariables
>(REQUEST_DEPLOYMENT_MODEL_DELETE);
