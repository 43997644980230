import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Position, Toaster } from '@blueprintjs/core';

import JobProgressStatusToast from 'components/JobProgressStatusToast/JobProgressStatusToast';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useScope } from 'app/contexts/scopeProvider';

import { GetJobProgress, PCJobType } from 'app/graphql/generated/graphqlApolloTypes';

import useShowToast from 'app/hooks/useShowToast';

import { JobStatus } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface CloneScenarioToastProps {
  planningCycleName: string;
  scenarioName: string;
}

const CloneScenarioToast: React.FC<CloneScenarioToastProps> = ({
  planningCycleName,
  scenarioName
}: CloneScenarioToastProps) => {
  const { selectedPlanningCycle } = useScope();
  const showToast = useShowToast();

  const onClonePollSuccess = (response: GetJobProgress, stopPolling: () => void) => {
    if (response?.getJobProgress?.pcCloneJobOutput?.pcCloneStatus === JobStatus.COMPLETED) {
      stopPolling();
      showToast(
        <ToastMessage
          title={formatMessage('SCENARIO_COPIED')}
          message={formatMessage('SCENARIO_COPIED_MESSAGE', { name: scenarioName })}
        />,
        'success'
      );
    } else if (response?.getJobProgress?.pcCloneJobOutput?.pcCloneStatus === JobStatus.FAILED) {
      stopPolling();
      showErrorToast();
    }
  };

  const showErrorToast = () => showToast(formatMessage('SCENARIO_COPY_FAILED'), 'danger');

  return (
    <Toaster position={Position.TOP_RIGHT}>
      <JobProgressStatusToast
        data-testid="clone-scenario-status-toast"
        key={selectedPlanningCycle.id}
        jobDetail={{
          planningCycleId: selectedPlanningCycle.id,
          planningCycleName,
          jobType: PCJobType.DEPLOYMENT_MODEL_CLONE
        }}
        title={formatMessage('SCENARIO_COPY_STARTED')}
        progressDescription={formatMessage('SCENARIO_COPY_MESSAGE', { name: scenarioName })}
        onPollSuccess={onClonePollSuccess}
        onPollError={showErrorToast}
      />
    </Toaster>
  );
};

export default CloneScenarioToast;
