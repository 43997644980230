// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WKdUU {\n  padding: 0 var(--data-panel-padding);\n  width: 100%;\n  overflow: scroll;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n  .WKdUU .ag-react-container {\n    height: 100%;\n  }\n\n.WKdUU.ag-theme-alpine .ag-root-wrapper {\n    border: 0;\n    height: 100%;\n  }\n\n.WKdUU.ag-theme-alpine .ag-row {\n    cursor: pointer;\n  }\n\n.kHMgT {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n.iTwBH {\n    width: 250px;\n    margin: 10px 0 20px;\n    display: inline-block;\n  }\n\n.adYxw {\n    height: 100%;\n    flex: 1;\n    min-height: 120px;\n  }\n\n.tgrH4 {\n    border: 1px solid rgb(var(--color-light-gray-3));\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n\n.BPvK0 {\n    height: 32px; /* to align the icon with the input box */\n    width: 30px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  } /* postcss-bem-linter: ignore */\n\n.BPvK0 > svg {\n      fill: rgb(var(--color-light-gray-2));\n    }\n\n.tIxJO {\n  }\n\n.oUDJh {\n    margin-bottom: 10px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SheetsPanel": "WKdUU",
	"SheetsPanel_toolbar": "kHMgT",
	"SheetsPanel_searchbar": "iTwBH",
	"SheetsPanel_sheetsGrid": "adYxw",
	"SheetsPanel_grid": "tgrH4",
	"SheetsPanel_searchIcon": "BPvK0",
	"SheetsPanel_noData": "tIxJO " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-no-data"] + "",
	"SheetsPanel_headingContainer": "oUDJh"
};
export default ___CSS_LOADER_EXPORT___;
