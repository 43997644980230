import gql from 'graphql-tag';

export const GET_SUBTREE_CUSTOM_HIERARCHIES = gql`
  query GetSubtreeCustomHierarchies(
    $planningCycleId: Int!
    $hierarchyId: Int!
    $depth: Int!
    $startRow: Int!
    $endRow: Int!
  ) {
    getSubtreeCustomHierarchies(
      customHierarchy: {
        planningCycleId: $planningCycleId
        hierarchyId: $hierarchyId
        depth: $depth
        startRow: $startRow
        endRow: $endRow
        version: 1
      }
    ) {
      items {
        hasChildren
        customProperties
        hierarchyId
        key
        name
        parentKey
        path
        planningCycleId
        effectiveDate
        endDate
        version
      }
      totalCount
    }
  }
`;

export const GET_SUBTREE_CUSTOMER_ACCOUNT_HIERARCHIES = gql`
  query GetSubtreeCustomerAccountHierarchies(
    $planningCycleId: Int!
    $hierarchyId: Int!
    $depth: Int!
    $startRow: Int!
    $endRow: Int!
  ) {
    getSubtreeCustomerAccountHierarchies(
      customerAccountHierarchy: {
        planningCycleId: $planningCycleId
        hierarchyId: $hierarchyId
        depth: $depth
        startRow: $startRow
        endRow: $endRow
        version: 1
      }
    ) {
      items {
        address1
        address2
        hasChildren
        city
        country
        customProperties
        customerAccountNumber
        hierarchyId
        industry
        linkedGeoKey
        visualGeoKey
        key
        name
        parentKey
        path
        planningCycleId
        zipPostal
        stateProvince
        effectiveDate
        endDate
        version
      }
      totalCount
    }
  }
`;

export const GET_SUBTREE_GEOGRAPHIC_HIERARCHIES = gql`
  query GetSubtreeGeographicTerritoryHierarchies(
    $planningCycleId: Int!
    $hierarchyId: Int!
    $depth: Int!
    $startRow: Int!
    $endRow: Int!
  ) {
    getSubtreeGeographicTerritoryHierarchies(
      geographicTerritoryHierarchy: {
        planningCycleId: $planningCycleId
        hierarchyId: $hierarchyId
        depth: $depth
        startRow: $startRow
        endRow: $endRow
        version: 1
      }
    ) {
      items {
        hasChildren
        customProperties
        geographicTerritoryId
        hierarchyId
        key
        name
        parentKey
        path
        planningCycleId
        version
      }
      totalCount
    }
  }
`;
