import { Context, useContext } from 'react';

export const useContextSafe = <T>(context: Context<T | null>): T => {
  // eslint-disable-next-line no-restricted-syntax
  const contextValue = useContext(context);

  if (contextValue === null || contextValue === undefined) {
    throw new Error(`Cannot use context ${context.displayName} outside of provider`);
  }
  return contextValue;
};
