import gql from 'graphql-tag';

export const ADD_FILE_METADATA = gql`
  mutation AddFileMetadata(
    $planningCycleId: Int!
    $fileName: String!
    $fileType: String!
    $quotaComponentId: Int
    $battlecardId: Int
    $hierarchyId: Int
  ) {
    addFileMetadata(
      input: {
        planningCycleId: $planningCycleId
        fileName: $fileName
        fileType: $fileType
        quotaComponentId: $quotaComponentId
        battlecardId: $battlecardId
        hierarchyId: $hierarchyId
      }
    ) {
      fileId
      fileName
      jobId
      planningCycleId
      tenantId
      bucket
      key
      fileType
      status
      message
      accessKeyId
      secretKey
      sessionToken
      tqAccessKeyId
      tqS3BucketId
      tqSecretKey
      tqS3FileName
    }
  }
`;
