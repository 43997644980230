import { useCallback, useEffect, useMemo, useState } from 'react';

import { lineString, convertLength } from '@turf/helpers';
import length from '@turf/length';
import { LngLat } from 'mapbox-gl';

import { LineRulerUnit } from 'app/models';

export interface UseLineRulerToolData {
  addLinePoint: (e) => void;
  lineCoordinates: number[][];
  handleDeleteMeasureLine: () => void;
  lineRulerIndicatorPosX: number;
  lineRulerIndicatorPosY: number;
  lineDistance: number;
}

export const useLineRulerTool = (unit: LineRulerUnit): UseLineRulerToolData => {
  const [lineCoordinates, setLineCoordinates] = useState<number[][]>([]);
  const [lineDistance, setLineDistance] = useState<number>(0);

  const handleDeleteMeasureLine = () => {
    setLineCoordinates([]);
    setLineDistance(0);
  };

  const addLinePoint = useCallback(
    (e: { lngLat: LngLat }) => {
      const newLineCoordinates = [...lineCoordinates, [e.lngLat.lng, e.lngLat.lat]];
      setLineCoordinates(newLineCoordinates);

      if (newLineCoordinates.length > 1) {
        const lineDistance = length(lineString(newLineCoordinates), { units: unit });

        setLineDistance(lineDistance);
      }
    },
    [lineCoordinates, lineDistance, unit]
  );

  useEffect(() => {
    const previousUnit = unit === 'kilometers' ? 'miles' : 'kilometers';
    setLineDistance(convertLength(lineDistance, previousUnit, unit));
  }, [unit]);

  const { lineRulerIndicatorPosX, lineRulerIndicatorPosY } = useMemo(() => {
    let lineRulerIndicatorPosX = 0;
    let lineRulerIndicatorPosY = 0;
    if (lineCoordinates.length > 0) {
      const lastPoint = lineCoordinates[lineCoordinates.length - 1];
      lineRulerIndicatorPosX = lastPoint[0];
      lineRulerIndicatorPosY = lastPoint[1];
    }
    return { lineRulerIndicatorPosX, lineRulerIndicatorPosY };
  }, [lineCoordinates]);

  return {
    addLinePoint,
    lineCoordinates,
    handleDeleteMeasureLine,
    lineRulerIndicatorPosX,
    lineRulerIndicatorPosY,
    lineDistance
  };
};
