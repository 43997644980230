import { useMemo } from 'react';

import { useAuthHeader } from 'app/contexts/AuthHeaderProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SymonApiProxyClient } from 'utils/helpers/SymonApiProxyClient';

const useSymonApiProxyClient = (): SymonApiProxyClient | null => {
  const { selectedTenant } = useScope();

  const { getAuthHeader } = useAuthHeader();

  return useMemo(() => {
    if (!selectedTenant || !selectedTenant.id || !selectedTenant.globalId) return null;
    return new SymonApiProxyClient({
      tenantId: selectedTenant.id,
      tenantGlobalId: selectedTenant.globalId,
      getAuthorization: getAuthHeader
    });
  }, [getAuthHeader, selectedTenant?.id]);
};

export default useSymonApiProxyClient;
