import React from 'react';

import { AddFilled } from '@carbon/icons-react';

import RoundIconButton from 'components/Buttons/RoundIconButton/RoundIconButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { useBattleCard } from 'app/contexts/battleCardProvider';

import { formatMessage } from 'utils/messages/utils';

interface ConnectedProps {
  handleClick: () => void;
}

const BattleCardAddCardButton: React.FC<ConnectedProps> = ({ handleClick }: ConnectedProps) => {
  const addCardTooltip = <span>{formatMessage('ADD_A_CARD')}</span>;
  const addCardButton = (
    <RoundIconButton intent="primary" icon={<AddFilled size={32} />} onClick={() => handleClick()} />
  );
  const { canvasRef } = useBattleCard();
  return (
    <MessageTooltip
      content={addCardTooltip}
      placement={'bottom'}
      target={addCardButton}
      portalContainer={canvasRef?.current}
    />
  );
};

export default BattleCardAddCardButton;
