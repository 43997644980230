import React, { useEffect, useRef, useState } from 'react';

import JobProgressStatusToast from 'components/JobProgressStatusToast/JobProgressStatusToast';

import { FileStatus } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export interface FileUploadStatusToastProps {
  jobDetail: FileStatus | null;
}

const FileUploadStatusToast: React.FC<FileUploadStatusToastProps> = ({ jobDetail }) => {
  const validStatuses = [FileStatus.IN_PROGRESS, FileStatus.VALIDATING];

  const [progress, setProgress] = useState(0);

  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Type depends on environment, Node.js or browser

  useEffect(() => {
    if (jobDetail === FileStatus.IN_PROGRESS) {
      intervalRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress < 0.75) {
            return prevProgress + 0.01;
          }
          return prevProgress;
        });
      }, 2000);
    } else if (jobDetail === FileStatus.VALIDATING) {
      intervalRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress < 0.99) {
            return prevProgress + 0.01;
          }
          return prevProgress;
        });
      }, 2000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      if (!jobDetail) {
        setProgress(1);
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [jobDetail]);

  if (!validStatuses.includes(jobDetail)) {
    return null;
  }

  const toastDescription =
    jobDetail === FileStatus.IN_PROGRESS
      ? formatMessage('IMPORT_SELLER_IN_PROGRESS')
      : formatMessage('IMPORT_SELLER_VALIDATING');

  return (
    <JobProgressStatusToast
      title={formatMessage('IMPORT_SELLER_ASSIGNMENTS')}
      progressDescription={toastDescription}
      progress={progress}
      onPollSuccess={() => {}}
      onPollError={() => {}}
    />
  );
};

export default FileUploadStatusToast;
