// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9RSGY {\n  display: block;\n}\n  ._5o4UD.bp3-overlay-enter-done {\n    /* TODO TQP-1414 Reuse .transition-animate from common classes\n    here instead of adding the properties manually */\n    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SheetsAddFieldDrawer": "_9RSGY",
	"SheetsAddFieldDrawer_transition": "_5o4UD"
};
export default ___CSS_LOADER_EXPORT___;
