import React from 'react';

import Dialog from 'components/Dialog/Dialog';

import { formatMessage } from 'utils/messages/utils';

interface CannotDeletePublishedScenarioDialogProps {
  scenarioName: string;
  onClose: () => void;
}

const CannotDeletePublishedScenarioDialog: React.FC<CannotDeletePublishedScenarioDialogProps> = ({
  scenarioName,
  onClose
}) => {
  return (
    <Dialog
      onClose={onClose}
      title={formatMessage('SCENARIO_PUBLISHED_ERROR', { name: scenarioName })}
      isOpen
      bodyMinHeight={0}
      size="small"
      cancelButtonText={formatMessage('CLOSE')}
      showConfirm={false}
    >
      {formatMessage('SCENARIO_PUBLISHED_ERROR_DESCRIPTION', { name: scenarioName })}
    </Dialog>
  );
};

export default CannotDeletePublishedScenarioDialog;
