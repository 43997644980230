// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+1ZNz {\n  width: 100%;\n  height: 100%;\n  overflow-y: scroll;\n  display: inline-flex;\n  flex-direction: column;\n}\n\n  .zIWw0 {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 0 var(--data-panel-padding);\n    background-color: white;\n    align-items: center;\n    box-sizing: border-box;\n    min-height: var(--data-drill-in-header-height);\n  }\n\n  .LBX62 {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .ifbNu {\n    margin: 4px var(--data-panel-margin) 0 0;\n  }\n\n  .ifbNu:hover {\n    cursor: pointer;\n  }\n\n  .dQGP9 {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .D-gcT {\n    margin-right: var(--data-panel-margin);\n    color: rgb(var(--color-gray-2));\n  }\n\n  /* postcss-bem-linter: ignore */\n\n  .LXbch > img {\n      width: var(--im);\n    }\n\n  ._45LUJ {\n    margin-left: var(--data-panel-medium-margin);\n  }\n\n  .W2pq4 {\n    display: none;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableDetail": "+1ZNz",
	"TableDetail_header": "zIWw0",
	"TableDetail_leftActionItems": "LBX62",
	"TableDetail_backIcon": "ifbNu",
	"TableDetail_rightActionItems": "dQGP9",
	"TableDetail_rightActionText": "D-gcT",
	"TableDetail_tableTypeIcon": "LXbch",
	"TableDetail_tableTitle": "_45LUJ",
	"TableDetail_hiddenItem": "W2pq4"
};
export default ___CSS_LOADER_EXPORT___;
