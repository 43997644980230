import React, { useMemo, Dispatch, SetStateAction, useEffect, useState, useCallback } from 'react';

import { ConditionBuilderOperatorType, ConditionBuilderValueType } from '@varicent/components';

import { GetTerritoryOptimizationPrerequisites_getDeploymentModelSpec_sheetMeasures } from 'app/graphql/generated/graphqlApolloTypes';
import { useGetTerritoryOptimizationPrerequisites } from 'app/graphql/queries/getTerritoryOptimizationPrerequisites';

import { useContextSafe } from 'app/hooks/useContextSafe';

import {
  BaseContext,
  TerritoryOptimizationStep,
  TerritoryOptimizationStatus,
  OptimizationScenario,
  MeasureType,
  TerritoryOptimizationJob,
  HierarchyType,
  ExpandedTerritoryGroupDefineAndRefinePillData
} from 'app/models';

import { useBattleCard } from './battleCardProvider';
import { useScope } from './scopeProvider';
import { useTerritoryDefineAndRefine } from './territoryDefineAndRefineProvider';

export type SourceColumns = {
  id: string;
  name: string;
  dataType: ConditionBuilderOperatorType;
  valueType: ConditionBuilderValueType;
};

export type MetricDropdownItem = {
  key: string;
  value: number;
};

type OptimizationTargetDetails = Pick<ExpandedTerritoryGroupDefineAndRefinePillData, 'name' | 'territoryGroupId'>;

export interface TerritoryOptimizationContextValues extends BaseContext {
  optimizationTargetTgId: string | null;
  setOptimizationTargetTgId: (tgId: string | null) => void;
  territoryOptimizationDrawerState: TerritoryOptimizationStep;
  setTerritoryOptimizationDrawerState: Dispatch<SetStateAction<TerritoryOptimizationStep>>;
  optimizationTarget: OptimizationTargetDetails | null;

  numberOfTerritories: number;
  metricDropdownItems: MetricDropdownItem[];
  constraintsSourceColumns: SourceColumns[];
  sheetMeasures: GetTerritoryOptimizationPrerequisites_getDeploymentModelSpec_sheetMeasures[];

  optimizationStatus: TerritoryOptimizationStatus;
  setOptimizationStatus: Dispatch<SetStateAction<TerritoryOptimizationStatus>>;

  selectedOptimizationMetric: MetricDropdownItem;
  setSelectedOptimizationMetric: Dispatch<SetStateAction<MetricDropdownItem>>;
  selectedOptimizationScenario: OptimizationScenario;
  setSelectedOptimizationScenario: Dispatch<SetStateAction<OptimizationScenario>>;
  optimizedTerritoryInProgress: TerritoryOptimizationJob | null;
  setOptimizedTerritoryInProgress: Dispatch<SetStateAction<TerritoryOptimizationJob | null>>;
}

export const TerritoryOptimizationContext = React.createContext<TerritoryOptimizationContextValues | null>(null);
TerritoryOptimizationContext.displayName = 'TerritoryOptimizationContext';

export const TerritoryOptimizationProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [optimizationTargetTgId, setOptimizationTargetTgId] = useState<string | null>(null);
  const [territoryOptimizationDrawerState, setTerritoryOptimizationDrawerState] =
    useState<TerritoryOptimizationStep>(null);

  const [selectedOptimizationMetric, setSelectedOptimizationMetric] = useState<MetricDropdownItem | null>(null);
  const [selectedOptimizationScenario, setSelectedOptimizationScenario] = useState<OptimizationScenario>(null);

  const [optimizedTerritoryInProgress, setOptimizedTerritoryInProgress] = useState<TerritoryOptimizationJob | null>(
    null
  );
  const [optimizationStatus, setOptimizationStatus] = useState<TerritoryOptimizationStatus>(
    TerritoryOptimizationStatus.UNSTARTED
  );

  const { selectedBattleCardId, selectedQuotaComponentId } = useBattleCard();
  const { selectedDeploymentModelId, selectedPlanningCycle } = useScope();
  const { tdrLookupMap } = useTerritoryDefineAndRefine();

  const optimizationTarget = useMemo(
    () => tdrLookupMap?.[optimizationTargetTgId] ?? null,
    [tdrLookupMap, optimizationTargetTgId]
  );

  const { data: prereqs } = useGetTerritoryOptimizationPrerequisites({
    fetchPolicy: 'network-only',
    skip:
      !selectedPlanningCycle?.id ||
      !selectedDeploymentModelId ||
      !selectedQuotaComponentId ||
      !selectedBattleCardId ||
      !optimizationTargetTgId,
    variables: {
      planningCycleId: selectedPlanningCycle?.id,
      deploymentModelId: selectedDeploymentModelId,
      quotaComponentId: selectedQuotaComponentId,
      battlecardId: +selectedBattleCardId,
      territoryGroupId: +optimizationTargetTgId
    }
  });

  const numberOfTerritories = useMemo(
    (): number => prereqs?.getNumberOfTerritoriesInTerritoryGroup.numOfTerritories ?? 0,
    [prereqs]
  );

  const constraintsSourceColumns = useMemo((): SourceColumns[] => {
    const relevantCustomerAttributes =
      prereqs?.getRootHierarchies
        .find((hierarchy) => hierarchy.hierarchyType === HierarchyType.CustomerAccountHierarchy)
        ?.attributes.filter((attribute) => attribute.type === 'number' && attribute.editable) ?? [];
    return relevantCustomerAttributes.map((attribute, index) => ({
      id: index.toString(),
      name: attribute.name,
      dataType: 'number' as const,
      valueType: 'number' as const
    }));
  }, [prereqs]);

  const sheetMeasures = useMemo(() => prereqs?.getDeploymentModelSpec.sheetMeasures ?? [], [[prereqs]]);

  const metricDropdownItems = useMemo((): MetricDropdownItem[] => {
    const allowedMeasureNames = [MeasureType.PRIOR_YEAR_ACTUAL, MeasureType.TRAILING_TWELVE_MONTHS] as string[];
    return sheetMeasures
      .filter((measure) => allowedMeasureNames.includes(measure.measureName))
      .map((measure) => ({
        key: measure.measureName,
        value: measure.measureId
      }));
  }, [sheetMeasures]);

  const resetValues = useCallback(() => {
    setOptimizationTargetTgId(null);
    setTerritoryOptimizationDrawerState(null);
    setOptimizationStatus(TerritoryOptimizationStatus.UNSTARTED);
    setSelectedOptimizationMetric(null);
    setSelectedOptimizationScenario(null);
    setOptimizedTerritoryInProgress(null);
  }, []);

  useEffect(() => {
    if (!territoryOptimizationDrawerState) {
      resetValues();
    }
  }, [territoryOptimizationDrawerState]);

  const values = useMemo(
    (): TerritoryOptimizationContextValues => ({
      optimizationTargetTgId,
      setOptimizationTargetTgId,
      optimizationTarget,
      territoryOptimizationDrawerState,
      setTerritoryOptimizationDrawerState,
      numberOfTerritories,
      sheetMeasures,
      metricDropdownItems,
      constraintsSourceColumns,
      optimizationStatus,
      setOptimizationStatus,
      selectedOptimizationMetric,
      setSelectedOptimizationMetric,
      selectedOptimizationScenario,
      setSelectedOptimizationScenario,
      optimizedTerritoryInProgress,
      setOptimizedTerritoryInProgress,
      resetValues
    }),
    [
      optimizationTargetTgId,
      setOptimizationTargetTgId,
      optimizationTarget,
      territoryOptimizationDrawerState,
      setTerritoryOptimizationDrawerState,
      numberOfTerritories,
      sheetMeasures,
      metricDropdownItems,
      constraintsSourceColumns,
      optimizationStatus,
      setOptimizationStatus,
      selectedOptimizationMetric,
      setSelectedOptimizationMetric,
      selectedOptimizationScenario,
      setSelectedOptimizationScenario,
      optimizedTerritoryInProgress,
      setOptimizedTerritoryInProgress,
      resetValues
    ]
  );
  return <TerritoryOptimizationContext.Provider value={values}>{children}</TerritoryOptimizationContext.Provider>;
};

export const useTerritoryOptimization = (): TerritoryOptimizationContextValues =>
  useContextSafe(TerritoryOptimizationContext);
