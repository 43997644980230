import gql from 'graphql-tag';

export const GET_EXPORT_DATA_STATUS = gql`
  query GetExportDataStatus($input: GetExportDataStatusInput!) {
    getExportDataStatus(input: $input) {
      exportId
      status
      message
    }
  }
`;
