// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6jJzg {\n  width: 100%;\n  min-height: 100%;\n  overflow-y: scroll;\n  display: inline-flex;\n  flex-direction: column;\n  padding-bottom: var(--data-panel-content-bottom-padding);\n}\n\n  .FyCv5 {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 0 var(--data-panel-padding);\n    background-color: white;\n    align-items: center;\n    box-sizing: border-box;\n    min-height: var(--data-drill-in-header-height);\n    font-weight: 600;\n    color: rgb(var(--color-dark-gray-3));\n    width: 100%;\n    overflow: hidden;\n  }\n\n  ._2Ylob {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  ._0PVXb {\n    margin: 4px var(--data-panel-margin) 0 0;\n  }\n\n  ._0PVXb:hover {\n    cursor: pointer;\n  }\n\n  .ayNIY {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  ._0CKUz {\n    margin-right: var(--data-panel-margin);\n    color: rgb(var(--color-gray-2));\n  }\n\n  ._9SrvK {\n    margin-left: var(--data-panel-medium-margin);\n    font-size: 16px;\n  }\n\n  .iNzKM {\n    display: none;\n  }\n\n  .A7YGh {\n    padding: 7px 15px 7px 0;\n    margin-right: 15px;\n    border-right: 1px solid rgb(var(--color-light-gray-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConfigurationDetail": "_6jJzg",
	"ConfigurationDetail_header": "FyCv5",
	"ConfigurationDetail_leftActionItems": "_2Ylob",
	"ConfigurationDetail_backIcon": "_0PVXb",
	"ConfigurationDetail_rightActionItems": "ayNIY",
	"ConfigurationDetail_rightActionText": "_0CKUz",
	"ConfigurationDetail_configuraionTitle": "_9SrvK",
	"ConfigurationDetail_hiddenItem": "iNzKM",
	"ConfigurationDetail_symonPipeName": "A7YGh"
};
export default ___CSS_LOADER_EXPORT___;
