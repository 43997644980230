import React from 'react';

import { Field } from 'formik';
import { FormattedNumber } from 'react-intl';

import BattleCardInputField from 'app/components/FormFields/BattleCardInputField/BattleCardInputField';

import { useBattleCard } from 'app/contexts/battleCardProvider';

import { BattleCardMeasure, BusinessTargetMeasure, MeasureFormatType, QuotaComponent } from 'app/models';

import block from 'utils/bem-css-modules';
import { FormattedCurrency } from 'utils/helpers/currencyHelpers';
import { formatMessage } from 'utils/messages/utils';

import style from './MeasureColumn.module.pcss';

const b = block(style);

interface MeasureColumnProps {
  panelData: BattleCardMeasure[];
  isEditingEnabled: boolean;
  isCurrencyConversionValid: boolean;
  isRootCard: boolean;
  currency: string;
  quotaComponentId: number;
  handleSubmit: (values: unknown, quotaComponentId: number) => void;
}

const MeasureColumn: React.FC<MeasureColumnProps> = ({
  panelData,
  isEditingEnabled,
  isCurrencyConversionValid,
  isRootCard,
  currency,
  quotaComponentId,
  handleSubmit
}: MeasureColumnProps) => {
  const { quotaComponentList } = useBattleCard();

  return (
    <div className={b('measureColumn')} data-testid="measure-column">
      <ColumnHeader
        isRootCard={isRootCard}
        quotaComponentList={quotaComponentList}
        quotaComponentId={quotaComponentId}
      />
      {panelData?.map((measure, index) => {
        return (
          <li className={b('lineItem')} key={index}>
            <span className={b('itemName')} data-testid={`measure-name-${measure.measureName}`}>
              {measure.measureName}
            </span>
            {(() => {
              if (!isCurrencyConversionValid) {
                return (
                  <span className={b('itemValue')} data-testid={`measure-value-${measure.measureName}`}>
                    {formatMessage('NOT_APPLICABLE_ABBREVIATION')}
                  </span>
                );
              } else if (isEditingEnabled && measure.isEditable) {
                return (
                  <NewBusinessTarget
                    quotaComponentId={quotaComponentId}
                    measure={measure}
                    index={index}
                    currency={currency}
                    handleSubmit={handleSubmit}
                  />
                );
              } else {
                return <MeasureValue measure={measure} currency={currency} />;
              }
            })()}
          </li>
        );
      })}
    </div>
  );
};

const ColumnHeader = ({
  isRootCard,
  quotaComponentList,
  quotaComponentId
}: {
  isRootCard: boolean;
  quotaComponentList: QuotaComponent[];
  quotaComponentId: number;
}) => {
  const hasMultipleQuotaComponents = quotaComponentList?.length > 1;

  if (hasMultipleQuotaComponents && isRootCard) {
    const quotaComponentName = quotaComponentList.find(
      (quotaComponent) => quotaComponent?.quotaComponentId === +quotaComponentId
    )?.quotaComponentName;

    return (
      <div className={b('columnHeader')} data-testid={`qc-measure-column-header-${quotaComponentId}`}>
        {formatMessage('TOTAL_WITH_LABEL', { label: quotaComponentName })}
      </div>
    );
  } else {
    return null;
  }
};

const MeasureValue = ({ measure, currency }: { measure: BattleCardMeasure; currency: string }) => {
  const getFormattedNumber = (value: number, formatType: MeasureFormatType | string) => {
    if (formatType === MeasureFormatType.PERCENTAGE) {
      return <FormattedNumber value={value / 100} style="percent" />;
    }
    return <FormattedNumber value={value} />;
  };

  return (
    <span className={b('itemValue')} data-testid={`measure-value-${measure.measureName}`}>
      {!!measure.measureValue &&
        !isNaN(measure.measureValue) &&
        (measure.measureFormatType === MeasureFormatType.CURRENCY ? (
          <FormattedCurrency value={measure.measureValue} currency={currency} />
        ) : (
          getFormattedNumber(measure.measureValue, measure.measureFormatType)
        ))}
    </span>
  );
};

const NewBusinessTarget = ({
  quotaComponentId,
  measure,
  index,
  currency,
  handleSubmit
}: {
  quotaComponentId: number;
  measure: BattleCardMeasure;
  index: number;
  currency: string;
  handleSubmit: (values: unknown, quotaComponentId: number) => void;
}) => {
  const newBusinessTargetPlaceholder =
    measure.measureName === BusinessTargetMeasure.NEW_BUSINESS_TARGET
      ? formatMessage('NEW_BUSINESS_TARGET_MEASURE_PLACEHOLDER')
      : formatMessage('BATTLECARD_MEASURE_PLACEHOLDER');
  return (
    <div className={b('targetContainer')} data-testid={`editable-measure-${measure.measureName}`}>
      <Field
        name={`newBusinessTarget-${quotaComponentId}`}
        lineItemFormat={measure.measureFormatType}
        index={index}
        placeholder={newBusinessTargetPlaceholder}
        currency={currency}
        component={BattleCardInputField}
        onSubmit={(values) => {
          handleSubmit(values, quotaComponentId);
        }}
      />
    </div>
  );
};

export default MeasureColumn;
