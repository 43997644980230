import {
  QueryResult,
  // eslint-disable-next-line no-restricted-imports
  useQuery
} from '@apollo/client';

import { GetSymonPipeNames, GetSymonPipeNamesVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_SYMON_PIPENAMES } from 'app/graphql/queries/getSymonPipeNames';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useGetSymonPipeNames = (tenantId: number): QueryResult<GetSymonPipeNames, GetSymonPipeNamesVariables> =>
  useQuery<GetSymonPipeNames, GetSymonPipeNamesVariables>(GET_SYMON_PIPENAMES, {
    variables: {
      tenantId
    },
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('GET_SYMON_PIPENAMES_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });
