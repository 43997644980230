import React, { Dispatch, SetStateAction, useState } from 'react';

import { Classes } from '@blueprintjs/core';
import { ChevronRight, ChevronDown } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import Icon from 'components/Icon/Icon';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';

import { SplitFeatures } from 'app/global/features';

import usePhase from 'app/hooks/usePhase';
import useTreatment from 'app/hooks/useTreatment';

import { DeploymentModelPhase, HierarchyBasedTargetItem } from 'app/models';
import { AllocationItemTypes } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { FormattedCurrency } from 'utils/helpers/currencyHelpers';
import { formatMessage } from 'utils/messages/utils';

import style from './AllocationSummary.module.pcss';
import {
  getAllocationSummaryItemsForManagePhase,
  getAllocationSummaryItemsForPlanPhase,
  getItemValueClassName
} from './AllocationSummaryUtils';

const b = block(style);

interface AllocationSummaryProps {
  plannedQuota?: number | null;
  revisedQuota?: number | null;
  assignedQuota?: number | null;
  businessTarget?: number | null;
  topDown?: number | null;
  adjustment?: number | null;
  hierarchyBasedTargets?: HierarchyBasedTargetItem[];
  quotaAdjustmentTargets?: HierarchyBasedTargetItem[];
  loading: boolean;
}

interface AllocationTargetsSummaryProps {
  targets: HierarchyBasedTargetItem[];
  loading: boolean;
}

interface AllocationHierarchyBasedToggleProps {
  isToggleExpanded: boolean;
  setIsToggleExpanded: Dispatch<SetStateAction<boolean>>;
}

interface AllocationSummaryItemValueProps {
  value: number;
  loading: boolean;
  itemType: number;
}
const AllocationSummaryItemValue: React.FC<AllocationSummaryItemValueProps> = ({ value, loading, itemType }) => {
  const { battleCardLookupMap, selectedBattleCardId } = useBattleCard();
  const itemValueClassName = [b('allocationItemValueWithBackground'), b('positiveGapItem'), b('negativeGapItem')];
  const className = getItemValueClassName(itemValueClassName, itemType, value);

  const isBattleCardCurrencyConversionValid = !battleCardLookupMap?.[selectedBattleCardId]?.invalidConversionReason;
  const battleCardLocalCurrency = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;

  if (loading) {
    return <div className={`${Classes.SKELETON} ${b('allocationItemValueSkeleton')}`} data-testid="value-loading" />;
  } else if (!isBattleCardCurrencyConversionValid) {
    return <span className={className}>{formatMessage('NOT_APPLICABLE_ABBREVIATION')}</span>;
  } else if (value) {
    return (
      <span className={className} data-testid="allocation-summary-value">
        {itemType === AllocationItemTypes.GAP && value > 0 ? `+` : null}
        <FormattedCurrency value={value} currency={battleCardLocalCurrency} />
      </span>
    );
  } else {
    return (
      <span className={className} data-testid="allocation-summary-value">
        {formatMessage('HYPHEN')}
      </span>
    );
  }
};

const AllocationHierarchyBasedTargetsSummary: React.FC<AllocationTargetsSummaryProps> = ({ targets, loading }) => {
  return (
    <div className={b('hierarchyTargets')}>
      {targets.map((hierarchyBasedTarget) => {
        return (
          <div className={b('allocationSummaryItem')} key={hierarchyBasedTarget.hierarchyKey}>
            <span data-testid="allocation-summary-hierarchy-based-target-label">
              {hierarchyBasedTarget.hierarchyName}
            </span>
            <AllocationSummaryItemValue
              itemType={AllocationItemTypes.TOP_DOWN_TARGET}
              value={hierarchyBasedTarget.value}
              loading={loading}
              data-testid="allocation-summary-hierarchy-based-target-item-value"
            />
          </div>
        );
      })}
    </div>
  );
};

const AllocationHierarchyBasedToggle: React.FC<AllocationHierarchyBasedToggleProps> = ({
  isToggleExpanded,
  setIsToggleExpanded
}) => {
  return (
    <IconButton
      icon={isToggleExpanded ? <Icon icon={<ChevronDown />} /> : <Icon icon={<ChevronRight />} />}
      type="button"
      onClick={(event) => {
        event.stopPropagation();
        setIsToggleExpanded((prev) => !prev);
      }}
      small
      testId={'hierarchy-based-target-detail-toggle'}
    />
  );
};

const AllocationSummary: React.FC<AllocationSummaryProps> = ({
  topDown,
  adjustment,
  plannedQuota,
  revisedQuota,
  assignedQuota,
  loading,
  businessTarget,
  hierarchyBasedTargets,
  quotaAdjustmentTargets
}: AllocationSummaryProps) => {
  const deploymentModelPhase = usePhase();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const [isHierarchyBasedTargetEnabled] = useTreatment(SplitFeatures.HIERARCHY_BASED_TOP_DOWN_TARGET_SETTING);
  const isPlanPhase = deploymentModelPhase === DeploymentModelPhase.plan;

  const [isHierarchyBasedTargetsExpanded, setIsHierarchyBasedTargetsExpanded] = useState(false);
  const [isQuotaBasedTargetsExpanded, setIsQuotaBasedTargetsExpanded] = useState(false);

  const getAllocationSummaryItems = () => {
    const summaryItemClassNames = [b('quotaItem'), b('allocationSummaryItem'), b('noValueItem')];

    if (isPlanPhase) {
      const planSummaryItems = {
        plannedQuota,
        businessTarget,
        topDown,
        adjustment
      };
      return getAllocationSummaryItemsForPlanPhase(
        selectedPillIdPlanTargets,
        planSummaryItems,
        summaryItemClassNames,
        isHierarchyBasedTargetEnabled
      );
    } else {
      const manageSummaryItems = {
        revisedQuota,
        assignedQuota,
        businessTarget
      };
      return getAllocationSummaryItemsForManagePhase(manageSummaryItems, summaryItemClassNames);
    }
  };

  const allocationSummaryItems = getAllocationSummaryItems();

  return (
    <div data-testid="allocation-summary">
      {allocationSummaryItems?.map((item) => {
        const shouldShowHierarchyBasedTargetToggle =
          item.type === AllocationItemTypes.TOP_DOWN_TARGET &&
          isHierarchyBasedTargetEnabled &&
          hierarchyBasedTargets?.length > 0;
        const shouldShowQuotaAdjustmentsBasedTargetToggle =
          item.type === AllocationItemTypes.QUOTA_ADJUSTMENTS &&
          isHierarchyBasedTargetEnabled &&
          quotaAdjustmentTargets?.length > 0;
        const shouldShowHierarchyBasedTarget = shouldShowHierarchyBasedTargetToggle && isHierarchyBasedTargetsExpanded;
        const shouldShowQuotaBasedTarget = shouldShowQuotaAdjustmentsBasedTargetToggle && isQuotaBasedTargetsExpanded;
        return (
          <React.Fragment key={item.title}>
            <div className={item.className}>
              <div>
                {shouldShowHierarchyBasedTargetToggle && (
                  <AllocationHierarchyBasedToggle
                    isToggleExpanded={shouldShowHierarchyBasedTarget}
                    setIsToggleExpanded={setIsHierarchyBasedTargetsExpanded}
                  />
                )}
                {shouldShowQuotaAdjustmentsBasedTargetToggle && (
                  <AllocationHierarchyBasedToggle
                    isToggleExpanded={shouldShowQuotaBasedTarget}
                    setIsToggleExpanded={setIsQuotaBasedTargetsExpanded}
                  />
                )}
                <span data-testid="allocation-summary-label">{item.title}</span>
              </div>
              <AllocationSummaryItemValue
                itemType={item.type}
                value={item.value}
                loading={loading}
                data-testid="allocation-summary-item-value"
              />
            </div>
            {shouldShowHierarchyBasedTarget && (
              <AllocationHierarchyBasedTargetsSummary targets={hierarchyBasedTargets} loading={loading} />
            )}
            {shouldShowQuotaBasedTarget && (
              <AllocationHierarchyBasedTargetsSummary targets={quotaAdjustmentTargets} loading={loading} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default AllocationSummary;
