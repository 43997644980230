import React, { useEffect, useState } from 'react';

import { GridApi } from '@ag-grid-community/core';
import { Spinner } from '@blueprintjs/core';
import { HTMLHeading } from '@varicent/components';

import DataMappingController from 'app/components/DataMappingDrillIn/DataMappingController';
import DataMappingDrillIn from 'app/components/DataMappingDrillIn/DataMappingDrillIn';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { FileTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { DataMappingType, DataPanelViews } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { SymonDataTableType } from './ConfigurationDialog';
import { useStartSymonPipeDataPreview } from './hooks/useStartSymonPipeDataPreview';
import style from './SymonDataMappingDialogContainer.module.pcss';

const b = block(style);

interface SymonDataMappingDialogContainerProps {
  isSubmitted?: boolean;
  handleDisabledNext: (value: boolean) => void;
  symonFileType?: SymonDataTableType;
}

const SymonDataMappingDialogContainer: React.FC<SymonDataMappingDialogContainerProps> = ({
  isSubmitted,
  handleDisabledNext,
  symonFileType
}) => {
  const { selectedPlanningCycle } = useScope();
  const { selectedConfiguration, setSelectedDataView } = useData();

  const [loading, setLoading] = useState(false);

  const { pollForConfigurationPreviewTokenId } = useStartSymonPipeDataPreview(
    selectedConfiguration?.exportId,
    selectedConfiguration?.pipeId,
    selectedPlanningCycle?.id
  );

  useEffect(() => {
    setLoading(true);
    handleDisabledNext(true);
    const getId = async () => {
      await pollForConfigurationPreviewTokenId();
    };
    getId().then(() => {
      setLoading(false);
      handleDisabledNext(false);
    });
  }, [selectedConfiguration]);

  const [gridApi, setGridApi] = useState<GridApi>(null);

  const getRowData = () => {
    const rowData = [];
    if (gridApi) {
      gridApi.forEachNode((node) => rowData.push(node.data));
    }
    return rowData;
  };

  return (
    <div className={b()}>
      {loading ? (
        <div className={b('spinnerContainer')} data-testid="spinner">
          <Spinner intent="primary" size={40} />
        </div>
      ) : (
        <>
          <DataMappingController
            data-testid="data-mapping-controller"
            rowData={getRowData()}
            controllerType={DataMappingType.CONFIGURATION}
            onCloseConfigurationDetail={() => {
              setSelectedDataView(DataPanelViews.DATA_OVERVIEW);
            }}
            withSpacing={false}
            isDialogMapping={true}
            isSubmitted={isSubmitted}
            fileType={symonFileType.value || FileTypeEnum.Activity}
            rootHierarchyId={symonFileType.rootHierarchyId}
          />
          <HTMLHeading
            bold
            tagLevel="h5"
            text={formatMessage('FILE_PREVIEW')}
            className={b('heading')}
            data-testid="data-mapping-heading"
          />
          <DataMappingDrillIn
            data-testid="data-mapping-drill-in"
            setGridApi={setGridApi}
            dataMappingType={DataMappingType.CONFIGURATION}
            withSpacing={false}
            fileType={symonFileType.value || FileTypeEnum.Activity}
            rootHierarchyId={symonFileType?.rootHierarchyId}
          />
        </>
      )}
    </div>
  );
};

export default SymonDataMappingDialogContainer;
