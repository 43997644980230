// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4Yhjl {\n  width: 100%;\n  cursor: pointer;\n}\n\n  .x1WK3 {\n      width: 100%;\n      display: flex;\n      justify-content: flex-end;\n    }\n\n  .PnCbA {\n    color: rgb(var(--color-cobalt-3));\n    cursor: pointer;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CurrencyCellRenderer": "_4Yhjl",
	"CurrencyCellRenderer_cellWrapper__alignRight": "x1WK3",
	"CurrencyCellRenderer__isLink": "PnCbA"
};
export default ___CSS_LOADER_EXPORT___;
