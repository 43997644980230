import React, { FC, useState, useMemo } from 'react';

import { Radio } from '@blueprintjs/core';
import { Filter } from '@carbon/icons-react';
// eslint-disable-next-line no-restricted-imports
import { HTMLHeading, Menu } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import { AccountFilter, AccountTerritoryFilterOptions, AccountTypeFilterOptions } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AccountFilterMenuV2.module.pcss';

const b = block(style);

const FilterOption: FC<{
  option: {
    key: AccountTerritoryFilterOptions | AccountTypeFilterOptions;
    name: string;
  };
  onChange: React.FormEventHandler<HTMLInputElement>;
  checked: boolean;
  testId: string;
  label: string;
}> = ({ option, onChange, checked, testId, label }) => (
  <li className={b('dropdownItem')} data-testid={testId}>
    <Radio onChange={onChange} checked={checked} data-testid={`filter-radio-${option.key}`} label={label} />
  </li>
);

const FilterHeader: FC<{ headerText: string }> = ({ headerText }) => (
  <li data-testid={'filter-menu-header'} className={b('dropdownItem')}>
    <HTMLHeading tagLevel="h6" text={headerText} />
  </li>
);

const ACCOUNT_TERRITORY_FILTER_OPTIONS = [
  { key: AccountTerritoryFilterOptions.ALL_TERRITORIES, name: formatMessage('SHOW_ALL_ACCOUNTS') },
  { key: AccountTerritoryFilterOptions.SELECTED_TERRITORIES, name: formatMessage('SHOW_SELECTED_TERRITORY_ACCOUNTS') }
];

const ACCOUNT_TYPE_FILTER_OPTIONS = [
  { key: AccountTypeFilterOptions.BASE_AND_OVERRIDES, name: formatMessage('BASE_OVERRIDE_ACCOUNTS') },
  { key: AccountTypeFilterOptions.OVERRIDES, name: formatMessage('OVERRIDE_ACCOUNTS_ONLY') }
];

export interface AccountFilterMenuV2Props {
  filterOptions: AccountFilter;
  onFilterOptionsChange: (key: Partial<AccountFilter>) => void;
  shouldShowTerritoryFilterOptions: boolean;
  isSmallVariant: boolean;
}

const AccountFilterMenuV2: React.FC<AccountFilterMenuV2Props> = ({
  filterOptions,
  onFilterOptionsChange,
  shouldShowTerritoryFilterOptions,
  isSmallVariant
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleToggleFilter = () => setIsPopupOpen((prev) => !prev);

  const territoryOptions = useMemo(
    () =>
      ACCOUNT_TERRITORY_FILTER_OPTIONS.map((option) => (
        <FilterOption
          option={option}
          onChange={() => onFilterOptionsChange({ territory: option.key })}
          checked={filterOptions.territory === option.key}
          testId={`account-territory-filter-option-${option.key}`}
          label={option.name}
          key={option.key}
        />
      )),
    [filterOptions.territory, onFilterOptionsChange]
  );

  const typeOptions = useMemo(
    () =>
      ACCOUNT_TYPE_FILTER_OPTIONS.map((option) => (
        <FilterOption
          option={option}
          onChange={() => onFilterOptionsChange({ override: option.key })}
          checked={filterOptions.override === option.key}
          testId={`account-type-filter-option-${option.key}`}
          label={option.name}
          key={option.key}
        />
      )),
    [filterOptions.override, onFilterOptionsChange]
  );

  return (
    <Popover
      content={
        <Menu data-testid="account-filter-menu">
          {shouldShowTerritoryFilterOptions && (
            <>
              <FilterHeader headerText={formatMessage('FILTER_ACCOUNTS_TERRITORY_HEADER')} />
              {territoryOptions}
            </>
          )}
          <FilterHeader headerText={formatMessage('FILTER_ACCOUNTS_TYPE_HEADER')} />
          {typeOptions}
        </Menu>
      }
      placement="top-end"
      minimal
      onClosed={handleToggleFilter}
      onOpened={handleToggleFilter}
    >
      <TextButton
        type={'button'}
        icon={<Filter />}
        tooltipText={isSmallVariant ? formatMessage('ACCOUNT_FILTER') : ''}
        text={isSmallVariant ? '' : formatMessage('ACCOUNT_FILTER')}
        testId={'account-filter-button'}
        minimal
        large={false}
        active={isPopupOpen}
      />
    </Popover>
  );
};

export default AccountFilterMenuV2;
