import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

import EllipsisText from 'components/EllipsisText/EllipsisText';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { MeasureFormatNumberType, MeasureFieldType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatNumber } from 'utils/messages/utils';

import style from './FooterCellRenderer.module.pcss';

const b = block(style);

interface FooterCellRendererProps extends ICellRendererParams {
  currency: string;
  measureType: string;
  alignLeft?: boolean;
  measureFieldType?: MeasureFieldType;
}

export const FooterCellRenderer: React.FC<FooterCellRendererProps> = ({
  data,
  colDef,
  currency,
  measureType,
  measureFieldType,
  alignLeft = false
}: FooterCellRendererProps) => {
  const [isHideCalcColumnTotalsEnabled] = useTreatment(SplitFeatures.HIDECALCCOLUMNTOTALS);
  const [isHidePercentTotalsEnabled] = useTreatment(SplitFeatures.HIDE_CALC_PERCENT_TOTALS);
  const key = colDef.field;
  let text;

  switch (measureType) {
    case MeasureFormatNumberType.PERCENT:
      text = formatNumber(measureFieldType === MeasureFieldType.FORMULA ? data[key] : data[key] / 100, {
        style: 'percent',
        maximumFractionDigits: 2
      });
      break;
    case MeasureFormatNumberType.UNIT:
      text = formatNumber(data[key], { style: 'unit' });
      break;
    case MeasureFormatNumberType.CURRENCY:
      text = formatNumber(data[key], { style: 'currency', currency });
      break;
    case MeasureFormatNumberType.NUMERIC:
      text = formatNumber(data[key]);
      break;
    default:
      text = data[key];
      break;
  }

  const shouldHidePercentTotals = isHidePercentTotalsEnabled && measureType === MeasureFormatNumberType.PERCENT;

  const shouldHideCalcColumnTotals = isHideCalcColumnTotalsEnabled && measureFieldType === MeasureFieldType.FORMULA;

  return (
    <div className={b({ alignLeft })} data-testid="footer-cell-content">
      <EllipsisText text={shouldHidePercentTotals || shouldHideCalcColumnTotals ? '' : text} />
    </div>
  );
};

export default FooterCellRenderer;
