// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kc-z0 {\n  display: flex;\n  height: calc(100% - var(--header-height));\n  align-items: center;\n  justify-content: center;\n  overflow: auto;\n}\n\n  ._5h-HP {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n  }\n\n  ._1a3cF {\n    color: #2abdbf;\n    font-size: 4em;\n    font-weight: 700;\n    text-align: center;\n  }\n\n  .ltj2l {\n    font-size: 1em;\n    width: 600px;\n    text-align: center;\n    margin: auto;\n    max-width: 350px;\n  }\n\n  .FHxCE {\n    background: none;\n    border: none;\n    color: rgb(var(--color-cobalt-3));\n    font-weight: bold;\n  }\n\n  ._3gNAd {\n    display: flex;\n    justify-content: center;\n  }\n\n  ._9gc\\+7 {\n    max-width: 20em;\n    width: auto;\n    margin-left: 25px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Fallback": "kc-z0",
	"Fallback_fallbackContainer": "_5h-HP",
	"Fallback_titleWrapper": "_1a3cF",
	"Fallback_textWrapper": "ltj2l",
	"Fallback_refreshButton": "FHxCE",
	"Fallback_artworkWrapper": "_3gNAd",
	"Fallback_artwork": "_9gc+7"
};
export default ___CSS_LOADER_EXPORT___;
