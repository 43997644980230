import React, { FC } from 'react';

import { LassoPolygon } from '@carbon/icons-react';
import { IllustrationFileBox } from '@varicent/components';

import { useDynamicMapHierarchyCopy } from 'app/components/TerritoryMap/hooks/useDynamicMapHierarchyCopy';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import useCanUser from 'app/hooks/useCanUser';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import style from './EmptyMapGridMessage.module.pcss';

const b = block(style);

export const EmptyMapGridMessage: FC = () => {
  // We intentionally do not use capabilities as the message should be based solely on role, not situation
  const userHasPermissionToEditRules = useCanUser(UserAction.MAP_RULE_EDIT);

  const { loadingTerritoryGroups, territoryGroupTree } = useDedicatedMapProvider();
  const { emptyMapGridText } = useDynamicMapHierarchyCopy();
  const hasTgsUnderTgt = territoryGroupTree.territoryGroups.length > 1;
  return (
    <div className={b()} data-testid="empty-map-grid-message">
      <IllustrationFileBox size={136} />
      <p className={b('text')} data-testid="empty-map-grid-message-text">
        {userHasPermissionToEditRules ? (
          <strong>
            {formatMessage('USE_LASSO_TOOL')} <LassoPolygon className={b('lassoIcon')} />
            {formatMessage('DRAW_NEW_TERRITORY')}
          </strong>
        ) : (
          <>
            <strong>{formatMessage('EMPTY_MAP_GRID_TITLE')}</strong>
            <br />
            {loadingTerritoryGroups || hasTgsUnderTgt ? formatMessage('EMPTY_MAP_TG_GRID_MESSAGE') : emptyMapGridText}
          </>
        )}
      </p>
    </div>
  );
};
