import React from 'react';

import { Field, FieldProps } from 'formik';

import { BasicSelect, BasicSelectProps } from './BasicSelects';

export type BasicSelectFieldProps<ValueType extends number | string> = Omit<
  BasicSelectProps<ValueType>,
  'value' | 'onSelectedValueChange' | 'testId'
> & {
  name: string;
  testId?: string;
};

export function BasicSelectField<ValueType extends number | string>(props: BasicSelectFieldProps<ValueType>) {
  return <Field {...props} component={BasicSelectWithinField} />;
}

function BasicSelectWithinField<ValueType extends number | string>(
  props: BasicSelectFieldProps<ValueType> & FieldProps
) {
  const { field, form, meta: _meta, testId, ...basicSelectProps } = props;
  return (
    <BasicSelect<ValueType>
      {...basicSelectProps}
      testId={testId ?? field.name}
      value={field.value}
      onSelectedValueChange={(value) => form.setFieldValue(field.name, value)}
      onIsOpenChange={(isOpen) => {
        if (!isOpen) {
          form.setFieldTouched(field.name);
        }
        basicSelectProps.onIsOpenChange?.(isOpen);
      }}
    />
  );
}
