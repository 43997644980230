import { GetMemberList, GetTerritoryRulesForAccountMove } from 'app/graphql/generated/graphqlApolloTypes';

export const handleTerritoryRuleMerge = (
  previousResult: GetTerritoryRulesForAccountMove,
  fetchMoreResult: GetTerritoryRulesForAccountMove
): GetTerritoryRulesForAccountMove => {
  const fetchMoreRules = fetchMoreResult?.getTerritoryRules?.territoryRules || [];
  const previousRules = previousResult?.getTerritoryRules?.territoryRules || [];

  const previousRuleIds = new Set(previousRules.map((rule) => rule.ruleId));

  const filteredFetchMoreRules = fetchMoreRules.filter((rule) => !previousRuleIds.has(rule.ruleId));

  const territoryRules = [...previousRules, ...filteredFetchMoreRules];

  return {
    getTerritoryRules: {
      __typename: previousResult?.getTerritoryRules?.__typename,
      territoryRules,
      totalCount: previousResult?.getTerritoryRules?.totalCount
    }
  };
};

export const handleMemberListMerge = (previousResult: GetMemberList, fetchMoreResult: GetMemberList): GetMemberList => {
  const fetchMoreMembers = fetchMoreResult?.getMemberList.memberList || [];
  const previousMembers = previousResult?.getMemberList.memberList || [];

  const previousMemberIds = new Set(previousMembers.map((member) => member.memberId));

  const filteredFetchMoreMembers = fetchMoreMembers.filter((member) => !previousMemberIds.has(member.memberId));

  const memberList = [...previousMembers, ...filteredFetchMoreMembers];

  return {
    getMemberList: {
      __typename: previousResult?.getMemberList?.__typename,
      memberList,
      totalCount: previousResult?.getMemberList?.totalCount
    }
  };
};
