import { useEffect } from 'react';

import { MapSelectionActions } from 'app/components/TerritoryMap/hooks/useMapSelectionActions';

const EXTERNAL_UI_CONTROL_KEY = 'EXTERNAL_UI_CONTROL';

type ExternalUIControl = Partial<{
  mapSelectionActions: MapSelectionActions;
  isMapLoading: boolean;
  hoverPolygon: (polygonId: string) => void;
  hoverAccount: (accountName: string) => void;

  mapSelectionActionsSecondary: MapSelectionActions;
  isMapLoadingSecondary: boolean;
  hoverPolygonSecondary: (polygonId: string) => void;
  hoverAccountSecondary: (accountName: string) => void;
}>;

export const useExposeExternalUIControl = <KeyType extends keyof ExternalUIControl>(
  key: KeyType,
  value: ExternalUIControl[KeyType]
): void => {
  useEffect(() => {
    globalThis[EXTERNAL_UI_CONTROL_KEY][key] = value;
  }, [key, value]);

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      globalThis[EXTERNAL_UI_CONTROL_KEY][key] = null;
    };
  }, []);
};

const setupE2EControl = () => {
  if (EXTERNAL_UI_CONTROL_KEY in globalThis) return;
  globalThis[EXTERNAL_UI_CONTROL_KEY] = {};
};

setupE2EControl();
