// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zeyc0 {\n  align-items: center;\n  background-color: rgb(var(--color-light-gray-5));\n  border-radius: 4px;\n  padding-left: 16px;\n  padding-right: 16px;\n  overflow-y: hidden;\n  overflow-x: hidden;\n  display: inline-flex;\n}\n\n  .DGVYi {\n    background-color: white;\n  }\n\n  .KgI-k {\n  }\n\n  .zEUOP {\n    margin: 0;\n  }\n\n  .kgHuj {\n    background-color: transparent;\n  }\n\n  .l1vXK, .euG3Y {\n    margin-left: 8px !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RuleEditorRow": "Zeyc0",
	"RuleEditorRow__darkMode": "DGVYi",
	"RuleEditorRow__connectors": "KgI-k " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["rule-connectors"] + "",
	"RuleEditorRow__inline": "zEUOP",
	"RuleEditorRow__isSelectedOrHovered": "kgHuj",
	"RuleEditorRow_editBtn": "l1vXK",
	"RuleEditorRow_deleteBtn": "euG3Y"
};
export default ___CSS_LOADER_EXPORT___;
