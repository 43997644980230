// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-KmNP {\n    padding: 10px;\n    background: white;\n    width: 190px;\n    height: 48px;\n  }\n\n:root .bp3-popover.bp3-minimal.filterHelper {\n  margin-top: 45px !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FilterHelper_filterContainer": "-KmNP"
};
export default ___CSS_LOADER_EXPORT___;
