import gql from 'graphql-tag';

export const EXPORT_CHANGE_HISTORY = gql`
  query ExportChangeHistory($input: ChangeHistoryInput!) {
    getChangeHistoryExport(input: $input) {
      id
      jobUUID
      objectType
      tenantId
      actorEmail
      status
      isActive
      description
      startDate
      progress
      downloadUrl
    }
  }
`;
