import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import {
  CancelSymonPipeConfiguration,
  CancelSymonPipeConfigurationVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const CANCEL_SYMON_PIPE_CONFIGURATION = gql`
  mutation CancelSymonPipeConfiguration($input: CancelSymonPipeConfigurationInput) {
    cancelSymonPipeConfiguration(input: $input)
  }
`;

export const useCancelSymonPipeConfiguration = createUseMutation<
  CancelSymonPipeConfiguration,
  CancelSymonPipeConfigurationVariables
>(CANCEL_SYMON_PIPE_CONFIGURATION);
