import React, { useImperativeHandle, useState } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';
import { ChevronDown } from '@carbon/icons-react';

import CustomSelect from 'components/CustomSelect/CustomSelect';
import { KeyValue } from 'components/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './DropdownMenuCellEditor.module.pcss';

const b = block(style);

interface DropdownMenuCellEditorProps extends ICellEditorParams {
  items: KeyValue<string>[];
}

const DropdownMenuCellEditor = (props: DropdownMenuCellEditorProps, ref) => {
  const { items, value } = props;
  const [currVal, setCurrVal] = useState<string>(value);

  const handleItemSelect = (item) => {
    setCurrVal(item.value);
  };

  // necessary for ag-grid cell editors to return value back to grid
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return currVal;
      }
    };
  });

  return (
    <div data-testid="select-menu-cell-editor" className={b()}>
      <CustomSelect
        value={currVal ? { key: currVal, value: currVal } : null}
        items={items}
        onChange={(item) => {
          handleItemSelect(item);
        }}
        rightIcon={<ChevronDown />}
        placeHolderText={currVal ?? formatMessage('SELECT')}
      />
    </div>
  );
};

export default React.forwardRef(DropdownMenuCellEditor);
