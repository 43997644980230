import gql from 'graphql-tag';

export const GET_INSIGHTS_CARD_STATS = gql`
  query GetInsightsCardStats($input: TGTAggregationInput!) {
    getTGTAggregate(input: $input) {
      numberOfAccounts
      measureValue
    }
  }
`;
