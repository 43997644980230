import React, { useMemo } from 'react';

import { Layer, Source } from 'react-map-gl';

import { LineRulerToolLineStyle, LayerIds, SourceIds } from 'app/components/TerritoryMap/MapStyleTokens';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { CustomerVisualization } from 'app/models';

interface LineRulerProps {
  lineStringCoordinates: number[][];
}

const LineRuler: React.FC<LineRulerProps> = ({ lineStringCoordinates }) => {
  const { customerVisuals } = useDedicatedMapProvider();
  const data: GeoJSON.Feature<GeoJSON.Point | GeoJSON.LineString> = useMemo(
    () => ({
      type: 'Feature',
      properties: {},
      geometry:
        lineStringCoordinates.length === 1
          ? { type: 'Point', coordinates: lineStringCoordinates[0] }
          : { type: 'LineString', coordinates: lineStringCoordinates }
    }),
    [lineStringCoordinates]
  );

  const beforeId = useMemo(() => {
    if (customerVisuals === CustomerVisualization.CLUSTER) {
      return LayerIds.account.cluster.symbol;
    } else if (customerVisuals === CustomerVisualization.HEAT) {
      return LayerIds.account.heat.circle;
    }
    return '';
  }, [customerVisuals]);

  return (
    <Source type="geojson" id={SourceIds.measuringTool} data={data}>
      <Layer
        id={LayerIds.measuringTool.line}
        type="line"
        source={SourceIds.measuringTool}
        layout={LineRulerToolLineStyle.lineLayout}
        paint={LineRulerToolLineStyle.linePaint}
        beforeId={beforeId}
      />
      <Layer
        id={LayerIds.measuringTool.vertice}
        type="circle"
        source={SourceIds.measuringTool}
        paint={LineRulerToolLineStyle.verticePaint}
        beforeId={beforeId}
      />
    </Source>
  );
};

export default LineRuler;
