// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wFfnb {\n  display: flex;\n  grid-gap: var(--s);\n  gap: var(--s);\n  align-items: center;\n}\n\n  .K7fTB {\n    color: var(--text-heading);\n    font-weight: bold;\n    font-size: 16px;\n    margin: 0;\n    white-space: nowrap;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompareModeMenu": "wFfnb",
	"CompareModeMenu_header": "K7fTB"
};
export default ___CSS_LOADER_EXPORT___;
