import { ColDef } from '@ag-grid-community/core';

import ContributorActionCellRenderer from 'app/components/AdvancedGrid/CellRenderers/ContributorActionCellRenderer/ContributorActionCellRenderer';
import StatusCellRenderer from 'app/components/StatusCellRenderer/StatusCellRenderer';

import { ContributorTreeData } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const buildContributorColumnDef = (
  contributorTreeData: ContributorTreeData[],
  onApprove: (data: ContributorTreeData) => void,
  onReject: (data: ContributorTreeData) => void,
  onSubmit: (data: ContributorTreeData) => void,
  submitWarningMessage: string,
  emailClass: string
): ColDef[] => {
  return [
    {
      headerName: formatMessage('USER'),
      field: 'user',
      flex: 1,
      minWidth: 300
    },
    {
      headerName: formatMessage('EMAIL'),
      field: 'email',
      flex: 1,
      minWidth: 200,
      cellClass: emailClass
    },
    {
      headerName: formatMessage('STATUS'),
      field: 'status',
      flex: 1,
      minWidth: 150,
      cellRendererFramework: StatusCellRenderer
    },
    {
      headerName: '',
      field: 'action',
      flex: 1,
      minWidth: 150,
      cellRendererFramework: ContributorActionCellRenderer,
      cellRendererParams: {
        contributorTreeData,
        onApprove,
        onReject,
        onSubmit,
        submitWarningMessage
      }
    },
    {
      headerName: formatMessage('APPROVER'),
      field: 'approver',
      flex: 1,
      minWidth: 300,
      menuTabs: []
    }
  ];
};

export default buildContributorColumnDef;
