import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetDataPreviewFromSymonPipe,
  GetDataPreviewFromSymonPipeVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_DATA_PREVIEW_FROM_SYMON_PIPE = gql`
  query GetDataPreviewFromSymonPipe($planningCycleId: Int!, $pollingTokenId: String!) {
    getDataPreviewFromSymonPipe(input: { planningCycleId: $planningCycleId, pollingTokenId: $pollingTokenId }) {
      rows
      colHeaders {
        name
        type
      }
      pollingTokenId
      status
    }
  }
`;

export const useGetDataPreviewForSymonPipe = createUseQuery<
  GetDataPreviewFromSymonPipe,
  GetDataPreviewFromSymonPipeVariables
>(GET_DATA_PREVIEW_FROM_SYMON_PIPE);
