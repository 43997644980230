import React, { useMemo } from 'react';

import { Classes } from '@blueprintjs/core';
import { CheckmarkFilled, Network_3Reference as NetworkReference } from '@carbon/icons-react';

import Icon from 'components/Icon/Icon';

import ComparisonModeCoinsortButton from 'app/components/CoinsortButtons/ComparisonModeCoinsortButton';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './ScenarioPlanningCompareAcrossDmHeader.module.pcss';

const b = block(style);

const ScenarioPlanningCompareAcrossDmHeader: React.FC = () => {
  const { selectedBattleCard, selectedQuotaComponentId, selectedDeploymentModelId } = useMapContextRedistributor();

  const battlecardName = selectedBattleCard?.battlecardName;
  const quotaComponentName = selectedBattleCard?.quotaComponents.find(
    (qc) => qc.quotaComponentId === selectedQuotaComponentId
  )?.quotaComponentName;

  const relevantScenario = useRelevantScenario(selectedDeploymentModelId);
  const scenarioName = relevantScenario?.deploymentModelName;
  const isScenarioPublished = relevantScenario?.isPublished;

  const isLoading = !scenarioName || !quotaComponentName || !battlecardName;
  return (
    <div className={b('')} data-testid={`scenario-header-${scenarioName}`}>
      {isLoading ? (
        <div className={b('skeletonWrapper')} data-testid="compare-selection-text-loading">
          <div className={Classes.SKELETON} />
          <div className={Classes.SKELETON} />
          <div className={Classes.SKELETON} />
          <div className={Classes.SKELETON} />
        </div>
      ) : (
        <div className={b('headerContainer')}>
          <div className={b('scenarioNameHeaderIcon', { isScenarioPublished })}>
            <Icon icon={isScenarioPublished ? <CheckmarkFilled /> : <NetworkReference />} />
          </div>
          <div className={b('compareSelectionText')} data-testid={`compare-selection-text`}>
            {formatMessage('COMPARE_SELECTION', { scenarioName, quotaComponentName })}
            <b className={b('battlecardName')}>{battlecardName}</b>
          </div>
          <CanUser
            perform={UserAction.RUN_COINSORT}
            yes={
              <ComparisonModeCoinsortButton
                selectedBattleCardId={selectedBattleCard?.battlecardId.toString()}
                selectedQuotaComponentId={selectedQuotaComponentId}
                selectedDeploymentModelId={selectedDeploymentModelId}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

const useRelevantScenario = (relevantScenarioId: number) => {
  const { userPlanningCycles } = useUser();
  const { selectedPlanningCycle } = useScope();
  return useMemo(
    () =>
      userPlanningCycles
        ?.find((pc) => pc.planningCycleId === selectedPlanningCycle?.id)
        ?.deploymentModels.find((dm) => dm.deploymentModelId === relevantScenarioId),
    [userPlanningCycles, selectedPlanningCycle, relevantScenarioId]
  );
};

export default ScenarioPlanningCompareAcrossDmHeader;
