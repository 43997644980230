// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".etW7B {\n    margin: var(--m) 0 var(--l);\n  }\n  .lc9\\+5 {\n    margin-top: var(--m);\n  }\n  ._7Mno\\+ {\n    margin-bottom: var(--l);\n  }\n  .zil4Z {\n    border-radius: 50%;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompareModeDialog_bodyText": "etW7B",
	"CompareModeDialog_callout": "lc9+5",
	"CompareModeDialog_calloutContainer": "_7Mno+",
	"CompareModeDialog_icon": "zil4Z"
};
export default ___CSS_LOADER_EXPORT___;
