// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ze4QL {\n  display: flex;\n  height: 100%;\n}\n\n  .UkXKv {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    height: 100%;\n    overflow: hidden;\n  }\n\n  .sozM8 {\n    width: 132px;\n  }\n\n  .LOCEB {\n    font-size: 16px;\n    font-weight: 600;\n    margin-bottom: 4px;\n  }\n\n  .WDijd {\n    font-size: 14px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EmbeddedMapFallbackViews": "ze4QL",
	"EmbeddedMapFallbackViews_fallbackContainer": "UkXKv",
	"EmbeddedMapFallbackViews_warningError": "sozM8",
	"EmbeddedMapFallbackViews_fallbackHeader": "LOCEB",
	"EmbeddedMapFallbackViews_fallbackBody": "WDijd"
};
export default ___CSS_LOADER_EXPORT___;
