// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Qgj7W {\n  border: var(--grey-border);\n  border-top: none;\n  border-left: none;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  margin: var(--page-margin);\n  overflow-x: auto;\n  position: relative;\n  width: var(--command-center-middle-panel-width);\n}\n\n  .Qgj7W .bp3-dialog {\n    padding: 20px 30px;\n  }\n\n  .ktCvd {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    height: 100%;\n  }\n\n  .Q8eCN {\n    padding: 20px 30px;\n  }\n\n  .ozM27 {\n    word-break: break-all;\n    white-space: normal;\n  }\n\n  .PLpDh {\n    margin: 0 0 20px;\n  }\n\n  .NZsH5 {\n    align-items: center;\n    height: 64px;\n    display: flex;\n    justify-content: flex-end;\n  }\n\n  .JBH5i {\n    align-self: flex-end;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n\n  .JBH5i > :first-child {\n      margin: 0 8px 0 0;\n    }\n\n  .Gfzld {\n    padding: 6px;\n  }\n\n  .xG6A5:first-child {\n      border-top: none;\n    }\n\n  .DVMEw {\n    border-bottom: 1px solid rgb(var(--color-light-gray-2));\n    padding: 16px 16px 0;\n  }\n\n  .RW\\+Cu {\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuotaComponentsPage": "Qgj7W",
	"QuotaComponentsPage_spinner": "ktCvd",
	"QuotaComponentsPage_form": "Q8eCN",
	"QuotaComponentsPage_dialogTitle": "ozM27",
	"QuotaComponentsPage_quotaComponentInput": "PLpDh",
	"QuotaComponentsPage_footer": "NZsH5",
	"QuotaComponentsPage_buttons": "JBH5i",
	"QuotaComponentsPage_addQuotaComponent": "Gfzld",
	"QuotaComponentsPage_quotaComponentsAccordion": "xG6A5",
	"QuotaComponentsPage_targetSettingSection": "DVMEw",
	"QuotaComponentsPage_label": "RW+Cu " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label-text"] + ""
};
export default ___CSS_LOADER_EXPORT___;
