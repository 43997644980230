import { FC, ReactElement } from 'react';

import useCanUser from 'app/hooks/useCanUser';

import { UserAction } from './userActions';

type CanUserProps = {
  perform: UserAction;
  yes: ReactElement;
  no?: ReactElement;
};

const CanUser: FC<CanUserProps> = ({ perform, yes, no = null }) => (useCanUser(perform) ? yes : no);

export default CanUser;
