import {
  // eslint-disable-next-line no-restricted-imports
  useMutation,
  MutationTuple
} from '@apollo/client';

import { SaveSymonCredentials, SaveSymonCredentialsVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { SAVE_SYMON_CREDENTIALS } from 'app/graphql/mutations/saveSymonCredentials';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

/**
 * This save mutation is used for saving as well as deletion
 * When its passed with both symonDomain and symonUiClientKey it will save the values
 * To delete, pass null on the variables
 */
export const useSaveSymonIntegrations = (): MutationTuple<SaveSymonCredentials, SaveSymonCredentialsVariables> =>
  useMutation<SaveSymonCredentials, SaveSymonCredentialsVariables>(SAVE_SYMON_CREDENTIALS, {
    onCompleted(data) {
      if (!data.saveSymonCredentials.symonDomain && !data.saveSymonCredentials.symonUiClientKey) {
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('SYMON_CREDENTIALS_DELETE_SUCCESS'), 'success');
      } else {
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('SYMON_CREDENTIALS_SAVE_SUCCESS'), 'success');
      }
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('SYMON_CREDENTIALS_SAVE_FAILED'), 'danger');
    }
  });
