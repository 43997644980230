import React from 'react';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { AgGridReactProps } from '@ag-grid-community/react/lib/shared/interfaces';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';

import block from 'utils/bem-css-modules';
import { config } from 'utils/config';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import style from './AdvancedGrid.module.pcss';

const b = block(style);

const modules = [
  ClientSideRowModelModule,
  RowGroupingModule,
  ServerSideRowModelModule,
  InfiniteRowModelModule,
  StatusBarModule
];

type BasicGridProps = AgGridReactProps;

const defaultGridProps: AgGridReactProps = {
  modules,
  debug: config.NODE_ENV === 'development',
  suppressColumnVirtualisation: config.NODE_ENV === 'test',
  suppressScrollOnNewData: true,
  suppressAnimationFrame: true,
  suppressColumnMoveAnimation: true,
  suppressMovableColumns: true,
  suppressRowDrag: true
};

const ExperimentalBasicGrid: React.FC<BasicGridProps> = (props: BasicGridProps) => {
  return (
    <div className={b()} data-testid="basic-grid">
      <div className="ag-theme-alpine">
        <AgGridReact {...defaultGridProps} {...props} />
      </div>
    </div>
  );
};

export default React.memo(ExperimentalBasicGrid);
