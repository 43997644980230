// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ADtMC {\n    color: rgb(var(--color-gray-1));\n  }\n  .dWP9N {\n    height: 100%;\n  }\n  .oSTKD {\n    height: 100%;\n  }\n  .Chy4s {\n    padding: var(--data-panel-padding) var(--data-panel-large-padding);\n    width: var(--custom-measure-dialog-width);\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n  ._2UoSU {\n    display: flex;\n    justify-content: space-between;\n  }\n  ._2eHHa {\n      justify-content: flex-end;\n    }\n  .FP6V2:not(:first-child) {\n      margin-left: 8px;\n    }\n  .VeOrT {\n    display: flex;\n    margin-left: 8px;\n  }\n  ._68M-G {\n    margin-bottom: var(--data-panel-margin);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .Qd7j4 {\n    display: flex;\n  }\n  ._6tx13 {\n    margin-top: var(--custom-measure-footer-margin-top);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SheetsAddFieldForm_icon": "ADtMC",
	"SheetsAddFieldForm_formContainer": "dWP9N",
	"SheetsAddFieldForm_formContent": "oSTKD",
	"SheetsAddFieldForm_formFieldContainer": "Chy4s",
	"SheetsAddFieldForm_buttons": "_2UoSU",
	"SheetsAddFieldForm_buttons__firstPage": "_2eHHa",
	"SheetsAddFieldForm_button": "FP6V2",
	"SheetsAddFieldForm_rightSideButtons": "VeOrT",
	"SheetsAddFieldForm_formHeader": "_68M-G",
	"SheetsAddFieldForm_paginationDotsContainer": "Qd7j4",
	"SheetsAddFieldForm_formFooter": "_6tx13"
};
export default ___CSS_LOADER_EXPORT___;
