import gql from 'graphql-tag';

export const CANCEL_COIN_SORT = gql`
  mutation CancelCoinsort($battlecardId: Int!, $quotaComponentId: Int!) {
    cancelCoinsort(input: { battlecardId: $battlecardId, quotaComponentId: $quotaComponentId }) {
      actorEmail
      assignments
      jobAction
      jobKey
      coinsortStatus
      numberOfRules
      numberOfRulesProcessed
      timeToLive
    }
  }
`;
