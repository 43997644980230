// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".akqoq {\n  padding: var(--dialog-padding) 0;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  position: sticky;\n  bottom: 0;\n  background-color: var(--white);\n}\n\n  .LN0CV {\n    display: flex;\n    flex: 1;\n    justify-content: flex-end;\n  }\n\n  .\\+7ZuF:not(:first-child) {\n      margin-left: 18px;\n    }\n\n  .\\+7ZuF:first-child:nth-last-child(3) {\n    flex: 1;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DialogFooter": "akqoq",
	"DialogFooter_buttons": "LN0CV",
	"DialogFooter_button": "+7ZuF"
};
export default ___CSS_LOADER_EXPORT___;
