import React, { useState } from 'react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Card from 'components/Card/Card';
import Dialog from 'components/Dialog/Dialog';

import ClipboardTextInput from 'app/components/ClipboardTextInput/ClipboardTextInput';

import { UserProfile } from 'app/core/userManagement/userProvider';

import { useGenerateApiKey } from 'app/graphql/hooks/useGenerateApiKey';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { config } from 'utils/config';
import { formatMessage } from 'utils/messages/utils';

import style from './GenerateApiKeyCard.module.pcss';

const b = block(style);

interface GenerateApiKeyCardProps {
  userProfile: UserProfile;
}

const GenerateApiKeyCard: React.FC<GenerateApiKeyCardProps> = ({ userProfile }: GenerateApiKeyCardProps) => {
  const [generateApiKeyDialogOpen, setGenerateApiKeyDialogOpen] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>('');
  const showToast = useShowToast();

  const [generateApiKey, { loading: generateApiKeyLoading }] = useGenerateApiKey();
  const onGenerateApiKey = async () => {
    const newApiKey = await generateApiKey({
      variables: {
        input: {
          emailAddress: userProfile.emailAddress
        }
      }
    });
    if (newApiKey?.data?.generateAPIKey?.apiKey) {
      setApiKey(newApiKey.data.generateAPIKey.apiKey);
      setGenerateApiKeyDialogOpen(true);
    } else {
      showToast(formatMessage('GENERATE_API_KEY_ERROR_MESSAGE'), 'danger');
    }
  };

  const apiURL = config.APPSYNC_GRAPHQL_ENDPOINT;

  return (
    <>
      <Card className={b('')} data-testid="generate-api-key-card">
        <p className={b('generateApiLink')}>{apiURL}</p>
        <p>{formatMessage('GENERATE_API_KEY_DESCRIPTION_1')}</p>
        <p>{formatMessage('GENERATE_API_KEY_DESCRIPTION_2')}</p>
        <div className={b('generateButton')}>
          <TextButton
            text={formatMessage('GENERATE_KEY')}
            type="button"
            testId="generate-api-key-button"
            intent="primary"
            onClick={onGenerateApiKey}
            loading={generateApiKeyLoading}
            disabled={generateApiKeyLoading}
          />
        </div>
      </Card>
      {generateApiKeyDialogOpen && (
        <Dialog
          isOpen={generateApiKeyDialogOpen}
          title={formatMessage('API_KEY_GENERATED')}
          onSubmit={() => setGenerateApiKeyDialogOpen(false)}
          onClose={() => setGenerateApiKeyDialogOpen(false)}
          size="small"
          confirmButtonText={formatMessage('DONE')}
          showCancel={false}
        >
          <p>{formatMessage('API_KEY_GENERATED_DESC_1')}</p>
          <p>{formatMessage('API_KEY_GENERATED_DESC_2')}</p>
          <ClipboardTextInput inputValue={apiKey} toastMessage={formatMessage('API_KEY_COPIED')} />
        </Dialog>
      )}
    </>
  );
};

export default GenerateApiKeyCard;
