import gql from 'graphql-tag';

export const GET_SYMON_PIPE_EXPORT_NODES = gql`
  query GetSymonPipeExportNodes($planningCycleId: Int!, $pipeId: String!) {
    getSymonPipeExportNodes(input: { planningCycleId: $planningCycleId, pipeId: $pipeId }) {
      exportId
      exportNodeName
      planningCycleInfo {
        planningCycleId
        planningCycleName
      }
    }
  }
`;
