import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Field, useFormikContext } from 'formik';

import { SearchableSelectMenuItem } from 'components/models';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import { SplitFeatures } from 'app/global/features';

import { useScenarioSummaries } from 'app/graphql/hooks/useScenarioSummaries';
import { useGetOptionsForCompareForm } from 'app/graphql/queries/getOptionsForMapCompareForm';

import useTreatment from 'app/hooks/useTreatment';

import block from 'utils/bem-css-modules';
import { findMappableBcTgtCombos } from 'utils/helpers/compareModeHelpers';
import { formatMessage } from 'utils/messages/utils';

import { CompareModeValues } from './CompareModeDialog';
import style from './CompareModeSelection.module.pcss';

const b = block(style);

export interface CompareModeSelectionProps {
  values: CompareModeValues;
  initialValues: CompareModeValues;
  ordinal: 'primary' | 'secondary';
  selectedQuotaComponentId: number;
}
const DYNAMIC_COPY = {
  primary: {
    battlecardTitle: formatMessage('BATTLE_CARD_1'),
    compareModeTitle: formatMessage('COMPARE')
  },
  secondary: {
    battlecardTitle: formatMessage('BATTLE_CARD_2'),
    compareModeTitle: formatMessage('WITH')
  }
};

const CompareModeSelection: React.FC<CompareModeSelectionProps> = ({ values, initialValues, ordinal }) => {
  const { scenarioSummaries } = useScenarioSummaries();
  const [battleCardSearchText, setBattleCardSearchText] = useState('');
  const [scenarioSearchText, setScenarioSearchText] = useState('');
  const [quotaComponentSearchText, setQuotaComponentSearchText] = useState('');
  const portalRef = useRef<HTMLDivElement>(null);

  const [isAccountFirstMapOn] = useTreatment(SplitFeatures.ACCOUNT_FIRST_MAP);

  const { setFieldValue } = useFormikContext();

  const scenarioId = +values[ordinal].scenario?.value;
  const { data } = useGetOptionsForCompareForm({
    skip: !scenarioId,
    variables: {
      input: {
        deploymentModelId: scenarioId
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  const scenarioItems = useMemo(
    () =>
      scenarioSummaries?.map((scenario) => ({
        key: scenario.deploymentModelName,
        value: scenario.deploymentModelId.toString()
      })) ?? [],
    [scenarioSummaries]
  );
  useEffect(() => {
    const prevScenario = scenarioItems.find((scenario) => +scenario.value === scenarioId);
    setFieldValue(`${ordinal}.scenario`, prevScenario ?? null);
  }, [scenarioItems]);

  const allQuotaComponents = data?.getDeploymentModelSpec.quotaComponents;
  const quotaComponentItems = useMemo(
    () =>
      allQuotaComponents?.map((item) => ({
        key: item.quotaComponentName,
        value: item.quotaComponentId.toString()
      })) ?? [],
    [allQuotaComponents]
  );

  useEffect(() => {
    const nextQuotaComponentItem = findBestNextKeyValue(
      initialValues[ordinal].quotaComponent,
      values[ordinal].quotaComponent,
      quotaComponentItems
    );
    setFieldValue(`${ordinal}.quotaComponent`, nextQuotaComponentItem);
  }, [quotaComponentItems]);

  const quotaComponentId = +values[ordinal].quotaComponent?.value;
  const battleCardSource = data?.getDeploymentModelSpec.battlecards;
  const mappableBcTgtCombos = useMemo(
    () => findMappableBcTgtCombos(Object.values(battleCardSource ?? {}), quotaComponentId, isAccountFirstMapOn),
    [battleCardSource, quotaComponentId, isAccountFirstMapOn]
  );
  const battlecardItems = useMemo(
    () =>
      mappableBcTgtCombos.map(({ battleCard }) => ({
        key: battleCard.battlecardName,
        value: battleCard.battlecardId.toString()
      })),
    [mappableBcTgtCombos]
  );

  useEffect(() => {
    const nextBattlecardItem = findBestNextKeyValue(
      initialValues[ordinal].battlecard,
      values[ordinal].battlecard,
      battlecardItems
    );
    setFieldValue(`${ordinal}.battlecard`, nextBattlecardItem);
  }, [battlecardItems]);

  const bcId = +values[ordinal].battlecard?.value;
  useEffect(() => {
    const selectedCombo = mappableBcTgtCombos.find(({ battleCard }) => battleCard.battlecardId === bcId);
    setFieldValue(`${ordinal}.geoTgt`, selectedCombo?.geoTgtId ?? null);
  }, [bcId]);

  const filteredQuotaComponentItems = useSearchByKey(quotaComponentItems, quotaComponentSearchText);
  const filteredComparableScenarioItems = useSearchByKey(scenarioItems, scenarioSearchText);
  const fitleredBattlecardItems = useSearchByKey(battlecardItems, battleCardSearchText);

  const { compareModeTitle } = DYNAMIC_COPY[ordinal];
  const hasQuotaComponentSelected = !!values[ordinal].quotaComponent;

  const battleCardField = () => (
    <div className={b('dropdown')}>
      <Field
        {...sharedMenuProps}
        name={`${ordinal}.battlecard`}
        data-testid={`select-battlecard-${ordinal}-menu`}
        placeHolderText={formatMessage('SELECT')}
        minimalDisabledItemStyle={true}
        items={fitleredBattlecardItems}
        disabled={!hasQuotaComponentSelected}
        onSearch={(searchString) => setBattleCardSearchText(searchString)}
        onSearchReset={() => setBattleCardSearchText('')}
        portalRef={portalRef}
      />
    </div>
  );

  return (
    <div ref={portalRef} className={b('selectionContainer')}>
      <div className={b('compareModeTitle')}>{compareModeTitle}</div>
      <div className={b('compareModeSelection')}>
        <div>{formatMessage('SCENARIO_WITH_COLON')}</div>
        <div className={b('dropdown')}>
          <Field
            {...sharedMenuProps}
            name={`${ordinal}.scenario`}
            data-testid={`select-scenario-${ordinal}-menu`}
            placeHolderText={formatMessage('SELECT')}
            items={filteredComparableScenarioItems}
            onSearch={(searchString) => setScenarioSearchText(searchString)}
            onSearchReset={() => setScenarioSearchText('')}
            portalRef={portalRef}
          />
        </div>
        <div>{formatMessage('QUOTA_COMPONENT_WITH_COLON')}</div>
        <div className={b('dropdown')}>
          <Field
            {...sharedMenuProps}
            name={`${ordinal}.quotaComponent`}
            data-testid={`select-quota-component-${ordinal}-menu`}
            placeHolderText={formatMessage('SELECT')}
            items={filteredQuotaComponentItems}
            disabled={!values[ordinal].scenario}
            onSearch={(searchString) => setQuotaComponentSearchText(searchString)}
            onSearchReset={() => setQuotaComponentSearchText('')}
            portalRef={portalRef}
          />
        </div>
        <div>{formatMessage('SCENARIO_PLANNING_BC_PICKER_LABEL')}</div>
        {battleCardField()}
      </div>
    </div>
  );
};

const findBestNextKeyValue = (
  initialValue: SearchableSelectMenuItem | null | undefined,
  currentValue: SearchableSelectMenuItem | null | undefined,
  items: SearchableSelectMenuItem[]
) => {
  const itemMatchingCurrent = currentValue && items.find((item) => areItemsSimilar(currentValue, item));
  if (itemMatchingCurrent) return itemMatchingCurrent;

  const itemMatchingInitial = initialValue && items.find((item) => areItemsSimilar(initialValue, item));
  if (itemMatchingInitial) return itemMatchingInitial;

  const firstItem = items[0];
  return firstItem ?? null;
};

const areItemsSimilar = (a: SearchableSelectMenuItem, b: SearchableSelectMenuItem) =>
  a.value === b.value || (a.key && a.key === b.key);

const sharedMenuProps = {
  theme: 'default',
  component: SearchableSelectMenu,
  showErrors: false,
  allowFlip: false,
  disabledIcon: false,
  showIconInField: false,
  usePortal: true,
  minimalDisabledItemStyle: true,
  initialLoadingComplete: true
};

const useSearchByKey = (items: { key: string }[] | null | undefined, searchText: string) =>
  useMemo(
    () => items?.filter((item) => item.key.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())),
    [items, searchText]
  );

export default CompareModeSelection;
