import { formatMessage } from 'utils/messages/utils';

export const STATIC_FILTER_COLUMNS = [
  {
    label: formatMessage('ACCOUNT_KEY'),
    colId: 'accountKey'
  },
  {
    label: formatMessage('ACCOUNT_NAME'),
    colId: 'accountName'
  },
  {
    label: formatMessage('TERRITORY_ID'),
    colId: 'territoryId'
  },
  {
    label: formatMessage('TERRITORY_NAME'),
    colId: 'territoryName'
  },
  {
    label: formatMessage('TERRITORY_GROUP'),
    colId: 'territoryGroupName'
  }
];
