import React from 'react';

import { Placement } from '@blueprintjs/popover2';
import { Button, Intent } from '@varicent/components';

import MessageTooltipV2 from 'components/MessageTooltip/MessageTooltipV2';

import block from 'utils/bem-css-modules';

import style from './IconButton.module.pcss';

const b = block(style);

export interface IconButtonProps {
  disabled?: boolean;
  type: 'submit' | 'reset' | 'button';
  icon: JSX.Element;
  outlined?: boolean;
  minimal?: boolean;
  intent?: Intent;
  small?: boolean;
  title?: string;
  onClick?: (e) => void;
  tooltipText?: string | JSX.Element;
  className?: string;
  active?: boolean;
  loading?: boolean;
  testId: string;
  tooltipPlacement?: Placement;
}

const IconButton: React.FC<IconButtonProps> = ({
  disabled,
  type,
  title,
  icon,
  outlined,
  minimal = true,
  intent,
  small = false,
  onClick,
  tooltipText,
  className,
  active,
  loading,
  testId,
  tooltipPlacement = 'top'
}: IconButtonProps) => {
  // VDS button requires an icon of type React.FunctionComponent instead of JSX.Element
  // convert JSX icon from consumer into React.FunctionComponent for backward compatibility and to preserve icon props
  const wrappedIcon = () => icon;

  const CustomButton = (
    <Button
      minimal={minimal}
      disabled={disabled}
      type={type}
      icon={wrappedIcon}
      outlined={outlined}
      intent={intent}
      small={small}
      onClick={(e) => onClick?.(e)}
      title={title}
      data-testid={testId}
      className={className}
      active={active}
      loading={loading}
      aria-label={title}
    />
  );

  return (
    <span className={b({ disabled })}>
      {tooltipText ? (
        <MessageTooltipV2
          target={CustomButton}
          content={tooltipText}
          placement={tooltipPlacement}
          data-testid={`${testId}-tooltip`}
        />
      ) : (
        CustomButton
      )}
    </span>
  );
};

export default IconButton;
