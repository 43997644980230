// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KjAd- .bp3-radio {\n      font-weight: normal;\n    }\n  .j7mK5 {\n    margin-top: 16px;\n  }\n  .xXhKP {\n    margin-left: 26px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DeleteRedirectDialog_radioWrapper": "KjAd-",
	"DeleteRedirectDialog_radioButton": "j7mK5",
	"DeleteRedirectDialog_radioOptionLabel": "xXhKP"
};
export default ___CSS_LOADER_EXPORT___;
