// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5-GTR {\n  height: 100%;\n  margin-left: var(--m);\n  border-radius: 12px;\n  background-color: var(--white);\n  padding: var(--m);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n  .CbOSU {\n    text-align: center;\n  }\n\n  .GfjyW {\n    display: flex;\n    justify-content: center;\n    margin: var(--m) 0;\n  }\n\n  ._5LY8P {\n    max-width: 400px;\n  }\n\n  .bOXU- {\n    display: flex;\n    justify-content: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OptimizeTerritories": "_5-GTR",
	"OptimizeTerritories_container": "CbOSU",
	"OptimizeTerritories_imageContainer": "GfjyW",
	"OptimizeTerritories_description": "_5LY8P",
	"OptimizeTerritories_buttonContainer": "bOXU-"
};
export default ___CSS_LOADER_EXPORT___;
