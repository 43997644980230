import React, { Dispatch, SetStateAction } from 'react';

import { PlayOutline } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import Dialog from 'components/Dialog/Dialog';

import { usePublishFileFromSymonS3 } from 'app/components/DataPanel/ConfigurationsPanel/hooks/usePublishFileFromSymonS3';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { GET_SYMON_PIPE_CONFIGURATIONS } from 'app/graphql/queries/getSymonPipeConfigurations';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ReviewConfigurationDialog.module.pcss';

const b = block(style);

interface ReviewConfigurationDialogProps {
  showReviewDialog: boolean;
  setShowReviewDialog: Dispatch<SetStateAction<boolean>>;
  onCloseConfigurationDetail: () => void;
}

export const ReviewConfigurationDialog: React.FC<ReviewConfigurationDialogProps> = ({
  showReviewDialog,
  setShowReviewDialog,
  onCloseConfigurationDetail
}: ReviewConfigurationDialogProps) => {
  const { selectedConfiguration, pollForSymonPipeProcessingStatus } = useData();
  const { selectedPlanningCycle } = useScope();

  const [publishFileFromS3, { loading: publishingFileFromS3 }] = usePublishFileFromSymonS3({
    setShowReviewDialog,
    onCloseConfigurationDetail,
    pollForProcessingStatus: pollForSymonPipeProcessingStatus
  });

  const handleSubmit = () => {
    const { exportId, pipeId } = selectedConfiguration;

    publishFileFromS3({
      variables: {
        input: {
          exportId,
          pipeId,
          planningCycleId: selectedPlanningCycle?.id
        }
      },
      refetchQueries: [
        {
          query: GET_SYMON_PIPE_CONFIGURATIONS,
          variables: { planningCycleId: selectedPlanningCycle.id }
        }
      ]
    });
  };

  return (
    <Dialog
      isOpen={showReviewDialog}
      onClose={() => setShowReviewDialog(false)}
      onSubmit={handleSubmit}
      cancelButtonText={formatMessage('CANCEL')}
      confirmButtonText={formatMessage('RUN')}
      confirmButtonIcon={<PlayOutline />}
      title={formatMessage('RUN_THIS_CONFIGURATION')}
      confirmButtonLoading={publishingFileFromS3}
    >
      <div className={b()} data-testid="review-configuration-dialog">
        <HTMLHeading bold tagLevel="h5" text="Data file" className={b('heading')} />
        <div className={b('dataFile')}>
          <div className={b('textContainer')}>
            <span className={b('subText')}>{formatMessage('FILE_TYPE')}</span>
            <span className={b('description')}>{selectedConfiguration?.fileType}</span>
          </div>
        </div>
        <HTMLHeading bold tagLevel="h5" text="Configuration" className={b('heading')}>
          {formatMessage('CONFIGURATION')}
        </HTMLHeading>
        <div className={b('configuration')}>
          <div className={b('textContainer')}>
            <span className={b('subText')}>{formatMessage('SYMON_AI_SOURCE')}</span>
            <span className={b('description')}>{selectedConfiguration?.pipeName}</span>
          </div>
          <div className={b('textContainer')}>
            <span className={b('subText')}>{formatMessage('SYMON_AI_EXPORT_NODE')}</span>
            <span className={b('description')}>{selectedConfiguration?.exportName}</span>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ReviewConfigurationDialog;
