import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Button } from '@blueprintjs/core';

import block from 'utils/bem-css-modules';

import style from './RoundIconButton.module.pcss';

const b = block(style);

interface RoundIconButtonProps {
  icon?: JSX.Element;
  intent?: 'primary';
  onClick?: () => void;
}

const RoundIconButton: React.FC<RoundIconButtonProps> = ({ icon, intent, onClick }: RoundIconButtonProps) => {
  return (
    <span className={b({ primary: intent === 'primary' })}>
      <Button minimal type="button" intent={intent} icon={icon} outlined={false} onClick={onClick} />
    </span>
  );
};

export default RoundIconButton;
