// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2GMm1 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n\n  ._2GMm1 .bp3-menu-item {\n    display: flex;\n    align-items: center;\n  }\n\n  .yWccE.bp3-popover2-target {\n    display: flex;\n    height: 100%;\n    align-items: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DropdownMenuCellRenderer": "_2GMm1",
	"DropdownMenuCellRenderer_menuCellPopover": "yWccE"
};
export default ___CSS_LOADER_EXPORT___;
