import React, { memo } from 'react';

import { CirclePaint, HeatmapPaint } from 'mapbox-gl';
import { Layer, Source } from 'react-map-gl';

import { CustomerFeatureCollection } from 'app/models';

import { LayerIds, MapStyleTokens, SourceIds } from './MapStyleTokens';
interface HeatPinSourcesProps {
  features: CustomerFeatureCollection;
}

const circlePaint: CirclePaint = {
  'circle-radius': ['interpolate', ['linear'], ['zoom'], 7, 10, 16, 15],
  'circle-color': MapStyleTokens.colors.cluster.fill,
  'circle-stroke-color': MapStyleTokens.colors.cluster.strokeColor,
  'circle-stroke-width': 2,
  'circle-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 1]
};

const heatMapPaint: HeatmapPaint = {
  'heatmap-weight': 1,
  'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
  'heatmap-color': [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
    MapStyleTokens.colors.heatMap.background,
    0.2,
    MapStyleTokens.colors.heatMap.fill_level_1,
    0.4,
    MapStyleTokens.colors.heatMap.fill_level_2,
    0.6,
    MapStyleTokens.colors.heatMap.fill_level_3,
    0.8,
    MapStyleTokens.colors.heatMap.fill_level_4,
    1,
    MapStyleTokens.colors.heatMap.fill_level_5
  ],
  'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 17],
  'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0]
};

const HeatPinAccountSourceLayers: React.FC<HeatPinSourcesProps> = memo(({ features }: HeatPinSourcesProps) => (
  <Source key={SourceIds.account.heat} id={SourceIds.account.heat} type="geojson" data={features}>
    <Layer id={LayerIds.account.heat.heat} type="heatmap" maxzoom={MapStyleTokens.zooms.city} paint={heatMapPaint} />
    <Layer id={LayerIds.account.heat.circle} type="circle" minzoom={MapStyleTokens.zooms.state} paint={circlePaint} />
  </Source>
));

export default HeatPinAccountSourceLayers;
