import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Checkbox } from '@blueprintjs/core';

import { BattlecardType } from 'app/models';

type ReferenceCheckboxBattleCardsCellRendererProps = ICellRendererParams;

const ReferenceCheckboxBattleCardsCellRenderer: React.FC<ReferenceCheckboxBattleCardsCellRendererProps> = ({
  data,
  ...rest
}: ReferenceCheckboxBattleCardsCellRendererProps) => {
  const { setValue } = rest;

  const handleCheckboxChange = () => {
    setValue(!data?.referenceThisSheet);

    // loop through node's children
    Object.values(rest.node.childrenMapped).forEach((child) => {
      // if children node has no more children, set it equal to parent
      if (!child?.allChildrenCount) {
        child?.setDataValue('referenceThisSheet', data?.referenceThisSheet);
      }
    });

    // if clicked node has no children and parent unchecked, set parent as checked
    if (!rest.node.allChildrenCount && !rest.node.parent.data?.referenceThisSheet) {
      rest.node.parent.setDataValue('referenceThisSheet', data?.referenceThisSheet);
    }
  };

  return (
    <Checkbox
      checked={data?.referenceThisSheet}
      onChange={handleCheckboxChange}
      disabled={data?.battlecardType === BattlecardType.Rollup}
      data-testid="reference-checkbox-battlecard-cell-renderer"
    />
  );
};

export default ReferenceCheckboxBattleCardsCellRenderer;
