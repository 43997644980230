import React from 'react';

import { SymbolLayout, SymbolPaint } from 'mapbox-gl';
import { Layer, Source } from 'react-map-gl';

import { PinIcon } from 'app/models';

import { LayerIds, MapStyleTokens, SourceIds } from './MapStyleTokens';

interface MapPinSourcesProps {
  mapPinFeatures: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  beforeLayerId: string;
}

const layout: SymbolLayout = {
  'icon-image': [
    'coalesce',
    ['image', ['concat', MapStyleTokens.icons.pinPrefix, ['get', 'icon']]],
    ['image', ['concat', MapStyleTokens.icons.pinPrefix, PinIcon.PIN]]
  ],
  'icon-size': 0.25,
  'icon-allow-overlap': true,
  'text-allow-overlap': true,
  'text-variable-anchor': ['top'],
  'text-field': ['get', 'name'],
  'text-font': [...MapStyleTokens.fonts.pins],
  'text-size': 10,
  'text-offset': [0, 1.7],
  'text-anchor': 'top'
};

const paint: SymbolPaint = {
  'icon-color': ['get', 'color'],
  'icon-halo-color': 'white',
  'icon-halo-width': 1,
  'text-color': ['get', 'color'],
  'text-halo-color': 'white',
  'text-halo-width': 0.7
};

const CustomPinSetSourceLayers: React.FC<MapPinSourcesProps> = ({
  mapPinFeatures,
  beforeLayerId
}: MapPinSourcesProps) => {
  return (
    <Source key={SourceIds.customPinSet} id={SourceIds.customPinSet} type="geojson" data={mapPinFeatures}>
      <Layer id={LayerIds.customPinSet.symbol} type="symbol" paint={paint} layout={layout} beforeId={beforeLayerId} />
    </Source>
  );
};

export default CustomPinSetSourceLayers;
