import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, Route, RouteProps, useHistory, useLocation } from 'react-router-dom';

import AuthSpinner from 'components/AuthSpinner/AuthSpinner';

import { RootPaths } from 'app/containers/App/Router/routePaths';

import { INVITATION_ID } from 'app/global/variables';

import { getSearchParams } from 'utils/helpers/searchParams';

type UnauthenticatedRouteProps = Pick<RouteProps, 'render' | 'component' | 'path' | 'exact'>;

// Routes that specifically require being UNAUTHENTICATED
// Once logged IN this route will be inaccessible
const UnauthenticatedRoute: React.FC<UnauthenticatedRouteProps> = (props: UnauthenticatedRouteProps) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const history = useHistory();
  const invitationId = getSearchParams(history.location.search, INVITATION_ID);

  if (invitationId) {
    localStorage.setItem(INVITATION_ID, invitationId);
  }

  if (isLoading) return <AuthSpinner />;

  if (isAuthenticated) return <Redirect to={getRouteToRoot(location.state)} />;

  return <Route {...props} />;
};

const getRouteToRoot = (state?: { from?: { pathname?: string } }): string => {
  const pathname = state?.from?.pathname;
  if (pathname && RootPaths.some((rootPath) => pathname.startsWith(rootPath))) {
    return pathname;
  }
  return '/';
};

export default UnauthenticatedRoute;
