// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Pmwp {\n  width: 300px;\n  height: 250px;\n  margin: var(--m) !important;\n  cursor: pointer;\n  position: relative\n}\n._1Pmwp.bp3-card {\n    padding: 0;\n    overflow: hidden;\n    box-shadow:\n      0 1px 3px 0 rgba(0, 0, 0, 0.1),\n      0 1px 2px 0 rgba(0, 0, 0, 0.06);\n  }\n.Dtl99 {\n    text-decoration: none;\n    color: rgb(var(--color-gray-2))\n  }\n.Dtl99:focus, .Dtl99:hover, .Dtl99:visited, .Dtl99:link, .Dtl99:active {\n      text-decoration: none;\n      color: rgb(var(--color-gray-2));\n    }\n.fk42W {\n      pointer-events: none;\n    }\n._8yH0s {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 19;\n    background-color: rgb(var(--color-dark-gray-4), 0.4);\n  }\n.h6zcB {\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n  }\n.cSkhE {\n    max-width: 100%;\n    max-height: 100%;\n  }\n.p0RNH {\n    font-family: var(--font-style);\n    font-size: 12px;\n    font-weight: normal;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: 1.67;\n    letter-spacing: normal;\n    color: rgb(var(--color-gray-2));\n    margin-bottom: 18px;\n    margin-left: 22px;\n    max-width: 200px;\n  }\n.D4ghK {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n  }\n.Y25KK {\n      margin-bottom: 20px;\n    }\n.gT6W4 {\n    display: flex;\n    justify-content: center;\n    align-content: center;\n    flex-direction: column;\n    margin-left: 22px;\n    margin-top: 10px;\n    max-width: 250px;\n    color: rgb(var(--color-dark-gray-3));\n  }\n.IYxdu {\n    margin-top: 18px;\n    cursor: pointer;\n  }\n.kkaoT .bp3-menu-item {\n      display: flex;\n      align-items: center;\n    }\n.EOgY0 {\n    margin-top: 18px;\n    visibility: hidden;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CyclesCard": "_1Pmwp",
	"CyclesCard_link": "Dtl99",
	"CyclesCard_link__disabled": "fk42W",
	"CyclesCard_spinner": "_8yH0s",
	"CyclesCard_thumbnailContainer": "h6zcB",
	"CyclesCard_thumbnail": "cSkhE",
	"CyclesCard_date": "p0RNH",
	"CyclesCard_header": "D4ghK",
	"CyclesCard_header__hideHeader": "Y25KK",
	"CyclesCard_title": "gT6W4",
	"CyclesCard_menu": "IYxdu",
	"CyclesCard_menu__item": "kkaoT",
	"CyclesCard_hide": "EOgY0"
};
export default ___CSS_LOADER_EXPORT___;
