// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VAeHq {\n  display: flex;\n  visibility: hidden;\n}\n\n  ._40NsI {\n    visibility: visible;\n  }\n\n  .ag-cell:hover .VAeHq {\n    visibility: visible;\n  }\n\n  ._4MI85 {\n    color: rgb(var(--color-light-gray-3));\n  }\n\n  .ag-cell:hover ._4MI85 {\n    color: unset;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapIgnoreCellRenderer": "VAeHq",
	"MapIgnoreCellRenderer__alwaysVisible": "_40NsI",
	"MapIgnoreCellRenderer_viewOffIcon": "_4MI85"
};
export default ___CSS_LOADER_EXPORT___;
