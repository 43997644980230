// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vh3MU {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  align-items: center;\n  cursor: pointer;\n  text-align: left;\n}\n\n  .CmWtE {\n    display: flex;\n    align-items: center;\n    width: 100%;\n  }\n\n  ._5MyzO {\n    display: flex;\n    overflow: hidden;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BattleCardReferenceCellRenderer": "vh3MU",
	"BattleCardReferenceCellRenderer_items": "CmWtE",
	"BattleCardReferenceCellRenderer_firstItem": "_5MyzO"
};
export default ___CSS_LOADER_EXPORT___;
