import React from 'react';

import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { Menu } from '@blueprintjs/core';
import { UserAdmin, UserFollow, ResetAlt } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';

import { LoadingCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/LoadingCellRenderer/LoadingCellRenderer';

import { useUser } from 'app/core/userManagement/userProvider';

import { UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UserActionCellRenderer.module.pcss';

const b = block(style);

interface UserActionCellRendererProps extends ICellRendererParams {
  onUserActionCellClick: (data: RowNode, inviteAdmin?: boolean) => void;
  icon?: boolean;
}

export const UserActionCellRenderer: React.FC<UserActionCellRendererProps> = ({
  data,
  onUserActionCellClick
}: UserActionCellRendererProps) => {
  const { userProfile } = useUser();
  // eslint-disable-next-line no-restricted-syntax
  const isUserNone = data?.systemRoleName === UserRoleType.NONE;

  // only need to check for participants
  // for contributor, instead of resend invite we actually switch their role, so no actual invitation is created
  // eslint-disable-next-line no-restricted-syntax
  const isInviteExist = data?.systemRoleName === UserRoleType.NONE && data?.memberInvitesInfo?.length;

  const isInviteDisabled = (inviteRoleName) => {
    const userRole = data?.systemRoleName;
    // eslint-disable-next-line no-restricted-syntax
    if (userRole === UserRoleType.CONTRIBUTOR && inviteRoleName === UserRoleType.CONTRIBUTOR) {
      return true;
    }
    // eslint-disable-next-line no-restricted-syntax
    return userRole === UserRoleType.ADMIN;
  };

  const isResendInvite = (inviteRoleName) => {
    return (
      !isInviteDisabled(inviteRoleName) &&
      data?.memberInvitesInfo?.some((invite) => {
        return invite.memberInvitedAs === inviteRoleName;
      })
    );
  };

  const createInviteMenuContent = () => {
    return (
      <div data-testid="invite-menu-content">
        <Menu>
          <MenuItem
            text={
              isResendInvite(UserRoleType.CONTRIBUTOR)
                ? formatMessage('USER_MANAGEMENT_RESEND_CONTRIBUTOR_INVITE')
                : formatMessage('USER_MANAGEMENT_CONTRIBUTOR_INVITE')
            }
            disabled={isInviteDisabled(UserRoleType.CONTRIBUTOR)}
            onClick={() => onUserActionCellClick(data, false)}
            data-testid="user-action-invite-as-contributor"
          />
          <MenuItem
            text={
              isResendInvite(UserRoleType.ADMIN)
                ? formatMessage('USER_MANAGEMENT_RESEND_ADMIN_INVITE')
                : formatMessage('USER_MANAGEMENT_ADMIN_INVITE')
            }
            disabled={isInviteDisabled(UserRoleType.ADMIN)}
            onClick={() => onUserActionCellClick(data, true)}
            data-testid="user-action-invite-as-admin"
          />
        </Menu>
      </div>
    );
  };

  const createInviteButton = () => {
    const tooltipMessage = isInviteExist ? formatMessage('RESEND_INVITATION') : formatMessage('SEND_INVITATION');
    return (
      <Popover content={createInviteMenuContent()} placement={'bottom'} minimal>
        <IconButton
          className={b('icon')}
          type={'button'}
          icon={isInviteExist ? <UserAdmin /> : <UserFollow />}
          testId={`user-action-${isInviteExist ? 'resend' : 'send'}-invite-button`}
          title={tooltipMessage}
          tooltipText={tooltipMessage}
        />
      </Popover>
    );
  };

  const createRevokeButton = () => {
    // eslint-disable-next-line no-restricted-syntax
    const isAdmin = data?.systemRoleName === UserRoleType.ADMIN;
    const tooltipMessage = isAdmin
      ? formatMessage('USER_MANAGEMENT_REVOKE_ADMIN')
      : formatMessage('USER_MANAGEMENT_REVOKE_CONTRIBUTOR');
    return (
      <IconButton
        className={b('icon')}
        type={'button'}
        icon={<ResetAlt />}
        testId={`user-action-revoke-${isAdmin ? 'admin' : 'contributor'}-button`}
        title={tooltipMessage}
        tooltipText={tooltipMessage}
        onClick={() => onUserActionCellClick(data, false)}
      />
    );
  };

  const createCellContent = () => {
    return userProfile.emailAddress === data.emailAddress ? (
      formatMessage('HYPHEN')
    ) : (
      <>
        {createInviteButton()}
        {!isUserNone && createRevokeButton()}
      </>
    );
  };

  return (
    <>
      {(() => {
        if (data) {
          return (
            <div className={b()} data-testid="action-cell-renderer">
              {createCellContent()}
            </div>
          );
        } else {
          return <LoadingCellRenderer {...data} />;
        }
      })()}
    </>
  );
};

export default UserActionCellRenderer;
