import React, { Dispatch, SetStateAction, useLayoutEffect, useEffect, useRef, MutableRefObject } from 'react';

import BattleCardAddCardButton from 'app/components/BattleCardDiagram/Canvas/BattleCardAddCardButton/BattleCardAddCardButton';
import BattleCard from 'app/components/BattleCardDiagram/Cards/BattleCard/BattleCard';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { BattlecardType, BattleCardDefinition, SelectedPlanningType } from 'app/models';

import block from 'utils/bem-css-modules';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './BattleCardNode.module.pcss';

const b = block(style);

interface BattleCardNodeProps {
  setParentBattleCardId: Dispatch<SetStateAction<string | null>>;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
  cachedPanelCollapseStateByCard: Record<string, Record<string, boolean>>;
  setCachedPanelCollapseStateByCard: Dispatch<SetStateAction<Record<string, Record<string, boolean>>>>;
  setEditingBattleCardId: Dispatch<SetStateAction<string | null>>;
  deploymentModelId: number;
  nodeData: BattleCardDefinition | null;
  hideBackgroundNodes: boolean | null;
  setHideBackgroundNodes: Dispatch<SetStateAction<boolean | null>>;
  canvasBackgroundRef: MutableRefObject<HTMLDivElement>;
}

const BattleCardNode: React.FC<BattleCardNodeProps> = ({
  setParentBattleCardId,
  setShowDialog,
  cachedPanelCollapseStateByCard,
  setCachedPanelCollapseStateByCard,
  setEditingBattleCardId,
  deploymentModelId,
  nodeData,
  hideBackgroundNodes,
  setHideBackgroundNodes,
  canvasBackgroundRef
}: BattleCardNodeProps) => {
  const cardData = nodeData['data'];
  const battlecardId = cardData['battlecardId'];

  const handleClick = () => {
    setEditingBattleCardId(null);
    setParentBattleCardId(battlecardId);
    setShowDialog(true);
  };

  const {
    expandedBattleCardId,
    exitingBattleCardId,
    cardIsExpanded,
    cardBox,
    battleCardLookupMap,
    selectedQuotaComponentId
  } = useBattleCard();

  const { selectedPlanningType } = useScope();

  const battleCardNodeRef = useRef(null);

  // align the root battle card top-center onload

  useEffect(() => {
    if (cardData.isRootCard) battleCardNodeRef?.current?.scrollIntoView({ inline: 'center', block: 'end' });
  }, []);

  useLayoutEffect(() => {
    if (battleCardNodeRef.current) {
      // remember the current height of this node before removing the battlecard from the document flow during animation to expanded state
      if (cardBox && expandedBattleCardId && expandedBattleCardId === battlecardId) {
        battleCardNodeRef.current.style.height = `${cardBox.bottom - cardBox.top}px`;
      }
    }

    if (!expandedBattleCardId && !exitingBattleCardId) {
      battleCardNodeRef.current.style.height = '';
    }
  }, [expandedBattleCardId, exitingBattleCardId, cardBox]);

  const battleCardType = battleCardLookupMap && battleCardLookupMap[battlecardId]?.battlecardType;

  const isActive =
    cardData?.quotaComponents?.length &&
    !!cardData.quotaComponents.find((quotaComponent) => quotaComponent?.quotaComponentId === selectedQuotaComponentId);

  return (
    <div
      className={b({
        expanded: expandedBattleCardId === cardData.battlecardId,
        obscured: !cardIsExpanded && !!hideBackgroundNodes,
        isActive,
        isRoot: cardData.isRootCard
      })}
      ref={battleCardNodeRef}
    >
      <BattleCard
        cardData={cardData}
        cachedPanelCollapseStateByCard={cachedPanelCollapseStateByCard}
        setCachedPanelCollapseStateByCard={setCachedPanelCollapseStateByCard}
        setShowDialog={setShowDialog}
        setEditingBattleCardId={setEditingBattleCardId}
        deploymentModelId={deploymentModelId}
        battleCardNodeRef={battleCardNodeRef}
        setHideBackgroundNodes={setHideBackgroundNodes}
        expandedBattleCardId={expandedBattleCardId}
        canvasBackgroundRef={canvasBackgroundRef}
      />
      {isActive && (battleCardType === BattlecardType.Rollup || battleCardType === BattlecardType.PrimaryTeam) && (
        <CanUser
          perform={UserAction.BATTLE_CARD_CANVAS_MODIFY}
          yes={
            <span
              className={b('button', { hidden: expandedBattleCardId === cardData.battlecardId })}
              data-testid={`add-card-button-${cardData?.battlecardName}`}
            >
              {selectedPlanningType === SelectedPlanningType.TERRITORY_PLANNING && (
                <BattleCardAddCardButton handleClick={handleClick} />
              )}
            </span>
          }
        />
      )}
    </div>
  );
};

export default BattleCardNode;
