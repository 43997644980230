// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hKHgR {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n}\n\n  .WO65B {\n    width: 100%;\n    height: 100%;\n    overflow-y: scroll;\n    justify-content: center;\n    background-color: rgb(var(--color-light-gray-5));\n  }\n\n  .W3acP {\n    flex-shrink: 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CyclesPage": "hKHgR",
	"CyclesPage_container": "WO65B",
	"CyclesPage_search": "W3acP"
};
export default ___CSS_LOADER_EXPORT___;
