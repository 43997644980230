import gql from 'graphql-tag';

export const DELETE_HIERARCHY_BY_ID = gql`
  mutation DeleteHierarchyById($hierarchyIds: [Int]!, $hierarchyType: String!, $planningCycleId: Int!) {
    deleteHierarchyById(
      input: { hierarchyIds: $hierarchyIds, hierarchyType: $hierarchyType, planningCycleId: $planningCycleId }
    ) {
      customProperties
      fileId
      hierarchyId
      key
      name
      parentKey
      path
      planningCycleId
      version
    }
  }
`;
