// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4kDXH {\n    width: 100%;\n  }\n\n    ._4kDXH .bp3-menu-item {\n      align-items: center;\n    }\n\n    ._4kDXH .bp3-menu-item > * {\n      margin-right: 0.5rem;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapActionMenu_menuItemWrapper": "_4kDXH"
};
export default ___CSS_LOADER_EXPORT___;
