import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Dialog } from '@blueprintjs/core';

import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './BalancingPanel.module.pcss';

const b = block(style);

interface BalancingPanelDialogProps {
  onClose: () => void;
  onConfirm: () => void;
}

const BalancingPanelDialog: React.FC<BalancingPanelDialogProps> = ({
  onClose,
  onConfirm
}: BalancingPanelDialogProps): JSX.Element => (
  <Dialog isOpen portalClassName={b('')} onClose={onClose}>
    <div data-testid="balancing-panel-dialog">
      <h4 className={b('dialogTitle')}>{formatMessage('BALANCING_MODAL_DELETE_HEADER')}</h4>
      <p className={b('deleteBody')}>{formatMessage('BALANCING_MODAL_DELETE_BODY')}</p>
      <div className={b('footer')}>
        <div className={b('buttons')}>
          <TextButton text={formatMessage('CANCEL')} type="button" testId="cancel-button" onClick={onClose} />
          <TextButton
            testId="close-button"
            text={formatMessage('REMOVE_TERRITORY')}
            type="submit"
            intent="danger"
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  </Dialog>
);

export default BalancingPanelDialog;
