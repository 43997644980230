// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lxXAJ {\n    position: relative;\n    display: flex;\n    top: -15px;\n    color: rgb(var(--color-lime-2));\n  }\n\n    .iMobB {\n      opacity: 1;\n      transition: all 500ms linear;\n    }\n\n    .If58S {\n      opacity: 0;\n      transition: all 500ms linear 2s;\n    }\n  .TTYjx {\n    height: 20px;\n  }\n  .uHNJS {\n    margin: 10px;\n  }\n  .yCSgd {\n      fill: rgb(var(--color-red-3));\n    }\n  .I-E4p {\n      fill: rgb(var(--color-lime-2));\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextMessageInputField_message": "lxXAJ",
	"TextMessageInputField_message__shown": "iMobB",
	"TextMessageInputField_message__hidden": "If58S",
	"TextMessageInputField_messageContainer": "TTYjx",
	"TextMessageInputField_statusIcon": "uHNJS",
	"TextMessageInputField_statusIcon__error": "yCSgd",
	"TextMessageInputField_statusIcon__success": "I-E4p"
};
export default ___CSS_LOADER_EXPORT___;
