import React from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

type GetAuthHeader = () => Promise<string | null>;

interface AuthHeaderContextValues {
  isLoading: boolean;
  isAuthenticated: boolean;
  getAuthHeader: GetAuthHeader;
}

export const AuthHeaderContext = React.createContext<AuthHeaderContextValues | null>(null);
AuthHeaderContext.displayName = 'AuthHeaderContext';

export const AuthHeaderProvider = AuthHeaderContext.Provider;

export const useAuthHeader = (): AuthHeaderContextValues => useContextSafe(AuthHeaderContext);
