// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable plugin/selector-bem-pattern */\n.xsLxB {\n  height: 100%;\n  width: 100%;\n  border-top: var(--grey-border);\n}\n.xsLxB .bp3-progress-bar {\n    background-color: white;\n  }\n.xsLxB .bp3-button-text {\n    display: flex;\n    align-items: center;\n  }\n.xsLxB .ag-header-cell-label .ag-header-cell-text {\n    font-size: 14px;\n    color: rgb(var(--color-dark-gray-3));\n    font-weight: normal;\n  }\n.xsLxB .ag-theme-alpine .ag-root-wrapper {\n    border-top: none;\n  }\n._9nMwW {\n    margin: 0 0 8px 8px;\n    padding-top: 0 !important;\n    padding-bottom: 0 !important;\n  }\n.Loqv- {\n    display: flex;\n    justify-content: space-between;\n    background-color: rgb(var(--color-light-gray-5));\n    padding: 10px;\n  }\n.pU0bX {\n    display: flex;\n    align-items: center;\n    width: 500px;\n    font-size: 16px;\n  }\n.YPDsk {\n      width: 25%;\n    }\n.cAGOk {\n      margin-left: 10px;\n      padding-left: 10px;\n      border-left: 1px solid;\n    }\n.DbJnL {\n    display: flex;\n  }\n._4MCPl {\n    margin: 3px 2px 0 0;\n  }\n.MSFjJ {\n    cursor: pointer;\n    height: 28px;\n  }\n.Vxnn2 {\n    }\n.Jv25b {\n    height: 100%;\n    width: 100%;\n  }\n.neOt3 {\n      height: 40px;\n      width: 100%;\n      margin: 4px;\n    }\n.dBEuX {\n      color: blue;\n    }\n.KmTW5 {\n    align-items: center;\n    display: flex;\n    margin-right: 10px;\n    color: rgb(var(--color-gray-1));\n  }\n.ufbtf {\n    display: flex;\n    align-items: center;\n    height: 32px;\n    padding: 8px 10px 0 8px;\n  }\n.Rk9DS {\n      color: rgb(var(--color-light-gray-2));\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ApprovalWorkflow": "xsLxB",
	"ApprovalWorkflow_workflowButton": "_9nMwW",
	"ApprovalWorkflow_header": "Loqv-",
	"ApprovalWorkflow_headerStatus": "pU0bX",
	"ApprovalWorkflow_headerStatus_statusBar": "YPDsk",
	"ApprovalWorkflow_headerStatus_numOfDone": "cAGOk",
	"ApprovalWorkflow_headerButtons": "DbJnL",
	"ApprovalWorkflow_headerButtonIcon": "_4MCPl",
	"ApprovalWorkflow_refresh": "MSFjJ",
	"ApprovalWorkflow_refresh__loading": "Vxnn2 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["refresh-loading"] + "",
	"ApprovalWorkflow_grid": "Jv25b",
	"ApprovalWorkflow_grid__loading": "neOt3",
	"ApprovalWorkflow_grid__emailBlue": "dBEuX",
	"ApprovalWorkflow_tableAction": "KmTW5",
	"ApprovalWorkflow_action": "ufbtf",
	"ApprovalWorkflow_actionSeparator__disabled": "Rk9DS"
};
export default ___CSS_LOADER_EXPORT___;
