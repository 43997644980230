import React from 'react';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import Four0OneImage from 'assets/pngs/401.png';
import Four0FourImage from 'assets/pngs/404.png';
import FiveHundredImage from 'assets/pngs/500.png';

import style from './ErrorPage.module.pcss';

const b = block(style);

export const enum ErrorType {
  _401 = '401',
  _404 = '404',
  _500 = '500',
  _503 = '503',
  INTERNAL = 'internal'
}

const getErrorProperties = (errorType: ErrorType) => {
  switch (errorType) {
    case ErrorType._401:
      return {
        image: Four0OneImage,
        title: formatMessage('FOUR_O_ONE'),
        number: '401'
      };
    case ErrorType._404:
      return {
        image: Four0FourImage,
        title: formatMessage('FOUR_O_FOUR'),
        number: '404'
      };
    case ErrorType._500:
      return {
        image: FiveHundredImage,
        title: formatMessage('FIVE_HUNDRED'),
        number: '500'
      };
    case ErrorType._503:
      return {
        image: FiveHundredImage,
        title: formatMessage('MAINTENANCE_MODE_TEXT_1'),
        description: formatMessage('MAINTENANCE_MODE_TEXT_2'),
        number: '503'
      };
    case ErrorType.INTERNAL:
    default:
      return {
        image: FiveHundredImage,
        title: formatMessage('INTERNAL_ERROR'),
        number: ''
      };
  }
};

export interface ErrorPageProps {
  errorType: ErrorType;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ errorType }: ErrorPageProps) => {
  const { image, title, description, number } = getErrorProperties(errorType);

  return (
    <div className={b()} data-testid="error-page">
      <div className={b('errorContainer')}>
        <div className={b('artworkWrapper')}>
          <img className={b('artwork')} src={image} alt={number} />
        </div>
        <div className={b('textErrorCode')} data-testid="error-code">
          {number}
        </div>
        <div className={b('textWrapper')} data-testid="error-text">
          <div className={b('title')}>{title}</div>
          {description && <div className={b('description')}>{description}</div>}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
