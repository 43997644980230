// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UQpo3 {\n    display: flex;\n    width: 100%;\n  }\n  .wrok4 {\n    width: 100%;\n    height: 100%;\n  }\n  .wrok4 .ag-cell {\n      overflow: visible !important;\n    }\n  /* reset padding to zero and let headerClass and headerCellRender handle the styling */\n  .wrok4 .ag-header-cell {\n      padding: 0;\n    }\n  .wrok4 .ag-row-group-leaf-indent {\n      margin-left: 0 !important;\n    }\n  .wrok4 .ag-row-group-leaf-indent > .ag-group-checkbox {\n      padding-right: var(--grid-header-padding);\n    }\n  .wrok4 .ag-row-group > .ag-group-contracted, .wrok4 .ag-row-group > .ag-group-expanded {\n      margin-right: 0;\n    }\n  /* reset cell hover background color, let cellClass handle each cell style */\n  .wrok4 .ag-cell:hover {\n      background-color: transparent;\n    }\n  .wrok4 .ag-theme-alpine .ag-row-selected > .ag-cell {\n      background-color: var(--intent-primary-translucent-hover) !important;\n    }\n  .O-fiu {\n    padding: 0 0 0 var(--grid-header-padding) !important;\n  }\n  .gDcZk {\n    display: inline-grid;\n    justify-content: flex-end;\n    padding-right: var(--grid-header-padding) !important;\n  }\n  .\\--fIr {\n    display: flex;\n    justify-content: flex-end;\n  }\n  .lBu2A:hover {\n    background-color: var(--intent-primary-translucent-hover) !important;\n  }\n  .vUQ5H {\n  }\n  .m62X5 {\n  }\n  .YcErq {\n    display: flex;\n    height: 70vh;\n    margin-bottom: -50px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SellerSheetGridV2_gridWrapper": "UQpo3",
	"SellerSheetGridV2_advancedGridWrapper": "wrok4",
	"SellerSheetGridV2_header": "O-fiu",
	"SellerSheetGridV2_rightAlignedHeader": "gDcZk",
	"SellerSheetGridV2_rightAlignedCell": "--fIr",
	"SellerSheetGridV2_highlightOnHoverCell": "lBu2A",
	"SellerSheetGridV2_gridOverlayContainer": "vUQ5H " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-container"] + "",
	"SellerSheetGridV2_gridOverlayText": "m62X5 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-text"] + "",
	"SellerSheetGridV2_lookupPreviewContents": "YcErq"
};
export default ___CSS_LOADER_EXPORT___;
