// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PHppb {\n  background: black;\n}\n\n  .a2e\\+2 {\n    max-width: 200px;\n    font-weight: bold;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    padding: 0 var(--xs);\n  }\n\n  ._0gBus {\n    padding: 0 var(--xs);\n    color: rgb(var(--color-dark-gray-4));\n  }\n\n  .CYPik {\n      color: rgb(var(--color-green-2));\n    }\n\n  .wf-JZ {\n    display: flex;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n  }\n\n  .wf-JZ .bp3-skeleton {\n      width: 20px;\n      height: 20px;\n    }\n\n  .wf-JZ .bp3-skeleton:nth-child(2) {\n      width: 100px;\n    }\n\n  .mPLhh {\n    min-height: var(--l);\n    min-width: var(--l);\n    max-width: var(--l);\n    max-height: var(--l);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ScenarioMenu": "PHppb",
	"ScenarioMenu_scenarioTitleText": "a2e+2",
	"ScenarioMenu_scenarioNameHeaderIcon": "_0gBus",
	"ScenarioMenu_scenarioNameHeaderIcon__isPublishedScenario": "CYPik",
	"ScenarioMenu_skeletonWrapper": "wf-JZ",
	"ScenarioMenu_scenarioMenuButton": "mPLhh"
};
export default ___CSS_LOADER_EXPORT___;
