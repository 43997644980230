import React from 'react';

import { DocumentImport } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { formatMessage } from 'utils/messages/utils';

interface DataTrayImportButtonProps {
  tooltipText: string;
  onClick: () => void;
  disabled?: boolean;
}

const DataTrayImportButton: React.FC<DataTrayImportButtonProps> = ({
  tooltipText,
  onClick,
  disabled = false
}: DataTrayImportButtonProps) => {
  return (
    <IconButton
      type="button"
      icon={<DocumentImport />}
      disabled={disabled}
      tooltipText={disabled ? formatMessage('NO_EDIT_PERMISSION') : tooltipText}
      onClick={onClick}
      testId={'data-tray-import-button'}
      title={formatMessage('IMPORT')}
    />
  );
};
export default DataTrayImportButton;
