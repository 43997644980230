import gql from 'graphql-tag';

export const UPDATE_TERRITORY_GROUP = gql`
  mutation UpdateTerritoryGroup($hierarchyId: Int, $territoryGroupId: Int!, $territoryGroupName: String!) {
    updateTerritoryGroup(
      hierarchyId: $hierarchyId
      territoryGroupId: $territoryGroupId
      territoryGroupName: $territoryGroupName
    ) {
      territoryGroupId
    }
  }
`;
