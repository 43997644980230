// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J6osu {\n  background-color: transparent !important;\n}\n\n  ._517tT {\n    fill: rgb(var(--color-gold-3)) !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WarningButton": "J6osu",
	"WarningButton_warningIcon": "_517tT"
};
export default ___CSS_LOADER_EXPORT___;
