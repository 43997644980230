// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b3dDS .bp3-dialog {\n    width: 800px;\n    box-sizing: border-box;\n    padding-bottom: 0;\n    box-shadow: var(--elevation-2);\n    border-radius: var(--dialog-border-radius);\n  }\n  .WduRb {\n    width: 550px !important;\n  }\n  .N9Bqx {\n    width: auto !important;\n  }\n  .b3dDS .bp3-heading {\n    font-weight: normal;\n    font-size: 24px !important;\n    color: rgb(var(--color-dark-gray-3));\n  }\n  .b3dDS .bp3-dialog-header {\n    height: var(--battle-card-designer-header-height);\n    color: rgb(var(--color-dark-gray-3));\n  }\n  .b3dDS .bp3-overlay-backdrop {\n    background-color: rgba(var(--color-dark-gray-3), 0.4);\n  }\n  .b3dDS .bp3-overlay.bp3-overlay-scroll-container.bp3-overlay-inline {\n    position: fixed;\n  }\n  .QQZ3Y {\n    font-size: 1.5rem;\n  }\n  .UWZO3 {\n    max-height: calc(90vh - var(--battle-card-designer-header-height));\n    background-color: white;\n    display: flex;\n    flex-direction: column;\n\n    /* For backwards compat: the default overflow is auto, even though there is not max */\n    overflow: auto;\n    padding: 0 var(--dialog-padding);\n    border-radius: inherit;\n  }\n  .woBc5 {\n      overflow: visible;\n    }\n  .D84VG {\n    height: calc(90vh - 60px);\n    border-radius: var(--xs) var(--xs) 0 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Dialog": "b3dDS",
	"Dialog__small": "WduRb",
	"Dialog__auto": "N9Bqx",
	"Dialog_ellipsisHeading": "QQZ3Y",
	"Dialog_dialogBody": "UWZO3",
	"Dialog_dialogBody__showOverflow": "woBc5",
	"Dialog_spinnerContainer": "D84VG"
};
export default ___CSS_LOADER_EXPORT___;
