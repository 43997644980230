import React from 'react';

import { ArrowLeft, ArrowRight } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import DialogFooter, { DialogFooterProps } from 'components/Dialog/DialogFooter/DialogFooter';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MultiStepDialogFooter.module.pcss';

const b = block(style);

export interface MultiStepDialogFooterProps extends DialogFooterProps {
  onNext?: (e?: React.SyntheticEvent<HTMLElement>) => void;
  onBack?: () => void;
  nextButtonText?: string;
  disableNext?: boolean;
  showBack?: boolean;
  isFinalStep: boolean;
}

const MultiStepDialogFooter: React.FC<MultiStepDialogFooterProps> = ({
  onNext,
  onBack,
  nextButtonText = formatMessage('NEXT'),
  disableNext = false,
  showBack = true,
  isFinalStep,
  ...rest
}: MultiStepDialogFooterProps) => {
  const extraActions = (
    <>
      {showBack && (
        <div className={b('button')}>
          <TextButton
            text={formatMessage('BACK')}
            type="button"
            onClick={onBack}
            testId={'dialog-back-button'}
            icon={<ArrowLeft />}
          />
        </div>
      )}
      {!isFinalStep && (
        <div className={b('button')}>
          <TextButton
            testId={'dialog-next-button'}
            text={nextButtonText}
            type="submit"
            intent="primary"
            onClick={onNext}
            disabled={disableNext}
            rightIcon={<ArrowRight />}
          />
        </div>
      )}
    </>
  );

  return <DialogFooter {...rest} showConfirm={isFinalStep} extraActions={extraActions} />;
};

export default MultiStepDialogFooter;
