import gql from 'graphql-tag';

export const UPDATE_HIERARCHY_PARENT = gql`
  mutation updateHierarchyParent($hierarchyIds: [Int]!, $newParentHierarchyId: Int!, $planningCycleId: Int!) {
    updateHierarchyParent(
      input: {
        hierarchyIds: $hierarchyIds
        newParentHierarchyId: $newParentHierarchyId
        planningCycleId: $planningCycleId
      }
    ) {
      newParentHierarchy {
        children
        cloneId
        customProperties
        fileId
        hierarchyId
        hierarchyType
        key
        name
        parentKey
        path
        planningCycleId
        version
      }
    }
  }
`;
