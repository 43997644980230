import { Dispatch, SetStateAction, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { useSearchParam } from './useSearchParam';

const useQueryParamState = <ParamType extends string | null = string | null>(
  key: string
): [ParamType, Dispatch<SetStateAction<ParamType>>] => {
  const state = useSearchParam(key) as ParamType;
  const history = useHistory();

  const setState = useCallback(
    (valueOrCallback: ParamType | ((p: ParamType) => ParamType)) => {
      const newSearchParams = new URLSearchParams(history.location.search);
      const oldValue = (newSearchParams.get(key) ?? null) as ParamType;
      const newValue = typeof valueOrCallback === 'function' ? valueOrCallback(oldValue) : valueOrCallback;

      if (newValue === null || newValue === undefined) {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, newValue);
      }
      history.push({ ...history.location, search: newSearchParams.toString() });
    },
    [key]
  );

  return [state, setState];
};

export default useQueryParamState;
