// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btvhl {\n    width: 500px !important;\n    max-width: 100% !important;\n  }\n  .tbY22 {\n    cursor: not-allowed;\n  }\n  ._8X\\+9n {\n    padding: 0;\n    text-align: right;\n    width: 100%;\n    display: flex !important;\n    justify-content: flex-end;\n  }\n  .F1YMR {\n    padding: 0;\n    text-align: left;\n    width: 100%;\n    display: flex !important;\n    justify-content: flex-start;\n  }\n  ._1wDPf {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EditableFieldCellRenderer__emptyCell": "btvhl",
	"EditableFieldCellRenderer__unauthorizedCursor": "tbY22",
	"EditableFieldCellRenderer__alignRight": "_8X+9n",
	"EditableFieldCellRenderer__alignLeft": "F1YMR",
	"EditableFieldCellRenderer__cellWrapper": "_1wDPf"
};
export default ___CSS_LOADER_EXPORT___;
