import React, { useEffect, useMemo, useRef, useState } from 'react';

import { RowSelectedEvent } from '@ag-grid-community/core';

import SearchableTreeDialog from 'components/SearchableTreeDialog/SearchableTreeDialog';

import { useBattleCard } from 'app/contexts/battleCardProvider';

import { HierarchyItem, HierarchyItemDeltaMap, SelectedMap, SelectedState } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import AllTerritories from './AllTerritories/AllTerritories';
import TerritorySearch from './TerritorySearch/TerritorySearch';
import style from './TerritorySearchDialog.module.pcss';

const b = block(style);

const onTerritorySelectionChanged = (node, isSelected, initialSelection, selectedMap, delta) => {
  const nodeData = node.data || {};
  const { territoryId, territoryName, ruleId } = nodeData;
  const nodeIsPreselected = initialSelection.findIndex((selection) => selection.ruleId === ruleId) >= 0;

  selectedMap[ruleId] = isSelected;
  if ((isSelected && !nodeIsPreselected) || !isSelected) {
    delta[territoryId] = {
      hierarchyItem: { territoryId, name: territoryName, ruleId, hierarchyId: territoryId },
      isSelected,
      parent: node?.parent?.data?.territoryGroupName
    };
  }
};

const onTerritoryRowSelected = (
  rowSelectedEvent: RowSelectedEvent,
  initialSelection: HierarchyItem[],
  selectedMap: SelectedMap,
  delta: HierarchyItemDeltaMap
): void => {
  const targetNode = rowSelectedEvent?.node;

  onTerritorySelectionChanged(targetNode, targetNode.isSelected(), initialSelection, selectedMap, delta);

  if (targetNode.isSelected()) {
    targetNode['selectedState'] = SelectedState.SELECTED;
  }
};

const useTerritorySelectDelta = (initialSelection: HierarchyItem[]) => {
  const output = useMemo(() => ({ delta: {}, selectedMap: {} }), [initialSelection]);
  useEffect(() => {
    initialSelection.forEach((selection) => {
      output.selectedMap[selection.hierarchyId] = true;
    });
  }, [initialSelection]);
  return output;
};

interface TerritorySearchDialogProps {
  selectedNodes: HierarchyItem[];
  onSelectionChange: (params) => void;
  isOpen: boolean;
  setShowTreeSelectDialog: (params: boolean) => void;
}

const TerritorySearchDialog: React.FC<TerritorySearchDialogProps> = ({
  selectedNodes,
  onSelectionChange,
  isOpen,
  setShowTreeSelectDialog
}: TerritorySearchDialogProps) => {
  const [searchString, setSearchString] = useState<string>('');

  const selectedNodesRef = useRef<HierarchyItem[]>();
  selectedNodesRef.current = [];

  const { delta, selectedMap } = useTerritorySelectDelta(selectedNodes);

  const onSelect = (rowSelectedEvent: RowSelectedEvent) => {
    onTerritoryRowSelected(rowSelectedEvent, selectedNodes, selectedMap, delta);
  };

  const { battleCardLookupMap, selectedBattleCardId, selectedQuotaComponentId } = useBattleCard();

  const onClose = () => {
    setSearchString('');
    setShowTreeSelectDialog(false);
  };

  const onSubmit = () => {
    onSelectionChange(delta);
    setSearchString('');
    setShowTreeSelectDialog(false);
  };

  return (
    <SearchableTreeDialog
      className={b('')}
      title={formatMessage('ADD_TERRITORIES')}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      searchString={searchString}
      setSearchString={setSearchString}
      defaultComponent={
        <AllTerritories
          selectedNodes={selectedNodes}
          onSelect={onSelect}
          battlecardId={battleCardLookupMap[selectedBattleCardId]?.battlecardParentId ?? +selectedBattleCardId}
          quotaComponentId={selectedQuotaComponentId}
        />
      }
      searchComponent={
        <TerritorySearch
          searchString={searchString}
          selectedNodes={selectedNodes}
          onSelect={onSelect}
          battlecardId={battleCardLookupMap[selectedBattleCardId]?.battlecardParentId ?? +selectedBattleCardId}
          quotaComponentId={selectedQuotaComponentId}
        />
      }
    />
  );
};

export default TerritorySearchDialog;
