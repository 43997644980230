import React, { useEffect, useRef, useState } from 'react';

import { RowNode } from '@ag-grid-community/core';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import get from 'lodash.get';

import Dialog from 'components/Dialog/Dialog';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { ReassignTerritories, ReassignTerritoriesVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { REASSIGN_TERRITORIES } from 'app/graphql/mutations/reassignTerritories';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { getLeafNodes } from 'utils/helpers/index';
import { formatMessage } from 'utils/messages/utils';

import style from './MoveTerritoryDialog.module.pcss';
import validationsSchema from './validationSchema';

const b = block(style);

interface MoveTerritoryDialogProps {
  battleCardId: string;
  onClose: () => void;
  selectedNodes: Array<unknown>;
  setSelectedNodes: (nodes: RowNode) => void;
  moveTerritoriesTGTypes: Array<number>;
}

const MoveTerritoryDialog: React.FC<MoveTerritoryDialogProps> = ({
  battleCardId,
  onClose,
  selectedNodes,
  setSelectedNodes,
  moveTerritoriesTGTypes
}) => {
  const { setRefreshGrid } = useGrid();
  const { getTDR, tdrLoadingLookupMap, tdrTreeLookupMap } = useTerritoryDefineAndRefine();
  const { selectedQuotaComponentId } = useBattleCard();
  const showToast = useShowToast();

  const portalRef = useRef(null);

  const [territoryGroupOptions, setTerritoryGroupOptions] = useState<Array<Record<string, string>>>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [selectedTerritoryGroup, setSelectedTerritoryGroup] = useState<string>();

  const [reassignTerritories, { loading, error: reassignTerritoriesError }] = useMutation<
    ReassignTerritories,
    ReassignTerritoriesVariables
  >(REASSIGN_TERRITORIES, {
    onCompleted() {
      const message =
        selectedNodes.length > 1
          ? formatMessage('MOVE_TERRITORY_SUCCESS_MULTIPLE', { territoryGroup: selectedTerritoryGroup })
          : formatMessage('MOVE_TERRITORY_SUCCESS_NAMED', {
              territoryId: get(selectedNodes[0], 'data.territoryId'),
              territoryGroup: selectedTerritoryGroup
            });
      showToast(message, 'success');
      setRefreshGrid(true);
      setSelectedNodes(null);
      setShowError(false);
      onClose();
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      setShowError(true);
    }
  });

  const getTerritoryGroupOptions = async () => {
    const tdrTree = tdrTreeLookupMap && tdrTreeLookupMap[battleCardId];
    const tdrLoading = tdrLoadingLookupMap && tdrLoadingLookupMap[battleCardId];

    if (tdrTree?.length) {
      const leafNodes = [];
      // We can assume its the only array elem if it got here
      const tgType = moveTerritoriesTGTypes[0];
      tdrTree.forEach((treeNode) => {
        if (tgType === treeNode.hierarchyTopId) {
          getLeafNodes(treeNode, leafNodes);
        }
      });

      setTerritoryGroupOptions(leafNodes);
    } else if (!tdrLoading) {
      // fetch territory group data if it does not yet exist
      await getTDR(battleCardId, selectedQuotaComponentId);
    }
  };

  useEffect(() => {
    if (moveTerritoriesTGTypes.length) {
      getTerritoryGroupOptions();
    }
  }, [tdrTreeLookupMap, battleCardId, selectedQuotaComponentId, moveTerritoriesTGTypes]);

  const submitForm = (values) => {
    setSelectedTerritoryGroup(values.territoryGroup.key);
    const territoryRuleIdList = selectedNodes.map((node) => get(node, 'data.ruleId'));
    reassignTerritories({
      variables: {
        input: {
          territoryGroupId: values.territoryGroup.value,
          territoryRuleIdList
        }
      }
    });
  };

  return (
    <Formik
      initialValues={{
        territoryGroup: { key: '', value: '' }
      }}
      validationSchema={validationsSchema}
      onSubmit={submitForm}
    >
      {({ handleSubmit }) => (
        <Dialog
          isOpen
          title={formatMessage('MOVE_TERRITORY')}
          disableConfirm={loading}
          cancelButtonText={formatMessage('CANCEL')}
          confirmButtonText={formatMessage('MOVE')}
          confirmButtonLoading={loading}
          data-testid="move-territory-dialog"
          onClose={onClose}
          onSubmit={handleSubmit}
          size="small"
        >
          <Form>
            <div className={b('content')} ref={portalRef}>
              {showError && (
                <div className={b('errorMessage')} data-testid="dialog-error-message">
                  {reassignTerritoriesError?.message || formatMessage('MOVE_TERRITORY_ERROR')}
                </div>
              )}
              <p className={b('description')}>{formatMessage('MOVE_TERRITORY_DESCRIPTION')}</p>
              <Field
                component={SearchableSelectMenu}
                name="territoryGroup"
                placeHolderText={formatMessage('SELECT')}
                shouldDisableSearch
                initialLoadingComplete={!!territoryGroupOptions}
                disabled={loading}
                showErrors
                items={
                  territoryGroupOptions?.map((territoryGroup) => {
                    return {
                      key: territoryGroup.name,
                      value: territoryGroup.territoryGroupId
                    };
                  }) ?? []
                }
                theme="default"
                usePortal
                portalRef={portalRef}
              />
            </div>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default MoveTerritoryDialog;
