import gql from 'graphql-tag';

export const SEARCH_MEMBER = gql`
  query SearchMember($tenantId: Int!, $searchString: String!) {
    searchMember(input: { tenantId: $tenantId, searchString: $searchString }) {
      totalCount
      memberList {
        customProperties
        deleted
        emailAddress
        employeeId
        firstName
        jobTitle
        memberCreatedAt
        lastName
        memberId
        memberUpdatedAt
        systemRoleName
        tenantId
      }
    }
  }
`;
