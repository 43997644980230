import { useEffect } from 'react';

import { originOfOuterFrame, isWithinIframe } from 'app/containers/App/EmbeddedAuth//EmbedderHelpers';
import { FromEmbedderMessage } from 'app/containers/App/EmbeddedAuth/EmbedderTypes';

export const useOnEmbedderMessage = (onMessage: (message: FromEmbedderMessage) => void): void => {
  useEffect(() => {
    const handleMessage = (event) => {
      if (!isWithinIframe || event.origin !== originOfOuterFrame) return;
      console.debug(`Received message from Embedder`, originOfOuterFrame, event.data);
      onMessage(event.data as FromEmbedderMessage);
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [onMessage]);
};
