import React from 'react';

import { FormGroup } from '@blueprintjs/core';
import { Calendar } from '@carbon/icons-react';
import dayjs from 'dayjs';
import { FieldProps, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';

import block from 'utils/bem-css-modules';
import { getLocaleDateStringFromLocalISOStr } from 'utils/helpers/dateHelpers';

import style from './FormDatePicker.module.pcss';

const b = block(style);
interface FormDatePickerProps extends FieldProps {
  className?: string;
  dateFormat?: string;
  showMonthYearPicker?: boolean;
  readonly?: boolean;
  placeholder?: string;
  showCalendarIcon?: boolean;
  rightCalendarIcon?: boolean;
  showErrors?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  minDate?: Date;
  maxDate?: Date;
  excludeDates?: Date[];
  label?: JSX.Element;
}

/* Separation of concerns between Formik and Blueprint:
// Formik controls input value, form state and validations
// Blueprint controls styling, and its change handlers set Formik state via form.setFieldValue */
const FormDatePicker: React.FC<FormDatePickerProps> = ({
  field: { value, name },
  form: { setFieldValue, setFieldTouched }, // touched, errors, also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  className,
  dateFormat,
  showMonthYearPicker = false,
  readonly = false,
  disabled = false,
  placeholder = null,
  showCalendarIcon = false,
  showErrors = true,
  fullWidth = false,
  minDate,
  maxDate,
  excludeDates,
  label
}: FormDatePickerProps) => {
  const onChange = (value) => {
    if (!isDateValid(value)) {
      setFieldValue(name, '');
    }
    setFieldValue(name, value);
  };

  const getSelectedDate = (date) => {
    if (!date) {
      return '';
    }
    if (typeof date === 'string') {
      return new Date(getLocaleDateStringFromLocalISOStr(dayjs(date).format('YYYY-MM-DD')));
    }
    return new Date(value);
  };

  const isDateValid = (value: number | Date | string) => {
    if (typeof value === 'number') {
      return false;
    }

    const date = new Date(value);
    return !isNaN(date.valueOf());
  };

  return (
    <div className={b()} onBlur={() => setFieldTouched(name)} data-testid="form-group-date-picker">
      <FormGroup label={label} labelFor={name} className={b()}>
        {/* Label is to make the icon click triggering the calendar */}
        <label className={b('label', { fullWidth })}>
          <DatePicker
            className={`${className} ${b('default', {
              disabled,
              withLabel: !!label,
              withIcon: showCalendarIcon
            })}`}
            dateFormat={dateFormat}
            selected={isDateValid(value) && getSelectedDate(value)}
            onChange={(value) => {
              onChange(value);
            }}
            showMonthYearPicker={showMonthYearPicker}
            readOnly={readonly}
            disabled={disabled}
            placeholderText={placeholder}
            minDate={minDate}
            maxDate={maxDate}
            excludeDates={excludeDates}
          />
          {showCalendarIcon && (
            <span className={b('calendarIcon')}>
              <Calendar data-testid="calendar-icon" />
            </span>
          )}
        </label>
        {showErrors && (
          <div className={b('validationMessage')}>
            <ErrorMessage name={name} component="div" className={b('validationMessage')} data-testid="error-message" />
          </div>
        )}
      </FormGroup>
    </div>
  );
};

export default FormDatePicker;
