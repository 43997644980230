// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FrTVH {\n  display: flex;\n  flex-direction: column;\n  padding: 16px 0;\n  min-height: 50vh;\n  overflow: auto;\n}\n\n  ._8W4nC {\n    display: flex;\n  }\n\n  .Kwgl1 {\n    font-weight: bold;\n  }\n\n  .lxW\\+G {\n    margin-bottom: 4px !important;\n  }\n\n  .keD61 {\n    margin-top: 20px;\n    margin-bottom: 20px;\n    width: 100%;\n  }\n\n  .WRBOg {\n    font-weight: normal;\n  }\n\n  .NcH97 {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 16px;\n  }\n\n  .Rcogo .bp3-input {\n      height: var(--select-menu-height);\n    }\n\n  .Rcogo .bp3-button {\n      height: var(--select-menu-height);\n    }\n\n  .WHNKH {\n  }\n\n  ._9GWvk {\n    height: var(--select-menu-height);\n  }\n\n  .FrTVH .bp3-divider {\n    margin: 24px 0;\n  }\n\n  .FrTVH .bp3-menu-item {\n    max-width: var(--select-menu-width);\n  }\n\n  .FrTVH .bp3-button {\n    max-width: var(--select-menu-width);\n  }\n\n  .raiww {\n    display: flex;\n    grid-gap: 16px;\n    margin-bottom: 20px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SymonDataFileSelectStep": "FrTVH",
	"SymonDataFileSelectStep_symonSelectSectionWrapper": "_8W4nC",
	"SymonDataFileSelectStep_sectionTitle": "Kwgl1",
	"SymonDataFileSelectStep_symonInputTextLabelWrapper": "lxW+G",
	"SymonDataFileSelectStep_symonSelectOptionsWrapper": "keD61",
	"SymonDataFileSelectStep_radioLabel": "WRBOg",
	"SymonDataFileSelectStep_selectMenuFieldsContainer": "NcH97",
	"SymonDataFileSelectStep_formInput": "Rcogo",
	"SymonDataFileSelectStep_label": "WHNKH " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label-text"] + "",
	"SymonDataFileSelectStep_selectMenuContainer": "_9GWvk",
	"SymonDataFileSelectStep_radioContainer": "raiww"
};
export default ___CSS_LOADER_EXPORT___;
