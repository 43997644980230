import { GeoBounds } from 'app/models';

interface Position {
  lat: number;
  lon: number;
}
interface PositionEntry<T> extends Position {
  value: T;
}

export class SpatialIndex<T> {
  private readonly buckets = new Map<string, Array<PositionEntry<T>>>();

  constructor(private readonly bucketWidth = 1) {}

  private asKeyPart(value: number): number {
    return Math.floor(value / this.bucketWidth);
  }

  private positionAsKey(position: Position): string {
    return `${this.asKeyPart(position.lon)}::${this.asKeyPart(position.lat)}`;
  }

  public forEachInBounds(bounds: GeoBounds, callbackFn: (entry: PositionEntry<T>) => void): void {
    for (let lon = bounds.minLon; lon <= bounds.maxLon + this.bucketWidth; lon += this.bucketWidth) {
      for (let lat = bounds.minLat; lat <= bounds.maxLat + this.bucketWidth; lat += this.bucketWidth) {
        const key = this.positionAsKey({ lat, lon });
        const entriesInBucket = this.buckets.get(key);
        entriesInBucket?.forEach((entry) => {
          if (
            entry.lon >= bounds.minLon &&
            entry.lon <= bounds.maxLon &&
            entry.lat >= bounds.minLat &&
            entry.lat <= bounds.maxLat
          )
            callbackFn(entry);
        });
      }
    }
  }

  public add(entry: PositionEntry<T>): void {
    const key = this.positionAsKey(entry);
    const bucket = this.buckets.get(key);
    if (bucket) {
      bucket.push(entry);
    } else {
      this.buckets.set(key, [entry]);
    }
  }

  public static widthFromCount(count: number): number {
    if (count < SpatialIndex.SMALL_INDEX_THRESHOLD) {
      return SpatialIndex.MAXIMUM_BUCKET_WIDTH;
    }
    return SpatialIndex.MINIMUM_BUCKET_WIDTH;
  }

  private static SMALL_INDEX_THRESHOLD = 1_000;
  private static MAXIMUM_BUCKET_WIDTH = 180;
  private static MINIMUM_BUCKET_WIDTH = 5;
}
