// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Ijjc {\n    width: 240px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    border-radius: 4px;\n    background-color: var(--white);\n    padding: 8px;\n    text-align: left;\n  }\n\n    .V7ufS {\n      width: 100%;\n    }\n  .akwv9 {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n  .r5fY4 {\n    padding-top: 8px;\n    padding-bottom: 8px;\n    width: 100%;\n  }\n  .r5fY4 .bp3-input {\n      height: 32px;\n    }\n  ._1pIIx {\n    font-weight: 400;\n    font-size: 12px;\n  }\n  .rCsYp {\n    display: flex;\n    flex-direction: row-reverse;\n    padding-top: 8px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GridFilter_filterContainer": "_1Ijjc",
	"GridFilter_filterContainer__fullWidth": "V7ufS",
	"GridFilter_filterInputs": "akwv9",
	"GridFilter_filterInputBox": "r5fY4",
	"GridFilter_textFilterLabel": "_1pIIx",
	"GridFilter_filterResetBtn": "rCsYp"
};
export default ___CSS_LOADER_EXPORT___;
