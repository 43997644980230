import { GetCatalogItem } from 'app/models';

export interface CountrySourceCatalogEntry {
  country: string;
  sourceKey: string;
  sourceId: string;
  sourceUrl: string;
  depth: number;
  polygonCount: number;
}
interface CountryWithDepth {
  country: string;
  depth: number;
}

const getCountriesByDepthComparison = <T extends CountryWithDepth>(
  direction: 'deepest' | 'shallowest',
  items: ReadonlyArray<T>
): Map<string, T> => {
  const map = new Map<string, T>();
  for (const item of items) {
    const prevDepth = map.get(item.country)?.depth;
    if (
      prevDepth == null ||
      (direction === 'deepest' && item.depth > prevDepth) ||
      (direction === 'shallowest' && item.depth < prevDepth)
    ) {
      map.set(item.country, item);
    }
  }
  return map;
};

export const getDeepestItemsByCountry = <T extends CountryWithDepth>(items: ReadonlyArray<T>): Map<string, T> =>
  getCountriesByDepthComparison('deepest', items);

export const getShallowestItemsByCountry = <T extends CountryWithDepth>(items: ReadonlyArray<T>): Map<string, T> =>
  getCountriesByDepthComparison('shallowest', items);

export const catalogItemComparator = (a: GetCatalogItem, b: GetCatalogItem): number =>
  specialCountryCodeComparator(a.country, b.country) || a.countryName.localeCompare(b.countryName);

const specialCountryCodeComparator = (countryA: string, countryB: string) => {
  if (countryA === countryB) return 0;

  if (countryA === 'US') return -1;
  if (countryB === 'US') return 1;

  if (countryA === 'CA') return -1;
  if (countryB === 'CA') return 1;

  return 0;
};
