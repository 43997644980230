import React, { useEffect, useState } from 'react';

import Tab from 'components/Tab/Tab';
import Tabs from 'components/Tabs/Tabs';

import TerritoryOptimizationMap from 'app/components/TerritoryOptimization/TerritoryOptimizationMap/TerritoryOptimizationMap';

import { useMapVariant } from 'app/contexts/mapVariantProvider';

import { TerritoryOptimizationPreviewTab } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryOptimizationPreview.module.pcss';

const b = block(style);

const TerritoryOptimizationPreview: React.FC = () => {
  const [activeTabPanel, setActiveTabPanel] = useState(TerritoryOptimizationPreviewTab.TERRITORY_MAP);
  const { setIsPreviewMapOpen } = useMapVariant();

  useEffect(() => {
    if (activeTabPanel === TerritoryOptimizationPreviewTab.TERRITORY_MAP) {
      setIsPreviewMapOpen(true);
    }
  }, [activeTabPanel]);

  return (
    <div className={b()} data-testid="territory-optimization-preview">
      <Tabs
        id="territory-optimization-tabs"
        className={b('tabs')}
        onChange={(tabId: TerritoryOptimizationPreviewTab) => setActiveTabPanel(tabId)}
      >
        <Tab
          id={TerritoryOptimizationPreviewTab.TERRITORY_MAP}
          className={b('tab')}
          title={formatMessage('TERRITORY_MAP')}
          panel={<TerritoryOptimizationMap />}
        />
      </Tabs>
    </div>
  );
};

export default TerritoryOptimizationPreview;
