import { useEffect } from 'react';

import { Events } from '@symon-ai/wisepipe-imports';

import { useConsumeEmbeddedAuthParams } from 'app/contexts/EmbeddedAuthProvider';

const useForwardRedirectParamsToSymon = (isInitialLoading: boolean): void => {
  const consumeAuthParams = useConsumeEmbeddedAuthParams();
  useEffect(() => {
    if (isInitialLoading) return;

    const params = consumeAuthParams();

    if (params) {
      console.debug('Found oauth data for symon, forwarding', params.toString());
      Events.fireOnAfterOAuthRedirect({
        search: `?${params}`
      } as Location);
    }
  }, [isInitialLoading]);
};

export default useForwardRedirectParamsToSymon;
