// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".i1SO2 {\n    display: flex;\n  }\n  .oTcHY .bp3-button {\n      border-radius: 0;\n      padding: 10px 16px;\n    }\n  ._3JSrP .bp3-button {\n        font-weight: bold;\n      }\n  ._3JSrP .bp3-button-text {\n        color: rgb(var(--color-dark-gray-3));\n      }\n  ._3JSrP .bp3-button.bp3-minimal {\n        box-shadow: inset 0 -2px rgb(var(--color-cobalt-3)) !important;\n      }\n  ._3Xn4M .bp3-button.bp3-minimal {\n        box-shadow: inset 0 -1px rgb(var(--color-light-gray-2)) !important;\n      }\n  .t6KqV {\n    height: 8px;\n    border-radius: 50%;\n    display: inline-block;\n    background-color: rgb(var(--color-gold-3));\n    margin-left: 8px;\n    width: 8px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TabButtonList_tabButtonList": "i1SO2",
	"TabButtonList_tabButtonWrapper": "oTcHY",
	"TabButtonList_tabButtonWrapper__selected": "_3JSrP",
	"TabButtonList_tabButtonWrapper__notSelected": "_3Xn4M",
	"TabButtonList_unviewedIndicator": "t6KqV"
};
export default ___CSS_LOADER_EXPORT___;
