import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ColDef, Column, GridApi, SelectionChangedEvent, SortChangedEvent } from '@ag-grid-community/core';
import { AgGridReactProps } from '@ag-grid-community/react';
import isequal from 'lodash.isequal';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import TextRenderer from 'app/components/AdvancedGrid/CellRenderers/TextRenderer/TextRenderer';
import { GridOverlayError } from 'app/components/DataTray/GridOverlayError/GridOverlayError';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { ACCOUNT_RULE_BLOCK_SIZE } from 'app/global/variables';

import { GetAccountRuleGridInput, SortModel, BCInfoLevelEnum } from 'app/graphql/generated/graphqlApolloTypes';
import { useGetAccountRuleGridLazy } from 'app/graphql/queries/getAccountRuleGrid';

import useCanUser from 'app/hooks/useCanUser';

import {
  ExpandedTerritoryGroupDefineAndRefinePillData,
  FilterInput,
  NamedTgt,
  AccountRuleRow,
  FilterChangeInput,
  TabIds
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import { formatAccountList } from './accountListUtils';
import style from './AccountRuleGrid.module.pcss';
import buildAccountRuleGridColumnDef from './AccountRuleGridColumnDef';
import AccountRuleHeader from './AccountRuleHeader';
import { formatFilterForRequest } from './AccountRuleHelpers';

const b = block(style);

const AccountRuleGrid: React.FC = () => {
  const territoryGroupTypes = useTgtsFromSelectedBc();
  const gridApi = useRef<GridApi>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { selectedQuotaComponentId, selectedBattleCardId } = useBattleCard();
  const { updateStaleTabsLookupMap } = useDataTray();

  const [selectedTgtId, setSelectedTgtId] = useState<number>(territoryGroupTypes?.[0]?.id ?? null);
  const [selectedAccountRules, setSelectedAccountRules] = useState<AccountRuleRow[]>([]);

  const draftAccountFilters = useRef<FilterInput>({});
  const [savedFilterModel, setSavedFilterModel] = useState<FilterInput>({});

  const [sortModel, setSortModel] = useState<SortModel>({ sortModel: [] });

  const [dynamicColumnsList, setDynamicColumnsList] = useState<ColDef[]>([]);

  const isViewingUnassigned = selectedTgtId === null;

  const canReassignAccount = useCanUser(UserAction.ACCOUNT_RULE_GRID_REASSIGN);

  useEffect(() => {
    setSelectedTgtId((currentSelection) => currentSelection ?? territoryGroupTypes?.[0]?.id ?? null);
  }, [territoryGroupTypes]);

  const accountRuleGridInput = useMemo(
    (): GetAccountRuleGridInput => ({
      battlecardId: +selectedBattleCardId,
      territoryGroupTypeId: selectedTgtId,
      quotaComponentId: selectedQuotaComponentId,
      startRow: 1,
      endRow: ACCOUNT_RULE_BLOCK_SIZE,
      filters: formatFilterForRequest(savedFilterModel),
      sorting: sortModel
    }),
    [selectedBattleCardId, selectedTgtId, selectedQuotaComponentId, savedFilterModel, sortModel]
  );
  const accountRuleGridInputRef = useAsLiveRef(accountRuleGridInput);

  const [getAccountRuleGrid, { data, fetchMore, error }] = useGetAccountRuleGridLazy({
    variables: {
      input: accountRuleGridInput
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    setDynamicColumnsList((prevState) => {
      // check if the dynamic columns were not set yet to prevent unnecessary rerenders
      if (prevState.length === 0)
        return (
          data?.getAccountRuleGrid.accountRuleHeaders.map(
            (column): ColDef => ({
              headerName: column.name,
              colId: column.colId,
              valueGetter: (value) => value.data.dynamic[column.colId],
              flex: 1,
              minWidth: 100,
              cellRendererFramework: TextRenderer,
              sortable: true,
              resizable: true
            })
          ) ?? []
        );
      else return prevState;
    });
  }, [data]);

  const isSelectionEnabled = canReassignAccount && !isViewingUnassigned;
  const columnDefs = useMemo(
    () =>
      buildAccountRuleGridColumnDef({
        dynamicColumnsList,
        includeTerritoryColumns: !isViewingUnassigned,
        isSelectionEnabled
      }),
    [dynamicColumnsList, isViewingUnassigned, isSelectionEnabled]
  );

  const accountRuleGridProps = useMemo(
    (): AgGridReactProps => ({
      rowModelType: 'serverSide',
      serverSideStoreType: 'partial',
      rowHeight: CELL_HEIGHT,
      cacheBlockSize: ACCOUNT_RULE_BLOCK_SIZE,
      serverSideDatasource: {
        getRows: async (params) => {
          try {
            const { startRow, endRow } = params.request;
            const { data } = startRow
              ? await fetchMore({
                  variables: {
                    input: {
                      ...accountRuleGridInputRef.current,
                      startRow: startRow + 1,
                      endRow
                    }
                  }
                })
              : await getAccountRuleGrid();
            params.success({
              rowData: formatAccountList(data.getAccountRuleGrid.accountRules),
              rowCount: data.getAccountRuleGrid.totalCount
            });
          } catch {
            params.fail();
          }
        }
      }
    }),
    [accountRuleGridInput]
  );

  const onGridReady = (params) => {
    gridApi.current = params.api;
  };

  const handleFilterChange = (updatedFilter: FilterChangeInput) => {
    const { filterType, type, filter, colId } = updatedFilter;
    draftAccountFilters.current = {
      ...draftAccountFilters.current,
      [colId]: {
        filterType,
        type,
        filter,
        filterTo: null
      }
    };
  };

  const handleSelectionChange = (event: SelectionChangedEvent) => {
    const selectedRows: AccountRuleRow[] = event.api.getSelectedRows();
    setSelectedAccountRules(selectedRows);
  };

  const handleClearAll = () => {
    draftAccountFilters.current = {};
    handleFilterApply();
  };
  const handleFilterApply = () => {
    if (!isequal(draftAccountFilters.current, savedFilterModel)) {
      setSavedFilterModel(draftAccountFilters.current);
      gridApi.current?.onFilterChanged();
      gridApi.current?.deselectAll();
    }
  };
  const handleDialogSubmit = () => {
    updateStaleTabsLookupMap(TabIds.ACCOUNT_LIST);
    gridApi.current?.deselectAll();
  };

  const activeFiltersCount = useMemo(
    () => Object.values(savedFilterModel).filter(({ filter }) => !!filter || filter === 0).length,
    [savedFilterModel]
  );

  const handleSortChanged = (event: SortChangedEvent) => {
    const columns: Column[] = event.columnApi.getAllColumns();
    const sortedColumns: Column[] = Object.values(columns).filter((column) => {
      return column['sort'];
    });
    const sortModel: SortModel = { sortModel: [] };
    sortedColumns.forEach((column) => {
      sortModel.sortModel.push({ colId: column['colId'], sort: column['sort'] });
    });

    setSortModel(sortModel);
  };

  return (
    <div className={b()} ref={containerRef}>
      <AccountRuleHeader
        territoryGroupTypes={territoryGroupTypes}
        onTerritoryGroupTypeChange={setSelectedTgtId}
        currentTerritoryGroupTypeId={selectedTgtId}
        selectedAccountRules={selectedAccountRules}
        onDialogSubmit={handleDialogSubmit}
        dynamicColumns={data?.getAccountRuleGrid.accountRuleHeaders ?? []}
        onFilterChange={handleFilterChange}
        onFilterApply={handleFilterApply}
        onClearAll={handleClearAll}
        accountFilters={draftAccountFilters.current}
        activeFiltersCount={activeFiltersCount}
      />
      {error ? (
        <GridOverlayError message={formatMessage('ACCOUNT_RULE_GRID_ERROR')} />
      ) : (
        <AdvancedGrid
          data-testid="account-rule-grid"
          gridProps={accountRuleGridProps}
          columnDefs={columnDefs}
          gridWidth={containerRef.current?.offsetWidth ?? window.innerWidth}
          gridHeight={containerRef.current?.offsetHeight}
          onGridReady={onGridReady}
          key={selectedTgtId ?? 'unassigned-key'}
          onSelectionChanged={handleSelectionChange}
          onSortChanged={handleSortChanged}
          rowSelection="multiple"
          rowMultiSelectWithClick
          suppressRowClickSelection
          getRowNodeId={(row: AccountRuleRow) => `R::${row.accountId}::${row.territoryId}`}
        />
      )}
    </div>
  );
};

const getViewableTgtsFromTdrTree = (tdrTree: ExpandedTerritoryGroupDefineAndRefinePillData[]): NamedTgt[] => {
  const ownedTgts: NamedTgt[] = [];

  for (const tgt in tdrTree) {
    const currentTgt = tdrTree[tgt];
    if (currentTgt?.children?.length > 0) {
      ownedTgts.push({
        name: currentTgt.name,
        id: +currentTgt.territoryGroupId
      });
    }
  }
  return ownedTgts;
};

const useTgtsFromSelectedBc = (): NamedTgt[] => {
  const { selectedBattleCardId, battleCardLookupMap } = useBattleCard();
  const selectedBattlecard = battleCardLookupMap?.[selectedBattleCardId];
  const { tdrTreeLookupMap } = useTerritoryDefineAndRefine();
  const { battlecardInfoLevel } = selectedBattlecard;

  const viewableTgts = useMemo(() => {
    const allTgts =
      selectedBattlecard?.territoryGroupTypes?.map((tgt) => ({
        id: tgt.territoryGroupId,
        name: tgt.hierarchyAlias
      })) ?? [];

    const tdrTree = tdrTreeLookupMap ? tdrTreeLookupMap[selectedBattleCardId] : [];

    switch (battlecardInfoLevel) {
      case BCInfoLevelEnum.all:
        return allTgts;
      case BCInfoLevelEnum.territoryGroupTypeOwner:
      case BCInfoLevelEnum.territoryGroupOwner:
        return getViewableTgtsFromTdrTree(tdrTree);
      case BCInfoLevelEnum.hidden:
      case BCInfoLevelEnum.territoryOwner:
      default:
        return [];
    }
  }, [selectedBattlecard, tdrTreeLookupMap, battlecardInfoLevel, selectedBattleCardId]);

  return viewableTgts;
};

export default AccountRuleGrid;

// Used to ensure the value of the variables used in the `getRows` calls are up to date despite not being able to update the callback itself
function useAsLiveRef<T>(value: T) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
}
