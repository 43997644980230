// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KT3nH {\n  width: 100px;\n  height: 32px;\n  background-color: transparent;\n  position: relative;\n  display: inline-flex;\n  align-items: center\n}\n.KT3nH::before {\n    content: '';\n    position: absolute;\n    width: 2px;\n    height: 100%;\n    background-color: rgb(var(--color-gray-3));\n    left: calc(50% - 1px);\n  }\n.J2ehk {\n    width: 100%;\n    height: 50%;\n    background-color: rgb(var(--color-light-gray-3));\n  }\n.aSIhy {\n    height: 100%;\n    background-color: rgb(var(--color-cobalt-2));\n    margin-left: calc(50% + 1px);\n    margin-right: auto;\n  }\n.iIqVV {\n    height: 100%;\n    background-color: rgb(var(--color-rose-1));\n    margin-left: auto;\n    margin-right: calc(50% + 1px);\n  }\n.h0hAv {\n    width: 100%;\n    height: 100%;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GapIndicator": "KT3nH",
	"GapIndicator_gapLine": "J2ehk",
	"GapIndicator_above": "aSIhy",
	"GapIndicator_below": "iIqVV",
	"GapIndicator_tooltip": "h0hAv"
};
export default ___CSS_LOADER_EXPORT___;
