// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QJvy3 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  height: var(--header-height);\n  min-width: 100px;\n}\n\n  /* stylelint-disable plugin/selector-bem-pattern */\n\n  .qWbh5 {\n    padding: 4px 8px 0 9px;\n  }\n\n  .qWbh5 > svg {\n      height: 24px;\n      flex-shrink: 0;\n    }\n\n  .pi62a.bp3-menu-item {\n    display: flex;\n    align-items: center;\n    padding: 10px 16px;\n  }\n\n  .pi62a.bp3-menu-item .bp3-fill {\n      max-width: 200px;\n      white-space: break-spaces;\n    }\n\n  .xOVAR {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: default;\n    height: var(--header-height);\n    min-width: 100px;\n  }\n\n  .IZe8B {\n    padding-right: 4px;\n    font-size: 14px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    min-width: 0;\n  }\n\n  .OmNGT {\n    max-height: 300px;\n    overflow: scroll;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TenantMenu": "QJvy3",
	"TenantMenu_menuIcon": "qWbh5",
	"TenantMenu_menuItem": "pi62a",
	"TenantMenu_singleTenant": "xOVAR",
	"TenantMenu_menuText": "IZe8B",
	"TenantMenu_menu": "OmNGT"
};
export default ___CSS_LOADER_EXPORT___;
