import { ICellRendererParams, RowNode, ValueSetterParams } from '@ag-grid-community/core';

import EditableFieldCellRenderer from 'app/components/AdvancedGrid/CellRenderers/EditableFieldCellRenderer/EditableFieldCellRenderer';

import {
  QUOTA_COLUMN_HIGHLIGHTING_THRESHOLD_HIGH,
  QUOTA_COLUMN_HIGHLIGHTING_THRESHOLD_LOW
} from 'app/global/variables';

import {
  LookupTypeEnum,
  GetTerritoryQuota_getTerritoryQuota,
  GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures,
  GetTerritoryQuota_getTerritoryQuota_periodicTerritories
} from 'app/graphql/generated/graphqlApolloTypes';

import {
  GridHeaders,
  Lookup,
  MeasureFieldType,
  MeasureFormatNumberType,
  MeasureFormatType,
  Month,
  PERIODICITY_ANNUAL_VALUE_FIELD_ID,
  PeriodicityType,
  PeriodicGroupMeasure,
  QuotaGridColumnName,
  QuotaGridMeasureColumn
} from 'app/models';
import { Column } from 'app/models/index';

import { formatNumber } from 'utils/messages/utils';

interface Periodicity {
  key: string;
  value: number;
  date?: string;
  quarter?: string;
  half?: string;
  year?: string;
}

export const buildMeasuresGridData = (
  data: GetTerritoryQuota_getTerritoryQuota,
  battlecardId: string,
  quotaComponentId: number,
  isBreakdownOfQuotaEnabled?: boolean
): Array<Record<string, unknown>> => {
  const gridData = [];
  const { periodicTerritories } = data;

  // Initialize gridColumns array to contain the measure columns. All periodicity breakdown columns is not added at first
  // periodicity columns will be added into gridColumns inplace in the call of constructRowItem
  const gridColumns: QuotaGridMeasureColumn[] = periodicTerritories?.[0]?.measures
    ? periodicTerritories[0].measures.map((m) => ({ measureName: m.measureName }))
    : [];

  periodicTerritories.forEach((territory: GetTerritoryQuota_getTerritoryQuota_periodicTerritories, index: number) => {
    const {
      measures,
      territoryGroupId,
      territoryId,
      territoryName,
      territoryGroupName,
      territoryGroupLevelName,
      ruleId,
      comment
    } = territory;

    // build empty rowItem for each territory
    const rowItem = {};
    gridColumns.forEach((measure) => {
      rowItem[measure.measureName] = {};
    });

    // inside this loop, it will go over each measure and adding periodicity breakdown column into gridColumns
    // so when we are generating data for the second row, gridColumns will have all the periodicity breakdown columns for each measure
    measures.forEach((measure) => {
      if (measure.measureName === QuotaGridColumnName.SEASONALITY) {
        rowItem[measure.measureName] = {
          value: measure?.measureValue?.textValue ? measure?.measureValue?.textValue : '',
          id: measure?.measureId ?? measure?.measureId,
          quotaComponentId
        };
      } else {
        // Add current measure data into rowItem (no breakdown at this point)
        // If index === 0 (ie: looking at the first territory), it will update the gridColumns array in place to include the seasonality breakdown columns
        constructRowItem(rowItem, measure, gridColumns, quotaComponentId, index, isBreakdownOfQuotaEnabled);
      }
    });

    // Add periodicity breakdowns for all measures into rowItem
    updateRowItem(rowItem, gridColumns, quotaComponentId);
    gridData.push(
      Object.assign(rowItem, {
        battlecardId,
        territoryGroupId,
        territoryId,
        id: territoryId,
        territoryName,
        territoryGroupName,
        territoryGroupLevelName,
        ruleId,
        comment,
        quotaComponentId
      })
    );
  });

  return gridData;
};

export const shouldColumnBeEditable = (columnHeader: string, periodicityType: PeriodicityType): boolean => {
  if (!columnHeader?.includes('_')) {
    return false;
  } else {
    const columnKeys = columnHeader.split('_');
    const periodicityHeader = columnKeys[columnKeys.length - 1];
    if (periodicityType === PeriodicityType.MONTHLY && !Object.values(Month).includes(periodicityHeader as Month)) {
      return false;
    }

    if (periodicityType === PeriodicityType.QUARTERLY && !periodicityHeader.startsWith('Q')) {
      return false;
    }

    if (periodicityHeader === PERIODICITY_ANNUAL_VALUE_FIELD_ID) {
      return false;
    }

    return true;
  }
};

export const constructRowItem = (
  rowItem: unknown,
  measure: GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures,
  gridColumns: QuotaGridMeasureColumn[],
  quotaComponentId: number,
  rowIndex: number,
  isBreakdownOfQuotaEnabled?: boolean
): void => {
  const periodicity = [];
  let periodicityType: PeriodicityType = PeriodicityType.NONE;
  let annualValue = 0;
  let measureValue = 0;
  let hasMonthlyPeriodicity = false;
  let hasQuarterlyPeriodicity = false;

  // when isBreakdownOfQuotaEnabled is true, quota grid using the new getTerritoryQuota api to load the grid
  // all computed columns expect updated quota will not have any periodicity, just one column
  if (
    measure.measureName === QuotaGridColumnName.ACCOUNT_QUOTA ||
    measure.measureValue?.measureFieldType === MeasureFieldType.LOADED ||
    (isBreakdownOfQuotaEnabled &&
      measure.measureValue?.measureFieldType === MeasureFieldType.FORMULA &&
      measure.measureName !== QuotaGridColumnName.UPDATED_QUOTA)
  ) {
    measureValue = measure.measureValue.value;
  } else if (measure.measureValue && typeof measure.measureValue === 'object') {
    measure.measureValue.years.forEach((year) => {
      measureValue += year.value;
      periodicityType = PeriodicityType.YEARLY;
      year?.halves?.forEach((half) => {
        periodicityType = PeriodicityType.SEMI_ANNUALLY;
        half?.quarters?.forEach((quarter) => {
          periodicityType = PeriodicityType.QUARTERLY;
          hasQuarterlyPeriodicity = true;
          if (quarter.months) {
            quarter?.months?.forEach((month) => {
              periodicity.push({
                key: `${year.year}_${month.month}`,
                value: month.value,
                date: month.date,
                quarter: quarter.quarter,
                half: half.half,
                year: year.year.toString()
              });
              periodicityType = PeriodicityType.MONTHLY;
              hasMonthlyPeriodicity = true;
            });
          }
          periodicity.push({
            key: `${year.year}_${quarter.quarter}`,
            value: quarter.value,
            date: quarter.date,
            half: half.half,
            year: year.year.toString()
          });
          annualValue += quarter.value;
        });
        periodicity.push({
          key: `${year.year}_${half.half}`,
          value: half.value,
          date: half.date,
          year: year.year.toString()
        });
      });

      if (hasQuarterlyPeriodicity || hasMonthlyPeriodicity) {
        periodicity.push({ key: year.year.toString(), value: annualValue, date: year.date });
      } else {
        periodicity.push({ key: year.year.toString(), value: year.value, date: year.date });
      }
    });
    // when rowIndex is 0, modify the gridColumns in place to add periodicity columns
    appendPeriodicityColumns(periodicity, measure, gridColumns, rowIndex);

    // temporary fix to display updated quota roll up value
    // TODO: This can be safely removed once this BE ticket is finished and returns proper periodicity values for updated quota https://varicent.atlassian.net/browse/TQP-10708
    if (measure.measureName === QuotaGridColumnName.UPDATED_QUOTA && !measureValue) {
      measureValue = measure.measureValue.value;
    }
  } else {
    measureValue = Number(measure.measureValue);
  }

  rowItem[measure.measureName] = {
    value: measureValue || '',
    id: measure?.measureId ?? '',
    quotaComponentId,
    periodicity,
    periodicityType,
    shouldBeEditable: periodicity?.length === 0,
    measureFieldType: measure.measureValue?.measureFieldType,
    parentMeasure: null
  };
};

const findParentMeasureAndPeriodicityMeasure = (rowItem: unknown, measureName: string) => {
  const columnKeys = measureName.split('_');
  let parentMeasure = columnKeys[0];
  let index = 0;
  for (let i = 1; i < columnKeys.length; i++) {
    if (rowItem[parentMeasure]?.id) break;
    parentMeasure = `${parentMeasure}_${columnKeys[i]}`;
    index++;
  }
  return { parentMeasure, periodicityMeasure: columnKeys.slice(index + 1).join('_') };
};

export const updateRowItem = (
  rowItem: unknown,
  gridColumns: QuotaGridMeasureColumn[],
  quotaComponentId: number
): void => {
  // setting data for the periodicity cells
  gridColumns.forEach((column) => {
    // To prevent measures that are already in the rowItem list to be overwritten when they contain underscores in the measure name
    if (rowItem[column.measureName]?.id) return;

    if (column.measureName.includes('_')) {
      const { parentMeasure, periodicityMeasure } = findParentMeasureAndPeriodicityMeasure(rowItem, column.measureName);

      const value = rowItem[parentMeasure]?.periodicity?.find((item) => item.key === periodicityMeasure)?.value ?? 0;
      const parentPeriodicityType = rowItem[parentMeasure]?.periodicityType;
      const id = rowItem[parentMeasure]?.id;
      rowItem[column.measureName] = {
        id,
        value,
        quotaComponentId,
        date: column.measureDate || '',
        quarter: column.measureQuarter || '',
        half: column.measureHalf || '',
        year: column.measureYear || '',
        periodicityType: parentPeriodicityType,
        periodicity: [],
        shouldBeEditable: shouldColumnBeEditable(column.measureName, parentPeriodicityType),
        measureFieldType: rowItem[parentMeasure]?.measureFieldType,
        parentMeasure
      };
    }
  });
};

export const appendPeriodicityColumns = (
  periodicity: Periodicity[],
  measure: GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures,
  currentColumns: QuotaGridMeasureColumn[],
  rowIndex: number
): void => {
  // when we are looping through all the territories, we only need to append periodicity columns once since all rows share the same column information
  if (rowIndex !== 0) return;
  const columns: QuotaGridMeasureColumn[] = [];
  periodicity.forEach((item) => {
    const periodicityItem = item.key;
    const measureItem = {
      measureName: `${measure.measureName}_${periodicityItem}`,
      measureDate: item?.date ? item?.date : null,
      measureQuarter: item.quarter ? item?.quarter : null,
      measureHalf: item.half ? item?.half : null,
      measureYear: item.year ? item?.year : null
    };
    columns.push(measureItem);
  });

  if (columns.length > 0) {
    const parentIndex = currentColumns.findIndex((measureTotal) => measureTotal.measureName === measure.measureName);
    currentColumns.splice(parentIndex + 1, 0, ...columns);
  }
};

export const getQuarter = (month: string): string => {
  // these are the column fields, not the actual title headers; hence they should not be translated
  switch (month) {
    case Month.JANUARY:
    case Month.FEBRUARY:
    case Month.MARCH:
      return 'Q1';
    case Month.APRIL:
    case Month.MAY:
    case Month.JUNE:
      return 'Q2';
    case Month.JULY:
    case Month.AUGUST:
    case Month.SEPTEMBER:
      return 'Q3';
    default:
      return 'Q4';
  }
};

export const getDisabledInputs = (
  disabledCellInputs: Record<string, string[]>[],
  node: RowNode,
  key: string,
  shouldDisable: boolean
): Record<string, string[]>[] => {
  if (shouldDisable) {
    const disabledItem = disabledCellInputs?.find((item) => item[node.id]);

    // since we need to disable the input for the header, need to check if the rowNode already exists then we update the header, for eg {[1]: [allocated td]}
    // in this case if allocated td is already there for row node 1, then we push the new header which is quota adjustment, if not just adding the row node 1 along with the header
    if (disabledItem) {
      disabledItem[node.id].push(key);
    } else {
      disabledCellInputs.push({ [node.id]: [key] });
    }
  } else {
    // removing the header from the row node key to enable the input
    disabledCellInputs = disabledCellInputs.map((disabledItem) => {
      if (disabledItem[node.id]) {
        return { [node.id]: disabledItem[node.id].filter((item) => item !== key) };
      }

      return disabledItem;
    });
  }

  return disabledCellInputs;
};

export const shouldHeaderHaveExtraPadding = (
  columnId: string,
  periodicityType: PeriodicityType,
  isExpandable: boolean,
  isExpanded?: boolean
): boolean => {
  if (!columnId) {
    return false;
  }
  const isEditableMeasure =
    columnId === QuotaGridColumnName.ALLOCATED_TD || columnId === QuotaGridColumnName.QUOTA_ADJUSTMENT;
  const isNotEditablePeriodicityMeasure = !shouldColumnBeEditable(columnId, periodicityType);
  const isPeriodicityColumn = columnId.includes('_');
  const isAllocatedOrQuotaPeriodicity =
    columnId.includes(QuotaGridColumnName.ALLOCATED_TD) || columnId.includes(QuotaGridColumnName.QUOTA_ADJUSTMENT);
  // when header has actionable icon (expandable or collapsible), if the header is one of the editable measures or not an editable periodicity; adding the extra padding so the header be aligned with numbers
  if (isExpandable) {
    if (isEditableMeasure || isNotEditablePeriodicityMeasure || isExpanded) {
      return true;
    }
    // when header is a periodicity which belongs to one of the editable columns which is either Allocated TD or Quota Adjustment, adding the extra padding so the headers be aligned with numbers
  } else if (isPeriodicityColumn && isAllocatedOrQuotaPeriodicity) {
    return true;
  } else if (columnId === QuotaGridColumnName.ALLOCATION || PeriodicityType.YEARLY) {
    return true;
  }

  return false;
};

export const shouldColumnBeExpandable = (
  measureName: string,
  isEditable: boolean,
  periodicityType: PeriodicityType,
  hasMultipleMeasureValue: boolean,
  measureFieldType?: string
): boolean => {
  if (measureFieldType === MeasureFieldType.LOADED) return false;
  if (measureName === QuotaGridColumnName.ACCOUNT_QUOTA) return false;
  if (periodicityType === PeriodicityType.YEARLY) {
    if (measureName.includes(QuotaGridColumnName.TRAILING_MONTHS) || (!hasMultipleMeasureValue && !isEditable)) {
      return false;
    }
  }

  return true;
};

export const getCellStyles = (
  params: ICellRendererParams,
  column: Column,
  isColumnHighlightingEnabled?: boolean
): unknown => {
  const isColumnEditable = column.editable && column.measureName !== QuotaGridColumnName.QUOTA_ADJUSTMENT;
  if (isColumnEditable && params?.data[column.measureName]?.shouldBeEditable && params?.node?.rowPinned) {
    return {
      display: 'flex',
      justifyContent: 'flex-end',
      border: 'none'
    };
  } else if (column.measureName === QuotaGridColumnName.QUOTA_ADJUSTMENT && !params?.node?.rowPinned) {
    return {
      textAlign: 'right',
      border: 'none',
      padding: 0
    };
  } else if (
    isColumnHighlightingEnabled &&
    column.measureName === QuotaGridColumnName.ADJUSTMENT &&
    (params?.value > QUOTA_COLUMN_HIGHLIGHTING_THRESHOLD_HIGH ||
      params?.value < QUOTA_COLUMN_HIGHLIGHTING_THRESHOLD_LOW)
  ) {
    return {
      background: 'var(--intent-danger-translucent-active)',
      textAlign: 'right',
      border: 'none'
    };
  } else {
    return {
      textAlign: 'right',
      border: 'none'
    };
  }
};

export const getMeasureType = (
  params: ICellRendererParams,
  columnsWithTotals: Array<string>,
  isCurrencyConversionValid: boolean
): string => {
  const key: string = params?.colDef?.field;
  if (key !== null && key !== undefined && columnsWithTotals.includes(params?.colDef.headerName)) {
    if (!isCurrencyConversionValid) return '';
    if (key.includes(QuotaGridColumnName.ALLOCATION)) {
      return MeasureFormatNumberType.PERCENT;
    } else if (key.includes(QuotaGridColumnName.UNITS_SOLD)) {
      return MeasureFormatNumberType.UNIT;
    } else if (params?.colDef?.type === MeasureFormatType.NUMERIC) {
      return MeasureFormatNumberType.NUMERIC;
    } else if (params?.colDef?.type === MeasureFormatType.PERCENTAGE) {
      return MeasureFormatNumberType.PERCENT;
    } else {
      return MeasureFormatNumberType.CURRENCY;
    }
  }
  return '';
};

export const getSeasonalityLookUpsItems = (lookups: Lookup[]): unknown => {
  const lookup = lookups?.find((lookup) => lookup.lookupType === LookupTypeEnum.Seasonality);
  const selectMenuItems = (!!lookup?.metadata?.tableData ? Object.keys(lookup.metadata.tableData) : []).map(
    (lookupVal) => ({
      key: lookupVal,
      value: lookupVal
    })
  );
  return {
    items: selectMenuItems
  };
};

export const setCellValueForSeasonality = (params: ValueSetterParams): unknown => {
  if (!params?.newValue) {
    return false;
  }

  if (params.data[GridHeaders.SEASONALITY]) {
    params.data[GridHeaders.SEASONALITY].value = params.newValue;
  } else {
    const seasonalityValue = { Seasonality: { value: params.newValue } };
    params.data = { ...params.data, ...seasonalityValue };
  }
  return true;
};

export const getValueForSeasonality = (params: ValueSetterParams): unknown => {
  const key = params?.colDef?.headerName;
  if (params?.data && params?.data[key]) {
    return params.data[key].value;
  }

  return null;
};

export const getSeasonalityCellRendererSelector = (params: ICellRendererParams): unknown => {
  if (params?.node?.rowPinned) {
    return '';
  } else {
    return {
      frameworkComponent: EditableFieldCellRenderer,
      params: {
        measureName: QuotaGridColumnName.SEASONALITY
      }
    };
  }
};

export const unitFormatter = (params: ICellRendererParams): unknown => {
  const key = params.colDef.field;
  if (params.data && params.data[key]) {
    return formatNumber(params.data[key].value, { style: 'unit' });
  }
  return null;
};

export const getPeriodicityType = (measure: PeriodicGroupMeasure) => {
  if (measure.measureValue?.years) {
    if (measure.measureValue?.years[0]?.halves) {
      if (measure.measureValue?.years[0]?.halves[0]?.quarters) {
        if (measure.measureValue?.years[0]?.halves[0]?.quarters[0]?.months) {
          return PeriodicityType.MONTHLY;
        }
        return PeriodicityType.QUARTERLY;
      }
      return PeriodicityType.SEMI_ANNUALLY;
    }
    return PeriodicityType.YEARLY;
  }
  return PeriodicityType.NONE;
};

export const getPeriodicColumns = (measure: PeriodicGroupMeasure, measureName: string) => {
  const columns: Column[] = [];
  const columnsWithTotals = [];
  measure.measureValue?.years.forEach((year) => {
    year?.halves?.forEach((half) => {
      half?.quarters?.forEach((quarter) => {
        quarter?.months?.forEach((month) => {
          const monthHeader = `${measureName}_${year.year}_${month.month}`;
          columns.push({ measureName: monthHeader, editable: measure.editable, type: measure.measureValue.type });
          columnsWithTotals.push(monthHeader);
        });
        const quarterHeader = `${measureName}_${year.year}_${quarter.quarter}`;
        columns.push({ measureName: quarterHeader, editable: measure.editable, type: measure.measureValue.type });
        columnsWithTotals.push(quarterHeader);
      });
      const halfHeader = `${measureName}_${year.year}_${half.half}`;
      columns.push({ measureName: halfHeader, editable: measure.editable, type: measure.measureValue.type });
      columnsWithTotals.push(halfHeader);
    });
    const yearHeader = `${measureName}_${year.year}`;
    columns.push({
      measureName: yearHeader,
      editable: year?.halves?.length ? false : measure.editable,
      type: measure.measureValue.type,
      measureFieldType: measure.measureValue.measureFieldType
    });

    columnsWithTotals.push(yearHeader);
  });
  return { columns, columnsWithTotals };
};
