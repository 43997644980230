// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wahpv {\n    height: 32px;\n    width: 100%;\n    border-radius: 4px;\n    padding: var(--select-menu-padding);\n    color: rgb(var(--color-dark-gray-3));\n    border: solid 1px rgb(var(--color-light-gray-1));\n  }\n\n    .NQXdE {\n      color: rgb(var(--color-light-gray-1));\n      background-color: rgb(var(--color-light-gray-4));\n      box-shadow: inset 0 0 0 1px rgb(var(--color-light-gray-1));\n      cursor: not-allowed !important; /* to override bootstrap style */\n    }\n\n    ._4OIgn {\n      border-color: rgb(var(--color-red-3));\n    }\n  .Wahpv:focus {\n      box-shadow:\n        inset 0 0 0 1px rgb(var(--color-cobalt-3)),\n        0 0 0 2px rgba(var(--color-cobalt-3), 0.3);\n    }\n  .mJ3Fp {\n    height: 20px;\n    color: rgb(var(--color-red-3));\n    margin-top: 6px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormattedCurrencyInput_currencyInput": "Wahpv",
	"FormattedCurrencyInput_currencyInput__disabled": "NQXdE",
	"FormattedCurrencyInput_currencyInput__danger": "_4OIgn",
	"FormattedCurrencyInput_validationMessage": "mJ3Fp"
};
export default ___CSS_LOADER_EXPORT___;
