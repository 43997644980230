import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import AuthSpinner from 'components/AuthSpinner/AuthSpinner';

import { RoutePaths } from 'app/containers/App/Router/routePaths';

import { useScope } from 'app/contexts/scopeProvider';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

type PrivateRouteProps = Pick<RouteProps, 'render' | 'component' | 'path' | 'exact'>;

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
  const history = useHistory();
  const { setSelectedTenant, setSelectedPlanningCycle } = useScope();
  const { isAuthenticated, isLoading } = useAuth0();
  const showToast = useShowToast();

  // check if the user is authenticated
  // if not, then route to login
  useEffect(() => {
    if (isLoading) return;

    try {
      if (!isAuthenticated) {
        setSelectedTenant(null);
        setSelectedPlanningCycle(null);
        history.push(RoutePaths.LOGIN, {
          from: { pathname: history.location.pathname, search: history.location.search }
        });
      }
    } catch (error) {
      console.error('Error during unauthenticated reset', error);
      showToast(formatMessage('AUTH_CHECK_FAILURE'), 'danger');
      history.push({ pathname: RoutePaths.LOGIN, search: history.location.search });
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading) return <AuthSpinner />;

  return <Route {...props} />;
};

export default PrivateRoute;
