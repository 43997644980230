import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetAccountRuleBindingsForQuotaMove,
  GetAccountRuleBindingsForQuotaMoveVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_ACCOUNT_RULE_BINDINGS_FOR_QUOTA_MOVE = gql`
  query GetAccountRuleBindingsForQuotaMove($input: GetAccountRuleBindingsInput!) {
    getAccountRuleBindings(input: $input) {
      totalCount
      bindings {
        measures {
          measureId
          measureValue
          measureName
          quotaComponentId
          quotaComponentName
          measureValueBreakdown {
            measureValue
            periodEndDate
            periodStartDate
          }
        }
      }
    }
  }
`;

export const useGetAccountRuleBindingsForQuotaMoveLazy = createUseLazyQuery<
  GetAccountRuleBindingsForQuotaMove,
  GetAccountRuleBindingsForQuotaMoveVariables
>(GET_ACCOUNT_RULE_BINDINGS_FOR_QUOTA_MOVE);
