// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3XfO8 {\n  padding: 16px 0;\n}\n\n  .aX7GD {\n  }\n\n  .DQNb- {\n    margin-bottom: 4px !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SymonConfigurationSelectMenu": "_3XfO8",
	"SymonConfigurationSelectMenu_label": "aX7GD " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label-text"] + "",
	"SymonConfigurationSelectMenu_symonInputTextLabelWrapper": "DQNb-"
};
export default ___CSS_LOADER_EXPORT___;
