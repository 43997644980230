import React from 'react';

import { AddAlt, WarningFilled } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import {
  EditableFieldCellRendererProps,
  GridHeaders,
  SellerSheetGridColumnName,
  TerritorySheetGridColumnName
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './EditableFieldCellRenderer.module.pcss';

const b = block(style);

const EditableFieldCellRenderer: React.FC<EditableFieldCellRendererProps> = (props) => {
  const {
    measureName,
    isTooltipDisplayed,
    value,
    node,
    api,
    shouldShowAddButton,
    shouldDisableAddButton,
    isEffectiveDateOutOfRange,
    tooltipMessage = null
  } = props;

  const getBEMModifiers = () => {
    type MeasureNameType = TerritorySheetGridColumnName | SellerSheetGridColumnName | GridHeaders;

    const alignLeftConditions: MeasureNameType[] = [
      TerritorySheetGridColumnName.SEASONALITY,
      TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES,
      TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE,
      SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES,
      SellerSheetGridColumnName.RAMP,
      GridHeaders.EFFECTIVE_DATE
    ];

    // Check if the current measureName requires 'alignLeft'
    const isAlignLeft = alignLeftConditions.includes(measureName as MeasureNameType);

    return {
      alignLeft: isAlignLeft,
      alignRight: !isAlignLeft,
      unauthorizedCursor: isTooltipDisplayed,
      emptyCell: !value
    };
  };

  const handleClick = () => {
    node.data.isEditingQuotaAdjustment = true;
    api.startEditingCell({
      rowIndex: node.rowIndex,
      colKey: TerritorySheetGridColumnName.SEASONALITY
    });
    node.data.isEditingQuotaAdjustment = false;
  };

  const cellWrapper =
    isEffectiveDateOutOfRange && props.colDef.field === TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE;

  return (
    <MessageTooltip
      className={b('', getBEMModifiers())}
      target={
        <div className={b('', getBEMModifiers())} data-testid="quota-cell-renderer-read-only">
          <div className={b({ cellWrapper })} data-testid="cell-value">
            <span>{value}</span>
            {isEffectiveDateOutOfRange && (
              <IconButton
                type="button"
                icon={<WarningFilled size={16} />}
                testId="select-menu-icon-button"
                intent="danger"
                minimal
                tooltipText={formatMessage('ADJUSTMENT_EFFECTIVE_DATE_WARNING_TOOLTIP')}
              />
            )}
          </div>
          {shouldShowAddButton && (
            <IconButton
              type="button"
              icon={<AddAlt size={20} />}
              tooltipText={
                shouldDisableAddButton
                  ? formatMessage('DISABLE_ADD_QUOTA_ADJUSTMENT_TOOLTIP')
                  : formatMessage('ADD_QUOTA_ADJUSTMENT')
              }
              onClick={handleClick}
              testId="add-quota-adjustment-btn"
              disabled={shouldDisableAddButton}
            />
          )}
        </div>
      }
      placement={'top'}
      content={isTooltipDisplayed ? tooltipMessage : null}
    />
  );
};

export default EditableFieldCellRenderer;
