import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import { EmbeddedMapGenericErrorView } from './EmbeddedMapFallbackViews';

interface ErrorBoundaryProps {
  children: React.ReactChild;
}

const EmbeddedErrorBoundary = ({ children }: ErrorBoundaryProps): JSX.Element => {
  return <ErrorBoundary FallbackComponent={EmbeddedMapGenericErrorView}>{children}</ErrorBoundary>;
};

export default EmbeddedErrorBoundary;
