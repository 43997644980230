import { ColDef } from '@ag-grid-community/core';

import StatusCellRenderer from 'app/components/StatusCellRenderer/StatusCellRenderer';

import { formatMessage } from 'utils/messages/utils';

const buildQuotaApprovalColumnDef = (
  hasData: boolean,
  gridAction: (params) => JSX.Element,
  emailClass: string
): ColDef[] => {
  const columnDefs: ColDef[] = [];

  if (hasData) {
    columnDefs.push({
      headerName: formatMessage('USER'),
      field: 'user',
      flex: 1,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      minWidth: 200
    });

    columnDefs.push({
      headerName: formatMessage('EMAIL'),
      field: 'email',
      flex: 1,
      menuTabs: ['filterMenuTab'],
      cellClass: emailClass,
      filter: 'agSetColumnFilter',
      minWidth: 200
    });

    columnDefs.push({
      headerName: formatMessage('STATUS'),
      field: 'status',
      flex: 1,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      cellRendererFramework: StatusCellRenderer,
      minWidth: 150,
      cellRendererParams: (params) => {
        return { isRootBC: params.data.workflowHierarchy.length === 1 };
      }
    });

    columnDefs.push({
      headerName: '',
      field: 'action',
      flex: 1,
      cellRendererFramework: gridAction,
      menuTabs: [],
      minWidth: 150
    });
  }

  return columnDefs;
};

export default buildQuotaApprovalColumnDef;
