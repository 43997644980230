import React from 'react';

import { Edit, TrashCan } from '@carbon/icons-react';
import clonedeep from 'lodash.clonedeep';

import IconButton from 'components/Buttons/IconButton/IconButton';

import ExpandedHierarchyRule from 'app/components/ExpandedHierarchyRule/ExpandedHierarchyRule';

import { NewRuleDefinitionResponse, RuleIncExc } from 'app/graphql/generated/graphqlApolloTypes';

import {
  CombinatorType,
  FlattenedFilter,
  HierarchyItem,
  HierarchyType,
  HierarchyTypeName,
  OperatorType,
  RuleBeingEdited,
  RuleIncExcGroup,
  RulePartType,
  TerritoryRuleHierarchyWithInfo
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import Combinator from './Combinator';
import CompactFilterV2 from './CompactFilterV2';
import style from './RuleEditorRow.module.pcss';

const b = block(style);

const operatorToIncExcKeyMap = {
  [OperatorType.EQUAL]: 'inclusions',
  [OperatorType.NOT_EQUAL]: 'exclusions'
};
interface RuleEditorRowProps {
  ruleDefinition: NewRuleDefinitionResponse;
  setRuleDefinition: (ruleDefinition: NewRuleDefinitionResponse) => void;
  rulePartType: RulePartType;
  filter: FlattenedFilter;
  rulesBeingEdited: RuleBeingEdited[];
  setRulesBeingEdited: (rulesBeingEdited: RuleBeingEdited[]) => void;
  shouldShowConnectors: boolean;
  isEditable: boolean;
  setNewInheritsFrom?: (inheritsFrom: TerritoryRuleHierarchyWithInfo[]) => void;
  isSelectedOrHovered?: boolean;
  darkMode?: boolean;
}

const RuleEditorRow: React.FC<RuleEditorRowProps> = ({
  ruleDefinition,
  setRuleDefinition,
  setNewInheritsFrom,
  rulePartType,
  filter,
  rulesBeingEdited,
  setRulesBeingEdited,
  shouldShowConnectors,
  isEditable,
  isSelectedOrHovered,
  darkMode
}: RuleEditorRowProps) => {
  const { base, modifiers } = ruleDefinition;

  const isEditingIndex = rulesBeingEdited?.findIndex(
    (existingFilter) =>
      filter?.field === existingFilter?.field &&
      filter?.rootHierarchyId === existingFilter?.rootHierarchyId &&
      existingFilter?.rulePartType === rulePartType
  );

  const findInclusionExclusionIndex = (inclusionExclusion: RuleIncExc) =>
    filter.field === inclusionExclusion.hierarchyType && filter.rootHierarchyId === inclusionExclusion.rootHierarchyId;

  const hierarchyInclusionIndex = base?.inclusions?.findIndex(findInclusionExclusionIndex);
  const hierarchyExclusionIndex = base?.exclusions?.findIndex(findInclusionExclusionIndex);

  const operatorToRuleIncExclMap = {
    [OperatorType.EQUAL]: hierarchyInclusionIndex,
    [OperatorType.NOT_EQUAL]: hierarchyExclusionIndex
  };

  const disableEditMode = () => {
    // remove rule from list of rules being edited
    const newRules = [...rulesBeingEdited];
    newRules.splice(isEditingIndex, 1);
    setRulesBeingEdited(newRules);
  };

  const updateRule = (hierarchyInclusionsExclusions: RuleIncExcGroup) => {
    if (rulePartType === RulePartType.BASE && filter) {
      updateBaseRule(hierarchyInclusionsExclusions);
    } else if (rulePartType === RulePartType.OVERRIDE) {
      updateModifiers(hierarchyInclusionsExclusions);
    }
  };

  const updateBaseRule = (hierarchyInclusionsExclusions: RuleIncExcGroup) => {
    const newBaseRule: RuleIncExcGroup = clonedeep(base);

    Object.keys(operatorToIncExcKeyMap).forEach((operator) => {
      const ruleToUpdateIndex = operatorToRuleIncExclMap[operator];
      const incExcKey = operatorToIncExcKeyMap[operator];

      const contains = hierarchyInclusionsExclusions[incExcKey].map((hierarchyItem: HierarchyItem) => {
        return {
          hierarchyId: hierarchyItem?.hierarchyId,
          key: hierarchyItem?.key,
          name: hierarchyItem?.name
        };
      });
      if (contains.length === 0) {
        newBaseRule[incExcKey].splice(ruleToUpdateIndex, 1);
      } else if (ruleToUpdateIndex >= 0) {
        newBaseRule[incExcKey][ruleToUpdateIndex].contains = contains;
        newBaseRule[incExcKey][ruleToUpdateIndex].containsTotal = contains.length;
      } else {
        newBaseRule[incExcKey]?.push({
          id: null,
          hierarchyType: filter.field,
          rootHierarchyId: filter.rootHierarchyId,
          rootHierarchyName: filter.rootHierarchyName,
          contains,
          containsTotal: contains.length
        });
      }
    });

    setRuleDefinition({
      ...ruleDefinition,
      base: newBaseRule
    });
  };

  const updateModifiers = (hierarchyInclusionsExclusions: RuleIncExcGroup) => {
    const newModifiers: RuleIncExcGroup = clonedeep(modifiers);

    const inclKey = operatorToIncExcKeyMap[OperatorType.EQUAL];
    const contains = hierarchyInclusionsExclusions[inclKey].map((hierarchyItem: HierarchyItem) => {
      return {
        hierarchyId: hierarchyItem?.hierarchyId,
        key: hierarchyItem?.key,
        name: hierarchyItem?.name
      };
    });

    // TODO customer + geo
    // update rule with new inclusions/exclusions
    newModifiers.inclusions[0].contains = contains;
    newModifiers.inclusions[0].containsTotal = contains.length;

    setRuleDefinition({
      ...ruleDefinition,
      modifiers: newModifiers
    });
  };

  const deleteRulePart = () => {
    if (filter.rootHierarchyName === HierarchyTypeName.OVERLAY_TERRITORIES) {
      // delete territory rule
      setNewInheritsFrom([]);
    } else if (rulePartType === RulePartType.BASE) {
      const newBaseRule = clonedeep(base);
      if (hierarchyInclusionIndex >= 0) newBaseRule.inclusions.splice(hierarchyInclusionIndex, 1);
      if (hierarchyExclusionIndex >= 0) newBaseRule.exclusions.splice(hierarchyExclusionIndex, 1);
      setRuleDefinition({ ...ruleDefinition, base: newBaseRule });
    } else if (rulePartType === RulePartType.OVERRIDE) {
      setRuleDefinition({ ...ruleDefinition, modifiers: { inclusions: [] } });
    }
  };

  const handleSave = () => {
    disableEditMode();
  };

  const handleCancel = () => {
    if (isEditingIndex < 0) {
      return;
    }

    const ruleBeingEdited = rulesBeingEdited[isEditingIndex];
    const hasContent =
      ruleBeingEdited?.fieldIdsDetails?.[OperatorType.EQUAL]?.length ||
      ruleBeingEdited?.fieldIdsDetails?.[OperatorType.NOT_EQUAL]?.length;

    // territory rules are not in base.inclusions/exclusions, need to check separately
    if (
      !hasContent &&
      (filter.rootHierarchyName !== HierarchyTypeName.OVERLAY_TERRITORIES ||
        !filter.fieldIdsDetails[OperatorType.EQUAL].length)
    ) {
      deleteRulePart();
    }

    disableEditMode();
  };

  const baseRuleInclusions = filter?.fieldIdsDetails?.[OperatorType.EQUAL]?.map((fieldIdDetail) => {
    return {
      name: fieldIdDetail?.fieldName,
      key: fieldIdDetail?.key,
      hierarchyId: fieldIdDetail?.fieldId,
      ruleId: fieldIdDetail.ruleId
    };
  });

  const baseRuleExclusions = filter?.fieldIdsDetails?.[OperatorType.NOT_EQUAL]?.map((fieldIdDetail) => {
    return {
      name: fieldIdDetail?.fieldName,
      key: fieldIdDetail?.key,
      hierarchyId: fieldIdDetail?.fieldId,
      ruleId: fieldIdDetail.ruleId
    };
  });

  const filterId = `${filter?.field}-${filter?.rootHierarchyId}`;

  const filterField = filter?.field?.split('.');
  const filterHierarchyType = filterField?.length ? (filterField[0] as HierarchyType) : null;

  return isEditingIndex >= 0 ? (
    <ExpandedHierarchyRule
      rootHierarchyId={filter?.rootHierarchyId}
      rootHierarchyName={filter?.rootHierarchyName}
      hierarchyType={filterHierarchyType}
      inclusions={baseRuleInclusions}
      exclusions={baseRuleExclusions}
      setInclusionsExclusions={updateRule}
      setNewInheritsFrom={setNewInheritsFrom}
      onSave={handleSave}
      onCancel={handleCancel}
      rulePartType={rulePartType}
      key={filterId}
      data-testid={`expanded-hierarchy-rule-${filterId}`}
    />
  ) : (
    <div
      className={b('', {
        connectors: shouldShowConnectors,
        inline: !isEditable,
        isSelectedOrHovered,
        darkMode
      })}
      key={filterId}
      data-testid={`rule-editor-row-${filterId}`}
    >
      {!!baseRuleInclusions?.length && (
        <CompactFilterV2
          rootHierarchyName={filter?.rootHierarchyName}
          operator={OperatorType.EQUAL}
          hierarchyItems={baseRuleInclusions}
          total={filter?.inclusionTotal}
          inline={!isEditable}
          data-testid={`inclusions-filter-${filterId}`}
        />
      )}
      {!!baseRuleInclusions?.length && !!baseRuleExclusions?.length && (
        <Combinator combinatorType={CombinatorType.AND} data-testid={`inner-combinator-${filterId}`} />
      )}
      {!!baseRuleExclusions?.length && (
        <CompactFilterV2
          rootHierarchyName={filter?.rootHierarchyName}
          operator={OperatorType.NOT_EQUAL}
          hierarchyItems={baseRuleExclusions}
          total={filter?.exclusionTotal}
          inline={!isEditable}
          data-testid={`exclusions-filter-${filterId}`}
        />
      )}
      {isEditable && (
        <>
          <IconButton
            onClick={() =>
              setRulesBeingEdited([
                ...rulesBeingEdited,
                {
                  ...filter,
                  rulePartType
                }
              ])
            }
            type="button"
            icon={<Edit />}
            title={formatMessage('EDIT')}
            className={b('editBtn')}
            testId={`edit-rule-btn-${filterId}`}
          />
          <IconButton
            onClick={deleteRulePart}
            type="button"
            icon={<TrashCan />}
            title={formatMessage('DELETE')}
            intent="danger"
            className={b('deleteBtn')}
            testId={`delete-rule-btn-${filterId}`}
          />
        </>
      )}
    </div>
  );
};

export default RuleEditorRow;
