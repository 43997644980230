import { GraphQLError } from 'graphql';

import { ApolloErrorContainer } from 'app/graphql/apolloTypeOverrides';

export const handleError = (graphQLErrors: ReadonlyArray<GraphQLError>, networkError: Error | null): void => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
};

export const handleErrorContainer = (errorContainer: ApolloErrorContainer) => {
  if (errorContainer.knownError) {
    const { message, errorCode } = errorContainer.knownError;
    console.warn(`[ERROR: ${errorCode}]: ${message}`, errorContainer.knownError);
  } else {
    handleError(errorContainer.graphQLErrors, errorContainer.networkError);
  }
};
