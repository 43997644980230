import React, { useEffect, useMemo, useState } from 'react';

import { TabButtonList } from 'components/TabButtonList/TabButtonList';

import { VIEWED_QUOTA_COMPONENT_TABS } from 'app/constants/ContributorConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { UserRoleType } from 'app/models';

import { createScopedLocalStorage } from 'utils/helpers/localStorageHelpers';

const viewedQcIdTabStorage = createScopedLocalStorage<number[]>(VIEWED_QUOTA_COMPONENT_TABS);

const QuotaComponentTabSwitcher: React.FC = () => {
  const { selectedQuotaComponentId, setSelectedQuotaComponentId } = useBattleCard();
  const { sheetDefinitions } = useData();
  const { userRole } = useUser();

  const qcsInSheet = useMemo(() => {
    // All SheetDefinitions within a Sheet share the same list of QCs
    const quotaComponentsForSheet = sheetDefinitions?.[0]?.quotaComponents ?? [];
    const seenNames = new Set<string>();
    return quotaComponentsForSheet.filter((qc) => {
      if (seenNames.has(qc.quotaComponentName)) return false;
      seenNames.add(qc.quotaComponentName);
      return true;
    });
  }, [sheetDefinitions]);

  const [viewedQcIds, setViewedQcIds] = useState(() => viewedQcIdTabStorage.read() ?? []);
  useEffect(() => {
    viewedQcIdTabStorage.write(viewedQcIds);
  }, [viewedQcIds]);

  const markQcIdViewed = (newQcId: number) =>
    setViewedQcIds((viewedQcIds) => (viewedQcIds.includes(newQcId) ? viewedQcIds : [...viewedQcIds, newQcId]));

  useEffect(() => {
    if (selectedQuotaComponentId) markQcIdViewed(selectedQuotaComponentId);
  }, [selectedQuotaComponentId]);

  // eslint-disable-next-line no-restricted-syntax
  const isUserContributor = userRole === UserRoleType.CONTRIBUTOR;
  const firstQcIdInSheet = qcsInSheet[0]?.quotaComponentId;
  useEffect(() => {
    if (!isUserContributor) return;
    if (qcsInSheet.length > 0 && qcsInSheet.every((qc) => qc.quotaComponentId !== selectedQuotaComponentId)) {
      setSelectedQuotaComponentId(firstQcIdInSheet);
    }
  }, [firstQcIdInSheet, qcsInSheet, isUserContributor]);

  if (!isUserContributor) return null;
  if (!selectedQuotaComponentId) return null;
  // Nothing to switch between
  if (qcsInSheet.length < 2) return null;

  return (
    <div data-testid="quota-component-tablist">
      <TabButtonList
        onSwitchTab={setSelectedQuotaComponentId}
        selectedTab={selectedQuotaComponentId}
        buttonList={qcsInSheet.map(({ quotaComponentId, quotaComponentName }) => ({
          id: quotaComponentId,
          text: quotaComponentName,
          viewed: viewedQcIds.includes(quotaComponentId)
        }))}
      />
    </div>
  );
};

export default QuotaComponentTabSwitcher;
