import React, { useMemo, useState } from 'react';

import { Search, Close } from '@carbon/icons-react';
import { UseComboboxStateChange } from 'downshift';
import { Field, Form, Formik } from 'formik';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import { SearchableSelectMenuItem } from 'components/models';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import SellerSelect from 'app/components/SellerSelect/SellerSelect';

import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { FilterChangeInput, FilterInput } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { TERRITORY_GRID_TEXT_FILTER_COLUMNS } from './TerritoryGridConstants';
import style from './TerritoryGridFilter.module.pcss';
import { formatStaticInitialValues, formatTerritoryGroupItems } from './territoryGridUtils';

const b = block(style);

interface TerritoryGridFilterProps {
  onFilterChange: (updatedFilter: FilterChangeInput) => void;
  territoryFilters: FilterInput;
  onFilterApply: () => void;
  onClearField: (colId: string) => void;
}

const TerritoryGridFilter: React.FC<TerritoryGridFilterProps> = ({
  onFilterChange,
  territoryFilters,
  onFilterApply,
  onClearField
}) => {
  const { tdrBattlecardLookupMap } = useTerritoryDefineAndRefine();

  const [territoryGroupSearchText, setTerritoryGroupSearchText] = useState('');
  const territoryGroupItems = useMemo(
    () => formatTerritoryGroupItems(tdrBattlecardLookupMap, territoryGroupSearchText),
    [tdrBattlecardLookupMap, territoryGroupSearchText]
  );
  const staticInitialValues = useMemo(() => {
    return {
      territoryId: '',
      territoryName: '',
      territoryGroupName: '',
      sellerName: '',
      ...formatStaticInitialValues(territoryFilters)
    };
  }, [territoryFilters]);

  const handleSelectItem = (filter: string, colId: string) => {
    onFilterChange({
      filterType: 'text',
      type: 'contains',
      filter,
      colId
    });
    onFilterApply();
  };

  return (
    <div className={b('inputWrapper')} data-testid="territory-grid-filter">
      <Formik initialValues={staticInitialValues} onSubmit={null} enableReinitialize>
        {({ values }) => {
          return (
            <Form>
              <div className={b('filterWrapper')}>
                {TERRITORY_GRID_TEXT_FILTER_COLUMNS.map((column) => (
                  <div key={column.colId}>
                    <Field
                      component={FormTextInputGroup}
                      name={column.colId}
                      label={column.label}
                      value={territoryFilters[column.colId]?.filter ?? ''}
                      showErrors={false}
                      leftIcon={<Search />}
                      icon={
                        territoryFilters[column.colId]?.filter && (
                          <IconButton
                            icon={<Close />}
                            className={b('clearTextButton')}
                            onClick={() => onClearField(column.colId)}
                            type="button"
                            testId={column.clearButtonTestId}
                          />
                        )
                      }
                      onChange={(e) =>
                        onFilterChange({
                          filterType: 'text',
                          type: 'contains',
                          filter: e.target.value,
                          colId: column.colId
                        })
                      }
                      onBlur={onFilterApply}
                    />
                  </div>
                ))}
                <div className={b('label')}>
                  <div>{formatMessage('TERRITORY_GROUP')}</div>
                  {values.territoryGroupName && (
                    <TextButton
                      text={formatMessage('CLEAR')}
                      className={b('clearButton')}
                      minimal
                      type="button"
                      testId="clear-tg-name-button"
                      large={false}
                      onClick={() => {
                        onClearField('territoryGroupName');
                      }}
                    />
                  )}
                </div>
                <Field
                  component={SearchableSelectMenu}
                  name="territoryGroupName"
                  onSelectItem={(changes: UseComboboxStateChange<SearchableSelectMenuItem>) => {
                    handleSelectItem(changes.selectedItem.key, 'territoryGroupName');
                  }}
                  onSearch={(searchString) => setTerritoryGroupSearchText(searchString)}
                  showIconInField={false}
                  items={territoryGroupItems}
                  placeHolderText={
                    territoryFilters['territoryGroupName']?.filter ?? formatMessage('SELECT_TERRITORY_GROUP')
                  }
                  theme="default"
                  initialLoadingComplete
                  showErrors={false}
                />
                <div className={b('label')}>
                  <div>{formatMessage('SELLER')}</div>
                  {values.sellerName && (
                    <TextButton
                      text={formatMessage('CLEAR')}
                      className={b('clearButton')}
                      minimal
                      type="button"
                      testId="clear-seller-button"
                      large={false}
                      onClick={() => {
                        onClearField('sellerName');
                      }}
                    />
                  )}
                </div>
                <Field
                  component={SellerSelect}
                  name="sellerName"
                  showEmployeeId={false}
                  placeHolderText={territoryFilters['sellerName']?.filter ?? formatMessage('SELECT_SELLER')}
                  onSelectItem={(changes: UseComboboxStateChange<SearchableSelectMenuItem>) => {
                    handleSelectItem(changes.selectedItem.key, 'sellerName');
                  }}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TerritoryGridFilter;
