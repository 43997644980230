import React from 'react';

import { Copy } from '@carbon/icons-react';
import { Button } from '@varicent/components';

import DropdownMenu from 'components/DropdownMenu/DropdownMenu';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TooltipWithCopy.module.pcss';

const b = block(style);

interface TooltipWithCopyProps {
  buttonText: string;
  copySuccessMessage: string;
  items: string[];
}

const TooltipWithCopy: React.FC<TooltipWithCopyProps> = ({
  buttonText,
  copySuccessMessage,
  items
}: TooltipWithCopyProps) => {
  const showToast = useShowToast();

  const handleCopyItems = () => {
    navigator.clipboard.writeText(items.toString());
    showToast(copySuccessMessage, 'success');
  };

  return (
    <div className={b('')} data-testid="tooltip-with-copy">
      <DropdownMenu
        target={
          <Button
            text={buttonText}
            small
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            data-testid="tooltip-btn"
          />
        }
        placement="top"
        content={
          <div className={b('itemsContainer')}>
            <Button
              minimal
              small
              type="button"
              icon={Copy}
              text={formatMessage('COPY')}
              onClick={handleCopyItems}
              data-testid="copy-btn"
            />
            <div className={b('items')}>
              {items.map((item, index) => (
                <span className={b('item')} key={`${item}-${index}`} data-testid={`item-${index}`}>
                  {item}
                </span>
              ))}
            </div>
          </div>
        }
      />
    </div>
  );
};
export default TooltipWithCopy;
