// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k\\+ZjF {\n    padding-left: 5px;\n    margin-bottom: 20px;\n  }\n\n    .k\\+ZjF a > .bp3-button-text {\n      padding: 0;\n    }\n  .eOm7O:hover {\n    cursor: not-allowed;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReorderableList_addListItemArea": "k+ZjF",
	"ReorderableList_addListItemArea__disabled": "eOm7O"
};
export default ___CSS_LOADER_EXPORT___;
