import gql from 'graphql-tag';

export const GET_UNASSIGNED_ACTIVITIES = gql`
  query GetUnassignedActivities($battlecardId: Int!, $quotaComponentId: Int!, $startRow: Int, $endRow: Int) {
    getUnassignedActivities(
      input: { battlecardId: $battlecardId, quotaComponentId: $quotaComponentId, startRow: $startRow, endRow: $endRow }
    ) {
      territoryActivitiesList
      territoryActivityCount
    }
  }
`;
