// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".i9vwM {\n    margin-top: var(--l);\n  }\n  .kY3VN {\n    margin-top: var(--m);\n  }\n  .LoZsA {\n    display: flex;\n    justify-content: center;\n    min-height: 200px;\n    align-items: center;\n  }\n  .NBw-h {\n    padding: 24px 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImportActivityFileUpload_callout": "i9vwM",
	"ImportActivityFileUpload_linkContainer": "kY3VN",
	"ImportActivityFileUpload_spinnerContainer": "LoZsA",
	"ImportActivityFileUpload_fileUploadContainer": "NBw-h"
};
export default ___CSS_LOADER_EXPORT___;
