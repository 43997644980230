import * as yup from 'yup';

import { formatMessage } from 'utils/messages/utils';

export default yup.object().shape({
  quotaComponentName: yup
    .string()
    .required(formatMessage('NAME_IS_A_REQUIRED_FIELD'))
    .max(64)
    .label(formatMessage('NAME')),
  quotaComponentComment: yup.string().max(1000).label(formatMessage('DESCRIPTION'))
});
