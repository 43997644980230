import React, { useMemo, useState } from 'react';

import { ChevronUp, ChevronDown, Information } from '@carbon/icons-react';

import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { useIsFilteringAccountsByActivities } from 'app/graphql/hooks/useIsFilteringAccountsByActivities';

import {
  CustomerInsight,
  HierarchyType,
  InsightCardStat,
  JobStatus,
  MapSelectionTarget,
  MchQuantity,
  RuleForMap,
  SelectedGeography
} from 'app/models';

import block from 'utils/bem-css-modules';
import { startPerformanceTimer } from 'utils/helpers/analyticsReporter';
import { formatAbbreviatedCurrency } from 'utils/helpers/currencyHelpers';
import {
  createInsightFilter,
  createSelectedExpandedGeosList,
  getSelectedExpandedRulesList
} from 'utils/helpers/territoryMapUtils';
import { formatMessage } from 'utils/messages/utils';

import { useMapSelectionStats } from './hooks/useMapSelectionStats';
import style from './InsightsCard.module.pcss';
import InsightsCardStat from './InsightsCardStat';
import InsightsCardStatTable from './InsightsCardStatTable';

const b = block(style);

export interface InsightsCardProps {
  selectionTarget: MapSelectionTarget;
  selectedGeoIds: number[];
  currency: string;
  showActivityStats: boolean;
  mappableTerritoryRules: RuleForMap[];
  selectedRuleIds: number[];
  customerInsights: ReadonlyArray<CustomerInsight>;
  geographyNameMap: ReadonlyMap<number, string>;
  selectedAccountIds: Array<number>;
  onExpandStat?: (isExpanded: boolean) => void;
}

const InsightsCard: React.FC<InsightsCardProps> = ({
  selectionTarget,
  selectedGeoIds,
  currency,
  showActivityStats,
  mappableTerritoryRules,
  selectedRuleIds,
  customerInsights,
  geographyNameMap,
  selectedAccountIds,
  onExpandStat
}: InsightsCardProps) => {
  const stats = useMapSelectionStats(
    selectionTarget,
    selectedGeoIds,
    showActivityStats,
    mappableTerritoryRules,
    selectedRuleIds
  );

  const { coinsortStatus, primaryHierarchy } = useMapContextRedistributor();

  const { chosenCustomHierarchy } = useDedicatedMapProvider();

  const isFilteringAccountsByActivities = useIsFilteringAccountsByActivities();

  const [isOverrideAccountsEnabled, setIsOverrideAccountsEnabled] = useState(true);

  const [expandedStat, setExpandedStat] = useState<InsightCardStat | null>(null);

  const isTargetingTerritories = selectionTarget === MapSelectionTarget.territories;
  const isTargetingGeographies = selectionTarget === MapSelectionTarget.polygons;
  const canViewOverrides = isTargetingTerritories && primaryHierarchy === HierarchyType.GeographicTerritoryHierarchy;
  const shouldShowOverrideAccounts = canViewOverrides && isOverrideAccountsEnabled;

  const selectedInsights = useMemo(() => {
    if (selectedRuleIds.length === 0 && selectedGeoIds.length === 0 && selectedAccountIds.length === 0) return [];
    const timer = startPerformanceTimer('filteringMapInsights');
    const insights = customerInsights.filter(
      createInsightFilter({
        selectedRuleIds,
        selectedGeoIds,
        selectionTarget,
        selectedAccountIds
      })
    );
    timer.stop({
      originalLength: customerInsights.length,
      filteredLength: insights.length
    });
    return insights;
  }, [customerInsights, selectedRuleIds, selectedGeoIds, selectedAccountIds]);

  const selectedTotalAccountsList = useMemo(
    () => selectedInsights.filter((customer) => !customer.isModifierAccount || isOverrideAccountsEnabled),
    [selectedInsights, isOverrideAccountsEnabled]
  );

  const accountMeasureValueTotal = useMemo(() => {
    if (selectionTarget !== MapSelectionTarget.accounts) return null;
    let totalValue = 0;
    selectedTotalAccountsList.forEach((account) => {
      if (account.measureValue) totalValue += account.measureValue;
    });
    return totalValue;
  }, [selectedTotalAccountsList]);

  const selectedOverrideAccountsList = useMemo(() => {
    if (!canViewOverrides) return [];
    return selectedInsights.filter((customer) => customer.isModifierAccount);
  }, [selectedInsights, canViewOverrides]);

  const selectedExpandedRulesList: RuleForMap[] = useMemo(() => {
    if (expandedStat !== InsightCardStat.TERRITORIES) {
      return [];
    }
    return getSelectedExpandedRulesList({
      mappableTerritoryRules,
      selectedRuleIds
    });
  }, [mappableTerritoryRules, selectedRuleIds, expandedStat]);

  const selectedExpandedGeographiesList: SelectedGeography[] = useMemo(() => {
    if (expandedStat !== InsightCardStat.GEOGRAPHIC_REGIONS) {
      return [];
    }
    return createSelectedExpandedGeosList({
      selectedGeoIds,
      selectedTotalAccountsList,
      geographyNameMap
    });
  }, [selectedTotalAccountsList, selectedGeoIds, geographyNameMap, expandedStat]);

  const sharedInsightsCardStatProps = getSharedStatProps(coinsortStatus, stats.isLoading, showActivityStats);

  const totalAccountsCount = selectedTotalAccountsList.length;

  const totalMeasureValue = accountMeasureValueTotal ?? stats.measureValue;

  const handleStatClick = (clickedStat: InsightCardStat) => {
    if (expandedStat === clickedStat) {
      setExpandedStat(null);
      onExpandStat?.(false);
    } else {
      setExpandedStat(clickedStat);
      onExpandStat?.(true);
    }
  };

  const getExpandableStatIcon = (statOfInterest: InsightCardStat) => {
    return expandedStat === statOfInterest ? <ChevronUp /> : <ChevronDown />;
  };

  const shouldShowMeasureValue = isTargetingTerritories || chosenCustomHierarchy.quantity === MchQuantity.NONE;
  const sharedInsightsCardStatTableProps = {
    currency,
    shouldShowMeasureValue
  };

  return (
    <aside className={b('card', { expanded: !!expandedStat })} data-testid="insights-card">
      <div className={b('body')}>
        {isTargetingTerritories && (
          <InsightsCardStat
            label={formatMessage('SELECTED_TERRITORIES')}
            text={stats.size.toString()}
            testId="selected-territories"
            onStatClick={() => handleStatClick(InsightCardStat.TERRITORIES)}
            rightIcon={getExpandableStatIcon(InsightCardStat.TERRITORIES)}
            disabled={!stats.size}
          />
        )}
        {isTargetingGeographies && (
          <InsightsCardStat
            label={formatMessage('SELECTED_GEOGRAPHIC_REGIONS')}
            text={stats.size.toString()}
            testId="selected-geographic-regions"
            onStatClick={() => handleStatClick(InsightCardStat.GEOGRAPHIC_REGIONS)}
            rightIcon={getExpandableStatIcon(InsightCardStat.GEOGRAPHIC_REGIONS)}
            disabled={!stats.size}
          />
        )}
        {expandedStat === InsightCardStat.TERRITORIES && (
          <div data-testid="selected-territories-table" className={b('statTable')}>
            <InsightsCardStatTable
              {...sharedInsightsCardStatTableProps}
              selectedRules={selectedExpandedRulesList}
              expandedTable={InsightCardStat.TERRITORIES}
            />
          </div>
        )}
        {expandedStat === InsightCardStat.GEOGRAPHIC_REGIONS && (
          <div data-testid="selected-geographies-table" className={b('statTable')}>
            <InsightsCardStatTable
              {...sharedInsightsCardStatTableProps}
              selectedGeographies={selectedExpandedGeographiesList}
              expandedTable={InsightCardStat.GEOGRAPHIC_REGIONS}
            />
          </div>
        )}
        <InsightsCardStat
          label={
            <>
              {formatMessage('TOTAL_NUMBER_OF_ACCOUNTS')}
              {isFilteringAccountsByActivities && (
                <MessageTooltip
                  content={formatMessage('TOTAL_ACCOUNTS_TOOLTIP')}
                  target={<Information size={16} data-testid="information-icon" />}
                  placement={'top'}
                />
              )}
            </>
          }
          text={totalAccountsCount.toString()}
          testId="number-accounts"
          showStatValue={showActivityStats}
          onStatClick={() => handleStatClick(InsightCardStat.TOTAL_ACCOUNTS)}
          rightIcon={getExpandableStatIcon(InsightCardStat.TOTAL_ACCOUNTS)}
          disabled={!totalAccountsCount}
          {...sharedInsightsCardStatProps}
        />
        {expandedStat === InsightCardStat.TOTAL_ACCOUNTS && (
          <div data-testid="selected-accounts-table" className={b('statTable')}>
            <InsightsCardStatTable
              {...sharedInsightsCardStatTableProps}
              selectedAccounts={selectedTotalAccountsList}
              expandedTable={InsightCardStat.TOTAL_ACCOUNTS}
            />
          </div>
        )}
        {shouldShowOverrideAccounts && (
          <InsightsCardStat
            label={formatMessage('NUMBER_OF_OVERRIDE_ACCOUNTS')}
            text={selectedOverrideAccountsList.length.toString()}
            testId="number-override-accounts"
            showStatValue={showActivityStats}
            onStatClick={() => handleStatClick(InsightCardStat.OVERRIDE_ACCOUNTS)}
            rightIcon={getExpandableStatIcon(InsightCardStat.OVERRIDE_ACCOUNTS)}
            disabled={selectedOverrideAccountsList.length === 0}
            {...sharedInsightsCardStatProps}
          />
        )}
        {shouldShowOverrideAccounts && expandedStat === InsightCardStat.OVERRIDE_ACCOUNTS && (
          <div data-testid="selected-overrides-table" className={b('statTable')}>
            <InsightsCardStatTable
              {...sharedInsightsCardStatTableProps}
              selectedAccounts={selectedOverrideAccountsList}
              expandedTable={InsightCardStat.OVERRIDE_ACCOUNTS}
            />
          </div>
        )}
        {shouldShowMeasureValue && (
          <InsightsCardStat
            label={formatMessage('PRIOR_YEAR_SALES')}
            text={formatAbbreviatedCurrency(showActivityStats ? totalMeasureValue : 0, currency)}
            testId="measure-value"
            disabled={selectionTarget === MapSelectionTarget.accounts}
            {...sharedInsightsCardStatProps}
          />
        )}
        {canViewOverrides && (
          <div className={b('overrideAccountsSwitch')}>
            <SwitchButton
              checked={isOverrideAccountsEnabled}
              onChange={() => setIsOverrideAccountsEnabled((prev) => !prev)}
              labelElement={<span>{formatMessage('INCLUDE_OVERRIDE_ACCOUNTS')}</span>}
              testId="override-accounts-switch"
            />
          </div>
        )}
      </div>
    </aside>
  );
};

function getSharedStatProps(coinsortStatus: JobStatus, isStatsLoading: boolean, showActivityStats: boolean) {
  const isCoinsortInProgress = coinsortStatus === JobStatus.IN_PROGRESS;
  const isCoinsortComplete = coinsortStatus === JobStatus.COMPLETED;

  let tooltipText = '';

  if (!showActivityStats) {
    tooltipText = formatMessage('NO_ACTIVITIES_TOOLTIP');
  } else if (isStatsLoading) {
    tooltipText = '';
  } else if (isCoinsortInProgress) {
    tooltipText = formatMessage('COINSORT_RUNNING_TOOLTIP');
  } else if (isCoinsortComplete) {
    tooltipText = formatMessage('COINSORT_COMPLETE_TOOLTIP');
  }

  return {
    tooltipText,
    isLoading: isStatsLoading || isCoinsortInProgress,
    shouldShowWarning: isCoinsortComplete
  };
}

export default InsightsCard;
