import React, { useEffect, useState } from 'react';

import { RowNode } from '@ag-grid-community/core';
import { CheckboxIndeterminate, TrashCan } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommandCenterHierarchyPanelMultiSelectedToolbar.module.pcss';

const b = block(style);

interface CommandCenterHierarchyPanelMultiSelectedToolbarProps {
  multiSelectedNodes: RowNode[];
  onMultiDelete: () => void;
  onDeselectAll: () => void;
}

export const CommandCenterHierarchyPanelMultiSelectedToolbar: React.FC<
  CommandCenterHierarchyPanelMultiSelectedToolbarProps
> = ({ multiSelectedNodes, onMultiDelete, onDeselectAll }) => {
  const [isLoading, setIsLoading] = useState(false);

  const multiDeleteTooltipText =
    multiSelectedNodes.length === 1
      ? formatMessage('DELETE_MEMBER_WITH_VALUE', { count: multiSelectedNodes.length })
      : formatMessage('DELETE_MEMBERS_WITH_VALUE', { count: multiSelectedNodes.length });

  // fake loader to make sure the latest calculation of
  // multiSelectedNodes is shown
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, [multiSelectedNodes, setIsLoading]);

  const selectedMessage =
    multiSelectedNodes.length > 1
      ? formatMessage('MEMBERS_SELECTED_WITH_COUNT', { count: multiSelectedNodes.length })
      : formatMessage('MEMBER_SELECTED_WITH_COUNT', { count: multiSelectedNodes.length });

  return (
    <div className={b()}>
      <div className={b('deselectContainer')}>
        <IconButton
          type="button"
          icon={<CheckboxIndeterminate />}
          onClick={onDeselectAll}
          tooltipText={formatMessage('DESELECT_ALL')}
          testId="hierarchies-deselect-all"
          title="deselect-all-icon"
        />
        {isLoading ? (
          <GridLoading gridWidth={200} gridHeight={40} />
        ) : (
          <span data-testid="members-count">{selectedMessage}</span>
        )}
      </div>
      <IconButton
        type="button"
        icon={<TrashCan />}
        onClick={onMultiDelete}
        tooltipText={multiDeleteTooltipText}
        testId="hierarchies-multi-delete"
        title="delete-hierarchies-icon"
      />
    </div>
  );
};
