// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ar6\\+x {\n  display: flex;\n  height: calc(100% - var(--header-height));\n  align-items: center;\n  justify-content: center;\n  overflow: auto;\n}\n\n  .gp16z {\n    max-width: 20em;\n    width: auto;\n    margin-left: 25px;\n  }\n\n  .FNEcM {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n  }\n\n  .i7mAf {\n    width: 600px;\n    text-align: center;\n    margin: auto;\n  }\n\n  ._1D8lZ {\n    padding-top: 16px;\n    font-size: 2em;\n  }\n\n  .FPXq1 {\n    padding-top: 16px;\n  }\n\n  .LnIch {\n    font-size: 6em;\n    font-weight: 700;\n    text-align: center;\n    color: rgb(var(--color-turquoise-3));\n  }\n\n  .hl8\\+h {\n    display: flex;\n    justify-content: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ErrorPage": "ar6+x",
	"ErrorPage_artwork": "gp16z",
	"ErrorPage_errorContainer": "FNEcM",
	"ErrorPage_textWrapper": "i7mAf",
	"ErrorPage_title": "_1D8lZ",
	"ErrorPage_description": "FPXq1",
	"ErrorPage_textErrorCode": "LnIch",
	"ErrorPage_artworkWrapper": "hl8+h"
};
export default ___CSS_LOADER_EXPORT___;
