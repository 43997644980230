import React, { useState, useMemo, Dispatch, SetStateAction } from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext } from 'app/models';

interface ContextValues extends BaseContext {
  defaultReportingCurrency: string;
  setDefaultReportingCurrency: Dispatch<SetStateAction<string>>;
  isLocalCurrencyMode: boolean;
  setIsLocalCurrencyMode: Dispatch<SetStateAction<boolean>>;
  areCurrencyConversionsInverted: boolean;
  setAreCurrencyConversionsInverted: Dispatch<SetStateAction<boolean>>;
  shouldValidateCurrencies: boolean;
  setShouldValidateCurrencies: Dispatch<SetStateAction<boolean>>;
  doesInvalidCurrencyConversionExist: boolean;
  setDoesInvalidCurrencyConversionExist: Dispatch<SetStateAction<boolean>>;
  currenciesInUse: string[];
  setCurrenciesInUse: Dispatch<SetStateAction<string[]>>;
  resetValues: () => void;
}

// to handle everything regarding our app localization
export const LocalizationContext = React.createContext<ContextValues | null>(null);
LocalizationContext.displayName = 'LocalizationContext';

export const LocalizationProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const defaultReportingCurrencyCode = 'USD';

  const [defaultReportingCurrency, setDefaultReportingCurrency] = useState<string>(defaultReportingCurrencyCode);
  const [isLocalCurrencyMode, setIsLocalCurrencyMode] = useState<boolean>(false);
  const [areCurrencyConversionsInverted, setAreCurrencyConversionsInverted] = useState<boolean>(false);
  const [shouldValidateCurrencies, setShouldValidateCurrencies] = useState<boolean>(false);
  const [doesInvalidCurrencyConversionExist, setDoesInvalidCurrencyConversionExist] = useState<boolean>(false);
  const [currenciesInUse, setCurrenciesInUse] = useState<string[]>([]);

  const resetValues = () => {
    setDefaultReportingCurrency(defaultReportingCurrencyCode);
    setIsLocalCurrencyMode(false);
    setAreCurrencyConversionsInverted(false);
    setShouldValidateCurrencies(false);
    setDoesInvalidCurrencyConversionExist(false);
    setCurrenciesInUse([]);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      defaultReportingCurrency,
      setDefaultReportingCurrency,
      isLocalCurrencyMode,
      setIsLocalCurrencyMode,
      areCurrencyConversionsInverted,
      setAreCurrencyConversionsInverted,
      shouldValidateCurrencies,
      setShouldValidateCurrencies,
      doesInvalidCurrencyConversionExist,
      setDoesInvalidCurrencyConversionExist,
      currenciesInUse,
      setCurrenciesInUse,
      resetValues
    }),
    [
      defaultReportingCurrency,
      isLocalCurrencyMode,
      areCurrencyConversionsInverted,
      shouldValidateCurrencies,
      doesInvalidCurrencyConversionExist,
      currenciesInUse
    ]
  );

  // Return the interface that we want to expose to our other components
  return <LocalizationContext.Provider value={values}>{children}</LocalizationContext.Provider>;
};

// Custom hook to read these values from
export const useLocalization = (): ContextValues => useContextSafe(LocalizationContext);
