import gql from 'graphql-tag';

export const PUBLISH_FILE_FROM_SYMON_S3 = gql`
  mutation publishFileFromSymonS3($input: PublishFileFromSymonS3Input!) {
    publishFileFromSymonS3(input: $input) {
      downloadId
      endAt
      exportId
      exportName
      fileFormat
      fileType
      pipeId
      pipeName
      planningCycleId
      progress
      properties
      runId
      seqPipeExecution
      startAt
      status
      symonPipeConfigurationId
      tableNameLabel
    }
  }
`;
