import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetJobs, GetJobsVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_JOBS = gql`
  query GetJobs($input: GetJobsInput!) {
    getJobs(input: $input) {
      items {
        id
        taskId
        objectType
        tenantId
        planningCycleId
        actorEmail
        impersonatorActorEmail
        status
        isActive
        description
        progress
        startDate
        endDate
        error {
          message
          errorType
          errorCode
          context
        }
        metadata
      }
      lastEvaluatedKey
      totalCount
    }
  }
`;

export const useGetJobsLazy = createUseLazyQuery<GetJobs, GetJobsVariables>(GET_JOBS);
