import React from 'react';

import { CalloutV2, Classes, Intent } from '@varicent/components';

import { useScope } from 'app/contexts/scopeProvider';

import { useGetCustomerAccountHierarchyGeocodingStatus } from 'app/graphql/queries/getCustomerAccountHierarchyGeocodingStatus';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AccountInformationCallout.module.pcss';

const b = block(style);

const AccountInformationCallout: React.FC = () => {
  const { selectedPlanningCycle } = useScope();
  const { data, loading } = useGetCustomerAccountHierarchyGeocodingStatus({
    fetchPolicy: 'network-only',
    skip: !selectedPlanningCycle?.id,
    variables: {
      planningCycleId: selectedPlanningCycle?.id
    }
  });

  const geoCodingSummary = data?.getCustomerAccountHierarchyGeocodingStatus?.summary;

  const renderLoadingSkeleton = () => {
    return (
      <div className={b('skeletonWrapper')} data-testid="scenario-menu-loading-skeleton">
        <div className={Classes.SKELETON} />
        <div className={Classes.SKELETON} />
      </div>
    );
  };

  const getAccountInfoList = () => {
    if (loading) {
      return renderLoadingSkeleton();
    }

    const accountInfoItems = [
      {
        count: geoCodingSummary.numOfAccountsWithLinkedGeoKey,
        testId: 'successfully-geocoded-accounts',
        text: formatMessage('SUCCESSFULLY_GEOCODED_ACCOUNTS', {
          count:
            geoCodingSummary.numOfAccountsWithLinkedGeoKey - geoCodingSummary.numOfAccountsWithUnmatchedLinkedGeoKey
        })
      },
      {
        count: geoCodingSummary.numOfAccountsWithoutLinkedGeoKey,
        testId: 'missing-geo-accounts',
        text: formatMessage('MISSING_LINKED_GEOKEY_ACCOUNTS', {
          count: geoCodingSummary.numOfAccountsWithoutLinkedGeoKey
        })
      },
      {
        count: geoCodingSummary.numOfAccountsWithUnmatchedLinkedGeoKey,
        testId: 'unmatched-geo-accounts',
        text: formatMessage('INCOMPLETE_LINKED_GEOKEY_ACCOUNTS', {
          count: geoCodingSummary.numOfAccountsWithUnmatchedLinkedGeoKey
        })
      },
      {
        count: geoCodingSummary.numOfAccountsWithUnmatchedVisualGeoKey,
        testId: 'unmatched-visual-geo-accounts',
        text: formatMessage('INCOMPLETE_VISUAL_GEOKEY_ACCOUNTS', {
          count: geoCodingSummary.numOfAccountsWithUnmatchedVisualGeoKey
        })
      }
    ];

    return (
      <ul className={b('list')}>
        {accountInfoItems.map(
          ({ count, testId, text }) =>
            count > 0 && (
              <li key={testId} data-testid={testId}>
                {text}
              </li>
            )
        )}
      </ul>
    );
  };

  return (
    <>
      {!loading && !geoCodingSummary ? (
        <CalloutV2 intent={Intent.DANGER} title={formatMessage('SOMETHING_WENT_WRONG')} className={b()}>
          <p>{formatMessage('GEOCODING_INFO_ERROR')}</p>
        </CalloutV2>
      ) : (
        <CalloutV2 intent={Intent.PRIMARY} title={formatMessage('ACCOUNT_CALLOUT_TITLE')} className={b()}>
          <p>{formatMessage('ACCOUNT_CALLOUT_BODY')}</p>
          {getAccountInfoList()}
        </CalloutV2>
      )}
    </>
  );
};

export default AccountInformationCallout;
