import * as React from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext, CustomHeader, Headers } from 'app/models';

const { useState, useEffect } = React;
export interface ApolloHeaderContextValues extends BaseContext {
  setHeaders: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  headers: Headers;
}

export const apolloHeaderContext = React.createContext<ApolloHeaderContextValues | null>(null);
apolloHeaderContext.displayName = 'apolloHeaderContext';

export const ApolloHeaderProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [headers, setHeaders] = useState<Headers>({});

  const resetValues = () => {
    setHeaders({});
  };

  const values = React.useMemo(() => ({ headers, setHeaders, resetValues }), [headers]);

  return <apolloHeaderContext.Provider value={values}>{children}</apolloHeaderContext.Provider>;
};

export const useCustomApolloHeaders = (): ApolloHeaderContextValues => useContextSafe(apolloHeaderContext);

// use this hook to add or remove custom headers to all Apollo client requests
export const setHeaderReducer =
  (headerName: CustomHeader, headerValue: string) =>
  (oldHeaders: Headers): Headers => {
    if (headerValue === '') {
      const newHeaders = { ...oldHeaders };
      delete newHeaders[headerName];
      return newHeaders;
    }

    return { ...oldHeaders, [headerName]: headerValue };
  };

/**
 * @deprecated Prefer HeaderSetter from HeaderCascade
 */
export const useApplyCustomHeader = (
  headerName: CustomHeader,
  rawHeaderValue: string | number | null | undefined
): void => {
  const { setHeaders } = useCustomApolloHeaders();

  const headerValue = rawHeaderValue?.toString() ?? '';

  useEffect(() => {
    setHeaders(setHeaderReducer(headerName, headerValue));
  }, [headerValue]);
};
