import { useCallback, useState } from 'react';

import { useUpsertTerritory } from 'app/graphql/hooks/useUpsertTerritoryRule';

import { wrapError } from 'utils/errors/ErrorUtil';

import { useAskMapQuestionAsPromise } from './useAskMapQuestionAsPromise';

interface UndoInput {
  onError: (error: Error) => void;
  onComplete: () => void;
}

interface UndoState {
  isLoading: boolean;
  errorCount: number;
  totalCount: number;
  completeCount: number;
}

const initialState: UndoState = {
  isLoading: false,
  errorCount: 0,
  totalCount: 0,
  completeCount: 0
};

export const useMapUndo = (input: UndoInput): [UndoState, () => Promise<void>] => {
  const [state, setState] = useState<UndoState>(initialState);
  const initiateUndo = useAskMapQuestionAsPromise('initiate-undo');
  const [upsertRule] = useUpsertTerritory();

  const undo = useCallback(async () => {
    try {
      setState({ ...initialState, isLoading: true });
      const { rules: rulesToUpsert } = await initiateUndo();
      setState((s) => ({ ...s, totalCount: rulesToUpsert.length }));
      await Promise.all(
        rulesToUpsert.map(async (rule) => {
          try {
            await upsertRule({ variables: rule });
            setState((s) => ({ ...s, completeCount: s.completeCount + 1 }));
          } catch (error) {
            console.error('Failed during undo', error);
            setState((s) => ({ ...s, errorCount: s.errorCount + 1 }));
            throw error;
          }
        })
      );
      setState((s) => ({ ...s, isLoading: false }));
      input.onComplete();
    } catch (error) {
      setState((s) => ({ ...s, isLoading: false }));
      input.onError(wrapError(error));
    }
  }, [initiateUndo, input.onComplete, input.onError]);

  return [state, undo];
};
