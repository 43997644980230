import gql from 'graphql-tag';

export const GET_ACTIVITIES_FOR_FILE = gql`
  query GetActivitiesForFile($planningCycleId: Int!, $fileId: String!, $startRow: Int, $endRow: Int) {
    getActivitiesForFile(
      input: { planningCycleId: $planningCycleId, tquuid: $fileId, startRow: $startRow, endRow: $endRow }
    ) {
      territoryActivitiesList
      territoryActivityCount
    }
  }
`;
