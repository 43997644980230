// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kVE2p {\n    padding: var(--m) 0;\n  }\n  .cf5Tz {\n    padding-bottom: var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PickTerritoryDialog_pickTerritoryDialogBody": "kVE2p",
	"PickTerritoryDialog_body": "cf5Tz"
};
export default ___CSS_LOADER_EXPORT___;
