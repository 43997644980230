import React from 'react';

import { Placement, Tooltip2 } from '@blueprintjs/popover2';

import style from './MessageTooltip.module.pcss';

export interface MessageTooltipProps {
  content: string | JSX.Element;
  target: JSX.Element;
  placement: Placement;
  isOpen?: boolean;
  className?: string;
  popoverClassName?: string;
  rootBoundary?: 'document' | 'viewport';
  portalContainer?: HTMLElement;
  disabled?: boolean;
  usePortal?: boolean;
  theme?: 'dark' | 'light';
}

const MessageTooltip: React.FC<MessageTooltipProps> = ({
  content,
  target,
  placement = 'right',
  isOpen,
  className,
  popoverClassName,
  rootBoundary,
  portalContainer,
  disabled,
  usePortal = true,
  theme = 'dark'
}: MessageTooltipProps) => {
  const classNameString = className ? ` ${className}` : '';

  return (
    <Tooltip2
      content={content}
      className={`${style.MessageTooltip}${classNameString}`}
      popoverClassName={popoverClassName ?? ''}
      placement={placement}
      usePortal={usePortal}
      isOpen={isOpen}
      openOnTargetFocus={false}
      rootBoundary={rootBoundary}
      portalContainer={portalContainer}
      autoFocus={false}
      disabled={disabled}
      portalClassName={`${theme}`}
    >
      {target}
    </Tooltip2>
  );
};

export default MessageTooltip;
