import React from 'react';

import { FormattedNumber } from 'react-intl';

import FlagIcon from 'components/FlagIcon/FlagIcon';
import { SearchableSelectMenuItem } from 'components/models';

import { Currency, CurrencyCode, MeasureFormatType } from 'app/models';

import { getCurrencySymbol } from 'utils/helpers/formNumberFormattingUtils';
import { formatCurrency, formatNumber } from 'utils/messages/utils';
interface FormattedCurrencyProps {
  value: number;
  currency: string;
}

export const getCurrenciesMap = (currencies: Currency[]): Record<string, string> => {
  const currenciesMap = {};

  currencies.forEach(({ currencyCode, currencyName }) => (currenciesMap[currencyCode] = currencyName));

  return currenciesMap;
};

export const createCurrencyItem = (
  currencyCode: string,
  currencyName: string,
  flagWidth = 24,
  flagHeight = 24
): SearchableSelectMenuItem => {
  return {
    key: `${currencyCode} - ${currencyName} (${getCurrencySymbol(currencyCode)})`,
    value: currencyCode,
    icon: (
      <FlagIcon countryId={currencyCode} width={flagWidth} height={flagHeight} data-testid={`${currencyCode}-flag`} />
    )
  };
};

export const getCurrencyItem = (
  currencies: Currency[],
  currencyCode: string,
  flagWidth = 24,
  flagHeight = 24
): SearchableSelectMenuItem => {
  if (!currencies?.length) {
    return null;
  }

  const { currencyName } = currencies.find((currency) => currency.currencyCode === currencyCode) || {};

  return currencyName ? createCurrencyItem(currencyCode, currencyName, flagWidth, flagHeight) : null;
};

// sorts currencies so that "used" currencies appear at the beginning of the array
export const getSortedCurrencyItems = (
  currencies: Currency[],
  currenciesInUse?: string[],
  flagWidth = 24,
  flagHeight = 24
): SearchableSelectMenuItem[] => {
  const currencyItems = [];
  const currenciesMap = getCurrenciesMap(currencies);

  // the currenciesInUse array is ordered by the most "used" currencies
  // so fill the array in the same order, since we want the most "used" currencies
  // to appear first in the dropdown menu
  currenciesInUse?.forEach((currencyCode) => {
    const currencyName = currenciesMap[currencyCode];
    const currencyMenuItem = createCurrencyItem(currencyCode, currencyName, flagWidth, flagHeight);

    currencyItems.push(currencyMenuItem);
  });

  // add the remaining currencies in the order provided (alphabetical, by currency code)
  currencies?.forEach((currency) => {
    const { currencyCode, currencyName } = currency;

    // we already added this currency to the beginning of the array!
    if (currenciesInUse?.includes(currencyCode)) {
      return;
    }

    const currencyMenuItem = createCurrencyItem(currencyCode, currencyName, flagWidth, flagHeight);

    currencyItems.push(currencyMenuItem);
  });

  return currencyItems;
};

export const FormattedCurrency: React.FC<FormattedCurrencyProps> = ({ value, currency }: FormattedCurrencyProps) => {
  return (
    <FormattedNumber
      value={value}
      currencyDisplay="symbol"
      style="currency"
      currency={currency}
      minimumFractionDigits={0}
      maximumFractionDigits={0}
    />
  );
};

export const formatNumberByMeasureType = (value: number, measureType: string, currency: string): string => {
  switch (measureType) {
    case MeasureFormatType.PERCENTAGE:
      return formatNumber(value, {
        style: 'percent'
      });
    case MeasureFormatType.CURRENCY:
      return formatNumber(value, { style: 'currency', currency });
    default:
      return formatNumber(value);
  }
};

export const formatAbbreviatedCurrency = (value: number, currency: string): string => {
  if (value < 1000) {
    return formatCurrency(value, currency);
  } else if (value < Math.pow(10, 6)) {
    return `${formatCurrency(value / 1000, currency)}K`;
  } else if (value < Math.pow(10, 9)) {
    return `${formatCurrency(value / Math.pow(10, 6), currency)}M`;
  } else if (value < Math.pow(10, 12)) {
    return `${formatCurrency(value / Math.pow(10, 9), currency)}B`;
  } else {
    return `${formatCurrency(value / Math.pow(10, 12), currency)}T`;
  }
};

export const isDecimalSupportedCurrency = (currencyCode: CurrencyCode): boolean => {
  // this list is not the finalized version, there needs to be an extensive research of which currencies don't support decimals.
  return currencyCode !== CurrencyCode.JPY && currencyCode !== CurrencyCode.KWD && currencyCode !== CurrencyCode.VND;
};
