import * as yup from 'yup';

import { expectedSymonApiOrigin } from 'app/containers/Symon/SymonTypes';

import { formatMessage } from 'utils/messages/utils';

const isExpectedSymonDomain = (value: string, allowCustomSymonDomain: boolean): boolean =>
  allowCustomSymonDomain || value === expectedSymonApiOrigin;

export const validationSchema = (allowCustomSymonDomain: boolean) =>
  yup.object().shape({
    symonDomain: yup
      .string()
      .url(formatMessage('URL_ERROR_MESSAGE_GENERIC'))
      .test('is-expected-symon-domain', formatMessage('SYMON_API_URL_INVALID'), (value) =>
        isExpectedSymonDomain(value, allowCustomSymonDomain)
      )
      .required(formatMessage('SYMON_DOMAIN_REQUIRED')),
    symonUiClientKey: yup.string().required(formatMessage('SYSTEM_API_KEY_REQUIRED'))
  });
