// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sjO2P {\n  color: rgb(var(--color-dark-gray-3));\n}\n\n  .K-lYl {\n    font-weight: normal;\n    width: 100%;\n  }\n\n  .P1aW3 {\n      color: rgb(var(--color-red-3));\n    }\n\n  .P1aW3 input {\n        border: 1px solid rgb(var(--color-red-3));\n      }\n\n  .sjO2P .bp3-form-group {\n    margin: 0;\n  }\n\n  .sjO2P .bp3-input {\n    height: 35px;\n  }\n\n  .sjO2P label.bp3-label {\n    font-size: 14px !important;\n  }\n\n  .nNeV- {\n    height: 20px;\n    color: rgb(var(--color-red-3));\n  }\n\n  ._8hcrC {\n    width: 400px;\n    padding: var(--dialog-row-padding) 0;\n    height: 450px;\n  }\n\n  .yG9SP {\n    padding: 5px 0;\n    display: inline-block;\n  }\n\n  ._2QWC0 {\n    font-style: italic;\n    margin-left: 5px;\n  }\n\n  .oQsAM {\n    margin: 5px 0 0 10px;\n  }\n\n  .oQsAM:hover > svg {\n      color: rgb(var(--color-dark-gray-3));\n      cursor: pointer;\n    }\n\n  .-\\+Zqi {\n    color: rgb(var(--color-light-gray-1));\n  }\n\n  .-\\+Zqi:hover {\n    cursor: not-allowed;\n  }\n\n  ._70zwv {\n    color: rgb(var(--color-cobalt-3));\n    font-size: 14px;\n    margin-left: 10px;\n  }\n\n  .sjO2P ::-moz-placeholder {\n    color: rgb(var(--color-light-gray-3));\n    padding-left: 5px;\n  }\n\n  .sjO2P ::placeholder {\n    color: rgb(var(--color-light-gray-3));\n    padding-left: 5px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CustomMeasureDialog": "sjO2P",
	"CustomMeasureDialog_label": "K-lYl",
	"CustomMeasureDialog_label__error": "P1aW3",
	"CustomMeasureDialog_validationMessage": "nNeV-",
	"CustomMeasureDialog_container": "_8hcrC",
	"CustomMeasureDialog_labelText": "yG9SP",
	"CustomMeasureDialog_subLabel": "_2QWC0",
	"CustomMeasureDialog_deleteButton": "oQsAM",
	"CustomMeasureDialog_deleteButton__disabled": "-+Zqi",
	"CustomMeasureDialog_addColumnText": "_70zwv"
};
export default ___CSS_LOADER_EXPORT___;
