import React from 'react';

import { QueryResult } from '@apollo/client';

import Tab from 'components/Tab/Tab';
import Tabs from 'components/Tabs/Tabs';

import RolePermissionsPanel from 'app/components/UserManagementPanel/RolePermissionPanel/RolePermissionsPanel';
import UserManagementPanel from 'app/components/UserManagementPanel/UserManagementPanel';

import { useData } from 'app/contexts/dataProvider';

import { GetMemberCount, GetMemberCountVariables } from 'app/graphql/generated/graphqlApolloTypes';

import { UserManagementTabNames } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UserManagementOverview.module.pcss';

const b = block(style);

interface UserManagementOverviewProps {
  getMemberCount: () => Promise<QueryResult<GetMemberCount, GetMemberCountVariables>>;
}

const UserManagementOverview: React.FC<UserManagementOverviewProps> = ({
  getMemberCount
}: UserManagementOverviewProps) => {
  const { selectedUserTab, setSelectedUserTab } = useData();

  return (
    <div className={b()}>
      <Tabs
        id="user-management-overview"
        large
        animate
        renderActiveTabPanelOnly
        className={b('tabs')}
        selectedTabId={selectedUserTab}
        onChange={(navBarId: UserManagementTabNames) => setSelectedUserTab(navBarId)}
      >
        <Tab
          id={UserManagementTabNames.USERS}
          title={formatMessage('USERS')}
          panel={<UserManagementPanel getMemberCount={getMemberCount} />}
        />

        <Tab
          id={UserManagementTabNames.ROLE_PERMISSIONS}
          title={formatMessage('ROLE_PERMISSIONS')}
          panel={<RolePermissionsPanel />}
        />
      </Tabs>
    </div>
  );
};

export default UserManagementOverview;
