import gql from 'graphql-tag';

export const CREATE_TERRITORY_GROUP = gql`
  mutation CreateTerritoryGroup(
    $battlecardId: Int!
    $hierarchyId: Int
    $precedence: Int!
    $territoryGroupName: String!
    $territoryGroupParentId: Int!
  ) {
    createTerritoryGroup(
      territoryGroup: {
        battlecardId: $battlecardId
        hierarchyId: $hierarchyId
        precedence: $precedence
        territoryGroupName: $territoryGroupName
        territoryGroupParentId: $territoryGroupParentId
      }
    ) {
      territoryGroupId
    }
  }
`;
