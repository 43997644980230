import React, { FC, useMemo, useRef } from 'react';

import { Button, CalloutV2, Intent } from '@varicent/components';

import { BasicSelect, BasicMultiSelect } from 'components/BasicSelect/BasicSelects';
import DropdownMenuDivider from 'components/DropdownMenu/DropdownMenuDivider';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { CollectionFilter, CollectionFilterKind, MchQuantity, NamedHierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CustomHierarchyFilterMenu.module.pcss';

const b = block(style);

export const CustomHierarchyFilterMenu: FC<{
  hierarchies: NamedHierarchy[];
  filter: CollectionFilter<number>;
}> = ({ hierarchies: rawHierarchies, filter: remoteFilter }) => {
  const hierarchyItems = useMemo(
    () => rawHierarchies.map((hierarchy) => ({ text: hierarchy.name, value: hierarchy.hierarchyId })),
    [rawHierarchies]
  );

  const { chosenCustomHierarchy } = useDedicatedMapProvider();
  const titleMessage =
    chosenCustomHierarchy.quantity === MchQuantity.SINGULAR
      ? formatMessage('CUSTOM_HIERARCHY_FILTER_DYNAMIC_TITLE', {
          hierarchyName: chosenCustomHierarchy.details.rootName
        })
      : formatMessage('CUSTOM_HIERARCHY_FILTER_TITLE');

  const postMessage = useMapWorkerPostMessage();
  const updateFilter = (patch: Partial<CollectionFilter<number>>) => {
    postMessage({ type: 'custom-hierarchy-filter-change', filter: { ...remoteFilter, ...patch } });
  };

  const containerRef = useRef();
  return (
    <section data-testid="custom-hierarchy-filter-menu" className={b('filter')} ref={containerRef}>
      <div>{titleMessage}</div>
      <form className={b('form')} onSubmit={(e) => e.preventDefault()} data-testid="custom-hierarchy-filter-form">
        <BasicSelect<CollectionFilterKind>
          testId="custom-filter-kind"
          items={kindItems}
          value={remoteFilter.kind}
          minimal
          placement="bottom-start"
          containerElement={containerRef.current}
          label={formatMessage('CUSTOM_HIERARCHY_FILTER_KIND_LABEL')}
          onSelectedValueChange={(kind) => updateFilter({ kind })}
        />
        <hr className={b('fieldSeparator')} />
        <BasicMultiSelect<number>
          testId="custom-filter-ids"
          items={hierarchyItems}
          onValuesChange={(ids) => updateFilter({ ids })}
          values={remoteFilter.ids}
          minimal
          placement="bottom-start"
          className={b('hierarchyMultiSelect')}
          label={formatMessage('CUSTOM_HIERARCHY_FILTER_IDS_LABEL')}
          matchTargetWidth
          containerElement={containerRef.current}
          placeholder={formatMessage(
            hierarchyItems.length === 0 ? 'CUSTOM_HIERARCHY_FILTER_IDS_EMPTY_PLACEHOLDER' : 'SELECT_A_HIERARCHY'
          )}
        />
      </form>
      {remoteFilter.kind !== CollectionFilterKind.EQUALS && (
        <div className={b('filterCallout')}>
          <CalloutV2 intent={Intent.PRIMARY} hideActionText={true} data-testid="filter-callout">
            {formatMessage('MAP_CUSTOM_HIERARCHY_FILTER_CALLOUT_MESSAGE')}
          </CalloutV2>
          <DropdownMenuDivider />
        </div>
      )}
      <div className={b('clearFilter')}>
        <Button
          minimal
          text={formatMessage('CLEAR_FILTERS')}
          onClick={() => updateFilter({ ids: [] })}
          data-testid="clear-filter-button"
        />
      </div>
    </section>
  );
};

const kindItems = [
  { value: CollectionFilterKind.EQUALS, text: formatMessage('COLLECTION_FILTER_EQUALS_TO') },
  { value: CollectionFilterKind.NOT_EQUALS, text: formatMessage('COLLECTION_FILTER_NOT_EQUALS_TO') },
  { value: CollectionFilterKind.CONTAINS_ANY, text: formatMessage('COLLECTION_FILTER_CONTAINS_ANY') },
  { value: CollectionFilterKind.NOT_CONTAINS_ANY, text: formatMessage('COLLECTION_FILTER_NOT_CONTAINS_ANY') }
];
