import React from 'react';

import { Account, ChevronRight, EarthFilled, Tools } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import { MenuItem } from 'components/menu/MenuItem';

import { GetRootHierarchies } from 'app/graphql/generated/graphqlApolloTypes';

import { CommandCenterMenuItem, HierarchySpec, HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommandCenterMenu.module.pcss';

const b = block(style);

const HierarchyIcon = {
  CustomHierarchy: <Tools size={24} />,
  CustomerAccountHierarchy: <Account size={24} />,
  GeographicTerritoryHierarchy: <EarthFilled size={24} />
};

interface HierarchyPanelItemsProps {
  data?: GetRootHierarchies;
  activeMenu: CommandCenterMenuItem | unknown;
  changeActiveMenuItem: ({ key, hierarchy }: { key: string; hierarchy: HierarchySpec }) => void;
}

export const CommandCenterHierarchyItems = ({
  data,
  activeMenu,
  changeActiveMenuItem
}: HierarchyPanelItemsProps): React.ReactElement => {
  if (!data?.getRootHierarchies) return <div data-testid="command-center-hierarchy-items-none" />;
  return (
    <>
      {data.getRootHierarchies?.map((menuItem, index) => {
        const active = activeMenu === menuItem.rootKey;
        return (
          <MenuItem
            className={b('hierarchyItem', { active })}
            icon={HierarchyIcon[menuItem.hierarchyType]}
            key={`${menuItem.rootKey}-${index}`}
            data-testid={`cc-hierarchy-item-${menuItem.rootKey}-${index}`}
            onClick={() => {
              changeActiveMenuItem({ key: menuItem.rootKey, hierarchy: menuItem });
            }}
            target={
              menuItem.hierarchyType === HierarchyType.CustomerAccountHierarchy
                ? CommandCenterMenuItem.CUSTOMER_ACCOUNT
                : 'default'
            }
            text={
              <div className={b('menuItemTextContainer')}>
                <div>
                  <div className={b('menuItemText')} data-testid={`menu-item-text-${menuItem.rootKey}`}>
                    <HTMLHeading tagLevel="h6" text={menuItem.rootName} />
                  </div>
                  <div className={b('menuItemSubtext')} data-testid={`menu-item-subtext-${menuItem.rootKey}`}>
                    {formatMessage('AVAILABLE_WITH_COUNT', { count: menuItem.numOfMembers })}
                  </div>
                </div>
                <div>
                  <ChevronRight />
                </div>
              </div>
            }
          />
        );
      })}
    </>
  );
};
