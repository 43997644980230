import React, { FC, useEffect, useState } from 'react';

import block from 'utils/bem-css-modules';

import style from './DragHandle.module.pcss';

const b = block(style);

export const DragHandle: FC<{
  height: number;
  invert: boolean;
  onResize: (height: number) => void;
}> = ({ height, invert, onResize }) => {
  const [dragState, setDragState] = useState<{
    originalClientY: number;
    originalHeight: number;
  } | null>(null);

  useEffect(() => {
    const handlePointerUp = (event: PointerEvent) => {
      if (event.isPrimary) setDragState(null);
    };
    const handleBlur = () => setDragState(null);
    window.addEventListener('pointerup', handlePointerUp);
    window.addEventListener('blur', handleBlur);
    return () => {
      window.removeEventListener('pointerup', handlePointerUp);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    if (!dragState)
      return () => {
        // No cleanup
      };

    const handlePointerMove = (event: PointerEvent) => {
      if (!event.isPrimary) return;
      if (event.buttons !== 1) return;

      const polarity = invert ? -1 : 1;
      const desiredHeight = dragState.originalHeight + polarity * (event.clientY - dragState.originalClientY);
      onResize(desiredHeight);
    };
    window.addEventListener('pointermove', handlePointerMove);
    return () => window.removeEventListener('pointermove', handlePointerMove);
  }, [dragState, invert]);

  return (
    <div
      data-testid="drag-handle"
      className={b({ isDragging: dragState !== null })}
      onPointerDown={({ clientY, isPrimary }) => {
        if (!isPrimary) return;
        setDragState({
          originalHeight: height,
          originalClientY: clientY
        });
      }}
    />
  );
};
