import React from 'react';

import { ArrowRight } from '@carbon/icons-react';
import { DividerV2, HTMLHeading, Select } from '@varicent/components';
import { ErrorMessage, Field, useFormikContext } from 'formik';

import TextButton from 'components/Buttons/TextButton/TextButton';

import FormRadioGroup from 'app/components/FormFields/FormRadioGroup/FormRadioGroup';
import { TerritoryOptimizationFormValues } from 'app/components/TerritoryOptimization/TerritoryOptimizationStepper/TerritoryOptimizationStepper';

import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { TerritoryRuleBase } from 'app/graphql/generated/graphqlApolloTypes';

import { TerritoryOptimizationStep } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ParametersAndTerritoryRules.module.pcss';

const b = block(style);

interface ParametersAndTerritoryRulesProps {
  handleNext: (completedStep: TerritoryOptimizationStep, nextStep: TerritoryOptimizationStep) => void;
}

const radioOptions = [{ value: TerritoryRuleBase.geographic, label: formatMessage('GEOGRAPHIC_BASED_RULES') }];

const ParametersAndTerritoryRules: React.FC<ParametersAndTerritoryRulesProps> = ({
  handleNext
}: ParametersAndTerritoryRulesProps) => {
  const { setTerritoryOptimizationDrawerState, numberOfTerritories, metricDropdownItems } = useTerritoryOptimization();

  const { values, setFieldValue, setFieldTouched, errors } = useFormikContext<TerritoryOptimizationFormValues>();

  return (
    <div className={b()} data-testid="parameters-and-territory-rules">
      <div>
        <HTMLHeading bold tagLevel="h5" text="Parameters" />
        <div className={b('section')}>
          <div className={b('textContainer')}>
            <span className={b('subText')}>{formatMessage('NUMBER_OF_TERRITORIES')}</span>
            <span className={b('description')} data-testid="parameters-number-of-territories">
              {numberOfTerritories}
            </span>
          </div>
        </div>
        <div className={b('section')}>
          <div className={b('textContainer')}>
            <span className={b('subText')}>{formatMessage('METRIC_OPTIMIZATION_SELECT_LABEL')}</span>
            <div className={b('dropdown')}>
              <Field
                name="metric"
                theme="default"
                component={Select}
                placeholder={formatMessage('SELECT')}
                items={metricDropdownItems}
                onSelectedItemChange={(item) => {
                  setFieldValue('metric', item);
                }}
                selectedItem={values.metric}
                getItemId={(item) => {
                  return item.value;
                }}
                getItemText={(item) => {
                  return item.key;
                }}
                label="metric"
              />
            </div>
            <ErrorMessage name="metric" component="div" className={b('validationMessage')} />
          </div>
        </div>
        {/* Commented out as requested, we do not need this for now but probably later. */}
        {/* <div className={b('section')}>
          <div className={b('textContainer')}>
            <span className={b('subText')}>{RUN_TIME_SELECT_LABEL}</span>
            <div className={b('dropdown')}>
              <Field
                name="runTime"
                theme="default"
                component={SelectMenu}
                placeHolderText={SELECT}
                items={runTime}
                shouldValidateOnTouch={false}
              />
            </div>
          </div>
        </div> */}
        <div className={b('divider')}>
          <DividerV2 />
        </div>
        <HTMLHeading bold tagLevel="h5" text={formatMessage('TERRITORY_RULES')} />
        <div className={b('section')}>
          <div className={b('textContainer')}>
            <FormRadioGroup
              name="territoryDefinition"
              selectedRadio={values.territoryDefinition}
              label={formatMessage('TERRITORY_DEFINITION_RADIO_LABEL')}
              radioOptions={radioOptions}
              inline
              setSelectedRadio={(option: string) => setFieldValue('territoryDefinition', option)}
            />
          </div>
        </div>
      </div>
      <div className={b('buttonContainer')}>
        <TextButton
          text={formatMessage('CONSTRAINTS')}
          type={'button'}
          testId={'parameters-next-button'}
          intent={'primary'}
          large={false}
          rightIcon={<ArrowRight />}
          onClick={() => {
            setFieldTouched('metric', true, true);
            if (!errors?.metric && values.metric) {
              setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.CONSTRAINTS);
              handleNext(TerritoryOptimizationStep.PARAMETERS, TerritoryOptimizationStep.CONSTRAINTS);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ParametersAndTerritoryRules;
