import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

import block from 'utils/bem-css-modules';
import { formatNumber } from 'utils/messages/utils';

import style from './PercentCellRenderer.module.pcss';

const b = block(style);

interface PercentCellRendererProps extends ICellRendererParams {
  highColour: string;
  medHighColour: string;
  medColour: string;
  lowColour: string;
  total: number;
  columnName: string;
  /** for this component to give the inverted text experience, the width which is being passed needs to be the column actual width minus all the extra paddings or margins */
  columnWidth: number;
}

const getColourForRange = (
  progress: number,
  highColour: string,
  medHighColour: string,
  medColour: string,
  lowColour: string
) => {
  if (progress === 100) {
    return highColour;
  } else if (progress < 100 && progress >= 70) {
    return medHighColour;
  } else if (progress < 70 && progress >= 30) {
    return medColour;
  } else if (progress < 30) {
    return lowColour;
  } else {
    return lowColour;
  }
};

const PercentCellRenderer: React.FC<PercentCellRendererProps> = ({
  data,
  highColour,
  medHighColour,
  medColour,
  lowColour,
  columnName,
  columnWidth
}: PercentCellRendererProps) => {
  if (!data) {
    return <div data-testid="no-data" />;
  }

  const value = data[columnName]?.value;
  const progressFraction = Math.min(1, Math.max(0, value || 0));
  const progressPercentage = progressFraction * 100;
  const progressText = formatNumber(progressFraction, { style: 'percent', maximumFractionDigits: 3 });

  return (
    <div className={b()} data-testid="percent-cell-renderer">
      <div
        style={{
          width: `${100 - progressPercentage}%`
        }}
        className={b('bar', { notProgressed: true })}
      >
        <span
          className={b('bar', { text: true })}
          style={{ width: columnWidth }}
          data-testid="percent-cell-renderer-text-notProgressed"
        >
          {progressText}
        </span>
      </div>
      <div
        style={{
          width: `${progressPercentage}%`,
          backgroundColor: getColourForRange(progressPercentage, highColour, medHighColour, medColour, lowColour)
        }}
        className={b('bar', { progressed: true })}
      >
        <span
          className={b('bar', { text: true })}
          style={{ width: columnWidth }}
          data-testid="percent-cell-renderer-text-progressed"
        >
          {progressText}
        </span>
      </div>
    </div>
  );
};

export default PercentCellRenderer;
