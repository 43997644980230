// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hp-12 {\n  height: 100%;\n  margin-top: var(--s);\n}\n  .-Kko8 {\n    height: 100%;\n  }\n  ._0e5IJ {\n    height: 100%;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n            user-select: none;\n    margin-top: 0 !important;\n  }\n  .P-CXq {\n      display: none;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryOptimizationPreview": "hp-12",
	"TerritoryOptimizationPreview_tabs": "-Kko8",
	"TerritoryOptimizationPreview_tab": "_0e5IJ",
	"TerritoryOptimizationPreview_tab__hidden": "P-CXq"
};
export default ___CSS_LOADER_EXPORT___;
