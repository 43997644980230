import React, { useEffect, useState } from 'react';

import { Field, useFormikContext } from 'formik';

import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SymonConfigurationSelectMenu.module.pcss';

const b = block(style);

interface SymonConfigurationSelectMenuProps {
  configurationItems: Record<string, string>[];
}

const SymonConfigurationSelectMenu: React.FC<SymonConfigurationSelectMenuProps> = ({ configurationItems }) => {
  const [items, setItems] = useState(configurationItems);

  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    setItems(configurationItems);
  }, [configurationItems]);

  const onSearchConfiguration = async (searchString) => {
    const filteredConfigurationItem = items.filter(({ key }) => {
      return key.toLowerCase().includes(searchString.toLowerCase());
    });
    setItems(filteredConfigurationItem);
  };

  const resetSearch = () => {
    setItems(configurationItems);
  };

  return (
    <div className={b()} data-testid="symon-configuration-select-menu-container">
      <label className={b('symonInputTextLabelWrapper')} htmlFor="symonAiSource">
        <span className={b('label')}>{formatMessage('SYMON_AI_SOURCE_AND_EXPORT_NODE')}</span>
      </label>
      <Field
        component={SearchableSelectMenu}
        name="configurationItem"
        placeHolderText={formatMessage('SELECT_AN_EXISTING_CONFIGURATION')}
        showErrors
        showIconInField={false}
        initialLoadingComplete={!!items}
        items={items}
        theme="default"
        onSelectItem={async (selectedNode) => {
          setFieldValue('configurationId', selectedNode?.selectedItem?.value?.symonPipeConfigurationId);
          setFieldValue('destinationTable', selectedNode?.selectedItem?.value?.tableNameLabel);
          setFieldValue('symonExportNode', {
            key: selectedNode?.selectedItem?.value?.exportName,
            value: selectedNode?.selectedItem?.value?.exportId
          });
          setFieldValue('symonPipeName', {
            key: selectedNode?.selectedItem?.value?.pipeName,
            value: selectedNode?.selectedItem?.value?.pipeId
          });
        }}
        onSearch={onSearchConfiguration}
        onSearchReset={resetSearch}
      />
    </div>
  );
};

export default SymonConfigurationSelectMenu;
