// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xdO7Z {\n  margin-top: var(--data-panel-margin);\n}\n\n  .Oxa0K {\n    padding: 0 var(--data-panel-padding);\n  }\n\n  .om3vc {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    margin-bottom: var(--data-panel-margin);\n    grid-gap: 10px;\n  }\n\n  ._5sAsi {\n    display: flex;\n    align-items: center;\n  }\n\n  ._7r1XE {\n    display: flex;\n    align-items: center;\n    border-left: 1px solid rgb(var(--color-light-gray-3));\n    padding-left: var(--data-panel-padding);\n    margin-left: var(--data-panel-margin);\n    margin-right: var(--data-panel-margin);\n  }\n\n  .yIX1F {\n    margin-right: var(--data-panel-medium-margin);\n  }\n\n  .Lpbbv {\n    font-size: 16px;\n    font-weight: 600;\n    display: flex;\n    padding: 0 0 20px;\n  }\n\n  .HDPTM {\n    font-weight: bold;\n    margin-left: var(--data-panel-medium-margin);\n  }\n\n  ._4lkuy {\n    font-size: 14px;\n    margin-bottom: 15px;\n  }\n\n  .J4zuC {\n    font-size: 12px;\n    margin-top: 20px;\n  }\n\n  .RWrFb {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .VzvBk {\n    margin-left: var(--data-panel-medium-margin);\n  }\n\n  .XBfpA .bp3-button:not([class*='bp3-intent-']), .XBfpA .bp3-button:not([class*='bp3-intent-']):hover {\n      color: rgb(var(--color-light-gray-1));\n      background: rgb(var(--color-light-gray-4));\n      cursor: not-allowed;\n      box-shadow: inset 0 0 0 1px rgb(var(--color-light-gray-3));\n    }\n\n  .VixLe {\n    display: none;\n  }\n\n  .yby3j {\n    display: flex;\n    align-items: center;\n    max-width: 900px;\n    margin-bottom: 10px;\n  }\n\n  .gjrBF {\n    display: flex;\n    align-items: center;\n  }\n\n  .IEWjB {\n    padding-left: 15px;\n    margin: 0 5px 0 10px;\n    border-left: 1px solid rgb(var(--color-light-gray-3));\n  }\n\n  ._00nvs {\n    min-width: 110px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DataMappingController": "xdO7Z",
	"DataMappingController__withSpacing": "Oxa0K",
	"DataMappingController_dataMappingControllerToolbar": "om3vc",
	"DataMappingController_dataMappingControllerRightActionItems": "_5sAsi",
	"DataMappingController_actionTextGroup": "_7r1XE",
	"DataMappingController_actionText": "yIX1F",
	"DataMappingController_fileType": "Lpbbv",
	"DataMappingController_fileTypeText": "HDPTM",
	"DataMappingController_matchFieldsText": "_4lkuy",
	"DataMappingController_dataMappingText": "J4zuC",
	"DataMappingController_missingFieldContent": "RWrFb",
	"DataMappingController_button": "VzvBk",
	"DataMappingController_button__disabled": "XBfpA",
	"DataMappingController_hiddenItem": "VixLe",
	"DataMappingController_calloutDanger": "yby3j",
	"DataMappingController_flexRow": "gjrBF",
	"DataMappingController_saveButton": "IEWjB",
	"DataMappingController_autoMapButtonContainer": "_00nvs"
};
export default ___CSS_LOADER_EXPORT___;
