import { useEffect } from 'react';

import { LoadStatus } from 'app/containers/App/EmbeddedAuth/EmbedderTypes';

import { TokenState } from 'app/models';

import { useSendEmbedderMessage } from './useSendEmbedderMessage';
import { useShouldUseEmbeddedAuth } from './useShouldUseEmbeddedAuth';

export const useSendLoadStatus = ({ token, isLoading }: TokenState): void => {
  const shouldUseEmbeddedAuth = useShouldUseEmbeddedAuth();
  const sendEmbedderMessage = useSendEmbedderMessage();
  const hasToken = !!token;

  useEffect(() => {
    if (!shouldUseEmbeddedAuth) return;

    let status: LoadStatus;
    if (isLoading) {
      status = 'loading';
    } else {
      status = hasToken ? 'success' : 'error';
    }
    sendEmbedderMessage({ type: 'load-status', payload: { status } });
  }, [isLoading, hasToken, shouldUseEmbeddedAuth]);
};
