import React from 'react';

import { PrimaryHierarchyTypeForMap } from 'app/workers/MapWorker/MapWorkerProtocol';

import {
  AccountSourceFilterEnum,
  GetMapVisualizationSettings_getDeploymentModelSpec_battlecards_mapVisualizationSettings
} from 'app/graphql/generated/graphqlApolloTypes';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { JobStatus, MapContextBattleCard, MapCustomHierarchySetting } from 'app/models';

export interface MapContextRedistributorContextValues {
  selectedBattleCardId: string | null;
  selectedQuotaComponentId: number | null;
  selectedTerritoryGroupTypeId: number | null;
  selectedTerritoryGroupId: number | null;
  coinsortStatus: JobStatus | null;
  isMapPointerDisabled: boolean;
  selectedBattleCard: MapContextBattleCard;
  isEmbedded: boolean;
  selectedDeploymentModelId: number;
  isBattlecardDataLoading: boolean;
  externalControlSuffix: '' | 'Secondary';
  primaryHierarchy: PrimaryHierarchyTypeForMap;
  savedCustomHierarchyPreference: MapCustomHierarchySetting;
  mapVisualizationSettings: GetMapVisualizationSettings_getDeploymentModelSpec_battlecards_mapVisualizationSettings;
  accountSourceFilter: AccountSourceFilterEnum;
  isMapVisualizationSettingsLoading: boolean;
}

export const MapContextRedistributorContext = React.createContext<MapContextRedistributorContextValues | null>(null);
MapContextRedistributorContext.displayName = 'MapContextRedistributor';

export const MapContextRedistributorProvider = MapContextRedistributorContext.Provider;

// Custom hook to read these values from
export const useMapContextRedistributor = (): MapContextRedistributorContextValues =>
  useContextSafe(MapContextRedistributorContext);
