import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetSymonPipeConfigurations,
  GetSymonPipeConfigurationsVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_SYMON_PIPE_CONFIGURATIONS = gql`
  query GetSymonPipeConfigurations($planningCycleId: Int!) {
    getSymonPipeConfigurations(input: { planningCycleId: $planningCycleId }) {
      exportId
      exportName
      fileType
      pipeId
      pipeName
      runId
      symonPipeConfigurationId
      status
      endAt
      tableNameLabel
      hierarchyId
    }
  }
`;

export const useGetSymonPipeConfigurationsLazy = createUseLazyQuery<
  GetSymonPipeConfigurations,
  GetSymonPipeConfigurationsVariables
>(GET_SYMON_PIPE_CONFIGURATIONS);
