import React from 'react';

import { Play } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { useCoinsortButton } from 'app/hooks/useCoinsortButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CoinsortButtons.module.pcss';

const b = block(style);

interface ComparisonModeCoinsortButtonProps {
  selectedBattleCardId: string;
  selectedQuotaComponentId: number;
  selectedDeploymentModelId: number;
}

const ComparisonModeCoinsortButton: React.FC<ComparisonModeCoinsortButtonProps> = ({
  selectedBattleCardId,
  selectedQuotaComponentId,
  selectedDeploymentModelId
}: ComparisonModeCoinsortButtonProps) => {
  const {
    coinsortEnabled,
    isCoinsortLoading,
    isCoinsortRunning,
    isButtonLoading,
    onClickCancelCoinsort,
    onClickRunCoinsort
  } = useCoinsortButton(selectedBattleCardId, selectedQuotaComponentId, selectedDeploymentModelId);

  const handleClick = async () => {
    if (isCoinsortRunning) {
      await onClickCancelCoinsort();
    } else {
      await onClickRunCoinsort();
    }
  };

  return (
    <span className={b()}>
      <TextButton
        testId={'coinsort-action-button'}
        type="button"
        icon={<Play />}
        text={isCoinsortRunning ? formatMessage('CANCEL') : formatMessage('RUN_COIN_SORT')}
        onClick={handleClick}
        isActiveWhileLoading={isCoinsortRunning && !isCoinsortLoading}
        loading={isButtonLoading}
        disabled={isCoinsortLoading || !coinsortEnabled}
      />
    </span>
  );
};

export default ComparisonModeCoinsortButton;
