/* The list is copied from the varicent design system condition builder example:
  https://design-system-storybook.spmdev.varicent.com/?path=/story/condition-builder--playground
*/
export const constraintsOperators = {
  string: [
    {
      id: 'isEqualTo',
      name: 'is equal to'
    },
    {
      id: 'isNotEqualTo',
      name: 'is not equal to'
    },
    {
      id: 'startsWith',
      name: 'starts with'
    }
  ],
  number: [
    {
      id: 'isEqualTo',
      name: 'is equal to'
    },
    {
      id: 'isGreaterThanOrEqualTo',
      name: 'is greater than or equal to'
    },
    {
      id: 'isLessThanOrEqualTo',
      name: 'is less than or equal to'
    }
  ],
  date: [
    {
      id: 'isEqualTo',
      name: 'is equal to'
    },
    {
      id: 'isNotEqualTo',
      name: 'is not equal to'
    },
    {
      id: 'isBefore',
      name: 'is before'
    },
    {
      id: 'isAfter',
      name: 'is after'
    },
    {
      id: 'isOnOrBefore',
      name: 'is on or before'
    },
    {
      id: 'isOnOrAfter',
      name: 'is on or after'
    }
  ]
};
