import React, { useState, useEffect, useRef } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { Redirect } from 'react-router-dom';

import AccountAlignmentUploadDialog from 'app/components/AccountAlignmentUpload/AccountAlignmentUploadDialog';
import {
  createLookupMap,
  getBattleCardCanvas,
  mapChildren,
  checkForInvalidCurrencyConversion,
  getAllCurrenciesInUse
} from 'app/components/BattleCardDiagram/Canvas/BattleCardCanvas/battleCardCanvasUtils';
import BattleCardTree from 'app/components/BattleCardDiagram/Canvas/BattleCardTree/BattleCardTree';
import BattleCardLoading from 'app/components/BattleCardDiagram/Cards/BattleCardLoading/BattleCardLoading';
import BattleCardDesignerDialog from 'app/components/BattleCardDiagram/Designer/BattleCardDesignerDialog/BattleCardDesignerDialog';
import CommandCenterDrawer from 'app/components/CommandCenter/CommandCenterDrawer/CommandCenterDrawer';
import HierarchyCreationDialog from 'app/components/HierarchyCreationDialog/HierarchyCreationDialog';
import HierarchyEditDialog from 'app/components/HierarchyEditDialog/HierarchyEditDialog';
import HierarchyUploadDialog from 'app/components/HierarchyUploadDialog/HierarchyUploadDialog';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';
import ErrorPage, { ErrorType } from 'app/containers/App/ErrorPage/ErrorPage';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { usePermissions } from 'app/contexts/permissionsProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';

import { SplitFeatures } from 'app/global/features';

import {
  GetRootHierarchies_getRootHierarchies,
  GetSheetMeasures_getDeploymentModelSpec
} from 'app/graphql/generated/graphqlApolloTypes';
import { useMarkScenariosOpen } from 'app/graphql/mutations/markScenariosOpen';
import { GET_SHEET_MEASURES } from 'app/graphql/queries/getSheetMeasures';

import useCanUser from 'app/hooks/useCanUser';
import useMakePlanningPath from 'app/hooks/useMakePlanningPath';
import usePhase from 'app/hooks/usePhase';
import useTreatment from 'app/hooks/useTreatment';

import { CommandCenterDrawerState, DeploymentModelPhase } from 'app/models';

import block from 'utils/bem-css-modules';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './BattleCardCanvas.module.pcss';
import BattleCardCanvasControl from './BattleCardCanvasControl/BattleCardCanvasControl';

const b = block(style);

const BattleCardCanvas: React.FC = () => {
  const {
    selectedBattleCardId,
    expandedBattleCardId,
    setBattleCardLookupMap,
    shouldRefetchBattleCardDataOnNextRender,
    setShouldRefetchBattleCardDataOnNextRender,
    shouldRefetchBattleCardDataImmediately,
    setShouldRefetchBattleCardDataImmediately,
    selectedQuotaComponentId,
    setIsBattleCardDataLoading,
    setSelectedBattleCardMeasures
  } = useBattleCard();
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();
  const { setTerritoryRuleId, setTerritoryGroupId, trayHeight } = useDataTray();
  const { showEditHierarchyDialog, showAddHierarchyDialog, showUploadHierarchyDialog } = useFileUpload();
  const { isLocalCurrencyMode, defaultReportingCurrency, setDoesInvalidCurrencyConversionExist, setCurrenciesInUse } =
    useLocalization();
  const makePlanningPath = useMakePlanningPath();
  const { commandCenterDrawerState, setCommandCenterDrawerState } = useCommandCenter();
  const [showAddBattleCardDialog, setShowAddBattleCardDialog] = useState(false);
  const deploymentModelPhase = usePhase();
  const { loadingPermissions } = usePermissions();
  const canViewPlan = useCanUser(UserAction.PLAN_VIEW);
  const canViewManage = useCanUser(UserAction.MANAGE_VIEW);
  const [isNewAccountRoutingOn] = useTreatment(SplitFeatures.NEW_ACCOUNT_ROUTING_MANAGE);

  const [hierarchyToEdit, setHierarchyToEdit] = useState<GetRootHierarchies_getRootHierarchies | null>(null);

  // Keep track of parent card id so that we know where to add the child card
  const [parentBattleCardId, setParentBattleCardId] = useState<string | null>(null);
  const [editingBattleCardId, setEditingBattleCardId] = useState<string | null>(null);

  const [showUploadAlignmentDialog, setShowUploadAlignmentDialog] = useState(false);

  const [hasInternalError, setHasInternalError] = useState(false);

  const [rootCard, setRootCard] = useState(null);

  const getSheetMeasures = async (battlecardId: number) => {
    if (!selectedDeploymentModelId || !selectedQuotaComponentId) {
      return;
    }

    const sheetMeasures: ApolloQueryResult<{
      getDeploymentModelSpec: GetSheetMeasures_getDeploymentModelSpec;
      // eslint-disable-next-line no-restricted-syntax
    }> = await apolloClient.query({
      query: GET_SHEET_MEASURES,
      variables: {
        deploymentModelId: selectedDeploymentModelId,
        quotaComponentId: selectedQuotaComponentId,
        battlecardId
      },
      fetchPolicy: 'network-only'
    });

    const measures = sheetMeasures?.data?.getDeploymentModelSpec?.sheetMeasures;
    setSelectedBattleCardMeasures(measures);
  };

  const canvasBackgroundRef = useRef(null);

  const isBattleCardExpanded = !!expandedBattleCardId;

  const [cachedPanelCollapseStateByCard, setCachedPanelCollapseStateByCard] = useState<
    Record<string, Record<string, boolean>>
  >({});

  const [markScenariosOpen] = useMarkScenariosOpen();

  useEffect(() => {
    if (selectedDeploymentModelId) {
      markScenariosOpen({
        variables: {
          deploymentModelIds: [selectedDeploymentModelId]
        }
      });
    }
  }, [selectedDeploymentModelId]);

  // load battle card data on the first render or when planning cycle / deployment model data changes
  useEffect(() => {
    if (shouldRefetchBattleCardDataOnNextRender) {
      setShouldRefetchBattleCardDataOnNextRender(false);
    }

    if (selectedPlanningCycle && selectedDeploymentModelId && deploymentModelPhase) {
      getBattleCardData();
    }

    if (commandCenterDrawerState === CommandCenterDrawerState.OPEN) {
      setCommandCenterDrawerState(CommandCenterDrawerState.CLOSE);
    }
  }, [selectedPlanningCycle, selectedDeploymentModelId, deploymentModelPhase]);

  // whenever a battlecard is selected,
  // get the measures associated with it
  // and reset the territoryRuleId and territoryGroupId values
  useEffect(() => {
    if (selectedBattleCardId && selectedQuotaComponentId) {
      getSheetMeasures(+selectedBattleCardId);
      setTerritoryRuleId(null);
      setTerritoryGroupId(null);
    }
  }, [selectedBattleCardId]);

  // force a battle card canvas rerender
  useEffect(() => {
    if (shouldRefetchBattleCardDataImmediately) {
      setShouldRefetchBattleCardDataImmediately(false);
      getBattleCardData();
    }
  }, [shouldRefetchBattleCardDataImmediately]);

  const getBattleCardData = async () => {
    const isBattleCardValid = !!(selectedDeploymentModelId && selectedQuotaComponentId && selectedPlanningCycle?.id);
    if (isBattleCardValid) {
      try {
        setIsBattleCardDataLoading(true);

        const suppressCache = shouldRefetchBattleCardDataOnNextRender || shouldRefetchBattleCardDataImmediately;

        const isTQMEnabled = deploymentModelPhase === DeploymentModelPhase.manage;
        // get battle card data
        const battleCardList = await getBattleCardCanvas(
          selectedDeploymentModelId,
          !isLocalCurrencyMode,
          suppressCache,
          isTQMEnabled
        );

        // get root BC id
        const rootCardId = battleCardList?.find((battleCard) => battleCard.battlecardParentId === null)?.battlecardId;

        // create tree from list of BCs
        const bcRootCard = mapChildren(battleCardList, isLocalCurrencyMode, rootCardId);

        // create the battleCardLookupMap
        const battleCardLookupMap = createLookupMap(battleCardList);

        // check if any BattleCard has an invalid currency conversion
        const doesInvalidCurrencyConversionExist = checkForInvalidCurrencyConversion(battleCardLookupMap);

        // get all currencies that are in use by a BattleCard
        const currenciesInUse = getAllCurrenciesInUse(battleCardLookupMap, defaultReportingCurrency);

        setRootCard(bcRootCard);
        setBattleCardLookupMap(battleCardLookupMap);
        setDoesInvalidCurrencyConversionExist(doesInvalidCurrencyConversionExist);
        setCurrenciesInUse(currenciesInUse);
      } catch (err) {
        console.log(err);
        setHasInternalError(true);
      } finally {
        setIsBattleCardDataLoading(false);
      }
    }
  };

  const shouldShowLoading = (!rootCard?.data.battlecardId || loadingPermissions) && !hasInternalError;

  const hasNoPermissions =
    (!canViewPlan && deploymentModelPhase === DeploymentModelPhase.plan) ||
    (!canViewManage && deploymentModelPhase === DeploymentModelPhase.manage);

  return (
    <div
      className={b({ expanded: isBattleCardExpanded })}
      style={{ marginBottom: `${trayHeight}px` }}
      data-testid="battle-card-canvas"
    >
      {!shouldShowLoading && hasInternalError && (
        <ErrorPage errorType={ErrorType.INTERNAL} data-testid="internal-error-page" />
      )}
      {!loadingPermissions && hasNoPermissions && (
        <ErrorPage errorType={ErrorType._401} data-testid="permissions-error-page" />
      )}
      {!hasInternalError && !hasNoPermissions && (
        <>
          {!isBattleCardExpanded && (
            <div className={b('canvasControls__battleCardRefactor')}>
              <BattleCardCanvasControl />
            </div>
          )}
          <div className={b('background')} ref={canvasBackgroundRef}>
            <div
              className={b('canvas', {
                showCommandCenter: commandCenterDrawerState === CommandCenterDrawerState.OPEN
              })}
            >
              <div className={b('canvasBox')} id="battlecard-canvas">
                {shouldShowLoading && (
                  <div className={b('treeWrapper')}>
                    <BattleCardLoading data-testid="battle-card-loading" />
                  </div>
                )}
                <div className={b('treeWrapper')}>
                  {!shouldShowLoading && !hasInternalError && (
                    <BattleCardTree
                      setParentBattleCardId={setParentBattleCardId}
                      setShowAddBattleCardDialog={setShowAddBattleCardDialog}
                      cachedPanelCollapseStateByCard={cachedPanelCollapseStateByCard}
                      setCachedPanelCollapseStateByCard={setCachedPanelCollapseStateByCard}
                      rootCard={rootCard}
                      setEditingBattleCardId={setEditingBattleCardId}
                      deploymentModelId={selectedDeploymentModelId}
                      canvasBackgroundRef={canvasBackgroundRef}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {showAddBattleCardDialog && (
            <BattleCardDesignerDialog
              isOpen={showAddBattleCardDialog}
              setShowDialog={setShowAddBattleCardDialog}
              parentBattleCardId={parentBattleCardId}
              deploymentModelId={selectedDeploymentModelId}
              editingBattleCardId={editingBattleCardId}
            />
          )}
          <CanUser
            perform={UserAction.COMMAND_CENTER_VIEW}
            yes={<CommandCenterDrawer setHierarchyToEdit={setHierarchyToEdit} />}
            no={<Redirect to={makePlanningPath()} />}
          />

          {showAddHierarchyDialog && <HierarchyCreationDialog />}
          {showEditHierarchyDialog && (
            <HierarchyEditDialog isOpen={showEditHierarchyDialog} hierarchyToEdit={hierarchyToEdit} />
          )}
          {showUploadHierarchyDialog && (
            <HierarchyUploadDialog
              triggerAccountAlignmentUpload={() => setShowUploadAlignmentDialog(true)}
              hierarchyToUpload={hierarchyToEdit}
            />
          )}
          {showUploadAlignmentDialog &&
            isNewAccountRoutingOn &&
            deploymentModelPhase === DeploymentModelPhase.manage && (
              <AccountAlignmentUploadDialog onClose={() => setShowUploadAlignmentDialog(false)} />
            )}
        </>
      )}
    </div>
  );
};

export default BattleCardCanvas;
