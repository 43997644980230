import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { DropdownMenu as VDSDropdownMenu, TooltipV2, Intent } from '@varicent/components';

interface DropdownMenuItemProps {
  text: string;
  tooltip?: string;
  onClick?: () => void;
  disabled?: boolean;
  intent?: Intent;
  icon?: React.FunctionComponent;
  children?: React.ReactElement;
  asChild?: boolean;
  'data-testid': string;
}

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  children,
  ...propsWithoutChildren
}: DropdownMenuItemProps) => {
  const { disabled, tooltip } = propsWithoutChildren;

  if (!children) {
    return <VDSDropdownMenu.Item {...propsWithoutChildren} />;
  }
  if (disabled) {
    return (
      <TooltipV2 content={tooltip} placement="right">
        <VDSDropdownMenu.Item {...propsWithoutChildren} />
      </TooltipV2>
    );
  }
  return (
    <VDSDropdownMenu.SubMenu {...propsWithoutChildren} placement="right">
      {children}
    </VDSDropdownMenu.SubMenu>
  );
};

export default DropdownMenuItem;
