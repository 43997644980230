// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".F7OvX {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 0 var(--data-panel-padding);\n    background-color: white;\n    align-items: center;\n    box-sizing: border-box;\n    height: var(--data-drill-in-header-height);\n  }\n  .Fb2b\\+ {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n  .nCQRQ {\n    margin: 0 var(--data-panel-padding) 0 0;\n  }\n  .v6lVG {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n  .v6lVG .bp3-divider {\n      margin: 0 var(--s);\n      height: var(--xl);\n    }\n  ._5cLQZ {\n    margin-right: var(--data-panel-margin);\n    color: rgb(var(--color-gray-2));\n  }\n  .vAowl {\n    margin-left: 10px;\n  }\n  ._39Ihq {\n    display: none;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuotaSheetHeader_header": "F7OvX",
	"QuotaSheetHeader_leftActionItems": "Fb2b+",
	"QuotaSheetHeader_backIcon": "nCQRQ",
	"QuotaSheetHeader_rightActionItems": "v6lVG",
	"QuotaSheetHeader_rightActionText": "_5cLQZ",
	"QuotaSheetHeader_sheetNameButtons": "vAowl",
	"QuotaSheetHeader_hiddenItem": "_39Ihq"
};
export default ___CSS_LOADER_EXPORT___;
