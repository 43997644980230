import React, { useState } from 'react';

import { Checkmark, DocumentExport, LocationStar, MapBoundary, Scales, View, Restart } from '@carbon/icons-react';
import { Card, DividerV2, HTMLHeading } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';

import ConfirmActionModal from 'app/components/ConfirmActionModal/ConfirmActionModal';
import { findPipeUuidForScenario } from 'app/components/TerritoryOptimization/utils/territoryOptimizationUtils';

import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { useExportData } from 'app/graphql/hooks/useExportData';
import { useCleanFileProcess } from 'app/graphql/mutations/cleanFileProcess';
import { usePublishFileToDb } from 'app/graphql/mutations/publishFileToDB';

import { ExportTableName, OptimizationScenario, TerritoryOptimizationStep } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './OptimizeResults.module.pcss';

const b = block(style);

const OptimizeResults: React.FC = () => {
  const {
    optimizationTargetTgId,
    optimizationTarget,
    setTerritoryOptimizationDrawerState,
    setSelectedOptimizationScenario,
    optimizedTerritoryInProgress
  } = useTerritoryOptimization();
  const [selectedScenario, setSelectedScenario] = useState<OptimizationScenario>(null);

  const { selectedPlanningCycle } = useScope();

  const { startExportData, isExportDataLoading } = useExportData();

  const [confirmPublishDialogOpen, setConfirmPublishDialogOpen] = useState<boolean>(false);
  const [confirmRestartDialogOpen, setConfirmRestartDialogOpen] = useState<boolean>(false);

  const [publishFile, { loading: publishing }] = usePublishFileToDb();
  const [cleanOptimizationStatus, { loading: cleaning }] = useCleanFileProcess();

  const handlePublish = async () => {
    if (optimizedTerritoryInProgress) {
      const pipeUuid = findPipeUuidForScenario(optimizedTerritoryInProgress, selectedScenario);
      if (pipeUuid) {
        await publishFile({
          variables: {
            input: {
              fileId: optimizedTerritoryInProgress.fileId,
              territoryOptimization: {
                pipeUuid
              }
            }
          }
        });
        setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.PUBLISHED);
        setSelectedOptimizationScenario(selectedScenario);
      }
    }
  };

  const handleRestart = () => {
    cleanOptimizationStatus({
      variables: {
        input: {
          territoryGroupId: +optimizationTargetTgId,
          forceDelete: true
        }
      },
      onCompleted() {
        setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.START);
      }
    });
  };

  return (
    <div className={b('resultsContainer')} data-testid="territory-optimization-results">
      <div className={b('content')}>
        <Card interactive className={b('card')}>
          <LocationStar size={32} className={b('icon')} />
          <HTMLHeading bold tagLevel="h4" text={formatMessage('CURRENT_STATE')} />
          <p className={b('description')}>{formatMessage('CURRENT_STATE_DESCRIPTION')}</p>
          <TextButton
            icon={<View size={20} />}
            text={formatMessage('PREVIEW')}
            minimal
            type="button"
            testId={'current-state-preview-button'}
            onClick={() => {
              setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.PREVIEW);
              setSelectedOptimizationScenario(OptimizationScenario.CURRENT_STATE);
            }}
            large={false}
          />
        </Card>
        <DividerV2 vertical />
        <Card interactive className={b('card')}>
          <Scales size={32} className={b('icon')} />
          <HTMLHeading bold tagLevel="h4" text={formatMessage('MOST_BALANCED')} />
          <p className={b('description')}>{formatMessage('MOST_BALANCED_DESCRIPTION')}</p>
          <TextButton
            icon={<View size={20} />}
            text={formatMessage('PREVIEW')}
            minimal
            type="button"
            testId={'most-balanced-preview-button'}
            onClick={() => {
              setSelectedOptimizationScenario(OptimizationScenario.MOST_BALANCED);
              setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.PREVIEW);
            }}
            large={false}
          />
          <TextButton
            text={
              selectedScenario === OptimizationScenario.MOST_BALANCED
                ? formatMessage('UNSELECT')
                : formatMessage('SELECT')
            }
            type="button"
            testId={'most-balanced-select-button'}
            onClick={() =>
              setSelectedScenario(
                selectedScenario === OptimizationScenario.MOST_BALANCED ? null : OptimizationScenario.MOST_BALANCED
              )
            }
            large={false}
          />
        </Card>
        <Card interactive className={b('card')}>
          <MapBoundary size={32} className={b('icon')} />
          <HTMLHeading bold tagLevel="h4" text={formatMessage('MOST_CONTIGUOUS')} />
          <p className={b('description')}>{formatMessage('MOST_CONTIGUOUS_DESCRIPTION')}</p>
          <TextButton
            icon={<View size={20} />}
            text={formatMessage('PREVIEW')}
            minimal
            type="button"
            testId={'most-contiguous-preview-button'}
            onClick={() => {
              setSelectedOptimizationScenario(OptimizationScenario.MOST_CONTIGUOUS);
              setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.PREVIEW);
            }}
            large={false}
          />
          <TextButton
            text={
              selectedScenario === OptimizationScenario.MOST_CONTIGUOUS
                ? formatMessage('UNSELECT')
                : formatMessage('SELECT')
            }
            type="button"
            testId={'most-contiguous-select-button'}
            onClick={() =>
              setSelectedScenario(
                selectedScenario === OptimizationScenario.MOST_CONTIGUOUS ? null : OptimizationScenario.MOST_CONTIGUOUS
              )
            }
            large={false}
          />
        </Card>
      </div>
      <div className={b('footer')}>
        <TextButton
          text={formatMessage('RESTART_OPTIMIZATION')}
          type="button"
          testId={'restart-optimization-button'}
          onClick={() => setConfirmRestartDialogOpen(true)}
          large={false}
          minimal
          icon={<Restart />}
        />
        <div>
          <TextButton
            icon={<Checkmark />}
            text={formatMessage('PUBLISH')}
            intent="primary"
            type={'button'}
            large={false}
            disabled={!selectedScenario}
            testId={'publish-button'}
            onClick={() => setConfirmPublishDialogOpen(true)}
            className={b('publishBtn')}
          />
        </div>
      </div>
      <ConfirmActionModal
        isSubmitting={publishing}
        data-testid="confirm-publish-dialog"
        isOpen={confirmPublishDialogOpen}
        onCancel={() => setConfirmPublishDialogOpen(false)}
        confirmButtonText={formatMessage('PUBLISH')}
        confirmButtonIcon={<Checkmark />}
        onConfirmAction={() => {
          void handlePublish();
        }}
        actionButtonClass="accentButton"
        minimal={true}
        title={formatMessage('PUBLISH_CHANGES_TO', { value: optimizationTarget?.name })}
        body={
          <div>
            <p>
              {formatMessage('PUBLISH_OPTIMIZATION_DESCRIPTION')}{' '}
              <b>{formatMessage('PUBLISH_OPTIMIZATION_DESCRIPTION_EMPHASIS')}</b>
            </p>
            <p>{formatMessage('PUBLISH_OPTIMIZATION_DESCRIPTION_WARNING')}</p>
            <div className={b('exportBtn')}>
              <TextButton
                icon={<DocumentExport />}
                minimal
                text={formatMessage('EXPORT_CURRENT_TERRITORY_RULES')}
                type={'button'}
                large={false}
                loading={isExportDataLoading}
                testId={'territoryOptimization-export-button'}
                onClick={() =>
                  startExportData({
                    planningCycleId: selectedPlanningCycle?.id,
                    tableName: ExportTableName.TERRITORY_RULES,
                    spInternalInput: {
                      territoryGroupId: +optimizationTargetTgId
                    }
                  })
                }
              />
            </div>
          </div>
        }
      />
      <ConfirmActionModal
        isSubmitting={cleaning}
        data-testid="confirm-restart-dialog"
        isOpen={confirmRestartDialogOpen}
        onCancel={() => setConfirmRestartDialogOpen(false)}
        confirmButtonText={formatMessage('RESTART_OPTIMIZATION')}
        confirmButtonIcon={<Checkmark />}
        onConfirmAction={handleRestart}
        actionButtonClass="accentButton"
        minimal={true}
        title={formatMessage('RESTART_OPTIMIZATION')}
        body={<div>{formatMessage('RESTART_OPTIMIZATION_DESCRIPTION')}</div>}
      />
    </div>
  );
};

export default OptimizeResults;
