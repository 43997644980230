import gql from 'graphql-tag';

export const GET_CONVERSION_RATES = gql`
  query GetConversionRates($planningCycleId: Int!, $reportingCurrencyCode: String, $localCurrencyCode: String) {
    getConversionRates(
      input: {
        planningCycleId: $planningCycleId
        reportingCurrencyCode: $reportingCurrencyCode
        localCurrencyCode: $localCurrencyCode
      }
    ) {
      canDelete
      currencyHierarchyId
      planningCycleId
      reportingCurrencyCode
      reportingCurrencyName
      localCurrencies {
        localCurrencyName
        localCurrencyCode
        currencyHierarchyId
        conversionRate
        canDelete
      }
    }
  }
`;
