// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1RrXs {\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: center;\n    white-space: pre-wrap;\n    margin-top: 12px;\n    padding: 0 10px;\n    font-size: 12px;\n    line-height: 1.67;\n    color: rgb(var(--color-gray-1));\n  }\n  .y5-\\+x {\n    color: rgb(var(--color-cobalt-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PolicyMessage_policy": "_1RrXs",
	"PolicyMessage_link": "y5-+x"
};
export default ___CSS_LOADER_EXPORT___;
