import React, { useState } from 'react';

import Tab from 'components/Tab/Tab';
import Tabs from 'components/Tabs/Tabs';

import ConfigurationsPanel from 'app/components/DataPanel/ConfigurationsPanel/ConfigurationsPanel';
import ImportErrorsPanel from 'app/components/DataPanel/ImportErrorsPanel/ImportErrorsPanel';
import SheetsPanel from 'app/components/DataPanel/SheetsPanel/SheetsPanel';
import TablesPanel from 'app/components/DataPanel/TablesPanel/TablesPanel';

import { useData } from 'app/contexts/dataProvider';

import { SplitFeatures } from 'app/global/features';

import useQueryParamState from 'app/hooks/useQueryParamState';
import useTreatment from 'app/hooks/useTreatment';

import { DataPanelTabIds } from 'app/models/index';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './DataOverview.module.pcss';

const b = block(style);

const DataOverview: React.FC = () => {
  const dataTabQueryParamKey = 'data-tab';
  const { setSelectedDataView } = useData();
  const [isActivityExpanded, setIsActivityExpanded] = useState(false);
  const [isSymonEmbedFlagOn] = useTreatment(SplitFeatures.SYMON_EMBED);
  const [isFileErrorTabOn] = useTreatment(SplitFeatures.FILE_ERROR_TAB_ENABLED);

  const [selectedTab, setSelectedTab] = useQueryParamState<DataPanelTabIds>(dataTabQueryParamKey);

  return (
    <div className={b()}>
      <Tabs
        large
        animate
        renderActiveTabPanelOnly
        id="data-overview"
        className={b('tabs')}
        selectedTabId={selectedTab}
        onChange={(navBarId: DataPanelTabIds) => setSelectedTab(navBarId)}
      >
        <Tab id={DataPanelTabIds.SHEETS} title={formatMessage('SHEETS')} panel={<SheetsPanel />} />
        {!isSymonEmbedFlagOn && (
          <Tab
            id={DataPanelTabIds.TABLES}
            title={formatMessage('TABLES')}
            panel={
              <TablesPanel
                setSelectedDataView={setSelectedDataView}
                isActivityExpanded={isActivityExpanded}
                setIsActivityExpanded={setIsActivityExpanded}
              />
            }
          />
        )}
        {!isSymonEmbedFlagOn && (
          <Tab
            id={DataPanelTabIds.CONFIGURATIONS}
            title={formatMessage('CONFIGURATIONS')}
            panel={<ConfigurationsPanel />}
          />
        )}
        {isFileErrorTabOn && (
          <Tab
            id={DataPanelTabIds.IMPORT_ERRORS}
            title={formatMessage('IMPORT_ERRORS')}
            panel={<ImportErrorsPanel />}
          />
        )}
      </Tabs>
    </div>
  );
};

export default DataOverview;
