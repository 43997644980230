import { INVITATION_ID } from 'app/global/variables';

// Migrated unchanged from app.tsx
export const getUserConfirmation = (content: string, callback: (ok: boolean) => void): void => {
  const { message, action } = JSON.parse(content);

  const confirmed = window.confirm(message);

  // this is the scenario when user cancels the prompt, and they don't want to leave the page
  // if they decide to stay when they have clicked on back button (POP action); we change the history to the url which they decided to leave from (history.forward)
  // if they decide to stay when they have clicked on forward button (PUSH action); we change the history to the url which they decided to leave from (history.back)
  if (!confirmed) {
    if (action === 'POP') {
      window.history.forward();
    }
    if (action === 'PUSH') {
      window.history.back();
    }
  } else {
    // to proceed with navigation if they click on ok
    callback(confirmed);
  }
};

export const clearAuthValuesFromStorage = (): void => {
  localStorage.removeItem(INVITATION_ID);
  localStorage.removeItem('regType');
  localStorage.removeItem('orgId');
};
