import gql from 'graphql-tag';

export const WRITE_LOCATION_FILE_TO_DB = gql`
  mutation WriteLocationFileToDB($input: WriteLocationFileToDBInput!) {
    writeLocationFileToDB(input: $input) {
      fileId
      status
      message
    }
  }
`;
