// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iYlFE {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  width: 100%;\n}\n\n  ._3jyeC {\n    background-color: rgb(var(--color-light-gray-5));\n    flex: 1;\n    padding: 48px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateOrganization": "iYlFE",
	"CreateOrganization_formContainer": "_3jyeC"
};
export default ___CSS_LOADER_EXPORT___;
