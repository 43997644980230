import React, { useEffect, useRef, useState } from 'react';

import { AnchorButton, Classes, MenuDivider, SpinnerSize, Spinner } from '@blueprintjs/core';
import { Placement } from '@blueprintjs/popover2';
import { ChevronDown, Search } from '@carbon/icons-react';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { FieldProps } from 'formik';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import EllipsisText from 'components/EllipsisText/EllipsisText';
import InfiniteScroll, { InfiniteScrollProps } from 'components/InfiniteScroll/InfiniteScroll';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import { SearchableSelectMenuItem } from 'components/models';

import { debounceDelay } from 'app/constants/DebounceConstants';

import useCombinedRefs from 'app/hooks/useCombinedRefs';
import useOutsideClick from 'app/hooks/useOutsideClick';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SearchableSelectMenu.module.pcss';

const b = block(style);
interface SearchableSelectMenuProps extends FieldProps {
  items: SearchableSelectMenuItem[];
  initialLoadingComplete: boolean;
  theme?: 'default' | 'secondary';
  className?: string;
  disabled?: boolean;
  disabledIcon?: boolean;
  requireTouchForError?: boolean;
  disabledItems?: SearchableSelectMenuItem[];
  shouldDisableSearch?: boolean;
  minimal?: boolean;
  small?: boolean;
  outlineItems?: boolean;
  placeHolderText?: string;
  allowFlip?: boolean;
  allowPreventOverflow?: boolean;
  allowPopperEventListeners?: boolean;
  showErrors?: boolean;
  showErrorStyle?: boolean;
  onSelectItem?: (changes: UseComboboxStateChange<SearchableSelectMenuItem>) => void;
  onSelectOpen?: () => void;
  shouldSupportInfiniteScroll?: boolean;
  infiniteScrollProps?: InfiniteScrollProps;
  onSearch?: (searchVariables: string | Record<string, unknown>) => void;
  queryVariables?: Record<string, number | string>;
  onSearchReset?: () => void;
  staticIcon?: React.ReactElement;
  showIconInField?: boolean;
  smallIcon?: boolean;
  dividerIndex?: number;
  menuTooltipPlacement?: Placement;
  renderTarget?: (onClick: (event: MouseEvent) => void, isOpen: boolean, disabled: boolean) => React.ReactNode;
  contentStyle?: Record<string, unknown>;
  usePortal?: boolean;
  portalRef?: { current: Element };
  isFullWidth?: boolean;
  minimalDisabledItemStyle?: boolean;
  loading?: boolean;
}

const SearchableSelectMenu: React.FC<SearchableSelectMenuProps> = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  theme,
  className,
  items,
  disabled,
  minimal,
  small,
  placeHolderText,
  onSelectItem,
  onSelectOpen,
  onSearch,
  queryVariables,
  onSearchReset,
  staticIcon,
  dividerIndex,
  disabledItems = [],
  disabledIcon = true,
  requireTouchForError = true,
  shouldDisableSearch = false,
  allowFlip = true,
  allowPreventOverflow = false,
  showErrors = true,
  allowPopperEventListeners = true,
  showErrorStyle = false,
  initialLoadingComplete = false,
  shouldSupportInfiniteScroll = false,
  infiniteScrollProps = null,
  showIconInField = true,
  smallIcon = false,
  menuTooltipPlacement = 'right',
  renderTarget,
  contentStyle,
  portalRef,
  usePortal = false,
  isFullWidth = false,
  minimalDisabledItemStyle = false,
  loading = false
}: SearchableSelectMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);

  const itemToString = (item) => {
    return item ? item.key : '';
  };

  const onSelectedItemChange = async (item) => {
    if (!!disabledItems.find((disabledItem) => disabledItem?.value === item.selectedItem?.value)) {
      return;
    }

    await Promise.resolve(setFieldValue(name, item.selectedItem));

    // after the selected item is set in formik, clear the input box
    setInputValue('');

    if (onSelectItem) {
      onSelectItem(item);
    }
    setIsOpen(false);
  };

  const {
    inputValue,
    selectedItem,
    setInputValue,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    getInputProps,
    getComboboxProps
  } = useCombobox({
    items: items ?? [],
    selectedItem: value,
    itemToString,
    onSelectedItemChange,
    onIsOpenChange: (isOpen) => {
      if (isOpen) {
        setInputValue('');
      }
    },
    onInputValueChange: ({ inputValue }) => {
      debounceHandler.current(inputValue);
    }
  });

  const handleSearch = (debounceHandler) => {
    if (shouldDisableSearch) {
      return;
    }
    if (debounceHandler === '') {
      setResetSearch(true);
    } else if (debounceHandler && onSearch && queryVariables) {
      const variables = { ...queryVariables, searchString: debounceHandler };
      onSearch({ variables });
    } else if (debounceHandler && onSearch) {
      onSearch(debounceHandler as string);
    }
  };

  useEffect(() => {
    if (resetSearch && onSearchReset) {
      onSearchReset();
      setResetSearch(false);
    }
  }, [resetSearch]);

  useEffect(() => {
    // update when items finish loading in as size changes
    if (forceUpdate) {
      forceUpdate();
    }
  }, [items]);

  const debounceHandler = useRef(debounce(handleSearch, debounceDelay));

  const isTouchedAndError = !!(get(touched, name) && get(errors, `${name}.value`));
  const shouldShowError = (!requireTouchForError || isTouchedAndError) && !!get(errors, `${name}.value`, '');
  const errorMessage = get(errors, `${name}.value`, '');
  const classNameString = className ? ` ${className}` : '';

  const defaultButtonText = placeHolderText || '';
  const referenceElement = useRef(null);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(null);
  const { styles, attributes, forceUpdate } = usePopper(referenceElement.current, popperElement, {
    placement: allowPreventOverflow ? 'auto-start' : 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        enabled: allowFlip
      },
      {
        name: 'preventOverflow',
        enabled: allowPreventOverflow,
        options: {
          altAxis: true,
          padding: 20
        }
      },
      {
        name: 'eventListeners',
        enabled: allowPopperEventListeners // TODO TQP-2707 - Refactor all instances of SearchableSelectMenu to not use Popper EventListeners modifier
      }
    ]
  });

  const { ...buttonProps } = getToggleButtonProps();
  const { ref: menuRef, ...menuProps } = getMenuProps();
  const combinedMenuRef = useCombinedRefs(menuRef, setPopperElement);
  const { ref: inputRef, ...inputProps } = getInputProps({
    // Prevents the form from being submitted
    onKeyDown(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    }
  });
  const combinedInputRef = useCombinedRefs(inputRef, setPopperElement);
  const clickedOutside = useOutsideClick(selectRef);

  useEffect(() => {
    // Force update the popper instance so that the styles update when dialog opens
    if (forceUpdate) {
      forceUpdate();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setIsOpen(!clickedOutside);
    }
  }, [clickedOutside]);

  // icons associated with the items themselves
  // take precedence over the staticIcon when rendering,
  // both in the button's field and in the dropdown
  let ButtonText;
  if (selectedItem?.key && showIconInField) {
    ButtonText = (
      <div className={b('menuItem')}>
        <div className={b('imageContainer')} data-testid="button-image">
          {selectedItem?.icon ?? staticIcon}
        </div>
        <EllipsisText text={selectedItem.key} />
      </div>
    );
  } else {
    ButtonText = <EllipsisText text={selectedItem?.key || defaultButtonText} />;
  }

  const onButtonClick = (e) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
    buttonProps.onClick(e);
    setFieldTouched(name);
    if (onSelectOpen) {
      onSelectOpen();
    }
  };

  const renderEllipsisText = (disabledTooltipMessage: string | null, item: SearchableSelectMenuItem) => (
    <div data-testid="ellipsis-text">
      {disabledTooltipMessage ? (
        <MessageTooltip
          content={disabledTooltipMessage}
          target={<EllipsisText placement={menuTooltipPlacement} text={item?.key} />}
          placement={'top'}
          data-testid="disabled-item-tooltip"
        />
      ) : (
        <EllipsisText placement={menuTooltipPlacement} text={item?.key} />
      )}
    </div>
  );

  const wrapWithPortal = (node: React.ReactNode) =>
    usePortal ? createPortal(<div ref={selectRef}>{node}</div>, portalRef?.current ?? document.body) : node;

  const findDisabledItem = (item: SearchableSelectMenuItem) => {
    const disabledItem = disabledItems.find((disabledItem) => disabledItem?.value === item.value);

    return { isItemDisabled: !!disabledItem, disabledTooltipMessage: disabledItem?.message };
  };

  return (
    <div ref={!usePortal ? selectRef : null} className={b({ fullWidth: isFullWidth })}>
      <div
        className={`${b({
          default: theme === 'default',
          secondary: theme === 'secondary',
          danger: shouldShowError && showErrorStyle
        })}${classNameString}`}
        {...getComboboxProps()}
        data-testid="searchable-select-menu-container"
      >
        {renderTarget ? (
          <div className={b('renderTargetContainer')} ref={referenceElement}>
            {renderTarget(onButtonClick, isOpen, disabled)}
          </div>
        ) : (
          <AnchorButton
            elementRef={referenceElement}
            {...buttonProps}
            text={ButtonText}
            type="button"
            rightIcon={<ChevronDown size={20} data-testid="searchable-select-button-icon" />}
            disabled={disabled}
            minimal={minimal}
            small={small}
            icon={loading && <Spinner size={SpinnerSize.SMALL} data-testid="searchable-select-menu-spinner" />}
            data-testid={'searchable-select-button'}
            onClick={onButtonClick}
          />
        )}
        {wrapWithPortal(
          <ul
            ref={combinedMenuRef}
            className={`${b('menuContent')} ${Classes.MENU}`}
            {...menuProps}
            style={{
              ...styles.popper,
              visibility: isOpen ? 'visible' : 'hidden',
              overflow: shouldSupportInfiniteScroll ? 'initial' : 'auto',
              ...contentStyle
            }}
            {...attributes.popper}
            data-testid={'menu'}
          >
            <div className={b('searchField', { disabled: shouldDisableSearch })}>
              <Search size={20} />
              <input
                value={inputValue}
                ref={combinedInputRef}
                placeholder={formatMessage('SEARCH')}
                {...inputProps}
                data-testid={'search-field'}
                disabled={shouldDisableSearch}
              />
            </div>
            {!initialLoadingComplete && (
              <div className={'bp3-skeleton'} data-testid={'searchable-select-menu-loading'} />
            )}
            {initialLoadingComplete && items?.length === 0 && (
              <div className={b('noResults')} data-testid={'searchable-select-menu-no-results'}>
                {formatMessage('NO_RESULTS')}
              </div>
            )}
            {initialLoadingComplete &&
              items?.length > 0 &&
              (shouldSupportInfiniteScroll ? (
                <div data-testid={'infinite-scroll'}>
                  {initialLoadingComplete && (
                    <InfiniteScroll
                      {...infiniteScrollProps}
                      items={items}
                      contentRenderer={(item, index, style) => (
                        <React.Fragment key={`${item?.value}${index}`}>
                          <li
                            style={style}
                            className={Classes.MENU_ITEM}
                            disabled={!!disabledItems.find((disabledItem) => disabledItem?.value === item?.value)}
                            {...getItemProps({
                              item,
                              index
                            })}
                            data-testid={`list-item-${item?.value}`}
                          >
                            {item?.icon || staticIcon ? (
                              <div className={b('menuItem', { dangerColor: item?.dangerColor })}>
                                <div
                                  className={b('imageContainer', {
                                    smallIcon,
                                    disabledIcon:
                                      disabledIcon &&
                                      !!disabledItems.find((disabledItem) => disabledItem?.value === item.value)
                                  })}
                                >
                                  {item?.icon ?? staticIcon}
                                </div>
                                <EllipsisText text={item.key} />
                              </div>
                            ) : (
                              item?.key
                            )}
                          </li>
                          {dividerIndex === index && <MenuDivider />}
                        </React.Fragment>
                      )}
                      loadingRenderer={(style) => <div style={style} className={'bp3-skeleton'} />}
                    />
                  )}
                </div>
              ) : (
                <div data-testid={'list-items'} className={b({ minimalDisabledItemStyle })}>
                  {items?.length > 0 &&
                    isOpen &&
                    items.map((item, index) => {
                      const { isItemDisabled, disabledTooltipMessage } = findDisabledItem(item);
                      return (
                        <React.Fragment key={`${item?.value}${index}`}>
                          <li
                            className={Classes.MENU_ITEM}
                            disabled={isItemDisabled}
                            {...getItemProps({
                              item,
                              index
                            })}
                            data-testid={`list-item-${item.value}`}
                          >
                            {item?.icon || staticIcon ? (
                              <div className={b('menuItem')}>
                                <div
                                  className={b('imageContainer', {
                                    smallIcon,
                                    disabledIcon: disabledIcon && isItemDisabled
                                  })}
                                  data-testid={`list-item-image-${item.value}`}
                                >
                                  {item?.icon ?? staticIcon}
                                </div>
                                {renderEllipsisText(disabledTooltipMessage, item)}
                              </div>
                            ) : (
                              renderEllipsisText(disabledTooltipMessage, item)
                            )}
                          </li>
                          {dividerIndex === index && <MenuDivider data-testid={`menu-divider-${item?.value}`} />}
                        </React.Fragment>
                      );
                    })}
                </div>
              ))}
          </ul>
        )}
        {showErrors ? (
          <div className={b('validationMessage')} data-testid={`searchable-select-menu-${name}-error-message`}>
            {shouldShowError && errorMessage}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SearchableSelectMenu;
