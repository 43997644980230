import React, { useState, Dispatch, SetStateAction, MutableRefObject, useLayoutEffect, useEffect, useRef } from 'react';

import { CSSTransition } from 'react-transition-group';

import BattleCardContent from 'app/components/BattleCardDiagram/Cards/BattleCardContent/BattleCardContent';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';

import { EXPANDED_BATTLECARD_DRAWER_MARGIN } from 'app/global/variables';

import usePhase from 'app/hooks/usePhase';
import useWindowSize from 'app/hooks/useWindowResize';

import { BattleCardData, DeploymentModelPhase } from 'app/models';

import block from 'utils/bem-css-modules';
import { calculateBattleCardScrollCoords } from 'utils/helpers/battleCardScrollCoordsHelper';
import { getDrawerWidthDifference } from 'utils/helpers/commandCenterHelper';
import { deepEqual } from 'utils/helpers/index';

import style from './BattleCard.module.pcss';

const b = block(style);

interface BattleCardProps {
  cachedPanelCollapseStateByCard: Record<string, Record<string, boolean>>;
  setCachedPanelCollapseStateByCard: Dispatch<SetStateAction<Record<string, Record<string, boolean>>>>;
  setShowDialog: Dispatch<SetStateAction<boolean | null>>;
  setEditingBattleCardId: Dispatch<SetStateAction<string | null>>;
  deploymentModelId: number;
  cardData: BattleCardData;
  battleCardNodeRef: MutableRefObject<HTMLDivElement>;
  setHideBackgroundNodes: Dispatch<SetStateAction<boolean | null>>;
  expandedBattleCardId: string;
  canvasBackgroundRef: MutableRefObject<HTMLDivElement>;
}

const BattleCard: React.FC<BattleCardProps> = ({
  cardData,
  cachedPanelCollapseStateByCard,
  setShowDialog,
  setEditingBattleCardId,
  deploymentModelId,
  setCachedPanelCollapseStateByCard,
  battleCardNodeRef,
  setHideBackgroundNodes,
  expandedBattleCardId,
  canvasBackgroundRef
}: BattleCardProps) => {
  const { trayHeight, userClosed } = useDataTray();
  const {
    selectedBattleCardId,
    setExpandedBattleCardId,
    exitingBattleCardId,
    setExitingBattleCardId,
    cardIsExpanded,
    cardBox
  } = useBattleCard();
  const { commandCenterDrawerState } = useCommandCenter();
  const deploymentModelPhase = usePhase();
  const { windowWidth } = useWindowSize();

  const battleCardRef = useRef(null);

  const [animation, setAnimation] = useState(false);
  const [battleCardWidth, setBattleCardWidth] = useState(null);

  const applyBattleCardBox = () => {
    if (battleCardRef.current == null || battleCardRef.current.style === undefined) return null;
    Object.assign(battleCardRef.current.style, {
      top: `${cardBox?.top}px`,
      left: `${cardBox?.left}px`,
      bottom: `${window.innerHeight - cardBox?.bottom}px`,
      right: `${window.innerWidth - cardBox?.right}px`
    });
  };

  const isBattleCardExpanded = () => expandedBattleCardId && cardData.battlecardId === expandedBattleCardId;

  const assignTrayHeight = () => {
    if (battleCardRef.current == null || battleCardRef.current.style === undefined) return null;
    Object.assign(battleCardRef.current.style, {
      bottom: `${trayHeight + 6}px`
    });
  };

  useLayoutEffect(() => {
    if (isBattleCardExpanded()) {
      assignTrayHeight();
    }
  }, [trayHeight, expandedBattleCardId]);

  useEffect(() => {
    if (selectedBattleCardId === cardData.battlecardId) {
      const { top, left, bottom, right } = battleCardRef.current.getBoundingClientRect();

      const { scrollY, scrollX } = calculateBattleCardScrollCoords(right, left, bottom, top, trayHeight, userClosed);

      canvasBackgroundRef.current.scrollBy({
        top: scrollY,
        left: scrollX,
        behavior: 'smooth'
      });
    }
  }, [selectedBattleCardId]);

  useEffect(() => {
    if (isBattleCardExpanded()) {
      const width = getDrawerWidthDifference(commandCenterDrawerState, windowWidth, EXPANDED_BATTLECARD_DRAWER_MARGIN);
      setBattleCardWidth(width);
    }
  }, [commandCenterDrawerState, windowWidth]);

  const clearBattleCardBox = () => {
    if (battleCardRef.current == null || battleCardRef.current.style === undefined) return null;
    Object.assign(battleCardRef.current.style, {
      top: '',
      left: '',
      bottom: '',
      right: ''
    });
  };

  useEffect(() => {
    if (isBattleCardExpanded()) {
      setAnimation(true);
    } else if (!expandedBattleCardId && exitingBattleCardId && cardData.battlecardId === exitingBattleCardId) {
      setAnimation(false);
    }
  }, [expandedBattleCardId, exitingBattleCardId, cardData.battlecardId]);

  const isManagePhase = deploymentModelPhase === DeploymentModelPhase.manage;

  const planPhaseClassNames = {
    enter: b('expand__enter'),
    enterActive: b('expand__enterActive'),
    enterDone: b('expand__enterDone'),
    exit: b('expand__exit'),
    appearActive: b('expand__appearActive'),
    exitActive: b('expand__exitActive'),
    exitDone: b('expand__exitDone'),
    appear: b('expand__appear'),
    appearDone: b('expand__appearDone')
  };

  const managePhaseClassNames = isManagePhase
    ? {
        enterActive: b('expand__enterActiveHigher'),
        enterDone: b('expand__enterDoneHigher'),
        exit: b('expand__exitHigher'),
        appearActive: b('expand__appearActiveHigher')
      }
    : {};

  const classNames = { ...planPhaseClassNames, ...managePhaseClassNames };

  return (
    <CSSTransition
      in={animation}
      appear={isBattleCardExpanded()}
      classNames={classNames}
      onEnter={() => {
        if (cardIsExpanded) {
          applyBattleCardBox();
          setHideBackgroundNodes(true);
        }
      }}
      onEntering={() => {
        if (cardIsExpanded) {
          clearBattleCardBox();
          assignTrayHeight();
        }
      }}
      onExit={() => {
        if (exitingBattleCardId && cardData.battlecardId === exitingBattleCardId) {
          assignTrayHeight();
          setHideBackgroundNodes(false);
        }
      }}
      onExiting={() => {
        if (exitingBattleCardId && cardData.battlecardId === exitingBattleCardId) {
          applyBattleCardBox();
        }
      }}
      onExited={() => {
        if (exitingBattleCardId && cardData.battlecardId === exitingBattleCardId && !cardIsExpanded) {
          clearBattleCardBox();
          setExitingBattleCardId(null);
          setExpandedBattleCardId(null);
        }
      }}
      timeout={200}
    >
      <div
        style={isBattleCardExpanded() ? { width: battleCardWidth, top: isManagePhase ? '66px' : '106px' } : {}}
        className={b()}
        ref={battleCardRef}
        data-testid={`battle-card${isBattleCardExpanded() ? '-expand' : '-collapse'}`}
      >
        <BattleCardContent
          cardData={cardData}
          cachedPanelCollapseStateByCard={cachedPanelCollapseStateByCard}
          setCachedPanelCollapseStateByCard={setCachedPanelCollapseStateByCard}
          setShowDialog={setShowDialog}
          setEditingBattleCardId={setEditingBattleCardId}
          deploymentModelId={deploymentModelId}
          battleCardRef={battleCardRef}
          battleCardNodeRef={battleCardNodeRef}
        />
      </div>
    </CSSTransition>
  );
};

export default React.memo(BattleCard, (prevProps, nextProps) => {
  // if there is an expanded battlecard, always skip re-rendering for battlecards that are not expanded.
  if (prevProps.expandedBattleCardId !== nextProps.expandedBattleCardId) {
    return false;
  }

  if (nextProps.expandedBattleCardId && nextProps.expandedBattleCardId !== nextProps.cardData.battlecardId) {
    return false;
  }

  if (!deepEqual(prevProps.cardData, nextProps.cardData)) {
    return false;
  }

  return true;
});
