// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eMZUE {\n  display: flex;\n  width: 100%;\n  height: 100%;\n}\n\n  .BVTco {\n    overflow: auto;\n    display: flex;\n    width: 100%;\n    position: relative;\n    background-image: var(--canvas-background);\n    background-color: white;\n    background-position:\n      0 0,\n      50px 50px;\n    background-size: 15px 15px;\n  }\n\n  .bHsuP {\n    position: relative;\n    display: inline-block;\n    width: 100%;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExpandedPlanTargets": "eMZUE",
	"ExpandedPlanTargets_treeViewContainer": "BVTco",
	"ExpandedPlanTargets_treeView": "bHsuP"
};
export default ___CSS_LOADER_EXPORT___;
