// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X7uxu {\n  display: flex;\n  justify-content: space-between;\n  padding: 30px 24px;\n  background-color: rgb(var(--color-light-gray-5));\n  border-radius: var(--border-rad);\n  grid-gap: 8px;\n}\n\n  .PXHFV {\n    border: none;\n    flex: 1;\n    background-color: transparent;\n  }\n\n  .UUpy2 {\n    fill: rgb(var(--color-cobalt-3)) !important;\n  }\n\n  ._8QLPX {\n    font-weight: bold;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ClipboardTextInput": "X7uxu",
	"ClipboardTextInput_clipboardInput": "PXHFV",
	"ClipboardTextInput_copyToClipboardIcon": "UUpy2",
	"ClipboardTextInput_toastHeader": "_8QLPX"
};
export default ___CSS_LOADER_EXPORT___;
