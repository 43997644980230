import { ColDef } from '@ag-grid-community/core';

import BalancingMetricCellRenderer from 'app/components/AdvancedGrid/CellRenderers/BalancingMetricCellRenderer/BalancingMetricCellRenderer';
import FooterCellRenderer from 'app/components/AdvancedGrid/CellRenderers/FooterCellRenderer/FooterCellRenderer';
import MessageTooltipCellRenderer from 'app/components/AdvancedGrid/CellRenderers/MessageTooltipCellRenderer/MessageTooltipCellRenderer';

import { BalancingPanelColumnFields, BalancingPanelColumnNames, GridHeaders } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const buildBalancingPanelColumnDef = (
  measureType: string,
  currency: string,
  firstColumnHeaderName: string
): ColDef[] => {
  const isBalancingPanel = true;

  return [
    {
      headerName: firstColumnHeaderName,
      field: BalancingPanelColumnFields.BALANCING_HIERARCHY_NAME,
      sortable: true,
      flex: 5,
      checkboxSelection: (params) => {
        return params?.data;
      },
      minWidth: 160,
      cellRendererSelector: (params) => {
        if (params?.node?.rowPinned) {
          // using the default ag grid cell renderer
          return {
            frameworkComponent: FooterCellRenderer,
            params: {}
          };
        } else {
          const canSelect = params?.data?.isValidForRebalance;
          const headerName = params?.colDef?.headerName;

          let cannotSelectTooltip;
          if (headerName === GridHeaders.CUSTOMER_ACCOUNTS) {
            cannotSelectTooltip = formatMessage('CANNOT_REBALANCE_CUSTOMER');
          } else if (headerName === GridHeaders.GEOGRAPHIES && params?.data?.id === '') {
            cannotSelectTooltip = formatMessage('CANNOT_REBALANCE_MISSING_GEO_HIERARCHY');
          } else {
            cannotSelectTooltip = formatMessage('CANNOT_REBALANCE_GEOGRAPHY');
          }
          return {
            frameworkComponent: MessageTooltipCellRenderer,
            params: {
              tooltipAdditionalContentField: 'id',
              message: canSelect ? null : cannotSelectTooltip
            }
          };
        }
      }
    },
    {
      headerName: BalancingPanelColumnNames.BALANCING_METRIC,
      field: BalancingPanelColumnFields.BALANCING_METRIC,
      flex: 6,
      minWidth: 200,
      headerClass: 'ag-right-aligned-header',
      cellRendererSelector: (params) => {
        if (params?.node?.rowPinned) {
          return {
            frameworkComponent: FooterCellRenderer,
            params: {}
          };
        } else {
          return {
            frameworkComponent: BalancingMetricCellRenderer,
            params: { measureType, currency, isBalancingPanel }
          };
        }
      }
    }
  ];
};

export default buildBalancingPanelColumnDef;
