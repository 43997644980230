import { ViewState } from 'react-map-gl';

import { SplitFeatures } from 'app/global/features';
import { TERRITORY_MAP_PADDING } from 'app/global/variables';

import useTreatment from 'app/hooks/useTreatment';

import { GeoBounds } from 'app/models';

export const useInitialViewState = (
  hierarchyBounds: GeoBounds
): Partial<ViewState> & {
  bounds?: mapboxgl.LngLatBoundsLike;
  fitBoundsOptions?: mapboxgl.FitBoundsOptions;
} => {
  const [isMapCenteringOn] = useTreatment(SplitFeatures.MAP_CENTERING);

  if (isMapCenteringOn && hierarchyBounds) {
    const { minLat, minLon, maxLat, maxLon } = hierarchyBounds;
    return {
      bounds: [minLon, minLat, maxLon, maxLat],
      padding: TERRITORY_MAP_PADDING
    };
  }
  return DEFAULT_INITIAL_VIEW_STATE;
};

export const DEFAULT_INITIAL_VIEW_STATE = {
  latitude: 40,
  longitude: -100,
  zoom: 3
};
