import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

export const useDuplicateOverrideSplit = () => {
  const [isDuplicateAccountOverrideEnabled] = useTreatment(SplitFeatures.DUPLICATE_ACCOUNT_RULE_GRID_OVERRIDE);
  const [isBayerDuplicateAccountOverrideEnabled] = useTreatment(
    SplitFeatures.DUPLICATION_FID_IN_RULE_EDITOR_EXCEPTION_FILE
  );
  return isBayerDuplicateAccountOverrideEnabled && isDuplicateAccountOverrideEnabled;
};
