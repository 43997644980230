import gql from 'graphql-tag';

export const RUN_FULL_COIN_SORT = gql`
  mutation RunFullCoinsort($battlecardId: Int!, $quotaComponentId: Int!) {
    runFullCoinsort(input: { battlecardId: $battlecardId, quotaComponentId: $quotaComponentId }) {
      messageId
      jobId
    }
  }
`;
