import { useCallback, useRef } from 'react';

import {
  FromMapWorkerMessage,
  MapAnswerOutput,
  MapQuestionInput,
  MapQuestionMessage,
  MapQuestionKind
} from 'app/workers/MapWorker/MapWorkerProtocol';
import { v4 as uuid } from 'uuid';

import { useMapWorkerPostMessage, useOnMapWorkerMessage } from 'app/contexts/mapWorkerContext';

interface QuestionPromise<T> {
  questionId: string;
  resolve: (result: T) => void;
  reject: (error: Error) => void;
}

export const useAskMapQuestionAsPromise = <T extends MapQuestionKind>(
  questionKind: T
): ((input: MapQuestionInput<T>) => Promise<MapAnswerOutput<T> | null>) => {
  const remotePromiseRef = useRef<QuestionPromise<MapAnswerOutput<T>> | null>(null);

  const postMessage = useMapWorkerPostMessage();

  const ask = useCallback(
    async (input: MapQuestionMessage<T>['input']) => {
      if (remotePromiseRef.current) throw new Error(`Cannot ask async questions concurrently`);

      const questionId = uuid();
      const promise = new Promise<MapAnswerOutput<T>>((resolve, reject) => {
        remotePromiseRef.current = {
          questionId,
          resolve,
          reject
        };
      }).finally(() => {
        remotePromiseRef.current = null;
      });

      postMessage({ type: 'question', questionId, questionKind, input });
      return promise;
    },
    [questionKind]
  );

  const handleAnswer = useCallback(
    (message: FromMapWorkerMessage) => {
      if (message.type !== 'answer') return;
      if (message.questionKind !== questionKind) return;
      if (message.questionId !== remotePromiseRef.current?.questionId) return;

      if (message.error) {
        remotePromiseRef.current.reject(new Error(message.error));
      } else {
        remotePromiseRef.current.resolve(message.output);
      }
    },
    [questionKind]
  );
  useOnMapWorkerMessage(handleAnswer);

  return ask;
};
