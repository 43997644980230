import React, { useEffect, useState } from 'react';

import { DocumentExport, ArrowRight } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { useExportData } from 'app/graphql/hooks/useExportData';
import { useCleanFileProcess } from 'app/graphql/mutations/cleanFileProcess';
import { useGetFileProcessStatusLazy } from 'app/graphql/queries/getFileProcessStatus';

import useShowToast from 'app/hooks/useShowToast';

import { TerritoryOptimizationJob, TerritoryOptimizationStep } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import taskOptimization from 'assets/svgs/task-optimization.svg';

import style from './TerritoryOptimizationPage.module.pcss';

const b = block(style);

const TerritoryOptimizationPage: React.FC = () => {
  const { optimizationTargetTgId, setTerritoryOptimizationDrawerState, setOptimizedTerritoryInProgress } =
    useTerritoryOptimization();

  const { selectedPlanningCycle } = useScope();

  const { startExportData, isExportDataLoading } = useExportData();

  const showToast = useShowToast();

  const [optimizationStatus, setOptimizationStatus] = useState(undefined);

  const [cleanOptimizationStatus] = useCleanFileProcess();

  const [getOptimizationStatus] = useGetFileProcessStatusLazy({
    variables: {
      input: {
        territoryGroupIds: [+optimizationTargetTgId]
      }
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.getFileProcessStatus.length > 0) {
        const pipes = data.getFileProcessStatus[0].pipes;
        const { fileId, status } = data.getFileProcessStatus[0].fileMetadata;
        const territoryInProgressStatus: TerritoryOptimizationJob = {
          fileId,
          status,
          pipes: pipes.map((pipe) => ({
            pipeUuid: pipe.pipeUuid,
            optimizationType: pipe.configuration.optimizationType,
            territoryRuleBase: pipe.configuration.territoryRuleBase
          }))
        };
        setOptimizedTerritoryInProgress(territoryInProgressStatus);
      }
      setOptimizationStatus(data.getFileProcessStatus);
    },
    onError() {
      setOptimizationStatus(null);
      showToast(formatMessage('UNABLE_TO_RETRIEVE_OPTIMIZATION_STATUS'), 'danger');
    }
  });

  useEffect(() => {
    cleanOptimizationStatus({
      variables: {
        input: {
          territoryGroupId: +optimizationTargetTgId
        }
      },
      onCompleted() {
        getOptimizationStatus({
          variables: {
            input: {
              territoryGroupIds: [+optimizationTargetTgId]
            }
          }
        });
      },
      onError({ graphQLErrors }) {
        if (graphQLErrors[0]?.message.includes('403')) {
          showToast(formatMessage('CANNOT_START_OPTIMIZATION_USER_MESSAGE'), 'danger');
        }
        setOptimizationStatus(null);
      }
    });
  }, []);

  return (
    <>
      <div className={b()} data-testid="territory-optimization-page">
        <div className={b('container')}>
          <HTMLHeading bold tagLevel="h4" text={formatMessage('TERRITORY_OPTIMIZATION_TITLE')} />
          <div className={b('imageContainer')}>
            <img src={taskOptimization} alt="task-optimization" />
          </div>
          <div className={b('subtitleContainer')}>
            <HTMLHeading bold tagLevel="h6" className={b('subtitleContainer')} text={formatMessage('HOW_IT_WORKS')} />
          </div>
          <p>{formatMessage('TERRITORY_OPTIMIZATION_DESCRIPTION_STEP1')}</p>
          <p>{formatMessage('TERRITORY_OPTIMIZATION_DESCRIPTION_STEP2')}</p>
          <p>{<strong>{formatMessage('TERRITORY_OPTIMIZATION_DESCRIPTION_WARNING')}</strong>}</p>
          <p>{formatMessage('TERRITORY_OPTIMIZATION_DESCRIPTION_EXPORT')}</p>
          <div className={b('buttonContainer')}>
            <TextButton
              icon={<DocumentExport />}
              text={formatMessage('EXPORT_CURRENT_TERRITORY_RULES')}
              type={'button'}
              large={false}
              loading={isExportDataLoading}
              testId={'territoryOptimization-export-button'}
              onClick={() =>
                startExportData({
                  planningCycleId: selectedPlanningCycle?.id,
                  tableName: 'Territory Rules',
                  spInternalInput: {
                    territoryGroupId: +optimizationTargetTgId
                  }
                })
              }
            />
            <TextButton
              text={
                optimizationStatus?.length === 0
                  ? formatMessage('START_OPTIMIZATION')
                  : formatMessage('CONTINUE_OPTIMIZATION')
              }
              rightIcon={<ArrowRight />}
              intent="primary"
              type={'button'}
              large={false}
              disabled={optimizationStatus === null}
              loading={optimizationStatus === undefined}
              testId={'territoryOptimization-start-button'}
              onClick={() => {
                if (optimizationStatus.length === 0) {
                  setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.PARAMETERS);
                } else {
                  const fileMetaDataStatus = optimizationStatus[0].fileMetadata.status;
                  if (fileMetaDataStatus === 'pending') {
                    setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.OPTIMIZE_RESULTS);
                  } else {
                    setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.OPTIMIZE_TERRITORIES);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TerritoryOptimizationPage;
