import React from 'react';

import { IHeaderParams } from '@ag-grid-community/core';
import { Checkbox } from '@blueprintjs/core';

import { useGrid } from 'app/contexts/gridProvider';

import block from 'utils/bem-css-modules';

import style from './SelectAllCheckboxHeaderCellRenderer.module.pcss';

const b = block(style);

interface SelectAllCheckboxHeaderCellRendererProps extends IHeaderParams {
  displayName: string;
}

export const SelectAllCheckboxHeaderCellRenderer: React.FC<SelectAllCheckboxHeaderCellRendererProps> = ({
  displayName
}) => {
  const { isBulkDeleteChecked, setIsBulkDeleteChecked } = useGrid();

  const handleOnChange = () => {
    setIsBulkDeleteChecked((prev) => !prev);
  };

  return (
    <div className={b()}>
      <Checkbox
        className={b('checkbox')}
        checked={isBulkDeleteChecked}
        onChange={handleOnChange}
        data-testid="select-all-checkbox-header-checkbox"
      />
      <span data-testid="select-all-checkbox-header-text">{displayName}</span>
    </div>
  );
};

export default SelectAllCheckboxHeaderCellRenderer;
