import { useMemo } from 'react';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { useGetPins } from 'app/graphql/hooks/useGetPins';

import { PinFeatureCollection, PinSet } from 'app/models';

import { formatPinAsFeature } from 'utils/helpers/mapDataUtils';

export const useCustomPins = (
  availablePinSets: PinSet[]
): {
  pinFeatures: PinFeatureCollection;
  loading: boolean;
} => {
  const { selectedPinSetIds } = useDedicatedMapProvider();

  const selectedAvailablePinSetIds = useMemo(
    () => selectedPinSetIds.filter((selectedId) => availablePinSets.some(({ pinSetId }) => pinSetId === selectedId)),
    [selectedPinSetIds, availablePinSets]
  );

  const { data: pinData, loading } = useGetPins(selectedAvailablePinSetIds);

  const pinFeatures = useMemo(() => {
    const selectedIdSet = new Set(selectedAvailablePinSetIds);
    const featureCollection: PinFeatureCollection = { type: 'FeatureCollection', features: [] };
    pinData?.getPins?.forEach(({ pinSetId, pins }) => {
      if (!selectedIdSet.has(pinSetId)) return;
      const pinSet = availablePinSets.find((pinSet) => pinSet.pinSetId === pinSetId);
      if (!pinSet) return;

      // Intersection of selected, available, and loaded
      pins.forEach((pin) => featureCollection.features.push(formatPinAsFeature(pinSet, pin)));
    });
    return featureCollection;
  }, [pinData, availablePinSets, selectedAvailablePinSetIds]);

  return {
    pinFeatures,
    loading
  };
};
