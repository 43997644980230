// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JSGIS {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  background: var(--command-center-drawer-portal-backdrop);\n  overflow: auto;\n  font-stretch: normal;\n  font-style: normal;\n}\n\n  .YI2V7 {\n    padding: 40px 150px 50px;\n  }\n\n  .tOhep {\n    font-size: 28px;\n    font-weight: normal;\n    line-height: 1.29;\n    letter-spacing: -0.5px;\n  }\n\n  .h8Yag {\n    font-size: var(--ml);\n    font-weight: 600;\n    line-height: 1.4;\n    letter-spacing: normal;\n    padding-bottom: 15px;\n  }\n\n  .uDbo3 {\n    padding: 30px 0;\n  }\n\n  .-cGmM {\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserProfilePage": "JSGIS",
	"UserProfilePage_userProfileContent": "YI2V7",
	"UserProfilePage_userProfileHeader": "tOhep",
	"UserProfilePage_cardTitle": "h8Yag",
	"UserProfilePage_cardContainer": "uDbo3",
	"UserProfilePage_backBtn": "-cGmM " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["header-menu-btn"] + ""
};
export default ___CSS_LOADER_EXPORT___;
