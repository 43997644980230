import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { RenameDeploymentModel, RenameDeploymentModelVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const RENAME_DEPLOYMENT_MODEL = gql`
  mutation RenameDeploymentModel($deploymentModelId: Int!, $deploymentModelName: String) {
    updateDeploymentModel(input: { deploymentModelId: $deploymentModelId, deploymentModelName: $deploymentModelName }) {
      deploymentModelId
      deploymentModelName
    }
  }
`;

export const useRenameDeploymentModel = createUseMutation<RenameDeploymentModel, RenameDeploymentModelVariables>(
  RENAME_DEPLOYMENT_MODEL
);
