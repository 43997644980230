import React from 'react';

import { Download } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './DownloadQuotaFileUploadTemplate.module.pcss';

interface DownloadQuotaFileUploadTemplateProps {
  onClick: () => void;
}

const b = block(style);

const DownloadQuotaFileUploadTemplate: React.FC<DownloadQuotaFileUploadTemplateProps> = ({
  onClick
}: DownloadQuotaFileUploadTemplateProps) => {
  return (
    <div className={b('linkContainer')} data-testid="download-quota-template-container">
      <IconButton
        intent="primary"
        type="button"
        icon={<Download />}
        testId={'download-template-csv-icon'}
        title={formatMessage('DOWNLOAD_QUOTA_FILE_TEXT')}
      />
      <p className={b('linkText')} data-testid="csv-download-text" onClick={onClick}>
        {formatMessage('DOWNLOAD_QUOTA_FILE_TEXT')}
      </p>
    </div>
  );
};

export default DownloadQuotaFileUploadTemplate;
