import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Dialog } from '@blueprintjs/core';

import TextButton from 'components/Buttons/TextButton/TextButton';

import CoinsortButton from 'app/components/CoinsortButtons/CoinsortButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './BalancingPanel.module.pcss';

const b = block(style);

interface CoinsortDialogProps {
  onClose: () => void;
}

const CoinsortDialog: React.FC<CoinsortDialogProps> = ({ onClose }: CoinsortDialogProps): JSX.Element => (
  <Dialog isOpen portalClassName={b('')} onClose={onClose}>
    <div data-testid="coinsort-dialog">
      <h4 className={b('dialogTitle')}>{formatMessage('REBALANCING_COINSORT_HEADER')}</h4>
      <p className={b('coinsortDialogBody')}>{formatMessage('REBALANCING_COINSORT_BODY')}</p>
      <div className={b('footer')}>
        <div className={b('buttons')}>
          <TextButton text={formatMessage('LATER')} type="button" testId="later-button" onClick={onClose} />
          <CoinsortButton data-testid="coinsort-button" onClick={onClose} />
        </div>
      </div>
    </div>
  </Dialog>
);

export default CoinsortDialog;
