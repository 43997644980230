import { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLazyQuery, useMutation } from '@apollo/client';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';

import {
  ExportData,
  ExportDataInput,
  ExportDataStatusEnum,
  ExportDataVariables,
  ExportData_exportData,
  GetExportDataStatus,
  GetExportDataStatusVariables,
  SPInternalInput
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { EXPORT_DATA } from 'app/graphql/mutations/exportData';
import { GET_EXPORT_DATA_STATUS } from 'app/graphql/queries/getExportDataStatus';

import useShowToast from 'app/hooks/useShowToast';

import { ExportTableName } from 'app/models';

import { config } from 'utils/config';
import { formatMessage } from 'utils/messages/utils';

const downloadBlob = (blob: Blob, downloadedFileName: string) => {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = downloadedFileName;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );

  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
};

const downloadCsvFileFromS3 = async (exportData: ExportData_exportData, fileName: string): Promise<void> => {
  try {
    const { accessKey, secretAccessKey } = exportData.credentials;
    const { bucket, key } = exportData.location;

    const response = await new S3Client({
      credentials: {
        accessKeyId: accessKey,
        secretAccessKey
      },
      region: config.AWS_REGION
    }).send(
      new GetObjectCommand({
        Bucket: bucket,
        Key: key
      })
    );

    const blob = await new Response(response.Body as ReadableStream).blob();

    const csvBlob = blob.slice(0, blob.size, 'text/csv;charset=utf-8;');

    downloadBlob(csvBlob, `${fileName}.csv`);
  } catch (e) {
    const error = new Error('S3 upload error');
    if (e instanceof Error) {
      error.stack = e.stack;
      error.message = `${error.message}. ${e.message}`;
    }
    throw error;
  }
};

interface ExportDataInputParams {
  planningCycleId: number;
  tableName: ExportTableName;
  spInternalInput?: SPInternalInput;
}

interface UseExportDataProps {
  startExportData: (ExportDataInputParams) => void;
  exportDataStatus: GetExportDataStatus;
  isExportDataLoading: boolean;
}

export const useExportData = (): UseExportDataProps => {
  const [fileName, setFileName] = useState<string>('');
  const [exportData, setExportData] = useState<ExportData_exportData>();
  const [isExportDataLoading, setIsExportDataLoading] = useState<boolean>(false);
  const [getExportDataStatus, { data, stopPolling }] = useLazyQuery<GetExportDataStatus, GetExportDataStatusVariables>(
    GET_EXPORT_DATA_STATUS,
    { pollInterval: 2000 }
  );

  const showToast = useShowToast();

  const [exportDataMutation, { loading: exportingData }] = useMutation<ExportData, ExportDataVariables>(EXPORT_DATA, {
    onCompleted(result) {
      setExportData(result.exportData);
      getExportDataStatus({ variables: { input: { exportId: result.exportData.exportData.exportId } } });
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('EXPORTING_DATA_FAILED'), 'danger');
    }
  });

  const startExportData = (exportDataInputParams: ExportDataInputParams) => {
    const { planningCycleId, tableName, spInternalInput } = exportDataInputParams;
    setFileName(tableName);
    const input: ExportDataInput = {
      planningCycleId,
      tableName,
      ...(spInternalInput && { spInternalInput })
    };
    exportDataMutation({
      variables: {
        input
      }
    });
  };

  useEffect(() => {
    if (data?.getExportDataStatus?.status === ExportDataStatusEnum.completed) {
      downloadCsvFileFromS3(exportData, fileName);
      setIsExportDataLoading(false);
      stopPolling();
    }
  }, [data]);
  useEffect(() => {
    if (exportingData) {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('EXPORTING_DATA'), 'success');
      setIsExportDataLoading(true);
    }
  }, [exportingData]);
  return { startExportData, exportDataStatus: data, isExportDataLoading };
};
