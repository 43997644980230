import { useMemo, useEffect, useCallback } from 'react';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useUpsertFieldValues } from 'app/graphql/mutations/upsertFieldValues';

import { MeasureType } from 'app/models';

export const useUpsertAccountQuotaMeasureValue = (
  onSuccess: () => void
): {
  fieldId: number;
  handleUpsertFieldValues: (input: { accountId: number; measureId: number; measureValue: string }) => Promise<void>;
} => {
  const { selectedQuotaComponentId } = useBattleCard();
  const { measuresData, getMeasures } = usePlanTargets();
  const { selectedPlanningCycle } = useScope();
  const { selectedQuotaDrillInTerritory } = useGrid();
  const { territoryGroupId, ruleId } = selectedQuotaDrillInTerritory;

  useEffect(() => {
    getMeasures(selectedPlanningCycle.id);
  }, []);

  const fieldId = useMemo(() => {
    const quotaMeasure = measuresData?.find((measure) => measure.measureName === MeasureType.ACCOUNT_QUOTA);
    return quotaMeasure?.measureId;
  }, [measuresData]);

  const [upsertFieldValues] = useUpsertFieldValues();
  const handleUpsertFieldValues = useCallback(
    async (input: { accountId: number; measureId: number; measureValue: string }) => {
      await upsertFieldValues({
        variables: {
          input: [
            {
              quotaComponentId: selectedQuotaComponentId,
              territoryGroupId,
              ruleId,
              hierarchyId: input.accountId,
              fieldId: input.measureId,
              fieldValue: input.measureValue
            }
          ]
        }
      });
      onSuccess();
    },
    [selectedQuotaComponentId, territoryGroupId, ruleId]
  );

  return { fieldId, handleUpsertFieldValues };
};
