import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UnassignRuleHierarchies, UnassignRuleHierarchiesVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const UNASSIGN_RULE_HIERARCHIES = gql`
  mutation UnassignRuleHierarchies($input: UnassignRuleHierarchiesInput!) {
    unassignRuleHierarchies(input: $input) {
      success
    }
  }
`;

export const useUnassignRuleHierarchies = createUseMutation<UnassignRuleHierarchies, UnassignRuleHierarchiesVariables>(
  UNASSIGN_RULE_HIERARCHIES
);
