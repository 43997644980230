import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Classes } from '@blueprintjs/core';

import block from 'utils/bem-css-modules';

import style from './LoadingCellRenderer.module.pcss';

const b = block(style);

const COLUMN_WIDTH = 200;
const COLUMN_WIDTH_MINUS_PADDING = 184;
const MIN_SIZE = 100;

interface CompleteLoadingCellRendererProps extends ICellRendererParams {
  gridWidth: number;
}

interface SimpleLoadingCellRendererProps {
  gridWidth: number;
}

type LoadingCellRendererProps = CompleteLoadingCellRendererProps | SimpleLoadingCellRendererProps;

export const LoadingCellRenderer: React.FC<LoadingCellRendererProps> = ({ gridWidth }: LoadingCellRendererProps) => {
  const numberOfCells = Math.floor(gridWidth / COLUMN_WIDTH);

  const loadingCells = [];
  for (let currentCell = 0; currentCell < numberOfCells; currentCell++) {
    const cellWidth = MIN_SIZE + Math.random() * (COLUMN_WIDTH_MINUS_PADDING - MIN_SIZE);
    loadingCells.push(
      <div data-testid="loading-cell" key={`loadingCell${currentCell}`} className={b('gridLoading')}>
        <div
          className={Classes.SKELETON}
          style={{
            height: '100%',
            width: `${cellWidth}px`
          }}
        />
      </div>
    );
  }

  const remainingWidth = gridWidth - COLUMN_WIDTH * numberOfCells;
  if (remainingWidth > MIN_SIZE) {
    const widthBaseline = remainingWidth / 2;
    // minus 8 to create an 8px padding on the right
    const cellWidth = widthBaseline + Math.random() * widthBaseline - 8;
    loadingCells.push(
      <div data-testid="loading-cell" key={`loadingCell${numberOfCells}`} className={b('gridLoading')}>
        <div
          className={Classes.SKELETON}
          style={{
            height: '100%',
            width: `${cellWidth}px`
          }}
        />
      </div>
    );
  }

  return (
    <div className={b('gridCell')} data-testid="loading-row">
      {loadingCells}
    </div>
  );
};

export default LoadingCellRenderer;
