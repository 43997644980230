import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetCustomerAccountHierarchyGeocodingStatus,
  GetCustomerAccountHierarchyGeocodingStatusVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_CUSTOMER_ACCOUNT_HIERARCHY_GEOCODING_STATUS = gql`
  query GetCustomerAccountHierarchyGeocodingStatus($planningCycleId: Int!) {
    getCustomerAccountHierarchyGeocodingStatus(input: { planningCycleId: $planningCycleId }) {
      summary {
        numOfAccountsWithLinkedGeoKey
        numOfAccountsWithUnmatchedLinkedGeoKey
        numOfAccountsWithUnmatchedVisualGeoKey
        numOfAccountsWithVisualGeoKey
        numOfAccountsWithoutVisualGeoKey
        numOfAccountsWithoutLinkedGeoKey
        numOfAccountsWithVisualWithoutLinkedGeoKey
      }
    }
  }
`;

export const useGetCustomerAccountHierarchyGeocodingStatus = createUseQuery<
  GetCustomerAccountHierarchyGeocodingStatus,
  GetCustomerAccountHierarchyGeocodingStatusVariables
>(GET_CUSTOMER_ACCOUNT_HIERARCHY_GEOCODING_STATUS);
