import { MapboxGeoJSONFeature } from 'mapbox-gl';

import {
  HierarchyPolygons,
  PolygonDetails,
  StructuredCloneableFeature,
  PinSet,
  PinFeature,
  GeoBounds
} from 'app/models';

import { BoundsCalculator } from './BoundsCalculator';
import { CountrySourceCatalogEntry } from './countrySourceCatalogHelpers';
import { optimizeNativePolygonIdForHashing } from './polygonIdHelpers';
import { SpatialIndex } from './SpatialIndex';

interface HierarchyPolygonLookups {
  polygonTable: ReadonlyMap<PolygonDetails['polygonId'], PolygonDetails>;
  geoTable: ReadonlyMap<PolygonDetails['geoId'], PolygonDetails>;
  geographyNameMap: ReadonlyMap<PolygonDetails['geoId'], PolygonDetails['name']>;
  polygonNameMap: ReadonlyMap<PolygonDetails['polygonId'], PolygonDetails['name']>;
  pointIndexes: SpatialIndex<PolygonDetails['polygonId']>[];
  bounds: GeoBounds;
}

export const createHierarchyPolygonLookups = (
  hierarchyPolygonPages: HierarchyPolygons[],
  leafCatalogMap: ReadonlyMap<string, CountrySourceCatalogEntry>
): HierarchyPolygonLookups => {
  const duplicatePolygonIds = new Set<string>();
  const boundsCalculator = new BoundsCalculator();

  const lookups = {
    polygonTable: new Map<string, PolygonDetails>(),
    geoTable: new Map<number, PolygonDetails>(),
    polygonNameMap: new Map<string, string>(),
    geographyNameMap: new Map<number, string>()
  };

  const pointIndexMap = new Map<string, SpatialIndex<string>>(
    [...leafCatalogMap.values()].map((catalogEntry) => [
      catalogEntry.country,
      new SpatialIndex(SpatialIndex.widthFromCount(catalogEntry.polygonCount))
    ])
  );

  for (const hierarchyPolygons of hierarchyPolygonPages) {
    const rowCount = hierarchyPolygons?.hierarchyId.length ?? 0;
    for (let index = 0; index < rowCount; index += 1) {
      const name = hierarchyPolygons.name[index];
      const hierarchyId = hierarchyPolygons.hierarchyId[index];
      const nativePolygonid = hierarchyPolygons.polygonId[index];
      const polygonId = optimizeNativePolygonIdForHashing(nativePolygonid);
      const center = hierarchyPolygons.center[index];
      const country = hierarchyPolygons.country[index];
      const sourceKey = hierarchyPolygons.sourceKey[index];

      // Disable polygon if a duplicate
      if (lookups.polygonTable.has(polygonId) || duplicatePolygonIds.has(polygonId)) {
        duplicatePolygonIds.add(polygonId);
        lookups.polygonTable.delete(polygonId);
        lookups.geoTable.delete(hierarchyId);
        continue;
      }

      const catalogEntry = leafCatalogMap.get(country);
      if (!catalogEntry || catalogEntry.sourceKey !== sourceKey) {
        // Omit non-leafs from data
        continue;
      }

      const polygonDetails: PolygonDetails = {
        geoId: hierarchyId,
        name,
        polygonId,
        country,
        center,
        featureStateIds: { id: nativePolygonid, source: catalogEntry.sourceId, sourceLayer: catalogEntry.sourceId }
      };
      lookups.polygonTable.set(polygonId, polygonDetails);
      lookups.geoTable.set(hierarchyId, polygonDetails);
      lookups.polygonNameMap.set(polygonId, name);
      lookups.geographyNameMap.set(hierarchyId, name);

      const pointIndex = pointIndexMap.get(country)!;
      const point = {
        lon: center[0],
        lat: center[1],
        value: polygonId
      };
      pointIndex.add(point);
      boundsCalculator.addPoint(point);
    }
  }

  if (duplicatePolygonIds.size > 0) {
    console.warn(`Hierarchy contains ${duplicatePolygonIds.size} duplicate polygons`, duplicatePolygonIds);
  }

  return {
    ...lookups,
    pointIndexes: [...pointIndexMap.values()],
    bounds: boundsCalculator.getBounds()
  };
};

export const asStructuredFeature = (mapboxFeature: MapboxGeoJSONFeature): StructuredCloneableFeature => ({
  id: optimizeNativePolygonIdForHashing(mapboxFeature.id),
  // By default the geometry data does not survive the structured clone
  // By pulling it onto an object literal it works as expected
  geometry: mapboxFeature.geometry as StructuredCloneableFeature['geometry']
});
interface Pin {
  name: string;
  position: { lat: number; lon: number };
}
export const formatPinAsFeature = (pinSet: PinSet, pin: Pin): PinFeature => ({
  type: 'Feature',
  geometry: { type: 'Point', coordinates: [pin.position.lon, pin.position.lat] },
  properties: {
    pinSetId: pinSet.pinSetId,
    name: pin.name,
    icon: pinSet.icon,
    color: pinSet.color
  }
});
