// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9sxKH {\n  display: grid;\n  grid-template-columns: 1fr var(--im);\n  width: 100%;\n}\n\n  .PNafp {\n    margin: auto 0;\n    text-align: left;\n    font-weight: normal;\n    font-size: var(--data-drill-in-header-font-size);\n  }\n\n  .Bp3FQ .bp3-popover2-target {\n      display: flex;\n      height: 100%;\n      align-items: center;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SheetsFieldHeaderCellRenderer": "_9sxKH",
	"SheetsFieldHeaderCellRenderer_headerTextContainer": "PNafp",
	"SheetsFieldHeaderCellRenderer_menuCellPopover": "Bp3FQ"
};
export default ___CSS_LOADER_EXPORT___;
