// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gVe-k {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n  }\n\n    .gVe-k .ag-cell {\n      overflow: visible !important;\n      background: var(--white);\n    }\n\n    /* reset padding to zero and let headerClass handle the styling */\n\n    .gVe-k .ag-header-cell {\n      padding: 0;\n    }\n\n    .gVe-k .ag-theme-alpine .ag-ltr .ag-cell {\n      border: none !important;\n    }\n  ._6BSF5 {\n    padding: 0 0 0 var(--grid-header-padding) !important;\n  }\n  .OPD9a {\n    display: inline-grid;\n    justify-content: flex-end;\n  }\n  .kYoD3 {\n  }\n  .l5tXn {\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryQuotaDrillInGrid_advancedGridWrapper": "gVe-k",
	"TerritoryQuotaDrillInGrid_header": "_6BSF5",
	"TerritoryQuotaDrillInGrid_rightAlignedCell": "OPD9a",
	"TerritoryQuotaDrillInGrid_gridOverlayContainer": "kYoD3 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-container"] + "",
	"TerritoryQuotaDrillInGrid_gridOverlayText": "l5tXn " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-text"] + ""
};
export default ___CSS_LOADER_EXPORT___;
