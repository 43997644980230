// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".prsve .bp3-dialog {\n    width: 800px;\n    box-sizing: border-box;\n    padding-bottom: 0;\n    box-shadow: var(--elevation-2);\n    border-radius: var(--dialog-border-radius);\n  }\n  .prsve .bp3-heading {\n    font-weight: normal;\n    font-size: 24px !important;\n    color: rgb(var(--color-dark-gray-3));\n  }\n  .prsve .bp3-dialog-header {\n    height: var(--battle-card-designer-header-height);\n    color: rgb(var(--color-dark-gray-3));\n  }\n  .prsve .bp3-overlay-backdrop {\n    background-color: rgba(var(--color-dark-gray-3), 0.4);\n  }\n  .prsve .bp3-overlay.bp3-overlay-scroll-container.bp3-overlay-inline {\n    position: fixed;\n  }\n  ._1Id0C:not(:first-child) {\n      margin-left: 18px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MultiStepDialog": "prsve",
	"MultiStepDialog_button": "_1Id0C"
};
export default ___CSS_LOADER_EXPORT___;
