import React from 'react';

import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { Checkbox } from '@blueprintjs/core';
import clonedeep from 'lodash.clonedeep';

import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { DeploymentModelTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { PermissionPerType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { PermissionFunctionality } from 'utils/permissions/userActions';

import style from './PermissionCheckboxCellRenderer.module.pcss';

const b = block(style);

interface PermissionCheckboxCellRendererProps extends ICellRendererParams {
  params: ICellRendererParams;
}

const updateViewPermission = (node: RowNode, functionality: string, viewPermissionValue: boolean): void => {
  const PERMISSION_TYPE = 'view';

  if (viewPermissionValue) {
    //1 if node selected === child
    if (functionality !== DeploymentModelTypeEnum.Plan && functionality !== DeploymentModelTypeEnum.Manage) {
      node.parent.setDataValue(
        'permissions',
        createUpdatedPermission(node.parent.data.permissions, true, PERMISSION_TYPE)
      );
      node.parent.allLeafChildren.forEach((child) => {
        child.setDataValue('permissions', createUpdatedPermission(child.data.permissions, true, PERMISSION_TYPE));
      });
    }
    //2 if node selected === parent
    else {
      node.allLeafChildren.forEach((child) => {
        child.setDataValue('permissions', createUpdatedPermission(child.data.permissions, true, PERMISSION_TYPE));
      });
    }
  } else {
    //3 if node deselected has children, then deselect all of them
    if (functionality === DeploymentModelTypeEnum.Plan || functionality === DeploymentModelTypeEnum.Manage) {
      node.allLeafChildren.forEach((child) => {
        child.setDataValue('permissions', createUpdatedPermission(child.data.permissions, false, PERMISSION_TYPE));
      });
    }

    //4 if node deselected has parent, then set parent to false
    else {
      node.parent.setDataValue(
        'permissions',
        createUpdatedPermission(node.parent.data.permissions, false, PERMISSION_TYPE)
      );

      node.parent.allLeafChildren.forEach((child) => {
        const permissionWithUpdatedViewValue = createUpdatedPermission(child.data.permissions, false, 'view');
        const permissionWithUpdatedEditValue = createUpdatedPermission(permissionWithUpdatedViewValue, false, 'edit');

        child.setDataValue('permissions', permissionWithUpdatedEditValue);
      });
    }
  }
};

const createUpdatedPermission = (originalPermissions: PermissionPerType, newValue: boolean, type: 'view' | 'edit') => {
  const newPermissions = clonedeep(originalPermissions);

  newPermissions[type] = newValue;

  return newPermissions;
};

const updateEditPermission = (node: RowNode, functionality: string, editPermissionValue: boolean): void => {
  const PERMISSION_TYPE = 'edit';

  if (editPermissionValue) {
    //1 if node selected has parent, then check if parent has other children
    //if all other children are selected, then set parent to true
    if (functionality !== DeploymentModelTypeEnum.Plan && functionality !== DeploymentModelTypeEnum.Manage) {
      if (
        node.parent?.allLeafChildren?.length === 1 ||
        node.parent.allLeafChildren.every(
          (child) =>
            node.key === child.key || isCheckboxGroupKey(child.key) || child.data?.permissions[PERMISSION_TYPE] === true
        )
      ) {
        node.parent.setDataValue(
          'permissions',
          createUpdatedPermission(node.parent.data.permissions, true, PERMISSION_TYPE)
        );
      }
    }
    //2 if node selected has children, select all of them
    else {
      node.allLeafChildren.forEach((child) => {
        child.setDataValue('permissions', createUpdatedPermission(child.data.permissions, true, PERMISSION_TYPE));
      });
    }
  } else {
    //3 if node deselected has children, then deselect all of them
    if (functionality === DeploymentModelTypeEnum.Plan || functionality === DeploymentModelTypeEnum.Manage) {
      node.allLeafChildren.forEach((child) => {
        child.setDataValue('permissions', createUpdatedPermission(child.data.permissions, false, PERMISSION_TYPE));
      });
    }

    //4 if node deselected has parent, then set parent to false
    else {
      node.parent.setDataValue(
        'permissions',
        createUpdatedPermission(node.parent.data.permissions, false, PERMISSION_TYPE)
      );
    }
  }
};

const updatePermissionInMap = (node: RowNode, functionality: string, permissions: PermissionPerType): void => {
  updateViewPermission(node, functionality, permissions.view);
  updateEditPermission(node, functionality, permissions.edit);
};

const isCheckboxGroupKey = (nodeKey: string): boolean => {
  return ([DeploymentModelTypeEnum.Plan, DeploymentModelTypeEnum.Manage] as string[]).includes(nodeKey);
};

export const HIDDEN_EDIT_FUNCTIONALITY_KEYS: PermissionFunctionality[] = [
  PermissionFunctionality.MAPS,
  PermissionFunctionality.TERRITORY_DEFINITION_AND_ASSIGNMENT
];

export const PermissionCheckboxCellRenderer: React.FC<PermissionCheckboxCellRendererProps> = ({
  params
}: PermissionCheckboxCellRendererProps) => {
  const { value, setValue } = params;

  const handleViewPermissionUpdate = () => {
    const newViewPermission = !value?.view;

    updatePermissionInMap(params.node, params.node?.key, { view: newViewPermission, edit: false });
    setValue({ view: newViewPermission, edit: false });
  };

  const handleEditPermissionUpdate = () => {
    const newEditPermission = !value?.edit;

    updatePermissionInMap(params.node, params.node?.key, { view: true, edit: newEditPermission });
    setValue({ view: true, edit: newEditPermission });
  };

  return (
    <div className={b('permissionCell')} data-testid="permission-cells">
      <div>
        <MessageTooltip
          content={!value?.view ? formatMessage('ROLE_PERMISSIONS_TABLE_VIEW_CHECKBOX_TOOLTIP') : null}
          target={
            <Checkbox
              disabled={!isCheckboxGroupKey(params.node?.key)}
              checked={value?.view === true}
              onChange={handleViewPermissionUpdate}
              data-testid="view-checkbox"
            />
          }
          placement={'top'}
        />
      </div>
      <div>
        <MessageTooltip
          content={!value?.view ? formatMessage('ROLE_PERMISSIONS_TABLE_EDIT_CHECKBOX_TOOLTIP') : null}
          target={
            <Checkbox
              className={b('', {
                hidden: (HIDDEN_EDIT_FUNCTIONALITY_KEYS as string[]).includes(params.node?.data.functionalityKey)
              })}
              onChange={handleEditPermissionUpdate}
              checked={value?.edit === true}
              data-testid="edit-checkbox"
            />
          }
          placement={'top'}
        />
      </div>
    </div>
  );
};

export default PermissionCheckboxCellRenderer;
