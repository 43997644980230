import { MeasureType } from 'app/models';

export type FallbackMeasure<T> = T & { measureId: Readonly<number>; measureName: Readonly<string> };

/**
 * Returns the element of the array `measures` whose `measureName` is "Prior Year Actual" or "Trailing 12 Months"
 *
 * @export
 * @template T
 * @param {{
 *   measures: Readonly<Array<FallbackMeasure<T>>>;
 * }} {
 *   measures
 * }
 * @return {*}  {(Readonly<FallbackMeasure<T>> | undefined)}
 */
export function fallbackSheetMeasure<T>({
  measures
}: {
  measures: Readonly<Array<FallbackMeasure<T>>> | undefined;
}): Readonly<FallbackMeasure<T>> | undefined {
  return (
    measures?.find((x) => x.measureName === MeasureType.PRIOR_YEAR_ACTUAL) ??
    measures?.find((x) => x.measureName === MeasureType.TRAILING_TWELVE_MONTHS)
  );
}

/**
 * Produce a fallback balancing metric given an array of measures
 *
 * @export
 * @template T
 * @param {{
 *   measures: Readonly<Array<FallbackMeasure<T>>>;
 * }} {
 *   measures
 * }
 * @return {*}  {{
 *   key: Readonly<number>;
 *   value: Readonly<string>;
 * }}
 */
export function fallbackRebalancingMetric<T>({
  measures
}: {
  measures: Readonly<Array<FallbackMeasure<T>>> | undefined;
}): {
  key: Readonly<number>;
  value: Readonly<string>;
} {
  const sheetMeasure = fallbackSheetMeasure({ measures });

  return {
    key: sheetMeasure?.measureId,
    value: sheetMeasure?.measureName
  };
}
