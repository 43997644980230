import React from 'react';

import { ChevronLeft } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import PasswordManagementCard from 'app/components/Cards/PasswordManagementCard/PasswordManagementCard';
import UserInformationCard from 'app/components/Cards/UserInformationCard/UserInformationCard';
import CyclesPageHeader from 'app/components/CyclesPageHeader/CyclesPageHeader';
import GenerateApiKeyCard from 'app/components/GenerateApiKeyCard/GenerateApiKeyCard';
import UserMenu from 'app/components/UserMenu/UserMenu';

import { useUser } from 'app/core/userManagement/userProvider';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UserProfilePage.module.pcss';

const b = block(style);

const UserProfilePage: React.FC = () => {
  const { userProfile } = useUser();

  const UserInformation = () => {
    return (
      <div className={b('cardContainer')}>
        <div className={b('cardTitle')}>{formatMessage('PROFILE_PAGE_PERSONAL_INFORMATION')}</div>
        <UserInformationCard userProfile={userProfile} />
      </div>
    );
  };

  const Password = () => {
    return (
      <div className={b('cardContainer')}>
        <div className={b('cardTitle')}>{formatMessage('PASSWORD')}</div>
        <PasswordManagementCard userProfile={userProfile} />
      </div>
    );
  };

  const GenerateApiKey = () => {
    return (
      <div className={b('cardContainer')}>
        <div className={b('cardTitle')}>{formatMessage('GENERATE_API_KEY')}</div>
        <GenerateApiKeyCard userProfile={userProfile} />
      </div>
    );
  };

  const homeBackButton = (
    <IconButton
      type="button"
      icon={<ChevronLeft size={24} />}
      title={formatMessage('BACK')}
      testId={'user-profile-back-btn'}
      className={b('backBtn')}
    />
  );

  return (
    <div className={b()} data-testid="user-profile-page">
      <CyclesPageHeader logoPath="" logoButton={homeBackButton} rightActionItems={[<UserMenu />]} hideActions />
      <div className={b('userProfileContent')}>
        <div className={b('userProfileHeader')}>{formatMessage('USER_PROFILE')}</div>
        <UserInformation />
        <Password />
        <GenerateApiKey />
      </div>
    </div>
  );
};

export default UserProfilePage;
