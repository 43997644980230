import React, { ReactNode } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Classes, Dialog as BlueprintDialog, DialogProps as Props, Spinner } from '@blueprintjs/core';
import { HTMLHeading } from '@varicent/components';

import DialogFooter, { DialogFooterProps } from 'components/Dialog/DialogFooter/DialogFooter';
import EllipsisText from 'components/EllipsisText/EllipsisText';

import block from 'utils/bem-css-modules';

import style from './Dialog.module.pcss';

const b = block(style);

export interface DialogProps extends Props, DialogFooterProps {
  title: string;
  isOpen: boolean;
  children?: ReactNode;
  isLoading?: boolean;
  spinnerElement?: JSX.Element;
  size?: 'small' | 'default' | 'auto';
  showOverflow?: boolean;
  bodyMinHeight?: number;
  showDialogFooter?: boolean; // must set to false if providing a custom footer
  isEllipsisHeading?: boolean;
}

const Dialog: React.FC<DialogProps> = ({
  title,
  children,
  isLoading = false,
  isOpen,
  size = 'default',
  spinnerElement,
  showOverflow = false,
  bodyMinHeight = 200,
  showDialogFooter = true,
  isEllipsisHeading = false,
  ...rest
}: DialogProps) => {
  const renderSpinner = spinnerElement || (
    <div data-testid="dialog-loading-spinner" className={b('spinnerContainer')}>
      <Spinner intent="primary" size={40} />
    </div>
  );
  return (
    <BlueprintDialog
      isOpen={isOpen}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      portalClassName={b()}
      className={b('', { small: size === 'small', auto: size === 'auto' })}
      {...rest}
    >
      {isLoading ? (
        renderSpinner
      ) : (
        <>
          <div className={Classes.DIALOG_HEADER}>
            {isEllipsisHeading ? (
              <EllipsisText text={title} data-testid="dialog-ellipsis-title" className={b('ellipsisHeading')} />
            ) : (
              <HTMLHeading tagLevel="h3" text={title} data-testid="dialog-title" />
            )}
          </div>
          <div
            className={b('dialogBody', { showOverflow })}
            style={{ minHeight: bodyMinHeight }}
            data-testid="dialog-body"
          >
            {children}
            {showDialogFooter && <DialogFooter data-testid="dialog-footer" {...rest} />}
          </div>
        </>
      )}
    </BlueprintDialog>
  );
};

export default Dialog;
