import React from 'react';

import { LocationPerson, ChevronDown } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import Popover from 'components/Popover/Popover';

import { useIsMapCapable } from 'app/components/TerritoryMap/hooks/useMapCapability';
import PinMenuV2 from 'app/components/TerritoryMap/MapToolbars/PinMenuV2/PinMenuV2';

import { PinSet } from 'app/models';

import block from 'utils/bem-css-modules';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';
import { formatMessage } from 'utils/messages/utils';

import style from './PinsButtonV2.module.pcss';

const b = block(style);

export interface PinsButtonV2Props {
  onAddPinSetClick: () => void;
  pinSets: PinSet[];
  loadingCustomPins: boolean;
  loadingPinSets: boolean;
  loadingCustomerFeatures: boolean;
  isMapLoaded: boolean;
}

const PinsButtonV2: React.FC<PinsButtonV2Props> = ({
  onAddPinSetClick,
  pinSets,
  loadingCustomPins,
  loadingPinSets,
  loadingCustomerFeatures,
  isMapLoaded
}) => {
  const isEditCustomPinsEnabled = useIsMapCapable(MapCapability.EDIT_CUSTOM_PINS);
  const isViewCustomPinsEnabled = useIsMapCapable(MapCapability.VIEW_CUSTOM_PINS);
  const isViewCustomerPinsEnabled = useIsMapCapable(MapCapability.VIEW_CUSTOMER_PINS);
  const isPinMenuVisible = isViewCustomPinsEnabled || isViewCustomerPinsEnabled || isEditCustomPinsEnabled;

  if (!isPinMenuVisible) return null;

  return (
    <Popover
      disabled={!isMapLoaded}
      content={
        <PinMenuV2
          pinSets={pinSets}
          isPinSetsLoading={loadingPinSets}
          isLoadingCustomFeatures={loadingCustomPins}
          isLoadingCustomerFeatures={loadingCustomerFeatures}
          onAddPinSet={onAddPinSetClick}
        />
      }
      placement={'bottom-end'}
      minimal={true}
      className={b('pinMenuPopover')}
    >
      <IconButton
        disabled={!isMapLoaded}
        type="button"
        icon={
          <>
            <LocationPerson />
            <ChevronDown />
          </>
        }
        testId={'pin-menu-trigger'}
        tooltipText={formatMessage('PIN_TOOLTIP')}
      />
    </Popover>
  );
};

export default PinsButtonV2;
