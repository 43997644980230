// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yl9Ks {\n  display: flex;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n}\n\n  .Pgf6C {\n    width: auto;\n    height: 700px;\n  }\n\n  .dx\\+YC {\n    height: 540px;\n    width: 750px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritorySearch": "Yl9Ks",
	"TerritorySearch_searchTerritoriesGrid": "Pgf6C",
	"TerritorySearch_fullWidthGrid": "dx+YC"
};
export default ___CSS_LOADER_EXPORT___;
