import React, { useMemo } from 'react';

import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import TerritorySearchableSelect from 'app/components/AdvancedGrid/Sheets/Account/TerritorySearchableSelect';
import FormDatePicker from 'app/components/FormFields/FormDatePicker/FormDatePicker';

import { useScope } from 'app/contexts/scopeProvider';

import useShowToast from 'app/hooks/useShowToast';

import { AccountMoveVariables } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AccountMoveDialog.module.pcss';
import {
  calculateRedirectDates,
  formatDateMonthDayYear,
  formatDateYearMonthDay,
  getPlanningCycleEndDate
} from './AccountQuotaUtils';
import { redirectAccountUnit } from './AccountSheetUtils';

const b = block(style);

interface AccountMoveDialogProps {
  accountMoveVariables: AccountMoveVariables;
  onClose: () => void;
  territoryGroupTypeId: number;
  onMoveSuccess: () => void;
}

const AccountMoveDialog: React.FC<AccountMoveDialogProps> = ({
  accountMoveVariables,
  onClose,
  territoryGroupTypeId,
  onMoveSuccess
}) => {
  const showToast = useShowToast();
  const { selectedPlanningCycle } = useScope();
  const { planningCycleStartDate, planningCycleDuration } = selectedPlanningCycle;
  const {
    accountId,
    sourceRuleId,
    redirects,
    accountName,
    sourceTerritoryId,
    targetTerritoryId,
    targetRuleId,
    sourceRule
  } = accountMoveVariables;

  const minDate: Date = useMemo(() => {
    if (sourceRule.effectiveDate && dayjs(sourceRule.effectiveDate).isAfter(planningCycleStartDate)) {
      return dayjs(sourceRule.effectiveDate).toDate();
    } else {
      return dayjs(planningCycleStartDate).toDate();
    }
  }, [planningCycleStartDate]);

  const maxDate: Date = useMemo(() => {
    const endDate = dayjs(sourceRule.endDate);
    if (
      sourceRule.endDate &&
      endDate.isBefore(getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration))
    ) {
      return endDate.toDate();
    } else {
      return dayjs(getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration)).toDate();
    }
  }, [planningCycleStartDate]);

  const handleSubmit = async (value) => {
    try {
      const { territory, startDate } = value;

      const { newRedirects, redirectIdsToRemove } = calculateRedirectDates(
        formatDateYearMonthDay(startDate),
        redirects,
        dayjs(maxDate).format('YYYY-MM-DD'),
        territory.value
      );

      await redirectAccountUnit({
        accountId,
        sourceRuleId,
        targets: newRedirects,
        redirectIdsToRemove
      });
      const targetText =
        targetTerritoryId !== formatMessage('UNASSIGNED_TERRITORY')
          ? targetTerritoryId
          : formatMessage('UNASSIGNED_LOWERCASE');
      const toastText = formatMessage('ACCOUNT_MOVE_DESCRIPTION_2', {
        accountName,
        sourceTerritoryId,
        targetText,
        startDate: formatDateMonthDayYear(value.startDate)
      });

      showToast(<ToastMessage title={formatMessage('ACCOUNT_MOVE_SCHEDULED')} message={toastText} />, 'success');
      onMoveSuccess();
    } catch (_error) {
      showToast(formatMessage('CREATE_REDIRECT_ERROR'), 'danger');
    } finally {
      onClose();
    }
  };

  const existingRedirectStartDates = useMemo(() => {
    return redirects.map((redirect) => dayjs(redirect.startDate).toDate());
  }, [redirects]);

  return (
    <Formik
      initialValues={{
        territory: {
          key: targetTerritoryId,
          value: targetRuleId
        },
        startDate: ''
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit, values, isSubmitting }) => {
        return (
          <Dialog
            isOpen
            onClose={onClose}
            onSubmit={handleSubmit}
            disableConfirm={!values.startDate || !values.territory}
            confirmButtonText={formatMessage('ACCOUNT_MOVE_DIALOG_CONFIRM')}
            confirmButtonLoading={isSubmitting}
            title={formatMessage('ACCOUNT_MOVE_DIALOG_TITLE', { accountName })}
            bodyMinHeight={0}
            size="small"
            showOverflow
            isEllipsisHeading
            data-testid="account-move-dialog"
            children={
              <Form>
                <div>
                  <div>{formatMessage('ACCOUNT_MOVE_DIALOG_BODY')}</div>
                  <div className={b('dropdownWrapper')}>
                    <div className={b('dropdownTitle')}>{formatMessage('TERRITORY_REQUIRED_MARK')}</div>
                    <Field
                      name="territory"
                      component={TerritorySearchableSelect}
                      territoryGroupId={territoryGroupTypeId}
                      shouldIncludeUnassignItem
                    />
                  </div>
                  <div className={b('dropdownWrapper')}>
                    <div className={b('dropdownTitle')}>{formatMessage('START_DATE_REQUIRED_MARK')}</div>
                    <Field
                      name="startDate"
                      component={FormDatePicker}
                      showCalendarIcon
                      data-testid="form-date-picker"
                      showErrors={false}
                      fullWidth
                      placeholder={formatMessage('DATE_FORMAT_PLACEHOLDER')}
                      minDate={minDate}
                      maxDate={maxDate}
                      excludeDates={existingRedirectStartDates}
                    />
                  </div>
                </div>
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default AccountMoveDialog;
