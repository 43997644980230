import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { PERIODIC_MEASURES } from 'app/graphql/fragments/periodicMeasures';
import {
  GetTerritoryQuotaDistribution,
  GetTerritoryQuotaDistributionVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const QUERY_GET_TERRITORY_QUOTA_DISTRIBUTION = gql`
  ${PERIODIC_MEASURES}
  query GetTerritoryQuotaDistribution($input: GetTerritoryQuotaInput!) {
    getTerritoryQuota(input: $input) {
      quotaDistributionByHierarchiesTotalCount
      periodicQuotaDistribution {
        ruleId
        measures {
          ...PeriodicMeasuresFragment
        }
        hierarchies {
          key
          name
          hierarchyId
          hierarchyType
          rootHierarchyId
          rootKey
          rootName
        }
      }
    }
  }
`;

export const useGetTerritoryQuotaDistribution = createUseQuery<
  GetTerritoryQuotaDistribution,
  GetTerritoryQuotaDistributionVariables
>(QUERY_GET_TERRITORY_QUOTA_DISTRIBUTION);
