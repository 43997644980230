import * as yup from 'yup';

import { formatMessage } from 'utils/messages/utils';

export default yup.object().shape({
  employeeId: yup.string().max(256).required(formatMessage('ENTER_EMPLOYEE_ID')),
  emailAddress: yup
    .string()
    .max(256)
    .email(formatMessage('INVALID_EMAIL'))
    .required(formatMessage('ENTER_EMAIL'))
    .label(formatMessage('EMAIL')),
  firstName: yup.string().max(256).required(formatMessage('ENTER_FIRST_NAME')).label(formatMessage('FIRST_NAME')),
  lastName: yup.string().max(256).required(formatMessage('ENTER_LAST_NAME')).label(formatMessage('LAST_NAME')),
  jobTitle: yup.string(),
  systemRoleName: yup.object().shape({
    key: yup.string().required(),
    value: yup.string().required()
  })
});
