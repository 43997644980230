import gql from 'graphql-tag';

export const GET_SHEET_DEFINITIONS = gql`
  query GetSheetDefinitions($deploymentModelId: Int!, $sheetId: Int, $isTQM: Boolean) {
    getDeploymentModelSpec(input: { deploymentModelId: $deploymentModelId, sheetId: $sheetId, isTQM: $isTQM }) {
      dataSheets {
        isTQM
        sheetDefinitions {
          fieldId
          fieldName
          measureId
          measureName
          measureCategory
          measureName
          measureSource
          measureFieldType
          measureFormatType
          measureFormula
          measureConstraints
          startDate
          endDate
          defaultMeasureId
          quotaComponents {
            quotaComponentId
            quotaComponentName
            roles {
              editable
              roleId
              roleName
              sheetACLId
              visible
            }
          }
          sheetDefinitionId
          sheetDefinitionType
          years {
            year
            periodicities
          }
        }
      }
    }
  }
`;
