import React from 'react';

import { MenuItemProps as BlueprintMenuItemProps } from '@blueprintjs/core';
// eslint-disable-next-line no-restricted-imports
import { MenuItem as VaricentMenuItem } from '@varicent/components';

// Force require a data-testid
type MenuItemProps = Omit<BlueprintMenuItemProps, 'icon'> & {
  'data-testid': string;
  // Disallow icons by name, they must come in as JSX
  icon?: Exclude<BlueprintMenuItemProps['icon'], string>;
};

export const MenuItem = (props: MenuItemProps) => <VaricentMenuItem {...props} />;
