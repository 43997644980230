import gql from 'graphql-tag';

export const GET_SYMON_ACCOUNT = gql`
  query GetSymonAccount($tenantId: Int!) {
    getSymonAccount(input: { tenantId: $tenantId }) {
      tenantId
      symonAccountName
      symonAccountEmailAddress
      symonAccountKey
      symonDomain
      symonUiClientKey
      symonAccountCreatedAt
    }
  }
`;
