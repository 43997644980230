/**
 * Represents a map that takes multiple keys to retrieve a value
 * The number and type of keys must be static and known at compile time
 * Constructor with defined tuple type, or explicit generics must always be used
 */
export class MultiKeyMap<KeysType extends UnknownTuple, ValueType = never> {
  private readonly root = new Map();

  constructor(entries?: [...KeysType, ValueType][]) {
    entries?.forEach((entry) => this.set(...entry));
  }

  public get(...keys: KeysType): ValueType {
    let node = this.root;
    for (const key of keys) {
      node = node?.get(key);
    }
    return node as unknown as ValueType;
  }

  public has(...keys: KeysType): boolean {
    let node = this.root;
    for (const key of keys) {
      if (!node.has(key)) return false;
      node = node.get(key);
    }
    return true;
  }

  public set(...args: [...KeysType, ValueType]): void {
    const value = args.pop();
    const lastKey = args.pop();
    const initialKeys = args;

    let node = this.root;
    for (const key of initialKeys) {
      let nextNode = node.get(key);
      if (!nextNode) {
        nextNode = new Map();
        node.set(key, nextNode);
      }
      node = nextNode;
    }
    node.set(lastKey, value);
  }

  public delete(...keys: KeysType): boolean {
    const lastKey = keys.pop();
    const initialKeys = keys;

    let node = this.root;
    for (const key of initialKeys) {
      node = node.get(key);
      if (!node) return false;
    }
    return node.delete(lastKey);
  }

  public clear(): void {
    this.root.clear();
  }
}

// Used instead of Array<unknown> to prevent non-tuple array usage
type UnknownTuple =
  | [unknown, unknown]
  | [unknown, unknown, unknown]
  | [unknown, unknown, unknown, unknown]
  | [unknown, unknown, unknown, unknown, unknown];
