import React from 'react';

import { Switch } from 'react-router-dom';

import { RoutePaths } from 'app/containers/App/Router/routePaths';
import Login from 'app/containers/Login/Login';

import UnauthenticatedRoute from './UnauthenticatedRoute/UnauthenticatedRoute';

export const AuthRoutes: React.FC = ({ children }) => {
  return (
    <Switch>
      <UnauthenticatedRoute exact path={RoutePaths.LOGIN} component={Login} data-testid="login-route" />
      {children}
    </Switch>
  );
};
