// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mStTl {\n  width: 100%;\n  height: 100%;\n}\n\n  .w8x4e {\n    background-color: rgb(var(--color-light-gray-5));\n    padding-top: 30px;\n    display: grid;\n    height: 100%;\n    grid-template: '. content .' / 1fr 2fr 1fr;\n  }\n\n  .g6YyG {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    grid-area: content;\n  }\n\n  .kDLbl {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: var(--xl);\n    border-radius: 0;\n  }\n\n  .hS8Pn {\n    margin-top: var(--l);\n  }\n\n  .W5Hpe {\n    font-size: var(--l);\n    color: var(--text-heading);\n    font-weight: 600;\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  .pLtHk {\n    width: 300px;\n    text-align: center;\n  }\n\n  .zMFJg {\n    font-size: 14px;\n    font-size: var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TenantAccessErrorPage": "mStTl",
	"TenantAccessErrorPage_errorWrapper": "w8x4e",
	"TenantAccessErrorPage_errorCardWrapper": "g6YyG",
	"TenantAccessErrorPage_errorCard": "kDLbl",
	"TenantAccessErrorPage_illustrationWrapper": "hS8Pn",
	"TenantAccessErrorPage_title": "W5Hpe",
	"TenantAccessErrorPage_textWrapper": "pLtHk",
	"TenantAccessErrorPage_text": "zMFJg"
};
export default ___CSS_LOADER_EXPORT___;
