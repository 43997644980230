import React, { useState } from 'react';

import { ArrowLeft, Close } from '@carbon/icons-react';
import { DividerV2, HTMLHeading } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import ConfirmActionModal from 'app/components/ConfirmActionModal/ConfirmActionModal';
import TerritoryOptimizationPreviewMenu from 'app/components/TerritoryOptimization/TerritoryOptimizationPreviewMenu/TerritoryOptimizationPreviewMenu';

import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { TerritoryOptimizationStep } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryOptimizationHeader.module.pcss';

const b = block(style);

interface OptimizationHeaderSettings {
  showTerritoryGroupName?: boolean;
  showConfirmExitDialog?: boolean;
  showDivider?: boolean;
}

const headerSettingsRecord: Record<TerritoryOptimizationStep, OptimizationHeaderSettings> = {
  [TerritoryOptimizationStep.START]: {},
  [TerritoryOptimizationStep.PARAMETERS]: {
    showDivider: true,
    showTerritoryGroupName: true,
    showConfirmExitDialog: true
  },
  [TerritoryOptimizationStep.CONSTRAINTS]: {
    showDivider: true,
    showTerritoryGroupName: true,
    showConfirmExitDialog: true
  },
  [TerritoryOptimizationStep.OPTIMIZE_TERRITORIES]: {
    showDivider: true,
    showTerritoryGroupName: true
  },
  [TerritoryOptimizationStep.OPTIMIZE_RESULTS]: { showDivider: true },
  [TerritoryOptimizationStep.PREVIEW]: { showDivider: true },
  [TerritoryOptimizationStep.PUBLISHED]: {}
};

const TerritoryOptimizationHeader: React.FC = () => {
  const { optimizationTarget, territoryOptimizationDrawerState, setTerritoryOptimizationDrawerState } =
    useTerritoryOptimization();

  const [showConfirmExitDialog, setShowConfirmExitDialog] = useState<boolean>(false);

  const headerSettings = headerSettingsRecord[territoryOptimizationDrawerState];

  return (
    <>
      <div className={b('header')} data-testid="territory-optimization-header">
        <div>
          {headerSettings?.showTerritoryGroupName && (
            <div className={b('titleContainer')}>
              <IconButton
                type="button"
                icon={<ArrowLeft />}
                testId="territory-optimization-header-back-button"
                onClick={() => setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.START)}
              />
              <HTMLHeading
                data-testid="territory-optimization-parameter-heading"
                tagLevel="h3"
                bold
                text={formatMessage('OPTIMIZE_TERRITORIES_FOR_TG', {
                  territoryGroup: optimizationTarget?.name
                })}
              />
            </div>
          )}
          {territoryOptimizationDrawerState === TerritoryOptimizationStep.PREVIEW && (
            <div className={b('titleContainer')}>
              <IconButton
                type="button"
                icon={<ArrowLeft />}
                testId="territory-optimization-header-back-button"
                onClick={() => setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.OPTIMIZE_RESULTS)}
              />
              <HTMLHeading
                data-testid="territory-optimization-parameter-heading"
                tagLevel="h3"
                bold
                text={formatMessage('PREVIEW')}
              />
              <TerritoryOptimizationPreviewMenu />
            </div>
          )}
          {territoryOptimizationDrawerState === TerritoryOptimizationStep.OPTIMIZE_RESULTS && (
            <div className={b('optimizationResultsTitleContainer')} data-testid="optimization-results-header">
              <HTMLHeading
                data-testid="territory-optimization-results-heading"
                tagLevel="h3"
                bold
                text={formatMessage('OPTIMIZATION_RESULTS', { territoryGroup: optimizationTarget?.name })}
              />
              <p>{formatMessage('OPTIMIZATION_RESULTS_PAGE_HEADER_DESCRIPTION')}</p>
            </div>
          )}
        </div>
        <IconButton
          type="button"
          icon={<Close />}
          testId="exit-opt-button"
          minimal
          onClick={() => {
            if (headerSettings.showConfirmExitDialog) {
              setShowConfirmExitDialog(true);
            } else {
              setTerritoryOptimizationDrawerState(null);
            }
          }}
        />
      </div>
      {headerSettings?.showDivider && <DividerV2 />}
      <ConfirmActionModal
        isOpen={showConfirmExitDialog}
        actionButtonIntent={'danger'}
        onCancel={() => setShowConfirmExitDialog(false)}
        confirmButtonText={formatMessage('EXIT')}
        confirmButtonIcon={<Close />}
        cancelButtonText={formatMessage('CANCEL')}
        onConfirmAction={() => {
          setTerritoryOptimizationDrawerState(null);
          setShowConfirmExitDialog(false);
        }}
        title={formatMessage('EXIT_OPTIMIZATION')}
        body={formatMessage('EXIT_OPTIMIZATION_CONFIRMATION')}
        data-testid="confirm-exit-dialog"
      />
    </>
  );
};

export default TerritoryOptimizationHeader;
