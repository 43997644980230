import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { FileType } from 'app/models';

interface FileUploadSequenceVisible {
  [FileType.ACTIVITY]: boolean;
  [FileType.CUSTOMER_ACCOUNT_HIERARCHY]: boolean;
  [FileType.CUSTOM_HIERARCHY]: boolean;
  [FileType.GEOGRAPHIC_TERRITORY_HIERARCHY]: boolean;
  [FileType.PARTICIPANT]: boolean;
  [FileType.TERRITORY_RULE]: boolean;
  [FileType.LOCATION]: boolean;
}

const fileUploadSequenceInitialVisibility = {
  [FileType.ACTIVITY]: false,
  [FileType.CUSTOMER_ACCOUNT_HIERARCHY]: false,
  [FileType.CUSTOM_HIERARCHY]: false,
  [FileType.GEOGRAPHIC_TERRITORY_HIERARCHY]: false,
  [FileType.PARTICIPANT]: false,
  [FileType.TERRITORY_RULE]: false,
  [FileType.LOCATION]: false
};

export interface FileHeader {
  csvHeader: string;
  dbColumn: string;
  columnRequired: boolean;
  columnType: string;
  valueRequired: boolean;
  valueType: string;
}

export interface FileUploadContextValues {
  showUploadSequenceByFileType: FileUploadSequenceVisible;
  setShowUploadSequenceByFileType: Dispatch<SetStateAction<FileUploadSequenceVisible>>;
  showEditHierarchyDialog: boolean;
  setShowEditHierarchyDialog: Dispatch<SetStateAction<boolean | null>>;
  showAddHierarchyDialog: boolean;
  setShowAddHierarchyDialog: Dispatch<SetStateAction<boolean | null>>;
  showUploadHierarchyDialog: boolean;
  setShowUploadHierarchyDialog: Dispatch<SetStateAction<boolean | null>>;
  resetValues: () => void;
}

export const FileUploadContext = createContext<FileUploadContextValues | null>(null);
FileUploadContext.displayName = 'FileUploadContext';

export const FileUploadProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [showUploadSequenceByFileType, setShowUploadSequenceByFileType] = useState<FileUploadSequenceVisible>(
    fileUploadSequenceInitialVisibility
  );
  const [showEditHierarchyDialog, setShowEditHierarchyDialog] = useState<boolean>(false);
  const [showAddHierarchyDialog, setShowAddHierarchyDialog] = useState<boolean>(false);
  const [showUploadHierarchyDialog, setShowUploadHierarchyDialog] = useState<boolean>(false);

  const resetValues = () => {
    setShowUploadSequenceByFileType(null);
    setShowEditHierarchyDialog(false);
    setShowAddHierarchyDialog(false);
    setShowUploadHierarchyDialog(false);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless particular values have changed
  const values = useMemo(
    () => ({
      showUploadSequenceByFileType,
      setShowUploadSequenceByFileType,
      showEditHierarchyDialog,
      setShowEditHierarchyDialog,
      showAddHierarchyDialog,
      setShowAddHierarchyDialog,
      showUploadHierarchyDialog,
      setShowUploadHierarchyDialog,
      resetValues
    }),
    [showUploadSequenceByFileType, showEditHierarchyDialog, showAddHierarchyDialog, showUploadHierarchyDialog]
  );

  // Return the interface that we want to expose to our other components
  return <FileUploadContext.Provider value={values}>{children}</FileUploadContext.Provider>;
};

// Custom hook to read these values from
export const useFileUpload = (): FileUploadContextValues => useContextSafe(FileUploadContext);
