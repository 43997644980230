import React from 'react';

import { Industry, Account, EarthFilled, Aperture, InformationFilled } from '@carbon/icons-react';

import { CategoryName, PillLevel } from 'app/models';

import { getHighlightCssPropertyValue } from 'utils/helpers/highlightHelpers';

export const getIcon = (categoryName: CategoryName, pillLevel: PillLevel, precedence: number): JSX.Element => {
  if (pillLevel === PillLevel.ROOT) {
    return <Aperture data-testid="root-tg-icon" />;
  }

  const pillHighlightValue = getHighlightCssPropertyValue(precedence);
  const style = pillLevel === PillLevel.TERRITORY ? { color: pillHighlightValue } : { color: 'var(--white)' };

  switch (categoryName) {
    case CategoryName.CUSTOMER_ACCOUNTS:
      return <Account style={style} data-testid="customer-accounts-tg-icon" />;
    case CategoryName.GEOGRAPHIC_TERRITORIES:
      return <EarthFilled style={style} data-testid="geo-tg-icon" />;
    case CategoryName.UNASSIGNED:
      return <InformationFilled style={style} data-testid="unassigned-tg-icon" />;
    case CategoryName.INDUSTRIES:
    default:
      return <Industry style={style} data-testid="default-tg-icon" />;
  }
};
