// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RmSbN {\n  background: #fff;\n  border-radius: 10px 10px 10px 0;\n  display: inline-flex;\n  padding: 10px;\n}\n\n  .UNjLY {\n    fill: rgb(var(--color-midnight-blue-2));\n    height: auto;\n    max-width: 35px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AssistantLoading": "RmSbN",
	"AssistantLoading_loader": "UNjLY"
};
export default ___CSS_LOADER_EXPORT___;
