// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FoPmQ {\n  width: 100%;\n  min-height: 100%;\n  overflow-y: scroll;\n  display: inline-flex;\n  flex-direction: column;\n  padding-bottom: var(--data-panel-content-bottom-padding);\n}\n\n  .nUAdI {\n    padding: var(--data-panel-padding);\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n\n  .ojJqM {\n    border-style: solid;\n    border-color: rgb(var(--color-light-gray-3));\n    border-width: 1px;\n    overflow: auto;\n  }\n\n  .ojJqM:last-child {\n    border-width: 1px;\n  }\n\n  ._3I4S7 {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n\n  .gIL51 {\n    display: flex;\n    height: var(--sheet-drill-in-grid-height);\n  }\n\n  .gIL51 .ag-theme-alpine .ag-body-horizontal-scroll-viewport {\n      display: none;\n    }\n\n  .JjgEa {\n    border-top: 1px solid rgb(var(--color-light-gray-1));\n    padding: var(--data-panel-padding);\n  }\n\n  .fXQuD {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-top: var(--data-panel-margin);\n  }\n\n  .wvs0d {\n    margin-left: var(--data-panel-xs-margin);\n  }\n\n  .-ndxx {\n    background-color: rgb(var(--color-light-gray-5)) !important;\n    font-weight: bold;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SellerSheetDetail": "FoPmQ",
	"SellerSheetDetail_content": "nUAdI",
	"SellerSheetDetail_gridSection": "ojJqM",
	"SellerSheetDetail_fullWidthGrid": "_3I4S7",
	"SellerSheetDetail_sellerSheetColumnsGrid": "gIL51",
	"SellerSheetDetail_noData": "JjgEa",
	"SellerSheetDetail_showTotalsArea": "fXQuD",
	"SellerSheetDetail_showTotalsText": "wvs0d",
	"SellerSheetDetail_bottomRowPinned": "-ndxx"
};
export default ___CSS_LOADER_EXPORT___;
