// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UAQDC {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  border-radius: var(--xs);\n}\n\n  .pt\\+ot {\n    color: rgb(var(--color-gray-1));\n    font-size: 13px;\n    font-weight: 300;\n    padding-bottom: var(--xs);\n  }\n\n  /* We are matching the style of the blueprint tagInput */\n\n  .KLyaO {\n    display: flex;\n    flex-grow: 1;\n    border: 1px solid #afafd5;\n    border-bottom: none;\n    padding: 5px;\n    border-radius: var(--xs) var(--xs) 0 0;\n    padding-left: 12px;\n  }\n\n  .PxnGK {\n    display: inline-table;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExpandedHierarchyRule": "UAQDC",
	"ExpandedHierarchyRule_hierarchyTitle": "pt+ot",
	"ExpandedHierarchyRule_filterKindBox": "KLyaO",
	"ExpandedHierarchyRule_inclusionsInput": "PxnGK"
};
export default ___CSS_LOADER_EXPORT___;
