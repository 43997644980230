import { useCallback } from 'react';

import { originOfOuterFrame } from 'app/containers/App/EmbeddedAuth//EmbedderHelpers';
import { ToEmbedderMessage } from 'app/containers/App/EmbeddedAuth/EmbedderTypes';

export const useSendEmbedderMessage = (): ((message: ToEmbedderMessage) => void) =>
  useCallback((message) => {
    if (!originOfOuterFrame) throw new Error(`Unable to find Embedder origin to message`);
    console.debug(`Sending message to Embedder`, message);
    window.top.postMessage(message, originOfOuterFrame);
  }, []);
