import gql from 'graphql-tag';

export const UPSERT_NEW_BUSINESS_TARGET = gql`
  mutation UpsertNewBusinessTarget($battlecardId: Int!, $quotaComponentId: Int!, $newBusinessTarget: Float!) {
    upsertNewBusinessTarget(
      input: { battlecardId: $battlecardId, quotaComponentId: $quotaComponentId, newBusinessTarget: $newBusinessTarget }
    ) {
      success
    }
  }
`;
