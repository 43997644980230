import React, { ReactNode } from 'react';

import { ArrowRight } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';

import style from './ToastMessage.module.pcss';

const b = block(style);

interface ToastMessageProps {
  title?: string;
  message?: ReactNode;
  actionButtonText?: string;
  actionButtonOnClick?: () => void;
}

const ToastMessage: React.FC<ToastMessageProps> = ({
  title,
  message,
  actionButtonText,
  actionButtonOnClick
}: ToastMessageProps) => {
  return (
    <>
      {title && (
        <div data-testid="toast-title" className={b('title')}>
          <b>{title}</b>
        </div>
      )}
      {message && <div data-testid="toast-message">{message}</div>}
      {actionButtonText && actionButtonOnClick && (
        <div className={b('actionButton')}>
          <TextButton
            text={actionButtonText}
            minimal
            type="button"
            rightIcon={<ArrowRight />}
            onClick={actionButtonOnClick}
            testId={'toast-action-button'}
          />
        </div>
      )}
    </>
  );
};

export default ToastMessage;
