const impersonationTokenKey = 'impersonationToken';

export function setImpersonationToken(impersonation: string): void {
  localStorage.setItem(impersonationTokenKey, impersonation);
}

export function getImpersonationToken(): string {
  return localStorage.getItem(impersonationTokenKey);
}

export function clearImpersonationToken(): void {
  localStorage.removeItem(impersonationTokenKey);
}
