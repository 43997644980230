// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NaWBh .bp3-menu-item {\n      padding: 10px 15px;\n    }\n  .Dot2O {\n    color: rgb(var(--color-dark-gray-3));\n    font-weight: 600;\n    height: 30px;\n    display: flex;\n    align-items: center;\n    margin-left: 15px;\n    margin-top: 8px;\n  }\n  .ygBI\\+ {\n    padding: 2px;\n  }\n  .AqmKc {\n    color: rgb(var(--color-gray-2));\n    font-weight: 600;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OperatorSelectMenu_menuContent": "NaWBh",
	"OperatorSelectMenu_menuHeader": "Dot2O",
	"OperatorSelectMenu_menuItem": "ygBI+",
	"OperatorSelectMenu_menuItemText": "AqmKc"
};
export default ___CSS_LOADER_EXPORT___;
