// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ebOSb {\n    display: flex;\n    color: var(--intent-primary);\n  }\n  .Us3gJ {\n    padding-left: var(--s);\n    display: flex;\n    align-items: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExternalLink_linkContainer": "ebOSb",
	"ExternalLink_linkIcon": "Us3gJ"
};
export default ___CSS_LOADER_EXPORT___;
