export const calculationItems = [
  { name: 'Addition', value: '+', sectionName: 'Operator' },
  { name: 'Subtraction', value: '-', sectionName: 'Operator' },
  { name: 'Multiplication', value: '*', sectionName: 'Operator' },
  { name: 'Division', value: '/', sectionName: 'Operator' },
  { name: 'Is equal to', value: '=', sectionName: 'Operator' },
  { name: 'Is greater than', value: '>', sectionName: 'Operator' },
  { name: 'Is greater or equal to', value: '>=', sectionName: 'Operator' },
  { name: 'Is less than', value: '<', sectionName: 'Operator' },
  { name: 'Is less or equal to', value: '<=', sectionName: 'Operator' },
  { name: 'Parenthesis', value: '()', sectionName: 'Operator' },
  {
    name: 'Row Sum',
    value: 'ROWSUM()',
    description: 'Returns the aggregate of the arguments within the brackets and provides a total',
    template: 'ROWSUM(#{1:value1}, #{2:value2}, #{3:...})',
    sectionName: 'Row based function'
  },

  {
    name: 'Row Minimum',
    value: 'ROWMIN()',
    description: 'Returns the minimum value of a set of values within the brackets',
    template: 'ROWMIN(#{1:value1}, #{2:value2}, #{3:...})',
    sectionName: 'Row based function'
  },
  {
    name: 'Row Maximum',
    value: 'ROWMAX()',
    description: 'Returns the maximum value of the arguments within the brackets',
    template: 'ROWMAX(#{1:value1}, #{2:value2}, #{3:...})',
    sectionName: 'Row based function'
  },
  {
    name: 'Row Average',
    value: 'ROWAVG()',
    description: 'Returns the average value of the arguments within the brackets',
    template: 'ROWAVG(#{1:value1}, #{2:value2}, #{3:...})',
    sectionName: 'Row based function'
  },
  {
    name: 'Absolute',
    value: 'ABS()',
    description:
      'Returns the value within the brackets as a positive number, regardless of whether the value within the brackets is positive or negative',
    template: 'ABS(#{1:value})',
    sectionName: 'Row based function'
  },
  {
    name: 'Round',
    value: 'ROUND()',
    description:
      'Rounds the results to the closest value by the number of defined decimal places specified in the brackets',
    template: 'ROUND(#{1:value}, #{2:num_digits})',
    sectionName: 'Row based function'
  },
  {
    name: 'Round Down',
    value: 'ROUNDDOWN()',
    description:
      'Rounds the results in the brackets down by the number of defined decimal places specified in the brackets',
    template: 'ROUNDDOWN(#{1:value}, #{2:num_digits})',
    sectionName: 'Row based function'
  },
  {
    name: 'If',
    value: 'IF()',
    description: 'Return a result when a condition is True or False based on a logical comparison',
    template: 'IF(#{1:logical_test}, #{2:value_if_true}, #{3:value_if_false})',
    sectionName: 'Row based function'
  },
  {
    name: 'Or',
    value: 'OR()',
    description: 'Return a result when one of the conditions are True or False',
    template: 'OR(#{1:logical1}, #{2:logical2}, #{3:...})',
    sectionName: 'Row based function'
  },
  {
    name: 'And',
    value: 'AND()',
    description: 'Return a result when all conditions are True or False',
    template: 'AND(#{1:logical1}, #{2:logical2}, #{3:...})',
    sectionName: 'Row based function'
  },
  {
    name: 'Not',
    value: 'NOT()',
    description: 'Return a result when a condition does not meet your criteria',
    template: 'NOT(#{1:logical})',
    sectionName: 'Row based function'
  },
  {
    name: 'Is empty',
    value: 'ISEMPTY()',
    description: 'Return a result when the field is empty',
    template: 'ISEMPTY(#{1:value})',
    sectionName: 'Row based function'
  }
];
