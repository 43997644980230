import React, { useEffect, useMemo, useState } from 'react';

import { Callout, Divider, Radio } from '@blueprintjs/core';
import { Field, useFormikContext } from 'formik';

import { KeyValue } from 'components/models';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import {
  ConfigurationDialogFormInput,
  SymonDataTableType
} from 'app/components/DataPanel/ConfigurationsPanel/ConfigurationDialog';
import { useSymonPipeConfigurations } from 'app/components/DataPanel/ConfigurationsPanel/hooks/useSymonPipeConfigurations';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import ImportSymonDataFileType from 'app/core/dataFileUpload/ImportSymonDataFileType';
import SymonConfigurationSelectMenu from 'app/core/dataFileUpload/symon/SymonConfigurationSelectMenu';

import { ConfigurationActionType, ConfigurationDialogType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { useGetSymonPipeExportNodes } from './hooks/useGetSymonExportNodes';
import { useGetSymonPipeNames } from './hooks/useGetSymonPipeNames';
import style from './SymonDataFileSelectStep.module.pcss';

const b = block(style);
interface SymonDataFileSelectStepProps {
  handleDisabledComplete: (value: boolean) => void;
  handleDisabledNext: (value: boolean) => void;
  dialogType?: ConfigurationDialogType;
  setSymonFileType?: (obj: SymonDataTableType) => void;
}

export const SymonDataFileSelectStep: React.FC<SymonDataFileSelectStepProps> = ({
  handleDisabledNext,
  dialogType = ConfigurationDialogType.IMPORT,
  setSymonFileType
}) => {
  const { selectedTenant, selectedPlanningCycle } = useScope();
  const { selectedConfiguration, setSelectedConfiguration } = useData();

  const { setFieldValue, values, resetForm } = useFormikContext<ConfigurationDialogFormInput>();

  const [selectedPipenameItem, setSelectedPipenameItem] = useState<Record<string, string>>();
  const [symonPipeNameSearchText, setSymonPipeNameSearchText] = useState<string>('');
  const [exportNodeSearchText, setExportNodeSearchText] = useState<string>('');

  const { data: symonPipenames } = useGetSymonPipeNames(selectedTenant?.id);
  const [getSymonPipeExportNodes, { data: symonPipeExportNodes, loading: loadingSymonPipeExportNodes }] =
    useGetSymonPipeExportNodes();

  const [configurationItems, setConfigurationItems] = useState([]);

  const [getSymonPipeConfigurations, { data: configurationsData }] = useSymonPipeConfigurations();

  useEffect(() => {
    resetForm();
    getSymonPipeConfigurations({
      variables: {
        planningCycleId: selectedPlanningCycle?.id
      }
    });
  }, []);

  useEffect(() => {
    if (!!values?.dataTableType) {
      setConfigurationItems(
        configurationsData?.getSymonPipeConfigurations
          ?.filter((configData) => {
            return configData.fileType === values?.dataTableType?.key;
          })
          .map((configData) => {
            return {
              key: `${configData.pipeName}, ${configData.exportName}`,
              value: {
                symonPipeConfigurationId: configData.symonPipeConfigurationId,
                pipeName: configData.pipeName,
                pipeId: configData.pipeId,
                exportName: configData.exportName,
                exportId: configData.exportId,
                tableNameLabel: configData.tableNameLabel
              }
            };
          })
      );
      setSymonFileType?.(values?.dataTableType);
    }
  }, [configurationsData, values?.dataTableType]);

  useEffect(() => {
    if (selectedPipenameItem) {
      getSymonPipeExportNodes({
        variables: {
          pipeId: selectedPipenameItem.value,
          planningCycleId: selectedPlanningCycle?.id
        }
      });
    }
  }, [selectedPipenameItem]);

  useEffect(() => {
    if ((values?.symonExportNode && values?.symonPipeName) || values?.configurationId) {
      handleDisabledNext(false);
    } else {
      handleDisabledNext(true);
    }
  }, [values]);

  const sortAndFilterItems = (items: KeyValue<string>[], searchText: string) =>
    items
      ?.filter((item) => item.key.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
      ?.sort((a, b) => a.key.localeCompare(b.key)) ?? null;

  const filteredPipeNameItems = useMemo(
    () =>
      sortAndFilterItems(
        symonPipenames?.getSymonPipeNames?.map((node) => ({
          key: node.name,
          value: node.pipeID
        })),
        symonPipeNameSearchText
      ),
    [symonPipenames, symonPipeNameSearchText]
  );

  const filteredExportNodeItems = useMemo(
    () =>
      sortAndFilterItems(
        symonPipeExportNodes?.getSymonPipeExportNodes?.map((node) => ({
          key: node.exportNodeName,
          value: node.exportId
        })),
        exportNodeSearchText
      ),
    [symonPipeExportNodes, exportNodeSearchText]
  );

  const disabledExportNodeItems =
    symonPipeExportNodes?.getSymonPipeExportNodes
      ?.filter((item) => item.planningCycleInfo)
      ?.map((node) => ({
        key: node.exportNodeName,
        value: node.exportId,
        message: formatMessage('CONFIGURATION_EXPORT_NODE_IN_USE_MESSAGE', {
          planningCycleName: node.planningCycleInfo.planningCycleName
        })
      })) || [];

  const handleRadioChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFieldValue('configurationAction', e.currentTarget.value);
    setFieldValue('configurationId', null);
    setFieldValue('symonPipeName', null);
    setFieldValue('symonExportNode', null);
    setFieldValue('configurationId', null);
  };

  const SelectMenuFields = (
    <>
      <div className={b('sectionTitle')}>{formatMessage('SOURCE')}</div>
      {formatMessage('CHOOSE_THE_SOURCE_AND_EXPORT_NODE')}
      <div className={b('selectMenuFieldsContainer')}>
        <div className={b('symonSelectOptionsWrapper')}>
          <label className={b('symonInputTextLabelWrapper')} htmlFor="symonAiSource">
            <span className={b('label')}>{formatMessage('SYMON_AI_SOURCE')}</span>
          </label>
          <div className={b('selectMenuContainer')}>
            <Field
              name="symonPipeName"
              items={filteredPipeNameItems}
              component={SearchableSelectMenu}
              showErrors={false}
              initialLoadingComplete={filteredPipeNameItems}
              placeHolderText={formatMessage('SELECT')}
              theme="default"
              onSelectItem={(item) => {
                const selectedItem = item.selectedItem;
                setSelectedPipenameItem(selectedItem);
                setFieldValue('symonPipeName', selectedItem);
                setFieldValue('symonExportNode', null);
                setSelectedConfiguration({ ...selectedConfiguration, pipeId: selectedItem.value });
              }}
              onSearch={(text) => setSymonPipeNameSearchText(text)}
              onSearchReset={() => setSymonPipeNameSearchText('')}
              showIconInField={false}
            />
          </div>
        </div>
        <div className={b('symonSelectOptionsWrapper')}>
          <label className={b('symonInputTextLabelWrapper')} htmlFor="symonExportNodes">
            <span className={b('label')}>{formatMessage('SYMON_AI_EXPORT_NODE')}</span>
          </label>
          <div className={b('selectMenuContainer')}>
            <Field
              name="symonExportNode"
              items={filteredExportNodeItems}
              disabledItems={disabledExportNodeItems}
              component={SearchableSelectMenu}
              initialLoadingComplete={filteredExportNodeItems}
              showErrors={false}
              placeHolderText={formatMessage('SELECT')}
              theme="default"
              disabled={!selectedPipenameItem || loadingSymonPipeExportNodes}
              onSelectItem={(item) => {
                const selectedItem = item.selectedItem;
                setFieldValue('symonExportNode', selectedItem);
                setSelectedConfiguration({ ...selectedConfiguration, exportId: selectedItem.value });
              }}
              onSearch={(text) => setExportNodeSearchText(text)}
              onSearchReset={() => setExportNodeSearchText('')}
              showIconInField={false}
              loading={loadingSymonPipeExportNodes}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className={b('')}>
      {dialogType === ConfigurationDialogType.IMPORT && (
        <div className={b('radioContainer')}>
          <div>
            <Radio
              data-testid="create-configuration-radio"
              checked={values.configurationAction === ConfigurationActionType.CREATE}
              value={ConfigurationActionType.CREATE}
              onChange={(e) => handleRadioChange(e)}
              labelElement={<span className={b('radioLabel')}>{formatMessage('CREATE_NEW_CONFIGURATION')}</span>}
            />
          </div>
          <div>
            <Radio
              data-testid="run-configuration-radio"
              checked={values.configurationAction === ConfigurationActionType.RUN}
              value={ConfigurationActionType.RUN}
              onChange={(e) => handleRadioChange(e)}
              labelElement={<span className={b('radioLabel')}>{formatMessage('RUN_EXISTING_CONFIGURATION')}</span>}
            />
          </div>
        </div>
      )}
      {values.configurationAction === ConfigurationActionType.CREATE ? (
        SelectMenuFields
      ) : (
        <SymonConfigurationSelectMenu
          data-testid="symon-configuration-select-menu"
          configurationItems={configurationItems}
        />
      )}
      {symonPipenames?.getSymonPipeNames?.length === 0 && (
        <Callout intent="danger">
          <p data-testid="no-pipename-error-message">{formatMessage('NO_SYMON_AI_SOURCE_ERROR_MESSAGE')}</p>
        </Callout>
      )}
      {symonPipeExportNodes?.getSymonPipeExportNodes?.length === 0 && (
        <Callout intent="danger">
          <p data-testid="no-export-node-error-message">{formatMessage('NO_SYMON_EXPORT_NODE_ERROR_MESSAGE')}</p>
        </Callout>
      )}
      {values.configurationAction === ConfigurationActionType.CREATE && (
        <>
          <Divider />
          <ImportSymonDataFileType
            data-testid="import-data-file-type"
            fileUploadType={values.dataTableType.value}
            rootHierarchyId={values.dataTableType.rootHierarchyId}
            dialogType={ConfigurationDialogType.CREATE}
          />
        </>
      )}
    </div>
  );
};
