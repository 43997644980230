// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iMFiM {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  .uszUU {\n    font-weight: bold;\n    color: rgb(var(--color-dark-gray-3));\n    font-size: 16px;\n    margin-top: var(--l);\n  }\n  ._2SYx4 {\n    margin: var(--xs) 0 var(--m) 0;\n    color: rgb(var(--color-gray-2));\n  }\n  .R3bPh {\n    transform: scale(2);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IntegrationPanelFallback_errorFallback": "iMFiM",
	"IntegrationPanelFallback_errorTitle": "uszUU",
	"IntegrationPanelFallback_errorBody": "_2SYx4",
	"IntegrationPanelFallback_warningIcon": "R3bPh"
};
export default ___CSS_LOADER_EXPORT___;
