import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Checkbox } from '@blueprintjs/core';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

type ReferenceCheckboxQuotaComponentsCellRendererProps = ICellRendererParams;

function hasAtLeastTwoTrue(arr) {
  let count = 0;
  arr.forEach((item) => {
    if (item) count++;
  });
  return count >= 2;
}

const ReferenceCheckboxQuotaComponentsCellRenderer: React.FC<ReferenceCheckboxQuotaComponentsCellRendererProps> = ({
  data,
  ...rest
}: ReferenceCheckboxQuotaComponentsCellRendererProps) => {
  const { setValue } = rest;
  const showToast = useShowToast();

  const handleCheckboxChange = () => {
    // there needs to be at least 2 quota component referenced to be able to uncheck
    if (
      !hasAtLeastTwoTrue(rest.api.getRenderedNodes().map((node) => node.data.referenceThisSheet)) &&
      data.referenceThisSheet
    ) {
      showToast(formatMessage('QUOTA_COMPONENTS_REFERENCED_CHECKBOX_SELECTION_WARNING'), 'warning');
    } else {
      setValue(!data?.referenceThisSheet);
    }
  };

  return (
    <Checkbox
      checked={data?.referenceThisSheet}
      onChange={handleCheckboxChange}
      data-testid="reference-checkbox-qc-cell-renderer"
    />
  );
};

export default ReferenceCheckboxQuotaComponentsCellRenderer;
