import gql from 'graphql-tag';

export const UPSERT_TERRITORY_OWNERS = gql`
  mutation UpsertTerritoryOwners($tenantId: Int!, $territoryId: Int!, $owners: [Owner]!, $quotaComponentId: Int) {
    upsertTerritoryOwners(
      input: { tenantId: $tenantId, territoryId: $territoryId, owners: $owners, quotaComponentId: $quotaComponentId }
    ) {
      success
      result {
        memberId
        ownerId
        ownership
        effectiveEndDate
        effectiveStartDate
      }
    }
  }
`;
