import React, { FC, useMemo } from 'react';

import { Compare } from '@carbon/icons-react';
import { CalloutV2, Intent } from '@varicent/components';
import { Formik, Form, FormikErrors } from 'formik';
import { useHistory } from 'react-router-dom';

import Dialog from 'components/Dialog/Dialog';
import { SearchableSelectMenuItem } from 'components/models';
import { NullableFieldErrorMessage } from 'components/NullableFieldErrorMessage/NullableFieldErrorMessage';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { useScope } from 'app/contexts/scopeProvider';

import useMakePlanningPath from 'app/hooks/useMakePlanningPath';
import useShowToast from 'app/hooks/useShowToast';

import { MapCompareState, PlanningPath } from 'app/models';

import block from 'utils/bem-css-modules';
import { getCompareMapStateAsSearchParams } from 'utils/helpers/compareModeHelpers';
import { formatMessage } from 'utils/messages/utils';

import style from './CompareModeDialog.module.pcss';
import CompareModeSelection from './CompareModeSelection';

const b = block(style);

export interface CompareModeDialogProps {
  relevantScenarioId?: number | null | undefined;
  onClose: () => void;
}

export interface CompareModeValues {
  primary: CompareModeFields;
  secondary: CompareModeFields;
}
interface CompareModeFields {
  battlecard: SearchableSelectMenuItem | null;
  scenario?: SearchableSelectMenuItem | null;
  geoTgt?: number;
  quotaComponent?: SearchableSelectMenuItem | null;
}

const CompareModeDialog: FC<CompareModeDialogProps> = ({ relevantScenarioId, onClose }) => {
  const { isMultiMapOpen, setIsMultiMapOpen, setIsDataTrayMapOpen } = useMapVariant();
  const { selectedBattleCardId, selectedQuotaComponentId } = useBattleCard();
  const history = useHistory();
  const makePlanningPath = useMakePlanningPath();
  const { selectedDeploymentModelId } = useScope();
  const { selectedTerritoryGroupTypeId } = useMapContextRedistributor();
  const showToast = useShowToast();

  const handleSubmit = ({ primary, secondary }: CompareModeValues) => {
    const { value: primaryBattlecardValue } = primary.battlecard;
    const { value: secondaryBattlecardValue } = secondary.battlecard;

    const mapCompareState: MapCompareState = {
      primary: {
        battlecardId: parseInt(primaryBattlecardValue),
        quotaComponentId: parseInt(primary.quotaComponent.value),
        deploymentModelId: parseInt(primary.scenario.value),
        geoTgtId: primary.geoTgt
      },
      secondary: {
        battlecardId: parseInt(secondaryBattlecardValue),
        quotaComponentId: parseInt(secondary.quotaComponent.value),
        deploymentModelId: parseInt(secondary.scenario.value),
        geoTgtId: secondary.geoTgt
      },
      selectionToRestore: {
        battlecardId: parseInt(selectedBattleCardId),
        quotaComponentId: selectedQuotaComponentId,
        deploymentModelId: selectedDeploymentModelId,
        geoTgtId: selectedTerritoryGroupTypeId
      }
    };
    const compareModeSearchParams = getCompareMapStateAsSearchParams(mapCompareState, history.location.search);

    history.push({
      pathname: makePlanningPath({ urlParam: 'compare', viewParam: PlanningPath.territory }).pathname,
      search: compareModeSearchParams
    });

    if (!isMultiMapOpen) {
      showToast(
        <ToastMessage
          title={formatMessage('COMPARE_MODE_TOOLTIP_TITLE')}
          message={formatMessage('COMPARE_MODE_TOOLTIP_BODY')}
        />,
        Intent.PRIMARY
      );
    }

    setIsDataTrayMapOpen(true);
    setIsMultiMapOpen(true);

    onClose();
  };

  const initialValues = useInitialValues(relevantScenarioId);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
      {({ handleSubmit, values, isValid, submitCount, initialValues }) => {
        const isShowingErrors = !isValid && submitCount > 0;
        const sharedCompareModeSelectionProps = {
          values,
          selectedQuotaComponentId,
          initialValues
        };
        return (
          <Dialog
            title={formatMessage('COMPARE_MODE')}
            isOpen
            onClose={onClose}
            onSubmit={handleSubmit}
            confirmButtonIcon={<Compare />}
            confirmButtonText={formatMessage('COMPARE')}
            disableConfirm={isShowingErrors}
            size="auto"
          >
            <Form>
              <div className={b('bodyText')} data-testid="compare-mode-dialog-body">
                {formatMessage('COMPARE_MODE_ACROSS_DM_DIALOG_BODY')}
              </div>
              <CompareModeSelection {...sharedCompareModeSelectionProps} ordinal="primary" />
              <CompareModeSelection {...sharedCompareModeSelectionProps} ordinal="secondary" />
            </Form>
            <div className={b('calloutContainer')}>
              <CalloutV2 className={b('callout')} intent={Intent.PRIMARY}>
                {formatMessage('COMPARE_MODE_TOOLTIP_BODY')}
              </CalloutV2>
              {isShowingErrors && (
                <CalloutV2 className={b('callout')} intent={Intent.DANGER} data-testid="compare-mode-dialog-errors">
                  <NullableFieldErrorMessage name="primary.battlecard" subName="key" />
                  <NullableFieldErrorMessage name="secondary.battlecard" subName="key" />
                </CalloutV2>
              )}
            </div>
          </Dialog>
        );
      }}
    </Formik>
  );
};

const validate = (values: CompareModeValues): FormikErrors<CompareModeValues> => {
  if (!values.primary.battlecard) {
    return { primary: { battlecard: { key: formatMessage('COMPARE_MODE_ERROR_MISSING_REQUIRED') } } };
  }
  if (!values.secondary.battlecard) {
    return { secondary: { battlecard: { key: formatMessage('COMPARE_MODE_ERROR_MISSING_REQUIRED') } } };
  }
  if (values.primary.battlecard.value === values.secondary.battlecard.value) {
    return { primary: { battlecard: { key: formatMessage('COMPARE_MODE_ERROR_IDENTICAL_BC') } } };
  }
  return {};
};

const useInitialValues = (relevantScenarioId: number): CompareModeValues => {
  const { mapCompareState } = useMapVariant();
  const { selectedDeploymentModelId } = useScope();
  const { selectedBattleCardId, selectedQuotaComponentId, battleCardLookupMap, quotaComponentList } = useBattleCard();

  return useMemo(
    (): CompareModeValues => {
      if (mapCompareState) {
        return mapCompareStateAsFormValues(mapCompareState);
      }

      const battlecardName = battleCardLookupMap[selectedBattleCardId]?.battlecardName || '';
      const quotaComponentName =
        quotaComponentList?.find((qc) => qc.quotaComponentId === selectedQuotaComponentId)?.quotaComponentName || '';

      const primaryState = {
        scenario: asInitialKeyValue(selectedDeploymentModelId),
        quotaComponent: asInitialKeyValue(selectedQuotaComponentId, quotaComponentName),
        battlecard: asInitialKeyValue(selectedBattleCardId, battlecardName),
        geoTgt: null
      };

      if (!relevantScenarioId || relevantScenarioId === selectedDeploymentModelId) {
        return {
          primary: primaryState,
          secondary: {
            scenario: null,
            quotaComponent: null,
            battlecard: null,
            geoTgt: null
          }
        };
      }
      return {
        primary: primaryState,
        secondary: { ...primaryState, scenario: asInitialKeyValue(relevantScenarioId) }
      };
    },
    [
      /* We never re-initialize the form so only the first value is used */
    ]
  );
};

const mapCompareStateAsFormValues = (mapCompareState: MapCompareState): CompareModeValues => ({
  primary: {
    scenario: asInitialKeyValue(mapCompareState.primary.deploymentModelId),
    quotaComponent: asInitialKeyValue(mapCompareState.primary.quotaComponentId),
    battlecard: asInitialKeyValue(mapCompareState.primary.battlecardId),
    geoTgt: mapCompareState.primary.geoTgtId
  },
  secondary: {
    scenario: asInitialKeyValue(mapCompareState.secondary.deploymentModelId),
    quotaComponent: asInitialKeyValue(mapCompareState.secondary.quotaComponentId),
    battlecard: asInitialKeyValue(mapCompareState.secondary.battlecardId),
    geoTgt: mapCompareState.secondary.geoTgtId
  }
});

const asInitialKeyValue = (id: number | string, name?: string | null | undefined) => {
  if (!id) return null;
  return { key: name || '', value: id.toString() };
};

export default CompareModeDialog;
