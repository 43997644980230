import {
  QueryTuple,
  // eslint-disable-next-line no-restricted-imports
  useLazyQuery
} from '@apollo/client';

import { GetAccountRuleBindings, GetAccountRuleBindingsVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_ACCOUNT_RULE_BINDINGS } from 'app/graphql/queries/getAccountRuleBindings';

export const useGetAccountRuleBindings = (
  variables: GetAccountRuleBindingsVariables
): QueryTuple<GetAccountRuleBindings, GetAccountRuleBindingsVariables> =>
  useLazyQuery<GetAccountRuleBindings, GetAccountRuleBindingsVariables>(GET_ACCOUNT_RULE_BINDINGS, {
    variables,
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });
