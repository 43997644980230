// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LlhrG {\n  display: grid;\n  margin-top: var(--l);\n  height: 100%;\n  grid-template-columns: min-content 1fr;\n  grid-gap: var(--m);\n}\n\n  .C8uJ8 {\n    width: 100%;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryOptimizationStepper": "LlhrG",
	"TerritoryOptimizationStepper_optimizationFormContent": "C8uJ8"
};
export default ___CSS_LOADER_EXPORT___;
