// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pu3Mh {\n  --border-height: 2px;\n  background-color: white;\n  position: sticky;\n  top: 0;\n  padding: 0;\n  min-height: calc(var(--header-height) + var(--border-height));\n  height: calc(var(--header-height) + var(--border-height));\n  width: 100%;\n  z-index: 10;\n  border-bottom: var(--border-height) solid rgb(var(--color-cobalt-3));\n}\n\n  ._1ctE7 {\n    display: flex;\n    margin-right: var(--l);\n    align-items: center;\n    height: 100%;\n  }\n\n  ._7hkf4 {\n    margin-left: var(--m);\n    height: 40px;\n    width: 40px;\n  }\n\n  .XdSdR {\n    display: flex;\n    flex: 3;\n    align-items: center;\n    height: 100%;\n    justify-content: flex-end;\n  }\n\n  .Zs5-f {\n    justify-content: flex-start;\n    display: flex;\n    flex-grow: 1;\n  }\n\n  .FWVsq {\n    justify-content: flex-end;\n    display: flex;\n    flex-shrink: 0;\n  }\n\n  .oOwAg {\n    display: flex;\n    flex-shrink: 1;\n    min-width: 0;\n    margin-left: auto;\n  }\n\n  .oOwAg .bp3-popover2-target {\n      min-width: 0;\n    }\n\n  .Juaus {\n    flex-shrink: 0;\n  }\n\n  .XEOqy {\n    border-bottom: inherit;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CyclesPageHeader": "Pu3Mh " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-row"] + "",
	"CyclesPageHeader_logoArea": "_1ctE7",
	"CyclesPageHeader_logo": "_7hkf4",
	"CyclesPageHeader_actionArea": "XdSdR " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["gutter-right-direct-l"] + "",
	"CyclesPageHeader_leftActions": "Zs5-f " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-row"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["gutter-right-direct-l"] + "",
	"CyclesPageHeader_rightActions": "FWVsq " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-row"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["gutter-right-direct-l"] + "",
	"CyclesPageHeader_tenantMenu": "oOwAg",
	"CyclesPageHeader_leftActionsItems": "Juaus",
	"CyclesPageHeader_backBtn": "XEOqy"
};
export default ___CSS_LOADER_EXPORT___;
