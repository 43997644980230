// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BH7jS {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountRuleGrid": "BH7jS"
};
export default ___CSS_LOADER_EXPORT___;
