import * as yup from 'yup';

import { MeasureFormatType } from 'app/models/index';

import { formatMessage } from 'utils/messages/utils';

export default yup.object().shape({
  panelData: yup.array().of(
    yup.object().shape({
      measureValue: yup
        .number()
        .nullable(true)
        .typeError(formatMessage('MEASURE_VALUE_TYPE_ERROR'))
        .when('measureFormatType', (measureFormatType, measureValue) => {
          if (measureFormatType === MeasureFormatType.PERCENTAGE) {
            return measureValue.max(100, formatMessage('PERCENTAGE_MEASURE_VALUE_RANGE_ERROR'));
          }
        })
    })
  )
});
