import { useEffect, useRef, useState } from 'react';

import { performTokenExchange } from 'app/graphql/mutations/exchangeTemporaryToken';

import { TokenState } from 'app/models';

interface ExpirableTokenState extends TokenState {
  expiresAt: number | null;
}

interface Input {
  tempToken: string | null;
  tenantSlug: string | null;
}

export const useExchangeToken = (input: Input): ExpirableTokenState => {
  const [state, setState] = useState<ExpirableTokenState>({ token: null, isLoading: false, expiresAt: null });
  const lastInputRef = useRef<Input>(input);

  const isPendingChange =
    lastInputRef.current.tempToken !== input.tempToken || lastInputRef.current.tenantSlug !== input.tenantSlug;

  useEffect(() => {
    lastInputRef.current = input;
    if (!input.tempToken || !input.tenantSlug) {
      setState({ token: null, isLoading: false, expiresAt: null });
      return noCleanup;
    }
    let isCancelled = false;
    setState({ token: null, isLoading: true, expiresAt: null });

    performTokenExchange(input)
      .then(({ data }) => {
        if (isCancelled) return;
        setState({
          token: data.exchangeTemporaryAuthToken.exchangeToken,
          expiresAt: data.exchangeTemporaryAuthToken.expiresAt,
          isLoading: false
        });
      })
      .catch((error) => {
        if (isCancelled) return;
        console.error(`Error fetching token`, error);
        setState({ token: null, isLoading: false, expiresAt: null });
      });

    return () => (isCancelled = true);
  }, [input.tempToken, input.tenantSlug]);

  return {
    ...state,
    isLoading: state.isLoading || isPendingChange
  };
};

const noCleanup = () => {
  // Used for effects that conditionally have cleanup
};
