import React from 'react';

import { Checkbox } from '@varicent/components';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './OverrideReassignDialogBody.module.pcss';

const b = block(style);

interface OverrideReassignDialogBodyProps {
  isChecked: boolean;
  onChange: () => void;
}

const OverrideReassignDialogBody: React.FC<OverrideReassignDialogBodyProps> = ({ isChecked, onChange }) => {
  return (
    <>
      <p>{formatMessage('OVERRIDE_REASSIGN_BODY_1')}</p>
      <p className={b('overrideReassignText')}>{formatMessage('OVERRIDE_REASSIGN_BODY_2')}</p>
      <Checkbox
        checked={isChecked}
        onChange={onChange}
        labelElement={<span className={b('labelElement')}>{formatMessage('DONT_SHOW_THIS_AGAIN')}</span>}
      />
    </>
  );
};

export default OverrideReassignDialogBody;
