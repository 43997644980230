// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nOaG5 {\n  display: flex;\n}\n\n  .nOaG5 .bp3-progress-bar {\n    margin: 0 10px;\n    height: 10px;\n  }\n\n  .nOaG5 .bp3-progress-bar .bp3-progress-meter {\n      background-color: rgb(var(--color-cobalt-3));\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StatusBar": "nOaG5"
};
export default ___CSS_LOADER_EXPORT___;
