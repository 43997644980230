import React, { useEffect } from 'react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { useAssistant } from 'app/contexts/assistantProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';

import { AssistantDialogState, CommandCenterDrawerState } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import AssistantIcon from './AssistantIcon';
import style from './AssistantMenu.module.pcss';

const b = block(style);

const AssistantMenu = () => {
  const { assistantDialogState, setAssistantDialogState } = useAssistant();
  const { commandCenterDrawerState, resetValues: resetCommandCenterValues } = useCommandCenter();

  useEffect(() => {
    // Close the assistant dialog if the command center is open
    if (
      commandCenterDrawerState !== CommandCenterDrawerState.CLOSE &&
      assistantDialogState === AssistantDialogState.OPEN
    ) {
      setAssistantDialogState(AssistantDialogState.CLOSE);
    }
  }, [commandCenterDrawerState]);

  const handleClick = () => {
    // We either close the dialog, or open the dialog and potentially also close the command center
    if (assistantDialogState === AssistantDialogState.OPEN) {
      setAssistantDialogState(AssistantDialogState.CLOSE);
    } else {
      if (commandCenterDrawerState !== CommandCenterDrawerState.CLOSE) {
        resetCommandCenterValues();
      }
      setAssistantDialogState(AssistantDialogState.OPEN);
    }
  };

  return (
    <div className={b()}>
      <TextButton
        text={formatMessage('COPILOT')}
        icon={<AssistantIcon style={{ height: 20, width: 20 }} />}
        large={false}
        active={assistantDialogState === AssistantDialogState.OPEN}
        onClick={handleClick}
        type="button"
        testId="assistant-menu-button"
      />
    </div>
  );
};

export default AssistantMenu;
