import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Position } from '@blueprintjs/core';

import {
  BACKEND_ERROR_TO_FRONTEND_ERROR_MAP,
  POLL_FREQUENCY_MS,
  CLOSE_INDICATOR_AFTER_MS
} from 'app/constants/CoinsortProviderConstants';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { useJobs } from 'app/contexts/jobsProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { CANCEL_COIN_SORT } from 'app/graphql/queries/cancelCoinsort';
import { GET_BATTLECARD } from 'app/graphql/queries/getBattlecard';
import { GET_COIN_SORT_PROGRESS } from 'app/graphql/queries/getCoinsortProgress';
import { RUN_FULL_COIN_SORT } from 'app/graphql/queries/runFullCoinsort';

import { useContextSafe } from 'app/hooks/useContextSafe';
import useTreatment from 'app/hooks/useTreatment';

import { BaseContext, JobStatus } from 'app/models';

import { wrapError } from 'utils/errors/ErrorUtil';
import { usePollJobProgress } from 'utils/helpers/jobProgressHelper';
// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface ContextValues extends BaseContext {
  territoryGroupsLoading: boolean;
  checkCanRunCoinsort: (battleCardId: string, selectedDeploymentModelId: number) => Promise<boolean>;
  setTerritoryGroupsLoading: Dispatch<SetStateAction<boolean>>;
  runCoinsort: (battleCardId: string, quotaComponentId: number) => Promise<void>;
  pollCoinsortProgressDeprecated: (battleCardId: string, quotaComponentId: number, isInitialRun?: boolean) => void;
  coinsortLoadingLookupMap: Record<string, boolean>;
  coinsortRunErrorLookupMap: Record<string, string>;
  cancelCoinsort: (battleCardId: string, quotaComponentId: number) => Promise<void>;
  coinsortCancelErrorLookupMap: Record<string, string>;
  coinsortProgressLookupMap: Record<string, CoinsortProgress>;
  coinsortRunningLookupMap: Record<string, boolean>;
  coinsortProgressErrorLookupMap: Record<string, string>;
  coinsortCheckErrorLookupMap: Record<string, string>;
  isCoinsortRunCompleted: boolean;
  setIsCoinSortRunCompleted: Dispatch<SetStateAction<boolean>>;
  checkCoinsortProgress: (battleCardId: string, jobItems) => void;
  updateCoinsortRunningLookupMap: (battleCardId: string, coinsortRunning: string | boolean) => void;
  updateCoinsortProgressLookupMap: (
    battleCardId: string,
    coinsortProgress: CoinsortProgress | Record<string, JobStatus>
  ) => void;
  resetValues: () => void;
}

interface CoinsortProgress {
  coinsortStatus: JobStatus;
  numberOfRulesProcessed: number;
  numberOfRules: number;
  assignments: Record<string, number>;
  totalActivities: number;
  unassignedActivities: number;
}

/**
 * Retrieves a user-friendly error message based on a backend error string.
 * @param {string} backendError - The error message or code received from the backend.
 * @param {string} defaultMsg - The default error message to return if no specific match is found.
 * @returns {string} A user-friendly error message.
 */
export const getValidatedErrMessage = (backendError: string, defaultMsg: string): string => {
  return BACKEND_ERROR_TO_FRONTEND_ERROR_MAP[backendError] || defaultMsg;
};

export const CoinsortContext = React.createContext<ContextValues | null>(null);
CoinsortContext.displayName = 'CoinsortContext';

export const CoinsortProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [territoryGroupsLoading, setTerritoryGroupsLoading] = useState(false);

  const [coinsortProgressLookupMap, setCoinsortProgressLookupMap] = useState<Record<string, CoinsortProgress>>({});
  const [coinsortRunningLookupMap, setCoinsortRunningLookupMap] = useState<Record<string, boolean>>({});
  const [coinsortProgressErrorLookupMap, setCoinsortProgressErrorLookupMap] = useState<Record<string, string>>({});

  const [coinsortLoadingLookupMap, setCoinsortLoadingLookupMap] = useState<Record<string, boolean>>({});

  const [coinsortRunErrorLookupMap, setCoinsortRunErrorLookupMap] = useState<Record<string, string>>({});

  const [coinsortCancelErrorLookupMap, setCoinsortCancelErrorLookupMap] = useState<Record<string, string>>({});

  const [coinsortCheckErrorLookupMap, setCoinsortCheckErrorLookupMap] = useState<Record<string, string>>({});
  const [isCoinsortRunCompleted, setIsCoinSortRunCompleted] = useState<boolean>(false);

  const [matchingEngineEnabled] = useTreatment(SplitFeatures.MATCHING_ENGINE);
  const { selectedPlanningCycle } = useScope();
  const { setJobItems, setPollingJobs } = useJobs();

  const setCoinsortLoading = (battleCardId, value) => {
    setCoinsortLoadingLookupMap((prevCoinsortLoadingLookupMap) => ({
      ...prevCoinsortLoadingLookupMap,
      [battleCardId]: value
    }));
  };

  const checkCanRunCoinsort = async (battleCardId: string, selectedDeploymentModelId: number) => {
    setCoinsortCheckErrorLookupMap({ ...coinsortCheckErrorLookupMap, [battleCardId]: null });
    if (!selectedDeploymentModelId) return false;
    setCoinsortLoading(battleCardId, true);

    // TODO TQP-1712 call BE endpoint to check if coin sort can be run and remove battle card fetch
    let hasActivityFiles = false;
    try {
      // eslint-disable-next-line no-restricted-syntax
      const battleCard = await apolloClient.query({
        query: GET_BATTLECARD,
        variables: { battlecardId: battleCardId, deploymentModelId: selectedDeploymentModelId },
        fetchPolicy: 'network-only'
      });
      hasActivityFiles = !!battleCard?.data?.getDeploymentModelSpec?.battlecards?.[0]?.files?.length;
      setCoinsortLoading(battleCardId, false);
    } catch (error) {
      console.log(error);
      const wrappedError = wrapError(error);
      setCoinsortCheckErrorLookupMap({
        ...coinsortCheckErrorLookupMap,
        [battleCardId]: getValidatedErrMessage(wrappedError.message, formatMessage('COINSORT_CHECK_ERROR'))
      });
    }

    return hasActivityFiles;
  };

  const getCoinsortProgressHelper = async (
    battleCardId: string,
    quotaComponentId: number
  ): Promise<CoinsortProgress> => {
    // get coinsort progress
    // eslint-disable-next-line no-restricted-syntax
    const progress = await apolloClient.query({
      query: GET_COIN_SORT_PROGRESS,
      variables: { battlecardId: battleCardId, quotaComponentId },
      fetchPolicy: 'network-only'
    });

    const coinsortProgress = progress?.data?.getCoinsortProgress
      ? {
          ...progress?.data?.getCoinsortProgress
        }
      : null;
    if (coinsortProgress?.assignments && typeof coinsortProgress?.assignments === 'string') {
      // replace JSON string with parsed object
      const parsedAssignments = JSON.parse(coinsortProgress.assignments);
      coinsortProgress.assignments = parsedAssignments;
    }

    return coinsortProgress;
  };

  const updateCoinsortProgressLookupMap = (battleCardId, coinsortProgress) => {
    setCoinsortProgressLookupMap((currentProgressLookupMap) => ({
      ...currentProgressLookupMap,
      [battleCardId]: coinsortProgress
    }));
  };

  const updateCoinsortRunningLookupMap = (battleCardId, coinsortRunning) => {
    setCoinsortRunningLookupMap((currentProgressLookupMap) => ({
      ...currentProgressLookupMap,
      [battleCardId]: coinsortRunning
    }));
  };

  const checkCoinsortProgress = (battleCardId, jobItems) => {
    if (jobItems.length === 0) {
      setCoinsortProgressLookupMap({});
      return setCoinsortRunningLookupMap({});
    }
    const activeJobItemByBCId = jobItems.find(
      (item) => JSON.parse(item.metadata)?.battlecardId.toString() === battleCardId && item.isActive
    );

    if (activeJobItemByBCId) {
      updateCoinsortRunningLookupMap(battleCardId, activeJobItemByBCId.id);
      updateCoinsortProgressLookupMap(battleCardId, activeJobItemByBCId);
    }
  };

  const pollCoinsortProgressDeprecated = async (
    battleCardId: string,
    quotaComponentId: number,
    isInitialRun: boolean
  ) => {
    // clear coinsort progress
    updateCoinsortProgressLookupMap(battleCardId, null);
    setIsCoinSortRunCompleted(false);

    // clear coinsort error
    setCoinsortProgressErrorLookupMap((currentProgressErrorLookupMap) => ({
      ...currentProgressErrorLookupMap,
      [battleCardId]: null
    }));

    // This function polls until we've seen a completed/cancelled/failed status.
    // Once it's done polling, it checks if the status was completed or cancelled.
    // If it was completed, it waits for a specified amount of time before marking the coinsort as "not running",
    // so the user has time to see the feedback.

    // If it was cancelled, it marks the coinsort as "not running" immediately, presumably because the user cancelled it themselves
    // so they don't need any lingering UI feedback.

    let isRunning = false;

    const poll = async () => {
      try {
        const coinsortProgress = await getCoinsortProgressHelper(battleCardId, quotaComponentId);

        const status = coinsortProgress?.coinsortStatus;

        // We want the user to experience a hang at the end
        if (
          coinsortProgress?.numberOfRulesProcessed &&
          coinsortProgress.numberOfRulesProcessed === coinsortProgress?.numberOfRules
        ) {
          coinsortProgress.numberOfRulesProcessed -= 1;
        }
        updateCoinsortProgressLookupMap(battleCardId, coinsortProgress);

        // if coinsort is completed, cancelled, or failed, stop polling
        // if we are polling to check progress on page load, also stop polling if progress is null
        if (
          status === JobStatus.COMPLETED ||
          status === JobStatus.CANCELLED ||
          status === JobStatus.FAILED ||
          (!isInitialRun && !coinsortProgress)
        ) {
          if (status === JobStatus.FAILED) {
            setCoinsortProgressErrorLookupMap((currentErrorLookupMap) => ({
              ...currentErrorLookupMap,
              [battleCardId]: formatMessage('COINSORT_FETCH_ERROR')
            }));
          }

          if (status === JobStatus.COMPLETED) {
            // if coinsort has completed, close status indicator after delay
            setTimeout(() => {
              updateCoinsortRunningLookupMap(battleCardId, false);

              // only show toast if coinsort was previously running (ie. not on first load)
              if (isRunning) {
                const totalActivities = coinsortProgress.totalActivities ?? 0;
                const unassignedActivities = coinsortProgress.unassignedActivities ?? 0;
                const numberOfRules = coinsortProgress.numberOfRules ?? 0;
                const assignedActivities = totalActivities - unassignedActivities;
                setIsCoinSortRunCompleted(true);

                // eslint-disable-next-line deprecation/deprecation
                showToast(
                  <div data-testid="coinsort-success-toast">
                    <div style={{ paddingBottom: 6 }}>
                      <b>{formatMessage('COINSORT_PROCESS_SUCCESS')}</b>
                    </div>
                    <div style={{ paddingBottom: 6 }} data-testid="assigned-text">
                      {formatMessage('ACTIVITIES_SORTED_INTO', {
                        activityCount: assignedActivities,
                        territoryCount: numberOfRules
                      })}
                    </div>
                    <div data-testid="unassigned-text">
                      {formatMessage('ACTIVITIES_UNASSIGNED', { activityCount: unassignedActivities })}
                    </div>
                  </div>,
                  'success',
                  Position.TOP_RIGHT
                );
              }
            }, CLOSE_INDICATOR_AFTER_MS);
          } else {
            // if cancelled, close right away
            updateCoinsortRunningLookupMap(battleCardId, false);
            updateCoinsortProgressLookupMap(battleCardId, coinsortProgress);
          }
        } else {
          if (!isRunning) {
            isRunning = true;

            // set coinsort running
            updateCoinsortRunningLookupMap(battleCardId, true);
          }

          // polling for coinsort status
          setTimeout(poll, POLL_FREQUENCY_MS);
        }
      } catch (error) {
        // stop running and show error

        updateCoinsortRunningLookupMap(battleCardId, false);
        setCoinsortProgressErrorLookupMap((currentErrorLookupMap) => {
          const wrappedError = wrapError(error);
          return {
            ...currentErrorLookupMap,
            [battleCardId]: getValidatedErrMessage(wrappedError.message, formatMessage('COINSORT_FETCH_ERROR'))
          };
        });
      }
    };

    // loading initial coinsort status and start polling.
    await poll();
  };

  const { pollJobProgress } = usePollJobProgress();

  const runCoinsort = async (battleCardId: string, quotaComponentId: number) => {
    setCoinsortRunErrorLookupMap((currentErrorLookupMap) => ({
      ...currentErrorLookupMap,
      [battleCardId]: null
    }));
    setCoinsortLoading(battleCardId, true);

    try {
      // run coinsort
      // eslint-disable-next-line no-restricted-syntax
      const response = await apolloClient.mutate({
        mutation: RUN_FULL_COIN_SORT,
        variables: { battlecardId: battleCardId, quotaComponentId }
      });
      if (matchingEngineEnabled) {
        updateCoinsortRunningLookupMap(battleCardId, response?.data?.runFullCoinsort?.jobId ?? true);
        setTimeout(() => {
          pollJobProgress(selectedPlanningCycle?.id, setJobItems, setPollingJobs);
        }, 3000);
      } else {
        await pollCoinsortProgressDeprecated(battleCardId, quotaComponentId, true);
      }
    } catch (error) {
      const wrappedError = wrapError(error);
      setCoinsortRunErrorLookupMap({
        ...coinsortRunErrorLookupMap,
        [battleCardId]: getValidatedErrMessage(wrappedError.message, formatMessage('COINSORT_PROCESS_FAILURE'))
      });
    } finally {
      setCoinsortLoading(battleCardId, false);
    }
  };

  const cancelCoinsort = async (battleCardId: string, quotaComponentId: number) => {
    setCoinsortCancelErrorLookupMap({ ...coinsortCancelErrorLookupMap, [battleCardId]: null });
    setCoinsortLoading(battleCardId, true);

    try {
      // cancel coinsort
      // eslint-disable-next-line no-restricted-syntax
      await apolloClient.mutate({
        mutation: CANCEL_COIN_SORT,
        variables: { battlecardId: battleCardId, quotaComponentId }
      });

      updateCoinsortRunningLookupMap(battleCardId, false);
    } catch (error) {
      console.log(error);
      const wrappedError = wrapError(error);
      setCoinsortCancelErrorLookupMap({
        ...coinsortCancelErrorLookupMap,
        [battleCardId]: getValidatedErrMessage(wrappedError.message, formatMessage('COINSORT_CANCEL_ERROR'))
      });
    } finally {
      setCoinsortLoading(battleCardId, false);
    }
  };

  const resetValues = () => {
    setTerritoryGroupsLoading(false);
    setCoinsortProgressLookupMap({});
    setCoinsortRunningLookupMap({});
    setCoinsortProgressErrorLookupMap({});
    setCoinsortLoadingLookupMap({});
    setCoinsortRunErrorLookupMap({});
    setCoinsortCancelErrorLookupMap({});
    setCoinsortCheckErrorLookupMap({});
    setIsCoinSortRunCompleted(false);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      territoryGroupsLoading,
      setTerritoryGroupsLoading,
      checkCanRunCoinsort,
      pollCoinsortProgressDeprecated,
      runCoinsort,
      coinsortLoadingLookupMap,
      coinsortRunErrorLookupMap,
      cancelCoinsort,
      coinsortCancelErrorLookupMap,
      coinsortProgressLookupMap,
      coinsortRunningLookupMap,
      coinsortProgressErrorLookupMap,
      coinsortCheckErrorLookupMap,
      isCoinsortRunCompleted,
      setIsCoinSortRunCompleted,
      checkCoinsortProgress,
      updateCoinsortRunningLookupMap,
      updateCoinsortProgressLookupMap,
      resetValues
    }),
    [
      territoryGroupsLoading,
      coinsortLoadingLookupMap,
      coinsortRunErrorLookupMap,
      coinsortCancelErrorLookupMap,
      coinsortProgressLookupMap,
      coinsortRunningLookupMap,
      coinsortProgressErrorLookupMap,
      coinsortCheckErrorLookupMap,
      isCoinsortRunCompleted
    ]
  );

  // Return the interface that we want to expose to our other components
  return <CoinsortContext.Provider value={values}>{children}</CoinsortContext.Provider>;
};

// Custom hook to read these values from
export const useCoinsort = (): ContextValues => useContextSafe(CoinsortContext);
