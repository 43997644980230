import { Dispatch, SetStateAction } from 'react';

import {
  MutationTuple,
  QueryLazyOptions,
  // eslint-disable-next-line no-restricted-imports
  useMutation
} from '@apollo/client';

import {
  GetFileUploadProgressVariables,
  publishFileToDB,
  publishFileToDBVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { PUBLISH_FILE_TO_DB } from 'app/graphql/mutations/publishFileToDB';

import { DataPanelViews, FileUploadResult } from 'app/models';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface UsePublishFileToDBProps {
  pollForProcessingStatus: (options?: QueryLazyOptions<GetFileUploadProgressVariables>) => void;
  selectedTable: FileUploadResult | null;
  setSelectedTable: Dispatch<SetStateAction<FileUploadResult | null>>;
  setSelectedDataView?: Dispatch<SetStateAction<DataPanelViews>>;
  setFileUploadInProgress: Dispatch<SetStateAction<FileUploadResult[] | []>>;
}
export const usePublishFileToDB = ({
  pollForProcessingStatus,
  selectedTable,
  setSelectedTable,
  setSelectedDataView,
  setFileUploadInProgress
}: UsePublishFileToDBProps): MutationTuple<publishFileToDB, publishFileToDBVariables> => {
  return useMutation<publishFileToDB, publishFileToDBVariables>(PUBLISH_FILE_TO_DB, {
    onCompleted() {
      setSelectedDataView?.(DataPanelViews.DATA_OVERVIEW);
      setFileUploadInProgress((prevState) => {
        // when there are no file upload in progress, set fileUploadInProgress as the selected table
        // in order to start the polling of file upload progress
        if (prevState.length === 0) return [selectedTable];

        // in a case where a 2nd request of the same file id, this will prevent adding a duplicate input
        const findIndexOfSelectedFileId = prevState.findIndex((file) => file.tableId === selectedTable?.tableId);
        // if index is less than 0 that means it doesn't exist and will add the file id to the array
        return findIndexOfSelectedFileId < 0 ? [...prevState, selectedTable] : prevState;
      });
      pollForProcessingStatus();
      setSelectedTable(null);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      // eslint-disable-next-line deprecation/deprecation
      showToast(`${selectedTable.tableName} ${formatMessage('FILE_HAS_BEEN_PUBLISH_ERROR')}`, 'danger');
    }
  });
};
