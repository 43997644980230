import React, { useMemo } from 'react';

import { SquareFill } from '@carbon/icons-react';
import { Form, Formik, FormikErrors } from 'formik';

import { BasicSelectField } from 'components/BasicSelect/BasicSelectField';
import Dialog from 'components/Dialog/Dialog';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './PickTerritoryDialog.module.pcss';

const b = block(style);

type TerritoryItem = { ruleId: number; territoryId: string; territoryName: string; color: string };

export interface PickTerritoryDialogProps {
  selectableTerritoryRules: TerritoryItem[];
  title: string;
  body: string;
  confirmText: string;
  onSubmit: (ruleId: number) => Promise<void>;
  onClose: () => void;
}

type PickTerritoryFormValues = {
  ruleId: number | null;
};

const initialValues: PickTerritoryFormValues = { ruleId: null };

const PickTerritoryDialog: React.FC<PickTerritoryDialogProps> = ({
  selectableTerritoryRules,
  title,
  body,
  confirmText,
  onSubmit,
  onClose
}: PickTerritoryDialogProps) => {
  const submitForm = async (value: PickTerritoryFormValues) => {
    if (!value.ruleId) return;
    await onSubmit(value.ruleId);
    onClose();
  };

  const selectableTerritoryItems = useMemo(
    () =>
      selectableTerritoryRules.map((rule) => ({
        text: `${rule.territoryName} (${rule.territoryId})`,
        value: rule.ruleId,
        icon: <SquareFill color={rule.color} />
      })),
    [selectableTerritoryRules]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} validate={validate}>
      {({ handleSubmit, isValid, isSubmitting }) => (
        <Dialog
          isOpen
          onClose={onClose}
          onSubmit={handleSubmit}
          title={title}
          cancelButtonText={formatMessage('CANCEL')}
          confirmButtonText={confirmText}
          disableConfirm={!isValid}
          confirmButtonLoading={isSubmitting}
          showOverflow
          size={'small'}
          bodyMinHeight={0}
        >
          <Form>
            <div data-testid="pick-territory-dialog-body" className={b('pickTerritoryDialogBody')}>
              <p className={b('body')}>{body}</p>
              <BasicSelectField
                name="ruleId"
                placeholder={formatMessage('SELECT_A_TERRITORY')}
                label={formatMessage('SELECT_A_TERRITORY')}
                items={selectableTerritoryItems}
                placement="bottom-start"
                fill
              />
            </div>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

const validate = (values: PickTerritoryFormValues) => {
  const errors: FormikErrors<PickTerritoryFormValues> = {};
  if (!values.ruleId) errors.ruleId = formatMessage('REQUIRED');
  return errors;
};

export default PickTerritoryDialog;
