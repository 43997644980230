// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n9nlJ .bp3-input {\n      height: var(--select-menu-height);\n    }\n    .n9nlJ .bp3-button {\n      height: var(--select-menu-height);\n    }\n  .hVRs1 {\n  }\n  .L\\+nHa {\n    display: flex !important;\n    align-items: center;\n  }\n  .yE2oy {\n    display: inline-block !important;\n    margin-top: 0 !important;\n    margin-left: 5px;\n  }\n  ._9kUi4 {\n  }\n  .xC-Xd {\n    font-weight: normal;\n  }\n  .XlmD\\+ {\n    color: grey;\n  }\n  ._6Rqql {\n    height: 100%;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SheetsExistingFieldFormFields_formInput": "n9nlJ",
	"SheetsExistingFieldFormFields_label": "hVRs1 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label-text"] + "",
	"SheetsExistingFieldFormFields_labelWithInformationTooltip": "L+nHa " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label-text"] + "",
	"SheetsExistingFieldFormFields_informationTooltip": "yE2oy",
	"SheetsExistingFieldFormFields_subCheckbox": "_9kUi4 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["sub-checkbox"] + "",
	"SheetsExistingFieldFormFields_checkboxLabel": "xC-Xd",
	"SheetsExistingFieldFormFields_autoValue": "XlmD+",
	"SheetsExistingFieldFormFields_measureFormPage": "_6Rqql"
};
export default ___CSS_LOADER_EXPORT___;
