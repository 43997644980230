import React, { forwardRef, useImperativeHandle, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@apollo/client';

import HierarchySuggest from 'app/components/HierarchySuggest/HierarchySuggest';

import { useGrid } from 'app/contexts/gridProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { handleError } from 'app/graphql/handleError';
import { GET_ROOT_HIERARCHIES } from 'app/graphql/queries/getRootHierarchies';

import useShowToast from 'app/hooks/useShowToast';

import { HierarchyQuerySpec } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './FilterHelper.module.pcss';

const b = block(style);

export default forwardRef((props, ref) => {
  const { filterModel, setFilterModel } = useGrid();
  const { selectedPlanningCycle } = useScope();
  const [filterText, setFilterText] = useState('');
  const showToast = useShowToast();

  const { data: hierarchies, loading } = useQuery<HierarchyQuerySpec>(GET_ROOT_HIERARCHIES, {
    variables: { planningCycleId: selectedPlanningCycle?.id },
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      showToast(formatMessage('UNABLE_TO_RETRIEVE_HIERARCHIES'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });

  // expose AG Grid Filter Lifecycle callbacks - required for custom filter to run
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterText != null && filterText !== '';
      }
    };
  });

  const applyFilter = (clickedFilter) => {
    const filters = [
      {
        hierarchyName: props?.['colDef'].field,
        filter: [clickedFilter]
      }
    ];

    // Update filter model conditionally based on whether the filter model exists and
    // whether a filter with that hierarchy name already exists
    if (filterModel) {
      const filterModelCopy = filterModel.slice();
      const hierarchyList = [];
      for (const model of filterModelCopy) {
        hierarchyList.push(model.hierarchyName);
      }
      if (hierarchyList.includes(filters[0].hierarchyName)) {
        const ind = hierarchyList.indexOf(filters[0].hierarchyName);
        filterModelCopy[ind]['filter'].push(...filters[0]['filter']);
        setFilterModel(filterModelCopy);
      } else {
        setFilterModel([...filterModelCopy, ...filters]);
      }
    } else {
      setFilterModel(filters);
    }
  };

  let hierarchy;
  let hierarchyType;
  if (!loading) {
    hierarchy = hierarchies?.getRootHierarchies?.filter((item) => {
      if (props?.['colDef']?.field.toLowerCase() === item.rootKey.toLowerCase()) {
        return true;
      } else {
        return false;
      }
    });
    if (hierarchy?.length && hierarchy[0].hierarchyType.includes('CustomHierarchy')) {
      hierarchyType = `${hierarchy[0].hierarchyType}.${hierarchy[0].rootKey}`;
    } else if (hierarchy?.length) {
      hierarchyType = hierarchy[0].hierarchyType;
    }
  }

  return (
    <div className={b('filterContainer')} data-testid={'filter-container'}>
      <div>
        <HierarchySuggest
          item={{ key: filterText, value: filterText }}
          onChange={(item) => {
            setFilterText(item);
          }}
          popoverProps={{ popoverClassName: 'filterHelper' }}
          onSelect={(item) => {
            applyFilter(item?.value);
          }}
          rootHierarchyId={hierarchy?.rootHierarchyId}
          hierarchyType={loading ? null : hierarchyType}
        />
      </div>
    </div>
  );
});
