import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { RequestAllocateTopDown, RequestAllocateTopDownVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const REQUEST_ALLOCATE_TOP_DOWN = gql`
  mutation RequestAllocateTopDown($input: RequestAllocateTopDownInput!) {
    requestAllocateTopDown(input: $input) {
      jobId
    }
  }
`;

export const useRequestAllocateTopDown = createUseMutation<RequestAllocateTopDown, RequestAllocateTopDownVariables>(
  REQUEST_ALLOCATE_TOP_DOWN
);
