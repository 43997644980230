import { TerritoryGroupWithMeasuresNodeData } from 'app/models';

export const getTreeNodeChildrenIds = (nodeData: TerritoryGroupWithMeasuresNodeData): number[] => {
  let result = [];
  nodeData.children.forEach((child) => {
    result.push(child.territoryGroupId);
    if (Array.isArray(child.children)) {
      result = result.concat(getTreeNodeChildrenIds(child));
    }
  });
  return result;
};
