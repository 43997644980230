import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { DocumentView, OverflowMenuHorizontal, TrashCan } from '@carbon/icons-react';
import { Intent } from '@varicent/components';

import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import MenuItem from 'components/DropdownMenu/DropdownMenuItem';
import { Menu } from 'components/menu/Menu';

import { DropdownMenuCellRendererCustomItem } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './DropdownMenuCellRenderer.module.pcss';

const b = block(style);

interface DropdownMenuCellRendererProps extends ICellRendererParams {
  viewItemText?: string;
  viewItemIcon?: React.FunctionComponent;
  onViewItemClicked?: (data) => void;
  showDelete?: boolean;
  onDeleteItemClicked?: (data) => void;
  customItems?: DropdownMenuCellRendererCustomItem[];
}

const DropdownMenuCellRenderer: React.FC<DropdownMenuCellRendererProps> = ({
  viewItemText,
  viewItemIcon,
  onViewItemClicked,
  showDelete = true,
  onDeleteItemClicked,
  customItems,
  data
}: DropdownMenuCellRendererProps) => {
  if (!data) {
    return null;
  }

  return (
    <div className={b()} data-testid="dropdown-menu-cell-renderer">
      <DropdownMenu
        content={
          <Menu data-testid="dropdown-menu-grid-options-menu">
            <MenuItem
              text={viewItemText || formatMessage('VIEW_DETAILS')}
              onClick={() => {
                onViewItemClicked?.(data);
              }}
              data-testid="dropdown-menu-view-details"
              icon={viewItemIcon || DocumentView}
            />
            {showDelete && (
              <MenuItem
                text={formatMessage('DELETE')}
                onClick={() => {
                  onDeleteItemClicked?.(data);
                }}
                data-testid="dropdown-menu-delete"
                icon={TrashCan}
                intent="danger"
              />
            )}
            {customItems?.map((item, index) => (
              <MenuItem
                key={`custom-item-${item.text}`}
                text={item.text}
                onClick={() => {
                  item.onClick(data);
                }}
                data-testid={`dropdown-menu-cell-renderer-custom-item-${index}`}
                icon={item.icon}
                intent={(item.intent as Intent) || 'primary'}
              />
            ))}
          </Menu>
        }
        target={<OverflowMenuHorizontal size={20} data-testid="dropdown-menu-cell-renderer-icon" />}
        placement="auto-end"
      />
    </div>
  );
};

export default DropdownMenuCellRenderer;
