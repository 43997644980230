// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1gZlg {\n    display: flex;\n    justify-content: center;\n  }\n  .mMqyj {\n    font-size: var(--m);\n    font-weight: 600;\n  }\n  .kWmV3 {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .F7zfJ {\n    min-height: var(--s);\n    padding: 0 !important;\n    margin: 0 var(--s) !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderSelect_headerSelectWrapper": "_1gZlg",
	"HeaderSelect_headerSelectTitle": "mMqyj",
	"HeaderSelect_headerSelectTitleWrapper": "kWmV3",
	"HeaderSelect_headerSelectButton": "F7zfJ"
};
export default ___CSS_LOADER_EXPORT___;
