// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ax5z6 {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 0 var(--data-panel-padding);\n  background-color: white;\n  align-items: center;\n  box-sizing: border-box;\n  height: var(--data-drill-in-header-height);\n}\n\n  .jgs7O {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .gnjFa {\n    width: 70%;\n    max-width: 1200px; /* this make sure users don't see blank container */\n    height: 100%;\n    overflow: auto;\n    border-right: 1px solid rgb(var(--color-light-gray-3));\n  }\n\n  .UaGAr {\n    margin: var(--data-panel-xs-margin) var(--data-panel-padding) 0 0; /* nudge top to align with text */\n  }\n\n  ._8hN\\+- {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .daiGk {\n    margin-right: var(--s);\n    color: rgb(var(--color-gray-2));\n  }\n\n  ._4VprK {\n    display: none;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SheetDetailHeader": "Ax5z6",
	"SheetDetailHeader_leftActionItems": "jgs7O",
	"SheetDetailHeader_condensedWidthGrid": "gnjFa",
	"SheetDetailHeader_backIcon": "UaGAr",
	"SheetDetailHeader_rightActionItems": "_8hN+-",
	"SheetDetailHeader_rightActionText": "daiGk",
	"SheetDetailHeader_hiddenItem": "_4VprK"
};
export default ___CSS_LOADER_EXPORT___;
