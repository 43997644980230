import { GeoBounds } from 'app/models';

export class BoundsCalculator {
  private bounds: GeoBounds | null = null;
  public addPoint({ lon, lat }: { lon: number; lat: number }): void {
    if (!this.bounds) {
      this.bounds = {
        minLon: lon,
        maxLon: lon,
        minLat: lat,
        maxLat: lat
      };
    } else {
      this.bounds.minLon = Math.min(lon, this.bounds.minLon);
      this.bounds.maxLon = Math.max(lon, this.bounds.maxLon);
      this.bounds.minLat = Math.min(lat, this.bounds.minLat);
      this.bounds.maxLat = Math.max(lat, this.bounds.maxLat);
    }
  }

  public getBounds(): GeoBounds {
    return this.bounds;
  }
}
