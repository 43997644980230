import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { DropdownMenu as VDSDropdownMenu } from '@varicent/components';

interface DropdownMenuRadioGroupProps {
  value: string;
  onValueChange: (value: string) => void;
}

const DropdownMenuRadioGroup: React.FC<DropdownMenuRadioGroupProps> = (props: DropdownMenuRadioGroupProps) => {
  return <VDSDropdownMenu.RadioGroup {...props} />;
};

export default DropdownMenuRadioGroup;
