import React, { useCallback, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';
import { Callout } from '@blueprintjs/core';
import { ArrowLeft, Close } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';
import dayjs, { Dayjs } from 'dayjs';
import { Field, Form, Formik, FormikProps } from 'formik';
import debounce from 'lodash.debounce';
import { useHistory } from 'react-router-dom';

import TextButton from 'components/Buttons/TextButton/TextButton';
import CurrencySelect from 'components/CurrencySelect/CurrencySelect';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import { SearchableSelectMenuItem } from 'components/models';
import SelectMenu from 'components/SelectMenu/SelectMenu';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import DirtyFormPrompt from 'app/components/DirtyFormPrompt/DirtyFormPrompt';
import FormDatePicker from 'app/components/FormFields/FormDatePicker/FormDatePicker';
import TextMessageInputField from 'app/components/FormFields/TextMessageInputField/TextMessageInputField';

import { debounceDelay } from 'app/constants/DebounceConstants';

import { useCurrency } from 'app/contexts/currencyProvider';
import { useLocalization } from 'app/contexts/localizationProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { DeploymentModelTypeEnum, PCJobType } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_PLANNING_CYCLE } from 'app/graphql/mutations/upsertPlanningCycle';
import { GET_PLANNING_CYCLES } from 'app/graphql/queries/getPlanningCycles';
import { GET_TENANT_WIDE_INFO } from 'app/graphql/queries/getTenantWideInfo';
import { usePlanningCycleExistsByNameLazy } from 'app/graphql/queries/planningCycleExistsByName';

import useMakePlanningPath from 'app/hooks/useMakePlanningPath';
import useShowToast from 'app/hooks/useShowToast';

import { PeriodicityLabels, PlanDurations } from 'app/models';

import block from 'utils/bem-css-modules';
import { getCurrencyItem, getSortedCurrencyItems } from 'utils/helpers/currencyHelpers';
import { formatMessage } from 'utils/messages/utils';

import style from './UpsertCyclePage.module.pcss';
import validationsSchema from './validationsSchema';

const b = block(style);

const intervalItems = [
  {
    key: formatMessage('YEARLY'),
    value: 'Yearly'
  },
  {
    key: formatMessage('SEMI_ANNUAL'),
    value: 'Semi_annual'
  },
  {
    key: formatMessage('QUARTERLY'),
    value: 'Quarterly'
  },
  {
    key: formatMessage('MONTHLY'),
    value: 'Monthly'
  }
];

const intervalItemsLookupMap = {
  [PeriodicityLabels.YEARLY]: intervalItems[0],
  [PeriodicityLabels.SEMI_ANNUAL]: intervalItems[1],
  [PeriodicityLabels.QUARTERLY]: intervalItems[2],
  [PeriodicityLabels.MONTHLY]: intervalItems[3]
};

const planDurationItems = [
  {
    key: PlanDurations.YEARLY,
    value: PlanDurations.YEARLY
  },
  {
    key: PlanDurations.SEMI_ANNUAL,
    value: PlanDurations.SEMI_ANNUAL
  },
  {
    key: PlanDurations.QUARTERLY,
    value: PlanDurations.QUARTERLY
  },
  {
    key: PlanDurations.MONTHLY,
    value: PlanDurations.MONTHLY
  }
];

const planDurationLookupMap = {
  [PlanDurations.YEARLY]: {
    item: planDurationItems[0],
    disabledIntervals: [],
    defaultInterval: intervalItemsLookupMap[PeriodicityLabels.YEARLY]
  },
  [PlanDurations.SEMI_ANNUAL]: {
    item: planDurationItems[1],
    disabledIntervals: [intervalItemsLookupMap[PeriodicityLabels.YEARLY]],
    defaultInterval: intervalItemsLookupMap[PeriodicityLabels.SEMI_ANNUAL]
  },
  [PlanDurations.QUARTERLY]: {
    item: planDurationItems[2],
    disabledIntervals: [
      intervalItemsLookupMap[PeriodicityLabels.YEARLY],
      intervalItemsLookupMap[PeriodicityLabels.SEMI_ANNUAL]
    ],
    defaultInterval: intervalItemsLookupMap[PeriodicityLabels.QUARTERLY]
  },
  [PlanDurations.MONTHLY]: {
    item: planDurationItems[3],
    disabledIntervals: [
      intervalItemsLookupMap[PeriodicityLabels.YEARLY],
      intervalItemsLookupMap[PeriodicityLabels.SEMI_ANNUAL],
      intervalItemsLookupMap[PeriodicityLabels.QUARTERLY]
    ],
    defaultInterval: intervalItemsLookupMap[PeriodicityLabels.MONTHLY]
  }
};

interface UpsertCyclePageProps {
  planningCycleSlug?: string;
}

interface UpsertPlanningCycleFormValues {
  title: string;
  description: string;
  planDuration: Record<string, string>;
  interval: Record<string, string>;
  startDate: Dayjs;
  currency: SearchableSelectMenuItem;
}

interface UpsertPlanningCycleObject extends UpsertPlanningCycleFormValues {
  currencyCode?: string;
  initialTitle?: string;
}

const UpsertCyclePage: React.FC<UpsertCyclePageProps> = ({ planningCycleSlug }: UpsertCyclePageProps) => {
  const history = useHistory();
  const { currencies } = useCurrency();
  const { setDefaultReportingCurrency } = useLocalization();
  const showToast = useShowToast();

  const [shouldShowSlugMessage, setShouldShowSlugMessage] = useState<boolean>(false);
  const [updatedPlanningCycle, setUpdatedPlanningCycle] = useState<Partial<UpsertPlanningCycleObject>>(null);
  const [shouldShowSlugSuccessIcon, setShouldShowSlugSuccessIcon] = useState<boolean>(false);
  const [isSlugValidated, setIsSlugValidated] = useState<boolean>(!!planningCycleSlug); // when page is loaded for the first time and is in edit mode; slug is already validated.

  const currencyItems = getSortedCurrencyItems(currencies);

  const formRef = useRef<FormikProps<UpsertPlanningCycleFormValues>>();

  const {
    userProfile: { tenant },
    userPlanningCycles,
    pcJobsInProgress,
    setPcJobsInProgress
  } = useUser();

  const currentPlanningCycle =
    userPlanningCycles &&
    userPlanningCycles.length > 0 &&
    userPlanningCycles.find((cycle) => cycle.planningCycleSlug === planningCycleSlug);

  const hasManageBeenActivated = currentPlanningCycle?.deploymentModels?.some(
    (deploymentModel) => deploymentModel.deploymentModelType === DeploymentModelTypeEnum.Manage
  );

  const isEditingPlanningCycle = !!currentPlanningCycle;

  const disabledPlanningCycleFieldCondition = !isEditingPlanningCycle;

  let formInitialValues;

  if (currentPlanningCycle) {
    const {
      planningCycleName,
      planningCycleDescription,
      planningCycleDuration,
      planningCyclePeriodicity,
      planningCycleStartDate,
      currencyCode
    } = currentPlanningCycle;

    formInitialValues = {
      title: planningCycleName,
      description: planningCycleDescription,
      planDuration: { key: planningCycleDuration.toString(), value: planningCycleDuration.toString() },
      interval: { key: planningCyclePeriodicity, value: planningCyclePeriodicity },
      startDate: planningCycleStartDate ? dayjs(planningCycleStartDate).startOf('month') : dayjs().startOf('month'),
      currencyCode: getCurrencyItem(currencies, currencyCode)
    };
  } else {
    formInitialValues = {
      title: '',
      description: '',
      planDuration: planDurationItems[0],
      interval: intervalItems[0],
      startDate: dayjs().startOf('month'),
      currencyCode: getCurrencyItem(currencies, 'USD')
    };
  }

  const getFormattedDate = (date) => {
    return dayjs(date).format('YYYY-MM-DD');
  };

  const makePlanningPath = useMakePlanningPath();

  const pcDateChanged = () => {
    return updatedPlanningCycle?.startDate;
  };

  const wasPCUpdated = () => {
    // Update this condition if more components in PC are editable from PC Edit menu
    return (
      pcDateChanged() ||
      updatedPlanningCycle?.title ||
      updatedPlanningCycle?.description ||
      updatedPlanningCycle?.currencyCode
    );
  };

  const handleUpsertPlanningCycleComplete = (data) => {
    const successText = wasPCUpdated()
      ? formatMessage('EDIT_NEW_CYCLE_SUCCESS')
      : formatMessage('CREATE_NEW_CYCLE_SUCCESS');

    if (pcDateChanged()) {
      setPcJobsInProgress([
        ...pcJobsInProgress,
        {
          planningCycleId: data?.upsertPlanningCycle?.planningCycleId,
          planningCycleName: updatedPlanningCycle?.title ?? updatedPlanningCycle.initialTitle,
          jobType: PCJobType.START_DATE_CHANGE
        }
      ]);
    } else {
      showToast(successText, 'success');
    }

    if (data?.upsertPlanningCycle?.currencyCode) {
      setDefaultReportingCurrency(data?.upsertPlanningCycle?.currencyCode);
    }

    // redirect the user back to the planning cycles page (if they updated a planning cycle),
    // otherwise redirect the user to their new planning cycle (if they created a planning cycle)
    const redirectPath = wasPCUpdated()
      ? `/${tenant?.slug}`
      : makePlanningPath({
          tenantSlug: tenant?.slug,
          planningCycleSlug: data?.upsertPlanningCycle?.planningCycleSlug
        });

    history.push(redirectPath);
  };

  const handleUpsertPlanningCycleError = ({ graphQLErrors, networkError }) => {
    handleError(graphQLErrors, networkError);
    const errorText = currentPlanningCycle
      ? formatMessage('EDIT_NEW_CYCLE_ERROR')
      : formatMessage('CREATE_NEW_CYCLE_ERROR');
    const generateErrorText = formatMessage('UPDATE_PC_FAILURE', {
      planningCycleName: currentPlanningCycle?.planningCycleName
    });

    if (wasPCUpdated()) {
      showToast(
        <ToastMessage title={formatMessage('EDIT_PLANNING_CYCLE_FAILURE_TITLE')} message={generateErrorText} />,
        'danger'
      );
    } else {
      showToast(errorText, 'danger');
    }
  };

  const [upsertPlanningCycle] = useMutation(UPSERT_PLANNING_CYCLE, {
    onCompleted: handleUpsertPlanningCycleComplete,
    onError: handleUpsertPlanningCycleError,
    awaitRefetchQueries: true,
    refetchQueries: [GET_TENANT_WIDE_INFO, GET_PLANNING_CYCLES]
  });

  const [planningCycleExistsByName, { data: planningCycleExists, loading: planningCycleExistsLoading }] =
    usePlanningCycleExistsByNameLazy({
      fetchPolicy: 'network-only'
    });

  const handlePlanningCycleNameValidation = () => {
    if (!formRef?.current) {
      return;
    }

    const { values, isSubmitting } = formRef?.current;

    const planningCycleName = values?.title;

    // in case page is in edit mode and planning cycle name has not been changed; slug is valid
    if (planningCycleName.trim().toLowerCase() === currentPlanningCycle?.planningCycleName?.trim().toLowerCase()) {
      setIsSlugValidated(true);
      return;
    }

    // in case planning cycle form is submitting we don't want to run this validation (we have already validated on change and blur)
    if (!planningCycleName || isSubmitting) {
      return;
    }

    planningCycleExistsByName({
      variables: { planningCycleName, tenantId: tenant?.id }
    });
  };

  const debounceHandler = useCallback(debounce(handlePlanningCycleNameValidation, debounceDelay), []);

  useEffect(() => {
    if (!formRef?.current) {
      return;
    }
    const { values, setFieldTouched } = formRef?.current;

    if (!planningCycleExistsLoading && planningCycleExists?.planningCycleExistsByName) {
      const slugExists = planningCycleExists?.planningCycleExistsByName.exists;

      if (slugExists) {
        setFieldTouched('title', true, false);
        setTitleError(
          formatMessage('UNAVAILABLE_WITH_PLANNING_CYCLE_ID', {
            name: values.title,
            slugName: planningCycleExists?.planningCycleExistsByName?.slugName
          })
        );
      }

      setIsSlugValidated(!slugExists);
      setShouldShowSlugMessage(!slugExists);
      setShouldShowSlugSuccessIcon(!slugExists);
    }
  }, [planningCycleExists, planningCycleExistsLoading, formRef]);

  const setTitleError = (errorMessage: string) => {
    const { setFieldError } = formRef?.current;
    setFieldError('title', errorMessage);
  };

  const handleTitleInputOnChange = () => {
    setIsSlugValidated(false);
    setShouldShowSlugSuccessIcon(false);
    setShouldShowSlugMessage(false);
    setTitleError('');
    debounceHandler();
  };

  return (
    <>
      <div className={b('container')} data-testid="upsert-cycle-page">
        <div>
          <div className={b('buttonContainer')}>
            <div className={b('navButton')} onClick={() => history.goBack()}>
              <ArrowLeft size={32} />
              {formatMessage('BACK')}
            </div>
            <div className={b('navButton')} onClick={() => history.goBack()}>
              <Close size={32} />
              {formatMessage('CANCEL')}
            </div>
          </div>
          <div className={b('title')}>
            <HTMLHeading
              tagLevel="h1"
              styleLevel="h3"
              data-testid="planning-cycle-heading"
              text={currentPlanningCycle ? formatMessage('EDIT_PLANNING_CYCLE') : formatMessage('NEW_PLANNING_CYCLE')}
            />
          </div>
        </div>
        <Formik
          innerRef={formRef}
          enableReinitialize
          validateOnMount
          initialValues={formInitialValues}
          validationSchema={validationsSchema}
          onSubmit={async (values) => {
            if (!tenant?.id) {
              return;
            }

            const startDate = getFormattedDate(values.startDate);

            const newDate = dayjs(startDate).startOf('month');
            const oldDate = dayjs(formInitialValues.startDate);

            const updatedComponent: Partial<UpsertPlanningCycleObject> = {
              initialTitle: formInitialValues.title
            };

            if (Math.abs(newDate.diff(oldDate)) > 0) {
              updatedComponent['startDate'] = newDate;
            }
            if (values.title !== formInitialValues.title) {
              updatedComponent['title'] = values.title;
            }

            if (values.currencyCode !== formInitialValues.currencyCode) {
              updatedComponent['currencyCode'] = values.currencyCode;
            }

            if (values.description !== formInitialValues.description) {
              updatedComponent['description'] = values.description;
            }

            setUpdatedPlanningCycle(currentPlanningCycle ? updatedComponent : null);

            await upsertPlanningCycle({
              variables: {
                tenantId: tenant?.id,
                planningCycleId: currentPlanningCycle ? currentPlanningCycle.planningCycleId : undefined,
                planningCycleName: values.title,
                planningCycleComment: values.description,
                planningCycleDuration: values.planDuration?.value,
                planningCyclePeriodicity: values.interval?.value,
                planningCycleStartDate: startDate,
                currencyCode: values.currencyCode?.value
              }
            });
          }}
        >
          {({ isValid, isSubmitting, values, setFieldValue }) => {
            return (
              <div className={b('form')}>
                <Form>
                  <DirtyFormPrompt data-testid="dirty-form-prompt" />
                  <div className={b('titleFormGroupContainer')}>
                    <TextMessageInputField
                      isRequired
                      name="title"
                      textLabel={formatMessage('PLANNING_CYCLE_NAME')}
                      textPlaceholder={formatMessage('UNTITLED')}
                      isLoading={planningCycleExistsLoading}
                      shouldShowSuccessIcon={shouldShowSlugSuccessIcon}
                      shouldShowErrorIcon={planningCycleExists?.planningCycleExistsByName?.exists}
                      isMessageAvailable={planningCycleExists?.planningCycleExistsByName?.slugName && isSlugValidated}
                      shouldShowMessage={shouldShowSlugMessage}
                      shouldValidateOnTouch={false}
                      textBoxClassName={b('titleFormGroup')}
                      message={
                        <>
                          <span className={b('messageBold')}>"{values.title}"</span>
                          {formatMessage('AVAILABLE_WITH_PLANNING_CYCLE_ID')}
                          <span className={b('messageBold')}>
                            "{planningCycleExists?.planningCycleExistsByName?.slugName}"
                          </span>
                        </>
                      }
                      onChange={handleTitleInputOnChange}
                      onTransitionEnd={() => setShouldShowSlugMessage(false)}
                    />
                  </div>

                  <div className={b('textAreaContainer')}>
                    <Field
                      className={b('textArea')}
                      name="description"
                      data-testid="description-field"
                      placeholder={formatMessage('ADD_DESCRIPTION_PLACEHOLDER')}
                      component="textarea"
                    />
                  </div>

                  <div className={b('formRow')}>
                    <div className={b('formRowDateContainer')}>
                      <div className={b('planDurationLabel')}>{formatMessage('PLAN_DURATION')}</div>
                      <div className={b('planDurationContainer')} data-testid="plan-duration-field">
                        {formatMessage('NUM_OF_MONTHS')}
                        <MessageTooltip
                          content={formatMessage('DISABLED_PLANNING_CYCLE_FIELD_TOOLTIP')}
                          disabled={disabledPlanningCycleFieldCondition}
                          target={
                            <Field
                              name="planDuration"
                              theme="default"
                              component={SelectMenu}
                              items={planDurationItems}
                              showErrors={false}
                              disabled={isEditingPlanningCycle}
                              onChange={async (selectedItem) => {
                                setFieldValue('planDuration', selectedItem);

                                // Set interval to its default value if the selected value is not allowed
                                // eg. if a planDuration of 6 months is selected, we don't allow the user to
                                // select an interval of 'Yearly' - so set the interval to 'Semi-annual'
                                const selectedPlanDuration = planDurationLookupMap[selectedItem.value];
                                if (
                                  selectedPlanDuration?.disabledIntervals?.filter(
                                    (interval) => interval.value === values.interval.value
                                  )?.length > 0
                                ) {
                                  setFieldValue('interval', selectedPlanDuration?.defaultInterval);
                                }
                              }}
                            />
                          }
                          placement={'top'}
                        />
                      </div>
                    </div>

                    <div className={b('formRowDateContainer')}>
                      <div className={b('intervalLabel')}>{formatMessage('PERIODICITY')}</div>
                      <div className={b('periodicityContainer')}>
                        <div className={b('intervalContainer')} data-testid="periodicity-interval-field">
                          {formatMessage('INTERVAL_CYCLE_REQUIRED_MARK')}
                          <MessageTooltip
                            content={formatMessage('DISABLED_PLANNING_CYCLE_FIELD_TOOLTIP')}
                            disabled={disabledPlanningCycleFieldCondition}
                            target={
                              <Field
                                name="interval"
                                theme="default"
                                component={SelectMenu}
                                disabledItems={planDurationLookupMap[values.planDuration.value]?.disabledIntervals}
                                items={intervalItems}
                                showErrors={false}
                                disabled={isEditingPlanningCycle}
                                data-testid="periodicity-field"
                              />
                            }
                            placement={'top'}
                          />
                        </div>
                        <div className={b('dateInputContainer')} data-testid="periodicity-start-date-field">
                          {formatMessage('START_DATE_MONTH_REQUIRED_MARK')}
                          <Field
                            name="startDate"
                            className={b('dateInput')}
                            component={FormDatePicker}
                            dateFormat="MM/yyyy"
                            showErrors={false}
                            disabled={isEditingPlanningCycle && hasManageBeenActivated}
                            showMonthYearPicker
                            data-testid="start-date-field"
                          />
                        </div>
                      </div>
                    </div>

                    <div className={b('formRowDateContainer')}>
                      <div className={b('currencyLabel')}>{formatMessage('CURRENCY')}</div>
                      <div className={b('currencyInputContainer')} data-testid="currency-input-field">
                        {formatMessage('REPORTING_CURRENCY')}
                        <Field
                          name="currencyCode"
                          theme="default"
                          items={currencyItems}
                          component={CurrencySelect}
                          showErrors={false}
                          data-testid={'currency-field'}
                        />
                      </div>
                    </div>
                  </div>

                  {!isEditingPlanningCycle && (
                    <Callout
                      intent="warning"
                      title={formatMessage('SET_PC_DURATION_PERIODICITY_WARNING')}
                      className={b('calloutWarning')}
                    >
                      <p data-testid="pc-duration-periodicity-warning">
                        {formatMessage('SET_PC_DURATION_PERIODICITY_WARNING_DETAIL_UPDATED')}
                      </p>
                    </Callout>
                  )}

                  <div className={b('footer')}>
                    <div className={b('submitButton')}>
                      <TextButton
                        text={currentPlanningCycle ? formatMessage('UPDATE') : formatMessage('CREATE')}
                        type="submit"
                        intent="primary"
                        disabled={!isValid || isSubmitting || !isSlugValidated}
                        loading={isSubmitting}
                        testId={'submit-btn'}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default UpsertCyclePage;
