import gql from 'graphql-tag';

import { createUseQuery, createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetSymonPipeConfiguration,
  GetSymonPipeConfigurationVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_SYMON_PIPE_CONFIGURATION = gql`
  query GetSymonPipeConfiguration($symonPipeConfigurationId: Int!) {
    getSymonPipeConfiguration(input: { symonPipeConfigurationId: $symonPipeConfigurationId }) {
      status
      mapping {
        fieldName
        value {
          id
          order
          type
        }
      }
      pipeId
      pipeName
      tableNameLabel
      exportId
      exportName
      fileType
    }
  }
`;

export const useGetSymonPipeConfiguration = createUseQuery<
  GetSymonPipeConfiguration,
  GetSymonPipeConfigurationVariables
>(GET_SYMON_PIPE_CONFIGURATION);
export const useGetSymonPipeConfigurationLazy = createUseLazyQuery<
  GetSymonPipeConfiguration,
  GetSymonPipeConfigurationVariables
>(GET_SYMON_PIPE_CONFIGURATION);
