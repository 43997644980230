import { useLocation, useParams } from 'react-router-dom';

import { DeploymentModelPhase } from 'app/models';

interface SlugParts {
  tenantSlug: string;
  planningCycleSlug: string;
  deploymentModelSlug: string;
}

interface LocationParts {
  tenantSlug?: string;
  planningCycleSlug?: string;
  deploymentModelSlug?: string;
  viewParam?: 'territory' | 'quota';
  scenarioParam?: string | null;
  urlParam?: string;
}

const QueryParams = {
  scenario: 'scenario',
  view: 'view'
} as const;

const useMakePlanningPath = (): ((locationParts?: LocationParts) => { pathname: string; search: string }) => {
  const baseSlugs = useParams<SlugParts>();
  const baseSearch = useLocation().search;

  return (overrides: LocationParts = {}): { pathname: string; search: string } => {
    const combinedSlugs = { ...baseSlugs, ...overrides };
    const phase = DeploymentModelPhase[combinedSlugs.deploymentModelSlug] ?? DeploymentModelPhase.plan;

    const baseSearchParams = new URLSearchParams(baseSearch);
    const combinedSearch = new URLSearchParams();
    if (overrides.scenarioParam) {
      combinedSearch.set(QueryParams.scenario, overrides.scenarioParam);
    } else if (baseSearchParams.has(QueryParams.scenario)) {
      combinedSearch.set(QueryParams.scenario, baseSearchParams.get(QueryParams.scenario));
    }

    if (overrides.viewParam) {
      combinedSearch.set(QueryParams.view, overrides.viewParam);
    }

    if (phase === DeploymentModelPhase.manage) {
      combinedSearch.delete(QueryParams.view);
      combinedSearch.delete(QueryParams.scenario);
    }

    const pathParts = [combinedSlugs.tenantSlug, combinedSlugs.planningCycleSlug, phase];

    if (overrides.urlParam) {
      pathParts.push(overrides.urlParam);
    }
    return {
      pathname: `/${pathParts.join('/')}`,
      search: `?${combinedSearch}`
    };
  };
};

export default useMakePlanningPath;
