import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { DeleteHierarchyAttribute, DeleteHierarchyAttributeVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const DELETE_HIERARCHY_ATTRIBUTE = gql`
  mutation DeleteHierarchyAttribute(
    $hierarchyId: Int!
    $planningCycleId: Int!
    $hierarchyType: String!
    $hierarchyAttributeName: String!
  ) {
    deleteHierarchyAttribute(
      input: {
        hierarchyId: $hierarchyId
        planningCycleId: $planningCycleId
        hierarchyType: $hierarchyType
        hierarchyAttributeName: $hierarchyAttributeName
      }
    ) {
      hierarchyId
      planningCycleId
      name
      key
      parentKey
      path
      version
      customProperties
      children
      hasChildren
    }
  }
`;

export const useDeleteHierarchyAttribute = createUseMutation<
  DeleteHierarchyAttribute,
  DeleteHierarchyAttributeVariables
>(DELETE_HIERARCHY_ATTRIBUTE);
