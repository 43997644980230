import React from 'react';

import { Copy } from '@carbon/icons-react';

import ConfirmActionModal from 'app/components/ConfirmActionModal/ConfirmActionModal';

import { formatMessage } from 'utils/messages/utils';

interface CycleCloneDialogProps {
  onSubmit: () => void;
  onClose: () => void;
  isOpen: boolean;
  planningCycleName?: string;
}

const CycleCloneDialog: React.FC<CycleCloneDialogProps> = ({
  onSubmit,
  onClose,
  isOpen,
  planningCycleName
}: CycleCloneDialogProps) => {
  const title = formatMessage('COPY_WITH_NAME', { name: planningCycleName });

  return (
    <>
      <div>
        <ConfirmActionModal
          data-testid="cycle-clone-dialog"
          isOpen={isOpen}
          onCancel={onClose}
          confirmButtonText={formatMessage('MAKE_A_COPY')}
          confirmButtonIcon={<Copy />}
          onConfirmAction={onSubmit}
          actionButtonClass="accentButton"
          minimal={true}
          title={title}
          body={
            <div data-testid="cycle-clone-dialog">
              {formatMessage('COPY_PC_MESSAGE')}
              <br />
              <br />
            </div>
          }
        />
      </div>
    </>
  );
};

export default CycleCloneDialog;
