import React from 'react';

import { Launch } from '@carbon/icons-react';

import block from 'utils/bem-css-modules';

import style from './ExternalLink.module.pcss';

const b = block(style);

interface ExternalLinkProps {
  linkText: string;
  url: string;
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({ linkText, url }) => {
  return (
    <a href={url} rel="noopener noreferrer" target="_blank" data-testid="external-link">
      <div className={b('linkContainer')}>
        <div data-testid="link-text">{linkText}</div>
        <div className={b('linkIcon')}>
          <Launch />
        </div>
      </div>
    </a>
  );
};
