import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

import EllipsisText from 'components/EllipsisText/EllipsisText';
import GapIndicator from 'components/GapIndicator/GapIndicator';
import StatusBar from 'components/StatusBar/StatusBar';

import block from 'utils/bem-css-modules';
import { formatNumberByMeasureType } from 'utils/helpers/currencyHelpers';

import style from './BalancingMetricCellRenderer.module.pcss';

const b = block(style);

interface BalancingMetricCellRendererProps extends ICellRendererParams {
  currency: string;
  measureType?: string;
  isBalancingPanel?: boolean;
  showAccountsGap?: boolean;
}

const BalancingMetricCellRenderer: React.FC<BalancingMetricCellRendererProps> = ({
  data,
  column,
  currency,
  measureType,
  isBalancingPanel,
  showAccountsGap
}) => {
  if (!data) {
    return null;
  }

  let value = data?.[column?.getColId()];
  if (measureType) {
    value = formatNumberByMeasureType(value, measureType, currency);
  }

  const isProgressVisualizable = data?.progress >= 0 && data?.progress <= 1;

  return (
    <div data-testid={'balancing-metric-cell-renderer'} className={b('balancingMetricRendererWrapper')}>
      {!isBalancingPanel && (
        <div data-testid={'balancing-metric-cell-renderer-gap-indicator'} className={b('gapIndicatorWrapper')}>
          <GapIndicator gap={showAccountsGap ? data['numberOfAccountsGap'] : data['measureGap']} />
        </div>
      )}
      {isBalancingPanel &&
        (!data?.isRemoved && isProgressVisualizable ? (
          <div className={b('statusBarWrapper')} data-testid={`${data.customer}-balancing-metric-progress-bar`}>
            <StatusBar completionPercent={data['progress']} data-testid={`${data.customer}-progress`} />
          </div>
        ) : (
          <div
            className={b('emptyStatusBarWrapper')}
            data-testid={`${data.customer}-empty-balancing-metric-progress-bar`}
          />
        ))}
      <div data-testid={'balancing-metric-cell-renderer-value'} className={b('balancingMetricValue')}>
        <EllipsisText text={value} />
      </div>
    </div>
  );
};

export default BalancingMetricCellRenderer;
