import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { SplitFeatures } from 'app/global/features';

import { AccountSourceFilterEnum } from 'app/graphql/generated/graphqlApolloTypes';

import useTreatment from 'app/hooks/useTreatment';

import { HierarchyType } from 'app/models';

export const useIsFilteringAccountsByActivities = (): boolean => {
  const [isAccountMapSourceFilterOn] = useTreatment(SplitFeatures.ACCOUNT_MAP_SOURCE_FILTER);
  const { primaryHierarchy, accountSourceFilter } = useMapContextRedistributor();

  return (
    isAccountMapSourceFilterOn &&
    primaryHierarchy === HierarchyType.CustomerAccountHierarchy &&
    accountSourceFilter === AccountSourceFilterEnum.Activity
  );
};
