import React, { useCallback, useMemo } from 'react';

import { CalloutV2, Intent, Radio, RadioGroup } from '@varicent/components';
import { Field, Formik, FormikErrors } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import { KeyValue } from 'components/models';
import SelectMenu from 'components/SelectMenu/SelectMenu';

import Combinator from 'app/components/TerritoryRuleBuilder/Combinator';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { useGetRootHierarchies } from 'app/graphql/queries/getRootHierarchies';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { CombinatorType, HierarchyType, MchQuantity, MchQuantityType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MapRuleSettingsDialog.module.pcss';

const b = block(style);

interface MapRuleSettingsDialogProps {
  onClose: () => void;
}

type RootItem = KeyValue<string>;

const fallbackRootItem: RootItem = { key: formatMessage('SELECT'), value: '' };

interface FormValues {
  quantity: MchQuantityType;
  customHierarchyRoot: RootItem;
}

const MapRuleSettingsDialog: React.FC<MapRuleSettingsDialogProps> = ({ onClose }: MapRuleSettingsDialogProps) => {
  const { selectedPlanningCycle } = useScope();
  const { chosenCustomHierarchy, setChosenCustomHierarchy } = useDedicatedMapProvider();
  const [isSingleCustomHierarchyMapOn] = useTreatment(SplitFeatures.MAP_SINGLE_CUSTOM_HIERARCHY);
  const showToast = useShowToast();

  const rootHierarchies = useGetRootHierarchies({
    variables: { planningCycleId: selectedPlanningCycle?.id },
    skip: !selectedPlanningCycle?.id || !isSingleCustomHierarchyMapOn,
    onError() {
      showToast(formatMessage('HIERARCHY_ERROR'), 'danger');
    }
  });
  const roots = useMemo(
    () =>
      rootHierarchies.data?.getRootHierarchies
        ?.filter((hierarchy) => hierarchy.hierarchyType === HierarchyType.CustomHierarchy)
        .map(({ rootHierarchyId, rootKey, rootName }) => ({ rootHierarchyId, rootKey, rootName })) ?? [],
    [rootHierarchies.data]
  );

  const rootItems = useMemo(
    () => roots.map((root) => ({ key: root.rootName, value: root.rootHierarchyId.toString() })),
    [roots]
  );

  const initialFormValues = useMemo((): FormValues => {
    let customHierarchyRoot: RootItem;
    if (chosenCustomHierarchy.quantity === MchQuantity.SINGULAR) {
      customHierarchyRoot = rootItems.find((item) => item.key === chosenCustomHierarchy.details.rootKey);
    }
    return {
      quantity: chosenCustomHierarchy.quantity,
      customHierarchyRoot: customHierarchyRoot ?? fallbackRootItem
    };
  }, [chosenCustomHierarchy, rootItems]);

  const validate = useCallback((formValues: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    if (formValues.quantity === MchQuantity.SINGULAR && !formValues.customHierarchyRoot.value)
      errors.customHierarchyRoot = { key: formatMessage('REQUIRED'), value: formatMessage('REQUIRED') };
    return errors;
  }, []);

  const handleFormikSubmit = (formValues: FormValues) => {
    if (formValues.quantity === MchQuantity.SINGULAR) {
      const rootDetails = roots.find((root) => root.rootHierarchyId === +formValues.customHierarchyRoot.value);
      if (!rootDetails) throw new Error(`No single hierarchy chosen`);
      setChosenCustomHierarchy({ quantity: formValues.quantity, details: rootDetails });
    } else {
      setChosenCustomHierarchy({ quantity: formValues.quantity });
    }
    onClose();
  };

  const renderCustomHierarchyName = (root: RootItem) => {
    if (!isSingleCustomHierarchyMapOn) return formatMessage('CUSTOM_HIERARCHY_RULES');
    if (!root.value) return formatMessage('DYNAMIC_HIERARCHY_PLACEHOLDER');
    return root.key;
  };

  return (
    <Formik<FormValues>
      initialValues={initialFormValues}
      onSubmit={handleFormikSubmit}
      enableReinitialize
      validate={validate}
    >
      {({ handleSubmit, values, setFieldValue, isValid }) => (
        <Dialog
          isOpen
          portalClassName={b('dialog')}
          title={formatMessage('VISUALIZATION_SETTINGS')}
          cancelButtonText={formatMessage('CANCEL')}
          confirmButtonText={formatMessage('VISUALIZE')}
          onClose={onClose}
          onSubmit={handleSubmit}
          disableConfirm={!isValid}
        >
          <div data-testid="map-rule-settings-dialog">
            <p className={b('dialogExplanation')}>{formatMessage('VISUALIZATION_SETTINGS_DIALOG_EXPLANATION')}</p>

            <div className={b('formElements')}>
              <RadioGroup
                name="quantity"
                className={b('radioGroup')}
                selectedValue={values.quantity}
                onChange={(event) => setFieldValue('quantity', event.currentTarget.value as MchQuantityType)}
              >
                <Radio
                  data-testid="default-visual-radio"
                  value={MchQuantity.NONE}
                  inline
                  className={b('radio')}
                  labelElement={
                    <div className={b('radioLabel')}>
                      <strong className={b('radioTitle')}>{formatMessage('GEOGRAPHY_RULES_ONLY')}</strong>
                      <div className={b('radioDescription')}>
                        {formatMessage('GEOGRAPHY')}
                        <Combinator data-testid="geography-rules-combinator-or" combinatorType={CombinatorType.OR} />
                        {formatMessage('ACCOUNT_OVERRIDES')}
                        <span className={b('defaultText')}>{formatMessage('DEFAULT_WITH_BRACKETS')}</span>
                      </div>
                    </div>
                  }
                />
                <Radio
                  data-testid="complex-visual-radio"
                  value={isSingleCustomHierarchyMapOn ? MchQuantity.SINGULAR : MchQuantity.ALL}
                  disabled={isSingleCustomHierarchyMapOn && rootItems.length === 0}
                  inline
                  className={b('radio')}
                  labelElement={
                    <div className={b('radioLabel')}>
                      <strong className={b('radioTitle')}>{formatMessage('COMPLEX_RULES')}</strong>
                      <div className={b('radioDescription')}>
                        {formatMessage('GEOGRAPHY')}
                        <Combinator data-testid="complex-rules-combinator-and" combinatorType={CombinatorType.AND} />
                        {renderCustomHierarchyName(values.customHierarchyRoot)}
                        <Combinator data-testid="complex-rules-combinator-or" combinatorType={CombinatorType.OR} />
                        {formatMessage('ACCOUNT_OVERRIDES')}
                      </div>
                    </div>
                  }
                />
              </RadioGroup>

              {isSingleCustomHierarchyMapOn && (
                <label className={b('hierarchyLabel')}>
                  {formatMessage('HIERARCHY')}
                  <Field
                    component={SelectMenu}
                    items={rootItems}
                    loading={rootHierarchies.loading}
                    name="customHierarchyRoot"
                    disabled={values.quantity !== MchQuantity.SINGULAR}
                    required={values.quantity === MchQuantity.SINGULAR}
                    placeHolderText={formatMessage('SELECT')}
                    fullWidth
                    showErrors
                  />
                </label>
              )}

              {values.quantity !== MchQuantity.NONE && (
                <CalloutV2 intent={Intent.WARNING}>{formatMessage('VISUALIZATION_SETTINGS_DIALOG_WARNING')}</CalloutV2>
              )}
            </div>
          </div>
        </Dialog>
      )}
    </Formik>
  );
};

export default MapRuleSettingsDialog;
