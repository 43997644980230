import React, { Dispatch, SetStateAction } from 'react';

import {
  FavoriteFilled,
  FlagFilled,
  LocationPersonFilled,
  PinFilled,
  StarFilled,
  StopFilledAlt,
  UserFilled
} from '@carbon/icons-react';
import { ErrorMessage } from 'formik';

import { PinIcon } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './PinSetCustomizer.module.pcss';

const b = block(style);

interface PinSetCustomizerProps {
  pinColor: string;
  setPinColor: Dispatch<SetStateAction<string>>;
  setSelectedIcon: Dispatch<SetStateAction<PinIcon>>;
  selectedIcon: PinIcon;
}

const pinIcons = [
  {
    name: PinIcon.STAR,
    icon: <StarFilled />
  },
  {
    name: PinIcon.SQUARE,
    icon: <StopFilledAlt />
  },
  {
    name: PinIcon.USER,
    icon: <UserFilled />
  },
  {
    name: PinIcon.FLAG,
    icon: <FlagFilled />
  },
  {
    name: PinIcon.LOCATION,
    icon: <LocationPersonFilled />
  },
  {
    name: PinIcon.PIN,
    icon: <PinFilled />
  },
  {
    name: PinIcon.FAVORITE,
    icon: <FavoriteFilled />
  }
] as const;

const PIN_SET_COLORS = [
  '#962C8D',
  '#4A2871',
  '#7C6712',
  '#425E2E',
  '#6452A1',
  '#0F437F',
  '#9C5734',
  '#02565D',
  '#027558',
  '#046D90',
  '#7A1F58',
  '#754813'
];

const PinSelector: React.FC<PinSetCustomizerProps> = ({
  pinColor,
  setPinColor,
  setSelectedIcon,
  selectedIcon
}: PinSetCustomizerProps) => {
  // Todo: TQP 5794 Switch to radio buttons
  // Todo: TQP 5794 Show errors when field invalid
  // Todo: TQP 5794 Split into PinIconSelector and PinColorSelector
  return (
    <>
      <label className={b('label')}>{formatMessage('PIN_SET_ICON')}</label>
      <div role="group">
        <div className={b('iconWrapper')}>
          {pinIcons.map((pinIcon) => (
            <div className={b('icon', { selected: selectedIcon === pinIcon.name })} key={pinIcon.name}>
              <label className={b('pickerLabel')}>
                <input
                  type="radio"
                  name="icon"
                  value={pinIcon.name}
                  className={b('radioField')}
                  aria-label={`${pinIcon.name} icon`}
                  onChange={() => setSelectedIcon(pinIcon.name)}
                  data-testid={`${pinIcon.name}-filled-icon`}
                />
                {pinIcon.icon}
              </label>
            </div>
          ))}
        </div>
        <div className={b('validationMessage')}>
          <ErrorMessage className={b('validationMessage')} name="icon" component="div" />
        </div>
      </div>

      <label className={b('label')}>{formatMessage('PIN_SET_COLOR')}</label>
      <div role="group">
        <div className={b('colorWrapper')}>
          {PIN_SET_COLORS.map((color, index) => (
            <div className={b('coloredCircleWrapper', { selectedCircle: color === pinColor })} key={color}>
              <label className={b('pickerLabel')}>
                <input
                  type="radio"
                  name="color"
                  value={color}
                  className={b('radioField')}
                  aria-label={formatMessage('COLOR_OPTION', { value: index + 1 })}
                  onChange={() => setPinColor(color)}
                  data-testid={`pin-color-${index}`}
                />
                <div className={b('coloredCircle')} style={{ backgroundColor: color }} />
              </label>
            </div>
          ))}
        </div>
        <div className={b('validationMessage')}>
          <ErrorMessage className={b('validationMessage')} name="color" component="div" />
        </div>
      </div>
    </>
  );
};
export default PinSelector;
