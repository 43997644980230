// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UYRjm {\n    font-weight: bold;\n    padding-bottom: 8px;\n  }\n  .JsGwP {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  }\n  .ehGtA {\n    display: flex;\n    flex-direction: row;\n    margin-bottom: 8px\n  }\n  .ehGtA:last-child {\n      margin-bottom: 0;\n    }\n  .xk7Cg {\n    flex-basis: 50%;\n    white-space: nowrap;\n    text-transform: lowercase\n  }\n  .xk7Cg::first-letter {\n      text-transform: uppercase;\n    }\n  .gO8gO {\n    flex: 1;\n    text-align: right;\n    padding-left: 16px;\n    padding-right: 2px;\n  }\n  .QLr1Z {\n    flex-basis: 50%;\n    padding-left: 16px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MeasureColumn_columnHeader": "UYRjm",
	"MeasureColumn_measureColumn": "JsGwP",
	"MeasureColumn_lineItem": "ehGtA",
	"MeasureColumn_itemName": "xk7Cg",
	"MeasureColumn_itemValue": "gO8gO",
	"MeasureColumn_targetContainer": "QLr1Z"
};
export default ___CSS_LOADER_EXPORT___;
