import React, { useEffect, useState } from 'react';

import { DocumentImport, PlayOutline } from '@carbon/icons-react';
import { Formik } from 'formik';

import MultiStepDialog from 'components/Dialog/MultiStepDialog';

import SymonDataMappingDialogContainer from 'app/components/DataPanel/ConfigurationsPanel/SymonDataMappingDialogContainer';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { ReviewImportConfiguration } from 'app/core/dataFileUpload/ReviewImportConfiguration';
import { SymonDataFileSelectStep } from 'app/core/dataFileUpload/symon/SymonDataFileSelectStep';

import { FileTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { ConfigurationActionType, ConfigurationDialogType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

import { usePublishFileFromSymonS3 } from './hooks/usePublishFileFromSymonS3';
import { useUpsertSymonPipeConfiguration } from './hooks/useUpsertSymonPipeConfiguration';
import validationSchema from './validationSchemaConfigurationDialog';

interface ConfigurationDialogProps {
  handleModalVisibility: (value: boolean) => void;
  isModalVisible: boolean;
  dialogTitle?: string;
  dialogType?: ConfigurationDialogType;
}

export type SymonDataTableType = {
  key: string;
  value: FileTypeEnum;
  rootHierarchyId: number | null;
};
export interface ConfigurationDialogFormInput {
  dataTableType: SymonDataTableType;
  symonExportNode: { key: string; value: string };
  symonPipeName: { key: string; value: string };
  destinationTable: string;
  configurationAction: ConfigurationActionType;
  configurationId: number | null;
  runOnCreate: boolean;
}

const initialFormValues: ConfigurationDialogFormInput = {
  dataTableType: { key: formatMessage('ACTIVITY_FILE'), value: FileTypeEnum.Activity, rootHierarchyId: null },
  symonExportNode: null,
  symonPipeName: null,
  destinationTable: '',
  configurationAction: ConfigurationActionType.CREATE,
  configurationId: null,
  runOnCreate: true
};

const ConfigurationDialog: React.FC<ConfigurationDialogProps> = ({
  handleModalVisibility,
  isModalVisible,
  dialogTitle = formatMessage('CREATE_CONFIGURATION'),
  dialogType = ConfigurationDialogType.IMPORT
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [disableNext, setDisableNext] = useState(false);
  const [disableComplete, setDisableComplete] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [symonFileType, setSymonFileType] = useState<SymonDataTableType>();
  const { selectedPlanningCycle } = useScope();
  const {
    setShouldRefetchConfigurations,
    missingRequiredFields,
    pollForSymonPipeProcessingStatus,
    mappingProperties,
    isAllColumnsMapped
  } = useData();
  const [isUpdateConfig, setIsUpdateConfig] = useState<boolean>(false);

  const [upsertSymonPipeConfiguration, { loading: upsertSymonPipeConfigurationLoading }] =
    useUpsertSymonPipeConfiguration(isUpdateConfig);

  const [publishFileFromS3, { loading: publishFileFromS3Loading }] = usePublishFileFromSymonS3({
    pollForProcessingStatus: pollForSymonPipeProcessingStatus
  });

  useEffect(() => {
    return () => {
      // Cleanup Function - reset button to default states
      setDisableNext(false);
      setDisableComplete(false);
      setIsSubmitted(false);
    };
  }, [isModalVisible]);

  const handleDisabledComplete = (value: boolean) => {
    setDisableComplete(value);
  };

  const handleDisabledNext = (value: boolean) => {
    setDisableNext(value);
  };

  const resetDialog = (resetForm) => {
    handleModalVisibility(false);
    setShouldRefetchConfigurations(dialogType !== ConfigurationDialogType.IMPORT);
    resetForm();
  };

  const publishSymon = async (exportId, pipeId, resetForm) => {
    await publishFileFromS3({
      variables: {
        input: {
          exportId,
          pipeId,
          planningCycleId: selectedPlanningCycle?.id
        }
      }
    });
    resetDialog(resetForm);
  };

  const onSubmit = async (values: ConfigurationDialogFormInput, { resetForm }) => {
    setIsUpdateConfig(values.configurationAction === ConfigurationActionType.RUN);

    if (values?.configurationId) {
      return publishSymon(values.symonExportNode.value, values.symonPipeName.value, resetForm);
    }

    const upsertSymon = await upsertSymonPipeConfiguration({
      variables: {
        input: {
          planningCycleId: selectedPlanningCycle?.id,
          fileType: values?.dataTableType?.value,
          pipeId: values.symonPipeName.value,
          pipeName: values.symonPipeName.key,
          exportId: values.symonExportNode.value,
          exportName: values.symonExportNode.key,
          tableNameLabel: values.destinationTable,
          properties: mappingProperties,
          symonPipeConfigurationId: values?.configurationId || null,
          hierarchyId: values?.dataTableType?.rootHierarchyId || null
        }
      }
    });

    const { exportId: exportIdQuery, pipeId: pipeIdQuery } = upsertSymon?.data?.upsertSymonPipeConfiguration;
    if (!values?.runOnCreate) {
      return resetDialog(resetForm);
    }
    return publishSymon(exportIdQuery, pipeIdQuery, resetForm);
  };

  const onClose = () => {
    handleModalVisibility(false);
  };

  const renderPages = (actionType: ConfigurationActionType) => {
    if (actionType === ConfigurationActionType.RUN) {
      return [
        <SymonDataFileSelectStep
          key="symon-data-file-select-step"
          handleDisabledComplete={handleDisabledComplete}
          dialogType={dialogType}
          handleDisabledNext={handleDisabledNext}
        />,
        <ReviewImportConfiguration key="review-import-configuration" />
      ];
    }
    return [
      <SymonDataFileSelectStep
        key="symon-data-file-select-step"
        handleDisabledComplete={handleDisabledComplete}
        dialogType={dialogType}
        handleDisabledNext={handleDisabledNext}
        setSymonFileType={setSymonFileType}
      />,
      <SymonDataMappingDialogContainer
        key="symon-data-mapping-dialog-container"
        isSubmitted={isSubmitted}
        handleDisabledNext={handleDisabledNext}
        symonFileType={symonFileType}
      />,
      <ReviewImportConfiguration key="review-import-configuration" />
    ];
  };

  const handleNext = () => {
    if (currentIndex === 1) {
      setIsSubmitted(true);
      if (missingRequiredFields.length === 0 && isAllColumnsMapped) {
        setCurrentIndex(currentIndex + 1);
        setIsSubmitted(false);
      }
    } else {
      setCurrentIndex(currentIndex + 1);
      setIsSubmitted(false);
    }
  };

  const renderProps = (values: ConfigurationDialogFormInput) => {
    const defaultProps = { onNext: handleNext };
    if (currentIndex === 2) {
      return { title: formatMessage('REVIEW_IMPORT') };
    }
    if (currentIndex === 1) {
      return {
        ...defaultProps,
        title: values.configurationId ? formatMessage('REVIEW_IMPORT') : formatMessage('MATCH_FIELDS_TITLE')
      };
    }
    return { ...defaultProps, title: dialogTitle };
  };

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ resetForm, handleSubmit, values }) => {
        return (
          <MultiStepDialog
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            disableNext={disableNext}
            disableConfirm={upsertSymonPipeConfigurationLoading || disableComplete || publishFileFromS3Loading}
            isOpen={isModalVisible}
            onClose={() => {
              resetForm();
              onClose();
            }}
            onSubmit={handleSubmit}
            cancelButtonText={formatMessage('CANCEL')}
            confirmButtonText={
              dialogType === ConfigurationDialogType.IMPORT
                ? formatMessage('IMPORT')
                : formatMessage('CREATE_CONFIGURATION')
            }
            confirmButtonLoading={upsertSymonPipeConfigurationLoading || publishFileFromS3Loading}
            data-testid="configuration-dialog"
            pages={renderPages(values.configurationAction)}
            confirmButtonIcon={dialogType === ConfigurationDialogType.IMPORT ? <DocumentImport /> : <PlayOutline />}
            {...renderProps(values)}
          />
        );
      }}
    </Formik>
  );
};

export default ConfigurationDialog;
