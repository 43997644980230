import React, { useState } from 'react';

import { SettingsAdjust } from '@carbon/icons-react';
// eslint-disable-next-line no-restricted-imports
import { Classes } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useDynamicMapHierarchyCopy } from 'app/components/TerritoryMap/hooks/useDynamicMapHierarchyCopy';
import { useIsMapCapable } from 'app/components/TerritoryMap/hooks/useMapCapability';
import MapVisualizationSettingsDialog from 'app/components/TerritoryMap/MapVisualizationSettingsDialog/MapVisualizationSettingsDialog';
import MapRuleSettingsDialog from 'app/components/TerritoryMap/TerritoryMapGrid/MapRuleSettingsDialog';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import block from 'utils/bem-css-modules';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';

import style from './MapGridTitle.module.pcss';

const b = block(style);

const MapGridTitle: React.FC = () => {
  const [isAccountFirstMapOn] = useTreatment(SplitFeatures.ACCOUNT_FIRST_MAP);
  const { isMapVisualizationSettingsLoading } = useMapContextRedistributor();
  const canViewVisualizationSettings = useIsMapCapable(MapCapability.VIEW_VISUALIZATION_SETTINGS);

  const [isRuleSettingsDialogOpen, setIsRuleSettingsDialogOpen] = useState(false);

  const { mapGridTitleText } = useDynamicMapHierarchyCopy();

  const renderRuleSettingsDialog = () => {
    if (isAccountFirstMapOn) {
      return (
        <MapVisualizationSettingsDialog
          onClose={() => setIsRuleSettingsDialogOpen(false)}
          onComplete={() => setIsRuleSettingsDialogOpen(false)}
          isLastStep={true}
        />
      );
    } else {
      return <MapRuleSettingsDialog onClose={() => setIsRuleSettingsDialogOpen(false)} />;
    }
  };

  return (
    <>
      {isMapVisualizationSettingsLoading ? (
        <div className={b('loadingSkeleton')} data-testid="loading-skeleton">
          <div className={Classes.SKELETON} />
        </div>
      ) : (
        <div className={b('titleWrapper')}>
          <span className={b('title')} data-testid="map-grid-title">
            {mapGridTitleText}
          </span>
          {canViewVisualizationSettings && (
            <IconButton
              type="button"
              icon={<SettingsAdjust />}
              testId="settings-button"
              onClick={() => setIsRuleSettingsDialogOpen(true)}
            />
          )}
          {isRuleSettingsDialogOpen && renderRuleSettingsDialog()}
        </div>
      )}
    </>
  );
};

export default MapGridTitle;
