// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5-WRa {\n  box-sizing: border-box;\n  margin: 0;\n  padding-top: 8px;\n}\n\n  .Zt6Q8 {\n    min-height: 138px;\n  }\n\n  .FfPVI {\n    display: flex;\n    padding-bottom: 10px;\n  }\n\n  .sIrqr {\n      padding-bottom: 0;\n    }\n\n  .sfMiX {\n    width: 30px;\n  }\n\n  .KN3SB {\n    color: rgb(var(--color-gray-1));\n    height: 130px;\n    cursor: pointer;\n    transition: all 0.2s ease-in-out;\n  }\n\n  .KN3SB:hover {\n    background-color: rgb(var(--color-light-gray-5));\n  }\n\n  .DW1IZ {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-top: 5px;\n    padding: 0;\n  }\n\n  .TOVjk {\n    width: 6px;\n    height: 6px;\n    background-color: white;\n    margin: 2px;\n    border: 1px solid rgb(var(--color-dark-gray-3));\n    border-radius: 50%;\n  }\n\n  ._2UzSi {\n    background-color: rgb(var(--color-dark-gray-3));\n  }\n\n  .T67In {\n    list-style: none;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    padding: 0 0 5px;\n  }\n\n  .RlEG- {\n    width: 100%;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BattleCardPanel": "_5-WRa",
	"BattleCardPanel__fullHeight": "Zt6Q8",
	"BattleCardPanel_panel": "FfPVI",
	"BattleCardPanel_panel__root": "sIrqr",
	"BattleCardPanel_arrowContainer": "sfMiX",
	"BattleCardPanel_arrow": "KN3SB",
	"BattleCardPanel_paginationDots": "DW1IZ",
	"BattleCardPanel_circle": "TOVjk",
	"BattleCardPanel_circle__fill": "_2UzSi",
	"BattleCardPanel_pagination": "T67In",
	"BattleCardPanel_form": "RlEG-"
};
export default ___CSS_LOADER_EXPORT___;
