// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u8Gbr {\n  display: flex;\n  overflow-x: scroll;\n}\n\n  .GJubN {\n    display: flex;\n    align-items: center;\n    width: -moz-fit-content;\n    width: fit-content;\n    max-width: 800px;\n    box-sizing: content-box;\n    height: 48px;\n    overflow-x: scroll;\n    overflow-y: hidden;\n  }\n\n  ._6lfmY {\n      overflow-x: hidden;\n      max-width: unset;\n    }\n\n  .DBwNb {\n    padding: 0 6px;\n  }\n\n  .\\-5pf1, .mea\\+t {\n    white-space: nowrap;\n    min-width: -moz-min-content;\n    min-width: min-content;\n    box-sizing: content-box;\n  }\n\n  .EDcyK {\n    padding: 5px 10px;\n    background-color: rgba(var(--color-cobalt-4), 0.3);\n    margin-left: 10px;\n    border-radius: 5px;\n    color: rgb(var(--color-cobalt-3));\n    font-weight: 500;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompactFilterV2": "u8Gbr",
	"CompactFilterV2_filter": "GJubN",
	"CompactFilterV2_filter__inline": "_6lfmY",
	"CompactFilterV2_operator": "DBwNb",
	"CompactFilterV2_hierarchyName": "-5pf1",
	"CompactFilterV2_hierarchyItems": "mea+t",
	"CompactFilterV2_remainingHierarchiesCount": "EDcyK"
};
export default ___CSS_LOADER_EXPORT___;
