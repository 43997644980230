import React from 'react';

import { FieldProps } from 'formik';

import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';

interface FormSwitchProps extends FieldProps {
  labelElement: JSX.Element;
  disabled: boolean;
}

const FormSwitch: React.FC<FormSwitchProps> = ({
  field: { name, value },
  form: { setFieldValue, setFieldTouched },
  labelElement,
  disabled,
  ...rest
}: FormSwitchProps) => {
  return (
    <SwitchButton
      labelElement={labelElement}
      disabled={disabled}
      checked={value}
      onChange={() => {
        setFieldTouched(name);
        setFieldValue(name, !value);
      }}
      {...rest}
      testId={'form-switch'}
    />
  );
};

export default FormSwitch;
