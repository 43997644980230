// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ELqrL {\n    min-width: 100%;\n    padding: var(--select-menu-padding);\n    width: var(--select-menu-width);\n    color: rgba(var(--color-light-gray-2));\n  }\n  ._2ZURz {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  }\n  .PFSVZ {\n    display: flex;\n    flex-direction: column;\n  }\n  .ug5dE {\n    font-size: 12px;\n    font-weight: normal;\n    padding: 8px 0;\n  }\n  ._7tG9d {\n    display: flex;\n    flex-direction: column;\n    padding: 0 5px;\n  }\n  .JAA42 {\n    display: flex;\n    flex-direction: column;\n    padding: 0 5px;\n  }\n  .nmzvl {\n    display: flex;\n    flex-direction: column;\n    padding: 0 5px;\n  }\n  .nOrrW {\n    display: flex;\n    flex-direction: column;\n    padding: 0 5px;\n  }\n  .WWEL0 {\n    display: flex;\n    flex-direction: column;\n    padding: 0 5px 26px;\n    width: 100%;\n  }\n  .WlRyT {\n    display: flex;\n    flex-direction: column;\n    padding: 0 5px;\n    margin-bottom: 150px;\n  }\n  .q7co3 {\n    display: flex;\n    flex: 1;\n    justify-content: center;\n  }\n  .YCj63 {\n    font-size: 12px;\n  }\n  .CwSPX {\n    margin-top: var(--xs);\n  }\n  .L9bmB {\n    margin-right: var(--xs);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UpsertTerritoryRuleDialog_battleCardName": "ELqrL",
	"UpsertTerritoryRuleDialog_inputRow": "_2ZURz",
	"UpsertTerritoryRuleDialog_formBody": "PFSVZ",
	"UpsertTerritoryRuleDialog_formBodyLabel": "ug5dE",
	"UpsertTerritoryRuleDialog_battleCardInput": "_7tG9d",
	"UpsertTerritoryRuleDialog_territoryGroupInput": "JAA42",
	"UpsertTerritoryRuleDialog_territoryIdInput": "nmzvl",
	"UpsertTerritoryRuleDialog_territoryNameInput": "nOrrW",
	"UpsertTerritoryRuleDialog_disabledTerritoryInputRow": "WWEL0",
	"UpsertTerritoryRuleDialog_territoryRuleInput": "WlRyT",
	"UpsertTerritoryRuleDialog_spinnerContainer": "q7co3",
	"UpsertTerritoryRuleDialog_customHierarchyLabel": "YCj63",
	"UpsertTerritoryRuleDialog_customHierarchyTags": "CwSPX",
	"UpsertTerritoryRuleDialog_customHierarchyTag": "L9bmB"
};
export default ___CSS_LOADER_EXPORT___;
