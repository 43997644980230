export const noop = (): void => {
  return;
};

export const form = {
  setFieldValue: noop,
  setFieldTouched: noop,
  setStatus: noop,
  setErrors: noop,
  setSubmitting: noop,
  setTouched: noop,
  setValues: noop,
  setFieldError: noop,
  validateForm: (): Promise<Record<string, string>> => Promise.resolve({}),
  validateField: noop,
  resetForm: noop,
  submitForm: (): Promise<void> => Promise.resolve(),
  setFormikState: noop,
  handleSubmit: noop,
  handleReset: noop,
  handleBlur: noop,
  handleChange: noop,
  getFieldProps: (): null => {
    return null;
  },
  getFieldMeta: (): null => {
    return null;
  },
  getFieldHelpers: (): null => {
    return null;
  },
  values: {},
  errors: {},
  touched: {},
  isSubmitting: null,
  isValidating: null,
  submitCount: null,
  dirty: false,
  isValid: false,
  initialValues: {},
  initialErrors: {},
  initialTouched: {},
  registerField: noop,
  unregisterField: noop
};
