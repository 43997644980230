import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { NumberFilterModel, TextFilterModel } from '@ag-grid-community/core';
import { Divider } from '@blueprintjs/core';
import { Field, Form, Formik } from 'formik';

import TextButton from 'components/Buttons/TextButton/TextButton';
import SelectMenu from 'components/SelectMenu/SelectMenu';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './GridFilter.module.pcss';

const b = block(style);

export interface GridFilterProps {
  type: 'number' | 'text';
  filterChangedCallback?: () => void;
  label?: string;
  isFullWidth?: boolean;
  usePortal?: boolean;
  showReset?: boolean;
  inputInitialValue?: string | number;
  typeInitialValue?: string;
  onFilterChange?: (filter: Record<string, string>) => void;
}

interface GridFilterSearchTypes {
  text: TextFilterModel['type'];
  number: NumberFilterModel['type'];
}

interface GridFilterDropdownSearchOptions {
  number: GridFilterDropdownOptions[];
  text: GridFilterDropdownOptions[];
}

interface GridFilterDropdownOptions {
  key: string;
  value: GridFilterSearchTypes['text'] | GridFilterSearchTypes['number'];
}

export default forwardRef((props: GridFilterProps, ref) => {
  const { usePortal = true, showReset = true, inputInitialValue, typeInitialValue } = props;

  const filterTypes: GridFilterDropdownSearchOptions = {
    number: [
      {
        key: formatMessage('EQUALS'),
        value: 'equals'
      },
      {
        key: formatMessage('NOT_EQUAL'),
        value: 'notEqual'
      },
      {
        key: formatMessage('LESS_THAN'),
        value: 'lessThan'
      },
      {
        key: formatMessage('LESS_THAN_OR_EQUALS'),
        value: 'lessThanOrEqual'
      },
      {
        key: formatMessage('GREATER_THAN'),
        value: 'greaterThan'
      },
      {
        key: formatMessage('GREATER_THAN_OR_EQUAL'),
        value: 'greaterThanOrEqual'
      }
    ],
    text: [
      { key: formatMessage('CONTAINS'), value: 'contains' },
      { key: formatMessage('EQUALS'), value: 'equals' },
      { key: formatMessage('NOT_EQUAL'), value: 'notEqual' },
      { key: formatMessage('NOT_CONTAINS'), value: 'notContains' },
      { key: formatMessage('STARTS_WITH'), value: 'startsWith' },
      { key: formatMessage('ENDS_WITH'), value: 'endsWith' }
    ]
  };

  const initialFilterType = typeInitialValue
    ? filterTypes[props.type].find((filterType) => filterType.value === typeInitialValue)
    : filterTypes[props.type][0];
  const [currentFilterType, setCurrentFilterType] = useState<GridFilterDropdownOptions>(initialFilterType);

  const initialFilterText = inputInitialValue?.toString() ?? undefined;
  const [filterText, setFilterText] = useState<string | undefined>(initialFilterText);

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterText != null && filterText !== '';
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        return { filterType: props.type, type: currentFilterType.value, filter: filterText };
      }
    };
  });

  const onChangeFilterText = (event) => {
    setFilterText(event.target.value);
    props.onFilterChange?.({ type: currentFilterType.value, filter: event.target.value });
  };

  const onChangeFilterType = (item) => {
    setCurrentFilterType(item);
    props.onFilterChange?.({ type: item.value, filter: filterText });
  };

  const handleReset = () => {
    onChangeFilterType(filterTypes[props.type][0]);
    onChangeFilterText({ target: { value: '' } });
  };

  useEffect(() => {
    if (typeInitialValue) {
      setCurrentFilterType(filterTypes[props.type].find((filterType) => filterType.value === typeInitialValue));
    }
  }, [typeInitialValue]);

  useEffect(() => {
    props.filterChangedCallback?.();
  }, [filterText]);

  return (
    <div
      className={b('filterContainer', { fullWidth: props.isFullWidth })}
      data-testid="account-quota-grid-filter-container"
    >
      <div className={b('filterInputs')} data-testid="account-quota-grid-filter-inputs">
        <Formik
          initialValues={{
            filterInputOptions: currentFilterType,
            filterInput: filterText
          }}
          onSubmit={null}
          enableReinitialize
        >
          {() => (
            <Form>
              <label data-testid="filter-label" className={b('textFilterLabel')}>
                {props.label ?? formatMessage('TEXT_FILTERS')}
              </label>
              <div>
                <Field
                  name="filterInputOptions"
                  items={filterTypes[props.type]}
                  theme="secondary"
                  showErrors={false}
                  component={SelectMenu}
                  onChange={onChangeFilterType}
                  usePortal={usePortal}
                  portalClassName="ag-custom-component-popup"
                />
              </div>
              <div className={b('filterInputBox')}>
                <Field
                  component={FormTextInputGroup}
                  value={filterText}
                  name="filterInput"
                  onChange={onChangeFilterText}
                  type={props.type}
                  showErrors={false}
                />
              </div>
              {showReset && (
                <>
                  <Divider />
                  <div className={b('filterResetBtn')}>
                    <TextButton
                      large={false}
                      text={formatMessage('RESET')}
                      testId="filter-reset-btn"
                      type="reset"
                      onClick={handleReset}
                    />
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
});
