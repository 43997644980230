import gql from 'graphql-tag';

export const GET_ACCOUNT_RULE_BINDINGS = gql`
  query GetAccountRuleBindings($input: GetAccountRuleBindingsInput!) {
    getAccountRuleBindings(input: $input) {
      totalCount
      bindings {
        hierarchies {
          key
          name
          hierarchyId
          type
        }
        sourceRule {
          ruleId
          territoryId
          territoryName
          territoryGroupId
          effectiveDate
          endDate
        }
        redirects {
          startDate
          endDate
          redirectId
          targetRule {
            ruleId
            territoryId
            territoryName
            territoryGroupId
            effectiveDate
            endDate
          }
          fields {
            primaryFieldId
            fieldId
            fieldValue
          }
        }
        measures {
          measureId
          measureValue
          measureName
        }
      }
    }
  }
`;
