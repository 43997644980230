// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yGIkF {\n  }\n  ._6xmVO {\n    height: auto;\n  }\n  .ZGL7s {\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GridOverlayError_gridOverlayContainer": "yGIkF " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-container"] + "",
	"GridOverlayError_gridOverlayImage": "_6xmVO " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-image"] + "",
	"GridOverlayError_gridOverlayText": "ZGL7s " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-text"] + ""
};
export default ___CSS_LOADER_EXPORT___;
