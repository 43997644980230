import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Industry, Account, EarthFilled } from '@carbon/icons-react';

import TextRenderer from 'app/components/AdvancedGrid/CellRenderers/TextRenderer/TextRenderer';

import { HierarchyTypeName } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './TerritoryGroupRenderer.module.pcss';
const b = block(style);

const TerritoryGroupRenderer: React.FC<ICellRendererParams> = (props: ICellRendererParams) => {
  const { data, colDef } = props;

  const cellData = data?.[colDef?.field];
  if (!cellData) {
    return <div data-testid="no-data" />;
  }

  let icon;
  switch (cellData) {
    case HierarchyTypeName.INDUSTRIES:
      icon = <Industry data-testid="industry-icon" />;
      break;
    case HierarchyTypeName.GEOGRAPHIC_TERRITORIES:
      icon = <EarthFilled data-testid="earth-icon" />;
      break;
    case HierarchyTypeName.CUSTOMER_ACCOUNTS:
    default:
      icon = <Account className={b('icon')} data-testid="account-icon" />;
      break;
  }

  return (
    <div className={b()} data-testid="territory-group-renderer">
      <>
        {icon}
        <TextRenderer {...props} data-testid="territory-group-text" />
      </>
    </div>
  );
};

export default TerritoryGroupRenderer;
