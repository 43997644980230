import React, { useEffect, useRef, useState, useCallback } from 'react';

import { GridApi, RowNode, GridReadyEvent } from '@ag-grid-community/core';
import { InputGroup } from '@blueprintjs/core';
import {
  AddAlt,
  CheckmarkFilled,
  Search,
  WarningFilled,
  PlayOutlineFilled,
  WarningAltFilled,
  ErrorFilled
} from '@carbon/icons-react';
import { Tag, Intent } from '@varicent/components';
import dayjs from 'dayjs';

import TextButton from 'components/Buttons/TextButton/TextButton';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { useDeleteSymonPipeConfigurations } from 'app/components/ConfigurationsPanel/hooks/useDeleteSymonPipeConfigurations';
import { buildConfigurationsGridColumnDef } from 'app/components/DataPanel/ConfigurationsPanel/buildConfigurationsGridColumnDef';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useCancelSymonPipeConfiguration } from 'app/graphql/mutations/cancelSymonPipeConfiguration';

import useShowToast from 'app/hooks/useShowToast';

import { ConfigurationDialogType, INVALID_DATE, SymonPipeConfigurationStatus } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import ConfigurationDetail from './ConfigurationDetail';
import ConfigurationDialog from './ConfigurationDialog';
import style from './ConfigurationsPanel.module.pcss';
import { useStartSymonPipeDataPreview } from './hooks/useStartSymonPipeDataPreview';
import { useSymonPipeConfigurations } from './hooks/useSymonPipeConfigurations';

const b = block(style);

const ConfigurationsPanel: React.FC = () => {
  const { selectedPlanningCycle } = useScope();
  const [viewConfigurationDetail, setViewConfigurationDetail] = useState(false);
  const gridApi = useRef<GridApi>(null);
  const searchString = useRef('');

  const {
    selectedConfiguration,
    setSelectedConfiguration,
    setPollingTokenId,
    shouldRefetchConfigurations,
    setShouldRefetchConfigurations
  } = useData();
  const showToast = useShowToast();

  const [getSymonPipeConfigurations, { data: configurationsData, loading: symonPipeConfigurationsLoading }] =
    useSymonPipeConfigurations();

  const { pollForConfigurationPreviewTokenId } = useStartSymonPipeDataPreview(
    selectedConfiguration?.exportId,
    selectedConfiguration?.pipeId,
    selectedPlanningCycle?.id
  );

  const containerRef = useRef(null);

  const handleOnGridReady = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  useEffect(() => {
    getSymonPipeConfigurations({
      variables: {
        planningCycleId: selectedPlanningCycle?.id
      }
    });

    setSelectedConfiguration(null);
    setPollingTokenId(null);
  }, []);

  useEffect(() => {
    if (shouldRefetchConfigurations) {
      getSymonPipeConfigurations({
        variables: {
          planningCycleId: selectedPlanningCycle?.id
        }
      });
      setShouldRefetchConfigurations(false);
    }
  }, [shouldRefetchConfigurations]);

  const processingStatusLookUpMap = {
    [SymonPipeConfigurationStatus.NOT_STARTED]: {
      icon: <WarningAltFilled />,
      intent: 'warning',
      text: formatMessage('COLUMNS_NOT_MATCHED')
    },
    [SymonPipeConfigurationStatus.RUNNING]: {
      icon: <PlayOutlineFilled />,
      intent: 'primary',
      text: formatMessage('RUNNING')
    },
    [SymonPipeConfigurationStatus.COMPLETED]: {
      icon: <CheckmarkFilled />,
      intent: 'success',
      text: formatMessage('COMPLETED')
    },
    [SymonPipeConfigurationStatus.FAILED]: {
      icon: <WarningFilled />,
      intent: 'danger',
      text: formatMessage('FAILED')
    },
    [SymonPipeConfigurationStatus.CANCELLED]: {
      icon: <ErrorFilled />,
      intent: 'none',
      text: formatMessage('CANCELLED')
    }
  };

  const FormatDate = (params) => {
    const date = dayjs(params.value).format('MMMM D, YYYY [at] h:mm A');
    return <div data-testid="last-run">{date === INVALID_DATE ? '-' : date}</div>;
  };

  const StatusCellRenderer = (params) => {
    const processingStatus = params.value;
    return (
      <Tag
        icon={processingStatusLookUpMap[processingStatus]?.icon}
        intent={processingStatusLookUpMap[processingStatus]?.intent}
      >
        {processingStatusLookUpMap[processingStatus]?.text}
      </Tag>
    );
  };

  const onConfigurationSelectionChanged = async (params) => {
    const configurationGridApi = params?.api as GridApi;
    const configData = configurationGridApi?.getSelectedNodes()[0]?.data;
    if (configData?.status === SymonPipeConfigurationStatus.RUNNING) {
      return;
    }
    setSelectedConfiguration(configData);
    showToast(<ToastMessage message={formatMessage('GETTING_PREVIEW_DATA')} />, Intent.PRIMARY);
    await pollForConfigurationPreviewTokenId();
    setViewConfigurationDetail(true);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalVisibility = (value: boolean) => {
    setIsModalVisible(value);
  };

  const isExternalFilterPresent = useCallback(() => {
    return !!searchString.current;
  }, [searchString]);

  const doesExternalFilterPass = useCallback((node: RowNode): boolean => {
    const { exportName, pipeName, fileType } = node.data;
    const searchInput = searchString.current?.toLowerCase();

    return (
      !searchInput ||
      exportName?.toLowerCase().includes(searchInput) ||
      pipeName?.toLowerCase().includes(searchInput) ||
      fileType?.toLowerCase().includes(searchInput)
    );
  }, []);

  const [deleteSymonPipeConfiguration] = useDeleteSymonPipeConfigurations();

  const handleDeleteConfiguration = async (data, onDeleteConfigurationComplete) => {
    const deleteConfiguration = await deleteSymonPipeConfiguration({
      variables: {
        input: {
          symonPipeConfigurationIds: [data.symonPipeConfigurationId]
        }
      }
    });
    if (deleteConfiguration) setShouldRefetchConfigurations(true);
    onDeleteConfigurationComplete();
  };

  const [cancelSymonPipeConfiguration] = useCancelSymonPipeConfiguration();

  const handleCancelConfiguration = (configData, onCancelConfigurationComplete) => {
    showToast(
      <ToastMessage
        title={formatMessage('CANCELLING_IMPORT')}
        message={formatMessage('CANCELLING_IMPORT_MESSAGE', { destinationTableName: configData.tableNameLabel || '' })}
      />,
      'primary'
    );

    cancelSymonPipeConfiguration({
      variables: {
        input: {
          symonPipeConfigurationId: configData.symonPipeConfigurationId,
          runId: configData.runId
        }
      },
      onCompleted() {
        setShouldRefetchConfigurations(true);
        showToast(
          <ToastMessage
            title={formatMessage('CANCEL_SYMON_PIPE_CONFIGURATION_SUCCESS')}
            message={formatMessage('CANCEL_SYMON_PIPE_CONFIGURATION_SUCCESS_MESSAGE', {
              destinationTableName: configData.tableNameLabel || ''
            })}
          />,
          'success'
        );
        onCancelConfigurationComplete();
      },
      onError() {
        showToast(
          <ToastMessage
            title={formatMessage('CANCEL_SYMON_PIPE_CONFIGURATION_FAILURE')}
            message={formatMessage('CANCEL_SYMON_PIPE_CONFIGURATION_FAILURE_MESSAGE', {
              destinationTableName: configData.tableNameLabel || ''
            })}
          />,
          'danger'
        );
        onCancelConfigurationComplete();
      }
    });
  };

  return (
    <div className={b()}>
      {viewConfigurationDetail ? (
        <ConfigurationDetail
          onCloseConfigurationDetail={() => {
            setViewConfigurationDetail(false);
          }}
        />
      ) : (
        <div className={b('content')} data-testid="configurations-panel">
          <div className={b('actionArea')}>
            <InputGroup
              placeholder={formatMessage('SEARCH')}
              rightElement={
                <div className={b('searchIcon')}>
                  <Search />
                </div>
              }
              onChange={(event) => {
                searchString.current = event.target?.value;
                gridApi.current?.onFilterChanged();
              }}
              data-testid="configurations-panel-search-box"
            />
            <span className={b('button')}>
              <TextButton
                testId={'create-configuration-button'}
                type="button"
                text={formatMessage('CREATE_CONFIGURATION')}
                intent="primary"
                icon={<AddAlt />}
                onClick={() => handleModalVisibility(true)}
              />
            </span>
          </div>
          <div className={b('grid')} ref={containerRef}>
            {(symonPipeConfigurationsLoading || configurationsData?.getSymonPipeConfigurations) && (
              <AdvancedGrid
                rowData={configurationsData?.getSymonPipeConfigurations}
                gridOptions={{
                  suppressCellSelection: true,
                  isExternalFilterPresent,
                  doesExternalFilterPass,
                  onGridReady: handleOnGridReady
                }}
                columnDefs={buildConfigurationsGridColumnDef(
                  FormatDate,
                  StatusCellRenderer,
                  handleDeleteConfiguration,
                  handleCancelConfiguration
                )}
                onSelectionChanged={onConfigurationSelectionChanged}
                rowSelection="single"
                noDataMessage={formatMessage('NO_SYMON_PIPES')}
                showGridLoading={symonPipeConfigurationsLoading}
                gridWidth={containerRef?.current?.offsetWidth}
                gridHeight={containerRef?.current?.offsetHeight}
                data-testid="configurations-grid"
              />
            )}
          </div>
        </div>
      )}
      {isModalVisible && (
        <ConfigurationDialog
          data-testid="configuration-dialog"
          isModalVisible={isModalVisible}
          handleModalVisibility={handleModalVisibility}
          dialogType={ConfigurationDialogType.CREATE}
        />
      )}
    </div>
  );
};

export default ConfigurationsPanel;
