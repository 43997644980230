import { useEffect } from 'react';

import { getBattlecardQuotaBreakdownHierarchies } from 'app/components/BattleCardDiagram/Canvas/BattleCardCanvas/battleCardCanvasUtils';

import { useBattleCard } from 'app/contexts/battleCardProvider';

export const useSetupQuotaBreakdownHierarchies = (hierarchies) => {
  const { battleCardLookupMap, selectedBattleCardId, setQuotaBreakdownHierarchies } = useBattleCard();
  const selectedBattlecard = battleCardLookupMap?.[selectedBattleCardId];
  useEffect(() => {
    if (!selectedBattlecard || !hierarchies?.getRootHierarchies) return;
    const quotaBreakdownHierarchies = getBattlecardQuotaBreakdownHierarchies(
      selectedBattlecard.quotaDistributionHierarchies?.map((hierarchy) => hierarchy.hierarchyRootId),
      hierarchies.getRootHierarchies
    );

    setQuotaBreakdownHierarchies(quotaBreakdownHierarchies);
  }, [selectedBattlecard, hierarchies]);
};
