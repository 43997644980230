import React, { createContext, Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

interface EmbeddedAuthContextType {
  searchParams: URLSearchParams | null;
  setSearchParams: Dispatch<SetStateAction<URLSearchParams | null>>;
}

const EmbeddedAuthContext = createContext<EmbeddedAuthContextType | undefined>(undefined);
EmbeddedAuthContext.displayName = 'EmbeddedAuthContext';

export const EmbeddedAuthContextProvider: FC = ({ children }) => {
  const [searchParams, setSearchParams] = useState<URLSearchParams | null>(null);
  const value = useMemo(() => ({ searchParams, setSearchParams }), [searchParams, setSearchParams]);
  return <EmbeddedAuthContext.Provider value={value}>{children}</EmbeddedAuthContext.Provider>;
};

const useEmbeddedAuthContext = (): EmbeddedAuthContextType => useContextSafe(EmbeddedAuthContext);

export const useSaveEmbeddedAuthParams = (): EmbeddedAuthContextType['setSearchParams'] =>
  useEmbeddedAuthContext().setSearchParams;

export const useConsumeEmbeddedAuthParams = (): (() => URLSearchParams) => {
  const { searchParams, setSearchParams } = useEmbeddedAuthContext();
  return useCallback(() => {
    setSearchParams(null);
    return searchParams;
  }, [setSearchParams]);
};
