import React, { useEffect, useState } from 'react';

import MultiStepDialog from 'components/Dialog/MultiStepDialog';

import ImportFileMappingContainer from 'app/components/ImportFileDialog/ImportFileMappingContainer/ImportFileMappingContainer';

import { useData } from 'app/contexts/dataProvider';

import { FileTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { formatMessage } from 'utils/messages/utils';

import AccountAlignmentUpload from './AccountAlignmentUpload';

interface AccountAlignmentUploadDialogProps {
  onClose: () => void;
}

const AccountAlignmentUploadDialog: React.FC<AccountAlignmentUploadDialogProps> = ({ onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dataMatchingRequired, setDataMatchingRequired] = useState<boolean>(false);

  const { resetValues } = useData();

  useEffect(() => {
    resetValues();
  }, []);

  const onNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  return (
    <MultiStepDialog
      data-testid="import-account-alignment-dialog"
      isOpen
      onClose={onClose}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      pages={[
        <AccountAlignmentUpload
          onClose={onClose}
          onNext={onNext}
          dataMatchingRequired={dataMatchingRequired}
          setDataMatchingRequired={setDataMatchingRequired}
          key={1}
        />,
        ...(dataMatchingRequired
          ? [<ImportFileMappingContainer fileType={FileTypeEnum.AccountRedirect} onClose={onClose} key={2} />]
          : [])
      ]}
      showDialogFooter={false}
      title={currentIndex === 0 ? formatMessage('UPLOAD_ACCOUNT_ALIGNMENT') : formatMessage('MATCH_FIELDS_TITLE')}
    />
  );
};

export default AccountAlignmentUploadDialog;
