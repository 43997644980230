// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-Xo75 {\n    justify-content: space-between;\n    display: flex;\n  }\n  .jYjH7 {\n    font-size: 12px;\n    font-weight: normal;\n  }\n  .BgLLR {\n    color: rgb(var(--color-red-3));\n    height: 20px;\n    margin-top: 6px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormTextArea_topRow": "-Xo75",
	"FormTextArea_label": "jYjH7",
	"FormTextArea_validationMessage": "BgLLR"
};
export default ___CSS_LOADER_EXPORT___;
