import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

import { UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UserRoleCellRenderer.module.pcss';

const b = block(style);

const USER_ROLE_TYPE_STRINGS = {
  [UserRoleType.ADMIN]: formatMessage('ADMIN'),
  [UserRoleType.CONTRIBUTOR]: formatMessage('CONTRIBUTOR'),
  [UserRoleType.TERRITORY_GROUP_TYPE_OWNER]: formatMessage('TERRITORY_GROUP_TYPE_OWNER'),
  [UserRoleType.TERRITORY_GROUP_OWNER]: formatMessage('TERRITORY_GROUP_OWNER'),
  [UserRoleType.BATTLE_CARD_OWNER]: formatMessage('BATTLE_CARD_OWNER'),
  [UserRoleType.NONE]: formatMessage('NONE')
};

const UserRoleCellRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { data } = params;
  const userRole = USER_ROLE_TYPE_STRINGS[data?.roleName] || formatMessage('ADMIN');

  return (
    <div className={b('gridCell')} data-testid="user-role-cell">
      {userRole}
    </div>
  );
};

export default UserRoleCellRenderer;
