// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-iEQ5 {\n    width: 100%;\n  }\n  .DR55Y {\n    text-align: right;\n    height: 32px;\n    width: 100%;\n    max-width: 220px;\n    padding: 0 8px;\n    border: none;\n    border-radius: 4px;\n    box-shadow: var(--elevation-0);\n    line-height: 1;\n    text-overflow: ellipsis;\n  }\n  .smH0R {\n      max-width: 120px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormatNumbersCellEditor_formatNumbersInputWrapper": "-iEQ5",
	"FormatNumbersCellEditor_formatNumbersInput": "DR55Y",
	"FormatNumbersCellEditor_formatNumbersInput__percentage": "smH0R"
};
export default ___CSS_LOADER_EXPORT___;
