import React from 'react';

import { Classes } from '@blueprintjs/core';
import dayjs from 'dayjs';
import Avatar from 'react-avatar';

import { GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_topLevelComment } from 'app/graphql/generated/graphqlApolloTypes';

import block from 'utils/bem-css-modules';

import style from './Comment.module.pcss';

const b = block(style);

interface CommentProps {
  comment: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_topLevelComment;
  skeleton: boolean;
}

const Comment: React.FC<CommentProps> = ({ comment, skeleton }: CommentProps) => {
  const { firstName, lastName, content, createdAt } = comment;
  const convertedDate = dayjs(createdAt * 1000).format('DD/MM/YYYY h:mm A');

  return (
    <>
      <div className={b('commentContainer')} data-testid="comment-section">
        <div className={b('profilePicture')} data-testid="profile-picture">
          <Avatar
            className={skeleton ? `${Classes.SKELETON}` : null}
            name={`${firstName} ${lastName}`}
            round={true}
            size={'35'}
          />
        </div>
        <div className={b('commentBody')}>
          <div className={skeleton ? `${Classes.SKELETON}` : null} data-testid="user-info">
            <span className={b('userName')}>
              {firstName} {lastName}
            </span>
            <span className={b('date')}>{convertedDate}</span>
          </div>
          <div
            className={skeleton ? `${Classes.SKELETON} ${b('commentText')}` : b('commentText')}
            data-testid="comment-text"
          >
            {content}
          </div>
        </div>
      </div>
    </>
  );
};

export default Comment;
