import { useCallback, useMemo } from 'react';

import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { MapCompareState } from 'app/models';

import { getCompareMapStateAsSearchParams } from 'utils/helpers/compareModeHelpers';

const useMapComparisonQueryParamState = (): [MapCompareState | null, (mapCompareState: MapCompareState) => void] => {
  const { search } = useLocation();
  const history = useHistory();

  const state = useMemo((): MapCompareState => {
    const params = new URLSearchParams(search);

    const primaryBC = parseInt(params.get('primaryBc'));
    const primaryQC = parseInt(params.get('primaryQc'));
    const primaryDM = parseInt(params.get('primaryDm'));
    const primaryGeoTgt = parseInt(params.get('primaryGeoTgt'));

    const secondaryBC = parseInt(params.get('secondaryBc'));
    const secondaryQC = parseInt(params.get('secondaryQc'));
    const secondaryDM = parseInt(params.get('secondaryDm'));
    const secondaryGeoTgt = parseInt(params.get('secondaryGeoTgt'));

    if (
      primaryBC &&
      primaryQC &&
      primaryDM &&
      primaryGeoTgt &&
      secondaryBC &&
      secondaryQC &&
      secondaryDM &&
      secondaryGeoTgt
    ) {
      return {
        primary: {
          battlecardId: primaryBC,
          quotaComponentId: primaryQC,
          deploymentModelId: primaryDM,
          geoTgtId: primaryGeoTgt
        },
        secondary: {
          battlecardId: secondaryBC,
          quotaComponentId: secondaryQC,
          deploymentModelId: secondaryDM,
          geoTgtId: secondaryGeoTgt
        },
        selectionToRestore: {
          battlecardId: primaryBC,
          quotaComponentId: primaryQC,
          deploymentModelId: primaryDM,
          geoTgtId: primaryGeoTgt
        }
      };
    } else {
      return null;
    }
  }, [search]);

  const setState = useCallback((value: MapCompareState) => {
    const searchParams = getCompareMapStateAsSearchParams(value, history.location.search);

    history.push({ ...history.location, search: searchParams });
  }, []);

  return [state, setState];
};

export default useMapComparisonQueryParamState;
