import React, { useState, useMemo, useEffect, Dispatch, SetStateAction } from 'react';

import { GetJobs_getJobs_items } from 'app/graphql/generated/graphqlApolloTypes';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext } from 'app/models/index';

import { usePollJobProgress } from 'utils/helpers/jobProgressHelper';

import { useScope } from './scopeProvider';

export interface JobsContextValues extends BaseContext {
  pollingJobs: boolean;
  setPollingJobs: Dispatch<SetStateAction<boolean>>;
  jobItems: GetJobs_getJobs_items[];
  setJobItems: Dispatch<SetStateAction<GetJobs_getJobs_items[]>>;
  resetValues: () => void;
}

export const JobsContext = React.createContext<JobsContextValues | null>(null);
JobsContext.displayName = 'JobsContext';

export const JobsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [pollingJobs, setPollingJobs] = useState<boolean>(false);
  const [jobItems, setJobItems] = useState<GetJobs_getJobs_items[]>([]);

  const { selectedPlanningCycle } = useScope();

  const { pollJobProgress, resetPollingJobs } = usePollJobProgress();

  useEffect(() => {
    setJobItems([]);

    resetPollingJobs();
    setPollingJobs(false);

    if (selectedPlanningCycle) {
      pollJobProgress(selectedPlanningCycle.id, setJobItems, setPollingJobs);
    }
  }, [selectedPlanningCycle]);

  const resetValues = () => {
    setPollingJobs(false);
    setJobItems([]);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(() => {
    return {
      pollingJobs,
      setPollingJobs,
      jobItems,
      setJobItems,
      resetValues
    };
  }, [pollingJobs, jobItems]);

  // Return the interface that we want to expose to our other components
  return <JobsContext.Provider value={values}>{children}</JobsContext.Provider>;
};

// Custom hook to read these values from
export const useJobs = (): JobsContextValues => useContextSafe(JobsContext);
