import { useEffect } from 'react';

import { Events } from '@symon-ai/wisepipe-imports';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Func = (...args: any) => any;

type FirstParam<T extends Func> = Parameters<T>[0];

type SymonEventsType = Omit<typeof Events, 'prototype'>;

export type SymonEventsMap = {
  [Key in keyof SymonEventsType as FirstParam<SymonEventsType[Key]> extends Func ? Key : never]: FirstParam<
    SymonEventsType[Key]
  >;
};

const useSymonEvent = <EventName extends keyof SymonEventsMap>(
  eventName: EventName,
  listener: SymonEventsMap[EventName]
): void => {
  useEffect(() => {
    if (typeof Events[eventName] !== 'function')
      throw new Error(`Cannot subscribe to "${eventName}" events from Symon`);

    const unsubscribe = Events[eventName](listener as Func);
    return () => {
      unsubscribe();
    };
  }, [eventName, listener]);
};

export default useSymonEvent;
