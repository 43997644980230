import React, { useRef } from 'react';

import { GridHeaders, QuotaReallocationPreviewItems } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatNumber } from 'utils/messages/utils';

import style from './QuotaReallocationPreviewTable.module.pcss';

const b = block(style);

interface QuotaReallocationPreviewTableProps {
  measures: QuotaReallocationPreviewItems[];
  currency: string;
}

const QuotaReallocationPreviewTable: React.FC<QuotaReallocationPreviewTableProps> = ({ measures, currency }) => {
  const portalRef = useRef<HTMLDivElement>(null);
  return (
    <div data-testid="quota-reallocation-preview-table" className={b('tableContainer')} ref={portalRef}>
      <table className={b('table')}>
        <thead>
          <tr className={b('tableHeaderRow')}>
            <th>{GridHeaders.QUOTA_COMPONENT}</th>
            <th className={b('fullAccountQuotaRow')}>{GridHeaders.FULL_ACCOUNT_QUOTA}</th>
            <th className={b('reallocatingRowHeader')}>{GridHeaders.REALLOCATING}</th>
          </tr>
        </thead>
        <tbody className={b('tableBody')}>
          {measures.map(({ quotaComponentName, fullAccountQuotaValue, reallocatingValue }) => (
            <tr key={`${quotaComponentName}`} data-testid={`quota-reallocation-row-${quotaComponentName}`}>
              <td>{quotaComponentName}</td>
              <td className={b('fullAccountQuotaRow')}>
                {formatNumber(fullAccountQuotaValue, { style: 'currency', currency })}
              </td>
              <td className={b('reallocatingRow')}>
                {formatNumber(reallocatingValue, { style: 'currency', currency })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuotaReallocationPreviewTable;
