import React, { FC, useLayoutEffect, useRef } from 'react';

const RESIZE_DEBOUNCE_DELAY = 10;
export const HeightRuler: FC<{ className: string; onResize: (height: number | null) => void }> = ({
  className,
  onResize,
  children
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    let timeoutId: number | null = null;
    const observer = new ResizeObserver(() => {
      if (timeoutId) window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => onResize(ref.current?.clientHeight ?? null), RESIZE_DEBOUNCE_DELAY);
    });
    if (ref.current) {
      observer.observe(ref.current);
      onResize(ref.current.clientHeight ?? null);
    } else {
      console.error('Unable to observe height, element ref not ready in time');
    }
    return () => {
      observer.disconnect();
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);
  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};
