import React, { useMemo } from 'react';

import { ColDef } from '@ag-grid-community/core';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import CurrencyCellRenderer from 'app/components/AdvancedGrid/CellRenderers/CurrencyCellRenderer/CurrencyCellRenderer';
import TextRenderer from 'app/components/AdvancedGrid/CellRenderers/TextRenderer/TextRenderer';

import { CustomerForMap, GridFields, GridHeaders, InsightCardStat, RuleForMap, SelectedGeography } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './InsightsCard.module.pcss';
import { MAP_GRID_HEADER_HEIGHT, MAP_GRID_ROW_HEIGHT } from './TerritoryMapGridV2';

const b = block(style);

interface InsightsCardStatTableProps {
  currency: string;
  shouldShowMeasureValue: boolean;
  selectedAccounts?: Pick<CustomerForMap, 'measureValue' | 'accountName'>[];
  selectedRules?: Pick<RuleForMap, 'measureValue' | 'territoryName'>[];
  selectedGeographies?: SelectedGeography[];
  expandedTable: InsightCardStat;
}

const expandableColumnDefs: Partial<Record<InsightCardStat, ColDef>> = {
  [InsightCardStat.OVERRIDE_ACCOUNTS]: { headerName: GridHeaders.ACCOUNTS, field: GridFields.ACCOUNT_NAME },
  [InsightCardStat.TOTAL_ACCOUNTS]: { headerName: GridHeaders.ACCOUNTS, field: GridFields.ACCOUNT_NAME },
  [InsightCardStat.TERRITORIES]: { headerName: GridHeaders.TERRITORY, field: GridFields.TERRITORY_NAME },
  [InsightCardStat.GEOGRAPHIC_REGIONS]: { headerName: GridHeaders.GEO_REGION, field: GridFields.GEOGRAPHY_NAME }
};

const InsightsCardStatTable: React.FC<InsightsCardStatTableProps> = ({
  currency,
  selectedAccounts = [],
  selectedRules = [],
  selectedGeographies = [],
  expandedTable,
  shouldShowMeasureValue
}: InsightsCardStatTableProps) => {
  const columnDefs: ColDef[] = useMemo(() => {
    const expandedColumnDef = expandableColumnDefs[expandedTable];
    if (!expandedColumnDef) return [];
    return [
      {
        flex: 1,
        cellRendererFramework: TextRenderer,
        ...expandableColumnDefs[expandedTable]
      },
      {
        headerName: GridHeaders.PRIOR_YEAR_ACTUAL,
        field: GridFields.MEASURE_VALUE,
        flex: 1,
        cellRendererFramework: CurrencyCellRenderer,
        cellRendererParams: { currency, isLink: false },
        hide: !shouldShowMeasureValue
      }
    ];
  }, [expandedTable, currency]);

  const data = useMemo(() => {
    switch (expandedTable) {
      case InsightCardStat.OVERRIDE_ACCOUNTS:
      case InsightCardStat.TOTAL_ACCOUNTS:
        return selectedAccounts;
      case InsightCardStat.TERRITORIES:
        return selectedRules;
      case InsightCardStat.GEOGRAPHIC_REGIONS:
        return selectedGeographies;
      default:
        return [];
    }
  }, [expandedTable, selectedAccounts, selectedRules, selectedGeographies]);

  return (
    <div className={b('insightsCardStatTableWrapper')}>
      <AdvancedGrid
        data={JSON.stringify(data)}
        suppressCellSelection={true}
        columnDefs={columnDefs}
        gridProps={{ rowHeight: MAP_GRID_ROW_HEIGHT, headerHeight: MAP_GRID_HEADER_HEIGHT }}
      />
    </div>
  );
};

export default InsightsCardStatTable;
