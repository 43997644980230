import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { CreateBlankScenario, CreateBlankScenarioVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const CREATE_BLANK_SCENARIO = gql`
  mutation CreateBlankScenario($deploymentModelName: String!, $planningCycleId: Int!) {
    createDeploymentModel(input: { deploymentModelName: $deploymentModelName, planningCycleId: $planningCycleId }) {
      deploymentModelId
      deploymentModelName
    }
  }
`;

export const useCreateBlankScenario = createUseMutation<CreateBlankScenario, CreateBlankScenarioVariables>(
  CREATE_BLANK_SCENARIO
);
