// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vM8mh {\n  position: relative;\n  margin-bottom: 46px;\n  border-radius: 4px;\n  box-sizing: border-box;\n  opacity: 1;\n}\n\n  .\\+gJUa {\n    min-width: 400px;\n  }\n\n  .vM8mh:not(.\\+gJUa) {\n    width: 400px;\n  }\n\n  .VpgtW {\n    z-index: 2;\n  }\n\n  .yW96M {\n    transition: opacity 200ms;\n    opacity: 0;\n  }\n\n  .GGV6R {\n    position: absolute;\n    bottom: -23px;\n    left: 50%;\n    margin-left: -27px;\n    width: 56px;\n    z-index: 1;\n    display: none;\n  }\n\n.vM8mh::after {\n    content: ' ';\n    position: absolute;\n    left: 50%;\n    margin-left: -27px;\n    width: 56px;\n    height: 22px;\n  }\n\n.kHnEF:hover {\n    box-shadow: 0 0 0 2px rgb(var(--color-blue-3));\n  }\n\n.VpgtW:hover {\n    box-shadow: none;\n  }\n\n.vM8mh:hover .GGV6R {\n    display: block;\n  }\n\n.iFBcp, .vM8mh:hover .iFBcp {\n    display: none;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BattleCardNode": "vM8mh",
	"BattleCardNode__isRoot": "+gJUa",
	"BattleCardNode__expanded": "VpgtW",
	"BattleCardNode__obscured": "yW96M",
	"BattleCardNode_button": "GGV6R",
	"BattleCardNode__isActive": "kHnEF",
	"BattleCardNode_button__hidden": "iFBcp"
};
export default ___CSS_LOADER_EXPORT___;
