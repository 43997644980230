import { useTreatments } from '@splitsoftware/splitio-react';
import { TreatmentWithConfig } from '@splitsoftware/splitio-react/types/splitio/splitio';

import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';

const useTreatment = (flag: SplitFeatures): [boolean, TreatmentWithConfig] => {
  const { selectedTenant } = useScope();
  const { userProfile } = useUser();

  const treatments = useTreatments([flag], {
    tenantId: selectedTenant?.id,
    tenantSlug: selectedTenant?.slug,
    userEmail: userProfile?.emailAddress
  });

  const isTreatmentOn = treatments[flag].treatment === 'on';
  return [isTreatmentOn, treatments[flag]];
};

export default useTreatment;
