import React, { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';

import { Spinner } from '@blueprintjs/core';
import { DocumentImport, WarningAltFilled, Close } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import MultiStepDialogFooter from 'components/Dialog/MultiStepDialogFooter/MultiStepDialogFooter';
import Icon from 'components/Icon/Icon';

import { useMappingFields } from 'app/components/DataMappingDrillIn/hooks/useMappingFields';
import DataTypeTagList from 'app/components/DataTypeTagList/DataTypeTagList';
import DownloadCsvSample from 'app/components/DownloadCsvSample/DownloadCsvSample';
import DownloadQuotaFileUploadTemplate from 'app/components/ImportQuotasDialog/DownloadQuotaFileUploadTemplate/DownloadQuotaFileUploadTemplate';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import DataFileUploadSequence from 'app/core/dataFileUpload/DataFileUploadSequence';

import { SplitFeatures } from 'app/global/features';
import { DOWNLOAD_TERRITORY_QUOTA_TEMPLATE_FILE_NAME } from 'app/global/variables';

import { FileTypeEnum, UpsertMappingInput } from 'app/graphql/generated/graphqlApolloTypes';
import { useExportData } from 'app/graphql/hooks/useExportData';
import { usePublishFileToDB } from 'app/graphql/hooks/usePublishFileToDB';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { ExportTableName } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ImportQuotasFileUpload.module.pcss';

const b = block(style);

interface ImportQuotasFileUploadProps {
  dataMatchingRequired?: boolean;
  setDataMatchingRequired?: Dispatch<SetStateAction<boolean>>;
  onNext?: () => void;
  onClose?: (event: SyntheticEvent<HTMLElement>) => void;
}

const ImportQuotasFileUpload: React.FC<ImportQuotasFileUploadProps> = ({
  dataMatchingRequired,
  onNext,
  onClose,
  setDataMatchingRequired
}: ImportQuotasFileUploadProps) => {
  const [disableNext, setDisableNext] = useState(false);
  const { selectedPlanningCycle } = useScope();
  const showToast = useShowToast();
  const { selectedBattleCardId, selectedQuotaComponentId, quotaBreakdownHierarchies } = useBattleCard();
  const { startExportData } = useExportData();
  const [isQbtWarningShowing, setIsQbtWarningShowing] = useState(true);
  const [isNewTerritoryQuotaFileMappingFieldsEnabled] = useTreatment(
    SplitFeatures.NEW_TERRITORY_QUOTA_FILE_MAPPING_FIELDS
  );

  const { mappingFields, mappingFieldsLoading } = useMappingFields(
    selectedPlanningCycle.id,
    FileTypeEnum.TerritoryQuota,
    null,
    +selectedBattleCardId
  );

  // function to get required headers into an array of strings
  const requiredHeaders = mappingFields.reduce((arr, field) => {
    if (field.properties.colRequired) {
      // there is a requirement to make Territory ID column to come first
      if (field.value === 'Territory ID') {
        arr.unshift(field.value);
      } else {
        // there is a requirement to make Value column to come last
        if (arr.includes('Value')) {
          arr.splice(-1, 0, field.value);
        }
        arr = [...arr, field.value];
      }
    }
    return arr;
  }, []);

  const {
    isAllColumnsMapped,
    selectedTable,
    setSelectedTable,
    setFileUploadInProgress,
    pollForProcessingStatus,
    mappingProperties,
    missingRequiredFields,
    resetFileUploadValues
  } = useData();

  const canSubmit = selectedTable && isAllColumnsMapped && missingRequiredFields.length === 0;

  const [publishFileToDB, { loading: publishingFileToDb }] = usePublishFileToDB({
    selectedTable,
    setSelectedTable,
    setFileUploadInProgress,
    pollForProcessingStatus
  });

  const onSubmit = async (e) => {
    if (canSubmit) {
      const input: UpsertMappingInput = {
        fileId: selectedTable.tableId,
        fieldNames: mappingProperties,
        planningCycleId: selectedPlanningCycle.id,
        fileType: FileTypeEnum.TerritoryQuota,
        ...(isNewTerritoryQuotaFileMappingFieldsEnabled ? { battlecardId: +selectedBattleCardId } : {})
      };
      const publishedFile = await publishFileToDB({
        variables: {
          input
        }
      });

      if (publishedFile?.data?.publishFileToDB) {
        showToast(formatMessage('PUBLISHING_IN_PROGRESS'), 'success');
        onClose(e);
        resetFileUploadValues();
      }
    }
  };

  const exportDataInput = {
    territoryQuotaTemplateInput: {
      battlecardId: +selectedBattleCardId,
      quotaComponentId: +selectedQuotaComponentId
    }
  };

  const shouldShowWarningMessage =
    isNewTerritoryQuotaFileMappingFieldsEnabled && isQbtWarningShowing && quotaBreakdownHierarchies.length > 0;

  return (
    <div data-testid="import-quotas-page">
      {mappingFieldsLoading ? (
        <div className={b('spinnerContainer')} data-testid="spinner">
          <Spinner intent="primary" size={40} />
        </div>
      ) : (
        <>
          <DataTypeTagList fields={mappingFields} fileUploadType={formatMessage('QUOTA')} />
          <div className={b('linkContainer')}>
            {isNewTerritoryQuotaFileMappingFieldsEnabled ? (
              <DownloadQuotaFileUploadTemplate
                onClick={() => {
                  startExportData({
                    planningCycleId: selectedPlanningCycle.id,
                    tableName: ExportTableName.TERRITORY_QUOTA_FILE_UPLOAD_TEMPLATE,
                    spInternalInput: exportDataInput
                  });
                }}
                data-testid="download-quota-file-upload-template"
              />
            ) : (
              <DownloadCsvSample
                data={[requiredHeaders]}
                linkText={formatMessage('DOWNLOAD_QUOTA_FILE_TEXT')}
                filename={DOWNLOAD_TERRITORY_QUOTA_TEMPLATE_FILE_NAME}
              />
            )}
          </div>
          {shouldShowWarningMessage && (
            <div className={b('quotaFileUploadWarningContainer')} data-testid="quota-file-upload-warning-container">
              <div>
                <Icon icon={<WarningAltFilled size={20} />} />
                <span className={b('warningNote')}>{formatMessage('QUOTA_FILE_UPLOAD_NOTES')}</span>
                <ul className={b('listParent')}>
                  <li className={b('listItem')}>{formatMessage('QUOTA_FILE_UPLOAD_WARNING_1')}</li>
                  <li className={b('listItem')}>{formatMessage('QUOTA_FILE_UPLOAD_WARNING_2')}</li>
                  <li className={b('listItem')}>{formatMessage('QUOTA_FILE_UPLOAD_WARNING_3')}</li>
                </ul>
              </div>
              <IconButton
                type="button"
                icon={<Close size={20} />}
                onClick={() => setIsQbtWarningShowing(false)}
                testId={'close-btn'}
                title={formatMessage('CLOSE')}
              />
            </div>
          )}
          <div className={b('fileUploadContainer')}>
            <DataFileUploadSequence
              mappingFields={mappingFields}
              fileUploadType={FileTypeEnum.TerritoryQuota}
              headersToValidate={requiredHeaders}
              setDataMatchingRequired={setDataMatchingRequired}
              setDisableComplete={setDisableNext}
            />
          </div>
        </>
      )}
      <MultiStepDialogFooter
        onNext={onNext}
        disableNext={disableNext}
        confirmButtonText={formatMessage('IMPORT')}
        confirmButtonIcon={<DocumentImport />}
        isFinalStep={!dataMatchingRequired}
        showBack={false}
        onClose={onClose}
        confirmButtonLoading={publishingFileToDb}
        onSubmit={onSubmit}
        disableConfirm={publishingFileToDb || !canSubmit}
      />
    </div>
  );
};

export default ImportQuotasFileUpload;
