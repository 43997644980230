import React, { Dispatch, SetStateAction } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Dialog } from '@blueprintjs/core';
import { Close } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SeasonalityDialog.module.pcss';

const b = block(style);

interface SeasonalityDialogProps {
  isOpen: boolean;
  setShowSeasonalityDialog: Dispatch<SetStateAction<boolean>>;
}

const SeasonalityDialog: React.FC<SeasonalityDialogProps> = ({
  isOpen,
  setShowSeasonalityDialog
}: SeasonalityDialogProps) => {
  return (
    <Dialog isOpen={isOpen} portalClassName={b()} data-testid="seasonality-dialog">
      <div className={b('dialogHeader')}>
        <span className={b('dialogTitle')} data-testid="seasonality-dialog-title">
          {formatMessage('SET_SEASONALITY')}
        </span>
        <IconButton
          type={'button'}
          icon={<Close size={20} />}
          testId={'seasonality-close-icon'}
          title={formatMessage('CLOSE')}
          onClick={() => setShowSeasonalityDialog(false)}
        />
      </div>
      <div className={b('dialogBody')}>
        <span className={b('instruction')} data-testid="seasonality-instruction">
          {formatMessage('SEASONALITY_INSTRUCTION')}
        </span>
      </div>
      <div className={b('buttons')}>
        <TextButton
          text={formatMessage('CANCEL')}
          type="button"
          testId={'cancel-button'}
          minimal
          onClick={() => setShowSeasonalityDialog(false)}
        />
        <TextButton text={formatMessage('APPLY')} type="submit" intent="primary" testId={'submit-button'} />
      </div>
    </Dialog>
  );
};

export default SeasonalityDialog;
