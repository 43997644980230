import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { DropdownMenu as VDSDropdownMenu } from '@varicent/components';

interface DropdownMenuCheckboxItemProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  textValue: string;
}

const DropdownMenuCheckboxItem: React.FC<DropdownMenuCheckboxItemProps> = (props: DropdownMenuCheckboxItemProps) => {
  return <VDSDropdownMenu.CheckboxItem {...props} />;
};

export default DropdownMenuCheckboxItem;
