import { useMemo } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { HierarchyType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const ACCOUNT_MAP_DYNAMIC_COPY = {
  mapGridTitleText: formatMessage('CUSTOMER_ACCOUNTS_MAP'),
  unassignAccountTooltipText: formatMessage('UNASSIGN_ACCOUNT_TOOLTIP'),
  unassignAccountText: formatMessage('UNASSIGN_ACCOUNTS'),
  unassignAccountSuccessText: formatMessage('UNASSIGN_ACCOUNT_SUCCESS_TOAST'),
  unassignAccountErrorText: formatMessage('UNASSIGN_ACCOUNT_ERROR_TOAST'),
  emptyMapGridText: formatMessage('EMPTY_MAP_TGT_GRID_MESSAGE_2'),
  removeConflictTooltipText: formatMessage('REMOVE_CONFLICT_ACTION_TOOLTIP_2'),
  sameLocationClusterToastText: formatMessage('MULTIPLE_ACCOUNTS_BODY_2')
};

const GEOGRAPHIES_MAP_DYNAMIC_COPY = {
  mapGridTitleText: formatMessage('GEOGRAPHIES_MAP'),
  unassignAccountTooltipText: formatMessage('UNASSIGN_OVERRIDE_TOOLTIP'),
  unassignAccountText: formatMessage('UNASSIGN_ACCOUNT_TITLE'),
  unassignAccountSuccessText: formatMessage('UNASSIGN_OVERRIDE_SUCCESS_TOAST'),
  unassignAccountErrorText: formatMessage('UNASSIGN_OVERRIDE_ERROR_TOAST'),
  emptyMapGridText: formatMessage('EMPTY_MAP_TGT_GRID_MESSAGE'),
  removeConflictTooltipText: formatMessage('REMOVE_CONFLICT_ACTION_TOOLTIP'),
  sameLocationClusterToastText: formatMessage('MULTIPLE_ACCOUNTS_BODY_2')
};

export const useDynamicMapHierarchyCopy = () => {
  const { primaryHierarchy } = useMapContextRedistributor();

  const dynamicCopy = useMemo(() => {
    switch (primaryHierarchy) {
      case HierarchyType.CustomerAccountHierarchy:
        return ACCOUNT_MAP_DYNAMIC_COPY;
      case HierarchyType.GeographicTerritoryHierarchy:
        return GEOGRAPHIES_MAP_DYNAMIC_COPY;
      default:
        throw new Error(`Unsupported hierarchy type ${primaryHierarchy}`);
    }
  }, [primaryHierarchy]);

  return dynamicCopy;
};
