import React from 'react';

import { Form, Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';

import TextMessageInputField from 'app/components/FormFields/TextMessageInputField/TextMessageInputField';

import { useUser } from 'app/core/userManagement/userProvider';

import { useGenerateImpersonationToken } from 'app/graphql/mutations/generateImpersonationToken';

import useShowToast from 'app/hooks/useShowToast';

import { setImpersonationToken } from 'utils/ImpersonationLocalStorage';
import { formatMessage } from 'utils/messages/utils';

interface Props {
  onClose: () => void;
}

const ImpersonationDialog: React.FC<Props> = ({ onClose }) => {
  const [generateImpersonationToken, { loading: isLoading }] = useGenerateImpersonationToken();
  const { userProfile } = useUser();
  const showToast = useShowToast();

  const redirectUrl = `/${userProfile.tenant.slug}`;

  const handleGenerateImpersonateToken = (email: string) => {
    generateImpersonationToken({
      variables: {
        input: {
          emailAddress: email
        }
      },
      onError() {
        showToast(formatMessage('FAILED_TO_GENERATE_IMPERSONATION_TOKEN'), 'danger');
      },
      onCompleted({ generateImpersonationToken: { token } }) {
        setImpersonationToken(token);
        window.location.href = redirectUrl;
      }
    });
  };

  return (
    <Formik
      initialValues={{
        emailToImpersonate: ''
      }}
      onSubmit={(values) => {
        handleGenerateImpersonateToken(values.emailToImpersonate);
      }}
    >
      {({ handleSubmit }) => {
        return (
          <Dialog
            isOpen
            size="small"
            confirmButtonText={formatMessage('IMPERSONATE')}
            confirmButtonLoading={isLoading}
            title={formatMessage('IMPERSONATE_USER')}
            onClose={onClose}
            bodyMinHeight={20}
            onSubmit={handleSubmit}
          >
            <Form data-testid="impersonation-dialog-form">
              <TextMessageInputField
                isRequired
                name="emailToImpersonate"
                textPlaceholder={formatMessage('ENTER_EMAIL')}
                isLoading={false}
                disabled={isLoading}
                message={''}
                shouldShowSuccessIcon={false}
                shouldShowErrorIcon={false}
                isMessageAvailable={false}
                textLabel={formatMessage('USER_EMAIL_TO_IMPERSONATE')}
                onChange={null}
              />
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ImpersonationDialog;
