import React, { useEffect } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import Avatar from 'react-avatar';
import { useQuill } from 'react-quilljs';

import TextButton from 'components/Buttons/TextButton/TextButton';

import FormTextArea from 'app/components/FormFields/FormTextArea/FormTextArea';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useComments } from 'app/contexts/commentProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { COMMENT_FIELD_MAX_LENGTH, COMMENT_VERSION } from 'app/global/variables';

import { handleError } from 'app/graphql/handleError';
import { ADD_TERRITORY_WORKFLOW_COMMENT } from 'app/graphql/mutations/addTerritoryWorkflowComment';

import useShowToast from 'app/hooks/useShowToast';

import { TerritoryWorkflowAddCommentFormValues } from 'app/models';

import block from 'utils/bem-css-modules';
import { getFullName } from 'utils/helpers/userProfileHelper';
import 'quill/dist/quill.snow.css';
import { formatMessage } from 'utils/messages/utils';

import style from './CommentForm.module.pcss';
import validationsSchema from './validationsSchema';

const b = block(style);

interface CommentFormProps {
  buttonText: string;
  placeholderText: string;
  threadId: number;
}

const CommentForm: React.FC<CommentFormProps> = ({ buttonText, placeholderText, threadId }: CommentFormProps) => {
  const { selectedBattleCardId } = useBattleCard();
  const { selectedDeploymentModelId } = useScope();
  const { setShouldRefetchCommentPanel, setShouldRenderCommentForm } = useComments();
  const { userProfile } = useUser();
  const { firstName, lastName } = userProfile;
  const showToast = useShowToast();

  const modules = {
    toolbar: false
  };
  const placeholder = placeholderText;
  const { quill } = useQuill({ modules, placeholder });

  const [addTerritoryWorkflowComment, { loading: addQueryLoading }] = useMutation(ADD_TERRITORY_WORKFLOW_COMMENT, {
    onCompleted(data) {
      if (data?.addTerritoryWorkflowComment?.success) {
        setShouldRefetchCommentPanel(true);
        setShouldRenderCommentForm(false);
      }
    },
    onError({ graphQLErrors, networkError }) {
      showToast(formatMessage('POST_COMMENT_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });

  const onSubmit = async (values: TerritoryWorkflowAddCommentFormValues) => {
    if (threadId) {
      await addTerritoryWorkflowComment({
        variables: {
          deploymentModelId: selectedDeploymentModelId,
          battlecardId: selectedBattleCardId,
          threadId: values.threadId,
          content: values.content,
          version: values.version
        }
      });
    } else {
      await addTerritoryWorkflowComment({
        variables: {
          deploymentModelId: selectedDeploymentModelId,
          battlecardId: selectedBattleCardId,
          content: values.content,
          version: values.version
        }
      });
    }
  };

  const NewCommentBox = (
    <div data-testid="comment-form">
      <Formik
        initialValues={{ threadId: threadId ? threadId : null, content: '', version: COMMENT_VERSION }}
        onSubmit={onSubmit}
        validationSchema={validationsSchema}
      >
        {function NewCommentForm({ isSubmitting, setFieldValue, errors, touched, setFieldTouched }) {
          useEffect(() => {
            if (quill) {
              quill.on('text-change', () => {
                setFieldTouched('content', true);
                setFieldValue('content', quill.getText());
              });
            }
          }, [quill]);
          return (
            <Form>
              <div className={b('commentForm')}>
                <Field
                  disabled={isSubmitting || addQueryLoading}
                  name="content"
                  type="text"
                  placeholder={placeholderText}
                  component={FormTextArea}
                  showErrors={false}
                  enableGrowVertically
                  growVerticallyMaxLength={COMMENT_FIELD_MAX_LENGTH}
                  onChange={() => {
                    setFieldTouched('content', true);
                  }}
                />
              </div>
              {(!!touched.content || addQueryLoading) && (
                <div className={b('buttons')}>
                  <div className={b('cancelButton')}>
                    <TextButton
                      text={formatMessage('CANCEL')}
                      type="button"
                      testId={'cancel-button'}
                      onClick={() => {
                        quill.deleteText(0, quill.getLength());
                        setFieldValue('content', '');
                        setFieldTouched('content', false);
                      }}
                      disabled={isSubmitting || addQueryLoading}
                    />
                  </div>
                  <div className={b('commentButton')}>
                    <TextButton
                      text={buttonText}
                      intent="primary"
                      type="submit"
                      testId={'comment-button'}
                      loading={addQueryLoading}
                      disabled={isSubmitting || addQueryLoading || !!errors.content}
                    />
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );

  const NewThread = (
    <div className={b('newCommentSection')} data-testid="new-comment-thread">
      <div className={b('profilePictureContainer')} data-testid="user-profile">
        <Avatar name={getFullName(userProfile)} round={true} size={'35'} />
      </div>
      <div className={b('commentFieldContainer')}>
        <span>
          {firstName} {lastName}
        </span>
        <div>{NewCommentBox}</div>
      </div>
    </div>
  );

  return <>{threadId ? NewCommentBox : NewThread}</>;
};

export default CommentForm;
