import React, { FC, useMemo } from 'react';

import { useScope } from 'app/contexts/scopeProvider';

import { HierarchyType } from 'app/models';

import {
  MapContextRedistributorContextValues,
  MapContextRedistributorProvider
} from './mapContextRedistributorProvider';

type EmbeddedMcrLoaderProps = Pick<
  MapContextRedistributorContextValues,
  'selectedBattleCard' | 'selectedQuotaComponentId'
>;

export const EmbeddedMcrLoader: FC<EmbeddedMcrLoaderProps> = ({
  children,
  selectedBattleCard,
  selectedQuotaComponentId
}) => {
  const selectedBattleCardId = selectedBattleCard?.battlecardId.toString() || null;
  const { selectedDeploymentModelId } = useScope();

  const selectedTerritoryGroupTypeId = useMemo(
    () =>
      selectedBattleCard?.territoryGroupTypes.find(
        (tgt) => tgt.hierarchyType === HierarchyType.GeographicTerritoryHierarchy
      )?.territoryGroupId ?? null,
    [selectedBattleCard]
  );
  const selectedTerritoryGroupId = selectedTerritoryGroupTypeId;

  const contextValue = useMemo(
    (): MapContextRedistributorContextValues => ({
      selectedBattleCardId,
      selectedQuotaComponentId,
      selectedTerritoryGroupTypeId,
      selectedTerritoryGroupId,
      coinsortStatus: null,
      isMapPointerDisabled: false,
      selectedBattleCard,
      isEmbedded: true,
      selectedDeploymentModelId,
      isBattlecardDataLoading: false,
      externalControlSuffix: '',
      primaryHierarchy: HierarchyType.GeographicTerritoryHierarchy,
      savedCustomHierarchyPreference: null,
      mapVisualizationSettings: null,
      accountSourceFilter: null,
      isMapVisualizationSettingsLoading: false
    }),
    [
      selectedBattleCardId,
      selectedBattleCard,
      selectedQuotaComponentId,
      selectedTerritoryGroupTypeId,
      selectedTerritoryGroupId
    ]
  );

  return <MapContextRedistributorProvider value={contextValue}> {children}</MapContextRedistributorProvider>;
};
