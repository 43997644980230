// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EOv5c {\n  display: block;\n}\n\n  .my96C {\n    width: auto;\n    height: 700px;\n  }\n\n  .pw\\+b0 {\n    height: 540px;\n  }\n\n  .mssKV {\n    display: flex;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AllTerritories": "EOv5c",
	"AllTerritories_allTerritoriesGrid": "my96C",
	"AllTerritories_fullWidthGrid": "pw+b0",
	"AllTerritories_validationMessage": "mssKV"
};
export default ___CSS_LOADER_EXPORT___;
