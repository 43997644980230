import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetDeploymentModelSummaries,
  GetDeploymentModelSummariesVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_DEPLOYMENT_MODEL_SUMMARIES = gql`
  query GetDeploymentModelSummaries($planningCycleId: Int!) {
    getPlanningCycleSpec(input: { planningCycleId: $planningCycleId }) {
      planningCycleId
      deploymentModelSummaries {
        deploymentModelId
        deploymentModelName
        deploymentModelType
        isPublished: isPrimaryScenario
        deploymentModelCreatedAt
        deploymentModelLastOpenedAt
      }
    }
  }
`;

export const useGetDeploymentModelSummaries = createUseQuery<
  GetDeploymentModelSummaries,
  GetDeploymentModelSummariesVariables
>(GET_DEPLOYMENT_MODEL_SUMMARIES);
