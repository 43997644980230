import React, { useEffect, useState } from 'react';

import StatusToast from 'components/StatusToast/StatusToast';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCoinsort } from 'app/contexts/coinsortProvider';

import { formatMessage } from 'utils/messages/utils';

const CoinsortProgress: React.FC = () => {
  const { selectedBattleCardId } = useBattleCard();
  const { coinsortProgressLookupMap, coinsortRunningLookupMap } = useCoinsort();
  const [isOpen, setIsOpen] = useState(false);

  const currentNumberOfRules = coinsortProgressLookupMap[selectedBattleCardId]?.numberOfRules || 0;
  const currentNumberOfRulesProcessed = coinsortProgressLookupMap[selectedBattleCardId]?.numberOfRulesProcessed || 0;

  useEffect(() => {
    setIsOpen(coinsortRunningLookupMap[selectedBattleCardId]);
  }, [coinsortRunningLookupMap[selectedBattleCardId]]);

  const getStatusValue = () => {
    return currentNumberOfRules ? currentNumberOfRulesProcessed / currentNumberOfRules : 0;
  };

  const getStatusDescription = () => {
    return currentNumberOfRules > 0
      ? formatMessage('SORTING_COINSORT', { currentNumberOfRulesProcessed, currentNumberOfRules })
      : '';
  };

  return isOpen ? (
    <StatusToast
      isOpen={isOpen}
      value={getStatusValue()}
      description={getStatusDescription()}
      onClose={() => setIsOpen(false)}
      canCancelOperation={false}
      data-testid="coinsort-status"
    />
  ) : null;
};

export default CoinsortProgress;
