import React from 'react';

import LoadingCellRenderer from 'app/components/AdvancedGrid/CellRenderers/LoadingCellRenderer/LoadingCellRenderer';

import block from 'utils/bem-css-modules';

import style from './GridLoading.module.pcss';

const b = block(style);

const ROW_HEIGHT = 40;

interface GridLoadingProps {
  gridHeight: number;
  gridWidth: number;
}

const GridLoading: React.FC<GridLoadingProps> = ({ gridHeight, gridWidth }: GridLoadingProps) => {
  const numberOfRows = Math.floor(gridHeight / ROW_HEIGHT);

  const rows = [];
  for (let currentRow = 0; currentRow < numberOfRows; currentRow++) {
    rows.push(
      <div className={b('gridRow')} key={`loadingRow${currentRow}`}>
        <LoadingCellRenderer gridWidth={gridWidth} key={`loadingCellRenderer${currentRow}`} />
      </div>
    );
  }

  return <div data-testid="loading-grid">{rows}</div>;
};

export default GridLoading;
