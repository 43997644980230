// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WgJTg {\n  text-align: center;\n  margin: var(--m) var(--xl) 0 var(--xl);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountLocationUploadInstructions": "WgJTg"
};
export default ___CSS_LOADER_EXPORT___;
