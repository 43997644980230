export const DRAWER_SIZE_OPEN = 500;
export const DRAWER_SIZE_CLOSED = 52;
export const CELL_HEIGHT = 40;
export const BALANCING_CELL_HEIGHT = 34;
export const BALANCING_HEADER_CELL_HEIGHT = 48;
export const MONTH_PRESET_ARRAY = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
