import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import {
  RequestDeleteTerritoryRules,
  RequestDeleteTerritoryRulesVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const REQUEST_DELETE_TERRITORY_RULES = gql`
  mutation RequestDeleteTerritoryRules($input: RequestDeleteTerritoryRulesInput!) {
    requestDeleteTerritoryRules(input: $input) {
      messageId
      jobKey
    }
  }
`;

export const useRequestDeleteTerritoryRules = createUseMutation<
  RequestDeleteTerritoryRules,
  RequestDeleteTerritoryRulesVariables
>(REQUEST_DELETE_TERRITORY_RULES);
