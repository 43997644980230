import React from 'react';

import { CombinatorType } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './Combinator.module.pcss';

const b = block(style);

interface CombinatorProps {
  combinatorType: CombinatorType;
  'data-testid': string;
}

const Combinator: React.FC<CombinatorProps> = ({ combinatorType, 'data-testid': dataTestId }: CombinatorProps) => (
  <div className={b('combinatorContainer', { [combinatorType.toLowerCase()]: true })} data-testid={dataTestId}>
    {combinatorType}
  </div>
);

export default Combinator;
