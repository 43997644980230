import {
  BattlecardWorkflow,
  ContributorTreeData,
  ContributorWorkflow,
  TerritoryGroupsWorkflow,
  WorkflowType
} from 'app/models';

export const getContributorRowNodeId = (item: ContributorTreeData): string => {
  if (!item) {
    return '';
  }

  return `${item.territoryGroupWorkflowId}_${item.parentTerritoryGroupId}_${item.rootParentType}`;
};

export const getContributorGridData = (contributorWorkflow: ContributorWorkflow): ContributorTreeData[] => {
  const contributorTreeData = [];

  contributorWorkflow?.battlecards?.forEach((battleCard) => {
    battleCard?.forEach((battleCardItem) => {
      createTreeData(battleCardItem, battleCardItem.territoryGroups, contributorTreeData);
    });
  });

  contributorWorkflow?.territoryGroups?.forEach((territoryGroup) => {
    createTreeData(null, territoryGroup, contributorTreeData);
  });

  return contributorTreeData;
};

export const getContributorRowDataById = (
  id: string,
  isBattleCardId: boolean,
  contributorTreeData: ContributorTreeData[]
): ContributorTreeData => {
  let workflowId = id;
  if (isBattleCardId) {
    workflowId = `BC-${id}`;
  }

  return contributorTreeData.find((contributorTreeItem) => contributorTreeItem.territoryGroupId === workflowId);
};

export const getParentNodes = (
  contributorTreeDataItem: ContributorTreeData,
  contributorTreeData: ContributorTreeData[]
): string[] => {
  const parentTerritoryGroupIds = contributorTreeDataItem?.territoryGroupIdsPath?.filter(
    (item) => item !== contributorTreeDataItem?.territoryGroupId
  ); // excluding the current territory to get the parents
  const parentTerritoryGroups = contributorTreeData.filter(
    (item) =>
      parentTerritoryGroupIds?.includes(item.territoryGroupId) &&
      item.rootParentType === contributorTreeDataItem.rootParentType
  );
  return parentTerritoryGroups?.map((parent) => getContributorRowNodeId(parent));
};

const createTreeData = (
  battleCard: BattlecardWorkflow,
  territoryGroups: TerritoryGroupsWorkflow[],
  contributorTreeData: ContributorTreeData[]
) => {
  let battleCardGroup: string[];
  if (battleCard) {
    battleCardGroup = createBattleCard(battleCard, contributorTreeData);
  }
  const territoryGroupLookup = Object.create(null);

  territoryGroups.forEach((territoryGroup) => {
    territoryGroupLookup[territoryGroup.territoryGroupId] = {
      ...territoryGroup,
      territoryGroupIdsPath: battleCard
        ? [...battleCardGroup, territoryGroup.territoryGroupId.toString()]
        : [territoryGroup.territoryGroupId.toString()]
    };

    if (territoryGroup.territoryGroupParentId) {
      if (territoryGroupLookup[territoryGroup.territoryGroupParentId]) {
        territoryGroupLookup[territoryGroup.territoryGroupId].territoryGroupIdsPath = [
          ...territoryGroupLookup[territoryGroup.territoryGroupParentId].territoryGroupIdsPath,
          territoryGroup.territoryGroupId.toString()
        ];
      }
    }
    createTerritoryGroup(battleCard, territoryGroup, contributorTreeData, territoryGroupLookup);
  });
};

const createBattleCard = (battleCard: BattlecardWorkflow, contributorTreeData: ContributorTreeData[]) => {
  const parentBattleCard =
    battleCard?.battlecardParentId && contributorTreeData?.length
      ? contributorTreeData?.find(
          (item) => item.territoryGroupId.replace('BC-', '') === battleCard?.battlecardParentId.toString()
        )
      : null;

  const battleCardParentIds = parentBattleCard?.territoryGroupIdsPath;

  const battleCardGroup = battleCardParentIds?.length
    ? [...battleCardParentIds, `BC-${battleCard?.battlecardId}`]
    : [`BC-${battleCard?.battlecardId}`];

  contributorTreeData.push({
    territoryGroupIdsPath: battleCardGroup,
    user: `${battleCard.ownerLastName}, ${battleCard.ownerFirstName}`,
    email: battleCard.ownerEmail,
    status: battleCard.status,
    approver:
      battleCard.approverLastName && battleCard.approverFirstName && !parentBattleCard?.approver
        ? `${battleCard.approverLastName}, ${battleCard.approverFirstName}`
        : '',
    parentTerritoryGroupId: battleCard.battlecardParentId ? battleCard.battlecardParentId.toString() : null,
    territoryGroupWorkflowId: battleCard.battlecardWorkflowId,
    territoryGroupId: `BC-${battleCard.battlecardId}`,
    type: WorkflowType.BATTLECARD,
    rootParentType: WorkflowType.BATTLECARD,
    territoryGroupName: battleCard.battlecardName,
    approverEmail: battleCard.approverEmail
  });

  return battleCardGroup;
};

const createTerritoryGroup = (
  battleCard: BattlecardWorkflow,
  territoryGroup: TerritoryGroupsWorkflow,
  contributorTreeData: ContributorTreeData[],
  territoryGroupLookup
) => {
  contributorTreeData.push({
    territoryGroupIdsPath: territoryGroupLookup[territoryGroup.territoryGroupId].territoryGroupIdsPath,
    user: `${territoryGroupLookup[territoryGroup.territoryGroupId].ownerLastName}, ${
      territoryGroupLookup[territoryGroup.territoryGroupId].ownerFirstName
    }`,
    email: territoryGroupLookup[territoryGroup.territoryGroupId].ownerEmail,
    status: territoryGroupLookup[territoryGroup.territoryGroupId].status,
    approver:
      !battleCard &&
      !territoryGroupLookup[territoryGroup.territoryGroupParentId] &&
      territoryGroupLookup[territoryGroup.territoryGroupId].approverLastName &&
      territoryGroupLookup[territoryGroup.territoryGroupId].approverFirstName
        ? `${territoryGroupLookup[territoryGroup.territoryGroupId].approverLastName}, ${
            territoryGroupLookup[territoryGroup.territoryGroupId].approverFirstName
          }`
        : '',
    parentTerritoryGroupId: getTerritoryGroupParent(battleCard, territoryGroup.territoryGroupParentId),
    territoryGroupWorkflowId: territoryGroupLookup[territoryGroup.territoryGroupId].territoryGroupWorkflowId,
    territoryGroupId: territoryGroupLookup[territoryGroup.territoryGroupId].territoryGroupId.toString(),
    territoryGroupName: territoryGroupLookup[territoryGroup.territoryGroupId].territoryGroupName,
    type: WorkflowType.TERRITORY_GROUP,
    rootParentType: battleCard ? WorkflowType.BATTLECARD : WorkflowType.TERRITORY_GROUP,
    approverEmail: territoryGroupLookup[territoryGroup.territoryGroupId].approverEmail
  });
};

const getTerritoryGroupParent = (battleCard, territoryGroupParentId) => {
  if (battleCard && !territoryGroupParentId) {
    return `BC-${battleCard.battlecardId}`;
  } else if (territoryGroupParentId) {
    return territoryGroupParentId.toString();
  }

  return null;
};
