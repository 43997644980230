import { TerritoryGroupTree } from 'utils/TerritoryGroupTree';

interface TgWithParent {
  territoryGroupId: number;
  territoryGroupParentId: number;
}

export class TerritoryGroupSlicer<T extends TgWithParent> {
  public readonly levels: ReadonlyArray<ReadonlySet<number>>;

  constructor(private readonly territoryGroupTree: TerritoryGroupTree<T>) {
    this.levels = TerritoryGroupSlicer.createLevels(territoryGroupTree);
  }

  private static createLevels<T extends TgWithParent>(territoryGroupTree: TerritoryGroupTree<T>) {
    if (!territoryGroupTree.rootId || !territoryGroupTree.groupById.has(territoryGroupTree.rootId)) return [];
    const levels = new Array<Set<number>>();
    let prevLevel = [territoryGroupTree.rootId];

    let hasMaterialChild: boolean;
    do {
      hasMaterialChild = false;
      levels.push(new Set(prevLevel));
      const newLevel = new Array<number>();

      for (const parentId of prevLevel) {
        const childrenIds = territoryGroupTree.idsByParentId.get(parentId);
        if (childrenIds?.length) {
          hasMaterialChild = true;
          newLevel.push(...childrenIds);
        } else {
          newLevel.push(parentId);
        }
      }
      prevLevel = newLevel;
    } while (hasMaterialChild);

    return levels;
  }

  private getNearestAncestorOnLevel(levelIndex: number, groupId: number | null): T | null {
    if (!groupId) return null;

    const level = this.levels[levelIndex];
    if (!level) return null;

    const group = this.territoryGroupTree.groupById.get(groupId);
    if (!group) return null;

    if (level.has(groupId)) return group;

    return this.getNearestAncestorOnLevel(levelIndex, group.territoryGroupParentId);
  }

  public getLeafLevelLookup(levelIndex: number): Map<number, T> {
    return new Map(
      [...this.territoryGroupTree.groupById.values()].map((group) => [
        group.territoryGroupId,
        this.getNearestAncestorOnLevel(levelIndex, group.territoryGroupId)
      ])
    );
  }

  public getLevelsAsGroups(): T[][] {
    return this.levels.map((groupIdsInLevel) =>
      [...groupIdsInLevel].map((groupId) => groupId && this.territoryGroupTree.groupById.get(groupId)).filter(Boolean)
    );
  }
}
