// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x8E92 {\n  padding: var(--dialog-padding) 0;\n}\n\n  .CkfeX {\n    font-weight: 600 !important;\n    padding-top: 24px !important;\n  }\n\n  .CkfeX:first-of-type {\n    padding-top: 8px !important;\n  }\n\n  .c7PfH {\n  }\n\n  .U-ugG {\n    display: flex;\n    flex-direction: column;\n    margin-right: 10px;\n    color: rgb(var(--color-dark-gray-3));\n  }\n\n  .Vwn4c {\n    display: grid;\n    grid-template-columns: 150px 300px;\n    margin-top: 16px;\n  }\n\n  ._89A7E {\n    display: grid;\n    grid-template-columns: 250px 300px;\n    margin-top: 16px;\n  }\n\n  ._0glIA {\n    display: flex;\n    padding-top: 24px;\n  }\n\n  ._9QCsP {\n    padding-top: 4px;\n  }\n\n  .lNR1P {\n    font-weight: 600;\n    line-height: 20px;\n  }\n\n  .bsNu4 {\n    padding-top: 24px;\n    width: 33%;\n  }\n\n  .rsZ2z {\n    width: 50%;\n  }\n\n  ._3AxMT {\n    padding-top: 24px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReviewConfigurationDialog": "x8E92",
	"ReviewConfigurationDialog_heading": "CkfeX",
	"ReviewConfigurationDialog_subText": "c7PfH " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["sub-text"] + "",
	"ReviewConfigurationDialog_textContainer": "U-ugG",
	"ReviewConfigurationDialog_dataFile": "Vwn4c",
	"ReviewConfigurationDialog_configuration": "_89A7E",
	"ReviewConfigurationDialog_checkbox": "_0glIA",
	"ReviewConfigurationDialog_description": "_9QCsP",
	"ReviewConfigurationDialog_subTitle": "lNR1P",
	"ReviewConfigurationDialog_fileTypeInput": "bsNu4",
	"ReviewConfigurationDialog_destinationFieldContainer": "rsZ2z",
	"ReviewConfigurationDialog_destinationInput": "_3AxMT"
};
export default ___CSS_LOADER_EXPORT___;
