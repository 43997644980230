import { MutableRefObject, useEffect, useState } from 'react';

export const useIsVisible = (ref: MutableRefObject<HTMLElement>): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      const isVisible = entries.some((entry) => entry.isIntersecting);
      setIsVisible(isVisible);
    });
    intersectionObserver.observe(ref.current);
    return () => intersectionObserver.disconnect();
    // No dependencies means we expect the ref to never change elements or be undefined
  }, []);

  return isVisible;
};
