// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CKeGE .bp3-menu {\n    width: var(--select-menu-width);\n    height: 230px !important; /* needed to override the command center */\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CurrencySelect": "CKeGE"
};
export default ___CSS_LOADER_EXPORT___;
