import { ApolloQueryResult } from '@apollo/client';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import {
  GetLookupTableVariables,
  GetLookupTable_getPlanningCycleSpec_lookups
} from 'app/graphql/generated/graphqlApolloTypes';
import { GET_LOOKUP_TABLE } from 'app/graphql/queries/getLookupTable';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export interface ScheduleData {
  scheduleName: string | null;
  month_1: number | null;
  month_2: number | null;
  month_3: number | null;
  month_4: number | null;
  month_5: number | null;
  month_6: number | null;
  month_7: number | null;
  month_8: number | null;
  month_9: number | null;
  month_10: number | null;
  month_11: number | null;
  month_12: number | null;
}

const getScheduleList = (data) => {
  if (!data) {
    return [];
  }
  const parsedData = JSON.parse(data);

  const scheduleList = [];
  Object.entries(parsedData).forEach((schedule) => {
    const data = {};
    data['scheduleName'] = schedule[0];
    Object.entries(schedule[1]).forEach((monthData) => (data[monthData[0]] = monthData[1]));
    scheduleList.push(data);
  });
  return scheduleList;
};

export const getLookupTable = async (variables: GetLookupTableVariables): Promise<ScheduleData[]> => {
  try {
    const result: ApolloQueryResult<{
      getPlanningCycleSpec: {
        lookups: GetLookupTable_getPlanningCycleSpec_lookups;
      };
      // eslint-disable-next-line no-restricted-syntax
    }> = await apolloClient.query({
      query: GET_LOOKUP_TABLE,
      variables,
      fetchPolicy: 'network-only'
    });

    return getScheduleList(result?.data?.getPlanningCycleSpec?.lookups[0]?.metadata?.tableData);
  } catch (_error) {
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('GET_LOOKUP_TABLE_SCHDULES_ERROR'), 'danger');
    return null;
  }
};
