import { useCallback, useEffect } from 'react';

import { FromSymonMessage } from 'app/containers/Symon/SymonTypes';

type OnSymonMessage = (message: FromSymonMessage) => void;

const useOnSymonMessage = (onMessage: OnSymonMessage, symonOrigin: string): void => {
  const handleMessageEvent = useCallback(
    (event: MessageEvent<FromSymonMessage>) => {
      if (event.origin !== symonOrigin) return;
      console.debug('Received message from Symon', event.data);
      onMessage(event.data);
    },
    [onMessage, symonOrigin]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handleMessageEvent]);
};

export default useOnSymonMessage;
