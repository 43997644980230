// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K5MwT {\n    width: 272px;\n    padding: 12px;\n    border-radius: 4px;\n    display: flex;\n    flex-direction: column;\n    grid-gap: var(--m);\n    gap: var(--m);\n  }\n  .IWmW6 {\n    margin-bottom: var(--xs);\n  }\n  .ZnIOn {\n    padding: 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapGridFilter_filterWrapper": "K5MwT",
	"MapGridFilter_menuTitle": "IWmW6",
	"MapGridFilter_colorByTerritoryToastButton": "ZnIOn"
};
export default ___CSS_LOADER_EXPORT___;
