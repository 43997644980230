import gql from 'graphql-tag';

export const QUERY_GET_HIERARCHY_POLYGON_ID = gql`
  query HierarchyPolygonId($includeCatalog: Boolean!, $planningCycleId: Int!, $startRow: Int, $endRow: Int) {
    getHierarchyPolygonId(input: { planningCycleId: $planningCycleId, startRow: $startRow, endRow: $endRow }) {
      compressedType
      compressed
      totalCount
    }
    getPlanningCycleSpec(input: { planningCycleId: $planningCycleId }) @include(if: $includeCatalog) {
      planningCycleId
      inUseCountrySourceCatalogItems {
        depth
        polygonCount
        country
        sourceId
        sourceKey
        sourceUrl
        bounds {
          maxLat
          minLon
          maxLon
          minLat
        }
        minZoom
      }
    }
  }
`;
