// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GEoVm {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  min-height: 300px;\n}\n\n  .GEoVm .ag-react-container {\n    height: 100%;\n  }\n\n  .GEoVm .ag-theme-alpine .ag-root-wrapper {\n    border: 0;\n    height: 100%;\n  }\n\n  .GEoVm .bp3-tab-indicator-wrapper .bp3-tab-indicator {\n    display: none;\n  }\n\n.GEoVm.ag-theme-alpine .ag-root-wrapper {\n    border: 0;\n    height: 100%;\n  }\n\n.GEoVm.ag-theme-alpine .ag-row {\n    cursor: pointer;\n  }\n\n.GEoVm .bp3-tab[aria-selected='false'] {\n    box-shadow: none !important;\n  }\n\n.iNAvj {\n    height: 100%;\n    max-height: 100vh;\n    margin-bottom: 20px;\n  }\n\n._8-vNH {\n    border: 1px solid rgb(var(--color-light-gray-3));\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n\n.GwstA {\n    border: none;\n  }\n\n.vWQdb {\n    margin-bottom: 10px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UnpublishedTable": "GEoVm",
	"UnpublishedTable_tablesGrid": "iNAvj",
	"UnpublishedTable_grid": "_8-vNH",
	"UnpublishedTable_noData": "GwstA " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-no-data"] + "",
	"UnpublishedTable_headingContainer": "vWQdb"
};
export default ___CSS_LOADER_EXPORT___;
