import gql from 'graphql-tag';

export const GET_IN_USE_COUNTRY_LEVELS = gql`
  query GetInUseCountryLevels($planningCycleId: Int!) {
    getPlanningCycleSpec(input: { planningCycleId: $planningCycleId }) {
      planningCycleId
      countryLevels {
        country
        level
      }
    }
  }
`;
