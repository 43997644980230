import React from 'react';

import { Locked } from '@carbon/icons-react';
import { Text } from '@varicent/components';
import { Popup } from 'react-map-gl';

import Icon from 'components/Icon/Icon';

import { MapSelectionTarget, PolygonTooltip } from 'app/models';

import block from 'utils/bem-css-modules';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';
import { formatMessage } from 'utils/messages/utils';

import { useIsMapCapable } from './hooks/useMapCapability';
import style from './MapTooltip.module.pcss';

const b = block(style);

export interface MapTooltipProps {
  selectionTarget: MapSelectionTarget;
  polygonTooltip: PolygonTooltip;
  showTgDetails: boolean;
  lockedRuleIds: Set<number>;
}

const MapTooltip: React.FC<MapTooltipProps> = ({
  selectionTarget,
  polygonTooltip,
  showTgDetails,
  lockedRuleIds
}: MapTooltipProps) => {
  const renderToolTipItems = () => {
    if (!polygonTooltip.territoryId) {
      const unassignedText =
        selectionTarget === MapSelectionTarget.territories
          ? formatMessage('NO_TERRITORY')
          : formatMessage('UNASSIGNED_PARENTHESES');
      return (
        <Text data-testid="unassigned-tooltip-item" className={b('unassigned')}>
          {unassignedText}
        </Text>
      );
    }
    if (showTgDetails) {
      return (
        <>
          <Text data-testid="group-name-tooltip-item" className={b('groupItem')}>
            {polygonTooltip.groupName}
          </Text>
          {polygonTooltip.groupOwner && (
            <Text data-testid="group-owner-tooltip-item" className={b('groupItem')}>
              {formatMessage('OWNER_WITH_COLON')} {polygonTooltip.groupOwner}
            </Text>
          )}
        </>
      );
    }
    return (
      <Text className={b('territoryIdAndName')}>
        {polygonTooltip.territoryId} ({polygonTooltip.territoryName})
      </Text>
    );
  };

  const shouldShowConflictWarning =
    polygonTooltip.isPolygonOverAssigned ||
    (polygonTooltip.isOverAssignedRule && selectionTarget === MapSelectionTarget.territories);
  const shouldShowPolygonName = selectionTarget === MapSelectionTarget.polygons && !showTgDetails;
  const canViewUnassigned = useIsMapCapable(MapCapability.VIEW_UNASSIGNED);

  if (!polygonTooltip?.territoryId && !canViewUnassigned && !shouldShowConflictWarning) return null;
  return (
    <div data-testid="map-tooltip">
      <Popup
        className={b('tooltipWrapper')}
        anchor="bottom"
        closeButton={false}
        closeOnClick={false}
        longitude={polygonTooltip.x}
        latitude={polygonTooltip.y}
        offset={[0, -10]}
      >
        <div className={b('tooltip')}>
          {lockedRuleIds.has(polygonTooltip.ruleId) && (
            <div data-testid="locked-tooltip-item" className={b('lockIcon')}>
              <Icon icon={<Locked size={20} color="white" />} />
            </div>
          )}
          <div>
            {shouldShowPolygonName && (
              <Text data-testid="polygon-name" className={b('polygonName')}>
                {polygonTooltip.polygonName}
              </Text>
            )}
            {renderToolTipItems()}
            {shouldShowConflictWarning && (
              <Text data-testid="conflicting-rules-tooltip-item" className={b('conflicts')}>
                {polygonTooltip.isPolygonOverAssigned
                  ? formatMessage('CONFLICTING_RULES', { count: polygonTooltip.overAssignmentCount })
                  : formatMessage('CONFLICTING_RULE')}
              </Text>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default MapTooltip;
