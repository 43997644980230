import React from 'react';

import { Spinner } from '@blueprintjs/core';
import { CheckmarkFilled, Misuse } from '@carbon/icons-react';
import { Field } from 'formik';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import block from 'utils/bem-css-modules';

import style from './TextMessageInputField.module.pcss';

const b = block(style);

interface TextMessageInputFieldProps {
  isLoading: boolean;
  message: string | JSX.Element;
  name: string;
  shouldShowSuccessIcon: boolean;
  shouldShowErrorIcon: boolean;
  isMessageAvailable: boolean;
  textLabel: string | JSX.Element;
  textPlaceholder: string;
  onChange: () => void;
  isRequired?: boolean;
  shouldShowMessage?: boolean;
  shouldValidateOnTouch?: boolean;
  textBoxClassName?: string;
  onTransitionEnd?: () => void;
  disabled?: boolean;
}

const TextMessageInputField: React.FC<TextMessageInputFieldProps> = ({
  isLoading,
  name,
  message,
  shouldShowSuccessIcon,
  shouldShowErrorIcon,
  isMessageAvailable,
  textLabel,
  textPlaceholder,
  onChange,
  isRequired = false,
  shouldValidateOnTouch = true,
  shouldShowMessage = true,
  textBoxClassName = '',
  onTransitionEnd = null,
  disabled = false
}) => {
  const getIcon = () => {
    if (isLoading) {
      return <Spinner data-testid="input-spinner" intent="primary" size={20} />;
    } else if (shouldShowSuccessIcon) {
      return (
        <CheckmarkFilled size={20} data-testid="success-input-icon" className={b('statusIcon', { success: true })} />
      );
    } else if (shouldShowErrorIcon) {
      return <Misuse size={20} data-testid="error-input-icon" className={b('statusIcon', { error: true })} />;
    } else {
      return null;
    }
  };

  return (
    <div>
      <div>
        <Field
          name={name}
          type="text"
          data-testid="text-input-field"
          className={textBoxClassName}
          label={textLabel}
          component={FormTextInputGroup}
          icon={getIcon()}
          placeHolder={textPlaceholder}
          shouldValidateOnTouch={shouldValidateOnTouch}
          isRequired={isRequired}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      <div className={b('messageContainer')}>
        <div
          onTransitionEnd={() => (onTransitionEnd ? onTransitionEnd() : null)}
          className={b('message', {
            shown: shouldShowMessage,
            hidden: !shouldShowMessage
          })}
          data-testid="message-container"
        >
          {isMessageAvailable && message}
        </div>
      </div>
    </div>
  );
};

export default TextMessageInputField;
