// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._675Ij {\n  display: flex;\n}\n\n  .sLylk {\n    display: flex;\n  }\n\n  .Uveiy {\n    margin-left: 1rem !important;\n    margin-right: 1rem !important;\n  }\n\n  ._7gQjk {\n    flex-grow: 0;\n  }\n\n  .ifa9c {\n    min-width: 280px;\n  }\n\n  .ifa9c .bp3-button-text {\n      width: 100%;\n    }\n\n  .Fu0sU {\n    margin: 0 25px 0 0;\n  }\n\n  .W-WSZ {\n    margin: 10px;\n  }\n\n  .stepZ {\n    height: 40px;\n    width: 40px;\n  }\n\n  .IwAdR {\n    padding: 0 3px 0 10px;\n  }\n\n  .QhbWv {\n    width: 100px;\n  }\n\n  ._9M5Dj {\n    font-weight: bold;\n  }\n\n  ._9T1EY {\n    margin-top: 5px;\n    max-width: 200px;\n  }\n\n  ._7PoXn {\n    margin-bottom: -5px !important;\n    margin-left: 5px;\n  }\n\n  ._675Ij .bp3-menu {\n    width: 150px;\n  }\n\n  ._675Ij .bp3-button {\n    height: var(--select-menu-height);\n  }\n\n  .G7VE9 {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .nfKtl {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    grid-gap: 0.5rem;\n    gap: 0.5rem;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BattleCardCanvasControl": "_675Ij",
	"BattleCardCanvasControl_canvasControlsCard": "sLylk",
	"BattleCardCanvasControl_canvasControlsDivider": "Uveiy",
	"BattleCardCanvasControl_canvasControlsFormBattleCardRefactor": "_7gQjk",
	"BattleCardCanvasControl_canvasSelect": "ifa9c",
	"BattleCardCanvasControl_menuText": "Fu0sU",
	"BattleCardCanvasControl_icon": "W-WSZ",
	"BattleCardCanvasControl_iconContainer": "stepZ",
	"BattleCardCanvasControl_iconButtonContainer": "IwAdR",
	"BattleCardCanvasControl__customSelect": "QhbWv",
	"BattleCardCanvasControl_toastHeader": "_9M5Dj",
	"BattleCardCanvasControl_toastBody": "_9T1EY",
	"BattleCardCanvasControl_dropdown": "_7PoXn",
	"BattleCardCanvasControl_changeRequestButtonCounter": "G7VE9",
	"BattleCardCanvasControl_changeRequestMenuItem": "nfKtl"
};
export default ___CSS_LOADER_EXPORT___;
