// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C9\\+xn {\n  display: inline-flex;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-end;\n  font-weight: normal;\n  font-size: 14px;\n  color: rgb(var(--color-dark-gray-3));\n  padding-right: var(--grid-header-padding);\n}\n\n  .j8RWk {\n    background-color: var(--intent-primary-translucent-hover);\n    border-bottom: 1px solid rgb(var(--color-cobalt-3));\n  }\n\n  .A8kU- {\n    margin-left: 5px;\n  }\n\n  .A8kU- .bp3-button {\n      padding: 0;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExpandableHeaderCellRenderer": "C9+xn",
	"ExpandableHeaderCellRenderer__highlight": "j8RWk",
	"ExpandableHeaderCellRenderer_chervonIcon": "A8kU-"
};
export default ___CSS_LOADER_EXPORT___;
