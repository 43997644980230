import React, { useEffect, useRef, useState, useMemo, MutableRefObject, useLayoutEffect } from 'react';

import { Classes } from '@blueprintjs/core';
import { ChevronDown } from '@carbon/icons-react';
import { ModifierPhases } from '@popperjs/core';
import {
  useSelect,
  UseSelectGetItemPropsOptions,
  UseSelectGetMenuPropsOptions,
  GetPropsCommonOptions
} from 'downshift';
import { FieldProps, FormikErrors } from 'formik';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Icon from 'components/Icon/Icon';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import { KeyValue, SelectMenuItem } from 'components/models';

import useCombinedRefs from 'app/hooks/useCombinedRefs';

import block from 'utils/bem-css-modules';

import style from './SelectMenu.module.pcss';

const b = block(style);

const outlineStyle = {
  border: 'solid 1px rgb(var(--color-light-gray-1))',
  borderRadius: '0.25rem',
  boxShadow: 'var(--overlay-box-shadow)',
  minWidth: 0
};

const fullWidth = {
  width: '100%'
};

interface CustomListItemProps {
  item: SelectMenuItem;
  disabledItems?: Record<string, unknown>[];
  getItemProps: (options: UseSelectGetItemPropsOptions<unknown>) => unknown;
  index: number;
}

const CustomListItem: React.FC<CustomListItemProps> = ({
  item,
  getItemProps,
  disabledItems,
  index
}: CustomListItemProps) => {
  return (
    <li
      className={Classes.MENU_ITEM}
      {...(disabledItems?.find((disabledItem) => disabledItem?.value === item.value) && { disabled: true })}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      {...getItemProps({
        item,
        index
      })}
      data-testid={`select-menu-list-item-${item.value}`}
    >
      <div className={b('itemWrapper')}>
        {item.icon ? (
          <div className={b('iconWrapper')}>
            <Icon icon={item.icon as JSX.Element} />
          </div>
        ) : null}
        {item.key}
      </div>
    </li>
  );
};

interface SelectMenuContentProps {
  usePortal: boolean;
  portalClassName?: string;
  combinedMenuRef; // TODO TQP-2023 Type combinedMenuRef
  menuProps: UseSelectGetMenuPropsOptions | GetPropsCommonOptions;
  styles: Record<string, React.CSSProperties>;
  attributes: Record<string, Record<string, string>>;
  reallyOpen: boolean;
  outlineItems: boolean;
  getItemProps: (options: UseSelectGetItemPropsOptions<unknown>) => unknown;
  items: KeyValue<string>[];
  disabledItems?: Record<string, unknown>[];
  shouldValidateOnTouch?: boolean;
}

const SelectMenuContent: React.FC<SelectMenuContentProps> = ({
  usePortal,
  portalClassName,
  combinedMenuRef,
  menuProps,
  styles,
  attributes,
  reallyOpen,
  outlineItems,
  getItemProps,
  items,
  disabledItems
}: SelectMenuContentProps) => {
  return (
    <ul
      ref={combinedMenuRef}
      className={`${Classes.MENU} ${portalClassName}`}
      {...menuProps}
      style={{
        /* TODO TQP-1415 revisit using classes here instead of inline styling */
        ...styles.popper,
        visibility: reallyOpen ? 'visible' : 'hidden',
        zIndex: 1000, // needed because of overlap in battlecard designer dialogue
        opacity: 1,
        ...(outlineItems ? outlineStyle : {}),
        ...(usePortal ? {} : fullWidth) // sets to 100% if not using portal
      }}
      {...attributes.popper}
      data-testid={'select-menu-contents'}
    >
      {reallyOpen &&
        items.map((item, index) => (
          <CustomListItem
            item={item}
            getItemProps={getItemProps}
            disabledItems={disabledItems}
            index={index}
            key={`${item.value}${index}`}
          />
        ))}
    </ul>
  );
};

export { SelectMenuContent };

interface SelectMenuProps extends FieldProps {
  theme?: 'default' | 'secondary' | 'tertiary';
  items: KeyValue<string>[];
  disabled?: boolean;
  disabledItems?: Record<string, unknown>[];
  minimal?: boolean;
  outlineItems?: boolean;
  placeHolderText?: string;
  allowFlip?: boolean;
  allowPopperEventListeners?: boolean;
  showErrors?: boolean;
  onChange?: (value: unknown) => void;
  usePortal?: boolean;
  portalClassName?: string;
  portalRef?: { current: Element };
  shouldValidateOnTouch?: boolean;
  loading?: boolean;
  parentRef?: MutableRefObject<HTMLDivElement>;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  helpText?: string;
  dropdownTooltipText?: string;
}

/* Separation of concerns between Formik, Downshift's useSelect, Blueprint and react-popper:
// 1. Formik controls initial values and current values
// 2. Downshift's useSelect controls accessibility and interactions, and its onSelectedItemChange prop
// sets Formik state via form.setFieldValue */
// 3. Blueprint provides styling via Button, menu and menu item classes
// 4. react-popper provides positioning (because Blueprint popover conflicts with Downshift)

const SelectMenu: React.FC<SelectMenuProps> = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  theme,
  items,
  disabled,
  disabledItems = [],
  minimal,
  outlineItems = true,
  placeHolderText,
  allowFlip = true,
  allowPopperEventListeners = true,
  showErrors = true,
  onChange,
  portalClassName = null,
  usePortal = false,
  portalRef,
  shouldValidateOnTouch = true,
  loading,
  parentRef,
  icon,
  rightIcon,
  helpText,
  dropdownTooltipText
}: SelectMenuProps) => {
  const itemToString = (item) => {
    return item ? item.key : '';
  };

  const onSelectedItemChange = (item) => {
    if (onChange) {
      onChange(item?.selectedItem);
    } else {
      setFieldValue(name, item?.selectedItem);
    }
  };

  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items,
    itemToString,
    selectedItem: value,
    onSelectedItemChange
  });

  const isTouched = !!touched?.[name];
  const errorMessage = isTouched ? getSelectErrorMessage(errors?.[name]) : null;

  const defaultButtonText = placeHolderText ? placeHolderText : '';

  const modifiers = useMemo(
    () => [
      {
        name: 'flip',
        enabled: allowFlip
      },
      {
        name: 'sameWidth',
        enabled: usePortal,
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        phase: 'beforeWrite' as ModifierPhases,
        requires: ['computeStyles']
      },
      {
        name: 'eventListeners',
        enabled: allowPopperEventListeners // TODO TQP-2707 - Refactor all instances of SearchableSelectMenu to not use Popper EventListeners modifier
      }
    ],
    []
  );

  useLayoutEffect(() => {
    const onScroll = () => {
      setReallyOpen(false);
    };

    if (parentRef && parentRef.current) {
      parentRef.current.addEventListener('scroll', onScroll);
    }

    return () => parentRef?.current?.removeEventListener('scroll', onScroll);
  }, [parentRef?.current]);

  // useState for the following to make sure they can measure on any changes
  // then we'll combine refs with the custom hook so that popper, downshift and
  // blueprint all play nicely together
  const referenceElement = useRef<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(null);
  const { styles, attributes, forceUpdate } = usePopper(referenceElement.current, popperElement, {
    placement: 'bottom-start',
    modifiers
  });

  const { ref: buttonRef, ...buttonProps } = getToggleButtonProps();
  const combinedButtonRef = useCombinedRefs(buttonRef, referenceElement);
  const { ref: menuRef, ...menuProps } = getMenuProps();
  const combinedMenuRef = useCombinedRefs(menuRef, setPopperElement);

  // TODO TQP-1446 https://varicent.atlassian.net/browse/TQP-1446 These next four lines are here as a temporary measure because adding the DataTray
  // somehow prevented the select menus in the battlecard header from re-rendering when isOpen
  // changed (which meant the menus never opened).
  const [reallyOpen, setReallyOpen] = useState(false);
  useEffect(() => {
    setReallyOpen(isOpen);
    // Force update the popper instance so that the styles update when dialog opens
    if (forceUpdate) {
      forceUpdate();
    }
  }, [isOpen]);

  const sharedContentProps = {
    usePortal,
    combinedMenuRef,
    menuProps,
    styles,
    attributes,
    reallyOpen,
    outlineItems,
    getItemProps,
    items,
    disabledItems
  };

  return (
    <div
      className={b({ default: theme === 'default', secondary: theme === 'secondary', tertiary: theme === 'tertiary' })}
      onBlur={() => setFieldTouched(name, true, shouldValidateOnTouch)}
      data-testid="select-menu"
    >
      <MessageTooltip
        className={b('selectBoxPopover')}
        content={!!dropdownTooltipText && dropdownTooltipText}
        placement={'top'}
        usePortal={false}
        disabled={!dropdownTooltipText}
        target={
          <TextButton
            refProps={combinedButtonRef}
            {...buttonProps}
            text={selectedItem ? selectedItem.key : defaultButtonText}
            type="button"
            rightIcon={rightIcon || <ChevronDown size={20} />}
            disabled={disabled}
            minimal={minimal}
            loading={loading}
            large={false}
            testId={'select-menu-button'}
            icon={icon}
          />
        }
      />

      {usePortal ? (
        createPortal(
          <div data-testid="menu-with-portal">
            <SelectMenuContent {...sharedContentProps} portalClassName={portalClassName} />
          </div>,
          portalRef?.current ?? document.body
        )
      ) : (
        <div data-testid="menu-without-portal">
          <SelectMenuContent {...sharedContentProps} />
        </div>
      )}
      {helpText && (
        <div className={b('helpText')} data-testid="select-menu-help-text">
          {helpText}
        </div>
      )}
      {showErrors ? <div className={b('validationMessage')}>{errorMessage}</div> : null}
    </div>
  );
};

const getSelectErrorMessage = (
  fieldError: string | FormikErrors<unknown> | string[] | FormikErrors<unknown>[]
): string | null => {
  if (Array.isArray(fieldError)) return fieldError.map((error) => getSelectErrorMessage(error)).toString();
  if (typeof fieldError === 'string') return fieldError;
  return fieldError?.['key'] ?? null;
};

export default SelectMenu;
