import { MutableRefObject } from 'react';

import { ColDef } from '@ag-grid-community/core';

import { getActivityFilesPanelCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/ActivityFilesPanelCellRenderer/ActivityFilesPanelCellRenderer';
import BattleCardReferenceCellRenderer from 'app/components/AdvancedGrid/CellRenderers/BattleCardReferenceCellRenderer/BattleCardReferenceCellRenderer';
import DropdownMenuCellRenderer from 'app/components/AdvancedGrid/CellRenderers/DropdownMenuCellRenderer/DropdownMenuCellRenderer';

import { DataTableFileStatusType, FileStatus, FileType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface BuildActivityFilesPanelColumnDefProps {
  totalCount: number;
  rowRef: MutableRefObject<HTMLDivElement>;
  deleteActivityFileEnabled?: boolean;
  showReferences?: boolean;
  onViewItemClicked?: (data) => void;
  onDeleteItemClicked?: (value: boolean) => void;
}

const buildActivityFilesPanelColumnDef = ({
  totalCount,
  rowRef,
  deleteActivityFileEnabled = false,
  showReferences = false,
  onViewItemClicked,
  onDeleteItemClicked
}: BuildActivityFilesPanelColumnDefProps): ColDef[] => {
  const columnDefs: ColDef[] = [];

  columnDefs.push({
    headerName: formatMessage('NAME'),
    field: 'tableName',
    flex: 2,
    minWidth: 500,
    cellRendererFramework: getActivityFilesPanelCellRenderer(DataTableFileStatusType.DEFAULT, totalCount, rowRef)
  });

  columnDefs.push({
    headerName: formatMessage('TYPE'),
    field: 'tableType',
    flex: 1,
    maxWidth: 250,
    valueFormatter: (params) => {
      if (params?.data?.tableType === 'Upload') {
        return params.data.tableDataType;
      }
    }
  });

  columnDefs.push({
    headerName: formatMessage('STATUS'),
    field: 'status',
    flex: 2,
    minWidth: 250,
    cellRendererFramework: getActivityFilesPanelCellRenderer(DataTableFileStatusType.STATUS, totalCount, rowRef)
  });

  if (deleteActivityFileEnabled) {
    if (showReferences) {
      columnDefs.push({
        headerName: formatMessage('REFERENCES'),
        flex: 2,
        width: 300,
        cellRendererFramework: BattleCardReferenceCellRenderer,
        cellRendererParams: (params) => {
          if (params?.node?.data?.tableDataType === FileType.ACTIVITY) {
            return {
              items: params.node.data.battlecardReferences?.map((bc) => bc.battlecardName).sort() || []
            };
          }
          return { items: [] };
        }
      });
    }

    columnDefs.push({
      field: 'menu',
      headerName: '',
      flex: 1,
      resizable: false,
      maxWidth: 50,
      cellRendererFramework: DropdownMenuCellRenderer,
      cellRendererParams: (params) => {
        const data = params?.node?.data;
        if (!data) {
          return {
            data: null
          };
        }
        const shouldShowMenu =
          data.status === FileStatus.COMPLETED ||
          data.status === FileStatus.FAILED ||
          data.status === FileStatus.PENDING;
        if (data.tableDataType === FileType.ACTIVITY && shouldShowMenu) {
          return {
            viewItemText: formatMessage('VIEW_TABLE'),
            onViewItemClicked,
            onDeleteItemClicked
          };
        }
        return {
          data: null
        };
      }
    });
  }

  return columnDefs;
};

export default buildActivityFilesPanelColumnDef;
