import { useEffect, useState } from 'react';

import { MapboxMap } from 'react-map-gl';

export const useIsMapLayerPresent = (
  mapboxMap: Pick<MapboxMap, 'on' | 'off' | 'getLayer'>,
  layerId: string
): boolean => {
  const [isLayerVisible, setIsLayerVisible] = useState(() => !!mapboxMap?.getLayer(layerId));

  const handler = (): void => {
    const isLayerPresent = !!mapboxMap?.getLayer(layerId);
    setIsLayerVisible(isLayerPresent);
  };

  useEffect(() => {
    handler();

    mapboxMap?.on('styledata', handler);

    return () => {
      mapboxMap?.off('styledata', handler);
    };
  }, [mapboxMap, layerId]);

  return isLayerVisible;
};
