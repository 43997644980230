import { UserProfile } from 'app/core/userManagement/userProvider';

export const getFullName = (userProfile: UserProfile): string => {
  return (
    (userProfile.firstName ? `${userProfile.firstName}` : '') + (userProfile.lastName ? ` ${userProfile.lastName}` : '')
  );
};

export const validatePassword = (oldPassword: string, newPassword: string): boolean => {
  return oldPassword.length > 0 && newPassword !== oldPassword && newPassword.length >= 8;
};
