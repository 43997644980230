// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UsRIh {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  align-items: center;\n}\n\n  .vpqn2 {\n    padding-left: 8px;\n    color: rgb(var(--color-gray-2));\n    width: 100%;\n    text-align: left;\n  }\n\n  .Y6fWM {\n    flex-shrink: 0;\n    color: rgb(var(--color-gray-2));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryGroupRenderer": "UsRIh",
	"TerritoryGroupRenderer_text": "vpqn2",
	"TerritoryGroupRenderer_icon": "Y6fWM"
};
export default ___CSS_LOADER_EXPORT___;
