import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import AuthSpinner from 'components/AuthSpinner/AuthSpinner';

import { RoutePaths } from 'app/containers/App/Router/routePaths';

import { useUser } from 'app/core/userManagement/userProvider';

import { INVITATION_ID } from 'app/global/variables';

import { handleError } from 'app/graphql/handleError';
import { ADD_USER } from 'app/graphql/mutations/addUser';
import { ADD_USER_TO_TENANT } from 'app/graphql/mutations/addUserToTenant';

import useShowToast from 'app/hooks/useShowToast';

import { RegistrationType } from 'app/models';

import { clearAuthValuesFromStorage } from 'utils/helpers/routingHelpers';
import { formatMessage } from 'utils/messages/utils';

const AuthUserWrapper: React.FC = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth0();
  const { setInvitation } = useUser();
  const history = useHistory();
  const [addUserSuccess, setAddUserSuccess] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const showToast = useShowToast();

  const invitationIdFromLocalStorage = localStorage.getItem(INVITATION_ID);
  const regTypeFromLocalStorage = localStorage.getItem('regType');

  const [addUser] = useMutation(ADD_USER, {
    onCompleted() {
      setAddUserSuccess(true);
      if (regTypeFromLocalStorage === RegistrationType.onboard) {
        setAuthLoading(false);
        history.push({
          pathname: RoutePaths.NEW_ORGANIZATION,
          search: `?invitationId=${invitationIdFromLocalStorage}&regType=${regTypeFromLocalStorage}`
        });
      }
    },
    onError({ graphQLErrors, networkError }) {
      showToast(formatMessage('INVALID_INVITATION_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
      console.debug('a clearAuthValuesFromStorage', [
        clearAuthValuesFromStorage,
        clearAuthValuesFromStorage.toString()
      ]);
      clearAuthValuesFromStorage();
      history.push(RoutePaths.LOGIN);
    }
  });

  const [addUserToTenant] = useMutation(ADD_USER_TO_TENANT, {
    onCompleted() {
      showToast(formatMessage('ADD_USER_TO_TENANT_SUCCESS'), 'success');
      localStorage.removeItem(INVITATION_ID);
      localStorage.removeItem('regType');
      setAuthLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      showToast(formatMessage('ADD_USER_TO_TENANT_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
      console.debug('a clearAuthValuesFromStorage', [
        clearAuthValuesFromStorage,
        clearAuthValuesFromStorage.toString()
      ]);
      clearAuthValuesFromStorage();
      history.push(RoutePaths.LOGIN);
    }
  });

  useEffect(() => {
    (async () => {
      try {
        if (isLoading) return;
        if (!isAuthenticated) return;

        if (invitationIdFromLocalStorage) {
          setInvitation({
            invitationId: invitationIdFromLocalStorage,
            regType: regTypeFromLocalStorage
          });
          await addUser({
            variables: {
              input: {
                invitationId: invitationIdFromLocalStorage
              }
            }
          });
        } else {
          setAuthLoading(false);
        }
      } catch (err) {
        console.error(err);
        showToast(formatMessage('AUTH_CHECK_FAILURE'), 'danger');
        history.push(RoutePaths.LOGIN);
      }
    })();
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    (async () => {
      try {
        if (invitationIdFromLocalStorage && addUserSuccess && regTypeFromLocalStorage !== RegistrationType.onboard) {
          await addUserToTenant({
            variables: {
              input: {
                invitationId: invitationIdFromLocalStorage
              }
            }
          });
        }
      } catch (err) {
        console.error(err);
        showToast(formatMessage('AUTH_CHECK_FAILURE'), 'danger');
        history.push(RoutePaths.LOGIN);
      }
    })();
  }, [addUserSuccess]);

  return authLoading ? <AuthSpinner /> : <>{children}</>;
};

export default AuthUserWrapper;
