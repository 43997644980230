import React from 'react';

import { Classes } from '@blueprintjs/core';
import { Field } from 'formik';

import SelectMenu from 'components/SelectMenu/SelectMenu';

import { HierarchyType, MeasureSource } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SheetsAddFieldFormFields.module.pcss';

const b = block(style);

interface HierarchyDataItems {
  key: string;
  value: number;
  type: HierarchyType;
}

interface CustomMeasureFormPartTwoProps {
  hierarchyDataItems: HierarchyDataItems[];
  hierarchyCustomPropertiesItems: { key: string; value: string }[];
  measureSource: Record<string, string> | '';
  fieldId: { key: string; value: number; type?: string } | '';
  isEditable: boolean;
}

const measureSourceItems = [
  { key: formatMessage('HIERARCHY'), value: 'Hierarchy' },
  { key: formatMessage('ACTIVITY'), value: 'Activity' },
  { key: formatMessage('TERRITORY'), value: 'Territory' },
  { key: formatMessage('CUSTOM'), value: 'Custom' },
  { key: formatMessage('DEFAULT'), value: 'Default' }
];

const disabledMeasureSourceItems = [
  { key: formatMessage('ACTIVITY'), value: 'Activity' },
  { key: formatMessage('TERRITORY'), value: 'Territory' },
  { key: formatMessage('CUSTOM'), value: 'Custom' },
  { key: formatMessage('DEFAULT'), value: 'Default' }
];

export const CustomMeasureFormPartTwo: React.FC<CustomMeasureFormPartTwoProps> = ({
  hierarchyDataItems,
  hierarchyCustomPropertiesItems,
  measureSource,
  fieldId,
  isEditable
}: CustomMeasureFormPartTwoProps) => {
  return (
    <div data-testid="form-two">
      <div className={b('formInput')} data-testid="measure-source-field">
        <label className={`${b('label')} ${Classes.LABEL}`}>{formatMessage('DATA_TABLE_TYPE_WITH_COLON')}</label>
        <Field
          name="measureSource"
          theme="default"
          placeHolderText={formatMessage('SELECT')}
          component={SelectMenu}
          items={measureSourceItems}
          disabledItems={disabledMeasureSourceItems}
          shouldValidateOnTouch={false}
          disabled={!isEditable}
        />
      </div>
      <div className={b('formInput')} data-testid="field-id-field">
        <label className={`${b('label')} ${Classes.LABEL}`}>{formatMessage('DATA_TABLE')}</label>
        <Field
          name="fieldId"
          theme="default"
          placeHolderText={formatMessage('SELECT')}
          component={SelectMenu}
          items={hierarchyDataItems ?? []}
          disabled={measureSource?.['value'] !== MeasureSource.HIERARCHY || !isEditable}
          shouldValidateOnTouch={false}
        />
      </div>
      <div />
      <div className={b('formInput')} data-testid="field-name-field">
        <label className={`${b('label')} ${Classes.LABEL}`}>{formatMessage('COLUMN')}</label>
        <Field
          name="fieldName"
          theme="default"
          component={SelectMenu}
          placeHolderText={formatMessage('SELECT')}
          items={hierarchyCustomPropertiesItems ?? []}
          shouldValidateOnTouch={false}
          disabled={!fieldId || !isEditable}
        />
      </div>
    </div>
  );
};

export default CustomMeasureFormPartTwo;
