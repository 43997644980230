// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D2a1h {\n  height: var(--hierarchy-panel-toolbar-height);\n  border-bottom: 1px solid rgb(var(--color-light-gray-4));\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n  ._6Zzj6 {\n    cursor: pointer;\n  }\n\n  ._6Zzj6 .bp3-button.bp3-minimal {\n      min-height: 0;\n    }\n\n  /* postcss-bem-linter: ignore */\n\n  ._6Zzj6 > svg {\n      color: rgb(var(--color-dark-gray-3));\n    }\n\n  ._6Zzj6:last-child {\n    margin-right: 11px;\n  }\n\n  ._6Zzj6:hover > svg {\n      color: rgb(var(--color-gray-1));\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommandCenterHierarchyPanelToolbar": "D2a1h",
	"CommandCenterHierarchyPanelToolbar_iconButton": "_6Zzj6"
};
export default ___CSS_LOADER_EXPORT___;
