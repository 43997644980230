// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".F3Xe\\+ {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n  .RJTmG {\n    height: 100%;\n    max-height: 100vh;\n    margin-bottom: 20px;\n  }\n\n  .\\+iQ1b {\n    border: none;\n  }\n\n.F3Xe\\+.ag-theme-alpine .ag-root-wrapper {\n    border: 0;\n  }\n\n.F3Xe\\+.ag-theme-alpine .ag-row {\n    cursor: pointer;\n  }\n\n._8ila7 {\n    border: 1px solid rgb(var(--color-light-gray-3));\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n\n.Ty3E4 {\n    margin-bottom: 10px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PublishedTable": "F3Xe+",
	"PublishedTable_tablesGrid": "RJTmG",
	"PublishedTable_noData": "+iQ1b " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-no-data"] + "",
	"PublishedTable_grid": "_8ila7",
	"PublishedTable_headingContainer": "Ty3E4"
};
export default ___CSS_LOADER_EXPORT___;
