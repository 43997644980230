// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QXpoh {\n  display: flex;\n}\n\n  .fgEFD {\n    width: auto;\n    height: 100%;\n  }\n\n  .SGj4c {\n    width: 200px;\n    padding: 10px;\n    margin-bottom: 20px;\n    border: 0;\n    border-bottom: 1px solid black;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritorySearchDialog": "QXpoh",
	"TerritorySearchDialog_territorySearch": "fgEFD",
	"TerritorySearchDialog_searchInput": "SGj4c"
};
export default ___CSS_LOADER_EXPORT___;
