import { useEffect, useState } from 'react';

import {
  LazyQueryExecFunction,
  // eslint-disable-next-line no-restricted-imports
  useLazyQuery
} from '@apollo/client';

import { usePlanTargets } from 'app/contexts/planTargetsProvider';

import { POLLING_INTERVAL, TDA_STOP_POLLING_TIME } from 'app/global/variables';

import { GetJobProgress, GetJobProgressVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_JOB_PROGRESS } from 'app/graphql/queries/getJobProgress';

import { JobStatus, LongRunningJob } from 'app/models';

export const useGetTDAJobProgress = (
  jobDetail: LongRunningJob
): {
  getJobProgressForTopdownAllocation: LazyQueryExecFunction<GetJobProgress, GetJobProgressVariables>;
  data: GetJobProgress;
} => {
  const { topdownAllocationJobStatusLookupMap, setTopdownAllocationJobStatusLookupMap } = usePlanTargets();
  const [currentJobStatus, setCurrentJobStatus] = useState(null);

  let quotaComponentId;
  let battlecardId;

  if (jobDetail && 'battlecardId' in jobDetail && 'quotaComponentId' in jobDetail) {
    battlecardId = jobDetail.battlecardId;
    quotaComponentId = jobDetail.quotaComponentId;
  }

  // Set a timeout for 90 seconds
  const timeoutId = setTimeout(() => {
    // Check if the status is still pending after 90 seconds
    if (currentJobStatus === JobStatus.PENDING) {
      // Update the status to failed
      updateTDAStatusLookUpMap(JobStatus.FAILED);
      stopPolling();
    }
  }, TDA_STOP_POLLING_TIME);

  // Clear the timeout if the query completes before 90 seconds
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const updateTDAStatusLookUpMap = (newJobStatus) => {
    const existingJobIndex = topdownAllocationJobStatusLookupMap.findIndex((job) => {
      return job.battlecardId === battlecardId && job.quotaComponentId === quotaComponentId;
    });

    if (existingJobIndex !== -1 && topdownAllocationJobStatusLookupMap[existingJobIndex].jobStatus !== newJobStatus) {
      const updatedTDAJobStatus = [...topdownAllocationJobStatusLookupMap];
      updatedTDAJobStatus[existingJobIndex] = { ...updatedTDAJobStatus[existingJobIndex], jobStatus: newJobStatus };

      setTopdownAllocationJobStatusLookupMap(updatedTDAJobStatus);
    }
  };

  const handleTDAJobProgress = (allocateTopDownJobStatus) => {
    if (!allocateTopDownJobStatus) {
      stopPolling();
    }
    setCurrentJobStatus((prevState) => {
      if (prevState !== allocateTopDownJobStatus) {
        updateTDAStatusLookUpMap(allocateTopDownJobStatus);
        return allocateTopDownJobStatus;
      }
      return prevState;
    });
    if (allocateTopDownJobStatus === JobStatus.COMPLETED || allocateTopDownJobStatus === JobStatus.FAILED) {
      stopPolling();
    }
  };

  const [getJobProgressForTopdownAllocation, { data, stopPolling }] = useLazyQuery<
    GetJobProgress,
    GetJobProgressVariables
  >(GET_JOB_PROGRESS, {
    pollInterval: POLLING_INTERVAL,
    fetchPolicy: 'network-only',
    onCompleted(response) {
      // handle job progress for request top down allocation
      const allocateTopDownJobStatus = response?.getJobProgress?.allocateTopDownJobOutput?.status;
      handleTDAJobProgress(allocateTopDownJobStatus);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      if (battlecardId && quotaComponentId) {
        updateTDAStatusLookUpMap(JobStatus.FAILED);
      }
    }
  });
  return { getJobProgressForTopdownAllocation, data };
};
