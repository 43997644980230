import React from 'react';

import { Edit, TrashCan, AddAlt, Flash } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';
import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { PillLevel, TerritoryGroupDialogModes, TerritoryOptimizationStep } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryHoverToolbar.module.pcss';

const b = block(style);

interface TerritoryHoverToolbarProps {
  pillLevel: PillLevel;
  territoryGroupId: string;
  shouldShowOptimize?: boolean;
}

const TerritoryHoverToolbar: React.FC<TerritoryHoverToolbarProps> = ({
  pillLevel,
  territoryGroupId,
  shouldShowOptimize
}: TerritoryHoverToolbarProps) => {
  const { openTdrDialog } = useTerritoryDefineAndRefine();

  const [isTerritoryOptimizationOn] = useTreatment(SplitFeatures.TERRITORY_OPTIMIZATION);
  const { setOptimizationTargetTgId, setTerritoryOptimizationDrawerState } = useTerritoryOptimization();

  const handleClick = (event) => {
    event.stopPropagation();
  };
  return (
    <div className={b()} onClick={handleClick}>
      {pillLevel === PillLevel.TERRITORY && (
        <>
          <div>
            <IconButton
              type="button"
              icon={<Edit />}
              testId="territoryHoverToolbar-edit"
              title={formatMessage('EDIT_TERRITORY_GROUP')}
              onClick={() => openTdrDialog({ territoryGroupId, mode: TerritoryGroupDialogModes.EDIT_GROUP })}
            />
          </div>
          <IconButton
            type="button"
            icon={<TrashCan />}
            testId="territoryHoverToolbar-delete"
            title={formatMessage('DELETE_TERRITORY_GROUP')}
            onClick={() => openTdrDialog({ territoryGroupId, mode: TerritoryGroupDialogModes.DELETE_GROUP })}
          />
        </>
      )}
      <IconButton
        type="button"
        icon={<AddAlt />}
        testId="territoryHoverToolbar-add"
        title={formatMessage('ADD_TERRITORY_GROUP')}
        onClick={() => openTdrDialog({ territoryGroupId, mode: TerritoryGroupDialogModes.CREATE_GROUP })}
      />
      {isTerritoryOptimizationOn && pillLevel === PillLevel.TERRITORY && shouldShowOptimize && (
        <IconButton
          type="button"
          icon={<Flash />}
          testId="territoryHoverToolbar-territory-optimization"
          title={formatMessage('TERRITORY_OPTIMIZATION')}
          onClick={() => {
            setOptimizationTargetTgId(territoryGroupId);
            setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.START);
          }}
        />
      )}
    </div>
  );
};

export default TerritoryHoverToolbar;
