// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable no-descending-specificity */\n.T0NgX {\n    --table-header-color: rgb(248, 248, 248);\n    --table-border-color: rgb(216, 216, 216);\n  }\n.T0NgX .bp3-dialog {\n      width: 680px;\n      padding-bottom: 0;\n    }\n.T0NgX .bp3-menu {\n      width: auto !important;\n    }\n.BUBOG {\n    display: flex;\n    justify-content: center;\n    height: 140px;\n  }\n.ep09W {\n    padding-bottom: var(--m);\n  }\n.XMcz3 {\n    font-weight: unset;\n    margin-bottom: 1rem;\n  }\n.XMcz3 label {\n    font-weight: unset;\n  }\n.jTGwo {\n    display: flex;\n  }\n.FMz3Z {\n    color: rgb(var(--color-red-3));\n    padding: var(--xl) 0;\n  }\n.N3Bw3 {\n  }\n.iYVTN {\n    max-height: 320px;\n    border-radius: 8px;\n    overflow-y: auto;\n    border: 1px solid var(--table-border-color);\n  }\n.Fe0pN {\n    width: 100%;\n    border-radius: 8px;\n  }\n.Fe0pN th, .Fe0pN td {\n      font-weight: unset;\n      padding: 4px 16px;\n    }\n.Fe0pN tr {\n      height: var(--default-cell-height);\n      border-bottom: 1px solid var(--table-border-color);\n    }\n.tizhM tr:last-child {\n      border-bottom: none;\n    }\n.aFZBX {\n    background-color: var(--table-header-color);\n    position: sticky;\n    top: 0;\n    z-index: 2;\n    white-space: nowrap;\n  }\n.vJF54 {\n    width: 220px;\n  }\n._01iYw:empty {\n    height: 32px;\n  }\n._8Fy-j {\n    text-align: right;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StandardGeoCatalogModal_dialog": "T0NgX",
	"StandardGeoCatalogModal_loadingBlock": "BUBOG",
	"StandardGeoCatalogModal_description": "ep09W",
	"StandardGeoCatalogModal_countryDropdownLabel": "XMcz3",
	"StandardGeoCatalogModal_countryDropdownWrapper": "jTGwo",
	"StandardGeoCatalogModal_errorMessage": "FMz3Z",
	"StandardGeoCatalogModal_visuallyHidden": "N3Bw3 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["visually-hidden"] + "",
	"StandardGeoCatalogModal_tableContainer": "iYVTN",
	"StandardGeoCatalogModal_table": "Fe0pN",
	"StandardGeoCatalogModal_tableBody": "tizhM",
	"StandardGeoCatalogModal_tableHeaderRow": "aFZBX",
	"StandardGeoCatalogModal_dropdownCell": "vJF54",
	"StandardGeoCatalogModal_dropdownContainer": "_01iYw",
	"StandardGeoCatalogModal_removeCell": "_8Fy-j"
};
export default ___CSS_LOADER_EXPORT___;
