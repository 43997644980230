import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Dialog } from '@blueprintjs/core';

import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './BalancingPanel.module.pcss';

const b = block(style);

interface BattleCardChangeDialogProps {
  onClose: () => void;
  onConfirm: () => void;
}

const BattleCardChangeDialog: React.FC<BattleCardChangeDialogProps> = ({
  onClose,
  onConfirm
}: BattleCardChangeDialogProps): JSX.Element => {
  return (
    <Dialog isOpen portalClassName={b('')} onClose={onClose}>
      <div data-testid="battlecard-change-dialog">
        <h4 className={b('dialogTitle')}>{formatMessage('UNSAVED_CHANGES')}</h4>
        <p className={b('battleCardChangeBody')}>{formatMessage('BALANCING_MODAL_BATTLECARD_BODY')}</p>
        <div className={b('footer')}>
          <div className={b('buttons')}>
            <TextButton
              text={formatMessage('DONT_SAVE')}
              type="button"
              intent="danger"
              testId="cancel-button"
              onClick={onClose}
            />
            <TextButton testId="switch-button" text={formatMessage('SAVE')} type="submit" onClick={onConfirm} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default BattleCardChangeDialog;
