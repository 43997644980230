// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MVSfK {\n  display: flex;\n  align-items: center;\n}\n\n  .XKLFP {\n    padding-right: 5px;\n  }\n\n  .Vr\\+uX {\n      fill: rgb(var(--color-blue-3));\n    }\n\n  .a9\\+65 {\n      fill: rgb(var(--color-lime-2));\n    }\n\n  .z9HZ5 {\n      fill: rgb(var(--color-indigo-3));\n    }\n\n  .Me7cQ {\n      fill: rgb(var(--color-dark-gray-3));\n    }\n\n  .OX-EE {\n      fill: rgb(var(--color-red-3));\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StatusCellRenderer": "MVSfK",
	"StatusCellRenderer_statusIcon": "XKLFP",
	"StatusCellRenderer_statusIcon__inProgress": "Vr+uX",
	"StatusCellRenderer_statusIcon__approved": "a9+65",
	"StatusCellRenderer_statusIcon__submitted": "z9HZ5",
	"StatusCellRenderer_statusIcon__notStarted": "Me7cQ",
	"StatusCellRenderer_statusIcon__rejected": "OX-EE"
};
export default ___CSS_LOADER_EXPORT___;
