import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';
import { Add, Edit, TrashCan } from '@carbon/icons-react';

import ConfirmDeleteModal from 'components/Dialog/ConfirmDeleteModal/ConfirmDeleteModal';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import buildMeasureColumnDef from 'app/components/AdvancedGrid/GridHelpers/Measures/MeasureColumnDef';
import CustomMeasureDialog from 'app/components/CustomMeasureDialog/CustomMeasureDialog';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { handleError } from 'app/graphql/handleError';
import { DELETE_CUSTOM_MEASURE } from 'app/graphql/mutations/deleteCustomMeasure';

import useShowToast from 'app/hooks/useShowToast';

import { PlanMeasureType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MeasuresContent.module.pcss';

const b = block(style);

const MeasuresContent: React.FC = () => {
  const { measuresData, measuresLoading, getMeasures } = usePlanTargets();
  const { selectedTenant, selectedPlanningCycle } = useScope();
  const { setShouldRefetchBattleCardDataImmediately } = useBattleCard();
  const showToast = useShowToast();

  const gridContainerRefTop = React.useRef(null);
  const gridContainerRefBottom = React.useRef(null);

  const [deleteMeasure, { loading: queryLoading }] = useMutation(DELETE_CUSTOM_MEASURE, {
    onCompleted() {
      getMeasures(selectedPlanningCycle?.id);
      setShouldRefetchBattleCardDataImmediately(true);
      setShowDeleteModal(false);
      showToast(formatMessage('DELETE_CUSTOM_MEASURE_SUCCESS'), 'success');
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('DELETE_CUSTOM_MEASURE_FAILURE'), 'danger');
      setShowDeleteModal(false);
    }
  });

  useEffect(() => {
    if (selectedPlanningCycle?.id) {
      getMeasures(selectedPlanningCycle?.id);
    }
  }, [selectedPlanningCycle?.id]);

  const [showCustomMeasureDialog, setShowCustomMeasureDialog] = useState(false);
  const [upsertLoading, setUpsertLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [measureId, setMeasureId] = useState<number>(null);
  const [measureName, setMeasureName] = useState<string>('');

  const getMeasureGridData = (type: PlanMeasureType) => {
    if (!measuresData?.length) {
      return null;
    }
    return JSON.stringify(measuresData.filter((measure) => measure.measureType === type));
  };

  const handleUpsertClick = (id) => {
    setMeasureId(id);
    setShowCustomMeasureDialog(true);
  };

  const handleDeleteClick = (id, name) => {
    setMeasureId(id);
    setMeasureName(name);
    setShowDeleteModal(true);
  };

  const ActionItems = (params) => {
    return (
      <>
        {params.data.measureType === PlanMeasureType.CUSTOM && (
          <div className={b('actionItems')}>
            <div className={b('iconHolder')}>
              <Edit
                className={b('editIcon')}
                data-testid="edit-icon"
                aria-label={formatMessage('EDIT')}
                onClick={(e) => {
                  e.stopPropagation();
                  handleUpsertClick(params.data.measureId);
                }}
              />
            </div>
            <div className={b('iconHolder')}>
              <TrashCan
                className={b('deleteIcon')}
                data-testid="delete-icon"
                aria-label={formatMessage('DELETE')}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(params.data.measureId, params.data.measureName);
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={b()} data-testid="measures-content">
      <div className={b('container')} ref={gridContainerRefTop}>
        <header className={b('tableHeader')}>
          <h5 data-testid="builtin-measures-title" className={b('tableTitle')}>
            {formatMessage('BUILT_IN_MEASURES')}
          </h5>
        </header>
        {(measuresLoading || measuresData?.length > 0) && (
          <AdvancedGrid
            columnDefs={buildMeasureColumnDef(!!measuresData?.length, PlanMeasureType.BUILTIN, ActionItems)}
            data={getMeasureGridData(PlanMeasureType.BUILTIN)}
            noDataMessage={formatMessage('EMPTY_GRID')}
            data-testid="builtin-measures-grid"
            gridWidth={gridContainerRefTop?.current?.offsetWidth}
            gridHeight={gridContainerRefTop?.current?.offsetHeight}
            showGridLoading={measuresLoading}
          />
        )}
      </div>
      <br />
      <div className={b('container')} ref={gridContainerRefBottom}>
        <header className={b('tableHeader')}>
          <h5 data-testid="custom-measures-title" className={b('tableTitle')}>
            {formatMessage('CUSTOM_MEASURES')}
          </h5>
          <div className={b('tableAction')}>
            <Add
              size={32}
              className={b('addMeasure')}
              data-testid="add-custom-measure"
              onClick={() => {
                handleUpsertClick(null);
              }}
            />
          </div>
        </header>
        {(measuresLoading || queryLoading || upsertLoading || measuresData?.length > 0) && (
          <AdvancedGrid
            columnDefs={buildMeasureColumnDef(!!measuresData?.length, PlanMeasureType.CUSTOM, ActionItems)}
            data={getMeasureGridData(PlanMeasureType.CUSTOM)}
            noDataMessage={formatMessage('EMPTY_GRID')}
            data-testid="custom-measures-grid"
            gridWidth={gridContainerRefBottom?.current?.offsetWidth}
            gridHeight={gridContainerRefBottom?.current?.offsetHeight}
            showGridLoading={measuresLoading || queryLoading || upsertLoading}
          />
        )}
      </div>
      <CustomMeasureDialog
        isOpen={showCustomMeasureDialog}
        setUpsertLoading={setUpsertLoading}
        measureId={measureId}
        setShowCustomMeasureDialog={setShowCustomMeasureDialog}
      />
      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        isSubmitting={queryLoading}
        onCancel={() => setShowDeleteModal(false)}
        onConfirmDelete={(e) => {
          e.stopPropagation();
          deleteMeasure({
            variables: {
              tenantId: selectedTenant?.id,
              measureId
            },
            awaitRefetchQueries: true
          });
        }}
        bodyText={formatMessage('DELETE_VALUE', { value: measureName })}
      />
    </div>
  );
};

export default MeasuresContent;
