import React from 'react';

import { withSplitFactory } from '@splitsoftware/splitio-react';
import '@symon-ai/wisepipe-components/dist/main.min.css';
import '@symon-ai/wisepipe-components/dist/variables.css';
import '@symon-ai/wisepipe-imports/dist/main.min.css';
import '@varicent/components/dist/main.css';
import { BrowserRouter } from 'react-router-dom';

import { EmbeddedMap } from 'app/components/TerritoryMap/EmbeddedMap/EmbeddedMap';

import EmbeddedAuthRouter from 'app/containers/App/EmbeddedAuth/EmbeddedAuthRouter';
import NativeProviders from 'app/containers/App/NativeProviders/NativeProviders';
import { RoutePaths } from 'app/containers/App/Router/routePaths';

import 'react-datepicker/dist/react-datepicker.css';
import { config } from 'utils/config';
import { initializeAnalytics } from 'utils/helpers/initializeAnalytics';
import { getUserConfirmation } from 'utils/helpers/routingHelpers';

import './app.pcss';
import Auth0ConnectorWrapper from './Auth0ConnectorWrapper';
import GlobalErrorBoundary from './components/GlobalErrorBoundary/GlobalErrorBoundary';
import EmbeddedErrorBoundary from './components/TerritoryMap/EmbeddedMap/EmbeddedErrorBoundary';
import { EmbeddedProviders } from './containers/App/EmbeddedProviders/EmbeddedProviders';
import { AuthRoutes } from './containers/App/Router/AuthRoutes';
import { LockRoute } from './containers/App/Router/LockRoute';
import { NativeRoutes } from './containers/App/Router/NativeRoutes';
import { SharedProviders } from './containers/App/SharedProviders/SharedProviders';
import './global/variables.pcss';

initializeAnalytics();

const App: React.FC = () => (
  <BrowserRouter getUserConfirmation={getUserConfirmation}>
    <EmbeddedAuthRouter>
      <Auth0ConnectorWrapper>
        <GlobalErrorBoundary>
          <SharedProviders>
            <LockRoute
              path={`${RoutePaths.EMBEDDED_MAP}/:tenantSlug`}
              onceMatched={
                <EmbeddedErrorBoundary>
                  <EmbeddedProviders>
                    <EmbeddedMap />
                  </EmbeddedProviders>
                </EmbeddedErrorBoundary>
              }
              fallback={
                <NativeProviders>
                  <AuthRoutes>
                    <NativeRoutes />
                  </AuthRoutes>
                </NativeProviders>
              }
            />
          </SharedProviders>
        </GlobalErrorBoundary>
      </Auth0ConnectorWrapper>
    </EmbeddedAuthRouter>
  </BrowserRouter>
);

export default withSplitFactory({
  core: {
    authorizationKey: config.SPLITIO_API_KEY_CLIENT,
    key: 'key'
  }
})(App);
