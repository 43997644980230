import React, { useMemo, useState, useEffect } from 'react';

import { FieldProps } from 'formik';
import get from 'lodash.get';
import CurrencyInput from 'react-currency-input-field';

import { useLocalization } from 'app/contexts/localizationProvider';

import block from 'utils/bem-css-modules';
import { getFormattedCurrencyInputLocaleConfig, numberUnformatter } from 'utils/helpers/formNumberFormattingUtils';
import { getLocale } from 'utils/helpers/getLocale';

import style from './FormattedCurrencyInput.module.pcss';

const b = block(style);

interface FormattedCurrencyInputProps extends FieldProps {
  localCurrencyCode?: string;
  label?: string;
  disabled?: boolean;
  allowDecimals?: boolean;
  allowNegativeValue?: boolean;
  placeholder?: string;
  decimalsLimit?: number;
  fixedDecimalLength?: number;
  showErrors?: boolean;
  showErrorStyle?: boolean;
  error?: string;
  maxDigit?: number;
  isRequired?: boolean;
  requireTouchForError?: boolean;
  maxLength?: number;
  useDefaultIntlFormatting?: boolean;
  hideCurrencySymbol?: boolean;
}

export const FormattedCurrencyInput: React.FC<FormattedCurrencyInputProps> = ({
  localCurrencyCode,
  field: { name, value }, // value is expected to be a number or null
  form: { touched, errors, status, setFieldValue, setFieldTouched },
  placeholder = '',
  disabled = false,
  allowDecimals = true,
  allowNegativeValue = false,
  decimalsLimit = 2,
  maxLength = 15,
  useDefaultIntlFormatting = true,
  hideCurrencySymbol = false, // requires useDefaultIntlFormatting to be false
  showErrors = true,
  showErrorStyle = false,
  requireTouchForError = true
}: FormattedCurrencyInputProps) => {
  const { defaultReportingCurrency } = useLocalization();

  // if a localCurrencyCode is provided, it takes precedence over the defaultReportingCurrency
  const currency = localCurrencyCode || defaultReportingCurrency;

  const [currentValue, setCurrentValue] = useState<string | number>(
    isNaN(value) || value === null ? '' : parseFloat(value).toFixed(decimalsLimit)
  );

  // if a new value is ever passed in directly, set the current value
  useEffect(() => {
    setCurrentValue(isNaN(value) || value === null ? '' : parseFloat(value).toFixed(decimalsLimit));
  }, [value]);

  // when using the react-currency-input-field's default Intl formatting (which occurs when the intlConfig prop is
  // successfully passed to the CurrencyInput with a locale + currency) there are some limitations on how locale-based parameters can be
  // overridden - to workaround these limitations (and be able to customize the input for our use cases while ensuring proper localization
  // is achieved) we can "override" the default Intl formatting behaviour by: 1. setting useDefaultIntlFormatting to false (which ensures the intlConfig
  // prop provided to the CurrencyInput is null) 2. extracting the locale-specific parameters we want from our localeConfig (which we create here),
  // and 3. setting or "overriding" these extracted locale-specific parameters in their respective props (e.g. prefix, suffix, groupSeparator, decimalSeparator)
  const localeConfig = useMemo(
    () => getFormattedCurrencyInputLocaleConfig({ locale: getLocale(), currency }),
    [currency]
  );

  const handleOnValueChange = (newValue: string | undefined): void => {
    setCurrentValue(newValue);
  };

  const handleOnBlur = (): void => {
    const formattedNumber = !!currentValue ? currentValue : 0;
    const unformattedNumber = numberUnformatter(formattedNumber.toString()).toFixed(decimalsLimit);

    // the value set in Formik is always a number
    setFieldValue(name, parseFloat(unformattedNumber));
    setCurrentValue(unformattedNumber);
  };

  let errorMessage = '';
  const isTouchedAndError = !!(get(touched, name) && get(errors, name));

  if (isTouchedAndError || !requireTouchForError) {
    errorMessage = get(errors, name, '');
  } else if (status && Object.keys(status).length > 0) {
    errorMessage = get(status, name, '');
  }

  const shouldShowError = !!errorMessage;

  return (
    <div onBlur={() => setFieldTouched(name)} data-testid={`formatted-currency-input-${name}-container`}>
      <CurrencyInput
        className={b('currencyInput', { disabled, danger: shouldShowError && showErrorStyle })}
        intlConfig={useDefaultIntlFormatting ? { locale: getLocale(), currency } : null}
        allowDecimals={allowDecimals}
        allowNegativeValue={allowNegativeValue}
        placeholder={placeholder}
        decimalsLimit={decimalsLimit}
        prefix={hideCurrencySymbol ? '' : localeConfig.prefix}
        suffix={hideCurrencySymbol ? '' : localeConfig.suffix}
        groupSeparator={localeConfig.groupSeparator}
        decimalSeparator={localeConfig.decimalSeparator}
        value={currentValue}
        disabled={disabled}
        maxLength={maxLength}
        onValueChange={(newValue) => handleOnValueChange(newValue)}
        onBlur={() => handleOnBlur()}
        data-testid={`formatted-currency-input-${name}`}
      />
      {showErrors ? (
        <div className={b('validationMessage')} data-testid={`formatted-currency-input-${name}-error-message`}>
          {errorMessage}
        </div>
      ) : null}
    </div>
  );
};

export default FormattedCurrencyInput;
