// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iyLCb {\n  display: block;\n  width: 100%;\n}\n\n  .DvtI5 {\n    margin: 0 var(--l);\n    height: calc(100% - 42px);\n  }\n\n  .E-ki- {\n      margin: 0;\n    }\n\n  .n\\+L4Z {\n      border-top: 1px solid rgb(var(--color-light-gray-4));\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryOptimizationDrawer": "iyLCb",
	"TerritoryOptimizationDrawer_container": "DvtI5",
	"TerritoryOptimizationDrawer_container__preview": "E-ki-",
	"TerritoryOptimizationDrawer_container__showBorder": "n+L4Z"
};
export default ___CSS_LOADER_EXPORT___;
