import gql from 'graphql-tag';

import { createUseLazyQuery, createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetBattleCard, GetBattleCardVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_BATTLECARD = gql`
  query GetBattleCard($battlecardId: Int!, $deploymentModelId: Int!, $isTQM: Boolean) {
    getDeploymentModelSpec(
      input: { battlecardId: $battlecardId, deploymentModelId: $deploymentModelId, isTQM: $isTQM }
    ) {
      battlecards {
        battlecardId
        battlecardName
        battlecardParentId
        battlecardType
        battlecardInfoLevel
        children
        conversionRate
        currencyHierarchyId
        invalidConversionReason
        localCurrencyCode
        localCurrencyName
        mapLevel
        reportingCurrencyCode
        rollupConversionRate
        territoryRules {
          ruleId
          territoryId
        }
        territoryGroups {
          territoryGroupId
          territoryGroupName
        }
        territoryGroupTypes {
          hierarchyAlias
          hierarchyRootName
          hierarchyTopId
          hierarchyTopName
          hierarchyType
          precedence
          sheets {
            sheetId
            sheetName
          }
          territoryGroupId
          territoryGroupOwnerEmailAddress
          territoryGroupOwnerEmployeeId
          territoryGroupOwnerFirstName
          territoryGroupOwnerId
          territoryGroupOwnerLastName
          territoryGroupOwnerMemberId
          territoryGroupTypeHierarchyId
        }
        files {
          fileId
          fileName
        }
        owner {
          battlecardOwnerId
          emailAddress
          employeeId
          firstName
          lastName
          memberId
        }
        quotaDistributionHierarchies {
          hierarchyRootId
          isAllocationTarget
        }
      }
    }
  }
`;

export const useGetBattlecard = createUseQuery<GetBattleCard, GetBattleCardVariables>(GET_BATTLECARD);
export const useGetBattlecardLazy = createUseLazyQuery<GetBattleCard, GetBattleCardVariables>(GET_BATTLECARD);
