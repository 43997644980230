import { ColDef } from '@ag-grid-community/core';

import ConfigurationActionsCellRenderer from 'app/components/DataPanel/ConfigurationsPanel/ConfigurationActionsCellRenderer';

import { ConfigurationGridData, GridHeaders } from 'app/models';

export const buildConfigurationsGridColumnDef = (
  FormatDate: (data: ConfigurationGridData) => JSX.Element,
  StatusCellRenderer: (data: ConfigurationGridData) => JSX.Element,
  handleDeleteConfiguration: (data: ConfigurationGridData, onDeleteConfigurationComplete: () => void) => void,
  handleCancelConfiguration: (data: ConfigurationGridData, onCancelConfigurationComplete: () => void) => void
): ColDef[] => {
  const columnDefs: ColDef[] = [];

  columnDefs.push({
    headerName: GridHeaders.PIPE,
    field: 'pipeName',
    flex: 2
  });

  columnDefs.push({
    headerName: GridHeaders.EXPORT_NODE,
    field: 'exportName',
    flex: 2
  });

  columnDefs.push({
    headerName: GridHeaders.TYPE,
    field: 'fileType',
    flex: 2
  });

  columnDefs.push({
    headerName: GridHeaders.LAST_RUN,
    field: 'endAt',
    flex: 2,
    cellRendererFramework: FormatDate
  });

  columnDefs.push({
    headerName: GridHeaders.STATUS,
    field: 'status',
    flex: 2,
    cellRendererFramework: StatusCellRenderer
  });

  columnDefs.push({
    flex: 1,
    cellRendererFramework: ConfigurationActionsCellRenderer,
    cellRendererParams: {
      onDeleteConfigurationClicked: handleDeleteConfiguration,
      onCancelConfigurationClicked: handleCancelConfiguration
    }
  });

  return columnDefs;
};
