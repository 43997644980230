// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MCKSg {\n  width: 100%;\n}\n  .h4FJn {\n    display: flex;\n    list-style-type: none;\n    margin: 0 4px;\n    padding: 0;\n    font-size: 12px;\n    font-weight: 500;\n    line-height: normal;\n    color: rgb(var(--color-gray-1));\n  }\n  .naOLG {\n      padding-bottom: 10px;\n      padding-top: 6px;\n    }\n  ._6xKqw {\n    display: flex;\n    list-style-type: none;\n    margin: 0 4px;\n    padding: 0;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: normal;\n    color: #52515d;\n  }\n  .woY1r {\n      padding: 10px 0;\n      overflow-x: scroll;\n      max-width: 840px;\n      width: 100%;\n    }\n  .x4zCP {\n    display: flex;\n    flex-direction: column\n  }\n  .x4zCP:not(:last-child) {\n      padding-right: 20px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BattleCardPanelPage": "MCKSg",
	"BattleCardPanelPage_lineItems": "h4FJn",
	"BattleCardPanelPage_lineItems__isRootCard": "naOLG",
	"BattleCardPanelPage_lineItemsBattleCardRefactor": "_6xKqw",
	"BattleCardPanelPage_lineItemsBattleCardRefactor__isRootCard": "woY1r",
	"BattleCardPanelPage_columnWrapper": "x4zCP"
};
export default ___CSS_LOADER_EXPORT___;
