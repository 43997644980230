// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6m8yQ {\n    display: inline-flex;\n    justify-content: left;\n    align-items: center;\n    font-weight: normal;\n    font-size: 14px;\n    width: 100%;\n    color: rgb(var(--color-dark-gray-3));\n  }\n  .jNptb {\n    margin-left: 10px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LookupInfoHeaderRenderer_label": "_6m8yQ",
	"LookupInfoHeaderRenderer_infoIcon": "jNptb"
};
export default ___CSS_LOADER_EXPORT___;
