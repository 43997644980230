import { Dispatch, SetStateAction } from 'react';

import { GridFields, SelectedTerritory, TerritoryGridRow } from 'app/models';

const dataTrayHelper = {
  onShowActivities: (
    rowData: TerritoryGridRow | Array<Record<string, unknown>>,
    setSelectedTerritory: Dispatch<SetStateAction<SelectedTerritory>>,
    setShowActivities: Dispatch<SetStateAction<boolean>>
  ): void => {
    if (rowData) {
      setSelectedTerritory({
        territoryId: rowData[GridFields.TERRITORY_ID],
        ruleId: rowData[GridFields.RULE_ID],
        territoryGroupId: rowData[GridFields.TERRITORY_GROUP_ID]
      });
    }
    setShowActivities(true);
  },
  onEditTerritory: (
    rowData: TerritoryGridRow,
    setSelectedRowData: Dispatch<SetStateAction<unknown>>,
    setShowUpsertTerritoryRuleView: Dispatch<SetStateAction<boolean>>
  ): void => {
    setSelectedRowData(rowData);
    setShowUpsertTerritoryRuleView(true);
  }
};

export default dataTrayHelper;
