import gql from 'graphql-tag';

export const GET_LOOKUPS_FOR_PLANNING_CYCLE = gql`
  query GetLookupsForPlanningCycle($planningCycleId: Int!) {
    getPlanningCycleSpec(input: { planningCycleId: $planningCycleId }) {
      planningCycleId
      lookups {
        fileId
        lookupId
        lookupType
        metadata {
          tableData
          tableSchema
        }
      }
    }
  }
`;
