export const addUnique = <ItemType>(array: ItemType[], itemToAdd: ItemType): ItemType[] => {
  if (array.includes(itemToAdd)) return array;
  return [...array, itemToAdd];
};

export const removeUnique = <ItemType>(array: ItemType[], itemToRemove: ItemType): ItemType[] => {
  const arrayWithItemRemoved = array.filter((i) => i !== itemToRemove);
  if (arrayWithItemRemoved.length === array.length) return array;
  return arrayWithItemRemoved;
};

export const setInArray = <ItemType>(
  array: ItemType[],
  itemToToggle: ItemType,
  shouldBeInArray: boolean
): ItemType[] => (shouldBeInArray ? addUnique(array, itemToToggle) : removeUnique(array, itemToToggle));

export const calculateSetDifferences = <ItemType>(prev: ReadonlySet<ItemType>, next: ReadonlySet<ItemType>) => {
  const removed = new Set<ItemType>();
  for (const prevItem of prev) {
    if (!next.has(prevItem)) removed.add(prevItem);
  }
  const added = new Set<ItemType>();
  for (const nextItem of next) {
    if (!prev.has(nextItem)) added.add(nextItem);
  }
  return { added, removed };
};

export const toggleItemsAsSet = <ItemType>(prevSet: ReadonlySet<ItemType>, itemsToToggle: ReadonlyArray<ItemType>) => {
  const nextSet = new Set(prevSet);
  for (const item of itemsToToggle) nextSet.add(item);

  // One or more items toggled on
  if (nextSet.size !== prevSet.size) return nextSet;

  // All are already toggled on, so toggle them off
  for (const item of itemsToToggle) nextSet.delete(item);

  return nextSet;
};
