import React, { SyntheticEvent } from 'react';

import { Intent } from '@blueprintjs/core';

import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './DialogFooter.module.pcss';

const b = block(style);

export interface DialogFooterProps {
  showConfirm?: boolean;
  showCancel?: boolean;
  disableConfirm?: boolean;
  confirmButtonText?: string;
  confirmButtonIntent?: Intent;
  confirmButtonLoading?: boolean;
  confirmButtonIcon?: JSX.Element;
  disableCancel?: boolean;
  cancelButtonText?: string;
  extraActions?: JSX.Element;
  onClose?: (event: SyntheticEvent<HTMLElement>) => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}

const DialogFooter: React.FC<DialogFooterProps> = ({
  confirmButtonText = formatMessage('COMPLETE'),
  confirmButtonIntent = Intent.PRIMARY,
  cancelButtonText = formatMessage('CANCEL'),
  extraActions,
  onClose,
  onSubmit,
  disableCancel = false,
  disableConfirm = false,
  confirmButtonLoading,
  confirmButtonIcon,
  showConfirm = true,
  showCancel = true
}: DialogFooterProps) => {
  return (
    <div className={b()} data-testid="dialog-footer">
      <div className={b('buttons')}>
        {showCancel && (
          <div className={b('button')}>
            <TextButton
              testId={'dialog-cancel-button'}
              text={cancelButtonText}
              type="button"
              onClick={onClose}
              disabled={disableCancel}
              minimal
            />
          </div>
        )}
        {extraActions}
        {showConfirm && (
          <div className={b('button')}>
            <TextButton
              testId={'dialog-confirm-button'}
              text={confirmButtonText}
              type="submit"
              intent={confirmButtonIntent}
              onClick={onSubmit}
              disabled={disableConfirm}
              loading={confirmButtonLoading}
              icon={confirmButtonIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DialogFooter;
