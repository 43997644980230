const getIsWithinIframe = () => {
  try {
    return window.self !== window.top;
  } catch {
    // We assume if the window objects or similar are unavialble we are effectively not embedded
    return false;
  }
};

export const isWithinIframe = getIsWithinIframe();

const getOriginOfOuterFrame = () => {
  if (!isWithinIframe) return null;
  if (!document.referrer) return null;
  try {
    const embedderUrl = new URL(document.referrer);
    return embedderUrl.origin;
  } catch {
    return null;
  }
};

export const originOfOuterFrame = getOriginOfOuterFrame();
