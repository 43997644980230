import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetCustomerAggregatePins, GetCustomerAggregatePinsVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_CUSTOMER_AGGREGATE_PINS = gql`
  query GetCustomerAggregatePins($input: TGTAggregationInput!, $includeCount: Boolean!) {
    getTGTAggregate(input: $input) {
      numberOfAccounts @include(if: $includeCount)
      pinsCompressed {
        compressed
        compressedType
      }
    }
  }
`;

export const useGetCustomerAggregatePins = createUseQuery<GetCustomerAggregatePins, GetCustomerAggregatePinsVariables>(
  GET_CUSTOMER_AGGREGATE_PINS
);
