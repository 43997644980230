import React, { useEffect, useState } from 'react';

import { FormGroup, PopoverPosition } from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';
import { Calendar } from '@carbon/icons-react';
import dayjs from 'dayjs';
import { FieldProps } from 'formik';

import Icon from 'components/Icon/Icon';

import {
  DEFAULT_DATE_RANGE_MAX_YEARS_FROM_CURRENT,
  DEFAULT_DATE_RANGE_MIN_YEARS_FROM_CURRENT
} from 'app/global/variables';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './FormDateRangePicker.module.pcss';

const b = block(style);
interface FormDateRangePickerProps extends FieldProps {
  shortcuts?: boolean;
  disabled?: boolean;
  defaultStartDate?: string;
  defaultEndDate?: string;
  position?: PopoverPosition;
  onChange?: (value) => void;
  minDate?: Date;
  maxDate?: Date;
  stacked?: boolean;
  fullWidth?: boolean;
  bothDatesRequired?: boolean;
  endDateDisabled?: boolean;
  usePortal?: boolean;
}

const getMinDate = () => {
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - DEFAULT_DATE_RANGE_MIN_YEARS_FROM_CURRENT);
  return minDate;
};

const getMaxDate = () => {
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + DEFAULT_DATE_RANGE_MAX_YEARS_FROM_CURRENT);
  return maxDate;
};

const FormDateRangePicker: React.FC<FormDateRangePickerProps> = ({
  field: { name },
  form: { setFieldTouched },
  defaultStartDate,
  defaultEndDate,
  shortcuts = false,
  disabled = false,
  position = 'top',
  onChange,
  minDate = getMinDate(),
  maxDate = getMaxDate(),
  stacked = false,
  fullWidth,
  bothDatesRequired,
  endDateDisabled = false,
  usePortal = true
}: FormDateRangePickerProps) => {
  const [closeOnSelection, setCloseOnSelection] = useState(false);
  const [touchedStartInput, setTouchedStartInput] = useState(false);
  const [touchedEndInput, setTouchedEndInput] = useState(false);

  // Currently, there is an issue with blueprint library, wont be able to change placeholderText for DateRangeInput
  // https://github.com/palantir/blueprint/issues/4487

  const startDate = defaultStartDate ? new Date(dayjs(defaultStartDate).format('MM-DD-YYYY')) : null;
  const endDate = defaultEndDate ? new Date(dayjs(defaultEndDate).format('MM-DD-YYYY')) : null;

  // If the start date and end date are removed, we don't want `shouldShowErrorState` to be true if we try inputting a start or end date again
  useEffect(() => {
    if (!startDate && !endDate) {
      setTouchedStartInput(false);
      setTouchedEndInput(false);
    }
  }, [startDate, endDate]);

  // TODO: Ideally, we don't want to format the date like this. We should be using locale once it's supported.
  const formatDate = (date: Date): string => {
    const month = date.toLocaleDateString('en-GB', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}. ${day}, ${year}`;
  };

  const shouldShowErrorState =
    ((!startDate && endDate) || (startDate && !endDate)) && touchedStartInput && touchedEndInput && bothDatesRequired;
  const dateRequiredErrorMessage = !startDate
    ? formatMessage('PLEASE_SELECT_A_START_DATE')
    : formatMessage('PLEASE_SELECT_AN_END_DATE');

  return (
    <div className={b({ stacked, fullWidth, defaultWidth: !fullWidth })} onBlur={() => setFieldTouched(name)}>
      <FormGroup labelFor={name}>
        {/* Label is to make the icon click triggering the calendar */}
        <label>
          <DateRangeInput
            closeOnSelection={closeOnSelection}
            defaultValue={[startDate, endDate]}
            disabled={disabled}
            formatDate={formatDate}
            parseDate={(str) => (str ? new Date(str) : null)}
            popoverProps={{ position, minimal: true, usePortal }}
            shortcuts={shortcuts}
            onChange={(value) => {
              onChange(value);
              setCloseOnSelection(!closeOnSelection);
            }}
            startInputProps={{
              leftIcon: <Icon icon={<Calendar size={32} />} />,
              onBlur: () => setTouchedStartInput(true),
              className: shouldShowErrorState ? 'bp3-intent-danger' : ''
            }}
            endInputProps={{
              leftIcon: <Icon icon={<Calendar size={32} />} />,
              onBlur: () => setTouchedEndInput(true),
              className: shouldShowErrorState ? 'bp3-intent-danger' : '',
              disabled: endDateDisabled
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
        </label>
        {shouldShowErrorState && (
          <div className={b('validationMessage')} data-testid={`form-date-range-picker-${name}-error-message`}>
            {dateRequiredErrorMessage}
          </div>
        )}
      </FormGroup>
    </div>
  );
};

export default FormDateRangePicker;
