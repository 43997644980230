import React, { useImperativeHandle, useState } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';
import { Field, Formik } from 'formik';

import FormDateRangePicker from 'app/components/FormFields/FormDateRangePicker/FormDateRangePicker';

// null dates represent no date
interface DateRangeCellEditorProps extends ICellEditorParams {
  name?: string;
  effectiveStartDate?: Date | null;
  effectiveEndDate?: Date | null;
  endDateDisabled?: boolean;
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
}

interface DateRangeCellEditorState {
  effectiveStartDate: Date | null;
  effectiveEndDate: Date | null;
}

const DateRangeCellEditor = (props: DateRangeCellEditorProps, ref) => {
  const { name, endDateDisabled, disabled, maxDate, minDate } = props;
  const [dateRange, setDateRange] = useState<DateRangeCellEditorState>({
    effectiveStartDate: props.effectiveStartDate,
    effectiveEndDate: props.effectiveEndDate
  });

  // necessary for ag-grid cell editors to return value back to grid
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return { effectiveStartDate: dateRange.effectiveStartDate, effectiveEndDate: dateRange.effectiveEndDate };
      }
    };
  });

  const handleUpdateEffectiveDate = (e) => {
    setDateRange({ effectiveStartDate: e?.[0], effectiveEndDate: e?.[1] });
  };

  return (
    <Formik initialValues={null} onSubmit={null}>
      <div data-testid="date-range-cell-editor">
        <Field
          name={name}
          component={FormDateRangePicker}
          defaultStartDate={dateRange.effectiveStartDate}
          defaultEndDate={dateRange.effectiveEndDate}
          position="bottom"
          onChange={handleUpdateEffectiveDate}
          endDateDisabled={endDateDisabled}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
        />
      </div>
    </Formik>
  );
};

export default React.forwardRef(DateRangeCellEditor);
