import gql from 'graphql-tag';

export const START_DATA_PREVIEW_FROM_SYMON_PIPE = gql`
  mutation StartDataPreviewFromSymonPipe($exportId: String!, $pipeId: String!, $planningCycleId: Int!) {
    startDataPreviewFromSymonPipe(input: { exportId: $exportId, pipeId: $pipeId, planningCycleId: $planningCycleId }) {
      exportId
      pipeId
      pollingTokenId
      progress
      status
    }
  }
`;
