// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xJgKs {\n    margin-top: 15px;\n  }\n\n    .xJgKs .bp3-input {\n      width: 360px !important;\n      height: 32px !important;\n      border-color: rgb(var(--color-light-gray-2));\n    }\n\n    .xJgKs label.bp3-label {\n      display: flex;\n      flex-direction: column;\n      line-height: 2;\n      font-weight: normal;\n      color: rgb(var(--color-dark-gray-3));\n      font-size: 14px !important;\n    }\n  .Dol1j {\n    resize: none;\n    font-size: 14px;\n    color: rgb(var(--color-dark-gray-3));\n    font-weight: normal;\n  }\n  .Dol1j textarea.bp3-input {\n      height: 96px;\n      width: 750px;\n      box-shadow: inset 0 0 0 1px rgb(var(--color-light-gray-2));\n      margin-top: 4px;\n    }\n  .Xu4mx {\n    color: rgb(var(--color-red-3));\n    flex-grow: 1;\n    padding: 1rem 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HierarchyCreationDialog_name": "xJgKs",
	"HierarchyCreationDialog_description": "Dol1j",
	"HierarchyCreationDialog_errorMessage": "Xu4mx"
};
export default ___CSS_LOADER_EXPORT___;
