// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WydWJ {\n  width: 100%;\n  height: 100%;\n  overflow-y: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n  .oPdIN {\n    background-color: rgb(var(--color-light-gray-5));\n    padding-top: 30px;\n    display: grid;\n    height: 100%;\n    grid-template: '. content .' / 1fr 2fr 1fr;\n    overflow-y: auto;\n  }\n\n  ._1HV8y {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    grid-area: content;\n    margin-bottom: var(--l);\n  }\n\n  .Iuf6O {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: var(--xl);\n    border-radius: 0;\n  }\n\n  .xE5iM {\n    width: 300px;\n    text-align: center;\n    padding-top: var(--l);\n  }\n\n  .CR9jC {\n    color: var(--text-heading);\n    font-weight: 600;\n    font-size: var(--l);\n  }\n\n  .-Yx8s {\n    font-size: 14px;\n    font-size: var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WelcomePage": "WydWJ",
	"WelcomePage_welcomePageWrapper": "oPdIN",
	"WelcomePage_welcomePageCardWrapper": "_1HV8y",
	"WelcomePage_welcomePageCard": "Iuf6O",
	"WelcomePage_welcomePageTextWrapper": "xE5iM",
	"WelcomePage_welcomePageTitle": "CR9jC",
	"WelcomePage_text": "-Yx8s"
};
export default ___CSS_LOADER_EXPORT___;
