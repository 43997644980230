import { useCallback, useEffect, useState } from 'react';

import { SymonToken, SymonUploadCredentials } from 'app/containers/Symon/SymonTypes';

import useSymonApiProxyClient from './useSymonApiProxy';

// How long before a token expires to update creds
const REREQUEST_BUFFER_MILLIS = 10_000;

const useSymonUploadCreds = (token: SymonToken): SymonUploadCredentials | null => {
  const client = useSymonApiProxyClient();
  const [awsCreds, setAwsCreds] = useState<SymonUploadCredentials | null>(null);

  const getSymonUploadCreds = useCallback(() => {
    if (token) {
      client.getSymonUploadCreds(token.orgID).then((creds) => setAwsCreds(creds));
    }
  }, [client, token]);

  useEffect(() => {
    let timer: number | undefined;

    if (awsCreds) {
      const millisUntilExpiry = new Date(awsCreds.expiration).getTime() - Date.now();

      timer = window.setTimeout(() => {
        getSymonUploadCreds();
      }, millisUntilExpiry - REREQUEST_BUFFER_MILLIS);
    } else {
      getSymonUploadCreds();
    }

    return () => {
      if (timer) window.clearTimeout(timer);
    };
  }, [awsCreds, getSymonUploadCreds]);

  return awsCreds;
};

export default useSymonUploadCreds;
