import React from 'react';

import { Tag } from '@blueprintjs/core';
import { Calendar, StringText, StringInteger, Currency, Percentage } from '@carbon/icons-react';

import { DataType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const dataTypeLookUpMap = {
  [DataType.TEXT]: {
    text: formatMessage('TEXT'),
    icon: <StringText />,
    background: 'var(--intent-primary-translucent-hover)',
    color: 'rgb(var(--color-cobalt-3))'
  },
  [DataType.NUMBER]: {
    text: formatMessage('NUMERIC'),
    icon: <StringInteger />,
    background: 'var(--highlight-04-translucent)',
    color: 'var(--highlight-04)'
  },
  [DataType.DATE]: {
    text: formatMessage('DATE'),
    icon: <Calendar />,
    background: 'var(--highlight-03-translucent)',
    color: 'var(--highlight-03)'
  },
  [DataType.CURRENCY]: {
    text: formatMessage('CURRENCY'),
    icon: <Currency />,
    background: 'var(--highlight-01-translucent)',
    color: 'var(--highlight-01)'
  },
  [DataType.PERCENTAGE]: {
    text: formatMessage('PERCENTAGE'),
    icon: <Percentage />,
    background: 'var(--highlight-02-translucent)',
    color: 'var(--highlight-02)'
  }
};

interface DataTypeTagProps {
  dataType: DataType;
}

const DataTypeTag: React.FC<DataTypeTagProps> = ({ dataType }: DataTypeTagProps) => {
  const dataTypeObject = dataTypeLookUpMap[dataType];
  return (
    <Tag
      minimal
      icon={dataTypeObject?.icon}
      style={{ background: dataTypeObject?.background, color: dataTypeObject?.color }}
    >
      {dataTypeObject?.text}
    </Tag>
  );
};

export default DataTypeTag;
