// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PxwRr {\n    border: 1px solid rgb(var(--color-light-gray-3));\n    border-top: none;\n    box-shadow: inset 3px 0 6px rgba(0, 0, 0, 0.08);\n    flex: 1;\n    width: 25%;\n    border-right: none;\n  }\n  .biFH- {\n    background: rgb(var(--color-light-gray-3));\n    color: rgb(var(--color-dark-gray-3));\n    height: var(--territory-balancing-header-height);\n    padding: 0 var(--grid-header-padding);\n    display: flex;\n    align-items: center;\n    font-weight: 600;\n    font-size: 14px;\n    border-bottom: 1px solid rgb(var(--color-light-gray-3));\n  }\n  .R7Fag {\n    height: calc(100% - 40px);\n    padding: var(--grid-header-padding);\n  }\n  .vMgSm {\n    height: 100%;\n    border: 1px dotted black;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-weight: 600;\n    color: rgb(var(--color-dark-gray-3));\n    text-align: center;\n  }\n  .ICWFU {\n    width: 50%;\n  }\n  .QrFQO {\n    width: 150px;\n    margin: 20px auto;\n  }\n  ._6sBee {\n    background-color: var(--quarter-background-color) !important;\n    color: rgb(var(--color-cobalt-3)) !important;\n    border: 1px solid rgb(var(--color-cobalt-3)) !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EmptyPanel_container": "PxwRr",
	"EmptyPanel_header": "biFH-",
	"EmptyPanel_body": "R7Fag",
	"EmptyPanel_dashedContainer": "vMgSm",
	"EmptyPanel_panelEmptyImage": "ICWFU",
	"EmptyPanel_textContainer": "QrFQO",
	"EmptyPanel_button": "_6sBee"
};
export default ___CSS_LOADER_EXPORT___;
