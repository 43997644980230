// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iwh5g .mapboxgl-popup-content {\n      background: var(--background-inverse);\n      padding: var(--s) var(--m);\n    }\n    .iwh5g .mapboxgl-popup-tip {\n      border-top-color: var(--background-inverse) !important;\n    }\n  .Lrbdj {\n    color: var(--white);\n  }\n  .rfTh2 {\n    color: var(--white);\n  }\n  .B-iNy {\n    width: -moz-fit-content;\n    width: fit-content;\n    margin: var(--xs) auto var(--xs) 0;\n  }\n  .DHV\\+2 {\n    display: flex;\n  }\n  ._1-utM {\n    padding-right: var(--s);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountTooltip_accountTooltipWrapper": "iwh5g",
	"AccountTooltip_tooltipTitle": "Lrbdj",
	"AccountTooltip_tooltipBody": "rfTh2",
	"AccountTooltip_tooltipTag": "B-iNy",
	"AccountTooltip_tooltipContainer": "DHV+2",
	"AccountTooltip_lockIcon": "_1-utM"
};
export default ___CSS_LOADER_EXPORT___;
