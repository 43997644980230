import React from 'react';

import { AddAlt, Edit } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommandCenterHierarchyPanelToolbar.module.pcss';
const b = block(style);

export const CommandCenterHierarchyPanelToolbar = ({
  hierarchyType,
  handleAddChildUnderRoot,
  handleAddStandardGeo
}: {
  hierarchyType?: HierarchyType;
  handleAddChildUnderRoot: () => void;
  handleAddStandardGeo?: () => void;
}): React.ReactElement => {
  return (
    <div className={b('')} data-testid="command-center-hierarchy-toolbar">
      <div className={b('iconButton')}>
        <IconButton
          type="button"
          icon={
            hierarchyType === HierarchyType.GeographicTerritoryHierarchy ? (
              <Edit data-testid="edit-icon" />
            ) : (
              <AddAlt data-testid="add-icon" />
            )
          }
          tooltipText={
            hierarchyType === HierarchyType.GeographicTerritoryHierarchy
              ? formatMessage('ADD_HIERARCHY')
              : formatMessage('ADD_HIERARCHY_UNDER_ROOT')
          }
          onClick={
            hierarchyType === HierarchyType.GeographicTerritoryHierarchy
              ? handleAddStandardGeo
              : handleAddChildUnderRoot
          }
          testId={'hierarchy-panel-add-button'}
          title={formatMessage('ADD')}
        />
      </div>
    </div>
  );
};
