import * as yup from 'yup';

import { BattlecardType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export default yup.object().shape({
  cardName: yup
    .string()
    .trim()
    .max(256)
    .required(formatMessage('BATTLE_CARD_TITLE_VALIDATION_ERROR'))
    .label(formatMessage('UNTITLED')),
  reorderableListItems: yup.array().test(function (value, values) {
    const { createError } = this;
    const emptyTerritoryGroupTypes = value?.filter((item) => !item.hierarchyTop);
    if (emptyTerritoryGroupTypes?.length > 0 && values?.parent?.cardType?.value !== BattlecardType.Rollup) {
      return createError();
    }
    return true;
  }),
  quotaComponents: yup.array().min(1, formatMessage('QUOTA_COMPONENT_VALIDATION_ERROR')),
  quotaBreakdowns: yup.array().max(2, formatMessage('QUOTA_BREAKDOWN_VALIDATION_ERROR'))
});
