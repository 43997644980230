// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R\\+iQq {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  align-items: center;\n  cursor: pointer;\n  text-align: left;\n}\n\n  .e-KgI {\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExtraItemTagCellRenderer": "R+iQq",
	"ExtraItemTagCellRenderer_remainingElementsCount": "e-KgI " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["extra-items-tag"] + ""
};
export default ___CSS_LOADER_EXPORT___;
