// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uHvIX {\n  padding-bottom: 10px;\n  border-bottom: 1px solid var(--border-divider);\n}\n\n  .VHJRY {\n    display: flex;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n  }\n\n  .VHJRY .bp3-skeleton {\n      width: 60px;\n      height: 40px;\n    }\n\n  .VHJRY .bp3-skeleton:first-child {\n      width: 20px;\n    }\n\n  .x-KZZ {\n    padding: 0 var(--xs);\n    color: rgb(var(--color-dark-gray-4));\n    display: flex;\n    align-items: center;\n  }\n\n  .M0Lj0 {\n      color: rgb(var(--color-green-2));\n    }\n\n  ._0qsPE {\n    display: flex;\n  }\n\n  ._3ryN6 {\n    display: flex;\n  }\n\n  .Z4EP2 {\n    margin-right: auto;\n    display: flex;\n    align-items: center;\n  }\n\n  .iOXPj {\n    margin-left: var(--xs);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ScenarioPlanningCompareAcrossDmHeader": "uHvIX",
	"ScenarioPlanningCompareAcrossDmHeader_skeletonWrapper": "VHJRY",
	"ScenarioPlanningCompareAcrossDmHeader_scenarioNameHeaderIcon": "x-KZZ",
	"ScenarioPlanningCompareAcrossDmHeader_scenarioNameHeaderIcon__isScenarioPublished": "M0Lj0",
	"ScenarioPlanningCompareAcrossDmHeader_scenarioNameContainer": "_0qsPE",
	"ScenarioPlanningCompareAcrossDmHeader_headerContainer": "_3ryN6",
	"ScenarioPlanningCompareAcrossDmHeader_compareSelectionText": "Z4EP2",
	"ScenarioPlanningCompareAcrossDmHeader_battlecardName": "iOXPj"
};
export default ___CSS_LOADER_EXPORT___;
