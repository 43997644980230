import React, { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import AuthSpinner from 'components/AuthSpinner/AuthSpinner';

import MaintenancePage from 'app/containers/App/MaintenancePage/MaintenancePage';
import TenantAccessErrorPage from 'app/containers/App/TenantAccessErrorPage/TenantAccessErrorPage';
import TenantContainer from 'app/containers/App/TenantContainer/TenantContainer';
import WelcomePage from 'app/containers/App/WelcomePage/WelcomePage';

import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';

import { useGetUserSpecExactlyOnce } from 'app/hooks/useGetUserSpecExactlyOnce';
import useTreatment from 'app/hooks/useTreatment';

export const TenantRouter: React.FC = () => {
  const { userSpec: initialUserSpec, loading } = useGetUserSpecExactlyOnce();
  const { selectedTenant, setSelectedTenant } = useScope();
  const { setUserProfile } = useUser();
  const { tenantSlug: tenantSlugInUrl } = useParams<{ tenantSlug: string }>();
  const [isMaintenanceModeEnabled] = useTreatment(SplitFeatures.MAINTENANCE_MODE);

  useEffect(() => {
    const profile = {
      tenant: {
        id: initialUserSpec?.getUserSpec?.membershipSpecs[0]?.tenantId,
        slug: initialUserSpec?.getUserSpec?.membershipSpecs[0]?.tenantSlug
      },
      subjectId: initialUserSpec?.getUserSpec?.subjectId,
      firstName: initialUserSpec?.getUserSpec?.firstName,
      lastName: initialUserSpec?.getUserSpec?.lastName,
      emailAddress: initialUserSpec?.getUserSpec?.emailAddress,
      isSupportUser: initialUserSpec?.getUserSpec?.isSupportUser
    };

    setUserProfile(profile);
  }, [initialUserSpec]);

  const tenantFromUrl = useMemo(
    () =>
      initialUserSpec?.getUserSpec?.membershipSpecs?.find((memberSpec) => memberSpec.tenantSlug === tenantSlugInUrl),
    [tenantSlugInUrl, initialUserSpec]
  );

  useEffect(() => {
    if (tenantFromUrl) {
      setSelectedTenant({
        id: tenantFromUrl.tenantId,
        globalId: tenantFromUrl.tenantGlobalId,
        slug: tenantFromUrl.tenantSlug
      });
    } else {
      setSelectedTenant(null);
    }
  }, [tenantFromUrl]);

  if (isMaintenanceModeEnabled) {
    return <MaintenancePage />;
  }

  if (loading) return <AuthSpinner />;

  if (selectedTenant) return <TenantContainer />;

  if (tenantSlugInUrl && !tenantFromUrl)
    return <TenantAccessErrorPage membershipSpecs={initialUserSpec?.getUserSpec?.membershipSpecs ?? []} />;

  return <WelcomePage membershipSpecs={initialUserSpec?.getUserSpec?.membershipSpecs ?? []} />;
};
