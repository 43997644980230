import dayjs from 'dayjs';

import { COMMAND_CENTER_DRAWER_HALF_WIDTH, COMMAND_CENTER_DRAWER_OPEN_WIDTH } from 'app/global/variables';

import { CommandCenterDrawerState, Member, MemberData } from 'app/models';

export const getDrawerWidthDifference = (
  commandCenterDrawerState: CommandCenterDrawerState,
  windowWidth: number,
  padding: number
): string => {
  let drawerWidth;
  const zeroWidth = 0;

  if (commandCenterDrawerState === CommandCenterDrawerState.EXPAND) {
    drawerWidth = zeroWidth;
  } else if (commandCenterDrawerState === CommandCenterDrawerState.HALF) {
    drawerWidth = COMMAND_CENTER_DRAWER_HALF_WIDTH;
  } else if (commandCenterDrawerState === CommandCenterDrawerState.OPEN) {
    drawerWidth = COMMAND_CENTER_DRAWER_OPEN_WIDTH;
  } else {
    drawerWidth = zeroWidth;
  }

  return `${windowWidth - drawerWidth - padding}px`;
};

// Parse the Member List data for use in the Table.
export const parseMemberData = (member: Member): MemberData => {
  return {
    user: `${member.firstName} ${member.lastName}`,
    firstName: member.firstName,
    lastName: member.lastName,
    emailAddress: member.emailAddress,
    jobTitle: member.jobTitle,
    systemRoleName: member.systemRoleName,
    memberUpdatedAt: dayjs(member.memberUpdatedAt).format('MMMM D, YYYY [at] h:mm A'),
    employeeId: member.employeeId ?? '',
    memberId: member.memberId,
    tenantId: member.tenantId,
    delete: '',
    memberInvitesInfo: member.memberInvitesInfo ?? []
  };
};
