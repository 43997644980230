import React from 'react';

import {
  TerritoryGroupDefineAndRefinePill,
  UnassignedPill
} from 'app/components/TerritoriesAndPlanTargets/TerritoryPill/customTerritoryPillsByType';

import { CategoryName, ExpandedTerritoryGroupDefineAndRefinePillData, PillLevel } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './ExpandedTerritoryDefineandRefineTree.module.pcss';

const b = block(style);

interface TreeNodeProps {
  nodeData: ExpandedTerritoryGroupDefineAndRefinePillData;
  pillIndex: number;
  categoryName: CategoryName;
  battleCardId: string;
  enableClick?: boolean;
}

const TreeNode: React.FC<TreeNodeProps> = ({
  nodeData,
  pillIndex,
  categoryName,
  battleCardId,
  enableClick
}: TreeNodeProps) => {
  const pillLevel = nodeData.territoryGroupParentId ? PillLevel.TERRITORY : PillLevel.CATEGORY;

  const isUnassignedPill = categoryName === CategoryName.UNASSIGNED;

  if (isUnassignedPill) {
    // render unassigned pill
    return (
      <div className={b('categoryNode')} data-testid={`tree-node-unassigned`}>
        <span className={b('arrowNode')} data-testid={`tree-node-arrow-unassigned`} />
        <UnassignedPill nodeData={nodeData} battleCardId={battleCardId} />
      </div>
    );
  } else if (pillLevel === PillLevel.TERRITORY) {
    return (
      <div>
        <TerritoryGroupDefineAndRefinePill
          nodeData={nodeData}
          pillIndex={pillIndex}
          categoryName={categoryName}
          battleCardId={battleCardId}
          enableClick={enableClick}
        />
      </div>
    );
  } else {
    // render category pill
    return (
      <div className={b('categoryNode')} data-testid={`tree-node-category-${nodeData.territoryGroupId}`}>
        <span className={b('arrowNode')} data-testid={`tree-node-arrow-${nodeData.territoryGroupId}`} />
        <TerritoryGroupDefineAndRefinePill
          nodeData={nodeData}
          pillIndex={pillIndex}
          categoryName={categoryName}
          battleCardId={battleCardId}
          enableClick={enableClick}
        />
      </div>
    );
  }
};

export default TreeNode;
