import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertRolePermissions, UpsertRolePermissionsVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const UPSERT_ROLE_PERMISSIONS = gql`
  mutation UpsertRolePermissions($input: UpsertRolePermissionInput!) {
    upsertRolePermissions(input: $input) {
      actionName
      deploymentModelId
    }
  }
`;

export const useUpsertRolePermissions = createUseMutation<UpsertRolePermissions, UpsertRolePermissionsVariables>(
  UPSERT_ROLE_PERMISSIONS
);
