import React from 'react';

import { ExternalRoutePaths } from 'app/containers/App/Router/routePaths';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './PolicyMessage.module.pcss';

const b = block(style);

interface PolicyMessageProps {
  buttonText: string;
}

const PolicyMessage: React.FC<PolicyMessageProps> = ({ buttonText }: PolicyMessageProps) => {
  return (
    <div className={b('policy')}>
      {formatMessage('BY_CLICKING', { actionText: buttonText })}
      <a className={b('link')} href={ExternalRoutePaths.TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer">
        {formatMessage('TERMS_OF_SERVICE')}
      </a>
      {` ${formatMessage('AND_OUR')} `}
      <a className={b('link')} href={ExternalRoutePaths.PRIVACY_STATEMENT} target="_blank" rel="noopener noreferrer">
        {formatMessage('PRIVACY_POLICY')}
      </a>
      .
    </div>
  );
};
export default PolicyMessage;
