import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { UpsertSheet, UpsertSheetVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_SHEET } from 'app/graphql/mutations/upsertSheet';

import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';

import { ADMIN, DeploymentModelPhase } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SheetController.module.pcss';

const b = block(style);

interface SheetControllerProps {
  permissionRowData: React.ReactNode[];
  bcRowData: React.ReactNode[];
  qcRowData: React.ReactNode[];
  getAllBattleCards: () => void;
}

const SheetController: React.FC<SheetControllerProps> = ({
  permissionRowData,
  bcRowData,
  qcRowData,
  getAllBattleCards
}) => {
  const { selectedSheet, sheetLookupMap, getSheetDefinitions } = useData();
  const { selectedDeploymentModelId } = useScope();
  const deploymentModelPhase = usePhase();
  const isTQM = deploymentModelPhase === DeploymentModelPhase.manage;
  const showToast = useShowToast();

  const buildRoleDefinitions = (measure) => {
    const roleDefinitons = [];
    permissionRowData.forEach((row) => {
      const roleDefiniton = {
        roleName: row['roleName'] === ADMIN ? null : row['roleName'],
        roleId: row['roleId']
      };
      Object.entries(row).forEach(([fieldName, fieldValue]) => {
        // Split field name by underscores to separate the field from measure name, eg. 'measure_name_permissions' -> ['measure', 'name', 'permissions']
        const fieldNameArray = fieldName.split('_');
        // Get the last element of the array, this field can be permissions or sheetACLId
        const field = fieldNameArray[fieldNameArray.length - 1];
        // Get the substring of fieldName by removing the field and the underscore before it, eg. 'measure_name_permissions' -> 'measure_name'
        const measureName = fieldName.slice(0, fieldName.length - field.length - 1);

        if (measureName === measure) {
          if (field === 'permissions') {
            roleDefiniton['visible'] = fieldValue.visible;
            roleDefiniton['editable'] = fieldValue.editable;
          } else {
            roleDefiniton[field] = fieldValue;
          }
        }
      });
      roleDefinitons.push(roleDefiniton);
    });
    return roleDefinitons;
  };

  const buildBcAssociation = () =>
    bcRowData
      .filter((bcRow) => bcRow['referenceThisSheet'])
      .map((row) => ({ battlecardId: row['battlecardId'], territoryGroupId: row['territoryGroupId'] }));

  const buildQcAssociation = () =>
    qcRowData.filter((qcRow) => qcRow['referenceThisSheet']).map((row) => row['quotaComponentId']);

  const buildSheetDefinitions = () => {
    const sheetDefinitions = [];
    Object.entries(sheetLookupMap).forEach(([measureName, sheet]) => {
      const sheetDefinition = {};
      Object.entries(sheet).forEach(([field, value]) => {
        sheetDefinition[field] = value;
      });
      const roleDefinitons = buildRoleDefinitions(measureName);

      sheetDefinition['quotaComponents'] = qcRowData
        .filter((qcRow) => qcRow['referenceThisSheet'])
        .map((qc) => ({ quotaComponentId: qc['quotaComponentId'], roles: roleDefinitons }));
      sheetDefinitions.push(sheetDefinition);
    });

    return sheetDefinitions;
  };

  const [upsertSheet, { loading: upsertSheetLoading }] = useMutation<UpsertSheet, UpsertSheetVariables>(UPSERT_SHEET, {
    onCompleted() {
      getAllBattleCards();
      getSheetDefinitions(selectedDeploymentModelId, selectedSheet.sheetId, isTQM);
      showToast(formatMessage('UPDATE_QUOTA_SHEET_SUCCESS'), 'success');
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('UPDATE_QUOTA_SHEET_FAILED'), 'danger');
    }
  });

  const handleSubmit = () => {
    upsertSheet({
      variables: {
        input: {
          deploymentModelId: selectedDeploymentModelId,
          dataSheets: [
            {
              sheetId: selectedSheet.sheetId,
              sheetDefinitions: buildSheetDefinitions(),
              battlecardAssociations: buildBcAssociation(),
              quotaComponentAssociations: buildQcAssociation()
            }
          ]
        }
      }
    });
  };

  return (
    <div className={b('sheetController')}>
      <TextButton
        type="button"
        text={formatMessage('PUBLISH')}
        onClick={handleSubmit}
        loading={upsertSheetLoading}
        testId={'publish-button'}
        large={false}
      />
    </div>
  );
};

export default SheetController;
