import { useEffect, useMemo } from 'react';

import { MapboxMap } from 'react-map-gl';

import { ROAD_LAYERS_IDS_AND_FILTERS } from 'app/components/TerritoryMap/MapStyleTokens';
import {
  createCountryCodeFilter,
  getAllCountriesFromSourceGroups
} from 'app/components/TerritoryMap/PolygonLayerHelpers';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { SourceGroup } from 'app/models';

export const useHighwaysHighlighting = (map: MapboxMap, sourceGroups: SourceGroup[]): void => {
  const { isHighwaysLayerVisible } = useDedicatedMapProvider();
  const [isHighlightHighwaysOn] = useTreatment(SplitFeatures.MAP_HIGHWAY_HIGHLIGHTING);

  const countryFilter = useMemo(
    () => createCountryCodeFilter(getAllCountriesFromSourceGroups(sourceGroups)),
    [sourceGroups]
  );

  useEffect(() => {
    if (map && isHighlightHighwaysOn) {
      const visibilityValue = isHighwaysLayerVisible ? 'visible' : 'none';

      ROAD_LAYERS_IDS_AND_FILTERS.forEach((layer) => {
        map.setLayoutProperty(layer.id, 'visibility', visibilityValue);
      });
    }
  }, [map, isHighlightHighwaysOn, isHighwaysLayerVisible]);

  useEffect(() => {
    if (map && isHighlightHighwaysOn) {
      ROAD_LAYERS_IDS_AND_FILTERS.forEach((layer) => {
        if (map.getLayer(layer.id)) {
          map.setFilter(layer.id, ['all', layer.filter, countryFilter]);
        }
      });
    }
  }, [map, isHighlightHighwaysOn, countryFilter]);
};
