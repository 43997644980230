import React, { useMemo, useState } from 'react';

import { Radio } from '@blueprintjs/core';
import { Form, Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import useShowToast from 'app/hooks/useShowToast';

import { DeleteOptions, DeleteRedirectVariables } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { calculateRedirectToDelete, getDeleteRedirectToastText, sortRedirectsByStartDate } from './AccountQuotaUtils';
import { redirectAccountUnit } from './AccountSheetUtils';
import style from './DeleteRedirectDialog.module.pcss';

const b = block(style);

interface DeleteRedirectDialogProps {
  deleteRedirectVariables: DeleteRedirectVariables;
  onClose: () => void;
  onDeleteSuccess: () => void;
}

const DeleteRedirectDialog: React.FC<DeleteRedirectDialogProps> = ({
  deleteRedirectVariables,
  onClose,
  onDeleteSuccess
}) => {
  const { accountId, customHierarchyId, sourceRuleId, redirects, accountName, redirectId } = deleteRedirectVariables;
  const showToast = useShowToast();

  const hasMultipleRedirects = redirects.length > 1;
  const [deleteOption, setDeleteOption] = useState<DeleteOptions>(
    hasMultipleRedirects ? DeleteOptions.ORIGINAL : DeleteOptions.END
  );
  const sortedRedirects = useMemo(() => sortRedirectsByStartDate([...redirects]).reverse(), [redirects]);
  const isDeletingFirstRedirect = useMemo(
    () => sortedRedirects[sortedRedirects.length - 1]?.redirectId === redirectId,
    [sortedRedirects, redirectId]
  );
  const isDeletingLatestRedirect = useMemo(
    () => sortedRedirects[0]?.redirectId === redirectId,
    [sortedRedirects, redirectId]
  );

  const handleDeleteOptionChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setDeleteOption(event.currentTarget.value as DeleteOptions);

  const handleSubmit = async () => {
    try {
      const { newRedirect, redirectIdsToRemove, formattedTerritoryNameText } = calculateRedirectToDelete(
        sortedRedirects,
        redirectId,
        sourceRuleId,
        deleteOption
      );
      await redirectAccountUnit({
        accountId,
        customHierarchyId,
        sourceRuleId,
        targets: newRedirect ? [newRedirect] : [],
        redirectIdsToRemove
      });

      const toastText = getDeleteRedirectToastText({
        newRedirect,
        deleteOption,
        sortedRedirects,
        redirectId,
        accountName,
        formattedTerritoryNameText
      });
      showToast(<ToastMessage title={formatMessage('DELETE_REDIRECT_TOAST_TITLE')} message={toastText} />, 'success');
      onDeleteSuccess();
    } catch (_error) {
      showToast(formatMessage('DELETE_REDIRECT_ERROR'), 'danger');
    } finally {
      onClose();
    }
  };

  const getRadioOptionText = (deleteOption: DeleteOptions) => {
    const { newRedirect, formattedTerritoryNameText } = calculateRedirectToDelete(
      sortedRedirects,
      redirectId,
      sourceRuleId,
      deleteOption
    );
    const formattedTerritoryName = <b>{formattedTerritoryNameText}</b>;
    switch (deleteOption) {
      case DeleteOptions.START:
        return (
          <div className={b('radioOptionLabel')} data-testid="delete-radio-start-label">
            {formattedTerritoryName} {formatMessage('WILL_START')} <b>{newRedirect.startDate}</b>
          </div>
        );
      case DeleteOptions.END:
        return (
          <div className={b('radioOptionLabel')} data-testid="delete-radio-end-label">
            {formattedTerritoryName} {formatMessage('WILL_END')} <b>{newRedirect.endDate}</b>
          </div>
        );
      case DeleteOptions.ORIGINAL:
        return (
          <div className={b('radioOptionLabel')} data-testid="delete-radio-original-label">
            {formattedTerritoryName}
          </div>
        );
    }
  };

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <Dialog
          isOpen
          onClose={onClose}
          onSubmit={handleSubmit}
          disableConfirm={!deleteOption}
          confirmButtonText={formatMessage('DELETE')}
          confirmButtonLoading={isSubmitting}
          cancelButtonText={formatMessage('KEEP')}
          title={formatMessage('DELETE_REDIRECT')}
          bodyMinHeight={0}
          size="small"
          showOverflow
          isEllipsisHeading
          data-testid="delete-redirect-dialog"
          children={
            <Form>
              <div>
                <div>
                  {hasMultipleRedirects
                    ? formatMessage('DELETE_REDIRECT_DIALOGUE_BODY', { accountName })
                    : formatMessage('DELETE_REDIRECT_DIALOGUE_SOLO_DELETE_BODY')}
                </div>
                {hasMultipleRedirects && (
                  <div className={b('radioWrapper')}>
                    {!isDeletingLatestRedirect && (
                      <>
                        <Radio
                          className={b('radioButton')}
                          label={formatMessage('DELETE_REDIRECT_START_RADIO')}
                          value={DeleteOptions.START}
                          checked={DeleteOptions.START === deleteOption}
                          onChange={handleDeleteOptionChange}
                          data-testid="delete-radio-start"
                        />
                        {getRadioOptionText(DeleteOptions.START)}
                      </>
                    )}
                    {!isDeletingFirstRedirect && (
                      <>
                        <Radio
                          className={b('radioButton')}
                          label={formatMessage('DELETE_REDIRECT_END_RADIO')}
                          value={DeleteOptions.END}
                          checked={DeleteOptions.END === deleteOption}
                          onChange={handleDeleteOptionChange}
                          data-testid="delete-radio-end"
                        />
                        {getRadioOptionText(DeleteOptions.END)}
                      </>
                    )}
                    <Radio
                      className={b('radioButton')}
                      label={formatMessage('DELETE_REDIRECT_ORIGINAL_RADIO')}
                      value={DeleteOptions.ORIGINAL}
                      checked={DeleteOptions.ORIGINAL === deleteOption}
                      onChange={handleDeleteOptionChange}
                      data-testid="delete-radio-original"
                    />
                    {getRadioOptionText(DeleteOptions.ORIGINAL)}
                  </div>
                )}
              </div>
            </Form>
          }
        />
      )}
    </Formik>
  );
};

export default DeleteRedirectDialog;
