import gql from 'graphql-tag';

export const EXPORT_DATA = gql`
  mutation ExportData($input: ExportDataInput!) {
    exportData(input: $input) {
      credentials {
        accessKey
        secretAccessKey
      }
      exportData {
        exportId
        status
        message
      }
      location {
        bucket
        key
      }
    }
  }
`;
