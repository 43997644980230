import { useEffect } from 'react';

import { MapboxMap } from 'react-map-gl';

import { originalLabelLayerFilters } from 'app/components/TerritoryMap/MapStyleTokens';
import {
  createCountryCodeFilter,
  getAllCountriesFromSourceGroups
} from 'app/components/TerritoryMap/PolygonLayerHelpers';

import { SourceGroup } from 'app/models';

export const useHideInactiveCountryLabels = (
  mapboxMap: Pick<MapboxMap, 'getLayer' | 'setFilter'> | null,
  sourceGroups: SourceGroup[]
) => {
  useEffect(() => {
    if (!mapboxMap) return;
    const inUseCountryCodes = getAllCountriesFromSourceGroups(sourceGroups);
    const countryCodeFilter = createCountryCodeFilter(inUseCountryCodes);

    originalLabelLayerFilters.forEach((layer) => {
      if (mapboxMap.getLayer(layer.id)) {
        mapboxMap.setFilter(layer.id, ['all', layer.filter, countryCodeFilter]);
      }
    });
  }, [mapboxMap, sourceGroups]);
};
