import { useEffect, useState } from 'react';

import { shortDebounceDelay } from 'app/constants/DebounceConstants';

const useDebouncedValue = <ValueType>(value: ValueType): ValueType => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const timeoutId = window.setTimeout(() => setState(value), shortDebounceDelay);
    return () => window.clearTimeout(timeoutId);
  }, [value]);

  return state;
};
export default useDebouncedValue;
