import React, { FC, ReactNode } from 'react';

import { ChevronDown, ChevronUp } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import block from 'utils/bem-css-modules';

import { DragHandle } from './DragHandle';
import style from './GutterPanel.module.pcss';

const b = block(style);

type GutterPanelProps = {
  headerContent: ReactNode;
  edge: 'top' | 'bottom';
  height: number;
  isExpanded: boolean;
  isResizeEnabled: boolean;
  children: ReactNode;
  onResize: (height: number) => void;
  onToggleExpanded: () => void;
};

export const GutterPanel: FC<GutterPanelProps> = ({
  headerContent,
  edge,
  height,
  isExpanded,
  isResizeEnabled,
  onToggleExpanded,
  onResize,
  children
}) => {
  const isTop = edge === 'top';
  const isBottom = edge === 'bottom';
  const rootTestId = `gutter-panel-${edge}`;
  return (
    <div
      data-testid={rootTestId}
      className={b({ isTop, isBottom, isExpanded, isFlat: height <= 0 })}
      style={{ height, maxHeight: height }}
    >
      {isBottom && isExpanded && isResizeEnabled && (
        <div className={b('handleContainer')}>
          <DragHandle height={height} invert={true} onResize={onResize} />
        </div>
      )}

      <header className={b('header')}>
        <div className={b('headerContent')} data-testid={`${rootTestId}-header-content`}>
          {headerContent}
        </div>

        <IconButton
          icon={isExpanded === isTop ? <ChevronUp /> : <ChevronDown />}
          type="button"
          onClick={onToggleExpanded}
          testId={`${rootTestId}-${isExpanded ? 'collapse' : 'expand'}-button`}
        />
      </header>

      {isExpanded && (
        <>
          <hr className={b('panelDivider')} />
          <div className={b('panelContent')} data-testid={`${rootTestId}-panel-content`}>
            {children}
          </div>
        </>
      )}

      {isTop && isExpanded && isResizeEnabled && (
        <div className={b('handleContainer')}>
          <DragHandle height={height} invert={false} onResize={onResize} />
        </div>
      )}
    </div>
  );
};
