import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertBattlecardOwner, UpsertBattlecardOwnerVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const UPSERT_BATTLECARD_OWNER = gql`
  mutation UpsertBattlecardOwner($battlecardId: Int!, $memberId: Int!, $tenantId: Int!) {
    upsertBattlecardOwner(input: { battlecardId: $battlecardId, memberId: $memberId, tenantId: $tenantId }) {
      success
    }
  }
`;

export const useUpsertBattleCardOwner = createUseMutation<UpsertBattlecardOwner, UpsertBattlecardOwnerVariables>(
  UPSERT_BATTLECARD_OWNER
);
