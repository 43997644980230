import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { DropdownMenu as VDSDropdownMenu } from '@varicent/components';

interface DropdownMenuRadioItemProps {
  value: string;
  textValue: string;
}

const DropdownMenuRadioGroup: React.FC<DropdownMenuRadioItemProps> = (props: DropdownMenuRadioItemProps) => {
  return <VDSDropdownMenu.RadioItem {...props} />;
};

export default DropdownMenuRadioGroup;
