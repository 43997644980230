// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DbA7p {\n    margin-left: 10px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 200px;\n  }\n    .DbA7p .bp3-button > svg {\n        background: white;\n      }\n  .y40Yj {\n    margin-top: 15px;\n  }\n  .y40Yj .bp3-input {\n      width: 360px !important;\n      height: 32px !important;\n      border-color: rgb(var(--color-light-gray-2));\n    }\n  .y40Yj label.bp3-label {\n      display: flex;\n      flex-direction: column;\n      line-height: 2;\n      font-weight: normal;\n      color: rgb(var(--color-dark-gray-3));\n      font-size: 14px !important;\n    }\n  .HDe7r {\n    resize: none;\n    font-size: 14px;\n    color: rgb(var(--color-dark-gray-3));\n    font-weight: normal;\n  }\n  .HDe7r textarea.bp3-input {\n      height: 96px;\n      width: 750px;\n      box-shadow: inset 0 0 0 1px rgb(var(--color-light-gray-2));\n      margin-top: 4px;\n    }\n  .YBOf2 {\n    color: rgb(var(--color-cobalt-3));\n    font-size: 14px;\n    margin-left: 10px;\n  }\n  .YBOf2:hover {\n    text-decoration: underline;\n    cursor: pointer;\n  }\n  .pjKo3 {\n    color: rgb(var(--color-red-3));\n    flex-grow: 1;\n    padding: 1rem 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HierarchyEditDialog_addColumnArea": "DbA7p",
	"HierarchyEditDialog_name": "y40Yj",
	"HierarchyEditDialog_description": "HDe7r",
	"HierarchyEditDialog_addColumnText": "YBOf2",
	"HierarchyEditDialog_errorMessage": "pjKo3"
};
export default ___CSS_LOADER_EXPORT___;
