import React, { MutableRefObject } from 'react';

import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';

export const getActivityGridCellRenderer = (
  totalCount: number,
  rowRef: MutableRefObject<HTMLDivElement>
): ((params) => JSX.Element) => {
  return (params) => {
    if (params?.value !== undefined) {
      return <div>{params.value}</div>;
    } else if (params?.rowIndex < totalCount && params?.rowIndex !== 0) {
      // since pinned bottom row will always have rowIndex 0, we dont want it to keep loading.
      return (
        <GridLoading
          data-testid="territory-renderer"
          gridWidth={rowRef?.current?.offsetWidth}
          gridHeight={rowRef?.current?.offsetHeight}
        />
      );
    } else {
      // When there are 2-3 rows and some white rows are required to fill the space
      return null;
    }
  };
};
