import { FilterInput } from 'app/models';

export const formatFilterForRequest = (input: FilterInput): string | null => {
  let hasFilter = false;
  const output: FilterInput = {};
  Object.entries(input).forEach(([key, value]) => {
    if (value.filter === null || value.filter === undefined || value.filter === '') return;
    hasFilter = true;
    output[key] = value;
  });

  return hasFilter ? JSON.stringify(output) : null;
};
