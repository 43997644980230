import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { ArrowLeft, ArrowRight } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Dialog, { DialogProps } from 'components/Dialog/Dialog';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MultiStepDialog.module.pcss';

const b = block(style);

interface MultiStepDialogProps extends DialogProps {
  pages: JSX.Element[];
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  onNext?: (e?: React.SyntheticEvent<HTMLElement>) => void;
  nextButtonText?: string;
  disableNext?: boolean;
  isLoading?: boolean;
  startIndex?: number;
  confirmButtonIcon?: JSX.Element;
  showBack?: boolean;
}

const MultiStepDialog: React.FC<MultiStepDialogProps> = ({
  title,
  confirmButtonText = formatMessage('COMPLETE'),
  confirmButtonIcon,
  onSubmit,
  onNext,
  nextButtonText = formatMessage('NEXT'),
  onClose,
  disableCancel = false,
  disableConfirm = false,
  isOpen,
  confirmButtonLoading,
  pages,
  isLoading,
  disableNext = false,
  startIndex,
  currentIndex,
  setCurrentIndex,
  showBack = true,
  ...rest
}: MultiStepDialogProps) => {
  const isFinalStep = currentIndex === pages.length - 1;

  const onModifiedConfirm = (e?: React.FormEvent<HTMLFormElement>) => {
    if (isFinalStep) {
      onSubmit(e);
    } else {
      onNext?.();
    }
  };

  const onBack = () => {
    setCurrentIndex?.(currentIndex - 1);
  };

  const extraActions = (
    <>
      {showBack && currentIndex > 0 && (
        <div className={b('button')}>
          <TextButton
            text={formatMessage('BACK')}
            type="button"
            onClick={onBack}
            testId={'dialog-back-button'}
            icon={<ArrowLeft />}
          />
        </div>
      )}
      {!isFinalStep && (
        <div className={b('button')}>
          <TextButton
            testId={'dialog-next-button'}
            text={nextButtonText}
            type="submit"
            intent="primary"
            onClick={onModifiedConfirm}
            disabled={disableNext}
            rightIcon={<ArrowRight />}
          />
        </div>
      )}
    </>
  );

  useEffect(() => {
    return () => {
      setCurrentIndex?.(startIndex ?? 0);
    };
  }, [isOpen, startIndex]);
  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      portalClassName={b()}
      onSubmit={onModifiedConfirm}
      onClose={onClose}
      disableCancel={disableCancel}
      isLoading={isLoading}
      disableConfirm={disableConfirm}
      confirmButtonText={confirmButtonText}
      confirmButtonLoading={confirmButtonLoading}
      confirmButtonIcon={confirmButtonIcon}
      showConfirm={isFinalStep}
      extraActions={extraActions}
      {...rest}
    >
      {pages[currentIndex]}
    </Dialog>
  );
};

export default MultiStepDialog;
