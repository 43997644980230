import React from 'react';

import { Route, Switch } from 'react-router';

import CyclesGrid from 'app/components/CyclesGrid/CyclesGrid';
import CyclesPageHeader from 'app/components/CyclesPageHeader/CyclesPageHeader';
import UserMenu from 'app/components/UserMenu/UserMenu';

import ErrorPage, { ErrorType } from 'app/containers/App/ErrorPage/ErrorPage';
import { RoutePaths } from 'app/containers/App/Router/routePaths';
import UpsertCyclePage from 'app/containers/PlanningCycles/UpsertCyclePage/UpsertCyclePage';

import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { MembershipSpec } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import varicentLogo from 'assets/svgs/varicentlogo.svg';

import style from './CyclesPage.module.pcss';

const b = block(style);

type RouteProps = {
  match: {
    params: {
      cardSlug: string;
    };
  };
};

const CyclesPage: React.FC = () => {
  const {
    userProfile: { subjectId },
    userMembershipSpecs
  } = useUser();

  const { selectedTenant } = useScope();

  const isValidTenantId = (tenantId: number, userMembershipSpecs: MembershipSpec[]) => {
    const membershipSpec = userMembershipSpecs.find((membershipSpec) => {
      return membershipSpec.tenantId === tenantId;
    });

    return !!membershipSpec;
  };

  const isValidTenantSlug = (tenantSlug: string, userMembershipSpecs: MembershipSpec[]) => {
    const membershipSpec = userMembershipSpecs.find((membershipSpec) => {
      return membershipSpec.tenantSlug === tenantSlug;
    });

    return !!membershipSpec;
  };

  return (
    <div className={b()} data-testid={'cycles-page'}>
      {subjectId &&
      ((selectedTenant?.id && isValidTenantId(selectedTenant?.id, userMembershipSpecs)) ||
        (selectedTenant?.slug && isValidTenantSlug(selectedTenant?.slug, userMembershipSpecs))) ? (
        <>
          <CyclesPageHeader
            logoPath={`/${selectedTenant?.slug}`}
            logoButton={<img src={varicentLogo} alt={formatMessage('VARICENT_SP')} />}
            leftActionItems={[<div className={style.CyclesPage_search}>{formatMessage('VARICENT_SP')}</div>]}
            rightActionItems={[<UserMenu />]}
          />
          <div className={b('container')}>
            <Switch>
              <Route
                path={[`/:tenantSlug${RoutePaths.CREATE_PLAN}`, `/:tenantSlug${RoutePaths.EDIT_PLAN}/:cardSlug`]}
                render={(props: RouteProps) => <UpsertCyclePage planningCycleSlug={props.match.params.cardSlug} />}
              />
              <Route
                path={'/:tenantSlug'}
                render={() => {
                  return <CyclesGrid />;
                }}
              />
            </Switch>
          </div>
        </>
      ) : (
        <>
          <CyclesPageHeader
            logoPath="/"
            logoButton={<img src={varicentLogo} alt={formatMessage('VARICENT_SP')} />}
            rightActionItems={[<UserMenu />]}
          />
          <ErrorPage errorType={ErrorType._401} />
        </>
      )}
    </div>
  );
};

export default CyclesPage;
