export interface AccordionData {
  name: string;
  header: string;
  isOpen: boolean;
  subheader?: string;
  component?: React.ReactNode;
  hidePanel?: boolean;
}

export enum GapIndicatorType {
  SHORT,
  LONG
}

export interface KeyValue<T extends string | number> {
  key: T;
  value: string;
}

export enum MultiSelectBubbleColor {
  PINK = '#e8dafd',
  BLUE = '#dae7fd'
}

export const OperatorMap = {
  '+': {
    key: 'Addition',
    value: '+'
  },
  '-': {
    key: 'Subtraction',
    value: '-'
  },
  '*': {
    key: 'Multiplication',
    value: '*'
  },
  '/': {
    key: 'Division',
    value: '/'
  },
  '()': {
    key: 'Parenthesis',
    value: '()'
  },
  '(': {
    key: 'Open Parenthesis',
    value: '('
  },
  ')': {
    key: 'Closing Parenthesis',
    value: ')'
  },
  '.': {
    key: 'Decimal point',
    value: '.'
  }
};

export interface SearchableSelectMenuItem extends KeyValue<string> {
  icon?: JSX.Element;
  dangerColor?: boolean;
  message?: string;
}

export interface SelectMenuItem extends KeyValue<string> {
  icon?: JSX.Element;
}
