import * as yup from 'yup';

import { formatMessage } from 'utils/messages/utils';

export default yup.object().shape({
  organizationName: yup
    .string()
    .min(2, formatMessage('ORGANIZATION_NAME_LENGTH_ERROR'))
    .required(formatMessage('ENTER_YOUR_ORGANIZATION_NAME'))
});
