// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SsdgX {\n    padding-top: 15px;\n  }\n  .TuVq2 {\n    height: 600px;\n    overflow-y: auto;\n  }\n  .efLCv {\n    font-weight: 400;\n    font-size: 12px;\n    margin-left: var(--s);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ManageScenariosDialog_unPublishedScenarios": "SsdgX",
	"ManageScenariosDialog_dialogBody": "TuVq2",
	"ManageScenariosDialog_publishedHeader": "efLCv"
};
export default ___CSS_LOADER_EXPORT___;
