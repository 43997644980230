import React, { useState } from 'react';

import { GridApi } from '@ag-grid-community/core';
import { ArrowLeft, Renew } from '@carbon/icons-react';

import DataMappingController from 'app/components/DataMappingDrillIn/DataMappingController';
import DataMappingDrillIn from 'app/components/DataMappingDrillIn/DataMappingDrillIn';

import { useData } from 'app/contexts/dataProvider';

import { DataMappingType } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ConfigurationDetail.module.pcss';

const b = block(style);

interface ConfigurationDetailProps {
  onCloseConfigurationDetail: () => void;
}

const ConfigurationDetail: React.FC<ConfigurationDetailProps> = ({ onCloseConfigurationDetail }) => {
  const { selectedConfiguration, setSelectedConfiguration } = useData();
  const [gridApi, setGridApi] = useState<GridApi>(null);

  const getRowData = () => {
    const rowData = [];
    if (gridApi) {
      gridApi.forEachNode((node) => rowData.push(node.data));
    }
    return rowData;
  };

  const handleBackClicked = () => {
    onCloseConfigurationDetail();
    setSelectedConfiguration(null);
  };

  return (
    <div className={b()} data-testid="configuration-detail">
      <div className={b('header')} data-testid="configuration-detail-header">
        <div className={b('leftActionItems')}>
          <span
            className={b('backIcon')}
            data-testid="configuration-detail-header-back-icon"
            onClick={handleBackClicked}
          >
            <ArrowLeft />
          </span>
          <span className={b('configuraionTitle')} data-testid="configuration-title">
            <span className={b('symonPipeName')} data-testid="title-symon-pipe-name">
              {selectedConfiguration?.pipeName}
            </span>
            <span data-testid="title-symon-export-node">{selectedConfiguration?.exportName}</span>
          </span>
        </div>
        <div className={b('rightActionItems')}>
          <div className={b('hiddenItem')}>
            <span className={b('rightActionText')} data-testid="configuration-detail-last-modified">
              {formatMessage('LAST_MODIFIED', { value: formatMessage('SYSTEM_TIMESTAMP') })}
            </span>
            <Renew />
          </div>
        </div>
      </div>
      <DataMappingController
        rowData={getRowData()}
        controllerType={DataMappingType.CONFIGURATION}
        onCloseConfigurationDetail={onCloseConfigurationDetail}
        fileType={selectedConfiguration?.fileType}
        rootHierarchyId={selectedConfiguration?.hierarchyId}
      />
      <DataMappingDrillIn
        setGridApi={setGridApi}
        dataMappingType={DataMappingType.CONFIGURATION}
        fileType={selectedConfiguration?.fileType}
        rootHierarchyId={selectedConfiguration?.hierarchyId}
      />
    </div>
  );
};

export default ConfigurationDetail;
