import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

const useShouldShowSymonTab = (): boolean => {
  const [, treatments] = useTreatment(SplitFeatures.SYMON_EMBED_CONF);
  return treatments?.treatment === 'show';
};

export default useShouldShowSymonTab;
