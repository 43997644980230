import React, { useEffect, useState } from 'react';

import FuzzySet from 'fuzzyset';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { useMappingFields } from 'app/components/DataMappingDrillIn/hooks/useMappingFields';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { FileTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { DataMappingSelectMenuItem } from 'app/models/index';

import { formatMessage } from 'utils/messages/utils';

interface AutoMapButtonProps {
  rowData: React.ReactNode[];
  fileType: FileTypeEnum;
  rootHierarchyId?: number | null;
}

const AutoMapButton: React.FC<AutoMapButtonProps> = ({
  rowData,
  fileType,
  rootHierarchyId = null
}: AutoMapButtonProps) => {
  const { columnParamsLookupMap, mappedFields, setMappedFields } = useData();
  const { selectedPlanningCycle } = useScope();
  const { selectedBattleCardId } = useBattleCard();

  const { mappingFields } = useMappingFields(
    selectedPlanningCycle.id,
    fileType,
    rootHierarchyId,
    +selectedBattleCardId
  );

  const [mappingFieldsLookupMap, setMappingFieldsLookupMap] = useState({});

  // Create a lookup map to get the mapping field object after fuzzy match
  useEffect(() => {
    const map = {};
    mappingFields.forEach((mappingField) => {
      const fieldName = mappingField.value;
      map[getFormedString(fieldName)] = mappingField;
    });
    setMappingFieldsLookupMap(map);
  }, [rowData]);

  const getFormedString = (str: string) => {
    return str.toLowerCase().replace(/[^a-z0-9]/g, '');
  };

  const handleAutoMatchForLoadedColumns = (columnName, matchedField, autoMappedHeaders) => {
    const columnHasBeenMapped = !!columnParamsLookupMap[columnName]?.data?.[columnName]?.key;
    const fieldHasBeenMapped = !!mappedFields?.find((header) => header.key === matchedField.key);

    // Set the value in the matched field only if there is no previous selection and the matched header has not been mapped
    if (!columnHasBeenMapped && !fieldHasBeenMapped) {
      autoMappedHeaders.push({ ...matchedField, autoMatched: true });
      columnParamsLookupMap[columnName].setValue(matchedField);
    }
  };

  const handleAutoMatchForUnLoadedColumns = (columnName, matchedField, autoMappedHeaders) => {
    const fieldHasBeenAutoMapped = !!autoMappedHeaders?.find((header) => header.key === matchedField.key);

    if (!fieldHasBeenAutoMapped) {
      autoMappedHeaders.push({ ...matchedField, autoMatched: true });
      columnParamsLookupMap[columnName]['key'] = matchedField.key;
      columnParamsLookupMap[columnName]['value'] = matchedField.value;
      if (matchedField.properties) {
        columnParamsLookupMap[columnName]['properties'] = matchedField.properties;
      }
    }
  };

  const handleAutoMap = () => {
    const autoMappedHeaders: DataMappingSelectMenuItem[] = [];

    // Create fuzzy set
    const fuzzySet = FuzzySet();
    // Add all the formated mappingFields' name to the set
    mappingFields.forEach((field) => {
      fuzzySet.add(getFormedString(field.value));
    });

    // For each column, format the column name and do fuzzy match
    Object.keys(rowData[0]).forEach((columnName) => {
      // Get the first result (the result with highest match score)
      const matchResult = fuzzySet.get(getFormedString(columnName))?.[0];
      const matchScore = matchResult?.[0];
      const matchField = matchResult?.[1];

      let matchedField;
      // If the match score is larger than 65%, we consider it as a match
      if (matchScore >= 0.65 && !mappingFieldsLookupMap[matchField]?.autoMatched) {
        matchedField = mappingFieldsLookupMap[matchField];
      }

      if (matchedField) {
        // If the colum has been loaded, it will have the data field created by ag-grid in the lookup map, then we can use setValue to update the value.
        if (columnParamsLookupMap[columnName]?.data) {
          handleAutoMatchForLoadedColumns(columnName, matchedField, autoMappedHeaders);
        } else {
          // Otherwise, we have to set the value manually.
          handleAutoMatchForUnLoadedColumns(columnName, matchedField, autoMappedHeaders);
        }
      }
    });

    const newMappedFields = [...mappedFields, ...autoMappedHeaders];
    setMappedFields(newMappedFields);
  };

  return (
    <TextButton
      type="button"
      text={formatMessage('AUTOMATCH')}
      testId="table-controller-auto-mapping-button"
      onClick={handleAutoMap}
      disabled={!rowData.length}
    />
  );
};

export default AutoMapButton;
