import gql from 'graphql-tag';

export const GET_PINS = gql`
  query GetPins($pinSetIds: [Int!]!) {
    getPins(input: { pinSetIds: $pinSetIds }) {
      pinSetId
      pins {
        name
        position {
          lat
          lon
        }
      }
    }
  }
`;
