import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { ReassignRuleHierarchies, ReassignRuleHierarchiesVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const REASSIGN_RULE_HIERARCHIES = gql`
  mutation ReassignRuleHierarchies($input: ReassignRuleHierarchiesInput!) {
    reassignRuleHierarchies(input: $input) {
      success
    }
  }
`;

export const useReassignRuleHierarchies = createUseMutation<ReassignRuleHierarchies, ReassignRuleHierarchiesVariables>(
  REASSIGN_RULE_HIERARCHIES
);
