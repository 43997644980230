// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nNIdd {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  flex: 1;\n}\n\n  .N-mzZ {\n    display: flex;\n    justify-content: center;\n    flex: 1;\n    align-items: center;\n  }\n\n  .\\-5-cf {\n    margin-bottom: 16px !important;\n    font-weight: 600 !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SymonDataMappingDialogContainer": "nNIdd",
	"SymonDataMappingDialogContainer_spinnerContainer": "N-mzZ",
	"SymonDataMappingDialogContainer_heading": "-5-cf"
};
export default ___CSS_LOADER_EXPORT___;
