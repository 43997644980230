import { MapboxMap } from 'react-map-gl';

export const useSetMapLabelLanguage = (map: MapboxMap, languageSuffix: string): void => {
  map?.getStyle().layers.forEach((layer) => {
    try {
      if (layer.id.endsWith('-label')) {
        map.setLayoutProperty(layer.id, 'visibility', 'visible');
        map.setLayoutProperty(layer.id, 'text-field', ['coalesce', ['get', `name${languageSuffix}`], ['get', 'name']]);
      }
    } catch (error) {
      console.error('Unable to set label layer property', { error, layer });
    }
  });
};
