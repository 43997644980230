import React from 'react';

import { Placement, TooltipV2 } from '@varicent/components';

export interface MessageTooltipV2Props {
  content: string | JSX.Element;
  target: JSX.Element;
  placement: Placement;
  disabled?: boolean;
  'data-testid': string;
}

const MessageTooltipV2: React.FC<MessageTooltipV2Props> = (props: MessageTooltipV2Props) => {
  const { content, target, placement, disabled = false } = props;
  return (
    <div data-testid={props['data-testid']}>
      <TooltipV2 content={content} placement={placement} disabled={disabled}>
        {target}
      </TooltipV2>
    </div>
  );
};

export default MessageTooltipV2;
