import React from 'react';

import { Classes } from '@blueprintjs/core';

import TreeNode from 'app/components/TerritoriesAndPlanTargets/ExpandedTerritoryDefineandRefineTree/TreeNode';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { BCInfoLevelEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { CategoryName, ExpandedTerritoryGroupDefineAndRefinePillData, UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ExpandedTerritoryDefineandRefineTree.module.pcss';

const b = block(style);

interface TreeProps {
  treeData: ExpandedTerritoryGroupDefineAndRefinePillData;
  categoryIndex: number;
  categoryName: CategoryName;
  battleCardId: string;
  enableClick?: boolean;
}

export const Tree: React.FC<TreeProps> = ({
  treeData,
  categoryIndex,
  categoryName,
  battleCardId,
  enableClick
}: TreeProps) => {
  const { userRole, userMembershipSpecs } = useUser();
  const { selectedTenant } = useScope();
  const { battleCardLookupMap, selectedBattleCardId } = useBattleCard();
  const { tgExpandedLookupMap } = useTerritoryDefineAndRefine();

  const isTreeNodeExpanded = tgExpandedLookupMap[treeData?.territoryGroupId];
  // eslint-disable-next-line no-restricted-syntax
  const isAdmin = userRole === UserRoleType.ADMIN;

  const userMemberId = userMembershipSpecs.filter(
    (membershipSpec) => membershipSpec.tenantId === selectedTenant.id
  )?.[0].memberId;

  const isOwner = userMemberId === treeData?.owner?.memberId;
  const selectedBattleCardInfoLevel = battleCardLookupMap[selectedBattleCardId]?.battlecardInfoLevel;

  if (isAdmin || isOwner || selectedBattleCardInfoLevel === BCInfoLevelEnum.all) {
    enableClick = true;
    // if the user is not the owner of this node,
    // but is the owner of the parent node, then enableClick stays true
  } else if (!isOwner && enableClick) {
    enableClick = true;
  } else {
    enableClick = false;
  }

  return (
    <>
      <TreeNode
        nodeData={treeData}
        pillIndex={categoryIndex}
        categoryName={categoryName}
        battleCardId={battleCardId}
        enableClick={enableClick}
      />
      {!!treeData?.children?.length && (treeData?.territoryGroupParentId ? isTreeNodeExpanded : true) && (
        <ul className={b('treeNode')}>
          {treeData.children.map((territoryGroup, territoryGroupIndex) => (
            <li
              className={b('listNode')}
              data-testid={`child-${territoryGroup.territoryGroupId}`}
              key={`territoryGroup-${territoryGroup?.territoryGroupId}`}
            >
              <Tree
                treeData={territoryGroup}
                categoryIndex={territoryGroupIndex}
                categoryName={categoryName}
                battleCardId={battleCardId}
                enableClick={enableClick}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

interface ExpandedTerritoryDefineandRefineTreeProps {
  battleCardId: string;
  tdrTree: ExpandedTerritoryGroupDefineAndRefinePillData[];
}

export const ExpandedTerritoryDefineandRefineTree: React.FC<ExpandedTerritoryDefineandRefineTreeProps> = ({
  battleCardId,
  tdrTree
}: ExpandedTerritoryDefineandRefineTreeProps) => {
  const {
    tdrLoadingLookupMap,
    tdrErrorLookupMap,
    selectedPillIdTDR,
    setSelectedPillIdTDR,
    setIsActivityCountSelected
  } = useTerritoryDefineAndRefine();

  const { selectedQuotaComponentId } = useBattleCard();

  const tdrLoading = tdrLoadingLookupMap && tdrLoadingLookupMap[battleCardId];
  const tdrError = tdrErrorLookupMap && tdrErrorLookupMap[battleCardId];

  return (
    <div
      onClick={(event) => {
        // On canvas click, deselect current territory pill.
        // Because we have an onClick handler on the entire TD&R canvas,
        // we need to call event.stopPropagation() in TerritoryPill
        // so that the territory pill's onClick handler can be triggered, and a pill can be selected,
        // without bubbling up and triggering this handler (which would deselect it again)
        if (selectedPillIdTDR) {
          setSelectedPillIdTDR(null);
          setIsActivityCountSelected(false);
        }

        event.stopPropagation();
      }}
      className={b()}
      data-testid="canvas-div"
    >
      {tdrLoading && !tdrTree && !tdrError && (
        <div data-testid="loading-pill" className={`${Classes.SKELETON} ${b('loadingPill')}`} />
      )}
      {tdrTree?.length ? (
        <>
          {tdrTree.map((treeItem, treeItemIndex) => {
            return (
              <Tree
                key={`tree-${treeItem?.territoryGroupId}`}
                treeData={treeItem}
                categoryIndex={treeItemIndex}
                categoryName={treeItem.name as CategoryName}
                battleCardId={battleCardId}
              />
            );
          })}
          <TreeNode
            nodeData={{
              name: formatMessage('UNASSIGNED'),
              territoryGroupId: CategoryName.UNASSIGNED,
              territoryGroupParentId: null,
              battlecardId: battleCardId,
              quotaComponentId: selectedQuotaComponentId,
              hierarchyId: 0,
              hierarchyTopId: 0,
              precedence: null,
              includeItem: false,
              owner: { firstName: '', lastName: '', employeeId: '', memberId: null }
            }}
            battleCardId={battleCardId}
            pillIndex={tdrTree?.length}
            categoryName={CategoryName.UNASSIGNED}
          />
        </>
      ) : null}
    </div>
  );
};

export default ExpandedTerritoryDefineandRefineTree;
