import dayjs from 'dayjs';
import * as yup from 'yup';

import { formatMessage } from 'utils/messages/utils';

export default yup.object().shape({
  title: yup
    .string()
    .max(100)
    .required(formatMessage('PLANNING_CYCLE_ERROR_EMPTY'))
    .label(formatMessage('PLANNING_CYCLE_NAME')),
  planDuration: yup
    .object()
    .shape({
      key: yup.string().required(),
      value: yup.string().required()
    })
    .required()
    .default({ key: '12', value: '12' }),
  interval: yup
    .object()
    .shape({
      key: yup.string().required(),
      value: yup.string().required()
    })
    .required()
    .default({ key: 'Yearly', value: 'Yearly' }),
  startDate: yup.string().required().label('Start Date (Month)').default(dayjs('MM/YYYY').toString())
});
