import { useCallback, useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { GRAPHQL_ENDPOINT } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';
import { useEmbeddedToken } from 'app/containers/App/EmbeddedAuth/hooks/useEmbeddedToken';

import { useShouldUseEmbeddedAuth } from './useShouldUseEmbeddedAuth';

export const useCreateAuthHeader = (): AuthHeader => {
  const shouldUseEmbeddedAuth = useShouldUseEmbeddedAuth();
  const auth0 = useAuth0AuthHeader();
  const embedded = useEmbeddedAuthHeader();

  if (shouldUseEmbeddedAuth) {
    return embedded;
  }
  return auth0;
};

const useAuth0AuthHeader = (): AuthHeader => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const getAuth0Token = useCallback(() => getAccessTokenSilently({ audience: GRAPHQL_ENDPOINT }), []);

  return useMemo(
    () => ({ isLoading, isAuthenticated, getAuthHeader: getAuth0Token }),
    [isLoading, isAuthenticated, getAuth0Token]
  );
};

const useEmbeddedAuthHeader = (): AuthHeader => {
  const { token, isLoading } = useEmbeddedToken();
  const getAuthHeader = useCallback(() => Promise.resolve(token), [token]);
  const isAuthenticated = !!token;

  return useMemo(
    () => ({
      isLoading,
      isAuthenticated,
      getAuthHeader
    }),
    [isLoading, isAuthenticated, getAuthHeader]
  );
};

interface AuthHeader {
  isLoading: boolean;
  isAuthenticated: boolean;
  getAuthHeader: () => Promise<string | null>;
}
