// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Msj3f {\n  display: flex;\n  align-items: center;\n  cursor: inital;\n}\n\n  /* stylelint-disable plugin/selector-bem-pattern */\n\n  ._2Mx7P > svg {\n      height: 24px;\n      fill: rgb(var(--color-gray-2)) !important\n    }\n\n  ._2Mx7P > svg:hover {\n        fill: rgb(var(--color-cobalt-3)) !important;\n      }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserActionCellRenderer": "Msj3f",
	"UserActionCellRenderer_icon": "_2Mx7P"
};
export default ___CSS_LOADER_EXPORT___;
