import React from 'react';

import { Divider } from '@blueprintjs/core';

import TextButton from 'components/Buttons/TextButton/TextButton';

import DataTrayImportButton from 'app/components/DataTray/DataTrayImportButton/DataTrayImportButton';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { BattlecardType, PlanningCycleModal } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './QuotaGridActionButtons.module.pcss';

const b = block(style);

interface QuotaGridActionButtonsProps {
  setOpenedModal: (dataTrayModal: PlanningCycleModal) => void;
}

const QuotaGridActionButtons: React.FC<QuotaGridActionButtonsProps> = ({
  setOpenedModal
}: QuotaGridActionButtonsProps) => {
  const { selectedBattleCardId } = useBattleCard();
  const { battleCardLookupMap } = useBattleCard();

  const { showAccountQuotaDrillIn, hasGridThresholdBeenExceeded } = useGrid();

  const isRootBattleCard = !battleCardLookupMap?.[selectedBattleCardId]?.battlecardParentId;
  const isRollUpBattleCard = battleCardLookupMap?.[selectedBattleCardId]?.battlecardType === BattlecardType.Rollup;

  const isDisabled = !selectedBattleCardId || isRootBattleCard || isRollUpBattleCard || showAccountQuotaDrillIn;
  const [isQuotaGridTotalsSlownessEnabled] = useTreatment(SplitFeatures.QUOTA_GRID_TOTALS_SLOWNESS);
  return (
    <CanUser
      perform={UserAction.TERRITORY_DEFINITION_MODIFY}
      yes={
        <>
          {isQuotaGridTotalsSlownessEnabled && hasGridThresholdBeenExceeded && (
            <>
              <TextButton
                disabled={false}
                text={formatMessage('PUBLISH')}
                type="submit"
                testId={'publish-quota-button'}
                tooltipText={formatMessage('PUBLISH_BUTTON_TOOLTIP')}
                tooltipPlacement="left-start"
                intent="primary"
                className={b('publishButton')}
              />
              <Divider className={b('divider')} />
            </>
          )}
          <DataTrayImportButton
            disabled={isDisabled}
            tooltipText={formatMessage('IMPORT_QUOTAS')}
            onClick={() => setOpenedModal(PlanningCycleModal.IMPORT_QUOTAS)}
            data-testid="import-quota-button"
          />
        </>
      }
    />
  );
};

export default QuotaGridActionButtons;
