// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nkqGo {\n  display: flex;\n  flex-direction: column;\n  width: 800px;\n  height: 800px;\n  border-radius: 4px;\n  box-shadow: 0 2px 4px 0 rgba(0, 21, 41, 0.15);\n  background-color: white;\n  padding: 24px 78px;\n}\n\n  .nkqGo .bp3-input:disabled {\n    border: none;\n  }\n\n  .RIy4l {\n    height: 44px;\n    text-align: center;\n    padding-top: 8px;\n    margin-bottom: 20px;\n  }\n\n  .nUaaJ {\n    font-weight: 700;\n    padding: 0 5px;\n  }\n\n  .P7cf6 {\n    position: relative;\n  }\n\n  ._8YH3n {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n\n  .iyd2F {\n    flex-basis: 50%\n  }\n\n  .iyd2F:first-child {\n      margin-right: 10px;\n    }\n\n  .Dw2mJ {\n    margin-top: 70px;\n  }\n\n  .CMrXc {\n    margin-top: 30px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OrganizationCreationForm": "nkqGo",
	"OrganizationCreationForm_title": "RIy4l",
	"OrganizationCreationForm_messageBold": "nUaaJ",
	"OrganizationCreationForm_url": "P7cf6",
	"OrganizationCreationForm_inlineInputs": "_8YH3n",
	"OrganizationCreationForm_inlineInput": "iyd2F",
	"OrganizationCreationForm_policy": "Dw2mJ",
	"OrganizationCreationForm_button": "CMrXc"
};
export default ___CSS_LOADER_EXPORT___;
