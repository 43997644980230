import React from 'react';

import Dialog from 'components/Dialog/Dialog';

import { useScope } from 'app/contexts/scopeProvider';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ScenarioLimitDialog.module.pcss';

const b = block(style);

interface ScenarioLimitDialogProps {
  onClose: () => void;
  scenarioCount: number;
}

const ScenarioLimitDialog: React.FC<ScenarioLimitDialogProps> = ({ onClose, scenarioCount }) => {
  const { selectedPlanningCycle } = useScope();
  return (
    <Dialog
      title={formatMessage('SCENARIO_LIMIT_REACHED')}
      isOpen
      onSubmit={onClose}
      cancelButtonText={formatMessage('MANAGE_SCENARIOS')}
      confirmButtonText={formatMessage('CLOSE')}
      size="small"
      bodyMinHeight={136}
    >
      <div className={b('body')} data-testid="scenario-limit-body">
        {formatMessage('SCENARIO_LIMIT_DIALOG_BODY', {
          limit: scenarioCount,
          name: selectedPlanningCycle.planningCycleName
        })}
      </div>
    </Dialog>
  );
};

export default ScenarioLimitDialog;
