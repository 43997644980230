import gql from 'graphql-tag';

export const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser($firstName: String!, $lastName: String!) {
    updateCurrentUser(input: { firstName: $firstName, lastName: $lastName }) {
      firstName
      lastName
      subjectId
      emailAddress
    }
  }
`;
