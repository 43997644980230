import { useMemo } from 'react';

import { MapLanguages } from 'utils/messages/utils';

export const useLanguageSuffix = (): string => {
  return useMemo(() => {
    for (const navigatorLanguage of navigator.languages) {
      const supportedLanguage = Object.values(MapLanguages).find((langAbbrev) =>
        navigatorLanguage.toLowerCase().includes(langAbbrev)
      );
      if (supportedLanguage) {
        return `_${supportedLanguage}`;
      }
    }
    return '';
  }, [navigator.languages]);
};
