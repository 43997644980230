import React, { FC, useState } from 'react';

import { NetworkStatus } from '@apollo/client';
import { HTMLHeading } from '@varicent/components';

import { MenuItem } from 'components/menu/MenuItem';

import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { GetRootHierarchies } from 'app/graphql/generated/graphqlApolloTypes';
import { GET_ROOT_HIERARCHIES } from 'app/graphql/queries/getRootHierarchies';

import { CommandCenterMenuItem, HierarchySpec } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { CommandCenterHierarchyItems } from './CommandCenterHierarchyItems';
import { CommandCenterHierarchyToolbar } from './CommandCenterHierarchyToolbar';
import style from './CommandCenterMenu.module.pcss';
import { useDeleteHierarchy } from './hooks/useDeleteHierarchy';

const b = block(style);

export const CommandCenterHierarchy: FC<{
  data?: GetRootHierarchies;
  activeMenu: CommandCenterMenuItem | unknown;
  changeActiveMenuItem: ({ key, hierarchy }: { key: string; hierarchy: HierarchySpec }) => void;
  isOnFullMenuView: boolean;
  hasError: boolean;
  networkStatus: number;
  selectedHierarchy;
  setSelectedHierarchy;
}> = ({
  data,
  activeMenu,
  changeActiveMenuItem,
  isOnFullMenuView,
  hasError,
  networkStatus,
  selectedHierarchy,
  setSelectedHierarchy
}) => {
  const { selectedPlanningCycle } = useScope();
  const { setCommandCenterDrawerState } = useCommandCenter();
  const handleConfirmDeleteModal = (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
    deleteHierarchy({
      variables: {
        hierarchyIds: [selectedHierarchy?.rootHierarchyId],
        hierarchyType: selectedHierarchy?.hierarchyType,
        planningCycleId: selectedPlanningCycle?.id
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ROOT_HIERARCHIES,
          variables: { planningCycleId: selectedPlanningCycle.id }
        }
      ]
    });
  };
  const [showDeleteHierarchyDialog, setShowDeleteHierarchyDialog] = useState<boolean>(false);
  const [deleteHierarchy, { loading: deletingHierarchy }] = useDeleteHierarchy({
    selectedHierarchy,
    setCommandCenterDrawerState,
    setShowDeleteHierarchyDialog,
    setSelectedHierarchy
  });
  return (
    <>
      <li className={b('menuHeader')} data-testid="menu-header-hierarchies">
        {isOnFullMenuView && <HTMLHeading tagLevel="h5" text={formatMessage('HIERARCHIES')} />}
        <CommandCenterHierarchyToolbar
          setShowDeleteHierarchyDialog={setShowDeleteHierarchyDialog}
          showDeleteHierarchyDialog={showDeleteHierarchyDialog}
          selectedHierarchy={selectedHierarchy}
          deletingHierarchy={deletingHierarchy}
          handleConfirmDeleteModal={handleConfirmDeleteModal}
        />
      </li>
      {hasError && (
        <div className={b('errorMessage')} data-testid="command-center-menu-error">
          {formatMessage('UNABLE_TO_RETRIEVE_HIERARCHIES')}
        </div>
      )}
      <CommandCenterHierarchyItems data={data} activeMenu={activeMenu} changeActiveMenuItem={changeActiveMenuItem} />
      {!data && !hasError && networkStatus !== NetworkStatus.refetch && (
        <MenuItem
          data-testid="cc-hierarchy-skeleton-item"
          text={
            <div data-testid="menu-item-skeleton">
              <div className={`${b('menuItemText')} bp3-skeleton`}>
                <HTMLHeading tagLevel="h6" />
              </div>
              <div className={`${b('menuItemSubtext')} bp3-skeleton`} />
            </div>
          }
        />
      )}
    </>
  );
};
