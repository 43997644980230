import { PrimitiveType } from 'intl-messageformat';
import { FormatNumberOptions, createIntl, createIntlCache } from 'react-intl';

import { MessageKey } from 'app/models';

import enUS from './en-US';
import esES from './es-ES';
import ptBR from './pt-BR';
import ptPT from './pt-PT';

const DEFAULT_LOCALE = 'en-US';
const usersLocale = navigator.language;

export enum MapLanguages {
  PORTUGUESE = 'pt',
  ENGLISH = 'en',
  SPANISH = 'es'
}

/* TODO TQP-9319 lazy load message files */
const messages = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-ES': esES,
  'pt-PT': ptPT,
  es: esES,
  pt: ptBR
};

const cache = createIntlCache();

// If the current user's language is not supported (i.e. not in `messages`), then use the default locale.
const selectedLocale = !!messages[usersLocale] ? usersLocale : DEFAULT_LOCALE;
const locale = selectedLocale ?? DEFAULT_LOCALE;

// This function will create a messages object that will fill in missing messages in the selected locale using the default locale (assuming the default locale has all the messages)
export const getMessagesByLocale = (
  messages: Record<string, Record<string, string>>,
  locale: string
): Record<string, string> => {
  const defaultMessages = messages[DEFAULT_LOCALE];
  if (locale === DEFAULT_LOCALE) return defaultMessages;
  const messagesWithDefaults = {};
  for (const key in defaultMessages) {
    messagesWithDefaults[key] = messages?.[locale]?.[key] ?? defaultMessages?.[key];
  }
  return messagesWithDefaults;
};

const intl = createIntl(
  {
    locale,
    messages: getMessagesByLocale(messages, locale)
  },
  cache
);

export type TemplateVariables = Record<string, PrimitiveType>;

export const formatMessage = (id: MessageKey, values?: TemplateVariables): string => {
  return intl.formatMessage(
    {
      id
    },
    values
  );
};

export const formatNumber = (value: number, options?: FormatNumberOptions): string => {
  return intl.formatNumber(value, options);
};

export const formatCurrency = (value: number, currency: string, decimals = 0): string => {
  return intl.formatNumber(value, {
    style: 'currency',
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
    currency
  });
};

export const formatListSummary = (allItems: string[], previewCount: number) => {
  if (allItems.length <= previewCount) return allItems.join(', ');
  const itemsList = allItems.slice(0, previewCount).join(', ');
  return formatMessage('ITEMS_PLUS_OTHERS', { itemsList, otherCount: allItems.length - previewCount });
};
