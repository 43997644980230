import { GetAccountRuleBindingsForQuotaDrillIn } from 'app/graphql/generated/graphqlApolloTypes';

import { SelectedAccountQuotaDrillInCell } from 'app/models';

export interface AccountQuotaDrillInRow extends SelectedAccountQuotaDrillInCell {
  accountName: string;
  accountQuota: number;
}

export const formatBindingsAsDrillInRows = (
  accountRuleBindings: GetAccountRuleBindingsForQuotaDrillIn,
  measureId: number
): AccountQuotaDrillInRow[] => {
  return accountRuleBindings.getAccountRuleBindings.bindings.map((binding) => {
    // Using the first hierarchy for now as we are not supporting multiple hierarchies yet
    // This will be added in the story TQP-14410
    const { hierarchyId, name, key } = binding.hierarchies[0];
    return {
      accountKey: key,
      accountId: hierarchyId,
      accountName: name,
      accountQuota: binding.measures?.[0]?.measureValue || 0,
      measureId
    };
  });
};
