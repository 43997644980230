// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AhgQa {\n  display: flex;\n}\n  .ppG-k {\n    margin: var(--m) 0;\n  }\n  .lJDon {\n    font-size: 12px;\n  }\n  .PG7Q8 {\n    font-weight: bold;\n  }\n  .GdA0z {\n    margin-bottom: var(--xs);\n    font-size: 12px;\n  }\n  .ww1In.bp3-divider {\n      margin: 0;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountMoveWithQuotaDialog": "AhgQa",
	"AccountMoveWithQuotaDialog_dropdownWrapper": "ppG-k",
	"AccountMoveWithQuotaDialog_dialogBody": "lJDon",
	"AccountMoveWithQuotaDialog_accountInfo": "PG7Q8",
	"AccountMoveWithQuotaDialog_dropdownTitle": "GdA0z",
	"AccountMoveWithQuotaDialog_divider": "ww1In"
};
export default ___CSS_LOADER_EXPORT___;
