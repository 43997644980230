// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zyPun {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n  }\n  .p-g9k {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-right: var(--l);\n  }\n  .p-g9k .bp3-popover2-target {\n      display: block;\n    }\n  .ZGMyc {\n    width: 95px;\n  }\n  .ZGMyc .bp3-progress-bar {\n      margin: 0;\n      height: 15px;\n      border-radius: 4px;\n    }\n  .ZGMyc .bp3-progress-bar .bp3-progress-meter {\n        border-radius: 4px;\n      }\n  .nKz0M {\n    width: 95px;\n  }\n  .JmrzV {\n    overflow: hidden;\n    display: flex;\n    align-items: center;\n  }\n  .Ia3Zc {\n    margin-right: var(--xs);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BalancingMetricCellRenderer_balancingMetricRendererWrapper": "zyPun",
	"BalancingMetricCellRenderer_gapIndicatorWrapper": "p-g9k",
	"BalancingMetricCellRenderer_statusBarWrapper": "ZGMyc",
	"BalancingMetricCellRenderer_emptyStatusBarWrapper": "nKz0M",
	"BalancingMetricCellRenderer_balancingMetricValue": "JmrzV",
	"BalancingMetricCellRenderer_total": "Ia3Zc"
};
export default ___CSS_LOADER_EXPORT___;
