// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".odJAa {\n  width: 100%;\n  min-height: 100%;\n  overflow: hidden;\n  display: inline-flex;\n  flex-direction: column;\n}\n\n  .odJAa .bp3-tab-panel {\n    height: 100%;\n    padding-bottom: var(--data-panel-content-bottom-padding);\n  }\n\n  .odJAa .bp3-tab-list {\n    background: white;\n    padding-left: 16px;\n  }\n\n  .Sz4Ce {\n    height: calc(100vh - var(--header-height) - var(--command-center-header-height) - 40px);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DataOverview": "odJAa",
	"DataOverview_tabs": "Sz4Ce"
};
export default ___CSS_LOADER_EXPORT___;
