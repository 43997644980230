import { AllocationItemTypes } from 'app/models/index';

import { formatMessage } from 'utils/messages/utils';

interface AllocationSummaryItem {
  title: string | null;
  value: number | null;
  className: string | null;
}

export const getGapValue = (value1: number | null, value2: number | null): null | number => {
  if (!value1 || !value2) {
    return null;
  }

  return value1 - value2;
};

interface AllocationSummaryProps {
  plannedQuota?: number | null;
  revisedQuota?: number | null;
  assignedQuota?: number | null;
  businessTarget?: number | null;
  topDown?: number | null;
  adjustment?: number | null;
}

export const getAllocationSummaryItemsForPlanPhase = (
  selectedPillIdPlanTargets: string | number,
  summaryItems: AllocationSummaryProps,
  cellClassName: string[],
  isHierarchyBasedTargetEnabled = false
): AllocationSummaryItem[] | [] => {
  const allocationSummaryItems = [];
  const { plannedQuota, businessTarget, topDown, adjustment } = summaryItems;
  const allocationSummaryItemsForBothBCAndTerritoryPills = [
    {
      title: formatMessage('PLANNED_TERRITORY_QUOTA'),
      value: plannedQuota,
      className: cellClassName[0],
      type: AllocationItemTypes.PLANNED_TERRITORY_QUOTA
    },
    {
      title: isHierarchyBasedTargetEnabled ? formatMessage('QUOTA_TARGET_TOP_DOWN') : formatMessage('TOP_DOWN_TARGET'),
      value: topDown,
      className: topDown ? cellClassName[1] : cellClassName[2],
      type: AllocationItemTypes.TOP_DOWN_TARGET
    },
    {
      title: formatMessage('QUOTA_ADJUSTMENTS'),
      value: adjustment,
      className: adjustment ? cellClassName[1] : cellClassName[2],
      type: AllocationItemTypes.QUOTA_ADJUSTMENTS
    }
  ];

  if (selectedPillIdPlanTargets === 'battlecard') {
    const gapValue = getGapValue(plannedQuota, businessTarget);
    allocationSummaryItems.push(
      {
        title: formatMessage('BUSINESS_TARGET'),
        value: businessTarget,
        className: cellClassName[0],
        type: AllocationItemTypes.BUSINESS_TARGET
      },
      ...allocationSummaryItemsForBothBCAndTerritoryPills,
      { title: formatMessage('GAP'), value: gapValue, className: cellClassName[0], type: AllocationItemTypes.GAP }
    );
  } else {
    allocationSummaryItems.push(...allocationSummaryItemsForBothBCAndTerritoryPills);
  }
  return allocationSummaryItems;
};

export const getAllocationSummaryItemsForManagePhase = (
  summaryItems: AllocationSummaryProps,
  cellClassName: string[]
): AllocationSummaryItem[] | [] => {
  const allocationSummaryItems = [];
  const { revisedQuota, assignedQuota, businessTarget } = summaryItems;

  if (assignedQuota === undefined) {
    const gapValue = getGapValue(revisedQuota, businessTarget);
    allocationSummaryItems.push(
      {
        title: formatMessage('BUSINESS_TARGET'),
        value: businessTarget,
        className: cellClassName[0],
        type: AllocationItemTypes.BUSINESS_TARGET
      },
      {
        title: formatMessage('REVISED_TERRITORY_QUOTA'),
        value: revisedQuota,
        className: cellClassName[0],
        type: AllocationItemTypes.REVISED_TERRITORY_QUOTA
      },
      { title: formatMessage('GAP'), value: gapValue, className: cellClassName[0], type: AllocationItemTypes.GAP }
    );
  } else {
    const gapValue = getGapValue(assignedQuota, revisedQuota);
    allocationSummaryItems.push(
      {
        title: formatMessage('REVISED_TERRITORY_QUOTA'),
        value: revisedQuota,
        className: cellClassName[0],
        type: AllocationItemTypes.REVISED_TERRITORY_QUOTA
      },
      {
        title: formatMessage('ASSIGNED_SELLER_QUOTA'),
        value: assignedQuota,
        className: cellClassName[0],
        type: AllocationItemTypes.ASSIGNED_SELLER_QUOTA
      },
      { title: formatMessage('GAP'), value: gapValue, className: cellClassName[0], type: AllocationItemTypes.GAP }
    );
  }

  return allocationSummaryItems;
};

export const getItemValueClassName = (className: string[], itemType: number, value: number | null): string | null => {
  if (
    (itemType === AllocationItemTypes.BUSINESS_TARGET ||
      itemType === AllocationItemTypes.PLANNED_TERRITORY_QUOTA ||
      itemType === AllocationItemTypes.REVISED_TERRITORY_QUOTA ||
      itemType === AllocationItemTypes.ASSIGNED_SELLER_QUOTA) &&
    value
  ) {
    return className[0];
  } else if (itemType === AllocationItemTypes.GAP && value) {
    return value > 0 ? className[1] : className[2];
  }
  return null;
};
