import { useCallback, useRef, useState } from 'react';

import {
  FromMapWorkerMessage,
  MapAnswerOutput,
  MapQuestionInput,
  MapQuestionMessage,
  MapQuestionKind
} from 'app/workers/MapWorker/MapWorkerProtocol';
import { v4 as uuid } from 'uuid';

import { useMapWorkerPostMessage, useOnMapWorkerMessage } from 'app/contexts/mapWorkerContext';

interface MapQuestionState<T extends MapQuestionKind> {
  isLoading: boolean;
  answer: MapAnswerOutput<T>;
  error: string | null;
}

export const useMapQuestion = <T extends MapQuestionKind>(
  questionKind: T
): {
  ask: (input: MapQuestionInput<T>) => void;
  answer: MapAnswerOutput<T> | null;
  error: string | null;
  isLoading: boolean;
} => {
  const questionIdRef = useRef<null | string>(null);

  const [state, setState] = useState<MapQuestionState<T>>({ isLoading: false, answer: null, error: null });

  const postMessage = useMapWorkerPostMessage();

  const ask = useCallback(
    (input: MapQuestionMessage<T>['input']) => {
      const questionId = uuid();
      questionIdRef.current = questionId;

      setState({ isLoading: true, error: null, answer: null });
      postMessage({ type: 'question', questionId, questionKind, input });
    },
    [questionKind]
  );

  const handleAnswer = useCallback(
    (message: FromMapWorkerMessage) => {
      if (message.type !== 'answer') return;
      if (message.questionKind !== questionKind) return;
      if (message.questionId !== questionIdRef.current) return;

      setState({
        answer: message.output,
        error: message.error,
        isLoading: false
      });
    },
    [questionKind]
  );
  useOnMapWorkerMessage(handleAnswer);

  return { ...state, ask };
};
