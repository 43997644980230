import React from 'react';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import image from 'assets/pngs/500.png';

import style from './Fallback.module.pcss';

const b = block(style);

const Fallback = (): JSX.Element => {
  const refreshPage = () => {
    window.location.href = window.location.origin;
  };

  return (
    <div className={b()} data-testid="fallback-page">
      <div className={b('fallbackContainer')}>
        <div className={b('artworkWrapper')}>
          <img className={b('artwork')} src={image} alt={'Error'} />
        </div>
        <div className={b('titleWrapper')} data-testid="fallback-title">
          Oops!
        </div>
        <div className={b('textWrapper')} data-testid="fallback-text">
          {formatMessage('GLOBAL_ERROR_BOUNDARY_TROUBLE')}
          <button className={b('refreshButton')} data-testid="fallback-refresh" onClick={refreshPage}>
            {formatMessage('GLOBAL_ERROR_BOUNDARY_REFRESH')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Fallback;
