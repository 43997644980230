import { useEffect, useMemo } from 'react';

import { InsightsCardState } from 'app/models';

import { useBisectedPanel } from './useBisectedPanel';

const collapsedHeight = 40;

export const Heights = {
  top: {
    minHeight: 222,
    maxHeight: null,
    collapsedHeight
  },
  bottom: {
    [InsightsCardState.Table]: {
      minHeight: 424,
      maxHeight: null,
      collapsedHeight
    },
    [InsightsCardState.None]: {
      minHeight: 0,
      maxHeight: 0,
      collapsedHeight
    }
  }
} as const;

export const useInsightAndGridGutter = (space: number, insightsState: InsightsCardState, insightsHeight: number) => {
  const insightsSummaryHeight = insightsState === InsightsCardState.Summary ? insightsHeight : 0;

  const bottomConstraints = useMemo(() => {
    switch (insightsState) {
      case InsightsCardState.Summary:
        return {
          collapsedHeight,
          minHeight: insightsSummaryHeight,
          maxHeight: insightsSummaryHeight
        };
      case InsightsCardState.Table:
      case InsightsCardState.None:
        return Heights.bottom[insightsState];
      default:
        throw new Error(`Unexpected insights state ${insightsState}`);
    }
  }, [insightsState, insightsSummaryHeight]);

  const [state, action] = useBisectedPanel({
    space,
    top: {
      isExpanded: true,
      height: space,
      constraints: Heights.top
    },
    bottom: {
      isExpanded: true,
      height: 0,
      constraints: bottomConstraints
    }
  });

  useEffect(() => {
    action({ kind: 'space-change', space });
  }, [space, action]);

  useEffect(() => {
    action({
      kind: 'constraints-change',
      constraints: bottomConstraints,
      panel: 'bottom'
    });
  }, [bottomConstraints, action]);

  return [state, action] as const;
};
