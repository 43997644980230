import React, { useState, useMemo, Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { useRouteMatch } from 'react-router-dom';

import { useScope } from 'app/contexts/scopeProvider';

import { useContextSafe } from 'app/hooks/useContextSafe';
import { useGetInsightsMeasureId } from 'app/hooks/useGetInsightsMeasureId';
import useMapComparisonQueryParamState from 'app/hooks/useMapComparisonQueryParamState';
import useQueryParamState from 'app/hooks/useQueryParamState';

import { BaseContext, MapCompareState } from 'app/models';

export interface MapVariantContextValues extends BaseContext {
  isDataTrayMapOpen: boolean;
  setIsDataTrayMapOpen: (isOpen: boolean) => void;
  isPreviewMapOpen: boolean;
  setIsPreviewMapOpen: (isOpen: boolean) => void;

  resetValues: () => void;

  openTerritoryColorPaletteId: number;
  setOpenTerritoryColorPaletteId: Dispatch<SetStateAction<number>>;

  isMapLoading: boolean;
  setIsMapLoading: Dispatch<SetStateAction<boolean>>;
  insightsMeasureId: number | null;

  isMultiMapOpen: boolean;
  setIsMultiMapOpen: Dispatch<SetStateAction<boolean>>;
  isCompareModeDialogOpen: boolean;
  setIsCompareModeDialogOpen: Dispatch<SetStateAction<boolean>>;

  secondarySelectedBattleCardId: string;
  setSecondarySelectedBattleCardId: Dispatch<SetStateAction<string | null>>;
  primarySelectedBattleCardId: string;
  setPrimarySelectedBattleCardId: Dispatch<SetStateAction<string | null>>;

  mapCompareState: MapCompareState;
  setMapCompareState: (value: MapCompareState) => void;
}

export const MapVariantContext = React.createContext<MapVariantContextValues | null>(null);
MapVariantContext.displayName = 'MapVariantContext';

const mapVariantCompareMapsBC1QueryKey = 'bc1';
const mapVariantCompareMapsBC2QueryKey = 'bc2';

export const MapVariantProvider = ({
  children,
  isEmbedded
}: {
  children: React.ReactNode;
  isEmbedded: boolean;
}): JSX.Element => {
  const { selectedTenant } = useScope();
  const match = useRouteMatch<{ deploymentModelSlug: string }>(
    `/:tenantSlug/:planningCycleSlug/:deploymentModelSlug/compare`
  );

  const isInScenarioPlanningMapsMode = !!match;

  const [openTerritoryColorPaletteId, setOpenTerritoryColorPaletteId] = useState<number>(null);
  const [isMapLoading, setIsMapLoading] = useState<boolean>(true);

  const [isDataTrayMapOpen, setIsDataTrayMapOpenRaw] = useState<boolean>(isInScenarioPlanningMapsMode);
  const [isPreviewMapOpen, setIsPreviewMapOpenRaw] = useState<boolean>(false);
  const [isMultiMapOpen, setIsMultiMapOpen] = useState<boolean>(isInScenarioPlanningMapsMode);
  const [primarySelectedBattleCardId, setPrimarySelectedBattleCardId] = useQueryParamState<string | null>(
    mapVariantCompareMapsBC1QueryKey
  );
  const [secondarySelectedBattleCardId, setSecondarySelectedBattleCardId] = useQueryParamState<string | null>(
    mapVariantCompareMapsBC2QueryKey
  );
  const [mapCompareState, setMapCompareState] = useMapComparisonQueryParamState();

  const [isCompareModeDialogOpen, setIsCompareModeDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsMultiMapOpen(isInScenarioPlanningMapsMode);
  }, [selectedTenant?.globalId]);

  const setIsDataTrayMapOpen = useCallback((isOpen: boolean) => {
    setIsDataTrayMapOpenRaw(isOpen);
    if (isOpen) setIsPreviewMapOpenRaw(false);
  }, []);

  const setIsPreviewMapOpen = useCallback((isOpen: boolean) => {
    setIsPreviewMapOpenRaw(isOpen);
    if (isOpen) setIsDataTrayMapOpenRaw(false);
  }, []);

  const isMapOpen = isEmbedded || isDataTrayMapOpen || isPreviewMapOpen;
  const insightsMeasureId = useGetInsightsMeasureId(!isMapOpen);

  const resetValues = () => {
    setIsDataTrayMapOpen(false);
    setIsPreviewMapOpen(false);
    setOpenTerritoryColorPaletteId(null);
    setIsMapLoading(false);
    setIsMultiMapOpen(false);
    setSecondarySelectedBattleCardId(null);
    setPrimarySelectedBattleCardId(null);
    setMapCompareState(null);
  };

  const values = useMemo(() => {
    return {
      isDataTrayMapOpen,
      setIsDataTrayMapOpen,
      isPreviewMapOpen,
      setIsPreviewMapOpen,
      openTerritoryColorPaletteId,
      setOpenTerritoryColorPaletteId,
      isMapLoading,
      setIsMapLoading,
      insightsMeasureId,
      isMultiMapOpen,
      setIsMultiMapOpen,
      isCompareModeDialogOpen,
      setIsCompareModeDialogOpen,
      secondarySelectedBattleCardId,
      setSecondarySelectedBattleCardId,
      primarySelectedBattleCardId,
      setPrimarySelectedBattleCardId,
      mapCompareState,
      setMapCompareState,
      resetValues
    };
  }, [
    isDataTrayMapOpen,
    isPreviewMapOpen,
    openTerritoryColorPaletteId,
    isMapLoading,
    insightsMeasureId,
    isMultiMapOpen,
    isCompareModeDialogOpen,
    secondarySelectedBattleCardId,
    primarySelectedBattleCardId,
    mapCompareState
  ]);

  return <MapVariantContext.Provider value={values}>{children}</MapVariantContext.Provider>;
};

// Custom hook to read these values from
export const useMapVariant = (): MapVariantContextValues => useContextSafe(MapVariantContext);
