import React from 'react';

import { Close, Draggable } from '@carbon/icons-react';
import { Field, useFormikContext } from 'formik';

import SelectMenu from 'components/SelectMenu/SelectMenu';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import SellerSelect from 'app/components/SellerSelect/SellerSelect';

import { BattleCardDesignerRootHierarchy, HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './BattleCardDesignerReorderableListItem.module.pcss';

const b = block(style);

interface ReorderableListItem {
  id: string;
  hierarchies: string;
  hierarchyId: number;
  hierarchyType: HierarchyType | null;
  referToAs: string;
  hierarchyTop: { key: string; value: number } | null;
  owner: { key: string; value: number };
}

interface FormValues {
  battleCardOwner: { key: string; value: unknown };
}
interface BattleCardDesignerReorderableListItemProps {
  item?: ReorderableListItem;
  itemIndex?: number;
  reorderableList?: Array<ReorderableListItem>;
  rootHierarchies: Record<number, BattleCardDesignerRootHierarchy>;
  removeItem?: () => void;
}

const getTGTDropdownItems = (rootHierarchies: Record<number, BattleCardDesignerRootHierarchy>) => {
  return Object.entries(rootHierarchies).map(([hierarchyId, hierarchy]) => {
    return { key: hierarchy.hierarchyTreeData.label, value: +hierarchyId };
  });
};

const BattleCardDesignerReorderableListItem: React.FC<BattleCardDesignerReorderableListItemProps> = ({
  item,
  itemIndex,
  reorderableList,
  rootHierarchies,
  removeItem
}: BattleCardDesignerReorderableListItemProps) => {
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const currentReorderableListItem = reorderableList?.find((x) => x.id === item?.id);
  const index = reorderableList?.indexOf(currentReorderableListItem);
  const numberLabel = index + 1;

  const disabledHierarchies = reorderableList?.map((reorderableListItem) => reorderableListItem.hierarchyTop);

  // Manually set hierarchyType in Formik state so it can be passed to create BC mutation
  const handleSelectItem = (nodeData) => {
    if (nodeData.hierarchyType) {
      setFieldValue(`reorderableListItems[${itemIndex}].hierarchyType`, nodeData.hierarchyType);
    } else {
      setFieldValue(`reorderableListItems[${itemIndex}].hierarchyTop`, nodeData);
      setFieldValue(
        `reorderableListItems[${itemIndex}].hierarchyType`,
        rootHierarchies[nodeData.value]?.hierarchyTreeData.hierarchyType
      );
    }
  };

  return (
    <div className={style.BattleCardDesignerReorderableListItem}>
      {reorderableList && (
        <>
          <Draggable size={24} />
          <div className={b('numberLabel')}>
            <div className={style.BattleCardDesignerReorderableListItem_number} data-testid={'item-number-label'}>
              {numberLabel}
            </div>
          </div>
          <div
            className={b('selectMenu', { validation: !values['reorderableListItems'][itemIndex].hierarchyTop })}
            data-testid={'hierarchy-top'}
          >
            <Field
              name={`reorderableListItems[${itemIndex}].hierarchyTop`}
              placeHolderText={formatMessage('SELECT')}
              component={SelectMenu}
              onChange={handleSelectItem}
              items={getTGTDropdownItems(rootHierarchies)}
              showErrors={false}
              disabledItems={disabledHierarchies}
              allowFlip={false}
              theme="default"
              data-testid={`battlecard-hierarchy-top-${index}-select-menu`}
            />
          </div>
          <div className={b('referToAs')} data-testid={'hierarchy-alias'}>
            <Field
              name={`reorderableListItems[${itemIndex}].referToAs`}
              type="text"
              component={FormTextInputGroup}
              style={{ backgroundColor: 'red' }}
            />
          </div>

          <div className={b('selectMenu')}>
            <Field name={`reorderableListItems[${itemIndex}].owner`} component={SellerSelect} />
          </div>
          <div
            className={b('removeButton', { disabled: reorderableList?.length === 1 })}
            data-testid="remove-button"
            onClick={() => removeItem()}
          >
            <Close size={24} />
          </div>
        </>
      )}
    </div>
  );
};

export default BattleCardDesignerReorderableListItem;
