import { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@apollo/client';

import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';
import { useScope } from 'app/contexts/scopeProvider';

import { HIERARCHY_POLYGON_PAGE_SIZE } from 'app/global/variables';

import {
  HierarchyPolygonId,
  HierarchyPolygonIdVariables,
  HierarchyPolygonId_getPlanningCycleSpec_inUseCountrySourceCatalogItems
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { QUERY_GET_HIERARCHY_POLYGON_ID } from 'app/graphql/queries/getHierarchyPolygonId';

import { BulkHierarchyPolygons, GeoBounds } from 'app/models';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useSetupHierarchyPolygons = (): GeoBounds => {
  const { selectedPlanningCycle } = useScope();
  const postMessage = useMapWorkerPostMessage();

  const [bulkHierarchyPolygons, setBulkHierarchyPolygons] = useState<BulkHierarchyPolygons | null>(null);
  const [firstPage, setFirstPage] = useState<HierarchyPolygonId | null>(null);
  const [combinedBounds, setCombinedBounds] = useState<GeoBounds | null>(null);

  const { fetchMore } = useQuery<HierarchyPolygonId, HierarchyPolygonIdVariables>(QUERY_GET_HIERARCHY_POLYGON_ID, {
    skip: !selectedPlanningCycle?.id,
    variables: {
      planningCycleId: selectedPlanningCycle?.id,
      includeCatalog: true,
      startRow: 1,
      endRow: HIERARCHY_POLYGON_PAGE_SIZE
    },
    onCompleted: (firstPage) => {
      setFirstPage(firstPage);
    },
    onError(error) {
      handleError(null, error);
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('MAP_DATA_ERROR'), 'danger');
      setBulkHierarchyPolygons(null);
    }
  });

  useEffect(() => {
    setFirstPage(null);
  }, [selectedPlanningCycle?.id]);

  useEffect(() => {
    if (!selectedPlanningCycle?.id || !firstPage) {
      setBulkHierarchyPolygons(null);
      return noCleanup;
    }
    let isCancelled = false;

    getAdditionalPages(selectedPlanningCycle.id, firstPage, fetchMore)
      .then((data) => {
        if (isCancelled) return;
        setBulkHierarchyPolygons(data);
        if (data?.inUseCatalogItems) {
          setCombinedBounds(calculateCombinedBounds(data.inUseCatalogItems));
        }
      })
      .catch((error) => {
        if (isCancelled) return;
        handleError(null, error);
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('MAP_DATA_ERROR'), 'danger');
        setBulkHierarchyPolygons(null);
      });

    return () => {
      isCancelled = true;
    };
  }, [selectedPlanningCycle?.id, firstPage]);

  useEffect(() => {
    postMessage({
      type: 'geo-hierarchy-change',
      bulkHierarchyPolygons
    });
  }, [bulkHierarchyPolygons]);

  return combinedBounds;
};

const noCleanup = () => {
  // Effect has no actions to perform on cleanup
};

type GetHierarchyPolygons = (input: {
  variables: HierarchyPolygonIdVariables;
}) => Promise<{ data: HierarchyPolygonId }>;

const getAdditionalPages = async (
  planningCycleId: number,
  firstPage: HierarchyPolygonId,
  getPage: GetHierarchyPolygons
): Promise<BulkHierarchyPolygons> => {
  const totalRowCount = firstPage.getHierarchyPolygonId.totalCount ?? 0;
  const pageCount = Math.ceil(totalRowCount / HIERARCHY_POLYGON_PAGE_SIZE);
  const pagePromises = [Promise.resolve(firstPage.getHierarchyPolygonId)];

  for (let pageIndex = 1; pageIndex < pageCount; pageIndex++) {
    pagePromises.push(
      getPage({
        variables: {
          planningCycleId,
          includeCatalog: false,
          startRow: HIERARCHY_POLYGON_PAGE_SIZE * pageIndex + 1,
          endRow: HIERARCHY_POLYGON_PAGE_SIZE * (pageIndex + 1)
        }
      }).then(({ data }) => data.getHierarchyPolygonId)
    );
  }

  return {
    inUseCatalogItems: firstPage.getPlanningCycleSpec.inUseCountrySourceCatalogItems,
    compressedHierarchyPolygonPages: await Promise.all(pagePromises)
  };
};

const calculateCombinedBounds = (
  catalogItems: HierarchyPolygonId_getPlanningCycleSpec_inUseCountrySourceCatalogItems[]
): GeoBounds => {
  let maxLat = -Infinity;
  let minLat = Infinity;
  let maxLon = -Infinity;
  let minLon = Infinity;

  catalogItems.forEach((catalogItem) => {
    const { bounds } = catalogItem;
    maxLat = Math.max(maxLat, bounds.maxLat);
    minLat = Math.min(minLat, bounds.minLat);
    maxLon = Math.max(maxLon, bounds.maxLon);
    minLon = Math.min(minLon, bounds.minLon);
  });

  return { maxLat, minLat, maxLon, minLon };
};
