// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-DAC7.bp3-heading {\n    font-size: 0.875rem;\n  }\n  .Ilv61 {\n    padding: unset;\n    margin: unset;\n  }\n  .ATNK1 {\n    display: flex;\n  }\n  .ATNK1 .bp3-radio {\n      font-weight: normal;\n      color: rgb(var(--color-dark-gray-3));\n    }\n  .bmooQ {\n    margin-left: 6px;\n  }\n.T9jK\\+.bp3-menu {\n  padding: 8px 0 0;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PinMenuV2_menuHeading": "-DAC7",
	"PinMenuV2_menuItem": "Ilv61",
	"PinMenuV2_radioLabel": "ATNK1",
	"PinMenuV2_spinner": "bmooQ",
	"PinMenuV2": "T9jK+"
};
export default ___CSS_LOADER_EXPORT___;
