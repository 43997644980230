import { FormatNumberOptions } from 'react-intl';

import {
  NumberInputFormatStyle,
  FormattedCurrencyInputIntlConfig,
  FormattedCurrencyInputLocaleConfig
} from 'app/models';

import { formatNumber } from 'utils/messages/utils';

import { getLocale } from './getLocale';

const getFormatConfig = (
  formatStyle: NumberInputFormatStyle,
  currency: string,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
): FormatNumberOptions => {
  if (formatStyle === NumberInputFormatStyle.CURRENCY) {
    return {
      currency,
      style: 'currency',
      minimumFractionDigits,
      maximumFractionDigits
    };
  } else if (formatStyle === NumberInputFormatStyle.PERCENTAGE) {
    return {
      style: 'percent',
      minimumFractionDigits,
      maximumFractionDigits
    };
  } else {
    return {
      style: 'decimal',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4
    };
  }
};

export const getFormattedValue = (
  value: number | '',
  currency: string,
  formatStyle: NumberInputFormatStyle,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
): string => {
  if (!value || isNaN(value)) {
    return '';
  }
  let styledValue;
  if (formatStyle === NumberInputFormatStyle.CURRENCY || formatStyle === NumberInputFormatStyle.NUMBER) {
    styledValue = value;
  } else if (formatStyle === NumberInputFormatStyle.PERCENTAGE) {
    styledValue = value / 100;
  }
  return formatNumber(
    styledValue,
    getFormatConfig(formatStyle, currency, minimumFractionDigits, maximumFractionDigits)
  );
};

export const getLastDigitPosition = (value: string, formatStyle: NumberInputFormatStyle, currency: string): number => {
  const parsedValue =
    NumberInputFormatStyle.PERCENTAGE === formatStyle ? numberUnformatter(value) / 100 : numberUnformatter(value);

  const formatter = new Intl.NumberFormat(getLocale(), getFormatConfig(formatStyle, currency));
  const formattedValue = formatter.format(parsedValue);
  const numberString = formatter
    .formatToParts(parsedValue)
    .map(({ type, value }) => {
      switch (type) {
        case 'integer':
          return value;
        case 'group':
          return value;
        default:
          return '';
      }
    })
    .reduce((string, part) => string + part);
  return formattedValue.indexOf(numberString) + numberString.length;
};

const getDecimalSeparator = (): string => {
  const numberWithDecimalSeparator = 1.1;
  return Intl.NumberFormat(getLocale())
    .formatToParts(numberWithDecimalSeparator)
    .find((part) => part.type === 'decimal')?.value;
};

export const numberUnformatter = (formattedValue: string): number => {
  if (!formattedValue) return null;
  const decimalSeparator = getDecimalSeparator();

  const splitString = formattedValue?.split(decimalSeparator);

  const intString = splitString[0]?.replace(/[^\d-]/g, ''); //discards any non numeric except the negative sign
  const decimalString = splitString[1]?.replace(/\D/g, ''); //discards any non numeric
  return parseFloat(`${intString}.${decimalString}`);
};

export const isValidDecimalInput = (inputValue: string, maxDecimalPlaces: number): boolean => {
  const regex = new RegExp(`^\\d*(\\.\\d{0,${maxDecimalPlaces}})?$`);
  return regex.test(inputValue);
};

export const getCurrencySymbol = (currency: string): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumSignificantDigits: 1
  });

  // we need to provide a number to format in order for the currency symbol to appear
  const numberWithCurrencySymbol = formatter.format(1);

  // remove the unneeded number and trim whitespace, since we only want the currency symbol
  return numberWithCurrencySymbol.replace('1', '').trim();
};

// from https://github.com/cchanxzy/react-currency-input-field/blob/master/src/components/utils/getLocaleConfig.ts
export const getFormattedCurrencyInputLocaleConfig = (
  intlConfig?: FormattedCurrencyInputIntlConfig
): FormattedCurrencyInputLocaleConfig => {
  const defaultConfig: FormattedCurrencyInputLocaleConfig = {
    currencySymbol: '',
    groupSeparator: '',
    decimalSeparator: '',
    prefix: '',
    suffix: ''
  };

  const { locale, currency } = intlConfig || {};
  const numberFormatter = locale
    ? new Intl.NumberFormat(locale, currency ? { currency, style: 'currency' } : undefined)
    : new Intl.NumberFormat();

  return numberFormatter.formatToParts(1000.1).reduce((prev, curr, i): FormattedCurrencyInputLocaleConfig => {
    if (curr.type === 'currency') {
      if (i === 0) {
        return { ...prev, currencySymbol: curr.value, prefix: curr.value };
      } else {
        return { ...prev, currencySymbol: curr.value, suffix: curr.value };
      }
    }
    if (curr.type === 'group') {
      return { ...prev, groupSeparator: curr.value };
    }
    if (curr.type === 'decimal') {
      return { ...prev, decimalSeparator: curr.value };
    }

    return prev;
  }, defaultConfig);
};
