import React from 'react';

import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { BattlecardType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import navaidCivil from 'assets/svgs/navaid--civil.svg';
import navaidMilitaryCivil from 'assets/svgs/navaid--miliary-civil.svg';
import navaidMilitary from 'assets/svgs/navaid--military.svg';
import navaidVor from 'assets/svgs/navaid--vor.svg';

import style from './BattleCardIcon.module.pcss';

const b = block(style);

interface BattleCardIconProps {
  isRootCard: boolean;
  battleCardType: BattlecardType;
  className: string;
}
const BattleCardIcon: React.FC<BattleCardIconProps> = ({
  isRootCard,
  battleCardType,
  className
}: BattleCardIconProps): JSX.Element => {
  let imgSrc;
  let battlecardTooltip;
  if (isRootCard) {
    imgSrc = navaidMilitary;
    battlecardTooltip = formatMessage('ROOT');
  } else if (battleCardType === BattlecardType.Rollup) {
    imgSrc = navaidVor;
    battlecardTooltip = formatMessage('BATTLECARD_TYPE_ROLL_UP');
  } else if (battleCardType === BattlecardType.PrimaryTeam) {
    imgSrc = navaidCivil;
    battlecardTooltip = formatMessage('BATTLECARD_TYPE_PRIMARY');
  } else if (battleCardType === BattlecardType.DirectOverlay) {
    imgSrc = navaidMilitaryCivil;
    battlecardTooltip = formatMessage('BATTLECARD_TYPE_OVERLAY');
  }

  const headerIcon = <img className={`${b('icon')} ${className}`} src={imgSrc} alt="" data-testid="battle-card-icon" />;
  return (
    <MessageTooltip content={formatMessage('CARD_TYPE') + battlecardTooltip} placement={'top'} target={headerIcon} />
  );
};

export default BattleCardIcon;
