import React, { useEffect, useState } from 'react';

import { ArrowRight } from '@carbon/icons-react';
import { Spinner } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { useGetFileProcessStatus } from 'app/graphql/hooks/useGetFileProcessStatus';

import {
  FileStatus,
  TerritoryOptimizationJob,
  TerritoryOptimizationStatus,
  TerritoryOptimizationStep
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import optimizationFailed from 'assets/svgs/optimization-failed.svg';
import taskOptimization from 'assets/svgs/task-optimization.svg';

import style from './OptimizeTerritories.module.pcss';

const b = block(style);

interface OptimizeTerritoriesProps {
  handleBack: (currentStep, prevStep) => void;
  handleNext: (completedStep, nextStep) => void;
}

const OptimizeTerritories: React.FC<OptimizeTerritoriesProps> = ({
  handleBack,
  handleNext
}: OptimizeTerritoriesProps) => {
  const {
    optimizationTarget,
    optimizationStatus,
    setOptimizationStatus,
    optimizedTerritoryInProgress,
    setOptimizedTerritoryInProgress
  } = useTerritoryOptimization();
  const [status, setStatus] = useState(TerritoryOptimizationStatus.OPTIMIZING);

  const handleFail = () => {
    setOptimizedTerritoryInProgress(null);
  };

  const handleComplete = (territoryInProgressStatus: TerritoryOptimizationJob) => {
    setOptimizedTerritoryInProgress(territoryInProgressStatus);
  };

  const { data, loading, called, pollFileProcessStatus } = useGetFileProcessStatus({
    onFail: handleFail,
    onComplete: handleComplete
  });

  useEffect(() => {
    if (optimizedTerritoryInProgress) {
      void pollFileProcessStatus({
        variables: {
          input: {
            fileIds: [optimizedTerritoryInProgress.fileId]
          }
        }
      });
    }
  }, [optimizedTerritoryInProgress]);

  useEffect(() => {
    if (!loading && called && data) {
      if (data.getFileProcessStatus[0].fileMetadata.status === FileStatus.FAILED) {
        setStatus(TerritoryOptimizationStatus.FAILED);
        // status pending means that it is ready to be published
      } else if (data.getFileProcessStatus[0].fileMetadata.status === FileStatus.PENDING) {
        setStatus(TerritoryOptimizationStatus.OPTIMIZED);
      }
    }
  }, [loading, called, data, optimizationStatus]);

  const LoadingPage = (
    <div data-testid="optimize-territories-loading">
      <div className={b('imageContainer')}>
        <Spinner intent="primary" size={40} />
      </div>

      <p>
        {<strong>{formatMessage('OPTIMIZING_TERRITORIES')}</strong>}
        <br />
        {formatMessage('OPTIMIZING_TERRITORIES_DESCRIPTION')}
      </p>
    </div>
  );

  const FailedPage = (
    <div data-testid="optimize-territories-failed">
      <div className={b('imageContainer')}>
        <img src={optimizationFailed} alt="optimization-failed" />
      </div>

      <p className={b('description')}>
        {<strong>{formatMessage('OPTIMIZATION_FAILED')}</strong>}
        <br />
        {formatMessage('OPTIMIZATION_FAILED_DESCRIPTION')}
      </p>
      <div className={b('buttonContainer')}>
        <TextButton
          text={formatMessage('BACK_TO_CONSTRAINTS')}
          type={'button'}
          intent="primary"
          large={false}
          testId={'back-to-constraints-button'}
          onClick={() => {
            setOptimizationStatus(TerritoryOptimizationStatus.UNSTARTED);
            handleBack(TerritoryOptimizationStep.OPTIMIZE_TERRITORIES, TerritoryOptimizationStep.CONSTRAINTS);
          }}
        />
      </div>
    </div>
  );

  const SuccessPage = (
    <div data-testid="optimize-territories-success">
      <div className={b('imageContainer')}>
        <img src={taskOptimization} alt="task-optimization" />
      </div>

      <p>
        {<strong>{formatMessage('OPTIMIZATION_SUCCESS')}</strong>}
        <br />
        {formatMessage('OPTIMIZED_TERRITORIES', {
          territoryGroup: optimizationTarget?.name
        })}
      </p>
      <div className={b('buttonContainer')}>
        <TextButton
          rightIcon={<ArrowRight />}
          text={formatMessage('VIEW_RESULTS')}
          intent="primary"
          type={'button'}
          large={false}
          testId={'view-optimization-results-button'}
          onClick={() => {
            handleNext(TerritoryOptimizationStep.OPTIMIZE_TERRITORIES, TerritoryOptimizationStep.OPTIMIZE_RESULTS);
          }}
        />
      </div>
    </div>
  );

  return (
    <div className={b()} data-testid="optimize-territories">
      <div className={b('container')}>
        {status === TerritoryOptimizationStatus.OPTIMIZING && LoadingPage}
        {status === TerritoryOptimizationStatus.FAILED && FailedPage}
        {status === TerritoryOptimizationStatus.OPTIMIZED && SuccessPage}
      </div>
    </div>
  );
};

export default OptimizeTerritories;
