// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ajrdi {\n    display: flex;\n    box-shadow: var(--elevation-2);\n    border-radius: 5px;\n    padding: 16px;\n    height: auto;\n    margin: 20px 0;\n  }\n  ._1gYEg {\n    padding-right: 10px;\n  }\n  .tv4Wn {\n    flex: 1;\n  }\n  .qsPt4 {\n    margin-top: 5px;\n  }\n  .qsPt4 textarea.bp3-input {\n      resize: none;\n    }\n  .XrLDl {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 10px;\n  }\n  .Lib2y .bp3-button.bp3-large {\n      box-shadow: none !important;\n      color: rgb(var(--color-gray-2)) !important;\n      min-height: 0;\n      min-width: 0;\n      padding: 5px 10px;\n      margin-right: 15px;\n    }\n  .xICf4 .bp3-button.bp3-large {\n      min-height: 0;\n      min-width: 0;\n      padding: 5px 10px;\n      color: white;\n      background-color: rgb(var(--color-cobalt-3));\n    }\n  .gPZC0 {\n    display: flex;\n    flex-direction: row;\n    margin-top: 5px;\n  }\n  .AblsV {\n    margin-right: 5px;\n    padding: 4px 8px;\n  }\n  ._3Fd0K {\n    padding: 2px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommentForm_newCommentSection": "ajrdi",
	"CommentForm_profilePictureContainer": "_1gYEg",
	"CommentForm_commentFieldContainer": "tv4Wn",
	"CommentForm_commentForm": "qsPt4",
	"CommentForm_buttons": "XrLDl",
	"CommentForm_cancelButton": "Lib2y",
	"CommentForm_commentButton": "xICf4",
	"CommentForm_tagsContainer": "gPZC0",
	"CommentForm_tagRow": "AblsV",
	"CommentForm_commentEditArea": "_3Fd0K"
};
export default ___CSS_LOADER_EXPORT___;
