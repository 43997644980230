import { formatMessage } from 'utils/messages/utils';

export const TERRITORY_GRID_TEXT_FILTER_COLUMNS = [
  {
    label: formatMessage('TERRITORY_ID'),
    colId: 'territoryId',
    clearButtonTestId: 'clear-territory-id-button'
  },
  {
    label: formatMessage('TERRITORY_NAME'),
    colId: 'territoryName',
    clearButtonTestId: 'clear-territory-name-button'
  }
];
