import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export const useSearchParam = (key: string): string | null => {
  const { search } = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(key);
  }, [search, key]);
};
