import { ColDef } from '@ag-grid-community/core';
import capitalize from 'lodash.capitalize';

import DateRangeCellEditor from 'app/components/AdvancedGrid/CellEditors/DateRangeCellEditor/DateRangeCellEditor';
import CurrencyCellRenderer from 'app/components/AdvancedGrid/CellRenderers/CurrencyCellRenderer/CurrencyCellRenderer';
import EditableFieldCellRenderer from 'app/components/AdvancedGrid/CellRenderers/EditableFieldCellRenderer/EditableFieldCellRenderer';
import TextRenderer from 'app/components/AdvancedGrid/CellRenderers/TextRenderer/TextRenderer';

import { MONTH_PRESET_ARRAY } from 'app/constants/DataTrayConstants';

import {
  MEASURES_GRID_COLUMN_WIDTH,
  MEASURES_GRID_COLUMN_WIDTH_EXTRA_LARGE,
  MEASURES_GRID_COLUMN_WIDTH_SMALL
} from 'app/global/variables';

import {
  GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_quotaComponents,
  ISheetDefinitionDef
} from 'app/graphql/generated/graphqlApolloTypes';

import {
  FieldIdsLookUpTable,
  GridFields,
  GridHeaders,
  Lookup,
  QuotaComponentEditableType,
  SelectedQuotaDrillInTerritory,
  TerritorySheetGridColumnName
} from 'app/models';

import { formatNumber } from 'utils/messages/utils';

import {
  enableEditableColumns,
  getCellClass,
  getCellEditorFramework,
  getCellEditorParams,
  getCellRendererParams,
  getHeaderClass,
  getHeaderComponent,
  getHeaderParams,
  getHiddenColumns,
  valueSetter,
  valueSetterForAdjEffectiveDate,
  valueSetterForTerritoryEffectiveDate
} from './TerritoryQuotaGridUtils';

interface TerritorySheetColumnDefsParams {
  sheetDefinitions: ISheetDefinitionDef[];
  currency: string;
  headerClassNames: string[];
  cellClassNames: string[];
  lookups: Lookup[];
  fieldIdsLookUpTable: FieldIdsLookUpTable;
  selectedQuotaComponentId: number;
  previewDialogOpener: () => void;
  canEditTerritoryQuota: boolean;
  onRevisedQuotaClicked: (value: SelectedQuotaDrillInTerritory) => void;
  isAccountMoveWithQuotaMoveSettingEnabled: boolean;
  isAccountMoveWithQuotaMoveFFEnabled: boolean;
}

const buildTerritoryQuotaGridColumnDefs = (params: TerritorySheetColumnDefsParams): Array<ColDef> => {
  const {
    sheetDefinitions,
    currency,
    headerClassNames,
    cellClassNames,
    lookups,
    selectedQuotaComponentId,
    previewDialogOpener,
    canEditTerritoryQuota,
    isAccountMoveWithQuotaMoveSettingEnabled,
    isAccountMoveWithQuotaMoveFFEnabled,
    onRevisedQuotaClicked
  } = params;

  const columnDefs = [];

  const currencyFormatter = (value, currency) => {
    if (!value) return '';
    return formatNumber(value, { style: 'currency', currency });
  };

  const valueFormatter = (params, isTextColumn) => {
    if (!params.value) return '';
    return isTextColumn ? params.value : currencyFormatter(params.value, currency);
  };

  const getCellValueForEffectiveDatingOfQuotaAdjustmnet = (params) => {
    const field = params.colDef.field;
    const parentMeasureNameMap = {
      [TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT]: GridHeaders.PARENT_QUOTA_ADJUSTMENTS,
      [TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE]: GridHeaders.PARENT_ADJ_EFFECTIVE_DATE
    };
    const parentMeasureName = parentMeasureNameMap[field];
    const parentRowMeasureValue = params.data[parentMeasureName];
    const emptyCellValue =
      isAccountMoveWithQuotaMoveFFEnabled &&
      field === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT &&
      isAccountMoveWithQuotaMoveSettingEnabled
        ? '0'
        : '';
    if (!params.value) return parentRowMeasureValue && params.node.level === 0 ? parentRowMeasureValue : emptyCellValue;
    return params.value;
  };

  const getCellValue = (params) => {
    const field = params.colDef.field;
    const isEffectiveDatingColumn =
      field === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT ||
      field === TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE;
    return isEffectiveDatingColumn ? getCellValueForEffectiveDatingOfQuotaAdjustmnet(params) : params.value;
  };

  columnDefs.push({
    headerName: TerritorySheetGridColumnName.TERRITORY_NAME,
    headerClass: headerClassNames[0],
    field: GridFields.TERRITORY_NAME,
    minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
    flex: 1,
    resizable: true,
    pinned: 'left',
    cellRendererFramework: TextRenderer,
    cellRendererParams: {
      isTooltipDisplayed: !canEditTerritoryQuota
    }
  });

  columnDefs.push({
    headerName: capitalize(TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES),
    headerClass: headerClassNames[0],
    field: TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES,
    minWidth: MEASURES_GRID_COLUMN_WIDTH_EXTRA_LARGE,
    flex: 1,
    editable: (params) =>
      canEditTerritoryQuota &&
      enableEditableColumns(params, isAccountMoveWithQuotaMoveSettingEnabled, isAccountMoveWithQuotaMoveFFEnabled),
    resizable: true,
    cellEditorFramework: DateRangeCellEditor,
    cellEditorParams: (params) => {
      return {
        name: TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES,
        effectiveStartDate: params.data.effectiveDate,
        effectiveEndDate: params.data.endDate
      };
    },
    valueSetter: (params) => valueSetterForTerritoryEffectiveDate(params),
    cellClass: (params) =>
      getCellClass(TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES, true, cellClassNames, params),
    cellRendererFramework: EditableFieldCellRenderer,
    cellRendererParams: (params) => {
      const value = params.data[TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES];
      return getCellRendererParams(
        value,
        params,
        canEditTerritoryQuota,
        isAccountMoveWithQuotaMoveFFEnabled,
        isAccountMoveWithQuotaMoveSettingEnabled
      );
    }
  });

  sheetDefinitions.forEach((column) => {
    const measureName = column.measureName;
    const isAdjustmentEffectiveDateColumn = measureName === TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE;
    const isRevisedTerritoryQuotaColumn = measureName === TerritorySheetGridColumnName.REVISED_TERRITORY_QUOTA;
    const selectedQuotaComponent = column?.quotaComponents?.find(
      (qc) => qc.quotaComponentId === selectedQuotaComponentId
    ) as GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_quotaComponents;
    const isEditableColumn = selectedQuotaComponent?.roles[0]?.editable === QuotaComponentEditableType.TRUE;
    const isTextColumn =
      measureName === TerritorySheetGridColumnName.SEASONALITY ||
      measureName === TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE;
    const baseColumnDef = {
      headerName: capitalize(measureName),
      headerComponentFramework: getHeaderComponent(column),
      headerComponentParams: getHeaderParams(column, previewDialogOpener),
      headerClass: getHeaderClass(column, headerClassNames),
      field: measureName,
      resizable: true,
      hide: getHiddenColumns(
        measureName,
        isAccountMoveWithQuotaMoveFFEnabled,
        isAccountMoveWithQuotaMoveSettingEnabled
      ),
      minWidth: isAdjustmentEffectiveDateColumn ? MEASURES_GRID_COLUMN_WIDTH_EXTRA_LARGE : MEASURES_GRID_COLUMN_WIDTH,
      flex: 1,
      valueFormatter: (params) => valueFormatter(params, isTextColumn),
      cellClass: (params) => getCellClass(column.measureFormatType, false, cellClassNames, params, measureName),
      cellRendererSelector: (params) => {
        if (
          isRevisedTerritoryQuotaColumn &&
          isAccountMoveWithQuotaMoveFFEnabled &&
          isAccountMoveWithQuotaMoveSettingEnabled
        ) {
          const accountQuotaMonthlyBreakdownTotals = {};
          // Helper function to find all the monthly breakdown values and format it into Month: value format
          // for each month within the pc duration
          Object.entries(params.data).forEach(([key, value]) => {
            if (MONTH_PRESET_ARRAY.includes(key)) {
              accountQuotaMonthlyBreakdownTotals[key] = value;
            }
          });

          const revisedTerritoryQuotaValue = params.data[TerritorySheetGridColumnName.REVISED_TERRITORY_QUOTA];
          if (!revisedTerritoryQuotaValue) {
            params.data[TerritorySheetGridColumnName.REVISED_TERRITORY_QUOTA] = 0;
          }

          return {
            frameworkComponent: CurrencyCellRenderer,
            params: {
              formatNumber,
              currency,
              isLink: true,
              onCellClick: ({ ruleId, territoryName, territoryId, territoryGroupId, effectiveDate, endDate }) => {
                onRevisedQuotaClicked({
                  ruleId,
                  territoryName,
                  territoryId,
                  territoryGroupId,
                  effectiveDate,
                  endDate,
                  accountQuotaTotal: revisedTerritoryQuotaValue,
                  accountQuotaMonthlyBreakdownTotals
                });
              }
            }
          };
        }
        return null;
      }
    };

    if (isEditableColumn) {
      columnDefs.push({
        ...baseColumnDef,
        editable: (params) =>
          canEditTerritoryQuota &&
          enableEditableColumns(params, isAccountMoveWithQuotaMoveSettingEnabled, isAccountMoveWithQuotaMoveFFEnabled),
        cellEditorParams: (params) => getCellEditorParams(column, lookups, params, currency),
        cellEditorFramework: getCellEditorFramework(measureName),
        cellRendererFramework: EditableFieldCellRenderer,
        cellRendererParams: (params) => {
          const cellValue = getCellValue(params);
          const value = isTextColumn ? cellValue : currencyFormatter(cellValue, currency);
          return getCellRendererParams(
            value,
            params,
            canEditTerritoryQuota,
            isAccountMoveWithQuotaMoveFFEnabled,
            isAccountMoveWithQuotaMoveSettingEnabled
          );
        },
        valueSetter: (params) => {
          const field = params.colDef.field;
          return field === TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE
            ? valueSetterForAdjEffectiveDate(params)
            : valueSetter(params);
        }
      });
    } else {
      columnDefs.push({ ...baseColumnDef });
    }
  });
  return columnDefs;
};

export default buildTerritoryQuotaGridColumnDefs;
