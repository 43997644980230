import React, { FC } from 'react';

import AuthUserWrapper from 'app/containers/App/AuthUserWrapper/AuthUserWrapper';
import PrivateRoute from 'app/containers/App/Router/PrivateRoute/PrivateRoute';
import { TenantRouter } from 'app/containers/App/TenantRouter/TenantRouter';

import { RoutePaths } from './routePaths';

const routes = [
  `/`,
  RoutePaths.WELCOME,
  `/:tenantSlug`,
  `/:tenantSlug/:planningCycleSlug`,
  `/:tenantSlug/:planningCycleSlug/compare`,
  `/:tenantSlug/:planningCycleSlug/:deploymentModelSlug`,
  `/:tenantSlug/:planningCycleSlug/:deploymentModelSlug/:deploymentModelComponent`,
  `/:tenantSlug${RoutePaths.USER_PROFILE}`,
  `/:tenantSlug${RoutePaths.CREATE_PLAN}`,
  `/:tenantSlug${RoutePaths.EDIT_PLAN}/:planningCycleSlug`,
  RoutePaths.NEW_ORGANIZATION
];

export const NativeRoutes: FC = () => (
  <PrivateRoute
    path={routes}
    exact
    render={() => (
      <AuthUserWrapper>
        <TenantRouter />
      </AuthUserWrapper>
    )}
  />
);
