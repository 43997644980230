import { useMemo } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';

import { MapSelectionTarget, RuleForMap } from 'app/models';

import { useInsightsCardStatsForGeoSelection } from './useInsightsCardStatsForGeoSelection';

interface MapSelectionStats {
  isLoading: boolean;
  measureValue: number;
  numberOfAccounts?: number;
  numberOfOverrides?: number;
  size: number;
}

const emptyStats: MapSelectionStats = {
  isLoading: false,
  measureValue: 0,
  numberOfAccounts: 0,
  numberOfOverrides: 0,
  size: 0
};

const getSelectedTerritoryStats = (
  mappableTerritoryRules: RuleForMap[],
  selectedRuleIds: number[]
): MapSelectionStats => {
  const selectedTerritorySet = new Set(selectedRuleIds);
  const stats = { ...emptyStats, size: selectedRuleIds.length };
  mappableTerritoryRules.forEach((rule) => {
    if (!selectedTerritorySet.has(rule.ruleId)) return;
    stats.numberOfAccounts += rule.numberOfAccounts;
    stats.numberOfOverrides += rule.accountModifierCount;
    stats.measureValue += rule.measureValue;
  });
  return stats;
};

export const useMapSelectionStats = (
  selectionTarget: MapSelectionTarget,
  selectedHierarchyIds: number[],
  showActivityStats: boolean,
  mappableTerritoryRules: RuleForMap[],
  selectedTerritories: number[]
): MapSelectionStats => {
  const { selectedBattleCardId, selectedQuotaComponentId, selectedTerritoryGroupTypeId } = useMapContextRedistributor();
  const { insightsMeasureId } = useMapVariant();

  const shouldSkip = !showActivityStats || selectedHierarchyIds.length === 0 || !insightsMeasureId;

  const polygonSelectionStatsTGT = {
    ...useInsightsCardStatsForGeoSelection(shouldSkip, {
      battlecardId: +selectedBattleCardId,
      geographyIds: selectedHierarchyIds,
      quotaComponentId: selectedQuotaComponentId,
      territoryGroupId: selectedTerritoryGroupTypeId,
      measureId: insightsMeasureId
    }),
    size: selectedHierarchyIds.length
  };

  const polygonInfoStats = polygonSelectionStatsTGT;

  const territoryInfoStats = useMemo(
    () =>
      selectionTarget === MapSelectionTarget.territories
        ? getSelectedTerritoryStats(mappableTerritoryRules, selectedTerritories)
        : emptyStats,
    [selectionTarget, selectedTerritories, mappableTerritoryRules]
  );

  switch (selectionTarget) {
    case MapSelectionTarget.polygons:
      return polygonInfoStats;
    case MapSelectionTarget.territories:
      return territoryInfoStats;
    case MapSelectionTarget.accounts:
      return emptyStats;
  }
};
