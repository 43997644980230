import gql from 'graphql-tag';

export const REJECT_WORKFLOW = gql`
  mutation RejectWorkflow($battlecardWorkflowId: Int, $territoryGroupWorkflowId: Int) {
    rejectWorkflow(
      input: { battlecardWorkflowId: $battlecardWorkflowId, territoryGroupWorkflowId: $territoryGroupWorkflowId }
    ) {
      success
    }
  }
`;
