import React, { useEffect, useMemo, useState } from 'react';

import { RowNode, RowSelectedEvent } from '@ag-grid-community/core';

import SearchableTreeDialog from 'components/SearchableTreeDialog/SearchableTreeDialog';

import HierarchyGrid from 'app/components/HierarchySearchDialog/HierarchyGrid/HierarchyGrid';
import HierarchySearch from 'app/components/HierarchySearchDialog/HierarchySearch/HierarchySearch';

import {
  HierarchyType,
  HierarchyItem,
  RulePartType,
  SelectedMap,
  HierarchyItemDeltaMap,
  SelectedState
} from 'app/models';

import block from 'utils/bem-css-modules';

import style from './HierarchySearchDialog.module.pcss';

const b = block(style);

// do not include nodes in the delta if their parent node is selected
const consolidateNodesWithSelectedParent = (
  deltaMap: HierarchyItemDeltaMap,
  selectedMap: SelectedMap
): HierarchyItemDeltaMap => {
  const consolidatedDelta: HierarchyItemDeltaMap = {};

  Object.keys(deltaMap).forEach((key) => {
    if (!selectedMap[deltaMap[key].parent]) {
      consolidatedDelta[key] = deltaMap[key];
    }
  });

  return consolidatedDelta;
};

const deselectNode =
  (rowSelectedEvent: RowSelectedEvent) =>
  (node: RowNode): void => {
    const { node: targetNode, data: targetNodeData } = rowSelectedEvent;
    const targetParentHierarchyId = targetNode.parent?.data?.hierarchyId;
    const nodeHierarchyId = node.data?.hierarchyId;
    // deselect all the children when the current node is not in an intermediate state
    if (
      targetNode['selectedState'] !== SelectedState.INTERMEDIATE &&
      node?.parent?.data?.hierarchyId === targetNodeData.hierarchyId
    ) {
      node.setSelected(false);
    }
    // deselect parents
    if (nodeHierarchyId === targetParentHierarchyId && !!targetParentHierarchyId) {
      node['selectedState'] = SelectedState.INTERMEDIATE;
      node.setSelected(false);
    }
  };

const selectNode =
  (rowSelectedEvent: RowSelectedEvent) =>
  (node: RowNode): void => {
    // select children
    const targetNodeData = rowSelectedEvent?.data;
    if (node.parent?.data?.hierarchyId === targetNodeData.hierarchyId) {
      node.setSelected(true);
    }
  };

const onSelectionChanged = (
  node: RowNode,
  isSelected: boolean,
  initialSelection: HierarchyItem[],
  selectedMap: SelectedMap,
  delta: HierarchyItemDeltaMap
) => {
  // nodes only contain parent name, not node reference
  const selectedNodeData = node.data || {};
  const { hierarchyId, name, key } = selectedNodeData;

  // if selected item is already selected
  const nodeIsPreselected = initialSelection.findIndex((selection) => selection.hierarchyId === hierarchyId) >= 0;

  // update selectedMap with new value
  selectedMap[node.data.hierarchyId] = isSelected;

  // if selecting without already being preselected, or is deselecting, update delta
  if ((isSelected && !nodeIsPreselected) || !isSelected) {
    delta[hierarchyId] = {
      hierarchyItem: { hierarchyId, name, key },
      isSelected,
      parent: node?.data?.parentKey || node?.parent?.data?.key
    };
  }
};

const onRowSelected = (
  rowSelectedEvent: RowSelectedEvent,
  initialSelection: HierarchyItem[],
  selectedMap: SelectedMap,
  delta: HierarchyItemDeltaMap
): void => {
  const targetNode = rowSelectedEvent?.node;
  onSelectionChanged(targetNode, targetNode.isSelected(), initialSelection, selectedMap, delta);

  // Always multi select
  if (targetNode.isSelected()) {
    targetNode['selectedState'] = SelectedState.SELECTED;

    // when selecting a node
    // loop through each node and if it is the children of current node
    // set it to selected
    rowSelectedEvent.api.forEachNode(selectNode(rowSelectedEvent));
  } else {
    // when de-selecting a node
    // loop through each node to deselect all its children if it is not in the intermediate state
    // and deselect its parent
    rowSelectedEvent.api.forEachNode(deselectNode(rowSelectedEvent));
  }
};

export interface HierarchySearchDialogProps {
  rootHierarchyId: number;
  rootHierarchyName: string;
  hierarchyType: HierarchyType;
  isOpen: boolean;
  planningCycleId: number;
  setShowTreeSelectDialog: (params: boolean) => void;
  onSelectionChange: (params) => void;
  testId?: string;
  initialSelection: HierarchyItem[];
  rulePartType: RulePartType;
}

const useHierarchySelectDelta = (initialSelection: HierarchyItem[]) => {
  const output = useMemo(() => ({ delta: {}, selectedMap: {} }), [initialSelection]);
  useEffect(() => {
    initialSelection.forEach((selection) => {
      output.selectedMap[selection.hierarchyId] = true;
    });
  }, [initialSelection]);
  return output;
};

const HierarchySearchDialog: React.FC<HierarchySearchDialogProps> = ({
  rootHierarchyId,
  rootHierarchyName,
  hierarchyType,
  planningCycleId,
  isOpen,
  setShowTreeSelectDialog,
  onSelectionChange,
  testId,
  initialSelection,
  rulePartType
}: HierarchySearchDialogProps) => {
  const [searchString, setSearchString] = useState<string>('');

  const { delta, selectedMap } = useHierarchySelectDelta(initialSelection);

  const onSelect = (rowSelectedEvent) => onRowSelected(rowSelectedEvent, initialSelection, selectedMap, delta);

  const onClose = () => {
    setSearchString('');
    setShowTreeSelectDialog(false);
  };

  const onSubmit = () => {
    onSelectionChange(consolidateNodesWithSelectedParent(delta, selectedMap));
    setSearchString('');
    setShowTreeSelectDialog(false);
  };

  return (
    <SearchableTreeDialog
      className={b()}
      title={rootHierarchyName}
      searchString={searchString}
      setSearchString={setSearchString}
      onSubmit={onSubmit}
      onClose={onClose}
      isOpen={isOpen}
      data-testid={testId}
      searchComponent={
        <HierarchySearch
          className={b('hierarchySearch')}
          searchString={searchString}
          hierarchyType={hierarchyType}
          rootHierarchyId={rootHierarchyId}
          planningCycleId={planningCycleId}
          onSelect={onSelect}
          enableCheckboxes={true}
          initialSelection={initialSelection}
          rulePartType={rulePartType}
        />
      }
      defaultComponent={
        <HierarchyGrid
          rootHierarchyId={rootHierarchyId}
          hierarchyType={hierarchyType}
          planningCycleId={planningCycleId}
          onSelect={onSelect}
          initialSelection={initialSelection}
          rulePartType={rulePartType}
        />
      }
    />
  );
};

export default HierarchySearchDialog;
