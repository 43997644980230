// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cjfiP {\n    width: 270px;\n    height: 300px;\n    overflow: scroll;\n    padding: 12px 12px 0;\n    border-radius: 4px;\n  }\n  .g0wGW {\n    display: flex;\n    flex-direction: column;\n    grid-gap: var(--s);\n    gap: var(--s);\n    margin-bottom: var(--s);\n  }\n  .g0wGW .bp3-menu {\n      min-width: 246px !important;\n    }\n  .g0wGW .bp3-button {\n      height: 40px;\n    }\n  .Tu4TP {\n    font-size: 12px;\n    margin: 4px 0 -4px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n  .ouE1P {\n    width: 100%;\n  }\n  .ouE1P .bp3-tag-input {\n      min-height: 40px;\n      box-shadow: none;\n      border: solid 1px rgb(var(--color-light-gray-1));\n    }\n  .ouE1P .bp3-tag {\n      max-width: 240px;\n    }\n  .CjIN1 {\n    display: flex;\n    justify-content: flex-end;\n    position: sticky;\n    background: white;\n    bottom: 0;\n    z-index: 1;\n    padding: var(--s) 0;\n  }\n  ._9TE\\+y {\n    min-height: var(--m) !important;\n    height: var(--m) !important;\n    padding: var(--xs);\n  }\n  ._09LRF {\n    height: var(--xl) !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryGridFilter_inputWrapper": "cjfiP",
	"TerritoryGridFilter_filterWrapper": "g0wGW",
	"TerritoryGridFilter_label": "Tu4TP",
	"TerritoryGridFilter_tagInputWrapper": "ouE1P",
	"TerritoryGridFilter_applyFilterButton": "CjIN1",
	"TerritoryGridFilter_clearButton": "_9TE+y",
	"TerritoryGridFilter_clearTextButton": "_09LRF"
};
export default ___CSS_LOADER_EXPORT___;
