// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KY-4I .bp3-form-group {\n      width: 226px;\n    }\n  .WvX9Y {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    grid-row-gap: var(--m);\n    row-gap: var(--m);\n    margin-top: var(--m);\n  }\n  .ZPTdJ {\n    margin-left: var(--m);\n  }\n  .ZPTdJ .bp3-button {\n      height: var(--xl);\n      width: 226px;\n      border: solid 1px rgb(var(--color-light-gray-1));\n      color: var(--text);\n      box-shadow: none;\n    }\n  .ggTH2 {\n    font-weight: normal;\n    font-size: 12px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateUserDialog_dialog": "KY-4I",
	"CreateUserDialog_formWrapper": "WvX9Y",
	"CreateUserDialog_dropdownWrapper": "ZPTdJ",
	"CreateUserDialog_roleLabel": "ggTH2"
};
export default ___CSS_LOADER_EXPORT___;
