// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9dRdV .bp3-button > *:first-child {\n      margin-right: var(--xs);\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PinsButtonV2_pinMenuPopover": "_9dRdV"
};
export default ___CSS_LOADER_EXPORT___;
