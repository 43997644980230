import { ApolloQueryResult } from '@apollo/client';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import {
  GetTerritoryRulesForSearch_getTerritoryRules,
  GetTerritoryRulesForSearch_getTerritoryRules_territoryRules,
  GetTerritoryRulesForSearchVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { GET_TERRITORY_RULES_FOR_SEARCH } from 'app/graphql/queries/getTerritoryRulesForSearch';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const getTerritoryRulesForSearch = async (
  variables: GetTerritoryRulesForSearchVariables
): Promise<{ items: GetTerritoryRulesForSearch_getTerritoryRules_territoryRules[]; totalCount: number }> => {
  try {
    const result: ApolloQueryResult<{
      getTerritoryRules: GetTerritoryRulesForSearch_getTerritoryRules;
      // eslint-disable-next-line no-restricted-syntax
    }> = await apolloClient.query({
      query: GET_TERRITORY_RULES_FOR_SEARCH,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables
    });

    return {
      items: result?.data?.getTerritoryRules.territoryRules,
      totalCount: result?.data?.getTerritoryRules.totalCount
    };
  } catch (error) {
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('TERRITORY_GRID_ERROR'), 'danger');
    throw error;
  }
};
