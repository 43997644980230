import React, { useEffect, useMemo } from 'react';

import { Field, Form, Formik } from 'formik';

import SelectMenu from 'components/SelectMenu/SelectMenu';

import { NamedTgt } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AccountQuotaTGTFilter.module.pcss';

const b = block(style);

interface AccountQuotaTGTFilterProps {
  territoryGroupTypes: NamedTgt[];
  currentTerritoryGroupType: number;
  onTerritoryGroupTypeChange: (territoryGroupType: number) => void;
  shouldIncludeUnassignedItem?: boolean;
  icon?: JSX.Element;
}

const unassignedMenuItem = {
  key: formatMessage('UNASSIGNED'),
  value: null
};

const AccountQuotaTGTFilter: React.FC<AccountQuotaTGTFilterProps> = ({
  territoryGroupTypes,
  currentTerritoryGroupType,
  onTerritoryGroupTypeChange,
  icon,
  shouldIncludeUnassignedItem = false
}) => {
  useEffect(() => {
    if (territoryGroupTypes?.length > 0) {
      onTerritoryGroupTypeChange(territoryGroupTypes[0].id);
    }
  }, [territoryGroupTypes]);

  const territoryGroupTypeSelectItems = useMemo(() => {
    const tgtItems = territoryGroupTypes
      ? territoryGroupTypes?.map((territoryGroupType) => ({
          key: territoryGroupType.name,
          value: territoryGroupType.id
        }))
      : [];
    return shouldIncludeUnassignedItem ? [...tgtItems, unassignedMenuItem] : tgtItems;
  }, [territoryGroupTypes]);

  return (
    <div className={b('filterContainer')}>
      <div className={b('territoryGroupTypeFilter')}>
        <Formik initialValues={null} onSubmit={null} enableReinitialize>
          {() => {
            return (
              <Form>
                <Field
                  loading={!territoryGroupTypes}
                  id="territoryGroupTypeFilter"
                  name="territoryGroupTypeFilter"
                  placeHolderText={
                    territoryGroupTypeSelectItems.find((item) => item.value === currentTerritoryGroupType)?.key
                  }
                  items={territoryGroupTypeSelectItems}
                  theme="secondary"
                  minimal
                  showErrors={false}
                  component={SelectMenu}
                  onChange={(item) => onTerritoryGroupTypeChange(item.value)}
                  icon={icon}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AccountQuotaTGTFilter;
