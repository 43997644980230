import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { DeleteFile, DeleteFileVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const DELETE_FILE = gql`
  mutation DeleteFile($fileId: String!) {
    deleteFile(input: { fileId: $fileId }) {
      battlecardId
      bucket
      fileId
      fileName
      fileType
      jobId
      key
      message
      planningCycleId
      published
      quotaComponentId
      status
      tenantId
    }
  }
`;

export const useDeleteFile = createUseMutation<DeleteFile, DeleteFileVariables>(DELETE_FILE);
