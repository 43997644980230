// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QfchN {\n  display: flex;\n  flex-direction: row;\n  padding: 4px 2px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryHoverToolbar": "QfchN"
};
export default ___CSS_LOADER_EXPORT___;
