// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M2v3F {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n  .ieXio {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n  ._9k8IU {\n    color: var(--text);\n    font-size: var(--ml);\n    font-weight: 600;\n    margin-top: var(--m);\n  }\n\n  .-FPBt {\n    display: flex;\n    justify-content: center;\n    margin: var(--m) 0;\n  }\n\n  .ecHVx {\n    color: var(--text);\n    font-size: 14px;\n    font-weight: 600;\n  }\n\n  .s79ta {\n      display: none;\n    }\n\n  .O5byr {\n    margin: var(--m) 0;\n    display: flex;\n  }\n\n  .rv-MJ {\n      display: none;\n    }\n\n  .hM2Gx {\n    margin: 0 var(--s);\n  }\n\n  .Aokqx {\n    color: var(--text);\n    font-size: 14px;\n    font-weight: 400;\n  }\n\n  .YQxnd {\n    display: flex;\n    align-items: center;\n  }\n\n  .o4R2y {\n    color: var(--text);\n    font-size: 16px;\n    font-weight: 600;\n    margin-right: var(--s);\n  }\n\n  .qRX7O {\n    display: flex;\n    justify-content: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryOptimizationPublishPage": "M2v3F",
	"TerritoryOptimizationPublishPage_container": "ieXio",
	"TerritoryOptimizationPublishPage_subTitle": "_9k8IU",
	"TerritoryOptimizationPublishPage_imageContainer": "-FPBt",
	"TerritoryOptimizationPublishPage_description": "ecHVx",
	"TerritoryOptimizationPublishPage_description__hidden": "s79ta",
	"TerritoryOptimizationPublishPage_summaryCard": "O5byr",
	"TerritoryOptimizationPublishPage_summaryCard__hidden": "rv-MJ",
	"TerritoryOptimizationPublishPage_scoreItem": "hM2Gx",
	"TerritoryOptimizationPublishPage_scoreItemTitle": "Aokqx",
	"TerritoryOptimizationPublishPage_score": "YQxnd",
	"TerritoryOptimizationPublishPage_number": "o4R2y",
	"TerritoryOptimizationPublishPage_buttonContainer": "qRX7O"
};
export default ___CSS_LOADER_EXPORT___;
