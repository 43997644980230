import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext, Currency } from 'app/models/index';

interface ContextValues extends BaseContext {
  currencies: Currency[];
  setCurrencies: Dispatch<SetStateAction<Currency[]>>;
}

export const CurrencyContext = React.createContext<ContextValues | null>(null);
CurrencyContext.displayName = 'CurrencyContext';

export const CurrencyProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  const resetValues = () => {
    setCurrencies([]);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      currencies,
      setCurrencies,
      resetValues
    }),
    [currencies]
  );

  // Return the interface that we want to expose to our other components
  return <CurrencyContext.Provider value={values}>{children}</CurrencyContext.Provider>;
};

// Custom hook to read these values from
export const useCurrency = (): ContextValues => useContextSafe(CurrencyContext);
