import React from 'react';

import { Classes, Switch } from '@varicent/components';

import block from 'utils/bem-css-modules';

import style from './SwitchButton.module.pcss';

const b = block(style);

interface SwitchButtonProps {
  checked: boolean;
  onChange: React.FormEventHandler<HTMLInputElement>;
  labelElement?: JSX.Element;
  label?: string;
  disabled?: boolean;
  intent?: string;
  large?: boolean;
  testId: string;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  checked,
  onChange,
  labelElement,
  disabled = false,
  intent,
  large = false,
  label,
  testId
}: SwitchButtonProps) => {
  const intentClass = () => {
    if (intent === 'primary') {
      return Classes.INTENT_PRIMARY;
    }
    if (intent === 'success') {
      return Classes.INTENT_SUCCESS;
    }
    if (intent === 'waring') {
      return Classes.INTENT_WARNING;
    }
    if (intent === 'danger') {
      return Classes.INTENT_DANGER;
    }
    return undefined;
  };
  return (
    <div className={b()} data-testid={testId}>
      <Switch
        className={intentClass()}
        labelElement={labelElement}
        label={label}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        data-testid={'switch-button'}
        large={large}
      />
    </div>
  );
};

export default SwitchButton;
