import { useCallback } from 'react';

import { useGetJobsLazy } from 'app/graphql/queries/getJobs';

import { JobCatalogObjectType, JobStatus } from 'app/models';

const GET_JOBS_POLL_INTERVAL = 3000;

export const usePollJobProgress = () => {
  const [getJobStatus, { startPolling, stopPolling }] = useGetJobsLazy({
    fetchPolicy: 'no-cache',
    pollInterval: GET_JOBS_POLL_INTERVAL
  });

  const pollJobProgress = useCallback(
    (planningCycleId: number, setJobItems, setPollingJobs) => {
      getJobStatus({
        variables: { input: { planningCycleId, objectTypes: [JobCatalogObjectType.COINSORT] } },
        pollInterval: GET_JOBS_POLL_INTERVAL,
        onCompleted: (data) => {
          const { getJobs } = data;
          if (getJobs) {
            const activeJobs = getJobs.items?.filter((item) => item.isActive || item.status === JobStatus.PENDING);
            setJobItems(getJobs.items);
            if (activeJobs.length === 0) {
              stopPolling();
              setPollingJobs(false);
            } else {
              setPollingJobs(true);
            }
          }
        },
        onError: () => {
          stopPolling();
          setPollingJobs(false);
        }
      });

      startPolling(GET_JOBS_POLL_INTERVAL);
    },
    [getJobStatus, startPolling, stopPolling]
  );

  const resetPollingJobs = useCallback(() => {
    getJobStatus({ variables: { input: { planningCycleId: null } }, pollInterval: 0 });
  }, [getJobStatus]);

  return { pollJobProgress, resetPollingJobs };
};
