// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yCczy {\n    height: 24px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: 14px;\n    color: rgb(var(--color-gray-1));\n    padding: 2px 8px 2px 12px;\n    background-color: rgb(var(--color-light-gray-5));\n    border-top: 1px solid rgb(var(--color-light-gray-2));\n    -webkit-user-select: none;\n       -moz-user-select: none;\n            user-select: none;\n    cursor: pointer;\n  }\n  ._5httJ {\n    background: white;\n    flex: 1 1 auto;\n    min-height: 24px;\n    display: flex;\n    flex-direction: column;\n  }\n  ._5httJ .bp3-collapse {\n      flex: 1 1 auto;\n      min-height: 0;\n      display: flex;\n    }\n  ._5httJ .bp3-collapse-body {\n      overflow-y: hidden;\n      width: 100%;\n    }\n  .BEA\\+U {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n  .-VwrD {\n    text-transform: uppercase;\n  }\n  .vNbtg {\n    margin: 4px 5px 0 -3px; /* align text with icon */\n    transform: rotate(0deg);\n    transition: transform 0.2s linear;\n    cursor: pointer;\n  }\n  ._4zaah {\n    margin: 0 4px 0 -2px; /* align text with icon */\n    transform: rotate(-90deg);\n    transition: transform 0.2s linear;\n    cursor: pointer;\n  }\n  /* stylelint-disable plugin/selector-bem-pattern */\n  .L\\+uqP {\n    display: flex;\n    cursor: pointer;\n    height: 18px;\n  }\n  .L\\+uqP > svg {\n      fill: rgb(var(--color-gray-4));\n    }\n  .EkdHd {\n    padding-left: 12px;\n    padding-top: 6px;\n    font-weight: 600;\n    color: rgb(var(--color-dark-gray-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BattleCardCollapsedView_panelHeader": "yCczy",
	"BattleCardCollapsedView_collapse": "_5httJ",
	"BattleCardCollapsedView_panelTitleArea": "BEA+U",
	"BattleCardCollapsedView_panelTitle": "-VwrD",
	"BattleCardCollapsedView_panelCollapseIconOpen": "vNbtg",
	"BattleCardCollapsedView_panelCollapseIconClosed": "_4zaah",
	"BattleCardCollapsedView_panelViewDetailIcon": "L+uqP",
	"BattleCardCollapsedView_panelTitleAreaBattleCardRefactor": "EkdHd"
};
export default ___CSS_LOADER_EXPORT___;
