// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LUb\\+Q {\n  border-radius: 4px;\n  background: rgb(var(--color-light-gray-5));\n  margin: 4px 0;\n  display: flex;\n  flex-direction: row;\n  padding: 0 5px;\n  align-items: center;\n  font-weight: normal;\n  width: 100%;\n}\n\n  .LUb\\+Q .bp3-button, .LUb\\+Q .bp3-button-text {\n    width: 200px;\n    overflow: hidden;\n  }\n\n  .IRjt1 {\n    margin: 0 4px;\n    width: 60px;\n  }\n\n  .lxysM {\n    font-weight: normal;\n  }\n\n  /* TODO TQP-1421 Replace border color with opacity after we update the color variables */\n\n  .PiSp0 {\n    border: 2px solid rgb(var(--color-cobalt-4));\n    border-radius: 50%;\n    color: white;\n    background: rgb(var(--color-cobalt-3));\n    font-size: 12px;\n    text-align: center;\n    padding: 0 5px;\n    background-clip: padding-box;\n    margin-left: 5px;\n  }\n\n  ._00-WL {\n    min-width: 7px;\n  }\n\n  .y8IFs {\n    margin-left: 12px;\n    width: 135px;\n    color: rgb(var(--color-dark-gray-3));\n    text-transform: lowercase\n  }\n\n  .y8IFs::first-letter {\n      text-transform: uppercase;\n    }\n\n  ._15ir8 {\n    margin-left: 25px;\n    margin-right: 12px;\n    height: 30px;\n    font-size: 12px;\n  }\n\n  .e87XN {\n    margin-left: 12px;\n    font-size: 12px;\n  }\n\n  .e87XN .bp3-button, .e87XN .bp3-button.bp3-minimal, .e87XN .bp3-button:not([class*='bp3-intent-']) {\n      min-height: 0;\n    }\n\n  .Fyyw\\+ .bp3-button.bp3-minimal, .Fyyw\\+ .bp3-button:not([class*='bp3-intent-']) {\n      border-color: rgb(var(--color-red-3));\n    }\n\n  .VqZs4 {\n    display: flex;\n    margin-right: 5px;\n    margin-left: auto;\n  }\n\n  .VqZs4:hover {\n    cursor: pointer;\n  }\n\n  ._4cHh0:hover {\n    cursor: not-allowed;\n  }\n\n  .LUb\\+Q .bp3-input-group .bp3-input {\n    align-items: center;\n    display: flex;\n    width: 200px;\n    height: 30px;\n  }\n\n  .LUb\\+Q .bp3-control.pb3-switch > label {\n    font-weight: normal;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BattleCardDesignerReorderableListItem": "LUb+Q",
	"BattleCardDesignerReorderableListItem_switch": "IRjt1",
	"BattleCardDesignerReorderableListItem_switchLabel": "lxysM",
	"BattleCardDesignerReorderableListItem_numberLabel": "PiSp0",
	"BattleCardDesignerReorderableListItem_number": "_00-WL",
	"BattleCardDesignerReorderableListItem_hierarchies": "y8IFs",
	"BattleCardDesignerReorderableListItem_referToAs": "_15ir8",
	"BattleCardDesignerReorderableListItem_selectMenu": "e87XN",
	"BattleCardDesignerReorderableListItem_selectMenu__validation": "Fyyw+",
	"BattleCardDesignerReorderableListItem_removeButton": "VqZs4",
	"BattleCardDesignerReorderableListItem_removeButton__disabled": "_4cHh0"
};
export default ___CSS_LOADER_EXPORT___;
