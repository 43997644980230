// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M-z\\+K .bp3-menu {\n      min-width: 220px;\n    }\n    .M-z\\+K .bp3-button {\n      width: 220px;\n    }\n    .M-z\\+K .bp3-menu-item[disabled] {\n      font-weight: bold;\n    }\n  .gQzo- {\n    display: flex;\n    align-items: center;\n    text-wrap: nowrap;\n    grid-gap: var(--m);\n    gap: var(--m);\n  }\n  ._4d7Xc {\n    font-weight: bold;\n    margin: var(--m) 0;\n  }\n  .c0buW {\n    width: 220px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompareModeSelection_selectionContainer": "M-z+K",
	"CompareModeSelection_compareModeSelection": "gQzo-",
	"CompareModeSelection_compareModeTitle": "_4d7Xc",
	"CompareModeSelection_dropdown": "c0buW"
};
export default ___CSS_LOADER_EXPORT___;
