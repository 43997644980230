import React, { FC } from 'react';

import { View, ViewOff } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MapIgnoreCellRenderer.module.pcss';

const b = block(style);

type MapIgnoreCellRendererProps = {
  value: boolean;
  node?: {
    group?: boolean;
    parent?: {
      aggData?: { isIgnored?: boolean };
    };
  };
};

export const MapIgnoreCellRenderer: FC<MapIgnoreCellRendererProps> = ({ value: isIgnored, node }) => {
  const isParentIgnored = !!node?.parent?.aggData?.isIgnored;
  return (
    <IconButton
      type="button"
      small
      className={b({ alwaysVisible: isIgnored && !isParentIgnored })}
      icon={
        isIgnored ? (
          <ViewOff className={b('viewOffIcon')} data-testid="view-off-icon" />
        ) : (
          <View data-testid="view-on-icon" />
        )
      }
      testId="map-ignore-cell"
      title={getTooltipText(isIgnored, node?.group)}
    />
  );
};

const getTooltipText = (isLocked: boolean, isGroup: boolean) => {
  if (isLocked) {
    if (isGroup) return formatMessage('SHOW_TERRITORY_GROUP');
    else return formatMessage('SHOW_TERRITORY');
  } else {
    if (isGroup) return formatMessage('HIDE_TERRITORY_GROUP');
    else return formatMessage('HIDE_TERRITORY');
  }
};
