import gql from 'graphql-tag';

export const GET_ALL_BATTLE_CARDS = gql`
  query GetAllBattleCards($deploymentModelId: Int!) {
    getDeploymentModelSpec(input: { deploymentModelId: $deploymentModelId }) {
      battlecards {
        battlecardId
        battlecardName
        battlecardType
        battlecardParentId
        sheets {
          sheetId
          sheetName
        }
        children
        territoryGroupTypes {
          hierarchyAlias
          hierarchyRootName
          hierarchyTopName
          hierarchyTopId
          hierarchyType
          precedence
          sheets {
            sheetId
            sheetName
          }
          territoryGroupId
          territoryGroupOwnerEmailAddress
          territoryGroupOwnerEmployeeId
          territoryGroupOwnerFirstName
          territoryGroupOwnerId
          territoryGroupOwnerLastName
          territoryGroupOwnerMemberId
          territoryGroupTypeHierarchyId
        }
        territoryGroupsLinked
      }
      quotaComponents {
        quotaComponentName
        quotaComponentId
        quotaComponentSlug
      }
    }
  }
`;
