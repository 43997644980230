import gql from 'graphql-tag';

export const GET_COIN_SORT_PROGRESS = gql`
  query GetCoinsortProgress($battlecardId: Int!, $quotaComponentId: Int!) {
    getCoinsortProgress(input: { battlecardId: $battlecardId, quotaComponentId: $quotaComponentId }) {
      actorEmail
      assignments
      totalActivities
      unassignedActivities
      jobAction
      jobKey
      coinsortStatus
      numberOfRules
      numberOfRulesProcessed
      timeToLive
    }
  }
`;
