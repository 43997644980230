// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VhtIh {\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  margin-left: auto;\n  font-weight: 600;\n}\n\n  ._7LAPO {\n    margin-left: 0;\n  }\n\n  .jyhab {\n    margin-right: var(--allocation-margin);\n    justify-content: flex-end;\n    width: 100%;\n    display: flex;\n  }\n\n  .XjpnE {\n    width: 100%;\n  }\n\n  .gJ\\+h0 {\n    padding: 0;\n    justify-content: flex-end;\n    width: 100%;\n    display: flex;\n  }\n\n  .U1o\\+a {\n    justify-content: flex-end;\n    width: 100%;\n    display: flex;\n  }\n\n  ._6-xCn {\n    margin-right: 5px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FooterCellRenderer": "VhtIh",
	"FooterCellRenderer__alignLeft": "_7LAPO",
	"FooterCellRenderer_allocationTotals": "jyhab",
	"FooterCellRenderer_footerText": "XjpnE",
	"FooterCellRenderer_allocatedAndQuotaTotals": "gJ+h0",
	"FooterCellRenderer_currencyFooter": "U1o+a",
	"FooterCellRenderer_averageText": "_6-xCn"
};
export default ___CSS_LOADER_EXPORT___;
