// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vjTzZ {\n  margin: 18px;\n  display: inline-flex;\n  flex-direction: column;\n}\n\n  .DTImA {\n    display: flex;\n    flex-direction: column;\n    border: 1px solid rgb(var(--color-light-gray-2));\n    overflow: auto;\n  }\n\n  .vjTzZ .ag-react-container {\n    justify-content: flex-end;\n  }\n\n  .vjTzZ .ag-row {\n    color: rgb(var(--color-gray-2));\n    background-color: white;\n  }\n\n  .vjTzZ .ag-theme-alpine {\n    border-top: 0;\n    height: 280px;\n    background-color: white;\n  }\n\n  .vjTzZ .ag-header-cell-label .ag-header-cell-text {\n    margin: 0 47px 0 0;\n    font-size: 14px;\n    font-weight: normal;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: 1.43;\n    letter-spacing: normal;\n    color: rgb(var(--color-gray-1));\n  }\n\n.vjTzZ.ag-theme-alpine .ag-root-wrapper {\n    border: 0;\n  }\n\n.vjTzZ.ag-theme-alpine .ag-row {\n    cursor: pointer;\n    color: rgb(var(--color-gray-2));\n    background-color: white;\n  }\n\n.vR8tS {\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 0 10px 16px;\n    border-bottom: 1px solid rgb(var(--color-light-gray-2));\n  }\n\n.QyO1L {\n    font-weight: bold;\n    font-size: 16px;\n    color: rgb(var(--color-dark-gray-3));\n  }\n\n.tI3wt {\n    align-items: center;\n    display: flex;\n    margin-right: 10px;\n    color: rgb(var(--color-gray-1));\n  }\n\n.G5UCZ {\n    cursor: pointer;\n  }\n\n.ogfHY {\n    cursor: pointer;\n  }\n\n._6k5NU {\n    cursor: pointer;\n    margin-left: 5px;\n  }\n\n.dVNdf {\n    height: 40px;\n    width: 100%;\n    margin: 4px;\n  }\n\n.LBbW3 {\n    text-align: right;\n    display: flex;\n    align-items: center;\n  }\n\n.UrJec {\n    display: flex;\n    height: 100%;\n    width: 100%;\n    align-items: center;\n    line-height: 0;\n    cursor: pointer;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MeasuresContent": "vjTzZ",
	"MeasuresContent_container": "DTImA",
	"MeasuresContent_tableHeader": "vR8tS",
	"MeasuresContent_tableTitle": "QyO1L",
	"MeasuresContent_tableAction": "tI3wt",
	"MeasuresContent_addMeasure": "G5UCZ",
	"MeasuresContent_editIcon": "ogfHY",
	"MeasuresContent_deleteIcon": "_6k5NU",
	"MeasuresContent_gridLoading": "dVNdf",
	"MeasuresContent_actionItems": "LBbW3",
	"MeasuresContent_iconHolder": "UrJec"
};
export default ___CSS_LOADER_EXPORT___;
