import React from 'react';

import { ICellRendererParams, RowNode } from '@ag-grid-community/core';

import EllipsisText from 'components/EllipsisText/EllipsisText';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TextRenderer.module.pcss';

const b = block(style);

interface TextRendererProps extends ICellRendererParams {
  onCellClick?: (data: RowNode) => void;
  isTooltipDisplayed?: boolean; // New prop
}

export const TextRenderer: React.FC<TextRendererProps> = ({
  value,
  data,
  colDef,
  onCellClick,
  isTooltipDisplayed = false
}: TextRendererProps) => {
  if (!data) {
    return null;
  }

  const isClickable = !!onCellClick;

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isClickable) {
      onCellClick(data);
    }
  };

  const displayText = value ?? data[colDef?.field]; // Use value if available, otherwise fallback to data[colDef?.field]

  const textComponent = (
    <div className={b(null, { isClickable })} onClick={handleClick} data-testid="text-renderer">
      <EllipsisText className={b('text', { isClickable })} text={displayText} />
    </div>
  );

  return isTooltipDisplayed ? (
    <MessageTooltip
      content={formatMessage('TERRITORY_SHEET_GRID_EDIT_UNALLOWED_TOOLTIP')}
      target={textComponent}
      placement={'top'}
    />
  ) : (
    textComponent
  );
};

export default TextRenderer;
