export const getHighlightCssPropertyValue = (precedence: number): string => {
  if (precedence === null) {
    return 'var(--neutral)';
  }

  return `var(--highlight-${getHighlightCssPropertyNameSuffix(precedence)})`;
};

export const getHighlightCssPropertyName = (precedence: number): string => {
  if (precedence === null) {
    return '';
  }

  return `vds-highlight-${getHighlightCssPropertyNameSuffix(precedence)}`;
};

const getHighlightCssPropertyNameSuffix = (precedence: number): string => {
  const numHighlights = 12;
  const highlight = (precedence % numHighlights) + 1;
  return highlight.toString().padStart(2, '0');
};
