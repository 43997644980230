import { Dispatch, SetStateAction } from 'react';

import { ColDef, ICellEditorParams, ICellRendererParams } from '@ag-grid-community/core';

import BalancingMetricCellRenderer from 'app/components/AdvancedGrid/CellRenderers/BalancingMetricCellRenderer/BalancingMetricCellRenderer';
import FooterCellRenderer from 'app/components/AdvancedGrid/CellRenderers/FooterCellRenderer/FooterCellRenderer';
import { SheetsFieldHeaderCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/SheetsFieldHeaderCellRenderer/SheetsFieldHeaderCellRenderer';

import { ISheetDefinitionDef, LookupTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { AVERAGE, FileUploadResult, Lookup, SheetDefinitionType, TOTAL } from 'app/models';

const getCellRenderer = function (params, shouldShowBalancingMetric) {
  if (shouldShowBalancingMetric) {
    return {
      frameworkComponent: params?.node?.rowPinned ? FooterCellRenderer : BalancingMetricCellRenderer
    };
  }
  return {};
};

export const shouldShowBalancingMetricDiagram = (sheetDefinitionType: string): boolean =>
  sheetDefinitionType === SheetDefinitionType.DEFAULT_BALANCING_METRIC ||
  sheetDefinitionType === SheetDefinitionType.BALANCING_METRIC;

export const sheetDefinitionColumns = (sheetDefinitions: ISheetDefinitionDef[]): Array<ColDef> => {
  const columnDefs: Array<ColDef> = [];
  sheetDefinitions.forEach((sheetDefinition, index) => {
    columnDefs.push({
      headerComponentFramework: SheetsFieldHeaderCellRenderer,
      headerComponentParams: {
        header: sheetDefinition.measureName,
        sheetDefinition
      },
      field: sheetDefinition.measureName,
      minWidth: 200,
      flex: 1,
      cellStyle: (params) => {
        if (params.value !== TOTAL && params.value !== AVERAGE && index % 2 === 0) {
          return { 'background-color': 'rgb(--light-blue-translucent)' };
        }
        return null;
      },
      cellRendererSelector: (params: ICellRendererParams | ICellEditorParams) =>
        getCellRenderer(params, shouldShowBalancingMetricDiagram(sheetDefinition.sheetDefinitionType))
    });
  });
  return columnDefs;
};

export const openPreviewDialogHelper = (
  lookups: Lookup[],
  selectedTable: FileUploadResult,
  setSelectedTable: Dispatch<SetStateAction<FileUploadResult>>,
  setIsLookupPreviewOpen: Dispatch<SetStateAction<boolean>>,
  selectedTenantId: number,
  selectedPlanningCycleId: number,
  currentLookupGrid: LookupTypeEnum
): void => {
  if (lookups) {
    if (selectedTable?.tableDataType !== currentLookupGrid) {
      lookups.forEach((lookupSchedule) => {
        if (lookupSchedule.lookupType === currentLookupGrid) {
          const selectedTableData = {
            tableId: lookupSchedule.lookupId,
            tableName: lookupSchedule.lookupType,
            tableDataType: lookupSchedule.lookupType,
            status: null,
            published: true,
            planningCycleId: selectedPlanningCycleId,
            tenantId: selectedTenantId,
            message: ''
          };
          setSelectedTable(selectedTableData);
        }
      });
    }
    setIsLookupPreviewOpen(true);
  }
};
