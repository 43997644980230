import { DRAWER_SIZE_OPEN } from 'app/constants/DataTrayConstants';

import { NAVBAR_HEIGHT, EXPANDED_BATTLECARD_DRAWER_MARGIN } from 'app/global/variables';
interface ScrollCoords {
  scrollY: number;
  scrollX: number;
}

export const calculateBattleCardScrollCoords = (
  right: number,
  left: number,
  bottom: number,
  top: number,
  trayHeight: number,
  userClosed: boolean
): ScrollCoords => {
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  let scrollX = right - windowWidth;
  let scrollY = bottom - windowHeight + trayHeight;

  const padding = EXPANDED_BATTLECARD_DRAWER_MARGIN;
  const headerOffset = NAVBAR_HEIGHT;

  const battleCardVisibleHorizontally = scrollX <= 0;
  const battleCardOffLeftOfScreen = left <= 0;
  const battleCardVisibleVertically = scrollY <= 0;
  const battleCardOffTopOfScreen = top <= headerOffset;

  if (battleCardVisibleHorizontally) {
    scrollX = 0;
  } else {
    scrollX += padding;
  }

  if (battleCardOffLeftOfScreen) {
    scrollX = left - padding;
  }

  if (!userClosed && trayHeight <= headerOffset) {
    scrollY = scrollY + DRAWER_SIZE_OPEN - trayHeight + padding;
  } else if (battleCardVisibleVertically) {
    scrollY = 0;
  } else {
    scrollY += padding;
  }

  if (battleCardOffTopOfScreen) {
    scrollY = top - padding - headerOffset;
  }

  return { scrollY, scrollX };
};
