import React, { useState } from 'react';

import { IHeaderParams } from '@ag-grid-community/core';
import { ChevronRight } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { ReactComponent as ChevronOpen } from 'assets/svgs/chevron-filled-open.svg';

import style from './ExpandableHeaderCellRenderer.module.pcss';

const b = block(style);

interface ExpandableHeaderCellRendererProps extends IHeaderParams {
  areColumnsHidden: boolean;
}

const ExpandableHeaderCellRenderer: React.FC<ExpandableHeaderCellRendererProps> = (params) => {
  const [isChevronOpen, setIsChevronOpen] = useState(true);

  const toggleExpandedView = () => {
    const allColumns = params.columnApi?.getAllColumns();

    const expandableColumns = allColumns?.filter(
      (column) => column?.getColDef()?.headerComponentParams?.areColumnsHidden === true
    );

    params.columnApi.setColumnsVisible(expandableColumns, isChevronOpen);
  };

  return (
    <div
      className={b({ highlight: !isChevronOpen || params?.areColumnsHidden })}
      data-testid="expandable-header-cell-renderer"
    >
      <span data-testid="display-name">{params?.displayName}</span>
      {!params?.areColumnsHidden && (
        <span className={b('chervonIcon')}>
          <IconButton
            type={'button'}
            icon={isChevronOpen ? <ChevronRight /> : <ChevronOpen />}
            testId={'toggle-expanded-view-icon'}
            title={formatMessage('OPEN')}
            onClick={() => {
              setIsChevronOpen(!isChevronOpen);
              toggleExpandedView();
            }}
          />
        </span>
      )}
    </div>
  );
};

export default ExpandableHeaderCellRenderer;
