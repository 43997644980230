import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertFieldValues, UpsertFieldValuesVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const UPSERT_FIELD_VALUES = gql`
  mutation UpsertFieldValues($input: [TerritoryFieldValuesInput!]!) {
    upsertFieldValues(input: $input) {
      battlecardId
      fieldId
      fieldValue
      quotaComponentId
      territoryGroupId
      territoryOwnerId
      territoryId
      factorPath
      factors
      quarter
      tmvId
      adate
      isTQM
    }
  }
`;

export const useUpsertFieldValues = createUseMutation<UpsertFieldValues, UpsertFieldValuesVariables>(
  UPSERT_FIELD_VALUES
);
