// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RN-z7 {\n  height: 16px;\n  display: flex;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n  cursor: ns-resize;\n  text-align: center\n}\n.RN-z7::before {\n    content: '';\n    width: 22px;\n    height: 4px;\n    background-color: #d9d9d9;\n    border-radius: 1000px;\n    margin: auto;\n  }\n.mxR5o::before {\n      background-color: #a9a9a9;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DragHandle": "RN-z7",
	"DragHandle__isDragging": "mxR5o"
};
export default ___CSS_LOADER_EXPORT___;
