import React from 'react';

import { Menu } from '@blueprintjs/core';
import { AddAlt } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import Popover from 'components/Popover/Popover';

import { formatMessage } from 'utils/messages/utils';

interface DataTrayAddButtonProps {
  menuItems: JSX.Element[];
  disabled?: boolean;
}

const DataTrayAddButton: React.FC<DataTrayAddButtonProps> = ({
  menuItems,
  disabled = false
}: DataTrayAddButtonProps) => {
  return (
    <Popover
      content={<Menu data-testid="add-assignment-menu">{menuItems}</Menu>}
      placement={'bottom-end'}
      modifiers={{
        arrow: {
          enabled: false
        }
      }}
      disabled={disabled}
    >
      <IconButton
        type={'button'}
        icon={<AddAlt />}
        disabled={disabled}
        testId={'add-button'}
        title={formatMessage('ADD')}
      />
    </Popover>
  );
};

export default DataTrayAddButton;
