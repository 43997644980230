import React from 'react';

import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import { GapIndicatorType } from 'components/models/index';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './GapIndicator.module.pcss';

const b = block(style);
const GAP_BAR_WIDTH = 50;

interface GapIndicatorProps {
  gap: number;
  variant?: GapIndicatorType;
}

const getOrdinalSuffix = (num) => {
  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return `${num}st`;
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    return `${num}nd`;
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    return `${num}rd`;
  } else {
    return `${num}th`;
  }
};

const getTooltipContent = (gap: number) => {
  let tooltipContent = '';
  const gapScorePercentile = (((gap + 1) / 2) * 100).toFixed(0); // Calculate percentile and format it as a string with one decimal place
  const gapScorePercentileText = getOrdinalSuffix(gapScorePercentile);

  let deviationType = '';

  // Determine the type of deviation based on the absolute value of gap
  if (Math.abs(gap) < 0.33) {
    deviationType = formatMessage('MINOR_DEVIATION');
  } else if (Math.abs(gap) < 0.66) {
    deviationType = formatMessage('MODERATE_DEVIATION');
  } else {
    deviationType = formatMessage('SIGNIFICANT_DEVIATION');
  }

  if (gap > 0) {
    tooltipContent = formatMessage('ABOVE_AVERAGE', {
      gapScore: gap.toFixed(2),
      percentile: gapScorePercentileText,
      deviationType
    });
  } else if (gap < 0) {
    tooltipContent = formatMessage('BELOW_AVERAGE', {
      gapScore: gap.toFixed(2),
      percentile: gapScorePercentileText,
      deviationType
    });
  } else if (gap === 0) {
    tooltipContent = formatMessage('ON_AVERAGE', { gapScore: '0.00' });
  }

  return tooltipContent;
};

const getBackgroundColor = (gap: number) => {
  let color;
  if (gap > 0.5) {
    color = 'rgb(var(--color-cobalt-2))';
  } else if (gap >= 0) {
    color = 'rgb(var(--color-cobalt-3))';
  } else if (gap >= -0.5) {
    color = 'rgb(var(--color-rose-2))';
  } else {
    color = 'rgb(var(--color-rose-1))';
  }
  return color;
};

const calculateGapBarWidth = (gap: number): number => {
  const maxGapWidth = GAP_BAR_WIDTH;
  const absGapValue = Math.abs(gap);
  return absGapValue * maxGapWidth;
};

// takes in value between -1 to 1
const GapIndicator: React.FC<GapIndicatorProps> = ({ gap, variant = GapIndicatorType.LONG }) => {
  return (
    <div
      className={b()}
      style={{ width: variant === GapIndicatorType.SHORT ? GAP_BAR_WIDTH : '' }}
      data-testid="gap-indicator-wrapper"
    >
      <div className={b('gapLine')}>
        <MessageTooltip
          content={getTooltipContent(gap)}
          target={
            <div
              data-testid={`gap-indicator-${gap > 0 ? 'above' : 'below'}`}
              className={gap > 0 ? b('above') : b('below')}
              style={{
                width: gap ? `${calculateGapBarWidth(gap)}px` : '0px',
                backgroundColor: `${getBackgroundColor(gap)}`,
                transition: '0.2s ease-in-out'
              }}
            />
          }
          placement={'top'}
          className={b('tooltip')}
        />
      </div>
    </div>
  );
};

export default GapIndicator;
