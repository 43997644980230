import React, { useEffect, useRef } from 'react';

import LoadingBar from 'react-top-loading-bar';

import { COLOR_COBALT_3 } from 'app/global/variables';

import useDebouncedValue from 'app/hooks/useDebouncedValue';

import block from 'utils/bem-css-modules';

import style from './PageLoadingBar.module.pcss';
const b = block(style);

interface LoadingBarProps {
  isLoading: boolean;
}

const PageLoadingBar: React.FC<LoadingBarProps> = ({ isLoading }) => {
  const loadingBarRef = useRef(null);
  const isLoadingBarLoading = useDebouncedValue(isLoading);

  useEffect(() => {
    if (loadingBarRef?.current) {
      if (isLoadingBarLoading) {
        loadingBarRef.current.continuousStart();
      } else {
        loadingBarRef.current.complete();
      }
    }
  }, [isLoadingBarLoading]);

  return (
    <LoadingBar
      containerClassName={b('loadingBar')}
      color={COLOR_COBALT_3}
      shadow={false}
      height={2}
      waitingTime={500}
      ref={loadingBarRef}
      data-testid="loading-bar"
    />
  );
};

export default PageLoadingBar;
