// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rdMQ4 {\n    display: flex !important;\n    align-items: center;\n    width: 100%;\n  }\n  .ZeWXN {\n    margin-right: 5px;\n    display: flex;\n  }\n  .OiU\\+c {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    margin-right: -5px; /* to align the overflow icon with the toolbar */\n  }\n  ._7JOrl {\n    max-width: 160px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n  .a\\+TvS {\n    display: flex;\n    margin-left: 5px;\n  }\n  .g8oj8 {\n    padding-right: 7px;\n    display: flex;\n    align-items: center;\n  }\n  .g8oj8:hover {\n    cursor: pointer;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HierarchyNodeCellRenderer_node": "rdMQ4",
	"HierarchyNodeCellRenderer_nodeIcon": "ZeWXN",
	"HierarchyNodeCellRenderer_nodeContent": "OiU+c",
	"HierarchyNodeCellRenderer_nodeTitle": "_7JOrl",
	"HierarchyNodeCellRenderer_overflowMenu": "a+TvS",
	"HierarchyNodeCellRenderer_overflowIcon": "g8oj8"
};
export default ___CSS_LOADER_EXPORT___;
