// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../app/global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\-1QtZ {\n    display: flex;\n    height: 100%;\n    width: 100%;\n    align-items: center;\n  }\n  .kivek {\n  }\n  .K9ZyS {\n    max-width: 120px;\n  }\n  .NWqJx {\n    display: flex;\n    align-items: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SheetsReferencedCellRenderer_gridCell": "-1QtZ",
	"SheetsReferencedCellRenderer_extraSheetsReferencedCount": "kivek " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["extra-items-tag"] + "",
	"SheetsReferencedCellRenderer_sheetsReferencedText": "K9ZyS " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["text-ellipsis"] + "",
	"SheetsReferencedCellRenderer_sheetsReferencedTextContainer": "NWqJx"
};
export default ___CSS_LOADER_EXPORT___;
