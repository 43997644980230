// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1AuZV {\n  height: 100%;\n}\n\n  .rKWIs {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: var(--s);\n  }\n\n  .TF37Y {\n    margin: 0 var(--s) !important;\n    height: var(--xl);\n  }\n\n  .-SDsg {\n    display: flex;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapGridHeader": "_1AuZV",
	"MapGridHeader_visualizationContainer": "rKWIs",
	"MapGridHeader_divider": "TF37Y",
	"MapGridHeader_iconsContainer": "-SDsg"
};
export default ___CSS_LOADER_EXPORT___;
