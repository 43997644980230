import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { AddAlt, CloseFilled } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useRebalancing } from 'app/contexts/rebalancingProvider';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AddTerritoriesCellRenderer.module.pcss';

const b = block(style);

interface AddTerritoriesCellRendererProps extends ICellRendererParams {
  toggleLeftDialog: () => void;
  toggleRightDialog: () => void;
}

export const AddTerritoriesCellRenderer: React.FC<AddTerritoriesCellRendererProps> = ({
  data,
  toggleLeftDialog,
  toggleRightDialog
}) => {
  const { selectedRebalancingRows, setSelectedRebalancingRows } = useRebalancing();
  if (!data) {
    return null;
  }
  const isButtonDisabled = selectedRebalancingRows.every((item) => item !== null);
  const isRemoveButton = selectedRebalancingRows.some((item) => item?.territoryId === data.territoryId);

  const handleClick = () => {
    setSelectedRebalancingRows((prevState) => {
      const newState = [...prevState];
      const shouldAddTerritoryToBalancingPanel = newState.indexOf(null) !== -1 && !isRemoveButton;
      const shouldRemoveTerritoryFromBalancingPanel = isRemoveButton;
      if (shouldAddTerritoryToBalancingPanel) {
        const index = newState.indexOf(null);
        newState[index] = data;
      } else if (shouldRemoveTerritoryFromBalancingPanel) {
        const index = newState.findIndex((item) => item?.territoryId === data.territoryId);
        if (index === 0) {
          toggleLeftDialog();
        } else if (index === 1) {
          toggleRightDialog();
        }
      }
      return newState;
    });
  };

  return (
    <div className={b()} data-testid="add-territories-cell-renderer">
      {isRemoveButton ? (
        <IconButton
          type={'button'}
          icon={<CloseFilled size={32} />}
          tooltipText={formatMessage('REMOVE_REBALANCING_TERRITORY')}
          onClick={handleClick}
          title={formatMessage('REMOVE')}
          testId={`remove-territories-icon-${data.territoryId}`}
        />
      ) : (
        <IconButton
          type={'button'}
          icon={<AddAlt size={32} />}
          tooltipText={
            isButtonDisabled
              ? formatMessage('ADDITIONAL_REBALANCING_TERRITORY_ERROR')
              : formatMessage('ADD_THIS_TERRITORY')
          }
          onClick={handleClick}
          disabled={isButtonDisabled}
          title={formatMessage('ADD')}
          testId={`add-territories-icon-${data.territoryId}`}
        />
      )}
    </div>
  );
};

export default AddTerritoriesCellRenderer;
