// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FNmBG {\n  --border-style: 1px solid rgb(var(--color-light-gray-3));\n}\n\n  /* stylelint-disable plugin/selector-bem-pattern */\n\n  .FNmBG h5 {\n    font-weight: 700;\n    font-size: 1.125rem;\n  }\n\n  .FNmBG .bp3-overlay-content {\n    width: 320px;\n    bottom: 25px;\n    right: 25px;\n    padding: 0;\n  }\n\n  .FNmBG .bp3-progress-bar {\n    background-color: rgb(var(--color-light-gray-4));\n  }\n\n  .FNmBG .bp3-progress-bar .bp3-progress-meter {\n      background-color: rgb(var(--color-cobalt-3));\n    }\n\n  ._27kPp {\n    border: var(--border-style);\n  }\n\n  .PIU7d {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 20px 20px 17px;\n    background-color: rgb(var(--color-light-gray-5));\n    border-bottom: var(--border-style);\n    border-top-left-radius: inherit;\n    border-top-right-radius: inherit;\n  }\n\n  .fYx4G {\n    padding: 20px 28px 20px 20px;\n  }\n\n  .\\+khST {\n    padding-bottom: 10px;\n  }\n\n  .NDLFa {\n    margin-right: 8px;\n  }\n\n  ._8UsGJ {\n    display: flex;\n    align-items: center;\n  }\n\n  .ag6wy {\n    font-size: 0.75rem;\n    margin-right: 20px;\n  }\n\n  .jRhnd {\n    padding-top: 10px;\n  }\n\n  .TTX2V {\n    cursor: pointer;\n    color: rgb(var(--color-cobalt-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StatusToast": "FNmBG",
	"StatusToast_card": "_27kPp",
	"StatusToast_title": "PIU7d",
	"StatusToast_body": "fYx4G",
	"StatusToast_descriptionContainer": "+khST",
	"StatusToast_progressBar": "NDLFa",
	"StatusToast_progressContainer": "_8UsGJ",
	"StatusToast_progressPercentage": "ag6wy",
	"StatusToast_view": "jRhnd",
	"StatusToast_link": "TTX2V"
};
export default ___CSS_LOADER_EXPORT___;
