// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0K-wR {\n    width: 100%;\n    height: 100%;\n  }\n\n    ._0K-wR .ag-pinned-right-header {\n      pointer-events: none;\n    }\n\n    ._0K-wR .ag-header-cell-label .ag-header-icon.ag-sort-order {\n      display: none;\n    }\n\n    .yjiQU {\n      width: 400px;\n      flex: none;\n    }\n  ._2HjJ8 {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  }\n  .zt99N {\n  }\n  .bYAvT {\n  }\n  .ajbWp {\n    width: 300px;\n  }\n  .QhI3b {\n    padding-top: 5px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryGrid_advancedGridWrapper": "_0K-wR",
	"TerritoryGrid_advancedGridWrapper__expanded": "yjiQU",
	"TerritoryGrid_gridWrapper": "_2HjJ8",
	"TerritoryGrid_gridOverlayContainer": "zt99N " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-container"] + "",
	"TerritoryGrid_gridOverlayImage": "bYAvT " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-image"] + "",
	"TerritoryGrid_gridOverlayText": "ajbWp " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-text"] + "",
	"TerritoryGrid_lassoIcon": "QhI3b"
};
export default ___CSS_LOADER_EXPORT___;
