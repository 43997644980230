import gql from 'graphql-tag';

export const ADD_TENANT = gql`
  mutation AddTenant($input: TenantAddInput!) {
    addTenant(input: $input) {
      tenantId
      tenantName
      tenantSlug
      tenantGlobalId
    }
  }
`;
