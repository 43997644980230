import React from 'react';

import { Formik, Form, Field } from 'formik';

import SelectMenu from 'components/SelectMenu/SelectMenu';

import { useBattleCard } from 'app/contexts/battleCardProvider';

import block from 'utils/bem-css-modules';

import style from './QuotaComponentDropdown.module.pcss';

const b = block(style);

const QuotaComponentDropdown: React.FC = () => {
  const {
    quotaComponentList,
    selectedQuotaComponentId,
    setSelectedQuotaComponentId,
    battleCardLookupMap,
    expandedBattleCardId
  } = useBattleCard();

  // if there is a battle card expanded, only show
  // those that are attached to current battle card as QC options
  let currentQCs;
  if (expandedBattleCardId) {
    const currentBC = battleCardLookupMap[expandedBattleCardId];
    currentQCs = currentBC?.quotaComponents.map((qc) => qc?.quotaComponentId);
  }

  const selectedQuotaComponent =
    selectedQuotaComponentId &&
    quotaComponentList?.length &&
    quotaComponentList.find((quotaComponent) => {
      return quotaComponent.quotaComponentId === selectedQuotaComponentId;
    });

  const quotaComponentOptions = quotaComponentList?.length
    ? quotaComponentList
        .filter((quotaComponent) => {
          if (expandedBattleCardId && currentQCs) {
            return currentQCs.includes(quotaComponent?.quotaComponentId);
          } else {
            return true;
          }
        })
        .map((quotaComponent) => {
          return {
            key: quotaComponent?.quotaComponentName,
            value: quotaComponent?.quotaComponentId
          };
        })
    : [];

  return (
    <Formik
      initialValues={{
        quotaComponent: selectedQuotaComponent
          ? {
              key: selectedQuotaComponent.quotaComponentName,
              value: selectedQuotaComponent.quotaComponentId
            }
          : {
              key: '',
              value: ''
            }
      }}
      onSubmit={null}
      enableReinitialize
    >
      {({ setFieldValue }) => (
        <Form>
          <div className={b('quotaComponentMenu')} data-testid={'quota-component-section'}>
            <Field
              name="quotaComponent"
              items={quotaComponentOptions}
              theme="primary"
              showErrors={false}
              component={SelectMenu}
              onChange={(item) => {
                setFieldValue('quotaComponent', item);
                setSelectedQuotaComponentId(item?.value);
              }}
              data-testid="quota-component-select"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default QuotaComponentDropdown;
