import { ReactNode, useCallback } from 'react';

import { Intent, ToasterContext } from '@varicent/components';

import { SplitFeatures } from 'app/global/features';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';

import { useContextSafe } from './useContextSafe';
import useTreatment from './useTreatment';

const toastIcons = {
  success: 'tick-circle',
  warning: 'warning-sign',
  primary: 'info-sign',
  danger: 'info-sign',
  none: 'info-sign'
} as const;

const useShowToast = () => {
  const toast = useContextSafe(ToasterContext);
  const [isVdsToastOn] = useTreatment(SplitFeatures.VDS_TOAST);

  // Timeout needs to be added as an optional parameter once the conditional Split treatment is removed
  const vdsShowToast = useCallback(
    (message: ReactNode, intent: Intent) => {
      toast.show({ message, intent, icon: toastIcons[intent] });
    },
    [toast.show]
  );

  // eslint-disable-next-line deprecation/deprecation
  return isVdsToastOn ? vdsShowToast : showToast;
};

export default useShowToast;
