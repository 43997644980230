import React, { useState, useMemo, Dispatch, SetStateAction, useEffect } from 'react';

import useCanUser from 'app/hooks/useCanUser';
import { useContextSafe } from 'app/hooks/useContextSafe';
import useQueryParamState from 'app/hooks/useQueryParamState';

import {
  BaseContext,
  CommandCenterDrawerState,
  CommandCenterMenuItem,
  CommandCenterMenuState,
  DataPanelTabIds
} from 'app/models';

import { UserAction } from 'utils/permissions/userActions';

// Menu item can be null when closed, or a string when a custom hierarchy is open
type FullCommandCenterMenuItem = CommandCenterMenuItem | string | null;

export interface CommandCenterContextValues extends BaseContext {
  commandCenterDrawerState: CommandCenterDrawerState;
  setCommandCenterDrawerState: Dispatch<SetStateAction<CommandCenterDrawerState>>;
  commandCenterMenuState: CommandCenterMenuState;
  setCommandCenterMenuState: Dispatch<SetStateAction<CommandCenterMenuState>>;
  activeMenu: FullCommandCenterMenuItem;
  setActiveMenu: (FullCommandCenterMenuItem) => void;
  commandCenterBackButtonCallback: () => void;
  setCommandCenterBackButtonCallback: Dispatch<SetStateAction<(...args: []) => void>>;
  forceRefresh: boolean;
  setForceRefresh: Dispatch<SetStateAction<boolean>>;
  resetValues: () => void;
}

export const CommandCenterContext = React.createContext<CommandCenterContextValues | null>(null);
CommandCenterContext.displayName = 'CommandCenterContext';

export const commandCenterActiveMenuQueryKey = 'commandCenter';
const dataTabQueryParamKey = 'data-tab';

export const CommandCenterProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const canViewCommandCenter = useCanUser(UserAction.COMMAND_CENTER_VIEW);

  const [activeMenu, _setActiveMenu] = useQueryParamState<FullCommandCenterMenuItem>(commandCenterActiveMenuQueryKey);
  const [selectedTab, setSelectedTab] = useQueryParamState<DataPanelTabIds>(dataTabQueryParamKey);

  const setActiveMenu = (activeMenu: FullCommandCenterMenuItem) => {
    if (canViewCommandCenter) {
      _setActiveMenu(activeMenu);
    }
  };

  const [commandCenterDrawerState, setCommandCenterDrawerState] = useState<CommandCenterDrawerState>(
    activeMenu ? CommandCenterDrawerState.EXPAND : CommandCenterDrawerState.CLOSE
  );
  const [commandCenterMenuState, setCommandCenterMenuState] = useState<CommandCenterMenuState>(
    CommandCenterMenuState.FULL
  );

  useEffect(() => {
    // if menu item is not set, and command center is already open, close it
    setCommandCenterDrawerState((state) => {
      if (!activeMenu) {
        return [CommandCenterDrawerState.EXPAND, CommandCenterDrawerState.HALF].includes(state)
          ? CommandCenterDrawerState.CLOSE
          : state;
      }
      // if menu item is set, and command center is closed, open it (else keep current state)
      else {
        return state === CommandCenterDrawerState.CLOSE ? CommandCenterDrawerState.EXPAND : state;
      }
    });

    if (activeMenu === CommandCenterMenuItem.DATA) {
      if (!selectedTab) {
        setSelectedTab(DataPanelTabIds.SHEETS);
      }
    } else {
      setSelectedTab(null);
    }
  }, [activeMenu]);

  const [commandCenterBackButtonCallback, setCommandCenterBackButtonCallback] =
    useState<(...args: []) => void | null>();
  const [forceRefresh, setForceRefresh] = useState(false);

  const resetValues = () => {
    setCommandCenterDrawerState(CommandCenterDrawerState.CLOSE);
    setActiveMenu(null);
    setSelectedTab(null);
    setCommandCenterMenuState(CommandCenterMenuState.FULL);
    setCommandCenterBackButtonCallback(null);
    setForceRefresh(false);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(() => {
    return {
      commandCenterDrawerState,
      setCommandCenterDrawerState,
      commandCenterMenuState,
      setCommandCenterMenuState,
      activeMenu,
      setActiveMenu,
      commandCenterBackButtonCallback,
      setCommandCenterBackButtonCallback: (callback) => {
        // in order to store functions with the useState hook, we must wrap them in an anonymous function
        // see here https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
        setCommandCenterBackButtonCallback(() => callback);
      },
      forceRefresh,
      setForceRefresh,
      resetValues
    };
  }, [commandCenterDrawerState, commandCenterMenuState, activeMenu, forceRefresh]);

  // Return the interface that we want to expose to our other components
  return <CommandCenterContext.Provider value={values}>{children}</CommandCenterContext.Provider>;
};

// Custom hook to read these values from
export const useCommandCenter = (): CommandCenterContextValues => useContextSafe(CommandCenterContext);
