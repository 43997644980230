import React, { useState } from 'react';

import { AnchorButton } from '@blueprintjs/core';
import { AddFilled } from '@carbon/icons-react';
import { FieldArray, useFormikContext } from 'formik';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import ReorderableListItem from 'components/ReorderableListItem/ReorderableListItem';

import block from 'utils/bem-css-modules';

import style from './ReorderableList.module.pcss';
const b = block(style);

interface ReorderableListProps {
  blankReorderableListItem?: Record<string, unknown>;
  addItemText?: string;
}

const ReorderableList: React.FC<ReorderableListProps> = (props) => {
  const { values } = useFormikContext();
  const [nextAvailableId, setNextAvailableId] = useState(values['reorderableListItems']?.length + 1);
  const onDragEnd = (fields) => (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    fields.move(source.index, destination.index);
  };

  const handleAddListItem = (fields, blankListItem) => {
    const blankReorderableListItemWithIncrementedIndex = {
      ...blankListItem,
      id: nextAvailableId.toString()
    };
    fields.push(blankReorderableListItemWithIncrementedIndex);
    setNextAvailableId(nextAvailableId + 1);
  };

  const handleRemoveListItem = (fields, index) => {
    if (fields?.form?.values?.reorderableListItems?.length > 1) {
      fields.remove(index);
    }
  };

  const hasBlankRow = (fields) => {
    return fields?.form?.values?.reorderableListItems?.filter((item) => !item.hierarchyTop)?.length > 0;
  };

  return (
    <FieldArray
      data-testid="reorderable-list"
      name="reorderableListItems"
      render={(fields) => (
        <>
          <DragDropContext onDragEnd={onDragEnd(fields)}>
            <Droppable droppableId="droppableList">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {fields?.form?.values?.reorderableListItems?.map((item, index) => (
                    <ReorderableListItem
                      key={`${item}-${index}`}
                      item={item}
                      itemIndex={index}
                      reorderableList={fields.form.values.reorderableListItems}
                      children={props.children}
                      removeItem={() => handleRemoveListItem(fields, index)}
                      data-testid={`reorderable-list-item-${index}`}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className={b('addListItemArea')}>
            <AnchorButton
              data-testid="add-item-button"
              intent="primary"
              minimal
              icon={<AddFilled />}
              text={props.addItemText}
              disabled={hasBlankRow(fields)}
              onClick={() => handleAddListItem(fields, props.blankReorderableListItem)}
            />
          </div>
        </>
      )}
    />
  );
};

export default ReorderableList;
