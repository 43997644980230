import React, { useState } from 'react';

import { GridApi } from '@ag-grid-community/core';
import { ArrowLeft, Renew } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import DataMappingController from 'app/components/DataMappingDrillIn/DataMappingController';
import DataMappingDrillIn from 'app/components/DataMappingDrillIn/DataMappingDrillIn';
import TablePublishedDrillIn from 'app/components/DataPanel/TablesPanel/TableDetail/TablePublishedDrillIn';

import { useData } from 'app/contexts/dataProvider';

import { FileTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { DataMappingType, DataPanelViews } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import excelFileIcon from 'assets/pngs/fileTypes/excel.png';

import style from './TableDetail.module.pcss';

const b = block(style);

const TableDetail: React.FC = () => {
  const { selectedTable, setSelectedDataView } = useData();
  const [gridApi, setGridApi] = useState<GridApi>(null);

  const getRowData = () => {
    const rowData = [];
    if (gridApi) {
      gridApi.forEachNode((node) => rowData.push(node.data));
    }
    return rowData;
  };

  const handleBackClicked = () => {
    setSelectedDataView(DataPanelViews.DATA_OVERVIEW);
  };

  return (
    <div className={b()} data-testid="table-detail">
      <div className={b('header')} data-testid="table-detail-header">
        <div className={b('leftActionItems')}>
          <span className={b('backIcon')} data-testid="table-detail-header-back-icon" onClick={handleBackClicked}>
            <ArrowLeft />
          </span>
          <span className={b('tableTypeIcon')}>
            <img src={excelFileIcon} alt={formatMessage('EXCEL')} data-testid="table-type-icon" />
          </span>
          <span className={b('tableTitle')}>
            <HTMLHeading tagLevel={'h5'} text={selectedTable?.tableName} data-testid="table-title" />
          </span>
        </div>
        <div className={b('rightActionItems')}>
          <div className={b('hiddenItem')}>
            <span className={b('rightActionText')} data-testid="table-detail-last-modified">
              {formatMessage('LAST_MODIFIED', { value: formatMessage('SYSTEM_TIMESTAMP') })}
            </span>
            <Renew />
          </div>
        </div>
      </div>
      <DataMappingController
        rowData={getRowData()}
        onCloseConfigurationDetail={() => {
          setSelectedDataView(DataPanelViews.DATA_OVERVIEW);
        }}
        controllerType={DataMappingType.DATA_TABLE}
        fileType={FileTypeEnum.Activity}
      />
      {selectedTable?.published ? <TablePublishedDrillIn /> : <DataMappingDrillIn setGridApi={setGridApi} />}
    </div>
  );
};

export default TableDetail;
