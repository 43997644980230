import React, { FC } from 'react';

import block from 'utils/bem-css-modules';

import gridErrorImage from 'assets/pngs/401.png';

import style from './GridOverlayError.module.pcss';

const b = block(style);

export const GridOverlayError: FC<{ message: string }> = ({ message }) => (
  <div className={b('gridOverlayContainer')} data-testid="grid-overlay-error">
    <img className={b('gridOverlayImage')} src={gridErrorImage} alt="" />
    <p className={b('gridOverlayText')}>{message}</p>
  </div>
);
