import React, { createContext, useMemo } from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { CustomHeader } from 'app/models';

export type CascadeHeaders = Partial<{
  [CustomHeader.PLANNING_CYCLE_ID]: number;
  [CustomHeader.DEPLOYMENT_MODEL_ID]: number;
  [CustomHeader.BATTLE_CARD_ID]: number;
}>;

const HeaderCascadeContext = createContext<CascadeHeaders>({});

export const useCascadeHeaders = (): CascadeHeaders => useContextSafe(HeaderCascadeContext);

export function HeaderSetter<T extends keyof CascadeHeaders>({
  headerKey,
  value: rawValue,
  children
}: Readonly<{
  children: React.ReactNode;
  headerKey: T;
  value: CascadeHeaders[T];
}>) {
  const originalHeaders = useCascadeHeaders();

  // For the purpose of our headers: [undefined, null, 0, '', false, NaN] are all interchangeable
  const cleanedValue = rawValue || null;

  const combinedHeaders = useMemo(() => {
    const currentValue = originalHeaders[headerKey];
    if (Object.is(currentValue, cleanedValue)) return originalHeaders;
    return { ...originalHeaders, [headerKey]: cleanedValue };
  }, [originalHeaders, headerKey, cleanedValue]);

  return <HeaderCascadeContext.Provider value={combinedHeaders}>{children}</HeaderCascadeContext.Provider>;
}
