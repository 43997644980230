import { useMemo, useReducer } from 'react';

type BiPanelConstraints = {
  minHeight: number;
  collapsedHeight: number;
  maxHeight: number | null;
};

type PanelState = {
  height: number;
  isExpanded: boolean;
  constraints: BiPanelConstraints;
};

export type BiPanelState = {
  space: number;
  top: PanelState;
  bottom: PanelState;
};

type PanelName = 'top' | 'bottom';

export type BiPanelAction =
  | { kind: 'toggle-expanded'; panel: PanelName }
  | { kind: 'resize-to'; panel: PanelName; height: number }
  | { kind: 'space-change'; space: number }
  | { kind: 'constraints-change'; constraints: BiPanelConstraints; panel: PanelName };

const visualHeight = (panelState: PanelState) =>
  panelState.isExpanded
    ? panelState.height
    : Math.min(panelState.constraints.collapsedHeight, panelState.constraints.maxHeight ?? Infinity);

const applyConstraints = (desiredHeight: number, constraint: BiPanelConstraints) => {
  if (desiredHeight < constraint.minHeight) {
    return constraint.minHeight;
  }
  if (constraint.maxHeight !== null && desiredHeight > constraint.maxHeight) {
    return constraint.maxHeight;
  }
  return desiredHeight;
};

const handleSpaceChange = (oldState: BiPanelState, newSpace: number): BiPanelState => {
  const areBothCollapsed = !oldState.top.isExpanded && !oldState.bottom.isExpanded;
  if (areBothCollapsed) return { ...oldState, space: newSpace };

  const spaceGrew = newSpace > oldState.space;
  if (spaceGrew) {
    if (!isLinked(oldState)) return { ...oldState, space: newSpace };
    return retainLinking({ ...oldState, space: newSpace }, oldState.top.isExpanded ? 'bottom' : 'top');
  }

  const hasRoomForPanels = newSpace >= visualHeight(oldState.top) + visualHeight(oldState.bottom);
  if (hasRoomForPanels) return { ...oldState, space: newSpace };

  return shrinkPanelsToAccomodateSmallerSpace(oldState, newSpace);
};

const shrinkPanelsToAccomodateSmallerSpace = (oldState: BiPanelState, newSpace: number) => {
  let spaceToFind = oldState.space - newSpace;
  let newTopHeight = oldState.top.height;
  let newBottomHeight = oldState.bottom.height;
  if (oldState.top.isExpanded) {
    newTopHeight = applyConstraints(oldState.top.height - spaceToFind, oldState.top.constraints);
    const amountTopShrank = oldState.top.height - newTopHeight;
    spaceToFind -= amountTopShrank;
  }
  if (oldState.bottom.isExpanded) {
    newBottomHeight = applyConstraints(oldState.bottom.height - spaceToFind, oldState.bottom.constraints);
  }
  return {
    ...oldState,
    space: newSpace,
    top: {
      ...oldState.top,
      height: newTopHeight
    },
    bottom: {
      ...oldState.bottom,
      height: newBottomHeight
    }
  };
};

const getOtherPanelName = (panel: PanelName): PanelName => (panel === 'top' ? 'bottom' : 'top');

export const LINKED_DISTANCE = 8;
const isLinked = (state: BiPanelState) =>
  visualHeight(state.top) + visualHeight(state.bottom) + LINKED_DISTANCE >= state.space;

const retainLinking = (oldState: BiPanelState, targetName: PanelName) => {
  const targetHeight = visualHeight(oldState[targetName]);
  const otherPanelName = getOtherPanelName(targetName);
  const other = oldState[otherPanelName];

  return {
    ...oldState,
    [otherPanelName]: {
      ...other,
      height: applyConstraints(oldState.space - targetHeight, other.constraints)
    }
  };
};

const handleRequestToResize = (
  oldState: BiPanelState,
  targetName: PanelName,
  requestedHeight: number,
  allowLinkedMovement: boolean
): BiPanelState => {
  const target = oldState[targetName];
  const otherPanelName = getOtherPanelName(targetName);
  const other = oldState[otherPanelName];

  const optimisticNewHeight = applyConstraints(requestedHeight, target.constraints);

  // If there is space to take, take it
  if (optimisticNewHeight + visualHeight(other) <= oldState.space) {
    const resizedTarget = {
      ...oldState,
      [targetName]: {
        ...target,
        height: optimisticNewHeight
      }
    };

    // If we are not linked, we are done
    if (!allowLinkedMovement || !isLinked(oldState)) return resizedTarget;

    // If we are linked, bring the other panel along for the ride
    return {
      ...resizedTarget,
      [otherPanelName]: {
        ...other,
        height: applyConstraints(oldState.space - optimisticNewHeight, other.constraints)
      }
    };
  }

  // If the other panel is collapsed, we can only take the space not in use
  if (!other.isExpanded) {
    const freeSpace = oldState.space - other.constraints.collapsedHeight;
    return {
      ...oldState,
      [targetName]: {
        ...target,
        height: Math.min(optimisticNewHeight, freeSpace)
      }
    };
  }

  // Try and shrink the other panel to accomodate, but might not get all we ask for

  const otherPanelShrunkenHeight = applyConstraints(oldState.space - optimisticNewHeight, other.constraints);
  const targetActualNewHeight = oldState.space - otherPanelShrunkenHeight;

  return {
    ...oldState,
    [targetName]: {
      ...target,
      height: targetActualNewHeight
    },
    [otherPanelName]: {
      ...other,
      height: otherPanelShrunkenHeight
    }
  };
};

export const biPanelReducer = (state: BiPanelState, action: BiPanelAction): BiPanelState => {
  if (action.kind === 'space-change') {
    return handleSpaceChange(state, action.space);
  }
  const panel = state[action.panel];
  switch (action.kind) {
    case 'resize-to':
      return handleRequestToResize(state, action.panel, action.height, false);

    case 'toggle-expanded': {
      const isExpanded = !panel.isExpanded;
      const newState = {
        ...state,
        [action.panel]: {
          ...panel,
          isExpanded
        }
      };
      if (isExpanded) return handleRequestToResize(newState, action.panel, panel.height, true);
      if (isLinked(state)) return retainLinking(newState, action.panel);
      return newState;
    }

    case 'constraints-change': {
      const isExpanded = panel.isExpanded;
      const newState = {
        ...state,
        [action.panel]: { ...panel, constraints: action.constraints }
      };
      if (isExpanded) return handleRequestToResize(newState, action.panel, panel.height, true);
      if (isLinked(state)) return retainLinking(newState, action.panel);
      return newState;
    }
  }
  return state;
};

export const useBisectedPanel = (initializer: BiPanelState) => {
  const [internalState, actions] = useReducer(biPanelReducer, initializer);
  const externalState = useMemo(
    () => ({
      top: {
        isExpanded: internalState.top.isExpanded,
        height: visualHeight(internalState.top)
      },
      bottom: {
        isExpanded: internalState.bottom.isExpanded,
        height: visualHeight(internalState.bottom)
      }
    }),
    [internalState]
  );
  return [externalState, actions] as const;
};
