import { MapboxMap } from 'react-map-gl';

import { ClusterBreakdown, ClusterFeature, ClusterId, LassoPolygon } from 'app/models';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const lassoPinsAndClusters = async (
  lassoPolygon: LassoPolygon,
  clusterSourceId: string,
  mapboxMap: MapboxMap
): Promise<Array<number>> => {
  const pinsInLasso = mapboxMap.querySourceFeatures(clusterSourceId, {
    filter: ['within', lassoPolygon.geometry]
  });

  const clusterIds = new Set<number>();
  const accounts = new Set<number>();
  pinsInLasso.forEach((pin) => {
    if (pin.properties?.cluster_id) {
      clusterIds.add(pin.properties.cluster_id);
    } else if (pin.properties?.accountId) {
      accounts.add(pin.properties.accountId);
    }
  });

  const source = mapboxMap.getSource(clusterSourceId);
  if (source.type !== 'geojson') throw new Error(`Unexpected cluster source ${source.type}`);

  try {
    await Promise.all(
      [...clusterIds].map((clusterId) =>
        getClusterLeaves(source, clusterId).then((leafFeatures) =>
          leafFeatures.forEach((leafFeature) => {
            if (leafFeature.properties?.accountId) accounts.add(leafFeature.properties.accountId);
          })
        )
      )
    );
  } catch (_error) {
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('FAILED_TO_LASSO_PINS'), 'danger');
    return [];
  }

  return [...accounts];
};

const getClusterLeaves = (source: mapboxgl.GeoJSONSource, clusterId: number) =>
  new Promise<GeoJSON.Feature<GeoJSON.Geometry>[]>((resolve, reject) =>
    source.getClusterLeaves(clusterId, Infinity, 0, (error, features) => {
      if (error) return reject(error);
      if (!features) return reject(new Error(`Failed to get cluster leaves`));
      resolve(features);
    })
  );

export const isClusterFeature = (feature: GeoJSON.Feature<GeoJSON.Geometry>): feature is ClusterFeature =>
  feature.geometry.type === 'Point' && typeof feature.properties?.cluster_id === 'number';

const getClusterExpansionZoom = (source: mapboxgl.GeoJSONSource, clusterId: ClusterId) =>
  new Promise<number>((resolve, reject) =>
    source.getClusterExpansionZoom(clusterId, (error, zoom) => {
      if (error) return reject(error);
      if (!zoom) return reject(new Error(`Failed to get cluster zoom`));
      resolve(zoom);
    })
  );

const getClusterChildren = (source: mapboxgl.GeoJSONSource, clusterId: ClusterId) =>
  new Promise<GeoJSON.Feature<GeoJSON.Geometry>[]>((resolve, reject) =>
    source.getClusterChildren(clusterId, (error, features) => {
      if (error) return reject(error);
      if (!features) return reject(new Error(`Failed to get cluster children`));
      resolve(features);
    })
  );

const arePointCoordinatesEqual = (a: [number, number], b: [number, number]) => a[0] === b[0] && a[1] === b[1];

const getCoordsFromPoint = (feature: GeoJSON.Feature<GeoJSON.Geometry>) => {
  if (feature.geometry.type !== 'Point') return null;
  return feature.geometry.coordinates as [number, number];
};

export const breakdownCluster = async (
  source: mapboxgl.GeoJSONSource,
  feature: ClusterFeature
): Promise<ClusterBreakdown> => {
  const clusterId = feature.properties.cluster_id;
  const center = getCoordsFromPoint(feature);
  if (!center) throw new Error(`Failed to get cluster center`);
  const zoomPromise = getClusterExpansionZoom(source, clusterId);
  const childFeatures = await getClusterChildren(source, clusterId);

  if (childFeatures.length === 0) throw new Error(`No children found for cluster`);

  const firstChildCoords = getCoordsFromPoint(childFeatures[0])!;
  const isSingularity = childFeatures.every(
    (childFeature): childFeature is GeoJSON.Feature<GeoJSON.Point> & boolean => {
      const childCoordinates = getCoordsFromPoint(childFeature);
      if (!childCoordinates) return false;
      if (isClusterFeature(childFeature)) return false;
      return arePointCoordinatesEqual(firstChildCoords, childCoordinates);
    }
  );

  if (isSingularity) {
    return {
      clusterId,
      flyTo: null
    };
  }

  return {
    clusterId,
    flyTo: {
      zoom: await zoomPromise,
      center
    }
  };
};
