import { useCallback } from 'react';

import { centroid } from '@turf/centroid';
import { MapboxMap } from 'react-map-gl';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { useExposeExternalUIControl } from 'app/hooks/useExposeExternalUIControl';

import { MapHoverEvent } from 'app/models';

interface ExternalHoverControlsInput {
  mapboxMap: Pick<MapboxMap, 'queryRenderedFeatures'>;
  interactiveLayerIds: string[];
  handleHoverPolygon: (event: MapHoverEvent) => void;
  handleHoverAccount: (event: MapHoverEvent) => void;
}
export function useExposeHoverControls({
  mapboxMap,
  interactiveLayerIds,
  handleHoverAccount,
  handleHoverPolygon
}: ExternalHoverControlsInput) {
  const { externalControlSuffix } = useMapContextRedistributor();

  const externalHoverPolygon = useCallback(
    (polygonId: string) => {
      const allFeatures = mapboxMap.queryRenderedFeatures(
        // @ts-expect-error (Mapbox supports no-pixel bounds but TS type doesn't include it)
        { filter: ['==', ['to-string', ['id']], polygonId] }
      );
      const firstMatchingFeature = allFeatures.find((feature) => interactiveLayerIds.includes(feature.layer?.id));
      if (!firstMatchingFeature) throw new Error(`No interactive feature found for featureId "${polygonId}"`);

      const [lng, lat] = centroid(firstMatchingFeature).geometry.coordinates;
      handleHoverPolygon({ features: [firstMatchingFeature], lngLat: { lng, lat } });
    },
    [mapboxMap, interactiveLayerIds, handleHoverPolygon]
  );
  useExposeExternalUIControl(`hoverPolygon${externalControlSuffix}`, externalHoverPolygon);

  const externalHoverAccount = useCallback(
    (accountName: string) => {
      const allFeatures = mapboxMap.queryRenderedFeatures(
        // @ts-expect-error (Mapbox supports no-pixel bounds but TS type doesn't include it)
        { filter: ['==', ['get', 'accountName'], accountName] }
      );
      const firstMatchingFeature = allFeatures.find((feature) => interactiveLayerIds.includes(feature.layer?.id));
      if (!firstMatchingFeature) throw new Error(`No interactive account found for accountName "${accountName}"`);

      const [lng, lat] = centroid(firstMatchingFeature).geometry.coordinates;
      handleHoverAccount({ features: [firstMatchingFeature], lngLat: { lng, lat } });
    },
    [mapboxMap, interactiveLayerIds, handleHoverAccount]
  );
  useExposeExternalUIControl(`hoverAccount${externalControlSuffix}`, externalHoverAccount);
}
