import React, { useRef } from 'react';

import { QueryLazyOptions } from '@apollo/client';
import { Position } from '@blueprintjs/core';
import { Intent } from '@varicent/components';
import { useHistory, useLocation } from 'react-router-dom';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useScope } from 'app/contexts/scopeProvider';

import { CONSECUTIVE_POLLING_ERROR_LIMIT, SYMON_PIPE_POLL_INTERVAL } from 'app/global/variables';

import { GetSymonPipeConfigurationVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { useGetSymonPipeConfigurationLazy } from 'app/graphql/queries/getSymonPipeConfiguration';

import { SymonPipeConfigurationStatus, FileType } from 'app/models';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface UseGetSymonPipeConfigurationReturn {
  pollForSymonPipeProcessingStatus: (options?: QueryLazyOptions<GetSymonPipeConfigurationVariables>) => void;
  stopPolling: () => void;
}

export const useGetSymonPipeConfigurationStatus = (
  setShouldRefetchConfigurations: (boolean) => void
): UseGetSymonPipeConfigurationReturn => {
  const { selectedTenant } = useScope();
  const consecutiveErrorCount = useRef(0);
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const phase = params.get('view');

  const navigateToErrorTab = () => {
    history.push({
      ...history.location,
      search: `?view=${phase}&commandCenter=data&data-tab=import-errors`
    });
  };

  const navigateToTablesTab = () => {
    history.push({
      ...history.location,
      search: `?view=${phase}&commandCenter=integration&integrations-tab=manage-tables`
    });
  };

  const [pollForSymonPipeProcessingStatus, { stopPolling, ...rest }] = useGetSymonPipeConfigurationLazy({
    fetchPolicy: 'network-only',
    pollInterval: SYMON_PIPE_POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onError() {
      if (!selectedTenant || consecutiveErrorCount.current >= CONSECUTIVE_POLLING_ERROR_LIMIT) {
        return stopPolling();
      }
      consecutiveErrorCount.current += 1;
    },
    onCompleted(data) {
      const { getSymonPipeConfiguration } = data;
      const isActivityFile = getSymonPipeConfiguration.fileType === FileType.ACTIVITY;
      if (getSymonPipeConfiguration.status === SymonPipeConfigurationStatus.COMPLETED) {
        stopPolling();
        // eslint-disable-next-line deprecation/deprecation
        showToast(
          <ToastMessage
            title={formatMessage('FILE_SUCCESSFULLY_PUBLISHED')}
            message={formatMessage('FILE_SUCCESSFULLY_PUBLISHED_MESSAGE', {
              tableName: getSymonPipeConfiguration.tableNameLabel
            })}
            actionButtonText={isActivityFile ? formatMessage('GO_TO_MANAGE_TABLES') : ''}
            actionButtonOnClick={isActivityFile ? navigateToTablesTab : null}
          />,
          Intent.SUCCESS,
          Position.TOP_RIGHT
        );
        setShouldRefetchConfigurations(true);
      } else if (getSymonPipeConfiguration?.status === SymonPipeConfigurationStatus.FAILED) {
        stopPolling();
        // eslint-disable-next-line deprecation/deprecation
        showToast(
          <ToastMessage
            title={formatMessage('SYMON_FILE_IMPORT_ERROR')}
            message={formatMessage('SYMON_FILE_IMPORT_ERROR_MESSAGE', {
              tableName: getSymonPipeConfiguration?.tableNameLabel
            })}
            actionButtonText={formatMessage('GO_TO_IMPORT_ERRORS')}
            actionButtonOnClick={navigateToErrorTab}
          />,
          Intent.DANGER,
          Position.TOP_RIGHT
        );
        setShouldRefetchConfigurations(true);
      } else if (getSymonPipeConfiguration?.status === SymonPipeConfigurationStatus.CANCELLED) {
        stopPolling();
      }
      consecutiveErrorCount.current = 0;
    }
  });
  return { pollForSymonPipeProcessingStatus, stopPolling, ...rest };
};
