import gql from 'graphql-tag';

export const GET_SYMON_PIPENAMES = gql`
  query GetSymonPipeNames($tenantId: Int!) {
    getSymonPipeNames(input: { tenantId: $tenantId }) {
      pipeID
      name
      engineMode
      rowVersion
      createdAt
    }
  }
`;
