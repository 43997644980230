import { useCallback } from 'react';

import { useScope } from 'app/contexts/scopeProvider';

import useSymonEvent from 'app/hooks/symon/useSymonEvent';
import usePhase from 'app/hooks/usePhase';

import { EmbeddedAuthSavedAppState } from 'app/models';

import { SP_APP_STATE_KEY } from 'utils/helpers/embeddedAuthHelpers';

const useSymonRedirectHandler = (): void => {
  const { selectedTenant, selectedPlanningCycle } = useScope();
  const phase = usePhase();

  const handleBeforeOauth = useCallback(() => {
    const appState: EmbeddedAuthSavedAppState = {
      tenantSlug: selectedTenant?.slug,
      planningCycleSlug: selectedPlanningCycle?.slug,
      deploymentModelPhase: phase
    };

    return {
      [SP_APP_STATE_KEY]: appState
    };
  }, [selectedTenant?.slug, selectedPlanningCycle?.slug, phase]);
  useSymonEvent('onBeforeOAuth', handleBeforeOauth);
};

export default useSymonRedirectHandler;
