import React from 'react';

import { Collapse } from '@blueprintjs/core';

import BattleCardPanel from 'app/components/BattleCardDiagram/Cards/BattleCardPanel/BattleCardPanel';

import { useBattleCard } from 'app/contexts/battleCardProvider';

import { SectionName, BattleCardData, BattleCardSection } from 'app/models';

interface BattleCardCollapsedGlobalMeasuresProps {
  isExpanded: boolean;
  section: BattleCardSection;
  cardData: BattleCardData;
}

const BattleCardCollapsedGlobalMeasures: React.FC<BattleCardCollapsedGlobalMeasuresProps> = ({
  isExpanded,
  section,
  cardData
}: BattleCardCollapsedGlobalMeasuresProps) => {
  const { isRootCard, globalMeasures, invalidConversionReason } = cardData;

  const { selectedQuotaComponentId } = useBattleCard();
  return (
    <Collapse isOpen={isExpanded} transitionDuration={400}>
      {section.name === SectionName.GLOBAL_MEASURES && (
        <BattleCardPanel
          cardData={cardData}
          panelData={isRootCard ? globalMeasures : globalMeasures.get(selectedQuotaComponentId)}
          data-testid="battle-card-panel"
          isCurrencyConversionValid={!invalidConversionReason}
        />
      )}
    </Collapse>
  );
};

export default BattleCardCollapsedGlobalMeasures;
