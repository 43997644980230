import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

const FALLBACK_PAGE_SIZE = 5000;

export interface CustomerPinsPaging {
  isPagingEnabled: boolean;
  pageSize: number;
}

export const useCustomerPinsPaging = (): CustomerPinsPaging => {
  const [isPagingEnabled, { config }] = useTreatment(SplitFeatures.CUSTOMER_PINS_PAGING);

  let pageSize: number;
  try {
    pageSize = isPagingEnabled
      ? parseInt(JSON.parse(config)?.customerPinsPageSize) || FALLBACK_PAGE_SIZE
      : FALLBACK_PAGE_SIZE;
  } catch {
    pageSize = FALLBACK_PAGE_SIZE;
  }

  return {
    isPagingEnabled,
    pageSize
  };
};
