import React, { MutableRefObject, createRef, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Drawer, NavbarGroup, Position } from '@blueprintjs/core';
import { ChevronLeft, WarningAltFilled } from '@carbon/icons-react';
import Draggable from 'react-draggable';

import IconButton from 'components/Buttons/IconButton/IconButton';
import WarningButton from 'components/Buttons/WarningButton/WarningButton';
import Tab from 'components/Tab/Tab';
import Tabs from 'components/Tabs/Tabs';

import DataTrayDrillInViewHeader from 'app/components/DataTray/DataTrayDrillInViewHeader/DataTrayDrillInViewHeader';
import QuotaComponentTabSwitcher from 'app/components/QuotaComponentTabSwitcher/QuotaComponentTabSwitcher';

import { CELL_HEIGHT, DRAWER_SIZE_CLOSED, DRAWER_SIZE_OPEN } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';
import { NAVBAR_HEIGHT, TRANSITION_NONE } from 'app/global/variables';

import useTreatment from 'app/hooks/useTreatment';
import useWindowSize from 'app/hooks/useWindowResize';

import {
  BattlecardType,
  CommandCenterDrawerState,
  CommandCenterMenuItem,
  DataTrayContent,
  SectionName,
  SelectedPlanningType,
  TabIds,
  UserRoleType
} from 'app/models';

import block from 'utils/bem-css-modules';
import { reportAnalyticsAction } from 'utils/helpers/analyticsReporter';
import { getDrawerWidthDifference } from 'utils/helpers/commandCenterHelper';
import { formatMessage } from 'utils/messages/utils';

import emptyGridImage from 'assets/pngs/empty_territorygrid.png';

import style from './DataTray.module.pcss';

const b = block(style);

const EmptyState: React.FC = () => {
  return (
    <div className={b('gridEmpty')} data-testid="grid-empty">
      <img className={b('gridEmptyImage')} src={emptyGridImage} alt="" />
      <div className={b('gridEmptyText')}>{formatMessage('SELECT_BATTLE_CARD_TO_VIEW_TERRITORIES')}</div>
    </div>
  );
};

interface DataTrayProps {
  content: Record<string, DataTrayContent>;
  selectedTab: string;
  onSelectedTabChange: (selectedTab: TabIds) => void;
  onBackClick: () => void;
  canvasRef: MutableRefObject<HTMLDivElement>;
  appContainerRef: MutableRefObject<HTMLDivElement>;
}
const DataTray: React.FC<DataTrayProps> = ({
  content,
  selectedTab,
  onSelectedTabChange,
  onBackClick,
  appContainerRef,
  canvasRef
}: DataTrayProps) => {
  const {
    trayHeight,
    setTrayHeight,
    trayOpen,
    setTrayOpen,
    userClosed,
    setUserClosed,
    setTerritoryRuleId,
    resetStaleTabsLookupMap,
    staleTabsLookupMap
  } = useDataTray();
  const { userRole } = useUser();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { windowWidth, windowHeight } = useWindowSize();
  const { selectedPillIdTDR, getTDR } = useTerritoryDefineAndRefine();
  const { selectedBattleCardId, expandedBattleCardId, battleCardLookupMap, sectionName, selectedQuotaComponentId } =
    useBattleCard();
  const { isCoinsortRunCompleted } = useCoinsort();
  const { commandCenterDrawerState, setCommandCenterDrawerState, setActiveMenu } = useCommandCenter();
  const {
    showActivities,
    showAggregatedActivities,
    showAccountQuotaDrillIn,
    setShowAccountQuotaDrillIn,
    setBlockSize,
    setSelectedCell,
    setSelectedRowData,
    selectedQuotaDrillInTerritory,
    showManageTerritoryQuotaDrillIn,
    setShowManageTerritoryQuotaDrillIn
  } = useGrid();

  useEffect(() => {
    if (selectedTab) reportAnalyticsAction('dataTrayTabChange', { dataTrayTab: selectedTab });
  }, [selectedTab]);

  const { selectedPlanningType } = useScope();
  const { isDataTrayMapOpen, isMultiMapOpen } = useMapVariant();
  const [isBreakdownOfQuotaEnabled] = useTreatment(SplitFeatures.BREAKDOWN_OF_QUOTA_BY_HIERARCHIES);
  const [isAccountMoveInTerritoryQuotaGridEnabled] = useTreatment(
    SplitFeatures.AMWQ_ACCOUNT_QUOTA_IN_TERRITORY_QUOTA_GRID
  );

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;
  const isRootBattleCard = !battleCardLookupMap?.[selectedBattleCardId]?.battlecardParentId;
  const isRollUpBattleCard = battleCardLookupMap?.[selectedBattleCardId]?.battlecardType === BattlecardType.Rollup;
  const invalidConversionReason = battleCardLookupMap?.[selectedBattleCardId]?.invalidConversionReason;

  const [isDragging, setIsDragging] = useState(false);
  const [isMapToggledWithDataTrayClosed, setIsMapToggledWithDataTrayClosed] = useState(false);
  const [dataTrayWidth, setDataTrayWidth] = useState(null);
  const [previousWindowHeight, setPreviousWindowHeight] = useState(null);

  const trayYPosition = useRef(0);
  const drawerRef = useRef(null);
  let dragDebounceCounter = 0;
  const gutterRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (selectedBattleCardId && selectedQuotaComponentId) {
      getTDR(selectedBattleCardId, selectedQuotaComponentId);
    }
  }, [selectedBattleCardId, selectedQuotaComponentId]);

  useEffect(() => {
    //pop up grid on battle card selection
    if (selectedBattleCardId && sectionName !== SectionName.WORKFLOW && !trayOpen && !userClosed) {
      setTrayOpen(true);
    }

    setSelectedRowData(null);
    setShowAccountQuotaDrillIn(false);
    setShowManageTerritoryQuotaDrillIn(false);
  }, [selectedBattleCardId]);

  // reset the territoryRuleId when the tab is changed
  useEffect(() => {
    setTerritoryRuleId(null);
  }, [selectedTab]);

  useEffect(() => {
    if (!isDragging) {
      if (trayOpen && isDataTrayMapOpen) {
        setDataTrayFullHeight();
      } else if (trayOpen && !isDataTrayMapOpen) {
        setTrayHeight(DRAWER_SIZE_OPEN);
      } else {
        setTrayOpen(true);
        setTrayHeight(DRAWER_SIZE_OPEN);
      }
    }

    if (isMapToggledWithDataTrayClosed) {
      setIsMapToggledWithDataTrayClosed(false);
    }
  }, [trayOpen, isDataTrayMapOpen]);

  useEffect(() => {
    if (isMapToggledWithDataTrayClosed) {
      setTrayOpen(true);
    }
  }, [isMapToggledWithDataTrayClosed]);

  useEffect(() => {
    setBlockSize(Math.round((DRAWER_SIZE_OPEN / CELL_HEIGHT) * 2));
  }, [selectedPillIdTDR, selectedPillIdPlanTargets, selectedQuotaComponentId, selectedBattleCardId, selectedTab]);

  useEffect(() => {
    if (expandedBattleCardId || (selectedBattleCardId && sectionName !== SectionName.WORKFLOW)) {
      setTrayOpen(true);
    } else {
      setTrayOpen(false);
    }
  }, [expandedBattleCardId]);

  useEffect(() => {
    // pop data tray on pill selection
    if (selectedPillId) {
      setTrayOpen(true);
    }
  }, [selectedPillId]);

  useEffect(() => {
    const width = getDrawerWidthDifference(commandCenterDrawerState, windowWidth, 0);
    setDataTrayWidth(width);
  }, [commandCenterDrawerState, windowWidth]);

  useEffect(() => {
    setSelectedCell(null);
    if (selectedTab === TabIds.ACCOUNT_QUOTA || selectedTab === TabIds.ACCOUNT_LIST) {
      setDataTrayFullHeight();
    }
  }, [selectedTab]);

  useLayoutEffect(() => {
    // when trayHeight changes, update the paddingBottom on the battlecard canvas to match, preventing the battlecard content from going behind the tray
    if (canvasRef && canvasRef.current) {
      canvasRef.current.style.marginBottom = `${trayHeight}px`;
    }
  }, [trayHeight]);

  useEffect(() => {
    if (trayOpen && trayHeight === previousWindowHeight - NAVBAR_HEIGHT) {
      setTrayHeight(windowHeight - NAVBAR_HEIGHT);
    }
    setPreviousWindowHeight(windowHeight);
  }, [windowHeight]);

  useEffect(() => {
    if (isCoinsortRunCompleted) {
      resetStaleTabsLookupMap();
    }
  }, [isCoinsortRunCompleted]);

  const openCurrencyPage = () => {
    setCommandCenterDrawerState(CommandCenterDrawerState.OPEN);
    setActiveMenu(CommandCenterMenuItem.CURRENCIES);
  };

  const setDataTrayFullHeight = () => {
    const maxHeight = appContainerRef?.current?.offsetHeight;
    setTrayHeight(maxHeight - NAVBAR_HEIGHT);
  };

  const toggleTray = () => {
    if (trayOpen && selectedBattleCardId) {
      setUserClosed(true);
    }

    setBlockSize(Math.round((DRAWER_SIZE_OPEN / CELL_HEIGHT) * 2));
    setTrayOpen(!trayOpen);
  };

  const onDragStart = (e) => {
    trayYPosition.current = e.clientY;

    if (gutterRef.current) {
      gutterRef.current.style.cursor = 'grab';
    }
  };

  const onDrag = (e) => {
    if (dragDebounceCounter === 0) {
      dragDebounceCounter++;
    } else {
      dragDebounceCounter++;
      setIsDragging(true);

      let newTrayHeight = 0;

      if (!trayOpen) {
        setTrayOpen(true);
      }

      if (gutterRef.current) {
        gutterRef.current.style.cursor = 'grab';
      }

      if (!trayYPosition.current || e.clientY === 0 || !appContainerRef.current) {
        return;
      }

      newTrayHeight = trayHeight - e.clientY + trayYPosition.current;
      trayYPosition.current = e.clientY;

      if (newTrayHeight <= DRAWER_SIZE_CLOSED) {
        setTrayOpen(false);
        return setTrayHeight(DRAWER_SIZE_CLOSED);
      }

      const maxHeight = appContainerRef.current.offsetHeight;

      if (newTrayHeight >= maxHeight - NAVBAR_HEIGHT) {
        return setDataTrayFullHeight();
      }
      setTrayHeight(newTrayHeight);
    }
  };

  const onDragStop = () => {
    dragDebounceCounter = 0;
    if (isDragging) {
      setIsDragging(false);
    } else {
      toggleTray();
    }
    if (gutterRef.current) {
      gutterRef.current.style.cursor = 'grab';
    }
  };

  const onTabChange = (newTabId) => {
    onSelectedTabChange(newTabId);
    setTrayOpen(true);
  };

  //eslint-disable-next-line no-restricted-syntax
  const isUserAdmin = userRole === UserRoleType.ADMIN;

  const shouldShowEmptyGrid =
    (!selectedBattleCardId && !selectedPillId) ||
    (isUserAdmin && isRootBattleCard) ||
    (isRollUpBattleCard && (!selectedPillId || selectedPillId === 'battlecard'));

  const className = b('gridWrapper');

  return (
    <div className={b()} data-testid="data-tray" style={{ width: dataTrayWidth }}>
      <Drawer
        isOpen
        autoFocus={false}
        enforceFocus={false}
        hasBackdrop={false}
        usePortal={false}
        size={trayHeight}
        position={Position.BOTTOM}
        transitionName={TRANSITION_NONE}
        data-testid="data-tray-drawer"
      >
        <div className={b('header', { hidden: isMultiMapOpen })} ref={gutterRef}>
          <div className={b('headerLeftElements')} data-testid="data-tray-tabs">
            {/*https://github.com/palantir/blueprint/issues/4615*/}
            {!showActivities &&
              !showAggregatedActivities &&
              !showAccountQuotaDrillIn &&
              !showManageTerritoryQuotaDrillIn && (
                <NavbarGroup>
                  <Tabs id="data-tray" large className={b('tabs')} selectedTabId={selectedTab} onChange={onTabChange}>
                    {Object.values(content).map(({ tabName, tabId, quotaSheetId }) => {
                      const shouldShowCoinsortPrompt = staleTabsLookupMap?.[selectedBattleCardId]?.includes(tabId);
                      return (
                        <Tab
                          id={quotaSheetId || tabId}
                          className={b('tab', { hasIcon: shouldShowCoinsortPrompt })}
                          title={
                            <>
                              {tabName}
                              {shouldShowCoinsortPrompt && (
                                <IconButton
                                  type={'button'}
                                  icon={<WarningAltFilled className={b('warningIcon')} />}
                                  tooltipText={formatMessage('STALE_DATA_MESSAGE')}
                                  testId="stale-data-warning-button"
                                />
                              )}
                            </>
                          }
                          key={quotaSheetId || tabId}
                        />
                      );
                    })}
                  </Tabs>
                </NavbarGroup>
              )}
            {showActivities ||
              (showAggregatedActivities && (
                <div className={b('backButton')} onClick={onBackClick} data-testid="territory-drill-in-header">
                  <div className={b('icon')}>
                    <ChevronLeft />
                  </div>
                  <div className={b('buttonText')} data-testid="territory-drill-in-back">
                    {formatMessage('TERRITORY_DEFINITIONS')}
                  </div>
                </div>
              ))}
            {showAccountQuotaDrillIn && (
              <DataTrayDrillInViewHeader
                rowDescriptionText={
                  isBreakdownOfQuotaEnabled
                    ? selectedQuotaDrillInTerritory.territoryId
                    : selectedQuotaDrillInTerritory.territoryName
                }
                drillInTitleText={
                  isBreakdownOfQuotaEnabled ? selectedQuotaDrillInTerritory.territoryName : formatMessage('ACCOUNT')
                }
                onClickBackButton={setShowAccountQuotaDrillIn}
                data-testid="territory-drill-in-header"
              />
            )}
            {showManageTerritoryQuotaDrillIn && isAccountMoveInTerritoryQuotaGridEnabled && (
              <DataTrayDrillInViewHeader
                rowDescriptionText={`${selectedQuotaDrillInTerritory.territoryName} (${selectedQuotaDrillInTerritory.territoryId})`}
                drillInTitleText={formatMessage('REVISED_TERRITORY_QUOTA')}
                onClickBackButton={setShowManageTerritoryQuotaDrillIn}
                data-testid="territory-quota-drill-in-header"
              />
            )}
          </div>
          <div data-testid="draggable-header-grabber">
            <Draggable bounds="parent" axis="y" onStart={onDragStart} onDrag={onDrag} onStop={onDragStop}>
              <div className={b('headerGrabberWrapper')} data-testid="header-grabber">
                <div className={b('headerGrabber')} />
              </div>
            </Draggable>
          </div>
          <div className={b('headerRightElements')}>
            {invalidConversionReason ? (
              <WarningButton
                onClick={(e) => {
                  // don't select/deselect the battlecard if the icon is clicked
                  e.stopPropagation();
                  openCurrencyPage();
                }}
                tooltipText={
                  // due to how the CommandCenterDrawer works, ensure the tooltip is only shown when the command center is closed,
                  // to prevent the tooltip from being "stuck" open when the user clicks on the icon and then closes the command center
                  commandCenterDrawerState === CommandCenterDrawerState.CLOSE ? invalidConversionReason : null
                }
                testId={'currency-conversion-warning-button'}
              />
            ) : null}
            {content[selectedTab]?.actionButtons}
          </div>
        </div>

        {selectedPlanningType === SelectedPlanningType.QUOTA_PLANNING && (
          <div data-testid="quota-component-tab-switcher">
            <QuotaComponentTabSwitcher />
          </div>
        )}
        <div className={b('dataTrayWrapper')}>
          <div data-testid="grid-wrapper" className={className} ref={drawerRef}>
            {shouldShowEmptyGrid ? <EmptyState /> : content[selectedTab]?.gridComponent}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default DataTray;
