import React, { useState } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { TrashCan, ErrorOutline } from '@carbon/icons-react';
import { IconOnlyButton } from '@varicent/components';

import ConfirmDeleteModal from 'components/Dialog/ConfirmDeleteModal/ConfirmDeleteModal';

import { ConfigurationGridData, SymonPipeConfigurationStatus } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ConfigurationActionsCellRenderer.module.pcss';

const b = block(style);

interface ConfigurationActionsCellRendererProps extends ICellRendererParams {
  onDeleteConfigurationClicked: (data: ConfigurationGridData, onDeleteConfigurationComplete: () => void) => void;
  onCancelConfigurationClicked: (data: ConfigurationGridData, onCancelConfigurationComplete: () => void) => void;
}

const ConfigurationActionsCellRenderer: React.FC<ConfigurationActionsCellRendererProps> = ({
  data,
  onDeleteConfigurationClicked,
  onCancelConfigurationClicked
}: ConfigurationActionsCellRendererProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cancellingSymonPipeConfiguration, setCancellingSymonPipeConfiguration] = useState(false);
  const [deletingSymonPipeConfiguration, setDeletingSymonPipeConfiguration] = useState(false);

  if (!data) {
    return null;
  }

  const handleCancelConfigurationComplete = () => {
    setCancellingSymonPipeConfiguration(false);
  };

  const handleDeleteConfigurationComplete = () => {
    setDeletingSymonPipeConfiguration(false);
    setShowDeleteModal(false);
  };

  return (
    <div className={b()} data-testid="configuration-cell-renderer">
      {data.status === SymonPipeConfigurationStatus.RUNNING ? (
        <IconOnlyButton
          minimal
          icon={ErrorOutline}
          text={cancellingSymonPipeConfiguration ? formatMessage('CANCEL_IN_PROGRESS') : formatMessage('CANCEL')}
          disabled={cancellingSymonPipeConfiguration}
          data-testid="cancel-configuration-icon"
          onClick={() => {
            setCancellingSymonPipeConfiguration(true);
            onCancelConfigurationClicked(data, handleCancelConfigurationComplete);
          }}
          key={formatMessage('CANCEL')}
        />
      ) : (
        <IconOnlyButton
          minimal
          icon={TrashCan}
          text={formatMessage('DELETE')}
          data-testid="delete-configuration-icon"
          onClick={(e) => {
            e.stopPropagation();
            setShowDeleteModal(true);
          }}
          key={formatMessage('DELETE')}
        />
      )}

      {showDeleteModal && (
        <ConfirmDeleteModal
          isOpen={showDeleteModal}
          onConfirmDelete={() => {
            setDeletingSymonPipeConfiguration(true);
            onDeleteConfigurationClicked(data, handleDeleteConfigurationComplete);
          }}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          bodyText={formatMessage('PIPE_CONFIGURATION_DELETE_PROMPT', {
            pipeName: data?.pipeName,
            exportName: data?.exportName
          })}
          titleText={formatMessage('DELETE')}
          isSubmitting={deletingSymonPipeConfiguration}
          data-testid="delete-configuration-confirm-modal"
        />
      )}
    </div>
  );
};

export default ConfigurationActionsCellRenderer;
