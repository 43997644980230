import gql from 'graphql-tag';

export const GET_FILE_HEADERS = gql`
  query GetFileHeaders($planningCycleId: Int!, $fileType: String!) {
    getFileHeaders(input: { planningCycleId: $planningCycleId, fileType: $fileType }) {
      csvHeader
      dbColumn
      columnRequired
      columnType
      valueRequired
      valueType
    }
  }
`;
