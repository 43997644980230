// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Lj0nV {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  flex-direction: column;\n}\n\n  .bW9Qp {\n    display: flex;\n    height: 100%;\n    width: 100%;\n  }\n\n  .V3ZhQ {\n    background-color: rgb(var(--color-light-gray-5));\n    padding: 10px 16px;\n    border-bottom: var(--grey-border);\n  }\n\n  .V3ZhQ .bp3-button {\n      background: white !important;\n      min-height: 25px;\n      height: -moz-min-content !important;\n      height: min-content !important;\n      border-radius: 18px !important;\n      padding: 0 8px !important;\n      margin: 0 5px;\n    }\n\n  .HpwFN {\n    flex-grow: 3;\n  }\n\n  .p0ICO {\n    border-top: 1px solid rgb(var(--color-light-gray-1));\n    padding: 12px 16px;\n    text-align: center;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  ._02J6n {\n    color: rgb(var(--color-cobalt-3));\n    cursor: pointer;\n    margin-left: 5px;\n    text-decoration: underline;\n  }\n\n  .M-\\+jE {\n    display: flex;\n    width: inherit;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AggregatedActivitiesGrid": "Lj0nV",
	"AggregatedActivitiesGrid_territoryDetails": "bW9Qp",
	"AggregatedActivitiesGrid_hierarchyFilters": "V3ZhQ",
	"AggregatedActivitiesGrid_territoryDetailsGrid": "HpwFN",
	"AggregatedActivitiesGrid_filesNotFound": "p0ICO",
	"AggregatedActivitiesGrid_resetButton": "_02J6n",
	"AggregatedActivitiesGrid_gridOverlayContainer": "M-+jE"
};
export default ___CSS_LOADER_EXPORT___;
