import React, { MutableRefObject } from 'react';

import dayjs from 'dayjs';

import FileStatusCellRenderer from 'app/components/AdvancedGrid/CellRenderers/FileStatusCellRenderer/FileStatusCellRenderer';
import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';

import { DataTableFileStatusType } from 'app/models';

export const getActivityFilesPanelCellRenderer = (
  type: string,
  totalCount: number,
  rowRef: MutableRefObject<HTMLDivElement>
): ((params) => JSX.Element) => {
  if (type === DataTableFileStatusType.DATE) {
    return (params) => {
      if (params?.value) {
        return <div>{dayjs.unix(params.value).format('MMMM D, YYYY [at] h:mm A')}</div>;
      } else {
        if (params?.rowIndex < totalCount) {
          return <GridLoading gridWidth={rowRef?.current?.offsetWidth} gridHeight={rowRef?.current?.offsetHeight} />;
        } else {
          return <div />;
        }
      }
    };
  } else if (type === DataTableFileStatusType.STATUS) {
    return (params) => <FileStatusCellRenderer value={params.value} />;
  } else {
    return (params) => {
      if (params?.value) {
        return <div>{params.value}</div>;
      } else {
        if (params?.rowIndex < totalCount) {
          return <GridLoading gridWidth={rowRef?.current?.offsetWidth} gridHeight={rowRef?.current?.offsetHeight} />;
        } else {
          return <div />;
        }
      }
    };
  }
};
