import gql from 'graphql-tag';

import { createUseLazyQuery, createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { PERIODIC_MEASURES } from 'app/graphql/fragments/periodicMeasures';
import {
  GetMeasures,
  GetMeasuresVariables,
  GetTerritoryQuota,
  GetTerritoryQuotaVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_MEASURES = gql`
  query GetMeasures($planningCycleId: Int!) {
    getMeasures(planningCycleId: $planningCycleId) {
      planningCycleId
      measureName
      measureCategory
      measureFieldType
      measureFormatType
      measureFormula
      measureConstraints
      activityMeasureColumnName
      measureId
      measureType
      isTQM
    }
  }
`;

// This will replace the QUERY_GET_TERRITORY_MEASURES_GRID above once FF BREAKDOWN_OF_QUOTA_BY_HIERARCHIES is complete, we should also move this into a new file
export const GET_TERRITORY_QUOTA = gql`
  ${PERIODIC_MEASURES}
  query GetTerritoryQuota(
    $battlecardId: Int
    $territoryGroupId: Int
    $quotaComponentId: Int!
    $startRow: Int
    $endRow: Int
    $sheetId: Int
    $filter: GetTerritoryQuotaFilter
  ) {
    getTerritoryQuota(
      input: {
        battlecardId: $battlecardId
        territoryGroupId: $territoryGroupId
        quotaComponentId: $quotaComponentId
        startRow: $startRow
        endRow: $endRow
        sheetId: $sheetId
        filter: $filter
      }
    ) {
      periodicTerritories {
        comment
        measures {
          ...PeriodicMeasuresFragment
        }
        territoryName
        territoryId
        territoryGroupName
        territoryGroupLevelName
        territoryGroupId
        ruleId
      }
      totalTerritoriesCount
      totalMeasuresCount
      hasGridThresholdBeenExceeded
    }
  }
`;

export const useGetMeasures = createUseQuery<GetMeasures, GetMeasuresVariables>(GET_MEASURES);
export const useGetTerritoryQuota = createUseQuery<GetTerritoryQuota, GetTerritoryQuotaVariables>(GET_TERRITORY_QUOTA);
export const useGetTerritoryQuotaLazy = createUseLazyQuery<GetTerritoryQuota, GetTerritoryQuotaVariables>(
  GET_TERRITORY_QUOTA
);
