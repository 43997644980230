// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6Owp- {\n    width: 270px;\n    height: 300px;\n    overflow: scroll;\n    padding: 12px 12px 0;\n    border-radius: 4px;\n  }\n  .KS-\\+I {\n    padding: var(--s);\n  }\n  .YTvAZ {\n    display: flex;\n    justify-content: flex-end;\n    position: sticky;\n    background: white;\n    bottom: 0;\n    z-index: 1;\n    padding: var(--s) var(--xs);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryQuotaDrillInGridFilter_inputWrapper": "_6Owp-",
	"TerritoryQuotaDrillInGridFilter_fieldWrapper": "KS-+I",
	"TerritoryQuotaDrillInGridFilter_applyFilterButton": "YTvAZ"
};
export default ___CSS_LOADER_EXPORT___;
