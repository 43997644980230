import React, { useState, useEffect } from 'react';

import JobProgressStatusToast from 'components/JobProgressStatusToast/JobProgressStatusToast';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';

import useShowToast from 'app/hooks/useShowToast';

import { LongRunningJob, JobStatus } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface TopDownAllocationToasterProps {
  jobDetail: LongRunningJob;
}

const TopDownAllocationToaster: React.FC<TopDownAllocationToasterProps> = ({
  jobDetail
}: TopDownAllocationToasterProps) => {
  const [progress, setProgress] = useState(0);
  const { topdownAllocationJobStatusLookupMap, setTopdownAllocationJobStatusLookupMap, planTargetsLookupMap } =
    usePlanTargets();

  const { battleCardLookupMap, quotaComponentList } = useBattleCard();
  const showToast = useShowToast();

  const battlecardId = jobDetail['battlecardId'];
  const quotaComponentId = jobDetail['quotaComponentId'];
  const selectedPillId = jobDetail['selectedPillId'];

  const quotaComponentName = quotaComponentList.find(
    (qc) => qc.quotaComponentId === quotaComponentId
  )?.quotaComponentName;
  const bcName = battleCardLookupMap[battlecardId].battlecardName;
  const jobStatus = topdownAllocationJobStatusLookupMap.find(({ battlecardId: jobBcId, quotaComponentId: jobQcId }) => {
    return battlecardId === jobBcId && quotaComponentId === jobQcId;
  })?.jobStatus;

  const selectedTerritoryGroupName = planTargetsLookupMap?.[battlecardId]?.[selectedPillId]?.name;

  const updateTopdownAllocationJobStatusLookupMap = () => {
    const updatedTopDownAllocationJobStatusLookupMap = topdownAllocationJobStatusLookupMap.filter(
      ({ battlecardId: jobBcId, quotaComponentId: jobQcId }) => {
        return battlecardId !== jobBcId || quotaComponentId !== jobQcId;
      }
    );
    setTopdownAllocationJobStatusLookupMap(updatedTopDownAllocationJobStatusLookupMap);
    clearInterval(interval);
  };

  let interval;
  useEffect(() => {
    // setInterval is used to create a fake progress bar since currently
    // requestTopDownAllocation does not provide a way to know % of the job progress from BE
    if (jobStatus === JobStatus.IN_PROGRESS) {
      interval = setInterval(() => {
        setProgress((progress) => {
          if (progress < 0.99) {
            return progress + 0.01;
          }
          return progress;
        });
      }, 1000);
    } else if (jobStatus === JobStatus.COMPLETED) {
      setProgress(1);
      // setTimeout to 2000 to indicate the top down allocation is successful
      // before clearing out the status
      setTimeout(() => {
        updateTopdownAllocationJobStatusLookupMap();
      }, 2000);
    } else if (jobStatus === JobStatus.FAILED) {
      updateTopdownAllocationJobStatusLookupMap();
      showToast(formatMessage('TOP_DOWN_ALLOCATION_ERROR'), 'danger');
    } else {
      clearInterval(interval);
    }
  }, [topdownAllocationJobStatusLookupMap]);

  const messageForTerritoryGroupName = selectedTerritoryGroupName ? `/${selectedTerritoryGroupName}` : '';
  const jobName = `${bcName}/${quotaComponentName}${messageForTerritoryGroupName}`;

  return (
    <JobProgressStatusToast
      jobDetail={jobDetail}
      title={formatMessage('ALLOCATING_TOP_DOWN')}
      progressDescription={formatMessage('ALLOCATING_TOP_DOWN_LOADING_MESSAGE', { jobName })}
      progress={progress}
      onPollSuccess={() => {
        // TODO use success callback instead of hardcoding logic in component
        return;
      }}
      onPollError={() => {
        // TODO use success callback instead of hardcoding logic in component
        return;
      }}
    />
  );
};

export default TopDownAllocationToaster;
