// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cBgR2 {\n    display: flex;\n    width: 100%;\n    flex-direction: column;\n  }\n  ._7k972 {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    align-items: center;\n    padding-right: var(--m);\n    height: 48px;\n    background-color: rgb(var(--color-light-gray-5));\n  }\n  ._7eYIJ {\n    margin-top: var(--m);\n    display: flex;\n    align-items: center;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n  }\n  ._7eYIJ .bp3-minimal {\n      background-color: transparent !important;\n    }\n  .wtJYU {\n    width: 100%;\n    height: 100%;\n    border: 1px solid rgb(var(--color-light-gray-3));\n    border-radius: var(--xs) var(--xs) 0 0;\n  }\n  .wtJYU .ag-header-cell {\n      padding: 0 var(--grid-header-padding);\n    }\n  .wtJYU .ag-row-level-0 {\n      background: var(--white);\n    }\n  .wtJYU .ag-row-level-1 {\n      background: rgb(var(--color-light-gray-5));\n    }\n  .wtJYU .ag-theme-alpine .ag-ltr .ag-cell {\n      overflow: visible;\n      overflow: initial;\n      border: none !important;\n    }\n  ._9pX-D {\n  }\n  .T9UKM {\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountQuotaGrid_gridWrapper": "cBgR2",
	"AccountQuotaGrid_container": "_7k972",
	"AccountQuotaGrid_tgtWrapper": "_7eYIJ",
	"AccountQuotaGrid_advancedGridWrapper": "wtJYU",
	"AccountQuotaGrid_gridOverlayContainer": "_9pX-D " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-container"] + "",
	"AccountQuotaGrid_gridOverlayText": "T9UKM " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-text"] + ""
};
export default ___CSS_LOADER_EXPORT___;
