import React from 'react';

import { OverflowMenuHorizontal } from '@carbon/icons-react';

import { combineBaseRuleInclusionsAndExclusions } from 'app/components/AdvancedGrid/GridHelpers/TerritoryGrid/territoryGridUtils';

import { NewRuleDefinitionResponse } from 'app/graphql/generated/graphqlApolloTypes';

import useIsWiderThanScroll from 'app/hooks/useIsWiderThanScroll';

import {
  CombinatorType,
  FlattenedFilter,
  OperatorType,
  RuleBeingEdited,
  RulePartType,
  TerritoryRuleHierarchyWithInfo
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import Combinator from './Combinator';
import style from './CompactRuleGroup.module.pcss';
import RuleEditorRow from './RuleEditorRow';

const b = block(style);

interface CompactRuleGroupProps {
  ruleDefinition: NewRuleDefinitionResponse;
  setRuleDefinition?: (ruleDefinition: NewRuleDefinitionResponse) => void;
  rulesBeingEdited?: RuleBeingEdited[];
  setRulesBeingEdited?: (rulesBeingEdited: RuleBeingEdited[]) => void;
  inheritsFrom?: TerritoryRuleHierarchyWithInfo[];
  setNewInheritsFrom?: (inheritsFrom: TerritoryRuleHierarchyWithInfo[]) => void;
  isSelectedOrHovered?: boolean;
}

const CompactRuleGroup: React.FC<CompactRuleGroupProps> = ({
  ruleDefinition,
  setRuleDefinition,
  setNewInheritsFrom,
  rulesBeingEdited,
  setRulesBeingEdited,
  inheritsFrom,
  isSelectedOrHovered
}: CompactRuleGroupProps) => {
  const { base, modifiers } = ruleDefinition;

  const isInline = !(setRuleDefinition && rulesBeingEdited && setRulesBeingEdited);

  const convertContains = (contains) => {
    return {
      fieldId: contains?.hierarchyId,
      fieldName: contains?.name,
      key: contains?.key
    };
  };

  const combinedBaseRules = combineBaseRuleInclusionsAndExclusions(base, inheritsFrom);
  const shouldShowRootCombinator = combinedBaseRules.length > 1;
  const shouldShowInclusions = !!modifiers?.inclusions?.length;

  const { showEllipsis, ref } = useIsWiderThanScroll(20);

  return isInline ? (
    /* inline */
    <div ref={ref} className={b('ruleGroupInline')} data-testid="compact-rule-group">
      <div className={b('containerInline')}>
        <div className={b('compactRuleGroupContainer', { inline: true })}>
          {shouldShowInclusions && <span className={b('bracket')}>{'['}</span>}
          {combinedBaseRules.map((filter: FlattenedFilter, index, array) => {
            const key = `${filter?.rootHierarchyName}-${filter?.rootHierarchyId}-${filter?.field}`;
            return (
              <div className={b('rowContainer')} key={key}>
                <RuleEditorRow
                  ruleDefinition={ruleDefinition}
                  rulePartType={RulePartType.BASE}
                  filter={filter}
                  rulesBeingEdited={rulesBeingEdited}
                  setRulesBeingEdited={setRulesBeingEdited}
                  setRuleDefinition={setRuleDefinition}
                  setNewInheritsFrom={setNewInheritsFrom}
                  shouldShowConnectors={false}
                  isEditable={false}
                  isSelectedOrHovered={isSelectedOrHovered}
                  data-testid={`base-rule-editor-row-${key}`}
                />
                {index < array.length - 1 && (
                  <Combinator combinatorType={CombinatorType.AND} data-testid={`base-combinator-${key}`} />
                )}
              </div>
            );
          })}
        </div>
        {shouldShowInclusions && <span className={b('bracket')}>{']'}</span>}
        {modifiers.inclusions?.map((inclusion) => {
          const key = `${inclusion?.rootHierarchyName}-${inclusion?.rootHierarchyId}`;
          return (
            <div className={b('rowContainer')} key={key}>
              <Combinator combinatorType={CombinatorType.OR} data-testid={`inclusion-combinator-${key}`} />
              <div className={b('compactRuleGroupContainer')}>
                <RuleEditorRow
                  ruleDefinition={ruleDefinition}
                  rulePartType={RulePartType.OVERRIDE}
                  filter={{
                    field: inclusion?.hierarchyType,
                    rootHierarchyId: inclusion?.rootHierarchyId,
                    rootHierarchyName: inclusion?.rootHierarchyName,
                    fieldIdsDetails: {
                      [OperatorType.EQUAL]: inclusion?.contains?.map(convertContains)
                    },
                    inclusionTotal: inclusion?.containsTotal
                  }}
                  rulesBeingEdited={rulesBeingEdited}
                  setRulesBeingEdited={setRulesBeingEdited}
                  setRuleDefinition={setRuleDefinition}
                  setNewInheritsFrom={setNewInheritsFrom}
                  shouldShowConnectors={false}
                  isEditable={false}
                  isSelectedOrHovered={isSelectedOrHovered}
                  data-testid={`inclusion-rule-editor-row-${key}`}
                />
              </div>
            </div>
          );
        })}
      </div>
      {showEllipsis && (
        <span className={b('ellipsisIcon')} data-testid="show-ellipsis-icon">
          <OverflowMenuHorizontal data-testid="compact-rule-group-ellipsis-icon" />
        </span>
      )}
    </div>
  ) : (
    /* expanded */
    <div className={b('ruleGroup')} data-testid="compact-rule-group">
      <div className={b('container')}>
        <div className={b('modifiersAndBaseContainer')}>
          {shouldShowInclusions && (
            <div className={b('modifierCombinatorContainer')}>
              <Combinator combinatorType={CombinatorType.OR} data-testid="modifier-combinator" />
            </div>
          )}
          <div>
            {shouldShowInclusions && (
              <div className={b('inclusions')}>
                {modifiers.inclusions?.map((inclusion) => {
                  const key = `${inclusion?.rootHierarchyName}-${inclusion?.rootHierarchyId}`;
                  return (
                    <div className={b('rowContainer')} key={key}>
                      <div>
                        <div className={b('rulePartLabel')}>{formatMessage('OVERRIDE')}</div>
                        <div className={b('compactRuleGroupContainer', { expanded: true })}>
                          <div className={b('modifier', { darkMode: shouldShowInclusions })}>
                            <RuleEditorRow
                              ruleDefinition={ruleDefinition}
                              rulePartType={RulePartType.OVERRIDE}
                              filter={{
                                field: inclusion?.hierarchyType,
                                rootHierarchyId: inclusion?.rootHierarchyId,
                                rootHierarchyName: inclusion?.rootHierarchyName,
                                fieldIdsDetails: {
                                  [OperatorType.EQUAL]: inclusion?.contains?.map(convertContains)
                                },
                                inclusionTotal: inclusion?.containsTotal
                              }}
                              rulesBeingEdited={rulesBeingEdited}
                              setRulesBeingEdited={setRulesBeingEdited}
                              setRuleDefinition={setRuleDefinition}
                              setNewInheritsFrom={setNewInheritsFrom}
                              shouldShowConnectors={shouldShowInclusions}
                              isEditable={true}
                              isSelectedOrHovered={isSelectedOrHovered}
                              darkMode={shouldShowInclusions}
                              data-testid={`inclusion-rule-editor-row-${key}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className={b('baseContainer', { addPadding: false })}>
              {shouldShowInclusions && <div className={b('rulePartLabel')}>{formatMessage('BASE_RULE')}</div>}
              <div className={b('base', { darkMode: shouldShowInclusions })}>
                <div
                  className={b('rootCombinatorContainer', {
                    hidden: !shouldShowRootCombinator,
                    hiddenConnector: !shouldShowRootCombinator && !shouldShowInclusions,
                    connectToExclusions: !shouldShowInclusions
                  })}
                >
                  <Combinator combinatorType={CombinatorType.AND} data-testid="root-combinator" />
                </div>
                <div className={b('compactRuleGroupContainer', { expanded: true })}>
                  {combinedBaseRules.map((filter: FlattenedFilter) => {
                    const key = `${filter?.rootHierarchyName}-${filter?.rootHierarchyId}-${filter?.field}`;
                    return (
                      <RuleEditorRow
                        ruleDefinition={ruleDefinition}
                        rulePartType={RulePartType.BASE}
                        filter={filter}
                        rulesBeingEdited={rulesBeingEdited}
                        setRulesBeingEdited={setRulesBeingEdited}
                        setRuleDefinition={setRuleDefinition}
                        setNewInheritsFrom={setNewInheritsFrom}
                        shouldShowConnectors={shouldShowRootCombinator}
                        isEditable={true}
                        darkMode={shouldShowInclusions}
                        key={key}
                        isSelectedOrHovered={isSelectedOrHovered}
                        data-testid={`base-rule-editor-row-${key}`}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompactRuleGroup;
