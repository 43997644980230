import React, { useEffect, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { IPopoverProps } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';

import { MenuItem } from 'components/menu/MenuItem';
import { KeyValue } from 'components/models';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { useScope } from 'app/contexts/scopeProvider';

import { GET_ALL_HIERARCHIES } from 'app/graphql/queries/getAllHierarchies';

import { Hierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { asyncThrottle } from 'utils/helpers/index';
import { formatMessage } from 'utils/messages/utils';

import style from './HierarchySuggest.module.pcss';

const b = block(style);

const areItemsEqual = (itemA: KeyValue<string>, itemB: KeyValue<string>) => {
  return itemA.value === itemB.value;
};

const getItemPredicate = (query: string, key: string) => {
  const normalizedTitle = key?.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  return normalizedTitle.includes(normalizedQuery);
};

const getItemRenderer = (searchResult, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={searchResult?.value}
      onClick={handleClick}
      text={searchResult?.key}
      data-testid={`hierarchy-suggest-item-${searchResult?.value}`}
    />
  );
};

export const searchHierarchies = async (
  hierarchyName: string,
  hierarchyType: string,
  rootHierarchyId: number,
  planningCycleId: number,
  getTree = false,
  startRow = 0,
  endRow = 10
): Promise<Hierarchy[]> => {
  const result: ApolloQueryResult<{
    getAllHierarchies: { hierarchies }[];
    // eslint-disable-next-line no-restricted-syntax
  }> = await apolloClient.query({
    query: GET_ALL_HIERARCHIES,
    variables: {
      planningCycleId,
      searchHierarchyInput: {
        hierarchyType,
        searchString: hierarchyName,
        rootHierarchyId,
        getTree,
        startRow,
        endRow
      }
    },
    fetchPolicy: 'network-only'
  });

  return result?.data?.getAllHierarchies?.[0]?.hierarchies;
};

interface CustomValueEditorProps {
  item: KeyValue<string>;
  onChange?: (newItem) => void;
  onSelect: (item: KeyValue<string>) => void;
  rootHierarchyId: number;
  hierarchyType: string;
  popoverProps?: Partial<IPopoverProps>;
}

const HierarchySuggest: React.FC<CustomValueEditorProps> = ({
  item,
  onChange,
  onSelect,
  rootHierarchyId,
  hierarchyType,
  popoverProps
}: CustomValueEditorProps) => {
  const { selectedPlanningCycle } = useScope();

  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [listening, setListening] = useState(false);

  const clearSearch = () => {
    setSearchString('');
    setSearchResults([]);
    setLoadingSearchResults(false);
  };

  const onItemSelect = (selectedItem) => {
    if (onSelect) {
      onSelect({
        key: selectedItem?.key,
        value: selectedItem?.value
      });
    }
    clearSearch();
  };

  const onInputChange = (searchInput) => {
    if (onChange) {
      onChange(searchInput);
    }
    setSearchString(searchInput);
  };

  const throttledSearch = async (hierarchyName) => {
    const rootHierarchyType = hierarchyType.split('.')[0];

    const throttleSearchHierarchies = asyncThrottle(searchHierarchies, 500);

    setLoadingSearchResults(true);
    const results = await throttleSearchHierarchies(
      hierarchyName,
      rootHierarchyType,
      rootHierarchyId,
      selectedPlanningCycle?.id
    );
    const hierarchyItems = (results as Hierarchy[]).map((hierarchy) => {
      return {
        key: hierarchy.name,
        value: hierarchy?.hierarchyId
      };
    });
    setSearchResults(hierarchyItems);
    setLoadingSearchResults(false);
  };

  useEffect(() => {
    if (searchString) {
      throttledSearch(searchString);
    }
  }, [searchString]);

  // To prevent on blur from occuring on custom ag grid filter components
  const options = document.getElementsByClassName('bp3-transition-container')[0];
  if (options && !listening) {
    setListening(true);
    options.addEventListener('mousedown', function (event) {
      event.stopPropagation();
    });
  }

  return (
    <div className={b('')}>
      <Suggest
        itemPredicate={(query, itemToCompare) => getItemPredicate(query, itemToCompare?.key)}
        itemRenderer={(searchResult, itemRendererOptions) => getItemRenderer(searchResult, itemRendererOptions)}
        items={loadingSearchResults ? [] : searchResults}
        inputValueRenderer={(itemToRender: KeyValue<string>) => itemToRender?.key}
        itemsEqual={areItemsEqual}
        inputProps={{ placeholder: item?.key || formatMessage('SEARCH') }}
        noResults={
          searchString && !loadingSearchResults ? (
            <MenuItem disabled={true} text={formatMessage('NO_RESULTS')} data-testid="hierarchy-suggest-no-results" />
          ) : null
        }
        query={searchString}
        onQueryChange={onInputChange}
        onItemSelect={onItemSelect}
        popoverProps={{ ...popoverProps, onClose: clearSearch }}
        resetOnSelect
        resetOnClose
        activeItem={item}
        selectedItem={item}
        data-testid="custom-value-editor"
      />
    </div>
  );
};

export default HierarchySuggest;
