import { ColDef } from '@ag-grid-community/core';

import SheetsReferencedCellRenderer from 'components/SheetsReferencedCellRenderer/SheetsReferencedCellRenderer';

import { GetDataSheets_getDeploymentModelSpec_dataSheets } from 'app/graphql/generated/graphqlApolloTypes';

import { SheetsDetailReferencedGridType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const buildBattleCardReferencedColumnDef = (
  selectedSheet: GetDataSheets_getDeploymentModelSpec_dataSheets,
  checkboxRenderer: (params) => JSX.Element
): ColDef[] => {
  const columnDefs: ColDef[] = [];

  columnDefs.push({
    headerName: formatMessage('TYPE'),
    field: 'battlecardType',
    flex: 1,
    minWidth: 120
  });

  columnDefs.push({
    headerName: formatMessage('SHEETS_REFERENCED'),
    field: 'sheetsReferenced',
    flex: 1,
    minWidth: 200,
    cellRendererFramework: SheetsReferencedCellRenderer
  });

  columnDefs.push({
    headerName: formatMessage('REFERENCE_THIS_SHEET'),
    headerTooltip: formatMessage('REFERENCE_THIS_SHEET'),
    field: 'referenceThisSheet',
    flex: 1,
    minWidth: 150,
    checkboxSelection: (params) => {
      if (params?.data?.sheetId === selectedSheet?.sheetId) {
        params.node.setSelected(true);
      }
      // Need to return params?.data?.battlecardType !== BattlecardType.Rollup; once we support multiple sheets
      return false;
    },
    headerCheckboxSelection: false, // disable header checkbox selection because we don't support updating/deleting a reference at this moment
    cellStyle: {
      'background-color': 'rgb(var(--light-blue-translucent))',
      display: 'flex',
      'justify-content': 'center'
    },
    cellRendererSelector: () => {
      return {
        frameworkComponent: checkboxRenderer,
        params: {
          gridType: SheetsDetailReferencedGridType.BATTLE_CARD
        }
      };
    }
  });

  return columnDefs;
};

export default buildBattleCardReferencedColumnDef;
