import React, { FC } from 'react';

import { Spinner } from '@blueprintjs/core';

import block from 'utils/bem-css-modules';

import style from './SymonSpinner.module.pcss';

const b = block(style);

export const SymonSpinner: FC = () => (
  <div className={b()} data-testid="symon-spinner">
    <Spinner intent="primary" />
  </div>
);
export default SymonSpinner;
