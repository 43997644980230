import React, { useEffect, useMemo, useRef } from 'react';

import { ApolloProvider, from, createHttpLink } from '@apollo/client';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

import { useCreateAuthHeader } from 'app/containers/App/EmbeddedAuth/hooks/useCreateAuthHeader';

import { useCustomApolloHeaders } from 'app/contexts/ApolloHeaderProvider';
import { AuthHeaderProvider } from 'app/contexts/AuthHeaderProvider';

import { PRE_TENANT_INFO } from 'app/global/variables';

import { useTenantHeaders } from 'app/hooks/useTenantHeaders';

import { TenantHeader } from 'app/models';

import { config } from 'utils/config';

export let apolloClient: ApolloClient<NormalizedCacheObject>;

export const GRAPHQL_ENDPOINT = config.APPSYNC_GRAPHQL_ENDPOINT;

const AuthApolloWrapper: React.FC = ({ children }) => {
  const tenantHeaders = useTenantHeaders();
  const { headers: customHeaders } = useCustomApolloHeaders();
  const createLink = () => {
    const authLink = setContext(async (operation, { headers, tempToken, cascadeHeadersRef, ...rest }) => {
      if (!tenantHeaders[TenantHeader.TENANT_GLOBAL_ID] && !PRE_TENANT_INFO.includes(operation?.operationName)) {
        throw new Error(`No tenant headers found, blocking operation ${operation?.operationName}`);
      }

      const authorization = tempToken || (await getLiveAuthHeader.current());
      if (!authorization) {
        console.warn('Unauthenticated user making apollo request');
        return { ...rest, headers };
      }

      const cascadeHeaders = { ...cascadeHeadersRef?.current };
      return {
        ...rest,
        headers: {
          ...headers,
          ...tenantHeaders,
          ...customHeaders,
          ...cascadeHeaders,
          authorization
        }
      };
    });
    return from([authLink, createHttpLink({ uri: GRAPHQL_ENDPOINT })]);
  };

  // ensure the apolloClient is not recreated, otherwise
  // redundant queries will occur on the initial load of the app
  apolloClient = useMemo(() => {
    const link = createLink();

    //this needs to be set for any incoming query which needs to support pagination
    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getDeploymentModelSpec: {
              merge: true
            }
          }
        },
        PlanningCycleResponse: {
          keyFields: ['planningCycleId']
        }
      }
    });
    return new ApolloClient({
      link,
      cache,
      connectToDevTools: true
    });
  }, [tenantHeaders]);

  useEffect(() => {
    const link = createLink();
    apolloClient.setLink(link);
  }, [customHeaders]);

  const authHeader = useCreateAuthHeader();
  // This ref allows swapping how the header is sourced without recreating the auth link
  const getLiveAuthHeader = useRef(authHeader.getAuthHeader);
  useEffect(() => {
    getLiveAuthHeader.current = authHeader.getAuthHeader;
  }, [authHeader.getAuthHeader]);

  return (
    <ApolloProvider client={apolloClient}>
      <AuthHeaderProvider value={authHeader}>{children}</AuthHeaderProvider>
    </ApolloProvider>
  );
};
export default AuthApolloWrapper;
