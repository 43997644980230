import { MapCapabilityContext } from 'app/components/TerritoryMap/hooks/useMapCapability';

export enum MapCapability {
  VIEW_ACTIONS_MENU = 'VIEW_ACTIONS_MENU',
  VIEW_UNASSIGNED = 'VIEW_UNASSIGNED',
  VIEW_CUSTOMER_PINS = 'VIEW_CUSTOMER_PINS',
  VIEW_CUSTOM_PINS = 'VIEW_CUSTOM_PINS',
  EDIT_CUSTOM_PINS = 'EDIT_CUSTOM_PINS',
  SELECT = 'SELECT',
  DELETE_RULE = 'DELETE_RULE',
  CREATE_RULE = 'CREATE_RULE',
  FIX_OVERLAP = 'FIX_OVERLAP',
  ASSIGN_TO_RULE = 'ASSIGN_TO_RULE',
  UNASSIGN_FROM_RULE = 'UNASSIGN_FROM_RULE',
  RECOLOR = 'RECOLOR',
  ACCOUNT_POINTS_FILTER = 'ACCOUNT_POINTS_FILTER',
  TOOLBAR = 'TOOLBAR',
  UNDO = 'UNDO',
  VIEW_VISUALIZATION_SETTINGS = 'VIEW_VISUALIZATION_SETTINGS'
}
type MapCapabilityChecker = (context: MapCapabilityContext) => boolean;

const every =
  (...checkers: MapCapabilityChecker[]): MapCapabilityChecker =>
  (context) =>
    checkers.every((checker) => checker(context));

const isEditableSituation: MapCapabilityChecker = ({ situation }) =>
  situation.canSeeAllRules &&
  !situation.isOverlayBattleCard &&
  !situation.isEmbedded &&
  !situation.isPreview &&
  !situation.isColoringByTerritoryGroup;

const isRuleEditEnabledForComplexity: MapCapabilityChecker = ({ situation }) =>
  !situation.areCustomHierarchyRulesVisible || situation.isExactCustomHierarchyFilterActive;

const canEditRules: MapCapabilityChecker = every(
  isEditableSituation,
  isRuleEditEnabledForComplexity,
  ({ permissions }) => permissions.ruleEdit
);

const canViewMapToolbar: MapCapabilityChecker = ({ situation }) => {
  if (situation.isPreview) return false;
  return !situation.isColoringByTerritoryGroup;
};

const canViewAccountPointsFilter: MapCapabilityChecker = ({ situation }) => {
  if (situation.isPreview) return false;
  if (!situation.isAccountsVisible) return false;
  if (situation.isAccountFirstMap) return false;
  return !situation.isColoringByTerritoryGroup;
};

export const MapCapabilityRegistry: Record<MapCapability, MapCapabilityChecker> = {
  [MapCapability.VIEW_UNASSIGNED]: ({ permissions, situation }) =>
    permissions.unassignedView && situation.canSeeAllRules && !situation.isEmbedded && !situation.isPreview,

  [MapCapability.VIEW_CUSTOMER_PINS]: ({ permissions, flags, situation }) =>
    (permissions.pinsView || flags.isContributorMapPinsOn) && !situation.isColoringByTerritoryGroup,
  [MapCapability.VIEW_CUSTOM_PINS]: ({ permissions, flags }) => permissions.pinsView || flags.isContributorMapPinsOn,
  [MapCapability.EDIT_CUSTOM_PINS]: ({ situation, permissions }) => permissions.pinsEdit && !situation.isEmbedded,

  [MapCapability.VIEW_ACTIONS_MENU]: canEditRules,

  [MapCapability.SELECT]: ({ situation }) => !situation.isPreview && !situation.isColoringByTerritoryGroup,

  [MapCapability.DELETE_RULE]: canEditRules,
  [MapCapability.CREATE_RULE]: canEditRules,
  [MapCapability.FIX_OVERLAP]: canEditRules,

  [MapCapability.ASSIGN_TO_RULE]: canEditRules,
  [MapCapability.UNASSIGN_FROM_RULE]: canEditRules,

  [MapCapability.RECOLOR]: every(
    isEditableSituation,
    isRuleEditEnabledForComplexity,
    ({ permissions }) => permissions.recolor
  ),

  [MapCapability.ACCOUNT_POINTS_FILTER]: canViewAccountPointsFilter,
  [MapCapability.TOOLBAR]: canViewMapToolbar,

  [MapCapability.UNDO]: every(canEditRules, ({ flags }) => flags.isUndoEnabled),

  [MapCapability.VIEW_VISUALIZATION_SETTINGS]: ({ permissions, flags, situation }) =>
    !flags.isAccountFirstMapOn ||
    (permissions.settingsView && !situation.isMultiMapOpen && !situation.isEmbedded && !situation.isPreview)
};
