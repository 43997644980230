import { BattlecardType, CardCopy, SectionName } from 'app/models';

import { ROOT_CARD_COPY, ROLL_UP_CARD_COPY, TEAM_OVERLAY_CARD_COPY } from 'utils/uiCopy/battleCards';

// determine which panel is open from the panelOpenState object
// note: only one panel can be expanded at a time
export const getOpenPanel = (panelOpenState: Record<string, boolean>): string => {
  for (const [panelName, isOpen] of Object.entries(panelOpenState)) {
    if (isOpen) {
      return panelName;
    }
  }
  return null;
};

export const getCardCopy = (battlecardType: BattlecardType, isRootCard: boolean): CardCopy => {
  let cardCopy: CardCopy;
  if (battlecardType === BattlecardType.Rollup) {
    cardCopy = isRootCard ? ROOT_CARD_COPY : ROLL_UP_CARD_COPY;
  } else {
    cardCopy = TEAM_OVERLAY_CARD_COPY;
  }
  return cardCopy;
};

export const isSectionExpandable = (section: string): boolean => {
  return (
    section === SectionName.PLAN_TARGETS || section === SectionName.TERRITORY_DEFINE || section === SectionName.WORKFLOW
  );
};
