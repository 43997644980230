import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import {
  generateImpersonationToken,
  generateImpersonationTokenVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GENERATE_IMPERSONATION_TOKEN = gql`
  mutation generateImpersonationToken($input: GenerateImpersonationTokenInput!) {
    generateImpersonationToken(input: $input) {
      token
    }
  }
`;

export const useGenerateImpersonationToken = createUseMutation<
  generateImpersonationToken,
  generateImpersonationTokenVariables
>(GENERATE_IMPERSONATION_TOKEN);
