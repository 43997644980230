import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertSheet, UpsertSheetVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const UPSERT_SHEET = gql`
  mutation UpsertSheet($input: UpsertSheetInput!) {
    upsertSheet(input: $input) {
      success
      sheetIds
    }
  }
`;

export const useUpsertSheet = createUseMutation<UpsertSheet, UpsertSheetVariables>(UPSERT_SHEET);
