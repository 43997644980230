import React from 'react';

import { ChevronDown } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import { useData } from 'app/contexts/dataProvider';

import { SheetType } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './SheetGridHeader.module.pcss';

const b = block(style);

interface SheetGridHeaderProps {
  title: string;
  subtitle?: string;
  leftIcon?: boolean;
  rightButton?: React.ReactNode;
}

const SheetGridHeader: React.FC<SheetGridHeaderProps> = ({
  title,
  subtitle,
  leftIcon = false,
  rightButton
}: SheetGridHeaderProps) => {
  const { selectedSheet } = useData();
  return (
    <div className={b('gridSection')}>
      <div className={b('gridHeaderContainer')}>
        <div className={b('gridHeader')}>
          {leftIcon && (
            <span data-testid="sheet-grid-left-icon" className={b('leftIcon')}>
              <ChevronDown />
            </span>
          )}
          <div data-testid="sheet-grid-header-text">
            <HTMLHeading style={{ display: 'inline' }} tagLevel={'h5'} bold text={title} />
            {subtitle ? <span className={b('subtitle')}>{` - ${subtitle}`}</span> : null}
          </div>
        </div>
        {/* Disable add button as temporary change for quota distribution sheet */}
        {selectedSheet?.sheetType !== SheetType.QUOTA_DISTRIBUTION_SHEET && rightButton}
      </div>
    </div>
  );
};

export default SheetGridHeader;
