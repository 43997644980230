// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6rSxO {\n  display: inline-flex;\n  align-items: center;\n  font-weight: normal;\n  font-size: 14px;\n}\n\n  .PW5kM {\n    margin-right: 6px;\n    transform: scale(1.4);\n    margin-left: 2px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectAllCheckboxHeaderCellRenderer": "_6rSxO",
	"SelectAllCheckboxHeaderCellRenderer_checkbox": "PW5kM"
};
export default ___CSS_LOADER_EXPORT___;
