import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertGeoSelection, UpsertGeoSelectionVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const UPSERT_GEO_SELECTION = gql`
  mutation UpsertGeoSelection($input: UpsertGeoHierarchySelectionInput!) {
    upsertGeoHierarchySelection(input: $input) {
      planningCycleId
    }
  }
`;

export const useUpsertGeoSelectionRaw = createUseMutation<UpsertGeoSelection, UpsertGeoSelectionVariables>(
  UPSERT_GEO_SELECTION
);
