import { ColDef } from '@ag-grid-community/core';

import CurrencyCellRenderer from 'app/components/AdvancedGrid/CellRenderers/CurrencyCellRenderer/CurrencyCellRenderer';
import FooterCellRenderer from 'app/components/AdvancedGrid/CellRenderers/FooterCellRenderer/FooterCellRenderer';

import { GridFields, GridHeaders, SelectedAccountQuotaDrillInCell } from 'app/models';

const buildAccountQuotaDrillinColumnDefs = (
  currency: string,
  handleOnBlur: (currentValue: string) => void,
  onCellClick: (data: SelectedAccountQuotaDrillInCell) => void
): ColDef[] => {
  return [
    {
      headerName: GridHeaders.ACCOUNT_KEY,
      field: GridFields.ACCOUNT_KEY,
      minWidth: 300,
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            frameworkComponent: FooterCellRenderer,
            params: { alignLeft: true }
          };
        }
        return { frameworkComponent: '' };
      }
    },
    {
      headerName: GridHeaders.ACCOUNT_NAME,
      field: GridFields.ACCOUNT_NAME,
      minWidth: 400
    },
    {
      headerName: GridHeaders.ACCOUNT_QUOTA,
      field: GridFields.ACCOUNT_QUOTA,
      minWidth: 200,
      editable: true,
      cellEditor: 'currencyCellEditor',
      cellEditorParams: {
        currency,
        handleOnBlur,
        onCellClick
      },
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            frameworkComponent: FooterCellRenderer,
            params: { currency, measureType: 'currency' }
          };
        }
        return {
          frameworkComponent: CurrencyCellRenderer,
          params: { currency, onCellClick, isLink: false }
        };
      }
    },
    {
      headerName: '',
      field: '',
      minWidth: 0,
      flex: 1
    }
  ];
};

export default buildAccountQuotaDrillinColumnDefs;
