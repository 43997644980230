// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IMm2d {\n  width: 100%;\n  height: calc(100% - 300px);\n}\n\n  ._2HmNl {\n    display: flex;\n    margin-left: 5px;\n  }\n\n  .zVeWf {\n    margin-right: 10px;\n  }\n\n  .ViSdL {\n    text-align: left;\n  }\n\n  .qhpcw {\n    height: 100%;\n    border-bottom-left-radius: var(--border-rad);\n    border-bottom-right-radius: var(--border-rad);\n  }\n\n  .fVgXv {\n      cursor: not-allowed;\n    }\n\n  .nitWv {\n    color: rgb(var(--color-red-3));\n    margin-top: 5px;\n  }\n\n  .Z3FcR {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: 40px;\n    border: 1px solid rgb(var(--color-light-gray-2));\n    border-bottom: none;\n    border-top-left-radius: var(--border-rad);\n    border-top-right-radius: var(--border-rad);\n    background: rgb(var(--color-light-gray-5));\n  }\n\n  .E4g84 {\n      cursor: not-allowed;\n    }\n\n  .Z3FcR .bp3-menu {\n      width: 250px !important;\n      border: none !important;\n    }\n\n  .Z3FcR .bp3-button:not([class*='bp3-intent-']) {\n      border: none !important;\n      background: transparent !important;\n      box-shadow: none;\n      color: rgb(var(--color-gray-2));\n    }\n\n  .Z3FcR .bp3-button:not([class*='bp3-intent-']):hover {\n      background: rgba(var(--color-cobalt-4), 0.15) !important;\n      color: rgb(var(--color-cobalt-3));\n      box-shadow: none;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormulaEditor": "IMm2d",
	"FormulaEditor_dropdownButtons": "_2HmNl",
	"FormulaEditor_tooltip": "zVeWf",
	"FormulaEditor_tooltipContent": "ViSdL",
	"FormulaEditor_formulaEditArea": "qhpcw",
	"FormulaEditor_formulaEditArea__disabled": "fVgXv",
	"FormulaEditor_errorMessage": "nitWv",
	"FormulaEditor_formulaEditorHeader": "Z3FcR",
	"FormulaEditor_formulaEditorHeader__disabled": "E4g84"
};
export default ___CSS_LOADER_EXPORT___;
