import React from 'react';

import { Divider } from '@blueprintjs/core';
import { ChevronLeft } from '@carbon/icons-react';

import EllipsisText from 'components/EllipsisText/EllipsisText';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './DataTrayDrillInViewHeader.module.pcss';

interface DataTrayDrillInViewHeaderProps {
  rowDescriptionText: string;
  drillInTitleText: string;
  onClickBackButton: (arg: boolean) => void;
}
const b = block(style);

const DataTrayDrillInViewHeader: React.FC<DataTrayDrillInViewHeaderProps> = ({
  rowDescriptionText,
  drillInTitleText,
  onClickBackButton
}: DataTrayDrillInViewHeaderProps) => {
  return (
    <div className={b('drillInHeader')} data-testid="data-tray-drill-in-header-view">
      <button className={b('backButton')} onClick={() => onClickBackButton(false)}>
        <div className={b('icon')}>
          <ChevronLeft />
        </div>
        <div className={b('buttonText')} data-testid="drill-in-back-btn">
          {formatMessage('BACK')}
        </div>
      </button>
      <div className={b('drillInHeaderText')} data-testid="row-description-text">
        <EllipsisText text={rowDescriptionText} />
      </div>
      <Divider />
      <div className={b('drillInHeaderText')} data-testid="drill-in-title-text">
        <EllipsisText text={drillInTitleText} />
      </div>
    </div>
  );
};

export default DataTrayDrillInViewHeader;
