import React from 'react';

import { Checkmark } from '@carbon/icons-react';

import Dialog from 'components/Dialog/Dialog';

import { usePublishAndApplyScenario } from 'app/components/PlanningCyclePageHeader/usePublishAndApplyScenario';

import { ScenarioSummary } from 'app/graphql/hooks/useScenarioSummaries';

import { formatMessage } from 'utils/messages/utils';

interface PublishScenarioDialogProps {
  onClose: () => void;
  relevantScenario: ScenarioSummary;
}

const PublishScenarioDialog: React.FC<PublishScenarioDialogProps> = ({ onClose, relevantScenario }) => {
  const { publishScenario, loading } = usePublishAndApplyScenario();

  return (
    <Dialog
      title={formatMessage('PUBLISH_SCENARIO')}
      isOpen
      onSubmit={async () => {
        await publishScenario(relevantScenario.deploymentModelId);
        onClose();
      }}
      onClose={onClose}
      confirmButtonIcon={<Checkmark />}
      confirmButtonLoading={loading}
      confirmButtonText={formatMessage('PUBLISH')}
      bodyMinHeight={0}
      size="small"
    >
      {formatMessage('PUBLISH_SCENARIO_CONFIRMATION', { scenarioName: relevantScenario.deploymentModelName })}
    </Dialog>
  );
};

export default PublishScenarioDialog;
