import React from 'react';

import { WarningAltFilled } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './WarningButton.module.pcss';

const b = block(style);

interface WarningButtonProps {
  tooltipText?: string;
  onClick?: (e) => void;
  testId: string;
}

const WarningButton: React.FC<WarningButtonProps> = ({
  tooltipText,
  onClick,
  testId = 'warning-button'
}: WarningButtonProps) => {
  return (
    <IconButton
      type={'button'}
      icon={<WarningAltFilled size={24} className={b('warningIcon')} tabIndex={'0'} />} // this icon will throw errors if a tabIndex is not provided
      onClick={(e) => onClick && onClick(e)}
      tooltipText={tooltipText}
      className={b()}
      testId={testId}
      title={formatMessage('WARNING')}
    />
  );
};

export default WarningButton;
