import React, { useImperativeHandle, useState } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';
import CurrencyInput from 'react-currency-input-field';

import { useLocalization } from 'app/contexts/localizationProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { NumberInputFormatStyle } from 'app/models';

import block from 'utils/bem-css-modules';
import { getFormattedValue, numberUnformatter, isValidDecimalInput } from 'utils/helpers/formNumberFormattingUtils';
import { getLocale } from 'utils/helpers/getLocale';

import style from './FormatNumbersCellEditor.module.pcss';

const b = block(style);

interface FormatNumbersCellEditorProps extends ICellEditorParams {
  placeHolder?: string;
  currency: string;
  formatStyle: NumberInputFormatStyle;
  maximumFractionDigits?: number;
}

const FormatNumbersCellEditor = (props: FormatNumbersCellEditorProps, ref) => {
  const { value: currentValue, placeHolder, formatStyle, currency } = props;
  const [value, setValue] = useState(currentValue ? currentValue.toString() : undefined);
  const { defaultReportingCurrency } = useLocalization();
  const [isSellerAssignmentTwoDecimalEnabled] = useTreatment(SplitFeatures.SELLER_ASSIGNMENT_TWO_DECIMAL_PLACES);
  const maximumFractionDigits = props.maximumFractionDigits ? props.maximumFractionDigits : 0;

  const handleOnChange = (inputValue) => {
    if (isSellerAssignmentTwoDecimalEnabled && formatStyle === NumberInputFormatStyle.PERCENTAGE) {
      if (isValidDecimalInput(inputValue, maximumFractionDigits)) {
        setValue(inputValue);
      }
      return;
    }

    if (formatStyle === NumberInputFormatStyle.PERCENTAGE) {
      const unformattedValue = numberUnformatter(inputValue);
      setValue(unformattedValue);
    } else {
      setValue(inputValue);
    }
  };

  // necessary for ag-grid cell editors to return value back to grid
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      }
    };
  });

  const renderInput = () => {
    if (isSellerAssignmentTwoDecimalEnabled && formatStyle === NumberInputFormatStyle.PERCENTAGE) {
      return (
        <input
          className={b('formatNumbersInput', { percentage: true })}
          data-testid={'format-numbers-input'}
          placeholder={value || placeHolder}
          value={value}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      );
    } else if (formatStyle === NumberInputFormatStyle.PERCENTAGE) {
      return (
        <input
          className={b('formatNumbersInput', { percentage: true })}
          data-testid={'format-numbers-input'}
          placeholder={value ? value : placeHolder}
          value={value ? getFormattedValue(value, defaultReportingCurrency, formatStyle) : ''}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      );
    } else {
      return (
        <CurrencyInput
          data-testid="editable-currency-input"
          className={b('formatNumbersInput')}
          decimalScale={0}
          value={value}
          placeholder={placeHolder}
          onValueChange={handleOnChange}
          intlConfig={{ locale: getLocale(), currency: currency || defaultReportingCurrency }}
          allowNegativeValue={true}
          decimalsLimit={4}
        />
      );
    }
  };

  return (
    <div className={b('formatNumbersInputWrapper')} data-testid="format-numbers-cell-editor">
      {renderInput()}
    </div>
  );
};

export default React.forwardRef(FormatNumbersCellEditor);
