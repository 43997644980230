// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CxuLt {\n  height: 48px;\n  background-color: rgb(var(--color-light-gray-5));\n  box-shadow: inset 0 -1px var(--border);\n  display: flex;\n  align-items: center;\n}\n\n  .ODtt4 {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    align-items: center;\n    padding-right: var(--m);\n  }\n\n  .IFbAU {\n    margin-top: var(--m);\n    display: flex;\n    align-items: center;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n  }\n\n  .IFbAU .bp3-minimal {\n      background-color: transparent !important;\n    }\n\n  .hcXGw {\n    margin-bottom: var(--m);\n  }\n\n  .cE9NM {\n    background: white;\n    padding: 2px 7px;\n    border-radius: 50%;\n    margin-left: var(--xs);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountQuotaHeader": "CxuLt",
	"AccountQuotaHeader_container": "ODtt4",
	"AccountQuotaHeader_tgtWrapper": "IFbAU",
	"AccountQuotaHeader_filterButton": "hcXGw",
	"AccountQuotaHeader_filterCount": "cE9NM"
};
export default ___CSS_LOADER_EXPORT___;
