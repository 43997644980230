import React, { useState, useEffect } from 'react';

import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { formatMessage } from 'utils/messages/utils';

interface TimerProps {
  time: Date;
}

const Timer: React.FC<TimerProps> = ({ time }: TimerProps) => {
  const [message, setMessage] = useState<string>(null);

  const getTime = (time: Date) => {
    const timeDuration = Math.abs(Date.now() - time.getTime());
    return {
      days: Math.floor(timeDuration / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeDuration / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((timeDuration / 1000 / 60) % 60),
      seconds: Math.floor((timeDuration / 1000) % 60)
    };
  };

  const updateMessage = () => {
    const { days, hours, minutes } = getTime(time);
    const message = getMessage(days, hours, minutes);
    setMessage(message);
  };

  useEffect(() => {
    if (message === null) {
      updateMessage();
    }
    const interval = setInterval(() => {
      updateMessage();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getMessage = (days: number, hours: number, minutes: number) => {
    let message: string;
    if (days === 1) {
      message = formatMessage('ONE_DAY_AGO');
    } else if (days > 1) {
      message = formatMessage('DAYS_AGO', { numDays: days });
    } else if (hours === 1) {
      message = formatMessage('ONE_HOUR_AGO');
    } else if (hours > 1) {
      message = formatMessage('HOURS_AGO', { numHours: hours });
    } else if (minutes > 1) {
      message = formatMessage('MINUTES_AGO', { numMinutes: minutes });
    } else if (minutes === 1) {
      message = formatMessage('A_MINUTE_AGO');
    } else {
      message = formatMessage('JUST_NOW');
    }
    return message;
  };

  return (
    <div data-testid="timer">
      {message && (
        <MessageTooltip
          data-testid="territory-tooltip"
          content={time?.toLocaleString()}
          target={<div data-testid="timer-message">{message}</div>}
          placement={'top'}
          usePortal={false}
        />
      )}
    </div>
  );
};

export default Timer;
