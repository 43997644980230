import { useEffect, useState } from 'react';

import { useGetS3File } from 'app/graphql/queries/getS3File';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

interface UseRequiredHeadersProps {
  fileRows: string[];
  headersData: string[];
  s3FileLoading: boolean;
}

export const useS3File = (fileId: string, startRow: number, endRow: number, skip: boolean): UseRequiredHeadersProps => {
  const [fileRows, setFileRows] = useState([]);
  const [headersData, setHeadersData] = useState([]);
  const showToast = useShowToast();

  const { data, loading } = useGetS3File({
    skip,
    variables: { fileId, startRow, endRow },
    fetchPolicy: 'network-only',
    onError() {
      showToast(formatMessage('GET_S3_FILE_ERROR'), 'danger');
    }
  });

  useEffect(() => {
    if (!loading && data) {
      setFileRows(data.getS3File?.fileRows);
      setHeadersData(data.getS3File?.headers);
    }
  }, [data, loading]);

  return { fileRows, headersData, s3FileLoading: loading };
};
