// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wTiU0 {\n  padding: var(--dialog-padding) 0;\n}\n\n  .IUeMg {\n    font-size: 12px;\n  }\n\n  .tQPT3 {\n    font-weight: 600 !important;\n    padding-top: 24px !important;\n  }\n\n  .tQPT3:first-of-type {\n    padding-top: 8px !important;\n  }\n\n  .UYS-v {\n  }\n\n  .ll-tx {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .Eq8Fn {\n    display: flex;\n    grid-gap: 16px;\n    margin-top: 16px;\n  }\n\n  .JN3mR {\n    display: flex;\n    grid-gap: 16px;\n    margin-top: 16px;\n  }\n\n  .BzVjO {\n    display: flex;\n    padding-top: 24px;\n  }\n\n  .Fk5T1 {\n    padding-top: 4px;\n  }\n\n  .O713C {\n    font-weight: normal;\n    font-size: 14px;\n  }\n\n  .wTiU0 .bp3-divider {\n    margin: 24px 0;\n  }\n\n  .WZOwx {\n    width: 50%;\n  }\n\n  .tvoyf {\n    margin-top: 16px;\n  }\n\n  .ryXrZ {\n    margin-top: 16px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReviewImportConfiguration": "wTiU0",
	"ReviewImportConfiguration_subTitle": "IUeMg",
	"ReviewImportConfiguration_heading": "tQPT3",
	"ReviewImportConfiguration_subText": "UYS-v " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["sub-text"] + "",
	"ReviewImportConfiguration_textContainer": "ll-tx",
	"ReviewImportConfiguration_dataFile": "Eq8Fn",
	"ReviewImportConfiguration_configuration": "JN3mR",
	"ReviewImportConfiguration_checkbox": "BzVjO",
	"ReviewImportConfiguration_description": "Fk5T1",
	"ReviewImportConfiguration_checkboxLabel": "O713C",
	"ReviewImportConfiguration_destinationFieldContainer": "WZOwx",
	"ReviewImportConfiguration_destinationInput": "tvoyf",
	"ReviewImportConfiguration_runOnCreateCheckbox": "ryXrZ"
};
export default ___CSS_LOADER_EXPORT___;
