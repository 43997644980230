import { gql } from '@apollo/client';

export const GET_TERRITORY_RULES_FOR_SEARCH = gql`
  query GetTerritoryRulesForSearch(
    $quotaComponentId: Int!
    $measureId: Int!
    $sorting: GetTerritoryRulesSortModel!
    $searchInput: GetTerritoryRulesSearchInput
    $territoryGroupId: Int
    $battlecardId: Int
    $startRow: Int
    $endRow: Int
    $completeDefinition: Boolean
  ) {
    getTerritoryRules(
      input: {
        measureId: $measureId
        quotaComponentId: $quotaComponentId
        sorting: $sorting
        searchInput: $searchInput
        territoryGroupId: $territoryGroupId
        battlecardId: $battlecardId
        startRow: $startRow
        endRow: $endRow
        completeDefinition: $completeDefinition
      }
    ) {
      territoryRules {
        effectiveDate
        ruleId
        territoryId
        territoryName
        territoryGroupId
        territoryGroupName
      }
      totalCount
    }
  }
`;
