import gql from 'graphql-tag';

export const UPSERT_QUOTA_COMPONENT = gql`
  mutation upsertQuotaComponent(
    $deleted: String
    $deploymentModelId: Int!
    $quotaComponentComment: String!
    $quotaComponentId: Int
    $quotaComponentName: String!
  ) {
    upsertQuotaComponent(
      input: {
        deleted: $deleted
        deploymentModelId: $deploymentModelId
        quotaComponentComment: $quotaComponentComment
        quotaComponentName: $quotaComponentName
        quotaComponentId: $quotaComponentId
      }
    ) {
      deleted
      deploymentModelId
      quotaComponentComment
      quotaComponentId
      quotaComponentName
    }
  }
`;
