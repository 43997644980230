import gql from 'graphql-tag';

export const GET_FILE_UPLOAD_PROGRESS = gql`
  query GetFileUploadProgress($fileIds: [String!]!) {
    getFileUploadProgress(input: { fileIds: $fileIds }) {
      fileId
      fileName
      fileType
      planningCycleId
      tenantId
      status
      message
      metaDataCreatedAt
      metaDataUpdateAt
    }
  }
`;
