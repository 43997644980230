// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3Pcxs {\n  display: flex;\n  align-items: center;\n}\n\n  .baMRL {\n    max-height: 200px;\n    overflow-y: auto;\n    overflow-x: hidden;\n  }\n\n  ._3Pcxs .bp3-button:not([class*='bp3-intent-']) {\n    background-color: white;\n    color: rgb(var(--color-midnight-blue-2));\n    height: 36px;\n    box-shadow: none;\n    border: 1px solid rgb(var(--color-midnight-blue-4));\n    min-width: 160px;\n    justify-content: space-between;\n    padding: 6px 12px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectMenuHeaderCellRenderer": "_3Pcxs",
	"SelectMenuHeaderCellRenderer_content": "baMRL"
};
export default ___CSS_LOADER_EXPORT___;
