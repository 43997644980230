// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DW\\+Ls {\n    height: 100%;\n  }\n  .LLrzM .bp3-input {\n      height: var(--select-menu-height);\n    }\n  .LLrzM .bp3-button {\n      height: var(--select-menu-height);\n    }\n  .T3NRL {\n    cursor: default;\n    margin-bottom: 1rem;\n  }\n  .mwqOW.bp3-popover2-target {\n    display: flex;\n    height: 100%;\n    align-items: center;\n  }\n  .VKeze .bp3-popover2 .bp3-popover2-content {\n    list-style: none;\n  }\n  .VKeze .bp3-menu-item {\n    color: rgb(var(--color-gray-2)) !important;\n  }\n  .VKeze .bp3-menu-item.bp3-intent-primary {\n    background-color: var(--white) !important;\n  }\n  .VKeze .bp3-menu-item:hover {\n    color: var(--intent-primary) !important;\n    background-color: var(--intent-primary-translucent-active) !important;\n  }\n  .QygOH {\n  }\n  .T394U {\n    display: flex !important;\n    align-items: center;\n  }\n  .yWR3x {\n    display: inline-block !important;\n    margin-top: 0 !important;\n    margin-left: 5px;\n  }\n  .LQvT6 {\n  }\n  ._3Lm2S {\n    font-weight: normal;\n  }\n  .v7Q6I {\n    width: 100%;\n  }\n  .\\-8LwJ {\n    height: 100%;\n  }\n  .zGPzy {\n    color: rgb(var(--color-red-3));\n    margin-bottom: 1rem;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SheetsAddFieldFormFields_calculatedFieldFormField": "DW+Ls",
	"SheetsAddFieldFormFields_formInput": "LLrzM",
	"SheetsAddFieldFormFields_staticFieldValue": "T3NRL",
	"SheetsAddFieldFormFields_menuCellPopover": "mwqOW",
	"SheetsAddFieldFormFields": "VKeze",
	"SheetsAddFieldFormFields_label": "QygOH " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label-text"] + "",
	"SheetsAddFieldFormFields_labelWithInformationTooltip": "T394U " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label-text"] + "",
	"SheetsAddFieldFormFields_informationTooltip": "yWR3x",
	"SheetsAddFieldFormFields_subCheckbox": "LQvT6 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["sub-checkbox"] + "",
	"SheetsAddFieldFormFields_checkboxLabel": "_3Lm2S",
	"SheetsAddFieldFormFields_formOneTooltip": "v7Q6I",
	"SheetsAddFieldFormFields_measureFormPage": "-8LwJ",
	"SheetsAddFieldFormFields_validationMessage": "zGPzy"
};
export default ___CSS_LOADER_EXPORT___;
