// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4O2kJ .bp3-dialog {\n    width: 620px;\n    box-sizing: border-box;\n    padding: 24px 24px 29px;\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);\n  }\n  ._4O2kJ .bp3-overlay-backdrop {\n    background-color: var(--background-overlay);\n  }\n  .ksUsH {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 32px;\n  }\n  ._4QBFa {\n    font-size: var(--ml);\n    color: rgb(var(--color-dark-gray-3));\n    font-weight: bold;\n  }\n  .A4gOA {\n    min-height: 100px;\n    font-size: 14px;\n    color: rgb(var(--color-gray-2));\n  }\n  .MyogR {\n    height: 24px;\n  }\n  ._2sAZp {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n  ._3RLcO {\n    background-color: rgb(var(--color-rose-3)) !important;\n    color: white !important;\n  }\n  ._4O2kJ .bp3-overlay.bp3-overlay-scroll-container.bp3-overlay-inline {\n    position: fixed;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConfirmActionModal": "_4O2kJ",
	"ConfirmActionModal_modalHeader": "ksUsH",
	"ConfirmActionModal_title": "_4QBFa",
	"ConfirmActionModal_modalBody": "A4gOA",
	"ConfirmActionModal_loadingPlaceholder": "MyogR",
	"ConfirmActionModal_modalFooter": "_2sAZp",
	"ConfirmActionModal_accentButton": "_3RLcO"
};
export default ___CSS_LOADER_EXPORT___;
