// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rBfKT .bp3-button {\n    padding: 6px;\n    transition-property: background-color;\n    transition-duration: 0.2s;\n  }\n  .tAeIr .bp3-button > svg {\n        fill: rgb(var(--color-light-gray-2));\n        color: rgb(var(--color-light-gray-2));\n      }\n  .tAeIr .bp3-button {\n\n      pointer-events: none;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconButton": "rBfKT",
	"IconButton__disabled": "tAeIr"
};
export default ___CSS_LOADER_EXPORT___;
