import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';

import { config } from 'utils/config';

interface S3UploadInput {
  tqAccessKeyId: string;
  tqSecretKey: string;
  tqS3BucketId: string;
  tqS3FileName: string;
  body: File;
}

export const uploadFileViaS3 = async (input: S3UploadInput): Promise<void> => {
  try {
    const { tqAccessKeyId, tqSecretKey, tqS3BucketId, tqS3FileName, body } = input;
    const upload = new Upload({
      client: new S3Client({
        credentials: {
          accessKeyId: tqAccessKeyId,
          secretAccessKey: tqSecretKey
        },
        region: config.AWS_REGION
      }),
      params: {
        Bucket: tqS3BucketId,
        Key: tqS3FileName,
        Body: body
      }
    });
    await upload.done();
  } catch (e) {
    const error = new Error('S3 upload error');
    if (e instanceof Error) {
      error.stack = e.stack;
      error.message = `${error.message}. ${e.message}`;
    }
    throw error;
  }
};
