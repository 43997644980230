import gql from 'graphql-tag';

export const UPSERT_TERRITORY_QUOTA_COMMENT = gql`
  mutation UpsertComment($input: UpsertTerritoryQuotaCommentInput!) {
    upsertTerritoryQuotaComment(input: $input) {
      quotaComponentId
      territoryGroupId
      territoryId
      comment
      factorPath
      ruleId
    }
  }
`;
