import React from 'react';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './PermissionHeaderCellRenderer.module.pcss';

const b = block(style);

const PermissionColumnHeader: React.FC = () => {
  return (
    <div className={b('permissionColumnHeader')}>
      <span data-testid="visible-cell">{formatMessage('VISIBLE')}</span>
      <span data-testid="editable-cell">{formatMessage('EDITABLE')}</span>
    </div>
  );
};

export default PermissionColumnHeader;
