import React, { FC } from 'react';

import AuthSpinner from 'components/AuthSpinner/AuthSpinner';

import { useEmbeddedMapBcQcSelection } from 'app/components/TerritoryMap/hooks/useEmbeddedMapBcQcSelection';
import TerritoryMapV3 from 'app/components/TerritoryMap/TerritoryMapV3';

import { DedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { EmbeddedMcrLoader } from 'app/contexts/MapContextRedistributor/EmbeddedMcrLoader';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { MapVariantProvider } from 'app/contexts/mapVariantProvider';
import { MapPermissionsLoader } from 'app/contexts/PermissionsContextRedistributor/MapPermissionsLoader';

import block from 'utils/bem-css-modules';

import style from './EmbeddedMap.module.pcss';
import { EmbeddedMapNoAccessView } from './EmbeddedMapFallbackViews';
import { EmbeddedMapHeader } from './EmbeddedMapHeader';

const b = block(style);

export const EmbeddedMap: FC = () => {
  const {
    isLoading,
    availableBattleCards,
    selectedBattleCard,
    selectedQuotaComponentId,
    setSelectedBattleCardId,
    setSelectedQuotaComponentId
  } = useEmbeddedMapBcQcSelection();
  if (!isLoading && availableBattleCards.length === 0) {
    return <EmbeddedMapNoAccessView />;
  }

  return (
    <div className={b()}>
      <MapVariantProvider isEmbedded={true}>
        <EmbeddedMcrLoader selectedBattleCard={selectedBattleCard} selectedQuotaComponentId={selectedQuotaComponentId}>
          <MapPermissionsLoader>
            <DedicatedMapProvider>
              {!isLoading ? (
                <>
                  <EmbeddedMapHeader
                    availableBattleCards={availableBattleCards}
                    selectedBattleCardId={selectedBattleCard?.battlecardId ?? null}
                    selectedQuotaComponentId={selectedQuotaComponentId}
                    onSelectedBattleCardChange={setSelectedBattleCardId}
                    onSelectedQuotaComponentChange={setSelectedQuotaComponentId}
                    localCurrencyCode={selectedBattleCard?.localCurrencyCode}
                  />
                  <MapWithScope />
                </>
              ) : (
                <AuthSpinner />
              )}
            </DedicatedMapProvider>
          </MapPermissionsLoader>
        </EmbeddedMcrLoader>
      </MapVariantProvider>
    </div>
  );
};

const MapWithScope = () => {
  const { selectedTerritoryGroupId } = useMapContextRedistributor();
  if (!selectedTerritoryGroupId) return <AuthSpinner />;
  return <TerritoryMapV3 />;
};
