import React, { useState } from 'react';

import { Download } from '@carbon/icons-react';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { CSVLink } from 'react-csv';

import SelectMenu from 'components/SelectMenu/SelectMenu';

import DataTypeTagList from 'app/components/DataTypeTagList/DataTypeTagList';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import FileUploadSequence from 'app/core/fileUpload/FileUploadSequence/FileUploadSequence';

import { DOWNLOAD_LOCATION_TEMPLATE_FILE_NAME, LOCATION_HEADERS } from 'app/global/variables';

import { DataType, FileType, LocationColumnHeaderKeys, LocationGroup } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './LocationGroupForm.module.pcss';

const b = block(style);

//It's intentional not to translate map pins into another language other then en-US, please do not format
const LocationColumnHeaders = [
  {
    key: LocationColumnHeaderKeys.COUNTRY_COLUMN,
    value: LocationColumnHeaderKeys.COUNTRY_COLUMN,
    properties: { colRequired: true, dataType: DataType.TEXT }
  },
  {
    key: LocationColumnHeaderKeys.ZIP_POSTAL,
    value: LocationColumnHeaderKeys.ZIP_POSTAL,
    properties: { colRequired: true, dataType: DataType.TEXT }
  },
  {
    key: LocationColumnHeaderKeys.NAME_COLUMN,
    value: LocationColumnHeaderKeys.NAME_COLUMN,
    properties: { colRequired: false, dataType: DataType.TEXT }
  },
  {
    key: LocationColumnHeaderKeys.ADDRESS_COLUMN_1,
    value: LocationColumnHeaderKeys.ADDRESS_COLUMN_1,
    properties: { colRequired: false, dataType: DataType.TEXT }
  },
  {
    key: LocationColumnHeaderKeys.ADDRESS_COLUMN_2,
    value: LocationColumnHeaderKeys.ADDRESS_COLUMN_2,
    properties: { colRequired: false, dataType: DataType.TEXT }
  },
  {
    key: LocationColumnHeaderKeys.CITY,
    value: LocationColumnHeaderKeys.CITY,
    properties: { colRequired: false, dataType: DataType.TEXT }
  },
  {
    key: LocationColumnHeaderKeys.STATE_PROVINCE,
    value: LocationColumnHeaderKeys.STATE_PROVINCE,
    properties: { colRequired: false, dataType: DataType.TEXT }
  },
  {
    key: LocationColumnHeaderKeys.LATITUDE,
    value: LocationColumnHeaderKeys.LATITUDE,
    properties: { colRequired: false, dataType: DataType.NUMBER }
  },
  {
    key: LocationColumnHeaderKeys.LONGITUDE,
    value: LocationColumnHeaderKeys.LONGITUDE,
    properties: { colRequired: false, dataType: DataType.NUMBER }
  }
];

interface LocationGroupFormProps {
  locationGroups: LocationGroup[];
  onUploadFinish: (locationGroupName: string) => void;
}

interface LocationGroupFormValues {
  locationGroupName: string;
}

const LocationGroupForm: React.FC<LocationGroupFormProps> = ({
  locationGroups,
  onUploadFinish
}: LocationGroupFormProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const isNameInUse = (name: string) => locationGroups.some((group) => group.locationGroupName.trim() === name.trim());

  return (
    <div data-testid="location-group-form">
      <Formik<LocationGroupFormValues>
        initialValues={{
          locationGroupName: ''
        }}
        validateOnMount
        validate={(values) => {
          const errors: FormikErrors<LocationGroupFormValues> = {};
          if (!values.locationGroupName) {
            errors.locationGroupName = formatMessage('MISSING_REQUIRED_LOCATION_GROUP_NAME');
          } else if (isNameInUse(values.locationGroupName)) {
            errors.locationGroupName = formatMessage('LOCATION_GROUP_UNIQUENESS_ERROR');
          }
          return errors;
        }}
        onSubmit={() => {
          return null;
        }}
      >
        {({ values, isValid, submitForm }) => (
          <Form>
            <div className={b('inputWrapper')}>
              <div>
                <label className={b('label')}>{formatMessage('FILE_TYPE')}</label>
                <div>
                  <Field
                    name="fileType"
                    items={[]}
                    component={SelectMenu}
                    disabled={true}
                    placeHolderText={formatMessage('PIN_SET')}
                    shouldValidateOnTouch={false}
                    showErrors={false}
                  />
                </div>
              </div>

              <div>
                <Field
                  label={formatMessage('DATA_TABLE_NAME')}
                  name="locationGroupName"
                  type="text"
                  placeHolder={formatMessage('DATA_TABLE_PLACEHOLDER')}
                  component={FormTextInputGroup}
                  disabled={isUploading}
                  required
                  autoComplete="off"
                  className={b('smallInput')}
                />
              </div>
            </div>

            <DataTypeTagList
              fields={LocationColumnHeaders}
              fileUploadType={formatMessage('LOCATION_FILE_TYPE')}
              showOptional
            />
            <CSVLink
              filename={DOWNLOAD_LOCATION_TEMPLATE_FILE_NAME}
              className={b('csvDownloadLink')}
              data={LOCATION_HEADERS}
            >
              <div data-testid="csv-download-wrapper" className={b('csvDownloadWrapper')}>
                <span className={b('csvDownloadIcon')}>
                  <Download data-testid="csv-download-icon" />
                </span>
                {formatMessage('DOWNLOAD_LOCATION_TEMPLATE_CSV')}
              </div>
            </CSVLink>
            <FileUploadSequence
              fileUploadType={FileType.LOCATION}
              onStart={() => setIsUploading(true)}
              onError={() => setIsUploading(false)}
              onComplete={() => {
                onUploadFinish(values.locationGroupName);
                submitForm();
              }}
              showImage={false}
              showFileHeaders={false}
              locationGroupName={values.locationGroupName}
              disabled={!isValid}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default LocationGroupForm;
