// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Nu449 {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  flex-direction: column;\n}\n\n  ._4IyNi {\n    display: flex;\n    height: 100%;\n    width: 100%;\n  }\n\n  ._5wAkl {\n    flex-grow: 3;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ActivitiesGrid": "Nu449",
	"ActivitiesGrid_territoryDetails": "_4IyNi",
	"ActivitiesGrid_territoryDetailsGrid": "_5wAkl"
};
export default ___CSS_LOADER_EXPORT___;
