import React, { useCallback, useEffect, useState } from 'react';

import SymonSpinner from 'app/components/IntegrationPanel/SymonSpinner';

import useOnSymonMessage from 'app/hooks/symon/useOnSymonMessage';
import useSymonPostMessage from 'app/hooks/symon/useSymonPostMessage';

import block from 'utils/bem-css-modules';

import style from './SymonPortal.module.pcss';
import { FromSymonMessage, symonIframeOriginsByApiOrigin, SymonIframePaths, SymonToken } from './SymonTypes';

export interface SymonPortalProps {
  pathname: SymonIframePaths;
  token: SymonToken;
  symonDomain: string;
}

const b = block(style);

const SymonPortal: React.FC<SymonPortalProps> = ({ pathname, token, symonDomain }) => {
  const symonUIDomain = symonIframeOriginsByApiOrigin[symonDomain];
  const iframeSrc = `${symonUIDomain}/${pathname}`;
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const sendMessageToSymon = useSymonPostMessage(iframeRef, symonUIDomain);

  useEffect(() => {
    // New pathname will refresh the iframe
    setIsIframeLoading(true);
  }, [pathname]);

  useEffect(() => {
    if (isIframeLoading) return;
    if (!token) return;

    console.debug('Sending token to Symon');
    sendMessageToSymon({
      type: 'sp_iframe_set_auth',
      data: JSON.stringify(token)
    });
  }, [isIframeLoading, token]);

  const handleSymonMessage = useCallback(
    (message: FromSymonMessage) => {
      if (message.type === 'symon_iframe_loaded') {
        setIsIframeLoading(false);
      } else if (message.type === 'symon_iframe_refresh') {
        // Refresh the iframe on behalf of symon
        iframeRef.current?.contentWindow.location.replace(iframeSrc);
        setIsIframeLoading(true);
      }
    },
    [iframeSrc]
  );

  useOnSymonMessage(handleSymonMessage, symonUIDomain);

  return (
    <div data-testid="symon-portal" className={b()}>
      {isIframeLoading && (
        <div className={b('spinnerContainer')}>
          <SymonSpinner />
        </div>
      )}
      <div data-testid="symon-iframe-container" className={b('iframeContainer')}>
        <iframe
          sandbox="allow-scripts allow-same-origin allow-downloads allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox"
          ref={iframeRef}
          referrerPolicy="strict-origin"
          src={iframeSrc}
          hidden={isIframeLoading}
          title={pathname}
          className={b('iframe')}
        />
      </div>
    </div>
  );
};

export default SymonPortal;
