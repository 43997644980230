// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JPpco {\n    box-shadow: none;\n  }\n    .JPpco .bp3-button:not([class*='bp3-intent-']) {\n      box-shadow: none;\n    }\n    .JPpco .bp3-button:not([class*='bp3-intent-']):hover, .JPpco .bp3-button:not([class*='bp3-intent-']):active {\n      background-color: rgb(var(--color-cobalt-5));\n      box-shadow: none;\n    }\n  ._1kaUH {\n    padding-left: var(--m);\n    min-width: 200px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuotaComponentDropdown_quotaComponentMenu": "JPpco",
	"QuotaComponentDropdown_headerMenu": "_1kaUH"
};
export default ___CSS_LOADER_EXPORT___;
