import React, { useState, useMemo, Dispatch, SetStateAction } from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext, TabIds } from 'app/models';

import { useBattleCard } from './battleCardProvider';
export interface DataTrayContextValues extends BaseContext {
  trayHeight: number;
  setTrayHeight: Dispatch<SetStateAction<number | null>>;
  territoryRuleId: number;
  setTerritoryRuleId: Dispatch<SetStateAction<number | null>>;
  territoryGroupId: string;
  setTerritoryGroupId: Dispatch<SetStateAction<string | null>>;
  trayOpen: boolean;
  setTrayOpen: Dispatch<SetStateAction<boolean>>;
  resetValues: () => void;
  userClosed: boolean;
  setUserClosed: Dispatch<SetStateAction<boolean | null>>;
  showChanges: boolean;
  setShowChanges: Dispatch<SetStateAction<boolean | null>>;
  showImpactedRowsOnly: boolean;
  setShowImpactedRowsOnly: Dispatch<SetStateAction<boolean | null>>;
  selectedDataTrayTab: string;
  setSelectedDataTrayTab: Dispatch<SetStateAction<string | null>>;
  staleTabsLookupMap: Record<string, TabIds[]>;
  updateStaleTabsLookupMap: (tab: TabIds) => void;
  resetStaleTabsLookupMap: () => void;
}

export const DataTrayContext = React.createContext<DataTrayContextValues | null>(null);
DataTrayContext.displayName = 'DataTrayContext';

export const DataTrayProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { selectedBattleCardId } = useBattleCard();

  const [trayHeight, setTrayHeight] = useState<number | null>(null);
  const [territoryRuleId, setTerritoryRuleId] = useState<number | null>(null);
  const [territoryGroupId, setTerritoryGroupId] = useState<string | null>(null);
  const [trayOpen, setTrayOpen] = useState(false);
  const [userClosed, setUserClosed] = useState(false);
  const [showChanges, setShowChanges] = React.useState(false);
  const [showImpactedRowsOnly, setShowImpactedRowsOnly] = React.useState(false);
  const [selectedDataTrayTab, setSelectedDataTrayTab] = useState<string | null>(null);
  const [staleTabsLookupMap, setStaleTabsLookupMap] = useState<Record<string, TabIds[]>>({});

  const resetValues = () => {
    setTrayHeight(null);
    setTerritoryRuleId(null);
    setTerritoryGroupId(null);
    setTrayOpen(false);
    setUserClosed(false);
    setShowChanges(false);
    setShowImpactedRowsOnly(false);
    setSelectedDataTrayTab(null);
    setStaleTabsLookupMap({});
  };

  const updateStaleTabsLookupMap = (tab: TabIds) => {
    setStaleTabsLookupMap({
      ...staleTabsLookupMap,
      [selectedBattleCardId]: staleTabsLookupMap[selectedBattleCardId]
        ? [...staleTabsLookupMap[selectedBattleCardId], tab]
        : [tab]
    });
  };

  const resetStaleTabsLookupMap = () => {
    setStaleTabsLookupMap({});
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      trayHeight,
      setTrayHeight,
      territoryRuleId,
      setTerritoryRuleId,
      territoryGroupId,
      setTerritoryGroupId,
      trayOpen,
      setTrayOpen,
      resetValues,
      userClosed,
      setUserClosed,
      showChanges,
      setShowChanges,
      showImpactedRowsOnly,
      setShowImpactedRowsOnly,
      selectedDataTrayTab,
      setSelectedDataTrayTab,
      staleTabsLookupMap,
      updateStaleTabsLookupMap,
      resetStaleTabsLookupMap
    }),
    [
      trayHeight,
      territoryRuleId,
      territoryGroupId,
      trayOpen,
      userClosed,
      showChanges,
      showImpactedRowsOnly,
      selectedDataTrayTab,
      staleTabsLookupMap
    ]
  );

  // Return the interface that we want to expose to our other components
  return <DataTrayContext.Provider value={values}>{children}</DataTrayContext.Provider>;
};

// Custom hook to read these values from
export const useDataTray = (): DataTrayContextValues => useContextSafe(DataTrayContext);
