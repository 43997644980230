import React, { useState } from 'react';

import { Menu } from '@blueprintjs/core';
import { ChevronDown, LocationStar, MapBoundary, Scales } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';

import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { OptimizationScenario } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryOptimizationPreviewMenu.module.pcss';
const b = block(style);

interface TextButtonProps {
  text: string;
  icon: JSX.Element;
}
const TerritoryOptimizationPreviewMenu: React.FC = () => {
  const { selectedOptimizationScenario, setSelectedOptimizationScenario } = useTerritoryOptimization();

  const [textButtonProps, setTextButtonProps] = useState<TextButtonProps>(() => {
    if (selectedOptimizationScenario === OptimizationScenario.MOST_BALANCED) {
      return {
        text: formatMessage('MOST_BALANCED'),
        icon: <Scales />
      };
    } else if (selectedOptimizationScenario === OptimizationScenario.MOST_CONTIGUOUS) {
      return {
        text: formatMessage('MOST_CONTIGUOUS'),
        icon: <MapBoundary />
      };
    }
    return {
      text: formatMessage('CURRENT_STATE'),
      icon: <LocationStar />
    };
  });

  const MenuContent = (
    <Menu data-testid="selected-scenario-menu">
      <div className={b('menuItemWrapper')}>
        <MenuItem
          icon={<LocationStar />}
          text={formatMessage('CURRENT_STATE')}
          onClick={() => {
            setSelectedOptimizationScenario(OptimizationScenario.CURRENT_STATE);
            setTextButtonProps({
              text: formatMessage('CURRENT_STATE'),
              icon: <LocationStar />
            });
          }}
          data-testid="current-state"
        />
      </div>
      <div className={b('menuItemWrapper')}>
        <MenuItem
          icon={<Scales />}
          text={formatMessage('MOST_BALANCED')}
          data-testid="most-balanced"
          onClick={() => {
            setSelectedOptimizationScenario(OptimizationScenario.MOST_BALANCED);
            setTextButtonProps({
              text: formatMessage('MOST_BALANCED'),
              icon: <Scales />
            });
          }}
        />
      </div>
      <div className={b('menuItemWrapper')}>
        <MenuItem
          icon={<MapBoundary />}
          text={formatMessage('MOST_CONTIGUOUS')}
          data-testid="most-contiguous"
          onClick={() => {
            setSelectedOptimizationScenario(OptimizationScenario.MOST_CONTIGUOUS);
            setTextButtonProps({
              text: formatMessage('MOST_CONTIGUOUS'),
              icon: <MapBoundary />
            });
          }}
        />
      </div>
    </Menu>
  );
  return (
    <div className={b('previewMenuButton')} data-testid="territory-optimization-preview-menu">
      <Popover content={MenuContent} placement={'bottom'}>
        <TextButton
          testId={'select-scenario-button'}
          type="button"
          minimal
          large={false}
          rightIcon={<ChevronDown />}
          {...textButtonProps}
        />
      </Popover>
    </div>
  );
};

export default TerritoryOptimizationPreviewMenu;
