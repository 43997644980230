import { useCallback, useEffect, useRef } from 'react';

import { TransformRequestFunction } from 'mapbox-gl';

import useAuth0TokenForMapbox from 'app/hooks/auth/useAuth0TokenForMapbox';
import { useTenantHeaders } from 'app/hooks/useTenantHeaders';

import { transformMapboxRequest } from 'utils/helpers/territoryMapUtils';

export const useTransformMapboxRequest = (): TransformRequestFunction | null => {
  const auth0Token = useAuth0TokenForMapbox();
  const tenantHeaders = useTenantHeaders();

  const unstableTrf: TransformRequestFunction = (url: string) => transformMapboxRequest(url, auth0Token, tenantHeaders);

  const unstableTrfRef = useRef(unstableTrf);
  useEffect(
    () => {
      unstableTrfRef.current = unstableTrf;
    } /* Intentially always changes to reflect the most up to date copy of the unstableTrf */
  );

  const stableTrf: TransformRequestFunction = useCallback(
    (...params) => unstableTrfRef.current(...params),
    [
      /* Never changes identity */
    ]
  );

  if (!auth0Token) return null;
  return stableTrf;
};
