import { useEffect } from 'react';

const useKeyDown = (key: string, action: () => void): void => {
  useEffect(() => {
    function onKeydown(e) {
      if (e.key === key) action();
    }
    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);
  }, [action]);
};

export default useKeyDown;
