import React, { FC, useState } from 'react';

import { Menu } from '@blueprintjs/core';
import { ChevronDown } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import { MenuItem } from 'components/menu/MenuItem';
import { KeyValue } from 'components/models';
import Popover from 'components/Popover/Popover';

import block from 'utils/bem-css-modules';

import style from './HeaderSelect.module.pcss';

const b = block(style);

interface HeaderSelectProps {
  items: KeyValue<string>[];
  onChange?: (value: KeyValue<string>[]) => void;
}

export const HeaderSelect: FC<HeaderSelectProps> = ({ items, onChange }) => {
  const [title, setTitle] = useState(items.length > 0 ? items[0].key : '');
  const [selected, setSelected] = useState(false);

  const onMenuItemClick = (item) => {
    setTitle(item.key);
    onChange(item);
  };

  const toggleButtonSelectedState = () => {
    setSelected((prevSelected) => !prevSelected);
  };

  return (
    <div className={b('headerSelectWrapper')} data-testid="header-select-wrapper">
      <div className={b('headerSelectTitleWrapper')}>
        <span className={b('headerSelectTitle')} data-testid="header-select-title">
          {title}
        </span>
      </div>
      <Popover
        onOpened={toggleButtonSelectedState}
        onClosed={toggleButtonSelectedState}
        content={
          <Menu>
            {items?.map((item) => (
              <MenuItem
                onClick={() => onMenuItemClick(item)}
                text={item.key}
                key={item.value}
                data-testid={`header-select-item${item.key}`}
              />
            ))}
          </Menu>
        }
        placement={'bottom-start'}
        usePortal={true}
        minimal={true}
      >
        <IconButton
          className={b('headerSelectButton')}
          icon={<ChevronDown size={24} />}
          type="button"
          testId="header-select-target"
          active={selected}
        />
      </Popover>
    </div>
  );
};
