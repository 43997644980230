import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Classes, Dialog, Intent } from '@blueprintjs/core';
import { Close } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ConfirmActionModal.module.pcss';

const b = block(style);

export interface ConfirmActionModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  isSubmitting?: boolean;
  body: string | JSX.Element;
  title: string;
  confirmButtonText: string;
  confirmButtonIcon?: JSX.Element;
  actionButtonIntent?: Intent;
  actionButtonClass?: string;
  onCancel: (e) => void;
  onConfirmAction: (e) => void;
  cancelButtonText?: string;
  minimal?: boolean;
}

const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
  body,
  title,
  isOpen,
  confirmButtonText,
  confirmButtonIcon,
  actionButtonIntent,
  onConfirmAction,
  onCancel,
  isLoading = false,
  isSubmitting = false,
  cancelButtonText = formatMessage('CANCEL'),
  actionButtonClass,
  minimal
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      portalClassName={b()}
      data-testid="confirm-action-modal"
    >
      <div className={b('modalHeader')}>
        <span data-testid="confirm-action-modal-title" className={b('title')}>
          {title}
        </span>
        <IconButton
          type="button"
          icon={<Close size={24} />}
          onClick={(e) => onCancel(e)}
          disabled={isSubmitting}
          testId={'close-button'}
          title={formatMessage('CLOSE')}
        />
      </div>
      <div className={b('modalBody')}>
        {isLoading ? (
          <div data-testid="loading-skeleton" className={`${Classes.SKELETON} ${b('loadingPlaceholder')}`} />
        ) : (
          <div data-testid="confirm-action-modal-body">{body}</div>
        )}
      </div>
      <div className={b('modalFooter')}>
        <TextButton
          testId={'confirm-action-modal-cancel-btn'}
          text={cancelButtonText}
          type="button"
          disabled={isSubmitting}
          onClick={(e) => onCancel(e)}
          large={false}
          minimal
        />
        <TextButton
          className={b(actionButtonClass ? `${actionButtonClass}` : null)}
          testId={'confirm-action-modal-action-btn'}
          text={confirmButtonText}
          minimal={minimal}
          type="submit"
          intent={actionButtonIntent}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={(e) => onConfirmAction(e)}
          icon={confirmButtonIcon}
          large={false}
        />
      </div>
    </Dialog>
  );
};

export default ConfirmActionModal;
