import React, { ReactNode, useMemo } from 'react';

import { Spinner } from '@blueprintjs/core';
import { SplitTreatments } from '@splitsoftware/splitio-react';

import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';

export interface SplitProps {
  name: SplitFeatures;
  additionalAttributes?: Record<string, unknown>;
  splitTreatment: Record<string, ReactNode>;
  fallback: ReactNode;
}

const Split: React.FC<SplitProps> = ({ name, additionalAttributes, splitTreatment, fallback }: SplitProps) => {
  const { selectedTenant } = useScope();
  const { userProfile } = useUser();

  const attributes = useMemo(
    () => ({
      tenantId: selectedTenant.id,
      tenantSlug: selectedTenant.slug,
      userEmail: userProfile.emailAddress,
      ...additionalAttributes
    }),
    [selectedTenant.id, userProfile.emailAddress, additionalAttributes]
  );

  return (
    <SplitTreatments names={[name]} attributes={attributes}>
      {({ treatments, isReady }) => {
        return isReady ? (
          <>{splitTreatment?.[treatments?.[name]?.treatment] ?? fallback}</>
        ) : (
          <div data-testid="spinner">
            <Spinner />
          </div>
        );
      }}
    </SplitTreatments>
  );
};

export default Split;
