// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrpHx {\n  display: flex;\n  padding: 4px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n}\n\n  .OrpHx .bp3-button {\n    padding: 0;\n  }\n\n  .HoPch {\n    padding: 0 4px;\n  }\n\n  .hha1W {\n    padding: 0 4px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LineRulerDistanceIndicator": "OrpHx",
	"LineRulerDistanceIndicator_distance": "HoPch",
	"LineRulerDistanceIndicator_unitControl": "hha1W"
};
export default ___CSS_LOADER_EXPORT___;
