import React from 'react';

import { Search, CollapseAll, ExpandAll } from '@carbon/icons-react';
import { DividerV2 } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { CollectionFilter, MchQuantity, NamedHierarchy, PrimaryTerritoryField } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import MapGridFilter from './MapGridFilter';
import style from './MapGridHeader.module.pcss';

const b = block(style);

interface MapGridHeaderProps {
  onSearchIconClick: () => void;
  customHierarchies: NamedHierarchy[];
  customHierarchyFilter: CollectionFilter<number>;
  onCollapseRows?: () => void;
  onExpandRows?: () => void;
  showCollapseAndExpandButtons?: boolean;
  primaryTerritoryField?: PrimaryTerritoryField;
  onPrimaryTerritoryFieldChange?: (field: PrimaryTerritoryField) => void;
}

const MapGridHeader: React.FC<MapGridHeaderProps> = ({
  onSearchIconClick,
  customHierarchies,
  customHierarchyFilter,
  onCollapseRows,
  onExpandRows,
  primaryTerritoryField,
  onPrimaryTerritoryFieldChange,
  showCollapseAndExpandButtons = false
}) => {
  const { chosenCustomHierarchy } = useDedicatedMapProvider();

  const isCustomHierarchiesEnabled = chosenCustomHierarchy.quantity !== MchQuantity.NONE;

  const expandAndCollapseButtons = () => {
    if (!showCollapseAndExpandButtons) return null;

    return (
      <>
        <DividerV2 vertical className={b('divider')} />
        <IconButton
          type="button"
          icon={<CollapseAll />}
          testId="collapse-all-button"
          minimal
          onClick={onCollapseRows}
          tooltipText={formatMessage('COLLAPSE_ALL')}
        />
        <IconButton
          type="button"
          icon={<ExpandAll />}
          testId="expand-all-button"
          minimal
          onClick={onExpandRows}
          tooltipText={formatMessage('EXPAND_ALL')}
        />
      </>
    );
  };

  return (
    <div className={b('visualizationContainer')}>
      <div className={b('iconsContainer')}>
        <IconButton type="button" icon={<Search />} testId="search-icon-button" minimal onClick={onSearchIconClick} />
        {expandAndCollapseButtons()}
      </div>
      <MapGridFilter
        customHierarchyFilter={customHierarchyFilter}
        customHierarchies={customHierarchies}
        shouldShowCustomHierarchyFilter={isCustomHierarchiesEnabled}
        primaryTerritoryField={primaryTerritoryField}
        onPrimaryTerritoryFieldChange={onPrimaryTerritoryFieldChange}
      />
    </div>
  );
};

export default MapGridHeader;
