import { useCallback, useMemo } from 'react';

import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import {
  LassoPolygon,
  MapSelectionRestriction,
  MapSelectionTarget,
  MapSelectionTool,
  StructuredCloneableFeature
} from 'app/models';

import { MapCapability } from 'utils/maps/mapCapabilityRegistry';

import { useIsMapCapable } from './useMapCapability';

export interface MapSelectionActions {
  clickPolygon: (polygonId: string) => void;
  clearSelection: () => void;
  lassoPolygons: (lassoPolygon: LassoPolygon, polygonsInBounds: StructuredCloneableFeature[]) => void;
  clickPin: (account: number) => void;
  lassoPins: (accounts: Array<number>) => void;
}

export const useMapSelectionActions = (
  target: MapSelectionTarget,
  tool: MapSelectionTool,
  lassoRestriction: MapSelectionRestriction
): MapSelectionActions => {
  const canViewUnassigned = useIsMapCapable(MapCapability.VIEW_UNASSIGNED);
  const postMessage = useMapWorkerPostMessage();
  const selectionActions = useMemo(() => {
    const restrictionOverride = canViewUnassigned ? null : MapSelectionRestriction.assigned;

    return {
      clickPolygon: (polygonId: string) =>
        postMessage({
          type: 'click-polygon',
          selectionOptions: { target, tool, restriction: restrictionOverride ?? MapSelectionRestriction.all },
          polygonId
        }),
      lassoPolygons: (lassoPolygon: LassoPolygon, polygonsInBounds: StructuredCloneableFeature[]) =>
        postMessage({
          type: 'lasso-polygons',
          selectionOptions: { target, tool, restriction: restrictionOverride ?? lassoRestriction },
          lassoPolygon,
          polygonsInBounds
        }),
      clickPin: (accountId: number) =>
        postMessage({
          type: 'click-pin',
          accountId,
          selectionOptions: { target, tool, restriction: restrictionOverride ?? MapSelectionRestriction.all }
        }),
      lassoPins: (accountIds: Array<number>) =>
        postMessage({
          type: 'lasso-pins',
          accountIds,
          selectionOptions: { target, tool, restriction: restrictionOverride ?? lassoRestriction }
        })
    };
  }, [postMessage, target, tool, lassoRestriction, canViewUnassigned]);

  const clearSelection = useCallback(() => postMessage({ type: 'clear-selection' }), [postMessage]);

  return { ...selectionActions, clearSelection };
};
