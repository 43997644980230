// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".U0g1n {\n  position: relative;\n  z-index: 1;\n  padding: var(--s) var(--m);\n}\n\n  .FS1ih {\n    width: 200px;\n    display: block;\n  }\n\n  .vl8HH {\n    font-size: 12px;\n    line-height: var(--m);\n    margin-bottom: 0;\n  }\n\n  .Ysoir {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n  }\n\n  .Z8UlP {\n    display: flex;\n    align-items: center;\n  }\n\n  .srhte {\n    border: 1px solid var(--light-purple-2-translucent);\n    border-radius: 100px;\n    padding: var(--xs) var(--s);\n    font-size: 12px;\n    color: var(--light-purple-2);\n    margin-right: var(--m);\n  }\n\n  .MNx-7 {\n    border-left: 1px solid #dddfee;\n    height: 100%;\n    align-items: center;\n    display: flex;\n    padding-left: var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EmbeddedMapHeader": "U0g1n",
	"EmbeddedMapHeader_battlecardLoadingIndicator": "FS1ih",
	"EmbeddedMapHeader_planningCycleTitle": "vl8HH",
	"EmbeddedMapHeader_headerControls": "Ysoir",
	"EmbeddedMapHeader_rightControls": "Z8UlP",
	"EmbeddedMapHeader_currencyLabel": "srhte",
	"EmbeddedMapHeader_salesPlanningLink": "MNx-7"
};
export default ___CSS_LOADER_EXPORT___;
