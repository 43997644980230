import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Recording, CheckmarkOutline, RadioButtonChecked, Undefined, MisuseOutline } from '@carbon/icons-react';

import { WorkflowStatus } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './StatusCellRenderer.module.pcss';

const b = block(style);

interface StatusCellRendererProps extends ICellRendererParams {
  isRootBC: boolean;
  value: string;
}

const StatusCellRenderer: React.FC<StatusCellRendererProps> = ({ value, isRootBC = false }) => {
  // This is to hide root bc status since when rootBC status is submitted,
  // the quota workflow is at 100 % completion rate
  if (isRootBC && value === WorkflowStatus.SUBMITTED) return <div data-testid="status-cell-renderer" />;
  if (value === WorkflowStatus.IN_PROGRESS) {
    return (
      <div className={b()} data-testid="status-cell-renderer">
        <Recording size={20} className={`${b('statusIcon')} ${b('statusIcon', { inProgress: true })}`} />
        <div>{formatMessage('IN_PROGRESS')}</div>
      </div>
    );
  } else if (value === WorkflowStatus.APPROVED) {
    return (
      <div className={b()} data-testid="status-cell-renderer">
        <CheckmarkOutline size={20} className={`${b('statusIcon')} ${b('statusIcon', { approved: true })}`} />
        <div>{formatMessage('APPROVED')}</div>
      </div>
    );
  } else if (value === WorkflowStatus.SUBMITTED) {
    return (
      <div className={b()} data-testid="status-cell-renderer">
        <RadioButtonChecked size={20} className={`${b('statusIcon')} ${b('statusIcon', { submitted: true })}`} />
        <div>{formatMessage('SUBMITTED')}</div>
      </div>
    );
  } else if (value === WorkflowStatus.REJECTED) {
    return (
      <div className={b()} data-testid="status-cell-renderer">
        <MisuseOutline size={20} className={`${b('statusIcon')} ${b('statusIcon', { rejected: true })}`} />
        <div>{formatMessage('REJECTED')}</div>
      </div>
    );
  } else {
    return (
      <div className={b()} data-testid="status-cell-renderer">
        <Undefined size={20} className={`${b('statusIcon')} ${b('statusIcon', { notStarted: true })}`} />
        <div>{formatMessage('NOT_STARTED')}</div>
      </div>
    );
  }
};

export default StatusCellRenderer;
