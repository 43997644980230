// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aJ94a {\n  z-index: 19;\n}\n\n  .Z0cs6 {\n    background: rgb(var(--color-midnight-blue-7));\n    border-radius: 12px;\n    bottom: 15px;\n    box-shadow: var(--elevation-1);\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n    position: fixed;\n    right: 15px;\n    top: calc(var(--header-height) + 15px);\n    width: 360px;\n    transition: all 0.3s ease;\n  }\n\n  .B9C18 {\n      width: calc(100vw - 30px);\n    }\n\n  .SHyKp {\n    align-items: center;\n    background: #fff;\n    height: 56px;\n    display: flex;\n    justify-items: center;\n    padding: 16px 12px;\n    width: 100%;\n  }\n\n  .IJrOI {\n    flex: 1;\n    font-size: 14px;\n    font-weight: bold;\n  }\n\n  .gz3KD {\n    display: flex;\n  }\n\n  .TbfkX {\n      transform: translateX(100%);\n    }\n\n  ._0ZL\\+5 {\n      opacity: 1;\n      transform: translateX(0);\n      transition: transform 200ms;\n    }\n\n  .actFK {\n      opacity: 1;\n    }\n\n  .mkyYS {\n      transform: translateX(100%);\n      transition: transform 200ms;\n    }\n\n  .mK79j {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n  }\n\n  .C-vhO {\n    flex: 1;\n    overflow-y: scroll;\n    padding: 16px;\n  }\n\n  .Q9SxU {\n    padding: 16px;\n  }\n\n  .uh4zY {\n    position: relative;\n  }\n\n  .hEaxW {\n    border: 1px solid rgb(var(--color-midnight-blue-4));\n    border-radius: 4px;\n    padding: 6px 12px 6px 6px;\n    max-height: 115px;\n    resize: none;\n    width: 100%\n  }\n\n  .hEaxW:focus {\n      border-color: var(--intent-primary);\n    }\n\n  .QcdBl {\n    bottom: 6px;\n    position: absolute;\n    right: 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Assistant": "aJ94a",
	"Assistant_wrapper": "Z0cs6",
	"Assistant_wrapper__maximized": "B9C18",
	"Assistant_header": "SHyKp",
	"Assistant_title": "IJrOI",
	"Assistant_titleActions": "gz3KD",
	"Assistant_wrapperAnimation__enter": "TbfkX",
	"Assistant_wrapperAnimation__enterActive": "_0ZL+5",
	"Assistant_wrapperAnimation__exit": "actFK",
	"Assistant_wrapperAnimation__exitActive": "mkyYS",
	"Assistant_emptyBodyContainer": "mK79j",
	"Assistant_body": "C-vhO",
	"Assistant_footer": "Q9SxU",
	"Assistant_sendChat": "uh4zY",
	"Assistant_sendChatInput": "hEaxW",
	"Assistant_sendChatButton": "QcdBl"
};
export default ___CSS_LOADER_EXPORT___;
