// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YWKxN {\n    padding: 0 0 var(--m) var(--m);\n    display: flex;\n  }\n  .HnhgZ {\n    max-width: 200px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountQuotaTGTFilter_filterContainer": "YWKxN",
	"AccountQuotaTGTFilter_territoryGroupTypeFilter": "HnhgZ"
};
export default ___CSS_LOADER_EXPORT___;
