import React from 'react';

import EllipsisText from 'components/EllipsisText/EllipsisText';
import TooltipWithCopy from 'components/TooltipWithCopy/TooltipWithCopy';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './BattleCardReferenceCellRenderer.module.pcss';

const b = block(style);

interface BattleCardReferenceCellRendererProps {
  items: string[];
}

const BattleCardReferenceCellRenderer: React.FC<BattleCardReferenceCellRendererProps> = ({
  items
}: BattleCardReferenceCellRendererProps) => {
  if (!items || items.length === 0) {
    return <div data-testid="no-data" />;
  }

  return (
    <div className={b('')} data-testid="battle-card-reference-cell-renderer">
      <div className={b('items')}>
        <span className={b('firstItem')} data-testid="first-item">
          <EllipsisText text={items[0]} />
        </span>
        {items.length > 1 && (
          <TooltipWithCopy
            items={items.slice(1, items.length)}
            buttonText={formatMessage('PLUS', { count: items.length - 1 })}
            copySuccessMessage={formatMessage('BC_REFERENCES_COPIED_TO_CLIPBOARD')}
            data-testid="remaining-items-btn"
          />
        )}
      </div>
    </div>
  );
};
export default BattleCardReferenceCellRenderer;
