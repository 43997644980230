// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B8NHG {\n  height: 32px;\n  border-bottom: 1px solid rgb(var(--color-light-gray-4));\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n  .weBgZ {\n    cursor: pointer;\n  }\n\n  .weBgZ .bp3-button.bp3-minimal {\n      min-height: 0;\n    }\n\n  /* postcss-bem-linter: ignore */\n\n  .weBgZ > svg {\n      color: rgb(var(--color-dark-gray-3));\n    }\n\n  .weBgZ:hover > svg {\n      color: rgb(var(--color-gray-1));\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommandCenterHierarchyToolbar": "B8NHG",
	"CommandCenterHierarchyToolbar_iconButton": "weBgZ"
};
export default ___CSS_LOADER_EXPORT___;
