import { ColDef } from '@ag-grid-community/core';

import AddTerritoriesButton from 'app/components/AdvancedGrid/CellRenderers/AddTerritoriesCellRenderer/AddTerritoriesCellRenderer';
import BalancingMetricCellRenderer from 'app/components/AdvancedGrid/CellRenderers/BalancingMetricCellRenderer/BalancingMetricCellRenderer';
import FooterCellRenderer from 'app/components/AdvancedGrid/CellRenderers/FooterCellRenderer/FooterCellRenderer';
import SelectMenuHeaderCellRenderer from 'app/components/AdvancedGrid/CellRenderers/SelectMenuHeaderCellRenderer/SelectMenuHeaderCellRenderer';

import { formatMessage } from 'utils/messages/utils';

const buildTerritoryBalancingGridColumnDef = (
  measureType: string,
  currency: string,
  toggleLeftDialog: () => void,
  toggleRightDialog: () => void
): ColDef[] => {
  return [
    {
      headerName: formatMessage('TERRITORY_ID'),
      field: 'territoryId',
      flex: 3,
      pinned: 'left',
      resizable: true
    },
    {
      headerName: formatMessage('TERRITORY_NAME'),
      field: 'territoryName',
      flex: 3,
      pinned: 'left',
      resizable: true
    },
    {
      headerName: formatMessage('BOOK_OF_BUSINESS'),
      field: 'measureValue',
      flex: 6,
      minWidth: 200,
      headerComponentFramework: SelectMenuHeaderCellRenderer,
      headerClass: 'ag-right-aligned-header',
      cellRendererSelector: (params) => {
        if (params?.node?.rowPinned) {
          return {
            frameworkComponent: FooterCellRenderer,
            params: {}
          };
        } else {
          return {
            frameworkComponent: BalancingMetricCellRenderer,
            params: { measureType, currency }
          };
        }
      },
      resizable: true
    },
    {
      headerName: formatMessage('NUMBER_OF_ACCOUNTS'),
      field: 'numberOfAccounts',
      flex: 6,
      minWidth: 200,
      headerClass: 'ag-right-aligned-header',
      cellRendererSelector: (params) => {
        if (params?.node?.rowPinned) {
          return {
            frameworkComponent: FooterCellRenderer,
            params: {}
          };
        } else {
          return {
            frameworkComponent: BalancingMetricCellRenderer,
            params: { showAccountsGap: true }
          };
        }
      },
      resizable: false
    },
    {
      headerName: '',
      field: formatMessage('ADD_TERRITORIES'),
      width: 50,
      cellRendererSelector: (params) => {
        if (!params?.node?.rowPinned) {
          return {
            frameworkComponent: AddTerritoriesButton,
            params: { toggleLeftDialog, toggleRightDialog }
          };
        } else {
          return {};
        }
      },
      resizable: false
    }
  ];
};

export default buildTerritoryBalancingGridColumnDef;
