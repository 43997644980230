import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

import { filterInheritedRulesAndRemoveEmptyHierarchies } from 'app/components/AdvancedGrid/GridHelpers/TerritoryGrid/territoryGridUtils';
import CompactRuleGroup from 'app/components/TerritoryRuleBuilder/CompactRuleGroup';

import block from 'utils/bem-css-modules';

import style from './RuleCellRenderer.module.pcss';

const b = block(style);

export const RuleCellRenderer: React.FC<ICellRendererParams> = ({ data }: ICellRendererParams) => {
  if (!data) {
    return null;
  }
  return (
    <div className={b()} data-testid="rule-cell-renderer">
      <CompactRuleGroup
        ruleDefinition={filterInheritedRulesAndRemoveEmptyHierarchies(data?.ruleDefinition)}
        inheritsFrom={data.inheritsFrom}
        // this information is needed for the <RuleEditorRow> child component, which allows the cell to change style based on this value
        isSelectedOrHovered={data.isSelectedOrHovered}
        data-testid="compact-rule-group"
      />
    </div>
  );
};

export default RuleCellRenderer;
