import { useState } from 'react';

import { API } from 'app/api/API';
import { APIError } from 'app/api/APIError';
import { Endpoint } from 'app/api/endpoints';
import { ApiHeaders, BuildHeaderParams } from 'app/api/util';

import { useAuthHeader } from 'app/contexts/AuthHeaderProvider';
import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

/**
 * Custom hook to prepare API request configurations.
 */
export const useApi = <Data, Payload extends Record<string, string>>(): {
  data: Data | null;
  isLoading: boolean;
  error: APIError | null;
  get: (endpoint: string, payload: Payload) => Promise<Data>;
  buildHeaders: () => Promise<ApiHeaders>;
  buildEndpoint: (type: Endpoint, suffix?: string) => string;
} => {
  const { getAuthHeader } = useAuthHeader();
  const { selectedTenant, selectedDeploymentModelId, selectedPlanningCycle } = useScope();
  const { selectedBattleCardId } = useBattleCard();

  const [data, setData] = useState<Data | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<APIError | null>(null);
  /**
   * Builds the API headers based on the current authentication header and other selected parameters.
   * @returns A promise that resolves to the API headers.
   */
  const buildHeaders = async (): Promise<ApiHeaders> => {
    const headerParams: BuildHeaderParams = {
      authToken: await getAuthHeader(),
      selectedTenant,
      selectedDeploymentModel: selectedDeploymentModelId || 0,
      selectedPlanningCycle: selectedPlanningCycle || { id: -1 },
      selectedBattlecard: selectedBattleCardId || ''
    };

    return API.buildHeaders(headerParams);
  };

  const buildEndpoint = (baseEndpoint: Endpoint, suffix?: string): string => {
    if (suffix) return `${baseEndpoint}/${suffix}`;
    return baseEndpoint;
  };

  /**
   * Makes a GET request to the specified endpoint with the given payload.
   * @param endpoint - The endpoint to send the request to.
   * @param payload - The payload to include in the request.
   * @returns A promise that resolves to the result of the GET request.
   */
  const get = async (endpoint: string, payload: Payload) => {
    const api = new API();
    setIsLoading(true);
    try {
      const result = await api.get<Data>(endpoint, payload, await buildHeaders());
      setData(result);
      return result;
    } catch (err) {
      setError(err as APIError);
      console.error(err);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return { buildHeaders, buildEndpoint, get, data, isLoading, error };
};
