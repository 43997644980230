import React from 'react';

import { FormGroup, InputGroup } from '@blueprintjs/core';
import { FieldProps } from 'formik';

import block from 'utils/bem-css-modules';

import style from './FormTextInputGroup.module.pcss';

const b = block(style);

interface FormTextInputGroupProps extends FieldProps {
  label?: string;
  type?: string;
  placeHolder?: string;
  disabled?: boolean;
  showErrors?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (value: string) => void;
  error?: string;
  isRequired?: boolean;
  shouldValidateOnTouch?: boolean;
  className?: string;
  icon?: JSX.Element;
  leftIcon?: JSX.Element;
  autoComplete?: 'off' | 'on';
  customError?: string | null;
}

/* Separation of concerns between Formik and Blueprint:
// Formik controls input value, form state and validations
// Blueprint controls styling, and its change handlers set Formik state via form.setFieldValue */
const FormTextInputGroup: React.FC<FormTextInputGroupProps> = ({
  field: { name, value },
  form: { touched, errors, status, setFieldValue, setFieldTouched },
  label,
  type,
  placeHolder = '',
  onChange,
  onBlur,
  disabled,
  icon,
  leftIcon,
  showErrors = true,
  isRequired = false,
  shouldValidateOnTouch = true,
  className = '',
  autoComplete = 'on',
  customError = null
}: FormTextInputGroupProps) => {
  const isError = !!(touched[name] && errors[name]);
  let displayError;

  // In order to handle setting errors programmatically, Formik suggests using "setStatus" instead of "setErrors" as it has been deprecated.
  // To support this, we will listen for the "status" value if it has been modified.
  // Regardless, an error or status will be saved into the "displayError" variable and then rendered.
  if (isError) {
    displayError = errors[name];
  } else if (status && Object.keys(status).length > 0) {
    displayError = status[name];
  }

  return (
    <div
      className={b('')}
      onBlur={() => {
        if (onBlur) {
          onBlur(value);
        }
        setFieldTouched(name, true, shouldValidateOnTouch);
      }}
    >
      <FormGroup label={isRequired ? `${label} *` : label} labelFor={name} className={`${b('')} ${className}`}>
        <InputGroup
          id={name}
          value={value ?? ''}
          type={type}
          placeholder={placeHolder}
          disabled={disabled}
          onChange={(event) => {
            setFieldValue(name, event.target.value);
            if (onChange) {
              onChange(event);
            }
          }}
          className={b({ danger: isError || !!customError, leftIcon: !!leftIcon })}
          data-testid={`form-text-input-group-${name}`}
          rightElement={icon}
          leftElement={leftIcon}
          autoComplete={autoComplete}
        />
      </FormGroup>
      {showErrors ? (
        <div className={b('validationMessage')} data-testid={`form-text-input-group-${name}-error-message`}>
          {(displayError || customError) ?? ''}
        </div>
      ) : null}
    </div>
  );
};

export default FormTextInputGroup;
