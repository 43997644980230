import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { UpsertTerritoryQuotaCommentInput } from 'app/graphql/generated/graphqlApolloTypes';
import { UPSERT_TERRITORY_QUOTA_COMMENT } from 'app/graphql/mutations/upsertTerritoryQuotaComment';

interface EditTerritoryQuotaCommentParams {
  quotaComponentId: number;
  territoryGroupId: number;
  territoryId: string;
  rootParent: string;
  comment: string;
  factorPath: string;
  ruleId: number;
}

export const editTerritoryQuotaComment = async (
  data: EditTerritoryQuotaCommentParams,
  newValue: string
): Promise<void> => {
  const queryVariables: UpsertTerritoryQuotaCommentInput = {
    quotaComponentId: data.quotaComponentId,
    territoryGroupId: data.territoryGroupId,
    territoryId: data.rootParent ? data.rootParent : data.territoryId,
    factorPath: data.factorPath && data.rootParent ? data.factorPath : '',
    comment: newValue,
    ruleId: data.ruleId
  };
  try {
    // eslint-disable-next-line no-restricted-syntax
    await apolloClient.mutate({
      mutation: UPSERT_TERRITORY_QUOTA_COMMENT,
      variables: {
        input: queryVariables
      }
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
