// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OSOA\\+ .bp3-input {\n    box-shadow: none;\n    border: solid 1px rgb(var(--color-midnight-blue-4));\n  }\n  .EKAxd .bp3-input {\n      border-color: rgb(var(--color-red-3));\n    }\n  .Vew21 .bp3-input {\n      padding-left: var(--xl) !important;\n    }\n  .OSOA\\+ .bp3-form-group {\n    margin-bottom: 0;\n  }\n  /* Aligning the right element icon vertically middle */\n  .OSOA\\+ .bp3-input-action {\n    display: flex;\n    height: 100%;\n    align-items: center;\n  }\n  .OSOA\\+ .bp3-input-left-container {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    margin-left: 12px;\n  }\n  .OSOA\\+ label.bp3-label {\n    font-weight: normal;\n    font-size: 12px;\n    margin-top: 5px;\n  }\n  .r1KdQ {\n    min-height: 20px;\n    color: rgb(var(--color-red-3));\n    margin-top: 6px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormTextInputGroup": "OSOA+",
	"FormTextInputGroup__danger": "EKAxd",
	"FormTextInputGroup__leftIcon": "Vew21",
	"FormTextInputGroup_validationMessage": "r1KdQ"
};
export default ___CSS_LOADER_EXPORT___;
