import gql from 'graphql-tag';

export const GENERATE_API_KEY = gql`
  mutation GenerateAPIKey($input: GenerateAPIKeyInput!) {
    generateAPIKey(input: $input) {
      apiKey
      status
      message
    }
  }
`;
