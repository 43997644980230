// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jyxGO {\n  display: flex;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n}\n\n  .UYLjh {\n    width: 200px;\n    padding: 10px;\n    margin-bottom: 20px;\n    border: 0;\n    border-bottom: 1px solid black;\n  }\n\n  .jyxGO .ag-header {\n    display: none;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HierarchySearch": "jyxGO",
	"HierarchySearch_searchInput": "UYLjh"
};
export default ___CSS_LOADER_EXPORT___;
