// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JKqDL {\n  height: 48px;\n  background-color: rgb(var(--color-light-gray-5));\n  box-shadow: inset 0 -1px var(--border);\n  display: flex;\n  align-items: center;\n}\n\n  .ISBaq {\n    display: flex;\n    justify-content: flex-start;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n    align-items: center;\n    padding: var(--s) var(--m);\n  }\n\n  .xj18h {\n    background: white;\n    padding: 2px 7px;\n    border-radius: 50%;\n    margin-left: var(--xs);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryQuotaDrillInGridHeader": "JKqDL",
	"TerritoryQuotaDrillInGridHeader_container": "ISBaq",
	"TerritoryQuotaDrillInGridHeader_filterCount": "xj18h"
};
export default ___CSS_LOADER_EXPORT___;
