import React from 'react';

// Mapbox requires we have a logo and a link in a corner of the map.
// We disable the default control for this in order to place it in our regular layout,
// but we still reference their class which includes their logo.
export const MapboxAttribution = () => (
  // (Aria-label supplied)
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    className="mapboxgl-ctrl-logo"
    target="_blank"
    rel="noopener nofollow noreferrer"
    href="https://www.mapbox.com/"
    aria-label="Mapbox logo"
  />
);
