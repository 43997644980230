import React, { ReactNode } from 'react';

import { Card as BlueprintCard } from '@blueprintjs/core';

export interface CardProps {
  children?: ReactNode;
  className?: string;
  interactive?: boolean;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({ children, className, interactive, onClick, ...rest }: CardProps) => {
  return (
    <BlueprintCard className={className} interactive={interactive} onClick={onClick} {...rest}>
      {children}
    </BlueprintCard>
  );
};

export default Card;
