import React from 'react';

import { Spinner } from '@blueprintjs/core';
import { CheckmarkOutline, Document } from '@carbon/icons-react';
import { FileWithPath } from 'react-dropzone';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './DataFileListItem.module.pcss';

const b = block(style);

type DataFileListItemProps = {
  files: FileWithPath[];
  isUploading: boolean;
  error: string;
  fileUploadSuccess: boolean;
};

export const DataFileListItem: React.FC<DataFileListItemProps> = ({ files, isUploading, error, fileUploadSuccess }) => (
  <ul className={b('listItems')} data-testid="data-file-list-item">
    {files?.map((file) => (
      <li key={file.path} className={b('listItem')} data-testid="dataFileListItem">
        <span className={b('nameDetails')}>
          <span className={b('fileIcon')}>
            <Document />
          </span>
          {file.path}
        </span>
        {fileUploadSuccess && !error && (
          <div className={b('statusDetails')} data-testid="data-file-list-item-success">
            <p className={b('statusDetailsText')}>{formatMessage('DONE')}</p>
            <CheckmarkOutline />
          </div>
        )}
        {isUploading && !fileUploadSuccess && (
          <div className={b('statusDetails')} data-testid="data-file-list-item-uploading">
            <p className={b('statusDetailsText')}>{formatMessage('UPLOADING')}</p>
            <Spinner intent="primary" size={24} />
          </div>
        )}
        {error && (
          <div className={b('statusDetails')} data-testid="data-file-list-item-error">
            <p className={b('statusDetailsTextError')}>{error}</p>
          </div>
        )}
      </li>
    ))}
  </ul>
);
