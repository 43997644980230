import React from 'react';

import { Play } from '@carbon/icons-react';
import { Intent } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { useCoinsortButton } from 'app/hooks/useCoinsortButton';
import useTreatment from 'app/hooks/useTreatment';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CoinsortButtons.module.pcss';

const b = block(style);

interface CoinsortButtonsProps {
  onClick?: () => void;
}

const CoinsortButton: React.FC<CoinsortButtonsProps> = ({ onClick }: CoinsortButtonsProps) => {
  const { selectedDeploymentModelId } = useScope();
  const { selectedBattleCardId, selectedQuotaComponentId, battleCardLookupMap } = useBattleCard();
  const {
    coinsortEnabled,
    isCoinsortLoading,
    isCoinsortRunning,
    isButtonLoading,
    onClickCancelCoinsort,
    onClickRunCoinsort
  } = useCoinsortButton(selectedBattleCardId, selectedQuotaComponentId, selectedDeploymentModelId);

  const [matchingEngineEnabled] = useTreatment(SplitFeatures.MATCHING_ENGINE);

  const handleClick = async () => {
    if (isCoinsortRunning) {
      await onClickCancelCoinsort();
    } else {
      await onClickRunCoinsort();
    }
    onClick?.();
  };

  const quotaComponentName = battleCardLookupMap[selectedBattleCardId]?.quotaComponents?.find(
    (qc) => qc.quotaComponentId === selectedQuotaComponentId
  )?.quotaComponentName;
  const battleCardName = battleCardLookupMap[selectedBattleCardId]?.battlecardName;

  return (
    <span className={b()}>
      <MessageTooltip
        disabled={!matchingEngineEnabled || !isCoinsortRunning}
        data-testid="coinsort-action-button-tooltip"
        content={formatMessage('RUN_COIN_SORT_DISABLED_MESSAGE', {
          quotaComponentName,
          battleCardName
        })}
        target={
          <TextButton
            testId={'coinsort-action-button'}
            type="button"
            text={
              matchingEngineEnabled || !isCoinsortRunning ? formatMessage('RUN_COIN_SORT') : formatMessage('CANCEL')
            }
            intent={Intent.PRIMARY}
            onClick={handleClick}
            icon={<Play />}
            isActiveWhileLoading
            loading={isButtonLoading}
            disabled={isCoinsortLoading || !coinsortEnabled || (isCoinsortRunning && matchingEngineEnabled)}
          />
        }
        placement={'top'}
      />
    </span>
  );
};

export default CoinsortButton;
