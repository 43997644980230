// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5D8sG .bp3-popover2-target {\n    width: 100%;\n  }\n  ._5D8sG {\n\n  display: flex;\n  align-items: center;\n  width: 100%;\n  font-weight: normal;\n  font-size: 14px;\n}\n  .jZHcO {\n    text-align: right;\n  }\n  .aHak2 {\n    text-align: left;\n  }\n\n:root .bp3-tooltip2 .bp3-popover2-content {\n  width: 100%;\n  text-align: left;\n  line-height: 1.29;\n  font-weight: 100;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MessageTooltipHeaderRenderer": "_5D8sG",
	"MessageTooltipHeaderRenderer_alignRight": "jZHcO",
	"MessageTooltipHeaderRenderer_alignLeft": "aHak2"
};
export default ___CSS_LOADER_EXPORT___;
