import React, { useState, useMemo, Dispatch, SetStateAction } from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext, AssistantDialogState } from 'app/models';

export interface AssistantContextValues extends BaseContext {
  assistantDialogState: AssistantDialogState;
  setAssistantDialogState: Dispatch<SetStateAction<AssistantDialogState>>;
  resetValues: () => void;
}

export const AssistantContext = React.createContext<AssistantContextValues | null>(null);
AssistantContext.displayName = 'AssistantContext';

export const AssistantProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [assistantDialogState, setAssistantDialogState] = useState<AssistantDialogState>(AssistantDialogState.CLOSE);

  const resetValues = () => {
    setAssistantDialogState(AssistantDialogState.CLOSE);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(() => {
    return {
      assistantDialogState,
      setAssistantDialogState,
      resetValues
    };
  }, [assistantDialogState]);

  // Return the interface that we want to expose to our other components
  return <AssistantContext.Provider value={values}>{children}</AssistantContext.Provider>;
};

// Custom hook to read these values from
export const useAssistant = (): AssistantContextValues => useContextSafe(AssistantContext);
