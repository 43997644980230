import React, { useMemo, useRef, useState } from 'react';

import { Copy } from '@carbon/icons-react';
import { Formik, Form, Field } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import { SearchableSelectMenuItem } from 'components/models';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import ScenarioNameField from 'app/components/PlanningCyclePageHeader/ScenarioNameField';

import { useScope } from 'app/contexts/scopeProvider';

import {
  DeploymentModelTypeEnum,
  GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries
} from 'app/graphql/generated/graphqlApolloTypes';
import { ScenarioSummary } from 'app/graphql/hooks/useScenarioSummaries';
import { useRequestDeploymentModelClone } from 'app/graphql/mutations/requestDeploymentModelClone';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

import CloneScenarioToast from './CloneScenarioToast';
import validationSchema from './validationSchema';

interface CopyScenarioDialogProps {
  onClose: () => void;
  planDms: Pick<
    GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries,
    'deploymentModelId' | 'deploymentModelName'
  >[];
  relevantScenario: Pick<ScenarioSummary, 'deploymentModelId' | 'deploymentModelName'>;
}

interface CopyScenarioValues {
  copiedScenario: SearchableSelectMenuItem;
  copyScenarioName: string;
}

const CopyScenarioDialog: React.FC<CopyScenarioDialogProps> = ({ onClose, planDms, relevantScenario }) => {
  const [isCopyLoading, setIsCopyLoading] = useState(false);
  const [scenarioSearchText, setScenarioSearchText] = useState('');

  const portalRef = useRef<HTMLDivElement>(null);

  const { selectedPlanningCycle } = useScope();
  const showToast = useShowToast();

  const [requestDeploymentModelClone, { loading }] = useRequestDeploymentModelClone({
    onCompleted() {
      setIsCopyLoading(true);
    },
    onError(e) {
      console.error(e);
      showToast(formatMessage('SCENARIO_COPY_FAILED'), 'danger');
    }
  });

  const initialValues: CopyScenarioValues = {
    copiedScenario: {
      key: relevantScenario.deploymentModelName,
      value: relevantScenario.deploymentModelId.toString()
    },
    copyScenarioName: formatMessage('COPY_SCENARIO_NAME', { name: relevantScenario.deploymentModelName })
  };

  const filteredScenarioItems: SearchableSelectMenuItem[] = useMemo(() => {
    return planDms
      ?.filter((item) => item.deploymentModelName.toLocaleLowerCase().includes(scenarioSearchText.toLocaleLowerCase()))
      .map((scenario) => ({
        key: scenario.deploymentModelName,
        value: scenario.deploymentModelId.toString()
      }));
  }, [planDms, scenarioSearchText]);

  const handleSubmit = ({ copiedScenario, copyScenarioName }: CopyScenarioValues) => {
    requestDeploymentModelClone({
      variables: {
        deploymentModelId: parseInt(copiedScenario.value),
        clonedDeploymentModelType: DeploymentModelTypeEnum.Plan,
        clonedDeploymentModelName: copyScenarioName
      }
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ handleSubmit, values, setFieldValue }) => (
        <>
          <Dialog
            title={formatMessage('COPY_SCENARIO')}
            isOpen
            onSubmit={handleSubmit}
            onClose={onClose}
            confirmButtonIcon={<Copy />}
            confirmButtonText={formatMessage('MAKE_A_COPY')}
            confirmButtonLoading={loading || isCopyLoading}
            size="small"
          >
            <Form>
              <div ref={portalRef}>
                <Field
                  name="copiedScenario"
                  data-testid="select-copied-scenario-menu"
                  items={filteredScenarioItems}
                  onSelectItem={({ selectedItem }: { selectedItem: SearchableSelectMenuItem }) => {
                    setFieldValue('copyScenarioName', formatMessage('COPY_SCENARIO_NAME', { name: selectedItem.key }));
                  }}
                  onSearch={(searchString) => setScenarioSearchText(searchString)}
                  onSearchReset={() => setScenarioSearchText('')}
                  component={SearchableSelectMenu}
                  theme="default"
                  initialLoadingComplete={true}
                  usePortal
                  portalRef={portalRef}
                  showIconInField={false}
                />
                <ScenarioNameField
                  currentLength={values.copyScenarioName.length}
                  planDms={planDms}
                  fieldName="copyScenarioName"
                />
              </div>
            </Form>
          </Dialog>
          {isCopyLoading && (
            <CloneScenarioToast
              planningCycleName={selectedPlanningCycle.planningCycleName}
              scenarioName={values.copyScenarioName}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default CopyScenarioDialog;
