import React from 'react';

import { ICellRendererParams, RowNode } from '@ag-grid-community/core';

import EllipsisText from 'components/EllipsisText/EllipsisText';

import block from 'utils/bem-css-modules';
import { formatNumber } from 'utils/messages/utils';

import style from './CurrencyCellRenderer.module.pcss';

const b = block(style);

interface CurrencyCellRendererProps extends ICellRendererParams {
  currency: string;
  measureType: string;
  onCellClick?: (data: RowNode) => void;
  alignLeft: boolean;
  isLink: boolean;
}

export const CurrencyCellRenderer: React.FC<CurrencyCellRendererProps> = ({
  data,
  colDef,
  currency,
  onCellClick,
  alignLeft,
  isLink
}) => {
  const key = colDef.field;

  const isClickable = !!onCellClick;

  const handleClick = (event) => {
    if (isClickable) {
      event.preventDefault();
      event.stopPropagation();
      onCellClick(data);
    }
  };

  const value = data[key]?.value !== '' ? data[key]?.value ?? data[key] : 0;

  return (
    <div
      className={b('cellWrapper', { alignRight: !alignLeft })}
      data-testid="currency-cell-renderer"
      onClick={handleClick}
    >
      <EllipsisText className={b({ isLink })} text={formatNumber(value, { style: 'currency', currency })} />
    </div>
  );
};

export default CurrencyCellRenderer;
