import React from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

import {
  BattleCardInfo,
  ExpandedTerritoryGroupDefineAndRefinePillData,
  MembershipSpec,
  UserRoleType
} from 'app/models';

export interface PermissionsContextRedistributorContextValues {
  selectedTenantId: number;
  selectedBattleCardId: string | null;
  battleCardLookupMap: Record<string, BattleCardInfo>;
  selectedTerritoryGroupId: string | null;
  territoryGroups: ExpandedTerritoryGroupDefineAndRefinePillData[];
  userRole: UserRoleType | null;
  userMembershipSpecs: MembershipSpec[];
}

export const PermissionsContextRedistributorContext =
  React.createContext<PermissionsContextRedistributorContextValues | null>(null);
PermissionsContextRedistributorContext.displayName = 'PermissionsContextRedistributor';

export const PermissionsContextRedistributorProvider = PermissionsContextRedistributorContext.Provider;

// Custom hook to read these values from
export const usePermissionsContextRedistributor = (): PermissionsContextRedistributorContextValues =>
  useContextSafe(PermissionsContextRedistributorContext);
