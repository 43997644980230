import React from 'react';

import { Spinner } from '@blueprintjs/core';

import block from 'utils/bem-css-modules';

import style from './CommentThreadLoading.module.pcss';

const b = block(style);

const CommentThreadLoading: React.FC = () => {
  return (
    <div className={b()} data-testid="comment-thread-loading">
      <div data-testid="spinner">
        <Spinner intent="primary" size={50} />
      </div>
    </div>
  );
};

export default CommentThreadLoading;
