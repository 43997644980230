import React, { FC } from 'react';

import { useField } from 'formik';

export const NullableFieldErrorMessage: FC<{ name: string; subName: string }> = (props) => {
  const [, parentMeta] = useField(props.name);
  const [, childMeta] = useField(`${props.name}.${props.subName}`);
  if (!childMeta.touched && !parentMeta.touched) return null;
  if (childMeta.error && typeof childMeta.error === 'string') return <>{childMeta.error}</>;
  if (parentMeta.error && typeof parentMeta.error === 'string') return <>{parentMeta.error}</>;
  return null;
};
