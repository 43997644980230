// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable plugin/selector-bem-pattern */\n.ep-UV {\n  display: flex;\n  padding-top: 8px;\n}\n._3anTv {\n    padding-left: 12px;\n    flex-direction: column;\n  }\n.sn1h6 {\n    display: flex;\n    flex-direction: column\n  }\n.sn1h6:first-child {\n      margin-bottom: 8px;\n    }\n.QbhP9 {\n    font-weight: 300;\n  }\n.U-gnj {\n    font-weight: 600;\n    padding-top: 6px;\n  }\n.Nnmqf {\n    position: absolute;\n    right: 10px;\n    bottom: 10px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TargetComparison": "ep-UV",
	"TargetComparison_values": "_3anTv",
	"TargetComparison_valueContainer": "sn1h6",
	"TargetComparison_valueLabel": "QbhP9",
	"TargetComparison_value": "U-gnj",
	"TargetComparison_allocationTooltip": "Nnmqf"
};
export default ___CSS_LOADER_EXPORT___;
