import gql from 'graphql-tag';

export const GET_SHEET_MEASURES = gql`
  query GetSheetMeasures($deploymentModelId: Int!, $quotaComponentId: Int, $battlecardId: Int) {
    getDeploymentModelSpec(
      input: { deploymentModelId: $deploymentModelId, quotaComponentId: $quotaComponentId, battlecardId: $battlecardId }
    ) {
      sheetMeasures {
        measureCategory
        measureConstraints
        measureFieldType
        measureFormatType
        measureFormula
        measureId
        measureName
        measureSource
        sheetDefinitionType
        sheetId
      }
    }
  }
`;
