// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kPITc:last-child {\n    border-width: 1px;\n  }\n  .qQR50 {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n  .I-RiL {\n    width: 70%;\n    max-width: 1200px;\n    height: 100%;\n    overflow: auto;\n    border-right: 1px solid rgb(var(--color-light-gray-3));\n  }\n  .L\\+MMi {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding: var(--data-drill-in-grid-header-padding);\n    border-bottom: 1px solid rgb(var(--color-light-gray-3));\n  }\n  .VZhOp {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n  .IyhfB {\n    margin: var(--data-panel-small-margin) var(--data-panel-margin) 0 0; /* nudge top to align with text */\n  }\n  .wVNcu {\n    font-size: var(--data-drill-in-subtitle-font-size);\n    color: rgb(var(--color-dark-gray-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SheetGridHeader_gridSection": "kPITc",
	"SheetGridHeader_fullWidthGrid": "qQR50",
	"SheetGridHeader_condensedWidthGrid": "I-RiL",
	"SheetGridHeader_gridHeaderContainer": "L+MMi",
	"SheetGridHeader_gridHeader": "VZhOp",
	"SheetGridHeader_leftIcon": "IyhfB",
	"SheetGridHeader_subtitle": "wVNcu"
};
export default ___CSS_LOADER_EXPORT___;
