// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KPg\\+\\+ {\n    font-size: 12px;\n    padding-right: 12px;\n    word-break: break-word;\n  }\n\n    .PfzrW {\n      display: flex;\n      align-items: center;\n    }\n  ._3BPB0 {\n    background: white;\n    color: rgb(var(--color-cobalt-3));\n    border: 2px rgb(var(--color-cobalt-3)) solid;\n    padding-left: 12px;\n    padding-right: 12px;\n    border-radius: 12px;\n    font-weight: 600;\n    margin-left: 12px;\n  }\n  ._9ik6L {\n      background: rgb(var(--color-cobalt-3));\n      color: white;\n    }\n  .JTOL7 {\n    display: inline-flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 4px 8px;\n    height: 24px;\n    max-width: auto;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n    margin-top: 12px;\n    margin-left: 12px;\n    border-radius: 4px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuotaComponentDescription_quotaComponentDescription": "KPg++",
	"QuotaComponentDescription_quotaComponentDescription__root": "PfzrW",
	"QuotaComponentDescription_quotaComponentBubble": "_3BPB0",
	"QuotaComponentDescription_quotaComponentBubble__selected": "_9ik6L",
	"QuotaComponentDescription_quotaComponentTitle": "JTOL7"
};
export default ___CSS_LOADER_EXPORT___;
