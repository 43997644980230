import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { FileWithPath } from 'react-dropzone';

import { useData } from 'app/contexts/dataProvider';

import { DataFileDragAndDrop } from 'app/core/dataFileUpload/DataFileDragAndDrop';
import { DataFileListItem } from 'app/core/dataFileUpload/DataFileListItem';

import { FileTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';
import { useFileUpload } from 'app/graphql/hooks/useFileUpload';

import { FileType, MappingFields } from 'app/models';

import { createMappingProperties, validateFileHeader } from 'utils/helpers/fileUploadUtils';
import { formatMessage } from 'utils/messages/utils';

export type DataFileUploadSequenceProps = {
  fileUploadType: FileType | FileTypeEnum;
  handleShouldRefetch?: (value: boolean) => void;
  setDisableCancel?: Dispatch<SetStateAction<boolean>>;
  setDisableComplete?: Dispatch<SetStateAction<boolean>>;
  hierarchyId?: number;
  headersToValidate?: string[];
  setDataMatchingRequired?: Dispatch<SetStateAction<boolean>>;
  mappingFields?: MappingFields[];
};

const DataFileUploadSequence: React.FC<DataFileUploadSequenceProps> = ({
  fileUploadType,
  handleShouldRefetch,
  setDisableCancel,
  setDisableComplete,
  headersToValidate = [],
  setDataMatchingRequired,
  mappingFields
}) => {
  const [dataFile, setDataFile] = useState<FileWithPath[]>([]);
  const { setIsAllColumnsMapped, setMappingProperties, setSelectedTable } = useData();

  const { startUpload, isUploading, isCompleted, error } = useFileUpload(fileUploadType);

  useEffect(() => {
    if (Array.isArray(dataFile) && dataFile.length > 0) {
      const name = dataFile[0].name;
      startUpload(name, dataFile[0] as File)
        .then(setSelectedTable)
        .catch((err) => console.error(err));
    }
  }, [dataFile]);

  useEffect(() => {
    if (error) {
      handleResetState();
    }
  }, [error]);

  useEffect(() => {
    if (isUploading) {
      setDisableCancel?.(true);
      setDisableComplete?.(true);
    }
    if (isCompleted) {
      setDisableCancel?.(false);
      setDisableComplete?.(false);
      handleResetState();
    }
  }, [isCompleted, isUploading]);

  const handleResetState = () => {
    setDisableComplete?.(false);
    handleShouldRefetch?.(true);
  };

  const [errorMessage, setErrorMessage] = useState<string>('');

  const onFileDragAndDrop = useCallback(async (acceptedFiles) => {
    setErrorMessage(null);
    if (acceptedFiles.length > 0) {
      const result = await validateFileHeader(acceptedFiles[0], headersToValidate);

      // parsing error
      if (result.errors.length > 0) return setErrorMessage(result.errors[0].message);

      // validation error - header contains invalid char or first row start with #
      if (result.validationError) return setErrorMessage(result.validationError);

      // not enough number of columns compared with required columns
      if (!result.hasEnoughColumns)
        return setErrorMessage(formatMessage('YOUR_FILE_IS_MISSING', { count: result.missingColumnTotal }));

      // has exactly same number of columns as required columns and all headers match required headers
      if (result.hasEnoughColumns && result.missingColumnTotal === 0 && result.missingHeaders.length === 0) {
        // uploads file to S3 then file will publish to DB automatically
        setDataFile(acceptedFiles);

        // functions to allow publish to DB without data matching part
        setIsAllColumnsMapped(true);

        // automatically set mapping properties when all column header are exact match
        if (mappingFields) setMappingProperties(createMappingProperties(result.headerDataOrder, mappingFields));
      } else {
        // has same or more columns than required columns, but not all headers match - need manual mapping
        // uploads file to S3 but user will have to match before publishing
        setDataFile(acceptedFiles);
        setDataMatchingRequired?.(true);
      }
    }
    return null;
  }, []);

  return (
    <div data-testid="data-file-upload-sequence">
      {/* Improve UI by hiding this component <DataFileTypeSelect fileUploadType={fileUploadType} /> */}
      {(isUploading || isCompleted) && (
        <DataFileListItem files={dataFile} isUploading={isUploading} error={error} fileUploadSuccess={isCompleted} />
      )}
      {(dataFile.length === 0 || error) && (
        <DataFileDragAndDrop onDrop={onFileDragAndDrop} errorMessage={errorMessage} />
      )}
    </div>
  );
};

export default DataFileUploadSequence;
