import React from 'react';

import { ArrowLeft, Renew } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useData } from 'app/contexts/dataProvider';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SheetDetailHeader.module.pcss';

const b = block(style);

interface SheetDetailHeaderProps {
  onBackClick?: () => void;
}

const SheetDetailHeader: React.FC<SheetDetailHeaderProps> = ({ onBackClick }: SheetDetailHeaderProps) => {
  const { selectedSheet } = useData();
  return (
    <div className={b('')} data-testid="sheet-detail-container">
      <div className={b('leftActionItems')}>
        <span className={b('backIcon')} data-testid="back-icon" onClick={onBackClick}>
          <IconButton
            type={'button'}
            icon={<ArrowLeft />}
            onClick={onBackClick}
            testId={'sheets-back-button'}
            title={formatMessage('BACK')}
          />
        </span>
        <HTMLHeading tagLevel={'h5'} text={selectedSheet.sheetType} data-testid="sheets-title" />
      </div>
      <div className={b('rightActionItems')}>
        <div className={b('hiddenItem')}>
          <span className={b('rightActionText')}>
            {formatMessage('LAST_MODIFIED', { value: formatMessage('SYSTEM_TIMESTAMP') })}
          </span>
          <IconButton type={'button'} icon={<Renew />} testId={'renew-button'} title={formatMessage('RENEW')} />
        </div>
      </div>
    </div>
  );
};

export default SheetDetailHeader;
