import React from 'react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';

import style from './TabButtonList.module.pcss';

const b = block(style);

interface TabButton {
  id: number;
  text: string;
  viewed: boolean | undefined;
}

interface TabButtonListProps {
  buttonList: TabButton[];
  selectedTab: number;
  onSwitchTab: (id: number) => void;
}

export const TabButtonList: React.FC<TabButtonListProps> = ({ buttonList, onSwitchTab, selectedTab }) => {
  const renderUnviewedIndicator = () => (
    <span data-testid="tab-button-unviewed-indicator" className={b('unviewedIndicator')} />
  );

  const renderText = (button: TabButton) => (
    <div data-testid="tab-button-text">
      {button.text}
      {!button.viewed && button.viewed !== undefined && renderUnviewedIndicator()}
    </div>
  );

  return (
    <div data-testid="tab-button-list" className={b('tabButtonList')}>
      {buttonList.map((button) => (
        <div
          className={b('tabButtonWrapper', {
            selected: button.id === selectedTab,
            notSelected: button.id !== selectedTab
          })}
          key={`${button.id}`}
        >
          <TextButton
            testId={`tab-button-${button.id}`}
            text={renderText(button)}
            minimal={true}
            onClick={() => onSwitchTab(button.id)}
            type="button"
          />
        </div>
      ))}
    </div>
  );
};
