// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gAA\\+U {\n    text-align: right;\n    height: 32px;\n    width: 100%;\n    padding: 0 16px;\n    border: none;\n    border-radius: 4px;\n    box-shadow: var(--elevation-0);\n    line-height: 1;\n    background-color: transparent;\n  }\n\n    ._0g-KE {\n      border: 1px solid rgb(var(--color-red-3));\n    }\n\n    .S\\+qu4 {\n      color: rgb(var(--color-light-gray-1));\n      background-color: rgb(var(--color-light-gray-4));\n      cursor: not-allowed;\n    }\n  .gAA\\+U:focus {\n    background-color: white !important; /** to override the prop input color **/\n  }\n  .HC7sb {\n    position: absolute;\n    left: 24px;\n    top: 3px;\n    color: rgb(var(--color-red-3));\n    cursor: pointer;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EditableCurrencyCellRenderer_editableInput": "gAA+U",
	"EditableCurrencyCellRenderer_editableInput__error": "_0g-KE",
	"EditableCurrencyCellRenderer_editableInput__disabled": "S+qu4",
	"EditableCurrencyCellRenderer_errorIcon": "HC7sb"
};
export default ___CSS_LOADER_EXPORT___;
