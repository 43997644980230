import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { useUser } from 'app/core/userManagement/userProvider';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext } from 'app/models';

import { getImpersonationToken } from 'utils/ImpersonationLocalStorage';

import { useScope } from './scopeProvider';

export interface UserMenuContextValues extends BaseContext {
  displayImpersonateMenuItem?: boolean;
  displayExitImpersonateMenuItem?: boolean;
  onImpersonationMenuItemClick?: () => void;
  setOnImpersonationMenuItemClick: Dispatch<SetStateAction<() => void>>;
  onExitImpersonationMenuItemClick?: () => void;
  setOnExitImpersonationMenuItemClick: Dispatch<SetStateAction<() => void>>;
}

export const UserMenuContext = React.createContext<UserMenuContextValues | null>(null);
UserMenuContext.displayName = 'UserMenuContext';

export const UserMenuProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { userProfile } = useUser();

  const hasImpersonationToken = getImpersonationToken() != null;
  const { selectedTenant } = useScope();

  const [onImpersonationMenuItemClick, setOnImpersonationMenuItemClick] = useState<() => void | null>();
  const [onExitImpersonationMenuItemClick, setOnExitImpersonationMenuItemClick] = useState<() => void | null>();

  const displayImpersonateMenuItem =
    onImpersonationMenuItemClick != null && !hasImpersonationToken && selectedTenant && userProfile.isSupportUser;
  const displayExitImpersonateMenuItem =
    onExitImpersonationMenuItemClick != null && hasImpersonationToken && userProfile.isSupportUser;

  const resetValues = () => {
    setOnImpersonationMenuItemClick(null);
    setOnExitImpersonationMenuItemClick(null);
  };

  const values = useMemo<UserMenuContextValues>(
    () => ({
      displayImpersonateMenuItem,
      displayExitImpersonateMenuItem,
      onImpersonationMenuItemClick,
      setOnImpersonationMenuItemClick,
      onExitImpersonationMenuItemClick,
      setOnExitImpersonationMenuItemClick,
      resetValues
    }),
    [
      displayImpersonateMenuItem,
      displayExitImpersonateMenuItem,
      onImpersonationMenuItemClick,
      onExitImpersonationMenuItemClick
    ]
  );

  return <UserMenuContext.Provider value={values}>{children}</UserMenuContext.Provider>;
};

export const useUserMenu = (): UserMenuContextValues => useContextSafe(UserMenuContext);
