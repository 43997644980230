import { useEffect, useState } from 'react';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCoinsort } from 'app/contexts/coinsortProvider';

export interface UseCoinsortValues {
  coinsortEnabled: boolean;
  isCoinsortLoading: boolean;
  isCoinsortRunning: boolean;
  isButtonLoading: boolean;
  onClickCancelCoinsort: () => Promise<void>;
  onClickRunCoinsort: () => Promise<void>;
}

export const useCoinsortButton = (
  selectedBattlecardId: string,
  selectedQuotaComponentId: number,
  selectedDeploymentModelId: number
): UseCoinsortValues => {
  const [coinsortEnabled, setCoinsortEnabled] = useState(false);

  const { isBattleCardDataLoading } = useBattleCard();
  const { runCoinsort, cancelCoinsort, checkCanRunCoinsort, coinsortLoadingLookupMap, coinsortRunningLookupMap } =
    useCoinsort();

  const isCoinsortRunning = coinsortRunningLookupMap?.[selectedBattlecardId];
  const isCoinsortLoading = !!coinsortLoadingLookupMap?.[selectedBattlecardId];

  const checkCoinsortEnabled = async (battleCardId: string) => {
    const isCoinsortEnabled = await checkCanRunCoinsort(battleCardId, selectedDeploymentModelId);
    setCoinsortEnabled(isCoinsortEnabled);
  };

  // Re-check if coinsort is enabled after battle card updates
  useEffect(() => {
    if (!isBattleCardDataLoading) {
      checkCoinsortEnabled(selectedBattlecardId);
    }
  }, [isBattleCardDataLoading, selectedBattlecardId]);

  const onClickRunCoinsort = async () => {
    await runCoinsort(selectedBattlecardId, selectedQuotaComponentId);
  };

  const onClickCancelCoinsort = async () => {
    await cancelCoinsort(selectedBattlecardId, selectedQuotaComponentId);
  };

  return {
    coinsortEnabled,
    isCoinsortLoading,
    isCoinsortRunning,
    isButtonLoading: isBattleCardDataLoading || isCoinsortLoading || isCoinsortRunning,
    onClickCancelCoinsort,
    onClickRunCoinsort
  };
};
