import React from 'react';

import { ProgressBar } from '@blueprintjs/core';

import block from 'utils/bem-css-modules';

import style from './StatusBar.module.pcss';

const b = block(style);

interface StatusBarProps {
  completionPercent: number;
}

const StatusBar: React.FC<StatusBarProps> = ({ completionPercent }: StatusBarProps) => {
  if (completionPercent < 0 || completionPercent > 1) {
    throw new Error('Invalid args: StatusBar requires a number between 0 and 1');
  }

  return (
    <div className={b('')}>
      <ProgressBar value={completionPercent} stripes={false} />
    </div>
  );
};

export default StatusBar;
