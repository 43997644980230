import React from 'react';

import { useBattleCard } from 'app/contexts/battleCardProvider';

import { BattleCardData } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './QuotaComponentDescription.module.pcss';

const b = block(style);

interface QuotaComponentDescriptionProps {
  cardData: BattleCardData;
}

const QuotaComponentDescription: React.FC<QuotaComponentDescriptionProps> = ({
  cardData
}: QuotaComponentDescriptionProps) => {
  const { isRootCard } = cardData;
  const { quotaComponentList } = useBattleCard();
  const HIGHLIGHT_SWATCHES_LENGTH = 12;

  const getQuotaComponentStyles = (quotaComponent, index) => {
    let colorReference;
    const rootCardColorIndex = (index % HIGHLIGHT_SWATCHES_LENGTH) + 1;
    const nonRootCardColorIndex =
      quotaComponentList.findIndex((component) => component.quotaComponentId === quotaComponent?.quotaComponentId) + 1;

    if (isRootCard) {
      colorReference = rootCardColorIndex < 10 ? `0${rootCardColorIndex}` : `${rootCardColorIndex}`;
    } else {
      colorReference = nonRootCardColorIndex < 10 ? `0${nonRootCardColorIndex}` : `${nonRootCardColorIndex}`;
    }
    return colorReference;
  };

  return (
    <div className={b('quotaComponentDescription', { root: isRootCard })} data-testid="qc-description">
      {!!cardData?.quotaComponents?.length &&
        cardData?.quotaComponents.map((quotaComponent, index) => {
          const qcName = quotaComponent?.quotaComponentName;

          return (
            <span key={`${cardData.battlecardId}-${quotaComponent?.quotaComponentId}`}>
              <span
                className={b('quotaComponentTitle')}
                style={{
                  backgroundColor: `var(--highlight-${getQuotaComponentStyles(quotaComponent, index)}-translucent)`,
                  color: `var(--highlight-${getQuotaComponentStyles(quotaComponent, index)})`
                }}
                data-testid={`qc-title-${qcName}`}
              >
                {qcName}
              </span>
            </span>
          );
        })}
    </div>
  );
};

export default QuotaComponentDescription;
