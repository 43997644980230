// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._89woT {\n}\n\n  .xOBz2 {\n    font-weight: normal;\n  }\n\n  ._8SPsh {\n    padding-top: 5px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EmptyMapGridMessage": "_89woT " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-container"] + "",
	"EmptyMapGridMessage_text": "xOBz2 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-text"] + "",
	"EmptyMapGridMessage_lassoIcon": "_8SPsh"
};
export default ___CSS_LOADER_EXPORT___;
