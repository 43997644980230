// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tH6DK {\n  border-radius: var(--m);\n  box-shadow: var(--box-shadow);\n  margin: var(--s);\n  padding: var(--m);\n}\n\n  ._3nBGG {\n    visibility: hidden;\n  }\n\n  ._3nBGG[focus-within], ._3nBGG:focus, .tH6DK:hover ._3nBGG {\n    visibility: visible;\n  }\n\n  ._3nBGG:focus-within, ._3nBGG:focus, .tH6DK:hover ._3nBGG {\n    visibility: visible;\n  }\n\n@media not (hover: hover) {\n    ._3nBGG {\n      visibility: visible;\n    }\n  }\n\n.i\\+9WB {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n    padding-bottom: var(--m);\n  }\n\n.xcHPF {\n    font-size: 14px;\n    font-weight: 600;\n  }\n\n.JYwkU {\n    font-size: 12px;\n    font-weight: 400;\n  }\n\n.NbG-A {\n    display: flex;\n    align-items: center;\n  }\n\n.IAbrY {\n    display: flex;\n    justify-content: center;\n    margin-right: 10px;\n  }\n\n.boFxa {\n    display: flex;\n  }\n\n.NuXkt {\n    display: flex;\n    flex-direction: column;\n  }\n\n.NStV- {\n    padding-left: var(--l);\n    display: flex;\n    flex-direction: column;\n  }\n\n.c\\+-qu {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n.pviqQ {\n    padding: 0 var(--xs);\n    color: rgb(var(--color-dark-gray-4));\n  }\n\n.-sPhJ {\n      color: rgb(var(--color-green-2));\n    }\n\n._8TTg\\+ {\n    padding: 0 var(--xs);\n    font-size: 16px;\n    font-weight: 600;\n    color: rgb(var(--color-dark-gray-4));\n    min-width: 0;\n    flex: 1;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ScenarioTile": "tH6DK",
	"ScenarioTile_launchScenarioButton": "_3nBGG",
	"ScenarioTile_scenarioName": "i+9WB",
	"ScenarioTile_date": "xcHPF",
	"ScenarioTile_dateLabel": "JYwkU",
	"ScenarioTile_rightContainer": "NbG-A",
	"ScenarioTile_currentScenario": "IAbrY",
	"ScenarioTile_scenarioDates": "boFxa",
	"ScenarioTile_lastOpened": "NuXkt",
	"ScenarioTile_dateCreated": "NStV-",
	"ScenarioTile_scenarioNameHeader": "c+-qu",
	"ScenarioTile_scenarioNameHeaderIcon": "pviqQ",
	"ScenarioTile_scenarioNameHeaderIcon__isPublished": "-sPhJ",
	"ScenarioTile_scenarioNameHeaderText": "_8TTg+"
};
export default ___CSS_LOADER_EXPORT___;
