import React, { FC } from 'react';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import warningError from 'assets/svgs/warning_error.svg';

import style from './EmbeddedMapFallbackViews.module.pcss';

const b = block(style);

const EmbeddedErrorView: FC<{ title: string; body: string }> = ({ title, body }) => (
  <div className={b('fallbackContainer')}>
    <img
      src={warningError}
      alt={formatMessage('AN_ERROR_OCCURRED')}
      className={b('warningError')}
      data-testid="embedded-error-img"
    />
    <header className={b('fallbackHeader')}> {title}</header>
    <p className={b('fallbackBody')}>{body}</p>
  </div>
);

export const EmbeddedMapNoAccessView: FC = () => {
  return EmbeddedErrorView({
    title: formatMessage('EMBEDDED_MAP_FALLBACK_HEADER'),
    body: formatMessage('EMBEDDED_MAP_FALLBACK_BODY_NO_ACCESS')
  });
};

export const EmbeddedMapGenericErrorView: FC = () => {
  return EmbeddedErrorView({
    title: formatMessage('EMBEDDED_MAP_FALLBACK_HEADER'),
    body: formatMessage('EMBEDDED_MAP_FALLBACK_BODY_ERROR')
  });
};
