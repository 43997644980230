import { useScope } from 'app/contexts/scopeProvider';

import { useGetMeasuresForMap } from 'app/graphql/queries/getMeasuresForMap';

import { MeasureType } from 'app/models';

export const useGetInsightsMeasureId = (shouldSkip: boolean): number | null => {
  const { selectedPlanningCycle } = useScope();

  const { data: measures } = useGetMeasuresForMap({
    skip: shouldSkip || !selectedPlanningCycle?.id,
    variables: {
      planningCycleId: selectedPlanningCycle?.id
    }
  });
  return (
    measures?.getMeasures?.find((measure) => measure.measureName === MeasureType.PRIOR_YEAR_ACTUAL)?.measureId ?? null
  );
};
