import React from 'react';

import { SortAscending, SortDescending, ChevronDown } from '@carbon/icons-react';
import { Button, Intent } from '@varicent/components';

import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import DropdownMenuDivider from 'components/DropdownMenu/DropdownMenuDivider';
import DropdownMenuRadioGroup from 'components/DropdownMenu/DropdownMenuRadioGroup';
import DropdownMenuRadioItem from 'components/DropdownMenu/DropdownMenuRadioItem';

import { SortDirection } from 'app/graphql/generated/graphqlApolloTypes';
import { ScenarioSummary } from 'app/graphql/hooks/useScenarioSummaries';

import { formatMessage } from 'utils/messages/utils';

type SortBy = keyof ScenarioSummary;

interface SortScenariosButtonProps {
  sortBy: SortBy;
  onChangeSortBy: (sortBy: SortBy) => void;
  sortOrder: SortDirection;
  onChangeSortOrder: (sortOrder: SortDirection) => void;
}

const SortScenariosButton: React.FC<SortScenariosButtonProps> = ({
  sortBy,
  onChangeSortBy,
  sortOrder,
  onChangeSortOrder
}) => {
  return (
    <DropdownMenu
      target={
        <Button
          icon={sortOrder === SortDirection.asc ? SortAscending : SortDescending}
          rightIcon={ChevronDown}
          minimal
          text={sortBy === 'deploymentModelLastOpenedAt' ? formatMessage('LAST_OPENED') : formatMessage('DATE_CREATED')}
          type="button"
          intent={Intent.NONE}
          data-testid="sort-scenarios-button"
        />
      }
      content={
        <>
          <DropdownMenuRadioGroup value={sortBy} onValueChange={(value) => onChangeSortBy(value as SortBy)}>
            <DropdownMenuRadioItem
              value={'deploymentModelLastOpenedAt'}
              textValue={formatMessage('LAST_OPENED')}
              data-testid="last-opened-dropdown-item"
            />
            <DropdownMenuRadioItem
              value={'deploymentModelCreatedAt'}
              textValue={formatMessage('DATE_CREATED')}
              data-testid="date-created-dropdown-item"
            />
          </DropdownMenuRadioGroup>
          <DropdownMenuDivider data-testid="sort-menu-divider" />
          <DropdownMenuRadioGroup
            value={sortOrder as string}
            onValueChange={(value) => onChangeSortOrder(value as SortDirection)}
          >
            <DropdownMenuRadioItem
              value={SortDirection.asc}
              textValue={formatMessage('ASCENDING')}
              data-testid="ascending-dropdown-item"
            />
            <DropdownMenuRadioItem
              value={SortDirection.desc}
              textValue={formatMessage('DESCENDING')}
              data-testid="descending-dropdown-item"
            />
          </DropdownMenuRadioGroup>
        </>
      }
    />
  );
};

export default SortScenariosButton;
