// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jOEMm {\n    margin-top: 30px;\n    padding: 20px 0;\n    height: 100%;\n    border-top: 1px solid rgba(var(--color-gray-1), 0.25);\n    border-bottom: 1px solid rgba(var(--color-gray-1), 0.25);\n  }\n  .uh3c\\+ {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n  .wZuX8 {\n    display: flex;\n    align-items: center;\n    color: rgb(var(--color-dark-gray-3));\n  }\n  .TqsUL {\n    padding-top: 5px; /* align icon vertically with text */\n    margin-right: 4px;\n  }\n  .vIZXu {\n    display: flex;\n    align-items: center;\n    justify-content: justify-between;\n    color: rgb(var(--color-gray-2));\n  }\n  ._7yLwt {\n    margin: 0 24px 0 0 !important;\n  }\n  .HrRof {\n    color: rgb(var(--color-red-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DataFileListItem_listItems": "jOEMm",
	"DataFileListItem_listItem": "uh3c+",
	"DataFileListItem_nameDetails": "wZuX8",
	"DataFileListItem_fileIcon": "TqsUL",
	"DataFileListItem_statusDetails": "vIZXu",
	"DataFileListItem_statusDetailsText": "_7yLwt",
	"DataFileListItem_statusDetailsTextError": "HrRof"
};
export default ___CSS_LOADER_EXPORT___;
