// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BSTtm {\n  line-height: 20;\n}\n\n  .xQiz6 {\n    cursor: pointer;\n    font-size: 14px;\n    border-top: 1px solid rgb(var(--color-light-gray-3));\n    font-weight: 600;\n    padding: 5px 0 10px;\n    text-align: center;\n  }\n\n  .zn6N9 {\n    padding-left: 20px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommentThread": "BSTtm",
	"CommentThread_threadReplyCollapseIcon": "xQiz6",
	"CommentThread_threadReplySection": "zn6N9"
};
export default ___CSS_LOADER_EXPORT___;
