// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y-lsn {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  height: var(--header-height);\n}\n\n  .kA3Xu {\n    padding-right: 4px;\n    font-size: 14px;\n  }\n\n  ._7XI7B {\n    padding-right: 10px;\n  }\n\n  .Kv3xu {\n    background: rgb(var(--color-cobalt-3)) !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserMenu": "Y-lsn",
	"UserMenu_userName": "kA3Xu",
	"UserMenu_profilePictureContainer": "_7XI7B",
	"UserMenu_profilePicture": "Kv3xu"
};
export default ___CSS_LOADER_EXPORT___;
