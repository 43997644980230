import gql from 'graphql-tag';

export const PERIODIC_MEASURES = gql`
  fragment PeriodicMeasuresFragment on PeriodicMeasure {
    measureId
    measureName
    editable
    measureValue {
      measureFieldType
      textValue
      type
      value
      years {
        year
        date
        value
        halves {
          half
          date
          value
          quarters {
            quarter
            date
            value
            months {
              month
              date
              value
            }
          }
        }
      }
    }
  }
`;
