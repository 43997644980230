import {
  BattlecardWorkflow,
  TerritoryGroupsWorkflow,
  QuotaApprovals,
  WorkflowTreeNode,
  WorkflowStatus,
  WorkflowType
} from 'app/models/index';
interface Hierarchy {
  [key: number]: string[];
}

interface Node {
  nodeId: number;
  nodeName: string;
  children: number[];
  ownerEmail: string;
  ownerFirstName: string;
  ownerLastName: string;
  approverFirstName: string;
  approverLastName: string;
  status: WorkflowStatus;
  type: WorkflowType;
  workflowId: number;
  territoryGroups?: TerritoryGroupsWorkflow[];
}
interface NodeMap {
  [key: number]: Node;
}

const isBattlecardWorkflow = (
  workflow: BattlecardWorkflow | TerritoryGroupsWorkflow
): workflow is BattlecardWorkflow => {
  return 'battlecardName' in workflow;
};

const bcPreDFS = (workflows: BattlecardWorkflow[]): NodeMap => {
  const nodeMap: NodeMap = {};
  workflows.forEach((workflow) => {
    const {
      battlecardId,
      battlecardName,
      children,
      ownerEmail,
      ownerFirstName,
      ownerLastName,
      status,
      territoryGroups,
      battlecardWorkflowId,
      approverFirstName,
      approverLastName
    } = workflow;
    nodeMap[battlecardId] = {
      nodeId: battlecardId,
      nodeName: battlecardName,
      children,
      ownerEmail,
      ownerFirstName,
      ownerLastName,
      status,
      territoryGroups,
      type: WorkflowType.BATTLECARD,
      workflowId: battlecardWorkflowId,
      approverFirstName,
      approverLastName
    };
  });
  return nodeMap;
};

const tgPreDFS = (workflows: TerritoryGroupsWorkflow[]): NodeMap => {
  const nodeMap: NodeMap = {};
  workflows.forEach((workflow) => {
    const {
      territoryGroupId,
      territoryGroupName,
      children,
      ownerEmail,
      ownerFirstName,
      ownerLastName,
      status,
      territoryGroupWorkflowId,
      approverFirstName,
      approverLastName
    } = workflow;
    nodeMap[territoryGroupId] = {
      nodeId: territoryGroupId,
      nodeName: territoryGroupName,
      children,
      ownerEmail,
      ownerFirstName,
      ownerLastName,
      status,
      type: WorkflowType.TERRITORY_GROUP,
      workflowId: territoryGroupWorkflowId,
      approverFirstName,
      approverLastName
    };
  });
  return nodeMap;
};

const depthFirstSearch = (
  nodeId: number,
  workflowMap: NodeMap,
  ancestors: string[],
  hierarchy: Hierarchy,
  inProgress = false
): Hierarchy => {
  if (!hierarchy[nodeId]) {
    hierarchy[nodeId] = [];
  }
  if (hierarchy[nodeId].length === 0) {
    if (ancestors) {
      hierarchy[nodeId].push(...ancestors);
    }
    hierarchy[nodeId].push(workflowMap[nodeId]?.nodeName);

    if (workflowMap[nodeId]?.children?.length > 0) {
      workflowMap[nodeId]?.children?.forEach((child) => {
        hierarchy = depthFirstSearch(child, workflowMap, hierarchy[nodeId], hierarchy, true);
      });
    }
  } else {
    if (inProgress) {
      // rerun dfs for this node as we need to update all the childen with the new ancestors
      hierarchy[nodeId] = [];
      hierarchy = depthFirstSearch(nodeId, workflowMap, ancestors, hierarchy, true);
    }
  }

  return hierarchy;
};

// Following algo performs a dfs first on the battlecard and then on its territory groups
// it updates and parses the data into a hierarchy in order to use in ag-grid tree data
export const parseWorkflowTree = (
  workflowsRaw: (BattlecardWorkflow | TerritoryGroupsWorkflow)[],
  ancestors: string[] = null
): QuotaApprovals => {
  if (workflowsRaw?.length === 0) {
    return {
      numberOfApproved: 0,
      totalApprovalsRequired: 0,
      workflows: [],
      rootBCOwnerEmail: null
    };
  }
  const rootBcIndex = workflowsRaw.findIndex((wf) => isBattlecardWorkflow(wf) && wf.battlecardParentId === null);
  if (rootBcIndex > 0) {
    workflowsRaw.splice(0, 0, workflowsRaw.splice(rootBcIndex, 1)[0]);
  }

  const tgWorkflowTree: { [key: number]: WorkflowTreeNode[] } = {};
  let hierarchy: Hierarchy = {};
  let totalApprovalsRequired;
  let numberOfApproved = 0;

  const workflowTree: WorkflowTreeNode[] = [];
  let workflowMap: NodeMap = {};

  if (isBattlecardWorkflow(workflowsRaw[0])) {
    const battlecardsRaw = workflowsRaw as BattlecardWorkflow[];
    workflowMap = bcPreDFS(battlecardsRaw);
    // exclude the root battlecard from the count as approvals are not needed
    totalApprovalsRequired = -1;
  } else {
    const territoryGroupsRaw = workflowsRaw as TerritoryGroupsWorkflow[];
    workflowMap = tgPreDFS(territoryGroupsRaw);
    totalApprovalsRequired = 0;
  }

  workflowsRaw?.forEach((workflowRaw) => {
    const { status } = workflowRaw;
    const territoryGroupsApprovals: WorkflowTreeNode[] = [];
    let nodeId: number;
    let territoryGroups: TerritoryGroupsWorkflow[] = null;

    if (isBattlecardWorkflow(workflowRaw)) {
      nodeId = workflowRaw.battlecardId;
      territoryGroups = workflowRaw.territoryGroups;
    } else {
      nodeId = workflowRaw.territoryGroupId;
    }

    hierarchy = depthFirstSearch(nodeId, workflowMap, ancestors, hierarchy);

    totalApprovalsRequired++;

    if (status === 'Approved') {
      numberOfApproved++;
    }

    if (territoryGroups?.length > 0) {
      const {
        totalApprovalsRequired: totalTerritoryApprovals,
        numberOfApproved: numberOfTerritoriesApproved,
        workflows: territories
      } = parseWorkflowTree(territoryGroups, ancestors || hierarchy[nodeId]);

      totalApprovalsRequired += totalTerritoryApprovals;
      numberOfApproved += numberOfTerritoriesApproved;
      territoryGroupsApprovals.push(...territories);
      tgWorkflowTree[nodeId] = territoryGroupsApprovals;
    }
  });

  Object.keys(hierarchy).forEach((nodeId) => {
    workflowTree.push({
      workflowHierarchy: hierarchy[nodeId],
      user: `${workflowMap[nodeId]?.ownerLastName}, ${workflowMap[nodeId]?.ownerFirstName}`,
      email: workflowMap[nodeId]?.ownerEmail,
      status: workflowMap[nodeId]?.status,
      workflowId: workflowMap[nodeId]?.workflowId,
      type: workflowMap[nodeId]?.type,
      approver:
        workflowMap[nodeId]?.approverFirstName && workflowMap[nodeId]?.approverLastName
          ? `${workflowMap[nodeId]?.approverFirstName} ${workflowMap[nodeId]?.approverLastName}`
          : ''
    });

    if (tgWorkflowTree[nodeId]?.length > 0) {
      workflowTree.push(...tgWorkflowTree[nodeId]);
    }
  });

  return {
    totalApprovalsRequired,
    numberOfApproved,
    workflows: workflowTree,
    rootBCOwnerEmail: workflowsRaw[0]?.ownerEmail
  };
};
