import { config } from 'utils/config';
import { formatMessage } from 'utils/messages/utils';

export enum IntegrationTabIds {
  SYMON = 'symon',
  SYMON_IMPORT = 'import-data',
  SYMON_DATA = 'view-sources',
  SYMON_PIPES = 'build-and-visualize',
  CONFIGURATIONS = 'configure-for-planning',
  MANAGE_DATA_TABLES = 'manage-tables'
}

export enum SymonIframePaths {
  DATA = 'data',
  HOME = 'home',
  DASHBOARD = 'dashboard'
}

export type IntegrationTab = {
  tabId: string;
  tabName: string;
} & ({ iframe: false } | { iframe: true; pathname: SymonIframePaths });

export const IntegrationTabs: Array<IntegrationTab> = [
  { tabId: IntegrationTabIds.SYMON_IMPORT, tabName: formatMessage('IMPORT_DATA'), iframe: false },
  {
    tabId: IntegrationTabIds.SYMON_DATA,
    tabName: formatMessage('VIEW_SOURCES'),
    iframe: true,
    pathname: SymonIframePaths.DATA
  },
  {
    tabId: IntegrationTabIds.SYMON_PIPES,
    tabName: formatMessage('BUILD_AND_VISUALIZE'),
    iframe: true,
    pathname: SymonIframePaths.HOME
  },
  { tabId: IntegrationTabIds.CONFIGURATIONS, tabName: formatMessage('CONFIGURE_FOR_PLANNING'), iframe: false },
  { tabId: IntegrationTabIds.MANAGE_DATA_TABLES, tabName: formatMessage('MANAGE_TABLES'), iframe: false },
  { tabId: IntegrationTabIds.SYMON, tabName: formatMessage('VARICENT_ELT'), iframe: false }
];

type SymonLoadedMessage = { type: 'symon_iframe_loaded' };

type SymonRefreshedMessage = { type: 'symon_iframe_refresh' };

export type FromSymonMessage = SymonLoadedMessage | SymonRefreshedMessage;

interface SymonSetAuthMessage {
  type: 'sp_iframe_set_auth';
  data: string; // JSON string containing a SymonToken
}
export type ToSymonMessage = SymonSetAuthMessage;

export interface SymonToken {
  token: string;
  orgID: string;
}

const symonApiOrigins = {
  dev: 'https://daily-api.wisepipe.com',
  'dev-ue2': 'https://daily-api.wisepipe.com',
  stg: 'https://test-api.wisepipe.com',
  'stg-ue2': 'https://test-api.wisepipe.com',
  production: 'https://api.symon.ai',
  'production-ue2': 'https://api.symon.ai',
  qr: 'https://api.symon.ai',
  dr: 'https://api.symon.ai',
  'production-eu': 'https://eu-api.symon.ai',
  'dr-eu': 'https://eu-api.symon.ai'
} as const;

type SymonApiOrigin = (typeof symonApiOrigins)[keyof typeof symonApiOrigins];

export const expectedSymonApiOrigin: SymonApiOrigin = symonApiOrigins[config.TARGET_ENV] ?? symonApiOrigins.dev;

export const symonIframeOriginsByApiOrigin: Record<SymonApiOrigin, string> = {
  'https://daily-api.wisepipe.com': 'https://daily-embedded.wisepipe.com',
  'https://test-api.wisepipe.com': 'https://test-embedded.wisepipe.com',
  'https://api.symon.ai': 'https://app-embedded.symon.ai',
  'https://eu-api.symon.ai': 'https://eu-embedded.symon.ai'
};
export const symonSourcesByApiOrigin: Record<SymonApiOrigin, string> = {
  'https://daily-api.wisepipe.com': 'sp-dev',
  'https://test-api.wisepipe.com': 'sp-staging',
  'https://api.symon.ai': 'sp-prod',
  'https://eu-api.symon.ai': 'sp-prod-eu'
};

export interface SymonUploadCredentials {
  expiration: string;
  identityID: string;
  accessKeyID: string;
  secretKey: string;
  sessionToken: string;
  region: string;
}

export interface SymonApiResponseTypeMap {
  ['auth/jwtLoginToken']: SymonToken;
  ['auth/aws']: SymonUploadCredentials;
}

// Enum version of UploadStage type in @symon-ai/wisepipe-imports
export enum UploadStage {
  'attempt' = 'attempt',
  'prepare' = 'prepare',
  'start' = 'start',
  'upload' = 'upload',
  'process' = 'process',
  'discover' = 'discover',
  'monitor' = 'monitor',
  'delete' = 'delete'
}

// Enum version of UploadStatus type in @symon-ai/wisepipe-imports
export enum UploadStatus {
  'before' = 'before',
  'progress' = 'progress',
  'success' = 'success',
  'failure' = 'failure',
  'after' = 'after'
}
