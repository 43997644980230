// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lDYJS {\n    /* Undoing Bootstrap styles */\n    font-weight: normal;\n    font-size: 0.75rem;\n    margin-bottom: 0.25rem;\n    margin-top: 5px;\n  }\n  ._9H61U {\n    display: grid;\n    grid-template-columns: 250px 250px;\n    grid-gap: 24px;\n    gap: 24px;\n  }\n  ._5lUhl .bp3-input {\n      height: 32px;\n    }\n  .mMQtK {\n    display: inline-flex;\n    margin-top: var(--xl);\n    margin-left: var(--m);\n    color: rgb(var(--color-cobalt-3));\n  }\n  .Rh24w {\n    margin-right: 11px;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n  }\n  .M\\+fAd {\n    color: rgb(var(--color-cobalt-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LocationGroupForm_label": "lDYJS",
	"LocationGroupForm_inputWrapper": "_9H61U",
	"LocationGroupForm_smallInput": "_5lUhl",
	"LocationGroupForm_csvDownloadWrapper": "mMQtK",
	"LocationGroupForm_csvDownloadIcon": "Rh24w",
	"LocationGroupForm_csvDownloadLink": "M+fAd"
};
export default ___CSS_LOADER_EXPORT___;
