import { datadogRum } from '@datadog/browser-rum';

type AnalyticsPayloads = {
  openMap: void;
  dataTrayTabChange: { dataTrayTab: string };
  //! Add your actions here
  // exampleA: void; // no payload
  // exampleB: { count: number }; // has payload (must be an object)
};

type ActionsWithoutPayloads = {
  [key in keyof AnalyticsPayloads as AnalyticsPayloads[key] extends void ? key : never]: AnalyticsPayloads[key];
};

type ActionsWithPayloads = {
  [key in keyof AnalyticsPayloads as AnalyticsPayloads[key] extends Record<string, unknown>
    ? key
    : never]: AnalyticsPayloads[key];
};

export function reportAnalyticsAction<K extends keyof ActionsWithoutPayloads>(kind: K, payload?: undefined): void;
export function reportAnalyticsAction<K extends keyof ActionsWithPayloads>(
  kind: K,
  payload: ActionsWithPayloads[K]
): void;
export function reportAnalyticsAction<K extends keyof AnalyticsPayloads>(
  kind: K,
  payload?: AnalyticsPayloads[K]
): void {
  // eslint-disable-next-line no-restricted-syntax
  datadogRum.addAction(kind, payload as object);
}

type TimerExtras = Record<string, string | number | boolean | null | undefined>;

export function startPerformanceTimer(label: string, budget?: number) {
  const startTime = performance.now();
  let stopped = false;
  return {
    stop: (extras?: TimerExtras) => {
      if (stopped) return;
      stopped = true;
      const endTime = performance.now();
      const duration = endTime - startTime;
      const timings = budget
        ? {
            duration,
            budget,
            percentOfBudget: (100 * duration) / budget
          }
        : null;
      // eslint-disable-next-line no-restricted-syntax
      datadogRum.addAction(label, {
        duration,
        timings,
        ...extras
      });
      addToPerformanceTrack(label, startTime, endTime, { ...timings, ...extras });
    }
  };
}

const addToPerformanceTrack = (label: string, start: number, end: number, extras: TimerExtras) => {
  performance.measure(label, {
    start,
    end,
    detail: {
      devtools: {
        dataType: 'track-entry',
        track: 'SalesPlanning',
        properties: extras ? Object.entries(extras).map(([key, value]) => [key, `${value}`]) : null
      }
    }
  });
};
