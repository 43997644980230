import React from 'react';

import { useGetSymonAccount } from 'app/components/IntegrationPanel/SymonIntegrationForm/hooks/useGetSymonAccount';
import SymonPortalWrapper from 'app/components/IntegrationPanel/SymonPortalWrapper';

import { useScope } from 'app/contexts/scopeProvider';

import useSymonToken from 'app/hooks/symon/useSymonToken';

import { SymonIframePaths } from './SymonTypes';

const SymonDashboardPortal: React.FC = () => {
  const tokenState = useSymonToken();
  const { selectedTenant } = useScope();
  const { data: symonAccount, loading: symonAccountLoading } = useGetSymonAccount(selectedTenant?.id);
  return (
    <SymonPortalWrapper
      symonDomainLoading={symonAccountLoading}
      symonDomain={symonAccount?.getSymonAccount?.symonDomain}
      tokenState={tokenState}
      pathname={SymonIframePaths.DASHBOARD}
    />
  );
};

export default SymonDashboardPortal;
