// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ENY3l label.bp3-label {\n    font-weight: normal;\n  }\n  .ENY3l label.bp3-control {\n    font-weight: normal;\n    color: var(--black);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormRadioGroup": "ENY3l"
};
export default ___CSS_LOADER_EXPORT___;
