// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._85cZR {\n  width: 100%;\n}\n\n  .gH\\+M1 {\n    height: 40px;\n    min-width: 1000px;\n    padding: 8px;\n    display: flex;\n    flex-direction: row;\n  }\n\n  .p6Cxd {\n    height: 100%;\n    width: 200px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoadingCellRenderer": "_85cZR",
	"LoadingCellRenderer_gridCell": "gH+M1",
	"LoadingCellRenderer_gridLoading": "p6Cxd"
};
export default ___CSS_LOADER_EXPORT___;
