// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1az50 {\n  font-family: Inter, sans-serif !important;\n}\n  ._1az50 .bp3-menu {\n    border-radius: 4px;\n    padding: 0;\n  }\n  ._1az50 .bp3-menu-item {\n    font-size: 14px;\n    padding: 10px 16px;\n    color: rgb(var(--color-gray-2));\n  }\n  ._1az50 .bp3-popover2-arrow::before {\n    box-shadow: none;\n  }\n  ._1az50 .bp3-menu-item:hover {\n    background-color: rgb(var(--color-cobalt-5));\n    color: rgb(var(--color-cobalt-3));\n  }\n  ._1az50 .bp3-popover2 {\n    border-radius: 4px;\n    box-shadow: var(--elevation-4);\n    background-color: var(--white);\n    margin: 0;\n  }\n  ._1az50 .bp3-overlay-content {\n    z-index: 23;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Popover": "_1az50"
};
export default ___CSS_LOADER_EXPORT___;
