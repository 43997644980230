// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cWnT1 .bp3-dialog {\n    width: 900px;\n  }\n  ._8P\\+AE {\n    max-height: calc(\n      90vh - var(--battle-card-designer-header-height) - var(--battle-card-designer-footer-height) - 60px\n    );\n    background-color: white;\n    display: flex;\n    flex-direction: column;\n    min-height: 200px;\n    overflow: auto;\n    padding: 0 var(--dialog-padding);\n  }\n  .IjI\\+6 {\n    font-size: 18px;\n    font-weight: 600;\n    color: rgb(var(--color-dark-gray-3));\n  }\n  .xb5lr {\n    color: rgb(var(--color-dark-gray-2));\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: 400;\n  }\n  .pZOfZ {\n    height: 64px;\n    border-radius: var(--xs) var(--xs) 0 0;\n    padding: 14px var(--dialog-padding);\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);\n  }\n  .VahIc {\n    display: flex;\n    justify-content: space-between;\n    padding: 0 var(--dialog-padding);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SeasonalityDialog": "cWnT1",
	"SeasonalityDialog_dialogBody": "_8P+AE",
	"SeasonalityDialog_dialogTitle": "IjI+6",
	"SeasonalityDialog_instruction": "xb5lr",
	"SeasonalityDialog_dialogHeader": "pZOfZ",
	"SeasonalityDialog_buttons": "VahIc"
};
export default ___CSS_LOADER_EXPORT___;
