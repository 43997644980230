// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FX1WH {\n  display: flex;\n  flex-direction: column;\n}\n\n  .bvw7I {\n    display: grid;\n    flex-direction: column;\n    max-height: 500px;\n    overflow: scroll;\n  }\n\n  .bvw7I .bp3-button.bp3-minimal {\n      justify-content: start !important;\n    }\n\n  .bo4Pl {\n    display: grid;\n    flex-direction: column;\n    overflow: scroll;\n  }\n\n  .ZuovH {\n    margin: 2px var(--s);\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  }\n\n  .FX1WH .bp3-button.bp3-minimal {\n    justify-content: start !important;\n  }\n\n  .FX1WH .bp3-button:not([class*='bp3-intent-']) {\n    height: var(--l);\n    margin-left: var(--s);\n    padding: 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TooltipWithCopy": "FX1WH",
	"TooltipWithCopy_itemsContainer": "bvw7I",
	"TooltipWithCopy_items": "bo4Pl",
	"TooltipWithCopy_item": "ZuovH"
};
export default ___CSS_LOADER_EXPORT___;
