// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2ZvQ- {\n  display: flex;\n  flex-direction: column;\n  max-width: 360px;\n  height: 100%;\n}\n\n  .dhVPS {\n    flex: 1;\n    margin-top: 16px;\n    text-align: center;\n  }\n\n  .HgOoz {\n    display: block;\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 20px;\n  }\n\n  .MKO80 {\n    display: block;\n    font-size: 12px;\n    line-height: 16px;\n  }\n\n  ._6ELne {\n    text-align: center;\n  }\n\n  .s4CCI {\n    background: #fff;\n    border: 1px solid rgb(var(--color-midnight-blue-6));\n    border-radius: 8px;\n    margin-top: 16px;\n    padding: 16px;\n    text-align: left;\n    width: 100%\n  }\n\n  .s4CCI:hover {\n      box-shadow: var(--elevation-1);\n    }\n\n  .s4CCI:focus {\n      border-color: var(--intent-primary);\n    }\n\n  .XGwXj {\n    align-items: center;\n    display: flex;\n    color: rgb(var(--color-midnight-blue-2));\n    font-size: 14px;\n    font-weight: 600;\n  }\n\n  .OGvFU {\n    padding-right: 2px;\n    margin-right: 2px;\n  }\n\n  .Z\\+weH {\n    display: block;\n    color: rgb(var(--color-midnight-blue-2));\n    font-size: 12px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AssistantEmptyState": "_2ZvQ-",
	"AssistantEmptyState_message": "dhVPS",
	"AssistantEmptyState_primaryMessage": "HgOoz",
	"AssistantEmptyState_secondaryMessage": "MKO80",
	"AssistantEmptyState_prompts": "_6ELne",
	"AssistantEmptyState_prompt": "s4CCI",
	"AssistantEmptyState_promptTitle": "XGwXj",
	"AssistantEmptyState_promptTitleIcon": "OGvFU",
	"AssistantEmptyState_promptExample": "Z+weH"
};
export default ___CSS_LOADER_EXPORT___;
