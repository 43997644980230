// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PdPDK {\n    display: inline-flex;\n    justify-content: flex-end;\n  }\n  .UZESi {\n    color: rgb(var(--color-gray-2)) !important;\n    background-color: white !important;\n    font-size: 14px;\n  }\n  .sk32y {\n    width: 100%;\n    height: 100%;\n  }\n  .sk32y .ag-react-container {\n      display: flex;\n      justify-content: flex-end;\n    }\n  .sk32y .ag-cell {\n      overflow: visible !important;\n    }\n  .MLZCM {\n    display: flex;\n    height: 70vh;\n    margin-bottom: -50px;\n  }\n  .fX0us {\n    min-width: 1200px;\n    width: '60%';\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuotaGrid_totalsHeader": "PdPDK",
	"QuotaGrid_gridRow": "UZESi",
	"QuotaGrid_gridContainer": "sk32y",
	"QuotaGrid_lookupPreviewContents": "MLZCM",
	"QuotaGrid_seasonalityScheduleDialog": "fX0us"
};
export default ___CSS_LOADER_EXPORT___;
