import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Dialog, Intent } from '@blueprintjs/core';

import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UserManagementPanel.module.pcss';

const b = block(style);

interface UserManagementDialogProps {
  closeDialog: () => void;
  loading: boolean;
  handleAction: () => void;
  headlineText: string;
  bodyText: string;
  actionButtonText: string;
  actionButtonIntent: Intent;
}

const UserManagementDialog: React.FC<UserManagementDialogProps> = ({
  closeDialog,
  loading,
  handleAction,
  headlineText,
  bodyText,
  actionButtonText,
  actionButtonIntent
}: UserManagementDialogProps): JSX.Element => (
  <Dialog
    isOpen
    portalClassName={b('')}
    onClose={(event) => {
      // In order to prevent the Command Center from closing when the user clicks away from the Dialog,
      // we must prevent this closing action from propagating.
      event.stopPropagation();

      closeDialog();
    }}
  >
    <div>
      <h4 className={b('dialogTitle')}>{headlineText}</h4>
      <p className={b('bodyText')}>{bodyText}</p>
      <div className={b('footer')}>
        <div className={b('buttons')}>
          <TextButton text={formatMessage('CANCEL')} type="button" onClick={closeDialog} testId={'cancel-button'} />
          <TextButton
            text={actionButtonText}
            type="submit"
            intent={actionButtonIntent}
            loading={loading}
            onClick={() => handleAction()}
            testId={'submit-button'}
          />
        </div>
      </div>
    </div>
  </Dialog>
);

export default UserManagementDialog;
