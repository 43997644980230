import gql from 'graphql-tag';

export const GET_TERRITORY_GROUP_CHILDREN = gql`
  query GetTerritoryGroupChildren($battlecardId: Int!, $territoryGroupParentId: Int!, $startRow: Int!, $endRow: Int!) {
    getTerritoryGroupChildren(
      battlecardId: $battlecardId
      territoryGroupParentId: $territoryGroupParentId
      startRow: $startRow
      endRow: $endRow
    ) {
      items {
        children {
          battlecardId
          color
          battlecardQuotaComponentId
          hierarchyId
          hierarchyTopId
          path
          precedence
          territoryGroupId
          territoryGroupParentId
          territoryGroupName
        }
        battlecardId
        battlecardQuotaComponentId
        color
        hierarchyId
        hierarchyTopId
        hierarchyType
        path
        territoryGroupId
        precedence
        territoryGroupName
        territoryGroupParentId
      }
      totalCount
    }
  }
`;
