import React, { useState } from 'react';

import { ChevronLeft, Flash } from '@carbon/icons-react';
import { ConditionBuilder, HTMLHeading } from '@varicent/components';
import { useFormikContext } from 'formik';

import TextButton from 'components/Buttons/TextButton/TextButton';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

import { TerritoryOptimizationFormValues } from 'app/components/TerritoryOptimization/TerritoryOptimizationStepper/TerritoryOptimizationStepper';

import { constraintsOperators } from 'app/constants/OptimizationConstraintsOperators';

import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { TerritoryOptimizationStep } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './Constraints.module.pcss';

const b = block(style);

const enum ConditionTarget {
  ACCOUNTS = 'accounts',
  TERRITORIES = 'territories'
}

const constraintsSourceColumnNumberOfAccount = [
  {
    id: '0',
    name: formatMessage('NUMBER_OF_ACCOUNTS'),
    dataType: 'number' as const,
    valueType: 'number' as const
  }
];

interface ConstraintsProps {
  handleBack: (currentStep, prevStep) => void;
}

const Constraints: React.FC<ConstraintsProps> = ({ handleBack }: ConstraintsProps) => {
  const { setFieldValue, values, errors, submitCount, isSubmitting } =
    useFormikContext<TerritoryOptimizationFormValues>();
  const { constraintsSourceColumns } = useTerritoryOptimization();
  const [activeTarget, setActiveTarget] = useState<ConditionTarget>(ConditionTarget.ACCOUNTS);

  return (
    <div className={b('constraintsContainer')} data-testid="territory-optimization-constraints">
      <div className={b('content')}>
        <HTMLHeading bold tagLevel="h5" text={formatMessage('CONSTRAINTS')} />
        <div className={b('constraintsPageDescription')}>{formatMessage('CONSTRAINTS_PAGE_DESCRIPTION')}</div>
        <ToggleSwitch
          onLeftToggle={() => setActiveTarget(ConditionTarget.ACCOUNTS)}
          onRightToggle={() => setActiveTarget(ConditionTarget.TERRITORIES)}
          leftToggleLabel={formatMessage('ACCOUNTS')}
          rightToggleLabel={formatMessage('TERRITORIES')}
          currentSelection={activeTarget === ConditionTarget.TERRITORIES}
        />
        <ConditionBuilder
          className={b('conditionBuilder')}
          conditionsData={
            activeTarget === ConditionTarget.ACCOUNTS
              ? values['constraints']['accounts']
              : values['constraints']['territories']
          }
          onConditionsChange={(data) => {
            if (activeTarget === ConditionTarget.ACCOUNTS) {
              setFieldValue('constraints[accounts]', data);
            } else {
              setFieldValue('constraints[territories]', data);
            }
          }}
          operators={constraintsOperators}
          sourceColumns={
            activeTarget === ConditionTarget.ACCOUNTS
              ? constraintsSourceColumns
              : constraintsSourceColumnNumberOfAccount
          }
          ifAllCombinatorDescriptionText={
            activeTarget === ConditionTarget.ACCOUNTS
              ? formatMessage('IF_ALL_COMBINATOR_DESCRIPTION_TEXT_ACCOUNTS')
              : formatMessage('IF_ALL_COMBINATOR_DESCRIPTION_TEXT_TERRITORIES')
          }
          maxDepth={0}
          combinatorsEditable={false}
        />
        {errors?.constraints && submitCount > 0 && (
          <div className={b('validationMessage')} data-testid="constraint-error-message">
            {formatMessage('CONSTRAINT_FIELD_ERROR')}
          </div>
        )}
      </div>

      <div className={b('footer')}>
        <TextButton
          icon={<ChevronLeft size={20} />}
          text={formatMessage('BACK')}
          type="button"
          large={false}
          testId={'constraints-back-button'}
          onClick={() => handleBack(TerritoryOptimizationStep.CONSTRAINTS, TerritoryOptimizationStep.PARAMETERS)}
          disabled={isSubmitting}
        />
        <TextButton
          icon={<Flash size={20} />}
          text={formatMessage('OPTIMIZE')}
          intent="primary"
          type="submit"
          large={false}
          testId={'constraints-optimize-button'}
          className={b('optimizeBtn')}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </div>
    </div>
  );
};

export default Constraints;
