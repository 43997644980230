import React from 'react';

import { useBeforeunload } from 'react-beforeunload';
import { Prompt, useHistory } from 'react-router-dom';

import { formatMessage } from 'utils/messages/utils';

interface DirtyPromptProps {
  dirty: boolean;
  submitCount: number;
}

const DirtyPrompt: React.FC<DirtyPromptProps> = ({ dirty, submitCount }: DirtyPromptProps) => {
  const history = useHistory();

  // to support tab close and page refresh scenarios, when URL doesn't change
  useBeforeunload((event) => {
    if (dirty && submitCount === 0) {
      event.preventDefault();
      return formatMessage('UNSAVED_CHANGES');
    }
    return null;
  });

  return (
    <Prompt
      data-testid="prompt"
      when={dirty && submitCount === 0}
      message={(locationNext, action) => {
        // Check if the current location and the next are equal. If so, ignore. This prevents some double
        // confirmation alerts.
        if (history.location.key === locationNext.key) {
          return false;
        }
        return JSON.stringify({
          action,
          message: formatMessage('UNSAVED_CHANGES')
        });
      }}
    />
  );
};

export default DirtyPrompt;
