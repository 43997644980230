import React from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { ErrorBoundary } from 'react-error-boundary';

import Fallback from './Fallback';

interface ErrorBoundaryProps {
  children: React.ReactChild;
}

const handleError = (error: unknown) => {
  datadogRum.addError(error, { source: 'boundary' });
};

const GlobalErrorBoundary = ({ children }: ErrorBoundaryProps): JSX.Element => {
  return (
    <ErrorBoundary FallbackComponent={Fallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
