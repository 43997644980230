// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DFruQ {\n    padding-top: 30px;\n  }\n  ._73KLO {\n    display: flex;\n    justify-content: center;\n    min-height: 200px;\n    align-items: center;\n  }\n  .HYg2l {\n    padding: 24px 0;\n  }\n  .wxsbR {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    padding: var(--dialog-padding);\n    background-color: rgba(var(--color-sepia-4), 0.4);\n    border: 1.5px solid rgba(var(--color-sepia-4));\n    border-radius: 8px;\n  }\n  .wxsbR .bp3-icon {\n      color: var(--intent-warning);\n    }\n  ._3jQYm {\n    padding: 2px;\n    margin: 10px 0 0 30px;\n    list-style-position: outside;\n  }\n  .T77d6 {\n    margin-left: 10px;\n  }\n  .oLM0F {\n    padding-left: 5px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImportQuotasFileUpload_linkContainer": "DFruQ",
	"ImportQuotasFileUpload_spinnerContainer": "_73KLO",
	"ImportQuotasFileUpload_fileUploadContainer": "HYg2l",
	"ImportQuotasFileUpload_quotaFileUploadWarningContainer": "wxsbR",
	"ImportQuotasFileUpload_listParent": "_3jQYm",
	"ImportQuotasFileUpload_listItem": "T77d6",
	"ImportQuotasFileUpload_warningNote": "oLM0F"
};
export default ___CSS_LOADER_EXPORT___;
