import { Dispatch, SetStateAction, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { IntegrationTabIds } from 'app/containers/Symon/SymonTypes';

import useQueryParamState from 'app/hooks/useQueryParamState';

export const integrationTabQueryParamKey = 'integrations-tab';
const defaultTab = IntegrationTabIds.SYMON_IMPORT;

const useIntegrationTabQueryParamState = (): [IntegrationTabIds, Dispatch<SetStateAction<IntegrationTabIds>>] => {
  const history = useHistory();

  useEffect(() => {
    {
      const params = new URLSearchParams(history.location.search);
      if (!params.has(integrationTabQueryParamKey)) {
        params.set(integrationTabQueryParamKey, defaultTab);
        history.replace({ ...history.location, search: params.toString() });
      }
    }
    return () => {
      const params = new URLSearchParams(history.location.search);
      if (params.has(integrationTabQueryParamKey)) {
        params.delete(integrationTabQueryParamKey);
        history.replace({ ...history.location, search: params.toString() });
      }
    };
  }, []);

  const [state, setState] = useQueryParamState<IntegrationTabIds>(integrationTabQueryParamKey);

  return [state ?? defaultTab, setState];
};

export default useIntegrationTabQueryParamState;
