import React, { useState } from 'react';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { SearchableSelectMenuItem } from 'components/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './InfiniteScroll.module.pcss';

const b = block(style);
export interface InfiniteScrollProps {
  onFetchMore: (startRow: number, endRow: number) => void;
  loadingRenderer?: (style) => JSX.Element | string;
  contentRenderer?: (
    item: Record<string, string> | SearchableSelectMenuItem,
    index: number,
    style
  ) => JSX.Element | string;
  totalRows: number;
  className?: string;
  items?: Record<string, string>[] | SearchableSelectMenuItem[];
  pageSize: number;
  isLoading: boolean;
  showRows?: number;
  staticItemsCount?: number;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  totalRows,
  pageSize,
  loadingRenderer,
  contentRenderer,
  className,
  onFetchMore,
  items,
  isLoading,
  showRows,
  staticItemsCount = 0
}) => {
  const [lastStartedRow, setLastStartedRow] = useState(0);
  const isItemLoaded = (index) => {
    return !!items && !!items[index];
  };
  const loadMoreItems = async (startRow: number, endRow: number) => {
    if (!isLoading && startRow > lastStartedRow) {
      setLastStartedRow(startRow);

      const adjustedStartRow = Math.max(startRow + 1 - staticItemsCount, 1);
      const adjustedEndRow = Math.max(endRow + 1 - staticItemsCount, 1);
      onFetchMore(adjustedStartRow, adjustedEndRow);
    }
  };

  // Check if there are more pages to load, adjusting for hardcoded items
  const actualItemsLength = items?.length - staticItemsCount;
  const hasNextPage = actualItemsLength < totalRows;
  const adjustedTotalRows = totalRows + staticItemsCount;
  let itemCount;

  if (hasNextPage && actualItemsLength + pageSize < totalRows) {
    // items left are more than one page - load full page
    itemCount = items?.length + pageSize;
  } else if (hasNextPage) {
    // items left are less than one page - load till we reach totalRows
    itemCount = adjustedTotalRows;
  } else {
    itemCount = items?.length; // no items left - stop loading
  }

  let height;
  // if a specified number of rows is provided, show that many rows
  if (showRows) {
    height = Math.min(showRows, adjustedTotalRows) * 35;
  } else {
    const viewPortHeight = window.innerHeight;
    height = Math.min(viewPortHeight * 0.2, adjustedTotalRows * 35);
  }

  return (
    <div className={`${className} ${b()}`} style={{ height }}>
      {items?.length === 0 && <div>{formatMessage('NO_RESULTS')}</div>}
      {items?.length > 0 && (
        <AutoSizer>
          {({ height, width }) => {
            return (
              <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
                {({ onItemsRendered, ref }) => {
                  return (
                    <div>
                      <List
                        height={height}
                        itemCount={itemCount}
                        itemSize={35} // this is the row height
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        width={width}
                      >
                        {({ index, style }) => {
                          let content;
                          if (!isItemLoaded(index)) {
                            content = loadingRenderer(style);
                          } else {
                            content = contentRenderer(items[index], index, style);
                          }
                          return content;
                        }}
                      </List>
                    </div>
                  );
                }}
              </InfiniteLoader>
            );
          }}
        </AutoSizer>
      )}
    </div>
  );
};

export default InfiniteScroll;
