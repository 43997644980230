import React, { useEffect, useState } from 'react';

import { AddAlt, ChevronDown, Information } from '@carbon/icons-react';
import { FieldProps } from 'formik';
import { parse } from 'mathjs';
import { useQuill } from 'react-quilljs';

import IconButton from 'components/Buttons/IconButton/IconButton';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import OperatorSelectMenu from 'components/FormulaEditor/OperatorSelectMenu/OperatorSelectMenu';
import { OperatorMap } from 'components/models';

import { MathJSErrorMap } from 'app/models';
import 'quill/dist/quill.snow.css';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './FormulaEditor.module.pcss';

const b = block(style);

interface FormulaEditorProps extends FieldProps {
  columns: string[];
  isEditable?: boolean;
}

const enum Operator {
  LEFT_PARENTHESIS = '(',
  RIGHT_PARENTHESIS = ')',
  LEFT_CURLY_BRACKET = '{',
  RIGHT_CURLY_BRACKET = '}'
}

const FormulaEditor: React.FC<FormulaEditorProps> = ({
  field: { name },
  form: { setFieldValue, values, touched, setFieldError, errors },
  columns,
  isEditable
}: FormulaEditorProps) => {
  const modules = {
    toolbar: false
  };
  const { quill, quillRef } = useQuill({ modules, readOnly: !isEditable });
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  useEffect(() => {
    // If Add button is clicked, the calculatedFieldFormula field will set to touched, then check if the formula is valid.
    if (!errors?.[name] && touched[name] && values[name]?.trim() === '') {
      setFieldError(name, formatMessage('FORMULA_REQUIRED'));
    }
  }, [touched]);

  useEffect(() => {
    if (values[name]?.length > 0 && quill) {
      quill.setText(values[name]);
      highLightText(values[name]);
    }
  }, [quill]);

  const highLightText = (text: string) => {
    let startIndex = 0;
    text.split('').forEach((char, index) => {
      if (char === Operator.LEFT_CURLY_BRACKET) {
        startIndex = index + 1;
      } else if (char === Operator.RIGHT_CURLY_BRACKET) {
        const sourceNodeText = text.slice(startIndex, index);
        if (columns.includes(sourceNodeText)) {
          quill.formatText(startIndex - 1, index - startIndex + 2, {
            color: '#2B4FF4'
          });
        }
      }
    });
  };

  const insertText = (text: string) => {
    if (!quill.hasFocus()) {
      quill.focus();
    }
    const { index } = quill.getSelection();

    const isParenthesis = text === '()';
    const formatText = isParenthesis ? '(  ) ' : `${text} `;
    const isSourceNode = columns.includes(text.slice(1, -1));

    quill.insertText(index, formatText);

    if (isSourceNode) {
      quill.formatText(index, text.length, {
        color: '#2B4FF4'
      });
    }

    if (isParenthesis) {
      quill.setSelection(index + 2, 0);
    }

    if (!quill.hasFocus()) {
      quill.focus();
    }
  };

  const checkTextBeforeCursor = (textBeforeCursor: string) => {
    return {
      hasValidLeftBracketBeforeCursor:
        textBeforeCursor.lastIndexOf(Operator.LEFT_CURLY_BRACKET) >
        textBeforeCursor.lastIndexOf(Operator.RIGHT_CURLY_BRACKET),
      leftBracketIndex: textBeforeCursor.lastIndexOf(Operator.LEFT_CURLY_BRACKET)
    };
  };

  const checkTextAfterCursor = (textAfterCursor: string) => {
    return {
      hasValidRightBracketAfterCursor:
        textAfterCursor.indexOf(Operator.RIGHT_CURLY_BRACKET) >= 0 &&
        (textAfterCursor.indexOf(Operator.RIGHT_CURLY_BRACKET) < textAfterCursor.indexOf(Operator.LEFT_CURLY_BRACKET) ||
          textAfterCursor.indexOf(Operator.LEFT_CURLY_BRACKET) < 0),
      rightBracketIndex: textAfterCursor.indexOf(Operator.RIGHT_CURLY_BRACKET)
    };
  };

  const getErrorMessage = (errMessage, errIndex, formulaText) => {
    let displayedErrorMessage = '';
    const text = formulaText.trim();

    if (
      errMessage.includes(MathJSErrorMap.UNEXPECTED_END_OF_EXPRESSION) ||
      errMessage.includes(MathJSErrorMap.VALUE_EXPECTED)
    ) {
      let char = text[errIndex - 2];
      let isBefore = false;

      // If the error occurs before an operator, the char would be space or undefined (when it is the first char in the text)
      // Then we get the char after that to show that in the error message
      if (char === ' ' || char === undefined) {
        char = text[errIndex - 1];

        // mathjs returns the index of the right parenthesis when some error occurs inside a pair of parenthesis
        // so we have to manually find the real index of error
        if (char === Operator.RIGHT_PARENTHESIS) {
          const operatorList = ['+', '-', '*', '/', '.', '('];
          const textBeforeErrorIndex = text.slice(0, errIndex).replace(/\)/g, '').trim();
          const lastValidChar = textBeforeErrorIndex[textBeforeErrorIndex.length - 1];

          if (operatorList.includes(lastValidChar)) {
            char = lastValidChar;
          }
        } else {
          isBefore = true;
        }
      }
      //case 1: no value before/after an operator
      const isParenthesis = char === Operator.LEFT_PARENTHESIS || char === Operator.RIGHT_PARENTHESIS;

      if (isParenthesis) {
        const operator = OperatorMap[Operator.LEFT_PARENTHESIS]?.key.toLowerCase();
        displayedErrorMessage = isBefore
          ? formatMessage('FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE', { operator })
          : formatMessage('FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER', { operator });
      } else {
        const operator = OperatorMap[char]?.key.toLowerCase() ?? 'undefined';
        displayedErrorMessage = isBefore
          ? formatMessage('FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE_FULL', { operator, text: char })
          : formatMessage('FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER_FULL', { operator, text: char });
      }
    } else if (errMessage.includes(MathJSErrorMap.RIGHT_PARENTHESIS_EXPECTED)) {
      // case 2: no closing brackets
      displayedErrorMessage = formatMessage('MISSING_CLOSED_PARENTHESIS_ERROR');
    } else if (errMessage.includes(MathJSErrorMap.END_OF_STRING_DOUBLE_QUOTES_EXPECTED)) {
      // case 3: '{' is entered but no matching '}'
      displayedErrorMessage = formatMessage('INVALID_DATA_SOURCE_ERROR');
    } else if (errMessage.includes(MathJSErrorMap.UNEXPECTED_PART)) {
      // case 4: no operator between two values (the second one is number)
      const value = text
        .slice(errIndex - 1)
        .trim()
        .split(' ')[0];
      displayedErrorMessage = formatMessage('UNEXPECTED_OPERAND_NO_OPERATOR_ERROR', { value });
    } else if (errMessage.includes(MathJSErrorMap.UNEXPECTED_OPERATOR)) {
      // case 5: no operator between two values (the second one is source text)
      const data = text.slice(errIndex - 1).match(/\{([^}]+)\}/g)?.[0];
      displayedErrorMessage = formatMessage('UNEXPECTED_OPERAND_NO_OPERATOR_ERROR', { value: data });
    } else {
      // show original error message from math js directly
      displayedErrorMessage = errMessage;
    }

    return displayedErrorMessage;
  };

  const validateFormula = (text) => {
    // get all the source nodes wrapped in {} and check if all of them are in the data source list
    const sourceNodes = text.match(/\{([^}]+)\}/g);

    sourceNodes?.forEach((sourceNode) => {
      if (!columns.includes(sourceNode.slice(1, -1))) {
        return setFieldError(name, formatMessage('INVALID_DATA_SOURCE_ERROR'));
      }
    });

    // check multiple +/- since it's allowed in mathjs
    const textWithoutSpaces = text.replace(/ /g, '');

    textWithoutSpaces.split('').forEach((_char, index) => {
      const operatorList = ['+', '-', '*', '/', '.'];
      const currentCharIsOperator = operatorList.includes(textWithoutSpaces[index]);
      const nextCharIsPlusOrMinus = textWithoutSpaces[index + 1] === '+' || textWithoutSpaces[index + 1] === '-';
      const noNumberAfterDot =
        textWithoutSpaces[index] === '.' &&
        !(textWithoutSpaces[index + 1] >= '0' && textWithoutSpaces[index + 1] <= '9');
      const operatorError = formatMessage('FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER_FULL', {
        operator: OperatorMap[textWithoutSpaces[index]]?.key.toLowerCase(),
        text: textWithoutSpaces[index]
      });
      if ((currentCharIsOperator && nextCharIsPlusOrMinus) || noNumberAfterDot) {
        return setFieldError(name, operatorError);
      }

      operatorList.pop();
      operatorList.push(Operator.LEFT_PARENTHESIS);
      const noOperaterBeforeLeftParenthsis =
        textWithoutSpaces[index] === Operator.LEFT_PARENTHESIS &&
        !operatorList.includes(textWithoutSpaces[index - 1]) &&
        index > 0;
      if (noOperaterBeforeLeftParenthsis) {
        const noOperaterBeforeLeftParenthsisError = formatMessage('FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE', {
          operator: OperatorMap[Operator.LEFT_PARENTHESIS]?.key.toLowerCase()
        });
        return setFieldError(name, noOperaterBeforeLeftParenthsisError);
      }

      operatorList.pop();
      operatorList.push(Operator.RIGHT_PARENTHESIS);
      const noOperaterAfterRightParenthesis =
        index !== textWithoutSpaces.length - 2 &&
        textWithoutSpaces[index] === Operator.RIGHT_PARENTHESIS &&
        !operatorList.includes(textWithoutSpaces[index + 1]);
      if (noOperaterAfterRightParenthesis) {
        const noOperaterAfterRightParenthsisError = formatMessage('FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER', {
          operator: OperatorMap[Operator.RIGHT_PARENTHESIS]?.key.toLowerCase()
        });
        return setFieldError(name, noOperaterAfterRightParenthsisError);
      }
    });
  };

  const isValidCharacter = (char) => {
    const isNumber = char >= '0' && char <= '9';
    const validOperator = ['+', '-', '*', '/', '(', ')', ' ', '.'];
    const isValidOperator = validOperator.includes(char);
    return isNumber || isValidOperator;
  };

  useEffect(() => {
    if (quill) {
      // Do the following operations when a text change is detected:
      // 1. check the inserted value is valid
      // 2. handle deleting source node text
      // 3. validate
      quill.on('text-change', (delta, oldDelta) => {
        const text = quill.getText();
        const currentCursorPosition = quill.getSelection()?.index;
        const textBeforeCursor = text.slice(0, currentCursorPosition);
        const { leftBracketIndex } = checkTextBeforeCursor(textBeforeCursor);

        const diffWithInsert = oldDelta.diff(quill.getContents());
        const diffWithDelete = quill.getContents().diff(oldDelta);

        setFieldValue(name, text, false);
        setFieldError(name, '');

        // If the editor is empty (length), reset the format
        if (quill.getLength() === 1) {
          setFieldError(name, formatMessage('FORMULA_REQUIRED'));
        }

        // Step 1: disallow user to type characters other than numbers and valid operators
        // the value insert can be in ops[0] or ops[1] depends on whether it's the first char
        const isInsertChar = diffWithInsert.ops[0]?.insert?.length === 1 || diffWithInsert.ops[1]?.insert?.length === 1;
        if (isInsertChar && !isValidCharacter(diffWithInsert.ops[0]?.insert || diffWithInsert.ops[1]?.insert)) {
          quill.deleteText(currentCursorPosition - 1, 1);
          setTimeout(() => quill.setSelection(currentCursorPosition - 1));
        } else if (delta.ops[1]?.delete === 1 && diffWithDelete.ops[1]?.insert === Operator.RIGHT_CURLY_BRACKET) {
          // Step 2: when user deletes '}', delete the entire source node if it is valid
          const textInsideCursor = text.slice(leftBracketIndex + 1, currentCursorPosition);
          if (columns.includes(textInsideCursor)) {
            quill.deleteText(leftBracketIndex, textInsideCursor.length + 1);
            setTimeout(() => quill.setSelection(currentCursorPosition - textInsideCursor.length - 1));
            return;
          }
        } else {
          // Step 3: validate operators with mathjs
          try {
            // When parsing with mathjs, replace all curly brackets with double quotes because mathjs treats curly brackets as object and requires colon inside it
            parse(text.trim().replace(/[{}]/g, '"'));
          } catch (err) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const errMsg = getErrorMessage(err.message, err.char, text);
            // Translate the error messages from mathjs into our own messages
            setFieldError(name, errMsg);
          }

          // Step 4: more validations
          validateFormula(text);
        }
      });

      // Check if the cursor is placed inside a valid source node text when a selection change is detected
      quill.on('selection-change', (range, oldRange) => {
        if (!range) {
          return;
        }

        const text = quill.getText();
        const pos = range.index;
        const textBeforeCursor = text.slice(0, pos);
        const textAfterCursor = text.slice(pos);
        const { hasValidLeftBracketBeforeCursor, leftBracketIndex } = checkTextBeforeCursor(textBeforeCursor);
        const { hasValidRightBracketAfterCursor, rightBracketIndex } = checkTextAfterCursor(textAfterCursor);

        // Disallow user to set the cursor inside a valid source node text
        if (hasValidLeftBracketBeforeCursor && hasValidRightBracketAfterCursor) {
          const textInsideCursor = text.slice(leftBracketIndex + 1, rightBracketIndex + pos);
          if (columns.includes(textInsideCursor)) {
            quill.setSelection(oldRange?.index === range.index + 1 ? leftBracketIndex : rightBracketIndex + pos + 1);
          }
        }
      });
    }
  }, [quill]);

  return (
    <div className={b()} data-testid="formula-editor">
      <div className={b('formulaEditorHeader', { disabled: !isEditable })}>
        <div className={b('dropdownButtons')}>
          <OperatorSelectMenu
            disabled={!isEditable}
            onSelect={(item) => insertText(item.value)}
            data-testid="formula-editor-operator-dropdown"
          />
          <CustomSelect
            data-testid="formula-editor-source-dropdown"
            value={{ key: 'Data source', value: 'Data source' }}
            items={columns.map((col) => ({
              key: col,
              value: col
            }))}
            onChange={(item) => {
              insertText(`{${item.value}}`);
            }}
            icon={<AddAlt />}
            rightIcon={<ChevronDown />}
            disabled={!isEditable}
          />
        </div>
        <div className={b('tooltip')}>
          <IconButton
            type={'button'}
            icon={<Information tabIndex="0" />}
            onClick={() => setTooltipOpen(!tooltipOpen)}
            testId={'info-icon'}
            disabled={!isEditable}
            tooltipText={
              <div className={b('tooltipContent')}>
                {formatMessage('FORMULA_EDITOR_TOOLTIP_MESSAGE')}
                <br />
                {formatMessage('CALCULATED_FIELD_EXAMPLE')}
              </div>
            }
            tooltipPlacement="top"
          />
        </div>
      </div>
      <div
        className={b('formulaEditArea', { disabled: !isEditable })}
        ref={quillRef}
        data-testid="formula-editor-text-area"
      />
      {errors[name] && (
        <div className={b('errorMessage')} data-testid="formula-editor-error">
          {errors[name]}
        </div>
      )}
    </div>
  );
};

export default FormulaEditor;
