import * as Yup from 'yup';

import { MeasureFieldType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const validationSchema = (isDisallowMeasureNameWithUnderscoreOn: boolean) => [
  Yup.object().shape({
    measureName: Yup.lazy((value) => {
      if (typeof value === 'object') {
        return Yup.object().required(formatMessage('SELECT_MEASURE_NAME_REQUIRED'));
      }
      return isDisallowMeasureNameWithUnderscoreOn
        ? Yup.string()
            .required(formatMessage('SELECT_MEASURE_NAME_REQUIRED'))
            .matches(/^[^.'"{}_,]+$/, formatMessage('MEASURE_NAME_VALIDATION_MESSAGE_WITH_UNDERSCORE'))
        : Yup.string()
            .required(formatMessage('SELECT_MEASURE_NAME_REQUIRED'))
            .matches(/^[^.'"{},]+$/, formatMessage('MEASURE_NAME_VALIDATION_MESSAGE'));
    }),
    measureFieldType: Yup.object().nullable().required(formatMessage('MEASURE_TYPE_REQUIRED')),
    measureFormatType: Yup.object().nullable().required(formatMessage('MEASURE_FORMAT_REQUIRED'))
  }),
  Yup.object().shape({
    measureName: Yup.string().required(formatMessage('MEASURE_NAME_REQUIRED')),
    measureSource: Yup.object()
      .nullable()
      .when('measureFieldType', (measureFieldType) => {
        if (measureFieldType?.value === MeasureFieldType.LOADED) {
          return Yup.object().nullable().required(formatMessage('MEASURE_SOURCE_REQUIRED'));
        }
        return Yup.object().nullable().notRequired();
      }),
    fieldId: Yup.object()
      .nullable()
      .when('measureFieldType', (measureFieldType) => {
        if (measureFieldType?.value === MeasureFieldType.LOADED) {
          return Yup.object().nullable().required(formatMessage('FIELD_ID_REQUIRED'));
        }
        return Yup.object().nullable().notRequired();
      }),
    fieldName: Yup.object()
      .nullable()
      .when('measureFieldType', (measureFieldType) => {
        if (measureFieldType?.value === MeasureFieldType.LOADED) {
          return Yup.object().nullable().required(formatMessage('FIELD_NAME_REQUIRED'));
        }
        return Yup.object().nullable().notRequired();
      }),
    calculatedFieldFormula: Yup.string().when('measureFieldType', (measureFieldType) => {
      if (measureFieldType?.value === MeasureFieldType.CALCULATED) {
        return Yup.string().required(formatMessage('FORMULA_REQUIRED'));
      }
      return Yup.string().notRequired();
    })
  })
];

export default validationSchema;
