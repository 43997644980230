// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OQa3m {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  padding-left: 8px;\n  grid-gap: 9px;\n  gap: 9px;\n}\n\n  .\\+j1l- {\n    color: rgb(var(--color-light-gray-3));\n  }\n\n  .MbTuV {\n    /* Fixes vertical alignment of icons within the cell */\n    display: flex !important;\n  }\n\n  .OB9in {\n    color: var(--intent-warning);\n  }\n\n  .QPCX5 {\n    cursor: not-allowed;\n  }\n\n  .sX8x6 {\n    width: 16px;\n    display: flex;\n    justify-content: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryMapOmniCellRenderer": "OQa3m",
	"TerritoryMapOmniCellRenderer__isIgnored": "+j1l-",
	"TerritoryMapOmniCellRenderer_iconTooltip": "MbTuV",
	"TerritoryMapOmniCellRenderer_overAssignedIcon": "OB9in",
	"TerritoryMapOmniCellRenderer_nonMappableIcon": "QPCX5",
	"TerritoryMapOmniCellRenderer_colorWrapper": "sX8x6"
};
export default ___CSS_LOADER_EXPORT___;
