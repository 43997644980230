// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bVJzr .bp3-dialog {\n      border-radius: 12px;\n      width: 550px;\n    }\n  .YXhpe {\n    margin: var(--m) 0;\n  }\n  .i4aHv {\n    padding: 0 var(--m);\n  }\n  ._9PBkj {\n    margin-bottom: var(--m);\n  }\n  .X5EZD {\n    margin-top: var(--xs);\n  }\n  ._9613R {\n    display: inline-flex;\n    flex-direction: column;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n  }\n  ._9P68Y {\n    display: inline-flex;\n    align-items: center;\n    font-weight: 400;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n  }\n  ._5UzTV {\n    font-size: 16px;\n  }\n  .M9FrY {\n    font-weight: bold;\n    margin-left: var(--xs);\n  }\n  ._9xRxv {\n    font-weight: 400;\n    display: block;\n    margin: var(--m) 26px 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapRuleSettingsDialog_dialog": "bVJzr",
	"MapRuleSettingsDialog_dialogExplanation": "YXhpe",
	"MapRuleSettingsDialog_formElements": "i4aHv",
	"MapRuleSettingsDialog_radioGroup": "_9PBkj",
	"MapRuleSettingsDialog_radio": "X5EZD",
	"MapRuleSettingsDialog_radioLabel": "_9613R",
	"MapRuleSettingsDialog_radioDescription": "_9P68Y",
	"MapRuleSettingsDialog_radioTitle": "_5UzTV",
	"MapRuleSettingsDialog_defaultText": "M9FrY",
	"MapRuleSettingsDialog_hierarchyLabel": "_9xRxv"
};
export default ___CSS_LOADER_EXPORT___;
