import * as yup from 'yup';

import { formatMessage } from 'utils/messages/utils';

export default yup.object().shape({
  hierarchyName: yup.string().max(256).required(formatMessage('ENTER_HIERARCHY_NAME')),
  attributes: yup.array().of(
    yup.object().shape({
      name: yup.string().required(formatMessage('ATTRIBUTE_NAME_MESSAGE'))
    })
  )
});
