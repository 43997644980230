import React, { useEffect, useRef } from 'react';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import CurrencyCellEditor from 'app/components/AdvancedGrid/CellEditors/CurrencyCellEditor/CurrencyCellEditor';
import { getPaginationCheck } from 'app/components/AdvancedGrid/GridHelper';
import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';

import { ACCOUNT_QUOTA_BLOCK_SIZE } from 'app/global/variables';

import { useGetAccountRuleBindingsForQuotaDrillInLazy } from 'app/graphql/queries/getAccountRuleBindingsForQuotaDrillIn';

import { SelectedAccountQuotaDrillInCell } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AccountQuotaDrillIn.module.pcss';
import buildAccountQuotaDrillinColumnDefs from './AccountQuotaDrillinColumnDefs';
import { formatBindingsAsDrillInRows } from './accountQuotaDrillinHelpers';
import { useUpsertAccountQuotaMeasureValue } from './useUpsertAccountQuotaMeasureValue';

const b = block(style);

const AccountQuotaDrillIn: React.FC = () => {
  const { battleCardLookupMap, selectedBattleCardId, selectedQuotaComponentId } = useBattleCard();
  const { defaultReportingCurrency } = useLocalization();
  const { selectedQuotaDrillInTerritory } = useGrid();

  const selectedCell = useRef<SelectedAccountQuotaDrillInCell>(null);

  const { territoryGroupId, ruleId } = selectedQuotaDrillInTerritory;
  const battleCardLocalCurrencyCode = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;

  const containerRef = useRef(null);

  const currency = battleCardLocalCurrencyCode || defaultReportingCurrency;

  const getAccountRuleBindingsInput = {
    territoryGroupId,
    quotaComponentId: selectedQuotaComponentId,
    startRow: 1,
    endRow: ACCOUNT_QUOTA_BLOCK_SIZE,
    filters: JSON.stringify({
      ruleId: { filterType: 'number', type: 'equals', filter: ruleId }
    })
  };

  const [getAccountRuleBindings, { data: accountRuleBindings, loading, fetchMore }] =
    useGetAccountRuleBindingsForQuotaDrillInLazy({
      fetchPolicy: 'network-only',
      variables: {
        input: getAccountRuleBindingsInput
      }
    });

  const { fieldId, handleUpsertFieldValues } = useUpsertAccountQuotaMeasureValue(getAccountRuleBindings);

  useEffect(() => {
    getAccountRuleBindings();
  }, []);

  const totalCount = accountRuleBindings?.getAccountRuleBindings?.totalCount || 0;
  const accountQuota = accountRuleBindings?.getAccountRuleBindings?.measureTotals?.[0]?.measureTotal || 0;

  const handleModelUpdate = (gridEvent) => {
    gridEvent.api.setPinnedBottomRowData([
      {
        accountKey:
          totalCount === 1
            ? formatMessage('ACCOUNT_WITH_COUNT', { totalCount })
            : formatMessage('ACCOUNTS_WITH_COUNT', { totalCount }),
        accountQuota
      }
    ]);
  };

  const accountQuotaDrillInProps = {
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial' as const,
    cacheBlockSize: ACCOUNT_QUOTA_BLOCK_SIZE,
    infiniteInitialRowCount: totalCount,
    suppressMenuHide: true,
    onModelUpdated: handleModelUpdate,
    serverSideDatasource: {
      rowCount: totalCount,
      getRows: async (params) => {
        const { startRow, endRow } = params?.request;

        if (endRow === ACCOUNT_QUOTA_BLOCK_SIZE) {
          params?.successCallback(formatBindingsAsDrillInRows(accountRuleBindings, fieldId), totalCount);
        } else if (getPaginationCheck(startRow, totalCount)) {
          const { data: fetchedBindings } = await fetchMore({
            variables: { input: { ...getAccountRuleBindingsInput, startRow: startRow + 1, endRow } }
          });
          params?.successCallback(formatBindingsAsDrillInRows(fetchedBindings, fieldId), totalCount);
        }
      }
    }
  };

  const onCellClick = ({ accountId, measureId }) => {
    selectedCell.current = { accountId, measureId };
  };

  const handleOnBlur = (measureValue: string) => {
    handleUpsertFieldValues({
      accountId: selectedCell.current.accountId,
      measureId: selectedCell.current.measureId,
      measureValue
    });
  };

  return (
    <div className={b('accountQuotaDrillInWrapper')} data-testid="account-quota-drill-in" ref={containerRef}>
      {totalCount && fieldId && !loading ? (
        <AdvancedGrid
          gridProps={accountQuotaDrillInProps}
          suppressCellSelection={true}
          frameworkComponents={{
            currencyCellEditor: CurrencyCellEditor
          }}
          columnDefs={buildAccountQuotaDrillinColumnDefs(currency, handleOnBlur, onCellClick)}
          gridWidth={containerRef?.current?.offsetWidth}
          gridHeight={containerRef?.current?.offsetHeight}
        />
      ) : (
        <>
          {loading ? (
            <div data-testid="account-drill-in-loading">
              <GridLoading
                gridHeight={containerRef?.current?.offsetHeight}
                gridWidth={containerRef?.current?.offsetWidth}
              />
            </div>
          ) : (
            <div className={b('gridOverlayContainer')} data-testid="no-data-overlay">
              <div className={b('gridOverlayText')}>{formatMessage('EMPTY_GRID')}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AccountQuotaDrillIn;
