// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sDjQX {\n  display: flex;\n  visibility: hidden;\n  width: 28px;\n  height: 28px;\n}\n\n  .iF9ri {\n    visibility: visible;\n  }\n\n  .ag-cell:not(:hover) .KhmWF {\n    color: rgb(var(--color-light-gray-3)) !important;\n  }\n\n  .ag-cell:hover .sDjQX {\n    visibility: visible;\n  }\n\n  ._1ohR8 {\n    display: unset;\n    margin-right: 0 !important;\n  }\n\n  .ag-cell:hover ._1ohR8 {\n    display: none;\n  }\n\n  .NJQDL {\n    display: none;\n  }\n\n  .ag-cell:hover .NJQDL {\n    display: unset;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapLockCellRenderer": "sDjQX",
	"MapLockCellRenderer__alwaysShow": "iF9ri",
	"MapLockCellRenderer__isIgnored": "KhmWF",
	"MapLockCellRenderer_biDotIcon": "_1ohR8",
	"MapLockCellRenderer_biLockedIcon": "NJQDL"
};
export default ___CSS_LOADER_EXPORT___;
