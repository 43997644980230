import gql from 'graphql-tag';

export const WRITE_FILE_TO_DB = gql`
  mutation WriteFileToDB($input: WriteFileToDBInput!) {
    writeFileToDB(input: $input) {
      fileId
      status
      message
    }
  }
`;
