import { gql } from '@apollo/client';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetTerritoryRulesForRebalancing,
  GetTerritoryRulesForRebalancingVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_TERRITORY_RULES_FOR_REBALANCING = gql`
  query GetTerritoryRulesForRebalancing($input: GetTerritoryRulesInput) {
    getTerritoryRules(input: $input) {
      territoryRules {
        effectiveDate
        endDate
        measureGap
        measureValue
        numberOfAccounts
        numberOfAccountsGap
        ruleId
        territoryId
        territoryName
        territoryGroupId
        territoryGroupName
      }
      totalCount
      totalAccountsCount
      totalAccountsMean
      measureValueMean
      measureValueStandardDeviation
    }
  }
`;

export const useGetTerritoryRulesForRebalancingLazy = createUseLazyQuery<
  GetTerritoryRulesForRebalancing,
  GetTerritoryRulesForRebalancingVariables
>(GET_TERRITORY_RULES_FOR_REBALANCING);
