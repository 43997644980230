import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { MapboxMap } from 'react-map-gl';

import { lassoStyle } from 'app/components/TerritoryMap/MapStyleTokens';

import { LassoPolygon } from 'app/models';

const getModeName = (isEnabled: boolean): MapboxDraw.DrawMode => (isEnabled ? 'draw_polygon' : 'simple_select');

interface MapDrawPermissive extends MapboxDraw {
  changeMode(mode: MapboxDraw.DrawMode): this;
}

type DrawRef = MutableRefObject<MapDrawPermissive>;

export const useMapDraw = (
  mapboxMap: MapboxMap | null,
  isLassoEnabled: boolean,
  onLassoCompleted: (lassoPolygon: LassoPolygon) => void
): {
  cancelLasso: () => void;
  isDrawing: boolean;
} => {
  const drawRef = useCreateMapDraw(mapboxMap);

  // Sets the desired mode when it changes
  useEffect(() => {
    const desiredMode = getModeName(isLassoEnabled);
    drawRef.current?.changeMode(desiredMode);
  }, [isLassoEnabled]);

  // Tracks the enabled state for use in stable callbacks
  const isLassoEnabledRef = useRef(isLassoEnabled);
  useEffect(() => {
    isLassoEnabledRef.current = isLassoEnabled;
  });

  usePreventAutomaticModeChanges(mapboxMap, drawRef, isLassoEnabledRef);

  const cancelLasso = useCallback(() => {
    drawRef.current?.deleteAll();
    // Required to allow the next-click after a right click to begin drawing
    drawRef.current?.changeMode(getModeName(isLassoEnabledRef.current));
  }, []);

  const isDrawing = useIsDrawing(mapboxMap, drawRef);
  useOnLassoCompleted(mapboxMap, drawRef, onLassoCompleted);

  return { isDrawing, cancelLasso };
};

const useCreateMapDraw = (mapboxMap: MapboxMap | null) => {
  const drawRef = useRef<MapDrawPermissive | null>(null);
  useEffect(() => {
    if (!mapboxMap) return;
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      styles: lassoStyle
    });
    mapboxMap.addControl(draw);
    drawRef.current = draw;
  }, [mapboxMap]);
  return drawRef;
};

const usePreventAutomaticModeChanges = (
  mapboxMap: MapboxMap | null,
  drawRef: DrawRef,
  isLassoEnabledRef: MutableRefObject<boolean>
) => {
  const handleModeChange = useCallback(({ mode }: { mode: string }) => {
    const desiredMode = getModeName(isLassoEnabledRef.current);
    if (mode !== desiredMode) {
      drawRef.current?.changeMode(desiredMode);
    }
  }, []);
  useOnMapboxEvent(mapboxMap, 'draw.modechange', handleModeChange);
};

const useIsDrawing = (mapboxMap: MapboxMap | null, drawRef: DrawRef) => {
  const [isDrawing, setIsDrawing] = useState(isMidDraw(drawRef.current));

  const handleDrawRender = useCallback(() => {
    setIsDrawing(isMidDraw(drawRef.current));
  }, []);
  useOnMapboxEvent(mapboxMap, 'draw.render', handleDrawRender);

  return isDrawing;
};

const useOnLassoCompleted = (
  mapboxMap: MapboxMap,
  drawRef: DrawRef,
  onLassoCompleted: (lassoPolygon: LassoPolygon) => void
) => {
  const handleCreate = useCallback(
    (event: { features: LassoPolygon[] }) => {
      const lassoFeature = event.features[0];
      drawRef.current?.deleteAll();
      onLassoCompleted(lassoFeature);
    },
    [onLassoCompleted]
  );
  useOnMapboxEvent(mapboxMap, 'draw.create', handleCreate);
};

const noCleanup = () => {
  // There is no cleanup to run, but we must still return a callback to ensure React still invokes future cleanup
};
const useOnMapboxEvent = <T>(mapboxMap: MapboxMap, eventName: string, handler: (event: T) => void) => {
  useEffect(() => {
    if (!mapboxMap) return noCleanup;
    mapboxMap.on(eventName, handler);
    return () => {
      mapboxMap.off(eventName, handler);
    };
  }, [mapboxMap, handler]);
};

const isMidDraw = (draw: MapboxDraw | null): boolean => {
  if (!draw) return false;
  const lassoFeature = draw.getAll()?.features?.[0];
  if (!lassoFeature || lassoFeature.geometry.type !== 'Polygon') return false;
  const { coordinates } = lassoFeature.geometry;
  return coordinates.length > 1 || coordinates[0].length > 2;
};
