import type { LinePaint, FillPaint } from 'mapbox-gl';

import { MapStyleTokens } from 'app/components/TerritoryMap/MapStyleTokens';

import { MapboxExpression, MapCountries, SourceGroup } from 'app/models';

const isSelectedExpression = ['to-boolean', ['feature-state', 'isSelected']];
const isAssignedExpression = ['to-boolean', ['feature-state', 'ruleId']];
const isOverAssignedGeoExpression = ['to-boolean', ['feature-state', 'isOverAssignedGeo']];
const isOverAssignedRuleExpression = ['to-boolean', ['feature-state', 'isOverAssignedRule']];
const isRuleIgnoredExpression = ['to-boolean', ['feature-state', 'isRuleIgnored']];

export const backgroundFillPaint: FillPaint = {
  'fill-opacity': [
    'case',
    isOverAssignedGeoExpression,
    0,
    isSelectedExpression,
    0.9,
    isRuleIgnoredExpression,
    0,
    isAssignedExpression,
    0.4,
    0
  ],
  'fill-color': [
    'coalesce',
    ['feature-state', 'groupColor'],
    ['feature-state', 'ruleColor'],
    MapStyleTokens.colors.unassigned
  ]
};

export const nonActiveBackgroundColorPaint: FillPaint = {
  'fill-color': MapStyleTokens.colors.nonInteractive
};

const isSelectedAndUniqueExpression = ['all', isSelectedExpression, ['!', isOverAssignedGeoExpression]];
export const linePaint: LinePaint = {
  'line-opacity': [
    'interpolate',
    ['linear'],
    ['zoom'],
    MapStyleTokens.zooms.continent,
    ['case', isSelectedAndUniqueExpression, 0.2, 0.03],
    MapStyleTokens.zooms.district,
    ['case', isSelectedAndUniqueExpression, 0.8, 0.2]
  ],
  'line-width': [
    'interpolate',
    ['linear'],
    ['zoom'],
    MapStyleTokens.zooms.continent,
    ['case', isSelectedAndUniqueExpression, 0.2, 0.03],
    MapStyleTokens.zooms.district,
    ['case', isSelectedAndUniqueExpression, 0.8, 0.2]
  ]
};

export const hierarchyOverlapStripePaint: FillPaint = {
  'fill-pattern': 'map-hatch',
  'fill-opacity': ['case', isOverAssignedGeoExpression, 0.5, 0]
};

const stripeOpacity = 0.5;
export const ruleOverlapStripePaint: FillPaint = {
  'fill-pattern': 'map-hatch',
  'fill-opacity': [
    'case',
    isOverAssignedGeoExpression,
    stripeOpacity,
    isRuleIgnoredExpression,
    0,
    isOverAssignedRuleExpression,
    stripeOpacity,
    0
  ]
};

export const isPolygonExpression = ['in', ['geometry-type'], ['literal', ['Polygon', 'MultiPolygon']]];

export const createCountryCodeFilter = (countryCodes: (MapCountries | string)[]): MapboxExpression => [
  'in',
  ['get', 'iso_3166_1'],
  ['literal', countryCodes]
];

const boundariesUsWorldViewFilter = ['any', ['==', 'all', ['get', 'worldview']], ['in', 'US', ['get', 'worldview']]];

export const createCountryFilter = (countryCodes: (MapCountries | string)[]): MapboxExpression => [
  'all',
  createCountryCodeFilter(countryCodes),
  boundariesUsWorldViewFilter
];

export const createNonInteractiveCountryFilter = (countryCodes: (MapCountries | string)[]): MapboxExpression => [
  'all',
  ['!', createCountryCodeFilter(countryCodes)],
  boundariesUsWorldViewFilter
];

export const createInteractivePolygonLayerId = (sourceId: string): string => `interactive_polygon_${sourceId}`;

export const getInteractivePolygonLayers = (sourceGroups: SourceGroup[]): string[] =>
  sourceGroups.map(({ sourceId }) => createInteractivePolygonLayerId(sourceId));

export const getAllCountriesFromSourceGroups = (sourceGroups: SourceGroup[]): string[] => {
  const countries = new Set<string>();
  sourceGroups.forEach((group) => group.countries.forEach((country) => countries.add(country)));
  return [...countries];
};
