// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rwJIy {\n  flex: 1;\n  display: flex;\n  justify-content: flex-end;\n}\n\n  .vnl3T {\n    border-bottom: 1px dashed rgb(var(--color-gray-5));\n\n    /* add negative margin-bottom so the border doesn't change vertical spacing between fields */\n    margin-bottom: -1px;\n  }\n\n  .vnl3T .bp3-editable-text-content {\n      min-width: 0 !important;\n    }\n\n  .vnl3T .bp3-editable-text.bp3-editable-text-editing {\n      box-shadow: inset 0 0 0 1px rgba(var(--color-light-gray-1), 0.5);\n    }\n\n  .vnl3T .bp3-editable-text.bp3-editable-text-editing::before {\n      right: -0.75rem !important; /* match default right alignment when not editing, so field doesn't jump around */\n      box-shadow: inset 0 0 0 1px rgba(var(--color-light-gray-1), 0.5);\n    }\n\n  .YL1TF .bp3-editable-text.bp3-editable-text-editing {\n        box-shadow: inset 0 0 0 1px rgba(var(--color-red-3), 0.5);\n      }\n\n  .YL1TF .bp3-editable-text.bp3-editable-text-editing::before {\n        box-shadow: inset 0 0 0 1px rgba(var(--color-red-3), 0.5);\n      }\n\n  .G--1Y {\n      margin-left: 3px;\n    }\n\n  .Hci\\+Z {\n      margin-right: 2px;\n    }\n\n  .Afabw {\n    margin-left: 12px;\n    color: rgb(var(--color-red-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BattleCardInputField": "rwJIy",
	"BattleCardInputField_inputField": "vnl3T",
	"BattleCardInputField_inputField__error": "YL1TF",
	"BattleCardInputField_inputField__currencyFormat": "G--1Y",
	"BattleCardInputField_inputField__percentageFormat": "Hci+Z",
	"BattleCardInputField_validationMessage": "Afabw"
};
export default ___CSS_LOADER_EXPORT___;
