import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Redirect, Switch, useHistory } from 'react-router-dom';

import AuthSpinner from 'components/AuthSpinner/AuthSpinner';

import ImpersonationDialog from 'app/components/ImpersonationDialog/ImpersonationDialog';
import UserProfilePage from 'app/components/UserProfilePage/UserProfilePage';

import ErrorPage, { ErrorType } from 'app/containers/App/ErrorPage/ErrorPage';
import MaintenancePage from 'app/containers/App/MaintenancePage/MaintenancePage';
import PrivateRoute from 'app/containers/App/Router/PrivateRoute/PrivateRoute';
import { RoutePaths } from 'app/containers/App/Router/routePaths';
import TenantAccessErrorPage from 'app/containers/App/TenantAccessErrorPage/TenantAccessErrorPage';
import style from 'app/containers/App/TenantContainer/TenantContainer.module.pcss';
import CyclesPage from 'app/containers/PlanningCycles/CyclesPage';
import PlanningCyclePage from 'app/containers/PlanningCycles/PlanningCyclePage/PlanningCyclePage';
import CreateOrganization from 'app/containers/TenantManagement/CreateOrganization';

import { useCurrency } from 'app/contexts/currencyProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useUserMenu } from 'app/contexts/userMenuProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';
import { INVITATION_ID } from 'app/global/variables';

import { handleError } from 'app/graphql/handleError';
import { useGetPlanningCycles } from 'app/graphql/queries/getPlanningCycles';
import { useGetTenantWideInfo } from 'app/graphql/queries/getTenantWideInfo';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { MembershipSpec, PlanningCycleModal, UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { getSearchParams } from 'utils/helpers/searchParams';
import { clearImpersonationToken } from 'utils/ImpersonationLocalStorage';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

const b = block(style);

export const TenantContainer: React.FC = () => {
  const ref = useRef();
  const { setOnImpersonationMenuItemClick, setOnExitImpersonationMenuItemClick } = useUserMenu();

  const { selectedTenant, setSelectedTenant } = useScope();
  const {
    setUserRole,
    setUserProfile,
    setUserMembershipSpecs,
    setUserPlanningCycles,
    userProfile,
    invitation,
    setInvitation,
    shouldFetchUserSession,
    setShouldFetchUserSession,
    userRole
  } = useUser();
  const { setCurrencies } = useCurrency();
  const history = useHistory();
  const showToast = useShowToast();

  const [isMaintenanceModeEnabled] = useTreatment(SplitFeatures.MAINTENANCE_MODE);

  const { data, error, loading, refetch } = useGetTenantWideInfo({
    fetchPolicy: 'network-only',
    skip: !selectedTenant?.globalId,
    notifyOnNetworkStatusChange: true
  });

  const {
    data: plansList,
    loading: plansListLoading,
    error: plansListError
  } = useGetPlanningCycles({
    variables: { tenantId: selectedTenant?.id },
    skip: !selectedTenant?.id,
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('PLANNING_CYCLE_ERROR'), 'danger');
    }
  });

  const [openedModal, setOpenedModal] = useState<PlanningCycleModal | null>(null);

  useEffect(() => {
    const redirectUrl = `/${selectedTenant?.slug}`;

    const handleExitImpersonation = () => {
      clearImpersonationToken();
      window.location.href = redirectUrl;
    };

    const handleOpenImpersonationDialog = () => {
      setOpenedModal(PlanningCycleModal.IMPERSONATE_USER);
    };

    setOnExitImpersonationMenuItemClick(() => () => handleExitImpersonation());
    setOnImpersonationMenuItemClick(() => () => handleOpenImpersonationDialog());
  }, []);

  useEffect(() => {
    setCurrencies(data?.getCurrencies ?? null);
  }, [data?.getCurrencies]);

  useEffect(() => {
    if (!data?.getUserSpec) return;
    const profile = {
      tenant: {
        id: data.getUserSpec.membershipSpecs[0]?.tenantId,
        slug: data.getUserSpec.membershipSpecs[0]?.tenantSlug
      },
      subjectId: data.getUserSpec.subjectId,
      firstName: data.getUserSpec.firstName,
      lastName: data.getUserSpec.lastName,
      emailAddress: data.getUserSpec.emailAddress,
      isSupportUser: data.getUserSpec.isSupportUser
    };
    setSelectedTenant({
      id: data.getUserSpec.membershipSpecs[0]?.tenantId,
      globalId: data.getUserSpec.membershipSpecs[0]?.tenantGlobalId,
      slug: data.getUserSpec.membershipSpecs[0]?.tenantSlug
    });
    setUserProfile(profile);
    setUserMembershipSpecs(data.getUserSpec.membershipSpecs as MembershipSpec[]);
    setUserRole(data.getUserSpec.membershipSpecs[0]?.systemRoleName as UserRoleType);
  }, [data?.getUserSpec]);

  useEffect(() => {
    if (!data?.getUserSpec || loading) {
      return;
    }

    loadInvitationParams();
  }, [data?.getUserSpec, selectedTenant]);

  useEffect(() => {
    if (shouldFetchUserSession) {
      refetch();
      setShouldFetchUserSession(false);
    }
  }, [shouldFetchUserSession]);

  const loadInvitationParams = () => {
    const invitationId = getSearchParams(history.location.search, INVITATION_ID);
    const regType = getSearchParams(history.location.search, 'regType');

    if (!invitation?.invitationId && invitationId) {
      setInvitation({ invitationId, regType });
    } else if (invitation?.invitationId && !invitationId) {
      setInvitation({ invitationId: null, regType: null });
    }
  };

  const planningCycleDeploymentModels = useMemo(() => {
    return data?.getUserSpec?.membershipSpecs.find((membershipSpec) => {
      return membershipSpec.tenantId === selectedTenant?.id || membershipSpec.tenantSlug === selectedTenant?.slug;
    })?.planningCycles;
  }, [data?.getUserSpec, selectedTenant]);

  useEffect(() => {
    if (!plansList || !planningCycleDeploymentModels) return;

    const planningCycles = [];
    for (const planningCycle of plansList.getPlanningCycles) {
      const matchedDeploymentModel = planningCycleDeploymentModels.find(
        (deploymentModel) => deploymentModel.planningCycleId === planningCycle.planningCycleId
      );
      if (!matchedDeploymentModel) return;
      const deploymentModels = matchedDeploymentModel.deploymentModels;

      planningCycles.push({
        planningCycleId: +planningCycle.planningCycleId,
        planningCycleName: planningCycle.planningCycleName,
        deploymentModelId: deploymentModels[0].deploymentModelId,
        planningCycleDuration: planningCycle.planningCycleDuration,
        planningCyclePeriodicity: planningCycle.planningCyclePeriodicity,
        planningCycleStartDate: planningCycle.planningCycleStartDate,
        planningCycleDescription: planningCycle.planningCycleComment,
        planningCycleSlug: planningCycle.planningCycleSlug,
        currencyCode: planningCycle.currencyCode,
        planningCycleArchived: planningCycle.planningCycleArchived,
        deploymentModels
      });
    }
    setUserPlanningCycles(planningCycles);
  }, [plansList, planningCycleDeploymentModels]);

  if (isMaintenanceModeEnabled) {
    return <MaintenancePage />;
  }

  if (loading) {
    return <AuthSpinner />;
  }
  // eslint-disable-next-line no-restricted-syntax
  if (!userRole || userRole === UserRoleType.NONE) {
    return <TenantAccessErrorPage membershipSpecs={data?.getUserSpec.membershipSpecs ?? []} />;
  }
  if (error || plansListError) {
    return <ErrorPage errorType={ErrorType._500} />;
  }
  if (plansListLoading || !selectedTenant?.id) {
    return <AuthSpinner />;
  }

  if (invitation?.regType && userProfile?.subjectId) {
    return <PrivateRoute path={[RoutePaths.NEW_ORGANIZATION]} component={CreateOrganization} />;
  } else if (!userProfile?.tenant?.id || !userProfile?.tenant?.slug) {
    return <TenantAccessErrorPage membershipSpecs={data?.getUserSpec.membershipSpecs ?? []} />;
  } else
    return (
      <>
        <Switch>
          {userProfile && (
            <PrivateRoute path={[`/:tenantSlug${RoutePaths.USER_PROFILE}/:userId`]} exact component={UserProfilePage} />
          )}
          <PrivateRoute
            path={[`/:tenantSlug${RoutePaths.CREATE_PLAN}`, `/:tenantSlug${RoutePaths.EDIT_PLAN}/:planningCycleSlug`]}
            exact
            render={() => (
              <CanUser perform={UserAction.PLANNING_CYCLES_MODIFY} yes={<CyclesPage />} no={<Redirect to="/" />} />
            )}
          />
          <PrivateRoute
            path={`/:tenantSlug`}
            exact
            render={() => (
              <CanUser perform={UserAction.PLANNING_CYCLES_VIEW} yes={<CyclesPage />} no={<Redirect to="/" />} />
            )}
          />
          <PrivateRoute
            path={[
              `/:tenantSlug/:planningCycleSlug/:deploymentModelSlug/:deploymentModelComponent`,
              `/:tenantSlug/:planningCycleSlug/:deploymentModelSlug`,
              `/:tenantSlug/:planningCycleSlug`
            ]}
            render={() => (
              <CanUser
                perform={UserAction.PLANNING_CYCLES_VIEW}
                yes={
                  <div className={b('tenantContainerWrapper')} ref={ref}>
                    <PlanningCyclePage appContainerRef={ref} />
                  </div>
                }
                no={<Redirect to="/" />}
              />
            )}
          />
        </Switch>
        {openedModal === PlanningCycleModal.IMPERSONATE_USER && (
          <ImpersonationDialog
            onClose={() => {
              setOpenedModal(null);
            }}
          />
        )}
      </>
    );
};

export default TenantContainer;
