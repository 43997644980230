import React, { useState, useMemo, Dispatch } from 'react';

import { ApolloError, ApolloQueryResult, isApolloError } from '@apollo/client';
import flatten from 'lodash.flatten';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { GET_CONTRIBUTOR_WORKFLOWS } from 'app/graphql/queries/getContributorWorkflows';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext, ContributorWorkflow, BattlecardWorkflow, TerritoryGroupsWorkflow } from 'app/models';

export interface ContributorContextValues extends BaseContext {
  contributorWorkflow: ContributorWorkflow;
  setContributorWorkflow: Dispatch<React.SetStateAction<ContributorWorkflow>>;
  contributorWorkflowLoading: boolean;
  setContributorWorkflowLoading: Dispatch<React.SetStateAction<boolean>>;
  contributorWorkflowError: ApolloError;
  getContributorWorkflow: (deploymentModelId: number, planningCycleId: number) => void;
  contributorTerritoryGroupToBattlecardMapping: Map<string, string>;
  setContributorTerritoryGroupToBattlecardMapping: Dispatch<React.SetStateAction<Map<string, string>>>;
  resetValues: () => void;
}

export const ContributorContext = React.createContext<ContributorContextValues | null>(null);
ContributorContext.displayName = 'ContributorContext';

export const ContributorProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [contributorWorkflow, setContributorWorkflow] = useState<ContributorWorkflow>(null);
  const [contributorWorkflowLoading, setContributorWorkflowLoading] = useState<boolean>(false);
  const [contributorWorkflowError, setContributorWorkflowError] = useState<ApolloError | null>(null);
  const [contributorTerritoryGroupToBattlecardMapping, setContributorTerritoryGroupToBattlecardMapping] =
    useState<Map<string, string>>(null);

  const getContributorWorkflow = async (deploymentModelId: number, planningCycleId: number) => {
    setContributorWorkflowLoading(true);
    setContributorWorkflowError(null);

    try {
      const contributorWorkflowResponse: ApolloQueryResult<{
        getContributorWorkflows: ContributorWorkflow;
        // eslint-disable-next-line no-restricted-syntax
      }> = await apolloClient.query({
        query: GET_CONTRIBUTOR_WORKFLOWS,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
          planningCycleId,
          deploymentModelId
        }
      });

      const contributorData = contributorWorkflowResponse?.data?.getContributorWorkflows;
      setContributorWorkflow(contributorData);

      const tgToBcMapping = new Map<string, string>();
      const flattenedContributorBattlecards: BattlecardWorkflow[] = flatten(contributorData?.battlecards);
      flattenedContributorBattlecards.forEach((bc) => {
        bc.territoryGroups.forEach((tg) => {
          tgToBcMapping.set(tg.territoryGroupId.toString(), bc.battlecardId.toString());
        });
      });
      const flattenedContributorTerritoryGroups: TerritoryGroupsWorkflow[] = flatten(contributorData?.territoryGroups);
      flattenedContributorTerritoryGroups.forEach((tg) => {
        tgToBcMapping.set(tg.territoryGroupId.toString(), tg.battlecardId.toString());
      });

      setContributorTerritoryGroupToBattlecardMapping(tgToBcMapping);
    } catch (error) {
      if (error instanceof Error && isApolloError(error)) {
        setContributorWorkflowError(error);
      }
    } finally {
      setContributorWorkflowLoading(false);
    }
  };

  const resetValues = () => {
    setContributorWorkflow(null);
    setContributorWorkflowLoading(false);
    setContributorWorkflowError(null);
    setContributorTerritoryGroupToBattlecardMapping(null);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      contributorWorkflow,
      setContributorWorkflow,
      contributorWorkflowLoading,
      setContributorWorkflowLoading,
      contributorWorkflowError,
      getContributorWorkflow,
      contributorTerritoryGroupToBattlecardMapping,
      setContributorTerritoryGroupToBattlecardMapping,
      resetValues
    }),
    [contributorWorkflow, contributorWorkflowLoading, contributorWorkflowError, getContributorWorkflow]
  );

  // Return the interface that we want to expose to our other components
  return <ContributorContext.Provider value={values}>{children}</ContributorContext.Provider>;
};

// Custom hook to read these values from
export const useContributor = (): ContributorContextValues => useContextSafe(ContributorContext);
