import { MutableRefObject, useCallback } from 'react';

import { ToSymonMessage } from 'app/containers/Symon/SymonTypes';

type SymonPostMessage = (message: ToSymonMessage) => void;

const useSymonPostMessage = (iframeRef: MutableRefObject<HTMLIFrameElement>, symonOrigin: string): SymonPostMessage =>
  useCallback(
    (message: ToSymonMessage) => {
      if (!iframeRef.current) throw new Error(`Unable to send message, iframe not yet defined`);
      if (!symonOrigin) throw new Error(`Unable to send message, symonOrigin not yet defined`);
      iframeRef.current.contentWindow.postMessage(message, symonOrigin);
    },
    [symonOrigin]
  );

export default useSymonPostMessage;
