import React from 'react';

import { Filter, ChevronDown } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import { FilterChangeInput, FilterInput } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import TerritoryQuotaDrillInGridFilter from './TerritoryQuotaDrillInGridFilter';
import style from './TerritoryQuotaDrillInGridHeader.module.pcss';

const b = block(style);

export interface TerritoryQuotaDrillInGridHeaderProps {
  activeFiltersCount: number;
  accountFilters: FilterInput;
  onFilterChange: (updatedFilter: FilterChangeInput) => void;
  onFilterApply: () => void;
  onClearAll: () => void;
}

const TerritoryQuotaDrillInGridHeader: React.FC<TerritoryQuotaDrillInGridHeaderProps> = ({
  activeFiltersCount,
  accountFilters,
  onFilterChange,
  onFilterApply,
  onClearAll
}) => {
  const filterButtonText = (
    <div>
      {formatMessage('FILTERS')}
      {!!activeFiltersCount && <span className={b('filterCount')}>{activeFiltersCount}</span>}
    </div>
  );

  return (
    <header className={b()}>
      <div className={b('container')}>
        <Popover
          content={
            <TerritoryQuotaDrillInGridFilter
              accountFilters={accountFilters}
              onFilterChange={onFilterChange}
              onFilterApply={onFilterApply}
            />
          }
          placement={'bottom-start'}
          onClosed={onFilterApply}
        >
          <TextButton
            text={filterButtonText}
            icon={<Filter size={20} />}
            type="button"
            testId="filter-button"
            minimal
            large={false}
            rightIcon={<ChevronDown size={20} />}
          />
        </Popover>
        {!!activeFiltersCount && (
          <TextButton
            text={formatMessage('CLEAR_ALL')}
            type="button"
            testId="clear-all-button"
            minimal
            large={false}
            onClick={onClearAll}
          />
        )}
      </div>
    </header>
  );
};

export default TerritoryQuotaDrillInGridHeader;
