import gql from 'graphql-tag';

export const GET_LOOKUP_TABLE = gql`
  query GetLookupTable($planningCycleId: Int!, $lookupInput: LookupInput) {
    getPlanningCycleSpec(input: { planningCycleId: $planningCycleId, lookupInput: $lookupInput }) {
      planningCycleId
      lookups {
        fileId
        lookupId
        lookupType
        metadata {
          tableData
          tableSchema
        }
      }
    }
  }
`;
