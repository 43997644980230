import React, { FC, useMemo } from 'react';

import { FillPaint } from 'mapbox-gl';
import { Layer, Source } from 'react-map-gl';

import {
  backgroundFillPaint,
  createCountryFilter,
  createInteractivePolygonLayerId,
  createNonInteractiveCountryFilter,
  getAllCountriesFromSourceGroups,
  hierarchyOverlapStripePaint,
  linePaint,
  nonActiveBackgroundColorPaint,
  ruleOverlapStripePaint
} from 'app/components/TerritoryMap/PolygonLayerHelpers';

import { MapSelectionTarget, SourceGroup } from 'app/models';

import { PolygonSourceIds } from './MapStyleTokens';

interface PolygonSourceLayersProps {
  sourceGroups: SourceGroup[];
  selectionTarget: MapSelectionTarget;
  beforeId: string;
}

const PolygonSourceLayers: FC<PolygonSourceLayersProps> = React.memo(({ sourceGroups, selectionTarget, beforeId }) => {
  const countriesInUse = useMemo(() => getAllCountriesFromSourceGroups(sourceGroups), [sourceGroups]);

  const getOverAssignedPaint = (selectionTarget: MapSelectionTarget): FillPaint => {
    switch (selectionTarget) {
      case MapSelectionTarget.polygons:
      case MapSelectionTarget.accounts:
        return hierarchyOverlapStripePaint;
      case MapSelectionTarget.territories:
        return ruleOverlapStripePaint;
    }
  };

  return (
    <>
      {countriesInUse.length > 0 && (
        <Source
          id={PolygonSourceIds.admin0}
          key="nonInteractiveCountryOverlay"
          url="mapbox://mapbox.boundaries-adm0-v3"
          type="vector"
        >
          <Layer
            id="non_interactive_overlay"
            filter={createNonInteractiveCountryFilter(countriesInUse)}
            type="fill"
            paint={nonActiveBackgroundColorPaint}
            source-layer={PolygonSourceIds.admin0}
            beforeId={beforeId}
          />
        </Source>
      )}

      {sourceGroups.map(({ sourceKey, sourceId, sourceUrl, countries }) => {
        const sharedProps = {
          beforeId,
          sourceId,
          'source-layer': sourceId,
          filter: createCountryFilter(countries),
          type: 'fill' as const
        };
        return (
          <Source key={sourceKey} id={sourceId} url={sourceUrl} type="vector">
            <Layer {...sharedProps} id={createInteractivePolygonLayerId(sourceId)} paint={backgroundFillPaint} />
            <Layer
              {...sharedProps}
              id={`over_assigned_stripes_${sourceId}`}
              paint={getOverAssignedPaint(selectionTarget)}
            />
            <Layer {...sharedProps} id={`outline_${sourceId}`} type="line" paint={linePaint} />
          </Source>
        );
      })}
    </>
  );
});

export default PolygonSourceLayers;
