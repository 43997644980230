// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LVcd4 {\n    padding-top: 30px;\n  }\n  .IEyrL {\n    display: flex;\n    justify-content: center;\n    min-height: 200px;\n    align-items: center;\n  }\n  ._0wdvv {\n    padding: 24px 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImportSellerAssignmentFileUpload_linkContainer": "LVcd4",
	"ImportSellerAssignmentFileUpload_spinnerContainer": "IEyrL",
	"ImportSellerAssignmentFileUpload_fileUploadContainer": "_0wdvv"
};
export default ___CSS_LOADER_EXPORT___;
