import { ColDef } from '@ag-grid-community/core';

import DateRangeCellEditor from 'app/components/AdvancedGrid/CellEditors/DateRangeCellEditor/DateRangeCellEditor';
import DropdownMenuCellEditor from 'app/components/AdvancedGrid/CellEditors/DropdownMenuCellEditor/DropdownMenuCellEditor';
import FormatNumbersCellEditor from 'app/components/AdvancedGrid/CellEditors/FormatNumbersCellEditor/FormatNumbersCellEditor';
import SellerAssignmentCellEditor from 'app/components/AdvancedGrid/CellEditors/SellerAssignmentCellEditor/SellerAssignmentCellEditor';
import EditableFieldCellRenderer from 'app/components/AdvancedGrid/CellRenderers/EditableFieldCellRenderer/EditableFieldCellRenderer';
import LookupInfoHeaderRenderer from 'app/components/AdvancedGrid/CellRenderers/LookupInfoHeaderRenderer/LookupInfoHeaderRenderer';
import TerritoryGroupRenderer from 'app/components/AdvancedGrid/CellRenderers/TerritoryGroupRenderer/TerritoryGroupRenderer';
import {
  getCellClass,
  getCellComponent,
  getCellEditorComponent,
  getCellEditorParams,
  getCellParams,
  getCellValue,
  getColumnWidth,
  getFormattedCellValue,
  getHeaderClass,
  getHeaderComponent,
  getHeaderParams,
  getRampLookUpsItems,
  isEditableColumn,
  isMonthlyBreakdownColumn,
  renameColumnHeaders,
  setCellValue
} from 'app/components/AdvancedGrid/Sheets/Seller/SellerSheetUtils';

import {
  MEASURES_GRID_COLUMN_WIDTH,
  MEASURES_GRID_COLUMN_WIDTH_EXTRA_LARGE,
  MEASURES_GRID_COLUMN_WIDTH_LARGE,
  MEASURES_GRID_COLUMN_WIDTH_SMALL
} from 'app/global/variables';

import { ISheetDefinitionDef } from 'app/graphql/generated/graphqlApolloTypes';

import {
  FieldIdsLookUpTable,
  GridFields,
  GridHeaders,
  Lookup,
  NumberInputFormatStyle,
  SellerSheetGridColumnName
} from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface SellerSheetColumnDefsParams {
  sheetDefinitions: ISheetDefinitionDef[];
  currency: string;
  headerClassNames: string[];
  cellClassNames: string[];
  lookups: Lookup[];
  fieldIdsLookUpTable: FieldIdsLookUpTable;
  selectedQuotaComponentId: number;
  previewDialogOpener: () => void;
  isTQM: boolean;
  canEditSellerAssignment: boolean;
  canEditSellerQuota: boolean;
  fieldId?: number;
  isSellerAssignmentTwoDecimalEnabled?: boolean;
}

const buildSellerSheetColumnDefsV2 = (params: SellerSheetColumnDefsParams): Array<ColDef> => {
  const {
    sheetDefinitions,
    currency,
    headerClassNames,
    cellClassNames,
    lookups,
    fieldIdsLookUpTable,
    selectedQuotaComponentId,
    previewDialogOpener,
    isTQM,
    canEditSellerAssignment,
    canEditSellerQuota,
    fieldId,
    isSellerAssignmentTwoDecimalEnabled
  } = params;
  const columnDefs: ColDef[] = [
    {
      headerName: isTQM ? SellerSheetGridColumnName.TERRITORY_NAME : formatMessage('TERRITORY_NAME'),
      headerClass: headerClassNames[0],
      field: GridFields.TERRITORY_NAME,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
      flex: 1,
      resizable: true,
      pinned: 'left'
    }
  ];

  const editable = isTQM ? canEditSellerQuota : canEditSellerAssignment;

  if (isTQM) {
    sheetDefinitions.forEach((column) => {
      const shouldColumnsBeHidden = isMonthlyBreakdownColumn(column.measureName);
      columnDefs.push({
        headerName: renameColumnHeaders(column.measureName, isTQM),
        field: column.measureName,
        minWidth: getColumnWidth(column),
        flex: 1,
        resizable: true,
        valueGetter: (params) => getFormattedCellValue(column.measureName, params, currency),
        valueSetter: (params) => setCellValue(column.measureName, params, isTQM, fieldIdsLookUpTable),
        cellClass: (params) =>
          getCellClass(
            column.measureFormatType,
            isEditableColumn(column, selectedQuotaComponentId, params),
            cellClassNames
          ),
        headerClass: getHeaderClass(column, headerClassNames),
        hide: shouldColumnsBeHidden,
        editable: (params) => editable && isEditableColumn(column, selectedQuotaComponentId, params),
        // TODO TQP-11293 Split view and edit functionality in SellerAssignmentCellEditor
        cellRendererFramework: getCellComponent(column.measureName),
        cellRendererParams: (params) => {
          return {
            ...(getCellParams(column.measureName, params) as object),
            editable
          };
        },
        // TODO TQP-11293 Split view and edit functionality in SellerAssignmentCellEditor
        cellEditorFramework: getCellEditorComponent(column),
        cellEditorParams: (params) => {
          return {
            ...(getCellEditorParams(column, lookups, params, currency) as object),
            editable
          };
        },
        headerComponentFramework: getHeaderComponent(column),
        headerComponentParams: getHeaderParams(column, previewDialogOpener)
      });
    });
  } else {
    columnDefs.push({
      headerName: formatMessage('TERRITORY_GROUP'),
      field: GridFields.TERRITORY_GROUP,
      flex: 1,
      minWidth: 200,
      maxWidth: null,
      cellRendererFramework: TerritoryGroupRenderer,
      headerClass: headerClassNames[0]
    });
    columnDefs.push({
      headerName: formatMessage('SELLER'),
      field: GridFields.SELLER,
      resizable: true,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_LARGE,
      maxWidth: null,
      flex: 1,
      // TODO TQP-11293 Split view and edit functionality in SellerAssignmentCellEditor
      cellRendererFramework: SellerAssignmentCellEditor,
      cellRendererParams: (params) => {
        return {
          ...(getCellParams(SellerSheetGridColumnName.SELLERS, params) as object),
          editable
        };
      },
      cellStyle: { overflow: 'visible' },
      headerClass: headerClassNames[0]
    });
    columnDefs.push({
      headerName: formatMessage('OWNERSHIP'),
      field: GridFields.OWNERSHIP,
      editable: (params) => editable && !!params.data.owners,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
      maxWidth: null,
      flex: 1,
      valueGetter: (params) =>
        getFormattedCellValue(GridHeaders.OWNERSHIP, params, currency, isSellerAssignmentTwoDecimalEnabled),
      valueSetter: (params) => setCellValue(GridHeaders.OWNERSHIP, params, isTQM),
      cellClass: [cellClassNames[0], cellClassNames[1]],
      headerClass: headerClassNames[1],
      cellRendererFramework: EditableFieldCellRenderer,
      cellRendererParams: (params) => getCellParams(GridHeaders.OWNERSHIP, params),
      cellEditorFramework: FormatNumbersCellEditor,
      cellEditorParams: (params) => {
        const value = getCellValue(GridHeaders.OWNERSHIP, params);
        return {
          formatStyle: NumberInputFormatStyle.PERCENTAGE,
          placeHolder: formatMessage('ENTER_AMOUNT'),
          value,
          maximumFractionDigits: 2
        };
      }
    });
    columnDefs.push({
      headerName: formatMessage('EFFECTIVE_DATE'),
      field: GridFields.EFFECTIVE_DATE,
      editable: (params) => editable && !!params.data.owners,
      flex: 1,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_EXTRA_LARGE,
      valueGetter: (params) => getFormattedCellValue(GridHeaders.EFFECTIVE_DATE, params, currency),
      valueSetter: (params) => setCellValue(GridHeaders.EFFECTIVE_DATE, params, isTQM),
      cellClass: [cellClassNames[1]],
      headerClass: headerClassNames[0],
      cellRendererFramework: EditableFieldCellRenderer,
      cellRendererParams: (params) => getCellParams(GridHeaders.EFFECTIVE_DATE, params),
      cellEditorFramework: DateRangeCellEditor,
      cellEditorParams: (params) => {
        return {
          name: 'territoryEffectiveDates',
          effectiveStartDate: params?.data?.owners?.[0]?.effectiveStartDate,
          effectiveEndDate: params?.data?.owners?.[0]?.effectiveEndDate
        };
      }
    });
    columnDefs.push({
      headerName: formatMessage('RAMP'),
      field: GridFields.RAMP,
      editable: (params) => editable && !!params.data.owners,
      flex: 1,
      resizable: true,
      minWidth: MEASURES_GRID_COLUMN_WIDTH,
      cellRendererFramework: EditableFieldCellRenderer,
      cellRendererParams: { measureName: GridHeaders.RAMP, fieldId },
      cellEditorFramework: DropdownMenuCellEditor,
      cellEditorParams: () => getRampLookUpsItems(lookups),
      valueGetter: (params) => getFormattedCellValue(GridHeaders.RAMP, params),
      valueSetter: (params) => setCellValue(GridHeaders.RAMP, params, isTQM, null, fieldId),
      cellClass: [cellClassNames[1]],
      headerClass: headerClassNames[0],
      headerComponentFramework: LookupInfoHeaderRenderer,
      headerComponentParams: {
        displayName: isTQM ? GridHeaders.RAMP : formatMessage('RAMP'),
        hover: formatMessage('VIEW_RAMP_DATA'),
        tooltipMessage: formatMessage('RAMP_TOOLTIP_MESSAGE'),
        onButtonClicked: previewDialogOpener
      }
    });
  }

  return columnDefs;
};

export default buildSellerSheetColumnDefsV2;
