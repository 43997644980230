import gql from 'graphql-tag';

export const QUERY_ALL_TERRITORY_GROUPS_MEASURE = gql`
  query AllTerritoryGroupsMeasure($battlecardId: Int!, $measureId: Int, $quotaComponentId: Int!) {
    getAllTerritoryGroupsMeasure(
      battlecardId: $battlecardId
      measureId: $measureId
      quotaComponentId: $quotaComponentId
    ) {
      territoryGroups {
        territoryGroupId
        territoryGroupParentId
        territoryGroupName
        hierarchyId
        battlecardId
        hierarchyType
        path
        precedence
        measures {
          measureId
          measureName
          measureValue
        }
        children {
          territoryGroupId
          territoryGroupParentId
          territoryGroupName
          hierarchyId
          battlecardId
          path
          precedence
        }
      }
      battlecard {
        battlecardId
        battlecardName
        newBusinessTarget
        updatedQuota
      }
    }
  }
`;
