import gql from 'graphql-tag';

export const TENANT_EXISTS_BY_NAME = gql`
  query TenantExistsByName($tenantName: String!) {
    tenantExistsByName(input: { tenantName: $tenantName }) {
      exists
      slugName
    }
  }
`;
