import React, { useEffect } from 'react';

import SheetDetail from 'app/components/AdvancedGrid/Sheets/Quota/QuotaSheetDetail/QuotaSheetDetail';
import SellerSheetDetail from 'app/components/AdvancedGrid/Sheets/Seller/SellerSheetDetail/SellerSheetDetail';
import TerritorySheetDetail from 'app/components/AdvancedGrid/Sheets/Territory/TerritorySheetDetail/TerritorySheetDetail';
import DataOverview from 'app/components/DataPanel/DataOverview/DataOverview';
import ImportErrorsDetail from 'app/components/DataPanel/ImportErrorsDetail/ImportErrorsDetail';
import LookupTableDetail from 'app/components/DataPanel/TablesPanel/LookupTableDetail/LookupTableDetail';
import TableDetail from 'app/components/DataPanel/TablesPanel/TableDetail/TableDetail';

import { useData } from 'app/contexts/dataProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { DataPanelViews, SheetType } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './DataPanel.module.pcss';

const b = block(style);

const DataPanel: React.FC = () => {
  const { selectedDataView, resetValues, selectedSheet } = useData();
  const [isSymonEmbedFlagOn] = useTreatment(SplitFeatures.SYMON_EMBED);

  useEffect(() => {
    resetValues();
  }, []);

  return (
    <div className={b()}>
      {selectedDataView === DataPanelViews.DATA_OVERVIEW && <DataOverview />}

      {selectedDataView === DataPanelViews.SHEET_DETAIL &&
        (selectedSheet?.sheetType === SheetType.QUOTA_SHEET ||
          selectedSheet?.sheetType === SheetType.QUOTA_DISTRIBUTION_SHEET) && <SheetDetail />}

      {selectedDataView === DataPanelViews.SHEET_DETAIL && selectedSheet?.sheetType === SheetType.TERRITORY_SHEET && (
        <TerritorySheetDetail />
      )}
      {selectedDataView === DataPanelViews.SHEET_DETAIL && selectedSheet?.sheetType === SheetType.SELLER_SHEET && (
        <SellerSheetDetail />
      )}
      {selectedDataView === DataPanelViews.ERROR_DETAIL && <ImportErrorsDetail />}

      {!isSymonEmbedFlagOn && (
        <>
          {selectedDataView === DataPanelViews.TABLE_DETAIL && <TableDetail />}
          {selectedDataView === DataPanelViews.LOOKUP_TABLE_DETAIL && <LookupTableDetail />}
        </>
      )}
    </div>
  );
};

export default DataPanel;
