import React, { useState } from 'react';

import { Callout } from '@blueprintjs/core';
import { Checkmark, InformationFilled } from '@carbon/icons-react';

import MultiStepDialog from 'components/Dialog/MultiStepDialog';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ActivatePlanningCycleDialog.module.pcss';

const b = block(style);

export interface ActivatePlanningCycleDialogProps {
  onSubmit: () => void;
  onClose: () => void;
  isOpen: boolean;
  deploymentModelName: string;
}

const getDialogText = (deploymentModelName: string) => {
  return {
    title: formatMessage('ACTIVATE_WITH_NAME', { name: deploymentModelName }),
    explanationPageConfirmation: formatMessage('ACTIVATE_SCENARIO_CONFIRMATION'),
    explanationPageBody: formatMessage('ACTIVATE_SCENARIO_MANAGE_VERSION'),
    confirmationPageBody: formatMessage('ACTIVATE_SCENARIO_BEFORE_MESSAGE'),
    confirmationPageWarning: formatMessage('ACTIVATE_SCENARIO_WARNING')
  };
};

const ActivatePlanningCycleDialog: React.FC<ActivatePlanningCycleDialogProps> = ({
  onSubmit,
  onClose,
  isOpen,
  deploymentModelName
}: ActivatePlanningCycleDialogProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const onNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const dialogText = getDialogText(deploymentModelName);
  const { title, explanationPageConfirmation, explanationPageBody, confirmationPageBody, confirmationPageWarning } =
    dialogText;

  const explanationPage = (
    <div className={b('explanationPage')} data-testid="activate-planning-cycle-explanation-page">
      <div>{explanationPageConfirmation}</div>
      <div>{explanationPageBody}</div>
    </div>
  );

  const paddedIcon = (
    <span data-testid="activate-planning-cycle-padded-icon" className={b('paddedIcon')}>
      <InformationFilled />
    </span>
  );

  const confirmationPage = (
    <div data-testid="activate-planning-cycle-confirmation-page">
      <div>{confirmationPageBody}</div>
      <Callout className={b('warningMessage')} intent="warning" icon={paddedIcon}>
        <div data-testid="activation-warning-message">{confirmationPageWarning}</div>
      </Callout>
    </div>
  );

  const pages = [explanationPage, confirmationPage];

  return (
    <div>
      <MultiStepDialog
        data-testid="activate-planning-cycle-dialog"
        title={title}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        onNext={onNext}
        pages={pages}
        size="small"
        confirmButtonText={formatMessage('ACTIVATE')}
        confirmButtonIcon={<Checkmark />}
      />
    </div>
  );
};

export default ActivatePlanningCycleDialog;
