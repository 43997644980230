import React, { useEffect, useState } from 'react';

import { Classes } from '@blueprintjs/popover2';
import { Close } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';
import { Form, Formik } from 'formik';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';

import SheetsAddFieldFormFields from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldForm/SheetsAddFieldFormFields';
import SheetsExistingFieldFormFields from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldForm/SheetsExistingFieldFormFields';
import validationSchema from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldForm/validationSchema';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { GetHierarchyForField_getDeploymentModelSpec_pinnedHierarchies } from 'app/graphql/generated/graphqlApolloTypes';
import { useUpsertSheet } from 'app/graphql/mutations/upsertSheet';
import { useGetHierarchyForField } from 'app/graphql/queries/getHierarchyForField';

import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  DataSheetDrawerFormType,
  DataSheetDrawerState,
  DeploymentModelPhase,
  MeasureFieldType,
  MeasureSource,
  SheetDefinitionType
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SheetsAddFieldForm.module.pcss';
import {
  formatFormulaToDisplayFormat,
  replaceFormulaIdsWithNames,
  replaceFormulaNamesWithIds,
  replaceBracesWithQuotes,
  formatFormulaToPeriodicityFunction
} from './SheetsAddFieldFormUtils';

const b = block(style);

export interface SheetsAddFieldFormValues {
  measureName: string | Record<string, string>;
  measureFormatType: { key: string; value: string } | '';
  measureCategory: string;
  measureFieldType: Record<string, string> | '';
  measureSource: Record<string, string> | '';
  fieldId: { key: string; value: number; type?: string } | '';
  fieldName: Record<string, string> | '';
  isBalancingMetric: boolean;
  isDefaultBalancingMetric: boolean;
  measureId: number;
  calculatedFieldFormula: string;
  startDate?: string;
  endDate?: string;
}

const initialValues: SheetsAddFieldFormValues = {
  measureName: '',
  measureFormatType: null,
  measureCategory: 'global',
  measureFieldType: null,
  measureSource: null,
  fieldId: null,
  fieldName: null,
  isBalancingMetric: false,
  isDefaultBalancingMetric: false,
  measureId: null,
  calculatedFieldFormula: '',
  startDate: null,
  endDate: null
};

const steps = (selectedFieldType) => {
  const isLoadedField = selectedFieldType === MeasureFieldType.LOADED;
  const isCalculatedField = selectedFieldType === MeasureFieldType.CALCULATED;
  const isHistoricalField = selectedFieldType === MeasureFieldType.HISTORICAL;
  if (isLoadedField || isCalculatedField || isHistoricalField) {
    return ['Form One', 'Form Two'];
  }
  return ['Form One'];
};

export const SheetsAddFieldForm: React.FC = () => {
  const { selectedDeploymentModelId } = useScope();
  const {
    selectedSheet,
    getSheetDefinitions,
    setDataSheetDrawerState,
    dataSheetDrawerFormType,
    setDataSheetDrawerFormType,
    sheetDefinition,
    setSheetDefinition,
    sheetMeasuresIdLookupMap,
    sheetMeasuresNameLookupMap
  } = useData();

  const [isDisallowMeasureNameWithUnderscoreEnabled] = useTreatment(
    SplitFeatures.DISALLOW_MEASURE_NAME_WITH_UNDERSCORE
  );
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema(isDisallowMeasureNameWithUnderscoreEnabled)[activeStep];
  const [selectedFieldType, setSelectedFieldType] = useState<MeasureFieldType | string | null>(null);
  const [isDefaultBMChecked, setIsDefaultBMChecked] = useState<boolean>(false);
  const [formInitialValues, setFormInitialValues] = useState<SheetsAddFieldFormValues>(initialValues);
  const [selectedPinnedHierarchy, setSelectedPinnedHierarchy] =
    useState<GetHierarchyForField_getDeploymentModelSpec_pinnedHierarchies | null>(null);
  const [formulaErrorCount, setFormulaErrorCount] = useState<number>(0);
  const deploymentModelPhase = usePhase();
  const showToast = useShowToast();

  const [isCalcColumnUpdateEnabled] = useTreatment(SplitFeatures.CALC_COLUMN_UPDATE);

  const isTQM = deploymentModelPhase === DeploymentModelPhase.manage ? true : false;
  const [upsertSheet, { loading: upsertSheetLoading }] = useUpsertSheet({
    onError({ graphQLErrors }) {
      showToast(graphQLErrors[0].message.replace(/[[\]']+/g, ''), 'danger');
    },
    onCompleted(result) {
      if (result?.upsertSheet?.success === true) {
        setDataSheetDrawerState(DataSheetDrawerState.CLOSE);
        setSheetDefinition(null);
        showToast(
          sheetDefinition ? formatMessage('UPDATE_FIELD_SUCCESS') : formatMessage('CREATE_FIELD_SUCCESS'),
          'success'
        );
        getSheetDefinitions(selectedDeploymentModelId, selectedSheet.sheetId, isTQM);
      }
    }
  });

  const { data: hierarchyData } = useGetHierarchyForField({
    variables: { deploymentModelId: selectedDeploymentModelId },
    fetchPolicy: 'network-only'
  });
  const existingField = dataSheetDrawerFormType === DataSheetDrawerFormType.EXISTING;

  useEffect(() => {
    if (sheetDefinition) {
      const {
        sheetDefinitionType,
        measureFormatType,
        measureFieldType,
        measureSource,
        measureFormula,
        fieldName,
        measureName,
        measureId,
        startDate,
        endDate
      } = sheetDefinition;
      setIsDefaultBMChecked(sheetDefinitionType === SheetDefinitionType.DEFAULT_BALANCING_METRIC);
      const realMeasureFieldType =
        measureFieldType === MeasureFieldType.FORMULA ? MeasureFieldType.CALCULATED : measureFieldType;
      let formulaWithMeasureName;
      if (isCalcColumnUpdateEnabled && measureFormula) {
        formulaWithMeasureName = replaceFormulaIdsWithNames(measureFormula, sheetMeasuresNameLookupMap);
      }
      const formatedFormula = formatFormulaToDisplayFormat(formulaWithMeasureName);
      setFormInitialValues({
        ...formInitialValues,
        measureName,
        measureId,
        measureFormatType: { key: measureFormatType, value: measureFormatType },
        measureFieldType: { key: realMeasureFieldType, value: realMeasureFieldType },
        measureSource: { key: measureSource, value: measureSource },
        startDate,
        endDate,
        calculatedFieldFormula: formatedFormula ? replaceBracesWithQuotes(formatedFormula) : '',
        fieldName: { key: fieldName, value: fieldName },
        isDefaultBalancingMetric: sheetDefinitionType === SheetDefinitionType.DEFAULT_BALANCING_METRIC,
        isBalancingMetric:
          sheetDefinitionType === SheetDefinitionType.BALANCING_METRIC ||
          sheetDefinitionType === SheetDefinitionType.DEFAULT_BALANCING_METRIC
      });
    }
    if (hierarchyData && sheetDefinition) {
      setSelectedPinnedHierarchy(
        hierarchyData?.getDeploymentModelSpec?.pinnedHierarchies.find(
          (pinnedHierarchy) => pinnedHierarchy.hierarchyId.toString() === sheetDefinition.fieldId
        )
      );
    }
  }, [sheetDefinition, hierarchyData]);

  useEffect(() => {
    if (selectedPinnedHierarchy) {
      setFormInitialValues({
        ...formInitialValues,
        fieldId: {
          key: selectedPinnedHierarchy.name,
          value: selectedPinnedHierarchy.hierarchyId,
          type: selectedPinnedHierarchy.hierarchyType
        }
      });
    }
  }, [selectedPinnedHierarchy]);

  const isLastStep = activeStep === steps(selectedFieldType).length - 1;

  const submitForm = async (values, actions) => {
    actions.setSubmitting(false);
    const {
      measureName,
      measureCategory,
      measureFieldType,
      measureFormatType,
      measureSource,
      fieldId,
      fieldName,
      measureId,
      isDefaultBalancingMetric,
      isBalancingMetric,
      calculatedFieldFormula,
      startDate,
      endDate
    } = values;
    const getSheetDefinitionType = () => {
      let type = SheetDefinitionType.NOT_BALANCING_METRIC;
      if (isBalancingMetric && isDefaultBalancingMetric) {
        type = SheetDefinitionType.DEFAULT_BALANCING_METRIC;
      } else if (isBalancingMetric) {
        type = SheetDefinitionType.BALANCING_METRIC;
      }
      return type;
    };

    const isHistoricalMeasure = measureFieldType?.value === MeasureFieldType.HISTORICAL;

    const sheetDefinition = {
      measureName,
      measureCategory,
      measureFieldType:
        measureFieldType?.value === MeasureFieldType.CALCULATED ? MeasureFieldType.FORMULA : measureFieldType.value,
      measureFormatType: measureFormatType?.value,
      measureSource: isHistoricalMeasure ? MeasureSource.ACTIVITY : measureSource?.value,
      fieldId: fieldId?.value,
      fieldName: fieldName?.value,
      measureId,
      measureFormula: null,
      sheetDefinitionType: getSheetDefinitionType(),
      startDate: isHistoricalMeasure ? startDate : null,
      endDate: isHistoricalMeasure ? endDate : null
    };

    if (measureFieldType?.value === MeasureFieldType.CALCULATED) {
      const formattedFormula = calculatedFieldFormula
        .replace(/"([^"]*)"/g, '{$1}')
        .replace(/'([^']*)'/g, '{$1}')
        .replace(/\n/g, '');

      sheetDefinition.measureFormula = isCalcColumnUpdateEnabled
        ? replaceFormulaNamesWithIds(formatFormulaToPeriodicityFunction(formattedFormula), sheetMeasuresIdLookupMap)
        : formattedFormula;
    }

    if (typeof measureName != 'string') {
      sheetDefinition.measureName = measureName?.['key'];
    }

    await upsertSheet({
      variables: {
        input: {
          dataSheets: [
            {
              sheetId: selectedSheet.sheetId,
              sheetDefinitions: [sheetDefinition]
            }
          ]
        }
      }
    });
    setDataSheetDrawerFormType(DataSheetDrawerFormType.NEW);
  };

  const handleSubmit = async (values, actions) => {
    if (isLastStep) {
      const isHistoricalField = selectedFieldType === MeasureFieldType.HISTORICAL;
      const dateRangeFullOrEmpty = (values?.startDate && values?.endDate) || (!values?.startDate && !values?.endDate);
      if ((!isHistoricalField && formulaErrorCount === 0) || (isHistoricalField && dateRangeFullOrEmpty)) {
        submitForm(values, actions);
      }
    }
  };

  const handleNext = (values, validateForm, isValid, setFieldTouched) => {
    if (
      values.measureFieldType.value === MeasureFieldType.LOADED ||
      values.measureFieldType.value === MeasureFieldType.CALCULATED ||
      values.measureFieldType.value === MeasureFieldType.HISTORICAL
    ) {
      // setFieldTouched for error messages to show up
      setFieldTouched('measureName');
      setFieldTouched('measureFieldType');
      setFieldTouched('measureFormatType');
      validateForm();
      if (isValid) {
        setActiveStep((prevState) => prevState + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const handleCancel = () => {
    setActiveStep(0);
    setDataSheetDrawerState(DataSheetDrawerState.CLOSE);
    setDataSheetDrawerFormType(DataSheetDrawerFormType.NEW);
    setSheetDefinition(null);
  };

  const headerText = () => {
    if (dataSheetDrawerFormType === DataSheetDrawerFormType.EXISTING) {
      return formatMessage('ADD_EXISTING_FIELD');
    } else {
      return sheetDefinition ? formatMessage('EDIT_FIELD') : formatMessage('CREATE_FIELD');
    }
  };

  const confirmButtonText = sheetDefinition ? formatMessage('UPDATE') : formatMessage('ADD');
  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validationSchema={currentValidationSchema}
      validateOnBlur
      validateOnMount
      enableReinitialize
    >
      {({ setFieldTouched, values, validateForm, isValid, resetForm, errors }) => {
        return (
          <Form className={b('formContainer')}>
            <div data-testid="measure-form" className={b('formFieldContainer')}>
              <div className={b('formContent')}>
                <div data-testid="form-header" className={b('formHeader')}>
                  <HTMLHeading bold tagLevel={'h3'} styleLevel={'h5'} text={headerText()} />
                  <div>
                    <div className={Classes.POPOVER2_DISMISS}>
                      <IconButton
                        type={'button'}
                        icon={<Close size={24} />}
                        onClick={handleCancel}
                        testId={'close-button'}
                        title={formatMessage('CLOSE')}
                      />
                    </div>
                  </div>
                </div>
                {existingField ? (
                  <SheetsExistingFieldFormFields />
                ) : (
                  <SheetsAddFieldFormFields
                    step={activeStep}
                    selectedFieldType={selectedFieldType}
                    setSelectedFieldType={setSelectedFieldType}
                    data-testid="custom-measure-form-fields"
                    isEditable={sheetDefinition?.defaultMeasureId ? false : true}
                    hierarchyData={hierarchyData}
                    isDefaultBalancingMetric={isDefaultBMChecked}
                    setFormulaErrorCount={setFormulaErrorCount}
                  />
                )}
              </div>
              <div className={b('formFooter')}>
                <div className={b('buttons', { firstPage: !activeStep })}>
                  <div className={b('button')}>
                    <div className={Classes.POPOVER2_DISMISS}>
                      <TextButton
                        type="button"
                        text={formatMessage('CANCEL')}
                        minimal
                        onClick={handleCancel}
                        testId={'cancel-button'}
                      />
                    </div>
                  </div>
                  <div className={b('rightSideButtons')}>
                    {activeStep !== 0 && (
                      <div className={b('button')}>
                        <TextButton
                          type="button"
                          text={formatMessage('BACK')}
                          onClick={() => {
                            handleBack();
                            resetForm({ values: { ...values, measureSource: null, fieldId: null, fieldName: null } });
                          }}
                          testId={'back-button'}
                        />
                      </div>
                    )}
                    {isLastStep ? (
                      <div className={b('button')}>
                        <TextButton
                          type="submit"
                          text={confirmButtonText}
                          intent="primary"
                          loading={upsertSheetLoading}
                          key="submit"
                          testId={'add-button'}
                          disabled={!!errors.calculatedFieldFormula}
                        />
                      </div>
                    ) : (
                      <div className={b('button')}>
                        <TextButton
                          type="button"
                          onClick={() => handleNext(values, validateForm, isValid, setFieldTouched)}
                          text={formatMessage('NEXT')}
                          intent="primary"
                          loading={upsertSheetLoading}
                          key="button"
                          testId={'next-button'}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SheetsAddFieldForm;
