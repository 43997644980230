import React, { useEffect, useState } from 'react';

import { FieldProps } from 'formik';

import { SearchableSelectMenuItem } from 'components/models';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CurrencySelect.module.pcss';

const b = block(style);

interface CurrencySelectProps extends FieldProps {
  items: SearchableSelectMenuItem[];
  currenciesInUse?: string[];
  disabledItems?: SearchableSelectMenuItem[];
  disabled?: boolean;
  disabledIcon?: boolean;
  showErrors?: boolean;
  showErrorStyle?: boolean;
  requireTouchForError?: boolean;
  allowPopperEventListeners?: boolean;
  allowFlip?: boolean;
}

const getDividerIndex = (currenciesInUse: string[]): number => {
  return currenciesInUse?.length ? currenciesInUse.length - 1 : null;
};

const CurrencySelect: React.FC<CurrencySelectProps> = ({
  items,
  field,
  form,
  meta,
  currenciesInUse = [],
  disabledItems = [],
  disabled = false,
  disabledIcon = true,
  showErrors = true,
  showErrorStyle = false,
  requireTouchForError = true,
  allowPopperEventListeners = true,
  allowFlip = true
}: CurrencySelectProps) => {
  const [currencyItems, setCurrencyItems] = useState(items);
  const [dividerIndex, setDividerIndex] = useState(getDividerIndex(currenciesInUse));

  useEffect(() => {
    setDividerIndex(getDividerIndex(currenciesInUse));
  }, [currenciesInUse]);

  const searchCurrency = (searchString) => {
    // we need to recompute the dividerIndex for the new filtered currency list
    let newDividerIndex = -1;

    const filteredCurrencyList = currencyItems.filter(({ key, value }) => {
      const doesCurrencyMatchSearch = key.toLowerCase().includes(searchString.toLowerCase());

      if (doesCurrencyMatchSearch) {
        const isCurrencyInUse = currenciesInUse.includes(value);

        if (isCurrencyInUse) {
          newDividerIndex++;
        }

        return true;
      } else {
        return false;
      }
    });

    setCurrencyItems(filteredCurrencyList);
    setDividerIndex(newDividerIndex === -1 ? null : newDividerIndex);
  };

  const resetSearch = () => {
    setDividerIndex(getDividerIndex(currenciesInUse));
    setCurrencyItems(items);
  };

  return (
    <SearchableSelectMenu
      initialLoadingComplete={true}
      items={currencyItems}
      showErrors={showErrors}
      showErrorStyle={showErrorStyle}
      placeHolderText={formatMessage('SELECT_CURRENCY')}
      onSearchReset={resetSearch}
      onSearch={searchCurrency}
      disabledItems={disabledItems}
      disabledIcon={disabledIcon}
      requireTouchForError={requireTouchForError}
      allowPopperEventListeners={allowPopperEventListeners}
      allowFlip={allowFlip}
      dividerIndex={dividerIndex}
      theme="default"
      className={b()}
      disabled={disabled}
      field={field}
      form={form}
      meta={meta}
    />
  );
};

export default CurrencySelect;
