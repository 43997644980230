// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@apollo/client';

import {
  GetSheetDefinitionsVariables,
  GetSheetDefinitions,
  GetSheetDefinitions_getDeploymentModelSpec_dataSheets
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_SHEET_DEFINITIONS } from 'app/graphql/queries/getSheetDefinitions';

export const useGetSheetDefinitions = (
  variables: GetSheetDefinitionsVariables
): {
  sheetDefinitions: GetSheetDefinitions_getDeploymentModelSpec_dataSheets[];
} => {
  const { data: sheetDefinitions } = useQuery<GetSheetDefinitions, GetSheetDefinitionsVariables>(
    GET_SHEET_DEFINITIONS,
    {
      fetchPolicy: 'no-cache',
      variables,
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
      }
    }
  );

  return {
    sheetDefinitions: sheetDefinitions?.getDeploymentModelSpec?.dataSheets
      ? sheetDefinitions?.getDeploymentModelSpec?.dataSheets
      : null
  };
};
