// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".r1iIU {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-gap: 0 16px;\n}\n\n  .pgiJG {\n    margin-bottom: 8px;\n    display: flex;\n    flex-direction: row;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommandCenterHierarchyPanelContentAttributeFields": "r1iIU",
	"CommandCenterHierarchyPanelContentAttributeFields_formFieldLabel": "pgiJG"
};
export default ___CSS_LOADER_EXPORT___;
