import React, { useEffect, useState } from 'react';

import {
  OperationVariables,
  QueryLazyOptions,
  // eslint-disable-next-line no-restricted-imports
  useLazyQuery
} from '@apollo/client';
import { UserFilled } from '@carbon/icons-react';
import { FieldProps } from 'formik';

import { SearchableSelectMenuItem } from 'components/models';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import { useScope } from 'app/contexts/scopeProvider';

import { MENU_INFINITE_SCROLL_ITEM_HEIGHT } from 'app/global/variables';

import {
  GetMemberList,
  GetMemberListVariables,
  GetMemberList_getMemberList,
  SearchMember,
  SearchMember_searchMember,
  SortDirection
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_MEMBER_LIST } from 'app/graphql/queries/getMemberList';
import { SEARCH_MEMBER } from 'app/graphql/queries/searchMember';

import { memberToString } from 'utils/helpers/index';
import { handleMemberListMerge } from 'utils/helpers/listMergeUtils';
import { formatMessage } from 'utils/messages/utils';

interface SellerSelectProps extends FieldProps {
  renderTarget?: (onClick: (e) => void, menuOpen: boolean, disabled: boolean) => React.ReactNode;
  shouldDisableExistingSellers?: boolean;
  isSellerDisabled?: (memberId: number) => boolean;
  allowPreventOverflow?: boolean;
  showEmployeeId?: boolean;
}

const SellerSelect: React.FC<SellerSelectProps> = (props: SellerSelectProps) => {
  const {
    form: { values },
    field: { value: fieldValue },
    renderTarget,
    shouldDisableExistingSellers = true,
    isSellerDisabled,
    allowPreventOverflow,
    showEmployeeId = true
  } = props;

  const { selectedTenant } = useScope();

  const [sellers, setSellers] = useState<SearchableSelectMenuItem[]>();
  const [isSellersLoaded, setIsSellersLoaded] = useState<boolean>(false);
  const [totalRows, setTotalRows] = useState<number>();
  const [disabledItems, setDisabledItems] = useState<SearchableSelectMenuItem[]>();

  // calculate how many rows to load on each fetch
  const viewportHeight = window.innerHeight;
  const pageSize = Math.ceil((viewportHeight * 0.2) / MENU_INFINITE_SCROLL_ITEM_HEIGHT) * 2;

  // TODO TQP-7023 use hooks
  const [getSellers, { data: sellerResults, loading: sellersLoading, fetchMore }] = useLazyQuery<
    GetMemberList,
    GetMemberListVariables
  >(GET_MEMBER_LIST, {
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  // TODO TQP-7023 use hooks
  const [searchMember, { data: searchResults, loading: searchResultsLoading }] = useLazyQuery<SearchMember>(
    SEARCH_MEMBER,
    {
      fetchPolicy: 'cache-and-network',
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
      }
    }
  );

  // Convert data we get from the server to sellers and total rows
  const setSellersAndTotalRows = (sellerData: SearchMember_searchMember | GetMemberList_getMemberList) => {
    if (sellerData) {
      const sellerItems = sellerData.memberList?.map((member) => ({
        key: memberToString(member, showEmployeeId),
        value: member.memberId
      }));

      if (sellerData.totalCount !== totalRows) {
        setTotalRows(sellerData.totalCount);
      }

      const disabledSellers = [];

      if (isSellerDisabled) {
        // use custom function provided by consumer to disable individual seller(s)
        disabledSellers.push(...sellerItems?.filter((seller) => isSellerDisabled(seller?.value)));
      }

      if (shouldDisableExistingSellers) {
        // disable seller(s) currently selected
        disabledSellers.push(...sellerItems?.filter((seller) => seller?.value === fieldValue?.value));
      }

      setDisabledItems(disabledSellers);
      setSellers(sellerItems);
      setIsSellersLoaded(true);
    }
  };

  useEffect(() => {
    if (!sellersLoading && sellerResults?.getMemberList) {
      setSellersAndTotalRows(sellerResults.getMemberList);
    }
  }, [sellerResults, sellersLoading, values?.reorderableListItems]);

  useEffect(() => {
    if (searchResultsLoading) {
      setSellers(null);
      setIsSellersLoaded(false);
      setTotalRows(0);
    }
    if (!searchResultsLoading && searchResults?.searchMember) {
      setSellersAndTotalRows(searchResults.searchMember);
    }
  }, [searchResults, searchResultsLoading]);

  const resetSearch = () => {
    if (sellerResults?.getMemberList) {
      setSellersAndTotalRows(sellerResults.getMemberList);
    } else {
      setSellers([]);
      setIsSellersLoaded(false);
    }
  };

  const onSelectOpen = () => {
    if (!sellerResults) {
      getSellers({
        variables: {
          tenantId: selectedTenant?.id,
          endRow: pageSize,
          startRow: 1,
          sorting: {
            sortModel: [
              {
                colId: 'lastName',
                sort: SortDirection.asc
              }
            ]
          }
        }
      });
    } else if (sellerResults.getMemberList) {
      setSellersAndTotalRows(sellerResults.getMemberList);
    }
  };

  return (
    <SearchableSelectMenu
      items={sellers}
      renderTarget={renderTarget}
      allowPopperEventListeners={false}
      showErrors={false}
      placeHolderText={formatMessage('SELECT')}
      disabledItems={disabledItems}
      initialLoadingComplete={isSellersLoaded}
      onSelectOpen={onSelectOpen}
      onSearch={(options) => searchMember(options as QueryLazyOptions<OperationVariables>)}
      queryVariables={{ tenantId: selectedTenant?.id }}
      onSearchReset={resetSearch}
      shouldSupportInfiniteScroll
      infiniteScrollProps={{
        onFetchMore: (startRow, endRow) =>
          fetchMore({
            variables: {
              startRow,
              endRow
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousResult;
              return handleMemberListMerge(previousResult, fetchMoreResult);
            }
          }),
        totalRows,
        pageSize,
        isLoading: sellersLoading
      }}
      staticIcon={<UserFilled data-testid="user-icon" />}
      allowPreventOverflow={allowPreventOverflow}
      showIconInField={false}
      smallIcon
      data-testid="seller-select"
      theme="default"
      {...props}
    />
  );
};

export default SellerSelect;
