import React from 'react';

import { Card, Overlay, ProgressBar } from '@blueprintjs/core';
import { Close } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import Icon from 'components/Icon/Icon';

import block from 'utils/bem-css-modules';
import { config } from 'utils/config';
import { formatMessage } from 'utils/messages/utils';

import style from './StatusToast.module.pcss';

const b = block(style);

export interface StatusToastProps {
  isOpen: boolean;
  description: string;
  value: number;
  onClose: () => void;
  canCancelOperation?: boolean;
  onCancelOperation?: () => void;
  canViewOperation?: boolean;
  onViewOperation?: () => void;
  heading?: string;
}

const StatusToast: React.FC<StatusToastProps> = ({
  isOpen,
  description,
  value,
  onClose,
  canCancelOperation = true,
  onCancelOperation,
  canViewOperation = false,
  onViewOperation,
  heading = formatMessage('STATUS')
}: StatusToastProps) => {
  if (value < 0 || value > 1) {
    console.warn('value provided to StatusToast must be a number between 0 and 1.');
  }

  return (
    <Overlay
      className={b()}
      isOpen={isOpen}
      hasBackdrop={false}
      autoFocus={false}
      usePortal={config.NODE_ENV !== 'test'}
      data-testid="status-toast"
    >
      <Card className={`bp3-elevation-3 ${b('card')}`}>
        <div className={b('title')}>
          <HTMLHeading tagLevel="h5">{heading}</HTMLHeading>
          <IconButton
            onClick={onClose}
            icon={<Icon icon={<Close />} />}
            type="button"
            testId={'status-toast-close-button'}
            title={formatMessage('CLOSE')}
          />
        </div>
        <div className={b('body')}>
          <div className={b('descriptionContainer')}>
            <span>{description}</span>
          </div>
          <div className={b('progressContainer')}>
            <ProgressBar className={b('progressBar')} stripes={false} value={value} />
            <span className={b('progressPercentage')}>{`${Math.floor(value * 100)}%`}</span>
            {canCancelOperation && (
              <span className={b('link')} onClick={onCancelOperation}>
                {formatMessage('CANCEL')}
              </span>
            )}
          </div>
          {canViewOperation && (
            <div className={b('view')}>
              <span className={b('link')} onClick={onViewOperation}>
                {formatMessage('VIEW_WITH_ELLIPSIS')}
              </span>
            </div>
          )}
        </div>
      </Card>
    </Overlay>
  );
};

export default StatusToast;
