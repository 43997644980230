// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7JGCh {\n  display: flex;\n  flex-direction: column;\n  width: 670px;\n  font-size: 14px;\n  font-stretch: normal;\n  font-style: normal;\n  letter-spacing: normal\n}\n._7JGCh.bp3-card {\n    padding: 20px 35px;\n  }\n.PCDyW {\n    width: 100%;\n  }\n.Tpv9h {\n    padding-top: 10px;\n  }\n.tvZY9 {\n    display: flex;\n  }\n.TfDqo {\n    flex: 2;\n  }\n.TfDqo .bp3-input-action {\n      padding: 12px 6px;\n    }\n._0go\\+P {\n    padding-top: 8px;\n    padding-bottom: 10px;\n  }\n.pl6gg {\n    font-weight: 600;\n    line-height: 1.14;\n    flex: 1;\n    padding-left: 5px;\n    padding-top: 1em;\n  }\n.aZ46V {\n    font-weight: normal;\n    line-height: 1.43;\n    flex: 2;\n    padding-top: 1em;\n  }\n.WlqF6 {\n    display: flex;\n  }\n.Ao0HN {\n    color: rgb(var(--color-gray-3));\n    padding: 10px 0 20px 5px;\n    width: 75%;\n  }\n.tgfso {\n    color: rgb(var(--color-cobalt-3));\n  }\n.HCkpG {\n    display: flex;\n    justify-content: flex-end;\n  }\n.veLmt {\n    margin-top: var(--m);\n    display: flex;\n    justify-content: flex-end;\n  }\n.bCVk9 {\n    padding: 0 10px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PasswordManagementCard": "_7JGCh",
	"PasswordManagementCard_divider": "PCDyW",
	"PasswordManagementCard_newPasswordContainer": "Tpv9h",
	"PasswordManagementCard_passwordRow": "tvZY9",
	"PasswordManagementCard_passwordInput": "TfDqo",
	"PasswordManagementCard_passwordLastChangedText": "_0go+P",
	"PasswordManagementCard_informationTitle": "pl6gg",
	"PasswordManagementCard_informationValue": "aZ46V",
	"PasswordManagementCard_informationSection": "WlqF6",
	"PasswordManagementCard_passwordInstructions": "Ao0HN",
	"PasswordManagementCard_editbutton": "tgfso",
	"PasswordManagementCard_footer": "HCkpG",
	"PasswordManagementCard_changePasswordFooter": "veLmt",
	"PasswordManagementCard_cancelButtonContainer": "bCVk9"
};
export default ___CSS_LOADER_EXPORT___;
