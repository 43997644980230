import { useEffect } from 'react';

import { emptyUserProfile, useUser } from 'app/core/userManagement/userProvider';

import { GetUserSpec, GetUserTenant } from 'app/graphql/generated/graphqlApolloTypes';

import { UserRoleType } from 'app/models';

import { EmbeddedMapSlugs } from './useEmbeddedMapSlugs';

export const useSetupUserForEmbeddedMap = (
  userSpec: GetUserTenant | GetUserSpec | null,
  slugs: EmbeddedMapSlugs
): void => {
  const { setUserProfile, setUserRole, setUserMembershipSpecs } = useUser();
  useEffect(() => {
    const tenantSpec = userSpec?.getUserSpec.membershipSpecs.find((tenant) => tenant.tenantSlug === slugs.tenantSlug);
    setUserRole((tenantSpec?.['systemRoleName'] as UserRoleType) ?? UserRoleType.NONE);

    setUserProfile(
      userSpec?.getUserSpec
        ? {
            tenant: { id: tenantSpec?.tenantId, slug: tenantSpec?.tenantSlug },
            subjectId: userSpec.getUserSpec.subjectId,
            firstName: userSpec.getUserSpec.firstName,
            lastName: userSpec.getUserSpec.lastName,
            emailAddress: userSpec.getUserSpec.emailAddress,
            isSupportUser: userSpec.getUserSpec.isSupportUser
          }
        : emptyUserProfile
    );

    // embedded map does not yet require spec PCs (normally only used for determining PCs to select from)
    setUserMembershipSpecs(
      userSpec?.getUserSpec.membershipSpecs.map((spec) => ({
        ...spec,
        systemRoleName: spec.systemRoleName as UserRoleType,
        planningCycles: []
      })) ?? []
    );
  }, [userSpec, slugs.tenantSlug]);
};
