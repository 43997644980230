// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tieGc {\n    display: flex;\n    justify-content: flex-start;\n    height: auto;\n    margin-bottom: 10px;\n  }\n  ._5ZnLT {\n    padding-top: 5px;\n  }\n  ._3lSmA {\n    padding-right: 10px;\n  }\n  .zlxrq {\n    flex: 1;\n  }\n  .wANln {\n    font-size: inherit;\n    font-weight: bold;\n    color: rgb(var(--color-dark-gray-5));\n  }\n  .FCkKX {\n    margin-left: 5px;\n    font-size: 13px;\n    color: rgb(var(--color-gray-4));\n  }\n  .OSIiU {\n    color: rgb(var(--color-cobalt-3));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Comment_commentContainer": "tieGc",
	"Comment_commentText": "_5ZnLT",
	"Comment_profilePicture": "_3lSmA",
	"Comment_commentBody": "zlxrq",
	"Comment_userName": "wANln",
	"Comment_date": "FCkKX",
	"Comment_commentMention": "OSIiU"
};
export default ___CSS_LOADER_EXPORT___;
