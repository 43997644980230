// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fQIOT {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n  .fQIOT .bp3-popover2-target {\n    width: 100%;\n  }\n\n  .RVSXT {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: block;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MessageTooltipCellRenderer": "fQIOT",
	"MessageTooltipCellRenderer_cellContent": "RVSXT"
};
export default ___CSS_LOADER_EXPORT___;
