import React from 'react';

import { Close } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

import { LineRulerUnit } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage, formatNumber } from 'utils/messages/utils';

import style from './LineRulerDistanceIndicator.module.pcss';

const b = block(style);

interface LineRulerDistanceIndicatorProps {
  lineDistance: number;
  unit: LineRulerUnit;
  onDeleteMeasureLine: () => void;
  onChangeLineUnit: (unit: LineRulerUnit) => void;
}

const LineRulerDistanceIndicator: React.FC<LineRulerDistanceIndicatorProps> = ({
  lineDistance,
  unit,
  onDeleteMeasureLine,
  onChangeLineUnit
}) => {
  const toggleSelection = () => {
    const newUnit = unit === 'miles' ? 'kilometers' : 'miles';
    onChangeLineUnit(newUnit);
  };

  return (
    <div className={b()}>
      <div className={b('distance')}>{formatNumber(Math.round(lineDistance))}</div>
      <div className={b('unitControl')}>
        <ToggleSwitch
          small
          rightToggleLabel={formatMessage('MILES_ABBREVIATION')}
          leftToggleLabel={formatMessage('KILOMETERS_ABBREVIATION')}
          onLeftToggle={toggleSelection}
          onRightToggle={toggleSelection}
          currentSelection={unit === 'miles'}
        />
      </div>
      <div>
        <IconButton
          small
          icon={<Close />}
          onClick={onDeleteMeasureLine}
          type="button"
          testId="delete-distance-measure-indicator"
        />
      </div>
    </div>
  );
};

export default LineRulerDistanceIndicator;
