import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Dialog, Radio, Spinner } from '@blueprintjs/core';

import LocationGroupForm from 'app/components/TerritoryMap/PinSetDialog/LocationGroupForm';
import PinSetCustomizer from 'app/components/TerritoryMap/PinSetDialog/PinSetCustomizer';

import { useLocationGroups } from 'app/graphql/hooks/useLocationGroups';

import { PinSet, PinSetSource } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './PinSetDialog.module.pcss';

const b = block(style);

interface PinSetDialogProps {
  onClose: () => void;
  pinSets: PinSet[];
}

const PinSetDialog: React.FC<PinSetDialogProps> = ({ onClose, pinSets }: PinSetDialogProps) => {
  const [pinSetSource, setPinSeSource] = useState<PinSetSource>(PinSetSource.NEW);
  const [uploadedLocationGroupName, setUploadedLocationGroupName] = useState<string | null>(null);
  const { refetch: refetchLocationGroup, loading: loadingLocationGroups, locationGroups } = useLocationGroups();

  const handlePinSetSourceChange = (event) => {
    setPinSeSource(event.currentTarget.value);
  };

  useEffect(() => {
    if (uploadedLocationGroupName) refetchLocationGroup();
  }, [uploadedLocationGroupName]);

  return (
    <Dialog isOpen portalClassName={b('dialog')} onClose={onClose} title={formatMessage('ADD_PIN_SET')}>
      <div data-testid="add-pin-set-dialog">
        {!uploadedLocationGroupName && (
          <div className={b('radioContainer')} data-testid="radio-container">
            <Radio
              data-testid="new-pin-set-radio"
              name="locationGroupSource"
              checked={pinSetSource === PinSetSource.NEW}
              value={PinSetSource.NEW}
              onChange={handlePinSetSourceChange}
              labelElement={formatMessage('NEW_PIN_SET_TEXT')}
              inline
            />

            <Radio
              data-testid="existing-pin-set-radio"
              name="locationGroupSource"
              checked={pinSetSource === PinSetSource.EXISTING}
              value={PinSetSource.EXISTING}
              onChange={handlePinSetSourceChange}
              labelElement={
                <span className={b('radioLabel')}>
                  {formatMessage('EXISTING_PIN_SET_TEXT')}
                  {loadingLocationGroups && <Spinner size={14} />}
                </span>
              }
              disabled={loadingLocationGroups || locationGroups.length === 0}
              inline
            />
          </div>
        )}

        {pinSetSource === PinSetSource.NEW && !uploadedLocationGroupName && (
          <LocationGroupForm locationGroups={locationGroups} onUploadFinish={setUploadedLocationGroupName} />
        )}
        {(pinSetSource === PinSetSource.EXISTING || uploadedLocationGroupName) && (
          <PinSetCustomizer
            forcedLocationGroupName={uploadedLocationGroupName}
            locationGroups={locationGroups}
            loadingLocationGroups={loadingLocationGroups}
            pinSets={pinSets}
            onPinSetCreated={onClose}
            onCancel={onClose}
          />
        )}
      </div>
    </Dialog>
  );
};

export default PinSetDialog;
