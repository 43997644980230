import React, { useState } from 'react';

import { Compare, SettingsAdjust } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import CompareModeDialog from 'app/components/TerritoryMap/CompareModeDialog';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CompareModeMenu.module.pcss';

const b = block(style);

export const CompareModeMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={b()}>
      <Compare />
      <h2 className={b('header')}>{formatMessage('COMPARE_MODE')}</h2>
      <IconButton
        icon={<SettingsAdjust />}
        type="button"
        testId="compare-mode-button"
        tooltipText={formatMessage('COMPARE_MODE')}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && <CompareModeDialog onClose={() => setIsOpen(false)} />}
    </div>
  );
};
