// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kfXWU {\n  display: flex;\n  justify-content: center;\n}\n\n  .Foux\\+ {\n    width: 300px;\n    height: 250px;\n    margin: var(--m) !important;\n    background-color: rgb(var(--color-light-gray-4));\n    border-radius: var(--xs) var(--xs) 0 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CyclesLoadingCard": "kfXWU",
	"CyclesLoadingCard_container": "Foux+"
};
export default ___CSS_LOADER_EXPORT___;
