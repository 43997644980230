import { useState, useCallback, useEffect } from 'react';

import { API } from 'app/api/API';
import { ApiHeaders, BuildHeaderParams } from 'app/api/util';

import { useAuthHeader } from 'app/contexts/AuthHeaderProvider';
import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { AssistantStreamResponseKeys, AssistantStreamResponseMessageTypes, Headers } from 'app/models';

export enum StreamServices {
  ASSISTANT = 'assistant/chat'
}

/**
 * Custom hook for streaming data from an API endpoint.
 * @param endpoint - The endpoint to stream data from.
 * @returns An object containing the streamed data, loading state, error state, and a function to start the stream.
 */
export function useStreamData<Req = never>(endpoint: StreamServices) {
  const [data, setData] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [streamStarted, setStreamStarted] = useState(false);
  const [params, setParams] = useState<{ endpoint: string; requestData: Req; headers?: Headers } | null>(null);

  const { getAuthHeader } = useAuthHeader();
  const { selectedTenant, selectedDeploymentModelId, selectedPlanningCycle } = useScope();
  const { selectedBattleCardId } = useBattleCard();

  /**
   * Builds the API headers based on the current authentication header and other selected parameters.
   * @returns A promise that resolves to the API headers.
   */
  const buildAndSetHeaders = useCallback(async (): Promise<ApiHeaders> => {
    const headerParams: BuildHeaderParams = {
      authToken: await getAuthHeader(),
      selectedTenant,
      selectedDeploymentModel: selectedDeploymentModelId,
      selectedPlanningCycle,
      selectedBattlecard: selectedBattleCardId
    };

    return API.buildHeaders(headerParams);
  }, [getAuthHeader, selectedTenant, selectedDeploymentModelId, selectedPlanningCycle, selectedBattleCardId]);

  const streamData = useCallback(async () => {
    if (!streamStarted || !params) return;
    setLoading(true);
    setError(null);

    try {
      const headers = await buildAndSetHeaders();
      const api = new API();
      await api.streamPost(params.endpoint, params.requestData, headers, (receivedData: string) => {
        checkForStreamError(receivedData);
        setData((prevData) => [...prevData, receivedData]);
      });
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
      setData([]);
    }
  }, [streamStarted, params, buildAndSetHeaders]);

  useEffect(() => {
    if (streamStarted) {
      streamData();
    }
    return () => {
      setStreamStarted(false);
      setData([]);
    };
  }, [streamData, streamStarted]);

  const handleStartStream = (requestData: Req, headers?: Headers) => {
    setParams({ endpoint, requestData, headers });
    setStreamStarted(true);
  };

  return { data, loading, error, handleStartStream };
}

const checkForStreamError = (item: string): void => {
  const dataEntries = item.split(AssistantStreamResponseKeys.DATA).filter((entry) => entry.trim() !== '');
  dataEntries.forEach((entry) => {
    const parsedData = JSON.parse(entry);
    if (parsedData.messageType === AssistantStreamResponseMessageTypes.ERROR) {
      const streamError = JSON.parse(parsedData.errorResponse);
      throw new Error(streamError.error);
    }
  });
};
