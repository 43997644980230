import React from 'react';

import { IntlProvider } from 'react-intl';

import { BootstrapDisabler } from 'app/components/BootstrapDisabler/BootstrapDisabler';

import AuthApolloWrapper from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { ApolloHeaderProvider } from 'app/contexts/ApolloHeaderProvider';
import { CurrencyProvider } from 'app/contexts/currencyProvider';
import { LocalizationProvider } from 'app/contexts/localizationProvider';
import { PermissionsProvider } from 'app/contexts/permissionsProvider';
import { ScopeProvider } from 'app/contexts/scopeProvider';
import { UserMenuProvider } from 'app/contexts/userMenuProvider';

import { UserProvider } from 'app/core/userManagement/userProvider';

import { getLocale } from 'utils/helpers/getLocale';

export const SharedProviders: React.FC = ({ children }) => (
  <IntlProvider locale={getLocale()} defaultLocale={getLocale()}>
    <CurrencyProvider>
      <LocalizationProvider>
        <UserProvider>
          <ApolloHeaderProvider>
            <ScopeProvider>
              <UserMenuProvider>
                <AuthApolloWrapper>
                  <PermissionsProvider>
                    <BootstrapDisabler>{children}</BootstrapDisabler>
                  </PermissionsProvider>
                </AuthApolloWrapper>
              </UserMenuProvider>
            </ScopeProvider>
          </ApolloHeaderProvider>
        </UserProvider>
      </LocalizationProvider>
    </CurrencyProvider>
  </IntlProvider>
);
