// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O1R-t {\n    font-weight: normal;\n    font-size: 0.75rem;\n    margin-bottom: 0.25rem;\n    margin-top: 16px;\n  }\n  ._0kwQK {\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n    grid-gap: 20px;\n    gap: 20px;\n  }\n  /* Undoing Bootstrap styles */\n  .U0KeU {\n    font-weight: unset;\n    margin-bottom: unset;\n    display: unset;\n  }\n  .HEh7w .bp3-label {\n    margin-top: 32px !important;\n  }\n  .HEh7w .bp3-input:disabled {\n    box-shadow: none !important;\n  }\n  .wma1S {\n    display: flex;\n  }\n  .NJJD4 {\n    -webkit-appearance: none;\n       -moz-appearance: none;\n            appearance: none;\n  }\n  ._5Npwx {\n      background-color: var(--map-background-color);\n      border-radius: 4px;\n    }\n  .P4siB {\n    height: 20px;\n    color: rgb(var(--color-red-3));\n  }\n  .ucGOr {\n    display: flex;\n  }\n  .btNwm {\n    display: flex;\n    margin-bottom: 0;\n    padding: 11px;\n    cursor: pointer;\n  }\n  .Yx4X\\+ {\n      background-color: var(--map-background-color);\n      border-radius: 4px;\n    }\n  .sv9bF {\n    height: 16px;\n    width: 16px;\n    border-radius: 50%;\n    cursor: pointer;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PinSetCustomizer_label": "O1R-t",
	"PinSetCustomizer_buttons": "_0kwQK",
	"PinSetCustomizer_locationGroupLabel": "U0KeU",
	"PinSetCustomizer": "HEh7w",
	"PinSetCustomizer_iconWrapper": "wma1S",
	"PinSetCustomizer_radioField": "NJJD4",
	"PinSetCustomizer_icon__selected": "_5Npwx",
	"PinSetCustomizer_validationMessage": "P4siB",
	"PinSetCustomizer_colorWrapper": "ucGOr",
	"PinSetCustomizer_pickerLabel": "btNwm",
	"PinSetCustomizer_coloredCircleWrapper__selectedCircle": "Yx4X+",
	"PinSetCustomizer_coloredCircle": "sv9bF"
};
export default ___CSS_LOADER_EXPORT___;
