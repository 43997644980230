// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhVgI {\n  width: 100%;\n  height: calc(var(--center-content-height) - var(--tab-list-height));\n}\n\n  .VaHjn {\n    padding: 0 var(--data-panel-padding) 0 var(--data-panel-padding);\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n\n  .YTZK- {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin: var(--l) 0;\n  }\n\n.PhVgI.ag-theme-alpine .ag-root-wrapper {\n    border: 0;\n  }\n\n.PhVgI.ag-theme-alpine .ag-row {\n    cursor: pointer;\n  }\n\n.e4EUO {\n    border: 1px solid rgb(var(--color-light-gray-3));\n    width: 100%;\n    height: calc(var(--center-content-height) - var(--tab-list-height) - var(--l) - var(--l) - 40px);\n    overflow: auto;\n    border-radius: 4px;\n    margin-bottom: var(--m);\n  }\n\n.SMmss {\n    height: 32px; /* to align the icon with the input box */\n    width: 30px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  } /* postcss-bem-linter: ignore */\n\n.SMmss > svg {\n      fill: rgb(var(--color-light-gray-2));\n    }\n\n.plp-j {\n    display: flex;\n    align-items: center;\n  }\n\n.tHqNY {\n    height: var(--data-overview-icon-height);\n    margin: var(--data-panel-small-margin);\n  }\n\n.mdZsG {\n    margin-left: var(--data-panel-medium-margin);\n  }\n\n.litD7 {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-right: var(--data-panel-padding);\n  }\n\n._8W9Zy {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-left: var(--data-panel-padding);\n  }\n\n.PhVgI .ag-pinned-right-cols-container {\n    box-shadow: none;\n  }\n\n.PhVgI .bp3-tag {\n    height: -moz-max-content;\n    height: max-content;\n    align-self: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConfigurationsPanel": "PhVgI",
	"ConfigurationsPanel_content": "VaHjn",
	"ConfigurationsPanel_actionArea": "YTZK-",
	"ConfigurationsPanel_grid": "e4EUO",
	"ConfigurationsPanel_searchIcon": "SMmss",
	"ConfigurationsPanel_tablesActionItems": "plp-j",
	"ConfigurationsPanel_actionItem": "tHqNY",
	"ConfigurationsPanel_button": "mdZsG",
	"ConfigurationsPanel_tablesViewOptions": "litD7",
	"ConfigurationsPanel_tablesFileOptions": "_8W9Zy"
};
export default ___CSS_LOADER_EXPORT___;
