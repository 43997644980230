// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".U1o6n {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    background: white;\n    border: '1px solid red';\n  }\n  .UiBQy {\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n    font-weight: bold;\n    color: rgb(var(--color-dark-gray-2));\n    padding: 6px 14px;\n    background-color: rgb(var(--color-light-gray-5));\n    border-top: 1px solid rgb(var(--color-light-gray-2));\n  }\n  .LFArL {\n    flex: 1 0 auto;\n  }\n  ._9njHf {\n    flex: 1 1 auto;\n    overflow: auto;\n  }\n  /* stylelint-disable plugin/selector-bem-pattern */\n  .m98vR {\n    display: flex;\n    cursor: pointer;\n    height: 18px;\n  }\n  .m98vR > svg {\n      fill: rgb(var(--color-gray-4));\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BattleCardExpandedView_expandedBody": "U1o6n",
	"BattleCardExpandedView_expandedBodyHeader": "UiBQy",
	"BattleCardExpandedView_expandedPanelTitle": "LFArL",
	"BattleCardExpandedView_expandedBodyContent": "_9njHf",
	"BattleCardExpandedView_panelViewDetailIcon": "m98vR"
};
export default ___CSS_LOADER_EXPORT___;
