import React from 'react';

import { Search, ReportData, Time } from '@carbon/icons-react';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AssistantEmptyState.module.pcss';
import AssistantIcon from './AssistantIcon';

const b = block(style);

const AssistantEmptyState = ({ setInputValue }) => {
  return (
    <div className={b()}>
      <div className={b('message')}>
        <AssistantIcon />
        <span className={b('primaryMessage')}>{formatMessage('ASSISTANT_EMPTY_TITLE')}</span>
        <span className={b('secondaryMessage')}>{formatMessage('ASSISTANT_EMPTY_SUBTITLE')}</span>
      </div>
      <div className={b('prompts')}>
        <button
          className={b('prompt')}
          onClick={() => setInputValue(formatMessage('ASSISTANT_PROMPT_GENERAL_INPUT'))}
          data-testid="assistant-empty-general"
        >
          <span className={b('promptTitle')}>
            <Search className={b('promptTitleIcon')} /> {formatMessage('ASSISTANT_PROMPT_GENERAL')}
          </span>
          <span className={b('promptExample')}>{formatMessage('ASSISTANT_PROMPT_GENERAL_EXAMPLE')}</span>
        </button>
        <button
          className={b('prompt')}
          onClick={() => setInputValue(formatMessage('ASSISTANT_PROMPT_HISTORICAL_INPUT'))}
          data-testid="assistant-empty-historical"
        >
          <span className={b('promptTitle')}>
            <Time className={b('promptTitleIcon')} /> {formatMessage('ASSISTANT_PROMPT_HISTORICAL')}
          </span>
          <span className={b('promptExample')}>{formatMessage('ASSISTANT_PROMPT_HISTORICAL_EXAMPLE')}</span>
        </button>
        <button
          className={b('prompt')}
          onClick={() => setInputValue(formatMessage('ASSISTANT_PROMPT_INSIGHTS_INPUT'))}
          data-testid="assistant-empty-insights"
        >
          <span className={b('promptTitle')}>
            <ReportData className={b('promptTitleIcon')} /> {formatMessage('ASSISTANT_PROMPT_INSIGHTS')}
          </span>
          <span className={b('promptExample')}>{formatMessage('ASSISTANT_PROMPT_INSIGHTS_EXAMPLE')}</span>
        </button>
      </div>
    </div>
  );
};

export default AssistantEmptyState;
