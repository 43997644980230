import { ColDef } from '@ag-grid-community/core';

import { formatMessage } from 'utils/messages/utils';

const buildSheetsSummaryColumnDef = (FormatDate: (params) => JSX.Element): ColDef[] => {
  const columnDefs: ColDef[] = [];

  columnDefs.push({
    headerName: formatMessage('NAME'),
    field: 'sheetName',
    flex: 1,
    minWidth: 200
  });

  columnDefs.push({
    headerName: formatMessage('TYPE'),
    field: 'sheetType',
    flex: 1,
    minWidth: 200
  });

  columnDefs.push({
    headerName: formatMessage('DATE_CREATED'),
    field: 'sheetCreatedAt',
    flex: 1,
    minWidth: 200,
    cellRendererFramework: FormatDate
  });

  columnDefs.push({
    headerName: formatMessage('DATE_MODIFIED'),
    field: 'sheetUpdatedAt',
    flex: 1,
    minWidth: 200,
    cellRendererFramework: FormatDate
  });

  return columnDefs;
};

export default buildSheetsSummaryColumnDef;
