import React, { useState, useMemo } from 'react';

import { ApolloError, ApolloQueryResult } from '@apollo/client';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { GET_WORKFLOWS } from 'app/graphql/queries/getWorkflows';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { QuotaApprovals, BattlecardWorkflowResponse, BaseContext } from 'app/models/index';

import { parseWorkflowTree } from 'utils/helpers/parseWorkflowTree';

export interface QuotaApprovalsContextValues extends BaseContext {
  quotaApprovals: QuotaApprovals;
  getQuotaApprovals: (deploymentModelId: number) => void;
  quotaApprovalsLoading: boolean;
  quotaApprovalsError: ApolloError;
  isWorkflowInitiated: boolean;
  setIsWorkflowInitiated: (isWorkflowInitiated: boolean) => void;
  resetValues: () => void;
}

export const QuotaApprovalWorkflowContext = React.createContext<QuotaApprovalsContextValues | null>(null);
QuotaApprovalWorkflowContext.displayName = 'QuotaApprovalWorkflowContext';

export const QuotaApprovalWorkflowProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [quotaApprovals, setQuotaApprovals] = useState<QuotaApprovals>({
    numberOfApproved: 0,
    totalApprovalsRequired: 0,
    completionRate: 0,
    workflows: [],
    rootBCOwnerEmail: null
  });
  const [quotaApprovalsLoading, setQuotaApprovalsLoading] = useState<boolean>(false);
  const [quotaApprovalsError, setQuotaApprovalsError] = useState<ApolloError | null>(null);
  const [isWorkflowInitiated, setIsWorkflowInitiated] = useState<boolean>(false);

  const getWorkflowsQuery = async (deploymentModelId: number) => {
    const workflowData: ApolloQueryResult<{
      getWorkflows: BattlecardWorkflowResponse;
      // eslint-disable-next-line no-restricted-syntax
    }> = await apolloClient.query({
      query: GET_WORKFLOWS,
      fetchPolicy: 'network-only',
      variables: { deploymentModelId }
    });

    if (!workflowData?.data?.getWorkflows?.workflows) {
      if (workflowData?.errors || workflowData?.error) {
        throw workflowData?.errors || workflowData?.error;
      }
      const error = {
        message: 'Error in fetching data'
      };
      throw new Error(error.message);
    }

    return workflowData;
  };

  const getQuotaApprovals = async (deploymentModelId: number) => {
    setQuotaApprovalsLoading(true);
    setQuotaApprovalsError(null);
    try {
      const workflowData = await getWorkflowsQuery(deploymentModelId);

      const { workflows = [], completionRate, workflowInitiated } = workflowData?.data?.getWorkflows;
      const workflowApprovals = parseWorkflowTree(workflows);
      workflowApprovals.completionRate = completionRate || 0;
      setQuotaApprovals(workflowApprovals);

      setIsWorkflowInitiated(workflowInitiated);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setQuotaApprovalsError(error);
    } finally {
      setQuotaApprovalsLoading(false);
    }
  };

  const resetValues = () => {
    setIsWorkflowInitiated(false);
    setQuotaApprovals({
      numberOfApproved: 0,
      totalApprovalsRequired: 0,
      completionRate: 0,
      workflows: [],
      rootBCOwnerEmail: null
    });
    setQuotaApprovalsError(null);
    setQuotaApprovalsLoading(false);
  };

  const values = useMemo(
    () => ({
      quotaApprovals,
      getQuotaApprovals,
      quotaApprovalsLoading,
      quotaApprovalsError,
      isWorkflowInitiated,
      setIsWorkflowInitiated,

      resetValues
    }),
    [quotaApprovals, quotaApprovalsLoading, quotaApprovalsError, isWorkflowInitiated]
  );

  return <QuotaApprovalWorkflowContext.Provider value={values}>{children}</QuotaApprovalWorkflowContext.Provider>;
};

export const useQuotaApprovalWorkflow = (): QuotaApprovalsContextValues => useContextSafe(QuotaApprovalWorkflowContext);
