// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iDa8s {\n    padding-top: 16px;\n    color: rgb(var(--color-gray-2));\n  }\n  ._8lxIU {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    padding-top: 8px;\n  }\n  .JM0Wx {\n    padding-top: 8px;\n    display: grid;\n    grid-template-columns: repeat(3, min-content);\n    grid-gap: 4px;\n    align-items: center;\n  }\n  .afi0- {\n    padding-top: 16px;\n  }\n  .G81Ds {\n    max-width: 150px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DataTypeTagList_requiredText": "iDa8s",
	"DataTypeTagList_requiredHeadersGrid": "_8lxIU",
	"DataTypeTagList_requiredHeader": "JM0Wx",
	"DataTypeTagList_spinnerContainer": "afi0-",
	"DataTypeTagList_ellipsisText": "G81Ds"
};
export default ___CSS_LOADER_EXPORT___;
