// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Natzg {\n  width: 600px;\n  height: 700px;\n}\n\n  .VopB5 {\n    width: auto;\n    height: 700px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HierarchySearchDialog": "Natzg",
	"HierarchySearchDialog_hierarchySearch": "VopB5"
};
export default ___CSS_LOADER_EXPORT___;
