import gql from 'graphql-tag';

export const UPSERT_LOOKUP_ROW = gql`
  mutation UpsertLookupRow($input: UpsertLookupInput) {
    upsertLookupRow(input: $input) {
      fileId
      lookupId
      lookupType
      metadata {
        tableData
        tableSchema
      }
    }
  }
`;
