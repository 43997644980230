import { ColDef } from '@ag-grid-community/core';

import TextRenderer from 'app/components/AdvancedGrid/CellRenderers/TextRenderer/TextRenderer';

import { GridFields, GridHeaders } from 'app/models';

const sharedStaticColumnDefs = {
  flex: 1,
  pinned: 'left',
  cellRendererFramework: TextRenderer,
  resizable: true,
  sortable: true
};

interface AccountRuleGridColumnDefParams {
  dynamicColumnsList: ColDef[];
  includeTerritoryColumns: boolean;
  isSelectionEnabled: boolean;
}

const buildAccountRuleGridColumnDef = (accountRuleGridColumnDefParams: AccountRuleGridColumnDefParams): ColDef[] => {
  const { dynamicColumnsList, includeTerritoryColumns, isSelectionEnabled } = accountRuleGridColumnDefParams;

  return [
    {
      headerName: GridHeaders.ACCOUNT_KEY,
      field: GridFields.ACCOUNT_KEY,
      checkboxSelection: isSelectionEnabled,
      minWidth: 220,
      ...sharedStaticColumnDefs
    },
    {
      headerName: GridHeaders.ACCOUNT_NAME,
      field: GridFields.ACCOUNT_NAME,
      minWidth: 220,
      ...sharedStaticColumnDefs
    },
    {
      headerName: GridHeaders.TERRITORY_ID,
      field: GridFields.TERRITORY_ID,
      hide: !includeTerritoryColumns,
      ...sharedStaticColumnDefs
    },
    {
      headerName: GridHeaders.TERRITORY_NAME,
      field: GridFields.TERRITORY_NAME,
      hide: !includeTerritoryColumns,
      ...sharedStaticColumnDefs
    },
    {
      headerName: GridHeaders.TERRITORY_GROUP,
      field: GridFields.TERRITORY_GROUP,
      hide: !includeTerritoryColumns,
      ...sharedStaticColumnDefs
    },
    ...dynamicColumnsList
  ];
};

export default buildAccountRuleGridColumnDef;
