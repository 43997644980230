import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetUserSpec } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_USER_SPEC = gql`
  query GetUserSpec {
    getUserSpec {
      subjectId
      emailAddress
      firstName
      lastName
      passwordUpdatedAt
      isSupportUser
      membershipSpecs {
        memberId
        tenantId
        tenantGlobalId
        tenantName
        tenantSlug
        systemRoleName
        planningCycles {
          planningCycleId
          planningCycleName
          planningCycleStartDate
          planningCycleDuration
          planningCyclePeriodicity
          planningCycleArchived
          planningCycleSlug
          currencyCode
          deploymentModels {
            deploymentModelId
            deploymentModelType
            deploymentModelName
            isPublished: isPrimaryScenario
          }
        }
      }
    }
  }
`;

export const useGetUserSpec = createUseQuery<GetUserSpec, never>(GET_USER_SPEC);
