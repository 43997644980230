import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

import EditableCurrencyCellRenderer from 'app/components/AdvancedGrid/CellRenderers/EditableCurrencyCellRenderer/EditableCurrencyCellRenderer';

import { CurrencyCode } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatNumber } from 'utils/messages/utils';

import style from './AllocatedCellRenderer.module.pcss';

const b = block(style);

interface AllocatedCellRendererProps extends ICellRendererParams {
  isEditable: boolean;
  value: number;
  currency: CurrencyCode;
  disabledCellInputs?: Record<string, string>[];
  errorMessage?: string;
  errorItems?: Record<string, string>[];
  header: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

const AllocatedCellRenderer: React.FC<AllocatedCellRendererProps> = (props: AllocatedCellRendererProps) => {
  const { onChange, onBlur, isEditable, value, data, header, errorMessage, errorItems, disabledCellInputs, currency } =
    props;
  const errorItem = errorItems?.find((item) => item[props?.node?.id]);
  const disabledItem = disabledCellInputs?.find((item) => item[props?.node?.id]);
  const isColumnEditable = isEditable && data[header]?.shouldBeEditable;

  return (
    <div className={b()} data-testid="allocated-cell-renderer">
      {isColumnEditable ? (
        <EditableCurrencyCellRenderer
          data-testid="editable-currency"
          onChange={onChange}
          onBlur={onBlur}
          value={value.toString()}
          currency={currency}
          hasErrors={errorItem && errorItem[props?.node?.id]?.includes(header)}
          disabled={disabledItem && disabledItem[props?.node?.id]?.includes(header)}
          errorMessage={errorMessage}
        />
      ) : (
        <div className={b('readOnly')}>
          {formatNumber(value ?? 0, {
            style: 'currency',
            currency
          })}
        </div>
      )}
    </div>
  );
};

export default AllocatedCellRenderer;
