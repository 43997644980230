import React from 'react';

import { Route } from 'react-router';

import CyclesPageHeader from 'app/components/CyclesPageHeader/CyclesPageHeader';
import OrganizationCreationForm from 'app/components/OrganizationCreationForm/OrganizationCreationForm';
import UserMenu from 'app/components/UserMenu/UserMenu';

import { RoutePaths } from 'app/containers/App/Router/routePaths';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import varicentLogo from 'assets/svgs/varicentlogo.svg';

import style from './CreateOrganization.module.pcss';

const b = block(style);

const CreationOrganization: React.FC = () => {
  return (
    <div className={b()}>
      <CyclesPageHeader
        logoPath="/"
        logoButton={<img src={varicentLogo} alt={formatMessage('VARICENT_SP')} />}
        rightActionItems={[<UserMenu />]}
      />
      <div className={b('formContainer')}>
        <Route path={RoutePaths.NEW_ORGANIZATION} component={OrganizationCreationForm} />
      </div>
    </div>
  );
};

export default CreationOrganization;
