import { useEffect, useState } from 'react';

import { MapWidth } from 'app/models';

const getMapWidth = (width: number): MapWidth => {
  if (width < 696) {
    return MapWidth.SMALL;
  }
  if (width < 960) {
    return MapWidth.MEDIUM;
  }
  return MapWidth.LARGE;
};

const useObserveMapSize = (mapRef: React.MutableRefObject<HTMLDivElement>): MapWidth => {
  const [mapWidth, setMapWidth] = useState<MapWidth>(MapWidth.LARGE);
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setMapWidth(getMapWidth(mapRef.current.clientWidth));
    });

    if (mapRef?.current) {
      observer.observe(mapRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return mapWidth;
};

export default useObserveMapSize;
