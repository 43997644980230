import React, { useEffect } from 'react';

import { ChevronRight, Close } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useCommandCenter } from 'app/contexts/commandCenterProvider';

import { CommandCenterDrawerState, CommandCenterMenuState } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommandCenterContent.module.pcss';

const b = block(style);

interface CommandCenterContentProps {
  title: string;
  content: React.ReactElement;
}

const CommandCenterContent: React.FC<CommandCenterContentProps> = ({ title, content }: CommandCenterContentProps) => {
  const {
    commandCenterDrawerState,
    setCommandCenterDrawerState,
    commandCenterMenuState,
    setCommandCenterBackButtonCallback,
    setActiveMenu
  } = useCommandCenter();

  const shouldShowWithFullMenu =
    commandCenterDrawerState === CommandCenterDrawerState.EXPAND &&
    commandCenterMenuState === CommandCenterMenuState.FULL;
  const shouldShowWithIconOnlyMenu =
    commandCenterDrawerState === CommandCenterDrawerState.EXPAND &&
    commandCenterMenuState === CommandCenterMenuState.ICON_ONLY;
  const shouldHide = commandCenterDrawerState === CommandCenterDrawerState.CLOSE;

  useEffect(() => {
    setCommandCenterBackButtonCallback(handleBackButton);
  });

  const handleBackButton = () => {
    setCommandCenterDrawerState(CommandCenterDrawerState.CLOSE);
    setActiveMenu(null);
  };

  return (
    <div
      className={b({
        showWithFullMenu: shouldShowWithFullMenu,
        showWithIconOnlyMenu: shouldShowWithIconOnlyMenu,
        hide: shouldHide
      })}
      data-testid="command-center-content"
    >
      <div
        className={b('contentHeader', {
          half: commandCenterDrawerState === CommandCenterDrawerState.HALF,
          expanded: commandCenterDrawerState === CommandCenterDrawerState.EXPAND
        })}
        data-testid="command-center-header"
      >
        <div
          className={b('collapseButton')}
          data-testid="collapse-btn"
          onClick={() => {
            setCommandCenterDrawerState(CommandCenterDrawerState.OPEN);
            setActiveMenu(null);
          }}
        >
          <ChevronRight />
        </div>
        <div className={b('headerInnerItems')}>
          <div className={b('title')} data-testid="command-center-title">
            <HTMLHeading tagLevel="h4" text={title} />
          </div>
          <div data-testid="close-btn-container">
            <IconButton
              type="button"
              icon={<Close size={24} />}
              onClick={() => {
                setCommandCenterDrawerState(CommandCenterDrawerState.CLOSE);
                setActiveMenu(null);
              }}
              testId={'close-btn'}
              title={formatMessage('CLOSE')}
            />
          </div>
        </div>
      </div>
      <div className={b('content')}>{content}</div>
    </div>
  );
};

export default CommandCenterContent;
