import get from 'lodash.get';
import set from 'lodash.set';

import { AccordionData, SearchableSelectMenuItem } from 'components/models';

import {
  ConversionRates,
  CurrencyPageFormValues,
  CurrencyPageSubmission,
  LocalCurrencyFormValues,
  ReportingCurrencyFormValues
} from 'app/models';

import { formatMessage } from 'utils/messages/utils';

// get all currencies that are currently in use by a given reporting currency
export const getDisabledCurrencies = (
  values: CurrencyPageFormValues,
  reportingCurrencyIndex: number,
  defaultReportingCurrency: string
): SearchableSelectMenuItem[] => {
  const disabledItems = [];

  Object.entries(values).forEach(([key, reportingCurrency]) => {
    if (parseInt(key) === reportingCurrencyIndex) {
      disabledItems.push({ key: reportingCurrency?.currency?.value, value: reportingCurrency?.currency?.value });

      reportingCurrency?.localCurrencies.forEach((localCurrency) => {
        // ignore null entries (which are fields that have been deleted)
        if (localCurrency) {
          disabledItems.push({ key: localCurrency?.currency?.value, value: localCurrency?.currency?.value });
        }
      });
    }
  });

  // ensure the default reporting currency is always disabled
  disabledItems.push({ key: defaultReportingCurrency, value: defaultReportingCurrency });

  return disabledItems;
};

// "sort" the accordion data so that the default reporting currency is always at the start
//  of the array (which means it will appear as the first panel in the top of the accordion)
export const getSortedAccordionData = (unsortedAccordionData: AccordionData[]): AccordionData[] => {
  const sortedAccordionData = [];

  unsortedAccordionData.forEach((accordionPanel) => {
    if (accordionPanel?.header.includes(formatMessage('REPORTING_CURRENCY_DEFAULT'))) {
      sortedAccordionData.unshift(accordionPanel);
    } else if (accordionPanel) {
      sortedAccordionData.push(accordionPanel);
    }
  });

  return sortedAccordionData;
};

// transform Formik's values into the format expected by the backend
export const getSubmissionData = (
  values: CurrencyPageFormValues,
  areCurrencyConversionsInverted: boolean,
  selectedPlanningCycleId: number
): CurrencyPageSubmission[] => {
  const submissionData = [];

  Object.values(values).forEach((reportingCurrency) => {
    const reportingCurrencyData = {
      planningCycleId: selectedPlanningCycleId,
      reportingCurrencyCode: reportingCurrency?.currency?.value,
      localCurrencies: []
    };

    reportingCurrency?.localCurrencies.forEach((localCurrency) => {
      // ignore null entries (which are fields that have been deleted), and entries not containing all the required values
      const isValidEntry =
        !!localCurrency && localCurrency.baseConversion && localCurrency.conversion && localCurrency.currency?.value;
      if (isValidEntry) {
        reportingCurrencyData?.localCurrencies.push({
          conversionRate: areCurrencyConversionsInverted
            ? getInvertedCurrency(localCurrency.baseConversion).toFixed(4)
            : localCurrency.conversion.toFixed(4),
          localCurrencyCode: localCurrency.currency.value
        });
      }
    });

    submissionData.push(reportingCurrencyData);
  });

  return submissionData;
};

export const validate = (
  values: Partial<CurrencyPageFormValues>,
  areCurrencyConversionsInverted: boolean
): Partial<CurrencyPageFormValues> => {
  const errors = {};

  Object.keys(values).forEach((key) => {
    const reportingCurrency = get(values, `${key}.currency`);
    const reportingCurrencyConversion = get(values, `${key}.conversion`);
    const reportingCurrencyBaseConversion = get(values, `${key}.baseConversion`);
    const localCurrencies = get(values, `${key}.localCurrencies`);

    const isReportingCurrencyConversionInvalid =
      !reportingCurrencyConversion || reportingCurrencyConversion === 0 || !isFinite(reportingCurrencyConversion);
    const isReportingCurrencyBaseConversionInvalid =
      !reportingCurrencyBaseConversion ||
      reportingCurrencyBaseConversion === 0 ||
      !isFinite(reportingCurrencyBaseConversion);

    if (!reportingCurrency) {
      set(errors, `${key}.currency`, formatMessage('SELECT_CURRENCY_ERROR_MESSAGE'));
    }

    if (isReportingCurrencyConversionInvalid) {
      set(errors, `${key}.conversion`, formatMessage('SELECT_CONVERSION_ERROR_MESSAGE'));
    }

    if (isReportingCurrencyBaseConversionInvalid) {
      set(errors, `${key}.baseConversion`, formatMessage('SELECT_CONVERSION_ERROR_MESSAGE'));
    }

    if (localCurrencies?.length > 0) {
      localCurrencies.forEach((localCurrencyData, index) => {
        // ignore null entries (which are fields that have been deleted)
        if (localCurrencyData) {
          const localCurrency = localCurrencyData?.currency;
          const localCurrencyConversion = localCurrencyData?.conversion;
          const localCurrencyBaseConversion = localCurrencyData?.baseConversion;

          const isLocalCurrencyConversionInvalid =
            !localCurrencyConversion || localCurrencyConversion === 0 || !isFinite(localCurrencyConversion);
          const isLocalCurrencyBaseConversionInvalid =
            !localCurrencyBaseConversion || localCurrencyBaseConversion === 0 || !isFinite(localCurrencyBaseConversion);

          // if the conversions are inverted, only throw an error if the currency
          // field is valid but the baseConversion field is invalid (or vice-versa)
          if (areCurrencyConversionsInverted) {
            if (!localCurrency && !isLocalCurrencyBaseConversionInvalid) {
              set(
                errors,
                `${key}.localCurrencies[${index}].currency.value`,
                formatMessage('SELECT_CURRENCY_ERROR_MESSAGE')
              );
            } else if (localCurrency && isLocalCurrencyBaseConversionInvalid) {
              set(
                errors,
                `${key}.localCurrencies[${index}].baseConversion`,
                formatMessage('SELECT_CONVERSION_ERROR_MESSAGE')
              );
            }

            // if the conversions are not inverted, only throw an error if the currency
            // field is valid but the conversion field is invalid (or vice-versa)
          } else {
            if (!localCurrency && !isLocalCurrencyConversionInvalid) {
              set(
                errors,
                `${key}.localCurrencies[${index}].currency.value`,
                formatMessage('SELECT_CURRENCY_ERROR_MESSAGE')
              );
            } else if (localCurrency && isLocalCurrencyConversionInvalid) {
              set(
                errors,
                `${key}.localCurrencies[${index}].conversion`,
                formatMessage('SELECT_CONVERSION_ERROR_MESSAGE')
              );
            }
          }
        }
      });
    }
  });

  return errors;
};

// get the information needed to synchronize the designated currency with the default reporting currency
export const getDesignatedToDefaultSynchronizationData = (
  values: CurrencyPageFormValues,
  defaultReportingCurrency: string,
  currentCurrencyValue: string
): {
  defaultReportingCurrencyValues: ReportingCurrencyFormValues;
  designatedCurrencyValues: LocalCurrencyFormValues;
} => {
  for (const reportingCurrency of Object.values(values)) {
    if (reportingCurrency?.currency?.value === defaultReportingCurrency) {
      for (const localCurrency of Object.values(reportingCurrency?.localCurrencies)) {
        if (localCurrency?.currency?.value === currentCurrencyValue) {
          return { defaultReportingCurrencyValues: reportingCurrency, designatedCurrencyValues: localCurrency };
        }
      }
    }
  }

  return { defaultReportingCurrencyValues: null, designatedCurrencyValues: null };
};

export const getInvertedCurrency = (conversionRate: number): number => {
  return 1 / conversionRate;
};

// invert the currency conversion values if they are not currently inverted -
// if the values are already inverted, "re-invert" the values back to their original form
export const invertCurrencyConversions = (
  values: CurrencyPageFormValues,
  areCurrencyConversionsInverted: boolean,
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void
): void => {
  Object.keys(values).forEach((key) => {
    const localCurrencies = get(values, `${key}.localCurrencies`);

    if (areCurrencyConversionsInverted) {
      const reportingCurrencyBaseConversion = get(values, `${key}.baseConversion`);
      const invertedBaseConversion = getInvertedCurrency(reportingCurrencyBaseConversion);
      setFieldValue(`${key}.baseConversion`, 1, false);
      setFieldValue(`${key}.conversion`, invertedBaseConversion, false);
    } else {
      const reportingCurrencyConversion = get(values, `${key}.conversion`);
      const invertedConversion = getInvertedCurrency(reportingCurrencyConversion);
      setFieldValue(`${key}.baseConversion`, invertedConversion, false);
      setFieldValue(`${key}.conversion`, 1, false);
    }

    if (localCurrencies?.length > 0) {
      localCurrencies.forEach((localCurrencyData, index) => {
        // ignore null entries (which are fields that have been deleted)
        if (localCurrencyData) {
          if (areCurrencyConversionsInverted) {
            const localCurrencyBaseConversion = get(values, `${key}.localCurrencies[${index}].baseConversion`);
            const invertedBaseConversion = getInvertedCurrency(localCurrencyBaseConversion);
            setFieldValue(`${key}.localCurrencies[${index}].baseConversion`, 1, false);
            setFieldValue(`${key}.localCurrencies[${index}].conversion`, invertedBaseConversion, false);
          } else {
            const localCurrencyConversion = get(values, `${key}.localCurrencies[${index}].conversion`);
            const invertedConversion = getInvertedCurrency(localCurrencyConversion);
            setFieldValue(`${key}.localCurrencies[${index}].baseConversion`, invertedConversion, false);
            setFieldValue(`${key}.localCurrencies[${index}].conversion`, 1, false);
          }
        }
      });
    }
  });
};

// gets the number of "unique" currencies in use
export const getNumberOfCurrencies = (conversionRates: ConversionRates[]): number => {
  if (!conversionRates) {
    return 0;
  }

  const currenciesInUse = {};

  Object.values(conversionRates).forEach((reportingCurrency) => {
    if (!currenciesInUse[reportingCurrency.reportingCurrencyCode]) {
      currenciesInUse[reportingCurrency.reportingCurrencyCode] = true;
    }

    reportingCurrency?.localCurrencies?.forEach((localCurrency) => {
      if (!currenciesInUse[localCurrency.localCurrencyCode]) {
        currenciesInUse[localCurrency.localCurrencyCode] = true;
      }
    });
  });

  return Object.keys(currenciesInUse).length;
};
