import React from 'react';

import { HIERARCHY_PREVIEW_COUNT } from 'app/global/variables';

import { OperatorType, HierarchyItem } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './CompactFilterV2.module.pcss';

const b = block(style);

interface CompactFilterV2Props {
  rootHierarchyName: string;
  operator: OperatorType;
  hierarchyItems: HierarchyItem[];
  inline?: boolean;
  total?: number;
}

const CompactFilterV2: React.FC<CompactFilterV2Props> = ({
  rootHierarchyName,
  operator,
  hierarchyItems,
  inline,
  total
}: CompactFilterV2Props) => {
  return (
    <div className={b('filter', { inline })} data-testid="compact-filter">
      <span className={b('hierarchyName')} data-testid="hierarchy-name">
        {rootHierarchyName}
      </span>
      <span className={b('operator')} data-testid="operator">
        {operator}
      </span>
      <div className={b('hierarchyItems')} data-testid="hierarchy-items">
        {[...hierarchyItems]
          .sort((a, b) => a.name.localeCompare(b.name))
          .slice(0, HIERARCHY_PREVIEW_COUNT)
          .map((item) => `"${item.name}"`)
          .join(', ')}
        {total > HIERARCHY_PREVIEW_COUNT && (
          <span className={b('remainingHierarchiesCount')} data-testid="remaining-hierarchies-count">
            +{total - HIERARCHY_PREVIEW_COUNT}
          </span>
        )}
      </div>
    </div>
  );
};

export default CompactFilterV2;
