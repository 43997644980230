import { useEffect, useState } from 'react';

import { SymonToken } from 'app/containers/Symon/SymonTypes';

import useSymonApiProxyClient from './useSymonApiProxy';

export interface SymonTokenState {
  token: SymonToken;
  isLoading: boolean;
  isRefreshing: boolean;
  hasError: boolean;
}

// Every 59 minutes
const REFRESH_INTERVAL = 59 * 60_000;

const useSymonToken = (): SymonTokenState => {
  const [state, setState] = useState<SymonTokenState>({
    token: null,
    hasError: false,
    isLoading: true,
    isRefreshing: true
  });
  const client = useSymonApiProxyClient();

  useEffect(() => {
    let isCancelled = false;
    const updateToken = async () => {
      if (!client) throw new Error(`Cannot get Symon token, no symon client available`);

      if (!isCancelled)
        setState((oldState) => (oldState.isRefreshing ? oldState : { ...oldState, isRefreshing: true }));
      try {
        const token = await client.getSymonToken();
        if (!isCancelled) setState({ token, isLoading: false, isRefreshing: false, hasError: false });
      } catch (error) {
        console.debug(`Received error from Symon API`, error);
        if (!isCancelled) setState({ token: null, isLoading: false, isRefreshing: false, hasError: true });
      }
    };

    updateToken();
    const intervalId = window.setInterval(updateToken, REFRESH_INTERVAL);

    return () => {
      isCancelled = true;
      window.clearInterval(intervalId);
    };
  }, [client]);

  return state;
};

export default useSymonToken;
