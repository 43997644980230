import { useMemo } from 'react';

import { UserAction } from 'utils/permissions/userActions';

import useCheckCanUser from './useCheckCanUser';

const useCanUser = (userAction: UserAction): boolean => {
  const checkCanUser = useCheckCanUser();
  return useMemo(() => checkCanUser(userAction), [checkCanUser, userAction]);
};

export default useCanUser;
