import Papa, { ParseError } from 'papaparse';

import { FieldNameInput } from 'app/graphql/generated/graphqlApolloTypes';

import { MappingFields } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export const validateFileHeader = (
  file: File,
  requiredHeaders: string[],
  fileName?: string
): Promise<{
  hasEnoughColumns: boolean;
  missingHeaders: string[];
  missingColumnTotal: number;
  headerDataOrder: string[];
  errors: ParseError[];
  validationError: string;
  // can add more if you need other results after header validation
}> => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      step(results, parser) {
        // stop processing after first row
        parser.abort();

        const headerData = results?.meta?.fields || [];

        const missingHeaders = requiredHeaders.filter((requiredHeader) => {
          return !headerData.includes(requiredHeader);
        });

        const result = {
          hasEnoughColumns: false,
          missingHeaders,
          missingColumnTotal: 0,
          headerDataOrder: headerData,
          errors: results.errors,
          validationError: validateFile(fileName, headerData, results.data)
        };

        result['missingColumnTotal'] = requiredHeaders.length - headerData.length;
        if (headerData.length >= requiredHeaders.length) {
          result['hasEnoughColumns'] = true;
        }

        resolve(result);
      },
      error(error) {
        return reject(new Error(error.message));
      }
    });
  });
};

export const createMappingProperties = (
  headerDataOrder: string[],
  mappingFields: MappingFields[]
): FieldNameInput[] => {
  return headerDataOrder.reduce((acc, header) => {
    const mappingProperty = mappingFields.find((mappingField) => mappingField.value === header);
    if (mappingProperty) {
      return [
        ...acc,
        {
          fieldName: mappingProperty.value,
          value: {
            id: mappingProperty.key,
            type: mappingProperty.properties.fieldType
          }
        }
      ];
    }
    return acc;
  }, []);
};

export const validateFile = (fileName: string, fileHeaders: string[], firstRowData: object): string => {
  const reg = new RegExp(`^[A-Za-z0-9\\(\\)\\_\\-\\. ]+$`);

  if (fileName) {
    const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
    if (!reg.test(nameWithoutExtension)) return formatMessage('FILE_NAME_VALIDATION_ERROR');
  }

  if (!fileHeaders) return '';
  for (const header of fileHeaders) {
    if (!reg.test(header)) return formatMessage('FILE_COLUMN_VALIDATION_ERROR');
  }

  if (!firstRowData) return '';
  const firstCellValue = firstRowData[fileHeaders[0]];
  if (firstCellValue?.startsWith('#')) return formatMessage('FILE_UPLOAD_INVALID_FIRST_ROW_ERROR');
  return '';
};
