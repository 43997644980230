// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xTHfN label.bp3-label {\n    font-size: 14px !important;\n  }\n  .pVO7B {\n    height: 20px;\n    color: rgb(var(--color-red-3));\n  }\n  .-BI-l {\n    width: 400px;\n    padding: var(--m);\n  }\n  .EhuXB {\n    width: 400px;\n    margin-top: 20px;\n  }\n  .rGgoS {\n    width: 100%;\n    padding: var(--dialog-row-padding);\n  }\n  ._3jPfk {\n    display: flex;\n    margin-top: var(--s);\n    margin-bottom: var(--m);\n  }\n  .tzl\\+t:not(:first-child) {\n      margin-left: 18px;\n    }\n  .FA3AW {\n    height: 400px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n  }\n  .Li8od {\n    padding: var(--dialog-row-padding) 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SymonIntegrationForm": "xTHfN",
	"SymonIntegrationForm_validationMessage": "pVO7B",
	"SymonIntegrationForm_formContainer": "-BI-l",
	"SymonIntegrationForm_loadingContainer": "EhuXB",
	"SymonIntegrationForm_container": "rGgoS",
	"SymonIntegrationForm_buttonContainer": "_3jPfk",
	"SymonIntegrationForm_button": "tzl+t",
	"SymonIntegrationForm_spinnerContainer": "FA3AW",
	"SymonIntegrationForm_validationText": "Li8od"
};
export default ___CSS_LOADER_EXPORT___;
