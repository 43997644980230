import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Spinner } from '@blueprintjs/core';
import { Checkmark, Close } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useData } from 'app/contexts/dataProvider';

import block from 'utils/bem-css-modules';

import style from './EditRowCellRenderer.module.pcss';

const b = block(style);

interface EditRowCellRendererProps extends ICellRendererParams {
  onSaveButtonClicked: () => void;
  onCancelButtonClicked: () => void;
}

export const EditRowCellRenderer: React.FC<EditRowCellRendererProps> = ({
  onSaveButtonClicked,
  onCancelButtonClicked
}: EditRowCellRendererProps) => {
  const { isLookupRowSubmitting } = useData();

  return (
    <div className={b()} data-testid="edit-row-cell-renderer">
      <IconButton
        icon={<Close />}
        onClick={onCancelButtonClicked}
        type="button"
        title="cancel"
        disabled={isLookupRowSubmitting}
        testId={'cancel-button'}
      />
      {isLookupRowSubmitting ? (
        <Spinner intent="primary" size={16} data-testid="edit-row-cell-renderer-spinner" />
      ) : (
        <IconButton
          icon={<Checkmark />}
          onClick={onSaveButtonClicked}
          type="button"
          title="save"
          testId={'save-button'}
        />
      )}
    </div>
  );
};

export default EditRowCellRenderer;
