import * as yup from 'yup';

import { FileTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { ConfigurationActionType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export default yup.object().shape({
  configurationId: yup.number().nullable().when('configurationAction', {
    is: ConfigurationActionType.RUN,
    then: yup.number().required()
  }),
  configurationAction: yup.string(),
  destinationTable: yup.string().when('dataTableType', {
    is: (val) => val.value === FileTypeEnum.Activity,
    then: (schema) => schema.required(formatMessage('DESTINATION_TABLE_REQUIRED'))
  }),
  symonExportNode: yup.object().required(formatMessage('SYMON_EXPORT_NODE_REQUIRED')).nullable(),
  symonPipeName: yup.object().required(formatMessage('SYMON_PIPE_NAME_REQUIRED')).nullable()
});
