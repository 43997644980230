import React, { FC, useMemo } from 'react';

import { AccountFilter, AccountTerritoryFilterOptions, CustomerForMap, CustomerVisualization } from 'app/models';

import { formatPinsAsFilteredFeatureCollection } from 'utils/helpers/territoryMapUtils';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';

import ClusterAccountSourceLayers from './ClusterAccountSourceLayers';
import HeatPinAccountSourceLayers from './HeatPinAccountSourceLayers';
import { useIsMapCapable } from './hooks/useMapCapability';

export interface AccountSourceLayersProps {
  customers: ReadonlyArray<CustomerForMap>;
  accountFilter: AccountFilter;
  selectedRuleIds: number[];
  ignoredRuleIds: Set<number>;
  visualizationType: CustomerVisualization.CLUSTER | CustomerVisualization.HEAT;
}

export const AccountSourceLayers: FC<AccountSourceLayersProps> = ({
  customers,
  accountFilter,
  selectedRuleIds,
  ignoredRuleIds,
  visualizationType
}) => {
  const canViewUnassigned = useIsMapCapable(MapCapability.VIEW_UNASSIGNED);
  const selectedRulesWhenRelevant =
    accountFilter.territory === AccountTerritoryFilterOptions.SELECTED_TERRITORIES ? selectedRuleIds : null;
  const clusterFeatures = useMemo(
    () =>
      formatPinsAsFilteredFeatureCollection(
        customers,
        accountFilter,
        selectedRulesWhenRelevant,
        ignoredRuleIds,
        canViewUnassigned
      ),
    [customers, accountFilter, selectedRulesWhenRelevant, ignoredRuleIds, canViewUnassigned]
  );
  switch (visualizationType) {
    case CustomerVisualization.CLUSTER:
      return <ClusterAccountSourceLayers features={clusterFeatures} />;
    case CustomerVisualization.HEAT:
      return <HeatPinAccountSourceLayers features={clusterFeatures} />;
    default:
      throw new Error(`Invalid visualization type ${visualizationType}`);
  }
};
