// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._556ED {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  background: rgb(var(--color-light-gray-5));\n  overflow-y: scroll;\n}\n\n  .UD1Vc {\n    margin: 15px 20px 0;\n    display: flex;\n    flex-flow: row wrap;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  ._6ti1P {\n    background: white;\n    margin: 20px;\n    padding: 15px;\n    min-width: 100px;\n    border-radius: 4px;\n    box-shadow: 0 0 3px rgba(0, 0, 0, 0.12);\n  }\n\n  .fT6TC {\n    padding-bottom: 15px;\n    margin-bottom: 25px;\n    border-bottom: 1px solid rgb(var(--color-light-gray-3));\n  }\n\n  .A18pQ {\n    max-width: 762px;\n  }\n\n  .cY3xd {\n    margin-bottom: 8px;\n    display: flex;\n    flex-direction: row;\n  }\n\n  .Zx6w- {\n    color: rgb(var(--color-red-3));\n    margin-left: 5px;\n  }\n\n  .PO\\+Ws {\n    display: flex;\n    flex-flow: row wrap;\n  }\n\n  ._5enxH {\n    flex: 1;\n    margin-right: 20px;\n    max-width: 365px;\n  }\n\n  .Tn4\\+q {\n    flex: 1;\n    margin-left: 20px;\n  }\n\n  .Tq4ls {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 0 16px;\n  }\n\n  .f9Rjt {\n    flex: 1;\n  }\n\n  .y-9zD {\n    margin: 10px;\n  }\n\n  .IFhQt {\n      fill: rgb(var(--color-red-3));\n    }\n\n  ._1jA8C {\n      fill: rgb(var(--color-lime-2));\n    }\n\n  .GL\\+SJ {\n    height: 20px;\n  }\n\n  ._08gI3 {\n    position: relative;\n    display: flex;\n    color: rgb(var(--color-lime-2));\n  }\n\n  .tZ5lR {\n    font-weight: 700;\n    padding: 0 5px;\n  }\n\n  .hxx3f {\n    margin-right: 20px;\n    margin-bottom: 20px;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n  }\n\n  ._4wWBx:not(:first-child) {\n      margin-left: 18px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommandCenterHierarchyPanelContent": "_556ED",
	"CommandCenterHierarchyPanelContent_header": "UD1Vc",
	"CommandCenterHierarchyPanelContent_container": "_6ti1P",
	"CommandCenterHierarchyPanelContent_containerHeader": "fT6TC",
	"CommandCenterHierarchyPanelContent_form": "A18pQ",
	"CommandCenterHierarchyPanelContent_formFieldLabel": "cY3xd",
	"CommandCenterHierarchyPanelContent_star": "Zx6w-",
	"CommandCenterHierarchyPanelContent_formRow": "PO+Ws",
	"CommandCenterHierarchyPanelContent_formFieldFirst": "_5enxH",
	"CommandCenterHierarchyPanelContent_formFieldSecond": "Tn4+q",
	"CommandCenterHierarchyPanelContent_effectiveDate": "Tq4ls",
	"CommandCenterHierarchyPanelContent_endDate": "f9Rjt",
	"CommandCenterHierarchyPanelContent_statusIcon": "y-9zD",
	"CommandCenterHierarchyPanelContent_statusIcon__error": "IFhQt",
	"CommandCenterHierarchyPanelContent_statusIcon__success": "_1jA8C",
	"CommandCenterHierarchyPanelContent_messageContainer": "GL+SJ",
	"CommandCenterHierarchyPanelContent_message": "_08gI3 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["fade-out"] + "",
	"CommandCenterHierarchyPanelContent_messageBold": "tZ5lR",
	"CommandCenterHierarchyPanelContent_footer": "hxx3f",
	"CommandCenterHierarchyPanelContent_button": "_4wWBx"
};
export default ___CSS_LOADER_EXPORT___;
