// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0\\+73L {\n    padding: var(--s) var(--m);\n  }\n\n    ._0\\+73L .bp3-control {\n      font-weight: normal;\n    }\n  ._0\\+73L:hover {\n      background-color: rgb(var(--color-cobalt-5));\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountFilterMenuV2_dropdownItem": "_0+73L"
};
export default ___CSS_LOADER_EXPORT___;
