import React from 'react';

import { Menu } from '@blueprintjs/core';
import { Placement } from '@blueprintjs/popover2';
import { ChevronDown } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';

import { useRebalancing } from 'app/contexts/rebalancingProvider';

import block from 'utils/bem-css-modules';

import style from './SelectMenuHeaderCellRenderer.module.pcss';
const b = block(style);

interface SelectMenuHeaderCellRendererProps {
  placement?: Placement;
}

export const SelectMenuHeaderCellRenderer: React.FC<SelectMenuHeaderCellRendererProps> = ({
  placement = 'bottom'
}: SelectMenuHeaderCellRendererProps) => {
  const { selectedRebalancingMetric, setSelectedRebalancingMetric, rebalancingMetricDropdownItems, resetPanelsConfig } =
    useRebalancing();

  const handleRebalancingMetricChange = (item) => {
    resetPanelsConfig();
    setSelectedRebalancingMetric(item);
  };

  const MenuContent = () => {
    return (
      <div data-testid="select-menu-content" className={b('content')}>
        <Menu>
          {rebalancingMetricDropdownItems.map((item) => {
            return (
              <MenuItem
                key={item?.key}
                data-testid="select-menu-item"
                onClick={() => handleRebalancingMetricChange(item)}
                text={item?.value}
              />
            );
          })}
        </Menu>
      </div>
    );
  };

  return (
    <div data-testid="select-menu" className={b()}>
      <Popover content={<MenuContent />} placement={placement} minimal>
        <TextButton
          testId={'select-menu-text-button'}
          type="button"
          text={selectedRebalancingMetric?.value as string}
          rightIcon={<ChevronDown />}
        />
      </Popover>
    </div>
  );
};

export default SelectMenuHeaderCellRenderer;
