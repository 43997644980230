// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JL6oa {\n  height: 100%;\n  overflow: auto;\n  position: relative;\n}\n\n  .JL6oa .ag-theme-alpine .ag-row {\n    cursor: pointer;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExpandedTerritoryDefineAndRefine": "JL6oa"
};
export default ___CSS_LOADER_EXPORT___;
