import gql from 'graphql-tag';

import { createUseLazyQuery, createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetOptionsForCompareForm, GetOptionsForCompareFormVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_OPTIONS_FOR_COMPARE_FORM = gql`
  query GetOptionsForCompareForm($input: GetDeploymentModelSpecInput!) {
    getDeploymentModelSpec(input: $input) {
      deploymentModelId
      battlecards {
        battlecardId
        battlecardInfoLevel
        battlecardName
        battlecardType
        territoryGroupTypes {
          hierarchyType
          territoryGroupId
        }
        quotaComponents {
          quotaComponentId
        }
        mapVisualizationSettings {
          territoryGroupType
        }
      }
      quotaComponents {
        quotaComponentId
        quotaComponentName
      }
    }
  }
`;

export const useGetOptionsForCompareForm = createUseQuery<GetOptionsForCompareForm, GetOptionsForCompareFormVariables>(
  GET_OPTIONS_FOR_COMPARE_FORM
);

export const useGetOptionsForCompareFormLazy = createUseLazyQuery<
  GetOptionsForCompareForm,
  GetOptionsForCompareFormVariables
>(GET_OPTIONS_FOR_COMPARE_FORM);
