// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ruvai {\n  position: relative;\n  height: 100%;\n}\n\n  ._081BS {\n    max-height: 100%;\n    overflow-y: auto;\n    padding: 0 16px;\n  }\n\n  .SD2PL {\n    max-width: 1100px;\n    margin: 0 auto;\n  }\n\n  .CGKkC {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    background-color: var(--overlay-spinner-background);\n    z-index: 2;\n    cursor: wait;\n    display: flex;\n    justify-content: center;\n    animation: WMq4R 1s ease-out 1 forwards;\n  }\n\n  @keyframes WMq4R {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SymonImport": "ruvai",
	"SymonImport_embedContainer": "_081BS",
	"SymonImport_gutters": "SD2PL",
	"SymonImport_internalSpinnerContainer": "CGKkC",
	"fade-in": "WMq4R"
};
export default ___CSS_LOADER_EXPORT___;
