import React, { useState, useMemo, Dispatch, SetStateAction } from 'react';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext } from 'app/models/index';

interface CommentContextValues extends BaseContext {
  isCommentPanelOpen: boolean;
  setIsCommentPanelOpen: Dispatch<SetStateAction<boolean>>;
  shouldRenderCommentForm: boolean;
  setShouldRenderCommentForm: Dispatch<SetStateAction<boolean>>;
  shouldRefetchCommentPanel: boolean;
  setShouldRefetchCommentPanel: Dispatch<SetStateAction<boolean>>;
  resetValues: () => void;
}

export const CommentContext = React.createContext<CommentContextValues | null>(null);
CommentContext.displayName = 'CommentContext';

export const CommentProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isCommentPanelOpen, setIsCommentPanelOpen] = useState<boolean>(false);
  const [shouldRenderCommentForm, setShouldRenderCommentForm] = useState(false);
  const [shouldRefetchCommentPanel, setShouldRefetchCommentPanel] = useState(false);

  const resetValues = () => {
    setIsCommentPanelOpen(false);
    setShouldRenderCommentForm(false);
    setShouldRefetchCommentPanel(false);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      isCommentPanelOpen,
      setIsCommentPanelOpen,
      shouldRenderCommentForm,
      setShouldRenderCommentForm,
      shouldRefetchCommentPanel,
      setShouldRefetchCommentPanel,
      resetValues
    }),
    [isCommentPanelOpen, shouldRenderCommentForm, shouldRefetchCommentPanel]
  );

  // Return the interface that we want to expose to our other components
  return <CommentContext.Provider value={values}>{children}</CommentContext.Provider>;
};

// Custom hook to read these values from
export const useComments = (): CommentContextValues => useContextSafe(CommentContext);
