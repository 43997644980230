// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M0gQ6 .bp3-overlay-content {\n    width: 320px;\n    bottom: 25px;\n    right: 25px;\n    padding: 0;\n  }\n  .M0gQ6 .bp3-progress-bar {\n    background-color: rgb(var(--color-light-gray-4));\n  }\n  .M0gQ6 .bp3-progress-bar .bp3-progress-meter {\n      background-color: rgb(var(--color-cobalt-3));\n    }\n  .fJwm2 {\n    padding-bottom: 10px;\n  }\n  .DtzgN {\n    margin-right: 8px;\n  }\n  .-crpN {\n    display: flex;\n    align-items: center;\n  }\n  .YlPb\\+ {\n    font-size: 0.75rem;\n    white-space: nowrap;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PostCoinsortProgressToast": "M0gQ6",
	"PostCoinsortProgressToast_descriptionContainer": "fJwm2",
	"PostCoinsortProgressToast_progressBar": "DtzgN",
	"PostCoinsortProgressToast_progressContainer": "-crpN",
	"PostCoinsortProgressToast_progressPercentage": "YlPb+"
};
export default ___CSS_LOADER_EXPORT___;
