import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Icon as VaricentIcon } from '@varicent/components';

interface IconProps {
  icon: JSX.Element;
  'data-testid'?: string;
}

const Icon: React.FC<IconProps> = ({ icon, ...rest }: IconProps) => {
  return <VaricentIcon data-testid={rest?.['data-testid'] || ''}>{icon}</VaricentIcon>;
};

export default Icon;
