// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VfNsz {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 16px;\n  padding: 40px;\n  width: 100%;\n  min-height: 210px;\n  background: rgba(var(--color-light-gray-3), 0.1);\n  border: 2px dashed rgb(var(--color-gray-3));\n  box-sizing: border-box;\n  border-radius: 4px;\n  text-align: center\n}\n.VfNsz:hover {\n    cursor: pointer;\n  }\n.lTC8w {\n    border-color: rgb(var(--color-cobalt-3));\n  }\n.wGgWk {\n    margin-top: 0;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: rgb(var(--color-cobalt-3));\n  }\n._8G3Lb {\n    font-weight: 300;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DataFileDragAndDrop": "VfNsz",
	"DataFileDragAndDrop__borderActive": "lTC8w",
	"DataFileDragAndDrop_title": "wGgWk",
	"DataFileDragAndDrop_dataFileDragAndDropText": "_8G3Lb"
};
export default ___CSS_LOADER_EXPORT___;
