import gql from 'graphql-tag';

export const CHANGE_CURRENT_USER_PASSWORD = gql`
  mutation ChangeCurrentUserPasswordMutation($proposedPassword: String!, $previousPassword: String!) {
    changeCurrentUserPassword(input: { proposedPassword: $proposedPassword, previousPassword: $previousPassword }) {
      errorMessage
      passwordUpdatedAt
      wasPasswordChanged
    }
  }
`;
