// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KdZq7 {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n  }\n  .BOFdK {\n    display: flex;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n    padding: var(--xs);\n    border-radius: var(--xs);\n  }\n  .BOFdK .bp3-button > svg {\n      fill: rgb(var(--color-cobalt-3));\n      color: rgb(var(--color-cobalt-3));\n      margin-right: 0;\n    }\n  .BOFdK .bp3-menu-item {\n      height: var(--xl);\n      padding: 0;\n    }\n  .BOFdK .bp3-menu-item > * {\n        margin-right: 0;\n      }\n  .BOFdK .bp3-active {\n      background-color: rgb(var(--color-cobalt-5)) !important;\n    }\n  .IFSTp {\n    display: flex;\n    border-radius: var(--xs);\n  }\n  .IFSTp .bp3-active > svg {\n      fill: rgb(var(--color-cobalt-3));\n      color: rgb(var(--color-cobalt-3));\n    }\n  .IFSTp:active, .IFSTp:hover {\n    background: var(--periodicity-header-footer-background-color);\n  }\n  .KqGw2 {\n    display: flex;\n  }\n  .GHahv .bp3-button {\n      border-radius: var(--xs) 0 0 var(--xs);\n    }\n  .K-hZ9 {\n    width: 20px;\n  }\n  .K-hZ9 .bp3-button.bp3-minimal {\n      padding: 0;\n      justify-content: start;\n      border-radius: 0 var(--xs) var(--xs) 0;\n      min-width: 20px;\n    }\n  .K-hZ9 .bp3-button > svg {\n      width: var (--xs);\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectionToolSettingsBarV2_selectionToolContainer": "KdZq7",
	"SelectionToolSettingsBarV2_lassoMenu": "BOFdK",
	"SelectionToolSettingsBarV2_lassoBtns": "IFSTp",
	"SelectionToolSettingsBarV2_lassoSelectBtn": "KqGw2",
	"SelectionToolSettingsBarV2_lassoSelectBtn__hasDropdown": "GHahv",
	"SelectionToolSettingsBarV2_lassoActionBtn": "K-hZ9"
};
export default ___CSS_LOADER_EXPORT___;
