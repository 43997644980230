import { CardCopy } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

// We have 4 types of battlecards: Roll-up, Primary Team, Direct Overlay and Indirect Overlay
// Roll-up cards only have 2 sections, and the other three types of cards have 6 sections

export const ROOT_CARD_COPY: CardCopy = {
  sections: [
    {
      name: 'globalMeasures',
      header: formatMessage('GLOBAL_MEASURES')
    },
    { name: 'workflow', header: formatMessage('QUOTA_APPROVAL_WORKFLOW') }
  ]
};

export const ROLL_UP_CARD_COPY = {
  sections: [
    {
      name: 'globalMeasures',
      header: formatMessage('GLOBAL_MEASURES')
    }
  ]
};

export const TEAM_OVERLAY_CARD_COPY = {
  sections: [
    {
      name: 'globalMeasures',
      header: formatMessage('GLOBAL_MEASURES')
    },
    {
      name: 'territoryDefine',
      header: formatMessage('TERRITORY_DEFINE_REFINE')
    },
    {
      name: 'planTargets',
      header: formatMessage('PLAN_TARGETS')
    }
  ]
};
