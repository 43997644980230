// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KlEUx {\n  text-align: right;\n  width: 100%;\n}\n\n  ._91KLk {\n    padding: 0;\n    display: flex;\n    justify-content: space-between;\n  }\n\n  .CQx-b {\n    background-color: rgba(var(--color-sepia-4), 0.6);\n  }\n\n  .J6uQh {\n    flex: 1 1 auto;\n    padding: 0 17px;\n  }\n\n  /* postcss-bem-linter: ignore */\n\n  .MVWYI .bp3-button > svg {\n      fill: #237804;\n      color: #237804;\n    }\n\n  .MVWYI .bp3-button:hover {\n      background-color: transparent !important;\n    }\n\n  .MVWYI .bp3-button:hover > svg {\n        fill: #237804 !important;\n        color: #237804 !important;\n      }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuotaAdjustmentCellRenderer": "KlEUx",
	"QuotaAdjustmentCellRenderer_readOnly": "_91KLk",
	"QuotaAdjustmentCellRenderer_highlightBackground": "CQx-b",
	"QuotaAdjustmentCellRenderer_readOnlyText": "J6uQh",
	"QuotaAdjustmentCellRenderer_iconButton": "MVWYI"
};
export default ___CSS_LOADER_EXPORT___;
