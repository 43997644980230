// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B71CD {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  line-height: 0;\n  cursor: pointer;\n}\n\n  .edVnr {\n    color: rgb(var(--color-gray-1));\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconButtonCellRenderer": "B71CD",
	"IconButtonCellRenderer_icon": "edVnr"
};
export default ___CSS_LOADER_EXPORT___;
