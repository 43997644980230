import gql from 'graphql-tag';

export const GET_CONTRIBUTOR_WORKFLOWS = gql`
  query GetContributorWorkflows($deploymentModelId: Int!, $planningCycleId: Int!) {
    getContributorWorkflows(deploymentModelId: $deploymentModelId, planningCycleId: $planningCycleId) {
      battlecards {
        approverEmail
        approverFirstName
        approverId
        approverLastName
        children
        ownerEmail
        ownerFirstName
        ownerLastName
        status
        battlecardId
        battlecardName
        battlecardOwnerId
        battlecardParentId
        battlecardWorkflowId
        territoryGroups {
          approverEmail
          approverFirstName
          approverId
          approverLastName
          children
          ownerEmail
          ownerFirstName
          ownerLastName
          status
          territoryGroupId
          territoryGroupName
          territoryGroupOwnerId
          territoryGroupParentId
          territoryGroupWorkflowId
        }
      }
      territoryGroups {
        approverEmail
        approverFirstName
        approverId
        approverLastName
        children
        ownerEmail
        ownerFirstName
        ownerLastName
        status
        territoryGroupId
        territoryGroupName
        territoryGroupOwnerId
        territoryGroupParentId
        territoryGroupWorkflowId
        battlecardId
      }
    }
  }
`;
