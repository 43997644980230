import { datadogRum } from '@datadog/browser-rum';

import { MapWorkerProxy } from './MapWorkerProxy';

export const wrapWithAnalytics = (mapWorker: MapWorkerProxy, workerLabel: string) => {
  mapWorker.addOnError((error) => {
    datadogRum.addError(error, { source: 'Map Worker On Error', workerLabel });
  });

  mapWorker.addOnMessageError((error) => {
    datadogRum.addError(error, { source: 'Map Worker Message Error', workerLabel });
  });

  mapWorker.addOnMessage((message) => {
    if (message.type === 'analytics')
      // To avoid type-bloat the map actions are unrestricted
      // eslint-disable-next-line no-restricted-syntax
      datadogRum.addAction(`MapWorker::${message.analyticsKind}`, {
        ...message,
        duration: message.timing.duration,
        count: message.messageExtras?.count,
        workerLabel
      });
  });

  return mapWorker;
};
