import React, { useState } from 'react';

import { TrashCan } from '@carbon/icons-react';

import Dialog from 'components/Dialog/Dialog';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useRequestDeploymentModelDelete } from 'app/graphql/mutations/requestDeploymentModelDelete';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

import DeleteScenarioToast from './DeleteScenarioToast';

interface DeleteScenarioDialogProps {
  scenarioName: string;
  scenarioId: number;
  planningCycleId: number;
  onClose: () => void;
}

const DeleteScenarioDialog: React.FC<DeleteScenarioDialogProps> = ({
  scenarioName,
  scenarioId,
  planningCycleId,
  onClose
}) => {
  const [isDeletionLoading, setIsDeletionLoading] = useState<boolean>(false);
  const [deleteJobKey, setDeleteJobKey] = useState<string | null>(null);

  const showToast = useShowToast();

  const handleDeleteScenario = () => {
    deleteScenario({
      variables: {
        deploymentModelIds: [scenarioId]
      }
    });
  };

  const [deleteScenario] = useRequestDeploymentModelDelete({
    onCompleted(data) {
      setIsDeletionLoading(true);
      setDeleteJobKey(data?.requestDeploymentModelDelete?.jobKey);
    },
    onError() {
      const title = formatMessage('SCENARIO_DELETED_FAILURE');
      const message = formatMessage('SCENARIO_DELETED_FAILURE_MESSAGE', { name: scenarioName });
      showToast(<ToastMessage title={title} message={message} />, 'danger');
    }
  });

  return (
    <>
      <Dialog
        title={formatMessage('DELETE_VALUE', { value: scenarioName })}
        isOpen
        bodyMinHeight={0}
        size="small"
        onSubmit={handleDeleteScenario}
        onClose={onClose}
        confirmButtonText={formatMessage('DELETE')}
        cancelButtonText={formatMessage('KEEP')}
        confirmButtonIcon={<TrashCan />}
        confirmButtonLoading={isDeletionLoading}
      >
        {formatMessage('SCENARIO_DELETE_PROMPT', { name: scenarioName })}
      </Dialog>
      {isDeletionLoading && deleteJobKey && (
        <DeleteScenarioToast scenarioName={scenarioName} jobKey={deleteJobKey} planningCycleId={planningCycleId} />
      )}
    </>
  );
};

export default DeleteScenarioDialog;
