import React from 'react';

import AccountQuotaDrillIn from 'app/components/DataTray/AccountQuotaDrillIn/AccountQuotaDrillIn';
import HierarchyQuotaDistribution from 'app/components/DataTray/HierarchyQuotaDistribution/HierarchyQuotaDistribution';
import QuotaGrid from 'app/components/DataTray/QuotaGrid/QuotaGrid';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

interface QuotaGridContainerProps {
  sheetId?: number;
}

const QuotaGridContainer: React.FC<QuotaGridContainerProps> = ({ sheetId }) => {
  const { selectedBattleCardId } = useBattleCard();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { showAccountQuotaDrillIn } = useGrid();
  const [isBreakdownOfQuotaEnabled] = useTreatment(SplitFeatures.BREAKDOWN_OF_QUOTA_BY_HIERARCHIES);

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;

  if (!selectedBattleCardId && !selectedPillId) {
    return null;
  } else if (showAccountQuotaDrillIn && !isBreakdownOfQuotaEnabled) {
    // Once the feature work under BREAKDOWN_OF_QUOTA_BY_HIERARCHIES is complete, we should remove related code to AccountQuotaDrillIn
    return <AccountQuotaDrillIn />;
  } else if (showAccountQuotaDrillIn && isBreakdownOfQuotaEnabled) {
    return <HierarchyQuotaDistribution quotaSheetId={sheetId} />;
  }

  return <QuotaGrid sheetId={sheetId} />;
};

export default QuotaGridContainer;
