// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hdTAC {\n  display: flex;\n  flex-direction: column;\n  width: 670px;\n  font-size: 14px;\n  font-stretch: normal;\n  font-style: normal;\n  letter-spacing: normal\n}\n.hdTAC.bp3-card {\n    padding: 20px 35px;\n  }\n.GbZFg {\n    width: 100%;\n  }\n.xbkh9 {\n    font-weight: 600;\n    line-height: 1.14;\n    flex: 1;\n    padding-left: 5px;\n  }\n.aHAZY {\n    font-weight: normal;\n    line-height: 1.43;\n    flex: 2;\n  }\n.o4mVj {\n    padding: 10px 0;\n    display: flex;\n  }\n.Bn8ej {\n    display: flex;\n    flex-direction: column;\n    flex: 3;\n  }\n.-CdL2 {\n    height: 70px;\n    flex: 1;\n    padding-top: 10px;\n    display: flex;\n    justify-content: right;\n  }\n.lwULk {\n    background: rgb(var(--color-cobalt-3)) !important;\n  }\n.Hpbaz {\n    font-weight: 600;\n    line-height: 1.14;\n    width: 30%;\n    padding-left: 5px;\n  }\n.m3Gx0 {\n    font-weight: normal;\n    line-height: 1.43;\n    padding-left: 5px;\n    padding-top: 15px;\n  }\n.ra4bS {\n    top: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: var(--l);\n  }\n.Rwfe8 {\n    color: rgb(var(--color-cobalt-3));\n  }\n.FNflt {\n    display: flex;\n    justify-content: right;\n    margin-top: var(--m);\n  }\n.q\\+DBc {\n    margin-left: var(--m);\n  }\n.LV85L {\n    margin-top: var(--xl);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserInformationCard": "hdTAC",
	"UserInformationCard_divider": "GbZFg",
	"UserInformationCard_informationTitle": "xbkh9",
	"UserInformationCard_informationValue": "aHAZY",
	"UserInformationCard_informationSection": "o4mVj",
	"UserInformationCard_profilePictureInformationSection": "Bn8ej",
	"UserInformationCard_profilePictureContainer": "-CdL2",
	"UserInformationCard_profilePicture": "lwULk",
	"UserInformationCard_profilePictureTitle": "Hpbaz",
	"UserInformationCard_profilePictureSubtitle": "m3Gx0",
	"UserInformationCard_profileEdit": "ra4bS",
	"UserInformationCard_editbutton": "Rwfe8",
	"UserInformationCard_formSubmitContainer": "FNflt",
	"UserInformationCard_submitButton": "q+DBc",
	"UserInformationCard_contactAdmin": "LV85L"
};
export default ___CSS_LOADER_EXPORT___;
