import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import {
  UpsertTQMTerritoryRuleEffectiveDates,
  UpsertTQMTerritoryRuleEffectiveDatesVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const UPSERT_TQM_TERRITORY_RULE_EFFECTIVE_DATES = gql`
  mutation UpsertTQMTerritoryRuleEffectiveDates(
    $ruleId: Int
    $territoryId: String!
    $territoryName: String!
    $territoryGroupId: Int!
    $quotaComponentId: Int
    $definition: NewRuleDefinitionResponse
    $effectiveDate: AWSDate
    $endDate: AWSDate
    $color: String
    $inheritsFrom: [InheritedRule]
  ) {
    upsertTerritoryRule(
      input: {
        ruleId: $ruleId
        territoryId: $territoryId
        territoryName: $territoryName
        territoryGroupId: $territoryGroupId
        quotaComponentId: $quotaComponentId
        definition: $definition
        effectiveDate: $effectiveDate
        endDate: $endDate
        color: $color
        inheritsFrom: $inheritsFrom
      }
    ) {
      __typename
    }
  }
`;

export const useUpsertTerritoryDate = createUseMutation<
  UpsertTQMTerritoryRuleEffectiveDates,
  UpsertTQMTerritoryRuleEffectiveDatesVariables
>(UPSERT_TQM_TERRITORY_RULE_EFFECTIVE_DATES);
