// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".i545u {\n    margin-right: var(--m);\n    min-width: 200px;\n  }\n\n    .i545u .bp3-button:not([class*='bp3-intent-']) {\n      background-color: white;\n      color: rgb(var(--color-midnight-blue-2));\n      height: 34px;\n      box-shadow: none;\n      border: 1px solid rgb(var(--color-midnight-blue-4));\n      width: 200px;\n      justify-content: space-between;\n      padding: 6px 12px;\n      min-height: auto;\n    }\n  .RrYTe {\n    padding: 10px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryBalancingGridActionButtons_hierarchySelect": "i545u",
	"TerritoryBalancingGridActionButtons_submitButtonContainer": "RrYTe"
};
export default ___CSS_LOADER_EXPORT___;
