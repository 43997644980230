import React, { useState, useEffect } from 'react';

export interface FlagIconProps {
  countryId: string;
  height?: number;
  width?: number;
}

const FlagIcon: React.FC<FlagIconProps> = ({ countryId, width = 24, height = 24 }: FlagIconProps) => {
  const [flagIcon, setFlagIcon] = useState<string>('');

  // dynamically import the image file and set it as the flagIcon
  useEffect(() => {
    let isImportingCancelled = false;

    const importFlagIcon = async () => {
      try {
        const image = await import(`assets/pngs/flags/${countryId}.png`);
        if (!isImportingCancelled) {
          setFlagIcon(image?.default);
        }
      } catch (error) {
        console.log(error);
      }
    };

    importFlagIcon();

    return () => {
      isImportingCancelled = true;
    };
  }, [countryId]);

  return (
    <img width={width} height={height} src={flagIcon} alt={`${countryId.toUpperCase()}`} data-testid="flag-icon" />
  );
};

export default FlagIcon;
