// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../app/global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G5x7z {\n  overflow: hidden;\n}\n\n  .GW9RG {\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EllipsisText": "G5x7z",
	"EllipsisText_textContainer": "GW9RG " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["text-ellipsis"] + ""
};
export default ___CSS_LOADER_EXPORT___;
