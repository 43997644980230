// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wnumr {\n  display: flex;\n  cursor: pointer;\n}\n\n  .wME7L {\n  }\n\n  .ixhSs {\n    white-space: nowrap;\n  }\n\n  .Wnumr .helpMenu a {\n    text-decoration: none;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HelpButton": "Wnumr",
	"HelpButton_helpBtn": "wME7L " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["header-menu-btn"] + "",
	"HelpButton_popover": "ixhSs"
};
export default ___CSS_LOADER_EXPORT___;
