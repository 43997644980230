import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { CleanFileProcess, CleanFileProcessVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const CLEAN_FILE_PROCESS = gql`
  mutation CleanFileProcess($input: CleanFileProcessInput!) {
    cleanFileProcess(input: $input) {
      pipes {
        fileId
        pipeUuid
        status
      }
      fileMetadata {
        fileId
        status
      }
    }
  }
`;

export const useCleanFileProcess = createUseMutation<CleanFileProcess, CleanFileProcessVariables>(CLEAN_FILE_PROCESS);
