import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { DropdownMenu as VDSDropdownMenu } from '@varicent/components';

const DropdownMenuDivider: React.FC = () => {
  return <VDSDropdownMenu.Divider />;
};

export default DropdownMenuDivider;
