// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2h9SZ {\n  width: 100%;\n}\n\n  .MCllu {\n    font-size: 14px;\n    font-size: var(--m);\n    margin: var(--m) 0 var(--s) 0;\n    text-align: left;\n  }\n\n  ._9jICV {\n    width: 100%;\n  }\n\n  .mtfJZ {\n    font-size: 14px;\n    font-size: var(--m);\n    margin: var(--m) 0;\n  }\n\n  ._0pTNM {\n    margin-bottom: var(--m);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccessibleTenantsList": "_2h9SZ",
	"AccessibleTenantsList_text": "MCllu",
	"AccessibleTenantsList_tenantsListWrapper": "_9jICV",
	"AccessibleTenantsList_tenantsListHeader": "mtfJZ",
	"AccessibleTenantsList_tenantCard": "_0pTNM"
};
export default ___CSS_LOADER_EXPORT___;
