// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tISFF {\n    margin: 0 var(--data-panel-margin);\n    overflow-y: scroll;\n    border: 1px solid rgb(var(--color-light-gray-3));\n    height: 100%;\n  }\n  .wC-FP {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n  }\n  .UxWwy {\n    border-top: 1px solid rgb(var(--color-light-gray-1));\n    padding: 12px 16px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TablePublishedDrillIn_tableGridContainer": "tISFF",
	"TablePublishedDrillIn_fullWidthGrid": "wC-FP",
	"TablePublishedDrillIn_noData": "UxWwy"
};
export default ___CSS_LOADER_EXPORT___;
