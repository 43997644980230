// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YqFr2 {\n  height: 100%;\n  width: 100%;\n}\n\n  .LKvqq, .YWkEU {\n    height: 100%;\n  }\n\n  ._86RTo {\n    border: 0;\n    height: 100%;\n    width: 100%;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SymonPortal": "YqFr2",
	"SymonPortal_spinnerContainer": "LKvqq",
	"SymonPortal_iframeContainer": "YWkEU",
	"SymonPortal_iframe": "_86RTo"
};
export default ___CSS_LOADER_EXPORT___;
