import React from 'react';

import { Warning } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './IntegrationPanelFallback.module.pcss';

const b = block(style);

interface IntegrationPanelFallbackProps {
  resetErrorBoundary?: () => void;
  showRefreshButton?: boolean;
}

const IntegrationPanelFallback: React.FC<IntegrationPanelFallbackProps> = ({
  resetErrorBoundary,
  showRefreshButton = true
}) => {
  return (
    <div className={b('errorFallback')}>
      <div className={b('warningIcon')}>
        <Warning size={32} />
      </div>
      <div className={b('errorTitle')}>{formatMessage('SYMON_ERROR_MESSAGE_1')}</div>
      {showRefreshButton && (
        <>
          <div className={b('errorBody')}>{formatMessage('SYMON_ERROR_MESSAGE_2')}</div>
          <TextButton
            testId={'import-data-button'}
            type="button"
            text={formatMessage('REFRESH')}
            intent="primary"
            onClick={resetErrorBoundary}
          />
        </>
      )}
    </div>
  );
};

export default IntegrationPanelFallback;
