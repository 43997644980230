// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n2hZw {\n    display: flex;\n    align-items: center;\n    min-width: auto;\n    height: var(--xl);\n  }\n\n    .n2hZw .bp3-menu-item > svg {\n        margin-right: 0;\n      }\n\n    .n2hZw .bp3-menu-item:not(.bp3-disabled) > svg {\n        fill: rgb(var(--color-cobalt-3)) !important;\n        color: rgb(var(--color-cobalt-3)) !important;\n      }\n  .zOwUi {\n    display: flex;\n    flex-direction: column;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ActionBarV2_bar": "n2hZw",
	"ActionBarV2_dropdown": "zOwUi"
};
export default ___CSS_LOADER_EXPORT___;
