// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QGgx6 {\n  width: 100%;\n  background-color: var(--white);\n}\n\n  .ZpyDl {\n  }\n\n  .dRqQn {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .MtWLV {\n    display: flex;\n    grid-gap: var(--l);\n    margin-top: var(--m);\n  }\n\n  .DVPh0 {\n    padding-top: 4px;\n  }\n\n  .OaIk5 {\n    padding-top: var(--m);\n    font-weight: 600 !important;\n  }\n\n  .ru0tf {\n    color: rgb(var(--color-gray-2));\n    background-color: rgb(var(--color-light-gray-5));\n    padding: var(--m);\n    margin-top: var(--m);\n    display: flex;\n    grid-gap: var(--m);\n  }\n\n  .khvFB {\n    border: none;\n    flex: 1;\n    background-color: transparent;\n    resize: none;\n  }\n\n  .V\\+HYg {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 0 var(--data-panel-padding);\n    background-color: white;\n    align-items: center;\n    box-sizing: border-box;\n    min-height: var(--data-drill-in-header-height);\n    border-bottom: 1px solid rgb(var(--color-light-gray-4));\n  }\n\n  .DleA7 {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .u5h1d {\n    margin-left: var(--data-panel-medium-margin);\n  }\n\n  .nGg7b {\n    padding: var(--data-panel-padding);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImportErrorsDetail": "QGgx6",
	"ImportErrorsDetail_subText": "ZpyDl " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["sub-text"] + "",
	"ImportErrorsDetail_textContainer": "dRqQn",
	"ImportErrorsDetail_configuration": "MtWLV",
	"ImportErrorsDetail_description": "DVPh0",
	"ImportErrorsDetail_heading": "OaIk5",
	"ImportErrorsDetail_copySummary": "ru0tf",
	"ImportErrorsDetail_clipboardInput": "khvFB",
	"ImportErrorsDetail_header": "V+HYg",
	"ImportErrorsDetail_leftActionItems": "DleA7",
	"ImportErrorsDetail_tableTitle": "u5h1d",
	"ImportErrorsDetail_detailContainer": "nGg7b"
};
export default ___CSS_LOADER_EXPORT___;
