import React, { useState } from 'react';

import { TrashCan } from '@carbon/icons-react';
import { UseComboboxStateChange } from 'downshift';
import { FieldProps } from 'formik';

import { SearchableSelectMenuItem } from 'components/models';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import { useBattleCard } from 'app/contexts/battleCardProvider';

import { MENU_INFINITE_SCROLL_ITEM_HEIGHT } from 'app/global/variables';

import {
  GetTerritoryRulesForAccountMove_getTerritoryRules,
  SortDirection,
  SortableGetTerritoryRulesGridCols
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { useGetTerritoryRulesForAccountMoveLazy } from 'app/graphql/queries/getTerritoryRulesForAccountMove';

import block from 'utils/bem-css-modules';
import { handleTerritoryRuleMerge } from 'utils/helpers/listMergeUtils';
import { formatTerritoryMenuItems } from 'utils/helpers/territoryMapUtils';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritorySearchableSelect.module.pcss';

const b = block(style);

export interface TerritorySearchableSelectProps extends FieldProps {
  renderTarget?: (onClick: (event: MouseEvent) => void) => React.ReactNode;
  allowPreventOverflow?: boolean;
  territoryGroupId: number;
  onSelectItem?: (changes: UseComboboxStateChange<SearchableSelectMenuItem>) => void;
  shouldIncludeDeleteItem?: boolean;
  shouldIncludeUnassignItem?: boolean;
  shouldReturnDates?: boolean;
  disabledItems?: SearchableSelectMenuItem[];
}

const TerritorySearchableSelect: React.FC<TerritorySearchableSelectProps> = (props: TerritorySearchableSelectProps) => {
  const {
    allowPreventOverflow,
    renderTarget,
    territoryGroupId,
    onSelectItem,
    disabledItems,
    shouldIncludeDeleteItem = false,
    shouldIncludeUnassignItem = false,
    shouldReturnDates = false
  } = props;
  const { selectedQuotaComponentId } = useBattleCard();

  const [territoryItems, setTerritoryItems] = useState<SearchableSelectMenuItem[]>();

  // calculate how many rows to load on each fetch
  const viewportHeight = window.innerHeight;
  const pageSize = Math.ceil((viewportHeight * 0.2) / MENU_INFINITE_SCROLL_ITEM_HEIGHT) * 2;

  const getTerritoryRulesVariables = {
    quotaComponentId: selectedQuotaComponentId,
    measureId: 0,
    sorting: {
      colId: SortableGetTerritoryRulesGridCols.territoryName,
      sort: SortDirection.asc
    },
    startRow: 1,
    endRow: pageSize,
    territoryGroupId
  };

  const [getTerritoryRulesForAccountMoveLazy, { data: territoryRules, loading: territoryRulesLoading, fetchMore }] =
    useGetTerritoryRulesForAccountMoveLazy({
      variables: getTerritoryRulesVariables,
      fetchPolicy: 'network-only',
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
      },
      onCompleted(territoryRules) {
        updateTerritoryItems(territoryRules.getTerritoryRules);
      }
    });
  const totalRows = territoryRules?.getTerritoryRules?.totalCount;

  const updateTerritoryItems = (territories: GetTerritoryRulesForAccountMove_getTerritoryRules) => {
    const formattedTerritoryItems = formatTerritoryMenuItems({
      territories,
      shouldReturnDates,
      shouldIncludeUnassignItem,
      shouldIncludeDeleteItem,
      deleteIcon: <TrashCan />
    });
    setTerritoryItems(formattedTerritoryItems);
  };

  const onSelectOpen = () => {
    if (!territoryRules) {
      getTerritoryRulesForAccountMoveLazy();
    } else if (territoryRules.getTerritoryRules) {
      updateTerritoryItems(territoryRules.getTerritoryRules);
    }
  };

  const handleSearch = (searchString: string) => {
    getTerritoryRulesForAccountMoveLazy({
      variables: {
        ...getTerritoryRulesVariables,
        // Uses AND logic so we can't search for territoryName and Id at the same time, may have to use the legacy method instead
        searchInput: {
          filters: JSON.stringify({
            territoryName: {
              filterType: 'text',
              type: 'contains',
              filter: searchString,
              filterTo: null
            }
          })
        }
      }
    });
  };

  return (
    <div className={b('', { hasTarget: !!renderTarget })}>
      <SearchableSelectMenu
        allowPopperEventListeners={false}
        showErrors={false}
        placeHolderText={formatMessage('SELECT')}
        allowPreventOverflow={allowPreventOverflow}
        showIconInField={false}
        smallIcon
        data-testid="territory-searchable-select"
        theme="default"
        items={territoryItems}
        initialLoadingComplete={!territoryRulesLoading}
        onSelectOpen={onSelectOpen}
        onSearch={(options) => {
          handleSearch(options as string);
        }}
        onSelectItem={onSelectItem}
        onSearchReset={() => {
          setTerritoryItems([]);
          handleSearch('');
        }}
        disabledItems={disabledItems}
        shouldSupportInfiniteScroll
        infiniteScrollProps={{
          onFetchMore: (startRow, endRow) =>
            fetchMore({
              variables: {
                startRow,
                endRow
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previousResult;
                return handleTerritoryRuleMerge(previousResult, fetchMoreResult);
              }
            }),
          totalRows,
          pageSize,
          isLoading: territoryRulesLoading,
          staticItemsCount: Number(shouldIncludeUnassignItem) + Number(shouldIncludeDeleteItem)
        }}
        {...props}
      />
    </div>
  );
};

export default TerritorySearchableSelect;
