// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".es\\+8f {\n    margin-right: 10px;\n  }\n  .q3SFg {\n    height: var(--xl);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuotaGridActionButtons_publishButton": "es+8f",
	"QuotaGridActionButtons_divider": "q3SFg"
};
export default ___CSS_LOADER_EXPORT___;
