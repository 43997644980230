// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".F\\+6z- {\n    display: flex;\n    width: 100%;\n  }\n  .HEZgC {\n    width: 100%;\n    height: 100%;\n  }\n  .HEZgC .ag-cell {\n      overflow: visible !important;\n    }\n  .HEZgC .ag-row-level-0 {\n      background: var(--white) !important;\n    }\n  .HEZgC .ag-row-level-1 {\n      background: rgb(var(--color-light-gray-5)) !important;\n    }\n  /* reset padding to zero and let headerClass and headerCellRender handle the styling */\n  .HEZgC .ag-header-cell {\n      padding: 0;\n    }\n  /* reset cell hover background color, let cellClass handle each cell style */\n  .HEZgC .ag-cell:hover {\n      background-color: transparent;\n    }\n  .jwXOU {\n    padding: 0 0 0 var(--grid-header-padding) !important;\n  }\n  .BtoY9 {\n    display: inline-grid;\n    justify-content: flex-end;\n    padding-right: var(--grid-header-padding) !important;\n  }\n  .QD\\+0r {\n    display: inline-grid;\n    justify-content: flex-end;\n  }\n  .wl\\+V1:hover {\n    background-color: var(--intent-primary-translucent-hover) !important;\n  }\n  .KU6vx {\n    font-weight: bold;\n  }\n  .eC7VM {\n    background-color: var(--intent-danger-translucent-active) !important;\n  }\n  .ceUb3 {\n  }\n  .NUK4o {\n  }\n  .k\\+E33 {\n    display: flex;\n    height: 70vh;\n    margin-bottom: -50px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryQuotaGrid_gridWrapper": "F+6z-",
	"TerritoryQuotaGrid_advancedGridWrapper": "HEZgC",
	"TerritoryQuotaGrid_header": "jwXOU",
	"TerritoryQuotaGrid_rightAlignedHeader": "BtoY9",
	"TerritoryQuotaGrid_rightAlignedCell": "QD+0r",
	"TerritoryQuotaGrid_highlightOnHoverCell": "wl+V1",
	"TerritoryQuotaGrid_boldedTextCell": "KU6vx",
	"TerritoryQuotaGrid_redHighlight": "eC7VM",
	"TerritoryQuotaGrid_gridOverlayContainer": "ceUb3 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-container"] + "",
	"TerritoryQuotaGrid_gridOverlayText": "NUK4o " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grid-overlay-text"] + "",
	"TerritoryQuotaGrid_lookupPreviewContents": "k+E33"
};
export default ___CSS_LOADER_EXPORT___;
