// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xRsto {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  width: 600px;\n  padding: 24px;\n  background: rgb(var(--color-light-gray-5));\n  border-radius: 4px;\n  margin-left: 8px;\n}\n\n  .eTr2E {\n    color: rgb(var(--color-dark-gray-3));\n    font-size: 16px;\n    font-weight: 600;\n    padding-bottom: 16px;\n  }\n\n  .xL9nI {\n    font-size: 12px;\n    padding-bottom: 2px;\n  }\n\n  .ZSi2n {\n    display: flex;\n    justify-content: flex-end;\n  }\n\n  .YeUUO {\n    margin-left: 12px;\n  }\n\n  .S74Ke {\n    margin-left: 4px;\n  }\n\n  ._3JzKQ {\n    display: inline-table;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExpandedHierarchyRule": "xRsto",
	"ExpandedHierarchyRule_hierarchyTitle": "eTr2E",
	"ExpandedHierarchyRule_inclExclTitle": "xL9nI",
	"ExpandedHierarchyRule_buttonContainer": "ZSi2n",
	"ExpandedHierarchyRule_saveBtn": "YeUUO",
	"ExpandedHierarchyRule_multiSelectButton": "S74Ke",
	"ExpandedHierarchyRule_inclusionsInput": "_3JzKQ"
};
export default ___CSS_LOADER_EXPORT___;
