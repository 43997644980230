import React, { FC, useMemo } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { useReassignRuleHierarchies } from 'app/graphql/mutations/reassignRuleHierarchies';

import useShowToast from 'app/hooks/useShowToast';

import { HierarchyType, RuleForMap } from 'app/models';

import { determineTargetRuleComponent } from 'utils/helpers/territoryMapUtils';
import { formatMessage } from 'utils/messages/utils';

import PickTerritoryDialog from './PickTerritoryDialog';

export interface ReassignHierarchiesDialogProps {
  mappableTerritoryRules: RuleForMap[];
  hierarchyIds: number[];
  onTerritoryUpdated: () => void;
  onClose: () => void;
  targetHierarchyType: HierarchyType.CustomerAccountHierarchy | HierarchyType.GeographicTerritoryHierarchy;
  lockedRuleIds: Set<number>;
  editableRuleIds: number[];
}

const ReassignHierarchiesDialog: FC<ReassignHierarchiesDialogProps> = ({
  mappableTerritoryRules,
  hierarchyIds,
  targetHierarchyType,
  onClose,
  onTerritoryUpdated,
  lockedRuleIds,
  editableRuleIds
}) => {
  const { selectedTerritoryGroupId, primaryHierarchy } = useMapContextRedistributor();
  const [reassignRuleHierarchies] = useReassignRuleHierarchies();
  const showToast = useShowToast();
  const selectableTerritoryRules = useMemo(
    () =>
      mappableTerritoryRules.filter(
        (rule) => rule.isPassingFilter && rule.isMappable && !rule.isOverAssigned && !lockedRuleIds.has(rule.ruleId)
      ),
    [mappableTerritoryRules, lockedRuleIds]
  );

  const dynamicCopy = useMemo(() => {
    switch (targetHierarchyType) {
      case HierarchyType.GeographicTerritoryHierarchy:
        return {
          body: formatMessage('REASSIGN_GEO_BODY', { count: hierarchyIds.length }),
          successToast: formatMessage('REASSIGN_GEO_SUCCESS_TOAST'),
          errorToast: formatMessage('REASSIGN_GEO_ERROR_TOAST')
        };
      case HierarchyType.CustomerAccountHierarchy:
        return {
          body: formatMessage('REASSIGN_ACCOUNT_BODY', { count: hierarchyIds.length }),
          successToast: formatMessage('REASSIGN_ACCOUNT_SUCCESS_TOAST'),
          errorToast: formatMessage('REASSIGN_ACCOUNT_ERROR_TOAST')
        };
      default:
        throw new Error(`Unsupported hierarchy type: ${targetHierarchyType}`);
    }
  }, [targetHierarchyType, hierarchyIds.length]);

  const handleSubmit = async (ruleId: number) => {
    try {
      const result = await reassignRuleHierarchies({
        variables: {
          input: {
            scopingTerritoryGroupId: selectedTerritoryGroupId,
            sourceHierarchyIds: hierarchyIds,
            targetRuleId: ruleId,
            targetRuleComponent: determineTargetRuleComponent({
              targetHierarchyType,
              primaryHierarchy
            }),
            ruleIdsFilter: editableRuleIds ? [...editableRuleIds, ruleId] : null
          }
        }
      });

      if (result.data?.reassignRuleHierarchies?.success) {
        showToast(dynamicCopy.successToast, 'success');
        onTerritoryUpdated();
      } else {
        showToast(dynamicCopy.errorToast, 'danger');
      }
    } catch (error) {
      showToast(dynamicCopy.errorToast, 'danger');
      throw error;
    }
  };

  return (
    <PickTerritoryDialog
      {...dynamicCopy}
      title={formatMessage('REASSIGN_TITLE')}
      confirmText={formatMessage('REASSIGN')}
      selectableTerritoryRules={selectableTerritoryRules}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
export default ReassignHierarchiesDialog;
