import React from 'react';

import { IHeaderParams, ColDef } from '@ag-grid-community/core';
import { Button } from '@varicent/components';

import { shouldHeaderHaveExtraPadding } from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/QuotaGridHelper';

import { useScope } from 'app/contexts/scopeProvider';

import { PeriodicityType, QuotaGridColumnName, QuotaGridMeasureColumn } from 'app/models';

import block from 'utils/bem-css-modules';

import { ReactComponent as ChevronClose } from 'assets/svgs/chevron-filled-close.svg';
import { ReactComponent as ChevronOpen } from 'assets/svgs/chevron-filled-open.svg';

import style from './MeasuresHeaderCellRenderer.module.pcss';

const b = block(style);

interface MeasuresHeaderCellRendererProps extends IHeaderParams {
  headerClass: string;
  measureName?: string;
  shouldHeaderBeExpandable?: boolean;
}

const MeasuresHeaderCellRenderer: React.FC<MeasuresHeaderCellRendererProps> = (params) => {
  const { selectedPlanningCycle } = useScope();

  if (!params) {
    return null;
  }

  const columnId = params?.column?.getId();
  const isPeriodicityColumn =
    params?.shouldHeaderBeExpandable &&
    params?.measureName !== QuotaGridColumnName.ALLOCATION &&
    selectedPlanningCycle.periodicity !== PeriodicityType.NONE;
  const periodicityType = selectedPlanningCycle.periodicity;

  const isTotalColumn = (periodicityColDef: ColDef, periodicity: PeriodicityType) => {
    switch (periodicity) {
      case PeriodicityType.MONTHLY:
        if (
          periodicityColDef.field.split('_')[1].includes('Q') ||
          periodicityColDef.field.split('_')[1].includes('H')
        ) {
          return true;
        }
        return false;
      case PeriodicityType.QUARTERLY:
        if (periodicityColDef.field.split('_')[1].includes('H')) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  const getHeaderName = (displayName: string) => {
    const colDef = params.column?.getColDef() as QuotaGridMeasureColumn;
    return colDef?.periodicityMeasure || displayName;
  };

  const togglePeriodicity = (showPeriodicity) => {
    const colDef = params.column?.getColDef() as QuotaGridMeasureColumn;
    const parentColumnHeader = colDef?.parentMeasure || params.measureName;

    const allColumns = params.columnApi?.getAllColumns();
    const parentColumn = allColumns?.find((column) => column?.getColDef()?.field === parentColumnHeader);
    const periodicityColumns = allColumns?.filter((column) =>
      column?.getColDef()?.field?.includes(`${parentColumnHeader}_`)
    );

    params.columnApi.setColumnsVisible(periodicityColumns, showPeriodicity);

    const parentColDef = parentColumn?.getColDef();

    if (showPeriodicity) {
      parentColDef.headerClass = `${b('expandedColumnHeader')} ${params.headerClass}`;
    } else {
      parentColDef.headerClass = params.headerClass;
    }

    parentColDef.cellClassRules = { [b('expandedColumn')]: () => showPeriodicity };

    periodicityColumns.forEach((column, index) => {
      const periodicityColDef = column.getColDef();

      periodicityColDef.headerClass = `${b('expandedPeriodicityHeader', {
        last: index === periodicityColumns.length - 1
      })} ${params.headerClass}`;

      periodicityColDef.cellClass = b('expandedPeriodicity', {
        last: index === periodicityColumns.length - 1,
        quarterNonEditable: isTotalColumn(periodicityColDef, periodicityType),
        annualTotal: periodicityColDef.field.includes('Annual')
      });

      if (periodicityType !== PeriodicityType.YEARLY) {
        if (index === periodicityColumns.length - 1) {
          column['lastPeriodicityItem'] = true;
        }
      }

      column['periodicityType'] = periodicityType;

      column['isExpanded'] = showPeriodicity;
    });

    parentColumn['isExpanded'] = showPeriodicity;
    if (periodicityType === PeriodicityType.YEARLY) {
      parentColumn['lastPeriodicityItem'] = showPeriodicity;
    }
    params.api.refreshCells({ force: true, suppressFlash: true });
    params.api.refreshHeader();
  };

  return (
    <div className={b()} data-testid="allocated-quota-header-cell-renderer">
      {isPeriodicityColumn || params.column['lastPeriodicityItem'] ? (
        <div
          className={b('label', {
            extraPadding: shouldHeaderHaveExtraPadding(
              columnId,
              params.column['periodicityType'],
              true,
              params.column['isExpanded']
            )
          })}
          data-testid="allocated-quota-periodicity-label"
        >
          {params.column['lastPeriodicityItem'] && (
            <Button
              icon={ChevronOpen}
              className={b('chevronOpen')}
              minimal
              data-testid="close-periodicity"
              onClick={() => togglePeriodicity(false)}
            />
          )}
          {getHeaderName(params.displayName)}
          {!params.column['isExpanded'] && (
            <Button
              icon={ChevronClose}
              className={b('chevronClose')}
              minimal
              data-testid="open-periodicity"
              onClick={() => togglePeriodicity(true)}
            />
          )}
        </div>
      ) : (
        <div
          className={b('label', {
            extraPadding: shouldHeaderHaveExtraPadding(columnId, params.column['periodicityType'], false)
          })}
          data-testid="allocated-quota-label"
        >
          {getHeaderName(params.displayName)}
        </div>
      )}
    </div>
  );
};

export default MeasuresHeaderCellRenderer;
