// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".osYrU {\n    padding: var(--m) 0;\n  }\n  .jjM7G {\n    padding-bottom: var(--m);\n  }\n  ._91MZT {\n    color: rgb(var(--color-red-3));\n    margin-bottom: 1rem;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MoveTerritoryDialog_content": "osYrU",
	"MoveTerritoryDialog_description": "jjM7G",
	"MoveTerritoryDialog_errorMessage": "_91MZT"
};
export default ___CSS_LOADER_EXPORT___;
