import React from 'react';

import { Callout } from '@blueprintjs/core';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './DataFileDragAndDrop.module.pcss';

const b = block(style);

interface DataFileDragAndDropProps extends DropzoneOptions {
  errorMessage?: string;
}

export const DataFileDragAndDrop: React.FC<DataFileDragAndDropProps> = ({ onDrop, errorMessage }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.csv, text/csv, text/*',
    maxFiles: 1,
    onDrop
  });

  return (
    <>
      <div
        {...getRootProps()}
        data-testid="dropzone"
        className={b('', {
          borderActive: isDragActive
        })}
      >
        <input {...getInputProps()} data-testid="dropzone-input-fileUpload" id="dropzone-input-fileupload" />
        <h4 className={b('title')} data-testid="select-file-input">
          <strong>{formatMessage('DATA_FILE_DRAG_DROP_TITLE')}</strong>
        </h4>
        <p className={b('dataFileDragAndDropText')}>{formatMessage('DATA_FILE_DRAG_DROP_TEXT')}</p>
      </div>
      {errorMessage && (
        <Callout intent="danger">
          <p data-testid="dropzone-error-message">{errorMessage}</p>
        </Callout>
      )}
    </>
  );
};
