import React from 'react';

import { MenuProps as BlueprintMenumProps } from '@blueprintjs/core';
// eslint-disable-next-line no-restricted-imports
import { Menu as VaricentMenu } from '@varicent/components';

// Force require a data-testid
type MenuProps = BlueprintMenumProps & {
  'data-testid': string;
};

export const Menu = (props: MenuProps) => <VaricentMenu {...props} />;
