// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2M0xl {\n  /* Undoing Bootstrap styles */\n  font-weight: unset;\n  margin-bottom: unset;\n}\n\n  .gAdBl {\n    margin-right: auto;\n  }\n\n  .Lf1IA {\n    display: flex;\n  }\n\n  .n97PC {\n    margin-left: 8px;\n    visibility: hidden;\n  }\n\n  .Usx\\+v {\n      visibility: visible;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CheckableMenuItem": "_2M0xl",
	"CheckableMenuItem_checkbox": "gAdBl",
	"CheckableMenuItem_text": "Lf1IA",
	"CheckableMenuItem_spinner": "n97PC",
	"CheckableMenuItem_spinner__isLoading": "Usx+v"
};
export default ___CSS_LOADER_EXPORT___;
