import { ColDef, ICellRendererParams } from '@ag-grid-community/core';

import { UserActionCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/UserActionCellRenderer/UserActionCellRenderer';

import { UserProfile } from 'app/core/userManagement/userProvider';

import { GridFields, MemberData, UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { ExistingMemberActionModalTypes, MemberActionModalState } from './UserManagementPanel';
import style from './UserManagementPanel.module.pcss';

const b = block(style);

const sharedColumnProperties = {
  flex: 1,
  sortable: true
};

interface UserManagementColumnDefParams {
  userProfile: UserProfile;
  cellRenderer: (cell: ICellRendererParams, userProfile: UserProfile) => JSX.Element;
  setMemberActionModalState: React.Dispatch<React.SetStateAction<MemberActionModalState>>;
}

// Set the Column Definitions.
const buildUserManagementPanelColumnDefs = (userManagementColumnDefParams: UserManagementColumnDefParams): ColDef[] => {
  const { userProfile, cellRenderer, setMemberActionModalState } = userManagementColumnDefParams;

  return [
    {
      field: '',
      maxWidth: 50,
      checkboxSelection: true,
      sortable: false,
      ...sharedColumnProperties
    },
    {
      headerName: formatMessage('USER_MANAGEMENT_TABLE_EMPLOYEE_ID'),
      field: GridFields.EMPLOYEE_ID,
      ...sharedColumnProperties
    },
    { headerName: formatMessage('FIRST_NAME'), field: GridFields.FIRST_NAME, ...sharedColumnProperties },
    { headerName: formatMessage('LAST_NAME'), field: GridFields.LAST_NAME, ...sharedColumnProperties },
    {
      headerName: formatMessage('EMAIL'),
      field: GridFields.EMAIL_ADDRESS,
      cellClass: b('emailBlue'),
      ...sharedColumnProperties
    },
    {
      headerName: formatMessage('USER_MANAGEMENT_TABLE_JOB_TITLE'),
      field: GridFields.JOB_TITLE,
      ...sharedColumnProperties
    },
    {
      headerName: formatMessage('USER_MANAGEMENT_TABLE_ROLE_NAME'),
      field: GridFields.SYSTEM_ROLE_NAME,
      maxWidth: 150,
      ...sharedColumnProperties
    },
    {
      headerName: formatMessage('DATE_MODIFIED'),
      field: GridFields.MEMBER_UPDATED_AT,
      ...sharedColumnProperties
    },
    {
      headerName: formatMessage('ACTION'),
      maxWidth: 100,
      field: GridFields.ACTION,
      cellClass: b('action'),
      ...sharedColumnProperties,
      cellRendererFramework: UserActionCellRenderer,
      sortable: false,
      cellRendererParams: {
        onUserActionCellClick: (row: MemberData, inviteAdmin: boolean) => {
          // Revoke the Contributor status from the user.
          const { systemRoleName } = row;
          // eslint-disable-next-line no-restricted-syntax
          if (systemRoleName === UserRoleType.CONTRIBUTOR) {
            if (inviteAdmin) {
              setMemberActionModalState({
                action: ExistingMemberActionModalTypes.InviteAdmin,
                member: row
              });
            } else {
              setMemberActionModalState({
                action: ExistingMemberActionModalTypes.RevokeContributor,
                member: row
              });
            }
            // eslint-disable-next-line no-restricted-syntax
          } else if (systemRoleName === UserRoleType.ADMIN) {
            setMemberActionModalState({
              action: ExistingMemberActionModalTypes.RevokeAdmin,
              member: row
            });
            // eslint-disable-next-line no-restricted-syntax
          } else if (systemRoleName === UserRoleType.NONE) {
            if (inviteAdmin) {
              // Invite the user to be a Admin.
              setMemberActionModalState({
                action: ExistingMemberActionModalTypes.InviteAdmin,
                member: row
              });
            } else {
              // Invite the user to be a Contributor.
              setMemberActionModalState({
                action: ExistingMemberActionModalTypes.InviteContributor,
                member: row
              });
            }
          }
        }
      }
    },
    {
      headerName: '',
      maxWidth: 60,
      cellRendererFramework: (cell) => cellRenderer(cell, userProfile),
      cellClass: b('delete'),
      sortable: false,
      field: GridFields.DELETE,
      onCellClicked: (cell) => {
        // Prevent the logged-in user from interacting with this field.
        if (userProfile.emailAddress !== cell.data.emailAddress) {
          setMemberActionModalState({
            action: ExistingMemberActionModalTypes.Delete,
            member: cell.data
          });
        }
      },
      ...sharedColumnProperties
    }
  ];
};

export default buildUserManagementPanelColumnDefs;
