import gql from 'graphql-tag';

export const GET_ACTIVITIES_FOR_TERRITORY = gql`
  query GetActivitiesForTerritory(
    $battlecardId: Int
    $territoryId: String!
    $territoryGroupId: Int
    $quotaComponentId: Int!
    $startRow: Int
    $endRow: Int
  ) {
    getActivitiesForTerritory(
      input: {
        battlecardId: $battlecardId
        territoryId: $territoryId
        territoryGroupId: $territoryGroupId
        quotaComponentId: $quotaComponentId
        startRow: $startRow
        endRow: $endRow
      }
    ) {
      territoryActivitiesList
      territoryActivityCount
    }
  }
`;
