// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4ymOB {\n    padding: 0;\n    text-align: right;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n  }\n  .EB1L6 {\n    padding: 0;\n    text-align: right;\n    width: 100%;\n    display: flex;\n    justify-content: flex-start;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountEditableFieldCellRenderer_readOnly": "_4ymOB",
	"AccountEditableFieldCellRenderer_alignLeft": "EB1L6"
};
export default ___CSS_LOADER_EXPORT___;
