import gql from 'graphql-tag';

// No fragment used to avoid coupling to API-type-name
export const GET_GEO_CATALOG_AND_SELECTION = gql`
  query GetGeoCatalogAndSelection($planningCycleId: Int!) {
    getPlanningCycleSpec(input: { planningCycleId: $planningCycleId }) {
      planningCycleId
      inUseCountrySourceCatalogItems {
        catalogId
        depth
        polygonCount
        country
        name
        sourceId
        sourceKey
        sourceUrl
        countryName
      }
      availableCountrySourceCatalogItems {
        catalogId
        depth
        polygonCount
        country
        name
        sourceId
        sourceKey
        sourceUrl
        countryName
      }
    }
  }
`;
