// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3CoAs {\n    display: flex;\n    width: 100%;\n    justify-content: space-around;\n    align-items: center;\n    font-weight: normal;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RolePermissionColumnHeaderCellRenderer_rolePermissionColumnHeader": "_3CoAs"
};
export default ___CSS_LOADER_EXPORT___;
